import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useIncentiveTablesStyles = makeStyles((theme: Theme) => ({
  IncentiveTableStyles: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
      },
      '&:nth-child(5) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
      },
      '&:nth-child(6) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      cursor: 'default !important',
      '&:nth-child(1)': {
        // backgroundColor: 'red',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(2)>div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(4)': {
        paddingLeft: 0,
      },
      '&:nth-child(4)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(5)': {
        paddingLeft: 0,
      },
      '&:nth-child(5)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },

      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
      },
    },
  },
}));
