import { useCallback } from 'react';
import {
  confirmLocation,
  GbpAccount,
  GbpLocation,
  getConnectedAccount,
  getGbpAccounts,
  getGbpLocations,
  googleOauth2,
} from '../../store/slices/gbpSlice';
import { getGbpLocation } from '../utilities';
import { useAppDispatch, useTypedSelector } from '../../store';
import { useVenueProperty } from '../../hooks/useVenueProperty';
import { VenueProperties } from '../../api/models/venue';

export const useGbpSync = (
  onError: (isError: boolean) => void,
  onSuccess?: () => void,
  onMultipleLocations?: () => void,
) => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const { value: companyAddress } = useVenueProperty<VenueProperties['company-address']>({
    property: 'company-address',
  });

  const { value: agreementAttributes } = useVenueProperty<VenueProperties['agreement_attributes']>({
    property: 'agreement_attributes',
  });

  const companyWebsite = agreementAttributes?.company_website || '';

  return useCallback(
    async (
      state: string | null,
      code: string | null,
      isBrand360?: boolean,
      selectedLocation?: GbpLocation,
    ) => {
      let signInError = true;
      let locations: GbpLocation[] = [];

      if (!selectedLocation && state && code) {
        await dispatch(
          googleOauth2({
            code,
            state,
          }),
        );

        const gbpAccounts = (
          await dispatch(
            getGbpAccounts({
              accountId: venueId,
            }),
          )
        ).payload as Array<GbpAccount>;

        const accountsId = gbpAccounts.map((account) => account.name.split('/')[1]);

        locations = (
          await dispatch(
            getGbpLocations({
              gbpAccountsId: accountsId,
              accountId: venueId,
            }),
          )
        ).payload as Array<GbpLocation>;

        if (locations.length > 1) {
          onMultipleLocations?.();
          return;
        }
      }
      let companyLocation;

      if (selectedLocation) {
        companyLocation = selectedLocation;
      } else if (locations.length && companyAddress) {
        const { zip, address } = companyAddress;
        companyLocation = getGbpLocation(
          selectedLocation ? [selectedLocation] : locations,
          zip,
          address,
          companyWebsite,
          isBrand360,
        );
      }
      if (companyLocation) {
        signInError = false;
        const { name, accountId } = companyLocation;
        dispatch(
          confirmLocation({
            gbpAccountId: accountId,
            locationId: name.split('/')[1],
            accountId: venueId,
          }),
        ).then((res) => {
          if (res.payload) {
            dispatch(
              getConnectedAccount({
                accountId: venueId,
              }),
            );

            onSuccess?.();
          }
        });
      }

      onError(signInError);
    },
    [dispatch, venueId, companyAddress, companyWebsite, onSuccess, onError, onMultipleLocations],
  );
};
