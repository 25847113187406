import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect, useMemo, useState } from 'react';
import { VideoCard } from '../../../../common/components/VideoCard/VideoCard';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { VideoApiModel } from '../../../../api/models/videos';
import { getAllSocialVerse } from '../../../../store/slices/socialVerseSlice';
import { NewDetailsModal } from '../../../../common/components/NewDetailsModal/NewDetailsModal';
import { useModal } from '../../../../hooks/useModal';
import { ClientTypes } from '../../../../api/models/common';
import { MenuOptions } from '../../../../common/components/VideoCard/VideoCard.helper';
import { LoadingButton } from '@mui/lab';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import { theme } from '../../../../theme';
import { isFirefox, isSafari } from 'react-device-detect';

const isSafariOrFirefox = isSafari || isFirefox;

export const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(189px, min(189px, 100%/5, max(189px, 100%/7))))',
    gridGap: '12px 24px',
    maxWidth: 'calc(100vw - 186px)',
    minWidth: '630px',
    maxHeight: `calc(100vh - ${isSafariOrFirefox ? '180px' : '70px'})`,
  },
  child: {
    width: '189px',
    height: '285px',
    borderRadius: '16px',
  },
  table: {
    position: 'relative',
    '& #common-table-wrapper': {},
    '& tr > td:first-child': {
      minWidth: '220px',
    },
    '& td > *': {
      fontSize: '13px !important',
    },
    '& th': {
      '&:nth-child(3) > div': {
        justifyContent: 'flex-start !important',
        paddingLeft: '30px',
      },
    },
    '& td': {
      '&:nth-child(3)': {
        textAlign: 'start !important',
        paddingLeft: '40px !important',
      },
      '&:nth-child(9)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(10)': {
        paddingLeft: '0px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        paddingRight: '12px',
      },
    },
  },
  addBtn: {
    position: 'absolute',
    top: '0',
    left: '0',
    zIndex: '99',
  },
  loadMoreBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    gridColumn: '1 / -1',
    marginTop: '20px',
  },
  loadMoreBtn: {
    borderRadius: '32px',
    border: '1px solid #DCDBE4',
    height: '32px',
    padding: '7px 12px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#1A1538',
    background: 'white',
    '& svg': {
      animation: '$rotate 2s linear infinite',
      color: theme.palette.primary.main,
    },
  },
}));

interface GridContentTableProps {
  items: VideoApiModel[];
  handleOpenConfirmationModal?: (id: string) => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  isEmptyFilterResults: boolean;
  onLoadMore?: () => void;
  isLoading: boolean;
  isLoadMoreBtnDisabled: boolean;
  onUpdateThumbnail?: () => void;
}

const GridContentTable: FC<GridContentTableProps> = ({
  items,
  handleOpenConfirmationModal,
  handleOpenDeleteModal,
  isEmptyFilterResults,
  onLoadMore,
  isLoading,
  isLoadMoreBtnDisabled,
  onUpdateThumbnail,
}) => {
  const classes = useStyles();
  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);
  const dispatch = useAppDispatch();
  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;

  const { open, close, isOpen } = useModal();

  const [story, setStory] = useState<VideoApiModel | undefined>();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const openDetailsModal = (type: MenuOptions, item: VideoApiModel) => {
    setStory(item);
    setActiveTabIndex(type === MenuOptions.VIEW_TRANSCRIPT ? 1 : 0);
    open();
  };

  useEffect(() => {
    dispatch(getAllSocialVerse({ accountId: venueId, withVideos: true }));
  }, [dispatch, venueId]);

  const svList = useMemo(() => {
    return socialVerseAll?.filter((item) => item.accountId === venueId) || [];
  }, [socialVerseAll, venueId]);

  return (
    <Box className={classes.root}>
      {isOpen && story && (
        <NewDetailsModal
          userId={isNotTopgolf ? (story as VideoApiModel)?.userId || '' : ''}
          isOpen={isOpen}
          videoId={story.id}
          handleClose={close}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          activeTabIndex={activeTabIndex}
          onUpdateThumbnail={onUpdateThumbnail}
        />
      )}
      {items.map((item) => (
        <Box className={classes.child} key={item.id}>
          <VideoCard
            video={item}
            svList={svList}
            isGridLayout
            openDetailsModal={openDetailsModal}
          />
        </Box>
      ))}
      {!isEmptyFilterResults && (
        <Box className={classes.loadMoreBtnWrapper}>
          <LoadingButton
            loading={isLoading}
            disabled={isLoadMoreBtnDisabled}
            onClick={onLoadMore}
            variant="outlined"
            className={classes.loadMoreBtn}
            loadingIndicator={<></>}
          >
            {isLoading ? (
              <AutorenewRoundedIcon
                sx={{
                  animation: 'spin 1s linear infinite',
                  '@keyframes spin': {
                    '0%': {
                      transform: 'rotate(0)',
                    },
                    '100%': {
                      transform: 'rotate(360deg)',
                    },
                  },
                }}
                color="primary"
              />
            ) : (
              'Load More'
            )}
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default GridContentTable;
