import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useReviewInvitesTableStyles = makeStyles((theme: Theme) => ({
  tableBodyContainer: {
    maxHeight: `${44 * 9}px`,
    overflowY: 'auto',
  },
  tableRowContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E1E5EE',
  },
  rowCell: {
    color: '#1A1538',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    lineHeight: '24px',
  },
  tableCell: {
    width: '160px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
  },
  headerCell: {
    color: '#8B89A0',
    fontSize: '13px',
    lineHeight: '24px',
  },
}));
