import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    padding: '0px 20px 16px 20px',
  },
  menuButton: {
    border: `1px solid #D0D5DD`,
    width: '218px',
    justifyContent: 'space-between',
    color: '#667085',
    fontWeight: '400',
    fontSize: '14px',
    paddingRight: '18px',
  },
  button: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 14px',
    width: '195px',
    height: '44px',
    color: theme.palette.primary.main,
  },
  uploadBtnControl: {
    display: 'flex',
  },
  uploadBtn: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 14px',
    width: '113px',
    height: '40px',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: '600',
  },
  menu: {
    border: '1px solid #EAECF0',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '8px',
  },
  item: {
    minHeight: '40px',
    padding: '10px 16px',
    background: theme.palette.common.white,
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#667085',
  },
  value: {
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700',
    marginLeft: '8px',
  },
  icon: {
    height: '18px',
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
  toggleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    marginBottom: '20px',
  },
  toggleWrapperTitle: {
    color: '#344054',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    textAlign: 'left',
  },
  toggleWrapperSubTitle: {
    color: '#475467',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    textAlign: 'left',
  },
  toggleWrapperSubTitleBold: {
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
  uploadSubTitle: {
    color: '#344054',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
  },
  uploadSubTitleBold: {
    color: '#344054',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  durationBold: {
    color: '#475467',
    fontWeight: '700',
    fontSize: '14px',
  },
  progressWrapper: {
    marginTop: '10px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
  thumbnail: {
    width: '106px',
    aspectRatio: '9 / 16',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    overflow: 'hidden',
    marginRight: '15px',
  },
  error: {
    marginTop: '15px',
    color: '#ED2E38',
  },
  videoTypeDescription: {
    marginTop: '20px',
    color: '#475467',
    fontWeight: '500',
    fontSize: '14px',
  },
}));
