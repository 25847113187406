import { MenuItem, Select } from '@mui/material';
import React, { FC } from 'react';
import { ArrowBottomIcon } from '../../../../common/assets/newDesign/ArrowBottomIcon';
import { useStyles } from './ImportHistoryTableSection.helper';

interface Option {
  content: string;
  value: number;
}

interface Props {
  value: number;
  options: Option[];
  selectValue: (value: number) => void;
}

export const SheduleSelect: FC<Props> = ({ value, selectValue, options }) => {
  const styles = useStyles({});
  return (
    <Select
      className={styles.select}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        PaperProps: {
          className: styles.menuWrapper,
        },
      }}
      disableUnderline={true}
      IconComponent={ArrowBottomIcon}
      value={value}
      displayEmpty={false}
      onChange={(event) => {
        selectValue(Number(event.target.value));
      }}
    >
      {options.map((option) => {
        return (
          <MenuItem value={option.value} key={option.value}>
            {option.content}
          </MenuItem>
        );
      })}
    </Select>
  );
};
