import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { IPhonePreview } from './IPhonePreview';
import { useVenue } from '../../../hooks/useVenue';

import { FakeRecaptcha } from './previewComponents/FakeRecaptcha';
import { FakeTextField } from './previewComponents/FakeTextField';

import Brush from '../../assets/BrushFrame.png';
import { theme } from '../../../theme';

const BrushFrame = () => <img src={Brush} alt="img" />;

interface IPhoneVerificationProps {
  phone?: string;
  phoneRequired?: boolean;
  name?: string;
  nameRequired?: boolean;
  email?: string;
  emailRequired?: boolean;
  brandColor: string;
  logoImage?: string;
  backgroundImage?: string;
}

export const IPhoneVerification: React.FC<IPhoneVerificationProps> = ({
  phone,
  phoneRequired,
  name,
  nameRequired,
  email,
  emailRequired,
  logoImage,
  backgroundImage,
  brandColor,
}) => {
  const { name: venueName } = useVenue();

  return (
    <Box>
      <IPhonePreview>
        {backgroundImage && (
          <img style={{ position: 'absolute' }} src={backgroundImage} alt="background" />
        )}
        <Box sx={{ position: 'relative' }}>
          <Box style={{ transform: 'rotateZ(12deg) translateX(24px) scale(1.2)' }}>
            <BrushFrame />
          </Box>

          <Typography
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              top: '40px',
              color: theme.palette.common.white,
              fontSize: '18px',
              fontWeight: '700',
            }}
          >
            {venueName}
          </Typography>
          <Typography
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              top: '68px',
              color: theme.palette.common.white,
              fontSize: '14px',
              fontWeight: '500',
            }}
          >
            Share your experiences with us
          </Typography>
          <Typography
            sx={{
              width: '100%',
              textAlign: 'center',
              position: 'absolute',
              top: '90px',
              color: theme.palette.common.white,
              fontSize: '12px',
              fontWeight: '400',
            }}
          >
            Create your profile to get started
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" p="8px">
          <Box
            sx={{
              width: '100%',
              padding: '18px 12px 12px',
              zIndex: 1,
              background: 'linear-gradient(rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
              marginTop: '20px',
              borderRadius: '12px',
              backdropFilter: 'blur(3.5px)',
            }}
          >
            {logoImage && (
              <Box>
                <img
                  src={logoImage}
                  alt="logo"
                  style={{
                    maxHeight: '40px',
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                />
              </Box>
            )}

            <FakeTextField
              title="Cell Phone"
              color={brandColor}
              required={phoneRequired}
              value={phone}
              mb="16px"
            />
            <FakeTextField
              title="Email"
              color={brandColor}
              required={emailRequired}
              value={email}
              mb="16px"
            />
            <FakeRecaptcha mb="16px" />

            <Button
              fullWidth
              variant="contained"
              style={{
                backgroundColor: brandColor || '#42a5f5',
                marginBottom: '10px',
                fontSize: '12px',
                color: '#fff',
              }}
              disabled
            >
              Send Activation Code
            </Button>
          </Box>
        </Box>
      </IPhonePreview>
    </Box>
  );
};
