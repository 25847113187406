import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CampaignsVideosDTO } from '../storeModels';
import { setTableSorting } from './utils';

const initialState: CampaignsVideosDTO = {};

const campaignsStoryVideosSlice = createSlice({
  name: 'campaignsStoryVideos',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<{ page: number; id: string }>) {
      const { id, page } = action.payload;
      state[id].page = page;
    },
    setCampaignsSorting(state, action: PayloadAction<{ sort: string; id: string }>) {
      const { id, sort } = action.payload;
      const campaignVideos = state[id];
      state[id] = setTableSorting(campaignVideos, sort);
    },
    updateTable(state, action: PayloadAction<{ id: string }>) {
      const { id } = action.payload;
      state[id].lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
  },
});

export const { goToSelectedPage, updateTable, setCampaignsSorting, reset } =
  campaignsStoryVideosSlice.actions;
export default campaignsStoryVideosSlice.reducer;
