import { FC } from 'react';
import { Box, Switch } from '@mui/material';
import { MonitorIcon } from '../../assets/MonitorIcon';
import { MobileIcon } from '../../assets/MobileIcon';

interface Props {
  isChecked: boolean;
  onChange: () => void;
}

export const MobileDesktopSwitch: FC<Props> = ({ isChecked, onChange }) => {
  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Box sx={{ position: 'absolute', top: '4px', left: '16px' }}>
        <MonitorIcon />
      </Box>
      <Box sx={{ position: 'absolute', top: '4px', left: '60px' }}>
        <MobileIcon />
      </Box>

      <Switch
        id="preview-device-change-button"
        checked={isChecked}
        onChange={onChange}
        checkedIcon={
          <Box
            id="preview-mobile-device-icon"
            sx={{
              width: '50px',
              height: '28px',
              backgroundColor: '#fff',
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              top: '0px',
              left: '0px',
            }}
          >
            <MobileIcon />
          </Box>
        }
        icon={
          <Box
            id="preview-desktop-device-icon"
            sx={{
              width: '50px',
              height: '28px',
              backgroundColor: '#fff',
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              top: '0px',
              left: '0px',
            }}
          >
            <MonitorIcon />
          </Box>
        }
        sx={{
          width: '96px',
          height: '28px',
          p: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '40px',
          border: '1px solid #C7C7D4',
          '& .MuiButtonBase-root': {
            p: '0px !important',
            backgroundColor: 'inherit !important',
          },
          '& .MuiSwitch-track': {
            backgroundColor: 'inherit !important',
          },
          '& .Mui-checked': {
            transform: 'translateX(43px) !important',
          },
        }}
      />
    </Box>
  );
};
