import styled from 'styled-components';

export const RootContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(1 / 0.9 * 100vh); // ignore zoom property
  justify-content: center;
  background-color: var(--contentBackgroundColor);
`;
export const AppContainer = styled.div`
  display: flex;
  width: 100%;
`;
