import { FC } from 'react';
import { Button, Grid } from '@mui/material';

import { FSModal } from '../../common/components/modal/Modal';
import { VenueWizardPreviewStepStrings } from '../../common/localization/en';
import { StyledConfirmText, ModalInfoText } from './Venuewizard.style';

interface WizardConfirmationModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  handleConfirm: () => void;
}

export const WizardConfirmationModal: FC<WizardConfirmationModalProps> = ({
  handleConfirm,
  isModalOpen,
  handleCloseModal,
}) => {
  return (
    <FSModal onClose={handleCloseModal} modalIsOpen={isModalOpen}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={5}>
          <StyledConfirmText>{VenueWizardPreviewStepStrings.ModalTitle}</StyledConfirmText>
        </Grid>
        <Grid item xs={10}>
          <ModalInfoText>{VenueWizardPreviewStepStrings.ModalText}</ModalInfoText>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Button color="primary" onClick={handleCloseModal}>
                {VenueWizardPreviewStepStrings.ModalCancel}
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button variant="contained" color="primary" onClick={handleConfirm}>
                {VenueWizardPreviewStepStrings.ModalApply}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
