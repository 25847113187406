import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    gap: '16px',
  },
  button: {
    width: '183px',
    height: '36px',
    borderRadius: '30px',
  },
  subtitle: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
    width: '100%',
  },
  statsPopupRoot: {
    width: '591px',
    height: '450px',
    padding: '15px',
  },
  popupTitle: {
    fontSize: '18px',
    color: theme.palette.common.black,
    fontWeight: 'bolder',
  },
  statsWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '591px',
    height: '50%',
    flexWrap: 'wrap',
    gap: '10px',
    marginTop: '20px',
  },
  actionButtonsWrapper: {
    position: 'absolute',
    bottom: '15px',
    left: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    gap: '10px',
  },
  actionButton: {
    width: '97px',
    borderRadius: '20px',
    height: '36px',
  },
}));
