import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '264px',
    position: 'relative',
  },
  clearButton: {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#344054',
    position: 'absolute',
    top: '-34px',
    right: '0',
    minWidth: '54px',
    padding: '5px 1px',
  },
  inputLabel: {
    backgroundColor: '#FFF',
    top: '-4px',
    fontSize: '14px',
  },
  checkbox: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  dotIcon: {
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: '5px',
  },
  iconWrapper: {
    height: '20px',
    width: '20px',
    marginLeft: '16px',
    marginBottom: '5px',
  },
  expandIconWrapper: {
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '-10px',
    left: '-30px',
  },
  placeholder: {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#667085',
  },
  campaignBadgesWrapperRef: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    paddingRight: '10px',

    maxHeight: '112px',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      height: '10px !important',
      maxHeight: '20px !important',
      borderRadius: '12px',
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#EAECF0',
      borderRadius: '5px',
      height: '30px !important',
      cursor: 'pointer',
    },
  },
  badgeButton: {
    padding: '4px 5px 4px 12px',
    backgroundColor: '#F4F3FF',
    borderRadius: '16px',
    fontSize: '11px',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#F4F3FF',
    },
  },
}));
