import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  link: {
    paddingTop: 9,
    paddingBottom: 9,
    color: theme.palette.primary.main,
    backgroundColor: '#eaf4ff',
    borderRadius: 83,
    fontSize: 13,
    letterSpacing: 0.46,

    '&:hover, &:focus, &:visited': {
      backgroundColor: '#eaf4ff',
    },
  },
  webappLink: {
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
  },
}));
