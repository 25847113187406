import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiTablePagination-selectLabel': {
      color: '#8B89A0',
      fontSize: '12px',
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '12px',
      color: '#1A1538',
    },
  },
  selectIconWrapper: {
    paddingRight: '10px',
    paddingBottom: '3px',
  },
  navButton: {
    '&.MuiIconButton-root': {
      backgroundColor: theme.palette.primary.main,
      color: '#FFFFFF',
      marginRight: '5px',
    },
    '&.Mui-disabled': {
      backgroundColor: '#F5F6F8 !important',
      color: '#8B89A0 !important',
    },
  },
  select: {
    border: '1px solid #DCDBE4',
    borderRadius: '7px',
    height: '34px',
    fontSize: '12px',
    color: '#1A1538',
    '& .MuiInputBase-input': {
      paddingRight: '10px !important',
      '&:focus': {
        background: '#fff',
      },
    },
    '& .MuiMenuItem-root': {
      fontSize: '12px',
    },
  },
}));
