import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useCreateVideoFilterModalStyles } from './CreateVideoFilterModal.style';
import { SearchFilterStrings } from '../../localization/en';
import {
  IFilterOption,
  createVideoFilterInputRows,
  createVideoFilterPreview,
  headCellsNames,
  stateToAtrributes,
  tableNumberRows,
  tableVideoFilterRows,
} from './CreateVideoFilterModal.helper';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { addSeveralFilterAttributes, setTarget } from '../../../store/slices/searchFiltersSlice';
import {
  SearchFilterApiModel,
  SearchFilterAttributes,
  SearchTargetsType,
} from '../../../api/models/searchFilter';
import { filtersResultRoutes } from '../CreateUserFilterModal/CreateUserFilterModal.helper';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import { getCampaigns } from '../../../store/slices/campaignsSlice';

interface ICreateFilterModalProps {
  isCreationModal: boolean;
  toggleCreationModal: () => void;
  children: ReactNode;
  savedFilter?: SearchFilterApiModel | SearchFilterAttributes[];
  target: SearchTargetsType;
  isSocailVerse?: boolean;
  onDateUpdate?: (value?: string) => void;
}

export const CreateVideoFilterModal: FC<ICreateFilterModalProps> = ({
  isCreationModal,
  toggleCreationModal,
  children,
  savedFilter,
  target,
  isSocailVerse,
  onDateUpdate,
}) => {
  const history = useHistory();
  const styles = useCreateVideoFilterModalStyles();
  const dispatch = useAppDispatch();
  const { createdAt, id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { items: campaings } = useTypedSelector((state) => state.campaigns);
  const { trackEvent } = useTrackEvent();

  const initialVideoFilterState: {
    [key: string]: null | IFilterOption;
  } = {
    createdAt: null,
    campaignId: {
      operation: 'IN',
      option: [],
      from: 0,
      to: 0,
    },
    sharePlatform: {
      option: [],
      from: 0,
      to: 0,
    },
    views: {
      option: '',
      from: 0,
      to: 10,
    },
    ctaClicks: {
      option: '',
      from: 0,
      to: 10,
    },
    keywordScore: {
      option: '',
      from: 0,
      to: 10,
    },
    status: {
      operation: 'EQUALS',
      option: '',
      from: 0,
      to: 0,
    },
  };

  useEffect(() => {
    dispatch(
      getCampaigns({
        accountId: venueId,
        pageable: {
          page: 0,
          size: 1000,
          sort: ['createdAt', 'desc'],
        },
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueId]);

  const filterCampaignNames = useMemo(() => {
    const campaignIds =
      //@ts-ignore
      (Array.isArray(savedFilter) ? savedFilter : savedFilter?.filter?.attributes)?.find(
        (attr) => attr.name === 'campaignId',
      )?.value || [];
    const displayedIds = campaignIds.slice(0, 5);

    return campaings
      .filter((campaign) => displayedIds.includes(campaign.id))
      .map((campaign) => campaign.name);
  }, [campaings, savedFilter]);

  const [filter, setFilter] = useState<typeof initialVideoFilterState>({
    ...initialVideoFilterState,
  });

  const handleDateUpdate = (filterName: string, value: any) => {
    onDateUpdate?.(value ? `from: ${value.from} to:${value.to}` : undefined);

    if (!value) {
      setFilter((prevState) => {
        let newState = _.set(prevState, filterName, null);
        return { ...newState };
      });
    } else {
      setFilter((prevState) => {
        let newState = _.set(prevState, `${filterName}.GREATER_THAN`, value?.from || null);
        newState = _.set(prevState, `${filterName}.LESS_THAN`, value?.to || null);
        return { ...newState };
      });
    }
  };

  const handleVideoOptionUpdate = (filterName: string, value: any) => {
    setFilter((prevState) => {
      const newState = _.set(prevState, `${filterName}.option`, value);
      return { ...newState };
    });
  };

  const handleRangeFiltersUpdate = (
    filterName: string,
    option: string,
    from: number,
    to: number,
  ) => {
    if (Number.isNaN(from)) {
      from = 1;
    }
    if (option === 'Range') {
      setFilter((prevState) => {
        let newState = _.set(prevState, `${filterName}.from`, from);
        newState = _.set(prevState, `${filterName}.to`, to);
        return { ...newState };
      });
    } else {
      setFilter((prevState) => {
        const newState = _.set(prevState, `${filterName}.from`, from);
        return { ...newState };
      });
    }
  };

  const handleCampaignsUpdate = (campaignIds: string[]) => {
    //@ts-ignore
    setFilter((prevState) => {
      const campaignId = { ...prevState.campaignId, option: campaignIds };
      return { ...prevState, campaignId };
    });
  };

  const handleReset = () => {
    setFilter(initialVideoFilterState);
  };

  const rangeIsOk = () => {
    let canSave = true;
    Object.entries(filter).map(([key, value]) => {
      if (value?.option === 'Range' && +value?.from >= +value?.to) {
        canSave = false;
      }
      return { key, value };
    });
    return canSave;
  };

  return (
    <Modal
      open={isCreationModal}
      onClose={toggleCreationModal}
      style={{
        background: 'rgba(16, 14, 24, 0.8)',
        backdropFilter: 'blur(20px)',
        overflowY: 'auto',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <div className={styles.Container}>
        <IconButton onClick={toggleCreationModal} className={styles.CloseButton}>
          <CloseRoundedIcon />
        </IconButton>
        <div>
          <>
            {children}
            {savedFilter ? (
              <Box display="flex" alignItems="baseline">
                <Typography component="h2" className={styles.Title}>
                  {SearchFilterStrings.VideoFilter}
                </Typography>
                <Typography component="h3" className={styles.SubTitle}>
                  {'name' in savedFilter && savedFilter.name}
                </Typography>
              </Box>
            ) : (
              <Typography component="h2" className={styles.Title}>
                {SearchFilterStrings.CreateVideofilter}
              </Typography>
            )}
            <TableContainer className={''}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow className={styles.TableRow}>
                    {headCellsNames.map((el, i) => {
                      let width = 'auto';
                      if (i === 0) width = '70px';
                      if (i === 1) width = '190px';
                      return (
                        <TableCell style={{ width: width }} key={i}>
                          {el}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={''}>
                  {tableNumberRows?.length
                    ? tableNumberRows.map((el, i) => {
                        return (
                          <TableRow key={i} className={styles.TableRow}>
                            <TableCell style={{ width: '70px' }}>
                              <Typography className={styles.RowNumber}>{i + 1}</Typography>
                            </TableCell>
                            <TableCell style={{ width: '190px' }}>
                              <Typography className={''}>{tableVideoFilterRows[i]}</Typography>
                            </TableCell>
                            <TableCell style={{ width: 'auto' }}>
                              {!savedFilter
                                ? createVideoFilterInputRows({
                                    filter,
                                    handleDateUpdate,
                                    handleRangeFiltersUpdate,
                                    handleVideoOptionUpdate,
                                    handleCampaignsUpdate,
                                    trackEvent,
                                  })[i]
                                : createVideoFilterPreview(
                                    'name' in savedFilter
                                      ? savedFilter.filter.attributes
                                      : savedFilter,
                                    filterCampaignNames,
                                  )[i]}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={styles.ButtonsContainer}>
              {savedFilter ? (
                <Box display="flex" justifyContent="center" width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      toggleCreationModal();
                    }}
                    className={[styles.Buttons].join(' ')}
                  >
                    {SearchFilterStrings.ClosePreview}
                  </Button>
                </Box>
              ) : (
                <Box display="flex">
                  <Button
                    disabled={!rangeIsOk()}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      toggleCreationModal();
                      const filterAttributes = stateToAtrributes(filter, createdAt);
                      trackEvent(VideosEventNames.applyFilterButtonClicked, {
                        //@ts-ignore
                        value: filterAttributes,
                      });
                      dispatch(addSeveralFilterAttributes(filterAttributes));
                      dispatch(setTarget(target));
                      !isSocailVerse && history.push(`${filtersResultRoutes[target]}/:filterId`);
                      handleReset();
                    }}
                    className={[styles.Buttons].join(' ')}
                  >
                    {SearchFilterStrings.ApplyFilter}
                  </Button>
                  <Box mr={2} />
                  <Button
                    variant="outlined"
                    className={[styles.Buttons].join(' ')}
                    onClick={() => {
                      handleReset();
                      trackEvent(VideosEventNames.resetFilterButtonClicked);
                    }}
                  >
                    {SearchFilterStrings.ResetAll}
                  </Button>
                </Box>
              )}
            </Box>
          </>
        </div>
      </div>
    </Modal>
  );
};
