import { Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import {
  creationDate,
  SearchFilterApiModel,
  statItemsIdEnum,
  status,
} from '../../../../api/models/searchFilter';
import { getLocalDateString } from '../../../../services/utilities';
import { VideoIcon } from '../../../assets/searchFilterIcons/VideoIcon';
import { statsItems } from '../components/FilterItems/VideoStatsFilter';
import { statsItems as userStatsItems } from '../components/FilterItems/UserStatsFilter';
import { statsItems as campaignStatsItems } from '../components/FilterItems/CampaignStatsFilter';
import { VideoStatuses } from '../components/FilterItems/VideoStatusFilter';
import { UserStatuses } from '../components/FilterItems/UserStatusFilter';
import { ReadOnlyInLineStatItem } from './ReadOnlyInLineStatItem';
import { shareTypePropertyName, ShareTypes } from '../components/FilterItems/ShareTypeFilter';
import { ShareIcon } from '../../../assets/searchFilterIcons/ShareIcon';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '20px 20px 30px',
    width: '360px',
    borderRadius: '12px',
  },
  titleLabel: {
    fontSize: '18px',
    fontWeight: 'bolder',
    marginRight: '20px',
    color: theme.palette.common.black,
  },
  title: {
    fontSize: '16px',
    color: theme.palette.common.black,
  },
  dateRowRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: ` 1px solid ${theme.palette.text.secondary}`,
    borderRadius: '12px',
    stroke: theme.palette.text.secondary,
    height: '40px',
    paddingLeft: '10px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface ListFilterItemPopperProps {
  item: SearchFilterApiModel;
}
enum ListFilterItemPopperStrings {
  Filter = 'Filter',
  Dates = 'Dates',
}

export const ListFilterItemPopper: FC<ListFilterItemPopperProps> = ({ item }) => {
  const classes = useStyles();

  const { attributes } = item.filter;
  const dateRows = attributes.filter((item) => item.name === creationDate);
  const statsRows = attributes.filter((item) => item.name in statItemsIdEnum);
  const statusRows = attributes.filter((item) => item.name === status);
  const sharedRows = attributes.filter((item) => item.name === shareTypePropertyName);

  const renderStatusRows = () => {
    return statusRows.map((statusItem) => {
      let videoStatusItem = [];

      const mapVideoStatuses = VideoStatuses.filter((status) => status.id === statusItem.value);
      if (mapVideoStatuses.length) {
        videoStatusItem = mapVideoStatuses;
      } else {
        videoStatusItem = UserStatuses.filter((status) => status.id === statusItem.value);
      }

      return (
        <div className={classes.statusRoot}>
          <span className={classes.flex}>
            <VideoIcon />
            <span style={{ marginLeft: '10px' }}>{videoStatusItem[0].title}</span>
          </span>
        </div>
      );
    });
  };

  const renderSharedRows = () => {
    return sharedRows.map((sharedItem) => {
      const videoSharedItem = ShareTypes.filter((shared) => shared.id === sharedItem.value);

      return (
        <div className={classes.statusRoot}>
          <span className={classes.flex}>
            <ShareIcon />
            <span style={{ marginLeft: '10px' }}>{videoSharedItem[0].title}</span>
          </span>
        </div>
      );
    });
  };

  const renderDateRows = () => {
    if (dateRows.length && dateRows.length === 2) {
      return (
        <div>
          <div className={classes.dateRowRoot}>
            <span>{ListFilterItemPopperStrings.Dates}</span>
            <span>
              {getLocalDateString(dateRows[0].value) +
                ' - ' +
                getLocalDateString(dateRows[1].value)}
            </span>
          </div>
        </div>
      );
    }
  };
  const renderStatsRows = () => {
    return (
      <div>
        {statsRows.map((item) => {
          let statsItem = [];
          const filterVideoStats = statsItems.filter((temp) => temp.id === item.name);
          if (filterVideoStats.length) {
            statsItem = filterVideoStats;
          } else if (userStatsItems.filter((temp) => temp.id === item.name).length) {
            statsItem = userStatsItems.filter((temp) => temp.id === item.name);
          } else {
            statsItem = campaignStatsItems.filter(
              (temp) => temp.id === item.name || temp.title.toLocaleLowerCase() === item.name,
            );
          }

          return (
            <ReadOnlyInLineStatItem
              item={item}
              icon={statsItem[0].icon}
              label={statsItem[0].title}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span className={classes.titleLabel}>{ListFilterItemPopperStrings.Filter}</span>
          <span className={classes.title}>{item.name}</span>
        </Grid>
        {dateRows.length ? (
          <Grid item xs={12}>
            {renderDateRows()}
          </Grid>
        ) : null}
        {statusRows.length ? (
          <Grid item xs={12}>
            {renderStatusRows()}
          </Grid>
        ) : null}
        {sharedRows.length ? (
          <Grid item xs={12}>
            {renderSharedRows()}
          </Grid>
        ) : null}

        {statsRows.length ? (
          <Grid item xs={12}>
            {renderStatsRows()}
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
};
