import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { httpClient } from '../../services/httpClient/httpClient';
import {
  SMSInviteConfig,
  SMSInviteGetConfigApiResponse,
  SMSInviteGetVariablesApiResponse,
  SMSInviteMessageDTO,
  SMSInviteMessageVariables,
  SMSInviteSendTestMessageApiPayload,
  SMSInviteUpdateConfigApiPayload,
} from '../../api/models/smsInvite';
import { SMSInviteEndpoints } from '../../api/endpoints';
import { SmsInviteStrings } from '../../common/localization/en';

const initialPastMessageConfig: SMSInviteMessageDTO = {
  body: '',
  mediaUrl: '',
  daysShift: 0,
  enabled: false,
};

const initialMessageConfig: SMSInviteMessageDTO = {
  body: '',
  mediaUrl: '',
  daysShift: 0,
  enabled: true,
};

interface SMSInvitesState {
  messageConfig: SMSInviteConfig;
  messageVariables: SMSInviteMessageVariables[];
  isConfigUpdating: boolean;
  isConfigLoading: boolean;
  isVariablesLoading: boolean;
  isError: boolean;
  uploadedMediaUrl: string | undefined;
  isTestMessageSending: boolean;
  isTestMessageError: boolean;
}

const initialState: SMSInvitesState = {
  messageConfig: {
    /*     preMessage: {
      ...initialPastMessageConfig,
      body: SmsInviteStrings.DefaultPreMessage,
    }, */
    postMessage: {
      ...initialMessageConfig,
      body: '',
    },
  },
  messageVariables: [],
  isConfigUpdating: false,
  isConfigLoading: false,
  isVariablesLoading: false,
  isError: false,
  uploadedMediaUrl: undefined,
  isTestMessageSending: false,
  isTestMessageError: false,
};

export const fetchMessagingConfig = createAsyncThunk(
  'smsInvites/fetchMessagingConfig',
  async (options: { accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ accountId: string }, SMSInviteGetConfigApiResponse>({
        url: `${SMSInviteEndpoints.GetConfig}`,
        requiresToken: true,
        params: {
          accountId: options.accountId,
        },
      });
    } catch (error) {
      // config is not initialized yet -> set default config
      if (error.response.data.status === 404) {
        return initialState.messageConfig;
      }

      return rejectWithValue(error.response.data);
    }
  },
);

export const fetchMessageVariables = createAsyncThunk(
  'smsInvites/fetchMessageVariables',
  async (options: { accountId: string }, { rejectWithValue }) => {
    try {
      return httpClient.get<{ accountId: string }, SMSInviteGetVariablesApiResponse>({
        url: `${SMSInviteEndpoints.GetVariables}`,
        requiresToken: true,
        params: {
          accountId: options.accountId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updateMessagingConfig = createAsyncThunk(
  'smsInvites/updateMessagingConfig',
  async (options: { accountId: string } & SMSInviteUpdateConfigApiPayload, { rejectWithValue }) => {
    try {
      return httpClient.post<
        { accountId: string } | SMSInviteUpdateConfigApiPayload,
        SMSInviteGetConfigApiResponse
      >({
        url: `${SMSInviteEndpoints.PostUpdateConfig}`,
        requiresToken: true,
        payload: options,
        params: {
          accountId: options.accountId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

export const sendTestMessage = createAsyncThunk(
  'smsInvites/sendTestMessage',
  async (
    options: { accountId: string } & SMSInviteSendTestMessageApiPayload,
    { rejectWithValue },
  ) => {
    try {
      return httpClient.post<{ accountId: string } | SMSInviteSendTestMessageApiPayload, undefined>(
        {
          url: `${SMSInviteEndpoints.PostSendTestMessage}`,
          requiresToken: true,
          payload: {
            to: options.to,
            body: options.body,
            mediaUrl: options.mediaUrl,
          },
          params: {
            accountId: options.accountId,
          },
        },
      );
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const smsInvitesSlice = createSlice({
  name: 'smsInvites',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<SMSInviteConfig>) {
      state.messageConfig = action.payload;
    },
    reset(state) {
      state = initialState;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(fetchMessagingConfig.rejected, (state) => {
      state.isError = true;
      state.isConfigLoading = false;
    });
    reducersBuilder.addCase(fetchMessagingConfig.pending, (state) => {
      state.isError = false;
      state.isConfigLoading = true;
    });
    reducersBuilder.addCase(fetchMessagingConfig.fulfilled, (state, { payload }) => {
      state.isConfigLoading = false;
      state.isError = false;
      state.messageConfig = {
        ...payload,
        preMessage: {
          ...initialPastMessageConfig,
          body: SmsInviteStrings.DefaultPreMessage,
        },
      };
    });

    reducersBuilder.addCase(fetchMessageVariables.rejected, (state) => {
      state.isError = true;
      state.isVariablesLoading = false;
    });
    reducersBuilder.addCase(fetchMessageVariables.pending, (state) => {
      state.isError = false;
      state.isVariablesLoading = true;
    });
    reducersBuilder.addCase(fetchMessageVariables.fulfilled, (state, { payload }) => {
      state.isVariablesLoading = false;
      state.isError = false;
      state.messageVariables = payload;
    });

    reducersBuilder.addCase(updateMessagingConfig.rejected, (state) => {
      state.isError = true;
      state.isConfigUpdating = false;
    });
    reducersBuilder.addCase(updateMessagingConfig.pending, (state) => {
      state.isError = false;
      state.isConfigUpdating = true;
    });
    reducersBuilder.addCase(updateMessagingConfig.fulfilled, (state, { payload }) => {
      state.isConfigUpdating = false;
      state.isError = false;
      state.messageConfig = payload;
    });

    reducersBuilder.addCase(sendTestMessage.rejected, (state) => {
      state.isTestMessageError = true;
      state.isTestMessageSending = false;
    });
    reducersBuilder.addCase(sendTestMessage.pending, (state) => {
      state.isTestMessageError = false;
      state.isTestMessageSending = true;
    });
    reducersBuilder.addCase(sendTestMessage.fulfilled, (state) => {
      state.isTestMessageError = false;
      state.isTestMessageSending = false;
    });
  },
});

export const { setConfig, reset } = smsInvitesSlice.actions;
export default smsInvitesSlice.reducer;
