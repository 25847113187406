import { FC, SetStateAction } from 'react';
import {
  SharesAnalyticsBlockModel,
  SharesAnalyticsBlockData,
  useStyles,
} from './SharesAnalytics.helper';
import {
  getAnalyticsData,
  webInitialAnalyticsTopGolfData,
} from '../AnalyticsSection/AnalyticsSection.helper';
import { useTypedSelector } from '../../../store';
import { PlatformType } from '../Dashboard.helper';
import { KPIApiNames } from '../../../api/models/analytics';
import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from '@mui/material';

interface SharesSubAnalyticsNavigationProps {
  setPlatform: (value: SetStateAction<PlatformType>) => void;
  platform: PlatformType;
}

export const SharesSubAnalyticsNavigation: FC<SharesSubAnalyticsNavigationProps> = ({
  setPlatform,
  platform,
}) => {
  const classes = useStyles();
  const { kpis } = useTypedSelector((state) => state.analytics);

  const TGAnalyticsData = getAnalyticsData(kpis, webInitialAnalyticsTopGolfData);
  const updatedAnalyticsData = getAnalyticsData(
    kpis,
    SharesAnalyticsBlockData,
  ) as SharesAnalyticsBlockModel[];

  updatedAnalyticsData[0].value =
    TGAnalyticsData.find((item) => item.kpiName === KPIApiNames.shares)?.value ||
    updatedAnalyticsData[0].value;

  const handleChange = (event: SelectChangeEvent) => {
    const type = event.target.value;
    setPlatform(type as PlatformType);
  };
  const renderShareBlock = () => {
    return (
      <FormControl
        style={{
          width: '70%',
        }}
      >
        <Select
          classes={{ select: classes.select, outlined: classes.outlined }}
          className={classes.root}
          value={platform}
          displayEmpty
          onChange={handleChange}
          MenuProps={{
            classes: { paper: classes.dropdownStyle },
            variant: 'menu',
          }}
        >
          {updatedAnalyticsData.map((item) => (
            <MenuItem key={item.kpiName} className={classes.itemContainer} value={item.platform}>
              <ListItemButton classes={{ root: classes.button }}>
                <ListItemIcon classes={{ root: classes.icon }}>{item.icon}</ListItemIcon>
                <ListItemText
                  sx={{ paddingLeft: '10px' }}
                  classes={{ primary: classes.text }}
                  className={classes.labelText}
                  primary={item.label}
                />
                <ListItemText
                  classes={{ primary: classes.text }}
                  className={classes.valueText}
                  primary={item.value}
                />
              </ListItemButton>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return <Box>{renderShareBlock()}</Box>;
};
