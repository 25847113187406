import { useHistory } from 'react-router-dom';
import { QsLaunchPadStepStatus, VenueProperties } from '../../../api/models/venue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { launchModalAccordionItems } from './QuickStartLaunchPadModal';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getApiUrlForId } from '../../../api/endpoints';
import { useEffect } from 'react';
import { setTabIndex as launchPadSetTabIndex } from '../../../store/slices/launchPadSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { LaunchpadEventNames } from '../../../common/constants/events/launchpadEvents';
import { patchVenue } from '../../../store/slices/venueSlice';

export const MAX_STEP_INDEX = launchModalAccordionItems.length - 1;
export const useQuickStartLaunchPad = () => {
  // uncomment to reset values
  // // @ts-expect-error
  // update(undefined);
  const { id } = useTypedSelector((state) => state.venue.venue);
  const { items: campaigns } = useTypedSelector((state) => state.campaigns);

  const { value: launchpadValues, update, isLoading, isUpdating } = useVenueProperty<
    VenueProperties['dashboard.quickstart-launchpad']
  >({
    property: 'dashboard.quickstart-launchpad',
  });

  const dispatch = useAppDispatch();

  const history = useHistory();
  const { trackEvent } = useTrackEvent();
  const isStarted = !!launchpadValues?.started;

  const currentTargetIndex =
    launchpadValues?.activeIndex! < 0 ? 0 : launchpadValues?.activeIndex || 0;
  const currentTopicStep = launchpadValues?.currentTopicStep || 0;

  const { tabIndex, forceHideQsMarker } = useTypedSelector((state) => state.launchPad);

  const setTabIndex = (newTabIndex: number) => {
    dispatch(launchPadSetTabIndex(newTabIndex));
  };

  useEffect(() => {
    setTabIndex(currentTargetIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTargetIndex]);

  const isQSMarkerVisible =
    isStarted &&
    launchpadValues?.isQSMarkerVisible &&
    !launchpadValues?.isLaunchpadVisible &&
    !forceHideQsMarker;

  const isFinished = isStarted && currentTargetIndex > MAX_STEP_INDEX;

  const startLaunchPadFlow = () => {
    trackEvent(LaunchpadEventNames.StartLaunchpad);
    return update({
      started: true,
      activeIndex: 0,
      isLaunchpadVisible: true,
      isQSMarkerVisible: true,
      stepsTracking: [],
    });
  };

  const finishLaunchPadFlow = async () => {
    trackEvent(LaunchpadEventNames.FinishLaunchpad);
    update({
      ...launchpadValues,
      started: false,
      finished: true,
      isLaunchpadVisible: false,
    });
    dispatch(patchVenue({ id, update: { tutorialCompleted: true } }));
  };

  const navigateToItemRoute = (itemIndex: number) => {
    const redirectUrl = launchModalAccordionItems[itemIndex].redirectTo;
    if (redirectUrl === WebsiteRoutes.RewardCampaigns) {
      const redirectCampaign = campaigns.find((campaign) => campaign.isPreferred) || campaigns[0];
      const route = getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, redirectCampaign.id);
      history.push(route);

      trackEvent(LaunchpadEventNames.NavigateToRoute, {
        value: itemIndex,
        route,
      });
    } else {
      trackEvent(LaunchpadEventNames.NavigateToRoute, {
        value: itemIndex,
        route: redirectUrl,
      });
      history.push(redirectUrl);
    }
  };

  const getStepsTracking = (isSkipped?: boolean) => {
    return [
      ...(launchpadValues?.stepsTracking || []),
      {
        stepName: launchModalAccordionItems[tabIndex]?.action,
        status: isSkipped ? QsLaunchPadStepStatus.Skipped : QsLaunchPadStepStatus.Completed,
      },
    ];
  };

  const navigateToActiveItem = async () => {
    await update({
      ...launchpadValues,
      isLaunchpadVisible: false,
      stepsTracking: getStepsTracking(),
    });
    trackEvent(LaunchpadEventNames.NavigateToActiveItem, {
      value: tabIndex,
    });
    return navigateToItemRoute(tabIndex);
  };

  const moveToNextItem = async ({
    redirect,
    skipTracking,
    isCompleted,
  }: { redirect?: boolean; skipTracking?: boolean; isCompleted?: boolean } = {}) => {
    trackEvent(LaunchpadEventNames.NavigateToActiveItem, {
      value: tabIndex + 1,
    });

    if (isFinished) {
      await finishLaunchPadFlow();
      return;
    }

    const newActiveIndex = tabIndex + 1;
    setTabIndex(newActiveIndex);

    const stepsTracking = skipTracking
      ? launchpadValues?.stepsTracking
      : getStepsTracking(!isCompleted);

    await update({
      started: true,
      activeIndex: newActiveIndex,
      isLaunchpadVisible: true,
      isQSMarkerVisible: true,
      stepsTracking,
      currentTopicStep: 0,
    });

    if (redirect) {
      navigateToItemRoute(newActiveIndex);
    }
  };

  const hideQSMarker = async () => {
    trackEvent(LaunchpadEventNames.HideQsMarker);

    await update({
      ...launchpadValues,
      isQSMarkerVisible: false,
    });
  };

  const moveToNextTopicStep = async () => {
    trackEvent(LaunchpadEventNames.MoveToNextTopicStep, {
      value: currentTopicStep + 1,
    });

    await update({
      ...launchpadValues,
      currentTopicStep: currentTopicStep + 1,
    });
  };

  const showLaunchpad = async () => {
    trackEvent(LaunchpadEventNames.ShowLaunchpad);

    await update({
      ...launchpadValues,
      isLaunchpadVisible: true,
    });
  };

  const resetTopicStep = async () => {
    trackEvent(LaunchpadEventNames.ResetTopicStep);

    await update({
      ...launchpadValues,
      currentTopicStep: 0,
    });
  };

  return {
    isLoading,
    isUpdating,
    isStarted,
    isFinished,
    isLaunchpadVisible: launchpadValues?.isLaunchpadVisible,
    tabIndex,
    setTabIndex,
    currentTargetIndex,
    isQSMarkerVisible,
    moveToNextItem,
    navigateToActiveItem,
    startLaunchPadFlow,
    finishLaunchPadFlow,
    hideQSMarker,
    currentTopicStep,
    moveToNextTopicStep,
    resetTopicStep,
    showLaunchpad,
  };
};
