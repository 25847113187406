import { FC, useEffect, useState } from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

import { useTypedSelector } from '../../../store';
import { Alert as IAlert } from '../../../store/slices/alertSlice';
import { AlertColor } from '@mui/material/Alert/Alert';

export const Toast: FC = () => {
  const { alerts } = useTypedSelector((state) => state.AlertSlice);
  const [alert, setAlert] = useState<IAlert>({ title: null, message: null, status: null });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (alerts.length) {
      setAlert(alerts[alerts.length - 1]);
      setOpen(true);
    }
  }, [alerts]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      onClose={handleClose}
    >
      <Alert severity={(alert?.status as AlertColor) || 'success'} variant="filled" onClose={handleClose}>
        {alert.title && <AlertTitle color="inherit">{alert.title}</AlertTitle>}
        {alert.message}
      </Alert>
    </Snackbar>
  );
};
