export const BonusPoints = () => {
    return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.7484 3.625C19.7484 2.52043 18.853 1.625 17.7484 1.625H3.5C2.39543 1.625 1.5 2.52043 1.5 3.625V15.477C1.5 16.5816 2.39543 17.477 3.5 17.477H6.65347C6.91868 17.477 7.17304 17.5824 7.36057 17.7699L9.91703 20.3263C10.3075 20.7169 10.9407 20.7169 11.3312 20.3264L13.8877 17.7699C14.0752 17.5824 14.3296 17.477 14.5948 17.477H17.7484C18.853 17.477 19.7484 16.5816 19.7484 15.477V3.625Z"
        stroke="white"
        strokeWidth="1.3"
      />
      <path
        d="M10.1755 5.25334C10.3589 4.88172 10.8888 4.88172 11.0722 5.25334L12.0772 7.28973C12.1501 7.4373 12.2909 7.53959 12.4537 7.56325L14.701 7.8898C15.1111 7.94939 15.2749 8.45338 14.9781 8.74265L13.352 10.3278C13.2341 10.4426 13.1803 10.6081 13.2082 10.7703L13.592 13.0085C13.6621 13.417 13.2334 13.7285 12.8666 13.5356L10.8565 12.4789C10.7109 12.4023 10.5368 12.4023 10.3912 12.4789L8.38115 13.5356C8.01433 13.7285 7.58562 13.417 7.65567 13.0085L8.03956 10.7703C8.06737 10.6081 8.0136 10.4426 7.89576 10.3278L6.2696 8.74265C5.97284 8.45338 6.1366 7.94939 6.54671 7.8898L8.794 7.56325C8.95685 7.53959 9.09764 7.4373 9.17047 7.28973L10.1755 5.25334Z"
        stroke="white"
        strokeWidth="1.3"
      />
    </svg>
  );
};
