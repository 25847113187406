import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useModalsStyles = makeStyles((theme: Theme) => ({
  AwardsModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    border: 'none !important',
    padding: 24,
    outline: 'none',
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalBtn: {
    padding: '10px 25px',
    fontSize: '14px',
    borderWidth: 1,
    borderRadius: '30px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  CancelBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    marginRight: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  paper: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: 15,
    overflow: 'visible',
    textAlign: 'center',
  },
  root: {
    padding: 0,
    borderRadius: 12,
  },
  backDrop: {
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  modalBody: {},
  wrapperMainContent: {
    width: '100%',
    padding: '15px 20px',
  },
  modalTitle: {
    textAlign: 'left',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    color: theme.palette.common.deepDark,
  },
  formControl:{
    height: '250px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '10px',
      marginBottom:'4px' ,
      marginLeft: '4px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.main,
    },
  },
  formGroup: {
    border: '1px solid #E1E5EE',
    borderRadius: '12px',
    width: '100%',
    margin: '5px 0',
    clear: 'both',
  },
  labelRoot: {
    margin: 0,
    justifyContent: 'space-between',
  },
  modalLabel: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    padding: '17px 25px',
    color: theme.palette.common.gray,
  },
}));
