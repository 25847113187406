import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  isSelectedUsersTable?: boolean;
}

export const useExistingCampaignUsersTableStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    borderRadius: '0 10px 10px 10px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    width: '100%',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 20px 11px 16px',
  },
  checkbox: {
    position: 'absolute',
    top: '30px',
    left: '7px',
    zIndex: 10,
  },
  rewardButton: {
    padding: '8px 31px',
    fontSize: '15x',
    marginLeft: '16px',
    marginTop: '14px',
    marginBottom: '4px',
  },
  tableWrapper: {
    marginTop: '20px',
    marginRight: '20px',
    width: '100%',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '60px',
  },
  table: {
    position: 'relative',
    '& #common-table-wrapper': {
      maxHeight: '630px',
      height: '630px',
    },

    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '5px',
        fontSize: '12px',
      },

      '&:nth-child(1) div': {
        display: 'flex',
        alignItems: 'center',
        minWidth: '100px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '15px',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',
      '& span': { fontSize: '13px !important' },
      '& p': { fontSize: '13px ' },
      '& > div': {
        padding: '5px',
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '10px !important',
        minWidth: '100px',
        '& div ~ p': { fontSize: '11px !important' },
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
        textAlign: 'center',
        paddingRight: '4px',
      },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '4px',
      },
      '&:nth-child(3) div': {
        margin: '0 auto',
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '4px',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
        paddingRight: '4px',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(9)': {
        paddingLeft: '0',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '52px',

        '& div': {
          '& p': {
            color: theme.palette.primary.main,
            fontSize: '13px !important',
            fontWeight: '600',
          },
        },

        '& span': {
          paddingLeft: '0',
          display: 'block',
          margin: '0 auto',
        },

        '& button': {
          borderColor: theme.palette.primary.main,
        },
      },
    },
  },
}));
