import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MIN_TABLE_HEIGHT } from '../../../common/constants/constants';

const TABLE_HEIGHT = 'calc(100vh - 250px)';

export const useFilterTablesStyles = makeStyles((theme: Theme) => ({
  VideoFilterTableStyles: {
    marginBottom: '60px !important',
    '& #common-table-wrapper': {
      height: TABLE_HEIGHT,
      maxHeight: TABLE_HEIGHT,
      minHeight: MIN_TABLE_HEIGHT,
    },
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {
        // backgroundColor: 'red',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7) div': {
        margin: '0 auto',
      },
      '&:nth-child(8)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(9)': {
        '& div': {
          width: 'fit-content',
          margin: '0 auto',
        },
      },
    },
  },

  TopGolfVideoFilterTableStyles: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        transform: 'translateX(-30%)',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        //
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6)': {
        margin: '0 auto',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7)': {
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        margin: '0 auto',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {
        // backgroundColor: 'red',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6) div': {
        margin: '0 auto',
      },
      '&:nth-child(7)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
        paddingLeft: 0,
      },
      '&:nth-child(7) div': {
        // margin: '0 auto',
      },
      '&:nth-child(8)': {
        padding: '0 10px 0 10px',
        textAlign: 'center',
      },
      '&:nth-child(8)>div': {
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(8)>div>div': {
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(8) span': {
        fontSize: '12px !important',
      },
    },
  },

  UsersFilterTableStyles: {
    '& th': {
      fontSize: '12px',
      backgroundColor: theme.palette.common.white,
      paddingBottom: '10px !important',
      '&:nth-child(1) div': {},
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {},
      '&:nth-child(2)': {
        '& svg': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&:nth-child(8) span': {
        paddingLeft: '0',
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(9)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(9) p': {
        display: 'block',
        margin: '0 auto',
        fontSize: '12px',
        color: theme.palette.common.deepDark,
      },
      '&:nth-child(10)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(11)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
    },
  },

  LeaderboardFilterResultsTable: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {},
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(2)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(3)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(4)': {
        paddingLeft: 0,
      },
      '&:nth-child(4)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
    },
  },
  toolbar: {
    zIndex: '10',
    marginTop: '20px',
    background: theme.palette.common.white,
    width: 'auto',
  },
}));
