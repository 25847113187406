import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '963px',
    minHeight: '100%',
    paddingTop: '173px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleWrapper: {
    padding: '14px 28px',
    borderRadius: '8px',
    width: '100%',
  },
  title: {
    fontSize: '32px',
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
  divider: {
    height: '1px',
    backgroundColor: '#E1E5EE',
    margin: '40px 0 20px 0',
    width: '100%',
  },
  infoTitle: {
    fontSize: '20px',
    fontWeight: 700,
    color: theme.palette.common.gray600,
    textAlign: 'center',
  },
  info: {
    fontsize: '20px',
    color: theme.palette.common.gray600,
    margin: '14px 0 24px 0',
    textAlign: 'center',
    maxWidth: '628px',
  },
  addCustomerButton: {
    backgroundColor: theme.palette.primary.main,
    padding: '12px 20px',
    borderRadius: '8px',
    color: theme.palette.common.white,
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
}));
