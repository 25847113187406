import { Box, CardMedia, IconButton } from '@mui/material';
import { useEffect, useRef } from 'react';

import { useVideoControls } from '../../../../../common/components/VideoPlayer/PlayerControls.helper';
import { usePreviewVideoStyles } from './PreviewVideo.styles';
import { PlayIconLaunchPad } from '../../../../../common/assets/PlayIconLaunchPad';
import { PauseIconLaunchPad } from '../../../../../common/assets/PauseIconLaunchPad';

interface PreviewVideoProps {
  src: string;
  poster: string;
  autoplay?: boolean;
  setVideoPlaying?: (isPlaying: boolean) => void;
  onVideoEnded?: () => void;
}

export const PreviewVideo: React.FC<PreviewVideoProps> = ({
  src,
  poster,
  autoplay = false,
  setVideoPlaying,
  onVideoEnded,
}) => {
  const styles = usePreviewVideoStyles();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const {
    togglePlayer,
    handleOnTimeUpdate,

    playerState,
    setPlayerState,
  } = useVideoControls(videoRef, false);

  useEffect(() => {
    setVideoPlaying?.(playerState.isPlaying);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.isPlaying]);

  useEffect(() => {
    if (autoplay) {
      togglePlayer();
      setVideoPlaying?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, src]);

  return (
    <Box className={styles.container}>
      <CardMedia
        component="video"
        ref={videoRef}
        onTimeUpdate={handleOnTimeUpdate}
        src={src}
        poster={poster}
        onEnded={() => {
          setPlayerState({ ...playerState, isPlaying: false });
          onVideoEnded?.();
        }}
        onClick={togglePlayer}
        onMouseUp={(e: any) => {
          e.stopPropagation();
        }}
        className={styles.cardMedia}
        preload="auto"
      />
      <IconButton
        className={styles.iconButton}
        sx={{
          opacity: playerState.isPlaying && playerState.timeProgress ? 0 : 1,
        }}
        onClick={togglePlayer}
        data-qs-button="true"
      >
        {playerState.isPlaying ? <PauseIconLaunchPad /> : <PlayIconLaunchPad />}
      </IconButton>
    </Box>
  );
};
