import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './TableFilterSelect.styles';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  filter?: { status?: string; hasVideos?: boolean };
  className?: string;
}

interface Props {
  tabsList: TabItem[];
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  handleTrackEvent: IEventTracker;
  type: 'active' | 'invited';
  isIncentiveCampaign?: boolean;
  flex?: boolean;
}

const TableFilterSelect: FC<Props> = ({
  tabsList,
  activeTabIndex,
  setActiveTabIndex,
  handleTrackEvent,
  type,
  isIncentiveCampaign,
  flex,
}) => {
  const styles = useStyles({ isIncentiveCampaign, flex });

  const replaceEventNameByTableType = RewardCampaignsEvents.RewardCampaginActiveCustomersTableViewAllTabClick.replace(
    'tab',
    `${type}_tab`,
  );
  const theme = useTheme();

  return (
    <Box className={styles.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = activeTabIndex === index;
        return (
          <Box
            className={`${styles.tab} ${tabItem.className}`}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              borderRadius: isActiveTab ? '6px' : 'none',
              boxShadow: tabItem.className
                ? 'none'
                : isActiveTab
                ? '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
                : 'none',
              ...(tabItem.className && isActiveTab ? { border: '1px solid #D9D9D9' } : {}),
            }}
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              handleTrackEvent(
                replaceEventNameByTableType
                  .replace('subtab', `${tabItem.tabName}_tab`)
                  .toLowerCase(),
              );
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter active-tab`}
          >
            <Typography
              className={styles.tabName}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography
              className={styles.amount}
              style={{
                background: isActiveTab && tabItem.className ? '#F2F4F7' : 'none',
                color: isActiveTab && tabItem.className ? theme.palette.primary.main : '#464c71',
              }}
            >
              {tabItem.usersCount}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default TableFilterSelect;
