import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme, { center: boolean }>((theme: Theme) => ({
  title: {
    fontWeight: 500,
    color: theme.palette.common.gray900,
  },
  subTitle: {
    fontWeight: 500,
    fontSize: '14px',
    color: theme.palette.common.gray900,
  },
  buttonsWrapper: (props) => ({
    display: 'flex',
    gap: '10px',
    marginLeft: props.center ? '0px' : 'auto',
  }),
}));
