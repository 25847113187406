import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  copied?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => ({
  PixeCopyTitle: {
    marginRight: '12px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  PixeCopyDescription: {
    marginBottom: '14px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  root: {
    display: 'flex',
  },
  wrapper__left: {
    padding: '25px 30px',
    background: theme.palette.common.white,
    height: 'calc(100vh - 128px)',
  },
  pageTitle: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '28px',
    color: '#101828',
  },
  pageDescription: {
    width: '375px',
    fontWeight: '400',
    fontSize: '11px',
    lineHeight: '28px',
    color: theme.palette.common.gray,
    letterSpacing: '0.02em',
  },
  divider: {
    marginTop: '25px',
    background: '#DCDBE4',
  },
  hero: {
    marginTop: 0,
  },
  hero__number: {
    marginRight: '12px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: '50%',
    border: `1px solid #C0C3C8`,
    textAlign: 'center',
  },
  hero__title: {
    marginRight: '12px',
    fontSize: '18px',
    lineHeight: '28px',
    fontWeight: '500',
  },
  hero__description: {
    marginTop: '4px',
    marginLeft: '40px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '30px',
    color: theme.palette.common.gray,
  },
  hero__steps: {
    marginTop: '14px',
    padding: '10px',
    marginLeft: '20px',
    background: '#F5F6F8',
    borderRadius: '12px',
  },
  hero__code: {
    padding: '9px 16px',
    borderRadius: '10px',
    border: '1px solid #E1E5EE',
    background: theme.palette.common.white,
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
  },
  tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    marginLeft: '0px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  inactive: {
    color: '#464c7199',
  },
  copyButton: {
    '&:hover': {
      background: (props) =>
        props.copied ? theme.palette.common.green : theme.palette.primary.dark,
    },
  },
  wrapper__right: {
    padding: '30px',
    paddingBottom: '120px',
    background: '#f5f6f8',
    maxHeight: 'calc(100vh - 128px)',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      paddingBottom: '50px',
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      marginBottom: '-64px',
    },
  },
  accordion: {
    borderRadius: '15px',
    '& > div': {
      boxShadow: 'none',
    },
    '& .MuiAccordion-rounded': {
      borderRadius: '15px',
    },
    '& .MuiAccordionSummary-content': {
      marginBottom: '10px',
    },
  },
  accordion__title: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '30px',
  },
  img: {
    maxHeight: '274px',
  },
  left: {
    padding: '80px 50px',
    background: theme.palette.common.white,
    height: '720px',
  },
  right: {
    background: '#F5F6F8',
    position: 'relative',
    height: '720px',
    padding: '0px',
    '& img': {
      objectFit: 'contain',
    },
  },
  beacon_mobile: {
    position: 'absolute',
    top: '108px',
    left: '37px',
    height: '291px',
    width: '144px',
  },
  beacon_sv: {
    position: 'absolute',
    top: '91px',
    right: '35px',
    width: '320px',
    height: '186px',
  },
  beacon_dashboard: {
    position: 'absolute',

    bottom: '106px',
    right: '100px',
    width: '320px',
    height: '186px',
  },
  beacon_down: {
    position: 'absolute',
    top: '250px',
    right: '88px',
    height: '160px',
    width: '163px',
  },
  beacon_up: {
    position: 'absolute',
    top: '280px',
    left: '155px',
    height: '64px',
    width: '195px',
  },
  modal__title: {
    marginTop: '24px',
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '52px',
  },
  modal__desc: {
    marginTop: '24px',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '30px',
    color: '#8B89A0',
  },
  modal__btn: {
    borderRadius: '100px',
  },
  modal__btnWrapper: {
    position: 'absolute',
    bottom: '80px',
  },
  shopifyCode: {
    position: 'absolute',
    top: '95px',
    left: '30px',
    fontFamily: 'IBM Plex Mono',
    fontWeight: '400',
    fontSize: '8px',
    lineHeight: '24px',
    color: '#26292A',
  },
  shopify: {
    position: 'relative',
    marginTop: '25px',
    width: '100%',
    maxWidth: '532px',
  },
  img__webflow: {
    objectPosition: 'top',
    width: '80%',
    margin: '0 auto',
    maxWidth: '532px',
    maxHeight: '238px',
  },
}));

export const instructions = [
  {
    title: 'HTML 5',
    steps: [
      { text: 'Open HTML page in HTML Editor', keywords: ['HTML', 'Editor'] },
      { text: 'Paste the pixel code just above the  </body> tag', keywords: ['</body>'] },
      { text: 'Save your changes', keywords: ['Save'] },
    ],
    img: false,
  },
  {
    title: 'Webflow',
    steps: [
      {
        text: 'Go to Settings →Insert Headers and Footers ',
        keywords: ['Settings', '→', 'Insert', 'Headers', 'and', 'Footers'],
      },
      {
        text: 'Paste the pixel code into the Scripts in Header box',
        keywords: ['Scripts', 'in', 'Header'],
      },
      { text: 'Save your changes', keywords: ['Save'] },
    ],
    img: 'webflow-beacon.jpg',
  },
  {
    title: 'Wordpress',
    steps: [
      {
        text: 'Go to Settings →Insert Headers and Footers ',
        keywords: ['Settings', '→', 'Insert', 'Headers', 'and', 'Footers'],
      },
      {
        text: 'Paste the pixel code into the Scripts in Header box',
        keywords: ['Scripts', 'in', 'Header'],
      },
      { text: 'Save your changes', keywords: ['Save'] },
    ],
    img: false,
  },
  {
    title: 'Shopify',
    steps: [
      { text: 'Login into Shopify and go to Settings ', keywords: ['Settings '] },
      { text: 'Click on Checkout and scroll to Order status page', keywords: ['Checkout'] },
      { text: 'In the Additional scripts field paste your pixel code', keywords: [] },
      { text: 'Save your changes', keywords: ['Save'] },
    ],
    img: 'shopify-beacon.jpg',
  },
];

export const CODE =
  '<img src="https://socialvenu.scaletrk.com/track/img?goal_id=12" height="1" width="1" />';

export const CODE_INSTRUCTION = `
Here are the instructions on how to integrate the tracking pixel code in the html page 
of your choice (we recommend the confirmation page at the end of a transaction loop):

1. Go to Settings > Insert Headers and Footers

2. Paste the pixel code into the Scripts in Header box
<img src="https://socialvenu.scaletrk.com/track/img?goal_id=12" height="1" width="1" />

3. Save your changes
`;
