import { ChangeEvent as ReactChangeEvent, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './TopgolfVideoDownloadDialog.helper';
import { CommonStrings } from '../../../../localization/en';
import { VideoApiModel } from '../../../../../api/models/videos';
import { downloadMedia } from '../../../../../services/utilities';
import { VideoDownloadCard } from '../components/VideoDownloadCard/VideoDownloadCard';
import { TopGolfVideosApiModel } from '../../../../../api/models/topgolfVideos';
import { useTypedSelector } from '../../../../../store';
import { ClientTypes } from '../../../../../api/models/common';
import { TopgolfVideoDownloadCard } from '../components/VideoDownloadCard/TopgolfVideoDownloadCard';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../../../constants/events/topGolfEvents';

interface VideoDownloadDialogProps {
  video: VideoApiModel | TopGolfVideosApiModel;
  open: boolean;
  onClose: () => void;
}

enum VideoTypes {
  WithFilter = 'withFilter',
  WithoutFilter = 'withoutFilter',
}

export const TopgolfVideoDownloadDialog: FC<VideoDownloadDialogProps> = ({
  onClose,
  open,
  video,
}) => {
  const classes = useStyles();
  const [type, setType] = useState<VideoTypes>(VideoTypes.WithFilter);
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const [noFilterVideoUrl, setNoFilterVideoUrl] = useState<string>('');
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const assets =
    process.env.REACT_APP_BUILD_ENV === 'production' ? ',l_sv-prod-assets' : ',l_sv-dev-assets';

  useEffect(() => {
    const parts = video.url.split(assets);
    const startPart = parts[0];
    const endPart = parts[1]?.substring(parts[1].indexOf('/v1/')) || '';
    setNoFilterVideoUrl(startPart + endPart);
  }, [video.url, assets]);
  const { trackEvent } = useTrackEvent();

  // useEffect(() => {
  //   if (story.url) {
  //     const [first, second] = story.url.split('/v1/');
  //     setVideoUrl(`${first},eo_0.01/v1/${second}`);
  //   }
  // }, [story.url, setVideoUrl]);
  const handleClose = () => {
    onClose();
  };

  const handleVideoTypeChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    setType(event.target.value as VideoTypes);
  };

  const downloadFile = (e: any) => {
    e.stopPropagation();
    if (video.url && noFilterVideoUrl) {
      downloadMedia(type === VideoTypes.WithFilter ? video.url : noFilterVideoUrl);
    }
    trackEvent(topGolfEvents.top_golf_video_slide_out_open_download_video, {
      videoId: video?.id,
    });
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleClose}
      open={open}
      fullWidth={true}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle className={classes.title} id="scroll-dialog-title">
        <Box className={classes.titleText}>Want to download video?</Box>
        <IconButton
          className={classes.close}
          type="button"
          aria-label="Close modal"
          onClick={handleClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <RadioGroup
          className={classes.group}
          onChange={handleVideoTypeChange}
          role="group"
          aria-labelledby="video-types-group"
        >
          <Box className={classes.label}>
            <TopgolfVideoDownloadCard url={video.url} videoId={video.id} />
          </Box>
          {!isTopgolf && (
            <Box className={classes.label}>
              <VideoDownloadCard url={noFilterVideoUrl} />

              <FormControlLabel
                name="video-type"
                control={<Radio />}
                value={VideoTypes.WithoutFilter}
                checked={type === VideoTypes.WithoutFilter}
                label="Without Branding"
              />
            </Box>
          )}
        </RadioGroup>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={handleClose} color="primary">
          {CommonStrings.Cancel}
        </Button>
        <Button onClick={downloadFile} color="primary" variant="contained">
          {CommonStrings.Download}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
