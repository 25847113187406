import { Box } from '@mui/material';
import { FC } from 'react';
import {
  IPhoneSMSPreview,
  IPhoneSMSPreviewProps,
} from '../../../common/components/IPhonePreview/IPhoneSMSPreview';

interface MessagePreviewProps extends IPhoneSMSPreviewProps {}

export const MessagePreview: FC<MessagePreviewProps> = ({ disabled, ...rest }) => {
  return (
    <Box
      height="605px"
      width="300px"
      position="relative"
      style={{ pointerEvents: 'none', userSelect: 'none' }}
    >
      <div
        style={{
          opacity: disabled ? 0.6 : 1,
          filter: disabled ? `blur(2px)` : '',
          transition: 'ease all 0.3s',
        }}
      >
        <IPhoneSMSPreview disabled={disabled} {...rest} />
      </div>
    </Box>
  );
};
