import { FC } from 'react';

type iconProps = {
  color?: string;
  width?: string;
  height?: string;
};

export const EyeIcon: FC<iconProps> = ({ color, width, height }) => {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.689 4a6.833 6.833 0 00-6.356 4.333 6.833 6.833 0 006.356 4.334 6.833 6.833 0 006.355-4.334A6.833 6.833 0 007.69 4zm0 7.222a2.89 2.89 0 010-5.778 2.89 2.89 0 010 5.778zm0-4.622c-.96 0-1.733.774-1.733 1.733 0 .96.774 1.734 1.733 1.734.959 0 1.733-.775 1.733-1.734S8.648 6.6 7.69 6.6z"
        fill={color || '#fff'}
      />
    </svg>
  );
};
