import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(() => ({
  root: {
    padding: '15px 0 20px',
  },
  invitationsInfo: {
    padding: '15px 19px',
    background: '#E7FFE6',
    border: '1px solid #EAECF0',
    borderRadius: '9px',
    height: '158px',
    width: '100%',
  },
  invitationsInfoError: {
    padding: '15px 19px',
    background: '#FFF8DD',
    border: '1px solid #EAECF0',
    borderRadius: '9px',
    height: '158px',
    width: '100%',
  },
  uploadStepListItem: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    color: '#344054',
    marginLeft: '20px',
  },
  invitationsInfoNumber: {
    fontSize: '72px',
    fontWeight: '600',
    lineHeight: '90px',
    color: '#101828',
  },
  invitationsInfoText: {
    fontSize: '17px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#8E8E8E',
  },
  invitationsInfoTextError: {
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '24px',
    color: '#8E8E8E',
  },
}));
