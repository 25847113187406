import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  scrollContext: {
    padding: '5px',
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  subheader: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '143%',
  },
  firstUserTitleBlock: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  titleBlock: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '157%',
    color: theme.palette.primary.dark,
  },
}));
