import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './UserDetails.styles';
import { UserApiModel } from '../../../api/models/users';
import { EditableName } from '../EditableText/EditableName';
import { updateUserById } from '../../../store/slices/userDetailsSlice';
import { formatIntPhoneNumber, getFullName } from '../../../services/utilities';
import { setRefetch } from '../../../store/slices/heroesSlice';
import { useAppDispatch } from '../../../store';
import { VerifiedIcon } from '../../assets/VerifiedIcon';
import { UnverifiedIcon } from '../../assets/UnverifiedIcon';
import { OptedOutIcon } from '../../assets/OptedOutIcon';
import { EmailIcon } from '../../assets/EmailIcon';
import { DashboardStrings, HelpersStrings } from '../../localization/en';
import IconButton from '@mui/material/IconButton';
import { useToasts } from 'react-toast-notifications';

interface Props {
  userDetails: UserApiModel;
}

export const UserDetails: FC<Props> = ({ userDetails }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const {
    id: userId,
    firstName,
    lastName,
    smsOptedIn,
    phoneNumberVerified,
    phoneNumber,
    email,
  } = userDetails;

  const phoneIcon = smsOptedIn ? (
    phoneNumberVerified ? (
      <VerifiedIcon />
    ) : (
      <UnverifiedIcon />
    )
  ) : (
    <OptedOutIcon />
  );

  const handleEmailIconClick = () => {
    if (email) {
      navigator.clipboard.writeText(email).then(() => {
        addToast(DashboardStrings.EmailCopied, {
          appearance: 'success',
        });
      });
    }
  };

  return (
    <Box className={classes.container}>
      <EditableName
        firstName={firstName}
        lastName={lastName}
        onChange={async ({ firstName, lastName }) => {
          await dispatch(
            updateUserById({
              update: {
                firstName,
                lastName,
                displayName: getFullName({ firstName, lastName }),
              },
              id: userId,
            }),
          );
          dispatch(setRefetch(true));
        }}
      />

      <Box className={classes.detailsWrapper}>
        {phoneNumber ? (
          <>
            {phoneIcon}
            <Typography className={classes.phoneNumberText} ml="5px">
              {formatIntPhoneNumber(phoneNumber)}
            </Typography>
          </>
        ) : (
          <Typography className={classes.phoneNumberText}>{HelpersStrings.NotAvailable}</Typography>
        )}

        {email && email !== '---' && (
          <>
            <Typography className={classes.phoneNumberText} margin="0 10px">
              |
            </Typography>
            <IconButton onClick={handleEmailIconClick}>
              <EmailIcon />
            </IconButton>
          </>
        )}
      </Box>
    </Box>
  );
};
