import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: '12px 10px',
    minWidth: '200px',
    border: '1px solid #005F9E',
    borderRadius: '8px',
    backgroundColor: '#E6EFF6',
    marginBottom: '33px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
  },
  title: {
    fontFamily: 'Inter',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#444E50',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: '10px 16px',
    fontSize: '14px',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
