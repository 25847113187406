import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  text: {
    color: '#344054',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '24px',
  },
  icon: {
    '& svg': {
      height: '34px',
      width: '34px',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '160px',
    height: '66px',
  },
  line: {
    width: '117px',
    background: '#C0C3C8',
    height: '2px',
    position: 'absolute',
  },
  lineLeft: {
    marginLeft: '100px',
    marginBottom: '32px',
  },
  lineRight: {
    marginLeft: '264px',
    marginBottom: '32px',
  },
}));
