import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    margin: '0 auto',
    border: '1px solid #EAECF0',
    overflowY: 'unset',
    background: theme.palette.common.white,
  },
  previewContainer: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },
  previewComponent: {
    textAlign: 'center',
  },
}));
