import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { urlMaskRegex } from '../../../../../services/utilities';

export const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    whiteSpace: 'nowrap',
  },
}));

export interface InitialValues {
  email: string | undefined;
  name: string;
  returnUrl: string;
  phoneNumber: string;
  displayName: string;
}

export const initialValues: InitialValues = {
  email: '',
  name: '',
  returnUrl: '',
  phoneNumber: '',
  displayName: '',
};

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().max(100, 'max length 100 symbols').required('Name is required'),
    email: Yup.string().email('Email is not valid').required('Required'),
    returnUrl: Yup.string().matches(urlMaskRegex, 'Return url is not valid'),
    phoneNumber: Yup.string(),
  });

export const getError = (name: string, touched: any, errors: any) => {
  const isTouched = Boolean(touched[name]);

  if (isTouched) {
    return errors[name] as string;
  }

  return undefined;
};
