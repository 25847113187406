import * as React from 'react';

export const DriveViewsIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" fill="none" viewBox="0 0 25 16">
      <path
        fill="#515182"
        d="M.122 8.412c7.322 9.698 16.182 9.977 24.298 1.543.732-.76.777-2.071.1-2.897C15.883-3.49 7.574-1.31.14 7.634a.644.644 0 00-.018.778z"
      ></path>
      <path
        fill="#fff"
        d="M.768 8.238c7.074 9.681 15.904 9.247 23.301 1.12.411-.452.388-1.218-.048-1.637C14.903-1.025 8.939.201.803 7.816a.33.33 0 00-.035.422z"
      ></path>
      <path
        fill="#DEE5FD"
        d="M12.553 14.985c3.297 0 5.97-2.96 5.97-6.613 0-3.652-2.673-6.612-5.97-6.612-3.298 0-5.971 2.96-5.971 6.612s2.673 6.613 5.97 6.613z"
      ></path>
      <path
        fill="#515182"
        d="M12.729 12.103c1.867 0 3.38-1.676 3.38-3.744 0-2.068-1.513-3.744-3.38-3.744-1.868 0-3.381 1.676-3.381 3.744 0 2.068 1.513 3.744 3.38 3.744z"
      ></path>
      <path
        fill="#fff"
        d="M11.408 6.238c.104-.743-.332-1.442-.973-1.563-.642-.12-1.246.384-1.35 1.126-.104.743.331 1.442.973 1.562.641.12 1.245-.383 1.35-1.125z"
      ></path>
    </svg>
  );
};
