export const UsersIcon = () => {
  return (
    <svg
      width="24"
      stroke="inherit"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.5" strokeWidth="1.5" />
      <circle cx="12" cy="10.4016" r="3.0444" strokeWidth="1.5" />
      <path
        d="M18.0076 19.4977C16.9629 17.2244 14.6658 15.646 12.0001 15.646C9.33443 15.646 7.03739 17.2244 5.99268 19.4977"
        strokeWidth="1.5"
      />
    </svg>
  );
};
