import { FC, useEffect, useRef } from 'react';
import { Box, CardMedia, IconButton } from '@mui/material';

import { CloseIcomModal } from '../../assets/newDesign/CloseIconModal';
import { PlayIcon } from '../../assets/newDesign/VideoControlsIcons/PlayIcon';
import { useVideoCardStyles } from './VideoCardComponent.style';
import '../TransitionWrapper/TransitionWrapper.css';
import { ChangeThumnailIcon } from '../VideoComponent/ChangeThumnailIcon';
import { useVideoTableIdContext } from '../../../pages/SocialVerse/SocialVerse.helper';
import { getVideoPoster } from '../../../services/utilities';

const ANIMATION_DURATION_MS = 300;

interface VideoCardComponentProps {
  videoUrl: string;
  id: string;
  thumbnailUrl: string;
  handlePlayVideo?: (videoUrl: string) => void;
  handleDelete?: (id: string) => void;
  playMountAnimation: boolean;
  onMountAnimationPlay?: () => void;
  viewMode?: string;
}

export const VideoCardComponent: FC<VideoCardComponentProps> = ({
  videoUrl,
  id,
  thumbnailUrl,
  handlePlayVideo,
  handleDelete,
  playMountAnimation,
  onMountAnimationPlay,
  viewMode,
}) => {
  const classes = useVideoCardStyles({ viewMode });

  const ref = useRef<any>(null);

  const animationCallbackTimeoutRef = useRef<number>();
  const { setStoryVideoId, setWithEditThumbnailOpen } = useVideoTableIdContext();

  useEffect(() => {
    animationCallbackTimeoutRef.current = setTimeout(() => {
      onMountAnimationPlay?.();
    }, ANIMATION_DURATION_MS);

    return () => {
      clearTimeout(animationCallbackTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      ref={ref}
      style={{
        animation: playMountAnimation ? `zoomIn ${ANIMATION_DURATION_MS}ms ease-in-out` : undefined,
      }}
      key={id}
      className={classes.videoCardWrapper}
      draggable
    >
      <Box key={id} id={id}>
        <CardMedia
          className={classes.videoCard}
          component={thumbnailUrl ? 'image' : 'video'}
          image={thumbnailUrl || getVideoPoster(thumbnailUrl)}
          style={{
            pointerEvents: 'none',
          }}
        />
        <IconButton
          classes={{ root: classes.closeButton }}
          className={classes.icon}
          onClick={() => {
            if (ref && ref.current && ref.current.style) {
              ref.current.style.animation = `zoomOut ${ANIMATION_DURATION_MS}ms ease-in-out`;
            }
            handleDelete?.(id);
          }}
        >
          <CloseIcomModal />
        </IconButton>
        <IconButton
          onClick={() => handlePlayVideo?.(videoUrl)}
          classes={{ root: classes.playButton }}
          className={classes.icon}
        >
          <PlayIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            setStoryVideoId(id);
            setWithEditThumbnailOpen?.(true);
          }}
          className={classes.thumbnailButton}
        >
          <ChangeThumnailIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
