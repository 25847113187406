import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    width: '100%',
    height: 'auto',
    overflowX: 'clip',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '9px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#d7dae1',
      borderRadius: '10px',
    },
  },
  switchWrapper: {
    backgroundColor: '#F5F6F8',
    width: 'fit-content',
    borderRadius: '50px',
    position: 'absolute',
    zIndex: 10000,
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-12px',
  },
  topSectionDeskTop: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '25px',
  },
  previewEmptyBlock: {
    backgroundColor: '#EEEEEE',
    borderRadius: '20px',
  },
  topSectionLeft: {
    width: '347px',
    height: '35px',
  },
  topSectionRight: {
    width: '169px',
    height: '35px',
  },
  middleBlockDesktop: {
    width: '100%',
    height: '127px',
    marginTop: '14px',
    marginBottom: '58px',
  },
  pluginDesktopWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  bottomBlockDesktop: {
    width: '100%',
    height: '200px',
    marginBottom: '10px',
  },
  topSectionMobile: {
    marginTop: '25px',
    width: '100%',
    height: '35px',
  },
  middleSectionMobile: {
    width: '100%',
    height: '80px',
    marginTop: '14px',
  },
  pluginMobileWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    paddingTop: '8px',
  },
  bottomBlockMobile: {
    width: '100%',
    height: '200px',
    marginBottom: '10px',
  },
  leftSection: {
    height: '430px',
    width: '60%',
    marginBottom: '28px',
  },
}));
