import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  svName: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.28',
  },
  divider: {
    color: '#8B89A0',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.28',
  },
  numberOfVideos: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '30px',
    letterSpacing: '0.28',
  },
}));
