import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Input } from '@mui/material';
import { useStyles } from './CreatorsAdvancedSearch.styles';
import { SearchIcon } from '../../assets/SearchIcon';
import { ClipsSearchStrings } from '../../localization/en';
import IconButton from '@mui/material/IconButton';
import { SearchButtonIcon } from '../../assets/SearchButton';
import { SearchPopUp } from './SearchPopUp/SearchPopUp';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setFilterSearch } from '../../../store/slices/searchFilterSlice';
import { fetchUsersBySearchFilterApiCall, resetPage } from '../../../store/slices/heroesSlice';
import { CreatorAttributeName, CreatorStatus } from '../../../api/models/searchFilterModels';
import CloseIcon from '@mui/icons-material/Close';
import { getDebouncedFunc } from '../../../services/utilities';
import { defaultPagination } from '../../constants/constants';
import { SearchResultsList } from '../SearchResultsList/SearchResultsList';

export const CreatorsAdvancedSearch: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const { id: accountId } = useTypedSelector((state) => state.venue.venue);
  const { search, activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const isPopUpOpenRef = useRef(false);
  const inputRef = useRef<HTMLElement | null>(null);

  const [predictiveSearchItems, setPredictiveSearchItems] = useState<string[]>([]);
  const [isPredictiveItemsLoading, setIsPredictiveItemsLoading] = useState(false);

  const isActiveUsersTable = !!activeCreatorFilterAttributes.find(
    (attribute) =>
      attribute.name === CreatorAttributeName.Status && attribute.value === CreatorStatus.Active,
  );

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  const getPredictiveSearchItems = getDebouncedFunc(async (value) => {
    if (!value) {
      setPredictiveSearchItems([]);
      return;
    }

    setIsPredictiveItemsLoading(true);
    const result = await fetchUsersBySearchFilterApiCall({
      venueId: accountId,
      search: value,
      filter: [],
      pageable: {
        page: 0,
        size: 100,
        sort: defaultPagination.sortByLastCreated,
      },
    });
    setIsPredictiveItemsLoading(false);
    const predictiveSearchItems = result.items
      .map(
        (item) => `${item.firstName || ''} ${item.lastName || ''}`.trim() || item.displayName || '',
      )
      .filter(Boolean);

    setPredictiveSearchItems([...new Set(predictiveSearchItems)]);
  }, 800);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const container = target.closest('#search-container') || target.closest('.MuiModal-root');
    if (!container && isPopUpOpenRef.current) {
      setIsPopUpOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);

  const togglePopUp = () => {
    setIsPopUpOpen((prev) => {
      const isOpen = !prev;
      isPopUpOpenRef.current = isOpen;
      return isOpen;
    });
  };

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(resetPage());
      dispatch(setFilterSearch(search || searchText));
      setIsPopUpOpen(false);
    },
    [dispatch, searchText],
  );

  return (
    <Box className={classes.container} id="search-container">
      <Input
        id="search-input"
        ref={inputRef}
        className={classes.input}
        disableUnderline={true}
        placeholder={ClipsSearchStrings.PlaceholderText}
        onChange={(event) => {
          const value = event.target.value;
          setSearchText(value);
          getPredictiveSearchItems(value);

          if (!value) {
            dispatch(setFilterSearch(''));
            setPredictiveSearchItems([]);
          }
        }}
        value={searchText}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSearch();
          }
        }}
        sx={{
          paddingRight: isActiveUsersTable ? '64px' : '34px',
        }}
      />
      <SearchResultsList
        anchorEl={inputRef.current}
        searchItems={predictiveSearchItems}
        clearItems={() => {
          setPredictiveSearchItems([]);
        }}
        onClick={(searchText) => {
          setSearchText(searchText);

          if (!isPopUpOpenRef.current) {
            onSearch(searchText);
          }
        }}
      />
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>

      {searchText && !isPredictiveItemsLoading && (
        <IconButton
          onClick={() => {
            setSearchText('');
            dispatch(setFilterSearch(''));
          }}
          className={classes.resetSearchIcon}
          sx={{
            right: isActiveUsersTable ? '38px' : '8px',
          }}
        >
          <CloseIcon style={{ fontSize: '16px', cursor: 'pointer' }} />
        </IconButton>
      )}

      {isPredictiveItemsLoading && (
        <CircularProgress
          size={20}
          className={classes.predictiveItemsLoader}
          sx={{
            right: isActiveUsersTable ? '45px' : '14px',
          }}
        />
      )}

      {isActiveUsersTable && (
        <IconButton className={classes.openSearchButton} onClick={togglePopUp}>
          <SearchButtonIcon />
        </IconButton>
      )}
      {isPopUpOpen && (
        <Box className={classes.searchPopUpWrapper}>
          <SearchPopUp
            onSearch={onSearch}
            onApplyFilter={() => {
              togglePopUp();
              setSearchText(search);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
