import { FC } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { FilterLogoPosition } from '../../../api/models/venue';

import backgroundImg from '../../../common/assets/newShareVideoPreview-2.png';
import TBbackgroundImg from '../../../common/assets/trublunewShareVideoPreview.jpg';
import topGolfBackImg from '../../../common/assets/defaultImageTopgolf.png';
import { Theme } from '@material-ui/core';
import { IPhonePreview } from './IPhonePreview';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTypedSelector } from '../../../store';
import { FakeButton } from './previewComponents/FakeButton';
import { PlayIcon } from '../../assets/newDesign/VideoControlsIcons/PlayIcon';

const useStyles = makeStyles<Theme, { isViewForTopGolf?: boolean }>(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageWrapper: {
    position: 'relative',
    width: '400px',
    height: '500px',
    borderRadius: '10px',
  },
  imageCover: {
    objectFit: 'cover',
    width: '400px',
    height: '300px',
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '10px',
  },
  previewWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: '0',
  },
  previewWrapNotTopGolf: {
    width: '100%',
    height: '91%',
  },
  overLayImage: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: '2px',
    left: '2px',
    borderRadius: '10px',
  },
  'top-left': {
    objectFit: 'contain',
    objectPosition: 'left',
    height: '32px',
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  'top-right': {
    objectFit: 'contain',
    objectPosition: 'right',
    height: '32px',
    position: 'absolute',
    top: '25px',
    right: '25px',
  },
  'bottom-left': {
    objectFit: 'contain',
    objectPosition: 'left',
    height: '32px',
    position: 'absolute',
    left: '25px',
    bottom: '25px',
  },
  'bottom-right': {
    objectFit: 'contain',
    objectPosition: 'right',
    height: '32px',
    position: 'absolute',
    right: '25px',
    bottom: '25px',
  },
  topGolfHome: {
    position: 'absolute',
    bottom: '-16px',
    left: '-9px',
    overflow: 'hidden',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    width: '107%',
    height: '106%',
  },
  square: {
    backgroundImage: `url(${topGolfBackImg})`,
    margin: '0 auto',
  },
  rectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`,
    border: 'none',
    margin: '0 auto',
  },
  tbrectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${TBbackgroundImg})`,
  },
  imageBlock: {
    margin: '0 auto',
    backgroundColor: 'black',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '37px',
    maxHeight: '37px',
    height: '37px',
    width: '37px',
    minWidth: '37px',
  },
  incentiveImg: {
    borderRadius: '9px',
    objectFit: 'contain',
    maxHeight: '100%',
    width: '100%',
  },
  PlayIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF52',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  previewContent: {
    position: 'absolute',
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: '20px',
  },
}));

interface IPhoneVideoPlaybackPreviewProps {
  tempFilterOverlayUrl?: string;
  logoPosition: FilterLogoPosition;
  tempFilterLogoEnabled?: boolean;
  tempFilterOverlayEnabled?: boolean;
  color?: 'black' | 'white';
  buttonText?: string;
  isViewForTopGolf?: boolean;
  logo: string;
  brandColor?: string;
}

export const IPhoneVideoPlaybackPreview: FC<IPhoneVideoPlaybackPreviewProps> = ({
  tempFilterOverlayUrl,
  logoPosition,
  tempFilterLogoEnabled,
  tempFilterOverlayEnabled,
  color = 'black',
  buttonText = 'Sign up for a consultation',
  logo,
  brandColor = '#42a5f5',
  isViewForTopGolf,
}) => {
  const classes = useStyles({ isViewForTopGolf });
  const { isHealthCare } = useClientType();
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const backgroundImage = venueProperties?.['webapp.cards-welcome']?.mobile?.original || '';
  return (
    <div className={classes.wrapper}>
      <IPhonePreview>
        {<img className={classes.image} src={backgroundImage} alt="card-background" />}
        <Box className={clsx(classes.previewWrap)}>
          <div
            className={clsx(
              classes.topGolfHome,
              isViewForTopGolf
                ? classes.square
                : isHealthCare
                ? classes.tbrectangle
                : classes.rectangle,
            )}
          >
            {tempFilterOverlayEnabled && tempFilterOverlayUrl && (
              <img className={classes.overLayImage} src={tempFilterOverlayUrl} alt="overlay" />
            )}
            {tempFilterLogoEnabled && logo && (
              <img
                className={classes[logoPosition]}
                src={logo}
                alt="logo"
                style={{ height: '45px', maxWidth: '60px' }}
              />
            )}
            <Box fontSize={45} className={classes.PlayIcon}>
              <PlayIcon />
            </Box>
          </div>
          <Box className={classes.buttonWrapper}>
            <FakeButton
              text={buttonText}
              color={brandColor}
              minWidth="184px"
              marginTop="10px"
              textAlign={'center'}
              width="fit-content"
            />
          </Box>
        </Box>
      </IPhonePreview>
    </div>
  );
};
