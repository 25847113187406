import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  backgroundImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxWidth: '100%',
    height: '100%',
    borderRadius: 'var(--iphoneXborderRadius)',
    objectPosition: 'bottom',
  },
  filterImg: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    borderRadius: 'var(--iphoneXborderRadius)',
  },
  filterImgWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    borderRadius: 'var(--iphoneXborderRadius)',
  },
}));
