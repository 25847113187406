import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { inviteModal?: boolean; isLoading?: boolean }>(
  (theme: Theme) => ({
    container: ({ inviteModal, isLoading }) => ({
      position: 'relative',
      width: '100%',
      border: inviteModal
        ? isLoading
          ? `1px solid ${theme.palette.primary.main}`
          : 'none'
        : '1px solid #DCDBE4',
      borderRadius: '15px',
      padding: '17px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '& .css-mpxmd2-MuiTypography-root': {
        fontSize: '14px',
        fontWeight: '500',
        color: '#344054',
        lineHeight: '20px',
      },
    }),
    title: ({ inviteModal }) => ({
      fontSize: '14px',
      fontWeight: '700',
      color: inviteModal ? '#475467' : theme.palette.common.deepDark,
      textAlign: 'center',
    }),
    subtitle: ({ inviteModal }) => ({
      fontSize: inviteModal ? '14px' : '12px',
      fontWeight: inviteModal ? '500' : '400',
      color: inviteModal ? '#475467' : theme.palette.primary.main,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '6px',
    }),
    loadingWrapper: {
      width: '100%',
      padding: '0 16px',
      position: 'relative',
    },
    fileName: {
      fontSize: '14px',
      color: '#344054',
    },
    fileSize: {
      fontSize: '14px',
      color: '#475467',
      fontWeight: '500',
    },
    progressWrapper: {
      marginTop: '10px',
    },
    progress: {
      width: '100%',
      height: '8px',
      borderRadius: '4px',
      backgroundColor: '#EAECF0',
    },
    completedIcon: {
      position: 'absolute',
      top: '0',
      right: '16px',
    },
    deleteIcon: {
      position: 'absolute',
      top: '-8px',
      right: '8px',
    },
    tryAgainButton: {
      fontSize: '14px',
      fontWeight: '600',
      padding: '0',
      marginTop: '4px',
    },
    dragOver: {
      width: '48px',
      height: '48px',
      borderRadius: '6px',
      boxShadow: '0px 24px 48px -12px #1018282E',
      position: 'absolute',
      bottom: '13px',
      right: '13px',
      display: 'grid',
      placeContent: 'center',
    },
    dropzone: {
      width: '544px',
      height: '68px',
      gap: '6px',
      border: '1px dashed #D0D5DD',
      padding: '22px 14px',
    },
    campaignSelectWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20px',
    },
    spanStyles: {
      fontSize: '14px',
      fontWeight: '500',
      whiteSpace: 'nowrap',
      color: '#344054',
    },
    menuWrapper: {
      boxShadow: 'none',
      border: '1px solid #DCDBE4',
      transform: 'translate(0px, 103px) !important',
      height: '100px',
      maxHeight: '400px',
      width: '400px',
      overflow: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '5px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '5px',
      },
    },
    selectItem: {
      width: '400px',
      height: '28px',
      background: 'white !important',
      padding: '10px 30px',
      color: 'gray',
      '&:hover': {
        background: '#F5F6F8 !important',
      },
    },
    addVideoButton: {
      marginTop: '32px',
      width: '100%',
      height: '44px',
      padding: '10px 18px 10px 18px',
      gap: '8px',
      opacity: '0px',
    },
    select: {
      width: '400px',
      height: '48px',
      background: '#fff',
      marginLeft: '8px',
      marginRight: '20px',

      '& ul': {
        marginRight: '0 !important',
        width: '100% !important',
      },

      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none !important',
      },
      '& .MuiMenu-list': {
        marginRight: '10px',
        width: '20px',
      },
      '& .MuiSelect-select': {
        color: '#667085',
        fontSize: '16px',
        fontWeight: '400',
      },
    },
  }),
);
