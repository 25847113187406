import { Box, Button, Grid, Theme, Typography, ClickAwayListener } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, MouseEvent } from 'react';
import { useTypedSelector } from '../../store';
import { ViewsIcon } from '../assets/searchFilterIcons/ViewsIcon';
import { ListFilterItemPopper } from './CreateSearchFilterComponent/listFiltersComponents/ListFilterItemPopper';
import { CustomPopper, PopperPlacements } from './Popper/Popper';
import { TopBar } from './topBar/TopBar';

const useStyles = makeStyles((theme: Theme) => ({
  SelectedFilter: {
    borderRadius: '25px',
    border: '1px solid #909090',
    padding: '9px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '288px',
    position: 'relative',
  },
  Icon: {
    display: 'flex',
    alignItems: 'center',
  },
  FilterBtn: {
    marginLeft: '10px',
    width: 'fit-content',
  },
}));

interface TopbarSelectedFilterProps {
  anchorEl: HTMLElement | null;
  handleSetAnchorEl: () => void;
  handleSetId: () => void;
  handleClick: (event: MouseEvent<HTMLSpanElement>) => void;
  handleClearFilter: () => void;
  sectionTitle: string;
  multiline: boolean;
}

export const TopbarSelectedFilter: FC<TopbarSelectedFilterProps> = ({
  anchorEl,
  handleSetAnchorEl,
  handleSetId,
  handleClick,
  handleClearFilter,
  sectionTitle,
  multiline = true,
}) => {
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const classes = useStyles();
  const handleClickAway = () => {
    handleSetAnchorEl();
    handleSetId();
  };

  return activeFilter ? (
    <TopBar sectionTitle={sectionTitle} multiline={multiline}>
      <Grid container alignItems="center" justifyContent={'space-between'}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.SelectedFilter}>
            {anchorEl && (
              <CustomPopper
                open={anchorEl ? true : false}
                anchorEl={anchorEl}
                placement={PopperPlacements.BottomEnd}
              >
                <ListFilterItemPopper item={activeFilter} />
              </CustomPopper>
            )}

            <Typography>Filter: {activeFilter.name}</Typography>

            <span
              className={classes.Icon}
              onClick={(e) => {
                if (anchorEl) {
                  handleClickAway();
                } else {
                  handleClick(e);
                }
              }}
            >
              <ViewsIcon />
            </span>
          </Box>
        </ClickAwayListener>
        <Button className={classes.FilterBtn} onClick={handleClearFilter} variant="contained">
          Exit
        </Button>
      </Grid>
    </TopBar>
  ) : (
    <TopBar sectionTitle={sectionTitle} />
  );
};
