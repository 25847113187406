import { Skeleton } from '@mui/material';
import { FC } from 'react';
type Props = {
  children?: JSX.Element;
  isLoading: boolean;
  width: number;
  height: number;
};

export const SkeletonLoader: FC<Props> = ({ children, isLoading, width, height }: Props) => {
  return (
    <>
      {isLoading ? (
        <Skeleton variant="rectangular" width={width} height={height} />
      ) : (
        <>{children}</>
      )}
    </>
  );
};
