import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    minHeight: 'calc(100vh - 19px)',
    padding: '24px 24px 24px',
    background: theme.palette.common.white,
    minWidth: '465px',
    boxShadow: '0 15px 20px 0px rgba(0, 0, 0, 0.25);',
    zIndex: 2,
  },
  customise: {
    color: '#8B89A0',
    marginBottom: '24px',
  },
  sectionWrapper: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginTop: '24px',
    marginBottom: '17px',
  },
  sectionTitle: {
    fontSize: '18px',
    color: theme.palette.common.deepDark,
    fontWeight: 500,
  },

  titleInput: {
    '::placeholder': {
      fontSize: '30px',
    },
  },
  configDivider: {
    marginTop: '24px',
  },
  configInfo: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '24px',
    marginTop: '-7px',
    marginBottom: '28px',
  },
  palette: {
    marginTop: '5px',
  },
  configMapSection: {
    background: '#f5f6f8',
    minHeight: 'calc(100vh - 164px)',
  },
  configMapWrapper: {
    margin: '38px 0 0 21px',
    padding: '28px 28px 24px 26px',
    background: theme.palette.common.white,
    borderRadius: '20px',
  },
  configMapTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '25px',
  },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    width: '115px',
    height: '44px',
  },
  titleInfo: {
    color: '#8B89A0',
    fontSize: '12px',
    marginTop: '17px',
    padding: '0 4px',
  },
}));
