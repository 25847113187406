import {
  SV_HEALTH_SERVICE_AGREEMENT,
  SV_SERVICE_AGREEMENT,
  TRUBLU_SERVICE_AGREEMENT,
} from '../common/constants/constants';
import { useClientType } from '../services/hooks/useClientType';

export const useServiceAgreementLink = () => {
  const { isTrubluClient, isSvHealth } = useClientType();

  if (isTrubluClient) {
    return `${window.location.origin}/${TRUBLU_SERVICE_AGREEMENT}`;
  }
  if (isSvHealth) {
    return `${window.location.origin}/${SV_HEALTH_SERVICE_AGREEMENT}`;
  }

  return `${window.location.origin}/${SV_SERVICE_AGREEMENT}`;
};
