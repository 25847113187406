import { FC } from 'react';

type IPictureIconProps = {
  color?: string;
};

export const PictureIcon: FC<IPictureIconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1183 3.29077H4.04687C2.94231 3.29077 2.04688 4.1862 2.04688 5.29077V18.7091C2.04688 19.8137 2.94231 20.7091 4.04688 20.7091H20.1183C21.2229 20.7091 22.1183 19.8137 22.1183 18.7091V5.29077C22.1183 4.1862 21.2229 3.29077 20.1183 3.29077Z"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.119 14.3309L18.172 10.8538C17.7841 10.5121 17.1996 10.5223 16.8239 10.8775L11.4216 15.9852L8.45169 13.1953C8.07313 12.8397 7.48534 12.8331 7.09888 13.1801L2.37842 17.4189"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8261 10.4818C11.7397 10.4818 12.4804 9.74119 12.4804 8.82758C12.4804 7.91397 11.7397 7.17334 10.8261 7.17334C9.9125 7.17334 9.17188 7.91397 9.17188 8.82758C9.17188 9.74119 9.9125 10.4818 10.8261 10.4818Z"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
