export interface RewardApiModel {
  id: string;
  isActive: boolean;
  imageUrl: string;
  name: string;
  points: number;
  value: number;
  fulfilled: number;
  performance: number;
}

export interface CreateRewardApiModel {
  name: string;
  reward: string;
  imageUri: string;
  isActive: boolean;
  redemptionPoints: number;
}

export interface ActivityApiModel {
  id: string;
  title: string;
  description: string;
  points: number;
  displayOrder: number;
  clientType: string | null;
  details: string | null;
  type: ActivitiesTypes;
  projections: number | null;
  oneHero: number | null;
  ptsEarned: number | null;
}

export enum ActivitiesTypes {
  CreateVideo = 'CREATE_VIDEO',
  CallToAction = 'CALL_TO_ACTION',
  BonusPoints = 'BONUS_POINTS',
  Donation = 'DONATION',
  EnterEnoughEmails = 'ENTER_ENOUGH_EMAILS',
  FirstDonation = 'FIRST_DONATION',
  HitFundraisingGoal = 'HIT_FUNDRAISING_GOAL',
  ReferVisitor = 'REFER_VISITOR',
  SendAutomatedEmails = 'SEND_AUTOMATED_EMAILS',
  VideoView = 'VIDEO_VIEW',
  Featured = 'GET_FEATURED',
  ReferPurchaser = 'REFER_PURCHASER',
  HitVideosCreatedGoal = 'HIT_VIDEOS_CREATED_GOAL',
  HitCalendarEngagementGoal = 'HIT_CALENDAR_ENGAGEMENT_GOAL',
  Engagement = 'ENGAGEMENT',
  VideoCreated = 'VIDEO_CREATED',
  VideosViewed = 'VIDEO_VIEWED',
  VideosFeatured = 'VIDEO_FEATURED',
  Conversions = 'CONVERSION',
  VideoCreationGoals = 'VIDEO_CREATION_GOAL_REACHED',
  VideoEngagementGoals = 'VIDEO_ENGAGEMENT_GOAL_REACHED',
  VideoShared = 'VIDEO_SHARED',
}
export interface RewardsListApiModel {
  id: string;
  isActive: boolean;
  imageUrl: string;
  name: string;
  points: number;
  value: number;
  fulfilled?: number;
  performance?: number;
}

export interface PostRewardRequestModel extends RewardsListApiModel {
  venueId: string;
}

export enum RewardsWizardNames {
  RecommendedRewardValue = 'recommendedRewardValue',
  RecommendedRewardBudget = 'recommendedRewardBudget',
  ProjectedHeroes = 'projectedHeroes',
}

export type RewardsCalculationModel = Record<RewardsWizardNames, number>;

export type RewardsCalculationActivityModel = Record<ActivitiesTypes, number>;

export type RewardsCalculationApiModel = RewardsCalculationModel & {
  activityProjections: RewardsCalculationActivityModel;
  incentiveValuePerSalesRep?: number;
  projectedHeroes?: number;
  recommendedRewardBudget?: number;
  recommendedRewardValue?: number;
  rewardValue?: number;
};

export interface RewardsCalculationOptions {
  venueId: string;
  payload: {
    rewardBudget: number;
    rewardValue: number;
    numberOfHeroes: number;
  };
}

export interface ActivityApiModel {
  userId: string;
  activityType: ActivitiesTypes;
  count: number;
  points: number;
  activityTitle: string;
  activityDescription: string;
}
