import { Box, Typography } from '@mui/material';
import React from 'react';
import { UnverifiedIcon, VerifiedIcon, OptedOutIcon } from './UserPhoneStatusIcon';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';

export const PhoneVerificationHeader: React.FC = () => {
  return (
    <Box pr="0 !important">
      <Typography fontSize="13px" color="#8B89A0" mr="-7px">
        Verification
      </Typography>
      <Tooltip
        rounded="12px"
        padding="12px"
        content={
          <Box width="250px">
            <Typography fontSize="12px" fontWeight="600" color="#344054" textAlign="start">
              Phone Number Verification Status:
            </Typography>
            {[
              { icon: <VerifiedIcon />, text: 'Verified' },
              { icon: <UnverifiedIcon />, text: 'Unverified' },
              { icon: <OptedOutIcon />, text: 'Opted-Out' },
            ].map(({ icon, text }) => (
              <Box display="flex" mt="4px" alignItems="center" key={text}>
                <Box
                  width="24px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  {icon}
                </Box>
                <Typography fontSize="12px" fontWeight="500" color="#667085" ml="6px">
                  {text}
                </Typography>
              </Box>
            ))}
          </Box>
        }
      />
    </Box>
  );
};
