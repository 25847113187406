import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  Container: {
    display: 'grid',
    width: '100%',
    background: '#f5f6f8',
    height: 'calc(100vh - 180px)',
    marginBottom: '-60px',
  },
  ShareSection: {
    backgroundColor: theme.palette.common.white,
    padding: '25px 30px',
    height: '100%',
  },
  SectionTitle: {
    color: '#8B89A0',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
  },
  Icon: {
    border: '1px solid #DCDBE4',
    borderRadius: '50%',
    fontSize: '14px',
    lineHeight: '26px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '13px',
  },
  SharingTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
  },
  EditOrderBtn: {
    marginLeft: 'auto',
    color: '#8B89A0',
    cursor: 'pointer',
  },
  ToolTipDrabble: {
    fontSize: '12px',
    lineHeight: '28px',
    color: 'rgba(0,0,0,.4)',
  },
  ActiveEditButton: {
    color: theme.palette.primary.main,
  },
  DragbbleWrapper: {
    backgroundColor: 'var(--pageBackgroundColor)',
    padding: '20px 18px',
    marginTop: '15px !important',
  },
  IconsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '50px',
    width: '100%',
  },
  IconWrapper: {
    cursor: 'pointer',
    marginRight: '35px',
    marginBottom: '25px',
  },
  Icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
  },
  IconName: {
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  IconNameActive: {
    color: theme.palette.common.black,
  },
  IconNameInactive: { color: '#8B89A0' },
  DashedCircle: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
    position: 'absolute',
    zIndex: -1,
  },

  title: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    letterSpacing: 0.1,
  },
  desc: {
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
    color: '#8f94b6',
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 1.7,
  },
  bottomControllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    paddingLeft: '260px',
    paddingTop: '20px',
    zIndex: 100,
  },
  actionButton: {
    margin: '5px 2px',
    width: '120px',
    borderRadius: '25px',
  },
  saveBtn: {
    marginRight: '14px',
  },
  inputLabel: {
    color: theme.palette.text.primary,
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
  },

  ShareItemWrapper: {
    padding: '20px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  ShareItemsSection: {
    marginTop: '20px',
    marginLeft: '0',
    width: '100%',
  },

  Title: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
}));

export enum ButtonsTypes {
  Message = 'Message',
  Email = 'Email',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Link = 'Link',
  LinkedIn = 'LinkedIn',
  Download = 'Download',
}

export interface AccordionsState {
  firstStage: boolean;
  secondStage: boolean;
  thirdStage: boolean;
}
