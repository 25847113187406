import { FC } from 'react';

export const ResendInviteIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.54977 3.74012L10.9173 1.61762C13.7748 0.665116 15.3273 2.22512 14.3823 5.08262L12.2598 11.4501C10.8348 15.7326 8.49477 15.7326 7.06977 11.4501L6.43977 9.56012L4.54977 8.93012C0.267266 7.50512 0.267266 5.17262 4.54977 3.74012Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
