import * as Yup from 'yup';
// import { emailMaskRegex } from '../../common/constants/constants';

export interface ForgotPasswordFormValues {
  email: string;
}

export const initialValuesForgotPasswordForm: ForgotPasswordFormValues = {
  email: '',
};

export const validationSchemaForgotPassword = () =>
  Yup.object().shape({
    email: Yup.string().email().required('Required'),
  });

export interface LoginFormValues {
  email: string;
  password: string;
}

export const initialValuesLoginForm: LoginFormValues = {
  email: '',
  password: '',
};

export const validationSchemaLoginForm = () =>
  Yup.object().shape({
    email: Yup.string().email().required('Required'),
    password: Yup.string()
      .min(5, 'password should be min 5 characters long')
      .max(25, 'max length 25 characters')
      .required('Required'),
  });

export interface ResetPasswordFormValues {
  password: string;
  confirmPassword: string;
  name?: string;
}

export const initialValuesResetPasswordForm: ResetPasswordFormValues = {
  password: '',
  confirmPassword: '',
};

export const validationSchemaResetPassword = () =>
  Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    password: Yup.string()
      .min(5, 'Password should be min 5 characters long')
      .max(25, 'Max length 25 characters')
      .required('Required'),
    confirmPassword: Yup.string()
      .min(5, 'Password should be min 5 characters long')
      .max(25, 'Max length 25 characters')
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

export const validationSchemaCreateAccount = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    password: Yup.string()
      .min(5, 'Password should be min 5 characters long')
      .max(25, 'Max length 25 characters')
      .required('Required'),
    confirmPassword: Yup.string()
      .min(5, 'Password should be min 5 characters long')
      .max(25, 'Max length 25 characters')
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
