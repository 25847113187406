import { Typography, Box, FormHelperText, FormControlLabel, Checkbox } from '@mui/material';
import clsx from 'clsx';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

import { useStyles } from './PhoneField.style';
import { TwilioSupportedCountries } from '../../constants/constants';
import { CommonStrings } from '../../localization/en';

export interface PhoneValidationState {
  isValid: boolean;
  isFilled: boolean;
  isEmpty: boolean;
}

export const DEFAULT_PHONE_VALIDATION_STATE = {
  isValid: false,
  isFilled: false,
  isEmpty: true,
};
export const VALID_PHONE_VALIDATION_STATE = {
  isValid: true,
  isFilled: true,
  isEmpty: false,
};

interface PhoneFieldProps {
  name: string;
  value: string;
  label?: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Use this value to get phone field validation state
   * @example
   * // If you want instantly check validation in your form -> use ref for storing this value
   * const phoneValidation = useRef<PhoneValidationState>(DEFAULT_PHONE_VALIDATION_STATE);
   * <PhoneField onValidationChange={(v) => { phoneValidation.current = v }}/>
   */
  onValidationChange?: (args: PhoneValidationState) => void;
  validateOnMount?: boolean;
  placeholder?: string;
  error?: string;
  enableSearch?: boolean;
  countryListMaxHeight?: number;
  defaultCountry?: string;
  onlyCountries?: string[];
  disabled?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  isCountryCodeDisabled?: boolean;
  setCountryCode?: (code: string) => void;
  autoComplete?: string;
  countriesDropDownPosition?: 'static' | 'fixed';
  inputClass?: string;
  setFieldValue?: (value: string) => void;
  countryCodeEditable?: boolean;
  hideInternationalPhoneNumberCheckbox?: boolean;
  isInternationalPhoneNumberChecked?: boolean;
}

interface PhoneInputCountry {
  countryCode: string;
  dialCode: string;
  name: string;
  format: string;
}

const DEFAULT_MASK = '... ... ... ...';
const MIN_PHONE_LENGTH = 11;
const MIN_PHONE_LENGTH_WITHOUT_CC = 10;

export const PhoneField: FC<PhoneFieldProps> = ({
  value,
  onChange,
  onValidationChange,
  validateOnMount,
  label,
  className,
  name,
  placeholder,
  error,
  countryListMaxHeight,
  enableSearch = false,
  defaultCountry = 'us',
  onlyCountries,
  disabled,
  onBlur,
  isCountryCodeDisabled,
  countryCodeEditable = false,
  setCountryCode,
  autoComplete = 'none',
  countriesDropDownPosition,
  inputClass = '',
  setFieldValue,
  hideInternationalPhoneNumberCheckbox,
  isInternationalPhoneNumberChecked,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const initialInternationalPhone = hideInternationalPhoneNumberCheckbox
    ? true
    : isInternationalPhoneNumberChecked || false;
  const [useInternationalPhone, setUseInternationalPhone] = useState(initialInternationalPhone);

  const classes = useStyles({
    enableSearch,
    countryListMaxHeight,
    isFocused,
    error: !!error,
    disabled,
  });
  const errorClassName = error && classes.error;
  const [isValid, setIsValid] = useState(DEFAULT_PHONE_VALIDATION_STATE.isValid);
  const [isFilled, setIsFilled] = useState(DEFAULT_PHONE_VALIDATION_STATE.isFilled);
  const [isEmpty, setIsEmpty] = useState(DEFAULT_PHONE_VALIDATION_STATE.isEmpty);

  const [mounted, setIsMounted] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setUseInternationalPhone(isInternationalPhoneNumberChecked || false);
  }, [isInternationalPhoneNumberChecked]);

  useEffect(() => {
    if (!mounted && !validateOnMount) return;
    onValidationChange?.({ isValid, isFilled, isEmpty });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isFilled, isEmpty]);

  useEffect(() => {
    if (!mounted) {
      setIsMounted(true);
      return;
    }
  }, [mounted]);

  return (
    <Box
      className={clsx(classes.root, className)}
      sx={{
        '& .react-tel-input .country-list': {
          boxShadow: '1px 2px 10px  rgb(0 0 0/35%)',
          position: countriesDropDownPosition ? countriesDropDownPosition : 'static',
        },
      }}
    >
      <Typography className={classes.label}>{label}</Typography>
      <PhoneInput
        country={defaultCountry}
        onlyCountries={onlyCountries || TwilioSupportedCountries}
        autoFormat
        placeholder={placeholder}
        value={value}
        onChange={(val, data, event, number) => {
          // When we switch country from a dropdown then onClick event triggers

          // This needed to call onChange with real value
          if (!event.target.value) {
            event.target.value = val;
            event.target = inputRef.current as HTMLInputElement;
          }

          if ('countryCode' in data) {
            setCountryCode?.(data.dialCode);
          }

          if (event.target.value === '+') {
            event.target.value = '';
          }
          onChange(event);
        }}
        containerClass={classes.container}
        inputClass={classes.field}
        dropdownClass={classes.countryList}
        buttonClass={errorClassName}
        searchClass={classes.search}
        searchPlaceholder="Search"
        buttonStyle={{
          display: useInternationalPhone ? 'block' : 'none',
        }}
        inputStyle={{
          paddingLeft: useInternationalPhone ? '60px' : '20px',
        }}
        inputProps={{
          id: name,
          name: name,
          className: clsx(classes.inputClassName, errorClassName, inputClass),
          ref: inputRef,
          autoComplete: autoComplete,
        }}
        disableCountryCode={isCountryCodeDisabled}
        countryCodeEditable={countryCodeEditable}
        enableSearch={true}
        defaultMask={DEFAULT_MASK}
        isValid={(value, country) => {
          const selectedCountry = country as PhoneInputCountry;
          const formatMaskLength = selectedCountry.format?.match(/\./g)?.length;

          const phoneLength = isCountryCodeDisabled
            ? MIN_PHONE_LENGTH_WITHOUT_CC
            : MIN_PHONE_LENGTH;

          const filled = value.length === formatMaskLength || value.length >= phoneLength;

          const startsWithDialCode = value.startsWith(selectedCountry.dialCode);

          const isValid = (startsWithDialCode || !!isCountryCodeDisabled) && filled;
          const isEmpty = !value;

          setIsFilled(filled);
          setIsValid(isValid);
          setIsEmpty(isEmpty);

          return isValid;
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={(e) => {
          setIsFocused(false);
          onBlur?.(e);
        }}
        disabled={disabled}
      />
      {error && (
        <FormHelperText error sx={{ display: 'flex', alignItems: 'center' }}>
          <ReportProblemRoundedIcon
            color="error"
            sx={{ height: '16px', width: '16px', mr: '8px' }}
          />
          {error}
        </FormHelperText>
      )}
      {hideInternationalPhoneNumberCheckbox
        ? null
        : (!onlyCountries || onlyCountries.length > 1) && (
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      padding: '8px',
                    }}
                    checked={useInternationalPhone}
                    disabled={disabled}
                    onChange={(event, checked) => {
                      setUseInternationalPhone(checked);
                      if (!checked) {
                        setFieldValue?.('+1');
                      }
                    }}
                  />
                }
                label={
                  <Typography fontSize="12px">
                    {CommonStrings.UseInternationalPhoneNumber}
                  </Typography>
                }
              />
            </Box>
          )}
    </Box>
  );
};
