import { FC } from 'react';
import { theme } from '../../theme';

type iconProps = {
  color?: string;
};

export const SettingsIcon: FC<iconProps> = ({ color = theme.palette.primary.main }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3333 5.41602H13.3333"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.00008 5.41602H1.66675"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.33341 8.33333C9.94425 8.33333 11.2501 7.0275 11.2501 5.41667C11.2501 3.80584 9.94425 2.5 8.33341 2.5C6.72258 2.5 5.41675 3.80584 5.41675 5.41667C5.41675 7.0275 6.72258 8.33333 8.33341 8.33333Z"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3333 14.584H15"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66675 14.584H1.66675"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 17.4993C13.2775 17.4993 14.5833 16.1935 14.5833 14.5827C14.5833 12.9719 13.2775 11.666 11.6667 11.666C10.0558 11.666 8.75 12.9719 8.75 14.5827C8.75 16.1935 10.0558 17.4993 11.6667 17.4993Z"
      stroke={color || theme.palette.primary.main}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
