import * as Yup from 'yup';

export interface ResetPasswordInitial {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
}
export const passwordInitialValue: ResetPasswordInitial = {
  oldPassword: '',
  newPassword: '',
  confirmedPassword: '',
};
export const enum PasswordsError {
  CurrentPassIsInvalid = 'Current password is invalid',
  UseDifferentPass = 'Please use a password that isn`t the same as your old password',
}
export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().min(6, 'Min 6 symbols').max(42, 'Max 42 characters').required('Required'),
    oldPassword: Yup.string().required('Required'),
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Required'),
    confirmedPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  });
