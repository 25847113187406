import React from 'react';

const CompleteIcon = () => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.333 29.9441C23.8191 29.9441 29.9441 23.8191 29.9441 16.333C29.9441 8.84692 23.8191 2.72192 16.333 2.72192C8.84692 2.72192 2.72192 8.84692 2.72192 16.333C2.72192 23.8191 8.84692 29.9441 16.333 29.9441Z"
        stroke="#1ED500"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5488 16.3331L14.4008 20.1851L22.1183 12.4812"
        stroke="#1ED500"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompleteIcon;
