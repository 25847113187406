import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  isUwmClient: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  modalContainer: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '1000px',
    backgroundColor: 'white',
    borderRadius: '16px',
    overflow: 'hidden',
    boxShadow: '24px',
  },
  heading: {
    display: 'flex',
    padding: '24px 27px',
    alignItems: 'center',
  },
  headingIconContainer: {
    marginRight: '18px',
  },
  headingContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  uwmTitle: {
    fontWeight: '400',
    fontSize: '32px',
    color: 'white',
    letterSpacing: '0.64px',
    lineHeight: '52px',
  },

  uwmSubtitleList: {
    display: 'block',
    color: '#fff',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '400',
    marginLeft: '10px',
    marginBottom: '3px',
    '&::before': {
      content: `"• "`,
      marginRight: '5px',
    },
  },
  uwmSubtitle: {
    color: '#fff',
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '400',
    marginTop: '15px',
  },
  headingTitle: {
    fontWeight: '600',
    fontSize: '32px',
    color: 'white',
  },
  headingSubtitle: {
    fontSize: '14px',
    color: 'white',
  },
  letsGoButton: ({ isUwmClient }) => ({
    borderRadius: '8px',

    fontWeight: '500',
    fontSize: '14px',
    '& p': {
      whiteSpace: 'nowrap',
      marginRight: '4px',
    },
    '&:hover': {
      borderLeft: isUwmClient ? '8px solid #A1CE57' : '8px solid #FFB515',
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  stepper: ({ isUwmClient }) => ({
    height: '100%',
    marginBottom: 'auto',
    padding: '24px 30px',
    width: '550px',
    '& .MuiStepLabel-labelContainer .MuiTypography-root': {
      color: '#1A1538 !important',
    },

    '& .Mui-disabled .MuiStepIcon-root': {
      color: '#F2F4F7',
      '& .MuiStepIcon-text': {
        fill: '#1A1538',
      },
    },
    '& .MuiStepIcon-root': {
      color: '#F2F4F7',
      '& .MuiStepIcon-text': {
        fill: '#1A1538',
      },
      '&.Mui-active': {
        color: isUwmClient ? '#00A39D' : '#3B6EED',
        '& .MuiStepIcon-text': {
          fill: '#FFF',
        },
      },
      '&.Mui-completed': {
        color: '#67CE67',
      },
    },
    '& .MuiStepContent-root, .MuiStepConnector-root': {
      borderColor: '#DCDBE4',
      borderWidth: '3px',
    },
    '& .MuiStepConnector-lineVertical': {
      borderColor: '#DCDBE4',
      borderWidth: '3px',
    },

    '& .Mui-completed .MuiStepContent-root': {
      borderColor: '#67CE67 !important',
    },

    '& .Mui-completed + .MuiStepConnector-root .MuiStepConnector-line': {
      borderColor: '#67CE67 !important',
    },
    '& .Mui-active + .MuiStep-root + .MuiStepConnector-root .MuiStepConnector-line': {
      borderColor: isUwmClient ? '#00A39D' : '#3B6EED',
    },
    '& .Mui-active + .MuiStep-vertical .MuiStepContent-root': {
      borderColor: isUwmClient ? '#00A39D' : '#3B6EED',
    },

    '& .MuiStepLabel-labelContainer .Mui-completed .MuiTypography-root': {
      color: `#1A1538 !important`,
    },

    '& .MuiStep-root:has(.Mui-active) + .MuiStepConnector-root .MuiStepConnector-line': {
      borderColor: isUwmClient ? '#00A39D' : '#3B6EED',
    },

    '& .MuiStep-root:has(.Mui-active) .MuiStepContent-root': {
      borderColor: isUwmClient ? '#00A39D' : '#3B6EED',
    },
  }),
  step: {
    position: 'relative',
  },
  stepLabel: {
    position: 'relative',
    width: '100%',

    '& .MuiStepLabel-label': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  labelText: {
    fontSize: '24px',
    fontWeight: '500',
    // letterSpacing: '0.02em',
    color: '#1A1538',
    lineHeight: '40px',
  },
  expandIcon: {
    transform: 'rotate(270deg)',
    marginRight: '10px',
  },
  stepContent: {
    fontSize: '14px',
    color: '#475467',
    fontWeight: '400',
    width: '413px',
    padding: 0,
  },
  doLaterButton: {
    whiteSpace: 'nowrap',
  },
  actionButtonsContainer: {
    display: 'flex',
    gap: '4px',
    marginTop: '24px',
    minHeight: '45px',
  },
  previewContainer: {
    width: '430px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 0',
  },
  finishedPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  finishedPreviewEmoji: {
    width: '100px',
    height: '100px',
    marginBottom: '24px',
  },
  finishedPreviewTitle: {
    fontWeight: '600',
    fontSize: '24px',
    color: '#1A1538',
    textAlign: 'center',
  },
  finishedPreviewSubtitle: {
    textAlign: 'center',
    fontSize: '19px',
    color: '#1A1538',
    width: '248px',
  },
  messageIcon: {
    height: '22px',
    marginLeft: '5px',
  },
  finishedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  finishedText: {
    color: '#475467',
    fontSize: '16px',
    marginTop: '16px',
  },
}));
