import { Box, Grid, GridProps, Typography } from '@mui/material';
import { FC, RefObject } from 'react';
import { MessageBuilder } from '../../../../../common/components/MessageBuilder/MessageBuilder';
import { LocalMessageVariables } from '../../../../../common/components/SMS/SMS.helper';
import { theme } from '../../../../../theme';
import { MESSAGE_MAX_CHARACTERS } from '../../SmsInvite.helper';
import { useStyles } from './Accordion.styles';
import { SmsInviteStrings } from '../../../../../common/localization/en';
import { RoundedButton } from '../../../../../common/components/Button/RoundedButton';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { InvitationSMSImageUploader } from '../../../../../common/components/ImageUploader/uploaders/InvitationSMSImageUploader';

interface SmsEditorProps extends GridProps {
  title: string;
  description?: string;
  messageLabel?: string;
  message: string;
  onMessageChange: (message: string) => Promise<void> | void;
  templateVariables: Record<LocalMessageVariables, string>;
  messageFieldRef?: RefObject<HTMLInputElement>;
  disabled?: boolean;
  onSendTestClick: () => void;
  errorText?: string;
  withUploadImageButton?: boolean;
  isMms?: boolean;
  isCreateNewCampaign?: boolean;
  hideMessageLabel?: boolean;
}

export const SmsEditor: FC<SmsEditorProps> = ({
  title,
  description,
  messageLabel = 'Edit SMS Message',
  message,
  onMessageChange,
  templateVariables,
  disabled,
  messageFieldRef,
  onSendTestClick,
  errorText,
  withUploadImageButton = false,
  isMms,
  isCreateNewCampaign,
  hideMessageLabel = false,
  ...restProps
}) => {
  const styles = useStyles({ isCreateNewCampaign });
  const { isHealthCare } = useClientType();
  return (
    <Grid xs={12} {...restProps}>
      <Grid mr={1} className={styles.btnWrapper}>
        <Typography className={styles.subtitle}>{title}</Typography>
        <RoundedButton
          onClick={(e) => {
            e.stopPropagation();
            onSendTestClick();
          }}
          className={styles.btn}
          title={SmsInviteStrings.SendSampleText}
          id="send-sample-sms-text-button"
        />
      </Grid>
      {description && (
        <Typography
          fontSize={14}
          color={theme.palette.common.gray}
          mb="24px"
          letterSpacing="0.02em"
          width={isHealthCare ? '400px' : '450px'}
        >
          {description}
        </Typography>
      )}
      {isCreateNewCampaign && (
        <Box mt={'15px'} mb={'30px'}>
          <InvitationSMSImageUploader
            title={'Select an Image'}
            subtitle={'Adding a familiar picture boosts video creation.'}
            imagePreviewWidth="202px"
            imagePreviewHeight="162px"
            alignment="flex-start"
            imagePadding={5}
          />
        </Box>
      )}
      {!hideMessageLabel && (
        <Box display="flex" justifyContent="space-between">
          <Typography fontSize={12} color="#1A1538" mb="10px" letterSpacing="0.02em">
            {messageLabel}
          </Typography>
        </Box>
      )}
      <Box className={styles.messageBuilderWrapper}>
        <MessageBuilder
          buttons={Object.keys(templateVariables).map((key) => ({ title: key, value: key }))}
          message={message}
          disabled={disabled}
          inputRef={messageFieldRef}
          onMessageChange={onMessageChange}
          borderRadius={'5px'}
          background={`${theme.palette.primary.main}0f`}
          squareBrackets
          errorText={errorText}
          limit={MESSAGE_MAX_CHARACTERS}
          withUploadImageButton={false}
          isMms={isMms}
        />
      </Box>
    </Grid>
  );
};
