import { FC, useMemo } from 'react';
import { useStyles } from './Clips.styles';
import { Box, Button } from '@mui/material';
import { useTypedSelector } from '../../../../store';
import { UserApiModel } from '../../../../api/models/users';
import { VideoCard } from '../../VideoCard/VideoCard';
import { UploadClipIcon } from '../../../assets/UploadClipIcon';
import { UploadModal } from '../UploadModal/UploadModal';
import { useModal } from '../../../../hooks/useModal';

interface Props {
  user: UserApiModel | null;
  campaignId?: string;
  slideoutHeight: number;
  onUpload?: () => void;
}

export const Clips: FC<Props> = ({ user, campaignId, slideoutHeight, onUpload }) => {
  const classes = useStyles();
  const { items } = useTypedSelector((state) => state.videos);

  const { isOpen: isUploadModalOpen, open: openUploadModal, close: closeUploadModal } = useModal();

  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);
  const { id: accountId } = useTypedSelector((state) => state.venue.venue);
  const svList = useMemo(() => {
    return socialVerseAll?.filter((item) => item.accountId === accountId) || [];
  }, [socialVerseAll, accountId]);

  return (
    <Box
      className={classes.container}
      sx={{
        height: `calc(100vh - 340px)`,
        /*  `${slideoutHeight - 200}px` */
      }}
    >
      <Box
        className={classes.videosWrapper}
        /*  sx={{
          height: `${slideoutHeight - 300}px`,
        }} */
      >
        {items
          .filter((item) => (campaignId ? item.campaignId === campaignId : true))
          .map((item, index) => (
            <Box key={item.id} marginRight={`${(index + 1) % 3 === 0 ? '0' : '10px'}`} mb="10px">
              <VideoCard video={item} svList={svList} isClipsSlideout />
            </Box>
          ))}
      </Box>

      <UploadModal
        isOpen={isUploadModalOpen}
        onClose={closeUploadModal}
        userId={user?.id}
        campaignId={campaignId}
        onUpload={onUpload}
      />
      <Box className={classes.btnWrapper}>
        <Button
          variant="outlined"
          className={classes.uploadButton}
          startIcon={<UploadClipIcon />}
          onClick={openUploadModal}
          id="open-upload-modal-btn"
        >
          Upload Clip
        </Button>
      </Box>
    </Box>
  );
};
