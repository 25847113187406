import React, { FC, useState } from 'react';
import { ClickAwayListener, Tooltip, TooltipProps } from '@mui/material';

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

import { Container, Circle } from './TooltipInfo.style';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.secondary,
    padding: '15px',
    fontSize: '12px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const TooltipInfo: FC<TooltipProps> = ({ title, placement = 'top', children }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setOpen(true);
  };
  return (
    <Container>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <BootstrapTooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          title={title}
          placement={placement}
        >
          <Circle
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => handleTooltipOpen(event)}
          >
            {children}
          </Circle>
        </BootstrapTooltip>
      </ClickAwayListener>
    </Container>
  );
};
