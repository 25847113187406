import React, { FC, useRef } from 'react';
import { Box, CircularProgress, IconButton, useTheme } from '@mui/material';
import { AddImageIcon } from '../../assets/AddImageIcon';
import { HtmlTooltip } from '../table/Tooltip';
import { useUploadFileToStorage } from '../../../hooks/useUploadFileToStorage';
import { resizeImageForTwilioMMSCommunication } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { RemoveImageIcon } from '../../assets/RemoveImageIcon';
import { useStyles } from './MessageBuilder.styles';

export const UploadImageButton: FC = () => {
  const classes = useStyles();
  const { inviteMediaUrl } = useTypedSelector((state) => state.createCampaign);

  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const theme = useTheme();

  const { uploadFileToStorage, loading } = useUploadFileToStorage();

  const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileUrl = (await uploadFileToStorage(file))?.downloadUrl;
      if (fileUrl) {
        const resizedImageUrl = await resizeImageForTwilioMMSCommunication(fileUrl);
        dispatch(setInviteMediaUrl(resizedImageUrl));
      }
    }
  };

  const handleRemoveImage = () => {
    dispatch(setInviteMediaUrl(''));
  };
  return (
    <>
      <Box className={classes.btnsWrapper} bgcolor={inviteMediaUrl ? '#F4F3FF' : ''}>
        {!loading && (
          <HtmlTooltip title="Add Image" rounded="5px" padding="5px" light={true}>
            <IconButton
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              <AddImageIcon color={inviteMediaUrl ? theme.palette.primary.main : '#000'} />
            </IconButton>
          </HtmlTooltip>
        )}

        {loading && (
          <Box className={classes.spinnerWrapper}>
            <CircularProgress className={classes.spinner} />
          </Box>
        )}

        {inviteMediaUrl && (
          <HtmlTooltip title="Remove Image" rounded="5px" padding="5px" light={true}>
            <IconButton
              className={classes.removeButton}
              onClick={() => {
                handleRemoveImage();
              }}
            >
              <RemoveImageIcon />
            </IconButton>
          </HtmlTooltip>
        )}
      </Box>

      <input
        type="file"
        accept="image/png, image/jpeg, image/svg+xml"
        ref={inputRef}
        onChange={handleInputChange}
        style={{ display: 'none' }}
      />
    </>
  );
};
