import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputsWrapper: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '640px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '24px 0',
  },
  closeIcon: {
    position: 'absolute',
    top: '25px',
    right: '22px',
    width: '24px',
    color: '#667085',
    cursor: 'pointer',
    display: 'none',
  },
  title: {
    color: '#101828',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px',
    textAlign: 'center',
  },
  fieldsWrapper: {
    padding: '20px 48px 0 48px',
    borderTop: '1px solid #EAECF0',
    borderRight: 0,
    borderLeft: 0,
  },
  select: {
    width: '400px',
    height: '44px',
    background: '#fff',
    marginLeft: '56px',
    marginRight: '20px',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& svg': {
      marginRight: '10px',
      width: '20px',
    },
    '& .MuiSelect-select': {
      color: '#667085',
      fontSize: '16px',
      fontWeight: '400',
    },
  },
  menuWrapper: {
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
    transform: 'translate(0px, 103px) !important',
    height: '100px',
    overflow: 'auto',
    zoom: '0.9',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px',
    },
  },
  selectItem: {
    background: 'white !important',
    padding: '10px 30px',
    color: 'gray',
    '&:hover': {
      background: '#F5F6F8 !important',
    },
  },
  dropdownIcon: {
    top: '9px',
    right: '7px',
  },
  dropdownIconOpen: {
    top: '7px',
    right: '20px',
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },
}));
