import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 9,
    top: '45%',
  },

  title: {
    color: theme.palette.primary.main,
    background: '#EBE9FF',
    borderRadius: '8px',
    padding: '5px 6px',
    lineHeight: '50px',
    fontWeight: 600,
    fontSize: '38px',
    textAlign: 'center',
  },
  subtitle: {
    color: '#475467',
    padding: '5px 6px',
    lineHeight: '30px',
    fontWeight: 400,
    fontSize: '20px',
    textAlign: 'center',
    marginTop: '24px',
  },
}));
