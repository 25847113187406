import { FC } from 'react';
import { Grid, Box, Typography, IconButton, Dialog, DialogContent, Button } from '@mui/material';
import { useFTEmodalStyles } from './FTEModal.style';
import { navItems } from '../WebappSettings.helper';
import { WebAppPageStrings } from '../../../common/localization/en';
import { WelcomeLogoModal } from '../../../common/assets/newDesign/WebAppIcons/WelcomeLogoModal';
import PreviewiPhone from '../../../common/assets/newDesign/PreviewiPhone-2.png';
import { useClientType } from '../../../services/hooks/useClientType';

interface IFTEModalProps {
  openModal: boolean;
  toggleModal: (open: boolean) => void;
}

export const FTEModal: FC<IFTEModalProps> = ({ openModal, toggleModal }) => {
  const classes = useFTEmodalStyles();
  const handleCloseModal = () => {
    toggleModal(!openModal);
  };
  const { isHealthCare } = useClientType();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleCloseModal}
      open={openModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <Grid container>
          <Grid item xs={7}>
            <Grid container flexDirection="row" className={classes.wrapperMainContent}>
              <Grid item container alignItems="center" justifyContent="space-between">
                <Grid item xs={2}>
                  <WelcomeLogoModal />
                </Grid>
              </Grid>
              <Grid item className={classes.body}>
                <Typography mt="25px" variant="h3">
                  {WebAppPageStrings.SayHello}
                </Typography>
                <Typography className={classes.textModal}>
                  {isHealthCare
                    ? WebAppPageStrings.ModalContentBlock1_TruBlu
                    : WebAppPageStrings.ModalContentBlock1}
                </Typography>
                <br />
                <Typography className={classes.textModal}>
                  {WebAppPageStrings.ModalContentBlock2}
                </Typography>
                <Grid
                  container
                  flexDirection="row"
                  justifyContent="start"
                  className={classes.iconsSection}
                >
                  {navItems.map((item) => (
                    <Grid mr="40px" key={item.title} item>
                      <IconButton className={classes.icon}>{item.icon}</IconButton>
                      <Typography className={classes.iconTitle}>{item.title}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <Button variant="contained" className={classes.button} onClick={handleCloseModal}>
                  <Typography variant="body2">{WebAppPageStrings.LetsGo}</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Box className={classes.wrapperEducationCards}>
              <img className={classes.img} src={PreviewiPhone} alt="Preview" />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
