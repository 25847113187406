import { FC } from 'react';
import { AccordionNumbered } from '../../../../../common/components/AccordionNumbered/AccordionNumbered';

interface AccordionProps {
  children?: JSX.Element;
  sectionsExpanded: boolean;
  onAccordionToggle: () => void;
  title: string;
  tooltip: string;
  showDivider?: boolean;
}

export const Accordion: FC<AccordionProps> = ({
  children,
  sectionsExpanded,
  onAccordionToggle,
  title,
  tooltip,
  showDivider = true,
}) => {
  return (
    <AccordionNumbered
      margin="0"
      /*    number={1} */
      title={title}
      isExpanded={sectionsExpanded}
      toggleAccordion={onAccordionToggle}
      tooltip={tooltip}
      fullLabel
      fullWidth
      marginTop={'14px'}
      showDivider={showDivider}
    >
      {children}
    </AccordionNumbered>
  );
};
