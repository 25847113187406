import { FC } from 'react';
import { Box, Icon } from '@mui/material';
// incons

import { LinkedinIcon } from '../../../../common/assets/LinkedinIcon';
import { FacebookIcon } from '../../../../common/assets/FacebookIcon';
import InstagramIcon from '@mui/icons-material/Instagram';
import { PreviewContainer } from '../previews.style';
import { IphoneXframeDeprecated } from '../phonePreviewWrapper';
import { ContentWrapper } from './StoryCardPreview.style';
import { SettingsPageStrings } from '../../../localization/en';
import { useStyles } from './StoryCardPreview.helper';
import { ButtonsTypes } from '../../../../pages/webappSettings/VideoSharing/VideoSharing.helper';
import { SharingOrder, SharingSettings } from '../../../../api/models/venue';
import { useTypedSelector } from '../../../../store';
import { VideoFilterApiModel } from '../../../../api/models/filters';
/* import { DownloadIcon } from '../../../assets/shareAssets/DownloadIcon'; */
import { LinkNewIcon } from '../../../assets/shareAssets/LinkNewIcon';
import { theme } from '../../../../theme';
import { SMSIcon } from '../../../assets/shareAssets/SMSIcon';
import { TwitterIcon } from '../../../assets/shareAssets/TwitterIcon';
import { EmailIcon } from '../../../assets/shareAssets/EmailIcon';

interface EditIconProps {
  color: string;
}

export const EditIcon: FC<EditIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8495 11.9106V17.832C18.8495 18.9366 17.9541 19.832 16.8495 19.832H6C4.89543 19.832 4 18.9366 4 17.832V6.98245C4 5.87788 4.89543 4.98245 6 4.98245H12.1676"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0956 3.70711C17.4862 3.31658 18.1193 3.31658 18.5098 3.70711L20.1248 5.32204C20.5153 5.71257 20.5153 6.34573 20.1248 6.73625L13.808 13.0531C13.6627 13.1983 13.4762 13.2953 13.2739 13.3309L10.6125 13.7983C10.271 13.8583 9.97356 13.5609 10.0335 13.2194L10.501 10.558C10.5365 10.3557 10.6335 10.1692 10.7788 10.0239L17.0956 3.70711Z"
          stroke={color}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

interface StoryCardPreviewProps {
  brandColor?: string;
  textColor?: string;
  ctaBtnText: string;
  videoText: string;
  sharingSettings: SharingSettings;
  activeFilter?: VideoFilterApiModel;
  mappedOrderedSharingList: SharingOrder[];
  editable: boolean;
  toggleEdit: () => void;
}

export const ShareCardPreview: FC<StoryCardPreviewProps> = ({
  sharingSettings,
  mappedOrderedSharingList,
  editable,
  toggleEdit,
}) => {
  const classes = useStyles();
  const { subdomain } = useTypedSelector((state) => state.venue.venue);
  const webappUrl = `https://${subdomain}${SettingsPageStrings.DomainName}`;
  const icons = [
    {
      name: 'Message' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <SMSIcon />
        </div>
      ),
    },
    {
      name: 'Email' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <EmailIcon />
        </div>
      ),
    },
    {
      name: 'LinkedIn' as ButtonsTypes,
      component: <LinkedinIcon />,
    },
    {
      name: 'Link' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <LinkNewIcon />
        </div>
      ),
    },
    {
      name: 'Facebook' as ButtonsTypes,
      component: <FacebookIcon />,
    },
    {
      name: 'Instagram' as ButtonsTypes,
      component: <InstagramIcon />,
    },
    {
      name: 'Twitter' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <TwitterIcon />
        </div>
      ),
    },
    /*  {
      name: 'Download' as ButtonsTypes,
      component: <DownloadIcon />,
    }, */
  ];

  return (
    <PreviewContainer>
      <IphoneXframeDeprecated color="black">
        <ContentWrapper>
          {/* <MenuDivWrapper>
            <MenuBar />
          </MenuDivWrapper> */}
          {/* <EduTitleWrapper>
            <EduTitle color={textColor || 'white'}>{StoryCardPreviewStrings.Title}</EduTitle>
          </EduTitleWrapper> */}
          {/* <Box
            paddingX={1}
            position="relative"
            style={{ background: 'rgba(69, 69, 69, 0.8)', borderRadius: '16px', zIndex: 4 }}
          > */}
          <Box
            component="img"
            zIndex={4}
            borderRadius={2}
            src="/non-tg-vide-sharing-preview-bg.jpg"
            alt="preview"
          />
          {/* <Box>
              <p style={{ color: 'grey', zIndex: 4, textAlign: 'center' }}>Share With</p>
            </Box> */}
          {/* </Box> */}
          <p className={classes.webAppUrl} style={{ zIndex: 4 }}>
            {webappUrl}
          </p>
          <Box
            alignItems="center"
            width="100%"
            borderRadius="8px"
            marginTop="25px"
            style={{
              backgroundColor: '#1C1D22',
              display: 'flex',
              alignSelf: 'center',
              flexWrap: 'wrap',
              width: '80%',
              top: '310px',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {mappedOrderedSharingList?.length &&
              icons
                .filter((item) => sharingSettings && sharingSettings[item.name])
                .slice()
                .sort((a, b) => {
                  const first =
                    mappedOrderedSharingList[
                      mappedOrderedSharingList.findIndex(
                        (item) => Object.entries(item)[0][0] === a.name,
                      )
                    ];
                  const second =
                    mappedOrderedSharingList[
                      mappedOrderedSharingList.findIndex(
                        (item) => Object.entries(item)[0][0] === b.name,
                      )
                    ];

                  return Object.entries(first)[0][1] > Object.entries(second)[0][1] ? 1 : -1;
                })
                .map((item) => {
                  return <div className={classes.nonTGiconContainer}>{item.component}</div>;
                })}
          </Box>
          <Icon
            onClick={toggleEdit}
            className={`${classes.EditIcon} ${
              editable ? classes.EditIconActive : classes.EditIconInactive
            }`}
          >
            <EditIcon color={editable ? theme.palette.common.white : theme.palette.primary.main} />
          </Icon>
        </ContentWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
