import { FC } from 'react';

type ICrownIconProps = {
  color?: string;
};

export const CrownIcon: FC<ICrownIconProps> = ({ color }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2266 16.8358H5.7659C5.38786 16.8358 4.96483 16.5388 4.83882 16.1788L1.11251 5.75591C0.581463 4.26179 1.20252 3.80275 2.48062 4.72083L5.99091 7.23204C6.57596 7.63707 7.24202 7.43005 7.49404 6.773L9.07815 2.55165C9.5822 1.20153 10.4193 1.20153 10.9233 2.55165L12.5074 6.773C12.7595 7.43005 13.4255 7.63707 14.0016 7.23204L17.2958 4.88284C18.7 3.87476 19.375 4.3878 18.799 6.01694L15.1627 16.1968C15.0277 16.5388 14.6046 16.8358 14.2266 16.8358Z"
      stroke={color || '#FFC955'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.5"
      d="M5.0459 19.5547H14.9467"
      stroke={color || '#FFC955'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.74609 12.3535H12.2465"
      stroke={color || '#FFC955'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
