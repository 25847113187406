import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RewardFulfillmentEndpoints } from '../../api/endpoints';
import {
  GetRewardFulfillmentConfigResponse,
  PostRewardFulfillmentConfigRequest,
  PostRewardFulfillmentConfigResponse,
  PostRewardFulfillmentTestSMSRequest,
  PostRewardFulfillmentTestWebhookRequest,
  RewardFulfillmentConfig,
  RewardFulfillmentMethod,
} from '../../api/models/rewardFulfillment';
import { httpClient } from '../../services/httpClient/httpClient';

interface RewardFulfillmentInitialState {
  config: RewardFulfillmentConfig;
  isFetched: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialState: RewardFulfillmentInitialState = {
  config: {
    method: RewardFulfillmentMethod.AUTOMATIC_SMS,
    sms: {
      mediaUrl: '',
      body: '',
      parameters: {},
    },
    webhook: {
      webhookUrl: '',
      validationKey: '',
    },
  },

  /**
   * Allows us to check if there was a try to fetch the config
   */
  isFetched: false,
  isLoading: false,
  isError: false,
};

export const getRewardFulfillmentConfig = createAsyncThunk(
  'rewardFulfillment/getRewardFulfillmentConfig',
  async (options: { venueId: string }, { rejectWithValue }) => {
    try {
      return httpClient.get<{}, GetRewardFulfillmentConfigResponse>({
        url: RewardFulfillmentEndpoints.GetRewardFulfillmentConfig,
        requiresToken: true,
        params: {
          venueId: options.venueId,
        },
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postRewardFulfillmentConfig = createAsyncThunk(
  'rewardFulfillment/postRewardFulfillmentConfig',
  async (options: PostRewardFulfillmentConfigRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<
        RewardFulfillmentConfig | { venueId: string },
        PostRewardFulfillmentConfigResponse
      >({
        url: RewardFulfillmentEndpoints.PostRewardFulfillmentConfig,
        requiresToken: true,
        payload: options.payload,
        params: {
          venueId: options.venueId,
        },
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postRewardFulfillmentTestWebhook = createAsyncThunk(
  'rewardFulfillment/postRewardFulfillmentTestWebhook',
  async (options: PostRewardFulfillmentTestWebhookRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<
        PostRewardFulfillmentTestWebhookRequest['payload'] | { venueId: string },
        { success: boolean }
      >({
        url: RewardFulfillmentEndpoints.PostRewardFulfillmentTestWebhook,
        requiresToken: true,
        payload: options.payload,
        params: {
          venueId: options.venueId,
        },
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postRewardFulfillmentTestSMS = createAsyncThunk(
  'rewardFulfillment/postRewardFulfillmentTestSMS',
  async (options: PostRewardFulfillmentTestSMSRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<
        PostRewardFulfillmentTestSMSRequest['payload'] | { venueId: string },
        { success: boolean }
      >({
        url: RewardFulfillmentEndpoints.PostRewardFulfillmentTestSMS,
        requiresToken: true,
        payload: options.payload,
        params: {
          venueId: options.venueId,
        },
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const rewardFulfillmentSlice = createSlice({
  name: 'rewardFulfillment',
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getRewardFulfillmentConfig.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getRewardFulfillmentConfig.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
      state.isFetched = true;
      state.config = initialState.config;
    });
    reducersBuilder.addCase(getRewardFulfillmentConfig.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.isFetched = true;
      state.config = payload;
    });

    reducersBuilder.addCase(postRewardFulfillmentConfig.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentConfig.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentConfig.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.isFetched = true;
      state.config = payload;
    });

    reducersBuilder.addCase(postRewardFulfillmentTestWebhook.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentTestWebhook.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentTestWebhook.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
    });

    reducersBuilder.addCase(postRewardFulfillmentTestSMS.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentTestSMS.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postRewardFulfillmentTestSMS.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
    });
  },
});

export const { reset } = rewardFulfillmentSlice.actions;

export default rewardFulfillmentSlice.reducer;
