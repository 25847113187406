import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: 'fit-content',
    height: 'fit-content',
  },
  input: {
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    width: '315px',
    height: '44px',
    paddingLeft: '40px',
    fontSize: '14px',
    lineHeight: '24px',
  },
  searchIcon: {
    position: 'absolute',
    top: '12px',
    left: '12px',
  },
  resetSearchIcon: {
    position: 'absolute',
    top: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  openSearchButton: {
    position: 'absolute',
    right: 0,
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  searchPopUpWrapper: {
    position: 'relative',
    width: '100%',
    bottom: '-1px',
  },
  predictiveItemsLoader: {
    position: 'absolute',
    top: '12px',
  },
}));
