import { useTypedSelector } from '../store';

export const useIsFteFinished = () => {
  const lastFTESection = { section: 'webapp', tab: 'webapp_sharing' };
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  return venueProperties?.['webapp.firstTimeOnboardingFinished.config']
    ? Object.keys(venueProperties?.['webapp.firstTimeOnboardingFinished.config'] || {})?.length >=
        3 &&
        Object.entries(venueProperties?.['webapp.firstTimeOnboardingFinished.config'] || {}).some(
          ([configName, configValue]) =>
            configName === lastFTESection.section && configValue[lastFTESection.tab],
        )
    : false;
};
