import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  root: {
    boxShadow: 'none',
  },
  paper: {
    boxShadow: 'none',
  },
  labelText: {
    fontSize: '16px',
    fontWeight: '700',
    // letterSpacing: '0.02em',
    color: '#1A1538',
    lineHeight: '30px',
  },
  content: {
    margin: '16px 0',
  },
  expanded: {
    '&:before': {
      opacity: '1 !important',
    },
  },
  icon: {
    '& svg': {
      width: '30px',
      height: '30px',
    },
    marginRight: '16px',
    height: '30px',
  },
  iconSmall: {
    '& svg': {
      width: '28px',
      height: '28px',
    },
  },
}));
