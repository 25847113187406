import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import isEqual from 'lodash/isEqual';
import { FC, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import {
  CommonStrings,
  SettingsInvitesPageString,
  SmsInviteStrings,
  WebAppSignUpConfigStrings,
} from '../../../common/localization/en';
import { useModal } from '../../../hooks/useModal';
import { theme } from '../../../theme';
import { WelcomeModal } from '../WelcomeModal/WelcomeModal';
import { MessagePreview } from './MessagePreview';
import {
  convertApiModelToFormValues,
  convertFormValuesToApiPayload,
  useClasses,
  validationSchemaSmsInviteInvite,
} from './SmsInvite.helper';
import { PurchaseType, SMSInviteFormValues } from './types';
import { useSMSInviteApiCalls } from '../../../services/hooks/useSMSInviteApiCalls';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { useWebAppLink } from '../../../hooks/useWebAppLink';
import { useVenue } from '../../../hooks/useVenue';
import { useClientType } from '../../../services/hooks/useClientType';
import { constructMessageFromTemplate } from '../../../common/components/SMS/SMS.helper';
import { Accordion } from './Sections/AccordionSection/Accordion';
import { SmsEditor } from './Sections/AccordionSection/SmsEditor';
import { SendTestMessageModal } from '../../../common/components/SMS/SendTestMessageModal';
import { AddUsersManually } from './Sections/ImportUsers/AddUsersManually/AddUsersManually';

import { getMe } from '../../../store/slices/meSlice';
import { ImportExcel } from './Sections/ImportUsers/ImportExcel/ImportExcel';
import { getActiveIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import { useSendTestSms } from '../../../services/hooks/useSendTestSms';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { getProperties, patchVenueProperties } from '../../../store/slices/venueSlice';
import { FieldSetting } from '../../../common/components/FieldSetting/FieldSetting';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

interface SectionTitleProps {
  title: string;
  tooltip: string;
}

export interface stateHistoryType {
  from: string;
}
export const SectionTitle: FC<SectionTitleProps> = ({ title, tooltip }) => {
  return (
    <Box display="flex" alignItems="center" mb="24px">
      <Typography fontWeight={500} fontSize="18px" color={theme.palette.common.deepDark}>
        {title}
      </Typography>
      <Tooltip title={tooltip} />
    </Box>
  );
};

/**
 * Post message -> Past Purchase
 * Pre message -> Future Purchase
 */

enum ActiveSection {
  MobilePreview,
  DocumentImport,
  ManualImport,
}

export const SmsInvite: FC = () => {
  const [previewTab] = useState<PurchaseType | undefined>(PurchaseType.PastPurchase);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const classes = useClasses();

  const { isLoading: isIncentiveLoading } = useVenueProperty<
    VenueProperties['webapp.incentive.config']
  >({
    property: 'webapp.incentive.config',
  });

  const { state } = useLocation<stateHistoryType>();

  const {
    value: FTEValues,
    update: updateFTEValues,
    isLoading: isFTEValuesLoading,
  } = useVenueProperty<VenueProperties['webapp.firstTimeOnboardingFinished.config']>({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });

  const { isHealthCare } = useClientType();

  const { webAppLink } = useWebAppLink();

  const showFTEModal = useMemo(() => (isFTEValuesLoading ? false : !FTEValues?.invites?.modal), [
    isFTEValuesLoading,
    FTEValues,
  ]);

  const [activeSection, setActiveSection] = useState<ActiveSection>(ActiveSection.DocumentImport);

  useEffect(() => {
    if (state && state.from === WebsiteRoutes.RewardCampaigns) {
      setActiveSection(ActiveSection.DocumentImport);
    }
  }, [state]);

  const {
    isOpen: showWelcomeModal,
    toggle: toggleWelcomeModal,
    close: closeWelcomeModal,
  } = useModal(showFTEModal, {
    updateOnDefaultValueChange: true,
  });

  const handleCloseModal = () => {
    if (showFTEModal) {
      updateFTEValues({
        ...FTEValues,
        invites: {
          ...FTEValues?.invites,
          modal: true,
        },
      });
    }
    closeWelcomeModal();
  };

  const {
    updateMessagingConfig,
    fetchMessageVariables,
    fetchMessagingConfig,
  } = useSMSInviteApiCalls();

  const {
    messageConfig: storeMessageConfig,
    isConfigLoading,
    isVariablesLoading,
    // messageVariables: availableMessageVariables,
  } = useTypedSelector((store) => store.smsInvitesSlice);

  const { venue } = useTypedSelector((state) => state.venue);
  const me = useTypedSelector((state) => state.me);

  useEffect(() => {
    dispatch(getMe({ id: me.id }));
  }, [me.id, dispatch]);

  const initialMediaUrl = '';

  const { addToast } = useToasts();

  const { isLoading: isVenueLoading, venueProperties, isLoaded } = useVenue({
    onVenueLoad: () => {
      dispatch(getActiveIncentiveCampaign(venue.id));
      fetchMessageVariables();
      fetchMessagingConfig();
    },
  });

  const isEmailRequired = venueProperties?.['webapp.signUp.config']
    ? venueProperties?.['webapp.signUp.config'].emailRequired
    : false;

  const [emailRequired, setEmailRequired] = useState<boolean>(!!isEmailRequired);
  useEffect(() => {
    if (isLoaded) setEmailRequired(isEmailRequired!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueProperties]);

  const initialValues = useMemo<SMSInviteFormValues>(() => {
    return convertApiModelToFormValues(storeMessageConfig, initialMediaUrl, isHealthCare, true);
  }, [initialMediaUrl, storeMessageConfig, isHealthCare]);

  const {
    values,
    setFieldValue,
    resetForm,
    submitForm,
    isSubmitting,
    isValid,
  } = useFormik<SMSInviteFormValues>({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchemaSmsInviteInvite,
    onSubmit: async (data) => {
      if (!data.postMessage.body.trim()) {
        /*  data.preMessage.body = isTrubluClient
            ? SmsInviteStrings.DefaultPreMessage_TruBlu
            : SmsInviteStrings.DefaultPreMessage; */
        data.postMessage.body = isHealthCare
          ? SmsInviteStrings.DefaultPostMessage_TruBlu
          : SmsInviteStrings.DefaultPostMessage;
      }

      const config = convertFormValuesToApiPayload(data, venue.name, true);

      await updateMessagingConfig(config);
    },
  });

  const isFormTouched = useMemo(() => {
    return !isEqual(initialValues, values) || emailRequired !== isEmailRequired;
  }, [initialValues, values, emailRequired, isEmailRequired]);

  const templateVariables = useSmsTemplateVariables();

  // Update preview tab on sections switch toggle
  /* useEffect(() => {
    const previewTabIsNotSet =
      !previewTab && [values.postMessage.enabled, values.preMessage.enabled].some((v) => v);
    const onlyOnePurchaseTypeEnabled = values.preMessage.enabled !== values.postMessage.enabled;

    if (previewTabIsNotSet || onlyOnePurchaseTypeEnabled) {
      setPreviewTab(
        values.preMessage.enabled ? PurchaseType.FuturePurchase : PurchaseType.PastPurchase,
      );
    }
    if (!values.postMessage.enabled && !values.preMessage.enabled) {
      setPreviewTab(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.postMessage.enabled, values.preMessage.enabled]); */

  const handleActionBarSave = async () => {
    await submitForm();
    dispatch(
      patchVenueProperties({
        id: venue.id,
        update: {
          name: 'webapp.signUp.config',
          value: {
            ...{
              phoneRequired: true,
              nameRequired: true,
            },
            emailRequired: emailRequired,
          },
        },
        alert: true,
      }),
    ).then(() => {
      dispatch(getProperties({ id: venue.id }));
      addToast(CommonStrings.OnSuccess, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      trackEvent(SettingsEvents.invites_save_button_click);
    });
  };

  /*   const constructedTestPreMessage = constructMessageFromTemplate(
    values.preMessage.body,
    templateVariables,
    true,
  ); */
  const constructedTestPostMessage = constructMessageFromTemplate(
    values.postMessage.body,
    templateVariables,
    true,
  );

  // const showBottomActionBar = isFormTouched;

  const isInitialLoading =
    isConfigLoading ||
    isVariablesLoading ||
    isVenueLoading ||
    isIncentiveLoading ||
    isFTEValuesLoading;

  const isLoading = isSubmitting;

  const initialAccordionValues =
    state && state.from === WebsiteRoutes.RewardCampaigns
      ? { invite: true, upload: true }
      : { invite: true, upload: true };

  const [sectionsExpanded, setSectionsExpanded] = useState(initialAccordionValues);

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const sendTestSMS = useSendTestSms();

  return (
    <>
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          closeTestMessageModal();
          trackEvent(SettingsEvents.invites_sample_sms_modal_closed);
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={({ message, phone }) => {
          sendTestSMS({ message, phone });
          trackEvent(SettingsEvents.invites_sample_sms_send);
        }}
        phoneNumber={me.phoneNumber}
        name={me.name}
      />
      <TwoSidePageLayout
        addPaddingForBottomBar={isFormTouched}
        loading={isInitialLoading}
        onHelpButtonClick={() => {
          toggleWelcomeModal();
          trackEvent(SettingsEvents.invites_welcome_modal_open);
        }}
        leftSideColumnWidths={5.5}
        leftSideDescription={
          isHealthCare ? SmsInviteStrings.TBSectionDescription : SmsInviteStrings.SectionDescription
        }
        leftSideDescriptionClassName={classes.leftSideDescription}
        rightSideTop="20px"
        leftSideNode={
          <Grid mt={-1}>
            <Box mt="10px" mb="10px">
              <FieldSetting
                title={WebAppSignUpConfigStrings.EmailTitle}
                description={
                  isHealthCare
                    ? WebAppSignUpConfigStrings.EmailDescription_TruBlu
                    : WebAppSignUpConfigStrings.EmailDescription
                }
                tooltip={
                  isHealthCare
                    ? WebAppSignUpConfigStrings.EmailTooltip_TruBlu
                    : WebAppSignUpConfigStrings.EmailTooltip
                }
                value={emailRequired!}
                onChange={(v) => {
                  setEmailRequired(v);
                  trackEvent(SettingsEvents.email_required_set, { value: v + '' });
                }}
              />
            </Box>
            <Divider />
            <Accordion
              title={
                isHealthCare
                  ? SmsInviteStrings.UploadPatientData
                  : SmsInviteStrings.UploadCustomerData
              }
              sectionsExpanded={sectionsExpanded.upload}
              onAccordionToggle={() => {
                setActiveSection(ActiveSection.DocumentImport);

                setSectionsExpanded({
                  invite: sectionsExpanded.invite,
                  upload: sectionsExpanded.upload,
                });
                if (isFormTouched) {
                  handleActionBarSave();
                }
              }}
              tooltip={
                isHealthCare
                  ? SettingsInvitesPageString.TooltipUpload.replace('customers', 'patients')
                  : SettingsInvitesPageString.TooltipUpload
              }
              showDivider={false}
            >
              <Grid xs={12}>
                {/*       <Typography
                  fontSize={14}
                  color={theme.palette.common.gray}
                  mb="24px"
                  letterSpacing="0.02em"
                >
                  {isTrubluClient
                    ? SmsInviteStrings.UploadDescriptionTB
                    : SmsInviteStrings.UploadDescription}
                </Typography>*/}

                <Grid container spacing={1} mt={1}>
                  <Grid item xs={5.5}>
                    <Button
                      fullWidth
                      color="primary"
                      variant={
                        activeSection === ActiveSection.DocumentImport ? 'contained' : 'outlined'
                      }
                      size="large"
                      sx={{ borderRadius: '100px', fontWeight: 400 }}
                      onClick={() => {
                        setActiveSection(ActiveSection.DocumentImport);
                        trackEvent(SettingsEvents.invites_import_file_button_click);
                      }}
                    >
                      {SmsInviteStrings.ImportExcel}
                    </Button>
                  </Grid>
                  <Grid item xs={6.5}>
                    <Button
                      fullWidth
                      color={'primary'}
                      variant={
                        activeSection === ActiveSection.ManualImport ? 'contained' : 'outlined'
                      }
                      size="large"
                      sx={{
                        borderRadius: '100px',
                        fontSize: '14px',
                        fontWeight: 400,
                        letterSpacing: '0.02em',
                      }}
                      onClick={() => {
                        setActiveSection(ActiveSection.ManualImport);
                        trackEvent(SettingsEvents.invites_import_manually_button_click);
                      }}
                    >
                      {isHealthCare
                        ? SmsInviteStrings.ImportManuallyTB
                        : SmsInviteStrings.ImportManually}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Accordion>
            <Box
              marginTop={
                activeSection === ActiveSection.DocumentImport ||
                activeSection === ActiveSection.MobilePreview
                  ? '50px'
                  : '500px'
              }
            >
              <Accordion
                title={
                  isHealthCare
                    ? SmsInviteStrings.PatientSmsInvites
                    : SmsInviteStrings.CustomersSmsInvites
                }
                sectionsExpanded={sectionsExpanded.invite}
                onAccordionToggle={() => {
                  setSectionsExpanded({
                    upload: sectionsExpanded.upload,
                    invite: sectionsExpanded.invite,
                  });
                  setActiveSection(ActiveSection.MobilePreview);
                }}
                tooltip={
                  isHealthCare
                    ? SettingsInvitesPageString.TooltipInvite.replace('customers', 'patients')
                    : SettingsInvitesPageString.TooltipInvite
                }
              >
                <Grid xs={12}>
                  <SmsEditor
                    title={SmsInviteStrings.PostPurchaseInvite}
                    description={
                      isHealthCare
                        ? SmsInviteStrings.TBPastPurchaseDescription
                        : SmsInviteStrings.PastPurchaseDescription
                    }
                    messageLabel={SmsInviteStrings.MessageTemplateLabel}
                    message={values.postMessage.body}
                    onMessageChange={(message) => {
                      setFieldValue('postMessage.body', message);
                      trackEvent(SettingsEvents.invites_sms_input_changed, {
                        value: values.postMessage.body,
                      });
                    }}
                    templateVariables={templateVariables}
                    disabled={!values.postMessage.enabled}
                    onSendTestClick={() => {
                      openTestMessageModal();
                      trackEvent(SettingsEvents.invites_open_sample_sms_modal_button_click);
                    }}
                  />
                </Grid>
              </Accordion>
            </Box>
          </Grid>
        }
        rightSideNode={
          <Box minHeight="700px">
            {(activeSection === ActiveSection.DocumentImport ||
              activeSection === ActiveSection.MobilePreview) && <ImportExcel />}
            {activeSection === ActiveSection.ManualImport && <AddUsersManually />}

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              marginTop={activeSection === ActiveSection.ManualImport ? '20px' : '0'}
            >
              <Box className={classes.SMSSample}>
                <Typography>{SmsInviteStrings.SMSSampleText}</Typography>
              </Box>

              <MessagePreview
                message={constructedTestPostMessage}
                image={''}
                linkTitle={webAppLink}
                disabled={!previewTab}
                showStartBlock={false}
                boldSubstrings={Object.values(templateVariables)}
              />
            </Box>
          </Box>
        }
      />
      <WelcomeModal isModalOpen={showWelcomeModal} toggleIsWelcomeModal={handleCloseModal} />
      <BottomActionBar
        show={isFormTouched && !isInitialLoading}
        onSaveClick={handleActionBarSave}
        onCancelClick={() => {
          resetForm();
          setEmailRequired(isEmailRequired!);
          trackEvent(SettingsEvents.invites_cancel_button_click);
        }}
        disableSaveButton={!isValid}
        loading={isLoading}
      />
      <UnsavedChangesModal isUnsavedChangesOnPage={isFormTouched} />
    </>
  );
};
