import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useEditCampaignModalStyles = makeStyles((theme: Theme) => ({
  Container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '745px',
    outline: 'none',
  },
  CloseButton: {
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    width: '50px',
    height: '50px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
