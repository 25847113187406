import { FC } from 'react';

interface RewardsCircleProps {
  color?: string;
}
export const RewardsCircle: FC<RewardsCircleProps> = ({ color = '#2DA7E5' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="none" viewBox="0 0 49 49">
      <path
        fill={color}
        fillRule="evenodd"
        d="M24.673.001H24.5C10.969 0 0 10.97 0 24.5 0 38.03 10.969 49 24.5 49c13.152 0 23.883-10.363 24.474-23.37l.026-.005V0H24.672v.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
