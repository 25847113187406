import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <path
      stroke="#101828"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19.5 13v1.2c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C17.22 19 16.38 19 14.7 19H6.3c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C1.5 16.72 1.5 15.88 1.5 14.2V13m14-5-5 5m0 0-5-5m5 5V1"
    />
  </svg>
);
export default SvgComponent;
