import { FC, ReactNode, useEffect } from 'react';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties, VideoAppTheme } from '../../../api/models/venue';
import { useHistory, useLocation } from 'react-router-dom';
import { httpClient } from '../../../services/httpClient/httpClient';
import { UnprotectedRoutes, WebsiteRoutes } from '../../constants/routes';
import {
  filterLogoPositions,
  uploadOverlay,
} from '../../../pages/webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { DEFAULT_UWM_BRAND_COLOR } from '../../constants/defaults';
import { useClientType } from '../../../services/hooks/useClientType';
import { useFTE } from '../../../hooks/useFTE';
import { useTypedSelector } from '../../../store';

interface Props {
  children?: ReactNode;
}

export const QuickStartHandler: FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const isLoggedIn = httpClient.isLoggedIn();
  const { isUwmClient } = useClientType();
  const { onFinishFte, isFteFinished } = useFTE();

  const {
    venue: { id: venueId, logo, isLoading: isVenueLoading, setupCompleted },
  } = useTypedSelector((state) => state.venue);

  const { value: quickStartValues, isLoading: isPropertiesLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const { value, update } = useVenueProperty<VenueProperties['webapp.config']>({
    property: 'webapp.config',
  });

  useEffect(() => {
    if (isUwmClient && !isFteFinished && !isVenueLoading && !isPropertiesLoading) {
      if (logo) {
        uploadOverlay(
          value?.['filter-overlay-url'] || '',
          logo,
          filterLogoPositions.topLeft,
          venueId,
          false,
        ).then((filterUrl) => {
          update(
            {
              'filter-logo-position': filterLogoPositions.topLeft,
              'filter-logo-enabled': true,
              'filter-overlay-enabled': false,
              'filter-overlay-url': '',
              'filter-url': filterUrl,
              'primary-color': value?.['primary-color'] || DEFAULT_UWM_BRAND_COLOR,
              theme: VideoAppTheme.Light,
              isThemeLayoutEnabled: true,
            },
            { patchObjectValue: true },
          );
        });
      }

      onFinishFte();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUwmClient, isFteFinished, isPropertiesLoading, isVenueLoading, logo, onFinishFte, venueId]);

  useEffect(() => {
    if (
      setupCompleted &&
      quickStartValues?.isQuickStartInProgress &&
      quickStartValues?.currentRoute &&
      !pathname.includes(WebsiteRoutes.QuickStart) &&
      !pathname.includes(WebsiteRoutes.Profile) &&
      !pathname.includes(WebsiteRoutes.PricingPlans) &&
      !pathname.includes(UnprotectedRoutes.SignUp) &&
      isLoggedIn &&
      !isPropertiesLoading
    ) {
      history.push(quickStartValues.currentRoute);
    }
  }, [history, pathname, quickStartValues, isLoggedIn, isPropertiesLoading, setupCompleted]);
  return <>{children}</>;
};
