import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { useVideosDetailPluginComponentStyles } from './VideosDetailPluginComponent.style';
import { ICheckedList } from './VideosDetailUserComponent';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideoStatusType } from '../../../api/models/searchFilterModels';

interface VideoDetailProps {
  listSocialVerse: ICheckedList[];
  handleRemoveVideoFromSV: (id: string) => void;
  handleAddVideoToCard?: (event: React.MouseEvent<HTMLButtonElement>, id: string) => void;
  storyID: string;
  storyStatus: string;
}

export const VideosDetailPluginComponent: FC<VideoDetailProps> = ({
  listSocialVerse,
  storyID,
  handleRemoveVideoFromSV,
  handleAddVideoToCard,
  storyStatus,
}) => {
  const styles = useVideosDetailPluginComponentStyles();
  const { trackEvent } = useTrackEvent();

  const history = useHistory();
  const canShowSocialVerses = listSocialVerse.some((item) => item.isChecked);
  const handleAddVideo = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (handleAddVideoToCard) {
      handleAddVideoToCard(event, storyID);
    }
  };

  const renderEmptyListSV = () => (
    <Grid item>
      <Paper elevation={0} className={styles.Paper}>
        {storyStatus === VideoStatusType.Moderated ? (
          <Box className={styles.moderatedWrapper}>
            <Typography className={styles.moderatedTitle}>
              {VideoDetailsModalStringsDv5.NoVideoAssignSV1}
            </Typography>
            <Typography className={styles.moderatedInfo}>
              {VideoDetailsModalStringsDv5.ModeratedInfo}
            </Typography>
          </Box>
        ) : listSocialVerse.length ? (
          <>
            <Typography sx={{ fontWeight: 'bold' }}>
              {VideoDetailsModalStringsDv5.NoVideoAssignSV1}
            </Typography>
            <Typography>{VideoDetailsModalStringsDv5.NoVideoAssignSV2}</Typography>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={styles.Button}
              onClick={handleAddVideo}
              disabled={!listSocialVerse.length}
            >
              {VideoDetailsModalStringsDv5.AddVideo}
            </Button>
          </>
        ) : (
          <Box display={'flex'} flexDirection={'column'}>
            <Typography>{VideoDetailsModalStringsDv5.NoSV}</Typography>
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={styles.Button}
              onClick={() => {
                history.push(WebsiteRoutes.SocialVerse);
              }}
            >
              {VideoDetailsModalStringsDv5.CreateSV}
            </Button>
          </Box>
        )}
      </Paper>
    </Grid>
  );

  const renderSocialVerseList = () => {
    return (
      <Grid item className={styles.PluginList}>
        {listSocialVerse
          .filter((i) => i.isChecked)
          .map((item) => (
            <Button
              key={item.id}
              onClick={() => handleRemoveVideoFromSV(item.id)}
              endIcon={<CloseRoundedIcon />}
              variant="outlined"
              className={styles.PluginBtn}
            >
              {item.name}
            </Button>
          ))}
      </Grid>
    );
  };
  return (
    <Grid container direction="column" className={styles.VideoPluginContainer}>
      <Grid
        container
        item
        direction="row"
        justifyContent="start"
        alignItems="center"
        className={styles.TitleContainer}
      >
        <Typography component="span" className={styles.Title}>
          {VideoDetailsModalStringsDv5.VideoSocialVerse}
        </Typography>
        {storyStatus !== VideoStatusType.Moderated && (
          <Button
            onClick={(event) => {
              handleAddVideo(event);
              trackEvent(SocialVerseEventNames.addToSocialVerse, { videoId: storyID });
            }}
          >
            {VideoDetailsModalStringsDv5.AddVideo}
          </Button>
        )}
      </Grid>
      {canShowSocialVerses ? renderSocialVerseList() : renderEmptyListSV()}
    </Grid>
  );
};
