import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';

export const useDownloadedVideoStyles = makeStyles((theme: Theme) => ({
  DisableColoration: {
    color: 'inherit',
    '&:visited': {
      color: 'inherit',
    },
    '&:hover': {
      color: 'inherit',
    },
    '&:active': {
      color: 'inherit',
    },
  },
  DownloadButtonWrapper: {
    marginBottom: '30px',
    stroke: 'white',
  },
  FilterQuestion: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    marginBottom: '20px',
  },
  NotesWrapper: {
    marginBottom: '10px',
  },
  NotesTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  NotesText: {
    color: theme.palette.common.black,
    fontSize: '14px',
  },
  StyledButton1: {
    borderRadius: '100px',
    width: 'fit-content',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
    '& .MuiButton-root': {
      width: '100%',
    },
  },
  imageWrapperForTopGolf: {
    maxWidth: '100px !important',
    height: '100px !important',
  },
  imageWrapper: {
    width: '70px !important',
    height: '100px !important',
  },
  imgChanger: {
    display: 'flex',
  },
  textWrapper: {
    paddingLeft: 25,
  },
  text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.common.gray,
  },
  Container: {
    display: 'grid',
    width: '100%',
    background: '#f5f6f8',
  },
  ShareSection: {
    backgroundColor: theme.palette.common.white,
    padding: '25px 30px',
  },
  SectionTitle: {
    color: '#8B89A0',
    fontSize: '14px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
  },
  Icon: {
    border: '1px solid #DCDBE4',
    borderRadius: '50%',
    fontSize: '14px',
    lineHeight: '26px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '13px',
  },
  SharingTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '30px',
  },
  EditOrderBtn: {
    marginLeft: 'auto',
    color: '#8B89A0',
    cursor: 'pointer',
  },
  IconsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: '50px',
    width: '100%',
  },
  IconWrapper: {
    cursor: 'pointer',
    marginRight: '35px',
    marginBottom: '25px',
  },
  Icons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    position: 'relative',
  },
  IconName: {
    fontSize: '12px',
    lineHeight: '24px',
    textAlign: 'center',
  },
  IconNameActive: {
    color: theme.palette.common.black,
  },
  IconNameInactive: { color: '#8B89A0' },
  DashedCircle: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.primary.main}`,
    background: theme.palette.primary.light,
    position: 'absolute',
    zIndex: -1,
  },
  input: {},
  group: {
    display: 'flex',
    columnGap: 24,
    marginTop: 32,
    marginBottom: 40,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    padding: 12,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    boxShadow: '0 2px 12px -2px rgba(7, 27, 134, 0.08)',
  },
  labelImg: {
    marginBottom: 12,
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: 5,

    '&:first-child': {
      backgroundColor: '#fee6dc',
    },
    '&:last-child': {
      backgroundColor: '#e4d8fc',
    },
  },
  radio: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  changeLogoButton: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    letterSpacing: 0.1,
    borderRadius: '40px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  desc: {
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
    color: '#8f94b6',
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 1.7,
  },
  bottomControllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    paddingLeft: '240px',
    paddingTop: '20px',
    zIndex: 100,
  },
  actionButton: {
    margin: '5px 2px',
    width: '120px',
  },
  inputLabel: {
    color: theme.palette.text.primary,
    marginBottom: '10px',
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
  },
  ColumnTitle: {
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: '400',
    color: theme.palette.grey[500],
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '30px',
  },
  HelperText: {
    color: theme.palette.text.secondary,
    fontSize: '15px',
    display: 'inline',
  },
  HelperTextEditable: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    display: 'inline',
  },
  BottomHint: {
    background: 'linear-gradient(0deg, #F9F9FC, #F9F9FC), #FFFFFF',
    height: '50px',
    justifyItems: 'center',
  },
  HintText: {
    color: theme.palette.text.secondary,
    fontSize: '17px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  Dot: {
    width: '26px',
    height: '26px',
    background: '#F7F8FA',
    borderRadius: '100px',
    margin: '0px 16px',
    color: theme.palette.primary.dark,
    textAlign: 'center',
    paddingLeft: '9px',
    paddingTop: '3px',
  },
  ShareItemWrapper: {
    padding: '20px 0px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  ShareItemsSection: {
    marginTop: '20px',
    marginLeft: '0',
    width: '100%',
  },
  SubItemLabel: {
    color: theme.palette.text.secondary,
    fontWeight: 600,
    fontSize: '15px',
    lineHeight: '26px',
    textTransform: 'capitalize',
    marginLeft: '60px',
    marginBottom: '20px',
  },
  labelAmount: {
    marginLeft: '0.7em',
    fontSize: '16px',
    lineHeight: '175%',
    color: theme.palette.text.secondary,
  },
  labelAmountError: {
    marginLeft: '0.7em',
    fontSize: '16px',
    lineHeight: '175%',
    color: theme.palette.error.main,
    marginBottom: '16px',
  },
  labelAmountMessage: {
    marginBottom: '16px',
    marginLeft: '0.7em',
    fontSize: '16px',
    lineHeight: '175%',
    color: theme.palette.text.secondary,
  },
  amountValue: {
    marginBottom: '8px',
  },
  Title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  SummaryWithTooltip:{
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 0',
    marginBottom: '10px',
  },
  Accordion:{
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  AccordionSummary:{
    display: 'flex',
    dlexDirection: 'row',
    paddingLeft: 0,
    paddingRight: 0,
  },
  Tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    marginLeft: '12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  Subtitle:{
    fontSize: '14px',
    lineHeight: '24px',
    color: '#8B89A0',
  },
  CancelButton1: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  new: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      backgroundColor: 'black',
      // height: '100px',
      height: '100vh',
      width: '2px',
    },
  },
  StageTitle: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  StageDescription: {
    textAlign: 'end',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.grey[500],
  },
  DisableAccordion: {
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },
  },
}));

export const validationSchema = () =>
  Yup.object().shape({
    editable: Yup.string().oneOf(['full', 'limited']),
    metaTitle: Yup.string()
      .max(82, 'Video title character limit: 82 characters')
      .required('Video title is required'),
    videoText: Yup.string()
      .min(5, 'Video text should be min 5 characters')
      .max(100, 'Video text max length 100 characters'),
    ctaBtnText: Yup.string()
      .max(24, 'Button text max length 24 characters')
      .required('Button text is required'),
    ctaBtnLinkUrl: Yup.string()
      .url('Enter valid url. E.g. https://orgName.socialvenu.com')
      .required('required'),
    videoMessage: Yup.string().max(82, 'Video text max length 82 characters'),
  });

export enum ButtonsTypes {
  Message = 'Message',
  Email = 'Email',
  Twitter = 'Twitter',
  Instagram = 'Instagram',
  Facebook = 'Facebook',
  Link = 'Link',
  LinkedIn = 'LinkedIn',
  Download = 'Download',
}


