import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
/* import { statItemsIdType } from './FilterItems/VideoStatsFilter'; */
interface IStyleProps {
  value: { min: number; max: number };
}

const useStyles = makeStyles<Theme, IStyleProps>((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignContent: 'center',
    background: 'transparent',
    borderRadius: '12px',
    padding: '5px 0px',
    height: '52px',
  },
  icon: {
    // width: '36px',
    width: 'auto',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputsRoot: {
    display: 'flex',
  },
  inputMin: (props) => {
    return {
      background: 'transparent',
      width: '60px',
      height: '40px',
      borderRadius: '12px',
      padding: '0px',
      '& fieldset': {
        borderColor: props.value.min ? 'blue' : 'initial',
      },
    };
  },
  inputMax: (props) => {
    return {
      background: 'transparent',
      width: '60px',
      height: '40px',
      borderRadius: '12px',
      padding: '0px',
      '& fieldset': {
        borderColor: props.value.max ? 'blue' : 'initial',
      },
    };
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    marginRight: '4px',
    fontSize: '12px',
    transform: 'translateY(8px)',
  },
  percent: {
    marginLeft: '4px',
    fontSize: '12px',
    transform: 'translateY(8px)',
  },
}));
interface InlineStatItemProps {
  item: { id: string; title: string; icon: JSX.Element };
  handleChangeValues: (
    id: string /* statItemsIdType */,
    newValue: { min: number; max: number },
  ) => void;
  value: { min: number; max: number };
  isPremiumFilterOption: boolean;
  isPercent?: boolean;
}
export const TwoParamsStatsItem: FC<InlineStatItemProps> = ({
  item,
  handleChangeValues,
  value,
  isPremiumFilterOption,
  isPercent = false,
}) => {
  const classes = useStyles({ value });
  const handleChange = (e: { target: { value: string; name: string } }) => {
    if (isPercent && +e.target.value > 100) return;

    if (e.target.value === '' || +e.target.value >= 0) {
      handleChangeValues(item.id /*  as statItemsIdType */, {
        ...value,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <div className={classes.root}>
      <div className={classes.labelRoot}>
        <span className={classes.icon}>{item.icon}</span>
        <span className={classes.label}>{item.title}</span>
      </div>
      <div className={classes.inputsRoot}>
        <TextField
          /*  disabled={isPremiumFilterOption} */
          className={classes.inputMin}
          name="min"
          id="min"
          variant="outlined"
          type="number"
          value={value.min === 0 ? '-' : value.min}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {isPercent && <span className={classes.percent}>%</span>}
        <span className={classes.label}>{'to'}</span>
        <TextField
          /* disabled={isPremiumFilterOption} */
          className={classes.inputMax}
          name="max"
          id="max"
          variant="outlined"
          type="number"
          value={value.max === 0 ? '-' : value.max}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {isPercent && <span className={classes.percent}>%</span>}
      </div>
    </div>
  );
};
