import { Typography } from '@mui/material';
import React, { useMemo } from 'react';

interface UserPhoneNumberProps {
  phone: string;
  fontSize?: string;
}

export const UserPhoneNumber: React.FC<UserPhoneNumberProps> = ({ phone, fontSize }) => {
  const formattedPhone = useMemo(() => {
    if (!phone) {
      return '';
    }

    if (!phone.startsWith('+1')) {
      return phone;
    }

    // apply US format
    const cleaned = phone.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (!match) {
      return '';
    }
    return ['(', match[2], ') ', match[3], '-', match[4]].join('');
  }, [phone]);

  return (
    <Typography color="#8B89A0" fontSize={fontSize || '13px'}>
      {formattedPhone}
    </Typography>
  );
};
