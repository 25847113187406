import { FC } from 'react';
import { Grid, Button } from '@mui/material';
import { ErrorPageStrings, ContactUsString } from '../../common/localization/en';
import {
  ErrorDescription,
  ErrorTitle,
  Container,
  ContentWrapper,
  ErrorImage,
} from './Venuewizard.style';
import { Mailto } from './wizardSteps/Steps.style';

export const ErrorPage: FC = () => {
  return (
    <Container>
      <ContentWrapper>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <ErrorImage src="/error.png" />
          </Grid>
          <Grid item xs={12} md={12}>
            <ErrorTitle>{ErrorPageStrings.Ooops}</ErrorTitle>
            <ErrorDescription>{ContactUsString.SecurityMessage}</ErrorDescription>
            <ErrorDescription>{ContactUsString.SecurityMessage2}</ErrorDescription>
          </Grid>
          <Grid item xs={10} md={5}>
            <Button color="primary" fullWidth>
              <Mailto
                href={`mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.EmailSubject}`}
              >
                {ErrorPageStrings.ContactSupport}
              </Mailto>
            </Button>
          </Grid>
        </Grid>
      </ContentWrapper>
    </Container>
  );
};
