import { SVGProps } from 'react';

export const ViewsIconNew = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={23} height={16} fill="none" {...props}>
    <path
      fill="#8B89A0"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M21.723 6.95c.513.78.513 1.788 0 2.568a12.08 12.08 0 0 1-20.196 0 2.339 2.339 0 0 1 0-2.569 12.081 12.081 0 0 1 20.196 0Z"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M11.626 11.484a3.25 3.25 0 1 0 0-6.5 3.25 3.25 0 0 0 0 6.5Z"
    />
  </svg>
);
