import { ActivityDTOModel } from '../../../../rewardsActivities/ActivitiesListSection/ActivitiesListSection.helper';

export interface EarningItemValues {
  id: string;
  points: number;
  title: string;
  description: string;
  icon: React.ReactNode;
}

export const mockEarningItems = [
  {
    id: '1',
    points: 300,
    title: 'Invite Friends',
    description: 'Earn by referring friends',
    icon: 'https://storage.googleapis.com/sv-prod-assets/ab59e68b-3681-42df-866e-918b0f6e2dec/tip1.png',
  },
];

export const createEarningValues = (items: ActivityDTOModel[]): EarningItemValues[] => {
  return items.map((item) => {
    return {
      id: item.id,
      points: item.points,
      title: item.title,
      description: item.description,
      icon: item.icon,
    };
  });
};
