import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: 3,
    height: '44px',
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: 'flex',
    alignItems: 'center',
    padding: '4px !important',
    width: 'fit-content',
  },
  tab: {
    padding: '0 12px',
    cursor: 'pointer',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabName: {
    fontsize: '15px',
    fontWeight: '500',
  },
  amount: {
    marginLeft: '.5em',
    height: '22px',
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    width: 'fit-content',
  },
}));
