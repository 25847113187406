import * as React from 'react';
import Box from '@mui/material/Box';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import IconButton from '@mui/material/IconButton';

import Tooltip from '@mui/material/Tooltip';

import Settings from '@mui/icons-material/Settings';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconWithInitials } from '../iconWithInitials/iconWithInitials';
import { useTypedSelector } from '../../../store';
import { LogoutIcon } from '../../assets/newDesign/LogoutIcon';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import { AccountMenuStrings } from '../../localization/en';
import { useHistory } from 'react-router-dom';
import { UnprotectedRoutes, WebsiteRoutes } from '../../constants/routes';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';

interface Props {
  showAccount?: boolean;
  disableProfile?: boolean;
}

export default function AccountMenu({ disableProfile, showAccount = true }: Props) {
  const history = useHistory();
  const { name } = useTypedSelector((state) => state.me) || '';
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const index = (name && name?.charCodeAt(0)) || 0;
  const { trackEvent } = useTrackEvent();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const { pathname } = useLocation();
  // const isOnProfilePage =
  //   pathname === WebsiteRoutes.ProfileAccounts || pathname === WebsiteRoutes.ProfileProfile;
  // const canSeeAccount = (items && items.length === 1) || !isOnProfilePage;
  const { isStarted: isQSFlow } = useQuickStartLaunchPad();
  const canSeeAccount = showAccount && items && items.length === 1 && !isQSFlow;

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    trackEvent(AccountEventNames.accountMenuOpenClicked);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    trackEvent(AccountEventNames.logoutButtonClicked);
    history.push(UnprotectedRoutes.Logout);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings" followCursor={true}>
          <IconButton
            onClick={handleClick}
            size="medium"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            data-qs-button="true"
          >
            <>
              <Box sx={{ fontSize: '17px', fontWeight: 600 }}>
                <IconWithInitials index={index} name={name} />
              </Box>
              <KeyboardArrowDownIcon />
            </>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            // backgroundColor: 'red',
            boxShadow: '0px 8px 30px rgba(20, 21, 61, 0.05)',
            borderRadius: '12px',
            width: '240px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            color: 'rgba(26, 21, 56, 1)',
            mt: 1,
            marginLeft: '-3%',
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ pt: '14px', pb: '14px', pl: '25px', pr: '25px', fontSize: '14px' }}
          onClick={() => {
            history.push(WebsiteRoutes.ProfileProfile);
            trackEvent(AccountEventNames.profileOpenButtonClicked);
          }}
          key={AccountMenuStrings.Profile}
          disabled={disableProfile}
        >
          <ListItemIcon>
            <PersonOutlineRoundedIcon />
          </ListItemIcon>
          {AccountMenuStrings.Profile}
        </MenuItem>
        {canSeeAccount ? (
          <MenuItem
            sx={{ pt: '14px', pb: '14px', pl: '25px', pr: '25px', fontSize: '14px' }}
            onClick={() => history.push(WebsiteRoutes.AccountsCompany)}
            key={AccountMenuStrings.Account}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            {AccountMenuStrings.Account}
          </MenuItem>
        ) : null}
        <MenuItem
          sx={{ pt: '14px', pb: '14px', pl: '25px', pr: '25px', fontSize: '14px' }}
          onClick={logOut}
          key={AccountMenuStrings.Logout}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {AccountMenuStrings.Logout}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
