import { FC } from 'react';

export const RemoveIcon: FC = () => {
  return (
    <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 1L1.5 7M1.5 1L7.5 7"
        stroke="#7A5AF8"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
