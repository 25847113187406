import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { WelcomeVideoModalStrings } from '../../../common/localization/en';
import { useTypedSelector } from '../../../store';
import { useStyles } from './WelcomeVideoStyles.styles';
import AddVideoSection from './components/AddVideoSection';
import SelectWelcomeVideoType from './components/SelectWelcomeVideoType';

export enum WelcomeVideoStylesType {
  inspirational = 'inspirational',
  custom = 'custom',
  none = 'none',
}

interface WelcomeVideoStylesProps {}

const WelcomeVideoStyles: FC<WelcomeVideoStylesProps> = () => {
  const styles = useStyles();
  const { recommendedVideoStyle } = useTypedSelector((state) => state.createCampaign);

  return (
    <Box className={styles.wrapper}>
      <SelectWelcomeVideoType />

      {recommendedVideoStyle === WelcomeVideoStylesType.custom ? (
        <Box style={{ marginTop: '20px' }}>
          <AddVideoSection />
        </Box>
      ) : (
        <Typography className={styles.videoTypeDescription}>
          {recommendedVideoStyle === WelcomeVideoStylesType.inspirational
            ? WelcomeVideoModalStrings.Hint
            : WelcomeVideoModalStrings.HintNoVideo}
        </Typography>
      )}
    </Box>
  );
};

export default WelcomeVideoStyles;
