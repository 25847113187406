import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCardBlockSlotsTimeStyles = makeStyles((theme: Theme) => ({
  form: {
    maxWidth: 324,
  },
  SectionTitle: {
    marginBottom: '16px  !important',
    color: '#464c71',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: 1.6,
  },
  TopgolfSlotsCaption: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
    marginBottom: '30px !important',
  },
  iconUp: {
    transform: 'rotate(180deg)',
  },
  HidenDropdownStyle: {
    opacity: 0,
    display: 'none',
    cursor: 'default',
    transition:
      'display 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  Input: {},
  DropdownStyle: {
    marginTop: '20px',
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
    width: '300px',
    transition:
      'display 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  InputContainer: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 190,
    border: '1px solid #DCDBE4',
    borderRadius: '12px',
    padding: '9px 10px 9px 15px',
    '&:hover': {
      backgroundColor: '#F5F6F8',
    },
  },
  SelectOptionContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: '22px',
    cursor: 'pointer',
    color: '#8B89A0',
    '&:hover': {
      backgroundColor: '#F5F6F8',
    },
    '&:first-child': {
      paddingTop: '10px',
    },
    '&:last-child': {
      paddingBottom: '10px',
    },
  },
  SelectedOptionText: {
    '& > span': {
      fontSize: '14px !important',
    },
  },
  OptionText: {
    position: 'relative',
    zIndex: 2,
    '& > span': {
      color: '#8B89A0 !important',
      fontSize: '14px !important',
    },
  },
  SelectOptionIcon: {
    marginRight: '10px',
    position: 'relative',
    zIndex: 1,
  },
}));
