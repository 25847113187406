import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: '0',
    minWidth: 0,
    background: `white !important`,
    fontWeight: '500',
    borderColor: '#CBCBCB',

    '& path': {
      stroke: `${theme.palette.primary.main} !important`,
    },

    '&:disabled': {
      background: `${theme.palette.primary.light} !important`,
      borderColor: `${theme.palette.primary.light} !important`,
      color: `${theme.palette.primary.main} !important`,
      '& path': {
        stroke: `${theme.palette.primary.main} !important`,
      },
    },

    '&:hover': {
      background: `${theme.palette.primary.main} !important`,
      '& path': {
        stroke: `#fff !important`,
      },
    },

    '&:active': {
      background: `${theme.palette.primary.dark} !important`,
      '& path': {
        stroke: `#fff !important`,
      },
    },
  },
}));
