import { Box, Button, Checkbox, FormControlLabel, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import { FC, useRef } from 'react';

import { FSModal, FSModalProps } from '../modal/Modal';
import {
  DEFAULT_PHONE_VALIDATION_STATE,
  PhoneField,
  PhoneValidationState,
} from '../PhoneField/PhoneField';
import { SmsInviteStrings } from '../../localization/en';
import { getYupValidationWithPhone } from '../../validation/commonValidation';
import SendTestWithPopulatedValuesMessageModal from './SendTestWithPopulatedValuesMessageModal';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../constants/events/quickstartEvents';
import { NewCampaignEvents } from '../../constants/events/newCampaignEvents';

const getValidationSchemaTestMessage = getYupValidationWithPhone({}, { phoneRequired: true });

export interface SendTestMessageModalProps extends FSModalProps {
  testMessage: string;
  onTestMessageSendClick: (data: { message: string; phone: string; mediaUrl?: string }) => void;
  phoneNumber?: string;
  name?: string;
  isQuickStart?: boolean;
}

export const SendTestMessageModal: FC<SendTestMessageModalProps> = ({
  testMessage,
  onTestMessageSendClick,
  phoneNumber,
  name,
  isQuickStart,
  ...props
}) => {
  const theme = useTheme();
  const phoneValidationRef = useRef<PhoneValidationState>(DEFAULT_PHONE_VALIDATION_STATE);
  const { trackEvent } = useTrackEvent();
  const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
    initialValues: { phone: phoneNumber, addTextPrefix: true },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    validateOnBlur: false,
    validationSchema: getValidationSchemaTestMessage({ phoneValidationRef }),
    onSubmit: async (data) => {
      if (data.phone) {
        const messageToSend = data.addTextPrefix ? `[Test] ${testMessage}` : testMessage;
        onTestMessageSendClick({
          message: messageToSend,
          phone: data.phone,
        });
      }

      props.onClose();
    },
  });

  return (
    <FSModal
      rounded="10px"
      padding="0"
      width="460px"
      {...props}
      onClose={() => {
        trackEvent(QuickStartEventNames.sample_sms_modal_close);
        props.onClose();
      }}
    >
      {phoneNumber && name ? (
        <SendTestWithPopulatedValuesMessageModal
          onSubmit={() => {
            if (values.phone) {
              const messageToSend = values.addTextPrefix ? `[Test] ${testMessage}` : testMessage;
              onTestMessageSendClick({
                message: messageToSend,
                phone: values.phone,
              });
            }

            props.onClose();
          }}
          onCancel={props.onClose}
          phoneNumber={phoneNumber}
          name={name}
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="6px"
          py="20px"
          px="16px"
          overflow="hidden"
        >
          <Typography fontSize="18px" fontWeight="600">
            {SmsInviteStrings.SampleText}
          </Typography>

          <Typography fontSize="12px">{SmsInviteStrings.SendMessageToLabel}</Typography>
          <PhoneField
            name="phone"
            value={values.phone || ''}
            onChange={(e) => {
              handleChange(e);
              trackEvent(
                isQuickStart
                  ? QuickStartEventNames.discount_input_typed
                  : NewCampaignEvents.NewRewardCampaignCustomizeMessageModalPhoneTyped,
                { value: e.target.value },
              );
            }}
            placeholder="Enter a phone number"
            onValidationChange={(v) => {
              phoneValidationRef.current = v;
            }}
            error={errors.phone}
            countryListMaxHeight={200}
            countriesDropDownPosition="fixed"
            setFieldValue={(value: string) => {
              setFieldValue('phone', value);
            }}
            hideInternationalPhoneNumberCheckbox
          />

          <Box display="none" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  name="addTextPrefix"
                  value={values.addTextPrefix}
                  checked={values.addTextPrefix}
                  onChange={handleChange}
                />
              }
              label={<Typography fontSize="12px">{SmsInviteStrings.AddTestPrefix}</Typography>}
            />
          </Box>

          <Box display="flex" gap="10px" justifyContent="flex-end" mt="24px">
            <LoadingButton
              variant="contained"
              style={{ borderRadius: '25px', height: '45px' }}
              disabled={!!errors.phone || !values.phone}
              onClick={() => {
                trackEvent(QuickStartEventNames.sample_sms_modal_send_message_button_click);
                handleSubmit();
              }}
              // loading={isLoading}
            >
              <Typography fontSize="14px" color={theme.palette.common.white} px="20px">
                {SmsInviteStrings.SendTestButton}
              </Typography>
            </LoadingButton>
            <Button
              variant="outlined"
              style={{ borderRadius: '25px', height: '45px' }}
              onClick={() => {
                props.onClose();
                trackEvent(QuickStartEventNames.sample_sms_modal_cancel_button_click);
              }}
            >
              <Typography fontSize="14px" color={theme.palette.primary.main} px="20px">
                {SmsInviteStrings.CancelButton}
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </FSModal>
  );
};
