import { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { FSModal } from '../../../../../../../common/components/modal/Modal';
import { useStyles } from './CancelInvitesModal.styles';
import { CancelInvitesStrings } from '../../../../../../../common/localization/en';
import { RoundedButton } from '../../../../../../../common/components/Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../../../../store';
import {
  deleteInvitations,
  getActiveInvitations,
  getInvitesQueueStats,
  getLastSendBatchStats,
  getQueuedInvitations,
  getUnsentInvitations,
} from '../../../../../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';

interface Props {
  closeModal: () => void;
  isOpenModal: boolean;
  onInvitesDelete?: () => void;
}

export const CancelInvitesModal: FC<Props> = ({ closeModal, isOpenModal, onInvitesDelete }) => {
  const classes = useStyles();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const campaignId = currentCampaign?.id!;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <FSModal
      onClose={closeModal}
      modalIsOpen={isOpenModal}
      width="660px"
      padding="16px"
      rounded="15px"
      closeBtn={false}
      blur
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>{CancelInvitesStrings.Title}</Typography>
        <Typography className={classes.subtitle}>
          {CancelInvitesStrings.Subtitle}
          <Typography display="inline" className={classes.undoneText}>
            {CancelInvitesStrings.Undone}
          </Typography>
        </Typography>
        <Box className={classes.buttonsWrapper}>
          <RoundedButton
            className={classes.goBackBtn}
            title={CancelInvitesStrings.GoBack}
            textVariant={true}
            onClick={closeModal}
          />
          <RoundedButton
            className={classes.cancelInvitesBtn}
            title={CancelInvitesStrings.CancelInvites}
            loading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await dispatch(
                deleteInvitations({
                  campaignId: currentCampaign?.id!,
                }),
              );
              dispatch(getInvitesQueueStats(campaignId));
              dispatch(getActiveInvitations({ campaignId }));
              dispatch(getUnsentInvitations({ campaignId, pageable: {} }));
              dispatch(getQueuedInvitations({ campaignId, pageable: {} }));
              dispatch(getLastSendBatchStats({ campaignId }));
              setIsLoading(false);
              addToast(CancelInvitesStrings.InvitesCancelled, { appearance: 'success' });
              onInvitesDelete?.();
              closeModal();
            }}
          />
        </Box>
      </Box>
    </FSModal>
  );
};
