import { useEffect, useState } from 'react';
import { FormControl, MenuItem, Select, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ClientTypes } from '../../../../api/models/common';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getAnalyticsMarket, setMarket } from '../../../../store/slices/organizationVenueSlice';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginLeft: 24,
    marginBottom: 16,
  },
  wrapper: {
    width: 220,
    height: 48,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #eeeef6',
    borderRadius: '12px',
  },
}));

export const MarketFilter = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { property_1: market } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (market) {
      dispatch(getAnalyticsMarket(market as ClientTypes));
    }
  }, [dispatch, market]);

  const handleChange = (event: { target: { value: string } }) => {
    dispatch(setMarket(event.target.value as ClientTypes));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const renderClientTypes = () => {
    return Object.keys(ClientTypes).map((item) => (
      <MenuItem key={item} value={item}>
        {item}
      </MenuItem>
    ));
  };

  return (
    <div className={classes.container}>
      <FormControl className={classes.wrapper}>
        <Select
          id="select-market-filter-id"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={market}
          onChange={handleChange}
          className={classes.wrapper}
        >
          {renderClientTypes()}
        </Select>
      </FormControl>
    </div>
  );
};
