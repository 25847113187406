import { BoxProps } from '@material-ui/core';
import { Box, Radio, RadioGroup, Typography, TypographyProps, useTheme } from '@mui/material';
import React from 'react';
import clsx from 'clsx';

import { Toggle } from '../toggle/CustomToggle';
import { Tooltip } from '../Tooltip/Tooltip';
import { useStyles } from './SubSectionTitle.helper';

export interface SubSectionTitleProps extends BoxProps {
  tooltip?: string;
  toggleConfig?: {
    onChange?: (value: boolean) => void;
    disable?: boolean;
    value?: boolean;
  };
  radioConfig?: {
    checked: boolean;
    onClick?: () => void;
  };
  title: string;
  titleProps?: TypographyProps;
}

export const SubSectionTitle: React.FC<SubSectionTitleProps> = ({
  tooltip,
  title,
  toggleConfig,
  radioConfig,
  titleProps,
  className,
  ...boxProps
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Box className={clsx(styles.wrapper, className)} {...boxProps}>
      <Box display="flex" alignItems="center">
        {!!toggleConfig && (
          <Toggle
            value={toggleConfig.value}
            onChange={(v) => toggleConfig?.onChange?.(v)}
            disabled={toggleConfig?.disable}
          />
        )}

        <Typography
          fontSize="18px"
          color={theme.palette.common.deepDark}
          fontWeight="500"
          {...titleProps}
        >
          {title}
        </Typography>
        {tooltip && <Tooltip title={tooltip} />}
      </Box>
      {radioConfig && (
        <RadioGroup>
          {/* Wrap in RadioGroup to add focus support */}
          <Radio checked={radioConfig.checked} onChange={radioConfig.onClick} />
        </RadioGroup>
      )}
    </Box>
  );
};
