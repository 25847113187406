import {
  frontEndDateFormat,
  LayoutMode,
  TypeSocialVerse,
  VIDEO_PADDING,
} from '../common/constants/constants';
import { BillingStrings, HelpersStrings } from '../common/localization/en';
import { domainZones } from './domainZones';
import { httpClient } from './httpClient/httpClient';
import { AtlassianEndpoints, getApiUrlForId, MediaEndpoints } from '../api/endpoints';
import axios from 'axios';
import { VideoFilterApiModel } from '../api/models/filters';
import { format } from 'date-fns';
import { UserApiModel } from '../api/models/users';
import { VideoApiModel } from '../api/models/videos';
import { PricingPlanResponse } from '../api/models/billing';
import { CampaignsApiModel, CampaignUser, CustomerActivitiesType } from '../api/models/campaigns';
import React from 'react';
import { distance } from 'fastest-levenshtein';
import { saveAs as saveFileAs } from 'file-saver';
import { GbpLocation } from '../store/slices/gbpSlice';
import * as Yup from 'yup';
import streamSaver from 'streamsaver';

export interface VideoResolution {
  width: number;
  height: number;
}

export const isDev = process.env.NODE_ENV !== 'production';

export const MAX_MEDIA_SIZE = 5000000;
export const MAX_MEDIA_WIDTH = 1920;
export const MAX_MEDIA_HEIGHT = 1280;

const getOriginalResolutionUrl = async (url: string) => {
  let result = url.replace(/,w_\d+,h_\d+/, '');

  const video = document.createElement('video');
  video.src = result.replace('fl_attachment/', '').replace(/fl_relative.+\/v1/, 'fl_relative/v1');

  const videoResolution = await new Promise<VideoResolution>((resolve) => {
    video.addEventListener(
      'loadedmetadata',
      () => {
        resolve({
          width: video.videoWidth,
          height: video.videoHeight,
        });
      },
      false,
    );
  });

  result = result
    .replace(/w_\d+/, `w_${videoResolution.width}`)
    .replace(/h_\d+/, `h_${videoResolution.height}`);

  return result;
};

export const DownloadMediaStream = async (fileUrl: string, fileName = '') => {
  try {
    // Fetch the file
    const response = await fetch(fileUrl);

    if (!response.ok) {
      throw new Error(`Failed to fetch file: ${response.statusText}`);
    }

    // Create a writable stream using StreamSaver
    const fileStream = streamSaver.createWriteStream(fileName);

    // Get the readable stream from the response
    const readableStream = response.body;

    if (!readableStream) {
      throw new Error('ReadableStream is not supported in this environment.');
    }

    // Check if the browser supports piping directly
    if ('pipeTo' in readableStream) {
      // Modern approach
      await readableStream.pipeTo(fileStream);
    } else {
      // Fallback for older browsers
      const writer = fileStream.getWriter();
      //@ts-ignore
      const reader = readableStream.getReader();

      const pump = async (): Promise<void> => {
        const { done, value } = await reader.read();
        if (done) {
          writer.close();
          return;
        }
        await writer.write(value);
        return pump();
      };

      await pump();
    }

    console.log('File downloaded successfully!');
  } catch (error) {
    try {
      await downloadMedia(fileUrl, fileName);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  }
};

export const downloadMedia = async (path: string, filename = '') => {
  await fetch(path);

  const [first, second] = path.split('/upload/');
  let downloadUrl = second ? `${first}/upload/fl_attachment/${second}` : `${path}`;
  downloadUrl = await getOriginalResolutionUrl(downloadUrl);

  if (filename) {
    saveFileAs(downloadUrl, filename);
  } else {
    const a = document.createElement('a');
    a.setAttribute('href', downloadUrl);
    a.setAttribute('download', 'My story.mp4');
    document.body.appendChild(a);
    a.click();
    a.remove();
    return;
  }
};

export const getSortFieldFromSortString = (sortString: string | undefined): string => {
  return sortString ? sortString.split(',')[0] : '';
};

export const getSortDirectionFromSortString = (sortString: string | undefined): string => {
  if (!sortString) return '';
  return sortString.split(',')[1] || '';
};

export const convertApiPageToFrontEndPage = (page: number) => {
  return page + 1;
};
export const convertFrontEndPageToApiPage = (page: number) => {
  return page - 1;
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(a: number, b = 2) {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
}

export const getVideoImgUrlFromS3 = (urlS3: string | null, urlCDN: string) => {
  if (!urlS3) {
    return '';
  }

  const indexPathUrl =
    urlS3.indexOf('/video/') !== -1 ? urlS3.indexOf('/video/') : urlS3.indexOf('/stories/');
  const pathUrl = urlS3.substring(indexPathUrl);
  return urlCDN + pathUrl;
};

export const getStringValue = (val?: string | number | null): string => {
  return val || typeof val === 'number' ? val.toString() : HelpersStrings.NotAvailable;
};

export const getLocaleNumberValue = (val: number): string => {
  return val || val === 0 ? val.toLocaleString('en') : HelpersStrings.NotAvailable;
};

// export const getVideoCropUrl = ({ height, width, x, y, id }: VideoCropParams)  => {
//   return `https://res.cloudinary.com/socialvenu/video/upload/c_crop,h_${height},w_${width},x_${x},y_${y}/v1/${id}.mp4`;
// }

export const getVideoInMp4 = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.mp4';
};

type VideoCropParams = {
  height: number;
  width: number;
  x: number;
  y: number;
  id: string;
  subdomain: string;
};

export const getVideoCropUrl = ({ height, width, x, y, id, subdomain }: VideoCropParams) => {
  return `https://res.cloudinary.com/socialvenu/video/upload/c_crop,h_${height},w_${width},x_${x},y_${y}/v1/${subdomain}/${id}.mp4`;
};

export const getVideoPublicId = (url: string) => {
  const [, path] = url.split('s3.amazonaws.com/');
  const pathArr = path.split('.');
  pathArr.pop();
  return pathArr.join('.');
};

export const getImageFilterPublicId = (url: string) => {
  const [, urlString] = url.split('s3.amazonaws.com/');
  return urlString.replace(/\//g, ':').replace('.png', '');
};

export const getVideoOverlayLink = (videoUrl: string, imageUrl: string, subdomain: string) => {
  const videoPublicId = getVideoPublicId(videoUrl);
  const imagePublicId = getImageFilterPublicId(imageUrl);

  return `https://res.cloudinary.com/socialvenu/video/upload/c_fill,h_552,w_374/h_552,l_${subdomain}:${imagePublicId},w_374/${subdomain}/${videoPublicId}.mp4`;
};

export const getCloudinaryUrlForFilter = (url: string, subdomain: string) => {
  const [, publicId] = url.split('s3.amazonaws.com/');
  return `https://res.cloudinary.com/socialvenu/image/upload/${subdomain}/${publicId}`;
};

type Dimensions = { width: number; height: number };

export function getPaddedVideoUrl(url: string, dim: Dimensions) {
  const [first, second] = url.split('/upload/');
  return `${first}/upload/w_${dim.width},h_${
    dim.height + VIDEO_PADDING
  },c_pad,b_black,g_south/${second}`;
}

export function getPaddedUrlForEdge(url: string, dim: Dimensions, subdomain: string) {
  const [, second] = url.split('/videos/');
  return `https://res.cloudinary.com/socialvenu/video/upload/w_${dim.width},h_${
    dim.height + 200
  },c_pad,b_black,g_south/v1/${subdomain}/videos/${second}`;
}

export async function getVideoDimensions(url: string): Promise<Dimensions> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    video.onloadeddata = function (e) {
      // @ts-ignore
      const vid: HTMLVideoElement = e.target;
      resolve({
        width: vid.videoWidth,
        height: vid.videoHeight,
      });
    };
    video.src = url;
  });
}

export const getVideoFromS3 = (uri: string) => {
  return uri.replace('s3://', 'https://s3.amazonaws.com/');
};

export const emailMaskRegex = new RegExp(
  `^[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.(${domainZones})$`,
);

export const urlMaskRegex = new RegExp(
  // eslint-disable-next-line no-useless-escape
  `((http|https)://)(www.)?[a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)`,
);

export const getLayoutFormat = (screenWidth: number): LayoutMode => {
  if (screenWidth >= 1280) return LayoutMode.Desktop;
  if (screenWidth < 1280 && screenWidth >= 640) return LayoutMode.Tablet;
  if (screenWidth < 640) return LayoutMode.Mobile;
  return LayoutMode.Desktop;
};
export const getCloudinaryUrl = (url: string) => {
  // https://res.cloudinary.com/socialvenu/video/upload/sv-dev-stories/stories/3cf29f4e-7a6d-4764-a09d-af850e148a8a.mp4
  // http://storage.googleapis.com/sv-dev-stories/stories/3cf29f4e-7a6d-4764-a09d-af850e148a8a.mp4
  let l = url.split('?')[0] || '';
  // const CDN = "https://storage.googleapis.com/";
  // const CLOUDINARY = "https://res.cloudinary.com/socialvenu/video/upload/";
  // const link = l.replace(CDN, CLOUDINARY);
  // const b = getVideoInMp4(link);
  return l;
};

export const transformGoogleToCloudinaryUrl = (url: string) => {
  const CDN = 'https://storage.googleapis.com/';
  const CLOUDINARY =
    'https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/';
  const link = url.replace(CDN, CLOUDINARY);
  const b = getVideoInMp4(link);
  return b;
};

const getCloudinaryVersion = (url: string): string => {
  const urlArrWithoutTransormationPart = url.split('/').slice(7).reverse().slice(1).reverse();
  let version: string[] = [];
  urlArrWithoutTransormationPart.forEach((chunk) => {
    const versionCondition =
      !chunk.includes('upload') &&
      !chunk.includes('c_scale') &&
      !chunk.includes('assets') &&
      !chunk.includes('w_') &&
      !chunk.includes(':');

    if (versionCondition) {
      version.push(chunk);
    }
  });
  return version.join('/');
};
/*
correct cloudinatu url from https://cloudinary.com/documentation/transformation_reference

https://res.cloudinary.com/<cloud_name>/<asset_type>/<delivery_type>/<transformations>/<version>/<public_id_full_path>.<extension>

we have different videos in Marketing Concord High School venue
*/

export const getProperCloudinaryUrl = (
  url: string,
  venueId: string,
  assets: string,
  filterId?: string,
) => {
  let version = 'v1/sv-dev-stories/stories';
  if (url.includes('https://res.cloudinary.com/')) {
    version = getCloudinaryVersion(url);
  }
  const videoId = url.split('/').reverse()[0].split('.')[0];
  if (filterId) {
    const updatedURL = `https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/c_scale,fl_relative${assets}:${venueId}:${filterId},w_540,h_960,y_0/${version}/${videoId}.mp4`;
    return updatedURL;
  } else {
    const updatedURL = `https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/${version}/${videoId}.mp4`;
    return updatedURL;
  }
};

export const transformAspectRatioCloudinaryUrl = (url: string) => {
  //wrong url
  // https://res.cloudinary.com/socialvenu/video/upload/ar_4:5,c_fill,w_400/c_scale,fl_relative,l_sv-dev-assets:de95786a-95c5-457b-a163-bd4cd5ba43d7:6870847e-2b12-446f-bf7f-ee9f7c22643f,w_400,h_500,y_0/v1/sv-dev-stories/stories/f1658ede-6a77-46cf-b4ad-56ca4db0e2e1.mp4
  //correct url
  // https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_540,h_960/c_scale,fl_relative,l_sv-dev-assets:de95786a-95c5-457b-a163-bd4cd5ba43d7:4abe9222-b42a-416a-a307-53d027da2247,w_540,h_960,y_0/v1/sv-dev-stories/stories/b569e316-abed-4153-acb4-80913682be6b.mp4
  // https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_400/c_scale,fl_relative,l_sv-dev-assets:e6d13789-3254-4996-859e-2b6efa85687f:e944275f-b229-446a-a359-1c9e47f92f99,w_400,h_500,y_0/v1/sv-dev-stories/stories/64d80d51-bc60-44ef-9b80-9e958838b3de.mp4
  // https://res.cloudinary.com/socialvenu/video/upload/ar_9:16,c_fill,w_400/c_scale,fl_relative,l_sv-dev-assets:e6d13789-3254-4996-859e-2b6efa85687f:e944275f-b229-446a-a359-1c9e47f92f99,w_400,h_500,y_0/v1/sv-dev-stories/stories/954c173b-d109-44a4-aad9-c9a07f28437e.mp4
  const oldAspectRatio = 'ar_4:5,c_fill,w_400';
  const newAspectRatio = 'ar_9:16,c_fill,w_540,h_960';
  const transformedRatioUrl = url.replace(oldAspectRatio, newAspectRatio);
  const oldSize = 'w_400,h_500';
  const newSize = 'w_540,h_960';
  const transformedSizeUrl = transformedRatioUrl.replace(oldSize, newSize);
  return transformedSizeUrl;
};

export const googleStorageToCloudinary = (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace(
      'https://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace(
      'http://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  return l;
};

export const asyncGoogleStorageToCloudinary = async (url: string) => {
  let l = url.split('?')[0] || '';

  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace(
      'https://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace(
      'http://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  await fetch(l);
  return l;
};
// resizeImageForTwilioMMSCommunication expects a googleStorage URL
export const resizeImageForTwilioMMSCommunication = async (url: string) => {
  if (!url) {
    return url;
  }

  const imageSize = await getRemoteImageSize(url);

  const cloudinaryUrl = await asyncGoogleStorageToCloudinary(url);
  const [first, second] = cloudinaryUrl.split('/upload/');

  const getAspectRatio = ({ width, height }: { width: number; height: number }) => {
    const LANDSCAPE = '16:9';
    const PORTRAIT = '9:16';
    const SQUARE = '1:1';

    const closesAspectRatio = findClosestNumber([16 / 9, 9 / 16, 1], width / height);

    if (closesAspectRatio < 1) {
      return PORTRAIT;
    }
    if (closesAspectRatio > 1) {
      return LANDSCAPE;
    }
    return SQUARE;
  };

  const result = `${first}/upload/ar_${getAspectRatio(imageSize)},c_pad,b_transparent/${second}`;
  await fetch(result);
  return result;
};

export const setPropertyToCloudinaryUrl = (
  url: string,
  properties: string,
  isReverseOrder?: boolean,
) => {
  const [first, second] = url.split(isReverseOrder ? '/v1/' : '/upload/');

  return isReverseOrder
    ? `${first}/${properties}/v1/${second}`
    : `${first}/upload/${properties}/${second}`;
};

/**
 * Extension value should be passed without a dot.
 * @example: 'png', 'jpg'
 */
export const updateFileExtension = (filename: string, newExtension: string) => {
  const urlWithoutExtension = filename.substring(0, filename.lastIndexOf('.'));
  return `${urlWithoutExtension}.${newExtension}`;
};

export function getItemsFromPageAndSize<T>(items: T[], page: number, size: number): T[] {
  if (items.length === size) {
    return items;
  }
  return items.filter((item, index) => index >= page * size).filter((item, index) => index < size);
}

export interface UploadUrlApiModel {
  url: string;
  method: string;
  headers: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  downloadUrl: string;
}

export const createUploadUrl = async (id: string, mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: getApiUrlForId(`${MediaEndpoints.CreateUploadSignedUrl}?ext=${mimeType}`, id),
      requiresToken: true,
    });
  } catch (error) {
    return console.log(error);
  }
};

interface UploadLogoAsFileRequest {
  options: UploadUrlApiModel;
  data: File;
}

export const uploadLogoAsFile = async ({ options, data }: UploadLogoAsFileRequest) => {
  if (!data) {
    return;
  }
  try {
    const res = await axios.put<typeof options, UploadLogoAsFileRequest>(options.url, data, {
      headers: options.headers,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getLocalDateString = (val: Date | string | null, separator?: string): string => {
  if (val) {
    const dateObject = new Date(val);
    return dateObject.toLocaleDateString('en-US').replace(/\//g, separator || '/');
  } else {
    return HelpersStrings.NotAvailable;
  }
};

export const getMonthsPassedFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const currDate = new Date();

  let result = (currDate.getFullYear() - date.getFullYear()) * 12;
  return result + (currDate.getMonth() - date.getMonth());
};

export const getDaysPassedFromDate = (dateString: string) => {
  const date = new Date(dateString);
  const currDate = new Date();

  return (currDate.getTime() - date.getTime()) / (1000 * 3600 * 24);
};

export const getPSTDateString = (val: Date | string | null, separator?: string): string => {
  if (val) {
    const dateObject = new Date(val);
    return dateObject
      .toLocaleDateString('en-US', { timeZone: 'America/Ensenada' })
      .replace(/\//g, separator || '/');
  } else {
    return HelpersStrings.NotAvailable;
  }
};

export const getUTCDateString = (val: string): string => {
  const dateObject = new Date(val.replace(/-/g, '/'));
  return dateObject.toISOString();
};

export const getDateString = (date: string) => format(new Date(date), frontEndDateFormat);

export const getAMPMTime = (val: Date | string | null): string => {
  if (val) {
    const date = new Date(new Date(val).toLocaleString('en-US', { timeZone: 'America/Ensenada' }));
    const [hours, minutes] = [date.getHours(), date.getMinutes()];
    const remainder = hours % 12;
    const isAM = hours === 0 ? true : 24 - hours > 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedHours = remainder === 0 ? '12' : remainder;
    const logTime = `${formattedHours}:${formattedMinutes}${isAM ? ' AM' : ' PM'}`;
    return logTime;
  } else {
    return HelpersStrings.NotAvailable;
  }
};

export const numberWithCommas = (value?: number | string | null): string => {
  if (!value) {
    return '0';
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const NumberWithOneDecimal = (value?: number) => {
  if (!value) {
    return 0;
  }
  return value.toFixed(1);
};

export const stringToNumber = (value?: string | number) => {
  if (typeof value === 'number') {
    return value;
  }
  if (!value) {
    return 0;
  }
  return parseInt(value.replace(',', '').replace(/ /g, ''));
};

export const setFieldNumberValue = (
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  setFieldValue: (name: string, value: number) => void,
) => {
  const { value, name } = event.target;
  const lastEnteredValue = value.slice(-1);
  const regExpCheckOnNumber = /^[-]?\d+$/;

  if (!regExpCheckOnNumber.test(lastEnteredValue)) {
    setFieldValue(name, +value);
    return;
  }

  const valueWithoutCommas = +value.replace(/,/g, '');

  setFieldValue(name, valueWithoutCommas);
};

export const formatPhoneNumber = (
  value: string | null,
  options: { showPlusOne?: boolean } = { showPlusOne: true },
) => {
  if (options.showPlusOne && value?.includes('+1')) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  } else {
    const cleaned = ('' + value).replace(/\D/g, '').slice(-10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return null;
  }
};

export const getWikiContentById = async (id: string) => {
  try {
    const res = await axios.get<any>(
      `https://us-central1-quantum-potion-305918.cloudfunctions.net/atlassian-api-proxy${AtlassianEndpoints.Content}/${id}?expand=body.export_view,children.page`,
      {
        headers: {
          Accept: 'application/json',
        },
      },
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getVideoPoster = (url: string) => {
  if (url && typeof url === 'string') {
    let l = url.split('.');
    l.pop();
    let b = l.join('.');
    return b + '.jpg';
  }
  return '';
};

export function sortFilters(a: VideoFilterApiModel, b: VideoFilterApiModel) {
  const keyA = new Date(a.createdAt);
  const keyB = new Date(b.createdAt);
  if (keyA < keyB) {
    return -1;
  } else if (keyA > keyB) {
    return 1;
  } else {
    return 0;
  }
}

export const downloadUrl = (url: string, name: string) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', name);
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export const infinityTableScroll = (
  e: React.UIEvent<HTMLDivElement, UIEvent>,
  isLoading: boolean,
  onGoToPage: (value: number) => void,
  currentPage: number,
) => {
  const target = e.target as HTMLDivElement;
  const bottom = target.scrollHeight - Math.round(target.scrollTop) <= target.clientHeight;
  if (bottom && !isLoading) {
    onGoToPage(currentPage + 2);
  }
};

export const getDisplayName = (name: string | null) => (name ? name.split(' ')[0] : '');

export const getDisplayNameAndInitial = (name: string): string => {
  const nameArray = name ? name.split(' ') : [''];
  return nameArray.length > 1
    ? `${nameArray[0]} ${nameArray[1].charAt(0).toUpperCase()}`
    : nameArray[0];
};

export const getInitial = (name: string): string => {
  const nameArray = name.split(' ');
  const initials = nameArray.map((name) => name.charAt(0).toUpperCase());
  return initials.join('');
};

export const avoidNan = (value: number): number | string => {
  return isNaN(value) ? '---' : value;
};

export const avoidNanWithReturnNumber = (value: number, fallback: number): number => {
  return isNaN(value) ? fallback : value;
};

export const getFixedValue = (value?: number | string | null) => {
  return value && typeof value === 'number' ? value.toFixed(1) : value;
};

export const getRoundedValue = (value?: number | string | null) => {
  return value && typeof value === 'number' ? Math.round(value) : value;
};

export const insertAt = (
  value: number | string,
  text: string,
  position: number | 'start' | 'end',
) => {
  const str = value.toString();
  const startAt = position === 'start' ? 0 : position === 'end' ? str.length : position;
  return `${str.slice(0, startAt)}${text}${str.slice(startAt)}`;
};

export const getError = (name: string, touched: any, errors: any) => {
  const isTouched = Boolean(touched[name]);

  if (isTouched) {
    return errors[name] as string;
  }

  return undefined;
};

export const isEqualsJson = (obj1: Object, obj2: Object) => {
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  //return true when the two json has same length and all the properties has same value key by key
  // @ts-ignore
  return keys1.length === keys2.length && Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
};

export const convertStripePriceToDollars = (value: number) => {
  const temp = value / 100;
  return temp.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
};

/**
 * @example
 * " a   b   c " -> "a b c"
 * "a  b  c" -> "a b c"
 * "a b c" -> "a b c"
 */

export const trimAndSquashSpaces = (value: string): string => {
  return value.trim().replace(/\s+/g, ' ');
};

/**
 *
 * @example
 * reorderArray([1, 2, 3], 0, 1)) -> [2, 1, 3]
 */

export function reorderArray<T>(array: T[], oldIndex: number, newIndex: number): T[] {
  array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  return array;
}

type FillEmptySupportedTypes = UserApiModel | VideoApiModel;

export function fillEmptyUserFields<T = FillEmptySupportedTypes>(data: FillEmptySupportedTypes) {
  const isStoryApiModel = (object: FillEmptySupportedTypes): object is VideoApiModel => {
    return 'userId' in object;
  };

  const buildEmptyCreatorName = ({ userId }: { userId: string }) => {
    return `Creator-${userId.slice(-4)}`;
  };

  if (isStoryApiModel(data)) {
    return ({
      ...data,
      userName: data.userName?.trim() || buildEmptyCreatorName({ userId: data.userId }),
    } as unknown) as T;
  }

  const name = data.name?.trim() ? data.name : buildEmptyCreatorName({ userId: data.id });
  const displayName = data.displayName?.trim() ? data.displayName : '---';

  return ({
    ...data,
    name,
    displayName,
    email: data.email || '---',
  } as unknown) as T;
}

export const isObjectEmpty = (obj: Record<string, unknown>) => {
  return Object.keys(obj).length === 0;
};

export const generateRandomString = (config?: { length?: number; symbols?: string }) => {
  const defaultConfig = {
    length: 10,
    symbols: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
  };
  const { length, symbols } = { ...defaultConfig, ...config };
  const getRandomSymbol = () => symbols.charAt(Math.floor(Math.random() * symbols.length));

  return Array.apply(null, Array(length)).map(getRandomSymbol).join('');
};

export const redirectToPage = (url: string, isNewPage = true) => {
  const a = document.createElement('a');
  a.href = url;
  if (isNewPage) {
    a.target = '_blank';
  }
  a.click();
  a.remove();
};

export const stringToBase64 = (str: string) => {
  return btoa(encodeURIComponent(str));
};

export const base64ToString = (str: string) => {
  return decodeURIComponent(atob(str));
};

export const getShortPlanPeriod = (pricingPlan: PricingPlanResponse) => {
  let result = '';
  if (pricingPlan.billingPeriod === 'monthly') {
    result = BillingStrings.PerMonth;
  } else if (pricingPlan.billingPeriod === 'yearly') {
    result = BillingStrings.PerYear;
  }
  return result;
};

export const isSpheresView = (viewMode?: TypeSocialVerse) => {
  return viewMode === TypeSocialVerse.Spheres || viewMode === TypeSocialVerse.Circles;
};

export const isCardsView = (viewMode?: TypeSocialVerse) => {
  return viewMode === TypeSocialVerse.Cards;
};
export const isEducationalView = (viewMode?: TypeSocialVerse) => {
  return viewMode === TypeSocialVerse.Educational;
};
export const isPerspectiveView = (viewMode?: TypeSocialVerse) => {
  return viewMode === TypeSocialVerse.Perspective;
};
export const isGridView = (viewMode?: TypeSocialVerse) => {
  return viewMode === TypeSocialVerse.Grid;
};

export function getThousandsSeparatedVal(value: string) {
  return value?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const escapeRegExp = (text: string) => {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
};

export const formatDuration = (
  durationInSeconds: number,
  options: { twoDigitsMinutes?: boolean } = {},
): string => {
  const minutesRaw = Math.floor(durationInSeconds / 60);
  const secondsRaw = Math.round(durationInSeconds - minutesRaw * 60);

  const minutes = options.twoDigitsMinutes && minutesRaw < 10 ? `0${minutesRaw}` : minutesRaw;
  const seconds = secondsRaw < 10 ? `0${secondsRaw}` : secondsRaw;

  return `${minutes}:${seconds}`;
};

export const formatStringWithPattern = (value: string, pattern: string, maskChar = '#') => {
  let normalizedPattern = '';
  let counter = 0;
  pattern.split('').forEach((chr) => {
    if (chr === maskChar) {
      counter++;
    }

    if (counter <= value.length) {
      normalizedPattern += chr;
    }
  });

  let index = 0;
  return normalizedPattern.replace(new RegExp(maskChar, 'g'), () => value[index++]);
};

interface HandleChangeWithPattern {
  event: React.ChangeEvent<HTMLInputElement>;
  prevVal: string;
  pattern: string;
  setVal: (value: string) => void;
  maskChar?: string;
  onlyDigits?: boolean;
}

export const handleChangeWithPattern = ({
  event,
  prevVal,
  pattern,
  setVal,
  maskChar = '#',
  onlyDigits = true,
}: HandleChangeWithPattern) => {
  let value = event.target.value;
  const prevValLength = prevVal.length;

  if (value.length > prevValLength) {
    if (prevValLength === pattern.length) {
      return;
    }

    if (onlyDigits) {
      value = value.replace(/\D+/g, '');
    } else {
      const charsToClean = pattern.replace(new RegExp(maskChar, 'g'), '').split('').join('\\');
      value = value.replace(new RegExp(`[${charsToClean}]`, 'g'), '');
    }

    const maskCharsCount = (pattern.match(new RegExp(maskChar, 'g')) || []).length;
    if (value.length > maskCharsCount) {
      value = value.slice(value.length - maskCharsCount, value.length);
    }

    value = formatStringWithPattern(value, pattern, maskChar);
  }
  setVal(value);
};

export const getFileExtension = (filename: string) => {
  return filename.split('.').pop();
};

export const convertImageFileToPng = (image: File) => {
  const blob = image.slice(0, image.size, image.type);
  return new File([blob], updateFileExtension(image.name, 'png'), { type: 'image/png' });
};

export const getOnlyNumbers = (value: string) => {
  return value.replace(/\D/g, '');
};

export const getRemoteImageSize = async (url: string) => {
  const img = new Image();
  img.src = url;

  await new Promise<void>((resolve) => {
    if (img) {
      img.onload = () => {
        resolve();
      };
    } else {
      resolve();
    }
  });

  return {
    width: img.width,
    height: img.height,
  };
};

export const lightenDarkenColor = (col: string, amt: number) => {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  let b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  let g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const getKeywordScore = (totalKeywords: number, matchedKeywords: number) => {
  if (totalKeywords === 0) return `0%`;

  return `${((matchedKeywords / totalKeywords) * 100).toFixed()}%`;
};

export const isImageBackgroundTransparent = async (imgUrl: string) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.src = imgUrl;

  return new Promise<boolean | null>((resolve) => {
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      if (!ctx) {
        resolve(null);
        return;
      }
      ctx.drawImage(img, 0, 0);
      const pixels = ctx.getImageData(0, 0, 1, 1).data;
      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i + 3] < 255) {
          resolve(true);
          break;
        }
      }
      resolve(false);
    };
  });
};

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }
  return 0;
};

export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const getVideoDuration = async (file: File | Blob | string): Promise<number> => {
  const video = document.createElement('video');

  if (typeof file !== 'string') {
    video.src = URL.createObjectURL(file);
  } else {
    video.src = file;
  }

  return new Promise((resolve) => {
    video.onerror = () => {
      resolve(0);
    };
    video.onloadedmetadata = () => {
      if (video.duration === Infinity) {
        video.currentTime = Number.MAX_SAFE_INTEGER;
        video.ontimeupdate = () => {
          video.ontimeupdate = null;
          resolve(video.duration);
          video.currentTime = 0;
        };
      } else {
        resolve(video.duration);
      }
    };
  });
};

export const trimAndLowercase = (v?: string) => {
  if (!v) return v;
  const a = v.trim().split(' ');
  const c = a.filter((e) => e !== '');
  return c.join(' ').toLowerCase();
};

// returns similarity score between two strings in percents
export const getSimilarityScore = (str1: string, str2: string) => {
  return (1 - distance(str1, str2) / Math.max(str1.length, str2.length)) * 100;
};

export const isElementInViewport = (element: HTMLElement, offset = 0) => {
  if (!element) return false;

  const top = element.getBoundingClientRect().top;

  return top + offset >= 0 && top - offset <= window.innerHeight;
};

export const getImageMetadata = async (url: string) => {
  const img = new Image();
  img.src = url;
  await img.decode();

  return img;
};

export const getImageDimensionsToFit = (
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number,
) => {
  let logoW = img.naturalWidth;
  let logoH = img.naturalHeight;

  if (logoW > logoH) {
    logoW = logoW > maxWidth ? maxWidth : logoW;
    logoH = Math.floor(logoW / (img.width / img.height));
    logoH = logoH > maxHeight ? maxHeight : logoH;
    logoW = logoH * (img.width / img.height);
  } else {
    logoH = maxHeight;
    logoW = logoH * (img.width / img.height);
  }

  return {
    width: Math.floor(logoW),
    height: Math.floor(logoH),
  };
};

export const getCampaignGoalData = (campaign: CampaignsApiModel, user: CampaignUser) => {
  const goalItemsMap: Record<CustomerActivitiesType, Array<number | undefined>> = {
    [CustomerActivitiesType.VideoUpload]: [user.videos],
    [CustomerActivitiesType.VideoViews]: [user.videos, user.videoViews],
    [CustomerActivitiesType.VideoClicks]: [user.videos, user.videoViews, user.videoCtaClicks],
  };

  const campaignObjective = campaign?.campaignObjective || CustomerActivitiesType.VideoUpload;
  const goalItems = goalItemsMap[campaignObjective];

  const isGoalAchieved = goalItems.every((item) => item && item > 0);
  const goalProgress = goalItems.reduce((acc: number, curr) => {
    return curr && curr > 0 ? acc + 1 : acc;
  }, 0);

  return {
    isGoalAchieved,
    goal: goalItems.length,
    goalProgress,
  };
};

export const convertZToPST = (date: string) => {
  const utcDate = new Date(date);
  utcDate.setHours(utcDate.getHours() - 10);
  return new Date(utcDate);
};

export const capitalizeWord = (word: string) => {
  const lowercased = word.toLowerCase();
  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
};

export const getUrlDomain = (url: string) => {
  return new URL(url).hostname.replace(/^www\./, '').toLowerCase();
};

export const formatIntPhoneNumber = (phoneNumber: string) => {
  const phoneDigits = getOnlyNumbers(phoneNumber);
  if (phoneDigits.length === 11 && /^1.+/.test(phoneDigits)) {
    return formatStringWithPattern(phoneDigits.replace(/^1/, ''), '(###) ###-####');
  } else {
    return phoneDigits.length ? formatStringWithPattern(phoneDigits, '+############') : '';
  }
};

export const formatSourceBadge = (source?: string) => {
  return (
    source
      ?.split('_')
      .map((word) => capitalizeWord(word))
      ?.join(' ') || '---'
  );
};

export const getFullName = <T extends { firstName: string; lastName: string }>(item: T) => {
  return (
    [item.firstName || '', item.lastName || ''].join(' ').trim() || HelpersStrings.NotAvailable
  );
};

export const waitFor = async (condition: () => boolean) => {
  await new Promise<void>((resolve) => {
    setInterval(() => {
      if (condition()) {
        resolve();
      }
    }, 50);
  });
};

export const findClosestNumber = (numbers: number[], goal: number) => {
  return numbers.reduce((prev, curr) => {
    return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
  });
};

interface Videos {
  thumbnailUrl: string;
}

export const getUnbrandedThumbnail = <T extends Videos>(videos: T[]): T[] => {
  return videos.map((item) => {
    let noFilterThumbnail = item.thumbnailUrl;
    if (
      noFilterThumbnail &&
      noFilterThumbnail.includes('/upload/') &&
      noFilterThumbnail.includes('/v1/') &&
      (noFilterThumbnail.includes('l_sv-dev-assets') ||
        noFilterThumbnail.includes('l_sv-prod-assets'))
    ) {
      noFilterThumbnail = removeFilterFromUrl(noFilterThumbnail);
    }
    return {
      ...item,
      thumbnailUrl: noFilterThumbnail,
    };
  });
};

export const getDebouncedFunc = <T extends (...args: any) => ReturnType<T>>(
  callback: T,
  delay: number,
) => {
  let timeoutId = 0;

  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

export const removeFilterFromUrl = (url: string) => {
  const filterStartIndex = url.indexOf('l_sv-dev-assets');
  const filterStartIndexprod = url.indexOf('l_sv-prod-assets');
  if (filterStartIndex === -1 && filterStartIndexprod === -1) return url;
  return url.split('/').reduce((acc, curr) => {
    return curr.includes('l_sv-dev-assets') || curr.includes('l_sv-prod-assets')
      ? acc
      : acc
      ? `${acc}/${curr}`
      : curr;
  }, '');
};

export const getGbpLocation = (
  locations: GbpLocation[],
  zip: string,
  address: string,
  companyWebsite: string,
  isBrand360?: boolean,
) => {
  if (locations.length === 1) {
    return locations[0];
  }

  return locations.find((loc) => {
    const addressDigits = address.replace(/\D/g, '');
    const digitsMatch = loc.storefrontAddress?.addressLines?.reduce((acc, curr) => {
      const lineDigits = curr.replace(/\D/g, '');
      return acc + lineDigits;
    }, '');
    return loc.storefrontAddress?.postalCode === zip && digitsMatch === addressDigits;
  });
};

export const isEmailValid = (email: string) => {
  try {
    return Yup.string().email().required().validateSync(email);
  } catch {
    return false;
  }
};

export const isDateValid = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const getVideoAspectRatio = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoURL = URL.createObjectURL(file);
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      URL.revokeObjectURL(videoURL);
      const aspectRatio = video.videoWidth / video.videoHeight;
      resolve(aspectRatio);
    };
    video.onerror = function () {
      reject(new Error('There was an error loading the video.'));
    };
    video.src = videoURL;
  });
};
export const isVideoARCloseToTargetAR = (
  aspectRatio: number,
  epsilon: number = 0.1,
  targetAspectRatio = 9 / 16,
): boolean => {
  return Math.abs(aspectRatio - targetAspectRatio) < epsilon;
};

export const getIsResendInviteDatePossible = (dateZFormat: string, daysToAdd: number): boolean => {
  // Parse the input date
  const inputDate: Date = new Date(dateZFormat);

  // Add the appropriate amount of time (days)
  inputDate.setDate(inputDate.getDate() + daysToAdd); // Add days

  // Get the current date and time
  const currentDate: Date = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference: number = inputDate.getTime() - currentDate.getTime();

  // Calculate the difference in days
  const diffInDays: number = timeDifference / (1000 * 60 * 60 * 24);

  return diffInDays <= 0;
};
