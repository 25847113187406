import React, { FC } from 'react';
import { useStyles } from './RewardInfo.styles';
import { Box, Button, Typography } from '@mui/material';
import { useTypedSelector } from '../../../../../store';
import { CampaignSummaryRewardStrings } from '../../../../../common/localization/en';
import { RewardFulfillmentMethod } from '../../../../../api/models/rewardFulfillment';
import { useModal } from '../../../../../hooks/useModal';
import RewardModal from '../../RewardModal/RewardModal';
import { useQuickStartLaunchPad } from '../../../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import AnimatedQsMarker, {
  QSStep,
} from '../../../../../common/components/AnimatedQsMarker/AnimatedQsMarker';

interface Props {
  qsSteps: QSStep[];
}

const RewardInfo: FC<Props> = ({ qsSteps }) => {
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const classes = useStyles();
  const { isQSMarkerVisible, currentTopicStep } = useQuickStartLaunchPad();
  const isActiveCampagn = !currentCampaign?.endedAt;

  const getInfoText = () => {
    if (!currentCampaign) return;

    if (currentCampaign.isNullReward) {
      return currentCampaign?.endedAt
        ? CampaignSummaryRewardStrings.NoRewardInfoEndedCampaign
        : CampaignSummaryRewardStrings.NoRewardInfo;
    }

    if (currentCampaign.fulfillmentConfig?.method === RewardFulfillmentMethod.MANUAL_SMS) {
      return CampaignSummaryRewardStrings.RewardManualInfo;
    }

    if (currentCampaign.isMultiFulfillment) {
      return CampaignSummaryRewardStrings.RewardAutoEveryInfo;
    }

    return CampaignSummaryRewardStrings.RewardAutoFirstInfo;
  };

  const {
    isOpen: isRewardModalOpen,
    open: openRewardModalOpen,
    close: closeRewardModalOpen,
  } = useModal(false);

  return (
    <>
      {currentCampaign && (
        <RewardModal
          isOpen={isRewardModalOpen}
          onClose={closeRewardModalOpen}
          campaign={currentCampaign}
        />
      )}
      <Box
        className={classes.container}
        style={{
          minHeight: isActiveCampagn ? '156px' : '120px',
        }}
      >
        {isQSMarkerVisible && currentTopicStep === 0 && (
          <Box className={classes.qsMarkerWrapper}>
            <AnimatedQsMarker arrowPosition="right" steps={qsSteps} direction="column" />
          </Box>
        )}
        <Typography className={classes.title}>
          {currentCampaign?.isNullReward
            ? CampaignSummaryRewardStrings.NoRewardTitle
            : CampaignSummaryRewardStrings.RewardTitle}
        </Typography>
        {!currentCampaign?.isNullReward && (
          <Typography className={classes.rewardName}>{currentCampaign?.rewardTitle}</Typography>
        )}
        <Typography className={classes.info}>{getInfoText()}</Typography>
        {!currentCampaign?.endedAt && (
          <Box sx={{ mt: 'auto' }}>
            <Button variant="contained" onClick={openRewardModalOpen}>
              {currentCampaign?.isNullReward
                ? CampaignSummaryRewardStrings.AddReward
                : CampaignSummaryRewardStrings.Customize}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default RewardInfo;
