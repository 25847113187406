import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: var(--white);
  font-size: 14px;
  transition: 0.2s ease-in;
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 8px;
`;
