import { Box, Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { DataSyncStrings } from '../../../localization/en';
import { useStyles } from './MatchProperties.helper';
import { useClientType } from '../../../../services/hooks/useClientType';
import { InfoIcon } from '../../../assets/newDesign/InfoIcon';

interface ImportModalExceedLimitProps {
  close: () => void;
}

const ImportModalExceedLimit: FC<ImportModalExceedLimitProps> = ({ close }) => {
  const styles = useStyles({});
  const { isHealthCare } = useClientType();
  const leftPart = DataSyncStrings.ExceedImportLimitInfo.substring(
    0,
    DataSyncStrings.ExceedImportLimitInfo.indexOf('750'),
  );
  const rightPart = DataSyncStrings.ExceedImportLimitInfo.substring(
    DataSyncStrings.ExceedImportLimitInfo.indexOf('750') + 3,
    DataSyncStrings.ExceedImportLimitInfo.length,
  );

  return (
    <Box className={styles.modalRoot}>
      <Typography textAlign={'center'} className={styles.modalTitle}>
        {isHealthCare
          ? DataSyncStrings.ExceedImportLimitTitleTrublu
          : DataSyncStrings.ExceedImportLimitTitle}
      </Typography>
      <Typography className={styles.description}>{DataSyncStrings.YouWillBeCharged}</Typography>
      <Typography className={styles.modalLabelLimit}>
        <InfoIcon className={styles.infoIcon} />
        {leftPart}
        <span className={styles.number}>750</span>
        {rightPart}
      </Typography>
      <Grid xs={12} container justifyContent="center">
        <Button className={styles.modalBtn} variant="contained" size="large" onClick={close}>
          {DataSyncStrings.GotIt}
        </Button>
      </Grid>
    </Box>
  );
};

export default ImportModalExceedLimit;
