import React, { FC } from 'react';
import { useStyles } from './PerspectivePreview.styles';
import { FormikProps } from 'formik';
import { FormikValues } from '../../SocialVerseSettingsPage';
import { Box, Typography } from '@mui/material';
import { getPreviewPerspectiveCards } from './PerspectivePreview.helpers';
import { CaptionIcon } from '../../../../../common/assets/CaptionIcon';
import { ArrowSharpIcon } from '../../../../../common/assets/newDesign/ArrowSharpIcon';
import { useTypedSelector } from '../../../../../store';
import { getDisplayNameAndInitial } from '../../../../../services/utilities';

interface Props {
  formik: FormikProps<FormikValues>;
}

const PerspectivePreview: FC<Props> = ({ formik }) => {
  const styles = useStyles();

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const previewPerspectiveCards = getPreviewPerspectiveCards(
    formik.values.numberOfPerspectiveCards,
  );

  return (
    <>
      <Box className={styles.container}>
        <Typography className={styles.title}>
          {formik.values.showTitle && formik.values.title}
        </Typography>
        {formik.values.showVideoCounter && (
          <Typography
            className={styles.videoCounter}
            style={{ color: formik.values.withGlow ? formik.values.glowColor : venueBrandColor }}
          >
            25 Videos
          </Typography>
        )}
        <Box className={styles.cardsWrapper}>
          {previewPerspectiveCards.map((card, index) => (
            <Box
              className={`${
                index === Math.floor(previewPerspectiveCards.length / 2) && styles.card
              }`}
              key={index}
            >
              {index === Math.floor(previewPerspectiveCards.length / 2) && (
                <>
                  <div className={styles.cardGradient}></div>
                  <Box className={styles.detailsWrapper}>
                    {formik.values.showUserInfo && (
                      <Typography className={styles.name}>
                        {getDisplayNameAndInitial(card.name)}
                      </Typography>
                    )}
                    <Box className={styles.infoWrapper}>
                      <Typography className={styles.time}>0:23</Typography>
                      {formik.values.captionEnabled && <CaptionIcon />}
                    </Box>
                  </Box>
                </>
              )}
              <img src={card.card} alt="preview card" />
            </Box>
          ))}
        </Box>
        <Box className={styles.navigationWrapper}>
          <Box
            className={styles.navigation}
            sx={{
              transform: 'rotate(-180deg)',
            }}
          >
            <ArrowSharpIcon />
          </Box>
          <Box className={styles.bar}>
            <Box
              className={styles.indicator}
              style={{
                backgroundColor: formik.values.withGlow ? formik.values.glowColor : venueBrandColor,
              }}
            ></Box>
          </Box>
          <Box className={styles.navigation}>
            <ArrowSharpIcon />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PerspectivePreview;
