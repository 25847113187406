import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '110px',
  },
  congradsIcon: {
    width: '75px',
    height: '74px',
    marginRight: '162px',
    marginTop: '24px',
  },
  closeIcon: {
    margin: '12px 12px 0 0 ',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    fontSize: '35px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0.36px',
    color: '#000',
    marginBottom: '10px',
  },
  description: {
    textAlign: 'center',
    width: '380px',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '28px',
    color: '#101828',
  },
  videosUploadedText: {
    marginTop: '10px',
    color: '#475467',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  },
  videosTakeText: {
    marginTop: '10px',
    color: '#8B89A0',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 600,
  },
  syncPreview: {
    width: '444px',
    marginTop: '9px',
  },
  exampleText: {
    color: '#8B89A0',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 400,
  },
  goToSvButton: {
    marginTop: '42px',
    marginBottom: '24px',
    width: '472px',
    height: '44px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
}));
