import React, { FC } from 'react';
import Select, { SelectOption } from '../Select/Select';
import { WebAppPageStrings } from '../../localization/en';

export enum AnimationStyleOptions {
  WhiteBrandingLogo = 'white_branding_logo',
  WhiteSplashLogo = 'white_splash_logo',
  WhiteBrandingName = 'white_branding_name',
  WhiteSplashName = 'white_splash_name',
  BlackBrandingLogo = 'black_branding_logo',
  BlackSplashLogo = 'black_splash_logo',
  BrandingSplashLogo = 'branding_splash_logo',
}

interface Props {
  splashImage: string;
  logo: string;
  value: AnimationStyleOptions;
  onChange: (value: AnimationStyleOptions) => void;
  onClick?: () => void;
}

const AnimationStyleSelect: FC<Props> = ({ splashImage, logo, value, onChange, onClick }) => {
  const splashOptions: SelectOption<AnimationStyleOptions>[] = [
    {
      value: AnimationStyleOptions.WhiteSplashLogo,
      name: WebAppPageStrings.LogoWhiteSplash,
      disabled: !logo || !splashImage,
    },
    {
      value: AnimationStyleOptions.WhiteBrandingLogo,
      name: WebAppPageStrings.LogoWhiteBranding,
      disabled: !logo,
      withBottomDivider: true,
    },
    {
      value: AnimationStyleOptions.BrandingSplashLogo,
      name: WebAppPageStrings.LogoBrandingSplash,
      disabled: !logo || !splashImage,
      withBottomDivider: true,
    },
    {
      value: AnimationStyleOptions.BlackSplashLogo,
      name: WebAppPageStrings.LogoBlackBranding,
      disabled: !logo || !splashImage,
    },
    {
      value: AnimationStyleOptions.BlackBrandingLogo,
      name: WebAppPageStrings.LogoBlackSplash,
      disabled: !logo,
      withBottomDivider: true,
    },
    {
      value: AnimationStyleOptions.WhiteSplashName,
      name: WebAppPageStrings.AccountNameWhiteSplash,
      disabled: !!logo || !splashImage,
    },
    {
      value: AnimationStyleOptions.WhiteBrandingName,
      name: WebAppPageStrings.AccountNameWhiteBrand,
      disabled: !!logo,
    },
  ];

  return (
    <>
      <Select
        onChange={onChange}
        options={splashOptions}
        value={value}
        width="490px"
        fontWeight="500"
        withDynamicPosition
        dynamicPositionBottomOffset={20}
        onClick={onClick}
      />
    </>
  );
};

export default AnimationStyleSelect;
