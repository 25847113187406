import { FormControlLabel, Switch } from '@mui/material';
import { RewardAdStrings } from '../../../../common/localization/en';
import { FC } from 'react';

interface IncentiveSwitcherProps {
  isActiveAd: boolean;
  setIsActiveAd: (active: boolean) => void;
}

export const IncentiveSwitcher: FC<IncentiveSwitcherProps> = ({ isActiveAd, setIsActiveAd }) => {
  return (
    <FormControlLabel
      control={
        <Switch color="primary" checked={isActiveAd} onChange={() => setIsActiveAd(!isActiveAd)} />
      }
      label={RewardAdStrings.SwitchText}
    />
  );
};
