import { FC, ReactNode } from 'react';
import { Grid, Dialog, DialogContent } from '@mui/material';

import { useStyles } from './SimpleModal.style';

interface Props {
  openModal: boolean;
  toggleModal?: (open: boolean) => void;
  children: ReactNode | JSX.Element;
  paperPadding?: string;
}

export const SimpleModal: FC<Props> = ({ children, openModal, toggleModal, paperPadding }) => {
  const classes = useStyles({ paperPadding });

  const handleCloseModal = () => {
    toggleModal && toggleModal(!openModal);
  };

  return (
    <Dialog
      PaperProps={{ classes: { root: classes.paper } }}
      className={classes.dialog}
      fullWidth={true}
      onClose={handleCloseModal}
      open={openModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent
        className={classes.root}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
      >
        <Grid container>{children}</Grid>
      </DialogContent>
    </Dialog>
  );
};
