import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { DataSyncStrings } from '../../../../common/localization/en';
import { useStyles } from './ImportHistoryTableSection.helper';
import { useClientType } from '../../../../services/hooks/useClientType';
import { SheduleSelect } from './SheduleSelect';
import { options } from '../ScheduleDelivery/ScheduleDelivery';

interface ApproveModalProps {
  handleApprove: () => void;
  handleClose: () => void;
  loading: boolean;
  daysShift: number;
  setDaysShift: (value: number) => void;
}

const ApproveModal: FC<ApproveModalProps> = ({
  handleApprove,
  handleClose,
  loading,
  daysShift,
  setDaysShift,
}) => {
  const styles = useStyles({});
  const { isMarketingClient, isHealthCare } = useClientType();

  return (
    <Box className={styles.modal}>
      <Typography className={styles.title}>{DataSyncStrings.ApproveModalTitle}</Typography>
      <Typography
        className={styles.subtitle}
        sx={{
          marginBottom: isHealthCare ? '25px' : '10px',
        }}
      >
        {isHealthCare
          ? DataSyncStrings.ApproveModalSubtitleTrublu
          : DataSyncStrings.ApproveModalSubtitle}
      </Typography>

      {isMarketingClient && (
        <>
          <Typography className={styles.deliveryTitle}>{DataSyncStrings.DeliveryTitle}</Typography>
          <Box className={styles.selectWrapper}>
            <Typography className={styles.selectDescr}>{DataSyncStrings.ScheduleDescr}</Typography>
            <SheduleSelect
              value={daysShift}
              selectValue={(value: number) => {
                setDaysShift(value);
              }}
              options={options}
            />
          </Box>
        </>
      )}
      <Grid xs={8} container alignItems="center" flexDirection="column">
        <LoadingButton
          size="large"
          className={styles.btnSubmit}
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleApprove}
          disabled={loading}
          loading={loading}
        >
          {DataSyncStrings.Submit}
        </LoadingButton>
        <Button size="large" onClick={handleClose} variant="text">
          {DataSyncStrings.Cancel}
        </Button>
      </Grid>
    </Box>
  );
};

export default ApproveModal;
