import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Icon, Typography } from '@mui/material';
import clsx from 'clsx';
import { FC, useEffect, useMemo, useState } from 'react';
import { ClientTypes } from '../../../api/models/common';
import { SharingOrder, SharingSettings } from '../../../api/models/venue';
import { DownloadIcon } from '../../../common/assets/shareAssets/DownloadIcon';
import { EmailIcon } from '../../../common/assets/shareAssets/EmailIcon';
import { LinkNewIcon } from '../../../common/assets/shareAssets/LinkNewIcon';
import { SMSIcon } from '../../../common/assets/shareAssets/SMSIcon';
import { TwitterIcon } from '../../../common/assets/shareAssets/TwitterIcon';
import { ShareCardPreview } from '../../../common/components/previews/StoryCardPreview/ShareCardPreview';
import { TopGolfShareCardPreview } from '../../../common/components/previews/StoryCardPreview/TopgolfShareCardPreview';
import {
  SharingScreen,
  VideoEditingStrings,
  VideoSharingStrings,
} from '../../../common/localization/en';
import { isEqualsJson } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getVideoFilters } from '../../../store/slices/filtersSlice';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { theme } from '../../../theme';
import { SectionDivider } from '../WebappSettings.style';
import { ButtonsTypes, useStyles } from './VideoSharing.helper';

interface A {
  [key: string]: (active: boolean) => JSX.Element;
}

const sharingIcons: A = {
  Download: (active) => (
    <div style={{ stroke: active ? theme.palette.common.white : theme.palette.common.black }}>
      <DownloadIcon />
    </div>
  ),
  Email: (active) => (
    <div style={{ color: active ? theme.palette.common.white : theme.palette.common.black }}>
      <EmailIcon />
    </div>
  ),
  Facebook: (active) => (
    <FacebookOutlinedIcon
      style={{ color: active ? theme.palette.common.white : theme.palette.common.black }}
    />
  ),
  Instagram: (active) => (
    <InstagramIcon
      style={{ color: active ? theme.palette.common.white : theme.palette.common.black }}
    />
  ),
  Link: (active) => (
    <div
      style={{
        stroke: active ? theme.palette.common.white : theme.palette.common.black,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LinkNewIcon />
    </div>
  ),
  LinkedIn: (active) => (
    <LinkedInIcon
      style={{ color: active ? theme.palette.common.white : theme.palette.common.black }}
    />
  ),
  Message: (active) => (
    <div style={{ color: active ? theme.palette.common.white : theme.palette.common.black }}>
      <SMSIcon />
    </div>
  ),
  Twitter: (active) => (
    <div style={{ stroke: active ? theme.palette.common.white : theme.palette.common.black }}>
      <TwitterIcon />
    </div>
  ),
};

/* enum SMSSharingMethod {
  notInContacts = 'notInContacts',
  SMSSequence = 'SMSSequence',
} */

export const VideoSharing: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    venue: { id, clientType },
    venueAttributes: { properties: venueProperties, isLoading },
  } = useTypedSelector((state) => state.venue);
  const webAppSharing = venueProperties?.['webapp.sharing'];
  const {
    sharingSettings,
    sharingSMSNotInContacts,
    sharingSMSSequence,
    sharingSMSSequenceAmount,
    sharingOrder,
  } = webAppSharing || {};

  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;
  const initialSharingState = useMemo(
    () => ({
      Message: sharingSettings ? sharingSettings?.Message : true,
      Email: sharingSettings ? sharingSettings?.Email : false,
      Twitter: sharingSettings ? sharingSettings?.Twitter : true,
      Instagram: sharingSettings ? sharingSettings?.Instagram : isTopgolfVenue ? false : true,
      Facebook: sharingSettings ? sharingSettings?.Facebook : true,
      Link: sharingSettings ? sharingSettings?.Link : true,
      LinkedIn: sharingSettings ? sharingSettings?.LinkedIn : false,
      Download: sharingSettings ? sharingSettings?.Download : isTopgolfVenue ? true : false,
    }),
    [sharingSettings, isTopgolfVenue],
  );

  const [sharingState, setSharingState] = useState<SharingSettings>(initialSharingState);
  const [isSMSInContactsEnabled, setIsSMSNotInContact] = useState<boolean>(
    !!sharingSMSNotInContacts,
  );
  const [isSMSSequenceEnabled, setIsSMSSequenceEnabled] = useState<boolean>(!!sharingSMSSequence);
  const [amount, setAmount] = useState(sharingSMSSequenceAmount || 5);

  const { videoFilters } = useTypedSelector((state) => state.filtersSlice);
  const activeFilter = videoFilters.find((item) => item.isActive === true);

  const [editable, setEditable] = useState(false);
  const [orderList, setOrderList] = useState<SharingOrder[]>([]);

  useEffect(() => {
    if (sharingOrder && sharingOrder.length > 0) {
      setOrderList(sharingOrder);
    } else {
      const array: SharingOrder[] = [];
      if (sharingSettings) {
        if (isTopgolfVenue) {
          //remove inst
          Object.keys(sharingSettings).map(
            (k) =>
              k !== ButtonsTypes.Instagram &&
              array.push({
                [k as keyof SharingSettings]: Object.keys(sharingSettings).findIndex(
                  (p) => p === k,
                ),
              }),
          );
        } else {
          //remove download
          Object.keys(sharingSettings).map(
            (k) =>
              k !== ButtonsTypes.Download &&
              array.push({
                [k as keyof SharingSettings]: Object.keys(sharingSettings).findIndex(
                  (p) => p === k,
                ),
              }),
          );
        }
      } else {
        if (isTopgolfVenue) {
          //remove inst
          Object.keys(ButtonsTypes).map(
            (k) =>
              k !== ButtonsTypes.Instagram &&
              array.push({
                [k as keyof SharingSettings]: Object.keys(ButtonsTypes).findIndex((p) => p === k),
              }),
          );
        } else {
          //remove download
          Object.keys(ButtonsTypes).map(
            (k) =>
              k !== ButtonsTypes.Download &&
              array.push({
                [k as keyof SharingSettings]: Object.keys(ButtonsTypes).findIndex((p) => p === k),
              }),
          );
        }
      }
      setOrderList(array);
    }
  }, [sharingOrder, sharingSettings, isTopgolfVenue]);

  const [currentCard, setCurrentCard] = useState<any>(null);
  const dragStartHandler = (e: any, item: any) => {
    setCurrentCard(item);
    e.target.style.cursor = 'pointer';
    e.target.style.opacity = 0.3;
  };
  const dragEndHandler = (e: any, item: any) => {
    e.target.style.opacity = 1;
  };
  const dragOverHandler = (e: any, item: any) => {
    e.preventDefault();
  };
  const dropHandler = (e: any, item: any) => {
    e.preventDefault();

    const updatedList = [...orderList].map((c) => {
      if (Object.keys(c)[0] === Object.keys(item)[0]) {
        return { [Object.keys(c)[0]]: Object.entries(currentCard)[0][1] };
      }
      if (Object.keys(c)[0] === Object.keys(currentCard)[0]) {
        return { [Object.keys(c)[0]]: Object.entries(item)[0][1] };
      }
      return c;
    });

    setOrderList(updatedList);

    e.target.style.cursor = 'default';
  };

  useEffect(() => {
    if (id) {
      dispatch(getVideoFilters({ venueId: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (webAppSharing) {
      setAmount(webAppSharing?.sharingSMSSequenceAmount || 5);
    }
  }, [webAppSharing]);

  useEffect(() => {
    if (initialSharingState) {
      setSharingState({ ...initialSharingState });
      setIsSMSNotInContact(!!sharingSMSNotInContacts);
      setIsSMSSequenceEnabled(!!sharingSMSSequence);
    }
  }, [initialSharingState, sharingSMSNotInContacts, sharingSMSSequence]);

  const handleClick = (name: keyof SharingSettings) => {
    const newState: SharingSettings = { ...sharingState, [name]: !sharingState[name] };
    setSharingState(newState);
  };

  const handleSubmit = () => {
    dispatch(
      patchVenueProperties({
        id,
        update: {
          name: 'webapp.sharing',
          value: {
            ...webAppSharing,
            sharingSettings: sharingState,
            sharingSMSNotInContacts: isSMSInContactsEnabled,
            sharingSMSSequence: isSMSSequenceEnabled,
            sharingSMSSequenceAmount: amount,
            sharingOrder: orderList,
          },
        },
      }),
    );
  };
  const handleReset = () => {
    sharingOrder && setOrderList(sharingOrder);

    setSharingState({ ...initialSharingState });
    setIsSMSNotInContact(!!sharingSMSNotInContacts);
    setIsSMSSequenceEnabled(!!sharingSMSSequence);
  };

  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const textColor = venueProperties?.['webapp.config']?.['text-color'];

  const canSave = () => {
    return (
      !sharingSettings ||
      !isEqualsJson(orderList, sharingOrder || []) ||
      !isEqualsJson(sharingState, sharingSettings) ||
      (sharingState.Message &&
        (sharingSMSNotInContacts !== isSMSInContactsEnabled ||
          sharingSMSSequence !== isSMSSequenceEnabled ||
          sharingSMSSequenceAmount !== amount))
    );
  };

  const toggleEdit = () => {
    setEditable(!editable);
  };

  const mappedOrderedSharingList =
    orderList &&
    orderList.slice().sort((a, b) => (Object.entries(a)[0][1] > Object.entries(b)[0][1] ? 1 : -1));

  return (
    <Grid className={classes.Container}>
      <form>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} md={6}>
            <Grid xs={12} className={classes.ShareSection}>
              <Typography className={classes.SectionTitle}>{VideoSharingStrings.Title}</Typography>
              <SectionDivider style={{ marginTop: '25px' }} />
              <Grid
                className={classes.ShareItemsSection}
                container
                justifyContent="space-between"
                spacing={3}
                alignItems="center"
              >
                <Grid container>
                  <Grid item container>
                    <Typography className={classes.Icon}>1</Typography>
                    <Typography className={classes.SharingTitle}>
                      {SharingScreen.SharingOptions}
                    </Typography>
                    <Typography
                      onClick={toggleEdit}
                      className={clsx(classes.EditOrderBtn, {
                        [classes.ActiveEditButton]: editable,
                      })}
                    >
                      {editable ? SharingScreen.Cancel : SharingScreen.EditOrder}
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="center">
                    {editable && (
                      <Typography className={classes.ToolTipDrabble}>
                        {SharingScreen.ClickDragToReorder}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className={clsx(classes.IconsWrapper, {
                    [classes.DragbbleWrapper]: editable,
                  })}
                >
                  {mappedOrderedSharingList.map((item) => {
                    const sharingName = Object.keys(item)[0] as keyof SharingSettings;
                    return (
                      <div className={classes.IconWrapper}>
                        <Icon
                          className={classes.Icons}
                          onClick={() => handleClick(sharingName)}
                          onDragStart={(e) => dragStartHandler(e, item)}
                          onDragLeave={(e) => dragEndHandler(e, item)}
                          onDragEnd={(e) => dragEndHandler(e, item)}
                          onDragOver={(e) => dragOverHandler(e, item)}
                          onDrop={(e) => dropHandler(e, item)}
                          draggable={sharingState[sharingName] && editable ? true : false}
                          style={{
                            background:
                              sharingState && sharingState[sharingName]
                                ? theme.palette.primary.main
                                : theme.palette.common.white,
                            border:
                              sharingState && sharingState[sharingName]
                                ? 'none'
                                : '1px solid #DCDBE4',
                          }}
                        >
                          <Box className={classes.DashedCircle}></Box>

                          {sharingIcons[sharingName](sharingState[sharingName])}
                        </Icon>
                        <Typography
                          className={`${classes.IconName} ${
                            sharingState && sharingState[sharingName]
                              ? classes.IconNameActive
                              : classes.IconNameInactive
                          }`}
                        >
                          {Object.keys(item)[0]}
                        </Typography>
                      </div>
                    );
                  })}
                </Grid>
                <SectionDivider style={{ marginTop: '25px' }} />
              </Grid>
            </Grid>
            {canSave() && (
              <Grid className={classes.bottomControllers} container direction="row" xs={12}>
                <Grid item xs={6}></Grid>
                <Grid item xs={6} alignItems="center" justifyContent="center">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LoadingButton
                      className={classes.styledButton}
                      variant="contained"
                      color="primary"
                      loading={isLoading}
                      loadingIndicator={VideoEditingStrings.loadingIndicatorText}
                      onClick={handleSubmit}
                      size="large"
                    >
                      {VideoEditingStrings.Save}
                    </LoadingButton>
                    <Button
                      className={classes.cancelButton}
                      color="primary"
                      variant="outlined"
                      onClick={handleReset}
                      size="large"
                    >
                      {VideoEditingStrings.Cancel}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={6} md={6}>
            {isTopgolfVenue ? (
              <Box marginTop="34px">
                <TopGolfShareCardPreview
                  brandColor={brandColor}
                  textColor={textColor}
                  ctaBtnText={`Let's Connect`}
                  videoText={`Check out my video`}
                  sharingSettings={sharingState}
                  mappedOrderedSharingList={mappedOrderedSharingList}
                  editable={editable}
                  toggleEdit={() => setEditable((prevState) => !prevState)}
                />
              </Box>
            ) : (
              <Box marginTop="34px">
                <ShareCardPreview
                  brandColor={brandColor}
                  textColor={textColor}
                  ctaBtnText={`Let's Connect`}
                  videoText={`Check out my video`}
                  sharingSettings={sharingState}
                  activeFilter={activeFilter}
                  mappedOrderedSharingList={mappedOrderedSharingList}
                  editable={editable}
                  toggleEdit={() => setEditable((prevState) => !prevState)}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
