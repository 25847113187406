import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 24px 10px',
  },
  selectTitle: {
    fontSize: '12px',
    fontWeight: 600,
    width: '100%',
    color: '#344054',
    margin: '16px 0 8px',
  },
  selectWrapper: {
    width: '264px',
  },
  searchButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '17px',
    marginBottom: '25px',
  },
}));
