import { httpClient } from '../services/httpClient/httpClient';
import { GetDataById } from './models/common';
import { UserMetricsAPIModel } from './models/users';
import { getApiUrlForId, VideosEndpoints } from './endpoints';

export const getVideoMetrics = async (options: GetDataById) =>
  await httpClient.get<GetDataById, UserMetricsAPIModel>({
    url: getApiUrlForId(VideosEndpoints.GetVideoMetrics, options.id),
    requiresToken: true,
  });
