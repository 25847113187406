import { KPIValueApiModel } from '../../../api/models/analytics';
import format from 'date-fns/format';
import { theme } from '../../../theme';
// import { theme } from '../../../theme';

export const options = {
  maintainAspectRatio: false,
  responsive: true,
  animation: false,
  animations: {
    radius: {
      duration: 400,
      easing: 'linear',
      loop: (context: any) => context.active,
    },
  },
  scales: {
    yAxes: [
      {
        gridLines: {
          drawOnChartArea: true,
          color: '#EEEEF6',
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
        },
      },
    ],
    xAxes: [
      {
        barThickness: 43,
        gridLines: {
          drawOnChartArea: false,
          color: '#EEEEF6',
        },
        ticks: {
          beginAtZero: true,
          min: 0,
          precision: 0,
        },
      },
    ],
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  tooltips: {
    xPadding: 9,
    yPadding: 5,
    bodyFontSize: 10,
    bodyFontColor: '#fff',
    backgroundColor: '#181F47',
    displayColors: false,
    callbacks: {
      title: function () {
        return null;
      },
      label: function (tooltipItem: any) {
        return tooltipItem.yLabel.toString();
      },
    },
  },
};

export const createOptions = (chartData: KPIValueApiModel[]) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    animation: false,
    animations: {
      radius: {
        duration: 400,
        easing: 'linear',
        loop: (context: any) => context.active,
      },
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: true,
            color: '#EEEEF6',
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          barThickness: 43,
          gridLines: {
            drawOnChartArea: false,
            color: '#EEEEF6',
          },
          ticks: {
            beginAtZero: true,
            min: 0,
            precision: 0,
          },
        },
      ],
    },
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltips: {
      xPadding: 9,
      yPadding: 5,
      titleFontSize: 10,
      bodyFontSize: 12,
      bodyFontColor: '#fff',
      backgroundColor: '#181F47',
      displayColors: false,
      titleAlign: 'center',
      bodyAlign: 'center',
      footerAlign: 'center',
      titleFontWeight: 'normal',
      bodyFontWeight: 'bold',
      callbacks: {
        title: function () {
          return null;
        },
        label: function (tooltipItem: any) {
          let date;
          if (chartData[tooltipItem.index].date.toString())
            date = format(new Date(chartData[tooltipItem.index].date.toString()), 'PP');
          return date;
        },
        footer: function (tooltipItem: any) {
          return tooltipItem[0].value.toString();
        },
      },
    },
  };

  return options;
};

export const annotationLine = {
  id: 'annotationLine',
  beforeDraw: (chart: any) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const ctx = chart.ctx;
      // ctx.globalCompositeOperation = 'xor';
      const gradient = ctx.createLinearGradient(0, 0, 0, 360);
      gradient.addColorStop(0, `${theme.palette.primary.main}`);
      gradient.addColorStop(0.93, `${theme.palette.primary.main}1a`);
      const { x, y } = chart.tooltip._eventPosition;
      ctx.save();
      ctx.beginPath();
      // ctx.moveTo(x, y);
      ctx.moveTo(x, y);
      ctx.lineTo(x, chart.chartArea.bottom);
      ctx.lineWidth = 2;
      ctx.strokeStyle = gradient;
      // ctx.strokeStyle = '#8868fe';
      ctx.stroke();
      ctx.restore();
    }
  },
};

export const getData = (canvas: any, chartData: KPIValueApiModel[], option: boolean) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 360);
  // gradient.addColorStop(0, theme.palette.primary.light);
  // gradient.addColorStop(0.93, theme.palette.common.white);
  gradient.addColorStop(0, `${theme.palette.primary.main}1a`);
  gradient.addColorStop(0.93, `${theme.palette.primary.main}00`);

  return {
    labels: option
      ? chartData.map((chartEntry) => {
          const formateCharEntry = chartEntry.date.split('-').join('/');
          const newDate = new Date(formateCharEntry);
          const formatDay = format(newDate, 'dd');
          return formatDay;
        })
      : getDays(chartData),

    datasets: [
      {
        data: option ? chartData.map((chartEntry) => chartEntry.value) : [0, chartData[0].value, 0],
        // data: chartData.map((chartEntry) => chartEntry.value),
        backgroundColor: gradient,
        borderColor: theme.palette.primary.main,
        border: 0,
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 6,

        pointBackgroundColor: '#fff',
        // pointBorderColor: '#5DD6AA',
        pointHoverBorderColor: theme.palette.primary.main,
        pointHoverBorderWidth: 4,
        pointBorderWidth: 2,
      },
    ],
  };
};

export const getNormalizedChartData = (chartData: KPIValueApiModel[]) => {
  let accumulatedValue = 0;
  return chartData.map((data) => {
    accumulatedValue += data.value;

    return {
      ...data,
      value: accumulatedValue,
    };
  });
};

export interface MonthValues {
  name: string;
  days: number;
}

export const getMonths = (chartData: KPIValueApiModel[]): MonthValues[] => {
  const months = [] as MonthValues[];
  let iteratedMonth = '';
  let countDays = 0;

  chartData.forEach((chartEntry, index) => {
    const formatMonth = format(new Date(chartEntry.date), 'MMMM');
    if (iteratedMonth && iteratedMonth !== formatMonth) {
      months.push({ name: iteratedMonth, days: countDays });
      countDays = 0;
    }
    iteratedMonth = formatMonth;
    countDays += 1;
    if (chartData.length === index + 1) {
      months.push({ name: iteratedMonth, days: countDays });
    }
  });

  return months;
};
export const getDays = (chartData: KPIValueApiModel[]) => {
  if (chartData) {
    const selectedDate = new Date(chartData[0]!.date.split('-').join('/'));
    const daysNumbers = [
      selectedDate.getDate() - 1,
      selectedDate.getDate(),
      selectedDate.getDate() + 1,
    ];
    return daysNumbers;
  }
};
