import { ReactNode } from 'react';

export const KeysExceptions = ['id', 'bgColor'];

export type ListCell = JSX.Element | ReactNode | string | number;
export interface ListRow {
  [key: string]: ListCell;
}

export interface ListContent<T> {
  itemWidth: string[];
  rows: T[];
  header?: { [key: string]: string };
}

export interface ContentMapperProps<T> {
  items: T[];
  onChange?: (item: T) => void;
  toggleItemHandler?: (item: T) => void;
  onDelete?: (id: string) => void;
}
