import { FC } from 'react';

import { RewardCirclePoint } from '../../../../../../common/components/previews/RewardCirclePoint/RewardCirclePoint';
import { EarningItemValues } from './EarningItem.helper';
import {
  Container,
  RewardCirclePointWrapper,
  ImageWrapper,
  ContentWrapper,
  TextWrapper,
  Title,
  Text,
  TitleWrapper,
} from './EarningItem.style';

interface EarningItemProps {
  item: EarningItemValues;
  mainColor: string;
  isTextFirst: boolean;
}
export const EarningItem: FC<EarningItemProps> = ({ item, mainColor, isTextFirst }) => {
  return (
    <Container>
      <RewardCirclePointWrapper>
        <RewardCirclePoint position="left" points={item.points} color={mainColor} size="small" />
      </RewardCirclePointWrapper>
      <ContentWrapper>
        <TitleWrapper>
          <ImageWrapper>{item.icon}</ImageWrapper>
          <Title>{item.title}</Title>
        </TitleWrapper>

        <TextWrapper revert={isTextFirst}>
          <Text>{item.description}</Text>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  );
};
