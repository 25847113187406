import React from 'react';

const UncheckedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="1" width="26" height="26" rx="5.5" fill="#F9F5FF" />
      <rect x="0.75" y="1" width="26" height="26" rx="5.5" stroke="#005F9E" />
    </svg>
  );
};

export default UncheckedIcon;
