import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { postMarketingCalculation } from '../../../../../store/slices/campaignsSlice';
import { getProperties } from '../../../../../store/slices/venueSlice';

import { FormSection } from '../FormSection';

import { CampaignsApiModel } from '../../../../../api/models/campaigns';
import { VenueProperties } from '../../../../../api/models/venue';

import { InitialValues, marketingFormFields } from '../FormSection.helper';
import { mockRecommendedValue } from './MarketingCalculationContainer.helper';
import { getConvertedRecommendedValue } from '../../RewardsWizard.helper';

interface RewardsCalculatorParams {
  monthlyDigitalMarketingBudget?: number;
  averageCartCheckout?: number;
}

interface MarketingCalculationContainerProps {
  properties: Partial<VenueProperties> | null;
  activeCampaign: CampaignsApiModel | null;
  onChangeForm: () => void;
  setCanLeave: (value: boolean) => void;
  venueId: string;
  isMobile: boolean;
}
export const MarketingCalculationContainer: React.FC<MarketingCalculationContainerProps> = ({
  properties = {},
  onChangeForm,
  setCanLeave,
  venueId,
  isMobile,
  activeCampaign,
}) => {
  const dispatch = useAppDispatch();
  const { recommendedValue } = useTypedSelector(
    (state) => state.campaigns,
  );
  const rewardsCalculatorParams = properties?.['rewards-calculator-params'] || {};
  const { monthlyDigitalMarketingBudget, averageCartCheckout } =
    rewardsCalculatorParams as RewardsCalculatorParams;
  const recommendedValues = getConvertedRecommendedValue(mockRecommendedValue, recommendedValue);

  let initValues: InitialValues | null = null;

  if (monthlyDigitalMarketingBudget && averageCartCheckout && activeCampaign) {
    initValues = {
      row1: monthlyDigitalMarketingBudget,
      row2: averageCartCheckout,
    };
  }

  const onSubmitHandler = useCallback(
    (values: InitialValues) => {
      dispatch(
        postMarketingCalculation({
          venueId,
          payload: {
            monthlyDigitalMarketingBudget: values.row1 || 0,
            averageCartCheckout: values.row2 || 0,
          },
        }),
      ).then(() => {
        dispatch(getProperties({ id: venueId }));
        setCanLeave(false);
      });
    },
    [dispatch, setCanLeave, venueId],
  );

  useEffect(() => {
    if (monthlyDigitalMarketingBudget && averageCartCheckout && activeCampaign) {
      onSubmitHandler({
        row1: monthlyDigitalMarketingBudget,
        row2: averageCartCheckout,
      });
    }
  }, [onSubmitHandler, monthlyDigitalMarketingBudget, averageCartCheckout, activeCampaign]);

  return (
    <>
      <FormSection
        onSubmit={onSubmitHandler}
        initValues={initValues}
        onChangeForm={onChangeForm}
        isMobile={isMobile}
        recommendedRewardsValue={recommendedValues}
        formFields={marketingFormFields}
      />
    </>
  );
};
