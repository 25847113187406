import { FC } from 'react';
import { Grid, Button, Typography } from '@mui/material';

import { LoginContainer, LoginForm, useLoginStyles } from '../Login.style';
import { LoginPageStrings } from '../../../common/localization/en';
import { ILoginProps } from '../Login';

export const ForgotPasswordSuccessComponent: FC<ILoginProps> = ({ setStep }) => {
  const styles = useLoginStyles();
  return (
    <>
      <LoginContainer>
        <LoginForm>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Typography className={styles.Title}>
                {LoginPageStrings.ForgotPasswordTitle}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography className={styles.SubTitle}>
                {LoginPageStrings.ForgotPasswordSuccessSubtitlePart}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setStep('login')}
                fullWidth
                className={styles.Button}
              >
                Back To Login
              </Button>
            </Grid>
          </Grid>
        </LoginForm>
      </LoginContainer>
    </>
  );
};
