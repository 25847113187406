import { FC, SetStateAction } from 'react';
import { Grid } from '@mui/material';

import { Title, Subtitle, AccountsWraper, WrapScrollBox } from './Steps.style';
import { AccountsBox } from '../../../common/components/accountsBox/AccountsBox';
import { VenueCreationWizardStrings } from '../../../common/localization/en';
import { AccountSetingsProps } from '../WizzardStepperHelper';

interface AccountsSettings {
  accounts: AccountSetingsProps[];
  setStep1State: (value: SetStateAction<AccountSetingsProps[]>) => void;
}

export const AccountSettingsStep: FC<AccountsSettings> = ({ accounts, setStep1State }) => {
  const renderAccounts = (accounts: AccountSetingsProps[]) => {
    return accounts.map((account, index) => {
      return (
        <div key={account.userAccountId}>
          <AccountsBox account={account} index={index} setStep1State={setStep1State} />
        </div>
      );
    });
  };
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>
      <Grid item xs={12}>
        <Title>{VenueCreationWizardStrings.OrganizationUsers}</Title>
        <Subtitle> {VenueCreationWizardStrings.ApplyPassword} </Subtitle>
      </Grid>
      <Grid item xs={9}>
        <WrapScrollBox>
          <AccountsWraper>{renderAccounts(accounts)}</AccountsWraper>
        </WrapScrollBox>
      </Grid>
    </Grid>
  );
};
