import { Dispatch, useReducer } from 'react';
import { formatBytes } from '../../../services/utilities';

export interface ChosenFile {
  name: string;
  localUrl: string;
  remoteUrl: string;
  size: string;
  file: File;
  isUploading: boolean;
  isUploaded: boolean;
  isUploadError: boolean;
}
export interface IDragAndDropState {
  inDropZone: boolean;
  fileList: ChosenFile[];
}

const initialDragAndDropState: IDragAndDropState = {
  inDropZone: false,
  fileList: [],
};

export enum DNDActionTypes {
  INSERT_TO_LIST = 'INSERT_TO_LIST',
  ADD_TO_LIST = 'ADD_TO_LIST',
  SET_OVER_DROPZONE = 'SET_OVER_DROPZONE',
  SET_IS_UPLOADING = 'SET_IS_UPLOADING',
  SET_IS_UPLOADED = 'SET_IS_UPLOADED',
  SET_IS_UPLOAD_ERROR = 'SET_IS_UPLOAD_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export type DNDAction =
  | { type: DNDActionTypes.RESET_STATE }
  | { type: DNDActionTypes.SET_OVER_DROPZONE; payload: boolean }
  | { type: DNDActionTypes.INSERT_TO_LIST; payload: File[] }
  | { type: DNDActionTypes.ADD_TO_LIST; payload: File[] }
  | {
      type: DNDActionTypes.SET_IS_UPLOADING;
      payload: { localUrl: string; isUploading: boolean };
    }
  | {
      type: DNDActionTypes.SET_IS_UPLOADED;
      payload: { localUrl: string; remoteUrl: string };
    }
  | {
      type: DNDActionTypes.SET_IS_UPLOAD_ERROR;
      payload: { localUrl: string };
    };

export const useDragAndDropReducer = (): [IDragAndDropState, Dispatch<DNDAction>] => {
  const dndReducer = (state: IDragAndDropState, action: DNDAction): IDragAndDropState => {
    switch (action.type) {
      case DNDActionTypes.SET_OVER_DROPZONE: {
        return { ...state, inDropZone: action.payload };
      }
      case DNDActionTypes.RESET_STATE: {
        return {
          inDropZone: false,
          fileList: [],
        };
      }
      case DNDActionTypes.ADD_TO_LIST: {
        const files = action.payload;
        const filesToAdd: ChosenFile[] = files.map((file) => {
          return {
            name: file.name,
            localUrl: URL.createObjectURL(file),
            remoteUrl: '',
            size: formatBytes(file.size),
            file: file,
            isUploading: false,
            isUploaded: false,
            isUploadError: false,
          };
        });
        return {
          ...state,
          fileList: [...state.fileList, ...filesToAdd],
        };
      }
      case DNDActionTypes.INSERT_TO_LIST: {
        const files = action.payload;
        const fileToInsert: ChosenFile[] = [
          {
            name: files[0].name,
            localUrl: URL.createObjectURL(files[0]),
            remoteUrl: '',
            size: formatBytes(files[0].size),
            file: files[0],
            isUploading: false,
            isUploaded: false,
            isUploadError: false,
          },
        ];
        return { ...state, fileList: fileToInsert };
      }
      case DNDActionTypes.SET_IS_UPLOADING: {
        const resultFiles: ChosenFile[] = state.fileList.map((_file) => {
          if (_file.localUrl === action.payload.localUrl) {
            return {
              localUrl: _file.localUrl,
              remoteUrl: _file.remoteUrl,
              isUploading: true,
              isUploaded: false,
              file: _file.file,
              name: _file.name,
              size: _file.size,
              isUploadError: false,
            };
          } else {
            return _file;
          }
        });
        return { inDropZone: state.inDropZone, fileList: resultFiles };
      }
      case DNDActionTypes.SET_IS_UPLOADED: {
        const resultFiles: ChosenFile[] = state.fileList.map((_file) => {
          if (_file.localUrl === action.payload.localUrl) {
            return {
              localUrl: _file.localUrl,
              remoteUrl: action.payload.remoteUrl,
              isUploading: false,
              isUploaded: true,
              file: _file.file,
              name: _file.name,
              size: _file.size,
              isUploadError: false,
            };
          } else {
            return _file;
          }
        });
        return { inDropZone: state.inDropZone, fileList: resultFiles };
      }
      case DNDActionTypes.SET_IS_UPLOAD_ERROR: {
        const resultFiles: ChosenFile[] = state.fileList.map((_file) => {
          if (_file.localUrl === action.payload.localUrl) {
            return {
              localUrl: _file.localUrl,
              remoteUrl: _file.remoteUrl,
              isUploading: false,
              isUploaded: false,
              file: _file.file,
              name: _file.name,
              size: _file.size,
              isUploadError: true,
            };
          } else {
            return _file;
          }
        });
        return { inDropZone: state.inDropZone, fileList: resultFiles };
      }
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(dndReducer, initialDragAndDropState);
  return [data, dispatch];
};
