import React, { useEffect, useState } from 'react';
import { AccountSidebarButton } from '../AccountSidebarButton/AccountSidebarButton';
import { Box } from '@mui/material';
import { useAccountsSidebar } from '../../useAccountsSidebar';
import { useDistributorsSelectorStyles } from './DistributorsSelector.styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DistributorsMenuIcon } from '../../../../assets/newDesign/DistributorsMenuIcon';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { toggleDistributorsMenuExpanded } from '../../../../../store/slices/uiSlice';

interface Props {
  mainDistributorIds: string[];
}

export const DistributorsSelector: React.FC<Props> = ({ mainDistributorIds }) => {
  const dispatch = useAppDispatch();
  const [subMenuMaxHeight, setSubMenuMaxHeight] = useState('');
  const { isDistributorsMenuExpanded } = useTypedSelector((state) => state.ui);

  const {
    isSidebarOpen,
    distributors,
    activeDistributor,
    handleDistributorClick,
  } = useAccountsSidebar();

  const classes = useDistributorsSelectorStyles({ isOpen: isDistributorsMenuExpanded });

  useEffect(() => {
    const resizeHandler = () => {
      const maxHeight = window.innerHeight - (mainDistributorIds.length + 1) * 43 - 250;
      if (maxHeight > 0) {
        setSubMenuMaxHeight(`${maxHeight}px`);
      }
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => window.removeEventListener('resize', resizeHandler);
  }, [mainDistributorIds.length]);

  const isActive = activeDistributor && !mainDistributorIds.includes(activeDistributor?.id);

  return (
    <>
      <AccountSidebarButton
        isActive={isActive}
        onClick={() => {
          dispatch(toggleDistributorsMenuExpanded());
        }}
      >
        <Box className={classes.buttonContainer}>
          <Box className={classes.buttonTitle}>
            <Box className={classes.iconWrapper}>
              <DistributorsMenuIcon isSelected={isActive} />
            </Box>
            Distributors
          </Box>
          <ChevronRightIcon className={classes.buttonChevron} />
        </Box>
      </AccountSidebarButton>
      <Box className={classes.distributorPopupContainer}>
        <Box
          className={classes.distributorPopup}
          sx={{
            maxHeight: subMenuMaxHeight,
            opacity: isSidebarOpen ? 1 : 0,
          }}
        >
          {distributors
            .filter((distributor) => distributor && !mainDistributorIds.includes(distributor.id))
            .map((distributor) => {
              const isActiveDistributor = distributor.id === activeDistributor?.id;
              return (
                <button
                  key={distributor.id}
                  onClick={() => {
                    handleDistributorClick(distributor);
                  }}
                  className={`${classes.distributorPopupButton} ${
                    isActiveDistributor ? classes.distributorPopupButtonActive : ''
                  }`}
                >
                  {distributor.name}
                </button>
              );
            })}
        </Box>
      </Box>
    </>
  );
};
