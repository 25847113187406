import { FC, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ClientTypes } from '../../../api/models/common';
import { AdedVideoConfiguration } from '../../../common/components/AddedVideoCinfiguration/AddedVideoConfiguration';
import { VideoPlaybackModal } from '../../../common/components/NewDetailsModal/DetailsModalContent/VideoPlaybackModal';
import { SocialVerseStrings } from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { resetNewFilter } from '../../../store/slices/searchFiltersSlice';
import {
  AddVideoToSocialVerse,
  getSocialVerseById,
  GetVideosBySocialVerseId,
} from '../../../store/slices/socialVerseSlice';
import { getVideoByIdForSocialVerse } from '../../../store/slices/videosSlice';
import { getTopGolfVideoByIdForSocialVerse } from '../../../store/slices/topGolfVideosSlice';
import { ContentTableUserVideos } from '../ContentTableUserVideos/ContentTableUserVideos';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';

export const Addvideos: FC = () => {
  const params = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();

  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const [isOpenPlaybackModal, setIsOpenPlaybaclModal] = useState(false);

  const [videoUrl, setVideoUrl] = useState('');

  const isTopgolf = clientType === ClientTypes.TOPGOLF;

  useEffect(() => {
    if (params.id) {
      dispatch(getSocialVerseById({ id: params.id }));
      dispatch(GetVideosBySocialVerseId({ id: params.id }));
    }
  }, [params.id, dispatch]);

  const handleCloseVideoPlaybackModal = () => {
    setIsOpenPlaybaclModal(!isOpenPlaybackModal);
    trackEvent(SocialVerseEventNames.closeVideoPlayback, { socialVerseId: params.id });
  };

  const handleOpenPlaybackVideo = (videoUrl: string) => {
    setIsOpenPlaybaclModal(true);
    setVideoUrl(videoUrl);
    trackEvent(SocialVerseEventNames.openVideoPlayback, {
      socialVerseId: params.id,
      videoUrl: videoUrl,
    });
  };
  const handleAddVideosToCard = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean = false,
  ) => {
    if (addUsingRowBtn) {
      event.stopPropagation();
      if (event.currentTarget) event.currentTarget.disabled = true;
    }

    const addVideo = () => {
      dispatch(AddVideoToSocialVerse({ videoId: id, id: params.id }))
        .then(() => {
          if (isTopgolf) {
            dispatch(getTopGolfVideoByIdForSocialVerse({ id }));
          } else {
            dispatch(getVideoByIdForSocialVerse({ id }));
          }
          dispatch(GetVideosBySocialVerseId({ id: params.id }));
          addToast(SocialVerseStrings.savingMessage, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
          trackEvent(SocialVerseEventNames.addSocialVerseVideo, {
            socialVerseId: params.id,
            videoId: id,
          });
        })
        .catch(() => {
          addToast('Error adding video', {
            appearance: 'error',
            autoDismiss: true,
          });
        });
    };

    addVideo();
  };

  const handleReplace = (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => {
    if (addIdParams.addUsingRowBtn) {
      addIdParams.event.stopPropagation();
    }

    addToast(<div>{SocialVerseStrings.ExistingVideoMessage}</div>, {
      appearance: 'warning',
    });
  };

  useEffect(() => {
    return () => {
      dispatch(resetNewFilter());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AdedVideoConfiguration key={params.id} handlePlayVideo={handleOpenPlaybackVideo} />
      <ContentTableUserVideos
        handleAddVideosToCard={handleAddVideosToCard}
        handleVideoReplace={handleReplace}
      />

      <VideoPlaybackModal
        isTopGolf={isTopgolf}
        isOpen={isOpenPlaybackModal}
        onClose={handleCloseVideoPlaybackModal}
        videoUrl={videoUrl}
      />
    </>
  );
};
