import React, { FC } from 'react';
import { Grid, Button, Box } from '@mui/material';

import { RewardsPageStrings, CommonStrings } from '../../../../../common/localization/en';

import { FSModal } from '../../../../../common/components/modal/Modal';
import { ModalTitle } from '../../../../../common/components/modal/Modal.style';
import { useDragAndDropReducer } from '../../../../../common/components/dragAndDrop/DragAndDropState';
import { DragAndDrop } from '../../../../../common/components/dragAndDrop/DragAndDrop';
import { ChosenFile } from '../../../../../common/components/dragAndDrop/DragAndDropState';
import { useStyles } from './CSVUploadModal.helper';

interface CSVUploadModalProps {
  isModal: boolean;
  onClose: () => void;
  onConfirm: (file: File) => void;
}
export const CSVUploadModal: FC<CSVUploadModalProps> = ({ isModal, onClose, onConfirm }) => {
  const classes = useStyles();
  const [uploadFileData, uploadFileDispatch] = useDragAndDropReducer();
  const isFile = uploadFileData.fileList.length;

  const resendMediaFileHandler = (file: ChosenFile) => {
    onConfirm(file.file);
  };

  const onConfirmHandler = () => {
    if (isFile) {
      onConfirm(uploadFileData.fileList[0].file);
    }
  };

  return (
    <>
      <FSModal modalIsOpen={isModal} onClose={onClose}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={8}>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12}>
                <ModalTitle>{RewardsPageStrings.UploadCSVFile}</ModalTitle>
              </Grid>
              <Grid item xs={12}>
                <DragAndDrop
                  resendMediaFile={(file) => resendMediaFileHandler(file)}
                  data={uploadFileData}
                  dispatch={uploadFileDispatch}
                  acceptableType=".csv"
                />
              </Grid>
              <Box mt={3} display="flex">
                <Box mr={3}>
                  <Grid item xs={2}>
                    <Button color="primary" onClick={onClose}>
                      {CommonStrings.Cancel}
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={2}>
                  <Button
                    className={classes.confirmButton}
                    disabled={!isFile}
                    variant="contained"
                    color="primary"
                    onClick={onConfirmHandler}
                  >
                    {CommonStrings.ApplyContinue}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </FSModal>
    </>
  );
};
