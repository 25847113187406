import { FC, useState, ReactNode } from 'react';
import { Grid, FormControlLabel, Radio, RadioGroup, Box, Typography } from '@mui/material';
import { KPIDTOActiveAnalyticsBlock } from '../../../store/storeModels';
import { FormControlFilterUser, useStyles } from './TopbarGraph.style';
import { DateRangeFilter } from '../datePicker/DateRangeFilter';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setIsCummulativeGraph } from '../../../store/slices/uiSlice';
import { ReturnedDate } from './../datePicker/DateRangeFilter';
import { updateFilter } from '../../../store/slices/analyticsSlice';
import { KPIApiNames } from '../../../api/models/analytics';
import { CommonStrings } from '../../localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import { PerfomanceEvents } from '../../constants/events/perfomanceEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

export interface TopbarGraphProps {
  activeAnalyticsBlock: KPIDTOActiveAnalyticsBlock;
  dropdownComponent: ReactNode;
}
export const TopbarGraph: FC<TopbarGraphProps> = ({ activeAnalyticsBlock, dropdownComponent }) => {
  enum GraphTypes {
    cumulative = 'cumulative',
    perday = 'perday',
  }
  const [value, setValue] = useState('');
  const dispatch = useAppDispatch();
  const { isCummulativeGraph } = useTypedSelector((state) => state.ui);
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = event.target.value;
    setValue(newValue);
    dispatch(setIsCummulativeGraph(newValue === GraphTypes.cumulative));

    const isCummulative = newValue === GraphTypes.cumulative;
    trackEvent(
      isCummulative
        ? PerfomanceEvents.Perfomance_Cumulative_Radio_Click
        : PerfomanceEvents.Perfomance_Perday_Radio_Click,
    );
  }
  const classes = useStyles();
  const renderDropDownComponent = () => {
    if (activeAnalyticsBlock.main === KPIApiNames.shares) {
      return dropdownComponent ? dropdownComponent : null;
    }
  };
  const updateFilterDate = (date: ReturnedDate | null) => {
    dispatch(updateFilter(date));

    trackEvent(
      date
        ? PerfomanceEvents.Perfomance_Apply_Date_Filter
        : PerfomanceEvents.Perfomance_Reset_Date_Filter,
    );
  };

  return (
    <Box className={classes.topbarGraphContainer}>
      <Grid container justifyContent="center" alignItems="center" flexWrap="wrap" spacing={1}>
        <Grid item xs={3}>
          <Typography component="span" className={classes.textTitle} id="chart-topbar-title">
            {isHealthCare && activeAnalyticsBlock.mainName === 'Creators'
              ? CommonStrings.Patients
              : activeAnalyticsBlock.mainName}
          </Typography>
        </Grid>
        <Grid item display="flex" justifyContent="center" xs={3}>
          <FormControlFilterUser>
            <RadioGroup className={classes.root} value={value} onChange={handleChange}>
              <FormControlLabel
                checked={isCummulativeGraph}
                className={`${classes.label} ${isCummulativeGraph ? classes.chacked : ''}`}
                value={GraphTypes.cumulative}
                control={<Radio className={classes.input} />}
                label="Cumulative"
              />
              <FormControlLabel
                checked={!isCummulativeGraph}
                className={`${classes.label} ${!isCummulativeGraph ? classes.chacked : ''}`}
                value={GraphTypes.perday}
                control={<Radio className={classes.input} />}
                label="Per Day"
              />
            </RadioGroup>
          </FormControlFilterUser>
        </Grid>
        <Grid item xs={3}>
          {renderDropDownComponent()}
        </Grid>
        <Grid item xs={3}>
          <DateRangeFilter updateRange={updateFilterDate} width="100%" />
        </Grid>
      </Grid>
    </Box>
  );
};
