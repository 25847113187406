import { theme } from '../../theme';

export const SearchButtonIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill={theme.palette.primary.main} />
      <path
        d="M6.5 10.6667L16.5 10.6667M16.5 10.6667C16.5 12.0475 17.6193 13.1667 19 13.1667C20.3807 13.1667 21.5 12.0475 21.5 10.6667C21.5 9.28604 20.3807 8.16675 19 8.16675C17.6193 8.16675 16.5 9.28604 16.5 10.6667ZM11.5 17.3334L21.5 17.3334M11.5 17.3334C11.5 18.7141 10.3807 19.8334 9 19.8334C7.61929 19.8334 6.5 18.7141 6.5 17.3334C6.5 15.9527 7.61929 14.8334 9 14.8334C10.3807 14.8334 11.5 15.9527 11.5 17.3334Z"
        stroke="white"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
