import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '28px 45px 31px 45px',
  },
  title: {
    textAlign: 'center',
    color: '#101828',
    fontSize: '36px',
    fontWeight: 600,
  },
  videoCounter: {
    textAlign: 'center',
    fontWeight: 500,
  },
  supportingText: {
    textAlign: 'center',
    color: '#475467',
    fontSize: '20px',
  },
  contentWrapper: {
    width: 'fit-content',
    margin: '11px auto 0 auto',
  },
  gridWrapper: {
    width: 'fit-content',
    display: 'grid',
    overflow: 'hidden',
    columnGap: '5px',
    rowGap: '10px',
  },
  image: {
    height: 'auto',
  },
  bottomWrapper: {
    width: '100%',
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  videos: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#344054',
  },
  toTop: {
    width: '125px',
    height: '36px',
    display: 'grid',
    placeItems: 'center',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    fontSize: '14px',
    fontWeight: 600,
    color: '#344054',
  },
  videoWrapper: {
    position: 'relative',
  },
  creatorName: {
    position: 'absolute',
    top: '10px',
    left: '14px',
    color: 'white',
    fontWeight: 600,
    fontSize: '14px',
  },
  duration: {
    position: 'absolute',
    top: '33px',
    left: '14px',
    color: theme.palette.common.white,
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0.02em',
    textAlign: 'left',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) 30%)',
  },
}));
