import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    userSelect: 'none',
    width: 'fit-content',
  },
  title: {
    position: 'absolute',
    top: '-25px',
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
  },
  select: {
    border: '1px solid',
    borderRadius: '8px',
    padding: '10px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    cursor: 'pointer',
    backgroundColor: '#FFF',
  },
  arrow: {
    marginLeft: '15px',
    transition: '0.11s',
  },
  optionsWrapper: {
    position: 'absolute',
    left: 0,
    minWidth: '100%',
    width: 'fit-content',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '2px 6px',
    color: '#667085',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    marginTop: '5px',
    backgroundColor: '#fff',
    zIndex: 10,
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      width: '8px',
      backgroundColor: '#D0D5DD',
      borderRadius: '8px',
    },
  },
  option: {
    width: '100%',
    height: '44px',
    padding: '0 10px',
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  disabledOption: {
    color: '#EAECF0',
    cursor: 'not-allowed',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  selected: {
    backgroundColor: '#F9FAFB',
  },
  checkmark: {
    width: '30px',
    height: '20px',
    marginLeft: 'auto',
    paddingLeft: '10px',

    '& path': {
      stroke: '#7F56D9',
    },
  },
  placeholder: {
    color: '#667085',
  },
  input: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
