import { FC, ReactNode } from 'react';
import Battery6BarIcon from '@mui/icons-material/Battery6Bar';
import NetworkWifiOutlinedIcon from '@mui/icons-material/NetworkWifiOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';

import { useStyles } from './phonePreviewWrapper.helper';

interface IphoneXframeProps {
  children?: ReactNode;
  color?: 'black' | 'white';
  showBar?: boolean;
  showNavButtons?: boolean;
  width?: string;
}

/**
 * @deprecated
 * Please use IPhonePreview component instead
 * src/common/components/IPhonePreview/IPhonePreview.tsx
 */
export const IphoneXframeDeprecated: FC<IphoneXframeProps> = ({
  children,
  color = 'white',
  showBar,
  showNavButtons,
  width,
}) => {
  const classes = useStyles({ color, width });

  return (
    <div className={classes.root}>
      <div
        style={{
          boxShadow: `inset 0 0 2px 2px ${
            color === 'white' ? `var(--device-silver-dark)` : 'var(--black)'
          }, inset 0 0 0 7px ${color === 'white' ? 'var(--device-silver)' : 'var(--black)'}`,
          background: color === 'white' ? 'var(--device-silver-panel)' : 'var(--black)',
          overflow: 'visible',
        }}
        className={classes.frame}
      >
        {children}
      </div>
      <div className={classes.stripe} />
      <div
        style={{ background: color === 'white' ? 'var(--device-silver-panel)' : 'var(--black)' }}
        className={classes.header}
      />
      <div className={classes.sensors} />
      <div className={classes.btns} />
      <div className={classes.volume} />
      {showBar && (
        <div className={classes.topBar}>
          <span className={classes.time}>9:45</span>

          <span className={classes.webSite}> socialv.io</span>
          <span className={classes.connection}>
            <SignalCellularAltOutlinedIcon />
            <NetworkWifiOutlinedIcon />
            <Battery6BarIcon />
          </span>
        </div>
      )}
      {showNavButtons && (
        <div className={classes.navBtns}>
          <ArrowBackIosNewRoundedIcon />
          <ArrowBackIosNewRoundedIcon className={classes.arrRight} />
          <IosShareRoundedIcon />
          <MenuBookRoundedIcon />
          <ContentCopyOutlinedIcon />
        </div>
      )}
    </div>
  );
};
