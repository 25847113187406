export const SharesIconNew = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4362 12.9061L6.93115 15.4302L7.49018 11.6316L8.24886 10.9813L10.4362 12.9061Z"
        fill="#8B89A0"
        stroke="#8B89A0"
        strokeWidth="0.948237"
        strokeLinejoin="round"
      />
      <path
        d="M1.46806 7.5898L17.9948 1.12444C18.5377 0.912056 19.1004 1.38485 18.9847 1.95623L16.0739 16.3365C15.9672 16.8636 15.3498 17.1022 14.9164 16.7838L6.93171 10.9194C6.87327 10.8765 6.8088 10.8424 6.74041 10.8183L1.49174 8.97001C0.847945 8.7433 0.83242 7.83847 1.46806 7.5898Z"
        fill="#8B89A0"
        stroke="#8B89A0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.21094 10.2754L16.7871 3.15344L8.00235 11.6587L7.29052 14.4616C7.16362 14.9612 6.45511 14.965 6.32292 14.4667L5.21094 10.2754Z"
        fill="white"
        stroke="#8B89A0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
