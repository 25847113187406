import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { FSModal } from '../../../common/components/modal/Modal';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { RewardsStrings, RewardsPageStrings } from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { postEndIncentiveCampaign } from '../../../store/slices/campaignsSlice';

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    height: '100px',
    width: '100px',
  },
  title: {
    marginTop: '20px',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
  },
  text: {
    color: theme.palette.common.gray,
    marginTop: '20px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '23px',
    letterSpacing: '0.02em',
    textAlign: 'center',
  },
  endButton: {
    marginTop: '34px',
    borderRadius: '50px',
  },
  cancelButton: {
    marginTop: '10px',
    borderRadius: '50px',
  },
}));

interface EndCampaignModalProps {
  endCampaignModalVisible: boolean;
  onClose: () => void;
  currentRewardCampaign?: CampaignsApiModel;
  onTrackEvent?: (event: string) => void;
}

const EndCampaignModal: FC<EndCampaignModalProps> = ({
  endCampaignModalVisible,
  onClose,
  currentRewardCampaign,
  onTrackEvent,
}) => {
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState(false);
  const { activeCampaign, isLoading, error } = useTypedSelector((state) => state.campaigns);
  const classes = useStyles();

  const onConfirm = () => {
    if (currentRewardCampaign) {
      dispatch(postEndIncentiveCampaign(currentRewardCampaign.id)).then(() => {
        addToast(RewardsStrings.endCampaignMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
    } else if (activeCampaign) {
      dispatch(postEndIncentiveCampaign(activeCampaign.id)).then(() => {
        addToast(RewardsStrings.endCampaignMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
    }
    onTrackEvent &&
      onTrackEvent(RewardCampaignsEvents.RewardCampaignsDetailsEndCampaignConfirmClick);
    onClose();
    return;
  };

  useEffect(() => {
    if (isLoading) {
      setDisabled(true);
    } else if (disabled) {
      if (!currentRewardCampaign && error) {
        addToast('Failed to end campaign, try again', { appearance: 'error' });
      }
      setDisabled(false);
    }
  }, [isLoading, disabled, error, addToast, currentRewardCampaign]);

  return (
    <FSModal onClose={onClose} modalIsOpen={endCampaignModalVisible} width="500px" rounded="15px">
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <img className={classes.image} src="/campaign-modal.svg" alt="" />
        <Typography className={classes.title}>{RewardsPageStrings.EndCampaignTitle}</Typography>
        {currentRewardCampaign && (
          <Typography className={classes.text}>
            {RewardsPageStrings.EndCampaignConfirmationText}
          </Typography>
        )}
        <Button
          className={classes.endButton}
          size="large"
          onClick={onConfirm}
          variant="contained"
          color="primary"
          disabled={disabled}
        >
          {RewardsPageStrings.EndCampaignConfirmationButton}
        </Button>
        <Button
          className={classes.cancelButton}
          size="large"
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          {RewardsPageStrings.CancelEndCampaignButton}
        </Button>
      </Grid>
    </FSModal>
  );
};

export default EndCampaignModal;
