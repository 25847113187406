import React from 'react';

const QuickstartIcon = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#ECEAFF" />
      <path
        d="M34 28.7397V16.6697C34 15.4697 33.02 14.5797 31.83 14.6797H31.77C29.67 14.8597 26.48 15.9297 24.7 17.0497L24.53 17.1597C24.24 17.3397 23.76 17.3397 23.47 17.1597L23.22 17.0097C21.44 15.8997 18.26 14.8397 16.16 14.6697C14.97 14.5697 14 15.4697 14 16.6597V28.7397C14 29.6997 14.78 30.5997 15.74 30.7197L16.03 30.7597C18.2 31.0497 21.55 32.1497 23.47 33.1997L23.51 33.2197C23.78 33.3697 24.21 33.3697 24.47 33.2197C26.39 32.1597 29.75 31.0497 31.93 30.7597L32.26 30.7197C33.22 30.5997 34 29.6997 34 28.7397Z"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 17.4902V32.4902"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.75 20.4902H17.5"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 23.4902H17.5"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default QuickstartIcon;
