import { VideoAppTheme } from '../api/models/venue';
import { useTypedSelector } from '../store';

export const useThemeLayout = () => {
  const { venueAttributes } = useTypedSelector((state) => state.venue);
  const theme = venueAttributes.properties?.['webapp.config']?.theme || VideoAppTheme.Dark;
  const isThemeLayoutEnabled = venueAttributes.properties?.['webapp.config']?.isThemeLayoutEnabled;

  const textColorMap: Record<VideoAppTheme, string> = {
    [VideoAppTheme.Dark]: '#fff',
    [VideoAppTheme.Light]: '#1A1A1A',
  };

  const backgroundColorMap: Record<VideoAppTheme, string> = {
    [VideoAppTheme.Dark]: '#000',
    [VideoAppTheme.Light]: '#fff',
  };

  return {
    theme,
    isThemeLayoutEnabled,
    themeTextColor: textColorMap[theme],
    themeBackgroundColor: backgroundColorMap[theme],
    isLightTheme: isThemeLayoutEnabled && theme === VideoAppTheme.Light,
    isDarkTheme: isThemeLayoutEnabled && theme === VideoAppTheme.Dark,
  };
};
