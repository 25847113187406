import { FC } from 'react';

export const PlayIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="10" fill="#F2F4F7" />
      <path
        d="M14.9453 9.3562C15.612 9.7411 15.612 10.7033 14.9453 11.0882L8.61198 14.7448C7.94531 15.1297 7.11198 14.6486 7.11198 13.8788L7.11198 6.56567C7.11198 5.79587 7.94531 5.31474 8.61198 5.69964L14.9453 9.3562Z"
        fill="#475467"
      />
    </svg>
  );
};
