import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface Props {
  isNullReward?: boolean;
}

export const useCouponStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    position: 'relative',
    width: 'fit-content',
    overflow: 'hidden',
  },
  logo: () => ({
    position: 'absolute',
    filter: 'none',
    left: '30px',
    top: '30px',
    height: '60px',
    width: 'auto',
    maxWidth: '160px',
    objectFit: 'contain',
  }),
  companyBadge: {
    position: 'absolute',
    filter: 'none',
    left: '30px',
    top: '30px',
    width: '160px',
    padding: '7px 9px',
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  companyName: {
    fontWeight: '800',
    // fontFamily: 'Poppins',
    fontSize: '20px',
    lineHeight: '1.5',
    letterSpacing: '0.02em',
    color: '#000',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    wordBreak: 'break-word',
  },
  congratsText: ({ isNullReward }) => ({
    fontWeight: isNullReward ? '600' : '700',
    // fontFamily: 'Poppins',
    height: isNullReward ? '35px' : 'auto',
    fontSize: '40px',
    lineHeight: isNullReward ? '29px' : '1.5',
    letterSpacing: '0.045em',
    color: '#fff',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    textAlign: 'right',
    wordBreak: 'break-all',
    textTransform: 'uppercase',
  }),
  couponText: {
    fontWeight: '400',
    // fontFamily: 'Poppins',
    fontSize: '10px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: '#fff',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    textAlign: 'right',
  },
  rewardText: ({ isNullReward }) => ({
    fontWeight: isNullReward ? '700' : '800',
    // fontFamily: 'Poppins',
    fontSize: isNullReward ? '30px !important' : '40px',
    lineHeight: isNullReward ? '35px' : '1',
    letterSpacing: '0.02em',
    color: '#fff',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    wordBreak: 'break-word',
    textAlign: isNullReward ? 'center' : 'right',
    marginTop: isNullReward ? '28px' : '0',
  }),
  rewardTextForNullReward: {
    position: 'absolute',
    filter: 'none',
    left: '50%',
    top: '50%',
    maxWidth: '325px',
    transform: 'translate(-50%, -50%)',
  },
  cardNumber: {
    fontWeight: '600',
    fontFamily: 'Poppins',
    fontSize: '32px',
    lineHeight: '35px',
    letterSpacing: '0.045em',
    color: '#fff',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    textAlign: 'left',
  },
  cardOwner: {
    fontWeight: '500',
    fontFamily: 'Poppins',
    fontSize: '22px',
    lineHeight: '1',
    letterSpacing: '0.045em',
    color: '#fff',
    textShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
    textAlign: 'left',
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  },
  card: {
    width: '502px',
    height: '320px',
  },
}));
