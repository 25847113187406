import React from 'react';
import { theme } from '../../theme';

const PurpleConfirmIcon = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="13"
        cy="13"
        r="12.5"
        fill={theme.palette.primary.main}
        stroke={theme.palette.primary.main}
      />
      <path
        d="M19.6666 8L10.4999 17.1667L6.33325 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PurpleConfirmIcon;
