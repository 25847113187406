import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { theme } from '../../../theme';

interface ModalHeaderProps {
  onClose: () => void;
  text?: string;
}
export const ModalHeader: FC<ModalHeaderProps> = ({ onClose, text }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom={`1px solid ${theme.palette.divider}`}
      paddingX={4}
      paddingY="20px"
    >
      <Box mr={2}>
        <IconButton color="primary" onClick={onClose} size="large">
          <ArrowForward />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto" color={theme.palette.text.primary}>
        {text}
      </Box>
    </Box>
  );
};
