import React, { FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './EditWelcomeVideo.styles';
import { CampaignsApiModel } from '../../../../../../api/models/campaigns';
import { Box, Button, ButtonGroup, Typography, useTheme } from '@mui/material';
import { WelcomeVideoModalStrings } from '../../../../../../common/localization/en';
import WelcomeVideoIPhonePreview, {
  defaultVideos,
} from '../../../../../NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import AddVideo from '../AddVideo/AddVideo';
import RecordVideo from '../RecordVideo/RecordVideo';
import {
  createStorageUploadUrl,
  uploadVideoAsBlob,
  reset,
} from '../../../../../../store/slices/uploadVideoSlice';
import { MediaFilePrefix } from '../../../../../../api/models/common';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { getUpdatedVideoUrl } from '../../../../../../common/components/VideoUploader/VideoUplader.helpers';
import { LinearProgressWithLabel } from '../../../../../../common/components/LinearProgressBar/LinearProgress.helper';
import { downloadMedia, getProperCloudinaryUrl } from '../../../../../../services/utilities';
import { LoadingButton } from '@mui/lab';
import {
  getCampaignById,
  patchIncentiveCampaign,
} from '../../../../../../store/slices/campaignsSlice';
import CreateVideoPreview from '../../../../../../common/components/previews/CreateVideoPreview/CreateVideoPreview';
import { useVenue } from '../../../../../../hooks/useVenue';
import { useBackgroundImage } from '../../../../../../hooks/webApp/useBackgroundImage';
import { useThemeLayout } from '../../../../../../hooks/useThemeLayout';
import { useClientType } from '../../../../../../services/hooks/useClientType';

export const checkIsWelcomeVideoDefault = (videoUrL: string | null) => {
  if (!videoUrL) return false;

  let isDefault = false;
  for (const video of Object.values(defaultVideos)) {
    if (video === videoUrL) {
      isDefault = true;
    }
  }

  return isDefault;
};

enum WelcomeVideoOptions {
  Inspirational = 'inspirational',
  Custom = 'custom',
  None = 'none',
}

export const getWelcomeVideoOption = (
  isDisplayWelcomeVideo: boolean | null,
  videoUrl: string | null,
  videoStyle: string | undefined,
) => {
  const isVideoDefault = checkIsWelcomeVideoDefault(videoUrl);

  if (isDisplayWelcomeVideo === null) {
    return videoStyle || 'none';
  }

  if (isDisplayWelcomeVideo && isVideoDefault) {
    return WelcomeVideoOptions.Inspirational;
  }

  if (isDisplayWelcomeVideo && !isVideoDefault) {
    return WelcomeVideoOptions.Custom;
  }

  return WelcomeVideoOptions.None;
};

interface Props {
  currentCampaign: CampaignsApiModel;
  onCancel: () => void;
  onClose: () => void;
  isUwmLayout?: boolean;
  setVideoUrl?: (url: string) => void;
}

const EditWelcomeVideo: FC<Props> = ({
  currentCampaign,
  onCancel,
  onClose,
  isUwmLayout,
  setVideoUrl,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { isUwmClient } = useClientType();

  const [selectedVideoOption, setSelectedVideoOption] = useState<string>(
    isUwmLayout
      ? WelcomeVideoOptions.Custom
      : getWelcomeVideoOption(
          currentCampaign.showWelcomeVideo,
          currentCampaign.welcomeVideoUrl,
          currentCampaign.recommendedVideoStyle,
        ),
  );
  const [welcomeVideoUrl, setWelcomeVideoUrl] = useState<string | null>(
    currentCampaign.welcomeVideoUrl ||
      (isUwmClient ? defaultVideos.defaultUwm : defaultVideos.default),
  );
  const [isRecordMode, setIsRecordMode] = useState(false);
  const [recordedVideoBlob, setRecordedVideoBlob] = useState<string | null>(null);
  const [uploadingError, setUploadingError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const { backgroundImage } = useBackgroundImage();

  const { theme: themeLayout } = useThemeLayout();
  const theme = useTheme();

  const { venueProperties, logo } = useVenue();
  const launchScreenOptions = venueProperties?.['webapp.launch-screen'];
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const handleSelectedWelcomeVideoOptionChange = (value: string) => {
    setSelectedVideoOption(value);

    if (isRecordMode) {
      handleRecordMode();
    }
  };

  const handleVideoUrlChange = (url: string) => {
    setWelcomeVideoUrl(url);
  };

  const handleRemoveVideo = () => {
    setWelcomeVideoUrl(isUwmClient ? defaultVideos.defaultUwm : defaultVideos.default);
  };

  const handleRecordMode = () => {
    setIsRecordMode(!isRecordMode);
  };

  const handleCancel = () => {
    if (isRecordMode) {
      setIsRecordMode(false);
      setRecordedVideoBlob(null);
    } else {
      onCancel();
    }
    dispatch(reset());
  };

  const handleVideoRecorded = (blob: string) => {
    setRecordedVideoBlob(blob);

    submitVideo(blob);
  };

  const uploadAbortController = useRef(new AbortController());

  const onUploadProgress = (event: ProgressEvent) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgress(percent);
  };

  const submitVideo = async (recordedVideoBlob: string) => {
    if (!recordedVideoBlob) return;

    return new Promise(async (resolve, reject) => {
      try {
        uploadAbortController.current.signal.addEventListener('abort', (e) => {
          reject('upload aborted');
        });

        setProgress(0);
        setUploadingError(false);
        setIsUploading(true);

        const res = await createStorageUploadUrl({ ext: 'mp4', prefix: MediaFilePrefix.Welcome });
        if (!res) {
          return reject('createStorageUploadUrl failed');
        }

        const welcomeVideoUrl = res.downloadUrl;
        if (!welcomeVideoUrl) {
          return reject('no downloadUrl');
        }

        await dispatch(
          uploadVideoAsBlob({
            options: res,
            data: recordedVideoBlob,
            onUploadProgress,
          }),
        );

        resolve(welcomeVideoUrl);
      } catch (error) {
        reject(error);
      }
    })
      .then(async (welcomeVideoUrl) => {
        if (!welcomeVideoUrl || typeof welcomeVideoUrl !== 'string') return;

        const url = await getUpdatedVideoUrl(welcomeVideoUrl);
        setWelcomeVideoUrl(url);
        setIsRecordMode(false);
        setRecordedVideoBlob(null);
      })
      .catch((error) => {
        if (uploadAbortController.current.signal.aborted) {
          // reset abort controller
          uploadAbortController.current = new AbortController();
        } else {
          console.error(error);
          setUploadingError(true);
        }
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const assets =
    process.env.REACT_APP_BUILD_ENV === 'production' ? ',l_sv-prod-assets' : ',l_sv-dev-assets';
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);

  const downloadFile = () => {
    if (!welcomeVideoUrl) return;

    if (welcomeVideoUrl.includes('ar_9:16')) {
      downloadMedia(welcomeVideoUrl);
      setIsDownloading(true);
      setTimeout(() => {
        setIsDownloading(false);
      }, 1000);
    } else {
      setIsDownloading(true);
      downloadMedia(getProperCloudinaryUrl(welcomeVideoUrl, venueId, assets)).then(() => {
        setIsDownloading(false);
      });
    }
  };

  const isConfirmButtonDisabled = () => {
    const isMatchingVideoOption = currentCampaign.recommendedVideoStyle === selectedVideoOption;
    const isVideoDefault = checkIsWelcomeVideoDefault(welcomeVideoUrl);

    return (
      (isMatchingVideoOption && !currentCampaign.showWelcomeVideo) ||
      (isMatchingVideoOption && welcomeVideoUrl === currentCampaign.welcomeVideoUrl) ||
      (isVideoDefault && selectedVideoOption === WelcomeVideoOptions.Custom)
    );
  };

  const handleConfirm = () => {
    const isVideoDefault = checkIsWelcomeVideoDefault(welcomeVideoUrl);

    let urlToSave;
    if (
      !welcomeVideoUrl ||
      selectedVideoOption === WelcomeVideoOptions.None ||
      selectedVideoOption === WelcomeVideoOptions.Inspirational ||
      isVideoDefault
    ) {
      urlToSave = isUwmClient ? defaultVideos.defaultUwm : defaultVideos.default;
    } else {
      urlToSave = welcomeVideoUrl;
    }

    let videoOptionToSave;
    if (isVideoDefault && selectedVideoOption === WelcomeVideoOptions.Custom) {
      videoOptionToSave = WelcomeVideoOptions.Inspirational;
    } else {
      videoOptionToSave = selectedVideoOption;
    }

    setIsConfirmLoading(true);
    dispatch(
      patchIncentiveCampaign({
        campaignId: currentCampaign.id,
        values: {
          showWelcomeVideo: selectedVideoOption !== WelcomeVideoOptions.None,
          welcomeVideoUrl: urlToSave,
          recommendedVideoStyle: videoOptionToSave,
        },
      }),
    )
      .then(() => {
        dispatch(reset());
        dispatch(getCampaignById(currentCampaign.id));
      })
      .then(() => {
        setIsConfirmLoading(false);
        onClose();
      });
  };

  const getVideoUrl = () => {
    if (
      welcomeVideoUrl &&
      !checkIsWelcomeVideoDefault(welcomeVideoUrl) &&
      selectedVideoOption !== WelcomeVideoOptions.Inspirational
    ) {
      return welcomeVideoUrl!;
    } else {
      return isUwmClient ? defaultVideos.defaultUwm : defaultVideos.default;
    }
  };

  const videoUrl = getVideoUrl();

  useEffect(() => {
    setVideoUrl?.(videoUrl);
  }, [setVideoUrl, videoUrl]);

  const getLeftSizeContent = (option: string | undefined) => {
    if (option === WelcomeVideoOptions.Inspirational) {
      return <Typography className={styles.hint}>{WelcomeVideoModalStrings.Hint}</Typography>;
    }

    if (option === WelcomeVideoOptions.Custom) {
      return (
        <AddVideo
          welcomeVideoUrl={welcomeVideoUrl}
          onWelcomeVideoUploadComplete={handleVideoUrlChange}
          onRemove={handleRemoveVideo}
          onRecordClick={handleRecordMode}
          isRecordMode={isRecordMode}
        />
      );
    }

    return (
      <>
        <Typography className={styles.noVideoTitle}>
          {WelcomeVideoModalStrings.NoWelcomeVideoTitle}
        </Typography>
        <Typography className={styles.noVideoText}>
          <b>{WelcomeVideoModalStrings.Note}</b>
          {WelcomeVideoModalStrings.NoWelcomeVideoText}
        </Typography>
      </>
    );
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.leftSideWrapper} paddingTop={isUwmLayout ? '10px' : '32px'}>
        {!isUwmLayout && (
          <Box className={styles.toggleWrapper} width={isUwmLayout ? '354px' : 'auto'}>
            <ButtonGroup fullWidth>
              <Button
                className={styles.videoOptionButton}
                onClick={() =>
                  handleSelectedWelcomeVideoOptionChange(WelcomeVideoOptions.Inspirational)
                }
                sx={{
                  border:
                    selectedVideoOption === WelcomeVideoOptions.Inspirational
                      ? `2px solid ${theme.palette.primary.main} !important`
                      : '2px solid #8B89A0',
                  borderRightColor:
                    selectedVideoOption === WelcomeVideoOptions.Inspirational
                      ? `${theme.palette.primary.main} !important`
                      : 'transparent',
                  borderRadius: '50px 0 0 50px',
                  background:
                    selectedVideoOption === WelcomeVideoOptions.Inspirational ? '#F9F5FF' : '#FFF',
                  color:
                    selectedVideoOption === WelcomeVideoOptions.Inspirational
                      ? theme.palette.primary.main
                      : '#8B89A0',
                  zIndex: selectedVideoOption === WelcomeVideoOptions.Inspirational ? 1 : 0,
                }}
              >
                {WelcomeVideoModalStrings.Default}
              </Button>
              <Button
                className={styles.videoOptionButton}
                onClick={() => handleSelectedWelcomeVideoOptionChange(WelcomeVideoOptions.Custom)}
                sx={{
                  borderRadius: isUwmLayout || isUwmClient ? '0 50px 50px 0 !important' : '1px',
                  borderColor:
                    selectedVideoOption === WelcomeVideoOptions.Custom
                      ? theme.palette.primary.main
                      : '#8B89A0',
                  borderRightColor:
                    selectedVideoOption === WelcomeVideoOptions.Custom
                      ? `${theme.palette.primary.main} !important`
                      : isUwmLayout || isUwmClient
                      ? '#8B89A0'
                      : 'transparent',
                  zIndex: selectedVideoOption === WelcomeVideoOptions.Custom ? 1 : 0,
                  background:
                    selectedVideoOption === WelcomeVideoOptions.Custom ? '#F9F5FF' : '#FFF',
                  color:
                    selectedVideoOption === WelcomeVideoOptions.Custom
                      ? theme.palette.primary.main
                      : '#8B89A0',
                }}
              >
                {WelcomeVideoModalStrings.Custom}
              </Button>
              {!isUwmClient && (
                <Button
                  className={styles.videoOptionButton}
                  onClick={() => handleSelectedWelcomeVideoOptionChange(WelcomeVideoOptions.None)}
                  sx={{
                    borderColor:
                      selectedVideoOption === WelcomeVideoOptions.None
                        ? theme.palette.primary.main
                        : '#8B89A0',
                    borderLeftColor:
                      selectedVideoOption === WelcomeVideoOptions.None
                        ? `${theme.palette.primary.main} !important`
                        : '#8B89A0',
                    borderRadius: '0 50px 50px 0',
                    background:
                      selectedVideoOption === WelcomeVideoOptions.None ? '#F9F5FF' : '#FFF',
                    color:
                      selectedVideoOption === WelcomeVideoOptions.None
                        ? theme.palette.primary.main
                        : '#8B89A0',
                  }}
                >
                  {WelcomeVideoModalStrings.None}
                </Button>
              )}
            </ButtonGroup>
          </Box>
        )}
        {isUploading && (
          <Box className={styles.progressWrapper}>
            <LinearProgressWithLabel value={progress} className={styles.progress} />
          </Box>
        )}
        <Box paddingLeft="15px">{getLeftSizeContent(selectedVideoOption)}</Box>
        {uploadingError && (
          <Typography className={styles.uploadingError}>
            {WelcomeVideoModalStrings.UploadingError}
          </Typography>
        )}

        <Box className={styles.buttonsWrapper}>
          <Button className={`${styles.button} ${styles.cancelButton}`} onClick={handleCancel}>
            {isUwmLayout ? WelcomeVideoModalStrings.Back : WelcomeVideoModalStrings.Cancel}
          </Button>
          <LoadingButton
            className={`${styles.button} ${styles.confirmButton}`}
            disabled={isUploading || isRecordMode || isConfirmButtonDisabled()}
            onClick={handleConfirm}
            loading={isConfirmLoading}
          >
            {WelcomeVideoModalStrings.ConfirmChanges}
          </LoadingButton>
        </Box>
      </Box>
      <Box className={styles.rightSideWrapper}>
        {isRecordMode ? (
          <RecordVideo onVideoRecorded={handleVideoRecorded} recordedVideo={recordedVideoBlob} />
        ) : (
          <Box className={styles.videoWrapper}>
            {selectedVideoOption !== WelcomeVideoOptions.None ? (
              <WelcomeVideoIPhonePreview videoUrl={videoUrl} />
            ) : (
              <Box>
                <CreateVideoPreview
                  brandColor={brandColor}
                  image={backgroundImage || ''}
                  logo={logo}
                  theme={themeLayout}
                  isDisplayWelcomeVideo={selectedVideoOption !== WelcomeVideoOptions.None}
                  launchScreenType={launchScreenOptions?.type || 'name'}
                  reveal={launchScreenOptions?.reveal || 'splash'}
                  cover={launchScreenOptions?.cover || 'white'}
                />
              </Box>
            )}
          </Box>
        )}
        {!isUwmLayout && (
          <LoadingButton
            className={`${styles.downloadButton} ${styles.button} ${styles.cancelButton} ${
              selectedVideoOption !== WelcomeVideoOptions.Custom && styles.hidden
            }`}
            disabled={!welcomeVideoUrl || checkIsWelcomeVideoDefault(welcomeVideoUrl)}
            onClick={downloadFile}
            loading={isDownloading}
          >
            {WelcomeVideoModalStrings.DownloadVideo}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};

export default EditWelcomeVideo;
