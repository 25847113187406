import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M12.452 7.942v3.149a2 2 0 0 1-2 2H4.8a2 2 0 0 1-2-2V5.438a2 2 0 0 1 2-2h3.309"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.3}
      d="M11.065 2.857a1 1 0 0 1 1.414 0l.555.555a1 1 0 0 1 0 1.414l-3.78 3.78a1 1 0 0 1-.535.278l-1.375.242a.5.5 0 0 1-.579-.58l.242-1.375a1 1 0 0 1 .278-.534l3.78-3.78Z"
    />
  </svg>
);
export default SvgComponent;
