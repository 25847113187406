import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { UserApiModel } from '../../../../api/models/users';

export const canApplyReward = (user: UserApiModel, activeCampaign: CampaignsApiModel) => {
  if (
    user &&
    user.status !== 'INACTIVE' &&
    activeCampaign &&
    user.campaignPoints &&
    activeCampaign.points
  ) {
    return (
      user.currentCampaignIncentiveReceived === 0 &&
      user.campaignPoints >= activeCampaign.points &&
      user.phoneNumberVerified
    );
  }
  return false;
};

export const isRewardApplied = (user: UserApiModel, activeCampaign: CampaignsApiModel) => {
  if (user && activeCampaign) {
    return user.currentCampaignIncentiveReceived > 0;
  } else return false;
};
