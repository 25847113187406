import React, { FC, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  useTheme,
} from '@mui/material';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subWeeks from 'date-fns/subWeeks';
import subMonths from 'date-fns/subMonths';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDateRangePicker from '@mui/lab/DesktopDateRangePicker';

import { RangeFilter } from '../../../store/storeModels';
import {
  createLabelFromDateRange,
  dateRangeFilterDefaultValues,
  predefinedFilterLabels,
  PredefinedFilters,
  useStyles,
} from './FilterDatePicker.helper';
import { ClipsSearchStrings, DateRangeFilterStrings } from '../../localization/en';
import { backEndDateFormat, segmentActions } from '../../constants/constants';
import { segmentTrackCall } from '../../../utils/segment';
import { useTypedSelector } from '../../../store';
import { endOfDay, endOfToday, endOfYesterday, startOfDay, startOfYesterday } from 'date-fns';
import { DateIcon } from '../../assets/newDesign/DateIcon';

export interface DateRange {
  from: string;
  to: string;
}

interface Props {
  updateRange: (dateRange: DateRange | null) => void;
  width?: string;
  noFormatDate?: boolean;
  initialValue?: RangeFilter | null;
  onClear?: () => void;
  anchorOrigin?: string;
  beginingDate?: string;
  shouldClearLabel?: boolean;
}

export const FilterDatePicker: FC<Props> = ({
  updateRange,
  width,
  noFormatDate,
  initialValue,
  onClear,
  anchorOrigin = 'center',
  beginingDate,
  shouldClearLabel,
}) => {
  const now = new Date();
  const classes = useStyles();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const initialToDate = new Date();

  initialToDate.setHours(23);
  initialToDate.setMinutes(59);

  const theme = useTheme();

  const [dateRange, setDateRange] = useState<RangeFilter>(
    initialValue
      ? {
          ...initialValue,
          label: createLabelFromDateRange(initialValue.from, initialValue.to),
        }
      : {
          from: new Date(),
          to: initialToDate,
        },
  );

  const [chosenPredefinedFilter, setChosenPredefinedFilter] = useState<PredefinedFilters | null>(
    dateRangeFilterDefaultValues.chosenPredefinedFilter,
  );
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorElement);

  const resetFilter = () => {
    const endOfTodayDay = endOfToday();
    const startOfThreeMonths = subMonths(now, 2);

    setDateRange({
      from: startOfThreeMonths,
      to: endOfTodayDay,
      label: shouldClearLabel ? '' : createLabelFromDateRange(startOfThreeMonths, endOfTodayDay),
    });
    setChosenPredefinedFilter(dateRangeFilterDefaultValues.chosenPredefinedFilter);
    onClear?.();
  };

  const applyRangeFilterChanges = () => {
    if (chosenPredefinedFilter === PredefinedFilters.SinceBeginning) {
      triggerUpdate();
    }
    if (!dateRange.from && !dateRange.to) {
      setDateRange({});
    }

    if (dateRange.from && dateRange.to) {
      const label = createLabelFromDateRange(dateRange.from, dateRange.to);
      setDateRange({ ...dateRange, label });
      triggerUpdate();
    }

    if (chosenPredefinedFilter === null && !dateRange.to) {
      resetFilter();
    }

    return handleClose();
  };

  const triggerUpdate = (reset?: boolean) => {
    if (reset) {
      const finalRange = {
        from: format(subMonths(now, 2), backEndDateFormat),
        to: format(endOfToday(), backEndDateFormat),
      };
      return updateRange(finalRange);
    }

    if (chosenPredefinedFilter === PredefinedFilters.SinceBeginning && !beginingDate) {
      return updateRange(null);
    }
    if (!dateRange.from || !dateRange.to) {
      return;
    }
    const finalRange: DateRange = {
      from: format(dateRange.from, backEndDateFormat),
      to: format(dateRange.to, backEndDateFormat),
    };
    if (noFormatDate) {
      updateRange({ from: dateRange.from.toString(), to: dateRange.to.toString() });
    } else {
      updateRange(finalRange);
    }
  };

  const setDateValue = (value: Array<Date | null | undefined>) => {
    segmentTrackCall(segmentActions.DateRange, venueId);
    setChosenPredefinedFilter(PredefinedFilters.Custom);
    return setDateRange({ from: value[0], to: value[1] || value[0] });
  };

  const getTextFieldLabel = (): string => {
    if (chosenPredefinedFilter !== null && chosenPredefinedFilter !== PredefinedFilters.Custom) {
      return predefinedFilterLabels[chosenPredefinedFilter];
    }
    if (dateRange.label) {
      return dateRange.label;
    }
    return 'Select...';
  };

  const choosePredefinedFilter = (value: PredefinedFilters) => {
    setChosenPredefinedFilter(value);
    switch (value) {
      case PredefinedFilters.SinceBeginning: {
        segmentTrackCall(segmentActions.SinceBeginning, venueId);
        //beginingDate
        const sinceBeginingDate = beginingDate ? new Date(beginingDate) : new Date();
        const endOfToday = endOfDay(now);
        setDateRange(
          beginingDate
            ? {
                from: sinceBeginingDate,
                to: endOfToday,
                label: createLabelFromDateRange(sinceBeginingDate, endOfToday),
              }
            : {},
        );
        break;
      }
      case PredefinedFilters.Today: {
        segmentTrackCall(segmentActions.Today, venueId);
        const startOfToday = startOfDay(now);
        const endOfToday = endOfDay(now);

        setDateRange({
          from: startOfToday,
          to: endOfToday,
          label: createLabelFromDateRange(startOfToday, endOfToday),
        });
        break;
      }
      case PredefinedFilters.Yesterday: {
        segmentTrackCall(segmentActions.Yesterday, venueId);
        const startOfDayBeforeToday = startOfYesterday();
        const endOfDayBeforeToday = endOfYesterday();

        setDateRange({
          from: startOfDayBeforeToday,
          to: endOfDayBeforeToday,
          label: createLabelFromDateRange(startOfDayBeforeToday, endOfDayBeforeToday),
        });
        break;
      }
      case PredefinedFilters.LastWeek: {
        segmentTrackCall(segmentActions.LastWeek, venueId);
        const endOfLastWeek = endOfWeek(subWeeks(now, 1));
        const startOfLastWeek = startOfWeek(endOfLastWeek);
        setDateRange({
          from: startOfLastWeek,
          to: endOfLastWeek,
          label: createLabelFromDateRange(startOfLastWeek, endOfLastWeek),
        });
        break;
      }
      case PredefinedFilters.ThisMonth: {
        segmentTrackCall(segmentActions.ThisMonth, venueId);
        const endOfThisMonth = endOfMonth(now);
        const startOfThisMonth = startOfMonth(endOfThisMonth);
        setDateRange({
          from: startOfThisMonth,
          to: endOfThisMonth,
          label: createLabelFromDateRange(startOfThisMonth, endOfThisMonth),
        });
        break;
      }
      case PredefinedFilters.LastMonth: {
        segmentTrackCall(segmentActions.LastMonth, venueId);
        const endOfLastMonth = endOfMonth(subMonths(now, 1));
        const startOfLastMonth = startOfMonth(endOfLastMonth);
        setDateRange({
          from: startOfLastMonth,
          to: endOfLastMonth,
          label: createLabelFromDateRange(startOfLastMonth, endOfLastMonth),
        });
        break;
      }
      case PredefinedFilters.LastThreeMonths: {
        segmentTrackCall(segmentActions.LastMonth, venueId);
        const endOfTodayDay = endOfToday();
        const startOfThreeMonths = subMonths(now, 2);
        setDateRange({
          from: startOfThreeMonths,
          to: endOfTodayDay,
          label: createLabelFromDateRange(startOfThreeMonths, endOfTodayDay),
        });
        break;
      }
      case PredefinedFilters.LastSixMonths: {
        segmentTrackCall(segmentActions.LastMonth, venueId);
        const endOfTodayDay = endOfToday();
        const startOfSixMonths = subMonths(now, 5);
        setDateRange({
          from: startOfSixMonths,
          to: endOfTodayDay,
          label: createLabelFromDateRange(startOfSixMonths, endOfTodayDay),
        });
        break;
      }
    }
  };

  const triggerPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const isDateSelected =
    (chosenPredefinedFilter !== null && chosenPredefinedFilter !== PredefinedFilters.Custom) ||
    dateRange.label;

  const handleClose = () => {
    segmentTrackCall(segmentActions.DatePickerClose, venueId);
    setAnchorElement(null);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        onClick={(event) => {
          triggerPopup(event);
        }}
        className={classes.selectContainer}
      >
        {isDateSelected && (
          <Button
            className={classes.clearButton}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              resetFilter();
            }}
          >
            {ClipsSearchStrings.Clear}
          </Button>
        )}
        <Typography
          className={classes.labelText}
          color={isDateSelected ? theme.palette.primary.main : '#667085'}
        >
          {getTextFieldLabel()}
        </Typography>
        <DateIcon />
      </Box>
      <Popover
        open={open}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: anchorOrigin }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        classes={{ paper: classes.paperRoot }}
      >
        <Grid spacing={0} container direction="row" height="100%">
          <Grid item>
            <Box className={classes.filterContainer} padding={2}>
              <List className={classes.listContainer}>
                {Object.keys(predefinedFilterLabels).map((item) => (
                  <ListItem
                    button
                    className={clsx({
                      [classes.activeListItem]: chosenPredefinedFilter === item,
                      [classes.listItem]: true,
                    })}
                    key={item as PredefinedFilters}
                    onClick={() => choosePredefinedFilter(item as PredefinedFilters)}
                    id={`${item}-date-range-button`}
                  >
                    <ListItemText
                      sx={{ fontSize: '14px', lineHeight: '26px' }}
                      primary={predefinedFilterLabels[item as PredefinedFilters]}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item>
            <Divider className={classes.divider} orientation="vertical" flexItem />
          </Grid>
          <Grid item>
            <Grid container direction="column" className={classes.buttonsCalendarBlock}>
              <Grid item>
                <Box>
                  <DesktopDateRangePicker
                    calendars={1}
                    open={open}
                    reduceAnimations
                    value={[dateRange.from, dateRange.to]}
                    onChange={(newValue) => setDateValue(newValue)}
                    renderInput={() => <></>}
                    PaperProps={{ elevation: 0 }}
                    showDaysOutsideCurrentMonth
                    PopperProps={{
                      className: 'desktop-date-range-picker',
                      style: { position: 'static' },
                      disablePortal: true,
                      anchorEl: this,
                      placement: undefined,
                      popperOptions: { strategy: 'absolute' },
                    }}
                    maxDate={new Date()}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box padding={2} className={classes.buttonsContainer}>
                  <Button
                    variant="text"
                    color="primary"
                    disableElevation
                    onClick={resetFilter}
                    id="date-reset-filter-button"
                  >
                    {DateRangeFilterStrings.Reset}
                  </Button>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={applyRangeFilterChanges}
                    id="date-apply-filter-button"
                  >
                    {DateRangeFilterStrings.Apply}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </LocalizationProvider>
  );
};
