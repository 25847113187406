import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme, { glowColor?: string }>((theme: Theme) => ({
  info: {
    position: 'absolute',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    top: '1px',
    height: '84px',
    width: '93px',
    zIndex: '10',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.396) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  container: {
    '& .swiper-slide-shadow-left': {
      display: 'none',
    },
    '& .swiper-slide-shadow-right ': {
      display: 'none',
    },
    '& .swiper-slide': {
      width: 'auto !important',
      maxHeight: '226px',
    },
    '& .swiper-slide-prev img, .swiper-slide-prev img $info': {
      borderBottomRightRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '6px',
      borderTopLeftRadius: '6px',
    },
    '& .swiper-slide-next img, .swiper-slide-prev img $info': {
      borderBottomLeftRadius: '0px',
      borderTopLeftRadius: '0px',
      borderBottomRightRadius: '6px',
      borderTopRightRadius: '6px',
    },
  },
  swiperContainer: {
    height: '196px',
    padding: '2px 0',
    position: 'relative',
  },
  swiperSlide: {
    width: '102px',
    maxHeight: '171px',
    position: 'relative',
  },
  img: {
    width: '93px',
    height: '172px',
    borderRadius: '6px',
    objectFit: 'cover',
    outline: 'solid 8px #ffffff',
    outlineOffset: '0px',
  },
  sliderControler: {
    left: '50%',
    transform: 'translateX(-45%)',
    position: 'absolute',
    bottom: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10',
  },

  swiperButtonPrev: {
    left: '58% !important',
    transform: 'translateX(-58%) !important',
    marginRight: '8px',
  },
  swiperButtonNext: {
    marginLeft: '14px',
  },
  swiperPagination: {
    display: 'none',
  },
  progressBar: {
    background: '#00000033',
    borderRadius: '4px',
    width: '80px',
    height: '6px',
    marginTop: '4px',
  },
  progressIndicator: ({ glowColor }) => ({
    background: '#43B6FE',
    borderRadius: '4px',
  }),
  sliderArrow: {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    left: '42%',
    transform: ' translateX(-42%)',
    filter: 'drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1))',
    cursor: 'pointer',
    '& path': {
      fill: '#CCCCCC',
    },
  },
  author: {
    fontFamily: 'HelveticaNeue',
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '9px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: theme.palette.common.white,
    margin: '22px 12px 0',
  },
  duration: {
    fontFamily: 'Poppins',
    fontSize: '9px',
    fontWeight: '400',
    lineHeight: '16px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: theme.palette.common.white,
    marginLeft: '12px',
  },
  icons: {
    position: 'absolute',
    top: '24px',
    right: '10px',
    zIndex: '11',
  },
}));
