import { FC } from 'react';

interface Props {
  className?: string;
}

export const TimeIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_515_153294)">
        <path
          d="M5.49935 2.7513V5.5013L7.33268 6.41797M10.0827 5.5013C10.0827 8.03261 8.03065 10.0846 5.49935 10.0846C2.96804 10.0846 0.916016 8.03261 0.916016 5.5013C0.916016 2.97 2.96804 0.917969 5.49935 0.917969C8.03065 0.917969 10.0827 2.97 10.0827 5.5013Z"
          stroke="#8B89A0"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_515_153294">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
