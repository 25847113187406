import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { RewardsPageStrings } from '../../../../../common/localization/en';

const useStyles = makeStyles((theme: Theme) => ({
  styledButton: {
    flexShrink: 0,
    width: '128px',
    color: theme.palette.primary.main,
    bgcolor: 'red',
    borderRadius: '50px',
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: '12px',
    lineHeight: '16px',
    height: '18px !important',
  },
}));

interface EndCampaignButtonProps {
  onClick: any;
  text?: string;
  campaignName?: string;
  id?: string;
}

export const EndCampaignButton: FC<EndCampaignButtonProps> = ({
  onClick,
  text,
  campaignName,
  id,
}) => {
  const styles = useStyles();
  return (
    <Button onClick={onClick} variant="outlined" className={styles.styledButton} id={id}>
      {text || RewardsPageStrings.EndCampaignButton}
    </Button>
  );
};
