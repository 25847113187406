import { Box, Card, CardActionArea, CardMedia } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useVideoComponentStyles } from './VideoComponent.style';

import { CampaignVideosApiModel } from '../../../api/models/campaigns';
import { useAppDispatch } from '../../../store';
import { openCampaignSummaryVideoDetails } from '../../../store/slices/campaignsSlice';
import { DownloadIcon } from '../../assets/newDesign/DownloadIcon';
import SettingsCogIcon from '../../assets/newDesign/SettingsCogIcon';
import { PlayIcon } from '../../assets/newDesign/VideoControlsIcons/PlayIcon';

interface IVideoComponentProps {
  url: string;
  handleOpenThumbnailsPanel?: () => void;
  thumbnail: string;
  isPlaying?: boolean;
  isTopGolf: boolean;
  handleOpenModal: () => void;
  withoutThumbnailEdit?: boolean;
  withoutShadow?: boolean;
  width?: number;
  height?: number;
  isRecentVideosSlider?: boolean;
  video?: CampaignVideosApiModel;
  handleOpenDownloadVideo?: (video: CampaignVideosApiModel) => void;
}

export const VideoComponent: FC<IVideoComponentProps> = ({
  url,
  handleOpenThumbnailsPanel,
  thumbnail,
  isTopGolf,
  handleOpenModal,
  isPlaying,
  withoutShadow,
  width,
  height,
  isRecentVideosSlider,
  video,
  handleOpenDownloadVideo,
}) => {
  const classes = useVideoComponentStyles();
  const [isMouseOverPlayIcon, setisMouseOverPlayIcon] = useState(false);

  const dispatch = useAppDispatch();

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const handleOpenVideoModal = () => {
    handleOpenModal();
  };

  const handleOpenVideoDetails = () => {
    if (!video) return;

    dispatch(openCampaignSummaryVideoDetails({ userId: video.userId, videoId: video.id }));
  };

  return (
    <Card
      sx={{
        width: width && `${width}px !important`,
        height: height && `${height}px !important`,
        boxShadow: withoutShadow ? 'none' : undefined,
      }}
      className={isTopGolf ? classes.TopGolfVideoCard : classes.VideoCard}
    >
      <CardActionArea className={classes.actionArea} id="video-detail-video-wrapper">
        <CardMedia
          component="video"
          ref={refVideo}
          poster={thumbnail}
          src={url}
          // onClick={() => playPauseSwitch()}
          onClick={handleOpenVideoModal}
          id="video-detail-play-button"
        />
        {!isPlaying && (
          <Box
            fontSize={45}
            className={[classes.icon, isMouseOverPlayIcon ? classes.MouseOverIcon : ''].join(' ')}
            onMouseOver={() => setisMouseOverPlayIcon(true)}
            onMouseLeave={() => setisMouseOverPlayIcon(false)}
          >
            <PlayIcon />
          </Box>
        )}

        {isRecentVideosSlider && (
          <Box className={classes.actionButtonWrapper}>
            <Box
              className={classes.actionButton}
              onClick={() => video && handleOpenDownloadVideo && handleOpenDownloadVideo(video)}
            >
              <DownloadIcon />
            </Box>
            <Box className={classes.actionButton} onClick={handleOpenVideoDetails}>
              <SettingsCogIcon />
            </Box>
          </Box>
        )}
      </CardActionArea>
    </Card>
  );
};
