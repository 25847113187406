import { FC } from 'react';
import { Box, Icon } from '@mui/material';
// incons
import { LinkedinIcon } from '../../../../common/assets/LinkedinIcon';
import { FacebookIcon } from '../../../../common/assets/FacebookIcon';
// import InstagramIcon from '@mui/icons-material/Instagram';

import { PreviewContainer } from '../previews.style';
import { IphoneXframeDeprecated } from '../phonePreviewWrapper';
import { ContentWrapper } from './StoryCardPreview.style';
// import { StoryCardPreviewStrings } from '../../../localization/en';
import { useStyles } from './StoryCardPreview.helper';
import { ButtonsTypes } from '../../../../pages/webappSettings/VideoSharing/VideoSharing.helper';
import { SharingOrder, SharingSettings } from '../../../../api/models/venue';
import { useTypedSelector } from '../../../../store';
import { SettingsPageStrings } from '../../../localization/en';
import { SMSIcon } from '../../../assets/shareAssets/SMSIcon';
import { TwitterIcon } from '../../../assets/shareAssets/TwitterIcon';
import { EmailIcon } from '../../../assets/shareAssets/EmailIcon';
import { theme } from '../../../../theme';
import { DownloadIcon } from '../../../assets/shareAssets/DownloadIcon';
import { LinkNewIcon } from '../../../assets/shareAssets/LinkNewIcon';
import { EditIcon } from './ShareCardPreview';

interface StoryCardPreviewProps {
  brandColor?: string;
  textColor?: string;
  ctaBtnText: string;
  videoText: string;
  sharingSettings: SharingSettings;
  mappedOrderedSharingList: SharingOrder[];
  editable: boolean;
  toggleEdit: () => void;
}

export const TopGolfShareCardPreview: FC<StoryCardPreviewProps> = ({
  mappedOrderedSharingList,
  sharingSettings,
  editable,
  toggleEdit,
}) => {
  const classes = useStyles();
  const { properties } = useTypedSelector((state) => state.venue.venueAttributes);
  const { subdomain } = useTypedSelector((state) => state.venue.venue);
  const filterSettings = properties?.['webapp.topgolf.config']?.filters;
  const webAppUrl = `https://${subdomain}${SettingsPageStrings.DomainName}`;
  const icons = [
    {
      name: 'Message' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <SMSIcon />
        </div>
      ),
    },
    {
      name: 'Facebook' as ButtonsTypes,
      component: <FacebookIcon />,
    },
    {
      name: 'Twitter' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <TwitterIcon />
        </div>
      ),
    },
    {
      name: 'Link' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <LinkNewIcon />
        </div>
      ),
    },
    {
      name: 'Email' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <EmailIcon />
        </div>
      ),
    },
    {
      name: 'LinkedIn' as ButtonsTypes,
      component: <LinkedinIcon />,
    },
    // {
    //   name: 'Instagram' as ButtonsTypes,
    //   component: <InstagramIcon />,
    // },
    {
      name: 'Download' as ButtonsTypes,
      component: (
        <div style={{ stroke: theme.palette.common.white }}>
          <DownloadIcon />
        </div>
      ),
    },
  ];

  return (
    <PreviewContainer>
      <IphoneXframeDeprecated color="black">
        <ContentWrapper>
          <div className={classes.contentwrapper}>
            <p className={classes.webAppUrl}>{webAppUrl}</p>
            <Box
              alignItems="center"
              width="100%"
              borderRadius="8px"
              marginTop="25px"
              style={{
                backgroundColor: '#1C1D22',
                display: 'flex',
                alignSelf: 'center',
                width: '90%',
              }}
            >
              <div className={classes.iconsWrapper}>
                {filterSettings?.isFilterActive && (
                  <img
                    className={classes.filterImage}
                    alt="filter"
                    src={filterSettings?.filterImageUrl || ''}
                  />
                )}
                {mappedOrderedSharingList?.length &&
                  icons
                    .filter((item) => sharingSettings && sharingSettings[item.name])
                    .slice()
                    .sort((a, b) => {
                      const first =
                        mappedOrderedSharingList[
                          mappedOrderedSharingList.findIndex(
                            (item) => Object.entries(item)[0][0] === a.name,
                          )
                        ];
                      const second =
                        mappedOrderedSharingList[
                          mappedOrderedSharingList.findIndex(
                            (item) => Object.entries(item)[0][0] === b.name,
                          )
                        ];

                      return Object.entries(first)[0][1] > Object.entries(second)[0][1] ? 1 : -1;
                    })
                    .map((item) => {
                      return <div className={classes.nonTGiconContainer}>{item.component}</div>;
                    })}
              </div>
              <Icon
                onClick={toggleEdit}
                className={`${classes.EditIcon} ${
                  editable ? classes.EditIconActive : classes.EditIconInactive
                }`}
              >
                <EditIcon
                  color={editable ? theme.palette.common.white : theme.palette.primary.main}
                />
              </Icon>
              <div className={classes.blackDiv}></div>
            </Box>
          </div>
        </ContentWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
