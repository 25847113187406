import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { theme } from '../../../theme';

interface PreviewContainerProps {
  modal?: boolean;
}

export const PreviewContainer = styled.div<PreviewContainerProps>`
  --iphoneXWidth: 258px;
  width: 100%;
  padding: ${(props) => (props.modal ? '75px 95px' : '16px 24px')};
  position: relative;
`;
// padding: 16px 26px 35px;

export const IphoneXFrame = styled.div`
  position: relative;
  z-index: 5;
  aspect-ratio: 1393 / 2704;

  margin: var(--iponeXMargin);
  width: var(--iphoneXWidth);
  border-radius: var(--iphoneXborderRadius);
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.16), 0 50px 78px rgba(0, 0, 0, 0.18),
    inset 0 -18px 30px rgba(0, 0, 0, 0.08);
`;

export const IphoneXNotch = styled.i`
  position: absolute;
  top: 15px;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
  height: 3.61247947454844%;
  width: 53.86666666666667%;
  background-color: #fff;
  border-radius: 0 0 68px 68px;
`;

export const LogoSection = styled.img`
  width: 30px;
  height: 30px;
`;

export const MenuBar = styled(MenuIcon)`
  height: 20px;
  width: 20px;
  color: ${theme.palette.primary.contrastText};
`;
