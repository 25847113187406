import { Fragment } from 'react';
import { Grid } from '@mui/material';

import { TextLabel, TextValue, UsersBox, BlockTitle } from './components.style';
import { SectionTitle } from '../Steps.style';
import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { useTypedSelector } from '../../../../store';
import { AccountsDTO } from '../../../../api/models/venueCreation';

interface LabelValuePair {
  label: string;
  value: string;
}

export const UsersBlock = () => {
  let Labels: AccountsDTO = {
    userAccountId: VenueWizardPreviewStepStrings.LabelUserId,
    userAccountEmail: VenueWizardPreviewStepStrings.LabelUserEmail,
    userAccountName: VenueWizardPreviewStepStrings.LabelUserName,
    userAccountRoleName: VenueWizardPreviewStepStrings.LabelUserTitle,
    svId: '',
    isRegistered: true,
  };
  type AccountKeys =
    | 'userAccountId'
    | 'userAccountEmail'
    | 'userAccountName'
    | 'userAccountRoleName';

  const { userAccounts } = useTypedSelector((state) => state.venueCreation.program_payload);
  const renderKeyValuePairs = (labelValuePair: LabelValuePair[]) => {
    return labelValuePair.map((pair, index) => {
      return (
        <Fragment key={index}>
          <Grid item xs={6}>
            <TextLabel>{pair.label}</TextLabel>
          </Grid>
          <Grid item xs={6}>
            <TextValue>{pair.value}</TextValue>
          </Grid>
        </Fragment>
      );
    });
  };
  const renderItems = () => {
    return userAccounts.map((item, index) => {
      let labelValuePair = (Object.keys(item as AccountsDTO) as Array<AccountKeys>).map((key) => {
        return {
          label: Labels[key],
          value: item[key],
        };
      });
      return (
        <UsersBox key={index}>
          <Grid container>
            <Grid item xs={12}>
              <BlockTitle>
                {VenueWizardPreviewStepStrings.UserId.replace(':id', (index + 1).toString())}
              </BlockTitle>
            </Grid>
            {renderKeyValuePairs(labelValuePair)}
          </Grid>
        </UsersBox>
      );
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionTitle>{VenueWizardPreviewStepStrings.UsersTitle}</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        {renderItems()}
      </Grid>
    </Grid>
  );
};
