import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '15px 17px 10px 25px',
    background: '#fff',
    borderRadius: '10px',
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    borderBottom: '1px solid #EAECF0',
    width: '100%',

    left: 0,
  },
  topWrapper: {
    height: '56px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '34px',
    letterSpacing: '0.4px',
    color: '#000',
    margin: '3px 0 0 20px',
  },
  userDetailsWrapper: {
    marginLeft: '22px',
  },
  tabsWrapper: {
    marginTop: '20px',
  },
}));
