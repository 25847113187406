import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={35} height={39} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M32.167 12.5H2.833C1.821 12.5 1 13.32 1 14.333V18c0 1.012.82 1.833 1.833 1.833h29.334c1.012 0 1.833-.82 1.833-1.833v-3.667c0-1.012-.82-1.833-1.833-1.833ZM30.334 19.833H4.666c-1.013 0-1.833.82-1.833 1.833v11c0 1.013.82 1.834 1.833 1.834h25.666c1.013 0 1.834-.821 1.834-1.834v-11c0-1.012-.821-1.833-1.834-1.833Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      d="M20.55 26.76h-2.11v2.13h-.89v-2.13h-2.1v-.83h2.1v-2.14h.89v2.14h2.11v.83Z"
      fill="currentColor"
    />
    <path
      clipRule="evenodd"
      d="M19.333 12.5H23A5.5 5.5 0 1 0 17.5 7v5.5h1.833ZM15.667 12.5H12A5.5 5.5 0 1 1 17.5 7v5.5h-1.833Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

const ManualSMSIcon = memo(SvgComponent);
export default ManualSMSIcon;
