import { Box } from '@mui/material';
import React from 'react';

interface VideoContainerProps {
  children: React.ReactNode;
  width: number;
  height: number;
}

export const VideoContainer: React.FC<VideoContainerProps> = ({ children, width, height }) => {
  return (
    <Box
      sx={{
        width,
        height,
        borderRadius: '14px',
        border: '3px solid #AEAEAE',
        overflow: 'hidden',
        transform: 'translate3d(0, 0, 0)', // fix overlay on safari
      }}
    >
      {children}
    </Box>
  );
};
