export const SocialVerseIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke="inherit"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="9.5" strokeWidth="1.5" />
      <path d="M21.5 12H2.5" strokeWidth="1.5" />
      <ellipse cx="11.9999" cy="12" rx="4.5126" ry="9.5" strokeWidth="1.5" />
    </svg>
  );
};
