import { TableContent, TableRow } from '../../commonTypes';
import { format } from 'date-fns';
import { Box, Typography } from '@mui/material';
import { SmsMessageModel, SmsMessageStatus, SmsMessageType } from '../../../api/models/smsMessages';
import {
  convertZToPST,
  formatStringWithPattern,
  getOnlyNumbers,
} from '../../../services/utilities';
import { SmsMessageTablesStrings } from '../../localization/en';
import { Tooltip } from '../Tooltip/Tooltip';

export interface SmsMessagesTableContent extends TableRow {
  id: string;
  createdAt: JSX.Element;
  toNumber: JSX.Element;
  displayName: JSX.Element;
  status: JSX.Element;
  communicationType: JSX.Element;
  mediaUrl: JSX.Element;
}

export const createSmsMessagesTableContent = ({
  items,
}: {
  items: SmsMessageModel[];
}): TableContent<SmsMessagesTableContent> => {
  const communicationTypesMap: Record<SmsMessageType, string> = {
    [SmsMessageType.Auth]: SmsMessageTablesStrings.Authentication,
    [SmsMessageType.Incoming]: SmsMessageTablesStrings.Incoming,
    [SmsMessageType.Invite]: SmsMessageTablesStrings.Invite,
    [SmsMessageType.Other]: SmsMessageTablesStrings.Other,
    [SmsMessageType.Reward]: SmsMessageTablesStrings.Acknowledgement,
    [SmsMessageType.ManagerNotification]: SmsMessageTablesStrings.ManagerNotification,
    [SmsMessageType.UploadNotification]: SmsMessageTablesStrings.VideoCreation,
    [SmsMessageType.Test]: SmsMessageTablesStrings.Sample,
  };

  const smsStatusMap: Record<SmsMessageStatus, string> = {
    [SmsMessageStatus.Accepted]: SmsMessageTablesStrings.Accepted,
    [SmsMessageStatus.Queued]: SmsMessageTablesStrings.Queued,
    [SmsMessageStatus.Sending]: SmsMessageTablesStrings.Sending,
    [SmsMessageStatus.Sent]: SmsMessageTablesStrings.Sent,
    [SmsMessageStatus.Failed]: SmsMessageTablesStrings.Failed,
    [SmsMessageStatus.Delivered]: SmsMessageTablesStrings.Delivered,
    [SmsMessageStatus.Undelivered]: SmsMessageTablesStrings.Undelivered,
    [SmsMessageStatus.Receiving]: SmsMessageTablesStrings.Receiving,
    [SmsMessageStatus.Received]: SmsMessageTablesStrings.Received,
    [SmsMessageStatus.Canceled]: SmsMessageTablesStrings.Canceled,
    [SmsMessageStatus.PartiallyDelivered]: SmsMessageTablesStrings.PartiallyDelivered,
    [SmsMessageStatus.Scheduled]: SmsMessageTablesStrings.Scheduled,
    [SmsMessageStatus.Read]: SmsMessageTablesStrings.Read,
    [SmsMessageStatus.OptOut]: SmsMessageTablesStrings.OptOut,
  };

  const smsErrorCodeMap: Record<number, string> = {
    30003: 'Delivery of the message failed because the number is switched off, cannot receive SMS or otherwise unavailable.',
    30005: 'Delivery of the message failed because the number is unknown or may no longer exist',
    30006: 'Delivery of the message failed because the number is a landline or unreachable.',
    30007: "This message was flagged as objectionable by recipient's wireless carrier and blocked.",
    30008: 'Delivery of the message failed for unknown reasons.',
    30410: 'Delivery of the message failed due to a disruption experienced by the wireless carrier.',
    11751: 'The attached image with this message exceeded the carriers limit (5MB).',
    63019: 'The attached image is broken and was unable to be delivered.',
  };

  const getSMSErrorText = (errorCode: number, errorMessage: string = 'Unknown error') => {
    return smsErrorCodeMap[errorCode] || `[Error: ${errorCode}] ${errorMessage}`;
  };

  const tableContent: TableContent<SmsMessagesTableContent> = {
    header: {
      createdAt: {
        name: 'Date',
        sortable: true,
        width: '15%',
      },
      toNumber: {
        name: 'To',
        sortable: true,
      },
      displayName: {
        name: 'Name',
        sortable: true,
      },
      status: {
        name: 'Status',
        sortable: false,
      },
      communicationType: {
        name: 'Message Type',
        sortable: true,
      },
      mediaUrl: {
        name: 'Format',
        sortable: true,
      },
    },
    rows: [],
  };
  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    const phoneNumber = getOnlyNumbers(item.toNumber);

    return {
      id: item.id!,
      createdAt: (
        <Typography width="80px" ml="18px">
          {item.createdAt ? `${format(convertZToPST(item.createdAt), 'PP HH:mm:ss')} PST` : '---'}
        </Typography>
      ),
      toNumber: (
        <Typography>
          {formatStringWithPattern(
            phoneNumber,
            phoneNumber.length === 11 && phoneNumber.startsWith('1')
              ? '+# (###) ###-#####'
              : '+############',
          )}
        </Typography>
      ),
      displayName: (
        <Typography
          maxWidth="500px"
          paddingRight="10px"
          sx={{
            overflowWrap: 'anywhere',
          }}
        >
          {item.displayName || '---'}
        </Typography>
      ),
      status: (
        <Box display="flex" justifyContent="center">
          <Typography>{item.status ? smsStatusMap[item.status] : '---'}</Typography>
          {item.status === SmsMessageStatus.Undelivered && (
            <Tooltip title={getSMSErrorText(item.errorCode, item.errorMessage)} />
          )}
        </Box>
      ),
      communicationType: (
        <Typography>
          {item.communicationType ? communicationTypesMap[item.communicationType] : '---'}
        </Typography>
      ),
      mediaUrl: <Typography>{item.mediaUrl !== 'null' ? 'MMS' : 'SMS'}</Typography>,
    };
  });

  return tableContent;
};
