export enum MessageVariables {
  CREATOR_NAME = 'CREATOR_NAME',
  INCENTIVE_NAME = 'INCENTIVE_NAME',
  COMPANY_NAME = 'COMPANY_NAME',
}

export enum LocalMessageVariables {
  CreatorName = 'Customer Name',
  IncentiveName = 'Incentive Name',
  PatientName = 'Patient Name',
  RewardName = 'Reward Name',
  CompanyName = 'Company Name',
  PracticeName = 'Practice Name',
}

enum MessageTemplateTag {
  Open = '{',
  Close = '}',
}
enum MessageTemplateSquareTag {
  Open = '[',
  Close = ']',
}

export const wrapTemplateVariableWithTags = (variable: string) => {
  return `${MessageTemplateTag.Open}${variable}${MessageTemplateTag.Close}`;
};
export const wrapTemplateVariableWithSquareTags = (variable: string) => {
  return `${MessageTemplateSquareTag.Open}${variable}${MessageTemplateSquareTag.Close}`;
};

export const constructMessageFromTemplate = (
  template: string,
  variables: Record<string, string>,
  squareBrackets: boolean | undefined = false,
) => {
  let result = squareBrackets ? constructMessageFromTemplateWithSquare(template) : template;

  for (const variableName in variables) {
    const variableValue = variables[variableName];

    result = squareBrackets
      ? result.replace(new RegExp(variableName, 'g'), variableValue)
      : result.replace(new RegExp(wrapTemplateVariableWithTags(variableName), 'g'), variableValue);
  }
  return result;
};

export const constructMessageFromTemplateWithSquare = (template: string) => {
  return template.replace(/[[\]]+/g, '');
};

export const replaceMessageBodyWithApiVariables = (
  message: string,
  squareBrackets: boolean | undefined = false,
) => {
  return constructMessageFromTemplate(
    message,
    {
      [LocalMessageVariables.CreatorName]: wrapTemplateVariableWithTags(
        MessageVariables.CREATOR_NAME,
      ),
      [LocalMessageVariables.IncentiveName]: wrapTemplateVariableWithTags(
        MessageVariables.INCENTIVE_NAME,
      ),
      [LocalMessageVariables.RewardName]: wrapTemplateVariableWithTags(
        MessageVariables.INCENTIVE_NAME,
      ),
      [LocalMessageVariables.PatientName]: wrapTemplateVariableWithTags(
        MessageVariables.CREATOR_NAME,
      ),
      [LocalMessageVariables.CompanyName]: wrapTemplateVariableWithTags(
        MessageVariables.COMPANY_NAME,
      ),
      [LocalMessageVariables.PracticeName]: wrapTemplateVariableWithTags(
        MessageVariables.COMPANY_NAME,
      ),
    },
    squareBrackets,
  );
};

export const replaceMessageBodyWithLocalVariables = (
  message: string,
  isHealthCare: boolean,
  squareBrackets: boolean | undefined = false,
) => {
  if (isHealthCare) {
    return constructMessageFromTemplate(message, {
      [MessageVariables.CREATOR_NAME]: squareBrackets
        ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.PatientName)
        : wrapTemplateVariableWithTags(LocalMessageVariables.PatientName),
      [MessageVariables.INCENTIVE_NAME]: squareBrackets
        ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.RewardName)
        : wrapTemplateVariableWithTags(LocalMessageVariables.RewardName),
      [MessageVariables.COMPANY_NAME]: squareBrackets
        ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.PracticeName)
        : wrapTemplateVariableWithTags(LocalMessageVariables.PracticeName),
    });
  }
  return constructMessageFromTemplate(message, {
    [MessageVariables.CREATOR_NAME]: squareBrackets
      ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.CreatorName)
      : wrapTemplateVariableWithTags(LocalMessageVariables.CreatorName),
    [MessageVariables.INCENTIVE_NAME]: squareBrackets
      ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.RewardName)
      : wrapTemplateVariableWithTags(LocalMessageVariables.RewardName),
    [MessageVariables.COMPANY_NAME]: squareBrackets
      ? wrapTemplateVariableWithSquareTags(LocalMessageVariables.CompanyName)
      : wrapTemplateVariableWithTags(LocalMessageVariables.CompanyName),
  });
};
