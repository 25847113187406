import { FC, MouseEvent as ReactMouseEvent, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  FormHelperText,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import _ from 'lodash';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { useToasts } from 'react-toast-notifications';

import { LoginContainer, LoginForm, useLoginStyles } from '../Login.style';
import { LoginPageStrings } from '../../../common/localization/en';
import { ILoginProps } from '../Login';
import {
  ResetPasswordFormValues,
  initialValuesResetPasswordForm,
  validationSchemaResetPassword,
  validationSchemaCreateAccount,
} from '../LoginFormsHelpers';
import { authRequest, getUserAccounts, ResetPassword } from '../../../store/slices/meSlice';
import {
  AdornmentPosition,
  TextFieldComponent,
} from '../../../common/components/TextField/TextField';
import { useAppDispatch, useTypedSelector } from '../../../store';

interface ResetPasswordFormProps extends ILoginProps {
  token: string;
  name?: string;
  email: string;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ setStep, token, email, name }) => {
  const styles = useLoginStyles();
  const { addToast } = useToasts();
  const appDispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { auth } = useTypedSelector((state) => state.me);

  useEffect(() => {
    if (auth) {
      appDispatch(getUserAccounts({}));
    }
  }, [auth, appDispatch]);

  const submitForm = async (values: ResetPasswordFormValues) => {
    try {
      const ResetPasswordResponse = await ResetPassword(
        values.name
          ? { token, newPassword: values.password, name: values.name }
          : { token, newPassword: values.password },
      );
      if (ResetPasswordResponse.success) {
        if (email) {
          appDispatch(authRequest({ username: email, password: values.password }));
        } else {
          setStep('login');
        }
      }
    } catch (error) {
      addToast(LoginPageStrings.InvalidToken, { appearance: 'error', autoDismissTimeout: 25000 });
    }
  };

  const actualValidtionSchema = email
    ? validationSchemaCreateAccount
    : validationSchemaResetPassword;

  const ForgotPasswordFormik = useFormik({
    initialValues: { ...initialValuesResetPasswordForm, email, name: name || '' },
    enableReinitialize: false,
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: actualValidtionSchema,
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  const handleMouseDownPassword = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { handleSubmit, values, handleChange, errors } = ForgotPasswordFormik;

  const disableSaveButton = () => {
    if (!values.password || !values.confirmPassword) {
      return true;
    }

    if (!email) {
      return !!errors.password || !!errors.confirmPassword;
    }
    // const isInitialValues = _.isEqual(values, initialValuesResetPasswordForm);
    const isErrors = !_.isEmpty(errors);
    // return !isInitialValues && isErrors;
    return isErrors;
  };

  return (
    <>
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Grid container spacing={3} direction="row-reverse" justifyContent="space-between">
                <Grid item xs={12}>
                  <Typography className={styles.Title}>
                    {email
                      ? LoginPageStrings.CreateYourAccount
                      : LoginPageStrings.ResetPasswordTitle}
                  </Typography>
                  {email ? (
                    <Typography className={styles.SubTitle} style={{ paddingTop: '12px' }}>
                      {LoginPageStrings.CreateYourAccountSubtitle}
                    </Typography>
                  ) : null}
                </Grid>
                {email && (
                  <Grid item xs={12} style={{ paddingTop: '12px', position: 'relative' }}>
                    <Typography className={styles.Caption}>{LoginPageStrings.Name} *</Typography>
                    <TextField
                      id="name"
                      fullWidth
                      name="name"
                      value={values.name}
                      // label={LoginPageStrings.Name}
                      variant="outlined"
                      placeholder={'Name'}
                      onChange={handleChange}
                      // disabled
                    />
                    {errors.name && (
                      <FormHelperText
                        id="standard-weight-helper-text"
                        style={{ position: 'absolute', bottom: '-20px' }}
                      >
                        {errors.name}
                      </FormHelperText>
                    )}
                  </Grid>
                )}

                {email && (
                  <Grid item xs={12} style={{ paddingTop: '20px', position: 'relative' }}>
                    <Typography className={styles.Caption}>{LoginPageStrings.Email} *</Typography>
                    <TextField
                      fullWidth
                      name="email"
                      value={email}
                      id="outlined-basic"
                      // label={LoginPageStrings.Email}
                      variant="outlined"
                      placeholder={LoginPageStrings.UserNameLabel}
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={12} style={{ paddingTop: '20px', position: 'relative' }}>
                  <Typography className={styles.Caption}>
                    {LoginPageStrings.NewPassword} *
                  </Typography>
                  <TextFieldComponent
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange}
                    placeholder={LoginPageStrings.NewPassword}
                    adornmentPosition={AdornmentPosition.End}
                    adornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((v) => !v)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    fullWidth
                  />
                  {errors.password && (
                    <FormHelperText
                      id="standard-weight-helper-text"
                      style={{ position: 'absolute', bottom: '-20px' }}
                    >
                      {/* {LoginPageStrings.InvalidPassword} */}
                      {errors.password}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '20px', position: 'relative' }}>
                  <Typography className={styles.Caption}>
                    {LoginPageStrings.ConfirmationPassword} *
                  </Typography>
                  <TextFieldComponent
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={handleChange}
                    placeholder={LoginPageStrings.ConfirmPassword}
                    adornmentPosition={AdornmentPosition.End}
                    adornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword((v) => !v)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    }
                    fullWidth
                  />
                  {(errors.confirmPassword || values.password !== values.confirmPassword) && (
                    <FormHelperText
                      id="standard-weight-helper-text"
                      style={{ position: 'absolute', bottom: '-20px' }}
                    >
                      {/* {LoginPageStrings.InvalidPassword} */}
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '30px' }}>
                  <Box className={styles.SubmitBtn}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      // disabled={values.password !== values.confirmPassword}
                      disabled={disableSaveButton()}
                      className={styles.Button}
                    >
                      {email ? LoginPageStrings.CreateAccount : LoginPageStrings.ResetPasswordTitle}
                    </Button>
                  </Box>
                </Grid>
                {!email && (
                  <Grid item xs={12} style={{ paddingTop: '12px' }}>
                    <Button
                      sx={{ color: '#475467 !important' }}
                      onClick={() => setStep('login')}
                      fullWidth
                      className={styles.Button}
                    >
                      {LoginPageStrings.Cancel}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </LoginForm>
      </LoginContainer>
    </>
  );
};
