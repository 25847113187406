import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '290px',
    height: '160px',
    border: '2px solid #EAECF0',
    borderRadius: '12px',
    padding: '20px',
    position: 'relative',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    cursor: 'pointer',
    transition: '0.11s',
  },
  containerActive: {
    borderColor: theme.palette.primary.main,
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    color: theme.palette.common.black,
    width: '200px',
    marginBottom: '7px',
    lineHeight: '32px',
    transition: '0.11s',
  },
  titleActive: {
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: '14px',
    color: '#475467',
    lineHeight: '24px',
  },
  checkmark: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    borderRadius: '50%',
    border: `1px solid #D0D5DD`,
    width: '20px',
    height: '20px',
    paddingLeft: '2px',
  },
  checkmarkActive: {
    border: `1px solid #7F56D9`,
    background: theme.palette.primary.main,
    '& path': {
      transform: `scale(.85)`,
    },
  },
}));
