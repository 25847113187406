import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../../theme';

export const useStylesStatisctic = makeStyles({
  root: {
    width: '100%',
  },
  textToltipRationWrapper: {
    paddingLeft: 15,
  },
  icon: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    border: '1px solid #ccc',
  },
  text: {
    marginRight: 0,
    fontSize: '14px',
    color: '#8B89A0',
    whiteSpace: 'nowrap',
  },
  bucketsWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    gap: '24px',
  },
  bucketWrapper: {
    flexBasis: '100%',
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '28px',
  },
  valueSkeleton: {
    width: '87px',
    height: '20px',
    borderRadius: '60px',
    transform: 'none',
  },
  value: {
    fontSize: '20px',
    lineHeight: '30px',
    color: '#000',
  },
  ratioWrapper: {
    lineHeight: '26px',
    fontStyle: 'normal',
    fontWeight: 400,
    color: '#8B89A0',
    marginTop: '10px',
    '&>span': {
      fontSize: '12px',
      '&:last-child': {
        paddingLeft: 8,
        color: '#1a1538',
      },
    },
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    flexShrink: 0,
    fontSize: '8px',
    color: '#c0c3c8',
    borderRadius: '50%',
    background: '#FFF',
    border: ' 1px solid #c0c3c8',
    padding: '2px',
  },
});
interface StatisticBlockProps {
  isActive: boolean;
}
export const StatisticsBlock = styled.div<StatisticBlockProps>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: ${(props) =>
    props.isActive ? `1px solid ${theme.palette.primary.main}` : '1px solid transparent'};
  ${(props) => (props.isActive ? 'box-shadow: 0px 8px 30px rgba(20, 21, 61, 0.05);' : '')}
  padding: 4px 20px;
  border-radius: 15px;
  letter-spacing: 0.02em;
  transition: all 0.3s ease;

  &:hover {
    border: 1px solid ${theme.palette.primary.main};
    margin-top: -5px;
    box-shadow: 0 7px 12px rgb(0 0 0 / 6%);
    cursor: pointer;
  }
`;
