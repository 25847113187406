import { FC } from 'react';

export const BookIcon: FC = () => {
  return (
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.3021 23.7147V6.61557C31.3021 4.91557 29.9138 3.65474 28.2279 3.79641H28.1429C25.1679 4.05141 20.6488 5.56724 18.1271 7.15391L17.8863 7.30974C17.4754 7.56474 16.7954 7.56474 16.3846 7.30974L16.0304 7.09724C13.5088 5.52474 9.00375 4.02307 6.02875 3.78224C4.34292 3.64057 2.96875 4.91557 2.96875 6.60141V23.7147C2.96875 25.0747 4.07375 26.3497 5.43375 26.5197L5.84458 26.5764C8.91875 26.9872 13.6646 28.5456 16.3846 30.0331L16.4413 30.0614C16.8238 30.2739 17.4329 30.2739 17.8013 30.0614C20.5213 28.5597 25.2813 26.9872 28.3696 26.5764L28.8371 26.5197C30.1971 26.3497 31.3021 25.0747 31.3021 23.7147Z"
        stroke="#5242EA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1357 7.77734V29.0273"
        stroke="#5242EA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1152 12.0273H7.92773"
        stroke="#5242EA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.1777 16.2773H7.92773"
        stroke="#5242EA"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
