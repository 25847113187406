import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './QSMarker.styles';
import { QuickStartStrings } from '../../localization/en';
import { Logout } from '@mui/icons-material';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { isUWMClient } from '../../../utils/isUWMClient';

interface Props {
  onClick?: () => void;
  isExitMarker?: boolean;
}

const QSMarker: FC<Props> = ({ onClick, isExitMarker }) => {
  const styles = useStyles();
  const isUwmClient = isUWMClient();

  const { moveToNextItem } = useQuickStartLaunchPad();

  const handleToGuide = async () => {
    await moveToNextItem({
      skipTracking: true,
    });
    onClick?.();
  };

  return (
    <Box className={styles.container}>
      <Box
        className={styles.textWrapper}
        sx={{
          backgroundColor: isUwmClient ? '#005F9E' : '#3B6EED',
          textAlign: isUwmClient ? 'start' : 'center',
          '&::before': {
            backgroundColor: isUwmClient ? '#005F9E' : '#FFB515',
          },
        }}
      >
        <Typography
          className={styles.text}
          sx={{
            color: isUwmClient ? '#ADEA48' : '#FFB515',
          }}
        >
          {QuickStartStrings.StartHere}
        </Typography>
      </Box>
      <Box
        className={styles.goBack}
        sx={{
          backgroundColor: isUwmClient ? '#5CA9DD' : '#739AFC',
          color: isUwmClient ? '#fff' : 'auto',
        }}
        onClick={handleToGuide}
      >
        <Logout className={styles.logoutIcon} />
        <Typography className={styles.toGuide}>
          {isExitMarker ? QuickStartStrings.ExitGuide : QuickStartStrings.ToGuide}
        </Typography>
      </Box>
    </Box>
  );
};

export default QSMarker;
