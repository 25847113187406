import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDistributorsSelectorStyles = makeStyles<Theme, { isOpen: boolean }>(
  (theme: Theme) => ({
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: '24px',
      zIndex: 20,
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonTitle: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: '12px',
    },
    buttonChevron: {
      rotate: '90deg',
    },
    distributorPopupContainer: {
      margin: '-10px auto 0',
      transition: 'all 0.15s ease',
      visibility: ({ isOpen }) => (isOpen ? 'inherit' : 'hidden'),
      opacity: ({ isOpen }) => (isOpen ? '1' : '0'),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
    },
    popupArrowContainer: {
      position: 'relative',
      width: '12px',
      height: '12px',
    },
    distributorPopup: {
      paddingTop: '10px',

      zIndex: 1, // should overflow the popup arrow
      background: theme.palette.common.sidebar.accountsMenu.popover.background,
      width: '205px',
      padding: '8px',
      minHeight: '43px',

      borderRadius: '10px',
      color: theme.palette.common.sidebar.accountsMenu.popover.text,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      fontWeight: '600',
      fontSize: '14px',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#fff',
        height: '10px !important',
        maxHeight: '20px !important',
        borderRadius: '12px',
        margin: '5px 0',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#EAECF0',
        borderRadius: '5px',
        height: '30px !important',
        cursor: 'pointer',
      },
    },

    distributorGroup: {
      width: '100%',
      padding: '0 15px',
    },
    distributorGroupDivider: {
      height: '1px',
      width: '100%',
      background: theme.palette.common.sidebar.accountsMenu.popover.divider,
      margin: '8px 0',
    },

    distributorPopupButton: {
      cursor: 'pointer',
      '&:not(:last-child)': {
        marginBottom: '8px',
      },
      fontWeight: '600',
      fontSize: '14px',
      padding: '8px 14px',
      borderRadius: '6px',
      width: '100%',
      textAlign: 'start',
      textWrap: 'nowrap',
      whiteSpace: 'nowrap',

      '&:hover': {
        background: '#EAE9F5',
      },
      transition: 'all 0.1s ease',
    },

    distributorPopupButtonActive: {
      background: theme.palette.common.sidebar.accountButton.activeBackground,
      '&:hover': {
        background: theme.palette.common.sidebar.accountButton.activeBackground,
      },
      color: theme.palette.common.sidebar.accountButton.activeText,
    },
  }),
);
