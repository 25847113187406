import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useFormik } from 'formik';
import React from 'react';

import { validationSchemaCompany } from './CompanyDetails.helper';
import { CommonStrings, WorkspaceStrings } from '../../../common/localization/en';
import { TextFieldComponent } from '../../../common/components/TextField/TextField';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { URLTextField } from '../../../common/components/TextField/URLTextField';
import { PricingPlanPreview } from './components/PricingPlanPreview';
import { useAppDispatch } from '../../../store';
import { patchVenue } from '../../../store/slices/venueSlice';
import { useVenue } from '../../../hooks/useVenue';
import { useToasts } from 'react-toast-notifications';
import { useClientType } from '../../../services/hooks/useClientType';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { states } from '../../../common/constants/constants';
import { SelectTextField } from '../../../common/components/TextField/SelectTextField';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../../common/constants/events/accountEvents';

const FieldLabel: React.FC<{ title?: string }> = ({ title }) => {
  const theme = useTheme();

  if (!title) {
    return <Box height="26px" />;
  }

  return (
    <Typography color={theme.palette.common.deepDark} fontSize="12px" mb="8px">
      {title}
    </Typography>
  );
};

export const CompanyDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const venue = useVenue();
  const { isHealthCare, isUwmClient } = useClientType();
  const { trackEvent } = useTrackEvent();

  const { value: companyAddress, update: updateCompanyAddress } = useVenueProperty<
    VenueProperties['company-address']
  >({
    property: 'company-address',
  });

  const { value: legalName } = useVenueProperty<VenueProperties['legal_name']>({
    property: 'legal_name',
  });

  const {
    values,
    handleChange,
    handleBlur,
    dirty,
    errors,
    submitForm,
    resetForm,
    isValid: isFormValid,
    isSubmitting,
  } = useFormik({
    initialValues: {
      name: venue.name ?? '',
      website: venue.websiteUrl ?? '',
      address: companyAddress?.address ?? '',
      city: companyAddress?.city ?? '',
      state: companyAddress?.state ?? '',
      zip: companyAddress?.zip ?? '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchemaCompany,
    onSubmit: async (values) => {
      await dispatch(
        patchVenue({
          id: venue.venueId,
          update: {
            name: values.name,
            websiteUrl: values.website,
          },
        }),
      );

      await updateCompanyAddress(
        {
          address: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
        },
        { patchObjectValue: true },
      );

      addToast(CommonStrings.OnSuccess, { appearance: 'success' });
    },
  });

  const companyPlaceHolder = isHealthCare ? 'Practice Name' : 'Company Name';

  return (
    <Box>
      <Grid container>
        <Grid item xs={9} bgcolor="white" borderRadius="10px" py="30px" px="20px" height="0">
          <Typography color="black" fontSize="24px" fontWeight={700} mb="24px">
            {isHealthCare ? 'Practice ' : 'Company '} Details
          </Typography>
          <Box bgcolor="#FBFBFB" p="10px" borderRadius="10px">
            <Grid container spacing="20px" mb="10px">
              {isHealthCare && (
                <Grid item xs={5}>
                  <FieldLabel title={WorkspaceStrings.CompanyName} />
                  <TextFieldComponent
                    placeholder={companyPlaceHolder}
                    name="name"
                    value={legalName || values.name}
                    disabled={true}
                    helperText
                    fullWidth
                    onChange={(event) => {
                      trackEvent(AccountEventNames.companyNameTyped, {
                        value: event.target.value,
                      });
                    }}
                  />
                </Grid>
              )}
              {isHealthCare && <Grid item xs={5}></Grid>}
              <Grid item xs={5}>
                <FieldLabel
                  title={
                    isHealthCare ? WorkspaceStrings.PracticeName : WorkspaceStrings.CompanyName
                  }
                />
                <TextFieldComponent
                  placeholder={companyPlaceHolder}
                  name="name"
                  value={values.name}
                  onChange={(event) => {
                    handleChange(event);
                    trackEvent(AccountEventNames.practiceNameTyped, {
                      value: event.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  errorText={errors.name}
                  fullWidth
                  helperText
                />
              </Grid>
              <Grid item xs={4}>
                <FieldLabel
                  title={isHealthCare ? WorkspaceStrings.PracticeAddress : 'Company Address'}
                />
                <TextFieldComponent
                  placeholder="Company Address"
                  name="address"
                  value={values.address}
                  onChange={(event) => {
                    handleChange(event);
                    trackEvent(AccountEventNames.companyAddressTyped, {
                      value: event.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  errorText={errors.address}
                  fullWidth
                  helperText
                />
              </Grid>
              <Grid item xs={3}>
                <FieldLabel title="City" />
                <TextFieldComponent
                  placeholder="City"
                  name="city"
                  value={values.city}
                  onChange={(event) => {
                    handleChange(event);
                    trackEvent(AccountEventNames.cityTyped, {
                      value: event.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  errorText={errors.city}
                  fullWidth
                  helperText
                />
              </Grid>
            </Grid>
            <Grid container spacing="20px">
              <Grid item xs={5}>
                <FieldLabel
                  title={
                    isHealthCare
                      ? WorkspaceStrings.PracticeWebsite
                      : WorkspaceStrings.CompanyWebsite
                  }
                />
                <URLTextField
                  placeholder="Company Website"
                  name="website"
                  value={values.website}
                  onChange={(event) => {
                    handleChange(event);
                    trackEvent(AccountEventNames.companyWebsiteTyped, {
                      value: event.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  errorText={errors.website}
                  fullWidth
                  helperText
                />
              </Grid>
              <Grid item xs={4}>
                <FieldLabel title="State" />
                <SelectTextField
                  fullWidth
                  name="state"
                  placeholder="State"
                  value={values.state}
                  onChange={(event) => {
                    handleChange(event);
                    trackEvent(AccountEventNames.stateChanged, {
                      value: event.target.value as string,
                    });
                  }}
                  options={states}
                />
              </Grid>

              <Grid item xs={1.5}>
                <FieldLabel title="Zip code" />
                <TextFieldComponent
                  placeholder="Zip code"
                  name="zip"
                  value={values.zip}
                  onChange={(event) => {
                    const val = event.target.value;
                    if ((/^\d+$/.test(val) || val === '') && val.length < 6) {
                      handleChange(event);
                    }
                    trackEvent(AccountEventNames.zipCodeTyped, {
                      value: event.target.value,
                    });
                  }}
                  onBlur={handleBlur}
                  errorText={errors.zip}
                  fullWidth
                  helperText
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={3} pl="15px">
          {!isUwmClient && (
            <PricingPlanPreview
              onOpenAgreement={() => {
                trackEvent(AccountEventNames.openAgreementClicked);
              }}
            />
          )}
        </Grid>
      </Grid>

      <BottomActionBar
        show={dirty}
        onSaveClick={() => {
          submitForm();
          trackEvent(AccountEventNames.companyDetailsActionBarSave);
        }}
        onCancelClick={() => {
          resetForm();
          trackEvent(AccountEventNames.companyDetailsActionBarCancel);
        }}
        disableSaveButton={!isFormValid}
        loading={isSubmitting}
      />
    </Box>
  );
};
