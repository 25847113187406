import { Menu } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  isOpen: boolean;
  anchorElement: HTMLElement | null;
  onClose: () => void;
  padding?: string;
  maxHeight?: string;
  left?: string;
}

const MenuContainer: FC<Props> = ({
  isOpen,
  anchorElement,
  onClose,
  padding,
  maxHeight,
  children,
  left,
}) => {
  return (
    <Menu
      open={isOpen}
      anchorEl={anchorElement}
      onClose={onClose}
      sx={{
        maxHeight: maxHeight || 'none',
        zoom: '0.9',
        marginTop: '5px',
        left: left || 0,

        '& .MuiPaper-root': {
          padding: padding || '0',
          boxShadow:
            '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
          border: '1px solid #EAECF0',

          '&::-webkit-scrollbar': {
            width: '6px',

            '&-thumb': {
              background: '#EAECF0',
              borderRadius: '8px',
            },
          },
        },
      }}
    >
      {children}
    </Menu>
  );
};

export default MenuContainer;
