export const SharesIcon = () => {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.074 23.514C11.7805 24.3139 10.6835 24.4001 10.2686 23.656L7.40964 18.528C7.31954 18.3664 7.18622 18.233 7.02462 18.1429L1.89663 15.284C1.15245 14.8691 1.23871 13.7721 2.0386 13.4786L15.8707 8.40351C16.6674 8.1112 17.4414 8.88522 17.1491 9.68193L12.074 23.514Z"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.27148 18.2808L16.7465 8.80577"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
