import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  userBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  avatar: {
    height: '40px',
    width: '40px',
    marginRight: theme.spacing(3),
  },
  userName: {
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.text.primary,
    lineHeight: '20px',
  },
  videoContainer: {
    width: '100%',
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
    flexGrow: 1,
  },
  textRow: {
    display: 'flex',
    width: '100%',
  },
  firstColumn: {
    display: 'flex',
    width: '60px',
    marginRight: theme.spacing(3),
  },
}));
