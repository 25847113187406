import { FC } from 'react';
import { Box, Switch, useTheme } from '@mui/material';
import { BadgeIconActive } from '../../assets/BadgeIconActive';
import { BadgeIconDeactivated } from '../../assets/BadgeIconDeactivated';

interface Props {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
}

export const ActivateNotificationSwitch: FC<Props> = ({ isChecked, onChange, disabled }) => {
  const theme = useTheme();
  return (
    <Box sx={{ display: 'flex', position: 'relative', cursor: disabled ? 'not-allowed' : 'auto' }}>
      <Switch
        disabled={disabled}
        checked={isChecked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
        checkedIcon={
          <Box
            sx={{
              width: '28px',
              height: '28px',
              backgroundColor: '#fff',
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              top: '0px',
              left: '0px',
            }}
          >
            <BadgeIconActive />
          </Box>
        }
        icon={
          <Box
            sx={{
              width: '28px',
              height: '28px',
              backgroundColor: '#8B89A0',
              borderRadius: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexShrink: 0,
              top: '0px',
              left: '0px',
            }}
          >
            <BadgeIconDeactivated />
          </Box>
        }
        sx={{
          width: '54px',
          height: '34px',
          p: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '40px',
          border: `0px solid ${theme.palette.primary.main}`,
          '& .MuiButtonBase-root': {
            p: '3px !important',
            backgroundColor: 'inherit !important',
          },
          '& .MuiSwitch-track': {
            backgroundColor: '#8B89A0',
            opacity: '0.2 !important',
          },
          '& .Mui-checked+.MuiSwitch-track': {
            backgroundColor: `${theme.palette.primary.main} !important`,
            opacity: '1 !important',
          },
          '& .Mui-checked': {
            transform: 'translateX(20px) !important',
          },
        }}
      />
    </Box>
  );
};
