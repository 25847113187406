import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export const useThumbnailCustomizationStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    bottom: 0,
    position: 'fixed',
    color: theme.palette.common.white,
    zIndex: 220,
    pointerEvents: 'none',
    opacity: 0,
    transfrom: 'translateY(80px)',
    transition: 'all .2s ease-in-out',
  },
  container: {
    position: 'relative',
    marginTop: 'auto',
    width: '100%',
    maxWidth: 560,
    boxSizing: 'border-box',
    backgroundColor: '#121212',
    borderRadius: 7,
    boxShadow: '0 0 0 9999px #000000b0',
    paddingBottom: 74,
    textAlign: 'center',
  },
  framesContainer: {
    display: 'flex',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: '2px',
    justifyContent: 'space-between',
    // maxWidth: 510,
    overflowX: 'hidden',
  },
  framesContainerItem: {
    position: 'relative',
    marginRight: 8,
    flexShrink: 0,
    borderRadius: 10,
    overflow: 'hidden',
    cursor: 'pointer',
    '&:last-child': {
      marginRight: 0,
    },
  },
  framesContainerItemActive: {
    border: `3px solid ${theme.palette.primary.main}`,
    borderBottom: `6px solid ${theme.palette.primary.main}`,
    outlineColor: theme.palette.primary.main,
  },
  skeleton: {
    backgroundColor: '#F5F6F8',
    border: '1px solid #DCDBE4',
  },
  circularProgress: {
    zIndex: 1204,
    position: 'absolute',
    top: '35%',
    left: '30%',
    transform: 'translate(-50%,-50%)',
    color: '#4334D2',
  },
}));
