export enum VideosEventNames {
  createKeywordButtonClicked = 'create_keyword_button_clicked',
  deleteKeywordButtonClicked = 'delete_keyword_button_clicked',
  saveKeywordButtonClicked = 'save_keyword_button_clicked',
  buttonCloseKeywordModalClicked = 'button_close_keyword_modal_clicked',
  filtersButtonBackClicked = 'filters_button_back_clicked',
  filtersSearchTyped = 'filters_search_typed',
  filterOrderChangeButtonClicked = 'filter_order_change_button_clicked',
  filterApplyButtonClicked = 'filter_apply_button_clicked',
  filterSettingsButtonClicked = 'filter_settings_button_clicked',
  filterDeleteButtonClicked = 'filter_delete_button_clicked',
  filterDeleteConfirmClicked = 'filter_delete_confirm_clicked',
  newFilterDateRangeUpdated = 'new_filter_date_range_update',
  filterDateRangeApplied = 'filter_date_range_applied',
  applyFilterButtonClicked = 'apply_filter_button_clicked',
  resetFilterButtonClicked = 'reset_filter_button_clicked',
  downloadCsvFilterResultsButtonClicked = 'download_csv_filter_results_button_clicked',
  assignBonusPointsButtonClicked = 'assign_bonus_points_button_clicked',
  assignToSocialVerseButtonClicked = 'assign_to_social_verse_button_clicked',
  addKeywordTyped = 'add_keyword_typed',
  changeVideoStatus = 'change_video_status',
  cancelVideoStatusChange = 'cancel_video_status_change',
  videoRowClicked = 'video_row_clicked',
  confirmVideoStatusChange = 'confirm_video_status_change',
  openVideoDetailsClicked = 'open_video_details_clicked',
  copyVideoAppLinkClicked = 'copy_video_app_link_clicked',
  copyInvitesBlockLinkClicked = 'invites_block_link_clicked',
  openVideoAppLinkClicked = 'open_video_app_link_clicked',
  videosTableRowClicked = 'videos_table_row_clicked',
  videosTableSizeChanged = 'videos_table_size_changed',
  videosTablePageChanged = 'videos_table_page_changed',
  videosApplyFilter = 'videos_apply_filter',
  videosTableSortClicked = 'videos_table_sort_clicked',
  closeVideoDetailsModal = 'close_video_details_modal',
  clearFilter = 'clear_filter',
  videosTableSearchUpdated = 'videos_table_search_updated',
  videosTableSearchTyped = 'videos_table_search_typed',
  createFilterButtonClicked = 'create_filter_button_clicked',
  savedFiltersButtonClicked = 'saved_filters_button_clicked',
  filterDeleteCancelButtonClicked = 'filter_delete_cancel_button_clicked',
  confirmCSVDownloadButtonClicked = 'confirm_csv_download_button_clicked',
  cancelCSVDownloadButtonClicked = 'cancel_csv_download_button_clicked',
  gotItButtonClicked = 'got_it_button_clicked',
  assignToSvCheckBoxClicked = 'assign_to_sv_check_box_clicked',
  videosPage = 'videos_page',
}
