import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '16px 24px 0 16px',
  },
  tableWrapper: {
    backgroundColor: theme.palette.common.white,
    padding: '32px 24px',
    borderRadius: '16px',
  },
}));
