import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textTransform: 'none',
    minHeight: '34px',
    padding: 0,
    minWidth: 'auto',
    marginRight: '16px',
    paddingRight: '5px',
    '&:first-child': {
      marginLeft: '22px',
    },
  },
  title: {
    marginRight: '8px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
  },
  count: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    padding: '2px 8px',
    borderRadius: '16px',
  },
}));
