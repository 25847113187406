import React, { useState } from 'react';
import { useStyles } from './AccountsSearchTags.styles';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { SearchTagsStrings } from '../../localization/en';
import MenuContainer from '../MenuContainer/MenuContainer';
import PurpleConfirmIcon from '../../assets/PurpleConfirmIcon';
import CancelIconBig from '../../assets/CancelIconBig';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useToasts } from 'react-toast-notifications';
import { getSearchFilters, postSearchFilter } from '../../../store/slices/searchFilterSlice';
import { SearchTarget } from '../../../api/models/searchFilterModels';
import AccountStatusTag from './AccountStatusTag/AccountStatusTag';
import CreatedAtTag from './CreatedAtTag/CreatedAtTag';
import PricingPlanTag from './PricingPlanTag/PrincingPlanTag';
import LastMemberLoginAtTag from './LastMemberLoginAtTag/LastMemberLoginAtTag';

const AccountsSearchTags = () => {
  const styles = useStyles();

  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const { activeAccountFilterAttributes, search } = useTypedSelector((state) => state.searchFilter);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [saveAnchorEl, setSaveAnchorEl] = useState<null | HTMLElement>(null);
  const isSaveMenuOpen = Boolean(saveAnchorEl);

  const handleSaveMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSaveAnchorEl(event.currentTarget);
  };

  const handleSaveMenuClose = () => {
    setSaveAnchorEl(null);
    setName('');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value);

    if (nameError) {
      setNameError(false);
    }
  };

  const handleSave = async () => {
    if (name === '') {
      setNameError(true);
      return;
    }

    handleSaveMenuClose();
    await dispatch(
      postSearchFilter({
        filter: { attributes: activeAccountFilterAttributes },
        name,
        searchText: search,
        target: SearchTarget.ACCOUNTS,
      }),
    );
    await dispatch(getSearchFilters({ target: SearchTarget.ACCOUNTS }));

    addToast(`${name} ${SearchTagsStrings.SavedInfo}`, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  return (
    <Box className={styles.container}>
      <AccountStatusTag />
      <LastMemberLoginAtTag />
      <CreatedAtTag />
      <PricingPlanTag />

      <Button onClick={handleSaveMenuOpen} className={styles.saveButton}>
        {SearchTagsStrings.SaveSearch}
      </Button>
      <MenuContainer
        anchorElement={saveAnchorEl}
        isOpen={isSaveMenuOpen}
        onClose={handleSaveMenuClose}
        padding="11px 8px"
      >
        <Box className={styles.saveWrapper}>
          <TextField
            type="text"
            className={styles.nameInput}
            value={name}
            onChange={handleNameChange}
            sx={{
              '& div': {
                borderColor: nameError ? 'red' : '#EEEEF6',
              },
            }}
          />
          <IconButton
            sx={{
              padding: '2px',
              marginLeft: '8px',
            }}
            onClick={handleSave}
          >
            <PurpleConfirmIcon />
          </IconButton>
          <IconButton sx={{ padding: '2px' }} onClick={handleSaveMenuClose}>
            <CancelIconBig />
          </IconButton>
        </Box>
      </MenuContainer>
    </Box>
  );
};

export default AccountsSearchTags;
