import React, { SVGProps } from 'react';
import { UserApiModel } from '../../../../api/models/users';
import { CampaignUser } from '../../../../api/models/campaigns';

export const VerifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={20} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#0788F5"
        d="M16.07 3.542c.19.46.554.825 1.013 1.015l1.61.667a1.875 1.875 0 0 1 1.014 2.45l-.666 1.609c-.19.46-.19.976 0 1.435l.666 1.609a1.874 1.874 0 0 1-1.015 2.45l-1.609.667c-.46.19-.824.554-1.015 1.013l-.667 1.61a1.875 1.875 0 0 1-2.45 1.015l-1.608-.667c-.46-.19-.976-.189-1.435.002l-1.61.665a1.875 1.875 0 0 1-2.448-1.014l-.667-1.61a1.875 1.875 0 0 0-1.014-1.015l-1.61-.667a1.875 1.875 0 0 1-1.015-2.449l.666-1.608c.19-.46.19-.976 0-1.435l-.666-1.611a1.875 1.875 0 0 1 1.015-2.45l1.609-.667c.459-.19.824-.554 1.014-1.013l.667-1.61A1.875 1.875 0 0 1 8.3.92l1.609.666c.46.19.975.19 1.434 0L12.953.92a1.875 1.875 0 0 1 2.45 1.014l.666 1.61v-.002Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14.542 7.379a.938.938 0 0 0-1.583-1.008L9.288 12.14l-1.68-2.1a.938.938 0 0 0-1.465 1.171l2.5 3.125a.937.937 0 0 0 1.524-.082l4.375-6.875Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.625 0h20v20h-20z" />
      </clipPath>
    </defs>
  </svg>
);

export const UnverifiedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none" {...props}>
    <path fill="#D0D5DD" d="M10.625 19.75a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5Z" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M7.125 11h7"
    />
  </svg>
);

export const OptedOutIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      stroke="red"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4.108 4.108 11.784 11.784M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0Z"
    />
  </svg>
);

interface UserPhoneStatusIconProps {
  user: UserApiModel | CampaignUser;
}

export const UserPhoneStatusIcon: React.FC<UserPhoneStatusIconProps> = ({ user }) => {
  if (user.smsOptedIn === false || user.optedIn === false || user.optOut === true) {
    return <OptedOutIcon />;
  }

  if (user.phoneNumberVerified) {
    return <VerifiedIcon />;
  }

  return <UnverifiedIcon />;
};
