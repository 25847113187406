import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    position: 'relative',
  },
  completeWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '115%',
    height: '33px',
    background: theme.palette.common.white,
    zIndex: 100,
    animation: '$backgroundReveal 1s 0.5s both',
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    position: 'absolute',
    top: '0',
    translate: 'transformX(-100%)',
    width: 'fit-content',
    height: 'fit-content',
    animation: '$iconSlide 1s 0.5s both',
    background: theme.palette.common.white,
  },
  completeText: {
    color: theme.palette.common.sidebar.item.icon,
    marginLeft: '38px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    animation: '$showText 0.2s 1.5s both',
    position: 'relative',

    '&::after': {
      content: '"."',
      position: 'absolute',
      left: '100%',
      bottom: '0',
      height: '100%',
      width: '5px',
      animation: '$dotsAnimation 1s 1.5s infinite',
    },
  },

  '@keyframes iconSlide': {
    '0%': {
      left: '100%',
    },
    '100%': {
      left: 0,
    },
  },
  '@keyframes backgroundReveal': {
    '0%': {
      clipPath: 'polygon(100% 0, 100% 0, 100% 100%, 100% 100%)',
    },
    '100%': {
      clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
    },
  },
  '@keyframes showText': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes dotsAnimation': {
    '0%': {
      content: '"."',
    },
    '34%': {
      content: '".."',
    },
    '67%': {
      content: '"..."',
    },
  },
}));
