import { FC, useEffect, useState } from 'react';
import { Box, Drawer } from '@mui/material';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { UserLogoSection } from './userLogoSection/UserLogoSection';
// import { CollapseSection } from './collapseSection/CollapseSection';
import { Menu } from './menu/Menu';
import { Container, FlexWrapper } from './SideBar.style';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import {
  BRAND_360_LOGO,
  LayoutMode,
  TRUBLU_SIDEBAR_LOGO,
  UserRoles,
} from '../../constants/constants';
import { setIsMenuExpanded, triggerSidebar } from '../../../store/slices/uiSlice';
import { useStyles } from './Sidebar.helper';

import { WebsiteRoutes } from '../../constants/routes';
import { SocialVenuLogo } from '../../assets/newDesign/Sidebar/SocialVenuLogo';
import { WorkspaceSection } from './menu/workspaceSection/WorkspaceSection';
import { AccountsButton, BackButton } from './menu/menuItem/MenuItem';
import { DistributorModel } from '../../../api/models/distributors';
import { setActiveDistributor } from '../../../store/slices/distributorsSlice';
import { setActiveAffiliate } from '../../../store/slices/affiliatesSlice';
import { AffiliateModel } from '../../../api/models/affiliates';
import {
  resetPage,
  setGroupId,
  setIsArchivedAccount,
  updateSearch,
} from '../../../store/slices/organizationVenueSlice';
import { useIsTBAccount } from '../../../hooks/common';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AllAccountsEventNames } from '../../constants/events/allAccountsEvents';
import { useClientType } from '../../../services/hooks/useClientType';
import { isUWMClient } from '../../../utils/isUWMClient';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';

export const SideBar: FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { selectedVenueId: selectedVenu } = useTypedSelector((state) => state.venue);
  const history = useHistory();
  const sidebarMode = useWindowResize();
  const { sidebarOpen } = useTypedSelector((state) => state.ui);
  const { name, email, role, accounts } = useTypedSelector((state) => state.me);
  const { distributors, activeDistributor } = useTypedSelector((state) => state.distributors);
  const { isArchivedAccount } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const distributorsWithoutTest = distributors.filter((d) => d.name !== 'TEST');
  const testDistributor = distributors.find((d) => d.name === 'TEST');
  const { affiliates, activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const { isMenuExpanded, isTabletView, isWindowLoaded } = useTypedSelector((state) => state.ui);

  const isSuperAdmin = ([UserRoles.admin_role] as string[]).includes(role);
  const { pathname } = useLocation();
  const singleAccount = accounts === 1;
  const isMultiAccount = accounts > 1;
  const isAdmin = isSuperAdmin || isMultiAccount;
  const isAccountsPage = history.location.pathname === WebsiteRoutes.ProfileAccounts;
  const { trackEvent } = useTrackEvent();
  const { isTrubluClient } = useClientType();
  const [isSvAccountSelected, setIsSvAccountSelected] = useState(false);

  const { isStarted: isQsFlowStarted } = useQuickStartLaunchPad();

  const isSVAdminAccountButtonActive =
    isAccountsPage && !activeAffiliate && !activeDistributor && !isArchivedAccount;

  const isDistributorAccountButtonActive = (id: string) => {
    return !!(isAccountsPage && activeDistributor && activeDistributor.id === id);
  };

  const isAffiliateAccountButtonActive = (id: string) => {
    return !!(isAccountsPage && activeAffiliate && activeAffiliate.id === id);
  };

  const handleDistrubtorsAccountsClick = (distributor: DistributorModel | undefined) => {
    trackEvent(AllAccountsEventNames.distributorAccountsButtonClicked, {
      distributorId: distributor?.id,
    });
    dispatch(updateSearch(''));
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(distributor));
    dispatch(setGroupId(distributor?.groupId || null));
    dispatch(setIsArchivedAccount(false));
    setIsSvAccountSelected(false);

    if (distributor) {
      localStorage.setItem('selectedDistributor', JSON.stringify(distributor));
      localStorage.removeItem('selectedAffiliate');
    }

    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const handleAffiliatesAccountsClick = (affiliate: AffiliateModel | undefined) => {
    trackEvent(AllAccountsEventNames.affiliateAccountsButtonClicked, {
      affiliateId: affiliate?.id,
    });
    dispatch(setActiveAffiliate(affiliate));
    dispatch(setActiveDistributor(undefined));
    dispatch(setGroupId(affiliate?.groupId || null));
    dispatch(setIsArchivedAccount(false));
    setIsSvAccountSelected(false);

    if (affiliate) {
      localStorage.setItem('selectedAffiliate', JSON.stringify(affiliate));
      localStorage.removeItem('selectedDistributor');
    }

    history.push(WebsiteRoutes.ProfileAccounts);
  };

  const handleSVadminAccountsClick = () => {
    // history.push(SocialvenueManagerRoutes.Performance);
    trackEvent(AllAccountsEventNames.allAccountsButtonClicked);
    localStorage.removeItem('selectedDistributor');
    localStorage.removeItem('selectedAffiliate');
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(undefined));
    dispatch(setGroupId(null));
    dispatch(setIsArchivedAccount(false));
    setIsSvAccountSelected(true);
    history.push(WebsiteRoutes.ProfileAccounts);
    // setIsActiveItem(true);
  };

  const handleSVadminArchivedAccountsClick = () => {
    // history.push(SocialvenueManagerRoutes.Performance);
    trackEvent(AllAccountsEventNames.archivedAccountsButtonClicked);
    localStorage.removeItem('selectedDistributor');
    localStorage.removeItem('selectedAffiliate');
    dispatch(setActiveAffiliate(undefined));
    dispatch(setActiveDistributor(undefined));
    history.push(WebsiteRoutes.ProfileAccounts);
    dispatch(setGroupId(null));
    dispatch(setIsArchivedAccount(true));
    setIsSvAccountSelected(true);
  };

  const selectSvAccounts = () => {
    const svDistributor = distributorsWithoutTest.find((distributor) => distributor.name === 'SV');
    handleDistrubtorsAccountsClick(svDistributor);
    setIsSvAccountSelected(true);
  };

  useEffect(() => {
    const distributor = localStorage.getItem('selectedDistributor')
      ? JSON.parse(localStorage.getItem('selectedDistributor') as string)
      : null;
    const affiliate = localStorage.getItem('selectedAffiliate')
      ? JSON.parse(localStorage.getItem('selectedAffiliate') as string)
      : null;

    if (
      !distributor &&
      !affiliate &&
      !isUWMClient() &&
      !isSvAccountSelected &&
      distributorsWithoutTest.length > 0 &&
      pathname === WebsiteRoutes.ProfileAccounts &&
      isSuperAdmin
    ) {
      selectSvAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributorsWithoutTest, isSuperAdmin, isSvAccountSelected, pathname]);

  useEffect(() => {
    const distributorJSON = localStorage.getItem('selectedDistributor');
    const affiliateJSON = localStorage.getItem('selectedAffiliate');

    if (distributorJSON && pathname === WebsiteRoutes.ProfileAccounts) {
      const distributor = JSON.parse(distributorJSON);

      dispatch(setActiveAffiliate(undefined));
      dispatch(setActiveDistributor(distributor));
      dispatch(setGroupId(distributor.groupId));
      dispatch(setIsArchivedAccount(false));
    }

    if (affiliateJSON && pathname === WebsiteRoutes.ProfileAccounts) {
      const affiliate = JSON.parse(affiliateJSON);

      dispatch(setActiveAffiliate(affiliate));
      dispatch(setActiveDistributor(undefined));
      dispatch(setGroupId(affiliate.groupId));
      dispatch(setIsArchivedAccount(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pathname]);

  useEffect(() => {
    if (pathname !== WebsiteRoutes.ProfileAccounts) {
      setIsSvAccountSelected(false);
    }
  }, [pathname]);

  // const isAdminSection = pathname.includes('/admin');
  const showVenuMenu = () => {
    if (selectedVenu) {
      if (
        singleAccount
          ? false
          : (isAdmin && pathname === WebsiteRoutes.ProfileAccounts) ||
            pathname === WebsiteRoutes.ProfileProfile
      ) {
        return false;
      }
      const isWebsiteRoute = Boolean(
        Object.values(WebsiteRoutes).filter((path) => matchPath(pathname, { path, exact: true }))
          .length,
      );

      return isWebsiteRoute;
    }

    return false;
  };

  const closeSideBar = () => {
    dispatch(triggerSidebar());
  };
  const isBackButton = pathname === WebsiteRoutes.ProfileProfile && isAdmin;

  const isTBAccount = useIsTBAccount();

  const renderDesktopSidebar = () => (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isMenuExpanded,
        [classes.drawerClose]: !isMenuExpanded,
      })}
      sx={{
        position: isTabletView ? 'fixed' : 'static',
      }}
      onMouseEnter={() => {
        dispatch(setIsMenuExpanded(true));
      }}
      onMouseLeave={() => {
        if (isTabletView) {
          dispatch(setIsMenuExpanded(false));
        }
      }}
      classes={{
        docked: classes.docked,
        paper: clsx(classes.root, {
          [classes.drawerOpen]: isMenuExpanded,
          [classes.drawerClose]: !isMenuExpanded,
        }),
      }}
    >
      <Container collapsed={!isMenuExpanded}>
        {isBackButton && <BackButton isMenuExpanded={isMenuExpanded} />}
        <FlexWrapper>
          <WorkspaceSection isButtonCollapsed={!isMenuExpanded} />
          <Menu
            isSuperAdmin={isSuperAdmin}
            showVenuMenu={showVenuMenu()}
            collapsed={!isMenuExpanded}
          />
        </FlexWrapper>

        <Box style={{ marginBottom: '2px' }}>
          {isAccountsPage && testDistributor && (
            <AccountsButton
              handleClick={() => {
                dispatch(resetPage());
                handleDistrubtorsAccountsClick(testDistributor);
              }}
              title={'Test'}
              label="Accounts"
              isActive={isDistributorAccountButtonActive(testDistributor.id)}
              isCollapsed={!isMenuExpanded}
            />
          )}
        </Box>
        <Box style={{ marginBottom: '32px' }}>
          {isAdmin && isAccountsPage && (
            <AccountsButton
              handleClick={() => {
                handleSVadminArchivedAccountsClick();
              }}
              title="Archived "
              label="Accounts"
              isActive={isArchivedAccount}
              isCollapsed={!isMenuExpanded}
            />
          )}
        </Box>

        {isAccountsPage &&
          affiliates.map((item: AffiliateModel) => (
            <div key={item.id}>
              <AccountsButton
                handleClick={() => {
                  dispatch(resetPage());
                  handleAffiliatesAccountsClick(item);
                }}
                title={item.name}
                label="Accounts"
                isActive={isAffiliateAccountButtonActive(item.id)}
                isCollapsed={!isMenuExpanded}
              />
            </div>
          ))}
        {isAccountsPage &&
          distributorsWithoutTest.map((item: DistributorModel, index) => {
            // need to render space between SV and TruBlu buttons
            const firstSvIndex = distributorsWithoutTest.findIndex((d) => d.code.startsWith('SV'));
            const isFirstSvItem = firstSvIndex === index;

            return (
              <Box key={item.id} mt={isFirstSvItem ? '32px' : undefined}>
                <AccountsButton
                  handleClick={() => {
                    dispatch(resetPage());
                    handleDistrubtorsAccountsClick(item);
                  }}
                  title={item.name}
                  label="Accounts"
                  isActive={isDistributorAccountButtonActive(item.id)}
                  isCollapsed={!isMenuExpanded}
                />
              </Box>
            );
          })}

        {isAdmin && isAccountsPage ? (
          <AccountsButton
            handleClick={() => {
              dispatch(resetPage());
              if (pathname !== WebsiteRoutes.ProfileAccounts && !isUWMClient() && isSuperAdmin) {
                selectSvAccounts();
              } else {
                handleSVadminAccountsClick();
              }
            }}
            title="All Accounts"
            label=""
            isActive={isSVAdminAccountButtonActive}
            isCollapsed={!isMenuExpanded}
            isAllAccountsButton={true}
          />
        ) : (
          isAdmin &&
          !isQsFlowStarted && (
            <AccountsButton
              handleClick={() => {
                dispatch(resetPage());
                if (pathname !== WebsiteRoutes.ProfileAccounts && !isUWMClient() && isSuperAdmin) {
                  selectSvAccounts();
                } else {
                  handleSVadminAccountsClick();
                }
              }}
              title="All Accounts"
              label=""
              isActive={isSVAdminAccountButtonActive}
              isCollapsed={!isMenuExpanded}
              isAllAccountsButton={true}
            />
          )
        )}

        {selectedVenu && (
          <div className={classes.logoWrapper}>
            {isUWMClient() ? (
              <Box mb="20px">
                <img
                  className={classes.brandLogoUwm}
                  src={BRAND_360_LOGO}
                  alt="distributor-logo"
                  style={{
                    width: isMenuExpanded ? 'auto' : '45px',
                  }}
                />
              </Box>
            ) : isTBAccount || isTrubluClient ? (
              <Box
                className={classes.trubluLogoWrapper}
                style={{
                  height: isMenuExpanded ? '112px' : '65px',
                  marginBottom: isMenuExpanded ? '' : '47px',
                  marginLeft: isMenuExpanded ? '12px' : '1px',
                }}
              >
                <img
                  src={TRUBLU_SIDEBAR_LOGO}
                  className={classes.brandLogoTrublu}
                  alt="distributor-logo"
                />
              </Box>
            ) : (
              <Box
                className={classes.brandLogoSv}
                style={{
                  width: isMenuExpanded ? 'auto' : '55px',
                }}
              >
                <SocialVenuLogo />
              </Box>
            )}
          </div>
        )}
      </Container>
    </Drawer>
  );

  const renderTabletSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar} classes={{ paper: classes.root }}>
        <FlexWrapper>
          <Menu
            isSuperAdmin={isSuperAdmin}
            showVenuMenu={showVenuMenu()}
            collapsed={!isMenuExpanded}
          />
        </FlexWrapper>
        <UserLogoSection
          isSuperAdmin={isSuperAdmin}
          collapsed={!isMenuExpanded}
          imageUrl=""
          firstName={name}
          email={email}
        />
        <WorkspaceSection />

        {/* <CollapseSection collapsed={!open} onCollapse={onCollapseHandler} /> */}
      </Drawer>
    );
  };

  const renderMobileSidebar = () => {
    return (
      <Drawer open={sidebarOpen} onClose={closeSideBar} classes={{ paper: classes.root }}>
        <FlexWrapper>
          <Menu
            isSuperAdmin={isSuperAdmin}
            showVenuMenu={showVenuMenu()}
            collapsed={!isMenuExpanded}
          />
        </FlexWrapper>
        <UserLogoSection
          isSuperAdmin={isSuperAdmin}
          collapsed={!isMenuExpanded}
          imageUrl=""
          firstName={name}
          email={email}
        />
        <WorkspaceSection />

        {/* <CollapseSection collapsed={!open} onCollapse={onCollapseHandler} /> */}
      </Drawer>
    );
  };

  if (pathname.includes(WebsiteRoutes.WorkspaceMembersInvite)) return null;

  if (!sidebarMode || !isWindowLoaded) return null;

  switch (sidebarMode) {
    case LayoutMode.Desktop: {
      return renderDesktopSidebar();
    }
    case LayoutMode.Tablet: {
      return renderTabletSidebar();
    }
    case LayoutMode.Mobile: {
      return renderMobileSidebar();
    }
    default: {
      return renderDesktopSidebar();
    }
  }
};
