import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useStyles } from './AddVideo.styles';
import { Box, Button, Typography } from '@mui/material';
import { RewardStrings, WelcomeVideoModalStrings } from '../../../../../../common/localization/en';
import {
  createUploadVideoUrl,
  uploadVideoAsFile,
  reset,
} from '../../../../../../store/slices/uploadVideoSlice';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { useOnUploadProgress } from '../../../../../../services/hooks/useOnUploadProgress';
import { getUpdatedVideoUrl } from '../../../../../../common/components/VideoUploader/VideoUplader.helpers';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { checkIsWelcomeVideoDefault } from '../EditWelcomeVideo/EditWelcomeVideo';
import { LinearProgressWithLabel } from '../../../../../../common/components/LinearProgressBar/LinearProgress.helper';

interface Props {
  welcomeVideoUrl: string | null;
  onWelcomeVideoUploadComplete: (url: string) => void;
  onRemove: () => void;
  onRecordClick: () => void;
  isRecordMode: boolean;
}

const AddVideo: FC<Props> = ({
  welcomeVideoUrl,
  onWelcomeVideoUploadComplete,
  onRemove,
  onRecordClick,
  isRecordMode,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const onUploadProgress = useOnUploadProgress({ videoUpload: true });
  const { uploadingProgress } = useTypedSelector((state) => state.uploadVideo);
  const [isUploading, setIsUploading] = useState(false);

  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isCancelUpload = useRef(false);

  const uploadFile = (file: File) => {
    setIsUploading(true);
    setIsErrorAlert(false);
    dispatch(reset());
    createUploadVideoUrl().then((res) => {
      if (res) {
        dispatch(
          uploadVideoAsFile({
            options: res,
            data: file,
            onUploadProgress,
          }),
        ).then(async () => {
          const url = await getUpdatedVideoUrl(res.downloadUrl);
          onWelcomeVideoUploadComplete(url);
          setIsUploading(false);

          if (isCancelUpload.current) {
            isCancelUpload.current = false;
            return;
          }
        });
      }
    });
  };

  const clearInput = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (!file) {
      clearInput();
      return;
    }

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);

    videoElement.addEventListener('loadedmetadata', () => {
      const duration = videoElement.duration;

      if (duration > 30) {
        setIsErrorAlert(true);
        return;
      }

      uploadFile(file);
    });
  };

  const handleInputClick = (e: React.MouseEvent<HTMLElement>) => {
    if (inputRef?.current && e.detail === 1) {
      inputRef.current.click();
    }
  };

  return (
    <>
      {isUploading && (
        <Box className={styles.progressWrapper}>
          <LinearProgressWithLabel value={uploadingProgress} className={styles.progress} />
        </Box>
      )}
      <Typography className={styles.uploadInfo}>{WelcomeVideoModalStrings.CustomInfo}</Typography>
      <Typography fontWeight={600} fontSize="14px">
        (30 secs max).
      </Typography>
      <Box className={styles.container}>
        {checkIsWelcomeVideoDefault(welcomeVideoUrl) || !welcomeVideoUrl ? (
          <RoundedButton
            title={WelcomeVideoModalStrings.RecordVideo}
            height="36px"
            padding="8px 14px"
            fontSize="12px"
            fontWeight={600}
            disabled={isUploading || isRecordMode}
            onClick={onRecordClick}
          />
        ) : (
          <img
            src={welcomeVideoUrl?.replace('mp4', 'jpg')}
            alt="video thumbnail"
            className={styles.thumbnail}
          />
        )}
        <Box className={styles.buttonsWrapper}>
          <Button
            className={styles.uploadBtn}
            onClick={handleInputClick}
            variant="outlined"
            size="large"
            color="primary"
            disabled={isUploading || isRecordMode}
          >
            <input
              className={['visually-hidden'].join(' ')}
              type="file"
              accept="video/mp4,video/webm,video/quicktime"
              ref={inputRef}
              id="upload-input"
              name="upload-input"
              onChange={onChangeHandler}
            />
            {WelcomeVideoModalStrings.BrowseFiles}
          </Button>
          {!checkIsWelcomeVideoDefault(welcomeVideoUrl) && welcomeVideoUrl && (
            <Button
              className={styles.removeButton}
              onClick={() => {
                onRemove();
                clearInput();
                setIsErrorAlert(false);
              }}
              disabled={isUploading}
            >
              {WelcomeVideoModalStrings.Remove}
            </Button>
          )}
        </Box>
      </Box>
      {isErrorAlert && (
        <Typography className={styles.error}>{RewardStrings.DurationError}</Typography>
      )}
    </>
  );
};

export default AddVideo;
