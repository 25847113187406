import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './AddUsersManually.styles';
import { Box, Button, Typography } from '@mui/material';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import {
  DataSyncStrings,
  InviteStrings,
  SmsImportUserStrings,
} from '../../../../../../common/localization/en';
import { CustomTextField } from '../../../../../../common/components/customTextField/CustomTextField';
import { useFormik } from 'formik';
import { Toggle } from '../../../../../../common/components/toggle/CustomToggle';
import { handleChangeWithPattern } from '../../../../../../services/utilities';
import { validationSchema } from './AddUsersManually.helpers';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import {
  addManualRecord,
  deleteManualRecord,
  postTransactionBatch,
  putTransactionBatch,
  resetManualRecords,
} from '../../../../../../store/slices/dataSyncSlice';
import { importManualRecordsTableContent } from '../../../../../../services/helpers/tableMappers';
import { CommonTable } from '../../../../../../common/components/table/CommonTable';
import ApproveImportModal from '../ImportModal/ApproveImportModal';
import { useToasts } from 'react-toast-notifications';
import { CreateTransactionBatchResponse } from '../../../../../../api/models/transaction';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import { CheckBox } from '../../../../../../common/components/CheckBox/CheckBox';
import { theme } from '../../../../../../theme';
import { LoadingButton } from '@mui/lab';
import {
  addCampaignUsers,
  deleteCampaignUser,
} from '../../../../../../store/slices/createCampaignSlice';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../../../../../common/constants/events/common';

interface Props {
  isQuickStart?: boolean;
  newCampaignLayout?: boolean;
  onDataImported?: () => void;
  margin?: string;
}

export const AddUsersManually: FC<Props> = ({
  isQuickStart,
  onDataImported,
  newCampaignLayout,
  margin,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();

  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const { manualRecords, data } = useTypedSelector((state) => state.dataSyncSlice);
  const { campaignUsers } = useTypedSelector((state) => state.createCampaign);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptInTouched, setIsOptInTouched] = useState(false);
  const [confirmationChecked, setConfirmationChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const unlisten = history.listen(() => {
      dispatch(resetManualRecords());
    });

    return unlisten;
  }, [dispatch, history]);

  const initialValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    optedIn: false,
    optIn: '',
    attributes: {
      additionalProp1: '',
      additionalProp2: '',
      additionalProp3: '',
    },
    refId: '',
    email: '',
    transactionDate: '',
    derivedTransactionDate: '',
  };

  const {
    values,
    handleChange,
    errors,
    isValid,
    touched,
    handleBlur,
    submitForm,
    setFieldValue,
    resetForm,
    validateForm,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: true,
    isInitialValid: false,
    onSubmit: () => {
      const recordData = {
        ...values,
        phoneNumber: `+1${values.phoneNumber}`,
        id: uuidv4(),
      };
      if (newCampaignLayout) {
        dispatch(addCampaignUsers([{ ...recordData, isManuallyAdded: true }]));
      } else {
        dispatch(addManualRecord(recordData));
      }

      resetForm();
      validateForm();
      setIsOptInTouched(false);
    },
  });

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.transactionDate,
      pattern: '##/##/##',
      setVal: (val: string) => {
        setFieldValue('transactionDate', val);
      },
    });
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.phoneNumber,
      pattern: '(###) ###-####',
      setVal: (val: string) => {
        setFieldValue('phoneNumber', val);
      },
    });
  };

  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? (errors[field] as string) : undefined;
  };

  const handleImportSubmit = useCallback(
    async (name: string) => {
      setIsModalOpen(false);

      const batch = await dispatch(
        postTransactionBatch({
          name,
          venueId,
          records: manualRecords,
        }),
      );

      await dispatch(
        putTransactionBatch({ id: (batch.payload as CreateTransactionBatchResponse).id }),
      );
      dispatch(resetManualRecords());
      setIsLoading(false);
      addToast(SmsImportUserStrings.ImportSuccess, {
        appearance: 'success',
        autoDismiss: true,
      });
    },
    [venueId, manualRecords, addToast, dispatch],
  );

  const handleRowDelete = (id: string) => {
    if (newCampaignLayout) {
      dispatch(deleteCampaignUser(id));
    } else {
      dispatch(deleteManualRecord({ id }));
    }
    trackEvent(CommonEventNames.delete_input_user_row_click, { value: id });
  };

  const tableContent = useMemo(() => {
    return importManualRecordsTableContent({
      items: newCampaignLayout
        ? campaignUsers.items.filter((user) => 'isManuallyAdded' in user && user?.isManuallyAdded)
        : manualRecords,
    });
  }, [campaignUsers, manualRecords, newCampaignLayout]);

  const handleSend = () => {
    if (isQuickStart) {
      setIsLoading(true);
      handleImportSubmit(InviteStrings.DefaultUserImport);
      onDataImported?.();
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <ApproveImportModal
        isModalOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        handleImport={handleImportSubmit}
        usersCount={manualRecords.length}
        loading={Boolean(data.approveLoading)}
      />
      <Box
        className={classes.container}
        style={{
          margin: margin || '60px 0 0 50px',
        }}
      >
        <Box className={classes.inputsWrapper}>
          <Typography className={classes.title}>
            {isHealthCare
              ? SmsImportUserStrings.PatientInformation
              : SmsImportUserStrings.CreatorInformation}
          </Typography>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.FirstName}
                value={values.firstName}
                name="firstName"
                handleChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.add_users_manually_first_name_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('firstName')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.FirstNamePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.LastName}
                value={values.lastName}
                name="lastName"
                handleChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.add_users_manually_last_name_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('lastName')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.LastNamePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.CellNumber}
                value={values.phoneNumber}
                name="phoneNumber"
                handleChange={(e) => {
                  handlePhoneNumberChange(e);
                  trackEvent(CommonEventNames.add_users_manually_cell_number_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('phoneNumber')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.PhonePlaceholder}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              <Typography className={classes.inputLabel} mb="16px">
                {isHealthCare ? SmsImportUserStrings.OptInTrublu : SmsImportUserStrings.OptIn}
              </Typography>
              <Toggle
                name="optedIn"
                value={values.optedIn}
                onChange={(checked) => {
                  setFieldValue('optedIn', checked);
                  setIsOptInTouched(true);
                  trackEvent(CommonEventNames.add_users_manually_opt_in_status_changed, {
                    value: checked ? 'checked' : 'unchecked',
                  });
                }}
                className={classes.toggle}
                error={(isOptInTouched && errors['optedIn']) || ''}
              />
            </Box>
          </Box>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.Email}
                value={values.email}
                name="email"
                handleChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.add_users_manually_email_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('email')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.EmailPlaceholder}
                onBlur={handleBlur}
              />
            </Box>
            <Box className={classes.inputWrapper}>
              <CustomTextField
                label={SmsImportUserStrings.TransactionDate}
                value={values.transactionDate}
                name="transactionDate"
                handleChange={(e) => {
                  handleDateChange(e);
                  trackEvent(CommonEventNames.add_users_manually_transaction_date_input_typed, {
                    value: e.target.value,
                  });
                }}
                error={getFieldError('transactionDate')}
                labelTextClassName={classes.inputLabel}
                inputClassName={classes.input}
                placeholder={SmsImportUserStrings.TransactionPlaceholder}
                onBlur={handleBlur}
              />
            </Box>
          </Box>
          <Button
            variant="contained"
            disabled={!isValid}
            onClick={() => {
              submitForm();
              trackEvent(CommonEventNames.add_users_manually_add_user_button_click);
            }}
            className={classes.btnAdd}
          >
            {SmsImportUserStrings.AddUser}
          </Button>
        </Box>
        <Box className={classes.tableWrapper}>
          <CommonTable
            content={tableContent}
            page={0}
            totalItems={manualRecords.length}
            totalPages={1}
            isLoading={false}
            tablePadding="0"
            size={manualRecords.length}
            onSizeChange={() => {}}
            disableFullHeight
            containerClassName={classes.tableContainer}
            topSectionClassName={classes.topSectionClassName}
            headerCellClassName={classes.headerCellClassName}
            rowClassName={classes.rowClassName}
            withRowDelete={true}
            withCellDivider={true}
            handleRowDelete={handleRowDelete}
            withAutoScroll={false}
          />
        </Box>
        {!newCampaignLayout && (
          <Box className={classes.btnNextWrapper}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading || manualRecords.length === 0 || !confirmationChecked}
              variant="contained"
              color="primary"
              size="large"
              style={{ borderRadius: '100px' }}
              onClick={() => {
                handleSend();
                trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
              }}
            >
              {isQuickStart
                ? SmsImportUserStrings.ScheduleDeliveryButtonTitleQuickStart
                : SmsImportUserStrings.ScheduleDeliveryButtonTitle}
            </LoadingButton>
          </Box>
        )}

        {!newCampaignLayout && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <CheckBox
              isChecked={confirmationChecked}
              onChange={(_, isChecked) => {
                setConfirmationChecked(isChecked);
                trackEvent(CommonEventNames.add_users_manually_agreement_checkbox_click);
              }}
              outlineColor="gray"
            />
            <Typography width="380px" fontSize="12px" color={theme.palette.common.gray}>
              {DataSyncStrings.ImportModalAgreement}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
};
