import { makeStyles } from '@mui/styles';

export const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  videosTitle: {
    position: 'sticky',
    top: '102px',
    left: 0,
    zIndex: 9,
    background: '#FFF',
    margin: '0 -32px',
    padding: '0 32px 8px 32px',
  },
  videosContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    paddingBottom: theme.spacing(3),
    justifyContent: 'space-between',
    marginTop: '16px',
  },
}));
