export enum WebAppSettingsEvents {
  NavigationTabChange = 'navigation_tab_change',
  BrandingStyleCarouselTabChange = 'branding_style_carousel_tab_change',
  ColorPaletteSelect = 'color_palette_click',
  BackgroundImageUpload = 'background_image_upload',
  LogoUpload = 'logo_upload',
  BrandColorFormSaveClick = 'brand_color_form_save_click',
  EmailFieldToggle = 'email_field_toggle',
  SignUpFormSave = 'sign_up_form_save',
  LogoToggle = 'logo_toggle',
  TopgolfOverlayToggle = 'topgolf_overlay_toggle',
  LogoPositingChange = 'logo_positing_change',
  VideoPlaybackFormSave = 'video_playback_form_save',
  TrackingPixelCodeCopy = 'tracking_pixel_code_copy',
  TrackingPixelInstructionsCopy = 'tracking_pixel_instructions_copy',
  TrackingPixelTabChange = 'tracking_pixel_tab_change',
}
