import { FC, Fragment } from 'react';
import { Box, Grid } from '@mui/material';
import MoodIcon from '@mui/icons-material/Mood';

import { ManagerAccount } from '../../../../../../../store/storeModels';
import { VenueWizardPreviewStepStrings } from '../../../../../../../common/localization/en';
import { LabelValuePair, UsersPreviewProps, useStyles } from './UsersPreview.helper';
import { SectionTitle } from '../../../../../../venuewizard/wizardSteps/Steps.style';

export const UsersPreview: FC<UsersPreviewProps> = ({ accounts }) => {
  const classes = useStyles();
  let Labels: Partial<ManagerAccount> = {
    email: VenueWizardPreviewStepStrings.LabelUserEmail,
    name: VenueWizardPreviewStepStrings.LabelUserName,
  };
  type AccountKeys = 'email' | 'name';

  const renderKeyValuePairs = (labelValuePair: LabelValuePair[]) => {
    return labelValuePair.map((pair, index) => {
      return (
        <Fragment key={index}>
          <Grid item xs={12}>
            <Box marginTop="6px">
              <Grid container>
                <Grid item xs={3}>
                  <p className={classes.textLabel}>{pair.label}</p>
                </Grid>
                <Grid item xs={9}>
                  <p className={classes.textValue}>{pair.value}</p>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Fragment>
      );
    });
  };
  const renderItems = () => {
    return accounts.map((item, index) => {
      let labelValuePair = (Object.keys(Labels) as Array<AccountKeys>).map((key) => {
        return {
          label: Labels[key],
          value: item[key],
        };
      });

      return (
        <Box mb={3} key={index}>
          <Grid container>
            <Grid item xs={1}>
              <MoodIcon color="secondary" />
            </Grid>
            <Grid item xs={11}>
              <p className={classes.textValue}>{`${
                VenueWizardPreviewStepStrings.OrganizationManager
              } ${index + 1}`}</p>
              {renderKeyValuePairs(labelValuePair)}
            </Grid>
          </Grid>
        </Box>
      );
    });
  };
  return (
    <Box mt={2}>
      <Grid container>
        <Box mb={2}>
          <Grid item xs={12}>
            <SectionTitle>{VenueWizardPreviewStepStrings.OrganizationManagers}</SectionTitle>
          </Grid>
        </Box>
        <Grid item xs={12}>
          {renderItems()}
        </Grid>
      </Grid>
    </Box>
  );
};
