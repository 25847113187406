import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from '../../store';
import { useParams } from 'react-router-dom';
import { setNewSearchFilterAttributes, setTarget } from '../../store/slices/searchFiltersSlice';

const getStorageKey = (page: string) => {
  return `${page}_unsaved_filter`;
};

export const useUnsavedFilter = ({ page }: { page: 'videos' | 'users' }) => {
  const params = useParams<{ filterId: string }>();
  const isUnsavedFilterPage = !params.filterId || params.filterId === ':filterId';

  const { target, newFilterAttributes } = useTypedSelector((state) => state.SearchFilterSlice);

  const dispatch = useAppDispatch();

  const storageKey = getStorageKey(page);

  // load filter from localStorage
  useEffect(() => {
    if (!isUnsavedFilterPage) return;

    if (newFilterAttributes && target) {
      localStorage.setItem(
        storageKey,
        JSON.stringify({
          newFilterAttributes,
          target,
        }),
      );
    }
  }, [isUnsavedFilterPage, newFilterAttributes, storageKey, target]);

  // save unsaved filter in localStorage
  useEffect(() => {
    if (!isUnsavedFilterPage) return;

    const filterAttributesRaw = localStorage.getItem(storageKey);
    if (!filterAttributesRaw) return;

    const savedFilters: any = JSON.parse(filterAttributesRaw);

    dispatch(setTarget(savedFilters.target));
    if (savedFilters.newFilterAttributes) {
      dispatch(setNewSearchFilterAttributes(savedFilters.newFilterAttributes));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
