import * as React from 'react';

export const HitCalendarEngagementGoal: React.FC = () => {
  return (
    <svg width={41} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83 12.023S6.955 7.924 5.832 8.456c-1.124.531 3.998 3.567 3.998 3.567zM9.647 10.922s-.44-3.081-1.131-2.96c-.692.12 1.131 2.96 1.131 2.96z"
        fill="#A8D29F"
      />
      <path
        d="M7.217 20.526s.276-1.37-.797-1.543c0 0-.728-.025-1.156.25 0 0 1.217.703 1.072.922-.145.219-1.3-.882-1.3-.882s-1.304.572-1.595.987c0 0 1.541.275 1.347.451-.194.176-1.811-.035-1.811-.035s-1.043.84-.589 1.51c.454.67.972.665 1.136.549.164-.117.967-1.078 1.07-.947.101.13-.252.942-.67 1.205 0 0 1.313.442 1.708-.119.395-.56.213-.997.463-1.339.25-.342.538-.503.487-.268-.052.235-.575.728-.578 1.177-.002.448-.046.775.465.378.512-.396 1.191-1.313.97-1.804l-.222-.492z"
        fill="#82B378"
      />
      <path
        d="M2.965 21.888s3.06-1.871 4.252-1.362c0 0 .408.373 1.111.528l.185.159s-.861-.27-1.075-.196c0 0-.316-1.28-4.473.871z"
        fill="#82B378"
      />
      <path
        d="M8.328 21.054s.95.121 1.492.984l-.116.08s-.727-.8-1.315-.942c-.588-.143-.06-.122-.06-.122z"
        fill="#82B378"
      />
      <path
        d="M8.154 16.526s2.223-1.246 1.185-2.939c0 0-.83-1.039-1.719-1.306 0 0 .475 2.524 0 2.583-.474.06-.326-2.85-.326-2.85s-2.341-1.128-3.26-1.039c0 0 1.452 2.464.978 2.405-.474-.06-2.104-2.553-2.104-2.553s-2.401-.446-2.786.98c-.386 1.424.237 2.137.592 2.226.356.089 2.638.059 2.579.356-.06.297-1.6.772-2.46.505 0 0 .948 2.345 2.193 2.226 1.245-.119 1.63-.89 2.401-.95.77-.06 1.334.148.948.356-.385.208-1.689.068-2.311.598-.623.53-1.127.857.03 1.094 1.155.238 3.23.09 3.645-.801l.415-.89z"
        fill="#A8D29F"
      />
      <path
        d="M1.218 12.252s6.224 2.019 6.936 4.275c0 0-.03 1.009.593 2.167v.445s-.652-1.514-1.008-1.722c0 0 1.393-1.959-6.52-5.165z"
        fill="#82B378"
      />
      <path
        d="M8.747 18.695s.96 1.46.412 3.237l-.247-.066s.24-1.958-.262-2.942c-.502-.985.097-.229.097-.229z"
        fill="#82B378"
      />
      <path
        d="M10.413 6.094L7.087 23.315c-.068.35.2.676.557.676h1.754l1.015-17.897z"
        fill="#ED7D2B"
      />
      <path
        d="M32.522 11.025s-1.496-.956-.708-2.091c0 0 .619-.69 1.248-.84 0 0-.43 1.736-.103 1.796.327.06.34-1.969.34-1.969s1.673-.69 2.309-.592c0 0-1.108 1.655-.776 1.633.332-.022 1.565-1.691 1.565-1.691s1.687-.214 1.898.793c.211 1.006-.25 1.476-.5 1.524-.252.048-1.837-.064-1.808.145.03.208 1.082.6 1.69.449 0 0-.752 1.593-1.613 1.46-.861-.132-1.099-.684-1.632-.756-.533-.072-.933.05-.673.21.26.16 1.172.115 1.583.508.412.393.75.64-.064.76-.813.118-2.25-.068-2.503-.704l-.253-.635z"
        fill="#C7DCFB"
      />
      <path
        d="M37.515 8.33s-4.409 1.154-4.993 2.694c0 0-.02.703-.5 1.483l-.017.31s.514-1.027.77-1.157c0 0-.891-1.418 4.74-3.33z"
        fill="#C7DCFB"
      />
      <path
        d="M32.023 12.508s-.726.977-.416 2.234l.175-.036s-.09-1.37.3-2.035c.388-.665-.059-.163-.059-.163z"
        fill="#C7DCFB"
      />
      <path
        d="M28.494 6.875s-2.299-.506-1.835-2.28c0 0 .456-1.141 1.17-1.616 0 0 .256 2.358.691 2.283.435-.075-.474-2.607-.474-2.607S29.814 1.03 30.65.862c0 0-.624 2.568-.221 2.388.403-.18 1.177-2.822 1.177-2.822S33.61-.61 34.331.546c.722 1.157.362 1.953.072 2.128-.29.174-2.316.76-2.184 1.006.131.247 1.62.253 2.31-.214 0 0-.212 2.328-1.344 2.557-1.132.229-1.68-.35-2.376-.195-.698.154-1.14.489-.743.569.396.08 1.511-.393 2.203-.092.692.302 1.225.455.267.975-.958.52-2.832.946-3.437.27l-.605-.675z"
        fill="#DFEBFD"
      />
      <path
        d="M33.48 1.234s-4.961 3.455-4.987 5.64c0 0 .297.885.056 2.075l.12.394s.17-1.513.43-1.792c0 0-1.756-1.359 4.382-6.317z"
        fill="#DFEBFD"
      />
      <path
        d="M28.55 8.95s-.459 1.547.5 2.971l.2-.125s-.735-1.666-.554-2.67c.18-1.006-.147-.177-.147-.177z"
        fill="#DFEBFD"
      />
      <path
        d="M10.453 6.024L8.25 22.934a.936.936 0 00.927 1.057h3.234L10.586 5.963l-.133.061z"
        fill="#78ACEA"
      />
      <path
        d="M10.453 6.024l-.987 16.802a1.1 1.1 0 001.098 1.165h4.823L10.453 6.024z"
        fill="#699DEE"
      />
      <path d="M10.416 6.161l.48 16.664a1.1 1.1 0 001.097 1.166h4.823l-6.4-17.83z" fill="#5288D8" />
      <path
        d="M33.509 23.99H14.49a.984.984 0 01-.967-.807L10.63 7.333a1.171 1.171 0 011.15-1.383h18.795c.474 0 .882.34.967.808l2.934 16.07a.984.984 0 01-.967 1.163z"
        fill="#D7E9FF"
      />
      <path
        d="M11.006 9.391h21.03l-.54-2.992a.545.545 0 00-.535-.449h-20.37a.179.179 0 00-.175.21l.59 3.231z"
        fill="#F99746"
      />
      <path
        d="M11.498 5.05a.913.913 0 00-.912.913h.176c0-.407.33-.738.736-.738a.738.738 0 01.042 1.474v.176a.917.917 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path
        d="M11.78 6.782a.198.198 0 11-.395 0 .198.198 0 01.395 0zM14.767 5.05a.913.913 0 00-.912.914h.175a.738.738 0 11.779.737v.175a.916.916 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path
        d="M15.049 6.783a.198.198 0 11-.395 0 .198.198 0 01.395 0zM18.036 5.05a.913.913 0 00-.912.914h.175a.738.738 0 11.779.737v.175a.916.916 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path
        d="M18.317 6.783a.198.198 0 11-.395 0 .198.198 0 01.396 0zM21.304 5.05a.913.913 0 00-.911.914h.175c0-.407.33-.738.736-.738a.738.738 0 01.043 1.474v.176a.916.916 0 00.87-.912.914.914 0 00-.913-.913z"
        fill="#78ACEA"
      />
      <path
        d="M21.587 6.783a.198.198 0 11-.395 0 .198.198 0 01.395 0zM24.573 5.05a.913.913 0 00-.911.914h.175c0-.407.33-.738.736-.738a.738.738 0 01.042 1.474v.176a.916.916 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path
        d="M24.855 6.783a.198.198 0 11-.395 0 .198.198 0 01.395 0zM27.842 5.05a.913.913 0 00-.912.914h.175a.738.738 0 11.779.737v.175a.916.916 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path
        d="M28.124 6.783a.198.198 0 11-.395 0 .198.198 0 01.395 0zM31.11 5.05a.913.913 0 00-.91.914h.174a.738.738 0 11.779.737v.175a.917.917 0 00.87-.912.914.914 0 00-.912-.913z"
        fill="#78ACEA"
      />
      <path d="M31.393 6.783a.198.198 0 11-.396 0 .198.198 0 01.396 0z" fill="#78ACEA" />
      <path
        d="M23.167 15.012h-1.063a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.024.123-.058.223-.18.223zM25.85 15.012h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.023-.123.058-.224.18-.224h1.064c.123 0 .243.1.266.224l.205 1.065c.024.123-.057.223-.18.223z"
        fill="#AFCDFB"
      />
      <path
        d="M28.533 15.012H27.47a.285.285 0 01-.267-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.024.123-.058.223-.18.223z"
        fill="#78ACEA"
      />
      <path
        d="M15.12 15.012h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.024-.123.058-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.024.123-.057.223-.18.223z"
        fill="#AFCDFB"
      />
      <path
        d="M13.955 11.817h-.457a.123.123 0 01-.115-.096l-.088-.458c-.01-.053.025-.096.078-.096h.457c.052 0 .104.043.114.096l.088.458c.01.053-.024.096-.077.096z"
        fill="#F99746"
      />
      <path
        d="M17.802 15.012H16.74a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.023.123-.058.223-.18.223zM20.485 15.012h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.023-.123.058-.224.18-.224h1.064c.123 0 .243.1.266.224l.205 1.065c.024.123-.057.223-.18.223z"
        fill="#AFCDFB"
      />
      <path
        d="M31.215 15.012h-1.063a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.023.123-.058.223-.18.223z"
        fill="#78ACEA"
      />
      <path
        d="M22.722 12.7H21.66a.285.285 0 01-.267-.224l-.205-1.065c-.023-.123.058-.223.18-.223h1.064c.123 0 .243.1.266.223l.205 1.065c.024.123-.057.224-.18.224zM25.405 12.7h-1.063a.285.285 0 01-.267-.224l-.205-1.065c-.024-.123.057-.223.18-.223h1.063c.123 0 .243.1.267.223l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#AFCDFB"
      />
      <path
        d="M28.087 12.7h-1.063a.285.285 0 01-.266-.224l-.205-1.065c-.024-.123.057-.223.18-.223h1.063c.123 0 .243.1.267.223l.205 1.065c.023.123-.058.224-.18.224zM30.77 12.7h-1.063a.285.285 0 01-.267-.224l-.205-1.065c-.023-.123.058-.223.18-.223h1.064c.123 0 .243.1.266.223l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#78ACEA"
      />
      <path
        d="M23.64 17.463h-1.064a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.023.123-.058.223-.18.223zM26.322 17.463H25.26a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.023.123-.058.223-.18.223z"
        fill="#AFCDFB"
      />
      <path
        d="M29.005 17.463h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.024.123-.057.223-.18.223z"
        fill="#78ACEA"
      />
      <path
        d="M15.592 17.463h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.023-.123.058-.224.18-.224h1.064c.123 0 .243.1.266.224l.205 1.065c.024.123-.057.223-.18.223zM18.274 17.463h-1.063a.285.285 0 01-.266-.223l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.065c.024.123-.058.223-.18.223zM20.957 17.463h-1.063a.285.285 0 01-.267-.223l-.205-1.065c-.023-.123.058-.224.18-.224h1.064c.122 0 .243.1.266.224l.205 1.065c.024.123-.057.223-.18.223z"
        fill="#AFCDFB"
      />
      <path
        d="M31.687 17.463h-1.063a.285.285 0 01-.266-.223l-.206-1.065c-.023-.123.058-.224.18-.224h1.064c.123 0 .243.1.266.224l.206 1.065c.023.123-.058.223-.18.223z"
        fill="#78ACEA"
      />
      <path
        d="M24.112 19.915h-1.063a.285.285 0 01-.267-.224l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.267.224l.205 1.065c.024.123-.057.224-.18.224zM26.794 19.915h-1.063a.285.285 0 01-.267-.224l-.204-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.266.224l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#AFCDFB"
      />
      <path
        d="M29.477 19.915h-1.063a.285.285 0 01-.267-.224l-.205-1.065c-.023-.123.058-.224.18-.224h1.063c.123 0 .243.101.267.224l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#78ACEA"
      />
      <path
        d="M16.064 19.915h-1.063a.285.285 0 01-.266-.224l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.266.224l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#AFCDFB"
      />
      <path
        d="M18.747 19.915h-1.064a.285.285 0 01-.266-.224l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.267.224l.205 1.065c.024.123-.058.224-.18.224z"
        fill="#F99746"
      />
      <path
        d="M21.43 19.915h-1.064a.285.285 0 01-.267-.224l-.204-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.266.224l.205 1.065c.024.123-.057.224-.18.224z"
        fill="#AFCDFB"
      />
      <path
        d="M32.16 19.915h-1.063a.285.285 0 01-.267-.224l-.205-1.065c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.101.267.224l.205 1.065c.023.123-.058.224-.18.224z"
        fill="#78ACEA"
      />
      <path
        d="M24.557 22.227h-1.063a.285.285 0 01-.267-.224l-.205-1.064c-.023-.123.058-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.064c.024.123-.057.224-.18.224zM27.24 22.227h-1.063a.285.285 0 01-.267-.224l-.205-1.064c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.064c.024.123-.057.224-.18.224z"
        fill="#AFCDFB"
      />
      <path
        d="M29.922 22.227H28.86a.285.285 0 01-.266-.224l-.205-1.064c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.064c.023.123-.058.224-.18.224z"
        fill="#78ACEA"
      />
      <path
        d="M16.51 22.227h-1.064a.285.285 0 01-.266-.224l-.205-1.064c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.064c.024.123-.058.224-.18.224zM19.192 22.227h-1.063a.285.285 0 01-.267-.224l-.205-1.064c-.023-.123.058-.224.18-.224h1.064c.122 0 .242.1.266.224l.205 1.064c.024.123-.057.224-.18.224zM21.875 22.227H20.81a.285.285 0 01-.266-.224l-.205-1.064c-.024-.123.057-.224.18-.224h1.063c.123 0 .243.1.267.224l.205 1.064c.024.123-.058.224-.18.224zM17.177 11.396H14.36a.076.076 0 010-.15h2.816a.076.076 0 010 .15zM17.262 11.737h-2.816a.076.076 0 010-.151h2.816a.076.076 0 010 .151z"
        fill="#AFCDFB"
      />
      <path
        d="M14.156 8.854h-.966a.144.144 0 010-.289h.966a.144.144 0 010 .289zM16.808 8.855h-.966a.144.144 0 010-.289h.966a.144.144 0 010 .289zM19.46 8.855h-.966a.144.144 0 010-.289h.966a.144.144 0 010 .289zM22.112 8.855h-.967a.144.144 0 010-.289h.967a.144.144 0 010 .289zM24.764 8.855h-.967a.144.144 0 010-.289h.967a.144.144 0 010 .289zM27.416 8.855h-.967a.144.144 0 010-.289h.967a.144.144 0 010 .289zM30.068 8.855H29.1a.144.144 0 010-.289h.967a.144.144 0 010 .289z"
        fill="#fff"
      />
      <path
        d="M23.955 12.722c-.213 0-.368-.068-.453-.2-.163-.252-.037-.607.372-1.052.3-.325.649-.583.652-.586a.03.03 0 01.037.05c-.014.01-1.36 1.009-1.01 1.555.112.172.35.184.53.163.334-.039.769-.219 1.164-.481.409-.272.697-.579.79-.841.062-.173.043-.295-.056-.363-.127-.088-.394-.088-.712 0-.305.084-.612.236-.8.396a.03.03 0 11-.04-.047c.195-.165.51-.322.824-.409.34-.094.619-.09.763.01.086.059.169.182.08.433-.098.275-.395.593-.815.872-.403.268-.848.452-1.19.492a1.237 1.237 0 01-.136.008z"
        fill="#C65447"
      />
      <path
        d="M29.536 10.967l-.11-.207s.054-.37-.408-.603c0 0-.169-.07-.264.069-.088.127.167.104.154.321a.4.4 0 00.232.416l.119.138.277-.134z"
        fill="#FFB27D"
      />
      <path
        d="M33.903 10.47s-.397 1.952-2.58 2.59c-.683.198-2.231-2.05-2.231-2.05s.054-.207.395-.16c0 0 1.508 1 1.698 1.11.169.095 1.276-1.24 1.781-1.455.506-.216.937-.034.937-.034z"
        fill="#233862"
      />
      <path d="M27.518 10.436l.087.473 2.378-.44-.088-.474-2.377.44z" fill="#C65447" />
      <path
        d="M27.008 10.776l.597.134-.088-.473-.509.339zM30.2 10.101l.027.15a.17.17 0 01-.135.197l-.11.02-.088-.472.12-.023a.16.16 0 01.186.128z"
        fill="#FFB27D"
      />
      <path
        d="M36.51 22.735s.272.248.219.424c-.053.176-.298.293-.33.509-.034.215-.166.332-.285.332h-.567s-.088-.151.124-.197c.211-.046.291-.298.278-.533a4.16 4.16 0 01.02-.548l.45-.104.092.117zM33.187 23.376s.048.564-.07.623l-1.523-.003s0-.177.244-.216c.245-.039.674-.182.747-.502.073-.319.602.098.602.098z"
        fill="#233862"
      />
      <path
        d="M31.901 15.415l.175 2.175c.128 1.614.282 3.595.298 3.97.026.652.132 1.695.132 1.695s.251.248.753.143c0 0 .448-1.488.576-3.063l.006-.078c.119-1.603.304-4.979.304-4.979l-2.244.137z"
        fill="#F99746"
      />
      <path
        d="M31.901 15.415l.175 2.176 1.759 2.744.006-.078c.119-1.603.304-4.979.304-4.979l-2.244.137z"
        fill="#ED7D2B"
      />
      <path
        d="M32.268 15.532s1.447 3.578 2.399 5.26c.952 1.68 1.249 2.15 1.249 2.15s.545-.215.595-.362c0 0-.364-3.246-1.001-4.037-.36-.447-.784-1.79-.803-2.258-.02-.47-.13-1.398-.13-1.398l-2.309.645z"
        fill="#F99746"
      />
      <path
        d="M34.172 10.019s-.98.473-1.544.297c0 0 .14-.175.383-.306a4.582 4.582 0 01-.044-.296c-.024-.192-.024-.301-.024-.301l.378-.298.358-.285c-.028.801.493 1.189.493 1.189z"
        fill="#FFB27D"
      />
      <path
        d="M31.84 15.497a.088.088 0 00.058.024c.213-.005.832.113 1.355.06.443-.043.834-.171 1.253-.355.056-.026.185-.095.182-.155a8.852 8.852 0 00-.017-.289 18.408 18.408 0 01-.05-1.004 12.59 12.59 0 01.023-1.08 8.22 8.22 0 00.01-.395c.002-.42-.029-.82-.09-1.178-.106-.621-.306-1.107-.6-1.323-.04-.03-.283.087-.509.197a.937.937 0 01-.608.072l-.195.172c-.237.216-.498.513-.613.963-.095.381-.152 1.07-.182 1.786-.015.325-.026.65-.03.961v.01c-.003.033-.003.065-.003.097-.01.795 0 1.427.016 1.437z"
        fill="#2B478B"
      />
      <path
        d="M33.686 9.137s-.248.452-.719.578c-.024-.193-.024-.302-.024-.302l.378-.298.365.022z"
        fill="#ED985F"
      />
      <path
        d="M32.771 9.4s.934.153 1.022-.422c.087-.575.267-.943-.326-1.065-.592-.122-.742.08-.819.265-.076.185-.118 1.168.123 1.222z"
        fill="#FFB27D"
      />
      <path
        d="M34.132 7.844c.009-.01.029-.018.072-.02 0 0-.035-.022-.081-.023-.01-.06-.041-.168-.145-.194 0 0 .055.03.063.094-.12-.124-.38-.214-1.095-.157-.944.074-.633.68-.51.751.121.072.429-.125.656-.107.228.018.271.347.348.393.078.045.047-.035.152-.082.104-.048.114.126.084.238-.03.113.113.262.113.262l.145-.157c.145-.156.282-.819.216-.96a1.621 1.621 0 00-.018-.038z"
        fill="#2C3A64"
      />
      <path
        d="M29.424 10.75s.032-.126-.056-.184c-.088-.057-.17-.212-.206-.191-.077.044.042.18.02.348-.008.06.13.215.242.028z"
        fill="#FFB27D"
      />
      <path
        d="M34.62 13.779a12.59 12.59 0 01.024-1.08c-.089-.44-.223-.832-.421-.845 0 0-.589.928.398 1.925z"
        fill="#233862"
      />
      <path
        d="M35.4 15.377s-.05.478-.103.569c-.054.09-.283.38-.38.436-.099.057.059-.38.039-.466-.02-.085-.08.155-.124.161-.043.006-.032-.21-.033-.29-.002-.078.21-.273.284-.44.074-.168.317.03.317.03z"
        fill="#FFB27D"
      />
      <path
        d="M33.963 9.797s.59.28.874.711c.44.669.95 3.105.959 3.33.009.226-.372 1.819-.372 1.819s-.387-.026-.487-.221c0 0 .078-1.637.029-1.742-.05-.105-1.613-2.195-1.437-2.64.176-.447.434-1.257.434-1.257z"
        fill="#2B478B"
      />
      <path d="M27.314 10.572l.03.28-.336-.076.306-.204z" fill="#C65447" />
      <path
        d="M40.103 23.991H38.4c-.092-.078.063-.253.224-.265.07-.006.159.009.26.03.024-.311.157-1.713.662-1.657.567.063 0 .617-.16.946a7.815 7.815 0 00-.302.756l.143.029c.075-.232.355-1.01.71-.964.418.053.25.365-.046.55-.217.136-.483.33-.61.423.107.017.22.029.333.026.291-.006.434.08.49.126zM1.222 23.91c-.09-.143-.362-.512-.724-.463-.395.053.435.314.69.463a.423.423 0 01-.141-.016c-.17-.06-.46.055-.554.096H2.11a.257.257 0 00-.116-.148c-.026-.016-.08-.018-.149-.013.096-.114.163-.476-.104-.383-.196.068-.195.29-.176.42a8.64 8.64 0 01-.126.02c-.08-.411-.365-1.615-.942-1.647-.584-.033.496 1.249.856 1.658-.046.006-.09.01-.131.012z"
        fill="#82B378"
      />
    </svg>
  );
};
