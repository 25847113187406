import { useToasts as useToast } from 'react-toast-notifications';
import { useState } from 'react';

export function useToasts() {
  const { addToast, ...rest } = useToast();
  const [isToastVisible, setIsToastVisible] = useState(false);

  const customAddToast = (...args: Parameters<typeof addToast>) => {
    const [content, options, callback] = args;

    if (!isToastVisible) {
      setIsToastVisible(true);
      addToast(
        content,
        {
          ...options,
          onDismiss: () => {
            setIsToastVisible(false);
          },
        },
        callback,
      );
    }
  };

  return {
    addToast: customAddToast,
    ...rest,
  };
}
