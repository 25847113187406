import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import backgroundImg from '../../../common/assets/newShareVideoPreview-2.png';
import TBbackgroundImg from '../../../common/assets/trublunewShareVideoPreview.jpg';

export const useStyles = makeStyles<Theme>((theme) => ({
  previewWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    bottom: '0',
    pointerEvents: 'all',
  },
  previewWrapNotTopGolf: {
    width: '100%',
    height: '91%',
    pointerEvents: 'all',
  },
  overLayImage: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    bottom: '2px',
    left: '2px',
    borderRadius: '10px',
  },
  logo: {
    zIndex: '2',
    objectFit: 'contain',
    objectPosition: 'left',
    height: '32px',
    position: 'absolute',
    top: '25px',
    left: '25px',
  },
  playIconWrapper: {
    position: 'absolute',
    bottom: '-16px',
    left: '-9px',
    overflow: 'hidden',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'cover',
    width: '107%',
    height: '106%',
  },
  rectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`,
    border: 'none',
    margin: '0 auto',
  },
  tbrectangle: {
    backgroundSize: 'cover',
    backgroundImage: `url(${TBbackgroundImg})`,
  },

  PlayIcon: {
    zIndex: '2',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  topInfo: {
    position: 'absolute',
    top: '5px',
    left: '0',
    margin: '0 6px',
    padding: '12px 2px',
    border: '1px solid #2D11AD',
    borderRadius: '7px',
    backgroundColor: theme.palette.common.white,
  },
  topInfoTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontSize: '15px',
  },
  topInfoInfo: {
    textAlign: 'center',
    color: '#475467',
    fontSize: '12px',
  },
  video: {
    pointerEvents: 'all',
    height: '470px',
  },
  bottomWrapper: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    width: '100%',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 17px',
    width: '100%',
  },
  muteIcon: {
    transform: 'scale(0.7)',
  },
  skipWrapper: {
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    fontSize: '15px',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  navigationItem: {
    flex: 1,
    fontSize: '14px',
    textAlign: 'center',
  },
  navigationWelcome: {
    color: theme.palette.common.white,
    borderBottom: '1px solid #2D11AD',
    borderRight: '1px solid #2D11AD',
    paddingRight: '4px',
  },
  navigationRest: {
    color: '#999',
    borderTop: '1px solid #2D11AD',
    backgroundColor: 'rgba(255, 255, 255, 0.60)',
  },
}));
