import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useStyles } from './InitialPreview.styles';
import { CampaignInvitesStrings } from '../../../common/localization/en';
import CampaignRewardIcon from '../../../common/assets/CampaignRewardIcon';
import CampaignInvitationIcon from '../../../common/assets/CampaignInvitationIcon';
import CampaignWelcomeVideoIcon from '../../../common/assets/CampaignWelcomeVideoIcon';

export const InitialPreview: FC = () => {
  const classes = useStyles();

  const tiles = [
    {
      icon: <CampaignRewardIcon />,
      title: CampaignInvitesStrings.OfferAReward,
      listItems: [
        CampaignInvitesStrings.RewardPoint1,
        CampaignInvitesStrings.RewardPoint2,
        CampaignInvitesStrings.RewardPoint3,
      ],
    },
    {
      icon: <CampaignInvitationIcon />,
      title: CampaignInvitesStrings.BrandYourInvitation,
      listItems: [
        CampaignInvitesStrings.InvitationPoint1,
        CampaignInvitesStrings.InvitationPoint2,
        CampaignInvitesStrings.InvitationPoint3,
      ],
    },
    {
      icon: <CampaignWelcomeVideoIcon />,
      title: CampaignInvitesStrings.UseAWelcomeVideo,
      listItems: [
        CampaignInvitesStrings.WelcomeVideoPoint1,
        CampaignInvitesStrings.WelcomeVideoPoint2,
        CampaignInvitesStrings.WelcomeVideoPoint3,
      ],
    },
  ];

  return (
    <Box className={classes.container}>
      <Typography className={classes.title}>
        {CampaignInvitesStrings.InitialPreviewTitle}
      </Typography>
      <Typography className={classes.info}>{CampaignInvitesStrings.InitialPreviewInfo}</Typography>
      <Box className={classes.tilesWrapper}>
        {tiles.map((tile) => (
          <Box className={classes.tileWrapper}>
            <Box className={classes.innerWrapper}>
              {tile.icon}
              <Typography className={classes.tileTitle}>{tile.title}</Typography>
              <ul className={classes.tileList}>
                {tile.listItems.map((point) => (
                  <li className={classes.tileOption}>{point}</li>
                ))}
              </ul>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
