import { Box } from '@mui/material';
import { SVGProps } from 'react';

const PlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={29} fill="none" {...props}>
    <path
      fill="#fff"
      d="M21.944 10.78 7.181 1.25a4.745 4.745 0 0 0-4.746-.22A4.556 4.556 0 0 0 .653 2.678 4.36 4.36 0 0 0 0 4.978V24.03c0 .81.227 1.603.656 2.297a4.557 4.557 0 0 0 1.783 1.644 4.744 4.744 0 0 0 4.742-.223l14.763-9.527a4.525 4.525 0 0 0 1.51-1.61A4.355 4.355 0 0 0 24 14.502c0-.735-.188-1.46-.546-2.108a4.525 4.525 0 0 0-1.51-1.61v-.005Z"
    />
  </svg>
);

interface PlayButtonProps {
  onClick: () => void;
}

export const PlayButton: React.FC<PlayButtonProps> = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '85px',
        height: '85px',
        bgcolor: '#FFFFFF52',
        borderRadius: '50%',
        pl: '4px',
      }}
    >
      <PlayIcon />
    </Box>
  );
};
