import { QSNavBarStrings } from '../../localization/en';

export const subtitlesList: {
  line1: string;
  line2: string;
}[] = [
  {
    line1: QSNavBarStrings.GreatStart,
    line2: QSNavBarStrings.Topics,
  },
  {
    line1: QSNavBarStrings.GreatJob,
    line2: QSNavBarStrings.Topics,
  },
  {
    line1: QSNavBarStrings.Amazing,
    line2: QSNavBarStrings.Topics,
  },
  {
    line1: QSNavBarStrings.Amazing,
    line2: QSNavBarStrings.Topics,
  },
];
