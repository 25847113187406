import { ShareIcon } from '../../../assets/searchFilterIcons/ShareIcon';
import { StatsIcon } from '../../../assets/searchFilterIcons/StatsIcon';
import { VideoIcon } from '../../../assets/searchFilterIcons/VideoIcon';
import { UsersIcon } from '../../../assets/searchFilterIcons/UserIcon';
import { SearchFilterStrings } from '../../../localization/en';
import { SearchTargets /* , SearchTargetsType */ } from '../../../../api/models/searchFilter';
import { statItemsIdType } from './FilterItems/VideoStatsFilter';
import { ChartIcon } from '../../../assets/searchFilterIcons/ChartIcon';

export type FilterOptionsType = {
  id: string;
  icon: JSX.Element;
  title: string;
};

export enum FilterOptionsId {
  VideoStatus = 'VideoStatus',
  ShareType = 'ShareType',
  VideoStats = 'VideoStats',
  UserStats = 'UserStats',
  UserStatus = 'UserStatus',
  CampaignStats = 'CampaignStats',
}
export const VideStatsPropertyNames: statItemsIdType[] = [
  'ctaClicks',
  'views',
  'shares',
  'conversions',
];
export const UserStatsPropertyNames: /* statItemsIdType */ string[] = [
  'ctaClicks',
  'rewardsFulfilled',
  'shares',
  'points',
];

export const FilterOptions: { [key in SearchTargets]: FilterOptionsType[] } = {
  [SearchTargets.VIDEOS]: [
    {
      id: FilterOptionsId.VideoStatus,
      title: SearchFilterStrings.FilterByVideoStatus,
      icon: <VideoIcon />,
    },
    {
      id: FilterOptionsId.ShareType,
      title: SearchFilterStrings.FilterByShareType,
      icon: <ShareIcon />,
    },
    {
      id: FilterOptionsId.VideoStats,
      title: SearchFilterStrings.FilterByStats,
      icon: <StatsIcon />,
    },
  ],
  [SearchTargets.USERS]: [
    {
      id: FilterOptionsId.UserStatus,
      title: SearchFilterStrings.FilterByUserStatus,
      icon: <UsersIcon />,
    },
    {
      id: FilterOptionsId.UserStats,
      title: SearchFilterStrings.FilterByStats,
      icon: <ChartIcon />,
    },
  ],
  [SearchTargets.VENUES]: [
    {
      id: FilterOptionsId.VideoStatus,
      title: SearchFilterStrings.FilterByVideoStatus,
      icon: <VideoIcon />,
    },
    {
      id: FilterOptionsId.ShareType,
      title: SearchFilterStrings.FilterByShareType,
      icon: <ShareIcon />,
    },
    {
      id: FilterOptionsId.VideoStats,
      title: SearchFilterStrings.FilterByStats,
      icon: <StatsIcon />,
    },
  ],
  [SearchTargets.INCENTIVE_CAMPAIGNS]: [
    {
      id: FilterOptionsId.CampaignStats,
      title: SearchFilterStrings.SelectCampaignStats,
      icon: <ChartIcon />,
    },
    {
      id: FilterOptionsId.UserStatus,
      title: SearchFilterStrings.FilterByUserStatus,
      icon: <UsersIcon />,
    },
  ],
};
