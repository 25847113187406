import { useTypedSelector } from '../../store';

export const useAttributesFilterUrl = () => {
  const { venueAttributes } = useTypedSelector((state) => state.venue);
  return venueAttributes.properties?.['webapp.config']?.['filter-url'];
};

export const useLogoFilterEnabled = () => {
  const { venueAttributes } = useTypedSelector((state) => state.venue);
  return venueAttributes.properties?.['webapp.config']?.['filter-logo-enabled'];
};
