import { VenueProperties } from '../../api/models/venue';
import { useVenueProperty } from '../useVenueProperty';

export const useBackgroundImage = () => {
  const {
    value: cards,
    update: updateCards,
    isLoading,
  } = useVenueProperty<VenueProperties['webapp.cards-welcome']>({
    property: 'webapp.cards-welcome',
  });

  const backgroundImage = cards?.mobile.original;

  const updateBackgroundImage = async (
    imageSrc: string,
    cropInfo: VenueProperties['webapp.cards-welcome']['mobile']['cropInfo'] = null,
  ) => {
    return updateCards({
      ...cards,
      mobile: {
        original: imageSrc,
        cropped: imageSrc,
        cropInfo,
      },
      desktop: {
        original: imageSrc,
        cropped: imageSrc,
        cropInfo,
      },
      tablet: {
        original: imageSrc,
        cropped: imageSrc,
        cropInfo,
      },
    });
  };

  return {
    backgroundImage,
    updateBackgroundImage,
    isLoading,
  };
};
