import { FC, SetStateAction } from 'react';
import { Button, Grid, TextField, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { SectionTitle } from '../Steps.style';
import { ValueField } from './components.style';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  resetIsSubdomainChecked,
  checkAvailability,
} from '../../../../store/slices/venueCreationSlice';

const useStyles = makeStyles((theme: Theme) => ({
  domainSuccessTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.success.main,
    '& svg': {
      marginRight: 10,
    },
  },
  subdomainSuccess: {
    fontSize: '12px',
  },
}));

interface SubdomainBlockProps {
  subdomain: string;
  setSubdomain: (value: SetStateAction<string>) => void;
}

export const SubdomainBlock: FC<SubdomainBlockProps> = ({ subdomain, setSubdomain }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const subdomainData = useTypedSelector((state) => state.venueCreation.subdomain);

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    dispatch(resetIsSubdomainChecked());
    setSubdomain((e.target.value as string).toLowerCase().replace(/[^a-z0-9]/g, ''));
  };

  const handleClick = () => {
    dispatch(checkAvailability({ subdomain: subdomain.toLowerCase() }));
  };

  return (
    <Box mt={5}>
      <Grid container alignItems="center" justifyContent="center" spacing={2}>
        <Grid container alignItems="center" justifyContent="center" ml={2} mb={4}>
          <Grid item xs={8}>
            <SectionTitle>{VenueWizardPreviewStepStrings.SubdomainTitle}</SectionTitle>
          </Grid>
          <Grid item xs={4}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleClick}
              disabled={subdomainData.checked || subdomainData.isLoading}
            >
              {VenueWizardPreviewStepStrings.Verify}
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center" ml={2}>
          <Grid item xs={4}>
            <TextField
              id="name"
              label={VenueWizardPreviewStepStrings.Subdomain}
              onChange={handleChange}
              variant="outlined"
              value={subdomain}
              fullWidth
              helperText={
                subdomainData.checked &&
                !subdomainData.available &&
                VenueWizardPreviewStepStrings.DomainUnavailble
              }
              error={subdomainData.checked ? !subdomainData.available : false}
            />
          </Grid>
          <Grid item xs={4}>
            <ValueField>{VenueWizardPreviewStepStrings.domain}</ValueField>
          </Grid>
          <Grid item xs={4}>
            {subdomainData.checked && subdomainData.available && (
              <Box className={classes.domainSuccessTextWrapper}>
                <CheckCircleOutlineIcon />
                <p className={classes.subdomainSuccess}>Domain successfully verified</p>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
