import { Box, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RewardsPageStrings } from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useStyles } from './VisitorsTable.style';
import {
  TabItem,
  TabsSelect,
} from '../../RewardCampaigns/CampaignDetails/MainContent/InviteTables/TabsSelect/TabsSelect';
import { VisitedTable } from './Tables/VisitedTable/VisitedTable';
import { CreatorsTable } from './Tables/CreatorsTable/CreatorsTable';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import { InfoCircleIcon } from '../../../common/assets/newDesign/InfoCircleIcon';
import { useParams } from 'react-router-dom';
import { getVisitors, resetVisitors } from '../../../store/slices/campaignsSlice';

interface Prop {}

export const CampaignVisitorsTable: FC<Prop> = () => {
  const classes = useStyles();
  const { campaignStats } = useTypedSelector((state) => state.campaigns);
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();

  const tabsList: TabItem[] = [
    {
      tabName: RewardsPageStrings.Visited,
      usersCount: campaignStats?.visited ? campaignStats?.visited?.toString() : '---',
      tabContent: <VisitedTable />,
      disabled: !campaignStats?.visited,
    },
    {
      tabName: RewardsPageStrings.Creators,
      usersCount: campaignStats?.creators ? campaignStats?.creators?.toString() : '---',
      tabContent: <CreatorsTable />,
      disabled: !campaignStats?.creators,
    },
  ];

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    dispatch(getVisitors({ id })).then((data) => {
      const payload: any = data.payload;
      if (payload?.visited === 0 && payload?.creators !== 0) {
        setActiveTabIndex(1);
      }
    });

    return () => {
      dispatch(resetVisitors());
    };
  }, [dispatch, id]);

  return (
    <Box className={classes.container}>
      <Box className={classes.tabsWrapper}>
        <TabsSelect
          tabsList={tabsList}
          activeTabIndex={activeTabIndex}
          onTabChange={(index) => {
            setActiveTabIndex(index);
          }}
        />
        <Box className={classes.total}>
          <Box className={classes.totalLabel}>Total</Box>
          <Box className={classes.totalNumber}>
            {campaignStats?.total ? campaignStats?.total : '---'}
          </Box>
        </Box>
        <Typography className={classes.aboutTooltip}>
          {RewardsPageStrings.AboutCountsTooltip}
          <Box className={classes.tooltip}>
            <Tooltip
              width="375px important"
              rounded="12px"
              padding="12px"
              maxWidth="375px"
              icon={
                <Box>
                  <InfoCircleIcon />
                </Box>
              }
              content={
                <Box width="375px">
                  <Typography fontSize="12px" fontWeight="500" color="#667085" textAlign="start">
                    <Typography
                      display="inline"
                      fontSize="12px"
                      fontWeight="600"
                      color="#344054"
                      textAlign="start"
                    >
                      {RewardsPageStrings.VisitedTooltip}
                    </Typography>{' '}
                    {RewardsPageStrings.VisitedTooltipDescription}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="500" color="#667085">
                    <Typography display="inline" fontSize="12px" fontWeight="500" color="#344054">
                      {RewardsPageStrings.CreatorsTooltip}
                    </Typography>{' '}
                    {RewardsPageStrings.CreatorsTooltipDescription}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Typography>
      </Box>
      <Box className={classes.tableWrapper}>{tabsList[activeTabIndex].tabContent}</Box>
    </Box>
  );
};
