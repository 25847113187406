import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    zIndex: 100,
    background: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
    maxHeight: '150px',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'absolute',
    width: '100%',

    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      height: '20px !important',
      maxHeight: '20px !important',
      borderRadius: '12px',
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#EAECF0',
      borderRadius: '5px',
      height: '40px !important',
      cursor: 'pointer',
    },
  },
  listItem: {
    fontSize: '11px',
    fontWeight: 500,
    color: '#464c71',
    width: '100%',
    height: '32px',
    paddingLeft: '18px',
    paddingRight: '18px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
}));
