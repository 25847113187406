import { FC, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { UserApiModel } from '../../../../api/models/users';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { useClasses } from './RewardsBlock.helper';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { RewardsPageStrings } from '../../../localization/en';
import { RoundedButton } from '../../Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { Reward } from '../../../assets/Reward';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../constants/routes';
import {
  approveReward,
  fetchUserDetails,
  getCampaignUser,
} from '../../../../store/slices/userDetailsSlice';
import { GoalBlock } from './GoalBlock/GoalBlock';
import { RewardedIcon } from '../../../assets/RewardedIcon';
import ArrowRightSmall from '../../../assets/ArrowRightSmall';
import CampaignSelect from './CampaignSelect/CampaignSelect';
import { useClientType } from '../../../../services/hooks/useClientType';

interface RewardsPointsBlockProps {
  user: UserApiModel | null;
  activeCampaign: CampaignsApiModel | null;
  leaderBoardButtonForMobile: ReactJSXElement;
  handleApproveRewardClick: () => void;
  onClickOutsideDisable?: (value: boolean) => void;
  isCampaignUser?: boolean;
  entity?: 'campaigns' | 'heroes';
}
export const RewardsPointsBlock: FC<RewardsPointsBlockProps> = ({
  user,
  activeCampaign,
  leaderBoardButtonForMobile,
  handleApproveRewardClick,
  onClickOutsideDisable,
  isCampaignUser,
  entity,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const classes = useClasses();
  const {
    currUserActiveCampaign,
    isActiveCampaignLoading,
    currentCampaign,
    items: campaigns,
  } = useTypedSelector((state) => state.campaigns);
  const { campaignUser } = useTypedSelector((state) => state.userDetails);

  const { isTrubluClient } = useClientType();

  useEffect(() => {
    if (!isCampaignUser && user && currentCampaign) {
      dispatch(getCampaignUser({ userId: user?.id, campaignId: currentCampaign?.id }));
    }
  }, [isCampaignUser, currUserActiveCampaign, user, currentCampaign, dispatch]);

  const campaign = isCampaignUser ? currentCampaign : currUserActiveCampaign;
  const isActiveCampaignsAvailable = campaigns.filter((campaign) => !campaign.endedAt).length;

  const canFulfillReward = () => {
    return (
      !campaignUser?.fulfilled &&
      campaignUser &&
      campaign &&
      campaignUser?.points >= campaign?.points
    );
  };

  /*  const handleConfirmAward = async () => {
    if (user?.id) {
      trackEvent(
        entity === 'campaigns'
          ? RewardCampaignsEvents.RewardCampaignsDetailsModalAddBonusPointsConfirmClick
          : CreatorsTableEventNames.CreatorDetailsModalAddBonusPointsConfirmClick,
        {
          selectedUserId: user?.id,
          campaignId: campaign?.id || '',
        },
      );
      await dispatch(
        addBonusPoints({ id: user.id, campaignId: campaign?.id, points: DEFAULT_POINTS_GOAL }),
      );
      await dispatch(getCampaignUser({ userId: user?.id, campaignId: campaign?.id || '' }));
      await dispatch(fetchUserDetails(user.id));
    }
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalBonusPointsAdded
        : CreatorsTableEventNames.CreatorDetailsModalBonusPointsAdded,
      {
        selectedUserId: user?.id,
        campaignId: campaign?.id || '',
      },
    );
    toggleAwardPointsModal();
  };*/

  const getCampaignName = () => {
    if (isCampaignUser) {
      return currentCampaign?.name;
    } else {
      return !isActiveCampaignLoading
        ? currUserActiveCampaign?.name || RewardsPageStrings.NoActiveCampaign
        : currUserActiveCampaign?.name;
    }
  };
  /*  const trackClose = () => {
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalConfirmAddBonusPointsModalClose
        : CreatorsTableEventNames.CreatorDetailsModalConfirmAddBonusPointsModalClose,
      {
        selectedUserId: user?.id,
        campaignId: campaign?.id || '',
      },
    );
  };*/

  const handleApproveReward = async () => {
    const userId = user?.id;
    const campaignId = campaign?.id;
    if (userId && campaignId) {
      await dispatch(approveReward({ id: userId }));
      await dispatch(getCampaignUser({ userId, campaignId }));
      await dispatch(fetchUserDetails(userId));
    }
  };

  const getButnRewardText = () => {
    const isNullReward = campaign?.isNullReward;

    const rewardText = isNullReward
      ? RewardsPageStrings.AknowledgeNow
      : RewardsPageStrings.RewardNow;

    const rewardedText = isNullReward
      ? RewardsPageStrings.Acknowledged
      : RewardsPageStrings.Rewarded;

    return campaignUser?.fulfilled ? rewardedText : rewardText;
  };

  return (
    <>
      {activeCampaign !== null || currentCampaign != null ? (
        <Grid
          display="flex"
          justifyContent="space-between"
          marginTop="10px"
          className={classes.pointsBarContainer}
        >
          <Box className={classes.pointsTitleWrapper}>
            <Typography
              textAlign="center"
              variant="body1"
              color="#fff"
              style={{ width: '100%', fontSize: '14px', fontWeight: '500' }}
            >
              {getCampaignName()}
            </Typography>
          </Box>
          <Box className={classes.pointsBlockWrapper}>
            {campaignUser && campaign && (
              <GoalBlock campaignUser={campaignUser} campaign={campaign} />
            )}
          </Box>
          {!history.location.pathname.includes(WebsiteRoutes.Heroes) &&
            campaignUser?.phoneNumberVerified && (
              <Box className={classes.buttonsWrapper}>
                <RoundedButton
                  title={getButnRewardText()}
                  color={campaignUser?.fulfilled ? 'secondary' : 'primary'}
                  startIcon={campaignUser?.fulfilled ? <RewardedIcon /> : <Reward />}
                  className={classes.rewardButton}
                  onClick={handleApproveReward}
                  disabled={!canFulfillReward()}
                />
              </Box>
            )}
        </Grid>
      ) : isActiveCampaignsAvailable ? (
        <CampaignSelect user={user} />
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.inactiveCampaignWrapper}>
              <div>
                <Typography variant="body1" className={classes.inactiveTitle}>
                  {RewardsPageStrings.NoActiveCampaignFound}
                </Typography>
                <Typography variant="body1" className={classes.inactiveText}>
                  {isTrubluClient
                    ? RewardsPageStrings.CampaignInfoTrublu
                    : RewardsPageStrings.CampaignInfo}
                </Typography>
              </div>
              <RoundedButton
                className={classes.inactiveButton}
                title={RewardsPageStrings.StartCampaign}
                onClick={() => {
                  history.push(WebsiteRoutes.NewCampaign);
                }}
                endIcon={<ArrowRightSmall />}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};
