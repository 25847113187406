import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {},
  notAvailableText: {
    color: '#101828',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
  },
  detailsWrapper: {
    marginTop: '4px',
    display: 'flex',
    alignItems: 'center',
  },
  phoneNumberText: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#475467',
  },
}));
