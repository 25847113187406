export const PreviewPositionBottomRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2.65"
        y="3.15"
        width="18.7"
        height="18.7"
        rx="4.35"
        stroke="inherit"
        strokeWidth="1.3"
      />
      <circle cx="16.5" cy="17" r="1.85" stroke="inherit" strokeWidth="1.3" />
    </svg>
  );
};
