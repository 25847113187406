export enum QuickStartEventNames {
  quick_start_set_up = 'quick_start_set_up_click',
  advanced_set_up = 'advanced_set_up_click',

  quick_start_next_button_click = 'quick_start_next_button_click',
  quick_start_back_button_click = 'quick_start_back_button_click',

  accordion_click = 'accordion_click',

  color_palette_click = 'color_palette_click',
  video_app_background_image_upload = 'video_app_background_image_change',
  company_logo_upload = 'company_logo_upload',

  new_reward_campaign_name_input_typed = 'new_reward_campaign_name_input_typed',

  change_most_important_customer_activities = 'change_most_important_customer_activities_to',
  change_discount_type = 'change_discount_type_to',
  change_custom_discount_type = 'change_custom_discount_type_to',
  sms_method_change = 'sms_method_changed_to',
  discount_input_typed = 'discount_input_typed',

  quick_start_open_sample_sms_modal_button_click = 'quick_start_open_sample_sms_modal_button_click',

  sample_sms_modal_send_message_button_click = 'sample_sms_modal_send_message_button_click',
  sample_sms_modal_cancel_button_click = 'sample_sms_modal_cancel_button_click',
  sample_sms_modal_close = 'sample_sms_modal_close',
  sample_sms_modal_phone_input_typed = 'sample_sms_modal_phone_input_typed',

  quick_start_upload_csv_button_click = 'quick_start_upload_csv_button_click',
  quick_start_add_clients_manually_button_click = 'quick_start_add_clients_manually_button_click',

  quick_start_import_excel_template_link_click = 'quick_start_import_excel_template_link_click',

  QuickStartGetStarted = 'quick_start_get_started_click',
  QuickStartDoThisLater = 'quick_start_do_this_later_click',
  QuickStartSavePersonalization = 'quick_start_save_personalization_click',
  QuickStartGoToDashboard = 'quick_start_go_to_dashboard_click',
  QuickStartConfigureVideoApp = 'quick_start_configure_video_app_click',
  QuickStartReviewDefaultCampaignFlow = 'quick_start_review_default_campaign_flow_click',
  QuickStartNavBarNextClick = 'quick_start_nav_bar_next_click',
  InviteCustomers = 'invite_customers',
  UploadFirstVideo = 'upload_first_video',
  ConnectToSocialverse = 'connect_to_social_verse',
  GoogleSync = 'google_sync',
  LaunchPadModalLetsGo = 'launch_pad_modal_lets_go',
  LaunchPadModalSkip = 'launch_pad_modal_skip',
}
