import { SVGProps } from 'react';

export const BookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={76} height={76} fill="none" {...props}>
    <path
      stroke="#FFBE32"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.5}
      d="M69.667 53.01V14.788c0-3.8-3.103-6.618-6.872-6.302h-.19c-6.65.57-16.752 3.959-22.388 7.505l-.538.349c-.919.57-2.439.57-3.357 0l-.792-.475c-5.637-3.515-15.707-6.872-22.357-7.41-3.768-.317-6.84 2.533-6.84 6.301V53.01c0 3.04 2.47 5.89 5.51 6.27l.919.126c6.872.919 17.48 4.402 23.56 7.727l.127.063c.855.475 2.216.475 3.04 0 6.08-3.356 16.72-6.871 23.623-7.79l1.045-.126c3.04-.38 5.51-3.23 5.51-6.27ZM38 17.385v47.5M24.541 26.885h-7.125M26.916 36.385h-9.5"
    />
  </svg>
);
