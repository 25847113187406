import { FC } from 'react';

interface Props {
  isSelected: boolean;
}
export const DistributorsMenuIcon: FC<Props> = ({ isSelected }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_491_18932)">
        <path
          d="M8.4 18.2C8.78 18.7 9 19.32 9 20C9 21.66 7.66 23 6 23C4.34 23 3 21.66 3 20C3 18.34 4.34 17 6 17C6.44 17 6.85 17.09 7.23 17.26L8.64 15.49C7.72 14.46 7.35 13.1 7.55 11.8L5.52 11.12C4.98 11.95 4.06 12.5 3 12.5C1.34 12.5 0 11.16 0 9.5C0 7.84 1.34 6.5 3 6.5C4.66 6.5 6 7.84 6 9.5C6 9.57 6 9.64 5.99 9.71L8.02 10.39C8.66 9.18 9.84 8.3 11.24 8.07V5.91C9.96 5.57 9 4.4 9 3C9 1.34 10.34 0 12 0C13.66 0 15 1.34 15 3C15 4.4 14.04 5.57 12.75 5.91V8.07C14.15 8.3 15.33 9.18 15.97 10.39L18 9.71C18 9.64 18 9.57 18 9.5C18 7.84 19.34 6.5 21 6.5C22.66 6.5 24 7.84 24 9.5C24 11.16 22.66 12.5 21 12.5C19.94 12.5 19.02 11.95 18.48 11.13L16.45 11.81C16.65 13.1 16.29 14.46 15.36 15.5L16.77 17.27C17.15 17.09 17.56 17 18 17C19.66 17 21 18.34 21 20C21 21.66 19.66 23 18 23C16.34 23 15 21.66 15 20C15 19.32 15.22 18.7 15.6 18.2L14.19 16.43C12.84 17.18 11.18 17.19 9.82 16.43L8.4 18.2Z"
          fill={isSelected ? '#fff' : '#667085'}
        />
      </g>
      <defs>
        <clipPath id="clip0_491_18932">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
