import { FC } from 'react';
import { Grid, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FSModal } from '../../../common/components/modal/Modal';
import { CommonStrings } from '../../../common/localization/en';

interface ConfirmationModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  onConfirm: () => void;
  message: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmDeleteText: {
    color: theme.palette.common.darkBlue,
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'center',
  },
}));

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  handleCloseModal,
  isModalOpen,
  onConfirm,
  message,
}) => {
  const classes = useStyles();
  return (
    <FSModal onClose={handleCloseModal} modalIsOpen={isModalOpen}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={10}>
          <p className={classes.confirmDeleteText}>{message}</p>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Button color="primary" onClick={handleCloseModal}>
                {CommonStrings.No}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={onConfirm}>
                {CommonStrings.Yes}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
