import { Box, Button, IconButton, Typography, useTheme } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { TextFieldComponent } from '../../../../common/components/TextField/TextField';
import { KeywordsStrings } from '../../../../common/localization/en';
import { useVenue } from '../../../../hooks/useVenue';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { updateKeywords } from '../../../../store/slices/keywordsSlice';
import { KeywordsPreview } from '../../../Keywords/KeywordsPreview';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const KEYWORD_MAX_LENGTH = 60;

interface ManageClipKeywordsPopupProps {
  show: boolean;
  onClose: () => void;
}

export const ManageClipKeywordsPopup: React.FC<ManageClipKeywordsPopupProps> = ({
  show,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { keywords: storeKeywords } = useTypedSelector((state) => state.KeywordsSlice);
  const keywords = storeKeywords.map((item) => item.keyword);

  const theme = useTheme();

  const { venueId } = useVenue();

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      onClose();
    },
  });

  const [keyword, setKeyword] = useState('');

  const isKeywordValid = (keyword: string) => {
    if (!keyword) return false;
    if (keyword.length > KEYWORD_MAX_LENGTH) return false;

    if (keywords.includes(keyword.trim())) return false;

    return true;
  };

  const saveKeywords = async (keywords: string[]) => {
    return dispatch(
      updateKeywords({
        venueId,
        keywords: keywords.map((keyword) => ({
          keyword,
          icon: '', // icons have been deprecated, but required by the backend
        })),
      }),
    );
  };

  const keywordsListRef = useRef<HTMLDivElement>(null);

  const scrollKeywordsListToBottom = () => {
    if (!keywordsListRef.current) return;

    keywordsListRef.current.scrollTop = keywordsListRef.current.scrollHeight;
  };

  if (!show) return null;

  const handleAddKeyword = () => {
    if (!isKeywordValid(keyword)) {
      return;
    }

    saveKeywords([...keywords, keyword.trim()]).then(scrollKeywordsListToBottom);
    setKeyword('');
  };

  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="48px"
        right="0"
        width="400px"
        ref={refDetectClickOutside}
        sx={{
          bgcolor: 'white',
          p: '24px',
          borderRadius: '12px',
          zIndex: 10,
          boxShadow:
            '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
        }}
      >
        <Typography fontWeight="600" color="#101828" fontSize="18px" mb="9px" textAlign="center">
          Manage Clip Keywords
        </Typography>

        <IconButton
          sx={{
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
          onClick={onClose}
        >
          <CloseRoundedIcon />
        </IconButton>

        <Typography fontWeight="500" color="#101828" fontSize="14px" mb="9px">
          Add new keyword
        </Typography>

        <TextFieldComponent
          name="keyword"
          placeholder={KeywordsStrings.AddNewKeyword}
          value={keyword}
          onChange={(event) => {
            setKeyword(event.target.value);
          }}
          fullWidth
          autocapitalize
          onEnterPress={handleAddKeyword}
          autoFocus
          buttonProps={{
            customComponent: (
              <Button
                disabled={!isKeywordValid(keyword)}
                onClick={handleAddKeyword}
                sx={{
                  minWidth: '42px',
                  height: '42px',
                  fontSize: '24px',
                  fontWeight: '400',
                  background: theme.palette.primary.main,
                  borderRadius: '10px',
                  color: 'white',
                  '& .MuiButton-root:hover': {
                    backgroundColor: `${theme.palette.primary.main}cc !important`,
                  },
                  '&:disabled': {
                    background: `${theme.palette.primary.main}33`,
                    color: `${theme.palette.primary.main}4d`,
                  },
                  '&:hover': {
                    backgroundColor: '#3423d1 !important',
                  },
                }}
              >
                +
              </Button>
            ),
          }}
        />

        <Typography fontWeight="500" color="#101828" fontSize="14px" my="9px">
          Your Keywords
        </Typography>
        <KeywordsPreview
          ref={keywordsListRef}
          minHeight="148px"
          maxHeight="320px"
          onDelete={({ keyword }) => {
            saveKeywords(keywords.filter((k) => k !== keyword));
          }}
          keywords={keywords.map((keyword) => ({ keyword }))}
        />
      </Box>
    </Box>
  );
};
