import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import { Spinner } from '../../../common/assets/Spinner';

import TransitionWrapper from '../../../common/components/TransitionWrapper/TransitionWrapper';
import { ModalHeader } from '../../../common/components/ModalHeader/ModalHeader';

interface ModalChartProps {
  children: React.ReactNode;
  isChart: boolean;
  isLoading?: boolean;
  onClose: () => void;
  title: string;
}
export const ModalChart: React.FC<ModalChartProps> = ({
  children,
  isLoading,
  isChart,
  onClose,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isChart);
  }, [isChart]);

  const renderModalContent = () => {
    if (isLoading) {
      return (
        <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
          <Spinner color="var(--lightBlue)" />
        </Box>
      );
    }

    return (
      <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)" overflow="scroll">
        <ModalHeader onClose={onClose} text={title} />
        {children}
      </Box>
    );
  };

  return <TransitionWrapper show={isOpen}>{renderModalContent()}</TransitionWrapper>;
};
