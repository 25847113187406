import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { FC } from 'react';
import { RoundedButton } from '../Button/RoundedButton';

import { ImageUploaderBase, ImageUploaderBaseProps } from './components/ImageUploaderBase';
import { CommonStrings, PersonalizationStrings } from '../../localization/en';
import { Alignment } from './ImageUploaderWithGallery';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../constants/events/common';
import { setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { useAppDispatch } from '../../../store';
import { useStyles } from './ImageUploader.styles';
import { useHistory } from 'react-router-dom';
import { PersonalizationSubroutes } from '../../constants/routes';

export const ImageUploaderInfo: React.FC<{
  title?: string;
  subtitle?: string | string[];
  warnBackgroundNotTransparent?: boolean;
  editInvitationModal?: boolean;
}> = ({ title, subtitle, warnBackgroundNotTransparent, editInvitationModal }) => {
  const styles = useStyles({ editInvitationModal });

  return (
    <Box>
      {title && <Typography className={styles.title}>{title}</Typography>}
      {subtitle && Array.isArray(subtitle) ? (
        <List className={styles.subtitleWrapper} dense>
          {subtitle.map((text) => (
            <ListItem disablePadding className={styles.listItem}>
              <ListItemIcon className={styles.bulletIcon}>
                <div className={styles.subTitleIcon} />
              </ListItemIcon>
              <ListItemText primary={text} className={styles.subTitle} disableTypography />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography className={styles.subTitle}>{subtitle}</Typography>
      )}
      {warnBackgroundNotTransparent && (
        <Typography className={styles.warning}>
          {CommonStrings.PreferTransperentBackground}
        </Typography>
      )}
    </Box>
  );
};

export interface ImageUploaderProps extends ImageUploaderBaseProps {
  title?: string;
  subtitle?: string | string[];
  buttonTitle?: string;
  rightSideContainerProps?: BoxProps;
  warnBackgroundNotTransparent?: boolean;
  alignment?: Alignment;
  imagePadding?: number;
  displayInfo?: boolean;
  displayButtons?: boolean;
  displayRemoveButton?: boolean;
  dndIcon?: JSX.Element;
  isQuickstart?: boolean;
  customInfo?: JSX.Element;
  showEditIcon?: boolean;
  editInvitationModal?: boolean;
}

export const ImageUploader: FC<ImageUploaderProps> = ({
  file,
  onUpload,
  accept,
  title,
  subtitle,
  buttonTitle = 'Upload Image',
  loading,
  children,
  rightSideContainerProps,
  warnBackgroundNotTransparent,
  alignment,
  imagePadding,
  displayButtons = true,
  displayRemoveButton = false,
  displayInfo = true,
  isQuickstart,
  customInfo,
  showEditIcon,
  editInvitationModal,
  ...restProps
}) => {
  const styles = useStyles({ editInvitationModal });
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleRemoveImage = () => {
    dispatch(setInviteMediaUrl(''));
  };

  const handleSkipLogoUpload = () => {
    history.replace(PersonalizationSubroutes.UploadBackgroundImage);
  };

  return (
    <ImageUploaderBase
      file={file}
      onUpload={onUpload}
      accept={accept}
      loading={loading}
      alignment={alignment}
      imagePadding={imagePadding}
      showEditIcon={showEditIcon}
      renderRightSide={({ openFileSelector }) => (
        <Box className={styles.container} {...rightSideContainerProps}>
          {displayInfo && (
            <ImageUploaderInfo
              title={title}
              subtitle={subtitle}
              warnBackgroundNotTransparent={warnBackgroundNotTransparent}
              editInvitationModal={editInvitationModal}
            />
          )}
          {customInfo}
          {displayButtons && (
            <Box className={styles.buttonsWrapper}>
              <RoundedButton
                title={buttonTitle}
                onClick={() => {
                  openFileSelector();
                  trackEvent(CommonEventNames.company_logo_upload_button_click);
                }}
                className={styles.browseFilesButton}
                sx={{
                  mt: warnBackgroundNotTransparent ? '5px' : '15px',
                  width: '100%',
                }}
                loading={loading}
              />
              {displayRemoveButton && file && (
                <Button
                  variant="text"
                  color="error"
                  onClick={handleRemoveImage}
                  className={styles.removeButton}
                  fullWidth={editInvitationModal}
                >
                  Remove
                </Button>
              )}
              {isQuickstart && (
                <Box className={styles.skipWrapper}>
                  <Button
                    variant="text"
                    className={styles.skipButton}
                    onClick={handleSkipLogoUpload}
                  >
                    {PersonalizationStrings.DoThisLater}
                  </Button>
                  <Typography className={styles.skipInfo}>
                    {PersonalizationStrings.DoThisLaterInfo}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {...restProps}
    />
  );
};
