export const ConfigurationsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="inherit"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 5.5H21" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="19" cy="5.5" r="2" fill="#1A1538" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 12H21" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="12" cy="12" r="2" fill="#1A1538" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 18.5H21" strokeWidth="1.5" strokeLinecap="round" />
      <circle cx="5" cy="18.5" r="2" fill="#1A1538" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
