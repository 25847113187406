import React, { FC, useState } from 'react';

import {
  TGStep1Caption,
  TimeSlotsCaptions,
  timeSlotsValues,
  timeSlotsValuesWithCaptions,
} from '../../../pages/webappSettings/WebAppCards/WebAppCards.helper';
import { ListItemIcon, ListItemText, Paper, Box, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useCardBlockSlotsTimeStyles } from './CardBlockSlotsTime.style';

interface CardBlockSlotsTimeProps {
  sectionTitle: string;
  initialSlotsTime: string;
  setFormDirty: (state: boolean) => void;
  setactiveSlotsTime: (state: string) => void;
}

export const CardBlockSlotsTime: FC<CardBlockSlotsTimeProps> = ({
  sectionTitle,
  initialSlotsTime,
  setFormDirty,
  setactiveSlotsTime,
}) => {
  const classes = useCardBlockSlotsTimeStyles();

  const values: { [key: string]: string } = {
    [TimeSlotsCaptions.Every10]: timeSlotsValues[0],
    [TimeSlotsCaptions.Every15]: timeSlotsValues[1],
    [TimeSlotsCaptions.Every20]: timeSlotsValues[2],
    [TimeSlotsCaptions.Every30]: timeSlotsValues[3],
  };

  const [slot, setslot] = useState<string>(
    timeSlotsValuesWithCaptions[initialSlotsTime] || TimeSlotsCaptions.Every10,
  );

  const [openList, setopenList] = useState(false);

  const handleOpen = () => {
    setopenList(!openList);
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>) => {
    const element = event.target;
    const text = (element as HTMLElement).innerText;
    const value = values[text];
    if (text !== undefined) {
      setFormDirty(true);
      setslot(text);
      setactiveSlotsTime(value);
    } else return;
  };

  return (
    <div>
      <Typography className={classes.SectionTitle} component="h2">
        {sectionTitle}
      </Typography>
      <Typography className={classes.TopgolfSlotsCaption}>{TGStep1Caption}</Typography>

      <Box display="flex">
        <Box>
          <Paper className={classes.Input} elevation={0} onClick={handleOpen}>
            <div className={classes.InputContainer}>
              <ListItemIcon className={classes.SelectOptionIcon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={slot} className={classes.SelectedOptionText} />
              <KeyboardArrowDownIcon className={!openList ? classes.iconUp : ''} />
            </div>
          </Paper>

          <Paper
            className={[classes.DropdownStyle, openList ? classes.HidenDropdownStyle : ''].join(
              ' ',
            )}
            onClick={handleChange}
          >
            <div className={classes.SelectOptionContainer}>
              <ListItemIcon className={classes.SelectOptionIcon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={TimeSlotsCaptions.Every10} className={classes.OptionText} />
            </div>
            <div className={classes.SelectOptionContainer}>
              <ListItemIcon className={classes.SelectOptionIcon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={TimeSlotsCaptions.Every15} className={classes.OptionText} />
            </div>
            <div className={classes.SelectOptionContainer}>
              <ListItemIcon className={classes.SelectOptionIcon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={TimeSlotsCaptions.Every20} className={classes.OptionText} />
            </div>
            <div className={classes.SelectOptionContainer}>
              <ListItemIcon className={classes.SelectOptionIcon}>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary={TimeSlotsCaptions.Every30} className={classes.OptionText} />
            </div>
          </Paper>
        </Box>
      </Box>
    </div>
  );
};
