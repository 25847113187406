import * as React from 'react';

export const ActivitiesFirstDonation: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="30" fill="none" viewBox="0 0 25 30">
      <path
        fill="#F78248"
        d="M21.962 2.281c-3.748-4.89-9.065.83-9.065.83S7.939-2.894 3.898 1.78c-4.04 4.673 8.603 14.233 8.603 14.233S25.71 7.17 21.961 2.281z"
      ></path>
      <path
        fill="#FFB699"
        d="M20.498 4.059a.938.938 0 01-1.052-.36c-.24-.358-.626-.768-1.188-.89-.378-.082-.615-.44-.53-.799.087-.36.463-.585.841-.503 1.219.263 1.916 1.182 2.256 1.796.163.294.005.656-.327.756zM11.524 14.137c-.7-.643-2.597-2.642-2.597-2.642-.307-.282-.75-.956-.454-1.247.297-.292 1.156.45 1.462.731 0 0 1.375 1.594 2.146 2.125.847.583 1.192 1.037.895 1.328-.151.149-.836.27-1.452-.295z"
      ></path>
      <path
        fill="#FFB27D"
        d="M.332 13.124c.116.485.343 1.424 1.205 2.556.621.817 1.804 2.025 2.96 3.603.835 1.139 1.32 1.81 1.586 2.183.189.265.32.56.385.87l-2.002 5.717 4.76.396s1.793-5.955 1.794-8.453v-.002c0-.29.003-.598.011-.928.034-1.528.501-2.733-4.147-2.775-.02 0-.037-.013-.058-.02-.181-.067-.458-.187-.866-.5a5.998 5.998 0 01-.217-.173 9.4 9.4 0 00-1.289-.919C2.341 13.437.227 12.682.332 13.124z"
      ></path>
      <path
        fill="#FFB27D"
        d="M.002 10.224c.07-.719.632-2.978.632-2.978s.55.12.753.455c.59.981.397 2.915.366 2.926-.001 0 .96 1.756 1.123 2.886 0 0 2.549 1.314 3.085 2.258.033.057.06.114.078.168.073.212.104.502.1.825-.018 1.143-.493 2.688-1.203 2.57C4.76 19.303.66 14.945.49 14.177c-.04-.18-.525-3.571-.488-3.954z"
      ></path>
      <path
        fill="#FFB27D"
        d="M6.624 15.115s.122 1.11-1.086.137l-.098 1.274 1.683.852.57-.935-1.069-1.328z"
      ></path>
      <path
        fill="#FFC69F"
        d="M.753 8.363A.416.416 0 00.8 8.226l.11-.759c.03-.211-.12-.383-.204-.415-.054-.02-.384.801-.396 1.574.193.003.36-.11.443-.263z"
      ></path>
      <path
        fill="#F6A56E"
        d="M2.876 13.514c.55.17 3.902 2.337 4.083 2.404.419.503.49 1.249.285 1.331-.277.113-.892-.113-1.303-.471-.55-.48-.71-1.113-.874-1.465-.075-.159-.562-.389-.747-.62-.485-.61-1.381-1.132-1.444-1.18z"
      ></path>
      <path
        fill="#FFB27D"
        d="M9.29 15.583s-4.364-3.34-4.846-4.187c0 0-.768.264-.462 1.372.307 1.108 2.452 3.097 2.58 3.882.482 2.948 2.244 3.855 2.244 3.855s4.782-1.623.483-4.922z"
      ></path>
      <path
        fill="#FFC69F"
        d="M4.724 12.63a.47.47 0 01-.104-.108l-.447-.653a.406.406 0 01.094-.558c.044-.034.07-.051.07-.051.212.173.76.498 1.124 1.199-.201.137-.591.28-.737.17z"
      ></path>
      <path
        fill="#4C65BA"
        d="M10.883 23.766s-.02.552-.056 1.425l-.003.07a793.038 793.038 0 01-.204 4.733c-2.765-.001-5.5-.004-8.102-.007a337.669 337.669 0 012.866-5.256 423.048 423.048 0 01.67-1.208 1.211 1.211 0 01.39-.341c.733-.409 1.948-.214 2.923.056a12.926 12.926 0 011.516.528z"
      ></path>
      <path
        fill="#88A0F0"
        d="M10.825 25.26s-1.925.494-3.038.51l-.41-.727-.36.615s-1.405-.406-1.692-.803l.98-1.676 4.563.087-.043 1.994z"
      ></path>
      <path
        fill="#F3F8FF"
        d="M10.746 22.339s.593 1.31.719 2.863c0 0-2.32.244-3.425.115l-.483-1.822-.704 1.583s-1.278-.157-1.722-.485c0 0 .827-1.347 1.348-2.24 0 0 2.15.617 4.267-.014z"
      ></path>
      <path
        fill="#4C65BA"
        d="M9.343 24.85c.057-.101.013-.225-.1-.277-.111-.052-.248-.012-.306.088-.057.1-.013.224.099.276.112.052.25.013.307-.088z"
      ></path>
      <path
        fill="#FFB27D"
        d="M24.667 13.124c-.116.485-.343 1.424-1.205 2.556-.622.817-1.804 2.025-2.961 3.603a219.615 219.615 0 00-1.585 2.183c-.19.265-.32.56-.385.87l2.002 5.717-4.76.396s-1.793-5.955-1.795-8.453v-.002c0-.29-.003-.598-.01-.928-.034-1.528-.501-2.733 4.147-2.775.02 0 .037-.013.058-.02.181-.067.458-.187.866-.5.068-.052.14-.11.216-.173a9.395 9.395 0 011.29-.919c2.112-1.242 4.227-1.997 4.122-1.555z"
      ></path>
      <path
        fill="#FFB27D"
        d="M24.998 10.225c-.07-.719-.632-2.978-.632-2.978s-.55.12-.753.455c-.59.981-.397 2.915-.366 2.926.001 0-.96 1.756-1.123 2.886 0 0-2.549 1.314-3.085 2.258a1.037 1.037 0 00-.078.168c-.073.212-.104.502-.1.825.019 1.142.494 2.688 1.204 2.57.175-.03 4.275-4.388 4.445-5.156.04-.18.525-3.571.488-3.954z"
      ></path>
      <path
        fill="#FFB27D"
        d="M18.375 15.115s-.12 1.11 1.087.137l.098 1.274-1.682.852-.571-.935 1.068-1.328z"
      ></path>
      <path
        fill="#FFC69F"
        d="M24.247 8.363a.415.415 0 01-.046-.137l-.11-.759c-.03-.211.119-.383.204-.415.053-.02.383.801.396 1.574a.503.503 0 01-.444-.263z"
      ></path>
      <path
        fill="#F6A56E"
        d="M22.124 13.514c-.55.17-3.902 2.337-4.083 2.404-.419.503-.49 1.249-.285 1.331.277.113.892-.113 1.303-.471.55-.48.71-1.113.874-1.465.075-.159.562-.389.747-.62.486-.61 1.381-1.132 1.444-1.18z"
      ></path>
      <path
        fill="#FFB27D"
        d="M15.71 15.583s4.364-3.34 4.846-4.187c0 0 .768.264.461 1.372-.306 1.108-2.451 3.097-2.58 3.882-.481 2.948-2.243 3.855-2.243 3.855s-4.782-1.623-.483-4.922z"
      ></path>
      <path
        fill="#FFC69F"
        d="M20.275 12.63a.47.47 0 00.105-.108l.446-.653a.406.406 0 00-.094-.558c-.044-.034-.07-.051-.07-.051-.212.173-.792.527-1.156 1.228.175.074.624.25.769.142z"
      ></path>
      <path
        fill="#4C65BA"
        d="M22.49 30l-8.11-.003a801.299 801.299 0 01-.207-4.806l-.056-1.424.025-.011a12.966 12.966 0 011.492-.518c.974-.27 2.19-.464 2.922-.056a1.228 1.228 0 01.393.347l.668 1.201A337.221 337.221 0 0122.489 30z"
      ></path>
      <path
        fill="#88A0F0"
        d="M14.175 25.26s1.925.495 3.038.511l.41-.727.36.615s1.405-.406 1.692-.803l-.98-1.676-4.563.087.043 1.994z"
      ></path>
      <path
        fill="#F3F8FF"
        d="M14.254 22.339s-.593 1.31-.719 2.863c0 0 2.32.244 3.425.115l.483-1.822.704 1.583s1.278-.157 1.722-.485c0 0-.827-1.347-1.348-2.24 0 0-2.15.617-4.267-.014z"
      ></path>
      <path
        fill="#4C65BA"
        d="M15.658 24.85c-.057-.101-.013-.225.099-.277.112-.052.249-.012.306.088.058.1.014.224-.098.276-.112.052-.25.013-.307-.088z"
      ></path>
    </svg>
  );
};
