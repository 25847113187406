import { FC } from 'react';
import { Theme } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { makeStyles } from '@mui/styles';

interface FavoriteButtonProps {
  checked: boolean;
  id: string;
  handleOpenConfirmationModal: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  favorite: {
    color: theme.palette.common.favorite,
    fontSize: 'large',
  },
}));
export const FavoriteButton: FC<FavoriteButtonProps> = ({
  checked,
  id,
  handleOpenConfirmationModal,
}) => {
  const classes = useStyles();
  const handleClick = (e: any) => {
    e.stopPropagation();
    handleOpenConfirmationModal(id);
  };
  return (
    <>
      {checked ? (
        <StarIcon onClick={handleClick} className={classes.favorite} />
      ) : (
        <StarOutlineIcon onClick={handleClick} className={classes.favorite} />
      )}
    </>
  );
};
