import { Box, Divider, Stack, Typography } from '@mui/material';
import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { useGetStepsMethod1 } from './RightSideWelcomeLayout.helper';
import { useRightSideWelcomeLayoutStyles } from './RightSideWelcomeLayout.style';

export const RightSideWelcomeLayout = () => {
  const styles = useRightSideWelcomeLayoutStyles();

  const steps = useGetStepsMethod1();
  return (
    <Box className={styles.container}>
      <Typography className={styles.description}>
        {OnboardingWelcomeStrings.RightSideDescription}
      </Typography>
      <Box className={styles.dividerWrapper}>
        <Divider className={styles.divider} />
      </Box>
      <Stack spacing={3}>
        {steps.map((step) => {
          return (
            <Box className={styles.stepWrapper}>
              {step.icon}
              <Box className={styles.titleWrapper}>
                <Typography className={styles.stepTitle}>{step.title}</Typography>
                <Box className={styles.stepDivider}>
                  <Divider sx={{ borderColor: '#F7B409' }} />
                </Box>
                <Typography className={styles.stepDescription}>{step.description}</Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};
