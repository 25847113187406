import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  loaderWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '80px',
  },
}));
