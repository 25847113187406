import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';

import { CommonStrings, WebAppSignUpConfigStrings, WorkspaceStrings } from '../../localization/en';
import { useActionBarStyles } from './BottomActionBar.helper';

export interface BottomActionBarProps {
  show: boolean;
  onSaveClick?: () => void;
  onCancelClick?: () => void;

  /**
   * When equals to true renders first time action title on buttons
   * Renders component event if "show" equals to false
   */
  firstTimeExperience?: boolean;
  onSaveAndNextClick?: () => void;
  onSetupLaterClick?: () => void;

  disableSaveButton?: boolean;
  loading?: boolean;

  /**
   * When equals to true renders only "continue" button
   */
  continueMode?: boolean;
  onContinueClick?: () => void;

  saveButtonText?: string;
  cancelButtonText?: string;
  continueButtonText?: string;
  width?: string;
}

export const BottomActionBar: FC<BottomActionBarProps> = ({
  show,
  onSaveClick,
  onCancelClick,
  onSaveAndNextClick,
  onSetupLaterClick,
  firstTimeExperience,
  disableSaveButton,
  loading,
  saveButtonText,
  cancelButtonText,
  continueMode,
  continueButtonText,
  onContinueClick,
  width = '90%',
}) => {
  const styles = useActionBarStyles();
  const { addToast } = useToasts();
  const saveButtonTitle = useMemo(() => {
    if (saveButtonText) return saveButtonText;
    return firstTimeExperience ? WorkspaceStrings.Save : CommonStrings.Save;
  }, [firstTimeExperience, saveButtonText]);

  const cancelButtonTitle = useMemo(() => {
    if (cancelButtonText) return cancelButtonText;
    return firstTimeExperience ? WorkspaceStrings.SetupLater : CommonStrings.Cancel;
  }, [firstTimeExperience, cancelButtonText]);

  const continueButtonTitle = useMemo(() => {
    if (continueButtonText) return continueButtonText;
    return firstTimeExperience ? WorkspaceStrings.Finish : WorkspaceStrings.Continue;
  }, [continueButtonText, firstTimeExperience]);

  if (!show && !firstTimeExperience) return null;

  const handleSaveButtonClick = () => {
    if (!onSaveAndNextClick || !firstTimeExperience) {
      onSaveClick?.();
      return;
    }
    onSaveAndNextClick?.();
  };

  return (
    <Grid className={styles.topbarWrapper} container justifyContent="center" style={{ width }}>
      <Grid item xs={6}>
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
          {continueMode ? (
            <LoadingButton
              size="large"
              className={styles.styledButton}
              variant="contained"
              color="primary"
              onClick={onContinueClick}
              disabled={disableSaveButton}
              loading={loading}
              id="bottom-bar-continue-action"
            >
              {continueButtonTitle}
            </LoadingButton>
          ) : (
            <>
              <LoadingButton
                size="large"
                className={styles.styledButton}
                id="bottom-bar-save-action"
                variant="contained"
                color="primary"
                onClick={handleSaveButtonClick}
                disabled={disableSaveButton}
                loading={loading}
              >
                {saveButtonTitle}
              </LoadingButton>
              <Button
                className={styles.cancelButton}
                id="bottom-bar-cancel-action"
                color="primary"
                variant="outlined"
                onClick={() => {
                  if (firstTimeExperience) {
                    onSetupLaterClick && onSetupLaterClick();
                    addToast(WebAppSignUpConfigStrings.setupLaterMessage, {
                      appearance: 'success',
                      autoDismissTimeout: 2500,
                    });
                  }
                  onCancelClick && onCancelClick();
                }}
                disabled={loading}
              >
                {cancelButtonTitle}
              </Button>
            </>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
