export const DownloadVideoIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_515_153298)">
        <rect x="0.5" y="0.5" width="24" height="24" rx="4.5" stroke="#D0D5DD" />
        <path
          d="M5.70508 14.3086V17.3567C5.70508 17.909 6.15279 18.3567 6.70508 18.3567H18.4206C18.9729 18.3567 19.4206 17.909 19.4206 17.3567V14.3086"
          stroke="#344054"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M9.20703 12.0664L11.8553 14.7147C12.2458 15.1052 12.879 15.1052 13.2695 14.7147L15.9178 12.0664"
          stroke="#344054"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M12.5625 6.64453V15.0604"
          stroke="#344054"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_515_153298"
          x="-4"
          y="-4"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_515_153298" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_515_153298"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
