import { Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { BeaconIcon } from '../../../common/assets/newDesign/BeaconIcon';
import { FSModal } from '../../../common/components/modal/Modal';
import { BeaconStrings } from '../../../common/localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import { useStyles } from './Beacon.helper';
interface BeaconWelcomePopupProps {
  isOpen: boolean;
  toggleModal: () => void;
}
export const BeaconWelcomePopup: FC<BeaconWelcomePopupProps> = ({ isOpen, toggleModal }) => {
  const styles = useStyles({});
  const { isHealthCare } = useClientType();
  return (
    <FSModal
      width="80%"
      backgroundColor="#F5F6F8"
      modalIsOpen={isOpen}
      onClose={toggleModal}
      rounded="32px"
      blur
      padding="0"
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid xs={6} className={styles.left}>
          <BeaconIcon />
          <Typography className={styles.modal__title}>{BeaconStrings.ModalTitle}</Typography>
          <Typography className={styles.modal__desc}>
            {isHealthCare
              ? BeaconStrings.ModalDescP1.replace('customer', 'patient')
              : BeaconStrings.ModalDescP1}
          </Typography>
          {/* <Typography className={styles.modal__desc}>{BeaconStrings.ModalDescP1}</Typography> */}
          <Grid className={styles.modal__btnWrapper}>
            <Button
              onClick={toggleModal}
              variant="contained"
              size="large"
              color="primary"
              className={styles.modal__btn}
            >
              {BeaconStrings.ModalBtn}
            </Button>
          </Grid>
        </Grid>
        <Grid xs={6} className={styles.right}>
          <img src={'/Tracking-Pixel-Welcome-Page.png'} alt="Tracking-Pixel-Preview" />
        </Grid>
      </Grid>
    </FSModal>
  );
};
