import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ResetPasswordOptions } from '../../../../api/models/auth';
import { useAppDispatch } from '../../../../store';
import { resetPasswordMe } from '../../../../store/slices/meSlice';
import { AccountProfileStrings, CommonStrings } from '../../../localization/en';
import { FSModal } from '../../modal/Modal';
import { PasswordsError } from '../../MyProfile/MyProfile.helper';
import { AdornmentPosition, TextFieldComponent } from '../../TextField/TextField';
import { useStyles, validationSchemaPassword } from '../AccountProfile.helper';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../../constants/events/accountEvents';

interface PasswordModalProps {
  openModal: boolean;
  toggleModal: () => void;
  isLoading: boolean;

  id: string | undefined;
}

export const PasswordModal: FC<PasswordModalProps> = ({
  openModal,
  toggleModal,
  isLoading,
  id,
}) => {
  const styles = useStyles();
  const { trackEvent } = useTrackEvent();

  const { addToast } = useToasts();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState({ newPassword: false, confirmPassword: false });

  const {
    values,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldTouched,
    setFieldError,
    dirty,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchemaPassword,
    onSubmit: async (values) => {
      if (!id) {
        return;
      }

      await updatePassword({
        currentPassword: values.password,
        newPassword: values.newPassword,
        id,
      });
    },
  });

  const handleSave = (e: any) => {
    trackEvent(AccountEventNames.savePasswordClicked);
    handleSubmit(e);
  };

  const handleReset = () => {
    trackEvent(AccountEventNames.cancelPasswordClicked);
    resetForm();
    toggleModal();
  };

  const updatePassword = async (values: ResetPasswordOptions) => {
    await dispatch(resetPasswordMe(values)).then(({ payload }) => {
      if (typeof payload !== 'string') {
        addToast('Password is updated', {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        handleReset();
      } else {
        payload === PasswordsError.CurrentPassIsInvalid.toLocaleLowerCase()
          ? setFieldError('password', PasswordsError.CurrentPassIsInvalid)
          : setFieldError('newPassword', PasswordsError.UseDifferentPass);
      }
    });
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    return () => {
      resetForm();
    };
  }, [resetForm]);

  return (
    <FSModal
      modalIsOpen={openModal}
      onClose={handleReset}
      rounded="24px"
      padding="16px"
      width="400px"
    >
      <Box>
        <Typography textAlign={'center'} className={styles.title}>
          {AccountProfileStrings.SetNewPassword}
        </Typography>
        <Typography className={styles.label}>{AccountProfileStrings.OldPassword}</Typography>
        <TextFieldComponent
          placeholder="Old Password"
          name="password"
          value={values.password || ''}
          onChange={(event) => {
            handleChange(event);
            trackEvent(AccountEventNames.oldPasswordTyped, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          errorText={errors.password || undefined}
          helperText
          fullWidth
        />
        <Typography className={styles.label}>{AccountProfileStrings.NewPassword}</Typography>
        <TextFieldComponent
          placeholder="6+ Characters"
          name="newPassword"
          value={values.newPassword || ''}
          onChange={(event) => {
            handleChange(event);
            trackEvent(AccountEventNames.newPasswordTyped, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          errorText={errors.newPassword || undefined}
          helperText
          fullWidth
          disabled={isLoading}
          type={showPassword.newPassword ? 'text' : 'password'}
          adornmentPosition={AdornmentPosition.End}
          adornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                setShowPassword((prev) => ({ ...prev, newPassword: !prev.newPassword }))
              }
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
        <Typography className={styles.label}>{AccountProfileStrings.ConfirmNewPassword}</Typography>
        <TextFieldComponent
          placeholder="6+ Characters"
          name="confirmPassword"
          value={values.confirmPassword || ''}
          onChange={(event) => {
            handleChange(event);
            trackEvent(AccountEventNames.confirmPasswordTyped, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          errorText={errors.confirmPassword || undefined}
          helperText
          fullWidth
          disabled={isLoading}
          type={showPassword.confirmPassword ? 'text' : 'password'}
          adornmentPosition={AdornmentPosition.End}
          adornment={
            <IconButton
              aria-label="toggle password visibility"
              onClick={() =>
                setShowPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
              }
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          }
        />
        <Box>
          <Button
            variant="contained"
            size="large"
            sx={{ marginTop: '36px', marginBottom: '16px', borderRadius: '100px' }}
            color="primary"
            onClick={handleSave}
            disabled={!dirty || !!Object.keys(errors).length || isLoading}
            fullWidth
          >
            {AccountProfileStrings.ChangePassword}
          </Button>
        </Box>
        <Box>
          <Button
            onClick={handleReset}
            variant="text"
            color="primary"
            size="large"
            sx={{ borderRadius: '100px' }}
            fullWidth
          >
            {CommonStrings.Cancel}
          </Button>
        </Box>
      </Box>
    </FSModal>
  );
};
