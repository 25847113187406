import { FC } from 'react';
import { Grid } from '@mui/material';
import { RewardWrapper, RewardImage, RewardText } from './components.style';
import { SectionTitle } from '../Steps.style';
import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { NewRewardSchema } from '../../WizzardStepperHelper';

interface RewardSectionProps {
  rewardStepState: NewRewardSchema;
}

export const RewardSection: FC<RewardSectionProps> = ({ rewardStepState }) => {
  return (
    <Grid container alignItems="center" justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <SectionTitle>{VenueWizardPreviewStepStrings.RewardTitle}</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        <RewardWrapper>
          <Grid container alignItems="center" justifyContent="space-evenly" spacing={3}>
            <Grid item xs={3}>
              <RewardImage src={rewardStepState.image} />
            </Grid>
            <Grid item xs={6}>
              <RewardText>{rewardStepState.name}</RewardText>
            </Grid>
          </Grid>
        </RewardWrapper>
      </Grid>
    </Grid>
  );
};
