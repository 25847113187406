import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    padding: '30px 16px 14px',
  },
  tabsWrapper: {
    width: '223px',
    height: '100%',
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    padding: '14px 8px',
  },
  tableWrapper: {
    width: '100%',
  },
  aboutTooltip: {
    marginTop: '50.5px',
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '15px',
    textAlign: 'left',
    position: 'relative',
    color: theme.palette.common.black,
  },
  tooltip: {
    position: 'absolute',
    top: '-2px',
    right: '-2px',
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 20px 7px 12px',
    marginTop: '12px',
  },
  totalLabel: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20px',
    color: '#667085',
  },
  totalNumber: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    total: '#344054',
  },
}));
