import React from 'react';

const RadioIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" fill="white" />
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" stroke="#D0D5DD" />
    </svg>
  );
};

export default RadioIcon;
