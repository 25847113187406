import { FC, useEffect } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { useStyles } from './UwmLaunchModal.styles';
import { UwmLaunchModalStrings } from '../../localization/en';
import IconButton from '@mui/material/IconButton';
import { CloseIcon } from '../../assets/CloseIcon';

import { CampaignsApiModel } from '../../../api/models/campaigns';
import VideoReviews from './VideoReviews/VideoReviews';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  campaign: CampaignsApiModel;
  openIndividualModal?: () => void;
  openImportModal?: () => void;
}

export const UwmLaunchModal: FC<Props> = ({
  isOpen,
  onClose,
  campaign,
  openIndividualModal,
  openImportModal,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (isOpen) {
      localStorage.setItem('showUwmLaunchModal', 'false');
    }
  }, [isOpen]);

  const handleClose = async () => {
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onMouseUp={(event) => {
        event.stopPropagation();
      }}
      onClose={handleClose}
      className={styles.modal}
    >
      <Box className={styles.container}>
        <Box className={styles.topWrapper}>
          <img src="/Brand360Icon.png" alt="Brand 360 Icon" className={styles.brandIcon} />
          <Box className={styles.titleWrapper}>
            <Typography className={styles.title}>{UwmLaunchModalStrings.Title}</Typography>
            <Box className={styles.subtitleWrapper}>
              <Typography className={styles.subtitle}>{UwmLaunchModalStrings.Subtitle}</Typography>
            </Box>
          </Box>
          <IconButton className={styles.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={styles.contentWrapper}>
          <VideoReviews
            onClose={onClose}
            openImportModal={openImportModal}
            openIndividualModal={openIndividualModal}
            linkedSocialverseId={campaign.linkedSocialverseId}
          />
        </Box>
      </Box>
    </Modal>
  );
};
