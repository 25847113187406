import { useEffect, useState } from 'react';

import { useTypedSelector } from '../store';

interface UseVenueConfig {
  onVenueLoad?: () => void;
}

export const useVenue = (config?: UseVenueConfig) => {
  const [onLoadCalled, setOnLoadCalled] = useState(false);

  const {
    venue: { id: venueId, isLoading: isVenueLoading, ...venue },
    venueAttributes: { properties: venueProperties, isLoading: isVenueAttributesIsLoading },
  } = useTypedSelector((state) => state.venue);

  const isLoading = isVenueLoading || isVenueAttributesIsLoading;
  const isLoaded = !!venueId && !!venueProperties;

  useEffect(() => {
    if (!isLoaded || onLoadCalled) return;
    config?.onVenueLoad?.();
    setOnLoadCalled(true);
  }, [config, isLoaded, onLoadCalled, venueId]);

  return {
    venueId,
    isVenueLoading,
    venueProperties,
    isVenueAttributesIsLoading,
    isLoading,
    isLoaded,
    ...venue,
  };
};
