import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.4px',
    color: '#1A1538',
    marginTop: '35px',
  },
  subtitle: {
    margin: '0 auto 30px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.36px',
    color: '#1A1538',
    marginTop: '35px',
    width: '317px',
  },
  btn: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '26px',
    borderRadius: '100px',
    height: '45px',
    marginBottom: '35px',
    padding: '0 30px',
  },
}));
