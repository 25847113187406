import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  titleWrapper: {
    display: 'flex',
  },
  reviewedContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  allSetText: {
    fontSize: '36px',
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    lineHeight: '44px',
    letterSpacing: '-0.72px',
    marginTop: '61px',
  },
  checkedVideoText: {
    fontFamily: 'Inter',
    marginTop: '10px',
    fontSize: '18px',
    fontWeight: 500,
    color: '#475467',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.common.gray900,
    margin: '16px 0 48px 0',
    fontFamily: 'Inter',
  },
  titleDescr: {
    fontFamily: 'Inter',
    paddingLeft: '3px',
    marginTop: '17px',
    fontSize: '15px',
    fontWeight: 500,
    color: '#555555',
  },
  noVideosText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: theme.palette.primary.main,
    marginTop: '41px',
    marginBottom: '40px',
  },
  viewVideosBtn: {
    width: '161px',
    height: '48px',
    background: theme.palette.primary.main,
    color: '#fff',
    marginTop: '20px',
    marginBottom: '50px',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      background: theme.palette.primary.main,
      boxShadow: 'none',
    },
    '&:active': {
      transform: 'scale(0.98)',
    },
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
  },
  placeholder: {
    minHeight: '336px',
  },
  swiperWrapper: {
    position: 'absolute',
    top: '135px',
    left: '-30px',
    width: '1060px',
    display: 'flex',
    justifyContent: 'center',
  },
  videosWrapper: {
    minHeight: '308px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '20px',
    overflow: 'hidden',
  },
  updateInfo: {
    color: '#555555',
    width: '100%',
    textAlign: 'center',
  },

  updateButton: {
    margin: '16px auto',
    height: '44px',
    width: '174px',
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    boxShadow: 'none',

    '&:active': {
      transform: 'scale(0.98)',
    },

    '&:hover': {
      background: theme.palette.primary.main,
      boxShadow: 'none',
    },

    '&:disabled': {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      opacity: '0.1',
    },

    '&.MuiLoadingButton-loading': {
      color: 'transparent',
    },

    '& .MuiLoadingButton-loadingIndicator': {
      color: theme.palette.common.white,
    },
  },
  navigationWrapper: {
    display: 'flex',
    marginRight: '-20px',
  },
  navigation: {
    minWidth: 34,
    maxWidth: 34,
    maxheight: 34,
    borderRadius: '50%',
    border: '1px solid #EAECF0',
    backgroundColor: '#F9FAFB',
    stroke: '#344054',
    fontSize: '20px',

    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      stroke: '#D0D5DD',
    },
  },
  noViedosWrapper: {
    marginTop: '62px',
  },
}));
