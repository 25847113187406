import { FC } from 'react';
import { Box, CardMedia, useTheme } from '@mui/material';
import { useStyles } from './VideoDownloadCard.helper';

interface VideoDownloadCardProps {
  url: string;
  isActive?: boolean;
  thumbnailUrl?: string;
  onClick?: () => void;
}

export const VideoDownloadCard: FC<VideoDownloadCardProps> = ({
  url,
  isActive,
  thumbnailUrl,
  onClick,
}) => {
  const classes = useStyles();

  const theme = useTheme();

  return (
    <Box
      className={classes.container}
      onClick={onClick}
      style={{ borderColor: isActive ? theme.palette.primary.main : '#EAECF0' }}
    >
      <CardMedia
        className={classes.video}
        component="video"
        src={url}
        controls
        image={thumbnailUrl}
      />
    </Box>
  );
};
