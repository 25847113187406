import React, { FC } from 'react';

export const PaginationSelectIcon: FC = () => {
  return (
    <svg width="6" height="5" viewBox="0 0 6 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.666007 1.41016L2.99938 3.74349L5.33268 1.41016"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
