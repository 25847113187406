import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  tabItem: {
    padding: '10px 0',
    fontSize: '18px',
    fontWeight: 500,
    color: '#828282',
    transition: 'color 0.11s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    marginRight: '42px',

    '&:hover': {
      color: '#5f5f5f',
    },
  },
  activeTab: {
    color: theme.palette.common.black,
    borderBottom: `3px solid ${theme.palette.primary.main}`,

    '&:hover': {
      color: theme.palette.common.black,
    },
  },
  tabCount: {
    minWidth: '24px',
    maxWidth: 'fit-content',
    height: '24px',
    borderRadius: '50%',
    marginLeft: '10px',
    backgroundColor: '#EFEDFF',
    display: 'grid',
    placeItems: 'center',
    fontSize: '12px',
    fontWeight: 500,
    color: '#344054',
  },
}));
