import { FC, SVGProps } from 'react';
interface EditIconProps extends SVGProps<SVGSVGElement> {
  color: string;
}
export const EditIcon: FC<EditIconProps> = (props) => {
  return (
    <svg {...props} viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.7659 7.45044V9.80723C10.7659 10.9118 9.87051 11.8072 8.76594 11.8072H4.59869C3.49413 11.8072 2.59869 10.9118 2.59869 9.80723V5.63998C2.59869 4.53542 3.49412 3.63998 4.59869 3.63998H7.09087"
        stroke={props.color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.48328 3.25673C9.8738 2.8662 10.507 2.8662 10.8975 3.25673L11.1493 3.50855C11.5398 3.89907 11.5398 4.53224 11.1493 4.92276L8.0937 7.97837C7.94843 8.12364 7.76194 8.22064 7.55959 8.25618L6.5518 8.43319C6.21027 8.49318 5.91285 8.19577 5.97284 7.85423L6.14986 6.84644C6.1854 6.6441 6.2824 6.45761 6.42767 6.31233L9.48328 3.25673Z"
        stroke={props.color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
