import { FC } from 'react';

export const SmsIcon: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.1252 4.90944C17.2442 3.05826 14.7108 2.02075 12.0716 2.02075C9.43246 2.02075 6.89907 3.05826 5.01803 4.90944V4.90944C3.53712 6.38451 2.55728 8.28771 2.217 10.35C1.87672 12.4123 2.19322 14.5294 3.12176 16.402L2.67643 20.0797C2.65278 20.2569 2.6703 20.4373 2.72763 20.6066C2.78497 20.776 2.88056 20.9299 3.00701 21.0564C3.13346 21.1828 3.28736 21.2784 3.45675 21.3357C3.62613 21.3931 3.80644 21.4106 3.98369 21.387L7.66132 20.9416C9.53395 21.8702 11.6511 22.1867 13.7134 21.8464C15.7757 21.5061 17.6789 20.5262 19.1539 19.0453V19.0453C20.085 18.118 20.8233 17.0154 21.3262 15.8013C21.8291 14.5872 22.0866 13.2855 22.084 11.9714C22.0813 10.6573 21.8185 9.35667 21.3107 8.14461C20.8028 6.93255 20.0601 5.83302 19.1252 4.90944V4.90944Z"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={12.0839}
      cy={12.0002}
      r={0.65}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.848258}
    />
    <circle
      cx={8.32413}
      cy={12.0002}
      r={0.65}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.848258}
    />
    <circle
      cx={15.8437}
      cy={12.0002}
      r={0.65}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.848258}
    />
  </svg>
);
