import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { WebsiteRoutes } from '../../../constants/routes';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '8px 12px',
    marginRight: '6px',
    '&:last-child': {
      marginRight: '0',
    },
    color: '#858C98',
    borderRadius: '6px',
    '&:hover': {
      background: '#F9FAFB',
    },
    cursor: 'pointer',
    height: '40px',
    fontWeight: '500',
  },
  active: {
    color: theme.palette.common.white,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

export interface SubNavigationTab {
  text: string;
  route: WebsiteRoutes;
}

export interface WebAppSubnaviationItem {
  text: string;
  route: WebsiteRoutes;
}
