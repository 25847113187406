import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    fill="none"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={25} cy={25} r={24} fill="currentColor" fillOpacity={0.05} />
    {/* <circle cx={25} cy={25} r={24} stroke="currentColor" /> */}
    <rect
      x={13}
      y={13}
      width={5.051}
      height={5.051}
      rx={1}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={33.254}
      y={13}
      width={5.051}
      height={5.051}
      rx={1}
      transform="rotate(90 33.254 13)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 23.078h2.525M20.65 23.078h7.553M23.176 13v2.525M23.176 20.55v2.526M33.254 23.078v1.642M23.176 28.203v5.05M28.204 23.152v5.05"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={33.254}
      cy={33.254}
      r={5.746}
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m35.101 33.586-1.846 1.846-1.848-1.847M33.255 35.434v-4.355"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={13}
      y={28.203}
      width={5.051}
      height={5.051}
      rx={1}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InvitesQRIcon = memo(SvgComponent);
