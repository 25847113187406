import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Fade,
  Modal,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useHistory, useLocation } from 'react-router-dom';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { LaunchPadStrings, SmsImportUserStrings } from '../../../common/localization/en';

import { BookIcon } from './icons/BookIcon';
import { useStyles } from './QuickStartLaunchPadModal.styles';
import { useQuickStartLaunchPad } from './useQuickStartLaunchPad';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../../common/constants/events/quickstartEvents';
import { isUWMClient } from '../../../utils/isUWMClient';
import { useModal } from '../../../hooks/useModal';
import { PreviewVideo } from './components/PreviewVideo/PreviewVideo';
import { createInvitation, sendInvitations } from '../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';
import { CampaignInvitation } from '../../../api/models/campaigns';
import { CommonEventNames } from '../../../common/constants/events/common';
import {
  setForceHideQSmarker,
  setTabIndex as launchPadSetTabIndex,
} from '../../../store/slices/launchPadSlice';
import { TextMessageIcon } from '../../../common/assets/TextMessageIcon';

interface LaunchModalAccordionItem {
  id: string;
  redirectTo: string;
  title: string;
  sectionContent: React.ReactNode;
  action: string;
  video: { src: string; poster: string };
}
export const launchModalAccordionItems: LaunchModalAccordionItem[] = [
  {
    id: 'invite-customers',
    redirectTo: WebsiteRoutes.RewardCampaigns,
    title: LaunchPadStrings.InviteCustomersTitle,
    sectionContent: <Box>{LaunchPadStrings.InviteCustomersDescription}</Box>,
    action: QuickStartEventNames.InviteCustomers,
    video: {
      src: 'https://storage.googleapis.com/sv-dev-assets/Topics%20Guide%20Step%201.mp4',
      poster:
        'https://res.cloudinary.com/socialvenu/video/upload/v1/sv-dev-assets/Topics%20Guide%20Step%201.png',
    },
  },
  {
    id: 'google',
    redirectTo: WebsiteRoutes.Google,
    title: LaunchPadStrings.GoogleProfileTitle,
    sectionContent: (
      <Box display="flex">
        <Box
          component="img"
          src="/GoogleProfile.png"
          alt="google icon"
          sx={{ width: '27px', mr: '16px' }}
        />

        {LaunchPadStrings.GoogleProfileDescription}
      </Box>
    ),
    action: QuickStartEventNames.GoogleSync,
    video: {
      src: 'https://storage.googleapis.com/sv-dev-assets/Topics%20Guide%20Step%202.mp4',
      poster:
        'https://res.cloudinary.com/socialvenu/video/upload/v1/sv-dev-assets/Topics%20Guide%20Step%202.jpg',
    },
  },
  {
    id: 'website-plugin',
    redirectTo: WebsiteRoutes.SocialVerse,
    title: LaunchPadStrings.WebsitePluginsTitle,
    sectionContent: <Box>{LaunchPadStrings.WebsitePluginsDescription}</Box>,
    action: QuickStartEventNames.ConnectToSocialverse,
    video: {
      src: 'https://storage.googleapis.com/sv-dev-assets/Topics%20Guide%20Step%203.mp4',
      poster:
        'https://res.cloudinary.com/socialvenu/video/upload/v1/sv-dev-assets/Topics%20Guide%20Step%203.jpg',
    },
  },
  {
    id: 'creator-app',
    redirectTo: WebsiteRoutes.BrandingStyleTab,
    title: LaunchPadStrings.CreatorAppTitle,
    sectionContent: <Box>{LaunchPadStrings.CreatorAppDescription}</Box>,
    action: QuickStartEventNames.UploadFirstVideo,
    video: {
      src: 'https://storage.googleapis.com/sv-dev-assets/Topics%20Guide%20Step%204.mp4',
      poster:
        'https://res.cloudinary.com/socialvenu/video/upload/v1/sv-dev-assets/Topics%20Guide%20Step%204.jpg',
    },
  },
];

export const QuickStartLaunchPadModal: React.FC = () => {
  const pathname = useLocation().pathname;
  const isUwmClient = isUWMClient();
  const classes = useStyles({
    isUwmClient,
  });
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const history = useHistory();
  const [initializedVenueId, setInitializedVenueId] = useState('');

  const {
    isStarted,
    tabIndex,
    currentTargetIndex,
    setTabIndex,
    navigateToActiveItem,
    moveToNextItem,
    isLoading,
    isUpdating,
    isLaunchpadVisible,
    isFinished,
    finishLaunchPadFlow,
    showLaunchpad,
  } = useQuickStartLaunchPad();
  const theme = useTheme();

  useEffect(() => {
    //show topics modal on page refresh and prevent qs marker blinking
    dispatch(setForceHideQSmarker(true));

    (async () => {
      if (isStarted && venueId !== initializedVenueId && !isLaunchpadVisible) {
        await showLaunchpad();
      }

      if (venueId && venueId === initializedVenueId) {
        dispatch(setForceHideQSmarker(false));
      }

      setInitializedVenueId(venueId);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStarted, venueId, initializedVenueId]);

  const {
    campaignUnsentInvitations: { items: unsentInvites },
    campaignActiveInvitations: { invitations, pendingUsers },
    campaignQueuedInvitations: { items: queuedInvites },
    primaryCampaign,
    items,
  } = useTypedSelector((state) => state.campaigns);
  const { phoneNumber, firstName, lastName } = useTypedSelector((state) => state.me);

  const isBorrowersAdded = [unsentInvites, pendingUsers, queuedInvites, invitations].some(
    (invites) => invites.length > 0,
  );

  const isAcceptableRoute = [
    WebsiteRoutes.Accounts,
    WebsiteRoutes.RewardCampaigns,
    WebsiteRoutes.RewardCampaigns,
    WebsiteRoutes.SocialVerse,
    WebsiteRoutes.Content,
    WebsiteRoutes.Heroes,
    WebsiteRoutes.WebAppSettings,
    WebsiteRoutes.Google,
  ].some((route) => pathname.startsWith(route));

  const isModalClosed =
    !isStarted || (isLoading && !isUpdating) || !isLaunchpadVisible || !isAcceptableRoute;

  const sendInvitation = async () => {
    const campaignId = primaryCampaign?.id || items[0]?.id;
    const record = { firstName, lastName, phoneNumber: phoneNumber || '' };
    const invitationResponse = await dispatch(
      createInvitation({
        campaignId,
        ...record,
        transactionDate: new Date().toISOString(),
      }),
    );

    //@ts-ignore
    if (invitationResponse?.error) {
      addToast(
        //@ts-ignore
        invitationResponse?.payload?.message?.includes('already exists')
          ? SmsImportUserStrings.InvitationAlreadyExists
          : SmsImportUserStrings.PleaseCheckPhoneNumber,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
      return;
    }

    const invitation = invitationResponse.payload as CampaignInvitation;
    dispatch(sendInvitations({ campaignId, invitationIds: [invitation.id] }));
    addToast(SmsImportUserStrings.InvitationBeenSent, {
      appearance: 'success',
      autoDismiss: true,
    });
    trackEvent(CommonEventNames.add_users_manually_import_data_button_click);
  };

  const navigateToPrimaryCampaign = () => {
    if (!primaryCampaign) return;
    history.push(WebsiteRoutes.CampaignDetails.replace(':id', primaryCampaign.id));
  };

  const onLetsGoClick = (item: LaunchModalAccordionItem) => {
    trackEvent(QuickStartEventNames.LaunchPadModalLetsGo, {
      value: item.action,
    });

    if (item.id === 'creator-app') {
      sendInvitation();
      moveToNextItem({
        isCompleted: true,
      });
      return;
    }
    navigateToActiveItem();
  };

  const onSkipClick = (item: LaunchModalAccordionItem) => {
    trackEvent(QuickStartEventNames.LaunchPadModalSkip, {
      value: item.action,
    });
    moveToNextItem();
  };

  const activeItem = launchModalAccordionItems[tabIndex];

  const [isClosing, setIsClosing] = useState(false);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const { isOpen, close, open } = useModal();

  useEffect(() => {
    if (isModalClosed) {
      close();
    } else {
      open();

      // unblock all elements on the current page
      const elementsToUnblock: NodeListOf<
        HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
      > = document.querySelectorAll('button, a, [data-qs-button="false"], div[role="button"]');

      elementsToUnblock.forEach((element) => {
        element.onmouseover = () => {
          element.style.cursor = 'pointer';
        };

        if (element instanceof HTMLButtonElement) {
          element.disabled = false;
          return;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalClosed]);

  return (
    <Modal open={isOpen}>
      <Fade
        timeout={500}
        in={isOpen}
        onEnter={() => {
          setIsClosing(false);
        }}
        onExit={() => {
          setIsClosing(true);
        }}
      >
        <Box
          className={classes.modalContainer}
          sx={{
            animation: `${isClosing ? 'closeModal' : 'openModal'} 0.5s ease-in-out`,
            '@keyframes openModal': {
              '0%': {
                top: '20%',
                left: '-50%',
                scale: '0',
              },
              '100%': {
                top: '50%',
                left: '50%',
                scale: '1',
              },
            },
            '@keyframes closeModal': {
              '0%': {
                top: '50%',
                left: '50%',
                scale: '1',
              },
              '100%': {
                top: '20%',
                left: '-50%',
                scale: '0',
              },
            },
          }}
        >
          <Box
            className={classes.heading}
            style={{
              backgroundColor: isUwmClient ? '#005F9E' : '#3B6EED',
            }}
          >
            <Box className={classes.headingIconContainer}>
              <BookIcon />
            </Box>
            {isUwmClient ? (
              <Box>
                <Typography className={classes.uwmTitle}>{LaunchPadStrings.UwmTitle}</Typography>
                {isBorrowersAdded ? (
                  <>
                    <li className={classes.uwmSubtitleList}>{LaunchPadStrings.WeInvitedClients}</li>
                    <li className={classes.uwmSubtitleList}>
                      {LaunchPadStrings.AutomaticallySend}
                    </li>
                  </>
                ) : (
                  <li className={classes.uwmSubtitleList}>{LaunchPadStrings.NextBorrower}</li>
                )}
                <Typography className={classes.uwmSubtitle}>
                  {LaunchPadStrings.CheckTopics}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.headingContentContainer}>
                <Typography className={classes.headingTitle}>{LaunchPadStrings.Title}</Typography>
                <Typography className={classes.headingSubtitle}>
                  {LaunchPadStrings.Subtitle}
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="center">
            <Stepper
              nonLinear
              activeStep={tabIndex}
              orientation="vertical"
              className={classes.stepper}
              sx={{
                '& .MuiStepLabel-labelContainer .Mui-active .MuiTypography-root': {
                  color: `${isUwmClient ? '#00A39D' : '#3B6EED'} !important`,
                },
                '& .MuiStepIcon-root': {
                  color: isUwmClient ? '#00A39D' : '#3B6EED',
                },
                '&.Mui-active': {
                  color: isUwmClient ? '#67CE67' : '#3B6EED',
                },
              }}
            >
              {launchModalAccordionItems.map((item, index) => {
                const isActiveStep = tabIndex === index;
                const isCompletedStep = index < currentTargetIndex;
                const isStepDisabled = index > currentTargetIndex;

                return (
                  <Step
                    key={item.title}
                    className={classes.step}
                    completed={isCompletedStep}
                    sx={{
                      cursor: isStepDisabled ? 'default' : 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isStepDisabled) return;

                      if (isFinished && isActiveStep) {
                        setTabIndex(-1);
                        return;
                      }

                      setTabIndex(index);
                    }}
                  >
                    <StepLabel className={classes.stepLabel}>
                      <Typography
                        className={classes.labelText}
                        style={{
                          color: isActiveStep ? theme.palette.primary.main : '#1A1538',
                        }}
                      >
                        {item.title}
                      </Typography>
                      {index <= currentTargetIndex && (
                        <ExpandMoreIcon
                          className={classes.expandIcon}
                          style={{
                            transform: isActiveStep ? 'none' : 'rotate(270deg)',
                          }}
                        />
                      )}
                    </StepLabel>

                    <StepContent
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Box className={classes.stepContent}>
                        {item.sectionContent}

                        <Box className={classes.actionButtonsContainer}>
                          {!isCompletedStep && !isVideoPlaying && (
                            <>
                              <RoundedButton
                                title={
                                  item.id === 'creator-app'
                                    ? LaunchPadStrings.TextInvite
                                    : LaunchPadStrings.ActionButtonConfirm
                                }
                                textColor="white"
                                backgroundColor={isUwmClient ? '#005F9E' : '#3B6EED'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onLetsGoClick(item);
                                  setIsVideoPlaying(true);
                                }}
                                loading={isUpdating}
                                sx={{
                                  width: item.id === 'creator-app' ? 'fit-content' : '130px',
                                  borderLeft: `8px solid ${isUwmClient ? '#A1CE57' : '#FFB515'}`,
                                }}
                                className={classes.letsGoButton}
                                startIcon={
                                  item.id === 'creator-app' ? (
                                    <Box className={classes.messageIcon}>
                                      <TextMessageIcon />
                                    </Box>
                                  ) : undefined
                                }
                              />
                              <RoundedButton
                                title={LaunchPadStrings.ActionButtonDoLater}
                                variant="text"
                                textColor={isUwmClient ? '#8B89A0' : '#8B89A0'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onSkipClick(item);
                                  setIsVideoPlaying(true);
                                }}
                                loading={isUpdating}
                                className={classes.doLaterButton}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    </StepContent>
                  </Step>
                );
              })}
            </Stepper>
            <Box className={classes.previewContainer}>
              {isFinished ? (
                <Box className={classes.finishedPreviewContainer}>
                  <ConfettiExplosion zIndex={9999} duration={4000} />
                  <img
                    src="/congrads.png"
                    className={classes.finishedPreviewEmoji}
                    alt="finished"
                  />
                  <Typography
                    className={classes.finishedPreviewTitle}
                    width={isUwmClient ? '284px' : 'auto'}
                  >
                    {isUwmClient
                      ? LaunchPadStrings.WelcomeToReviewManager
                      : LaunchPadStrings.WelcomeToSv}
                  </Typography>
                  <Typography className={classes.finishedPreviewSubtitle}>
                    {LaunchPadStrings.YouAreReady}
                  </Typography>
                </Box>
              ) : (
                <PreviewVideo
                  src={activeItem?.video?.src}
                  poster={activeItem?.video?.poster}
                  autoplay={true}
                  setVideoPlaying={(isPlaying) => {
                    setIsVideoPlaying(isPlaying);
                  }}
                />
              )}
            </Box>
          </Box>

          {isFinished && (
            <Box className={classes.finishedContainer}>
              <RoundedButton
                backgroundColor={isUwmClient ? '#00A39D' : '#3B6EED'}
                title="Take me to my Dashboard"
                onClick={async () => {
                  await finishLaunchPadFlow();
                  setTimeout(() => {
                    setIsVideoPlaying(false);
                    dispatch(launchPadSetTabIndex(0));
                  }, 1000);
                  navigateToPrimaryCampaign();
                }}
              />
              <Typography className={classes.finishedText}>
                You can access this tour guide from the tutorials menu of the dashboard
              </Typography>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};
