import React, { FC } from 'react';
import { Box, Checkbox, Typography } from '@mui/material';
import { useStyles } from './DaysSelector.styles';
import { RectangleSelectedFirst } from '../../../../../../../common/assets/newDesign/RectangleSelectedFirst';
import { RectangleSelected } from '../../../../../../../common/assets/newDesign/RectangleSelected';
import { RectangleSelectedLast } from '../../../../../../../common/assets/newDesign/RectangleSelectedLast';
import { Tooltip } from '../../../../../../../common/components/Tooltip/Tooltip';
import { ElementsStrings } from '../../../../../../../common/localization/en';
import { useTypedSelector } from '../../../../../../../store';

interface Props {
  dayNumberSelected: number;
  setDayNumberSelected: (dayNumber: number) => void;
}

export const DaysSelector: FC<Props> = ({ dayNumberSelected, setDayNumberSelected }) => {
  const classes = useStyles();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);

  const followUpMessages = [
    {
      delayInDays: 1,
      enabled: true,
      body: '',
    },
    ...(currentCampaign?.followUpMessages || []),
  ].sort((a, b) => a.delayInDays - b.delayInDays);

  return (
    <Box className={classes.container}>
      {followUpMessages.map(({ delayInDays, enabled }) => {
        const isSelected = delayInDays === dayNumberSelected;
        const daysArray = followUpMessages.map((item) => item.delayInDays) || [];
        const minDay = Math.min(...daysArray);
        const maxDay = Math.max(...daysArray);
        return (
          <Box
            key={delayInDays}
            className={classes.dayItem}
            onClick={() => {
              setDayNumberSelected(delayInDays);
            }}
            sx={{
              border: isSelected ? 'none' : '1px solid #D9D9D9',
              borderTop:
                isSelected || delayInDays === dayNumberSelected + 1 ? 'none' : '1px solid #D9D9D9',
              borderBottom:
                isSelected || delayInDays === dayNumberSelected - 1 ? 'none' : '1px solid #D9D9D9',
              paddingLeft: isSelected ? '1px' : '',
              backgroundColor: isSelected ? '#fff' : '#F9FAFB',

              height:
                isSelected ||
                delayInDays === dayNumberSelected + 1 ||
                delayInDays === dayNumberSelected - 1
                  ? '40px'
                  : '39px',

              '&:hover': {
                background: isSelected ? '' : '#EBE9FF',
              },
              '&:first-child': {
                borderTopRightRadius: isSelected ? '0' : '6px',
              },
              '&:last-child': {
                borderBottomRightRadius: isSelected ? '0' : '6px',
              },
            }}
          >
            {isSelected && delayInDays === minDay && (
              <Box className={classes.rectangleSelected}>
                <RectangleSelectedFirst />
              </Box>
            )}
            {isSelected && delayInDays > minDay && delayInDays < maxDay && (
              <Box className={classes.rectangleSelected}>
                <RectangleSelected />
              </Box>
            )}
            {isSelected && delayInDays === maxDay && (
              <Box className={classes.rectangleSelected}>
                <RectangleSelectedLast />
              </Box>
            )}
            <Tooltip
              title={ElementsStrings.MessageWillNotBeSent}
              placement="left"
              icon={
                <Checkbox
                  color="primary"
                  checked={enabled}
                  // onChange={(_, isChecked) => {
                  //   if (delayInDays !== 1 && !isUpdating) {
                  //     handleCheckBoxClick(isChecked, delayInDays);
                  //   }
                  // }}
                  inputProps={{ 'aria-label': 'controlled' }}
                  icon={<Box className={classes.iconUnchecked} />}
                  checkedIcon={<Box className={classes.iconChecked} />}
                  sx={{
                    cursor: delayInDays === 1 ? 'default' : 'pointer',
                    position: 'absolute',
                    zIndex: 5,
                    width: '195px',
                    '&:hover': {
                      backgroundColor: 'unset',
                    },
                  }}
                />
              }
              light={true}
              hidden={enabled}
            />

            <Typography sx={{ marginLeft: '35px' }}>{`Day ${delayInDays}`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};
