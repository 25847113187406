import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box, Collapse, useTheme } from '@mui/material';
import React from 'react';
import { useModal } from '../../../hooks/useModal';

interface ToggleBlockProps {
  children: React.ReactNode;
}

export const ToggleBlock: React.FC<ToggleBlockProps> = ({ children }) => {
  const { isOpen, toggle } = useModal();
  const theme = useTheme();

  return (
    <>
      <Box
        component="button"
        onClick={toggle}
        sx={{
          color: theme.palette.primary.main,
          display: 'flex',
          cursor: 'pointer',
        }}
      >
        <Box component="span" pr="4px" display="flex" justifyContent="center" alignItems="center">
          <PlayArrowIcon
            sx={{
              height: '18px',
              width: '18px',
              transition: 'all 0.2s ease',
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0)',
            }}
          />
        </Box>
        <span>View Example</span>
      </Box>
      <Collapse in={isOpen}>{children}</Collapse>
    </>
  );
};
