import { FC } from 'react';

export const EyeIcon: FC = () => (
    <svg
    width={25}
    height={15}
    viewBox="0 0 25 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.061 5.77415C23.7575 6.61213 23.7575 7.81968 23.061 8.65766C22.0327 9.89473 20.7117 10.949 19.1729 11.7512C17.0659 12.8497 14.6345 13.4318 12.1535 13.4318C9.67263 13.4318 7.2412 12.8497 5.13417 11.7512C3.59537 10.949 2.27443 9.89473 1.24614 8.65766C0.549583 7.81968 0.549583 6.61213 1.24614 5.77415C2.27443 4.53708 3.59537 3.48284 5.13417 2.68061C7.2412 1.58214 9.67263 1 12.1535 1C14.6345 1 17.0659 1.58214 19.1729 2.68061C20.7117 3.48284 22.0327 4.53708 23.061 5.77415Z"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.1541 10.2158C14.1295 10.2158 15.731 8.87267 15.731 7.21582C15.731 5.55897 14.1295 4.21582 12.1541 4.21582C10.1786 4.21582 8.57715 5.55897 8.57715 7.21582C8.57715 8.87267 10.1786 10.2158 12.1541 10.2158Z"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
