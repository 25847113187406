import { Box } from '@mui/material';
import { TableSection } from '../../../../content/tableSection/TableSection';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { useEffect, useState } from 'react';
import {
  closeCampaignSummaryVideoDetails,
  getCampaignVideos,
} from '../../../../../store/slices/campaignsSlice';
import { GetCampaignVideosApiModel } from '../../../../../api/models/campaigns';
import { NewDetailsModal } from '../../../../../common/components/NewDetailsModal/NewDetailsModal';
import { VideoApiModel } from '../../../../../api/models/videos';
import { getUnbrandedThumbnail } from '../../../../../services/utilities';

export const Clips: React.FC = () => {
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);

  const campaignId = useTypedSelector((state) => state.campaigns.currentCampaign?.id);
  const { campaignSummaryVideoDetails } = useTypedSelector((state) => state.campaigns);
  const { sort } = useTypedSelector((state) => state.videos);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [videos, setVideos] = useState<GetCampaignVideosApiModel>({
    items: [],
    page: 0,
    size: 0,
    totalItems: 0,
    totalPages: 0,
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!campaignId) return;

    dispatch(
      getCampaignVideos({
        id: campaignId,
        pageable: {
          page: 0,
          size: 100000,
          sort,
        },
      }),
    ).then((data) => {
      if (data?.payload) {
        let items: VideoApiModel[];
        //@ts-ignore
        items = data?.payload.items;
        items = getUnbrandedThumbnail(items);
        //@ts-ignore
        data.payload.items = items;
        setVideos(data.payload as any);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId, sort, updateTrigger]);

  const closeVideoModal = () => {
    dispatch(closeCampaignSummaryVideoDetails());
  };

  return (
    <>
      {campaignSummaryVideoDetails.isOpen && (
        <NewDetailsModal
          userId={campaignSummaryVideoDetails.userId}
          isOpen={campaignSummaryVideoDetails.isOpen}
          videoId={campaignSummaryVideoDetails.videoId}
          handleClose={closeVideoModal}
          onUpdateThumbnail={() => {
            setUpdateTrigger((prev) => !prev);
          }}
        />
      )}
      <Box width="100%">
        <TableSection
          videoStateOverride={videos}
          onToolbarSortingUpdate={() => {}}
          onTableViewChange={() => {}}
          handleOpenDeleteModal={() => {}}
          handleOpenConfirmationModal={() => {}}
          handleOpenAddNewTagModal={() => {}}
          archivedFilter={false}
          showActionBar={activeFilter ? true : false}
          isSocialVerseAddVideo={false}
          disablePagination={true}
          isCampaignSummaryPage
          onUpdateThumbnail={() => {
            setUpdateTrigger((prev) => !prev);
          }}
        />
      </Box>
    </>
  );
};
