import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { VenueProperties } from '../../../api/models/venue';
import { Tooltip } from '../../../common/components/table/Tooltip';
import { BeaconStrings } from '../../../common/localization/en';
import { useModal } from '../../../hooks/useModal';
import { useVenue } from '../../../hooks/useVenue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { theme } from '../../../theme';
import { CODE, CODE_INSTRUCTION, instructions, useStyles } from './Beacon.helper';
import { BeaconWelcomePopup } from './BeaconWelcomePopup';
import { useClientType } from '../../../services/hooks/useClientType';
import { AccordionNumbered } from '../../../common/components/AccordionNumbered/AccordionNumbered';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';

export const Beacon: FC = () => {
  const { addToast } = useToasts();
  const [step, setStep] = useState<'copy' | 'paste'>('copy');

  const venue = useVenue();

  const {
    value: FTEValues,
    isLoading: isFTEValuesLoading,
    isUpdating: isFTEValuesUpdating,
  } = useVenueProperty<VenueProperties['webapp.firstTimeOnboardingFinished.config']>({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });
  const showFTEModal = !FTEValues?.webapp?.modal;

  const {
    open: openWelcomeModal,
    close: closeWelcomeModal,
    isOpen: isWelcomeModalOpen,
  } = useModal(showFTEModal, {
    updateOnDefaultValueChange: true,
  });

  const { trackEvent } = useTrackEvent();

  const styles = useStyles({ copied: step === 'paste' });

  const copyCodeBtnRef = useRef<any>(null);
  const copyInstructionBtnRef = useRef<any>(null);

  const { isHealthCare } = useClientType();

  const handleCopy = (ref: any, textToClipboard: string, toastMessage: string, BtnText: string) => {
    if (ref && ref.current) {
      ref.current.style.background = theme.palette.common.lime;
      ref.current.textContent = 'Copied';
    }

    setStep('paste');
    navigator.clipboard.writeText(textToClipboard);
    addToast(toastMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
    setTimeout(() => {
      if (ref && ref.current) ref.current.style.background = theme.palette.primary.main;
      ref.current.textContent = BtnText;
    }, 2000);
  };

  const renderTextWithKeywords = (text: string, keywords: string[]) => {
    const splitted = text.split(' ');
    const finalArray = [];
    for (const word of splitted) {
      if (keywords.find((keyword) => keyword === word)) {
        finalArray.push(<b> {word}</b>);
      } else {
        finalArray.push(` ${word}`);
      }
    }
    return finalArray;
  };

  const initialLoading = (isFTEValuesLoading && !isFTEValuesUpdating) || !venue.isLoaded;

  if (initialLoading) {
    return <></>;
  }

  return (
    <Box className={styles.root}>
      <BeaconWelcomePopup isOpen={isWelcomeModalOpen} toggleModal={closeWelcomeModal} />
      <Grid sm={5} className={styles.wrapper__left}>
        <Typography className={styles.pageTitle} component="h1">
          {BeaconStrings.PageTitle}
        </Typography>
        <Typography className={styles.pageDescription}>
          {isHealthCare ? BeaconStrings.PageDescriptionTrublu : BeaconStrings.PageDescription}
        </Typography>
        <Divider className={styles.divider} />
        <Grid container direction="column" spacing={3} className={styles.hero}>
          <Grid item>
            <AccordionNumbered
              tooltip={BeaconStrings.CopyTooltip}
              number={1}
              title={BeaconStrings.CopyTitle}
              isExpanded={step === 'copy'}
              margin="0px"
              toggleAccordion={() => {
                setStep((prevState) => {
                  const newStep = prevState === 'copy' ? 'paste' : 'copy';
                  trackEvent(WebAppSettingsEvents.TrackingPixelTabChange, { value: newStep });

                  return newStep;
                });
              }}
            >
              <>
                <Typography className={styles.hero__description}>
                  {BeaconStrings.CopyDesc}
                </Typography>
                <Grid className={styles.hero__steps}>
                  <Typography className={styles.hero__code}>{CODE}</Typography>
                  <Button
                    sx={{
                      borderRadius: '10px',
                      marginTop: '4px',
                      /*  background:
                    step === 'copy' ? theme.palette.primary.main : theme.palette.common.lime, */
                    }}
                    className={styles.copyButton}
                    size="large"
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      handleCopy(copyCodeBtnRef, CODE, BeaconStrings.Toast, BeaconStrings.CopyBtn);
                      trackEvent(WebAppSettingsEvents.TrackingPixelCodeCopy);
                    }}
                    ref={copyCodeBtnRef}
                  >
                    {BeaconStrings.CopyBtn}
                  </Button>
                </Grid>
              </>
            </AccordionNumbered>
          </Grid>
          <Grid item>
            <AccordionNumbered
              tooltip={BeaconStrings.PasteTooltip}
              number={2}
              title={BeaconStrings.PasteTitle}
              isExpanded={step === 'paste'}
              margin="0px"
              toggleAccordion={() => {
                setStep((prevState) => {
                  const newStep = prevState === 'copy' ? 'paste' : 'copy';
                  trackEvent(WebAppSettingsEvents.TrackingPixelTabChange, { value: newStep });

                  return newStep;
                });
              }}
            >
              <>
                <Grid>
                  <Typography
                    className={`${styles.hero__description} ${
                      step === 'copy' ? styles.inactive : ''
                    }`}
                  >
                    {BeaconStrings.PasteDesc}
                  </Typography>
                </Grid>
                <Grid className={styles.hero__steps}>
                  <Box sx={{ pl: '10px', pr: '10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '14px' }}>
                      <Typography className={styles.PixeCopyTitle}>
                        {BeaconStrings.PasteTitleDesc}
                      </Typography>
                      <Tooltip
                        padding="23px 25px"
                        rounded="15px"
                        title={BeaconStrings.PasteTooltip}
                        icon={<QuestionMarkRoundedIcon className={styles.tooltip} />}
                      />
                    </Box>
                    <Typography className={styles.PixeCopyDescription}>
                      {BeaconStrings.PasteCopyBlockDesc}
                    </Typography>
                    <Button
                      sx={{
                        borderRadius: '10px',
                        marginTop: '4px',
                      }}
                      className={styles.copyButton}
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        handleCopy(
                          copyInstructionBtnRef,
                          CODE_INSTRUCTION,
                          BeaconStrings.ToastInstruction,
                          BeaconStrings.CopyPixelCodeInstruction,
                        );

                        trackEvent(WebAppSettingsEvents.TrackingPixelInstructionsCopy);
                      }}
                      ref={copyInstructionBtnRef}
                    >
                      {BeaconStrings.CopyPixelCodeInstruction}
                    </Button>
                  </Box>
                </Grid>
              </>
            </AccordionNumbered>
          </Grid>
        </Grid>
      </Grid>
      <Grid sm={7} className={styles.wrapper__right}>
        <Grid container justifyContent="flex-end" mb={6}>
          <IconButton
            style={{
              backgroundColor: '#fff',
              color: '#8B89A0',
              marginTop: '0px',
            }}
            onClick={openWelcomeModal}
          >
            <HelpOutlineRoundedIcon />
          </IconButton>
        </Grid>
        <Grid container direction="column" spacing={2}>
          {Object.entries(instructions).map(([i, el]) => (
            <Grid item className={styles.accordion}>
              <Accordion
                square={false}
                className={styles.accordion}
                // defaultExpanded={i === '1' ? true : false}
                defaultExpanded={true}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className={styles.accordion__title}>{el.title}</Typography>
                </AccordionSummary>
                <Divider sx={{ mb: '20px' }} />
                <AccordionDetails>
                  {el.steps.map((step, i) => (
                    <Grid sx={{ marginBottom: '19px' }}>
                      <Grid container alignItems="center">
                        <Box className={styles.hero__number}>{i + 1}</Box>
                        <Typography>
                          {renderTextWithKeywords(step.text, step.keywords).map((el) => el)}
                        </Typography>
                      </Grid>

                      {el.img && i === el.steps.length - 2 && (
                        <Grid
                          className={styles.shopify}
                          sx={{ position: 'relative', width: '100%', marginTop: '25px' }}
                        >
                          {el.title === 'Shopify' && (
                            <Typography className={styles.shopifyCode}>{CODE}</Typography>
                          )}
                          <img
                            className={`${styles.img} ${
                              el.title === 'Webflow' ? styles.img__webflow : ''
                            }`}
                            src={`/${el.img}`}
                            alt=""
                          />
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};
