import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DistributorsStrings } from '../../../localization/en';
import Select, { SelectOption } from '../../Select/Select';
import { useTypedSelector } from '../../../../store';
import { RoundedButton } from '../../Button/RoundedButton';
import { GbpLocation } from '../../../../store/slices/gbpSlice';
import { useStyles } from './LocationSelect.styles';

interface Props {
  onCancel: () => void;
  onSyncProfile: (location: GbpLocation) => void;
  centerbuttons?: boolean;
}

const LocationSelect: FC<Props> = ({ onCancel, onSyncProfile, centerbuttons }) => {
  const styles = useStyles({ center: !!centerbuttons });

  const { gbpLocations } = useTypedSelector((state) => state.gpbSlice);

  const [selectedLocation, setSelectedLocation] = useState(gbpLocations[0].name.split('/')[1]);

  const locationOptions: SelectOption<string>[] = gbpLocations.map((location) => ({
    value: location.name.split('/')[1],
    name: `${location.title} | ${location.storefrontAddress?.locality} (${location.storeCode})`,
  }));

  const handleLocationChange = (value: string) => {
    setSelectedLocation(value);
  };

  const handleCancel = () => {
    localStorage.setItem('isGbpSyncStarted', String(false));
    onCancel();
  };

  return (
    <>
      <Typography className={styles.title}>{DistributorsStrings.OneLastStep}</Typography>
      <Typography className={styles.subTitle}>
        {DistributorsStrings.ConnectBusinessLocation}
      </Typography>
      <Select
        onChange={handleLocationChange}
        options={locationOptions}
        value={selectedLocation}
        width="500px"
        margin="24px 0"
        fontWeight="500"
        withEllipsis
      />
      <Box className={styles.buttonsWrapper}>
        <RoundedButton
          title={DistributorsStrings.Cancel}
          variant="outlined"
          borderRadius="8px"
          padding="15px"
          fontWeight={600}
          sx={{
            borderColor: '#D0D5DD',
            '& p': {
              color: '#464c71',
            },
          }}
          onClick={handleCancel}
        />
        <RoundedButton
          title={DistributorsStrings.SyncProfile}
          borderRadius="8px"
          padding="15px"
          fontWeight={600}
          onClick={() => {
            onSyncProfile(
              gbpLocations.find((location) => location.name.split('/')[1] === selectedLocation) ||
                gbpLocations[0],
            );
            onCancel();
          }}
        />
      </Box>
    </>
  );
};

export default LocationSelect;
