import { FC } from 'react';

export const CSVIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#E3FCF3" rx="8"></rect>
      <path
        fill="#54D2A5"
        d="M12.744 16.401c-.939 0-1.677.333-2.215.998-.537.666-.806 1.584-.806 2.755 0 1.226.257 2.154.772 2.782.52.63 1.27.944 2.25.944.423 0 .833-.041 1.23-.123.396-.087.809-.196 1.237-.328v1.401c-.784.296-1.673.444-2.666.444-1.463 0-2.586-.442-3.37-1.326C8.392 23.06 8 21.79 8 20.141c0-1.04.19-1.949.567-2.728a4.094 4.094 0 011.655-1.79c.72-.416 1.565-.623 2.536-.623 1.02 0 1.964.214 2.83.643L15 17.003a8.335 8.335 0 00-1.073-.417 3.759 3.759 0 00-1.183-.185zM23.228 22.424c0 .889-.321 1.586-.964 2.092-.642.506-1.529.758-2.66.758-1.13 0-2.055-.175-2.774-.526v-1.545c.455.214.938.383 1.449.506.515.123.993.185 1.435.185.647 0 1.124-.123 1.429-.37.31-.246.465-.576.465-.99 0-.374-.142-.691-.424-.951-.283-.26-.866-.567-1.75-.923-.912-.369-1.554-.79-1.928-1.265-.373-.473-.56-1.043-.56-1.709 0-.834.296-1.49.888-1.968.593-.479 1.388-.718 2.386-.718.957 0 1.91.21 2.858.629l-.52 1.333c-.889-.374-1.682-.56-2.379-.56-.528 0-.93.116-1.203.348-.273.228-.41.53-.41.91 0 .259.055.482.164.67.11.181.29.355.54.519.25.164.702.38 1.353.649.734.305 1.272.59 1.614.854.341.265.592.563.752.896.16.333.239.725.239 1.176zM30.957 15.144h1.695l-3.5 9.994H27.43l-3.486-9.994h1.682l2.085 6.213c.11.297.225.68.348 1.149.123.465.203.811.24 1.039.059-.346.15-.748.273-1.203.123-.456.223-.793.3-1.012l2.086-6.186z"
      ></path>
    </svg>
  );
};
