import { FC } from 'react';
import { Button } from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useToasts } from 'react-toast-notifications';

import { sendInvites } from '../../../../store/slices/heroesSlice';
import { useTypedSelector } from '../../../../store';
import { CommonStrings, TeamSettingsStrings } from '../../../../common/localization/en';

export const InviteAllButton: FC = () => {
  const { addToast } = useToasts();
  const { selectedVenueId: venueId, isWebAppAvailable } = useTypedSelector((state) => state.venue);

  const handleClick = async () => {
    if (venueId) {
      const response = await sendInvites({ venueId });
      if (response.success) {
        addToast(CommonStrings.SuccessInvite, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      } else {
        addToast(CommonStrings.ErrorInvite, { appearance: 'error' });
      }
    }
  };

  return (
    <>
      <Button
        style={{ padding: '13px 0' }}
        disabled={!venueId || !isWebAppAvailable}
        onClick={handleClick}
        color="primary"
        startIcon={<PostAddIcon />}
      >
        {TeamSettingsStrings.InviteMembers}
      </Button>
    </>
  );
};
