import styled from 'styled-components';
import { theme } from '../../../theme';

export const IndicatorWrapper = styled.div`
  width: 204px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  box-shadow: 0px 10px 40px -6px rgba(10, 25, 107, 0.15);
  padding: 0 16px;
  &.active-i {
    color: ${theme.palette.success.dark};
  }
  &.inactive-i {
    color: ${theme.palette.text.primary};
  }
  &.archived-i {
    color: ${theme.palette.secondary.dark};
  }
  &.all-i {
    color: ${theme.palette.primary.main};
  }
  &.small-i {
    width: auto;
    border: none;
    background-color: transparent;
    padding: 0;
  }
`;

export const Indicator = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  &.active-i {
    background-color: ${theme.palette.success.main};
  }
  &.inactive-i {
    background-color: ${theme.palette.grey[300]};
  }
  &.archived-i {
    background-color: ${theme.palette.secondary.main};
  }
  &.all-i {
    background-color: ${theme.palette.primary.main};
  }
`;
