import ClearIcon from '@mui/icons-material/Clear';
import { Box, Chip, Typography } from '@mui/material';
import { FC, forwardRef } from 'react';
import { animated, Transition } from 'react-spring';

import { theme } from '../../theme';
import { KeywordItem } from './Keywords.helper';

const OUTLINE_COLOR = '#DCDBE4';

interface ChipLabelProps {
  title: string;
  withIcon?: boolean;
}
const ChipLabel: FC<ChipLabelProps> = ({ title, withIcon }) => {
  return (
    <Typography
      color={theme.palette.common.deepDark}
      fontSize="12px"
      fontWeight="500"
      style={{ marginLeft: withIcon ? '-4px' : undefined }}
    >
      {title}
    </Typography>
  );
};

interface KeywordsPreviewProps {
  keywords: Array<KeywordItem>;
  onDelete?: (keywordItem: KeywordItem) => void;
  borderless?: boolean;
  minHeight?: string;
  maxHeight?: string;
}

export const KeywordsPreview = forwardRef<HTMLDivElement, KeywordsPreviewProps>(
  ({ keywords, onDelete, borderless = false, minHeight, maxHeight }, ref) => {
    return (
      <Box
        ref={ref}
        px={borderless ? 0 : '16px'}
        py={borderless ? 0 : '10px'}
        border={borderless ? 'none' : `1px solid ${OUTLINE_COLOR}`}
        borderRadius="10px"
        minHeight={minHeight || (borderless ? 'auto' : '64px')}
        maxHeight={maxHeight}
        sx={{ overflowY: 'auto', scrollBehavior: 'smooth' }}
      >
        <Transition
          native
          items={keywords.filter((keyword) => !keyword.disabled)}
          keys={keywords.map((item) => item.keyword)}
          from={{ opacity: 0 }}
          enter={{ opacity: 1 }}
          trail={400 / keywords.length}
        >
          {(style, item) => {
            if (!item) return null;
            return (
              <animated.div
                style={{
                  ...style,
                  display: 'inline-block',
                }}
              >
                <Box display="inline-block" m="5px">
                  <Chip
                    variant="outlined"
                    // icon={icon ? <Emoji code={icon} width="26px" /> : undefined}
                    icon={<Box sx={{ w: '260px' }} />}
                    label={<ChipLabel title={item.keyword} withIcon={!!item.icon} />}
                    deleteIcon={
                      onDelete ? (
                        <ClearIcon
                          style={{ color: theme.palette.common.deepDark, height: '14px' }}
                        />
                      ) : (
                        <></>
                      )
                    }
                    onDelete={() => onDelete?.(item)}
                    disabled={item.disabled}
                    sx={{ pl: '6px' }}
                  />
                </Box>
              </animated.div>
            );
          }}
        </Transition>
      </Box>
    );
  },
);
