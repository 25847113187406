import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

import { Tooltip } from '../../../../../../../common/components/Tooltip/Tooltip';
import { useStyles } from './PreparedInvites.styles';
import { RoundedButton } from '../../../../../../../common/components/Button/RoundedButton';
import { SendInvitesStrings } from '../../../../../../../common/localization/en';
import { CancelInvitesModal } from '../CancelInvitesModal/CancelInvitesModal';
import { useModal } from '../../../../../../../hooks/useModal';
import { ReviewInvitesModal } from './ReviewInvitesModal/ReviewInvitesModal';

interface PreparedInvitesProps {
  preparedInvites: number;
  availableCustomers: number;
  onInvitesDelete?: () => void;
}

export const PreparedInvites: React.FC<PreparedInvitesProps> = ({
  preparedInvites,
  availableCustomers,
  onInvitesDelete,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const {
    isOpen: isCancelInvitesModalOpen,
    open: openCancelInvitesModal,
    close: closeCancelInvitesModal,
  } = useModal();

  const {
    isOpen: isReviewInvitesModalOpen,
    open: openReviewInvitesModal,
    close: closeReviewInvitesModal,
  } = useModal();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CancelInvitesModal
        closeModal={closeCancelInvitesModal}
        isOpenModal={isCancelInvitesModalOpen}
        onInvitesDelete={onInvitesDelete}
      />
      <ReviewInvitesModal onClose={closeReviewInvitesModal} isOpen={isReviewInvitesModalOpen} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '20px',
          fontFamily: 'Inter',
          padding: '24px',
          width: '350px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '14px' }}>
          <Typography
            sx={{
              color: '#444E50',
              fontWeight: '500',
              fontSize: '28px',
              fontFamily: 'Inter',
            }}
          >
            Prepared Invites
          </Typography>

          <Tooltip
            maxWidth="250px"
            content={
              <>
                Invites will be sent to these available customers, starting with the most recent
                transaction date.
              </>
            }
          />
        </Box>
        <Typography
          sx={{
            fontSize: '68px',
            lineHeight: '68px',
            fontWeight: '800',
            color: theme.palette.primary.main,
            fontFamily: 'Inter',
          }}
        >
          {preparedInvites}
        </Typography>
        <Box className={classes.buttonsWrapper}>
          <RoundedButton
            className={classes.actionButton}
            title={SendInvitesStrings.ReviewCustomers}
            textVariant={true}
            onClick={openReviewInvitesModal}
          />
          <RoundedButton
            className={classes.actionButton}
            title={SendInvitesStrings.CancelInvites}
            textVariant={true}
            onClick={openCancelInvitesModal}
          />
        </Box>
      </Box>
    </Box>
  );
};
