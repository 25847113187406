import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { theme } from '../../../theme';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStylesCardBlock = makeStyles((theme: Theme) => ({
  SectionTitle: {
    marginBottom: '14px',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.32px',
    color: theme.palette.common.deepDark,
  },
  uploadLogoTitle: {
    color: '#1A1538',
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '10px',
    marginLeft: '10px',
    letterSpacing: '0.32px',
    lineHeight: '28px',
  },
  mentionIncentiveWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '-68px',
  },
  formControlWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconWrapper: {
    '& > button': {
      marginRight: 8,
      padding: 0,
    },
  },
  dndTitle: {
    fontSize: '11px',
    color: '#475467',
    fontWeight: 500,
    width: '90px',
  },
  formControllabel: {
    marginRight: 0,
  },
  container: {
    width: '100%',
  },
  themeText: {
    color: '#1A1538',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.32px',
    marginLeft: '10px',
    marginBottom: '15px',
  },
  changeLogoButton: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.02em',
    borderRadius: '40px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    border: '1px solid transparent !important',
    '&:hover': {
      border: `1px solid ${theme.palette.primary.light} !important`,
    },
  },
  formContainer: {
    width: '100%',
  },
  labelText: {
    paddingBottom: 10,
    marginTop: 15,
    color: theme.palette.common.deepDark,
  },
  label: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
  },
  textWrapper: {
    paddingLeft: 25,
  },
  title: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    color: theme.palette.common.deepDark,
    padding: '0px',
  },
  text: {
    width: '165px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    color: theme.palette.common.gray,
    letterSpacing: '0.02em',
  },
  sectondText: {
    marginTop: '5px',
  },
  imageContainer: {
    flexDirection: 'row',
  },
  imageWrapper: {
    maxWidth: '100px !important',
    height: '178px !important',
  },
  imageWrapperTopGolf: {
    maxWidth: '100px !important',
    height: '100px !important',
  },
  DisableAccordion: {
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },
  },
}));

export const SectionTitle = styled.h2`
  margin-bottom: 16px;
  color: var(--primaryTextColor);
  font-size: 20px;
  font-weight: 600;
  line-height: 1.6;
`;

export const SectionSubtitle = styled.h3`
  color: var(--secondaryColor);
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 143%;
`;

export const TextSpan = styled.span`
  display: inline-block;
`;

export const StyledTextField = styled(TextField)`
  margin: 10px;
  width: 100%;
`;

export const SectionDivider = styled.div`
  width: calc(100% + 48px);
  border-bottom: 2px solid #eeeef6;
  margin-left: -24px;
  margin-top: 16px;
`;

export const SaveButton = styled(Button)`
  color: var(--successDark);
  background-color: var(--successLight);
`;

export const EditButton = styled(Button)`
  color: #2d5af0;
  background-color: #dbefff;
`;

export const AddnewWrapper = styled.div`
  margin: 30px;
`;

export const HelperText = styled.span`
  display: block;
  color: var(--secondaryColor);
  font-size: 10px;
  line-height: 14px;
`;

export const ImageBlock = styled.img`
  height: 320px;
  margin-bottom: 24px;
  border-radius: 20px;
`;

export const PicturedInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
`;

export const InputWrapper = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 190px;
  height: 320px;
  margin-bottom: 20px;
  padding: 24px;
  background-color: #f0f7ff;
  background-image: url('/input-background.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: calc(100% - 48px) auto;
  border-radius: 20px;
  cursor: pointer;

  &.no-bg-uploader {
    background-image: none;
  }
`;

export const ItemWrapper = styled.div`
  flex-grow: 1;
  max-width: 190px;
`;

export const WebCardSize = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.palette.secondary.contrastText};
  white-space: nowrap;
`;

export const ItemTypeTitle = styled.h3`
  margin: 20px;
  color: var(--primaryTextColor);
  font-size: 16px;
  font-weight: 600;
  line-height: 1.57;
`;
