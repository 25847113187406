import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoNotificationsStyles = makeStyles((theme: Theme) => ({
  toggle: {
    '&.MuiSwitch-root': {
      height: '34px',
      width: '54px',
    },
    '& .MuiSwitch-thumb': {
      height: '28px !important',
      width: '28px !important',
    },
    '& .Mui-checked': {
      transform: 'translateX(18px) !important',
    },
    '& .MuiSwitch-switchBase': {
      padding: '3px 0 0 3px',
    },
  },
  Text: {
    fontWeight: 400,
    color: theme.palette.common.black,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  Description: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.02em',
  },
}));
