import { FC } from 'react';
import clsx from 'clsx';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

import { useStyles } from './UserInfoBlock.helper';
import { NumberOfStoriesIcon } from '../../../../assets/NumberOfStoriesIcon';
import { UserApiModel } from '../../../../../api/models/users';
import {
  getLocalDateString,
  getLocaleNumberValue,
  formatPhoneNumber,
} from '../../../../../services/utilities';
import { HeroesDetails } from '../../../../localization/en';

interface UserInfoBlockProps {
  userData: UserApiModel;
}

export const UserInfoBlock: FC<UserInfoBlockProps> = ({ userData }) => {
  const classes = useStyles();

  const avatar = userData.photoUrl || undefined;

  const renderInfoBlock = (block: string, value: number) => {
    let icon = <NumberOfStoriesIcon />;
    // switch (block) {
    // case HeroesPageStrings.Followers: {
    //   icon = <FollowersIcon />;
    //   break;
    // }
    // case HeroesPageStrings.Following: {
    //   icon = <FollowingIcon />;
    //   break;
    // }
    //   case HeroesPageStrings.NumberOfStories: {
    //     icon = <NumberOfStoriesIcon /> ;
    //     break;
    //   }
    //   default: {
    //     icon = <FollowersIcon />;
    //   }
    // }
    return (
      <Box className={classes.infoBlockContainer}>
        <Box className={classes.iconContainer}>{icon}</Box>
        <Typography className={classes.infoBlockName}>{block}</Typography>
        <Typography variant="subtitle2">{getLocaleNumberValue(value)}</Typography>
      </Box>
    );
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Box className={classes.avatarRow}>
          <Avatar variant="rounded" className={classes.avatar} src={avatar} />
          {renderInfoBlock(HeroesDetails.Block1, userData.videos)}
        </Box>
      </Grid>
      <Grid item>
        <Grid container className={classes.textInfo}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" mb={2}>
              <Typography className={clsx([classes.text, classes.secondaryTextColor])}>
                {HeroesDetails.Data1}
              </Typography>
              <Typography className={clsx([classes.text, classes.primaryTextColor])}>
                {userData.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" mb={2}>
              <Typography className={clsx([classes.text, classes.secondaryTextColor])}>
                {HeroesDetails.Data4}
              </Typography>
              <Typography className={clsx([classes.text, classes.primaryTextColor])}>
                {getLocalDateString(userData.createdAt, '/')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" mb={2}>
              <Typography className={clsx([classes.text, classes.secondaryTextColor])}>
                {HeroesDetails.Data3}
              </Typography>
              <Typography className={clsx([classes.text, classes.primaryTextColor])}>
                {formatPhoneNumber(userData.phoneNumber) || '-'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex" mb={2}>
              <Typography className={clsx([classes.text, classes.secondaryTextColor])}>
                {HeroesDetails.Data5}
              </Typography>
              <Typography className={clsx([classes.text, classes.primaryTextColor])}>
                {getLocaleNumberValue(userData.rewardsFulfilled)}
              </Typography>
            </Box>
          </Grid>
          {userData.email && (
            <Grid item xs={12} sm={6}>
              <Box display="flex" mb={2}>
                <EmailIcon color="disabled" />
                <Box ml={2} />
                <Typography className={clsx([classes.text, classes.primaryTextColor])}>
                  {userData.email}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
