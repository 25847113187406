import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 16px',
    borderRadius: '60px',
    letterSpacing: '0.02em',
    width: '217px',
    height: '40px',
    fontSize: '14px',
    fontWeight: 400,

    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  disabled: {
    backgroundColor: `${theme.palette.common.slightlyBlueish} !important`,
  },
}));
