import { FormikProps } from 'formik';
import { FormikValues } from '../../SocialVerseSettingsPage';
import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { SocialVerseStrings } from '../../../../../common/localization/en';
import previewImage from '../../../../../common/assets/newDesign/SocialVerse/previews/spherePreview.png';
import { PlayIcon } from '../../../../../common/assets/newDesign/VideoControlsIcons/PlayIcon';
import { useStyles } from './SpheresPreview.styles';

interface SpheresPreviewProps {
  userInfo: string;
  getSphereBoxShadow: () => string;
  formik: FormikProps<FormikValues>;
}

export const SpheresPreviewDeskop: FC<SpheresPreviewProps> = ({
  userInfo,
  formik,
  getSphereBoxShadow,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{SocialVerseStrings.PassiveStateExample}</Typography>
      <Typography className={styles.title}>{SocialVerseStrings.HoverStateExample}</Typography>
      <Box
        className={styles.passiveSphere}
        style={{
          boxShadow: getSphereBoxShadow(),
        }}
      >
        <Box className={`${styles.playIcon} ${styles.smallIcon}`}>
          <PlayIcon width={25} height={25} />
        </Box>
        <img src={previewImage} alt="sphere" />
      </Box>
      <Box
        className={styles.hoverSphere}
        style={{
          boxShadow: getSphereBoxShadow(),
        }}
      >
        {formik.values.showUserInfo && (
          <Typography className={styles.userInfo}>{userInfo}</Typography>
        )}
        <img src={previewImage} alt="preview card" />
      </Box>
    </Box>
  );
};

export const SpheresPreviewMobile: FC<SpheresPreviewProps> = ({
  userInfo,
  formik,
  getSphereBoxShadow,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.mobileContainer}>
      <Typography className={styles.title} sx={{ marginBottom: '10px' }}>
        {SocialVerseStrings.PassiveStateExample}
      </Typography>
      <Box
        className={styles.passiveSphere}
        style={{
          boxShadow: getSphereBoxShadow(),
        }}
      >
        <Box className={`${styles.playIcon} ${styles.smallIcon}`}>
          <PlayIcon width={25} height={25} />
        </Box>
        <img src={previewImage} alt="sphere" />
      </Box>
      <Typography className={styles.title} sx={{ margin: '25px 0 10px 0' }}>
        {SocialVerseStrings.HoverStateExample}
      </Typography>
      <Box
        className={styles.hoverSphere}
        style={{
          boxShadow: getSphereBoxShadow(),
        }}
      >
        {formik.values.showUserInfo && (
          <Typography className={styles.userInfo}>{userInfo}</Typography>
        )}
        <img src={previewImage} alt="preview card" />
      </Box>
    </Box>
  );
};
