import { AccountsDTO, ETSCampaignData } from '../../api/models/venueCreation';

export interface ValidationSchemaType {
  0: Function;
  1: Function;
  2: Function;
  3: Function;
}

export interface NewRewardSchema {
  image: string;
  name: string;
}
export interface FilterProps {
  url: string;
  isActive: boolean;
}
export interface RewardsProps {
  rewardStepState: NewRewardSchema;
  setRewardsStepState: (value: React.SetStateAction<NewRewardSchema>) => void;
}

export interface AccountSetingsProps extends AccountsDTO {
  password: string;
  submited: boolean;
}

interface mapProgramDataSchema {
  step1State: AccountSetingsProps[];
  filterStepState: FilterProps[];
  rewardStepState: NewRewardSchema;
  subdomain: string;
  program_data: ETSCampaignData;
}
export const mapProgramDataToVenueCreationOptions = (data: mapProgramDataSchema) => {
  return {
    name: data.program_data.programName,
    subdomain: data.subdomain.toLowerCase(),
    logoUrl: data.program_data.campaignLogoUrl,
    primaryColor: data.program_data.campaignPrimaryColorHex,
    secondaryColor: data.program_data.campaignSecondaryColorHex,
    refId: data.program_data.programId,
    reward: {
      name: data.rewardStepState.name,
      imageUrl: data.rewardStepState.image,
    },
    videoCategory: {
      refId: data.program_data.campaignId,
      name: data.program_data.campaignName,
      returnUrl: data.program_data.defaultCampaignLandingUrl,
      startDate: data.program_data.campaignStartDate,
    },
    videoFilters: data.filterStepState,
    managerAccounts: data.step1State.map((item) => {
      return {
        email: item.userAccountEmail,
        name: item.userAccountName,
        refId: item.userAccountId,
        role: item.userAccountRoleName,
        password: item.password,
      };
    }),
    users: data.program_data.participants.map((item) => {
      return {
        email: item.participantEmail,
        name: item.participantName,
        refId: item.participantId,
        phoneNumber: item.participantPhone,
        returnUrl: item.participantDonorPageUrl,
      };
    }),
  };
};
