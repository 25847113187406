import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
/* import { statItemsIdType } from './FilterItems/VideoStatsFilter'; */

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignContent: 'center',
    backgroundColor: '#F5F6F8',
    borderRadius: '12px',
    padding: '5px 0px',
    height: '52px',
  },
  icon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputsRoot: {
    display: 'flex',
  },
  input: {
    background: '#EDEDEE',
    width: '50px',
    height: '40px',
    borderRadius: '12px',
    padding: '0px',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
interface InlineStatItemProps {
  item: { id: string; title: string; icon: JSX.Element };
  handleChangeValues: (
    id: string /* statItemsIdType */,
    newValue: { min: number; max: number },
  ) => void;
  value: { min: number; max: number };
}
export const InLineStatItem: FC<InlineStatItemProps> = ({ item, handleChangeValues, value }) => {
  const classes = useStyles();
  const handleChange = (e: { target: { value: string; name: string } }) => {
    handleChangeValues(item.id /*  as statItemsIdType */, {
      ...value,
      [e.target.name]: parseInt(e.target.value),
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.labelRoot}>
        <span className={classes.icon}>{item.icon}</span>
        <span className={classes.label}>{item.title}</span>
      </div>
      <div className={classes.inputsRoot}>
        <TextField
          className={classes.input}
          name="min"
          id="min"
          variant="outlined"
          type="number"
          value={value.min}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <span className={classes.label}>{'to'}</span>
        <TextField
          className={classes.input}
          name="max"
          id="max"
          variant="outlined"
          type="number"
          value={value.max}
          onChange={handleChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};
