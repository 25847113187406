import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTranscriptStyles = makeStyles((theme: Theme) => ({
  TranscriptContainer: {
    borderRadius: '12px',
    padding: '14px 20px 14px 0px',
    margin: '0px',
    marginBottom: 30,
  },
  TranscriptTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '8px',
    borderBottom: '1px solid #DCDBE4',
    alignItems: 'center',
  },
  TranscriptTitle: {
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.02em',
    lineHeight: '28px',
    color: theme.palette.common.black,
  },
  TranscriptScore: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.primary.main,
    borderRadius: '16px',
    background: theme.palette.primary.light,
    padding: '3.5px 10px',
    display: 'flex',
    alignItems: 'center',
  },
  TranscriptScoreBold: {
    fontWeight: 600,
    fontSize: '16px',
  },
  TranscriptScoreValue: {
    marginLeft: 6,
    fontWeight: 500,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  TranscriptText: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  HighlightTag: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontWeight: '500',
    fontStyle: 'inherit',
  },
}));
