import { FC } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { UpdatePointsModalStrings } from '../../../common/localization/en';

interface UpdatePointsModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    borderRadius: 16,
    padding: '40px  30px 30px',
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 18,
    textAlign: 'center',
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.02em',
    color: theme.palette.common.gray,
    marginBottom: 24,
    textAlign: 'center',
  },
}));

export const UpdatePointsModal: FC<UpdatePointsModalProps> = ({ isOpen, onConfirm, onCancel }) => {
  const styles = useStyles();

  return (
    <>
      <Modal
        open={isOpen}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        onClose={onCancel}
      >
        <Box className={styles.Modal}>
          <Typography className={styles.ModalTitle}>
            {UpdatePointsModalStrings.ModalTitle}
          </Typography>
          <Typography className={styles.ModalText}>{UpdatePointsModalStrings.ModalText}</Typography>
          <Box display="flex" justifyContent="center" gap="10px">
            <RoundedButton
              title={UpdatePointsModalStrings.Cancel}
              onClick={onCancel}
              outlined
              fontSize="12px"
            />
            <RoundedButton
              title={UpdatePointsModalStrings.Update}
              onClick={onConfirm}
              fontSize="12px"
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
