import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  circularProgressClasses,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './ReviewAndFinalize.helper';
import { useImportValidator } from './useImportValidator';
import { ImportRecord } from '../../../../pages/rewards/CampaignSummary/Modals/ImportFileModal';
import { useAppDispatch } from '../../../../store';
import { createInvitationBatch } from '../../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';
import { ImportInvalidRecordsButton } from './components/ImportInvalidRecordsButton/ImportInvalidRecordsButton';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../../constants/events/common';
import { useVenueProperty } from '../../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../../api/models/venue';

interface ReviewAndFinalizeProps {
  records: ImportRecord[];
  campaignId: string;
  onStartOverClick: () => void;
  onImportFinished: () => void;
}

export const ReviewAndFinalize: FC<ReviewAndFinalizeProps> = ({
  records,
  campaignId,
  onStartOverClick,
  onImportFinished,
}) => {
  const styles = useStyles();
  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();

  const {
    invalidRecords,
    validRecords,
    isLoading: isValidating,
    validateRecords,
  } = useImportValidator({
    campaignId,
  });

  useEffect(() => {
    validateRecords(records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addToast } = useToasts();

  const [isImporting, setIsImporting] = useState(false);
  const { value: inviteStatsViewed, update } = useVenueProperty<
    VenueProperties['dashboard.invite-stats-viewed']
  >({
    property: 'dashboard.invite-stats-viewed',
  });

  const importRecords = () => {
    trackEvent(CommonEventNames.data_sync_import_confirm_click);

    if (!validRecords) return;

    if (inviteStatsViewed) {
      update(inviteStatsViewed.filter((id) => id !== campaignId));
    }

    setIsImporting(true);

    const groupName = `${new Date().toDateString()} import`;

    dispatch(
      createInvitationBatch({
        campaignId,
        invitations: validRecords,
        groupName,
        sendImmediately: false,
      }),
    )
      .catch(() => {
        addToast('Something went wrong', { appearance: 'error' });
      })
      .finally(() => {
        onImportFinished();
        setIsImporting(false);
      });
  };

  if (isValidating) {
    return (
      <Box padding="174px 44px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="15px 19px"
          border="1px solid #EAECF0"
          borderRadius="9px"
        >
          <CircularProgress
            size="50px"
            sx={{
              mb: '16px',
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round',
              },
            }}
          />
          <Typography fontWeight="500" fontSize="18px" color="#344054">
            Verifying Uploaded data...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles.root}>
      <Divider color="#DCDBE4" />
      <Box pt="20px">
        <Stack direction="row" spacing="20px" px="43px">
          {validRecords.length > 0 && (
            <Box className={styles.invitationsInfo}>
              <Typography className={styles.invitationsInfoText}>Valid Customers</Typography>
              <Typography className={styles.invitationsInfoNumber}>
                {validRecords.length}
              </Typography>
            </Box>
          )}
          {invalidRecords.length > 0 && (
            <Box className={styles.invitationsInfoError}>
              <Typography className={styles.invitationsInfoText}>Invalid Customers</Typography>
              <Box display="flex" alignItems="center">
                <Typography className={styles.invitationsInfoNumber}>
                  {invalidRecords.length}
                </Typography>
                <Box ml="8px">
                  <ImportInvalidRecordsButton invalidRecords={invalidRecords} />
                </Box>
              </Box>
              <Typography className={styles.invitationsInfoTextError}>
                Duplicate/missing/unformatted data
              </Typography>
            </Box>
          )}
        </Stack>

        <Divider color="#DCDBE4" style={{ marginTop: '40px', marginBottom: '14px' }} />

        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems={'center'}
          mt="10px"
          padding="0 44px"
        >
          <Button
            disabled={isImporting}
            fullWidth
            style={{ height: '45px', marginRight: '20px' }}
            variant="outlined"
            onClick={() => {
              trackEvent(CommonEventNames.data_sync_start_over_click);
              onStartOverClick();
            }}
            size="large"
          >
            Start Over
          </Button>
          <Button
            disabled={validRecords.length === 0 || isImporting}
            fullWidth
            style={{ height: '45px' }}
            variant="contained"
            onClick={importRecords}
            size="large"
            id="import-data-button"
          >
            Add Customers
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
