import React, { useRef, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import clsx from 'clsx';

import { useStyles } from './VideoCardNew.helper';
import { StatusDropdown } from '../../../StatusDropdown/StatusDropdown';
import { FavoriteButton } from '../../../favoriteButton/FavoriteButton';
import { getPSTDateString } from '../../../../../services/utilities';
import { TopGolfVideosApiModel } from '../../../../../api/models/topgolfVideos';
import { VideoPlaybackModal } from '../VideoPlaybackModal';
import { useTypedSelector } from '../../../../../store';
import { ClientTypes } from '../../../../../api/models/common';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../../../constants/events/topGolfEvents';

interface VideoCardProps {
  video: TopGolfVideosApiModel;
  handleOpenConfirmationModal?: (id: string) => void;
}
export const TopGolfVideoCard: React.FC<VideoCardProps> = ({
  video,
  handleOpenConfirmationModal,
}) => {
  const classes = useStyles();
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const { trackEvent } = useTrackEvent();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const [isOpenPlaybackModal, setIsOpenPlaybaclModal] = useState(false);
  const handleOpenPlayVideoModal = () => {
    setIsOpenPlaybaclModal(!isOpenPlaybackModal);
    setIsPlaying(true);
    trackEvent(topGolfEvents.top_golf_video_slide_out_video_play, {
      videoId: video.id,
    });
  };
  const handleClose = () => {
    setIsOpenPlaybaclModal(false);
    setIsPlaying(false);
    trackEvent(topGolfEvents.top_golf_video_slide_out_video_modal_close, {
      videoId: video.id,
    });
  };

  const isTopgolf = clientType === ClientTypes.TOPGOLF;

  return (
    <Card className={classes.topgolfRoot}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          component="video"
          ref={refVideo}
          className={classes.topgolfVideo}
          src={video.url}
          onClick={handleOpenPlayVideoModal}
        />
        {!isPlaying && (
          <Box fontSize={40} className={classes.icon}>
            <PlayCircleOutlineIcon fontSize="inherit" color="inherit" />
          </Box>
        )}
        {handleOpenConfirmationModal && (
          <Box className={classes.favouriteButton}>
            <FavoriteButton
              checked={video.featured}
              id={video.id}
              handleOpenConfirmationModal={handleOpenConfirmationModal}
            />
          </Box>
        )}
      </CardActionArea>
      <CardContent className={classes.contentCard}>
        <Box className={classes.box} paddingX={2}>
          <Typography className={clsx([classes.smallText, classes.smallTextSecondary])}>
            Created
          </Typography>
          <Box mr={2} />
          <Typography className={clsx([classes.smallText, classes.smallTextPrimary])}>
            {getPSTDateString(video.shotTime)}
          </Typography>
          <Box mr={2} />
          {/* <Typography className={classes.public} color="primary">
            {story.isPublic ? VideCardStrings.Public : VideCardStrings.Private}
          </Typography> */}
        </Box>
        <StatusDropdown
          id={video.id}
          TG
          publishedToWebapp={video.publishedToWebapp ? 'published' : 'unpublished'}
          webAppStatus
        />
      </CardContent>
      <VideoPlaybackModal
        isTopGolf={isTopgolf}
        isOpen={isOpenPlaybackModal}
        onClose={handleClose}
        videoUrl={video.url}
        videoId={video.id}
        thumbnailUrl={video.thumbnailUrl}
      />
    </Card>
  );
};
