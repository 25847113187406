import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Toggle } from '../../../common/components/toggle/CustomToggle';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
interface FieldSettingProps {
  title: string;
  description: string;
  tooltip?: string;
  value: boolean;
  disableToggle?: boolean;
  hideToggle?: boolean;
  onChange?: (value: boolean) => void;
}
export const FieldSetting: FC<FieldSettingProps> = ({
  title,
  description,
  tooltip,
  value,
  disableToggle,
  hideToggle,
  onChange,
}) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Box display="flex" justifyItems="center" alignItems="center">
        {!hideToggle && (
          <Toggle value={value} onChange={(v) => onChange?.(v)} disabled={disableToggle} />
        )}
        <Typography fontSize="18px" color={theme.palette.common.deepDark} fontWeight="500">
          {title}
        </Typography>
        {tooltip && <Tooltip title={tooltip} />}
      </Box>
      <Typography
        fontSize="14px"
        color={theme.palette.common.gray}
        sx={{ letterSpacing: '0.02em' }}
      >
        {description}
      </Typography>
    </Box>
  );
};
