import { theme } from '../../../../theme';

export const LogoModalIcon = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4203_87879"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="70"
    >
      <circle cx="35" cy="35" r="35" fill="#EEECFC" />
    </mask>
    <g mask="url(#mask0_4203_87879)">
      <circle cx="35" cy="35" r="35" fill="#EEECFC" />
      <path
        d="M0 42.5L69.9999 42.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 57.5L69.9999 57.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 27.5L69.9999 27.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 12.5L69.9999 12.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="15"
        y="20"
        width="40"
        height="30.66"
        rx="2"
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="18" y="29.332" width="34" height="17.96" fill="white" />
      <path
        d="M29.5946 36.3107C30.0661 34.9917 30.9935 33.8846 32.2094 33.1891C33.4254 32.4937 34.8498 32.2557 36.2258 32.518C37.6018 32.7804 38.8387 33.5259 39.7134 34.62C40.5882 35.714 41.0431 37.0847 40.9961 38.4847C40.9492 39.8847 40.4035 41.2218 39.4574 42.2549C38.5114 43.2879 37.2273 43.9488 35.8368 44.1184C34.4464 44.288 33.0411 43.9551 31.8745 43.1798C30.7078 42.4045 29.8567 41.2377 29.4746 39.8901"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.7807 35.7959L29.581 36.3379L29.0391 34.1445"
        stroke="black"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0752 37.8808C37.4085 38.0732 37.4085 38.5543 37.0752 38.7468L34.3369 40.3277C34.0036 40.5202 33.5869 40.2796 33.5869 39.8947L33.5869 36.7329C33.5869 36.348 34.0036 36.1074 34.3369 36.2998L37.0752 37.8808Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M15 22C15 20.8954 15.8954 20 17 20H53C54.1046 20 55 20.8954 55 22V25.9749H15V22Z"
        fill="white"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="18.2826" cy="23.0814" r="0.64" fill="#1A1538" />
      <circle cx="20.6302" cy="23.0814" r="0.64" fill="#1A1538" />
      <circle cx="22.9759" cy="23.0814" r="0.64" fill="#1A1538" />
    </g>
  </svg>
);
