import styled from 'styled-components';

import { theme } from '../../../theme';

export const CodeStyled = styled.pre`
  color: #fff;
`;

export const FormCtaStyled = styled.form`
  max-width: 524px;
`;

export const ListStyled = styled.ul``;

export const ContentStyled = styled.div`
  max-width: 1024px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 1rem;
  }

  ul {
    margin-top: 1.5rem;

    li {
      display: flex;
      margin-bottom: 1rem;

      &::before {
        content: '';
        flex: 0 0 auto;
        width: 16px;
        height: 16px;
        margin-top: 2px;
        margin-right: 8px;
        background-color: ${theme.palette.primary.main};
        background-clip: content-box;
        border: 4px solid rgba(45, 90, 240, 0.2);
        border-radius: 50%;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

  pre {
    padding: 1rem;
    background-color: #101634;
    border-radius: 20px;
    color: #fff;
  }

  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;

    th,
    td {
      padding: 0.75rem;
      border-top: 1px solid #dee2e6;
      vertical-align: top;
    }

    th {
      border: none;
      vertical-align: bottom;
      white-space: nowrap;
    }
  }

  @media (max-width: 767px) {
    .table-wrap {
      overflow-x: auto;
    }
  }

  thead {
    font-size: 10px;
    font-weight: bold;
    color: var(--textGrey);
  }
`;
