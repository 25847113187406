import React, { FC, useRef } from 'react';
import { useVideoControls } from '../../../../../../common/components/VideoPlayer/PlayerControls.helper';
import { Box } from '@mui/material';
import { useStyles } from './RecordVideo.styles';
import { PlayArrowRounded } from '@mui/icons-material';
import { MuteVolumeIconDark } from '../../../../../../common/assets/newDesign/VideoControlsIcons/MuteVolumeIconDark';
import { MutedVolumeIconDark } from '../../../../../../common/assets/newDesign/VideoControlsIcons/MutedVolumeIconDark';

interface Props {
  src: string;
}

const RecordedPreview: FC<Props> = ({ src }) => {
  const styles = useStyles();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { playerState, toggleMute, togglePlayer, setPlayerState } = useVideoControls(
    videoRef,
    false,
  );

  return (
    <Box className={styles.videoPreviewWrapper}>
      <video
        ref={videoRef}
        src={src}
        onClick={togglePlayer}
        onEnded={() => setPlayerState({ ...playerState, isPlaying: false })}
      />
      {!playerState.isPlaying && (
        <Box fontSize={45} className={styles.playIcon}>
          <PlayArrowRounded fontSize="inherit" color="inherit" />
        </Box>
      )}
      {playerState.isPlaying && (
        <Box fontSize={45} className={styles.muteIcon} onClick={toggleMute}>
          {playerState.isMuted ? <MutedVolumeIconDark /> : <MuteVolumeIconDark />}
        </Box>
      )}
    </Box>
  );
};

export default RecordedPreview;
