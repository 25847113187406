export const UploadIcon = () => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.7558 9.0293C19.2058 9.32638 20.6145 11.0993 20.6145 14.9805V15.1051C20.6145 19.3889 18.8991 21.1043 14.6154 21.1043H8.37661C4.09286 21.1043 2.37744 19.3889 2.37744 15.1051V14.9805C2.37744 11.128 3.76702 9.35513 7.15953 9.03888"
        stroke="#5242EA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.5 14.8752V3.96936"
        stroke="#5242EA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.7099 6.10605L11.4995 2.89563L8.28906 6.10605"
        stroke="#5242EA"
        stroke-width="1.3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
