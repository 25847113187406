export const SvVideoIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.91667" cy="9.4987" r="7.91667" stroke="#027A48" strokeWidth="1.5" />
      <path d="M16.8333 9.5H1" stroke="#027A48" strokeWidth="1.5" />
      <ellipse
        cx="8.91675"
        cy="9.4987"
        rx="3.7605"
        ry="7.91667"
        stroke="#027A48"
        strokeWidth="1.5"
      />
    </svg>
  );
};
