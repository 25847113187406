import { VenueProperties } from '../../../api/models/venue';

export type AgreementAttributes = VenueProperties['agreement_attributes'];

export function getAgreementsHtmlText(agreementAttributes: AgreementAttributes) {
  return `<table class='nl-container' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;background-color:#fff'><tbody><tr><td><table class='row row-1' align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table
class='row-content stack' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='100%' style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-top:0;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='image_block' width='100%' border='0' cellpadding='0'
cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='width:100%;padding-right:0;padding-left:0'><div align='center' style='line-height:10px'><img class='big' src='https://userimg-bee.customeriomail.com/images/client-env-100827/Signup%20Confirmation.jpg' style='display:block;height:auto;border:0;width:625px;max-width:100%' width='625'></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-2' align='center'
width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table class='row-content' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='100%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='heading_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='text-align:center;width:100%'><h1
style='margin:0;color:#183056;direction:ltr;font-family:Arial,Helvetica Neue,Helvetica,sans-serif;font-size:19px;font-weight:400;letter-spacing:normal;line-height:120%;text-align:center;margin-top:0;margin-bottom:0'><strong>&nbsp;Sign Up Details</strong></h1></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-3' align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody>
<tr><td><table class='row-content stack' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='100%' style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-left:25px;padding-right:25px;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0'><table
class='text_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td style='padding-bottom:10px'><div style='font-family:Arial,sans-serif'><div class='txtTinyMce-wrapper' style="font-size:12px;mso-line-height-alt:18px;color:#183056;line-height:1.5;font-family:'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif"><p dir='ltr' style='margin:0;mso-line-height-alt:21px'>
<span style='font-size:14px;'><strong>Payment Plan</strong></span><br>${agreementAttributes.pricing_plan}</p><p dir='ltr' style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0;mso-line-height-alt:21px'><span style='font-size:14px;'><strong>Name</strong></span></p><p style='margin:0'>${agreementAttributes.customer_name}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0;mso-line-height-alt:21px'><span style='font-size:14px;'><strong>Email</strong></span><br>
${agreementAttributes.customer_email}</p><p style='margin:0'><br><strong><span style='font-size:14px;'>Phone</span></strong><br>${agreementAttributes.customer_phone}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Company Name</span></strong><br>${agreementAttributes.company_name}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Company Address</span></strong><br>${agreementAttributes.company_address}</p>
<p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Company Website</span></strong><br>${agreementAttributes.company_website}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Webapp Subdomain</span></strong><br>${agreementAttributes.webapp_subdomain}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'>
<strong><span style='font-size:14px;'>How they heard about us</span></strong><br>${agreementAttributes.heard_about}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Email System</span></strong><br>${agreementAttributes.email_system}</p><p style='margin:0;mso-line-height-alt:18px'>&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>SMS System</span></strong><br>${agreementAttributes.sms_system}</p><p style='margin:0;mso-line-height-alt:18px'>
&nbsp;</p><p style='margin:0'><strong><span style='font-size:14px;'>Reward System</span></strong><br>${agreementAttributes.reward_system}</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-4' align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table class='row-content stack' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation'
style='mso-table-lspace:0;mso-table-rspace:0;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='100%' style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;padding-left:25px;padding-right:25px;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='text_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation'
style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td style='padding-bottom:10px'><div style='font-family:Arial,sans-serif'><div class='txtTinyMce-wrapper' style="font-size:14px;font-family:'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;mso-line-height-alt:21px;color:#183056;line-height:1.5"><p style='margin:0;font-size:14px;text-align:center'>
Have a question? <a href='mailto:feedback@socialvenu.com?subject=I%20have%20a%20question%20about%20my%20recent%20SocialV%20signup' target='_blank' title='feedback@socialvenu.com' rel='noopener' style='text-decoration: underline; color: #5242ea;'>Drop us a line</a></p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-5' align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table class='row-content'
align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;background-color:#f5f6f8;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='16.666666666666668%' style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;background-color:#f5f6f8;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='empty_block' width='100%' border='0' cellpadding='0'
cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='padding-right:0;padding-bottom:5px;padding-left:0;padding-top:5px'><div></div></td></tr></table></td><td class='column column-2' width='16.666666666666668%' style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='image_block' width='100%' border='0' cellpadding='0' cellspacing='0'
role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='width:100%;padding-right:0;padding-left:0;padding-top:5px;padding-bottom:5px'><div align='center' style='line-height:10px'><img src='https://userimg-bee.customeriomail.com/images/client-env-100827/Youtube.png' style='display:block;height:auto;border:0;width:40px;max-width:100%' width='40'></div></td></tr></table></td><td class='column column-3' width='16.666666666666668%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='image_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='width:100%;padding-right:0;padding-left:0;padding-top:5px;padding-bottom:5px'><div align='center' style='line-height:10px'>
<a href='https://www.instagram.com/social_venu/' target='_blank' style='outline:none' tabindex='-1'><img src='https://userimg-bee.customeriomail.com/images/client-env-100827/Instagram.png' style='display:block;height:auto;border:0;width:40px;max-width:100%' width='40'></a></div></td></tr></table></td><td class='column column-4' width='16.666666666666668%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='image_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='width:100%;padding-right:0;padding-left:0;padding-top:5px;padding-bottom:5px'><div align='center' style='line-height:10px'>
<a href='https://twitter.com/social_venu' target='_blank' style='outline:none' tabindex='-1'><img src='https://userimg-bee.customeriomail.com/images/client-env-100827/Twitter.png' style='display:block;height:auto;border:0;width:40px;max-width:100%' width='40'></a></div></td></tr></table></td><td class='column column-5' width='16.666666666666668%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='image_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='width:100%;padding-right:0;padding-left:0;padding-top:5px;padding-bottom:5px'><div align='center' style='line-height:10px'>
<a href='https://www.facebook.com/mysocialvenu/' target='_blank' style='outline:none' tabindex='-1'><img src='https://userimg-bee.customeriomail.com/images/client-env-100827/Facebook.png' style='display:block;height:auto;border:0;width:40px;max-width:100%' width='40'></a></div></td></tr></table></td><td class='column column-6' width='16.666666666666668%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='empty_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tr><td style='padding-right:0;padding-bottom:5px;padding-left:0;padding-top:5px'><div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-6'
align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table class='row-content stack' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='100%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;background-color:#f5f6f8;padding-top:5px;padding-bottom:5px;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='text_block' width='100%' border='0' cellpadding='10' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td><div style='font-family:sans-serif'><div class='txtTinyMce-wrapper'
style='font-size:14px;mso-line-height-alt:16.8px;color:#555;line-height:1.2;font-family:Arial,Helvetica Neue,Helvetica,sans-serif'><p style='margin:0;font-size:14px;text-align:center'>This email was sent to <strong>${agreementAttributes.customer_name}</strong> by SocialVenu, Inc.</p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table><table class='row row-7' align='center' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation'
style='mso-table-lspace:0;mso-table-rspace:0'><tbody><tr><td><table class='row-content' align='center' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;background-color:#f5f6f8;color:#000;width:625px' width='625'><tbody><tr><td class='column column-1' width='33.333333333333336%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;background-color:#f5f6f8;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='text_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td style='padding-bottom:15px;padding-left:10px;padding-right:10px;padding-top:15px'><div style='font-family:sans-serif'><div
class='txtTinyMce-wrapper' style='font-size:14px;mso-line-height-alt:16.8px;color:#555;line-height:1.2;font-family:Arial,Helvetica Neue,Helvetica,sans-serif'><p style='margin:0;font-size:14px;text-align:center'><a href='https://www.socialvenu.com/terms' target='_blank' rel='noopener' style='color: #677381;'>Privacy</a>&nbsp;</p></div></div></td></tr></table></td><td class='column column-2' width='33.333333333333336%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='text_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td style='padding-bottom:15px;padding-left:10px;padding-right:10px;padding-top:15px'><div style='font-family:sans-serif'><div class='txtTinyMce-wrapper'
style='font-size:14px;mso-line-height-alt:16.8px;color:#555;line-height:1.2;font-family:Arial,Helvetica Neue,Helvetica,sans-serif'><p style='margin:0;font-size:14px;text-align:center'><a href='https://www.socialvenu.com/terms' target='_blank' rel='noopener' style='color: #677381;'>Terms of Use</a></p></div></div></td></tr></table></td><td class='column column-3' width='33.333333333333336%'
style='mso-table-lspace:0;mso-table-rspace:0;font-weight:400;text-align:left;vertical-align:top;border-top:0;border-right:0;border-bottom:0;border-left:0'><table class='text_block' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation' style='mso-table-lspace:0;mso-table-rspace:0;word-break:break-word'><tr><td style='padding-bottom:15px;padding-left:10px;padding-right:10px;padding-top:15px'><div style='font-family:sans-serif'><div class='txtTinyMce-wrapper'
style='font-size:14px;mso-line-height-alt:21px;color:#555;line-height:1.5;font-family:Arial,Helvetica Neue,Helvetica,sans-serif'><p style='margin:0;font-size:14px;text-align:center'><a href='{% unsubscribe_url %}' target='_blank' style='text-decoration: underline; color: #677381;' rel='noopener'>Unsubscribe</a></p></div></div></td></tr></table></td></tr></tbody></table></td></tr></tbody></table></td></tr></tbody></table>`;
}
