import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    marginTop: '15px',
  },
  thumbnail: {
    width: '106px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    boxShadow: `0px 0px 4px 0px ${theme.palette.primary.main}`,
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '15px',
    position: 'relative',
  },
  removeButton: {
    fontSize: '12px',
    color: '#B42318',
    fontWeight: 500,
    marginTop: '5px',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  uploadBtn: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 14px',
    height: '36px',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: '600',

    '&:hover': {
      borderWidth: '2px',
    },

    '&:disabled': {
      border: `2px solid ${theme.palette.primary.main}66`,
      color: `${theme.palette.primary.main}66`,
    },
  },
  error: {
    color: '#ED2E38',
    fontSize: '11px',
    marginTop: '10px',
    marginBottom: '50px',
  },
  uploadTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginTop: '20px',
  },
  uploadInfo: {
    fontSize: '14px',
    color: '#475467',
    marginTop: '20px',
  },
  opacity: {
    opacity: '0.4',
  },
  progressWrapper: {
    marginTop: '10px',
    marginBottom: '-20px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
}));
