import { FC } from 'react';

export const BlockedIcon: FC = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" fill="white" stroke="#DCDBE4" />
    <path
      d="M5.09758 24.4863L24.0976 5.48625M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
      stroke="#C0C3C8"
      strokeWidth="2"
    />
    <path
      d="M5.09758 24.4863L24.0976 5.48625M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
      stroke="#C0C3C8"
      strokeWidth="2"
    />
  </svg>
);
