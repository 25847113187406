import { FC } from 'react';
import { Button, useTheme } from '@mui/material';
import { CommonStrings, RewardsLeaderboardDetails } from '../../localization/en';

import { FSModal } from '../modal/Modal';
import { ModalTitle, ModalText, ModalBtnWrapper } from '../modal/Modal.style';
import { useTypedSelector } from '../../../store';

interface Props {
  isOpen: boolean;
  onClose: (e?: React.SyntheticEvent) => void;
  onConfirm: (e?: React.SyntheticEvent) => void;
}

enum RedeemPointsStrings {
  modalText = 'This will remove :points points from the user and you must reward the user manually.',
}

export const FulfillRewardModal: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const targetPoints = activeCampaign?.points || 1000;
  const theme = useTheme();
  return (
    <FSModal onClose={onClose} modalIsOpen={isOpen}>
      <ModalTitle>{RewardsLeaderboardDetails.RedeemModalTitle}</ModalTitle>
      <ModalText>
        {RedeemPointsStrings.modalText.replace(':points', targetPoints.toString())}
      </ModalText>
      <ModalBtnWrapper>
        <Button color="primary" onClick={onClose}>
          {CommonStrings.Cancel}
        </Button>
        <Button
          style={{ borderRadius: '100px', backgroundColor: theme.palette.primary.main }}
          variant="contained"
          color="primary"
          onClick={onConfirm}
        >
          {CommonStrings.Confirm}
        </Button>
      </ModalBtnWrapper>
    </FSModal>
  );
};
