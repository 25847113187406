import React, { FC } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { QrCodeType } from '../../../api/models/venue';

interface QRCodeStyleSwitchProps {
  value: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const QRCodeStyleSwitch: FC<QRCodeStyleSwitchProps> = ({ value, handleChange }) => {
  const styles = useQRCodeStyleSwitchStyles();

  const isBranded = value === QrCodeType.Branded;
  return (
    <FormControl fullWidth>
      <RadioGroup
        name="radio-buttons-group"
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
        value={value}
        onChange={handleChange}
      >
        <FormControlLabel
          value={QrCodeType.Branded}
          control={<Radio sx={{ display: 'none' }} />}
          label="Branded"
          className={styles.Tab}
          sx={{
            color: isBranded ? '#fff !important' : '',
            backgroundColor: isBranded ? '' : '#fff !important',
            borderRadius: ' 10px 0px 0px 10px',
          }}
        />
        <FormControlLabel
          value={QrCodeType.Default}
          control={<Radio sx={{ display: 'none' }} />}
          label="Default"
          className={styles.Tab}
          sx={{
            color: isBranded ? '' : '#fff !important',
            backgroundColor: isBranded ? '#fff !important' : '',
            borderRadius: ' 0px 10px 10px 0px',
          }}
        />
      </RadioGroup>
    </FormControl>
  );
};

export const useQRCodeStyleSwitchStyles = makeStyles((theme: Theme) => ({
  Tab: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,
    margin: 0,
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '16px',
    color: theme.palette.primary.main,
    '& span': {
      color: 'inherit',
    },
  },
}));
