export enum NewCampaignEvents {
  NewRewardCampaignPage = 'new_campaign',
  NewRewardCampaignBackClick = 'new_campaign_back_click',
  NewRewardCampaignSaveAndCreateClick = 'new_campaign_save_and_create_click',
  NewRewardCampaignPageCampaignTypeChange = 'new_campaign_page_campaign_type_change',

  NewRewardCampaignNameTyped = 'new_campaign_name_typed',
  NewRewardCampaignRewardNameTyped = 'new_campaign_reward_name_typed',
  NewRewardCampaignAccordionClick = 'new_campaign_accordion_#number_click',
  NewRewardCampaignPageCampaignUsersLimitToggle = 'new_campaign_page_limit_campaign_to_the_current_users',
  NewRewardCampaignPageCampaignUsersSetPreferred = 'new_campaign_page_set_preferred',
  NewRewardCampaignPageRequireEmail = 'new_campaign_page_require_email',
  NewRewardCampaignActivityTypeClick = 'new_campaign_activity_type_click',
  NewRewardCampaignActivityToggleClick = 'new_campaign_activity_toggle_click',
  NewRewardCampaignRewardCreatorsClick = 'new_campaign_reward_creators_button_click',
  NewRewardCampaignPageInvitesPreviewTabsClick = 'new_campaign_page_invites_preview_tabs_click',
  NewRewardCampaignPageInvitesPreviewInputManuallyButtonClick = 'new_campaign_page_invites_preview_inputs_manually_button_click',
  NewRewardCampaignPageInvitesPreviewImportFileButtonClick = 'new_campaign_page_invites_preview_import_file_button_click',

  NewRewardCampaignRewardCreatorsTyped = 'new_campaign_reward_creators_typed',
  NewRewardCampaignCustomizeCardUploadBg = 'new_campaign_customize_card_upload_bg',
  NewRewardCampaignCustomizeCardSelectBgClick = 'new_campaign_customize_card_select_bg_click',
  NewRewardCampaignCustomizeCardOpenFileSelectorClick = 'new_campaign_customize_card_open_file_selector_click',
  NewRewardCampaignCustomizeCardOpenGalleryClick = 'new_campaign_customize_card_open_gallery_click',
  NewRewardCampaignCustomizeMessageTyped = 'new_campaign_customize_sms_message_typed',
  NewRewardCampaignCustomizeMessageTemplateButtonClick = 'new_campaign_customize_sms_message_template_button_click',
  NewRewardCampaignCustomizeMessageSendSampleTestClick = 'new_campaign_customize_sms_message_send_sample_test_click',
  NewRewardCampaignCustomizeMessageModalCloseClick = 'new_campaign_customize_sms_message_modal_close_click',
  NewRewardCampaignCustomizeMessageModalSendClick = 'new_campaign_customize_sms_message_modal_send_click',
  NewRewardCampaignCustomizeMessageModalPhoneTyped = 'new_campaign_customize_sms_message_modal_phone_typed',
  NewRewardCampaignAutoFulfillSelect = 'new_campaign_auto_fulfill_reward_select',
  NewRewardCampaignManualulfillSelect = 'new_campaign_manual_fulfill_reward_select',

  NewRewardCampaignInvitationMessageModalCloseClick = 'new_campaign_invitation_sms_message_send_sample_test_click',
  NewRewardCampaignInvitationMessageModalSendClick = 'new_campaign_invitation_sms_message_modal_send_click',
  NewRewardCampaignInvitationMessageSendSampleTestClick = 'new_campaign_invitation_sms_message_send_sample_test_click',

  NewRewardCampaignImproveBusinessTypesSwitch = 'new_campaign_improve_business_types_switch',
  NewRewardCampaignVideoStyleTypesSwitch = 'new_campaign_video_types_switch',

  NewRewardCampaignRewardCardHeaderChange = 'new_campaign_reward_card_header_change',
  NewRewardCampaignRewardCardNameChange = 'new_campaign_reward_card_name_change',
  NewRewardCampaignRewardCardPhoneNumberChange = 'new_campaign_reward_card_phone_number_change',
  NewRewardCampaignRewardCardEmailAddressChange = 'new_campaign_reward_card_email_address_change',
  NewRewardCampaignRewardCardWebsiteChange = 'new_campaign_reward_card_website_change',
  NewRewardCampaignRewardCardColorChange = 'new_campaign_reward_card_color_change',

  new_reward_campaign_existed_users_table_row_click = 'new_reward_campaign_existed_users_table_row_click',
  new_reward_campaign_existed_users_table_sort_by_name_click = 'new_reward_campaign_existed_users_table_sort_by_name_click',
  new_reward_campaign_existed_users_select_all = 'new_reward_campaign_existed_users_select_all',
  new_reward_campaign_existed_users_deselect_all = 'new_reward_campaign_existed_users_deselect_all',
  new_reward_campaign_existed_users_searchbar_typed = 'new_reward_campaign_existed_users_searchbar_typed',

  NewRewardCampaignContactMethodChange = 'new_reward_campaign_contact_method_change',
  NewRewardCampaignRewardTypeChange = 'new_reward_capmaign_reward_type_change',
}
