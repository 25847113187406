import React, { FC, useState } from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { SearchObject, useStyles } from './SearchAccordion.helper';
import { LayoutMode } from '../../constants/constants';

interface SearchAccordionProps {
  placeholder: string;
  chosenValue?: SearchObject;
  onChangeSearch: (name: string) => void;
  selectValue: (val: SearchObject) => void;
  items: SearchObject[];
  searchValue: string;
  layout: LayoutMode;
  disabled?: boolean;
}
export const SearchAccordion: FC<SearchAccordionProps> = ({
  placeholder,
  chosenValue,
  onChangeSearch,
  selectValue,
  items,
  searchValue,
  layout,
  disabled = false,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  
  const clickExpand = () => {
    setExpanded(!expanded);
  };
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(e.target.value);
  };
  const handleClick = (item: SearchObject) => {
    selectValue(item);
    clickExpand();
  };
  const filterSearch = (items: SearchObject[]): SearchObject[] => {
    return items.filter((item) =>
      searchValue
        ? item.name
            .toLowerCase()
            .split(' ')
            .filter((word) => word.startsWith(searchValue.toLowerCase())).length
        : true,
    );
  };
  return (
    <div className={classes.root}>
      {expanded && <div className={classes.closedArea} onClick={clickExpand} />}
      <Accordion
        className={clsx({ [classes.card]: true, [classes.cardOpened]: expanded })}
        elevation={expanded ? 4 : 0}
        expanded={expanded}
        onChange={clickExpand}
        disabled={disabled}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.title}>{placeholder}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start">
            <TextField
              variant="outlined"
              size="small"
              className={classes.searchField}
              label="Search"
              value={searchValue}
              onChange={onSearchChange}
              InputLabelProps={{ shrink: true }}
            />

            {filterSearch(items).map((item) => (
              <Grid item xs={12} key={item.id}>
                <Divider />
                <Button
                  fullWidth
                  variant="text"
                  onClick={() => handleClick(item)}
                  color="inherit"
                  className={classes.button}
                >
                  <span className={classes.text}>{item.name}</span>
                </Button>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
