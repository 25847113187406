import { Box, Typography } from '@mui/material';
// import { format } from 'date-fns';
import React from 'react';
// import { CalendarIcon } from '../../../../../../../common/assets/CalendarIcon';
import { useStyles } from './PreparedInvites.styles';
import { NewCalendarIcon } from '../../../../../../../common/NewCalendarIcon';

interface SendInviteTimeoutCardProps {
  nextSendDate: string;
}

export const SendInviteTimeoutCard: React.FC<SendInviteTimeoutCardProps> = ({ nextSendDate }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.nextSendWrapper}
      sx={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #026AA2',
        background: '#F0F9FF',
        padding: '24px 20px',
      }}
    >
      <span className={classes.calendarIcon}>
        <NewCalendarIcon />
      </span>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '22px',
          color: '#026AA2',
          fontWeight: '600',
          lineHeight: '100%',
          fontFamily: 'Poppins',
        }}
      >
        Next Send Date Available {nextSendDate}
      </Typography>
      {/* <Typography
        sx={{
          textAlign: 'center',
          fontSize: '68px',
          color: '#026AA2',
          fontWeight: '800',
          lineHeight: '100%',
          fontFamily: 'Inter',
        }}
      >
        
      </Typography> */}
    </Box>
  );
};
