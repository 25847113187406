import { FC } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    cursor: 'pointer',
  },
  buttonText: {
    marginLeft: '8px',
    fontSize: '15px',
    color: theme.palette.primary.main,
  },
}));

interface AddButtonWithTextProps {
  onClick: () => void;
  text: string;
}
export const AddButtonWithText: FC<AddButtonWithTextProps> = ({ onClick, text }) => {
  const styles = useStyles();

  return (
    <div className={styles.container} onClick={onClick}>
      <AddCircleOutlineIcon color="primary" />
      <div className={styles.buttonText}>{text}</div>
    </div>
  );
};
