import { FC } from "react";

export const DeleteIcon:FC = () => (
  <svg
    width={18}
    height={20}
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.92969 3.76074H15.2384V16.8072C15.2384 17.9117 14.343 18.8072 13.2384 18.8072H4.92969C3.82512 18.8072 2.92969 17.9117 2.92969 16.8072V3.76074Z"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5752 3.76074H16.5925"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.89453 3.76092V2.19336C6.89453 1.64107 7.34225 1.19336 7.89453 1.19336H10.2729C10.8252 1.19336 11.2729 1.64107 11.2729 2.19336V3.76092"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M7.1123 8.41211L7.1123 14.1561"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M11.084 8.41211L11.084 14.1561"
      stroke="#8B89A0"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
  </svg>
);

