import { FC, useMemo } from 'react';
import { Box, Button, Grid } from '@mui/material';

import { VideoMetricsStoreModel } from '../../../../api/models/users';
import { CommonStrings } from '../../../localization/en';
import { InteractionsBlock } from './components/InteractionsBlock';
import { getRoundedValue, insertAt } from '../../../../services/utilities';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { TopGolfVideoBlock } from './components/TopGolfVideoBlock';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../../constants/events/topGolfEvents';

interface ModalInfoProps {
  video: TopGolfVideosApiModel;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  handleOpenDownloadVideoDialog?: () => void;
}

export const TopGolfModalContentInfo: FC<ModalInfoProps> = ({
  video,
  handleOpenDeleteModal,
  handleOpenConfirmationModal,
  handleOpenDownloadVideoDialog,
}) => {
  // const [stats, setStats] = useState<StoryMetricsStoreModel | null>(null);
  const { trackEvent } = useTrackEvent();
  const stats = {
    videoViews: video.views,
    referrals: video.ctaClicks,
    convertedReferrals: video.views && video.ctaClicks ? (video.views / video.ctaClicks) * 100 : 0,
  };
  // useEffect(() => {
  //   getStoryMetrics({ id: story.id })
  //     .then((metrics) => {
  //       setStats(metrics);
  //     })
  //     .catch(() => setStats(null));
  //   segmentTrackCall(segmentActions.ContentDetails, venueId);
  // }, [story, venueId]);

  const handleDelete = () => {
    if (handleOpenDeleteModal) {
      handleOpenDeleteModal(video.id, video.status === 'ARCHIVED');
    }
    trackEvent(topGolfEvents.top_golf_video_slide_out_archive_button_click, {
      videoId: video.id,
    });
  };
  const conversionRate = useMemo(
    () =>
      insertAt(
        getRoundedValue(!video.views ? 0 : (video.ctaClicks / video.views) * 100) || 0,
        '%',
        'end',
      ),
    [video],
  );

  const { convertedReferrals, ...statsWithoutScaleoConversions } = stats
    ? stats
    : { convertedReferrals: 0 };

  return (
    <Grid container direction="column" spacing={2}>
      <Box margin="20px">
        <Grid item>
          <InteractionsBlock
            metricsData={
              { ...statsWithoutScaleoConversions, conversionRate } as VideoMetricsStoreModel
            }
            withHeader
          />
        </Grid>
        <Grid item>
          <TopGolfVideoBlock
            video={video}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
          />
        </Grid>
        <Grid item>
          <Box display="flex" justifyContent="flex-end" paddingRight={4}>
            <Button color="primary" onClick={handleDelete}>
              {video.status === 'ARCHIVED' ? CommonStrings.Unarchive : CommonStrings.Archive}
            </Button>
            <Box mr={2} />
            <Button variant="contained" color="primary" onClick={handleOpenDownloadVideoDialog}>
              {CommonStrings.Download}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
