import { Box, Typography } from '@mui/material';
import React from 'react';
import { useCustomizationDisplayStyles } from './CustomizationDisplay.styles';
import { Tooltip } from '../../../../../../../common/components/Tooltip/Tooltip';

interface CustomizationDisplayProps {
  title: string;
  value: string;
  tooltip: string;
}

export const CustomizationDisplay: React.FC<CustomizationDisplayProps> = ({
  title,
  value,
  tooltip,
}) => {
  const classes = useCustomizationDisplayStyles();

  return (
    <Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
        <Tooltip
          iconClassName={classes.tooltipIcon}
          content={<Box className={classes.tooltip}>{tooltip}</Box>}
          maxWidth="265px"
        />
      </Box>
      <Typography className={classes.value}>{value}</Typography>
    </Box>
  );
};
