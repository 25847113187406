import { FC } from 'react';

export const LivePreviewIcon: FC = () => {
  return (
    <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="19" r="18" stroke="#8B89A0" />
      <path
        d="M25.5 16.5L25.5 11.5M25.5 11.5H20.5M25.5 11.5L18.8333 18.1667M16.3333 13.1667H14.5C13.0999 13.1667 12.3998 13.1667 11.865 13.4392C11.3946 13.6788 11.0122 14.0613 10.7725 14.5317C10.5 15.0665 10.5 15.7665 10.5 17.1667V22.5C10.5 23.9001 10.5 24.6002 10.7725 25.135C11.0122 25.6054 11.3946 25.9878 11.865 26.2275C12.3998 26.5 13.0999 26.5 14.5 26.5H19.8333C21.2335 26.5 21.9335 26.5 22.4683 26.2275C22.9387 25.9878 23.3212 25.6054 23.5608 25.135C23.8333 24.6002 23.8333 23.9001 23.8333 22.5V20.6667"
        stroke="#8B89A0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
