import { Box } from '@mui/material';
import { VideoApiModel } from '../../../../api/models/videos';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { Spinner } from '../../../assets/Spinner';
import { SalesHeader } from './salesComponents/SalesHeader';
import { SalesHeroDetails } from './salesComponents/SalesHeroDetails';
import { FC, useEffect, useRef, useState } from 'react';
import { getUserActiveCampaign } from '../../../../store/slices/campaignsSlice';
import { isElementInViewport } from '../../../../services/utilities';
import { isSafari, isIOS } from 'react-device-detect';

interface SalesDetailsContentProps {
  userId: string;
  story?: VideoApiModel;
  handleClose: () => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  onClickOutsideDisabled?: (value: boolean) => void;
  isOpen?: boolean;
  campaignId?: string;
  entity?: 'heroes' | 'campaigns';
}
export const SalesDetailsContent: FC<SalesDetailsContentProps> = ({
  handleClose,
  userId,
  onClickOutsideDisabled,
  isOpen,
  campaignId,
  entity,
}) => {
  const dispatch = useAppDispatch();
  const { isLoadingUserDetails, isLoadingCampaignUser, userDetails } = useTypedSelector(
    (state) => state.userDetails,
  );
  const { isActiveCampaignLoading, campaignSummaryUserDetails } = useTypedSelector(
    (state) => state.campaigns,
  );
  const { isLoading: isLoadingVideos } = useTypedSelector((state) => state.videos);
  const ref = useRef<HTMLDivElement>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isActiveCampaignLoadStarted, setIsActiveCampaignLoadStarted] = useState(false);

  const isLoading =
    isLoadingUserDetails || isActiveCampaignLoading || isLoadingCampaignUser || isLoadingVideos;

  useEffect(() => {
    if (isActiveCampaignLoading) {
      setIsActiveCampaignLoadStarted(true);
    }
  }, [isActiveCampaignLoading]);

  useEffect(() => {
    if (!isLoading && isActiveCampaignLoadStarted) {
      setIsLoaded(true);
    }
  }, [isLoading, isActiveCampaignLoadStarted]);

  const handleOutsideSlideOutClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    // const tableRow = target.closest('.MuiTableRow-root') as HTMLTableElement;
    const slideout = target.closest('#slideout-root') as HTMLDivElement;
    const modal = target.closest('.MuiModal-root') as HTMLDivElement;

    if (slideout?.dataset.preventSlideoutClose || modal) {
      return;
    }

    handleClose();
  };

  useEffect(() => {
    dispatch(getUserActiveCampaign({ userId }));
  }, [dispatch, userId]);

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideSlideOutClick);

    return () => window.removeEventListener('mousedown', handleOutsideSlideOutClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaignSummaryUserDetails.fromRecentVideos && isLoaded && !isLoading) {
      const videoInSlideout = document.getElementById(
        campaignSummaryUserDetails.videoId,
      ) as HTMLElement;
      if (!videoInSlideout) return;

      const isVisible = isElementInViewport(videoInSlideout, -250);
      if (!isVisible) {
        videoInSlideout.scrollIntoView({
          behavior: 'smooth',
          block: isSafari || isIOS ? 'end' : 'center',
        });
      }
    }
  }, [campaignSummaryUserDetails, isLoading, isLoaded]);

  return (
    <>
      {!isLoaded && (
        <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
          <Spinner color="var(--lightBlue)" />
        </Box>
      )}
      <Box
        id="slideout-root"
        ref={ref}
        bgcolor="#fff"
        height="calc(110vh - 80px)"
        paddingBottom="20px"
        borderRadius="10px"
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          display: isLoaded ? '' : 'none',
        }}
        data-prevent-slideout-close="true"
      >
        <SalesHeader onClose={handleClose} entity={entity} />
        {userDetails && (
          <SalesHeroDetails
            userId={userId}
            onClickOutsideDisable={onClickOutsideDisabled}
            campaignId={campaignId}
            entity={entity}
          />
        )}
      </Box>
    </>
  );
};
