import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    background: '#fff',
  },
  tabs: {
    paddingLeft: '40px',
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginRight: '6px',
      borderRadius: '10px 10px 0px 0px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabName: {
    marginRight: '8px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
  },
  usersCount: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    padding: '2px 8px',
    borderRadius: '16px',
  },
  divider: {
    width: '100%',
    height: '21px',
    background: '#f5f6f8',
  },
  contentWrapper: {
    padding: '0 44px 0 40px',
    background: '#f5f6f8',
  },
}));
