import React, { useMemo } from 'react';

import { RewardsCalculationActivityModel, ActivityApiModel } from '../../../api/models/rewards';

import { TitleSection } from '../TitleSection/TitleSection';
import { ActivitiesListSection } from './ActivitiesListSection/ActivitiesListSection';

import { LayoutMode } from '../../../common/constants/constants';
import { useWindowResize } from '../../../services/hooks/useWindowResize';

import {
  getCalculationActivities,
  getActivitiesListWithIcons,
} from './ActivitiesListSection/ActivitiesListSection.helper';

import {
  TitleRightBlock,
  TitleRightBlockText,
  TitleRightBlockNumber,
} from './RewardsActivities.style';
import { SectionDivider } from '../Rewards.style';
import { RewardsNewCampaignWizard } from '../../../common/localization/en';

interface RewardsActivitiesProps {
  calculation?: RewardsCalculationActivityModel;
  activities: ActivityApiModel[];
}
export const RewardsActivities: React.FC<RewardsActivitiesProps> = ({
  calculation,
  activities,
}) => {
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;
  const activitiesListWithIcons = getActivitiesListWithIcons(activities);
  const calculationActivities = getCalculationActivities(activitiesListWithIcons, calculation);

  const ExtraRightBlock = useMemo(
    () => (
      <TitleRightBlock>
        <TitleRightBlockText>{RewardsNewCampaignWizard.Step2SubTitle}</TitleRightBlockText>
        <TitleRightBlockNumber>
          1,000 <span>Points</span>
        </TitleRightBlockNumber>
      </TitleRightBlock>
    ),
    [],
  );
  return (
    <div>
      <TitleSection
        title={RewardsNewCampaignWizard.Step2Title}
        subTitle={isMobile && ExtraRightBlock}
        extraRightBlock={!isMobile && ExtraRightBlock}
      />
      <SectionDivider />

      <ActivitiesListSection items={calculationActivities} />
    </div>
  );
};
