import { FC } from 'react';

export const SvIconLarge: FC = () => (
  <svg width="132" height="95" viewBox="0 0 132 95" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.9707 43.9003L66.2786 76.2636L101.637 41.3815L103.57 39.4668L121.078 39.5873L119.262 41.4057L66.2786 94.9994L13.9453 43.9003H31.9707Z"
      fill="#FDB927"
    />
    <path
      d="M28.344 0L0 28.3439L8.71809 37.062H123.431L131.13 28.2819L127.223 23.6605H24.7592L36.3963 11.764H97.8423L101.855 15.9069H119.984L105.399 0.41398L28.344 0Z"
      fill="url(#paint0_linear_1_1033)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_1033"
        x1="65.5648"
        y1="0"
        x2="65.5648"
        y2="37.062"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#119BBF" />
        <stop offset="0.53125" stopColor="#813B8A" />
        <stop offset="0.973958" stopColor="#E32A54" />
      </linearGradient>
    </defs>
  </svg>
);
