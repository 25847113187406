import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import {
  CommonStrings,
  SavedFiltersHeaderStrings,
  SavedFiltersPageStrings,
} from '../../common/localization/en';
import { useFiltersStyles } from './Filters.style';
import { useHistory, useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../common/constants/routes';

import { useAppDispatch, useTypedSelector } from '../../store';

import { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { deleteSearchFilterById, getSearchFilters } from '../../store/slices/searchFiltersSlice';
import {
  SearchFilterApiModel,
  SearchTargets,
  SearchTargetsType,
} from '../../api/models/searchFilter';
import { FiltersTable } from './FiltersTable';
import { SearchOnClient } from '../../common/components/searchOnClient/searchOnClient';
import { CreateUserFilterModal } from '../../common/components/CreateUserFilterModal/CreateUserFilterModal';
import { CreateVideoFilterModal } from '../../common/components/CreateVideoFilterModal/CreateVideoFilterModal';
import { detectFilterTarget } from './Filters.helper';
import { useToasts } from 'react-toast-notifications';
import { useClientType } from '../../services/hooks/useClientType';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { VideosEventNames } from '../../common/constants/events/videosEvents';

export interface stateType {
  prevLocation: string;
}

export const Filters = () => {
  const styles = useFiltersStyles();
  const { state } = useLocation<stateType>();
  const { trackEvent } = useTrackEvent();
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { items } = useTypedSelector((state) => state.SearchFilterSlice);
  const { isHealthCare } = useClientType();
  const [searched, setsearched] = useState(items);
  const [open, setOpen] = useState(false);

  const filterTarget = detectFilterTarget((state?.prevLocation as unknown) as string) || 'VIDEOS';
  const prevPage = detectFilterTarget((state?.prevLocation as unknown) as string);
  const capitalizePrevPage = state?.prevLocation
    ? state?.prevLocation === WebsiteRoutes.Heroes && isHealthCare
      ? CommonStrings.Patients
      : ((state?.prevLocation as unknown) as string) === WebsiteRoutes.RewardsLeaderboard
      ? 'Leaderboard'
      : ((state?.prevLocation as unknown) as string) === WebsiteRoutes.ContentAll
      ? 'Clips'
      : (prevPage as string)?.charAt(0)?.toUpperCase() +
        (prevPage as string)?.slice(1)?.toLocaleLowerCase()
    : 'Clips';

  const [currenTFilter, setcurrenTFilter] = useState<SearchFilterApiModel | undefined>(undefined);

  const handleOpen = (filter: SearchFilterApiModel) => {
    setcurrenTFilter(filter);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    trackEvent(VideosEventNames.filterDeleteCancelButtonClicked);
  };

  useEffect(() => {
    dispatch(getSearchFilters({ accountId: venueId, target: filterTarget as SearchTargetsType }));
  }, [venueId, dispatch, filterTarget]);

  const handleDelete = () => {
    trackEvent(VideosEventNames.filterDeleteConfirmClicked, {
      filterId: currenTFilter?.id,
    });
    if (currenTFilter && currenTFilter.id) {
      dispatch(deleteSearchFilterById(currenTFilter.id)).then(() => {
        addToast(SavedFiltersHeaderStrings.DeleteMessage, {
          appearance: 'success',
          autoDismissTimeout: 1500,
        });
      });
      setOpen(false);
    }
  };
  useEffect(() => {
    setsearched(items);
  }, [items]);

  const onSearch = (e: BaseSyntheticEvent) => {
    const val = e?.target?.value;
    trackEvent(VideosEventNames.filtersSearchTyped, {
      value: val,
    });
    if (!val) {
      return setsearched(items);
    } else {
      const serachValue = (val as string).trim().toLocaleLowerCase();
      const filtered = items.filter((item) => {
        return item.name.includes(serachValue);
      });
      setsearched(filtered);
    }
  };

  const [isCreationVideoModal, setCreationVideoModal] = useState(false);
  const toggleCreationVideoModal = useCallback(() => {
    setCreationVideoModal(!isCreationVideoModal);
  }, [isCreationVideoModal]);

  const openPreviewModal = (filter: SearchFilterApiModel) => {
    setcurrenTFilter(filter);
    setCreationVideoModal(true);
  };
  const backString = () => {
    if (capitalizePrevPage === 'Users') {
      return 'Creators';
    } else {
      return capitalizePrevPage;
    }
  };

  const onReset = () => {
    setsearched(items);
  };

  const isSocailVerse = state && state.prevLocation.includes('socialverse');
  return (
    <div className={styles.Container}>
      <header className={styles.Header}>
        <Grid container>
          <Grid item xs={2}>
            <Button
              startIcon={<ArrowBackRoundedIcon />}
              variant="outlined"
              className={styles.HeaderBtn}
              onClick={() => {
                history.goBack();
                trackEvent(VideosEventNames.filtersButtonBackClicked);
              }}
            >
              {capitalizePrevPage
                ? `${CommonStrings.Back} to ${backString()}`
                : `${CommonStrings.Back}`}
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Typography component="h1" className={styles.Title}>
              {SavedFiltersPageStrings.Header.replace(
                'Clips',
                capitalizePrevPage ? backString() : '',
              )}
            </Typography>
          </Grid>
        </Grid>
      </header>
      <main>
        <Grid container>
          <Grid item xs={2} className={styles.Sidebar}>
            <Box width="90%" marginLeft="auto" marginRight="auto">
              <Divider />
            </Box>
            <Typography className={styles.PageDescription}>
              {SavedFiltersPageStrings.PageDescription}
            </Typography>
          </Grid>
          <Grid item xs={10} className={styles.Main}>
            <SearchOnClient onReset={onReset} onChange={onSearch} />
            <Box
              style={{
                backgroundColor: '#FFFF',
                height: 'calc(100vh - 80px - 105px)',
                marginTop: '20px',
              }}
            >
              {searched.length ? (
                <FiltersTable
                  items={searched}
                  handleOpen={handleOpen}
                  openPreviewModal={openPreviewModal}
                  isSocailVerse={isSocailVerse}
                />
              ) : (
                <Typography className={styles.Message}>
                  {SavedFiltersPageStrings.NoFilters}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </main>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.Modal}>
          <Typography component="h2" className={styles.ModalTitle}>
            {SavedFiltersPageStrings.DeleteFilter}
          </Typography>
          <Typography>{SavedFiltersPageStrings.DeleteDescription}</Typography>
          <Box display="flex" justifyContent="center" alignItems="center" marginTop="52px">
            <Button
              variant="outlined"
              onClick={() => {
                handleDelete();
              }}
              className={styles.ModalBtn}
            >
              {SavedFiltersPageStrings.DeleteConfirmation}
            </Button>
            <Button variant="contained" onClick={handleClose} className={styles.ModalBtn}>
              {CommonStrings.Cancel}
            </Button>
          </Box>
        </Box>
      </Modal>
      {filterTarget === SearchTargets.VIDEOS ? (
        <CreateVideoFilterModal
          isCreationModal={isCreationVideoModal}
          toggleCreationModal={toggleCreationVideoModal}
          savedFilter={currenTFilter}
          target={filterTarget}
        >
          <></>
        </CreateVideoFilterModal>
      ) : (
        <CreateUserFilterModal
          isCreationModal={isCreationVideoModal}
          toggleCreationModal={toggleCreationVideoModal}
          savedFilter={currenTFilter}
          target={filterTarget}
        >
          <></>
        </CreateUserFilterModal>
      )}
    </div>
  );
};
