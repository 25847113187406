import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  indicator: {
    fontSize: '14px',
    borderRadius: '16px',
    padding: '2px 8px',
  },
}));
