export const DNDUploadIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2553 8.52734C19.7053 8.82443 21.114 10.5973 21.114 14.4786V14.6032C21.114 18.8869 19.3986 20.6023 15.1149 20.6023H8.87612C4.59237 20.6023 2.87695 18.8869 2.87695 14.6032V14.4786C2.87695 10.6261 4.26654 8.85318 7.65904 8.53693"
          stroke="#292D32"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 14.3746V3.46875"
          stroke="#292D32"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.2109 5.60495L12.0005 2.39453L8.79004 5.60495"
          stroke="#292D32"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
