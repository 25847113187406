import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useAccountsSidebarStyles = makeStyles<Theme, { isSidebarOpen: boolean }>(
  (theme: Theme) => ({
    root: {
      background: theme.palette.common.sidebar.accountsMenu.background,
      width: '64px',
    },
    iconWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: ({ isSidebarOpen }) => (isSidebarOpen ? '222px' : '64px'),
      filter:
        'drop-shadow(0px 4px 6px rgba(16, 24, 40, 0.03)) drop-shadow(0px 12px 16px rgba(16, 24, 40, 0.08))',
      height: '100%',
      background: theme.palette.common.sidebar.accountsMenu.background,
      zIndex: 1400,
      transition: 'all 0.3s ease-out',
      padding: '8px 8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'fit-content',
    },

    distributorPopupTriggerButton: {
      position: 'relative',
    },
  }),
);
