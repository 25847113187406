import styled from 'styled-components';

export const SettingsLayout = styled.div`
  width: 100%;
  padding: 16px 24px;
  display: grid;
`;

export const ControlsBlock = styled.div`
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  min-height: 500px;
  padding: 32px 24px;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  color: var(--primaryTextColor);
  margin-bottom: 8px;
`;

export const PageSubtitle = styled.h3`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 143%;
  color: var(--secondaryColor);
`;

export const SectionDivider = styled.div`
  width: calc(100% + 48px);
  border-bottom: 1px solid #eeeef6;
  margin-left: -24px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
