import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { paperPadding?: string }>(() => ({
  root: (props) => ({
    // borderRadius: 30,
    padding: props.paperPadding || '24px',
  }),
  backDrop: {
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  paper: {
    maxWidth: '100%',
    width: 'auto',
    minWidth: '900px',
  },
  dialog: {
    borderRadius: 30,
    minWidth: 'none !important',
  },
}));
