import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '173px',
    backgroundColor: '#F9FAFB',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #F2F4F7',
    borderRadius: '25px',
    padding: '4px',
    marginRight: '14px',
    cursor: 'pointer',
    position: 'relative',
  },
  optionWrapper: {
    width: '80px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 600,
    userSelect: 'none',
    zIndex: '3',
    transition: '0.3s ease-in-out',
  },
  optionBackground: {
    position: 'absolute',
    zIndex: '2',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '40px',
    width: '80px',
    backgroundColor: '#FFF',
    border: '1px solid #D0D5DD',
    borderRadius: '20px',
    transition: '0.3s ease-in-out',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
  },
}));
