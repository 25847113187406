import { FC, SetStateAction } from 'react';
import { Grid, Box } from '@mui/material';

import { AddButtonWithText } from '../../../../../common/components/addButtonWithText/AddButtonWithText';
import { VenuCreationUserRoles } from '../../../../../common/constants/constants';
import { VenueCreationWizardStrings } from '../../../../../common/localization/en';
import { ManagerAccount } from '../../../../../store/storeModels';
import { OrganizationBlock } from './components/OrganizationBlock/OrganizationBlock';
import { Usersblock } from './components/UsersBlock/UsersBlock';
import { useStyles } from './Step1.helper';

interface Step1Props {
  accounts: ManagerAccount[];
  setAccounts: (accounts: SetStateAction<ManagerAccount[]>) => void;
  setVenuName: (name: SetStateAction<string>) => void;
  venuName: string;
  setCompanyName: (name: SetStateAction<string>) => void;
  companyName: string;
  setTeamName: (name: SetStateAction<string>) => void;
  teamName: string;
  companyUrl: string;
  setCompanyUrl: (name: SetStateAction<string>) => void;
}
export const Step1: FC<Step1Props> = ({
  accounts,
  setAccounts,
  companyName,
  setCompanyName,
  venuName,
  setVenuName,
  setTeamName,
  teamName,
  companyUrl,
  setCompanyUrl,
}) => {
  const classes = useStyles();

  const handleDelete = (index: number) => {
    const newAccounts = [...accounts];
    newAccounts.splice(index, 1);
    setAccounts(newAccounts);
  };

  const renderUsersBlock = () => {
    return accounts.map((account, index) => {
      const additionalTopBlock =
        0 === index ? (
          <Grid item xs={12}>
            <Box className={classes.firstUserTitleBlock}>
              <Box className={classes.titleBlock}>
                {VenueCreationWizardStrings.WorkspaceManagers}
              </Box>
              <AddButtonWithText
                text={VenueCreationWizardStrings.AddOwnerBtn}
                onClick={handleAddUser}
              />
            </Box>
          </Grid>
        ) : null;

      return (
        <Grid item xs={12} key={index}>
          <Usersblock
            account={account}
            index={index}
            setUsers={setAccounts}
            additionalTopBlock={additionalTopBlock}
            handleDelete={handleDelete}
            isDeleteBtn={accounts.length > 1}
            canEdit={!venuName}
          />
        </Grid>
      );
    });
  };

  const handleAddUser = () => {
    if (!!venuName) {
      setAccounts([
        {
          email: '',
          name: '',
          role: VenuCreationUserRoles.venue_manager,
          password: '',
          submited: false,
        },
        ...accounts,
      ]);
    }
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <p className={classes.header}>{VenueCreationWizardStrings.WorkspaceAndUsers}</p>
        <p className={classes.subheader}>
          {VenueCreationWizardStrings.ApplyWorkspaceAndUserDetails}
        </p>
      </Grid>
      <Grid item xs={12}>
        <OrganizationBlock
          setVenuName={setVenuName}
          venuName={venuName}
          setCompanyName={setCompanyName}
          companyName={companyName}
          teamName={teamName}
          setTeamName={setTeamName}
          companyUrl={companyUrl}
          setCompanyUrl={setCompanyUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.scrollContext}>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            {renderUsersBlock()}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
