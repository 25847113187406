import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { FormikValues } from '../../SocialVerseSettingsPage';
import { useStyles } from './GridPreview.styles';
import { getPreviewGridImages } from './GridPreview.helpers';
import { SocialVerseStrings } from '../../../../../common/localization/en';
import { useTypedSelector } from '../../../../../store';

interface Props {
  formik: FormikProps<FormikValues>;
}

const GridPreview: FC<Props> = ({ formik }) => {
  const styles = useStyles();

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const previewGrids = getPreviewGridImages(formik.values.numberOfGridRows);

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>
        {formik.values.showTitle && formik.values.title}
      </Typography>
      {formik.values.showVideoCounter && (
        <Typography
          className={styles.videoCounter}
          style={{ color: formik.values.withGlow ? formik.values.glowColor : venueBrandColor }}
        >
          25 Videos
        </Typography>
      )}
      <Box className={styles.contentWrapper}>
        <Box
          className={styles.gridWrapper}
          style={{
            gridTemplate: `repeat(${formik.values.numberOfGridRows}, 168px) 78px / repeat(5, 168px)`,
          }}
        >
          {previewGrids.map((card, index) => (
            <Box key={index} className={styles.videoWrapper}>
              {formik.values.showUserInfo && (
                <>
                  <Box className={styles.gradient}></Box>
                  <Typography className={styles.creatorName}>{card.name}</Typography>
                  <Typography className={styles.duration}>00:23</Typography>
                </>
              )}
              <img src={card.card} alt="preview card" className={styles.image} />
            </Box>
          ))}
        </Box>
        <Box className={styles.bottomWrapper}>
          <Box className={styles.toTop}>{SocialVerseStrings.ReturnToTop}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GridPreview;
