import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  contaner: {
    width: '100%',
    paddingTop: '18px',
    paddingLeft: '41px',
  },
  goalText: {
    width: 'fit-content',
    textAlign: 'start',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#1A1538',
    letterSpacing: '0.02em',
  },
  progressWrapper: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginTop: '11px',
  },
  progressBar: {
    width: '470px',
    height: '18px',
    borderRadius: '100px',
    marginRight: '10px',
  },
  percentsText: {
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '24px',
    color: '#000',
  },
  succesTextWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'max-content',
    marginTop: '22px',
    paddingRight: '18px',
  },
  successText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '26px',
    color: '#24B947',
    letterSpacing: '0.02em',
    marginLeft: '10px',
  },
  btnEnableWrapper: {
    width: '100%',
    paddingRight: '18px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  btnEnableReward: {
    width: '225px',
    height: '46px',
  },
}));
