import { FC, SetStateAction, useEffect, useState, useMemo } from 'react';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';

import CreationHeroFundraising from '../../../../../../../common/assets/OrgWizard/CreationHeroFundraising.png';
import CreationHeroMarketing from '../../../../../../../common/assets/OrgWizard/CreationHeroMarketing.png';
import CreationHeroSales from '../../../../../../../common/assets/OrgWizard/CreationHeroSales.png';
import { VenueCreationWizardStrings } from '../../../../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../../../../store';
import { setSelectedVenue } from '../../../../../../../store/slices/venueSlice';
import { WebsiteRoutes } from '../../../../../../../common/constants/routes';
import { LinearProgressBar } from '../../../../../../../common/components/LinearProgressBar/LinearProgressBar';
import { ClientTypes } from '../../../../../../../api/models/common';

const useStyles = makeStyles((theme: Theme) => ({
  successTitle: {
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '133.4%',
    textAlign: 'center',
  },
  successSubtitle: {
    color: theme.palette.text.secondary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.15px',
    overflowWrap: 'break-word',
    width: '100%',
  },
  successLabel: {
    color: theme.palette.text.primary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    textAlign: 'center',
    letterSpacing: '0.15px',
    overflowWrap: 'break-word',
    width: '100%',
  },
  successValue: {
    color: theme.palette.text.secondary,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
    textAlign: 'left',
    letterSpacing: ' 0.15px',
    overflowWrap: 'break-word',
    width: '100%',
  },
  bottomNav: {
    height: '100px',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  innerNav: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
  },
  button: {
    width: '120px',
    margin: theme.spacing(3),
  },
}));
interface WizardSuccessProps {
  setShowWizardFailure: (value: SetStateAction<boolean>) => void;
  marketName?: string;
}

export const WizardSuccess: FC<WizardSuccessProps> = ({ setShowWizardFailure, marketName }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const { createdVenue } = useTypedSelector((state) => state.venueCreation);
  const { created, initiated } = useTypedSelector((state) => state.venueCreation.createdVenue);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initiated && !created) {
      setShowWizardFailure(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [created, initiated, history]);

  const handleLetsGo = () => {
    if (createdVenue.payload) {
      dispatch(setSelectedVenue({ id: createdVenue.payload.id }));
      setTimeout(() => {
        history.push(WebsiteRoutes.ContentAll);
      }, 500);
    }
  };

  const SuccessImage = useMemo(() => {
    switch (marketName) {
      case ClientTypes.ETS:
        return CreationHeroFundraising;
      case ClientTypes.FUNDRAISING:
        return CreationHeroFundraising;
      case ClientTypes.MARKETING:
        return CreationHeroMarketing;
      case ClientTypes.SALES:
        return CreationHeroSales;

      default:
        return CreationHeroSales;
    }
  }, [marketName]);

  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12}>
          <p className={classes.successTitle}>
            {VenueCreationWizardStrings[progress !== 100 ? 'WeAreCreating' : 'WizardSuccessTtitle']}
          </p>
        </Grid>
        {progress !== 100 ? (
          <Grid item xs={12}>
            <p className={classes.successSubtitle}>
              {VenueCreationWizardStrings.WizardSuccessSubtitle1}
            </p>
            <p className={classes.successSubtitle}>
              {VenueCreationWizardStrings.WizardSuccessSubtitle2}
            </p>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={6}>
                <p className={classes.successLabel}>
                  {VenueCreationWizardStrings.AccountActivated}
                </p>
                <p className={classes.successSubtitle}>
                  {VenueCreationWizardStrings.AccountActivatedSubheader}
                </p>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={6}>
          <LinearProgressBar progress={progress} setProgress={setProgress} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <img src={SuccessImage} alt="Wizzard Success" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.bottomNav}>
        <div className={classes.innerNav}>
          <Button
            color="primary"
            variant="contained"
            disabled={progress !== 100}
            onClick={handleLetsGo}
            className={classes.button}
          >
            {VenueCreationWizardStrings.LetsGo}
          </Button>
        </div>
      </div>
    </>
  );
};
