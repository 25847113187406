import React from 'react';
import { AccordionItem } from '../../common/components/CustomizationAccordion/CustomizationAccordion';
import { NewCampaignSubroutes } from '../../common/constants/routes';
import { NewCampaignStrings } from '../../common/localization/en';
import { AcknowledgementSMSContent } from './AcknowledgementSMS/AcknowledgementSMSContent';
import { AcknowledgementSMSPreview } from './AcknowledgementSMS/AcknowledgementSMSPreview';
import { CustomizeSMSPreview } from './CustomizeSMS/CustomizeSMSPreview';
import { CustomizeSMSSectionContent } from './CustomizeSMS/CustomizeSMSSectionContent';
/* import { VideoStyleTypesSwitch } from './VideoStyleTypesSwitch/VideoStyleTypesSwitch'; */
import WelcomeVideoStyles from './WelcomeVideoStyles/WelcomeVideoStyles';
import WelcomeVideoStylesPreview from './WelcomeVideoStylesPreview/WelcomeVideoStylesPreview';
import { CallToAction } from './CallToAction/CallToAction';
import { CallToActionPreview } from './CallToActionPreview/CallToActionPreview';

interface NewCampaignAccordionItem extends AccordionItem {
  preview?: React.ReactNode;
}

export const getAccordionItems: (isNullReward: boolean) => NewCampaignAccordionItem[] = (
  isNullReward: boolean,
) => [
  {
    sectionContent: <CustomizeSMSSectionContent />,
    preview: <CustomizeSMSPreview />,
    title: NewCampaignStrings.InviteSms,
    subroute: NewCampaignSubroutes.InviteSms,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <WelcomeVideoStyles />,
    preview: <WelcomeVideoStylesPreview />,
    title: NewCampaignStrings.VideoStyle,
    subroute: NewCampaignSubroutes.VideoStyle,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <AcknowledgementSMSContent />,
    preview: <AcknowledgementSMSPreview />,
    title: isNullReward
      ? NewCampaignStrings.CustomizeAcknowledgementSMS
      : NewCampaignStrings.CustomizeRewardSMS,
    subroute: NewCampaignSubroutes.AcknowledgementSms,
    tooltipText: '',
    withToolTip: true,
  },
  {
    sectionContent: <CallToAction />,
    preview: <CallToActionPreview />,
    title: NewCampaignStrings.CallToAction,
    subroute: NewCampaignSubroutes.CallToAction,
    tooltipText: '',
    withToolTip: true,
  },

  /*  {
    sectionContent: <FulfillRewards />,
    preview: <FulfillRewardsPreview />,
    title: NewCampaignStrings.FulfillRewards,
    subroute: NewCampaignSubroutes.FulfillRewards,
    tooltipText: '',
    withToolTip: true,
  }, */
];

export interface VisitedRoutes {
  [key: string]: {
    visited: boolean;
    revisited?: boolean;
    valid: boolean;
  };
}

export const rewardRoutes = {
  'acknowledgement-sms': {
    visited: false,
    valid: false,
  },
  'invite-sms': {
    visited: false,
    valid: false,
  },
  'video-style': {
    visited: false,
    valid: true,
  },
  'call-to-action': {
    visited: false,
    valid: true,
  },
};
export const isEditRewardRoutes = {
  'invite-sms': {
    visited: false,
    valid: false,
  },
  'acknowledgement-sms': {
    visited: false,
    valid: false,
  },
};
