import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    width: '250px',
  },
  actionArea: {
    position: 'relative',
    height: 'calc(100% - 70px)',
  },
  video: {
    aspectRatio: '9/16',
    width: '250px',
  },
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '70px',
    padding: '0 20px!important',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#8B89A0',
    fontSize: '12px',
    marginRight: '10px',
  },
  icon: {
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '100%',
    backdropFilter: 'blur(30px)',
    height: '56px',
    width: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  favouriteButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    borderRadius: '15px',
    backgroundColor: theme.palette.common.white,
    marginLeft: 'auto',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '20px 12px',
  },
  smallText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  smallTextPrimary: {
    color: '#8B89A0',
  },
  smallTextSecondary: {
    color: theme.palette.text.secondary,
  },
  public: {
    fontSize: '14px',
    display: 'inline-block',
    color: '#fff',
    padding: '4px 17px',
    borderRadius: 50,
    backgroundColor: theme.palette.primary.main,
  },
  private: {
    backgroundColor: theme.palette.grey['700'],
  },
}));
