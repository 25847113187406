import { FC } from 'react';

export const VisibilityPassOffIcon: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.47188 15.28C9.28188 15.28 9.09187 15.21 8.94187 15.06C8.12187 14.24 7.67188 13.15 7.67188 12C7.67188 9.61001 9.61187 7.67001 12.0019 7.67001C13.1519 7.67001 14.2419 8.12001 15.0619 8.94001C15.2019 9.08001 15.2819 9.27001 15.2819 9.47001C15.2819 9.67001 15.2019 9.86001 15.0619 10L10.0019 15.06C9.85187 15.21 9.66188 15.28 9.47188 15.28ZM12.0019 9.17001C10.4419 9.17001 9.17188 10.44 9.17188 12C9.17188 12.5 9.30187 12.98 9.54187 13.4L13.4019 9.54001C12.9819 9.30001 12.5019 9.17001 12.0019 9.17001Z"
      fill="#292D32"
    />
    <path
      d="M5.59984 18.51C5.42984 18.51 5.24984 18.45 5.10984 18.33C4.03984 17.42 3.07984 16.3 2.25984 15C1.19984 13.35 1.19984 10.66 2.25984 9.00001C4.69984 5.18001 8.24984 2.98001 11.9998 2.98001C14.1998 2.98001 16.3698 3.74001 18.2698 5.17001C18.5998 5.42001 18.6698 5.89001 18.4198 6.22001C18.1698 6.55001 17.6998 6.62001 17.3698 6.37001C15.7298 5.13001 13.8698 4.48001 11.9998 4.48001C8.76984 4.48001 5.67984 6.42001 3.51984 9.81001C2.76984 10.98 2.76984 13.02 3.51984 14.19C4.26984 15.36 5.12984 16.37 6.07984 17.19C6.38984 17.46 6.42984 17.93 6.15984 18.25C6.01984 18.42 5.80984 18.51 5.59984 18.51Z"
      fill="#292D32"
    />
    <path
      d="M11.9986 21.02C10.6686 21.02 9.3686 20.75 8.1186 20.22C7.7386 20.06 7.5586 19.62 7.7186 19.24C7.8786 18.86 8.3186 18.68 8.6986 18.84C9.7586 19.29 10.8686 19.52 11.9886 19.52C15.2186 19.52 18.3086 17.58 20.4686 14.19C21.2186 13.02 21.2186 10.98 20.4686 9.81C20.1586 9.32 19.8186 8.85 19.4586 8.41C19.1986 8.09 19.2486 7.62 19.5686 7.35C19.8886 7.09 20.3586 7.13 20.6286 7.46C21.0186 7.94 21.3986 8.46 21.7386 9C22.7986 10.65 22.7986 13.34 21.7386 15C19.2986 18.82 15.7486 21.02 11.9986 21.02Z"
      fill="#292D32"
    />
    <path
      d="M12.6915 16.27C12.3415 16.27 12.0215 16.02 11.9515 15.66C11.8715 15.25 12.1415 14.86 12.5515 14.79C13.6515 14.59 14.5715 13.67 14.7715 12.57C14.8515 12.16 15.2415 11.9 15.6515 11.97C16.0615 12.05 16.3315 12.44 16.2515 12.85C15.9315 14.58 14.5515 15.95 12.8315 16.27C12.7815 16.26 12.7415 16.27 12.6915 16.27Z"
      fill="#292D32"
    />
    <path
      d="M2.00141 22.75C1.81141 22.75 1.62141 22.68 1.47141 22.53C1.18141 22.24 1.18141 21.76 1.47141 21.47L8.94141 14C9.23141 13.71 9.71141 13.71 10.0014 14C10.2914 14.29 10.2914 14.77 10.0014 15.06L2.53141 22.53C2.38141 22.68 2.19141 22.75 2.00141 22.75Z"
      fill="#292D32"
    />
    <path
      d="M14.5287 10.22C14.3387 10.22 14.1488 10.15 13.9988 10C13.7088 9.71 13.7088 9.23 13.9988 8.94L21.4687 1.47C21.7587 1.18 22.2388 1.18 22.5288 1.47C22.8188 1.76 22.8188 2.24 22.5288 2.53L15.0588 10C14.9088 10.15 14.7187 10.22 14.5287 10.22Z"
      fill="#292D32"
    />
  </svg>
);
