import { FC, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useStyles } from './VideoAppCopyUrl.styles';
import { SettingsPageStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { CopyIcon } from '../../assets/newDesign/CopyIcon';
import { CopiedIcon } from '../../assets/newDesign/CopiedIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import { useWebAppLink } from '../../../hooks/useWebAppLink';

interface Prop {
  autoSize?: boolean;
  isQRCodeLink?: boolean;
}

export const VideoAppCopyUrl: FC<Prop> = ({ autoSize, isQRCodeLink }) => {
  const classes = useStyles();
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const { webAppLink } = useWebAppLink({ qrCode: true });

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (isLinkCopied) {
      trackEvent(VideosEventNames.copyVideoAppLinkClicked, {
        value: webAppLink,
      });
      navigator.clipboard.writeText(webAppLink);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 3000);
    }
  }, [isLinkCopied, webAppLink, trackEvent]);

  function getTextWidth(text: string, font: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }
    return 0;
  }
  const textWidth = getTextWidth(webAppLink, 'normal 11px Poppins');
  const containerWidth = textWidth + 130;
  const inputWidth = textWidth + 215;

  return (
    <Box
      className={classes.container}
      width={autoSize ? containerWidth : '100%'}
      minWidth={275}
      sx={{
        borderRadius: isQRCodeLink ? '10px !important' : '',
        paddingLeft: isQRCodeLink ? '0px !important' : '',
        paddingRight: isQRCodeLink ? '0px !important' : '',
      }}
    >
      <Box className={classes.fieldsWrapper}>
        <TextField
          className={classes.urlField}
          value={webAppLink}
          disabled={true}
          style={{
            width: autoSize ? inputWidth : '100%',
            minWidth: 205,
          }}
          sx={{
            '& input': {
              backgroundColor: isQRCodeLink ? '#F5F6F8 !important' : '',
              border: isQRCodeLink ? 'none !important' : '',
            },
          }}
        />
        <RoundedButton
          title={
            isLinkCopied
              ? SettingsPageStrings.VideoLinkCopied
              : isQRCodeLink
              ? SettingsPageStrings.CopyQRLink
              : SettingsPageStrings.CopyLink
          }
          className={classes.btn}
          startIcon={
            isLinkCopied ? (
              <CopiedIcon />
            ) : (
              <Box sx={{ color: '#fff' }}>
                <CopyIcon />
              </Box>
            )
          }
          onClick={() => {
            setIsLinkCopied(true);
          }}
          style={{
            backgroundColor: isLinkCopied ? '#17B950' : '',
            width: isLinkCopied ? '100%' : isQRCodeLink ? '104px' : '75px',
            boxShadow: isLinkCopied ? 'none' : '',
          }}
        />
      </Box>
    </Box>
  );
};
