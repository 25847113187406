import { FC } from 'react';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

import { ContentModalHeaderStrings } from '../../../localization/en';
import { useStyles } from './ContentModalHeader.helper';

interface ContentModalHeaderProps {
  storyLink: string;
  onClose: () => void;
  shortCode: string;
  onOpenVideoApp: () => void;
}

export const ContentModalHeader: FC<ContentModalHeaderProps> = ({
  storyLink,
  onClose,
  shortCode,
  onOpenVideoApp,
}) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom="1px solid #EEEEF6"
      borderColor="#EEEEF6"
      paddingX={4}
      paddingY="20px"
    >
      <Box mr={2}>
        <IconButton color="primary" onClick={onClose} size="large">
          <ArrowForward />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto">
        {ContentModalHeaderStrings.Title}
      </Box>
      <Box mr="auto">
        <Typography variant="body1" fontSize="14px">{`Short code: ${shortCode}`}</Typography>
      </Box>
      <Button
        className={classes.link}
        variant="contained"
        disableElevation
        onClick={onOpenVideoApp}
      >
        <a href={storyLink} target="_blank" rel="noreferrer" className={classes.webappLink}>
          {ContentModalHeaderStrings.WebappVideoLink}
        </a>
      </Button>
    </Box>
  );
};
