import React from 'react';

import { useTypedSelector } from '../../../store';
import { Box } from '@mui/material';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
} from '../../../common/components/SMS/SMS.helper';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { IPhoneHalfPreview } from '../../../common/components/IPhonePreview/IPhoneHalfPreview';
import { PreviewContainer } from '../components/PreviewContainer';
import { MessageBox } from '../../../common/components/IPhonePreview/IPhoneSMSPreview';
import RewardCardPreview from '../../../common/components/RewardCardPreview/RewardCardPreview';

export const AcknowledgementSMSPreview: React.FC = () => {
  const message = useTypedSelector((state) => state.createCampaign.smsRewardMessage);

  const {
    isNullReward,
    howToRewardCreators: { cardColor, contact, rewardName, rewardHeader, contactMethod },
  } = useTypedSelector((state) => state.createCampaign);
  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);

  const templateVariables = useSmsTemplateVariables();
  const currentEditableVariables: Record<LocalMessageVariables, string> = {
    ...templateVariables,
    'Reward Name': rewardName,
  };

  const constructedMessage = constructMessageFromTemplate(message, currentEditableVariables, true);

  return (
    <Box display="flex" justifyContent="center" position="sticky" top="80px" height="fit-content">
      <PreviewContainer title={isNullReward ? 'Acknowledgement Message' : 'Reward Notification'}>
        <IPhoneHalfPreview height={730}>
          {!isNullReward && (
            <Box mt="-2px" mb="4px">
              <RewardCardPreview
                cardColor={cardColor}
                contact={contact}
                rewardTitle={rewardName}
                rewardHeader={rewardHeader}
                logo={logo}
                contactMethod={contactMethod}
                scaleFactor={0.6}
              />
            </Box>
          )}
          <MessageBox
            width="280px"
            fontSize={16}
            message={constructedMessage}
            boldSubstrings={Object.values(currentEditableVariables)}
            showStartBlock={false}
          />
        </IPhoneHalfPreview>
      </PreviewContainer>
    </Box>
  );
};
