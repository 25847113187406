import { FC } from 'react';
import { theme } from '../../../theme';

export const SEOIcon: FC = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_1184_4838"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="50"
      height="50"
    >
      <circle cx="25" cy="25" r="25" fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_1184_4838)">
      <circle cx="25" cy="25" r="25" fill="#EEECFD" />
      <path
        d="M-0.970703 30.0173H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 40.9827H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 20.0173H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-0.970703 9.01733H52.1425"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5851 21.3771C27.5851 25.7681 24.0255 29.3277 19.6345 29.3277C15.2435 29.3277 11.6839 25.7681 11.6839 21.3771C11.6839 16.9861 15.2435 13.4265 19.6345 13.4265C24.0255 13.4265 27.5851 16.9861 27.5851 21.3771Z"
        fill="#EEECFD"
      />
      <path
        d="M12.4685 24.7118L18.1907 18.9891L21.5793 22.4142L33.7474 10.2471"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.7714 10.2471H33.7474V14.2231"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4839 29.2858L30.295 32.0969"
        stroke="#1A1538"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="28.8561"
        y="33.552"
        width="4.17615"
        height="11.9328"
        rx="1"
        transform="rotate(-45 28.8561 33.552)"
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.7767 21.3765C30.7767 27.5301 25.7882 32.5187 19.6346 32.5187C13.4809 32.5187 8.49243 27.5301 8.49243 21.3765C8.49243 15.2229 13.4809 10.2343 19.6346 10.2343C25.7882 10.2343 30.7767 15.2229 30.7767 21.3765ZM27.5851 21.3771C27.5851 25.7681 24.0255 29.3277 19.6345 29.3277C15.2435 29.3277 11.6839 25.7681 11.6839 21.3771C11.6839 16.9861 15.2435 13.4265 19.6345 13.4265C24.0255 13.4265 27.5851 16.9861 27.5851 21.3771Z"
        fill="white"
        stroke="#1A1538"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
