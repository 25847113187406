import { FC, useEffect, useState } from 'react';
import {
  CommonStrings,
  EducationCardsStrings,
  RewardAdStrings,
} from '../../../common/localization/en';
import { TitleSection } from '../TitleSection/TitleSection';
import { SectionDivider } from '../Rewards.style';
import { TopBar } from '../../../common/components/topBar/TopBar';
import { IncentiveSwitcher } from './IncentiveSwitcher/IncentiveSwitcher';
import { makeStyles } from '@mui/styles';
import { IncentiveForm } from './IncentiveSwitcher/IncentiveForm';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { IncentiveAdPreview } from './IncentiveSwitcher/IncentiveAdPreview';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { Button, Grid } from '@mui/material';
import { isEqualsJson } from '../../../services/utilities';
import { getTopGolfGeneralKPIs } from '../../../store/slices/analyticsSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../../common/constants/events/topGolfEvents';

const useStyles = makeStyles(() => ({
  incentiveBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'stretch',
    width: '100%',
    margin: '20px auto',
    padding: 20,
  },
  incentiveBlockLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: '650px',
    borderRadius: '16px',
    padding: 24,
    background: '#FFF',
    marginRight: 40,
  },
  incentiveBlockRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: '300px',
  },
  previewTitle: {
    fontSize: 16,
    textAlign: 'center',
  },
  disabled: {
    opacity: 0.3,
  },
  bottomControllers: {
    display: 'flex',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    flexDirection: 'row-reverse',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    padding: '24px 58px',
    zIndex: 100,
  },
  actionButton: {
    float: 'right',
    margin: '5px 2px',
    width: '120px',
  },
}));

const validationSchema = () =>
  Yup.object().shape({
    title: Yup.string().max(42, 'Title character limit: 42 characters').required('Required'),
    subtitle: Yup.string().max(82, 'Subtitle character limit: 82 characters').required('Required'),
    buttonText: Yup.string()
      .max(24, 'Button Text character limit: 24 characters')
      .required('Required'),
    buttonUrl: Yup.string()
      .required('Required')
      .matches(/[a-zA-Z]+:\/\//i, 'Must include “https://"'),
  });

export const IncentiveAd: FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const {
    venue: { logo, id: venueId },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { trackEvent } = useTrackEvent();

  const config = venueProperties?.['webapp.topgolf.config'];
  const [imageNewUrl, setImageNewUrl] = useState(config?.imageNewUrl || '');
  const [isActiveAd, setIsActiveAd] = useState<boolean>(!!config?.activeAd);
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const textColor = venueProperties?.['webapp.config']?.['text-color'];
  useEffect(() => {
    if (venueProperties) {
      const config = venueProperties?.['webapp.topgolf.config'];
      setImageNewUrl(config?.imageNewUrl || '');
      setIsActiveAd(!!config?.activeAd);
    }
  }, [venueProperties]);

  useEffect(() => {
    if (venueId) {
      dispatch(getTopGolfGeneralKPIs({ accountId: venueId }));
    }
  }, [venueId, dispatch]);

  const initialformstate = {
    title: config?.title || '',
    subtitle: config?.subtitle || '',
    buttonText: config?.buttonText || '',
    buttonUrl: config?.buttonUrl || '',
  };
  const formik = useFormik({
    initialValues: initialformstate,
    validationSchema,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: true,
    onSubmit: () => {},
  });

  const handleSave = async () => {
    dispatch(
      patchVenueProperties({
        id: venueId,
        update: {
          name: 'webapp.topgolf.config',
          value: { ...config, ...formik.values, imageNewUrl, activeAd: isActiveAd },
        },
      }),
    ).then(() => {
      trackEvent(topGolfEvents.top_golf_rewards_page_save_button_click);
    });
  };

  const reset = () => {
    formik.setValues({
      title: config?.title || '',
      subtitle: config?.subtitle || '',
      buttonText: config?.buttonText || '',
      buttonUrl: config?.buttonUrl || '',
    });
    setImageNewUrl(config?.imageNewUrl || '');
    setIsActiveAd(!!config?.activeAd);
    trackEvent(topGolfEvents.top_golf_rewards_page_cancel_button_click);
  };

  const canSave = () => {
    return (
      (imageNewUrl !== config?.imageNewUrl ||
        !isEqualsJson(formik.values, initialformstate) ||
        config.activeAd !== isActiveAd) &&
      !formik.errors.title &&
      !formik.errors.subtitle &&
      !formik.errors.buttonText &&
      !formik.errors.buttonUrl
    );
  };

  return (
    <>
      <TopBar />

      <Grid
        container
        alignItems={'center'}
        justifyContent="center"
        spacing={3}
        style={{ marginTop: '20px', padding: '20px' }}
      >
        <Grid item xs={8} style={{ background: 'white' }}>
          <TitleSection
            title={RewardAdStrings.Title}
            subTitle={
              <IncentiveSwitcher
                isActiveAd={isActiveAd}
                setIsActiveAd={(value) => {
                  setIsActiveAd(value);
                  trackEvent(topGolfEvents.top_golf_reward_activation_change, {
                    value: value ? 'activate' : 'deactivate',
                  });
                }}
              />
            }
          />
          <SectionDivider />
          <IncentiveForm
            canSave={canSave}
            setImage={(value) => {
              setImageNewUrl(value);
              trackEvent(topGolfEvents.top_golf_rewards_page_change_reward_img, {
                value,
              });
            }}
            image={imageNewUrl}
            isActiveAd={isActiveAd}
            formik={formik}
            reset={reset}
          />
        </Grid>
        <Grid item xs={4}>
          <h3 className={styles.previewTitle}>{EducationCardsStrings.AdPreview}</h3>
          <IncentiveAdPreview
            brandColor={brandColor}
            textColor={textColor}
            logo={logo}
            image={imageNewUrl}
            title={formik.values.title}
            subtitle={formik.values.subtitle}
            buttonText={formik.values.buttonText}
            isActiveAd={isActiveAd}
          />
        </Grid>
      </Grid>

      {canSave() && (
        <div className={styles.bottomControllers}>
          <Button
            className={styles.actionButton}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {CommonStrings.Save}
          </Button>
          <Button className={styles.actionButton} color="primary" onClick={reset}>
            {CommonStrings.Cancel}
          </Button>
        </div>
      )}
    </>
  );
};
