export const InitialStatusIcon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z"
          fill="#2491EB"
        />
        <path
          d="M5.99922 13.8002C6.99333 13.8002 7.79922 12.9943 7.79922 12.0002C7.79922 11.0061 6.99333 10.2002 5.99922 10.2002C5.00511 10.2002 4.19922 11.0061 4.19922 12.0002C4.19922 12.9943 5.00511 13.8002 5.99922 13.8002Z"
          fill="#2491EB"
        />
        <path
          d="M11.9992 13.8002C12.9933 13.8002 13.7992 12.9943 13.7992 12.0002C13.7992 11.0061 12.9933 10.2002 11.9992 10.2002C11.0051 10.2002 10.1992 11.0061 10.1992 12.0002C10.1992 12.9943 11.0051 13.8002 11.9992 13.8002Z"
          fill="#2491EB"
        />
        <path
          d="M17.9992 13.8002C18.9933 13.8002 19.7992 12.9943 19.7992 12.0002C19.7992 11.0061 18.9933 10.2002 17.9992 10.2002C17.0051 10.2002 16.1992 11.0061 16.1992 12.0002C16.1992 12.9943 17.0051 13.8002 17.9992 13.8002Z"
          fill="#2491EB"
        />
      </svg>
    </>
  );
};
