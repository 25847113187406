import * as React from 'react';

export const ReferralIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        fill="url(#paint0_linear)"
        d="M.647 13.528s2.899 8.617 3.814 10.41c1.063 2.08 4.168.755 4.014-.987-.155-1.741-3.782-8.94-3.782-8.94l-4.046-.483z"
      ></path>
      <path fill="#B0B8E9" d="M4.564 7.25L22.58.21l-.189 19.053-18.32-4.607.493-7.405z"></path>
      <path
        fill="#515182"
        d="M22.597 19.584c.977 0 1.77-4.384 1.77-9.792 0-5.408-.793-9.792-1.77-9.792-.978 0-1.77 4.384-1.77 9.792 0 5.408.792 9.792 1.77 9.792z"
      ></path>
      <path
        fill="#979EC8"
        d="M22.731 18.914c-.16-.183-.508-.89-.807-2.72-.289-1.768-.448-4.062-.448-6.458s.16-4.689.448-6.457c.299-1.83.647-2.537.807-2.72.16.183.508.89.807 2.72.29 1.768.448 4.061.448 6.457s-.16 4.69-.448 6.458c-.299 1.83-.647 2.537-.807 2.72z"
      ></path>
      <path
        fill="#2B2B5F"
        d="M25 9.664c0 1.664-.795 3.012-2.37 3.012-1.573 0-2.849-1.349-2.849-3.012 0-1.664 1.276-3.013 2.85-3.013S25 8 25 9.664z"
      ></path>
      <path
        fill="url(#paint1_linear)"
        d="M1.658 6.841a8.98 8.98 0 013.377.188c-.433 2.55-.368 5.206.133 7.958H1.46c-.59 0-1.094-.484-1.187-1.142-.185-1.319-.41-3.535-.17-5.316.122-.893.757-1.584 1.554-1.688z"
      ></path>
      <path
        fill="#BDD0FB"
        d="M3.882 8.793H.995a.257.257 0 01-.232-.155.325.325 0 01.01-.303c.023-.039.069-.05.103-.025a.09.09 0 01.022.116.142.142 0 00-.005.132c.02.043.059.068.101.068h2.888c.063 0 .115-.058.115-.13v-.231c0-.071-.052-.13-.115-.13H.973c-.041 0-.074-.037-.074-.083 0-.046.033-.084.074-.084h2.91c.144 0 .263.133.263.297v.231c0 .164-.119.297-.264.297zM3.624 10.235H.737a.257.257 0 01-.232-.155.325.325 0 01.01-.303c.023-.039.069-.05.103-.024a.09.09 0 01.022.115.142.142 0 00-.004.133c.02.042.058.067.1.067h2.888c.063 0 .115-.058.115-.13v-.23c0-.072-.052-.13-.115-.13H.715c-.04 0-.074-.038-.074-.084 0-.046.033-.084.074-.084h2.91c.145 0 .263.133.263.297v.232c0 .163-.118.296-.264.296z"
      ></path>
      <path
        fill="url(#paint2_linear)"
        d="M7.181 6.735c-.031 0-.06-.023-.07-.059-.013-.044.009-.09.048-.105l13.645-4.894c.04-.014.081.01.094.054.012.044-.01.091-.049.105L7.204 6.731a.066.066 0 01-.023.004z"
      ></path>
      <path
        fill="url(#paint3_linear)"
        d="M5.336 7.585a.075.075 0 01-.069-.052c-.015-.043.003-.092.04-.11L6.01 7.1c.038-.018.081.003.097.046.015.042-.003.091-.04.109l-.703.323a.064.064 0 01-.028.007z"
      ></path>
      <path
        fill="#BDD0FB"
        d="M3.882 11.923H.994a.257.257 0 01-.23-.155.325.325 0 01.01-.303c.022-.039.068-.05.102-.025a.09.09 0 01.022.116.141.141 0 00-.005.132c.02.042.059.067.101.067h2.888c.063 0 .115-.058.115-.129v-.231c0-.072-.052-.13-.115-.13H.972c-.04 0-.073-.038-.073-.084 0-.046.033-.083.074-.083h2.909c.145 0 .263.133.263.296v.232c0 .164-.118.297-.263.297zM4.152 13.797H1.265a.257.257 0 01-.231-.155.325.325 0 01.01-.303c.022-.04.068-.05.102-.025a.09.09 0 01.022.115.141.141 0 00-.004.133c.02.042.058.067.1.067h2.888c.064 0 .115-.058.115-.13v-.231c0-.071-.051-.13-.115-.13H1.243c-.04 0-.074-.037-.074-.083 0-.046.033-.084.074-.084h2.91c.145 0 .263.133.263.297v.231c0 .165-.118.298-.264.298zM6.731 23.985c-.133 0-.267-.016-.402-.049-.587-.144-1.064-.58-1.31-1.197L2.71 16.962c-.017-.042 0-.092.037-.11.038-.02.082 0 .099.041l2.307 5.777c.226.568.667.97 1.207 1.102.531.131 1.07-.024 1.475-.425a3.49 3.49 0 00.066-.066.068.068 0 01.105.006.091.091 0 01-.005.118 4.076 4.076 0 01-.068.068c-.34.335-.763.512-1.203.512zM2.438 16.157a.074.074 0 01-.068-.05l-.257-.644c-.017-.043 0-.092.037-.111.037-.019.081 0 .098.042l.257.644c.017.043 0 .092-.037.111a.07.07 0 01-.03.008z"
      ></path>
      <path
        fill="url(#paint4_linear)"
        d="M24.578 9.577c-.039 0-.071-.033-.074-.077-.052-.816-.302-1.423-.745-1.807-.766-.663-1.86-.435-1.87-.433-.041.009-.08-.02-.088-.066-.008-.045.019-.09.06-.098.046-.01 1.17-.244 1.988.464.478.414.748 1.063.803 1.928.003.046-.028.086-.069.09h-.005z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="0.049"
          x2="7.387"
          y1="25.355"
          y2="16.621"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D"></stop>
          <stop offset="1" stopColor="#1A0F91"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="2.716"
          x2="2.127"
          y1="14.444"
          y2="1.303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#09005D"></stop>
          <stop offset="1" stopColor="#1A0F91"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="7.107"
          x2="20.901"
          y1="4.204"
          y2="4.204"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE3FE"></stop>
          <stop offset="1" stopColor="#E9EFFD"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="5.262"
          x2="6.113"
          y1="7.339"
          y2="7.339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE3FE"></stop>
          <stop offset="1" stopColor="#E9EFFD"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="21.8"
          x2="24.652"
          y1="8.314"
          y2="8.314"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DAE3FE"></stop>
          <stop offset="1" stopColor="#E9EFFD"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
