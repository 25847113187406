import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './VideoReviews.styles';
import { Box, Button, Typography } from '@mui/material';
import { UwmLaunchModalStrings } from '../../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { VideoStatusType } from '../../../../api/models/searchFilterModels';
import VideoComponent from './VideoComponent/VideoComponent';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import type { Swiper as SwiperType } from 'swiper/types';
import ArrowLeft from '../../../assets/ArrowLeft';
import ArrowRight from '../../../assets/ArrowRight';
import { LoadingButton } from '@mui/lab';
import { useToasts } from 'react-toast-notifications';
// import { NoVideosContent } from './NoVideosContent/NoVideosContent';
import { removeFilterFromUrl } from '../../../../services/utilities';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { UwmEventNames } from '../../../constants/events/uwmEventNames';
import {
  BatchVideosToSocialVerse,
  getSocialVerseById,
} from '../../../../store/slices/socialVerseSlice';
import { getApiUrlForId } from '../../../../api/endpoints';
import { WebsiteRoutes } from '../../../constants/routes';
import { useHistory, useParams } from 'react-router-dom';
// import { getVideos } from '../../../../store/slices/videosSlice';

interface Props {
  onClose: () => void;
  openIndividualModal?: () => void;
  openImportModal?: () => void;
  onContentTypeChange?: (isNoVideoContent: boolean) => void;
  linkedSocialverseId?: string;
}

const VideoReviews: FC<Props> = ({
  onClose,
  openIndividualModal,
  openImportModal,
  onContentTypeChange,
  linkedSocialverseId,
}) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();
  const history = useHistory();
  const { id: campaignId } = useParams<{ id: string }>();

  const { items, isLoading } = useTypedSelector((state) => state.videos);
  const initialState = items.map((video) => ({
    id: video.id,
    thumbnailUrl: video.thumbnailUrl,
    url: video.url,
    displayName: video.userName,
    checked: video.status === VideoStatusType.Unpublished,
  }));

  const [videosToReview, setVideosToReview] = useState(initialState);

  const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(true);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);

  const [isVideoReviewed, setIsVideoReviewed] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (linkedSocialverseId) {
      dispatch(getSocialVerseById({ id: linkedSocialverseId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (items.length !== 0 && !isVideoReviewed) {
      setVideosToReview(
        items
          .map((video) => ({
            id: video.id,
            thumbnailUrl: video.thumbnailUrl,
            url: removeFilterFromUrl(video.url),
            displayName: video.userName,
            checked: video.status === VideoStatusType.Unpublished,
          }))
          .filter((video) => video.checked),
      );
    }
  }, [items, isVideoReviewed]);

  // useEffect(() => {
  //   onContentTypeChange(isVideoReviewed);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isVideoReviewed]);

  const handleChangeVideoStatus = (videoId: string) => {
    setVideosToReview((prevState) =>
      prevState.map((video) => {
        if (video.id !== videoId) return video;

        trackEvent(UwmEventNames.videoSelectClick, {
          value: !video.checked,
        });

        return { ...video, checked: !video.checked };
      }),
    );
  };

  const sliderRef = useRef<SwiperRef | null>(null);
  const SLIDES_PER_VIEW = 6;

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const checkIfNavButtonDisabled = (swiper: SwiperType) => {
    if (swiper.realIndex === 0) {
      setIsPrevButtonDisabled(true);
    } else {
      setIsPrevButtonDisabled(false);
    }

    if (swiper.isEnd && swiper.realIndex !== 0) {
      setIsNextButtonDisabled(true);
    } else {
      setIsNextButtonDisabled(false);
    }
  };

  const handleUpdateVideos = async () => {
    trackEvent(UwmEventNames.videosConfirmClick);
    setIsUpdating(true);

    // const videosToUpdate = videosToReview.filter((video, i) => {
    //   if (initialState[i]?.checked !== videosToReview[i]?.checked) {
    //     return video;
    //   }
    //   return null;
    // });

    const videosToPublish = videosToReview.filter((video) => video.checked).map((item) => item.id);

    if (linkedSocialverseId) {
      await dispatch(
        BatchVideosToSocialVerse({ videoIds: videosToPublish, id: linkedSocialverseId }),
      );
    }
    // const promises = [];
    // for (const video of videosToPublish) {
    //   promises.push(
    //     dispatch(
    //       getVideoById({
    //         id: video,
    //       }),
    //     ),
    //   );
    // }
    // await Promise.all(promises);

    setIsUpdating(false);
    setIsVideoReviewed(true);
    addToast(UwmLaunchModalStrings.UpdateSuccess, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };
  return (
    <Box className={styles.container}>
      {/* {videosToReview.length === 0 && !isLoading && (
        <Box className={styles.noViedosWrapper}>
          <NoVideosContent
            onClose={onClose}
            openIndividualModal={openIndividualModal}
            openImportModal={openImportModal}
          />
        </Box>
      )} */}
      {isVideoReviewed && (
        <Box className={styles.reviewedContentWrapper}>
          <Typography className={styles.allSetText}>{UwmLaunchModalStrings.AllSet}</Typography>
          <Typography className={styles.checkedVideoText}>
            {UwmLaunchModalStrings.CheckedVideo}
          </Typography>
          <Button
            className={styles.viewVideosBtn}
            onClick={() => {
              onClose();
              history.push(getApiUrlForId(WebsiteRoutes.CampaignDetailsClips, campaignId));
            }}
          >
            {UwmLaunchModalStrings.ViewVideos}
          </Button>
        </Box>
      )}
      {videosToReview.length > 0 && !isVideoReviewed && (
        <>
          <Box className={styles.titleWrapper}>
            <Typography className={styles.title}>
              {UwmLaunchModalStrings.BorrowersVideoClips}
            </Typography>
            <Typography className={styles.titleDescr}>
              {UwmLaunchModalStrings.CreatedWithin}
            </Typography>
          </Box>
          <Box
            className={styles.infoWrapper}
            sx={{
              justifyContent: videosToReview.length > SLIDES_PER_VIEW ? 'space-between' : 'center',
            }}
          >
            <Typography
              className={styles.infoText}
              marginLeft={videosToReview.length > SLIDES_PER_VIEW ? '-20px' : '0'}
            >
              {UwmLaunchModalStrings.UncheckVideo}
            </Typography>
            {videosToReview.length > SLIDES_PER_VIEW && (
              <Box className={styles.navigationWrapper}>
                <Button
                  onClick={handlePrev}
                  disabled={isPrevButtonDisabled}
                  className={styles.navigation}
                  sx={{ marginRight: '16px' }}
                >
                  <ArrowLeft />
                </Button>
                <Button
                  onClick={handleNext}
                  disabled={isNextButtonDisabled}
                  className={styles.navigation}
                >
                  <ArrowRight />
                </Button>
              </Box>
            )}
          </Box>

          <Box className={styles.placeholder} />
          <Box className={styles.swiperWrapper}>
            <Box className={styles.videosWrapper}>
              {!isLoading && (
                <Swiper
                  slidesPerView={
                    videosToReview.length >= SLIDES_PER_VIEW
                      ? SLIDES_PER_VIEW
                      : videosToReview.length
                  }
                  ref={sliderRef}
                  onSlideChange={checkIfNavButtonDisabled}
                >
                  {videosToReview.map((video, i) => (
                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }} key={i}>
                      <VideoComponent
                        id={video.id}
                        thumbnail={video.thumbnailUrl}
                        url={video.url}
                        name={video.displayName}
                        isChecked={video.checked}
                        onStatusChange={handleChangeVideoStatus}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </Box>
          </Box>
          <Typography className={styles.updateInfo}>{UwmLaunchModalStrings.UpdateInfo}</Typography>
          <LoadingButton
            className={styles.updateButton}
            onClick={handleUpdateVideos}
            loading={isUpdating}
            variant="contained"
          >
            {UwmLaunchModalStrings.Confirm}
          </LoadingButton>
        </>
      )}
    </Box>
  );
};

export default VideoReviews;
