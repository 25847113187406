import { Close } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { DetailsSidebarStrings, SmsImportUserStrings } from '../../../../localization/en';
import { useStyles } from './CampaignInvite.styles';
import Select, { SelectOption } from '../../../Select/Select';
import { SMSInvitationType } from '../../../SMSInvitationTypeSelector/SMSInvitationTypeSelector';
import { CampaignInvitation, CampaignsApiModel } from '../../../../../api/models/campaigns';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { LoadingButton } from '@mui/lab';
import { useClientType } from '../../../../../services/hooks/useClientType';
import {
  createInvitation,
  getUserInvitedCampaigns,
  sendInvitations,
} from '../../../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';

interface Props {
  handleClose: () => void;
  availableCampaigns: CampaignsApiModel[];
}

const CampaignInvite: FC<Props> = ({ handleClose, availableCampaigns }) => {
  const styles = useStyles();

  const [invitationOption, setInvitationOption] = useState<SMSInvitationType>(
    SMSInvitationType.SAVE_FOR_LATER,
  );
  const [campaignOption, setCampaignOption] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { userDetails } = useTypedSelector((state) => state.userDetails);

  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();

  const invitationOptions: Array<SelectOption<SMSInvitationType>> = [
    {
      name: 'Send at first opportunity (queued)',
      value: SMSInvitationType.SEND_IMMEDIATELY,
    },
    {
      name: 'Save for later',
      value: SMSInvitationType.SAVE_FOR_LATER,
    },
  ];

  const campaignOptions: Array<SelectOption<string>> = availableCampaigns.map((campaign) => ({
    name: campaign.name,
    value: campaign.id,
  }));

  const handleInvitationOptionChange = (value: SMSInvitationType) => {
    setInvitationOption(value);
  };

  const handleCampaignOptionChange = (value: string) => {
    setCampaignOption(value);
  };

  const handleCampaignInvite = async () => {
    if (!userDetails) return;

    setIsLoading(true);

    const invitationResponse = await dispatch(
      createInvitation({
        campaignId: campaignOption,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber!,
      }),
    );

    if (invitationOption === SMSInvitationType.SEND_IMMEDIATELY) {
      const invitation = invitationResponse.payload as CampaignInvitation;

      await dispatch(
        sendInvitations({ campaignId: campaignOption, invitationIds: [invitation.id] }),
      );
      addToast(
        `${userDetails.firstName} ${userDetails.lastName} ${SmsImportUserStrings.HasBeenInvited}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    } else {
      addToast(
        `${userDetails.firstName} ${userDetails.lastName} ${SmsImportUserStrings.SuccessSaveForLater}`,
        {
          appearance: 'success',
          autoDismiss: true,
        },
      );
    }

    if (userDetails.phoneNumber) {
      await dispatch(
        getUserInvitedCampaigns({
          accountId: userDetails.accountId,
          phoneNumber: userDetails.phoneNumber,
          pageable: { page: 0, size: 1000, sort: [] },
        }),
      );
    }

    setIsLoading(false);
    handleClose();
  };

  return (
    <Box className={styles.inviteWrapper}>
      <Close onClick={handleClose} className={styles.close} />
      <Typography className={styles.inviteTitle}>
        {DetailsSidebarStrings.InviteToCampaign}
      </Typography>
      <Typography className={styles.inviteInfo}>{DetailsSidebarStrings.InviteInfo}</Typography>
      <Box className={styles.optionsWrapper}>
        <Box className={styles.option}>
          <Typography className={styles.optionTitle}>
            {DetailsSidebarStrings.SelectActiveCampaign}
          </Typography>
          <Select
            onChange={handleCampaignOptionChange}
            options={campaignOptions}
            value={campaignOption}
            withPlaceholder
            placeholder="Select Campaign"
            width="289px"
            position="top"
            fontSize="14px"
            fontWeight="500"
            withEllipsis
            optionsWrapperHeight="500px"
          />
        </Box>
        <Box className={styles.option}>
          <Typography className={styles.optionTitle}>
            {DetailsSidebarStrings.InvitationSent}
          </Typography>
          <Select
            onChange={handleInvitationOptionChange}
            options={invitationOptions}
            value={invitationOption}
            width="289px"
            position="top"
            fontSize="14px"
            fontWeight="500"
            withEllipsis
          />
        </Box>
      </Box>
      <LoadingButton
        variant="contained"
        fullWidth
        className={styles.inviteButton}
        disabled={campaignOption === ''}
        loading={isLoading}
        onClick={handleCampaignInvite}
      >
        {isHealthCare
          ? DetailsSidebarStrings.InviteCreatorTrublu
          : DetailsSidebarStrings.InviteCreator}
      </LoadingButton>
    </Box>
  );
};

export default CampaignInvite;
