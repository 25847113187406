import { makeStyles } from '@mui/styles';
import { HeroDetailsStrings } from '../../localization/en';

export enum ActiveTab {
  Videos = 1,
  Engagements,
  Rewards,
}

interface ActiveTabsItem {
  tab: ActiveTab;
  name: string;
}
export const activeTabsList: ActiveTabsItem[] = [
  {
    tab: ActiveTab.Videos,
    name: HeroDetailsStrings.VideosTab,
  },
  {
    tab: ActiveTab.Engagements,
    name: HeroDetailsStrings.InteractionsTab,
  },
  {
    tab: ActiveTab.Rewards,
    name: HeroDetailsStrings.RewardsAndPointsTab,
  },
];

export const useStyles = makeStyles((theme) => ({
  userDetailsTab: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    position: 'sticky',
    top: '68px',
    left: 0,
    paddingLeft: '32px',
    paddingRight: '32px',
    background: '#FFF',
    zIndex: 10,
  },
  userDetailsTabRow: {
    display: 'flex',
    background: '#F5F6F8',
    padding: '5px 10px',
    borderRadius: '40px',
  },
  userDetailTabAlt: {
    fontSize: 12,
    lineHeight: 1.2,
    textAlign: 'center',
    padding: '5px',
    whiteSpace: 'nowrap',
    color: '#8B89A0',
  },
  userDetailTabActiveColorAlt: {
    color: '#1A1538',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 18px 30px rgba(64, 77, 104, 0.1)',
    padding: '5px 15px',
    borderRadius: '40px',
    margin: '0 3px',
  },
  tabsContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
  },
  tabsRow: {
    display: 'flex',
    height: theme.spacing(4),
  },
  tab: {
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  tabActiveColor: {
    color: theme.palette.text.primary,
  },
  tabCursor: {
    height: '4px',
    width: '32px',
    borderRadius: '4px',
    background: theme.palette.secondary.main,
    transition: '0.2s ease',
  },

  tabsRowAlt: {
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#f9f9fc',
    overflow: 'hidden',
  },
  tabAlt: {
    padding: 13,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontSize: 15,
    lineHeight: 1.5,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  tabActiveColorAlt: {
    color: theme.palette.primary.main,
    backgroundColor: '#eeeef6',
  },
}));
