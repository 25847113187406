import {
  GetKeywordsResponse,
  KeywordApiModel,
  PostKeywordsPayload,
  PostKeywordsRequest,
  PostKeywordsResponse,
} from '../../api/models/keywords';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getApiUrlForId, KeywordsEndpoints } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import { RootState } from '..';

interface KeywordsInitialState {
  keywords: KeywordApiModel[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: KeywordsInitialState = {
  keywords: [],
  isLoading: false,
  isError: false,
};

const getKeywordsAPICall = async ({ accountId }: { accountId: string }) => {
  return httpClient.get<{}, GetKeywordsResponse>({
    url: KeywordsEndpoints.GetKeywordsByVenueId,
    requiresToken: false,
    params: {
      accountId,
    },
  });
};

const postKeywordsAPICall = async ({
  accountId,
  keywords,
}: {
  accountId: string;
  keywords: KeywordApiModel[];
}) => {
  return httpClient.post<PostKeywordsPayload | { accountId: string }, PostKeywordsResponse>({
    url: KeywordsEndpoints.PostKeywordsByVenueId,
    requiresToken: true,
    payload: {
      keywords,
    },
    params: {
      accountId,
    },
  });
};

const deleteKeywordAPICall = async ({
  accountId,
  keyword,
}: {
  accountId: string;
  keyword: string;
}) => {
  return httpClient.delete<{}, undefined>({
    url: getApiUrlForId(KeywordsEndpoints.DeleteKeywordByVenueId, keyword),
    requiresToken: true,
    params: {
      accountId,
    },
  });
};

const deleteAllKeywordsAPICall = async ({ accountId }: { accountId: string }) => {
  return httpClient.delete<{}, undefined>({
    url: KeywordsEndpoints.DeleteAllKeywordsByVenueId,
    requiresToken: true,
    params: {
      accountId: accountId,
    },
  });
};

export const getKeywordsByVenueId = createAsyncThunk(
  'keywords/getKeywordsByVenueId',
  async (options: { accountId: string }, { rejectWithValue }) => {
    try {
      return getKeywordsAPICall(options);
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postKeywords = createAsyncThunk(
  'keywords/postKeywords',
  async (options: PostKeywordsRequest, { rejectWithValue }) => {
    try {
      return postKeywordsAPICall({
        accountId: options.accountId,
        keywords: options.payload.keywords,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteKeyword = createAsyncThunk(
  'keywords/deleteKeyword',
  async (
    options: { accountId: string; keyword: string },
    { rejectWithValue },
  ): Promise<string | unknown> => {
    try {
      await deleteKeywordAPICall(options);
      return options.keyword;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const deleteAllKeywords = createAsyncThunk(
  'keywords/deleteAllKeywords',
  async (options: { accountId: string }, { rejectWithValue }) => {
    try {
      return deleteAllKeywordsAPICall(options);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

/**
 * Update all keywords on backend with new value
 */
export const updateKeywords = createAsyncThunk(
  'keywords/updateKeywords',
  async (
    { venueId, keywords }: { venueId: string; keywords: KeywordApiModel[] },
    { rejectWithValue, getState },
  ) => {
    try {
      const storeKeywords = (getState() as RootState).KeywordsSlice.keywords;

      const keywordsToAdd = keywords.filter(({ icon, keyword }) => {
        return !storeKeywords.find((item) => item.icon === icon && item.keyword === keyword);
      });
      const keywordsToRemove = storeKeywords.filter(({ icon, keyword }) => {
        return !keywords.find((item) => item.icon === icon && item.keyword === keyword);
      });
      const removeAllKeywords = keywords.length === 0;

      if (keywordsToRemove.length) {
        if (removeAllKeywords) {
          await deleteAllKeywordsAPICall({ accountId: venueId });
        } else {
          const promises = keywordsToRemove.map(({ keyword }) => {
            return deleteKeywordAPICall({
              accountId: venueId,
              keyword,
            });
          });
          await Promise.all(promises);
        }
      }

      if (keywordsToAdd.length) {
        await postKeywordsAPICall({
          accountId: venueId,
          keywords: keywordsToAdd,
        });
      }

      return keywords;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const KeywordsSlice = createSlice({
  name: 'keywords',
  initialState,
  reducers: {
    setKeywords(state, action: PayloadAction<any>) {
      state.keywords = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getKeywordsByVenueId.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getKeywordsByVenueId.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getKeywordsByVenueId.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.keywords = payload.map(({ keyword, icon }) => ({ keyword, icon }));
    });

    reducersBuilder.addCase(postKeywords.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(postKeywords.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(postKeywords.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.keywords = [...state.keywords, ...payload];
    });

    reducersBuilder.addCase(deleteKeyword.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteKeyword.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(deleteKeyword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.keywords = state.keywords.filter(({ keyword }) => keyword !== payload);
    });

    reducersBuilder.addCase(deleteAllKeywords.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(deleteAllKeywords.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(deleteAllKeywords.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.keywords = [];
    });

    reducersBuilder.addCase(updateKeywords.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(updateKeywords.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(updateKeywords.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.keywords = payload;
    });
  },
});

export const { setKeywords } = KeywordsSlice.actions;

export default KeywordsSlice.reducer;
