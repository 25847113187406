import { FC, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { LoginFormComponent } from './LoginForm';
import { ForgotPasswordRequestComponent } from './ForgotPasswordRequest/ForgotPasswordRequest';
import { ForgotPasswordSuccessComponent } from './ForgotPasswordSuccess/ForgotPasswordSuccess';
import { useLoginStyles } from './Login.style';
import { useAppDispatch, useTypedSelector } from '../../store';
import { setSelectedVenue } from '../../store/slices/venueSlice';
import { HttpStatusCode } from '../../api/HttpStatusCodes';
import { LoginPageStrings } from '../../common/localization/en';

import SocialVenuLogo from '../../common/assets/newDesign/SV Logo.png';
import TruBluSocialSmilesLogo from '../../common/assets/newDesign/TruBluSocialSmiles Logo.svg';
import { ClientTypes } from '../../api/models/common';
import { isUWMClient } from '../../utils/isUWMClient';
import { BRAND_360_LOGO } from '../../common/constants/constants';
import { UwmTransitionModalContent } from '../../common/components/UwmTransitionModal/UwmTransitionModal';

export interface ILoginProps {
  setStep: Function;
}
interface LoginProps {
  initialStep?: 'login' | 'forgotPasswordRequest' | 'forgotPasswordSuccess';
}

export const Login: FC<LoginProps> = ({ initialStep }) => {
  const styles = useLoginStyles();
  const query = new URLSearchParams(useLocation().search);
  const clientType = query.get('clientType');
  const programId = query.get('programId');
  const partnerToken = query.get('partner-token') || query.get('partnerAuthCode');
  const { authStatusCode } = useTypedSelector((state) => state.me);
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  useEffect(() => {
    if (authStatusCode === HttpStatusCode.Forbidden) {
      addToast(LoginPageStrings.AccountDisabled, {
        appearance: 'error',
        autoDismissTimeout: 25000,
      });
    }
  }, [authStatusCode, addToast]);
  useEffect(() => {
    if (programId) {
      dispatch(setSelectedVenue({ id: programId }));
    }
  }, [dispatch, programId]);

  const [step, setStep] = useState<'login' | 'forgotPasswordRequest' | 'forgotPasswordSuccess'>(
    initialStep || 'login',
  );

  const displayTrubluLogo = clientType && clientType === ClientTypes.TRUBLU;

  const isUwmTransitionModal = isUWMClient() && partnerToken;

  return (
    <>
      <Box className={styles.root}>
        <Grid container sx={{ margin: '0 auto' }} className={styles.signUp}>
          <Grid item xs={3}></Grid>
          <Grid
            item
            xs={6}
            container
            className={styles.formWrapper}
            maxWidth={isUwmTransitionModal ? '703px' : 'auto'}
            padding={isUwmTransitionModal ? '80px 0px' : '50px 0px'}
            borderRadius={isUWMClient() ? '4px' : '20px'}
          >
            <Grid item xs={3}></Grid>
            <Grid
              item
              xs={isUwmTransitionModal ? 12 : 6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Box className={styles.Logo}>
                <Box
                  component={'img'}
                  src={
                    isUWMClient()
                      ? BRAND_360_LOGO
                      : displayTrubluLogo
                      ? TruBluSocialSmilesLogo
                      : SocialVenuLogo
                  }
                  alt="logo"
                  width={isUWMClient() ? 288 : 146}
                />
              </Box>
              {isUWMClient() && partnerToken && <UwmTransitionModalContent />}
              {step === 'login' && <LoginFormComponent setStep={setStep} />}
              {step === 'forgotPasswordRequest' && (
                <ForgotPasswordRequestComponent setStep={setStep} />
              )}
              {step === 'forgotPasswordSuccess' && (
                <ForgotPasswordSuccessComponent setStep={setStep} />
              )}
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </Box>
    </>
  );
};
