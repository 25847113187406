import { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import {
  Container,
  StepperWrapper,
  SuccessTitle,
  SuccessSubtitle,
  SuccessLabel,
  SuccessValue,
} from './Venuewizard.style';
import { VenueCreationWizardStrings } from '../../common/localization/en';
import { LinearProgressBar } from '../../common/components/LinearProgressBar/LinearProgressBar';
import { useAppDispatch, useTypedSelector } from '../../store';
import { setSelectedVenue } from '../../store/slices/venueSlice';
import { WizardCreationRoute, WebsiteRoutes } from '../../common/constants/routes';

export const WizardSuccess: FC = () => {
  const [progress, setProgress] = useState(0);
  const { createdVenue, program_payload } = useTypedSelector((state) => state.venueCreation);
  const { created, initiated } = useTypedSelector((state) => state.venueCreation.createdVenue);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (initiated && !created) {
      history.push(WizardCreationRoute.WizardFailure);
    }
  }, [created, initiated, history]);

  const handleLetsGo = () => {
    if (createdVenue.payload) {
      dispatch(setSelectedVenue({ id: createdVenue.payload.id }));
      setTimeout(() => {
        history.push(WebsiteRoutes.ContentAll);
      }, 300);
    }
  };

  return (
    <Container>
      <StepperWrapper>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            {progress !== 100 ? (
              <SuccessTitle>{VenueCreationWizardStrings.WeAreCreating}</SuccessTitle>
            ) : (
              <SuccessTitle>{VenueCreationWizardStrings.WizardSuccessTtitle}</SuccessTitle>
            )}
          </Grid>
          {progress !== 100 ? (
            <Grid item xs={12}>
              <SuccessSubtitle>{VenueCreationWizardStrings.WizardSuccessSubtitle1}</SuccessSubtitle>
              <SuccessSubtitle>{VenueCreationWizardStrings.WizardSuccessSubtitle2}</SuccessSubtitle>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={6}>
                  <SuccessLabel>{VenueCreationWizardStrings.VenueSuccessCreate}</SuccessLabel>
                </Grid>
                <Grid item xs={6}>
                  <SuccessValue>{program_payload.programName}</SuccessValue>
                </Grid>
                <Grid item xs={6}>
                  <SuccessLabel>{VenueCreationWizardStrings.CompaingSpecific}</SuccessLabel>
                </Grid>
                <Grid item xs={6}>
                  <SuccessValue>{createdVenue.payload?.subdomain + '.socialv.io'}</SuccessValue>
                </Grid>
                <Grid item xs={6}>
                  <SuccessLabel>{VenueCreationWizardStrings.SocialVenueDashboard}</SuccessLabel>
                </Grid>
                <Grid item xs={6}>
                  <SuccessValue>
                    {window.location.origin + '?program_id=' + program_payload.programId}
                  </SuccessValue>
                </Grid>

                <Grid item xs={6}>
                  <SuccessSubtitle>{VenueCreationWizardStrings.note}</SuccessSubtitle>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={4}>
                <SuccessImage src={Success} />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={6}>
            <LinearProgressBar progress={progress} setProgress={setProgress} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              {/* <Grid item xs={2}>
                <Button color="primary">{VenueCreationWizardStrings.StartOver}</Button>
              </Grid> */}
              <Grid item xs={2}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={progress !== 100}
                  onClick={handleLetsGo}
                >
                  {VenueCreationWizardStrings.LetsGo}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StepperWrapper>
    </Container>
  );
};
