import styled from 'styled-components';
import { Button } from '@mui/material';
import { theme } from '../../../../../../../theme';

export const Title = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  color: ${theme.palette.primary.dark};
`;

export const Label = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 175%;
  /* or 28px */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: ${theme.palette.text.secondary};
`;
export const TextField = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  letter-spacing: 0.15px;
  color: ${theme.palette.text.primary};
`;
export const Wrapper = styled.div`
  background: ${theme.palette.common.white};
  /* Shadow / 20 */

  box-shadow: 0px 10px 40px -6px rgba(10, 25, 107, 0.2);
  border-radius: 8px;
  min-height: 250px;
  width: 100%;
  padding: 20px;
`;

export const DeleteButton = styled(Button)`
  margin-right: 16px;
  color: ${theme.palette.error.main};
`;
