import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    gap: '10px',
  },
  uploadBtn: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 18px',
    height: '44px',
    color: theme.palette.primary.main,
    fontWeight: '600',

    '&:hover': {
      borderWidth: '2px',
    },

    '&:disabled': {
      border: `2px solid ${theme.palette.primary.main}66`,
      color: `${theme.palette.primary.main}66`,
    },
  },
  progressWrapper: {
    marginTop: '10px',
    width: '321px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
  removeButton: {
    color: '#EF0000',
    fontWeight: 600,
    marginTop: '5px',

    '&:hover': {
      backgroundColor: 'white',
    },
  },
}));
