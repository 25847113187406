import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { MonthPicker } from '../../MonthPicker/MonthPicker';
import parseDate from 'date-fns/parse';
import getLastDayOfMonth from 'date-fns/lastDayOfMonth';
import parseISODate from 'date-fns/parseISO';
import maxDate from 'date-fns/max';
import {
  BILLING_START_DATE,
  clearSmsMessageItems,
  DATE_FORMAT,
  resetDateFilter,
  updateDateFilter,
  updateSmsMessagesTableSearch,
} from '../../../../store/slices/smsMessagesSlice';

export const BillingPeriodSection: React.FC = () => {
  const venueCreatedAt = useTypedSelector((store) => store.venue.venue.createdAt);
  const pricingPlan = useTypedSelector((store) => store.BillingSlice.pricingPlan);
  const dateFilter = useTypedSelector((store) => store.smsMessages.dateFilter);

  const billingStartDate = useMemo(() => {
    const createdAtDate = parseISODate(venueCreatedAt);
    return maxDate([createdAtDate, BILLING_START_DATE]);
  }, [venueCreatedAt]);

  const dispatch = useAppDispatch();
  const theme = useTheme();

  useEffect(() => {
    dispatch(resetDateFilter());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="row" spacing="20px" alignItems="center">
      <Typography color="#667085" fontSize="20px" fontWeight="500">
        Billing Period
      </Typography>
      <MonthPicker
        startDate={billingStartDate}
        value={parseDate(dateFilter.from, DATE_FORMAT, new Date())}
        endDate={new Date()}
        onChange={(date) => {
          dispatch(updateSmsMessagesTableSearch(''));
          dispatch(updateDateFilter({ from: date, to: getLastDayOfMonth(date) }));
          dispatch(clearSmsMessageItems());
        }}
      />
      <Box bgcolor="#EAE8FF" borderRadius="16px" p="2px 8px">
        <Typography
          fontSize="10px"
          fontWeight="600"
          color={theme.palette.primary.main}
          lineHeight="18px"
        >
          {pricingPlan?.name}
        </Typography>
      </Box>
    </Stack>
  );
};
