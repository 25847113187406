import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 32px;
`;

export const TitleSectionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
`;
