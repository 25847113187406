import { FC } from 'react';

type iconProps = {
  color?: string;
};

export const SharesIcon: FC<iconProps> = ({ color = '#000' }) => (
  <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0768 19.5066C13.7822 20.3095 12.6811 20.3961 12.2646 19.6491L9.41016 14.5293C9.31972 14.367 9.1859 14.2332 9.02369 14.1428L3.90382 11.2883C3.15682 10.8719 3.24341 9.77071 4.04632 9.47612L17.863 4.40669C18.6627 4.11326 19.4397 4.89022 19.1463 5.68994L14.0768 19.5066Z"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27148 14.2811L18.7465 4.80613"
      stroke={color}
      strokeWidth="1.3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
