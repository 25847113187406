import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.5 7.625V11.5833"
      stroke="#FC4740"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.50021 17.4494H4.70271C1.95563 17.4494 0.807711 15.4861 2.13771 13.0874L4.60771 8.63818L6.93521 4.45818C8.34438 1.91693 10.656 1.91693 12.0652 4.45818L14.3927 8.6461L16.8627 13.0953C18.1927 15.494 17.0369 17.4574 14.2977 17.4574H9.50021V17.4494Z"
      stroke="#FC4740"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.49561 13.9585H9.50272"
      stroke="#FC4740"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ErrorIcon = memo(SvgComponent);
export default ErrorIcon;
