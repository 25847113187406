import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Box, Link, Typography, useTheme } from '@mui/material';
import React from 'react';

import { RewardSMSStrings } from '../../localization/en';
import { HighlightedText } from '../HighlightedText/HighlightedText';
import { IPhonePreview } from './IPhonePreview';

interface ChatNameProps {
  fontSize?: number;
}

export const ChatName: React.FC<ChatNameProps> = ({ children, fontSize = 11 }) => {
  const theme = useTheme();

  const textSize = `${fontSize}px`;
  const iconSize = `${fontSize * 1.8}px`;

  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={textSize} ml="10px">
      <Typography fontSize={textSize} fontWeight="600" color={theme.palette.common.black}>
        {children}
      </Typography>
      <ChevronRightRoundedIcon
        style={{
          color: '#BCBCBE',
          height: iconSize,
          width: iconSize,
        }}
      />
    </Box>
  );
};

interface MessageBoxProps {
  message: string;
  boldSubstrings?: string[];
  image?: string;

  linkTitle?: string;

  time?: string;
  disabled?: boolean;
  showStartBlock?: boolean;
  fontSize?: number;
  width?: string;
}

export const MessageBox: React.FC<MessageBoxProps> = ({
  message,
  boldSubstrings,
  image,
  linkTitle,
  time = '12:41',
  disabled,
  showStartBlock = true,
  fontSize = 12,
  width,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={width || (disabled !== undefined ? '230px' : '200px')}
      p="2px"
      gap="5px"
    >
      {image && (
        <Box position="relative">
          <Box borderRadius="12px" overflow="hidden">
            <Box
              width="196px"
              maxHeight="130px"
              component="img"
              alt="selected-file"
              style={{ objectFit: 'cover' }}
              src={image}
            />
          </Box>
        </Box>
      )}
      <Box position="relative" padding="10px" bgcolor="#E9E9EB" borderRadius="12px">
        <Box
          maxHeight={disabled !== undefined ? '425px' : '310px'}
          overflow="hidden"
          sx={{ whiteSpace: 'pre-line' }}
        >
          <Typography
            mt="6px"
            fontSize={`${fontSize}px`}
            letterSpacing="0.02em"
            lineHeight={1.2}
            color={theme.palette.common.black}
            style={{
              wordBreak: 'break-word',
            }}
          >
            {boldSubstrings ? (
              <HighlightedText text={message} highlights={boldSubstrings} />
            ) : (
              message
            )}
          </Typography>
          {showStartBlock && (
            <Typography mt="12px" fontSize={`${fontSize}px`} color={theme.palette.common.black}>
              Start Now:
            </Typography>
          )}
          {linkTitle && (
            <Link
              mt="-10px"
              color="#0867F4"
              fontWeight="400"
              fontSize={`${fontSize}px`}
              sx={{ wordWrap: 'break-word' }}
            >
              &#91;{linkTitle}&#93;
            </Link>
          )}
          <Typography mt="12px" fontSize={`${fontSize}px`} color={theme.palette.common.black}>
            {RewardSMSStrings.ReplyStopToQuit}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export interface IPhoneSMSPreviewProps extends MessageBoxProps {
  chatName?: string;
  disabled?: boolean;
}

export const IPhoneSMSPreview: React.FC<IPhoneSMSPreviewProps> = ({
  chatName = '+1 (833) 340-0304',
  disabled,
  children, // messageBox will not be rendered if children passed
  ...messageBoxProps
}) => {
  return (
    <IPhonePreview
      background={{
        src: '/sms-iphone-background.png',
        topOffset: 32,
        bottomOffset: 46,
      }}
    >
      <Box display="flex" justifyContent="center" width="100%" height="24px">
        <ChatName>{chatName}</ChatName>
      </Box>
      {/* safari bug MYF-3303 [Dashboard] Invites SMS - The phone preview images and texts not appear */}
      {/* <Box position="fixed"> */}
      <Box>
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt="8px"
          mx="14px"
        >
          <Box alignSelf="flex-start">
            {children || <MessageBox disabled={disabled} {...messageBoxProps} />}
          </Box>
        </Box>
      </Box>
    </IPhonePreview>
  );
};
