import { FC, useState } from 'react';
import { useStyles } from './TimePicker.styles';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { TimeSelectIcon } from '../../../assets/TimeSelectIcon';
import { LengthRange } from '../LengthSelect';
import { SearchOperationType } from '../../../../api/models/searchFilterModels';

interface Props {
  onChange: (range: LengthRange[]) => void;
  minutesInitialVal: number;
  secondsInitialVal: number;
  operation: SearchOperationType.LessThan | SearchOperationType.GreaterThan;
  lengthRange: LengthRange[];
}

export const TimePicker: FC<Props> = ({
  onChange,
  minutesInitialVal,
  secondsInitialVal,
  lengthRange,
  operation,
}) => {
  const classes = useStyles();
  const [minutes, setMinutes] = useState(minutesInitialVal);
  const [seconds, setSeconds] = useState(secondsInitialVal);
  const [isOpen, setIsOpen] = useState(false);

  const getRenderArray = (rangeStep: number, maxVal: number, initialVal: number) => {
    const arraySize = maxVal / rangeStep;
    return [...Array(arraySize + 1).keys()].map((_, index) => {
      const value = index * rangeStep;
      return {
        value,
        selected: value === initialVal,
      };
    });
  };

  const [minutesRenderArray, setMinutesRenderArray] = useState(
    getRenderArray(1, 30, minutesInitialVal),
  );

  const [secondsRenderArray, setSecondsRenderArray] = useState(
    getRenderArray(5, 55, secondsInitialVal),
  );

  const formatValue = (value: number) => {
    return value < 0 ? '--' : value > 9 ? value : `0${value}`;
  };

  const handleMinutesChange = (value: number, index: number) => {
    if (seconds < 0) {
      setSeconds(0);
    }

    setMinutesRenderArray((prev) => {
      const result = prev.map(({ value, selected }) => {
        return {
          value,
          selected: false,
        };
      });

      result[index].selected = true;
      setMinutes(value);

      const currRangeIndex = lengthRange.findIndex((item) => item.operation === operation);
      const resultValue = value * 60 + (seconds > 0 ? seconds : 0);

      if (currRangeIndex > -1) {
        const updatedRange = [...lengthRange];
        updatedRange[currRangeIndex].value = resultValue;
        onChange(updatedRange);
      } else {
        onChange([
          ...lengthRange,
          {
            value: resultValue,
            operation,
          },
        ]);
      }

      return result;
    });
  };

  const handleSecondsChange = (value: number, index: number) => {
    if (minutes < 0) {
      setMinutes(0);
    }

    setSecondsRenderArray((prev) => {
      const result = prev.map(({ value, selected }) => {
        return {
          value,
          selected: false,
        };
      });

      result[index].selected = true;
      setSeconds(value);

      const currRangeIndex = lengthRange.findIndex((item) => item.operation === operation);
      const resultValue = (minutes > 0 ? minutes : 0) * 60 + value;

      if (currRangeIndex > -1) {
        const updatedRange = [...lengthRange];
        updatedRange[currRangeIndex].value = resultValue;
        onChange(updatedRange);
      } else {
        onChange([
          ...lengthRange,
          {
            value: resultValue,
            operation,
          },
        ]);
      }

      return result;
    });
  };

  return (
    <Box>
      <Select
        multiline={true}
        displayEmpty={true}
        open={isOpen}
        onMouseUp={(event) => {
          const target = event.target as HTMLElement;
          const listItemTarget = target.closest('li');

          if (!listItemTarget) {
            setIsOpen((prev) => !prev);
          }
        }}
        renderValue={() => {
          return (
            <Typography className={classes.text}>{`${formatValue(minutes)}:${formatValue(
              seconds,
            )}`}</Typography>
          );
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
              maxHeight: '300px',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#fff',
                height: '20px !important',
                maxHeight: '20px !important',
                borderRadius: '12px',
                margin: '5px 0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#EAECF0',
                borderRadius: '5px',
                height: '40px !important',
                cursor: 'pointer',
              },
            },
          },
        }}
        sx={{
          height: '40px',
          width: '90px',
          border: '1px solid #cacae4',
          paddingRight: 0,

          '& .MuiSelect-select': {
            padding: '6px 0 8px 16px !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none!important',
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: 'none!important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <TimeSelectIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        <MenuItem className={classes.menuItem}>
          <Box className={classes.sectionWrapper}>
            {minutesRenderArray.map(({ value, selected }, index) => {
              return (
                <Button
                  onClick={() => {
                    handleMinutesChange(value, index);
                  }}
                  className={classes.menuButton}
                  sx={{
                    backgroundColor: selected ? '#F9FAFB' : '#FFF',
                  }}
                >
                  {formatValue(value)}
                </Button>
              );
            })}
          </Box>

          <Box className={classes.sectionWrapper}>
            {secondsRenderArray.map(({ value, selected }, index) => {
              return (
                <Button
                  className={classes.menuButton}
                  sx={{
                    backgroundColor: selected ? '#F9FAFB' : '#FFF',
                  }}
                  onClick={() => {
                    handleSecondsChange(value, index);
                  }}
                >
                  {formatValue(value)}
                </Button>
              );
            })}
          </Box>
        </MenuItem>
      </Select>
    </Box>
  );
};
