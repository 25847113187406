import React, { useEffect, useMemo, useRef, useState } from 'react';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { RoundedButton } from '../Button/RoundedButton';
import { Box, rgbToHex } from '@mui/material';
import { googleStorageToCloudinary, setPropertyToCloudinaryUrl } from '../../../services/utilities';
import { DEFAULT_BRAND_COLOR } from '../../constants/defaults';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SettingsEvents } from '../../constants/events/settingsEvents';

interface Props {
  content: string;
  logo?: string;
  size?: number;
  color?: string;
  backgroundColor?: string;
  className?: string;
  btnClassName?: string;
  withDownloadButton?: boolean;
  btnTitle?: string;
  padding?: string;
  qrMargin?: number;
  logoBorderWidth?: string;
  downloadTrigger?: number;
  downloadOnClick?: boolean;
  withHover?: boolean;
}

const DEFAULT_SIZE = 430;
const LOGO_SIZE_COEFF = 0.5;

export const QRCode: React.FC<Props> = ({
  content,
  logo,
  size = DEFAULT_SIZE,
  color = DEFAULT_BRAND_COLOR,
  backgroundColor,
  className,
  btnClassName,
  withDownloadButton,
  btnTitle,
  padding,
  qrMargin,
  logoBorderWidth,
  downloadTrigger,
  downloadOnClick,
  withHover,
}) => {
  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const [isLogoLoading, setIsLogoLoading] = useState(false);
  const [currentLogo, setCurrentLogo] = useState('');
  const { trackEvent } = useTrackEvent();

  const qrCode = useMemo(() => {
    return new QRCodeStyling({});
  }, []);

  const logoTransformed = useMemo(() => {
    if (!logo) return;

    setIsLogoLoading(true);

    let colorVal = color.replace('#', '');
    if (colorVal && colorVal.startsWith('rgb')) {
      colorVal = rgbToHex(colorVal).replace('#', '');
    }
    const logoSize = DEFAULT_SIZE * LOGO_SIZE_COEFF;

    const innerPadding = '15px';
    const backgroundColor = 'e1e5ee';
    const borderWidth = logoBorderWidth || '20px';
    const borderRadiusPx = '30';

    const cloudinaryLogo = googleStorageToCloudinary(logo);

    return setPropertyToCloudinaryUrl(
      cloudinaryLogo,
      `e_make_transparent/c_pad,h_${logoSize},w_${logoSize},b_rgb:${backgroundColor},bo_${innerPadding}_solid_rgb:${backgroundColor}/bo_${borderWidth}_solid_rgb:${colorVal},r_${borderRadiusPx}/`,
      true,
    );
  }, [color, logo, logoBorderWidth]);

  useEffect(() => {
    if (!logo) setCurrentLogo('');
    if (!isLogoLoading && logoTransformed) {
      setCurrentLogo(logoTransformed);
    }
  }, [isLogoLoading, logoTransformed, logo]);

  const qrOptions: Partial<Options> = useMemo(() => {
    return {
      width: size,
      height: size,
      image: currentLogo,
      type: 'svg',
      dotsOptions: {
        color: color || DEFAULT_BRAND_COLOR,
        type: 'extra-rounded',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
      },
      data: content,
      margin: qrMargin || 20,
      imageOptions: {
        crossOrigin: 'anonymous',
        imageSize: 0.5,
      },
      backgroundOptions: {
        color: backgroundColor || '#ffffff',
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backgroundColor, color, content, currentLogo, qrCode, size, logo]);

  useEffect(() => {
    qrCode.update(qrOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrOptions]);

  useEffect(() => {
    if (qrCodeRef.current) {
      qrCode.append(qrCodeRef.current);
    }
  }, [qrCode]);

  const handleDownload = () => {
    const RESOLUTION = 1024;

    new QRCodeStyling({
      ...qrOptions,
      width: RESOLUTION,
      height: RESOLUTION,
    }).download({ name: 'qr', extension: 'png' });
    trackEvent(SettingsEvents.qr_code_download_button_click);
  };

  useEffect(() => {
    if (downloadTrigger) {
      handleDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadTrigger]);

  return (
    <Box
      width="fit-content"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={padding || '32px'}
      borderRadius="16px"
      bgcolor="white"
      style={{
        cursor: downloadOnClick ? 'pointer' : 'default',
      }}
      sx={{
        '&:hover': {
          boxShadow: withHover ? '0px 4px 14px 0px rgba(0, 0, 0, 0.13)' : 'none',
        },
      }}
      onClick={() => {
        if (downloadOnClick) {
          handleDownload();
        }
      }}
      data-qs-button="false"
    >
      <img
        style={{ display: 'none' }}
        src={logoTransformed}
        onLoad={() => setIsLogoLoading(false)}
        alt="qr"
      />
      <div
        ref={qrCodeRef}
        className={className}
        style={{
          width: size,
          height: size,
          zoom: 'calc(1 / 0.9)',
          overflow: 'hidden',
        }}
      />
      {withDownloadButton && (
        <RoundedButton
          title={btnTitle || 'Download QR Code'}
          onClick={handleDownload}
          outlined={true}
          className={btnClassName}
        />
      )}
    </Box>
  );
};
