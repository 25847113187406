import { useTheme } from '@mui/styles';
import React from 'react';

const CampaignWelcomeVideoIcon = () => {
  const theme = useTheme();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M44 30.0001V18.0001C44 8.00012 40 4.00012 30 4.00012H18C8 4.00012 4 8.00012 4 18.0001V30.0001C4 40.0001 8 44.0001 18 44.0001H30C40 44.0001 44 40.0001 44 30.0001Z"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04004 14.22H42.96"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.04 4.21985V13.9399"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.96 4.21985V13.0398"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 28.9002V26.5002C19.5 23.4202 21.68 22.1602 24.34 23.7002L26.42 24.9002L28.5 26.1002C31.16 27.6402 31.16 30.1602 28.5 31.7002L26.42 32.9002L24.34 34.1002C21.68 35.6402 19.5 34.3802 19.5 31.3002V28.9002V28.9002Z"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CampaignWelcomeVideoIcon;
