import { FC, useEffect, useState } from 'react';
import { Box, Button, Switch, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';

import {
  ManagerItemProps,
  useStyles,
  getStatusColor,
  getStatusTitle,
  validationSchema,
} from './ManagerItem.helper';
import { AdminVenueManagementStrings } from '../../localization/en';
import { getLocalDateString } from '../../../services/utilities';

export const ManagerItem: FC<ManagerItemProps> = ({
  id,
  status,
  index,
  name,
  email,
  save,
  cancel,
  sendInvite,
  statusUpdate,
  loading,
  error,
  hideActions,
  setNewManager,
  setIsValidationError,
  lastInvitationSentAt,
}) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email,
      name,
    },
    validationSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      save(values);
    },
  });

  const [tempEmail, setTempEmail] = useState<string>();

  useEffect(() => {
    if (setNewManager && !formik.errors.email && !formik.errors.name) {
      setNewManager(formik.values);
    }
    if (setIsValidationError) {
      setIsValidationError(formik.errors.email || formik.errors.name ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.errors]);

  useEffect(() => {
    setTempEmail(error ? formik.values.email : undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    statusUpdate(event.target.checked);
  };

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit} noValidate autoComplete="off">
      <Box display="flex" alignSelf="center">
        {id && (
          <Switch
            color="primary"
            checked={status === 'ACTIVE'}
            onChange={handleSwitchChange}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        )}
      </Box>
      <Box display="flex" alignItems="center" alignSelf="center" color={getStatusColor(status)}>
        <Box
          bgcolor={getStatusColor(status)}
          width={8}
          height={8}
          marginRight={1}
          borderRadius="50%"
        />
        {getStatusTitle(status)}
      </Box>
      <TextField
        id={`org-manager-${index + 1}-name`}
        label={
          id
            ? `${AdminVenueManagementStrings.Manager} ${index + 1}`
            : AdminVenueManagementStrings.ManagerName
        }
        variant="outlined"
        name="name"
        // InputProps={{ readOnly: Boolean(id) }}
        onChange={formik.handleChange}
        value={formik.values.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
      />
      <TextField
        id={`org-manager-${index + 1}-email`}
        label={
          id
            ? AdminVenueManagementStrings.ManagerEmail.replace(':index', `${index + 1}`)
            : AdminVenueManagementStrings.ManagerEmail.replace(':index', '')
        }
        variant="outlined"
        name="email"
        InputProps={{ readOnly: Boolean(id) }}
        type="email"
        onChange={formik.handleChange}
        value={formik.values.email}
        error={(error && tempEmail === formik.values.email) || Boolean(formik.errors.email)}
        helperText={
          Boolean(formik.errors.email)
            ? formik.errors.email
            : error &&
              tempEmail === formik.values.email &&
              AdminVenueManagementStrings.AddVenueManagerError
        }
      />

      <Box display="flex" alignItems="center" color="#8f94b6" fontSize={14} fontWeight={400}>
        {lastInvitationSentAt && `Invite was sent ${getLocalDateString(lastInvitationSentAt)}`}
      </Box>

      {id && (
        <Box display="flex" justifyContent="space-between">
          {status === 'PENDING' && (
            <LoadingButton
              color="primary"
              loading={loading}
              loadingIndicator={AdminVenueManagementStrings.SendInviteLoading}
              variant="outlined"
              onClick={() => sendInvite()}
            >
              {AdminVenueManagementStrings.SendInvite}
            </LoadingButton>
          )}
        </Box>
      )}
      {!id && !hideActions && (
        <Box display="flex" justifyContent="space-around">
          <Button color="primary" type="button" onClick={() => cancel()}>
            {AdminVenueManagementStrings.Cancel}
          </Button>
          <Button color="primary" type="submit">
            {AdminVenueManagementStrings.Save}
          </Button>
        </Box>
      )}
    </form>
  );
};
