import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 7H2a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1ZM17 11H3a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path d="M11 8H9v10h2V8Z" fill="currentColor" />
    <path
      clipRule="evenodd"
      d="M11 7h2a3 3 0 1 0-3-3v3h1ZM9 7H7a3 3 0 1 1 3-3v3H9Z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

const CouponIcon = memo(SvgComponent);
export default CouponIcon;
