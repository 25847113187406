import React from 'react';

import { RouteLeavingModal } from './RouteLeavingModal';
import { useRouteLeavingGuard } from '../../../hooks/useRouteLeavingGuard';

interface RouteLeavingGuardProps {
  when?: boolean;
  titleText: string;
  contentText: string;
}
export const RouteLeavingGuard: React.FC<RouteLeavingGuardProps> = ({
  when = false,
  titleText,
  contentText,
}: RouteLeavingGuardProps) => {
  const { confirmNavigation, cancelNavigation, showGuard, renderGuardComponent } =
    useRouteLeavingGuard(when);

  return (
    <>
      {renderGuardComponent()}
      {/* Your own alert/dialog/modal component */}
      <RouteLeavingModal
        open={showGuard}
        titleText={titleText}
        contentText={contentText}
        onCancel={cancelNavigation}
        onConfirm={confirmNavigation}
      />
    </>
  );
};
