import { FC, useEffect } from 'react';
import {
  setIsMenuExpanded,
  setIsTabletView,
  setIsWindowLoaded,
} from '../../../store/slices/uiSlice';
import { useAppDispatch } from '../../../store';

export const MenuResizeHandler: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setIsWindowLoaded(true));

    const resizeHandler = () => {
      const isDesktopView = window.innerWidth > 1440;
      dispatch(setIsTabletView(!isDesktopView));
      dispatch(setIsMenuExpanded(isDesktopView));
    };

    resizeHandler();
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <></>;
};
