import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';
import { ClientTypes } from '../../api/models/common';
import {
  SearchFilterApiModel,
  SearchFilterAttributes,
  SearchTargets,
  SearchTargetsType,
} from '../../api/models/searchFilter';
import { VideoApiModel } from '../../api/models/videos';
import { UserApiModel } from '../../api/models/users';
import { ViewsIcon } from '../../common/assets/searchFilterIcons/ViewsIcon';
import { ActiveModal } from '../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../common/components/ActionBar/ActionBarModals';
import { CreateUserFilterModal } from '../../common/components/CreateUserFilterModal/CreateUserFilterModal';
import { CreateVideoFilterModal } from '../../common/components/CreateVideoFilterModal/CreateVideoFilterModal';
import { ICheckedList } from '../../common/components/VideosDetailUserComponent/VideosDetailUserComponent';
import {
  CommonStrings,
  SavedFiltersHeaderStrings,
  SavedFiltersPageStrings,
} from '../../common/localization/en';
import {
  createContentDataTableContent,
  createRewardsRequestTableContent,
  createUserDataTableContent,
  downloadCSVContentDataTable,
  downloadCSVUserDataTable,
} from '../../services/helpers/tableMappers';
import { useClientType } from '../../services/hooks/useClientType';
import { useTypedSelector, useAppDispatch } from '../../store';
import { BatchVideosToSocialVerse } from '../../store/slices/socialVerseSlice';

import { useFiltersStyles } from './Filters.style';
import {
  createDownloadCSVTopGolfTableContent,
  createContentDataTableContent as createTopGolfContentDataTableContent,
} from '../../services/helpers/topgolfTableMappers';
import { TopGolfVideosApiModel } from '../../api/models/topgolfVideos';

export const MAX_LENGTH = 42;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name must be at least 3 characters long')
      .max(MAX_LENGTH, CommonStrings.ExceededCharLimit)
      .required(),
  });

export const generateEntity: {
  [key: string]: 'heroes' | 'videos' | 'campaigns' | 'OrganizationVenueSlice' | 'TopgGolfVideos';
} = {
  VIDEOS: 'videos',
  USERS: 'heroes',
  INCENTIVE_CAMPAIGNS: 'campaigns',
};

export const backButtonRoutes = {
  USERS: '/creators',
  VIDEOS: '/content/all',
  VENUES: '/content/all',
  INCENTIVE_CAMPAIGNS: '/rewards/leaderboard',
};

interface ModalsProps {
  activeModal: ActiveModal;
  handleClose: () => void;
  headers: {
    label: string;
    key: string;
  }[];
  storyItems?: any[];
  content: any;
  setSelectedRows?: (selected: any[]) => void;
  filterName?: string;
}

export const Modals: FC<ModalsProps> = ({
  activeModal,
  handleClose,
  headers,
  content,
  storyItems,
  setSelectedRows,
  filterName,
}) => {
  const dispatch = useAppDispatch();
  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);

  const { addToast } = useToasts();

  const listOfSocialVerses = socialVerseAll || [];

  const initialCheckedList = listOfSocialVerses.map((sv) => ({
    id: sv.id,
    name: sv.name,
    isChecked: false,
    createdAt: sv.createdAt,
  }));

  const [checkedList, setCheckedList] = useState<Array<ICheckedList>>(initialCheckedList ?? []);

  const changeCheckbox = (id: string, isChecked: boolean) => {
    setCheckedList((prev) => [
      ...initialCheckedList.map((sv) => (sv.id === id ? { ...sv, isChecked } : { ...sv })),
    ]);
  };
  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    const isChecked = event.target.checked;

    changeCheckbox(id, isChecked);
  };
  const handleConfirmAddingVideos = (data: ICheckedList[]) => {
    const socialVerseToAssign = checkedList.find((sv) => sv.isChecked);

    if (socialVerseToAssign && selectedRows) {
      dispatch(
        BatchVideosToSocialVerse({
          videoIds: selectedRows.map((row) => row.id),
          id: socialVerseToAssign.id,
        }),
      ).then(() => {
        handleClose();
        addToast(
          SavedFiltersHeaderStrings.AssignedSVMessage.replace(
            '<SocialVerse Name>',
            socialVerseToAssign.name,
          ),
          {
            appearance: 'success',
            autoDismissTimeout: 2500,
          },
        );
      });
    }
  };

  return (
    <>
      {activeModal === 'DOWNLOAD_CSV' &&
        activeModalLiteral[activeModal](
          Boolean(activeModal),
          handleClose,
          headers,
          filterName,
          content,
          setSelectedRows,
        )}
      {activeModal === 'ASSIGN_BONUS_POINTS' &&
        activeModalLiteral[activeModal](Boolean(activeModal), handleClose, storyItems ?? [])}
      {activeModal === 'DOWNLOAD_CSV_LIMIT' &&
        activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
      {activeModal === 'ASSIGN_TO_PLUGIN' &&
        activeModalLiteral[activeModal](
          Boolean(activeModal),
          handleClose,
          checkedList,
          handleConfirmAddingVideos,
          handleChangeCheckbox,
        )}
      {activeModal === 'ASSIGN_TO_PLUGIN_LIMIT' &&
        activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
      {activeModal === 'ASSIGN_BONUS_POINTS_LIMIT' &&
        activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
    </>
  );
};

export const videoHeaders = [
  { label: 'Date Created', key: 'createdAt' },
  { label: 'Id', key: 'id' },
  { label: 'Shortcode', key: 'shortcode' },
  { label: 'Status', key: 'status' },
  { label: 'Thumbnail URL', key: 'thumbnailUrl' },
  { label: 'Video File URL', key: 'url' },
  { label: 'Campaign Name', key: 'campaignName' },
  { label: 'Playback URL', key: 'videoPageUrl' },
  { label: 'Keyword Score', key: 'keywordScore' },
  { label: 'Shares', key: 'shares' },
  { label: 'Views', key: 'views' },
  { label: 'Clicks', key: 'ctaClicks' },
  { label: 'Name', key: 'userDisplayName' }, // TODO: split to firstName and lastName
  { label: 'Phone', key: 'userPhoneNumber' },
  // TODO: add verification status
  { label: 'Email', key: 'userEmail' },
];

export const userHeaders = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Verification Status', key: 'phoneNumberVerified' },
  { label: 'Email', key: 'email' },
  { label: 'Last Clip (date-time)', key: 'lastVideoCreatedAt' },
  { label: 'Date Joined (date-time)', key: 'createdAt' },
  { label: 'Active Campaigns', key: 'activeCampaigns' },
  { label: 'Clips', key: 'videos' },
  { label: 'Shares', key: 'shares' },
  { label: 'Views', key: 'videoViews' },
  { label: 'Clicks', key: 'videoCtaClicks' },
  { label: 'Rewarded', key: 'rewardsFulfilled' },
  { label: 'Status', key: 'status' },
  { label: 'Last Activity (date-time)', key: 'lastVideoCreatedAt' },
];
export const accountHeaders = [
  { label: 'Account Name', key: 'name' },
  { label: 'Pricing Plan', key: 'pricingPlanName' },
  { label: 'Sold By', key: 'soldByName' },
  { label: 'Payment Status', key: 'paymentStatus' },
  { label: 'Setup Status', key: 'setupCompleted' },
  { label: 'Tutorial Status', key: 'tutorialCompleted' },
  { label: 'Date Joined (date-time)', key: 'createdAt' },
  { label: 'Active Campaigns', key: 'numActiveCampaigns' },
  { label: 'Last Invite Sent', key: 'lastCampaignInvitationAddedAt' },
  { label: 'Total Invites', key: 'numInvitesSent' },
  { label: 'Total Clips', key: 'videos' },
  { label: 'Clips in Live SV', key: 'numPublishedVideos' },
  { label: 'Live SV', key: 'socialverses' },
  { label: 'GBP Clips Synced', key: 'numGbpVideos' },
];

export const leaderboardHeaders = [
  { label: 'Full Name', key: 'name' },
  { label: 'Campaign points', key: 'campaignPoints' },
  { label: 'Current points', key: 'points' },
  { label: 'Rewards owed', key: 'rewardsFulfilled' },
];

export const getHeaders = {
  VENUES: videoHeaders,
  VIDEOS: videoHeaders,
  USERS: userHeaders,
  INCENTIVE_CAMPAIGNS: leaderboardHeaders,
};

export const updateSearch = (
  search: string,
  getEntitiesBySearchFilter: (data: any) => any,
  newFilterAttributes: SearchFilterAttributes[],
  venueId: string,
  page: number,
  size: number,
  sort: string[],
) => {
  return getEntitiesBySearchFilter({
    venueId,
    filter: newFilterAttributes,
    pageable: {
      page,
      size,
      sort,
    },
    search,
  });
};

export const getTableContents = (items: UserApiModel[], history: any, activeCampaign: any) =>
  createRewardsRequestTableContent({
    items,
    onRewardClick: (userId) => history.push(`/rewards/leaderboard/${userId}`),
    activeCampaign,
    renderCheckbox: true,
  });

export const getUserDataTableContents = (
  items: UserApiModel[],
  activeCampaign: any,
  clientType: ClientTypes,
  approveRewardHandler: (userId: any) => Promise<void>,
  toggleRedeemPointsModal: () => void,
  isRedeemPointsModal: boolean,
  isHealthCare?: boolean,
) =>
  createUserDataTableContent({
    items,
    activeCampaign,
    venuType: clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    renderCheckbox: true,
    isHealthCare,
  });

export const getDownloadCSVModalUserContents = (
  items: UserApiModel[],
  activeCampaign: any,
  clientType: ClientTypes,
  approveRewardHandler: (userId: any) => Promise<void>,
  toggleRedeemPointsModal: () => void,
  isRedeemPointsModal: boolean,
  isHealthCare?: boolean,
) =>
  downloadCSVUserDataTable({
    items,
    activeCampaign,
    venuType: clientType || ClientTypes.SALES,
    approveRewardHandler,
    toggleRedeemPointsModal,
    isRedeemPointsModal,
    renderCheckbox: true,
    isHealthCare,
  });

export const getTableConfig = (items: VideoApiModel[], isHealthCare?: boolean, totalKeywords = 0) =>
  createContentDataTableContent({
    items,
    handleOpenDeleteModal: () => {},
    handleOpenConfirmationModal: () => {},
    handleOpenAddNewTagModal: () => {},
    renderCheckbox: true,
    isHealthCare,
    totalKeywords,
  });

export const getModalTableConfig = (
  items: VideoApiModel[],
  isHealthCare?: boolean,
  totalKeywords = 0,
) =>
  downloadCSVContentDataTable({
    items,
    handleOpenDeleteModal: () => {},
    handleOpenConfirmationModal: () => {},
    handleOpenAddNewTagModal: () => {},
    renderCheckbox: true,
    isHealthCare,
    totalKeywords,
  });

export const getTopGolfTableConfig = (
  items: TopGolfVideosApiModel[],
  // totalKeywords = 0,
) =>
  createTopGolfContentDataTableContent({
    items,
    renderCheckbox: true,
    handleOpenConfirmationModal: () => {},
    handleAddVideoToCard: () => {},
    // totalKeywords,
  });

export const getDownloadCSVTopGolfTableConfig = (
  items: TopGolfVideosApiModel[],
  // totalKeywords = 0,
) =>
  createDownloadCSVTopGolfTableContent({
    items,
    renderCheckbox: true,
    handleOpenConfirmationModal: () => {},
    handleAddVideoToCard: () => {},
    // totalKeywords,
  });

const titleMap = {
  VIDEOS: 'Clips',
  VENUES: 'Clips',
  USERS: 'Creators',
  INCENTIVE_CAMPAIGNS: 'Leaderboard',
};

interface HeaderProps {
  handleGoBack: () => void;
  handleSaveBtn: () => void;
  target: SearchTargetsType;
  showSaveBtn: boolean;
  filterName: string | undefined;
}
export const Header: FC<HeaderProps> = ({
  handleGoBack,
  handleSaveBtn,
  target,
  showSaveBtn,
  filterName,
}) => {
  const styles = useFiltersStyles();
  const [currenTFilter, setcurrenTFilter] = useState<
    SearchFilterApiModel | SearchFilterAttributes[] | undefined
  >(undefined);
  const [isCreationVideoModal, setCreationVideoModal] = useState(false);
  const { isHealthCare } = useClientType();

  const { newFilterAttributes, activeFilter } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );

  const toggleCreationVideoModal = useCallback(() => {
    setCreationVideoModal(!isCreationVideoModal);
  }, [isCreationVideoModal]);

  const getTarget = () => {
    const result = titleMap[target];
    if (isHealthCare) {
      return result.replaceAll('Creator', 'Patient');
    }

    return result;
  };

  return (
    <header className={styles.Header}>
      <Grid container alignItems="center" width="100%">
        <Grid item xs={2}>
          <Button
            startIcon={<ArrowBackRoundedIcon />}
            variant="outlined"
            className={styles.HeaderBtn}
            onClick={handleGoBack}
          >
            {SavedFiltersPageStrings.Back} {getTarget()}
          </Button>
        </Grid>
        <Grid item xs={10} container alignItems={'center'}>
          <Typography className={styles.Title}>{getTarget()} Filter Results</Typography>

          <Grid
            container
            justifyContent="start"
            alignItems="center"
            width="max-content"
            pr="30px"
            marginLeft="auto"
          >
            <Box
              onClick={() => {
                toggleCreationVideoModal();
                setcurrenTFilter(
                  newFilterAttributes.length
                    ? newFilterAttributes
                    : activeFilter?.filter.attributes,
                );
              }}
              className={styles.UnsavedFilter}
            >
              <span className={styles.Icon}>
                <ViewsIcon />
              </span>
              <span className={styles.UnsavedFilterText}>
                {filterName ? filterName : SavedFiltersPageStrings.UnsavedFilter}
              </span>
            </Box>
            {showSaveBtn && (
              <Box ml="14px">
                <Button
                  className={styles.SaveBtn}
                  onClick={handleSaveBtn}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  {SavedFiltersPageStrings.SaveThisFilterButton}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      {target === SearchTargets.VIDEOS ? (
        <CreateVideoFilterModal
          isCreationModal={isCreationVideoModal}
          toggleCreationModal={toggleCreationVideoModal}
          savedFilter={currenTFilter}
          target={target}
        >
          <></>
        </CreateVideoFilterModal>
      ) : (
        <CreateUserFilterModal
          isCreationModal={isCreationVideoModal}
          toggleCreationModal={toggleCreationVideoModal}
          savedFilter={currenTFilter}
          target={target}
        >
          <></>
        </CreateUserFilterModal>
      )}
    </header>
  );
};
