import { FC } from 'react';

interface Props {
  stepNumber: number;
  fillColor?: string;
}

export const QSProgress: FC<Props> = ({ stepNumber, fillColor = '#FFB515' }) => {
  return (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34.8097 3.13644C40.8952 3.72882 46.6376 6.22943 51.2168 10.281C55.7959 14.3325 58.9773 19.7277 60.3064 25.6956"
        stroke="#E9E9E9"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.9313 33.9953C60.5004 40.2431 58.0586 46.1835 53.971 50.9282C49.8834 55.6729 44.3696 58.9669 38.2544 60.3175"
        stroke={stepNumber > 1 ? '#E9E9E9' : fillColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0047 60.9313C23.7569 60.5004 17.8165 58.0586 13.0718 53.971C8.3271 49.8833 5.03312 44.3696 3.68247 38.2544"
        stroke={stepNumber > 2 ? '#E9E9E9' : fillColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.06872 30.0047C3.4996 23.7569 5.94143 17.8165 10.029 13.0718C14.1166 8.3271 19.6304 5.03312 25.7456 3.68246"
        stroke={stepNumber > 3 ? '#E9E9E9' : fillColor}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.3332 38.3199V22.2266C45.3332 20.6266 44.0265 19.4399 42.4398 19.5733H42.3598C39.5598 19.8133 35.3065 21.2399 32.9332 22.7333L32.7065 22.8799C32.3198 23.1199 31.6798 23.1199 31.2932 22.8799L30.9598 22.6799C28.5865 21.1999 24.3465 19.7866 21.5465 19.5599C19.9598 19.4266 18.6665 20.6266 18.6665 22.2133V38.3199C18.6665 39.5999 19.7065 40.7999 20.9865 40.9599L21.3732 41.0133C24.2665 41.3999 28.7332 42.8666 31.2932 44.2666L31.3465 44.2933C31.7065 44.4933 32.2798 44.4933 32.6265 44.2933C35.1865 42.8799 39.6665 41.3999 42.5732 41.0133L43.0132 40.9599C44.2932 40.7999 45.3332 39.5999 45.3332 38.3199Z"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 23.3203V43.3203"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.3335 27.3203H23.3335"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.3335 31.3203H23.3335"
        stroke={fillColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
