import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './RewardCampaigns.styles';
import { Route, Switch, useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../common/constants/routes';
import { IncentiveAd } from '../rewards/IncentiveAd/IncentiveAd';
import { useClientType } from '../../services/hooks/useClientType';
import { RewardsPageStrings } from '../../common/localization/en';
import { TopBar } from '../../common/components/topBar/TopBar';
import { Campaigns } from './Campaigns/Campaigns';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../common/constants/events/rewardCampaignsEvents';
import { CampaignDetails } from './CampaignDetails/CampaignDetails';

export const RewardCampaigns: FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const { isTopGolfClient } = useClientType();

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent(RewardCampaignsEvents.RewardCampaignsPageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      {!isTopGolfClient ? (
        <Switch>
          <>
            <TopBar
              sectionTitle={RewardsPageStrings.RewardCampaigns}
              withBackButton={location.pathname.split('/').length >= 3}
              backButtonText={RewardsPageStrings.BackToList}
              backButtonPath={WebsiteRoutes.RewardCampaigns}
            />
            <Route exact path={WebsiteRoutes.RewardCampaigns}>
              <Campaigns />
            </Route>
            <Route path={[WebsiteRoutes.CampaignDetails, `${WebsiteRoutes.CampaignDetails}/*`]}>
              <CampaignDetails />
            </Route>
          </>
        </Switch>
      ) : (
        <IncentiveAd />
      )}
    </Box>
  );
};
