import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './StatusIndicator.styles';

type StatusType = 'ACTIVE' | 'INACTIVE' | 'INVITED' | 'PENDING';

interface StatusData {
  name: string;
  backgroundColor: string;
  textColor: string;
  fontWeight: number;
}

interface Props {
  status: StatusType;
}

const StatusIndicator: FC<Props> = ({ status }) => {
  const styles = useStyles();

  const StatusMap: Record<StatusType, StatusData> = {
    ACTIVE: {
      name: 'Creator',
      backgroundColor: '#ECFDF3',
      textColor: '#027A48',
      fontWeight: 400,
    },
    INACTIVE: {
      name: 'Deactivated',
      backgroundColor: '#ECFDF3',
      textColor: '#027A48',
      fontWeight: 400,
    },
    INVITED: {
      name: 'Invited',
      backgroundColor: '#F2F4F7',
      textColor: '#344054',
      fontWeight: 500,
    },
    PENDING: {
      name: 'Pending',
      backgroundColor: '#F0F9FF',
      textColor: '#026AA2',
      fontWeight: 400,
    },
  };

  return (
    <Box className={styles.container}>
      <Typography
        className={styles.indicator}
        sx={{
          backgroundColor: StatusMap[status]?.backgroundColor,
          color: StatusMap[status]?.textColor,
          fontWeight: StatusMap[status]?.fontWeight,
        }}
      >
        {StatusMap[status]?.name || '---'}
      </Typography>
    </Box>
  );
};

export default StatusIndicator;
