import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDownloadCSVTablesStyles = makeStyles((theme: Theme) => ({
  VideoFilterTableStyles: {
    '& table': {
      borderSpacing: '0 !important',
    },
    '& tr': {
      height: '45px',
      lineHeight: '1',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: theme.palette.common.white,
      paddingTop: '20px !important',
      paddingBottom: '10px !important',
      '&:nth-child(1)': {
        paddingLeft: '25px !important',
        width: 'auto !important',
      },
      '&:nth-child(2)': {
        width: '8% !IMPORTANT',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3)': {
        width: '8% !IMPORTANT',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4)': {
        width: '8% !IMPORTANT',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        width: '8% !IMPORTANT',
      },
      '&:nth-child(5) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        width: '8% !IMPORTANT',
      },
      '&:nth-child(6) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(7)': {
        width: '8% !IMPORTANT',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        width: '8% !IMPORTANT',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9)': {
        width: '8% !IMPORTANT',
        paddingRight: '35px !important',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      fontSize: '12px',
      '&:nth-child(1)': {
        paddingLeft: '35px !important',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7) div': {
        margin: '0 auto',
      },
      '&:nth-child(8)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)  div': {
        margin: '0 auto',
      },
      '&:nth-child(9)': {
        paddingRight: '35px !important',
      },
      '&:nth-child(9)  div': {
        margin: '0 auto',
      },
    },
  },

  TopGolfVideoFilterTableStyles: {
    '& table': {
      borderSpacing: '0 !important',
    },
    '& tr': {
      height: '45px',
      lineHeight: '1',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: theme.palette.common.white,
      paddingTop: '20px !important',
      paddingBottom: '10px !important',
      '&:nth-child(1)': {
        width: 'min-content',
        paddingLeft: '30px !important',
      },
      '&:nth-child(2)': {
        // width: '10% !important',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3)': {
        // width: '10% !important',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4)': {
        // width: '10% !important',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        // width: '10% !important',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6)': {
        // width: '10% !important',
        margin: '0 auto',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7)': {
        // width: '10% !important',
        margin: '0 auto',
      },
      '&:nth-child(7) div': {
        // width: '10% !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        // width: '10% !important',
        margin: '0 auto',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      fontSize: '12px !important',
      '&:nth-child(1)': {
        paddingLeft: '30px !important',
      },
      '&:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6) div': {
        margin: '0 auto',
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingRight: '35px !important',
        paddingLeft: '35px',
      },
      '&:nth-child(7)>div': {
        // outline: '1px solid red',
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(7)>div>div': {
        width: '110px',
        minWidth: '110px !important',
      },
      '&:nth-child(7) span': {
        fontSize: '12px !important',
      },
    },
  },

  UsersFilterTableStyles: {
    '& table': {
      borderSpacing: '0 !important',
    },
    '& tr': {
      height: '45px',
      lineHeight: '1',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: theme.palette.common.white,
      paddingTop: '20px !important',
      paddingBottom: '10px !important',
      '&:nth-child(1)': {
        paddingLeft: '20px !important',
        width: 'auto !important',
      },
      '&:nth-child(2)': {
        width: '10% !important',
        paddingRight: '10px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3)': {
        width: '8% !important',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4)': {
        width: '8% !important',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        width: '8% !important',
      },
      '&:nth-child(5) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        width: '8% !important',
      },
      '&:nth-child(6) div': {
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(7)': {
        width: '8% !important',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8)': {
        width: '8% !important',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9)': {
        width: '8% !important',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(10)': {
        '& div': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
    '& td': {
      fontSize: '12px',
      '&:nth-child(1)': {
        paddingLeft: '30px !important',
      },
      '&:nth-child(1) div': {
        fontSize: '12px',
      },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(2) span': {
        fontSize: '12px',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&:nth-child(8) span': {
        paddingLeft: '0',
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(9)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'inherit',
      },
      '&:nth-child(9) p': {
        fontSize: '12px',
        paddingRight: '35px !important',
      },
      '&:nth-child(10) p': {
        fontSize: '12px',
        textAlign: 'center',
      },
    },
  },

  LeaderboardFilterResultsTable: {
    '& th': {
      '&:nth-child(1)': {
        // backgroundColor: 'blue',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    '& td': {
      '&:nth-child(1)': {},
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(2)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(3)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
      '&:nth-child(4)': {
        paddingLeft: 0,
      },
      '&:nth-child(4)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '14px',
      },
    },
  },
  toolbar: {
    zIndex: '10',
    marginTop: '20px',
    background: theme.palette.common.white,
    width: 'auto',
    paddingLeft: '20px',
    paddingBottom: '20px',
  },
  Btn: {
    marginLeft: '14px',
    width: 'fit-content',
    fontWeight: '500',
    fontSize: '14px',
    color: theme.palette.common.gray,
  },
}));
