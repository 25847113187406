import React from 'react';

import tubluLogoUrl from './TruBluSocialSmiles-Logo-Square-White.png';

interface TrubluIconProps extends React.CSSProperties {}

export const TrubluIcon: React.FC<TrubluIconProps> = (cssProperties) => {
  return (
    <img
      src={tubluLogoUrl}
      alt="trublu-icon"
      style={{
        userSelect: 'none',
        pointerEvents: 'none',
        width: '61px',
        height: '72px',
        ...cssProperties,
      }}
    />
  );
};
