import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useEducationalCardsPreviewStyles = makeStyles((theme: Theme) => ({
  ContentWrapperTopGolfTimeSlotsSection: {
    minHeight: '470px',
    position: 'relative',
    zIndex: 0,
    paddingTop: 'var(--iphone-header-height)',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    borderRadius: '24px',
    overflow: 'hidden',
  },
  SliderImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    width: '100%',
    height: '100%',
    objectPosition: 'bottom',
  },
  SlotPreviewContainer: {
    marginTop: '80px',
  },
  SlotContainer: {
    position: 'relative',
    top: 66,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  Slot: {
    borderRadius: '5px',
    backgroundColor: '#0B4C8C',
  },
  ActiveSlot: {
    marginLeft: '3px',
    marginRight: '3px',
    backgroundColor: '#F4B93B',
  },
  TypographySlot: {
    color: theme.palette.common.white,
    padding: '5px 10px',
    fontWeight: 800,
    fontSize: '9px !important',
  },
}));
