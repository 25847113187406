import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { ErrorsStrings } from '../../../common/localization/en';
import { urlValidationSchema } from '../../../common/validation/commonValidation';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: theme.palette.common.white,
    borderRadius: '15px',
    padding: '45px 30px',
    height: '100%',
    position: 'relative',
  },
  organizationName: {
    lineHeight: '34px',
    fontSize: '20px',
    fontWeight: '500',
  },
  date: {
    color: '#C0C3C8',
    fontSize: '12px',
    lineHeight: '24px',
  },
  imageWrapper: {
    borderRadius: '10px',
    border: `1px solid ${theme.palette.common.imageSelectorBackground}`,
    backgroundColor: theme.palette.common.imageSelectorBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70px',
    height: '70px',
    marginRight: '20px',
  },
  image: {
    width: '70px',
    height: '70px',
    padding: 5,
    objectFit: 'contain',
  },
  inputWrapper: {
    marginTop: '15px',
    marginBottom: '11px',
    position: 'relative',
    maxWidth: '190px',
    height: '45px',
    cursor: 'pointer',
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    opacity: 0,
  },
  btnContainer: {
    paddingTop: '20px',
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
    paddingRight: '54px',
  },
  btnSave: {
    borderRadius: '30px',
    marginLeft: '30px',
  },
  btnCancel: {
    borderRadius: '30px',
    marginLeft: '10px',
  },
  btn: {
    width: '135px',
    borderRadius: '30px',
    '&:focus': {
      outline: 0,
    },
  },
  label: {
    marginTop: '15px',
    marginBottom: '11px',
    fontSize: '14px',
    lineHeight: '26px',
  },
  label__company: {
    marginTop: '0',
  },
  website: {
    padding: '9px 16px',
    border: '1px solid #e1e5ee',
    borderRadius: '10px',
  },
  modal: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'end',
    bottom: '20px',
    width: '50%',
    right: '0px',
    paddingTop: '18px',
  },

  phoneSection: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: '#F5F6F8',
  },
  phone: {
    width: '381px',
    height: '444px',
  },
  titleIcon: {
    marginBottom: '24px',
    width: '70px',
    height: '70px',
  },
  title: {
    fontSize: '32px',
    fontWeight: '500',
    lineHeight: '52px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  description: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '22px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
  },
  marginTop: {
    marginTop: '15px',
  },
  btnWrapper: {
    display: 'flex',
    marginTop: '80px',
    height: '45px',
    width: '276px',
    justifyContent: 'space-between',
  },
  divider: {
    width: '50%',
    marginTop: '15px',
  },
  colorInputWrapper: {
    width: '430px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    border: '1px solid #E1E5EE',
    borderRadius: '10px',
  },
  select: {
    border: 'none !important',

    '&>.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  inputText: {
    padding: '8px 20px',
    fontWeight: 400,
    fontSize: '14px',
    lineHight: '26px',
    color: theme.palette.common.deepDark,
  },
  colorSelectInput: {
    position: 'absolute',
    right: 0,
    '&>div>.ColorPicker-MuiButtonBase-root, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiButton-label>div, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiTouchRipple-root':
      {
        borderRadius: '50%',
      },
  },
  palette: {
    marginTop: '25px',
  },
  palette__title: {
    marginTop: '15px',
  },
}));

const required = ErrorsStrings.FieldIsRequired;
const str = Yup.string();

export const validationSchemaCompany = () =>
  Yup.object().shape({
    name: str
      .min(3, ErrorsStrings.Minlength3characters)
      // .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    address: str
      .min(5, ErrorsStrings.Minlength5characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    city: str
      .min(2, ErrorsStrings.Minlength2characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .trim()
      .required(required),
    state: str
      .min(3, ErrorsStrings.Minlength3characters)
      .max(48, ErrorsStrings.Maxlength48characters)
      .required(required),
    zip: str.length(5, ErrorsStrings.InvalidZipCode).required(required),
    website: urlValidationSchema(),
  });
