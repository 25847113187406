import React, { useState } from 'react';
import { useStyles } from './CampaignHistory.styles';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CampaignHistoryStrings } from '../../../../localization/en';
import ArrowRightSmall from '../../../../assets/ArrowRightSmall';
import { useTypedSelector } from '../../../../../store';

const CampaignHistory = () => {
  const styles = useStyles();

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);

  const { userIncentiveCampaigns } = useTypedSelector((state) => state.campaigns);

  const handleHistoryOpen = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  return (
    <>
      <div className={styles.container} onClick={handleHistoryOpen}>
        <Typography className={styles.title}>{CampaignHistoryStrings.Title}</Typography>
        <Box sx={{ transform: isHistoryOpen ? 'rotate(90deg)' : 'none' }}>
          <ArrowRightSmall stroke="#1A1538" />
        </Box>
      </div>
      {isHistoryOpen && (
        <Box className={styles.tableWrapper}>
          <Table>
            <TableHead>
              <TableCell width={250} className={styles.tableHeadCell}>
                {CampaignHistoryStrings.Name}
              </TableCell>
              <TableCell align="center" className={styles.tableHeadCell}>
                {CampaignHistoryStrings.Videos}
              </TableCell>
              <TableCell align="center" className={styles.tableHeadCell}>
                {CampaignHistoryStrings.GoalMet}
              </TableCell>
              <TableCell align="center" className={styles.tableHeadCell}>
                {CampaignHistoryStrings.Rewarded}
              </TableCell>
            </TableHead>
            <TableBody>
              {userIncentiveCampaigns.map(
                (campaign) =>
                  !campaign.active && (
                    <TableRow>
                      <TableCell className={styles.tableRowCell} sx={{ color: '#000' }}>
                        {campaign.campaign.name}
                      </TableCell>
                      <TableCell
                        className={styles.tableRowCell}
                        align="center"
                        sx={{ color: '#1A1538' }}
                      >
                        {campaign.videos}
                      </TableCell>
                      <TableCell className={styles.tableRowCell} align="center">
                        <Box className={styles.borderedCell}>
                          {campaign.points >= campaign.campaignPoints
                            ? CampaignHistoryStrings.Yes
                            : CampaignHistoryStrings.No}
                        </Box>
                      </TableCell>
                      <TableCell className={styles.tableRowCell} align="center">
                        <Box className={styles.borderedCell}>
                          {campaign.fulfilled
                            ? CampaignHistoryStrings.Yes
                            : CampaignHistoryStrings.No}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ),
              )}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
};

export default CampaignHistory;
