import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { FC, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { UserApiModel } from '../../../../api/models/users';
import { VideoApiModel } from '../../../../api/models/videos';
import { getTopGolfVideoSharesById } from '../../../../store/slices/topGolfVideosSlice';
import { getVideoSharesById } from '../../../../store/slices/videosSlice';
import { SettingsPageStrings } from '../../../localization/en';
import { BottomToolbar } from '../../BottomToolbar/BottomToolbar';
import { EditThumbnail, ThumbnailFrame } from '../../EditThumbnail/EditThumbnail';
import { TopGolfVideoInfo } from '../../TopGolfVideoInfo/TopGolfVideoInfo';
import { Transcript } from '../../Transcript/Transcript';
import { VideoComponent } from '../../VideoComponent/VideoComponent';
import { VideosDetailUserComponent } from '../../VideosDetailUserComponent/VideosDetailUserComponent';
import { VideosDetailVideoData } from '../../VideosDetailVideoData/VideosDetailVideoData';
import { VideoPlaybackModal } from './VideoPlaybackModal';

import { useTypedSelector } from '../../../../store';
import MessageIcon from '../../../assets/ClipsDetailsIcons/MessageIcon';
import PublishedIcon from '../../../assets/ClipsDetailsIcons/PublishedIcon';
import StatsIcon from '../../../assets/ClipsDetailsIcons/StatsIcon';
import VideoAuthorInfo from '../../VideosDetailUserComponent/VideoAuthorInfo/VideoAuthorInfo';
import { theme } from '../../../../theme';

interface ModalInfoProps {
  userDateCreated: string;
  activeCampaign: CampaignsApiModel | null;
  isTopGolf: boolean;
  subdomain: string;
  userData?: UserApiModel;
  video: VideoApiModel | TopGolfVideosApiModel;
  thumbnailFrames: ThumbnailFrame[];
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  handleOpenDownloadVideoDialog: () => void;
  convertedRefferals: number;
  handleDeleteModalOpen: () => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  regenerateThumbnails: (interval: number) => void;
  areFramesLoading: boolean;
  activeTabIndex?: number;
  onUpdateThumbnail?: () => void;
}

export const ContentModalInfo: FC<ModalInfoProps> = ({
  userDateCreated,
  activeCampaign,
  video,
  handleOpenDownloadVideoDialog,
  convertedRefferals: convertedReferrals,
  isTopGolf,
  subdomain,
  handleDeleteModalOpen,
  handleAddVideoToCard,
  thumbnailFrames,
  userData,
  regenerateThumbnails,
  areFramesLoading,
  activeTabIndex = 0,
  onUpdateThumbnail,
}) => {
  const thumbnailRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const storyLink = `https://${subdomain}${SettingsPageStrings.DomainName}/s?id=${video.shortcode}`;
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!video.sharesAnalyticsData) {
      if (video.id) {
        if (!isTopGolf) {
          dispatch(getVideoSharesById({ id: video.id }));
        } else if (isTopGolf) {
          dispatch(getTopGolfVideoSharesById({ id: video.id }));
        }
      }
    }
  }, [video.sharesAnalyticsData, dispatch, isTopGolf, video.id]);

  const [isOpen, setIsOpen] = useState(false);
  const handleOpenPlayVideoModal = () => {
    setIsOpen(!isOpen);
    setIsPlaying(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsPlaying(false);
  };
  const handleAddVideoToCardClick = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
    if (handleAddVideoToCard) {
      handleAddVideoToCard(e, id, false);
    }
  };

  const [tab, setTab] = useState(activeTabIndex);

  const handleChangeTab = (_event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const socialVerseAll = useTypedSelector((state) => state.SocialVerseSlice.socialVerseAll);

  const listOfSocialVerses = useMemo(() => {
    return socialVerseAll
      ? socialVerseAll
          .filter((item) => item.accountId === venueId)
          .filter(
            (item) => item.topVideos && item.topVideos.some((item) => item.videoId === video.id),
          )
      : [];
  }, [socialVerseAll, venueId, video.id]);

  return (
    <Grid
      container
      spacing={2}
      alignContent="center"
      justifyContent="center"
      style={{ position: 'initial', width: '100%', marginLeft: '0' }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <VideoPlaybackModal
        isOpen={isOpen}
        isTopGolf={isTopGolf}
        videoUrl={video.url}
        onClose={handleClose}
        thumbnailUrl={video.thumbnailUrl}
      />
      <Box margin="20px 30px 20px">
        <Grid>
          <Grid>
            <VideoAuthorInfo />
          </Grid>
        </Grid>
        <Box style={{ height: 'calc(-218px + 100vh)', overflow: 'hidden auto' }}>
          <Grid container direction="row" wrap="nowrap">
            <VideoComponent
              url={video.url}
              thumbnail={video.thumbnailUrl}
              isTopGolf={isTopGolf}
              handleOpenModal={handleOpenPlayVideoModal}
              isPlaying={isPlaying}
            />
            <Box style={{ marginLeft: '13px' }}>
              <Box style={{ height: '34px' }}>
                <Tabs value={tab} onChange={handleChangeTab}>
                  <Tab
                    style={{
                      padding: '10px 8px',
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                    label={
                      <>
                        <PublishedIcon color={tab === 0 ? theme.palette.primary.main : '#667085'} />

                        {listOfSocialVerses.length > 0 && (
                          <Typography
                            style={{
                              marginLeft: '8px',
                              width: '29px',
                              height: '24px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              background: theme.palette.primary.light,
                              color: theme.palette.primary.main,
                              fontWeight: 500,
                              fontSize: '13px',
                              borderRadius: '16px',
                            }}
                          >
                            {listOfSocialVerses.length}
                          </Typography>
                        )}
                      </>
                    }
                  />
                  <Tab
                    label={
                      <MessageIcon color={tab === 1 ? theme.palette.primary.main : '#667085'} />
                    }
                  />
                  <Tab
                    label={<StatsIcon color={tab === 2 ? theme.palette.primary.main : '#667085'} />}
                  />
                </Tabs>
              </Box>
              <Box style={{ marginTop: '8px', width: '414px' }}>
                {tab === 0 &&
                  (!isTopGolf ? (
                    <Grid marginTop={'16px'}>
                      <VideosDetailUserComponent
                        activeCampaign={activeCampaign}
                        userDateCreated={userDateCreated}
                        userId={isTopGolf ? '' : (video as VideoApiModel).userId}
                        userPhotoUrl={isTopGolf ? '' : (video as VideoApiModel).userPhotoUrl}
                        userName={
                          isTopGolf
                            ? ''
                            : (video as VideoApiModel).userDisplayName ||
                              (video as VideoApiModel).userName
                        }
                        isTopGolf={isTopGolf}
                        storyID={video.id}
                        storyStatus={video.status}
                        handleAddVideoToCard={handleAddVideoToCardClick}
                        userData={userData}
                      />
                    </Grid>
                  ) : (
                    <TopGolfVideoInfo
                      shotTime={(video as TopGolfVideosApiModel).shotTime}
                      teeTime={(video as TopGolfVideosApiModel).teeTime}
                      shortcode={(video as TopGolfVideosApiModel).shortcode}
                    />
                  ))}
                {tab === 1 &&
                  (!isTopGolf ? (
                    <Grid>
                      <Transcript
                        trancsriptText={video.transcript || ''}
                        keywords={keywords ? keywords.map((item) => item.keyword) : []}
                        keywordsScore={
                          'keywordScore' in video ? Number(video.keywordScore?.toFixed(2)) || 0 : 0
                        }
                      />
                    </Grid>
                  ) : null)}
                {tab === 2 && (
                  <VideosDetailVideoData
                    views={video.views}
                    shares={video.shares}
                    ctaClicks={video.ctaClicks}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid ref={thumbnailRef}>
            <EditThumbnail
              isOpen={true}
              handleClose={() => {}}
              storyThumbnailUrl={video.thumbnailUrl}
              storyAltThumbnailUrl={video.altThumbnailUrl}
              storyUrl={video.url}
              storyId={video.id}
              isTopGolf={isTopGolf}
              frames={thumbnailFrames}
              regenerateThumbnails={regenerateThumbnails}
              areFramesLoading={areFramesLoading}
              onUpdateThumbnail={onUpdateThumbnail}
            />
          </Grid>
          <BottomToolbar
            storyPageUrl={isTopGolf ? storyLink : (video as VideoApiModel).videoPageUrl}
            handleOpenDownloadVideoDialog={handleOpenDownloadVideoDialog}
            handleDeleteModalOpen={handleDeleteModalOpen}
            isTopGolf={isTopGolf}
          />
        </Box>
      </Box>
    </Grid>
  );
};
