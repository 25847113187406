import React, { FC, forwardRef, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  BaseTextFieldProps,
  FormHelperText,
} from '@mui/material';

import { theme } from '../../../theme';
import { EmojiPicker } from '../Emoji/EmojiPicker';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
  textArea: {
    '& .MuiInputBase-root': {
      overflow: 'hidden',
    },

    '& textarea': {
      overflow: 'auto !important',
      minHeight: '160px',
      maxHeight: '160px !important',

      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
        borderRadius: '10px',
        backgroundClip: 'padding-box',
      },
    },
  },
}));

export enum AdornmentPosition {
  Start = 'start',
  End = 'end',
}

interface WithCharLimitProps {
  enabled?: boolean;
}

const WithCharLimit: FC<WithCharLimitProps> = ({ enabled, children }) => {
  if (!enabled) return <>{children}</>;

  return <Box position="relative">{children}</Box>;
};

interface WithFlexWrapperProps {
  enabled: boolean;
  fullWidth?: boolean;
}

const WithFlexWrapper: FC<WithFlexWrapperProps> = ({ enabled, fullWidth, children }) => {
  if (!enabled) return <>{children}</>;

  return (
    <Box display="flex" width={fullWidth ? '100%' : 'auto'}>
      {children}
    </Box>
  );
};

interface SideButtonProps {
  customComponent?: React.ReactNode; // if custom component is set -> rest props ignores
  text?: string;
  onClick?: () => void;
  width?: string;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  disabled?: boolean;
}

interface EmojiPickerProps {
  show?: boolean;
  iconSize?: string;
  selectedEmoji?: string;
  onSelect?: (emojiCode: string) => void;
  onDeselect?: () => void;
}

export interface TextFieldProps {
  label?: string;
  displayLabel?: boolean;
  name?: string;
  value?: string | number;
  errorText?: string;
  type?: string;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onChange?: (value: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onEnterPress?: () => void;
  setFieldTouched?: (name: string, isTouched: boolean) => void;
  autocapitalize?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  adornmentPosition?: AdornmentPosition;
  adornment?: string | JSX.Element;
  readOnly?: boolean;
  focused?: boolean;
  placeholder?: string;
  helperText?: boolean;
  multiline?: boolean;
  rows?: number;
  className?: string;
  buttonProps?: SideButtonProps;
  emojiPickerProps?: EmojiPickerProps;
  inputRef?: BaseTextFieldProps['inputRef'];
  characterLimit?: number;
  inputProps?: BaseTextFieldProps['inputProps'];
  autoComplete?: BaseTextFieldProps['autoComplete'];
  autoFocus?: BaseTextFieldProps['autoFocus'];
  inputStyle?: React.CSSProperties;
  adornmentStyles?: string;
  hideErrorText?: boolean;
  id?: string;
  hideCharLimit?: boolean;
}

export const TextFieldComponent = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      displayLabel = true,
      name,
      value,
      errorText,
      type = 'text',
      setFieldTouched,
      onChange,
      onEnterPress,
      onBlur = () => {},
      autocapitalize = false,
      disabled = false,
      fullWidth = false,
      readOnly = false,
      focused = false,
      adornmentPosition = AdornmentPosition.Start,
      adornment,
      placeholder,
      helperText,
      multiline,
      rows,
      className,
      buttonProps,
      emojiPickerProps,
      inputRef,
      characterLimit: maxLength,
      inputProps,
      autoComplete = 'none',
      autoFocus,
      inputStyle,
      adornmentStyles,
      hideErrorText,
      id,
      hideCharLimit = false,
    },
    ref,
  ) => {
    const classes = useStyles();
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);

    const showEmojiPicker = useMemo(() => emojiPickerProps && emojiPickerProps.show, [
      emojiPickerProps,
    ]);

    const showSideButton = !!buttonProps;

    const sideButtonAdornment = useCallback(() => {
      if (!showSideButton || !buttonProps) return <></>;

      const paddingGapToRemove = '13px';

      return (
        <InputAdornment
          position="end"
          className={adornmentStyles}
          style={{
            marginRight: `-${paddingGapToRemove}`,
            color: 'white',
          }}
        >
          {buttonProps.customComponent || (
            <Button
              onClick={buttonProps.onClick}
              style={{
                width: buttonProps.width || 'auto',
                height: buttonProps.height || '40px',
                borderRadius: '10px',
              }}
              color="primary"
              variant="contained"
              size="large"
              disabled={buttonProps.disabled}
            >
              <Typography
                color={theme.palette.common.white}
                fontSize={buttonProps.fontSize || '14px'}
                fontWeight={buttonProps.fontWeight || '600'}
              >
                {buttonProps.text}
              </Typography>
            </Button>
          )}
        </InputAdornment>
      );
    }, [buttonProps, showSideButton, adornmentStyles]);

    const emojiPickerAdornment = useCallback(() => {
      return (
        <InputAdornment
          position="start"
          style={{
            margin: 0,
            color: 'white',
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            // width={emojiPickerProps?.iconSize || '20px'}
            onClick={(e) => {
              e.stopPropagation();
              setIsEmojiPickerOpen((v) => !v);
            }}
          >
            {/* hided because of https://socialvenu.atlassian.net/browse/MYF-3130, 092022*/}
            {/* {emojiPickerProps?.selectedEmoji ? (
              <Emoji code={emojiPickerProps?.selectedEmoji} />
            ) : (
              <EmojiPickerButton active={isEmojiPickerOpen} />
            )} */}
          </Box>
        </InputAdornment>
      );
    }, []);

    const AdornmentBlock = useMemo(() => {
      const key = adornmentPosition === AdornmentPosition.Start ? 'startAdornment' : 'endAdornment';

      const adornmentValue = adornment
        ? {
            [key]: <InputAdornment position={adornmentPosition}>{adornment}</InputAdornment>,
            readOnly,
          }
        : { readOnly };

      if (showEmojiPicker) {
        adornmentValue.startAdornment = emojiPickerAdornment();
      }

      if (showSideButton) {
        adornmentValue.endAdornment = sideButtonAdornment();
      }

      return adornmentValue;
    }, [
      adornment,
      adornmentPosition,
      emojiPickerAdornment,
      readOnly,
      showEmojiPicker,
      showSideButton,
      sideButtonAdornment,
    ]);

    const autoCompleteOnPassword = useMemo(
      () =>
        type === 'password'
          ? {
              autoComplete: 'new-password',
            }
          : {},
      [type],
    );

    const clearEmojiOnBackspaceKeyPress = useMemo(
      () => ({
        onKeyDown: (key: React.KeyboardEvent<HTMLInputElement>) => {
          if (!value && key.code === 'Backspace') {
            emojiPickerProps?.onDeselect?.();
          }
        },
      }),
      [emojiPickerProps, value],
    );

    const labelValue = useMemo(() => (helperText ? '' : errorText || label), [
      errorText,
      label,
      helperText,
    ]);

    const onBlurHandler = (event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (setFieldTouched && name) {
        setFieldTouched(name, true);
      }
      onBlur(event);
    };

    const onChangeHandler = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (autocapitalize && e.target.value.length === 1) {
          e.target.value = e.target.value.toUpperCase();
        }
        onChange?.(e);
      },
      [autocapitalize, onChange],
    );

    return (
      // Need add flex wrapper for right-side button support.
      // If no buttonProps provided -> don't wrap to save existing layouts
      <WithCharLimit enabled={!!maxLength}>
        <WithFlexWrapper enabled={!!buttonProps} fullWidth={fullWidth}>
          <Box position="relative">
            {emojiPickerProps && (
              <EmojiPicker
                show={isEmojiPickerOpen}
                previewPosition="none"
                onEmojiSelect={({ shortcodes }) => emojiPickerProps?.onSelect?.(shortcodes)}
                onClickOutside={() => setIsEmojiPickerOpen(false)}
                containerProps={{
                  position: 'absolute',
                  top: '48px',
                }}
              />
            )}
          </Box>
          {maxLength && !hideCharLimit && (
            <Box
              className="charsLimit"
              style={{
                position: 'absolute',
                top: '-36px',
                right: '6px',
                color: errorText ? theme.palette.error.main : theme.palette.common.gray,
                fontSize: '14px',
                lineHeight: '26px',
                fontWeight: 400,
                overflow: 'hidden',
              }}
            >{`${value?.toString().length || 0}/${maxLength}`}</Box>
          )}
          <TextField
            disabled={disabled}
            name={name}
            error={Boolean(errorText)}
            label={displayLabel ? labelValue : ''}
            value={value}
            type={type}
            variant="outlined"
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
            fullWidth={fullWidth}
            InputProps={AdornmentBlock}
            placeholder={placeholder}
            inputProps={{
              ...autoCompleteOnPassword,
              ...clearEmojiOnBackspaceKeyPress,
              style: {
                paddingLeft: showEmojiPicker ? '10px' : undefined,
                overflow: 'auto',
                ...inputStyle,
              },
              maxLength,
              ...inputProps,
            }}
            focused={focused}
            /* helperText={helperText ? errorText : ''} */
            multiline={multiline}
            rows={rows}
            className={multiline ? classes.textArea : className}
            onKeyDown={(key) => {
              if (key.code === 'Enter') {
                onEnterPress?.();
              }
            }}
            ref={ref}
            inputRef={inputRef}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            id={id}
          />
          {!hideErrorText && errorText && (
            <FormHelperText
              className={classes.errorWrapper}
              id="input-meta-title-helper-text"
              error
            >
              <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />

              {errorText}
            </FormHelperText>
          )}
        </WithFlexWrapper>
      </WithCharLimit>
    );
  },
);
