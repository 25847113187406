import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export interface SearchObject {
  name: string;
  id: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    height: 50,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 2,
    border: '1px solid #EEEEF6',
  },
  cardOpened: {
    zIndex: 101,
  },
  searchField: {
    width: '100%',
  },
  details: {
    maxWidth: '500px',
    minWidth: '300px',
    backgroundColor: 'white',
    maxHeight: '500px',
    overflow: 'auto',
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      maxWidth: '300px',
      minWidth: '250px',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  text: {
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      fontSize: '12px',
    },
  },
  title: {
    fontSize: '16px',
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      fontSize: '12px',
    },
  },
  closedArea: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    zIndex: 100,
  },
}));
