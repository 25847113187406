import { useEffect, useRef } from 'react';

export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = useRef(callback);
  const timerId = useRef<number | undefined>(undefined);

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const startInterval = () => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) return;

    // Interval already started
    if (timerId.current) {
      return;
    }

    timerId.current = setInterval(() => savedCallback.current(), delay);
  };

  const stopInterval = () => {
    clearInterval(timerId.current);
    timerId.current = undefined;
  };

  const resetInterval = () => {
    stopInterval();
    startInterval();
  };

  // Set up the interval.
  useEffect(() => {
    startInterval();

    return () => stopInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  return {
    resetInterval,
  };
};
