import React from 'react';

const SmallArrowIcon = () => {
  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8049 2.45893L6.69246 8.37835C6.46518 8.7055 6.35154 8.86908 6.20327 8.92402C6.07394 8.97194 5.9263 8.97194 5.79697 8.92402C5.64871 8.86908 5.53507 8.7055 5.30778 8.37835L1.19534 2.45893C0.967237 2.1306 0.853186 1.96643 0.870042 1.8317C0.884745 1.71418 0.95863 1.60739 1.07331 1.5379C1.20479 1.45824 1.43242 1.45824 1.88768 1.45824L10.1126 1.45824C10.5678 1.45824 10.7955 1.45824 10.9269 1.5379C11.0416 1.60739 11.1155 1.71418 11.1302 1.8317C11.1471 1.96643 11.033 2.1306 10.8049 2.45893Z"
        fill="#555555"
      />
    </svg>
  );
};

export default SmallArrowIcon;
