import { FC } from 'react';

export const ErrorCircleOutlined: FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_688_8936)">
      <path
        d="M10 6.66669V10M10 13.3334H10.0083M18.3333 10C18.3333 14.6024 14.6024 18.3334 10 18.3334C5.39763 18.3334 1.66667 14.6024 1.66667 10C1.66667 5.39765 5.39763 1.66669 10 1.66669C14.6024 1.66669 18.3333 5.39765 18.3333 10Z"
        stroke="#D92D20"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_688_8936">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
