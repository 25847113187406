import * as React from 'react';

export const CreateASaleIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        fill="#FBEECD"
        d="M22.185 20.4a12.57 12.57 0 01-.763.854c-4.64 4.738-12.233 5.037-17.237.58C-.59 17.58-1.333 10.488 2.232 5.368a12.459 12.459 0 011.682-1.954c.389-.369.797-.707 1.22-1.016.629-.46 1.293-.856 1.983-1.183A12.538 12.538 0 0113.954.084a12.45 12.45 0 016.86 3.083c5.02 4.47 5.585 12.078 1.37 17.234z"
      ></path>
      <path
        fill="#FBE6AF"
        d="M12.596 23.985a11.305 11.305 0 01-7.56-2.886 11.318 11.318 0 01-3.794-7.848 11.312 11.312 0 012.866-8.232 11.316 11.316 0 017.848-3.794c3.034-.178 5.96.842 8.23 2.867a11.32 11.32 0 013.795 7.848 11.317 11.317 0 01-2.866 8.232 11.313 11.313 0 01-7.848 3.793c-.224.015-.448.02-.67.02zm.03-22.1c-.21 0-.42.006-.63.02a10.636 10.636 0 00-7.38 3.567 10.639 10.639 0 00-2.695 7.741 10.638 10.638 0 003.568 7.38 10.63 10.63 0 007.74 2.696 10.636 10.636 0 007.38-3.568 10.639 10.639 0 002.694-7.741 10.638 10.638 0 00-3.567-7.38 10.632 10.632 0 00-7.11-2.714z"
      ></path>
      <path
        fill="#FFB515"
        d="M16.125 14.448c0 .768-.281 1.401-.845 1.9-.563.497-1.376.805-2.439.921v1.725h-1.06v-1.677c-1.318-.021-2.399-.223-3.244-.604v-1.677c.457.223.991.41 1.602.564a7.388 7.388 0 001.642.23v-2.972l-.67-.246c-.87-.334-1.508-.731-1.912-1.192-.399-.466-.598-1.041-.598-1.725 0-.73.284-1.332.853-1.804.574-.471 1.35-.757 2.327-.858V5.698h1.06v1.311a8.803 8.803 0 013.069.652l-.582 1.454a8.568 8.568 0 00-2.487-.588v2.893l.606.23c1.01.387 1.708.795 2.096 1.224.388.43.582.954.582 1.574zm-1.88.12a.97.97 0 00-.328-.756c-.212-.201-.57-.395-1.076-.58v2.535c.936-.143 1.403-.543 1.403-1.2zm-3.771-4.889c0 .302.093.554.279.755.191.202.534.4 1.028.597V8.559c-.425.063-.75.193-.972.39a.934.934 0 00-.335.73z"
      ></path>
    </svg>
  );
};
