import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { postSalesCalculation } from '../../../../../store/slices/campaignsSlice';
import { getProperties } from '../../../../../store/slices/venueSlice';

import { FormSection } from '../FormSection';

import { CampaignsApiModel } from '../../../../../api/models/campaigns';
import { VenueProperties } from '../../../../../api/models/venue';

import { InitialValues, salesFormFields } from '../FormSection.helper';
import { mockRecommendedValue } from './SalesCalculationContainer.helper';
import { getConvertedRecommendedValue } from '../../RewardsWizard.helper';

interface RewardsCalculatorParams {
  numberOfSalesReps?: number;
  incentiveBudget?: number;
  videosCreated?: number;
  engagements?: number;
}

interface SalesCalculationContainerProps {
  properties: Partial<VenueProperties> | null;
  activeCampaign: CampaignsApiModel | null;
  onChangeForm: () => void;
  setCanLeave: (value: boolean) => void;
  venueId: string;
  isMobile: boolean;
}
export const SalesCalculationContainer: React.FC<SalesCalculationContainerProps> = ({
  properties = {},
  onChangeForm,
  setCanLeave,
  venueId,
  isMobile,
  activeCampaign,
}) => {
  const dispatch = useAppDispatch();
  const { recommendedValue } = useTypedSelector((state) => state.campaigns);
  const rewardsCalculatorParams = properties?.['rewards-calculator-params'] || {};
  const { numberOfSalesReps, incentiveBudget, videosCreated, engagements } =
    rewardsCalculatorParams as RewardsCalculatorParams;
  const recommendedValues = getConvertedRecommendedValue(mockRecommendedValue, recommendedValue);

  let initValues: InitialValues | null = null;

  if (numberOfSalesReps && incentiveBudget && videosCreated && engagements && activeCampaign) {
    initValues = {
      row1: numberOfSalesReps,
      row2: incentiveBudget,
      row3: videosCreated,
      row4: engagements,
    };
  }

  const onSubmitHandler = useCallback(
    (values: InitialValues) => {
      dispatch(
        postSalesCalculation({
          venueId,
          payload: {
            numberOfSalesReps: values.row1 || 0,
            incentiveBudget: values.row2 || 0,
            videosCreated: values.row3 || 0,
            engagements: values.row4 || 0,
          },
        }),
      ).then(() => {
        dispatch(getProperties({ id: venueId }));
        setCanLeave(false);
      });
    },
    [dispatch, setCanLeave, venueId],
  );

  useEffect(() => {
    if (numberOfSalesReps && incentiveBudget && videosCreated && engagements && activeCampaign) {
      onSubmitHandler({
        row1: numberOfSalesReps,
        row2: incentiveBudget,
        row3: videosCreated,
        row4: engagements,
      });
    }
  }, [
    onSubmitHandler,
    numberOfSalesReps,
    incentiveBudget,
    activeCampaign,
    videosCreated,
    engagements,
  ]);

  return (
    <>
      <FormSection
        onSubmit={onSubmitHandler}
        initValues={initValues}
        onChangeForm={onChangeForm}
        isMobile={isMobile}
        recommendedRewardsValue={recommendedValues}
        formFields={salesFormFields}
      />
    </>
  );
};
