import { FC } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

import { useStyles } from './AccordionWithoutState.style';
import clsx from 'clsx';
import { Tooltip } from '../table/Tooltip';

interface Props {
  number?: number;
  title: string;
  className?: string;
  children?: JSX.Element;
  defaultOpen?: boolean;
  subtitle?: string;
  divider?: boolean;
  showExpandIcon?: boolean;
  isExpanded?: boolean;
  marginTop?: string;
  marginBottom?: string;
}

export const AccordionWithoutState: FC<Props> = ({
  title,
  number,
  className,
  children,
  defaultOpen,
  subtitle,
  divider,
  showExpandIcon = true,
  isExpanded,
  marginBottom,
  marginTop,
}) => {
  const classes = useStyles();
  return (
    <Accordion
      expanded={isExpanded}
      className={clsx(classes.root, className)}
      defaultExpanded={defaultOpen}
      style={{
        marginBottom: marginBottom || '30px',
        marginTop: marginTop || '30px',
        borderRadius: '15px',
      }}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={showExpandIcon ? <ExpandMoreIcon /> : null}
      >
        <Box className={classes.header}>
          <Box className={classes.titleWrap}>
            {number && <Typography className={classes.number}>{number}</Typography>}
            <Typography className={classes.title}>{title}</Typography>
            {subtitle && (
              <Tooltip
                padding="23px 25px"
                rounded="15px"
                title={subtitle}
                icon={<QuestionMarkRoundedIcon className={classes.Tooltip} />}
              />
            )}
          </Box>
        </Box>
      </AccordionSummary>
      {divider && <Divider />}
      <AccordionDetails className={classes.detailes}>{children}</AccordionDetails>
    </Accordion>
  );
};
