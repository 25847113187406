import { FC } from "react";

export const ConversionsIcon:FC = () => (
  <svg
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx={10.2505}
      cy={9.99999}
      r={9.28568}
      stroke="#8B89A0"
      strokeWidth={1.3}
    />
    <path
      d="M7.82227 7.3537C7.82227 6.56197 8.69863 6.08424 9.36408 6.5132L13.4691 9.1594C14.08 9.55322 14.08 10.4466 13.4691 10.8404L9.36408 13.4866C8.69863 13.9156 7.82227 13.4378 7.82227 12.6461L7.82227 7.3537Z"
      stroke="#8B89A0"
      strokeWidth={1.3}
    />
  </svg>
);

