import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>(() => ({
  shrotcutsButton: {
    display: 'flex',
    gap: '8px',
    color: '#475467',
    fontWeight: 600,
    cursor: 'pointer',
    userSelect: 'none',
  },
  shortcutsWrapper: {
    position: 'relative',
    zIndex: 10,
  },
  menuWrapper: {
    position: 'absolute',
    top: '100%',
    right: 0,
    backgroundColor: '#FFF',
    padding: '24px 20px',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    marginTop: '22px',
    transformOrigin: 'top right',
    animation: '$show 0.2s',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '12px',
    cursor: 'pointer',
    borderRadius: '12px',
    transition: '0.11s',
    width: '100%',
    maxWidth: '290px',

    '&:hover': {
      backgroundColor: '#f8f8ff',
    },
  },
  actionText: {
    color: '#101828',
    fontWeight: 600,
    lineHeight: '24px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  actionInfo: {
    fontSize: '14px',
    color: '#475467',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  distributionWrapper: {
    display: 'flex',
    gap: '24px',
  },

  '@keyframes show': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));
