import { Box, Grid, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import _isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ClientTypes } from '../../../api/models/common';
import TopGolfSlotsBackground from '../../../common/assets/TopGolfSlotsSectionPreview.jpg';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { CardBlockSlotsTime } from '../../../common/components/CardBlockSlotsTime/CardBlockSlotsTime';
import { CardBlock } from '../../../common/components/EditableTextFields/CarBlock';
import { EducationCardsPreviewTopgolfTimeSlots } from '../../../common/components/previews/EducationalCardsPreview/EducationCardsPreviewTopgolfTimeSlots';
import { SidebarStrings, WebAppSignUpConfigStrings } from '../../../common/localization/en';
import { useFTE } from '../../../hooks/useFTE';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { patchVenue, patchVenueProperties } from '../../../store/slices/venueSlice';
import {
  Card,
  Cards,
  getSteps,
  getTGStep1Title,
  getTGSteps,
  timeSlotsValues,
  validationSchemaBrandingStyle,
  WebAppCardTypes,
} from './WebAppCards.helper';
import { useWebAppCards } from './WebAppCards.style';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { useFormik } from 'formik';
import { DEFAULT_BRAND_COLOR } from '../../../common/constants/defaults';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../api/models/venue';
import { uploadOverlay } from '../VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { useQuery } from '../../../hooks/useQuery';
import { PreviewSection } from '../../QuickStart/Personalize/PreviewSection/PreviewSection';
import { TopBar } from '../../../common/components/topBar/TopBar';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';
import { WebappSubnavigation } from '../../../common/components/webappSubnavigation/WebappSubnavigation';
import { webappTabs } from '../../webapp/tabs';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { isFirefox } from 'react-device-detect';
import { AnimationStyleOptions } from '../../../common/components/AnimationStyleSelect/AnimationStyleSelect';
import { useQuickStartLaunchPad } from '../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';

const cardTypeMapper = ['webapp.cards-welcome', 'webapp.cards-tip', 'webapp.cards-edu'];
export const emptyCard: Card = {
  mobile: {
    original: null,
    cropped: null,
    cropInfo: null,
  },
  tablet: {
    original: null,
    cropped: null,
    cropInfo: null,
  },
  desktop: {
    original: null,
    cropped: null,
    cropInfo: null,
  },
};
export interface ConfigStateCard {
  aligmentText: string;
  activePanel: string;
  isUploadPhoto: boolean;
  isDirtyBrandColorForm: boolean;
}
export const WebAppCards: FC = () => {
  const theme = useTheme();
  const query = useQuery();
  const { trackEvent } = useTrackEvent();
  const classes = useWebAppCards();
  const dispatch = useAppDispatch();
  const {
    venue: { logo, id: venueId, clientType },
    venueAttributes: { isLoading, properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const step = query.get('step') ? parseInt(query.get('step') ?? '0') : 0;
  const [activeStep, setActiveStep] = useState(step);
  const { addToast } = useToasts();
  const [formDirty, setFormDirty] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [colorError, setColorError] = useState(false);
  const alignmentText = venueProperties?.['webapp.cards-welcome']?.alignment || 'start';
  const [, setTitles] = useState({ title: '', subtitle: '', alignment: alignmentText });

  const { theme: themeLayout } = useThemeLayout();
  const [image, setImage] = useState<string>(
    venueProperties?.['webapp.cards-welcome']?.mobile?.original || '',
  );
  const dashboardLogo = venueProperties?.['dashboard.config']?.logoUrl;
  const { value: FTEValues, isLoading: isFTELoading } = useVenueProperty<
    VenueProperties['webapp.firstTimeOnboardingFinished.config']
  >({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });
  const showFTEActionBar = isFTELoading ? false : !FTEValues?.webapp?.branding_style;
  const { moveToNextRoute } = useFTE();
  const [cards, setCards] = useState<Cards>({
    'webapp.cards-welcome': emptyCard,
    'webapp.cards-tip': emptyCard,
    'webapp.cards-edu': emptyCard,
  });
  const venueBrandColor =
    venueProperties?.['webapp.config']?.['primary-color'] || theme.palette.common.black;
  const [brandColor, setBrandColor] = useState(venueBrandColor);
  const { properties } = useTypedSelector((state) => state.venue.venueAttributes);
  const initialSlotsTime = useTypedSelector(() =>
    properties?.['webapp.topgolf.config']
      ? properties?.['webapp.topgolf.config'].shotTimeSlotIncrement
      : properties?.['webapp.cards-tip']?.shotTimeSlotIncrement,
  );
  const [activeSlotsTime, setactiveSlotsTime] = useState<string>(
    initialSlotsTime || timeSlotsValues[0],
  );
  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;
  const steps = isTopgolfVenue ? getTGSteps() : getSteps();
  // const textColor = venueProperties?.['webapp.config']?.['text-color'];
  const [configCard, setConfigCard] = useState<ConfigStateCard>({
    aligmentText: alignmentText,
    activePanel: 'picture',
    isUploadPhoto: false,
    isDirtyBrandColorForm: false,
  });
  const { isQSMarkerVisible } = useQuickStartLaunchPad();

  const handleUpload = async () =>
    await uploadOverlay(
      '',
      values.webAppLogo,
      venueProperties?.['webapp.config']['filter-logo-position'] || 'top-left',
      venueId,
      isTopgolfVenue,
    );

  useEffect(() => {
    setConfigCard((prev) => ({ ...prev, aligmentText: alignmentText }));
  }, [alignmentText]);

  // generate new cards when new venueProperties available
  useEffect(() => {
    setCards({
      'webapp.cards-welcome': venueProperties?.['webapp.cards-welcome'] || emptyCard,
      'webapp.cards-tip': venueProperties?.['webapp.cards-tip'] || emptyCard,
      'webapp.cards-edu': venueProperties?.['webapp.cards-edu'] || emptyCard,
    });
    setImage(venueProperties?.['webapp.cards-welcome']?.mobile?.original || '');
  }, [venueProperties, activeStep]);

  useEffect(() => {
    setConfigCard((prev) => ({
      ...prev,
      aligmentText: venueProperties?.['webapp.cards-welcome']?.alignment || 'start',
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBrandColor(venueBrandColor);
  }, [venueBrandColor]);

  const contentHandler = (card: Card) => {
    const newCards = deepmerge(getCardByName(getCurrentCardType()), card);
    dispatch(
      patchVenueProperties({
        id: venueId,
        update: { name: cardTypeMapper[activeStep], value: newCards },
      }),
    );
  };

  const isShotTimeSlotsSection = isTopgolfVenue && activeStep === 1;
  const getCardSectionTitle = () => {
    if (isShotTimeSlotsSection) {
      return getTGStep1Title();
    }
    return steps[activeStep];
  };
  const getCardByName = useCallback(
    (name: WebAppCardTypes): Card => {
      return cards[name];
    },
    [cards],
  );

  const getCurrentCardType = useCallback((): WebAppCardTypes => {
    return cardTypeMapper[activeStep] as WebAppCardTypes;
  }, [activeStep]);

  const getDefaultBackgroundImage = useCallback(() => {
    if (isShotTimeSlotsSection) {
      return TopGolfSlotsBackground;
    } else {
      const type = getCurrentCardType();
      const card: Card = getCardByName(type);
      return card.mobile?.original || card.tablet?.original || card.desktop?.original || '';
    }
  }, [isShotTimeSlotsSection, getCardByName, getCurrentCardType]);

  const resetTitle = () => {
    if (isShotTimeSlotsSection) {
      setactiveSlotsTime(initialSlotsTime as string);
      setActiveStep(0);
      setFormDirty(false);
    } else {
      setCards({
        'webapp.cards-welcome': venueProperties?.['webapp.cards-welcome'] || emptyCard,
        'webapp.cards-tip': venueProperties?.['webapp.cards-tip'] || emptyCard,
        'webapp.cards-edu': venueProperties?.['webapp.cards-edu'] || emptyCard,
      });
      setConfigCard((prev) => ({ ...prev, aligmentText: alignmentText }));
      activeStep === 0
        ? setTitles({
            title: venueProperties?.['webapp.cards-welcome']?.title || '',
            subtitle: venueProperties?.['webapp.cards-welcome']?.subtitle || '',
            alignment: venueProperties?.['webapp.cards-welcome']?.alignment || 'start',
          })
        : setTitles({
            title: venueProperties?.['webapp.cards-tip']?.title || '',
            subtitle: venueProperties?.['webapp.cards-tip']?.subtitle || '',
            alignment: venueProperties?.['webapp.cards-tip']?.alignment || 'start',
          });
    }
  };

  const submitBrandColor = (brandColor: string) => {
    handleUpload().then((filterUrl) => {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.config',
            value: {
              ...(venueProperties?.['webapp.config'] || {}),
              'filter-url': filterUrl,
              'primary-color': `${brandColor}`,
            },
          },
        }),
      ).then(() => {
        dispatch(
          patchVenueProperties({
            id: venueId,
            update: {
              name: 'dashboard.QRCode.config',
              value: {
                ...(venueProperties?.['dashboard.QRCode.config'] || {}),
                logo: values.webAppLogo,
                brandColor: values.brandColor,
              },
            },
            alert: true,
          }),
        );
      });
    });
    setConfigCard((prev) => ({ ...prev, isDirtyBrandColorForm: false }));
  };

  const resetBrandColor = () => {
    setBrandColor(venueBrandColor);
    setFieldValue('brandColor', initialValues.brandColor);
    setConfigCard((prev) => ({ ...prev, isDirtyBrandColorForm: false }));
  };

  const resetPicture = () => {
    setImage(venueProperties?.['webapp.cards-welcome']?.mobile.original || '');
    setFieldValue('backgroundImg', initialValues.backgroundImg);
    setConfigCard((prev) => ({ ...prev, isUploadPhoto: false }));
  };

  const renderEducationCardsPreview = (
    values: typeof initialValues,
    showButton?: boolean,
    modal?: boolean,
  ) => (
    <PreviewSection
      logoImage={values.webAppLogo}
      brandColor={brandColor}
      backgroundImage={values.backgroundImg}
      setConfigCard={setConfigCard}
      configCard={configCard}
      title={values.title}
      subtitle={values.subtitle}
      theme={values.theme}
      selectedAnimation={values.launchColors}
      launchScreenType={launchScreenType}
      handleAnimationStyleChange={(value) => formik.setFieldValue('launchColors', value)}
      formik={formik}
    />
  );

  const mainReset = () => {
    resetForm();
    resetTitle();
    resetBrandColor();
    resetPicture();
    if (showFTEActionBar) {
      moveToNextRoute();
    }
  };

  const updateLogo = (): void => {
    dispatch(
      patchVenue({
        id: venueId,
        update: {
          logo: values.webAppLogo,
        },
      }),
    );

    dispatch(
      patchVenueProperties({
        id: venueId,
        update: {
          name: 'dashboard.QRCode.config',
          value: {
            ...(venueProperties?.['dashboard.QRCode.config'] || {}),
            logo: values.webAppLogo,
          },
        },
      }),
    );
    handleUpload().then((filterUrl) => {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.config',
            value: {
              ...(venueProperties?.['webapp.config'] || {}),
              'filter-url': filterUrl,
              'primary-color': `${brandColor}`,
              'filter-logo-enabled': logo
                ? venueProperties?.['webapp.config']['filter-logo-enabled']
                : true,
            },
          },
        }),
      );
    });
  };

  const FTEsetupLater = () => {
    resetTitle();
    resetBrandColor();
    resetPicture();
    if (showFTEActionBar) {
      moveToNextRoute();
    }
  };

  const handleSubmitData = () => {
    const lanuchScreenOptions = values.launchColors.split('_');

    if (values.launchColors !== initialValues.launchColors) {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.launch-screen',
            value: {
              cover: lanuchScreenOptions[0],
              reveal: lanuchScreenOptions[1],
              type: lanuchScreenOptions[2] ? lanuchScreenOptions[2] : logo ? 'logo' : 'name',
            },
          },
        }),
      );
    }

    if (formDirty || configCard.isUploadPhoto) {
      const _card: Card = {
        mobile: {
          original: image,
          cropped: image,
          cropInfo: null,
        },
        desktop: {
          original: image,
          cropped: image,
          cropInfo: null,
        },
        tablet: {
          original: image,
          cropped: image,
          cropInfo: null,
        },
      };
      let card, newCard, newCards;
      if (isShotTimeSlotsSection) {
        card = getCardByName(getCurrentCardType());
        newCard = deepmerge(card, { shotTimeSlotIncrement: activeSlotsTime });
        newCards = deepmerge(getCardByName(getCurrentCardType()), newCard);
      } else {
        card = getCardByName(getCurrentCardType());
        newCard = deepmerge(_card, {
          title: values.title,
          subtitle: values.subtitle,
          alignment: values.alignment,
        });
        newCards = deepmerge(getCardByName(getCurrentCardType()), newCard);
      }

      if (card && newCard && newCards) {
        dispatch(
          patchVenueProperties({
            id: venueId,
            update: { name: cardTypeMapper[activeStep], value: newCards },
          }),
        );
      }
    }

    setConfigCard((prev) => ({ ...prev, isUploadPhoto: false }));
    configCard.isDirtyBrandColorForm ||
      (values.brandColor !== initialValues.brandColor && submitBrandColor(values.brandColor));
    values.webAppLogo !== initialValues.webAppLogo && updateLogo();
    addToast(WebAppSignUpConfigStrings.nextStepMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
    if (showFTEActionBar) {
      moveToNextRoute();
    }
  };

  const webappLaunchScreen = venueProperties?.['webapp.launch-screen']
    ? `${venueProperties?.['webapp.launch-screen'].cover}_${venueProperties?.['webapp.launch-screen'].reveal}_${venueProperties?.['webapp.launch-screen'].type}`
    : AnimationStyleOptions.WhiteBrandingName;
  const initialValues = useMemo(
    () => ({
      //web app logo
      webAppLogo: logo || dashboardLogo || '',
      // card title
      title: venueProperties?.['webapp.cards-welcome']?.title || '',
      // card subtitle
      subtitle: venueProperties?.['webapp.cards-welcome']?.subtitle || '',
      // titlePosition
      alignment: venueProperties?.['webapp.cards-welcome']?.alignment || 'start',
      // background img
      backgroundImg: getDefaultBackgroundImage(),
      // brand color
      brandColor: venueBrandColor || DEFAULT_BRAND_COLOR,
      // launch screen colors
      launchColors: webappLaunchScreen,

      theme: themeLayout,
    }),
    [
      logo,
      dashboardLogo,
      venueProperties,
      getDefaultBackgroundImage,
      venueBrandColor,
      webappLaunchScreen,
      themeLayout,
    ],
  );
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchemaBrandingStyle,
    onSubmit: handleSubmitData,
  });

  const { values, setFieldValue, dirty, submitForm: onSubmit, resetForm } = formik;
  const isFormTouched = useMemo(() => {
    return !_isEqual(values, initialValues);
  }, [initialValues, values]);
  const isConfigLoading = isLoading;
  const isConfigFetched = false;
  const showBottomActionBar = showFTEActionBar || dirty || formDirty;

  useEffect(() => {
    if (colorError) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
    }
  }, [colorError]);

  const onRouteClick = (route: string) => {
    const subRoute = route.split('/').reverse()[0].replaceAll('-', '_');
    const eventName = `${subRoute}_${SettingsEvents.video_app_sub_navigation_tab_click}`;
    trackEvent(eventName);
  };

  const launchScreenType = values.launchColors.split('_')[2]
    ? (values.launchColors.split('_')[2] as 'logo' | 'name')
    : logo
    ? 'logo'
    : 'name';

  return (
    <>
      <TopBar
        sectionTitle={SidebarStrings.WebApp}
        onTabChange={(tab) => {
          trackEvent(WebAppSettingsEvents.NavigationTabChange, { value: tab.text });
        }}
      >
        <WebappSubnavigation items={webappTabs} onRouteClick={onRouteClick} />
      </TopBar>
      {!showFTEActionBar && <UnsavedChangesModal isUnsavedChangesOnPage={isFormTouched} />}
      <TwoSidePageLayout
        loading={isConfigLoading && !isConfigFetched && !isQSMarkerVisible}
        leftSideColumnWidths={5}
        rightSideTop={'128px'}
        withRightSideScroll
        leftSideNode={
          <Box
            sx={{
              paddingBottom: '120px',
              marginBottom: isFirefox ? '120px' : '0',
              position: 'relative',
              zIndex: 999,
            }}
          >
            <Grid container>
              {isShotTimeSlotsSection ? (
                <CardBlockSlotsTime
                  sectionTitle={getCardSectionTitle()}
                  setFormDirty={setFormDirty}
                  setactiveSlotsTime={setactiveSlotsTime}
                  initialSlotsTime={initialSlotsTime as string}
                />
              ) : (
                <CardBlock
                  setCard={contentHandler}
                  formik={formik}
                  color={brandColor}
                  setColor={setBrandColor}
                  setConfigCard={setConfigCard}
                  logo={values.webAppLogo}
                  onLogoChange={(newLogo) => {
                    setFieldValue('webAppLogo', newLogo);
                  }}
                  backgroundImage={values.backgroundImg}
                  onBackgroundImageChange={(newBackground) => {
                    setFieldValue('backgroundImg', newBackground);
                    setImage(newBackground);
                  }}
                  setColorError={setColorError}
                />
              )}
            </Grid>
          </Box>
        }
        leftSideDescriptionClassName={classes.leftSideDescription}
        rightSideNode={
          <Grid item xs={12} className={classes.cardPreviewWrapper}>
            {isShotTimeSlotsSection ? (
              <Box marginTop="34px" display="flex" justifyContent="center" width="100%">
                <EducationCardsPreviewTopgolfTimeSlots
                  image={getDefaultBackgroundImage()}
                  activeSlotsTime={activeSlotsTime as string}
                />
              </Box>
            ) : (
              <Box sx={{ height: '100%' }}>{renderEducationCardsPreview(values, true)}</Box>
            )}
          </Grid>
        }
      />
      <BottomActionBar
        show={showBottomActionBar}
        firstTimeExperience={showFTEActionBar}
        onSaveClick={onSubmit}
        onCancelClick={mainReset}
        onSetupLaterClick={FTEsetupLater}
        loading={isLoading}
        disableSaveButton={formInvalid}
      />
    </>
  );
};
