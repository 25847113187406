import { FC } from 'react';
import { makeStyles } from '@mui/styles';
// import { TextField } from '@mui/material';
import { RewardAdStrings } from '../../../../common/localization/en';
import { FormikProps } from 'formik';
import {
  IncentiveFieldWrapper,
  IncentiveFormField,
} from '../../rewardsWizard/FormSection/FormSection.style';
import { UploadButton } from './UploadButton';
import { CustomTextField } from '../../../../common/components/customTextField/CustomTextField';
import { useHandleChangeWithTracking } from '../../../../services/hooks/useHandleChangeWithTracking';

interface IncentiveFormProps {
  isActiveAd: boolean;
  setImage: (image: string) => void;
  image: string;
  formik: FormikProps<any>;
  canSave: () => boolean;
  reset: () => void;
}
const useStyles = makeStyles(() => ({
  formSection: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 34,
    marginBottom: '100px',
  },
  disabled: {
    opacity: 0.3,
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  formDisclaimer: {
    fontSize: '10px',
    lineHeight: '16px',
    color: '#8F94B6',
  },
  imageBtnBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {
    borderRadius: '16px',
  },
  requirementListWrap: {
    paddingLeft: 15,
  },
  requirementList: {
    listStyle: 'disc',
  },
  formStyle: {
    marginBottom: '10px',
    minWidth: '400px',
  },

  bottomControllers: {
    display: 'flex',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    flexDirection: 'row-reverse',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    padding: '24px 58px',
    zIndex: 100,
  },
  actionButton: {
    float: 'right',
    margin: '5px 2px',
    width: '120px',
  },
  error: {
    color: '#ED453C',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '20px',
  },
}));
export const IncentiveForm: FC<IncentiveFormProps> = ({ image, setImage, formik }) => {
  const styles = useStyles();
  const { errors, values } = formik;
  const { handleChangeWithTracking } = useHandleChangeWithTracking(formik.handleChange);
  return (
    <div className={styles.formSection}>
      <div className={styles.formContainer}>
        <div className={styles.imageBtnBlock}>
          <UploadButton isActiveAd={true} style={styles.image} image={image} setImage={setImage} />
          <div className={styles.formDisclaimer}>
            <p>{RewardAdStrings.ImageRequirements}</p>
            <ul className={styles.requirementListWrap}>
              <li className={styles.requirementList}>{RewardAdStrings.RequirementOne}</li>
              <li className={styles.requirementList}>{RewardAdStrings.RequirementTwo}</li>
            </ul>
          </div>
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <IncentiveFormField>
              <IncentiveFieldWrapper>
                <CustomTextField
                  value={values.title}
                  error={errors.title as string | undefined}
                  name="title"
                  handleChange={handleChangeWithTracking}
                  charcherLimit={42}
                  label={'Title'}
                />
                <CustomTextField
                  value={values.subtitle}
                  error={errors.subtitle as string | undefined}
                  name="subtitle"
                  handleChange={handleChangeWithTracking}
                  charcherLimit={82}
                  label={'Subtitle'}
                />
                <CustomTextField
                  value={values.buttonText}
                  error={errors.buttonText as string | undefined}
                  name="buttonText"
                  handleChange={handleChangeWithTracking}
                  charcherLimit={24}
                  label={'Button Text'}
                />
                <CustomTextField
                  value={values.buttonUrl}
                  error={errors.buttonUrl as string | undefined}
                  name="buttonUrl"
                  handleChange={handleChangeWithTracking}
                  label={'Button Link'}
                />
              </IncentiveFieldWrapper>
            </IncentiveFormField>
          </form>
        </div>
      </div>
    </div>
  );
};
