import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '160px',
    height: '280px',
    borderRadius: '9px',
    position: 'relative',
    margin: '0 8px',
    backgroundColor: theme.palette.primary.main,
    border: `4px solid ${theme.palette.primary.main}`,
    paddingBottom: '4px',
  },
  video: {
    borderRadius: '9px',
    cursor: 'pointer',
  },
  loader: {
    position: 'absolute',
    top: '130px',
    left: '60px',
    color: theme.palette.primary.main,
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '42px',
    height: '42px',
    borderRadius: '50%',
    background: 'rgba(255, 255, 255, 0.20)',
    backdropFilter: 'blur(10px)',
    display: 'grid',
    placeItems: 'center',
    paddingLeft: '4px',
    cursor: 'pointer',
  },
  userName: {
    position: 'absolute',
    top: '15px',
    left: '10px',
    color: theme.palette.common.white,
    maxWidth: '140px',
    overflow: 'hidden',
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
  },
  checkWrapper: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    cursor: 'pointer',
  },
}));
