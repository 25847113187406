export const useCrispChat = () => {
  const openCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:open']);
  };

  const closeCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:close']);
  };

  const toggleCrispChat = () => {
    if (!window || !window.$crisp) return;
    window.$crisp.push(['do', 'chat:toggle']);
  };

  const hideCrispChatButton = () => {
    if (!window.$crisp) return;
    window.$crisp.push(['do', 'chat:hide']);
  };

  const showCrispChatButton = () => {
    if (!window.$crisp) return;
    window.$crisp.push(['do', 'chat:show']);
  };

  return {
    openCrispChat,
    closeCrispChat,
    toggleCrispChat,
    showCrispChatButton,
    hideCrispChatButton,
  };
};
