import * as React from 'react';

export const CreateStoryIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        fill="#4CBA8C"
        d="M18.671 21.044H1.95c-.552 0-1-.479-1-1.069V2.087c0-.59.448-1.069 1-1.069H18.67c.552 0 1 .48 1 1.07v17.889c-.002.59-.448 1.067-1 1.067z"
        opacity="0.36"
      ></path>
      <path
        fill="#fff"
        d="M17.72 20.027H1c-.552 0-1-.479-1-1.069V1.07C0 .479.448 0 1 0h16.72c.552 0 1 .479 1 1.069v17.89c0 .59-.448 1.068-1 1.068z"
      ></path>
      <path
        fill="#F9F9FC"
        d="M17.72 20.027H1c-.552 0-1-.479-1-1.069V1.07C0 .479.448 0 1 0h16.72c.552 0 1 .479 1 1.069v17.89c0 .59-.448 1.068-1 1.068z"
      ></path>
      <path fill="#485CE2" d="M17.115 1.708H1.585v16.615h15.53V1.708z"></path>
      <g opacity="0.47">
        <path
          fill="#fff"
          d="M17.371 18.467H1.37v-3.844l2.765-3.426c.4-.496 1.078-.581 1.576-.202l2.545 1.945c.129.1.303.09.424-.02l3.66-3.368a2.017 2.017 0 012.782.021l2.25 2.133v6.76zm-15.53-.505h15.06v-6.029L14.808 9.95a1.57 1.57 0 00-2.163-.017l-3.66 3.37a.774.774 0 01-1.005.049l-2.545-1.945a.67.67 0 00-.945.121l-2.65 3.285v3.15z"
        ></path>
        <path
          fill="#F9F9FC"
          d="M17.371 18.467H1.37v-3.844l2.765-3.426c.4-.496 1.078-.581 1.576-.202l2.545 1.945c.129.1.303.09.424-.02l3.66-3.368a2.017 2.017 0 012.782.021l2.25 2.133v6.76zm-15.53-.505h15.06v-6.029L14.808 9.95a1.57 1.57 0 00-2.163-.017l-3.66 3.37a.774.774 0 01-1.005.049l-2.545-1.945a.67.67 0 00-.945.121l-2.65 3.285v3.15z"
        ></path>
      </g>
      <g opacity="0.47">
        <path
          fill="#fff"
          d="M10.305 8.541c-1.084 0-1.967-.945-1.967-2.104 0-1.16.883-2.105 1.967-2.105 1.085 0 1.968.945 1.968 2.105 0 1.16-.882 2.104-1.968 2.104zm0-3.706c-.824 0-1.495.717-1.495 1.6 0 .882.67 1.6 1.495 1.6.825 0 1.496-.718 1.496-1.6 0-.883-.67-1.6-1.496-1.6z"
        ></path>
        <path
          fill="#F9F9FC"
          d="M10.305 8.541c-1.084 0-1.967-.945-1.967-2.104 0-1.16.883-2.105 1.967-2.105 1.085 0 1.968.945 1.968 2.105 0 1.16-.882 2.104-1.968 2.104zm0-3.706c-.824 0-1.495.717-1.495 1.6 0 .882.67 1.6 1.495 1.6.825 0 1.496-.718 1.496-1.6 0-.883-.67-1.6-1.496-1.6z"
        ></path>
      </g>
      <ellipse cx="18.148" cy="17.73" fill="#fff" rx="4.568" ry="4.847"></ellipse>
      <ellipse cx="18.148" cy="17.73" fill="#F9F9FC" rx="4.568" ry="4.847"></ellipse>
      <path
        fill="#54D2A5"
        d="M18.719 14.701h-1.142v2.423h-2.284v1.212h2.284v2.423h1.142v-2.423h2.284v-1.212h-2.284v-2.423zm-.571-3.03c-3.152 0-5.71 2.715-5.71 6.06 0 3.343 2.558 6.058 5.71 6.058s5.71-2.715 5.71-6.059c0-3.344-2.558-6.058-5.71-6.058zm0 10.906c-2.518 0-4.569-2.175-4.569-4.847 0-2.672 2.05-4.847 4.569-4.847 2.518 0 4.568 2.175 4.568 4.847 0 2.672-2.05 4.847-4.568 4.847z"
      ></path>
    </svg>
  );
};
