import { FC, SetStateAction, useEffect, useState } from 'react';
import { Theme, Grid, Button, Step, Stepper, StepLabel, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createColor } from 'material-ui-color';
import { RouteLeavingGuard } from '../../../../common/components/RouteLeavingGuard/RouteLeavingGuard';
import { CommonStrings, VenueCreationWizardStrings } from '../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { createMarketVenue } from '../../../../store/slices/venueCreationSlice';
import { ManagerAccount } from '../../../../store/storeModels';
import { WizardConfirmationModal } from '../../../venuewizard/WizardConfirmationModal';
import { FilterProps, ValidationSchemaType } from '../../../venuewizard/WizzardStepperHelper';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { Step4 } from './Steps/Step4';
import { VenuCreationUserRoles } from '../../../../common/constants/constants';
import { Spinner } from '../../../../common/assets/Spinner';
import { ClientTypes } from '../../../../api/models/common';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(3),
    width: '120px',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  bottomNav: {
    height: '100px',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  innerNav: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
  },
}));

function getSteps() {
  return [
    VenueCreationWizardStrings.WorkspaceAndUsers,
    VenueCreationWizardStrings.WebappBrandingStep,
    VenueCreationWizardStrings.FiltersBrandingStep,
    VenueCreationWizardStrings.PreviewStep,
  ];
}

interface VenueCreationStepperProps {
  market: string;
  setShowWizardSuccess: (value: SetStateAction<boolean>) => void;
  setShowWizardFailure: (value: SetStateAction<boolean>) => void;
}

export const VenueCreationStepper: FC<VenueCreationStepperProps> = ({
  market,
  setShowWizardSuccess,
  setShowWizardFailure,
}) => {
  const { createdVenue } = useTypedSelector((state) => state.venueCreation);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const subdomainData = useTypedSelector((state) => state.venueCreation.subdomain);
  const filterUrlsLoading = useTypedSelector((state) => state.venueCreation.filterUrlsLoading);
  const [accounts, setAccounts] = useState<ManagerAccount[]>([
    {
      email: '',
      name: '',
      role: VenuCreationUserRoles.venue_manager,
      password: '',
      submited: false,
    },
  ]);
  const [teamName, setTeamName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [venuName, setVenuName] = useState('');
  const [venuLogo, setVenuLogo] = useState('');
  const bcolor = createColor('#57B8B1');
  const tcolor = createColor('#fff');
  const [brandcolor, setBrandcolor] = useState(bcolor);
  const [textColor, setTextColor] = useState(tcolor);
  const stateFilters = useTypedSelector((state) => state.venueCreation.filterUrls);
  const mappedStateFilters = stateFilters.map((item, index) => ({ url: item, isActive: !index }));
  const [filterLogo, setFilterLogo] = useState<string>();
  const [filters, setFilters] = useState<FilterProps[]>(mappedStateFilters);
  const [filterPrimaryColor, setFilterPrimaryColor] = useState(bcolor);
  const [filterSecondaryColor, setFilterSecondaryColor] = useState(bcolor);
  const [subdomain, setSubdomain] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [companyUrl, setCompanyUrl] = useState('');
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const validateStep1 = () => {
    return !(accounts.filter((el) => !el.submited).length || !venuName);
  };
  const validateStep2 = () => {
    return !!(venuLogo && brandcolor && textColor);
  };
  const validateStep3 = () => {
    return filters.length && filters[0].url.length;
  };
  const validateStep4 = () => {
    return subdomainData.checked && subdomainData.available;
  };

  const validationSchema = {
    0: validateStep1,
    1: validateStep2,
    2: validateStep3,
    3: validateStep4,
  };

  const validateSteps = (step: 0 | 1 | 2 | 3, validationSchema: ValidationSchemaType) => {
    let validation = validationSchema[step];
    return validation();
  };

  const handleConfirmCreateVenu = async () => {
    const options = {
      name: venuName,
      subdomain: subdomain,
      teamName: teamName,
      logoUrl: venuLogo,
      websiteUrl: companyUrl,
      clientType: market,
      videoFilters: filters,
      managerAccounts: accounts.map((item) => ({
        name: item.name,
        password: item.password,
        email: item.email,
        role: item.role,
      })),
      properties: {
        'webapp.config': {
          'primary-color': `#${brandcolor.hex}`,
          'logo-img-url': venuLogo,
          'filter-logo': filterLogo,
          'filter-primary-color': `#${filterPrimaryColor.hex}`,
          'filter-secondary-color': `#${filterSecondaryColor.hex}`,
        },
        'webapp.default-story-details': {
          editable: market === ClientTypes.SALES ? 'full' : 'limited',
          metaTitle: 'Video message from {Display Name}',
          videoText:
            market === ClientTypes.SALES ? 'Hi, this is {Display Name} from {Org Name}' : '',
          ctaBtnText:
            market === ClientTypes.SALES
              ? "Let's Connect"
              : market === ClientTypes.MARKETING
              ? 'Check it out'
              : market === ClientTypes.FUNDRAISING
              ? 'Donate'
              : 'Donate',
          ctaBtnLinkUrl: companyUrl || '',
          webAppSettings: {
            metaTitle: market === ClientTypes.SALES,
            videoText: market === ClientTypes.SALES,
            ctaBtnText: market === ClientTypes.SALES,
            ctaBtnLinkUrl: market === ClientTypes.SALES,
          },
        },
      },
    };
    // do something with data collected then
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(createMarketVenue(options));

    setShowWizardSuccess(true);
    handleCloseModal();
  };

  useEffect(() => {
    if (venuName) {
      // accept only letters
      setSubdomain(venuName.toLowerCase().replace(/[^a-zA-Z]/g, ''));
    }
  }, [venuName]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const shouldResetFiltersToStore = () => {
    if (stateFilters.length) {
      const filterUrls = filters.map((item) => item.url);
      return !filterUrls.includes(stateFilters[0]);
    }
    return false;
  };
  useEffect(() => {
    if (mappedStateFilters.length && shouldResetFiltersToStore()) {
      setFilters(mappedStateFilters);
    }
  }, [mappedStateFilters, shouldResetFiltersToStore]);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // createVenue
      setIsConfirmationModalOpen(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return (
          <Step1
            setVenuName={setVenuName}
            venuName={venuName}
            setCompanyName={setCompanyName}
            setAccounts={setAccounts}
            accounts={accounts}
            companyName={companyName}
            teamName={teamName}
            setTeamName={setTeamName}
            companyUrl={companyUrl}
            setCompanyUrl={setCompanyUrl}
          />
        );
      case 1:
        return (
          <Step2
            venuLogo={venuLogo}
            setVenuLogo={setVenuLogo}
            brandcolor={brandcolor}
            setBrandcolor={setBrandcolor}
            textColor={textColor}
            setTextColor={setTextColor}
          />
        );
      case 2:
        return (
          <Step3
            venuLogo={venuLogo}
            setVenuLogo={setVenuLogo}
            filterLogo={filterLogo}
            setFilterLogo={setFilterLogo}
            filterPrimaryColor={filterPrimaryColor}
            setFilterPrimaryColor={setFilterPrimaryColor}
            filterSecondaryColor={filterSecondaryColor}
            setFilterSecondaryColor={setFilterSecondaryColor}
            filters={filters}
            setFilters={setFilters}
          />
        );
      case 3:
        return (
          <Step4
            venuLogo={venuLogo}
            brandcolor={brandcolor}
            textColor={textColor}
            filterPrimaryColor={filterPrimaryColor}
            filterSecondaryColor={filterSecondaryColor}
            filters={filters}
            venuName={venuName}
            accounts={accounts}
            companyName={companyName}
            subdomain={subdomain}
            setSubdomain={setSubdomain}
            teamName={teamName}
          />
        );
      default:
        return <div></div>;
    }
  }

  return (
    <>
      {filterUrlsLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255,255,255, 0.7)"
          height="100%"
          width="100%"
          zIndex="2"
          position="absolute"
        >
          <Spinner color="var(--lightBlue)" />
        </Box>
      )}

      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={12}>
          <RouteLeavingGuard
            when={!createdVenue.initiated}
            titleText={VenueCreationWizardStrings.LeaveTitle}
            contentText={VenueCreationWizardStrings.LeaveContent}
          />
          <WizardConfirmationModal
            handleConfirm={handleConfirmCreateVenu}
            isModalOpen={isConfirmationModalOpen}
            handleCloseModal={handleCloseModal}
          />
          <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <Box mt={5}>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </div>
              ) : (
                <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
                  <Grid item xs={12}>
                    {getStepContent(activeStep)}
                  </Grid>
                </Grid>
              )}
            </Box>
          </div>
        </Grid>
      </Grid>
      <div className={classes.bottomNav}>
        <div className={classes.innerNav}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            disabled={!validateSteps(activeStep as 0 | 1 | 2 | 3, validationSchema)}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </Button>
          <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
            {CommonStrings.Back}
          </Button>
        </div>
      </div>
    </>
  );
};
