import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '93px',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '100%',
    letterSpacing: '-0.6px',
    textAlign: 'center',
  },
  info: {
    color: theme.palette.common.gray600,
    fontSize: '18px',
    lineHeight: '162%',
    textAlign: 'center',
    marginTop: '10px',
  },
  tilesWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '19px',
    marginTop: '50px',

    [theme.breakpoints.down(1330)]: {
      justifyContent: 'start',
      marginLeft: '20px',
      gap: '10px',
    },
  },
  tileWrapper: {
    borderRadius: '37px',
    position: 'relative',
    padding: '10px',
    border: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `4px solid ${theme.palette.primary.main}`,
  },
  tileHalfBorder: {
    position: 'absolute',
    top: '-5px',
    left: '-5px',
  },
  innerWrapper: {
    width: '265px',
    height: '290px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: '30px',

    [theme.breakpoints.down('lg')]: {
      width: '240px',
      height: '270px',
    },
  },
  tileTitle: {
    fontSize: '18px',
    fontWeight: 600,
    color: theme.palette.common.gray600,
    lineHeight: '130%',
    margin: '24px 0 14px 0',
  },
  tileList: {
    width: '100%',
  },
  tileOption: {
    color: theme.palette.common.gray600,
    lineHeight: '162%',
    fontSize: '14px',
    fontWeight: 400,
    paddingLeft: '40px',
    position: 'relative',

    '&::before': {
      content: "'•'",
      position: 'absolute',
      left: '28px',
      color: theme.palette.common.gray600,

      [theme.breakpoints.down('lg')]: {
        left: '20px',
      },
    },

    [theme.breakpoints.down('lg')]: {
      paddingLeft: '30px',
    },
  },
}));
