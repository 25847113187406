import { Redirect, Route, Switch } from 'react-router-dom';
import { TopBar } from '../../common/components/topBar/TopBar';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';

import { WebsiteRoutes } from '../../common/constants/routes';
import { subnavigationItems, useStyles } from './AccountsPage.helper';
import { Members } from '../../common/components/WorkspaceManagers/Members';
import { WorkspaceStrings } from '../../common/localization/en';
import { Billing } from '../../common/components/Billing/Billing';
import { AccountOverview } from './AccountOverview';
import { CompanyDetails } from './CompanyDetails/CompanyDetails';
import { AccountProfile } from '../../common/components/AccountProfile/AccountProfile';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { AccountEventNames } from '../../common/constants/events/accountEvents';
import { useAppDispatch, useTypedSelector } from '../../store';
import { UserRoles, VenueMappingRoles } from '../../common/constants/constants';
import { useEffect } from 'react';
import {
  getVenueManagerList,
  setCurrentLoggedInManager,
} from '../../store/slices/venueManagerSlice';
import SMSTracking from '../../common/components/SMSTracking/SMSTracking';
import { useClientType } from '../../services/hooks/useClientType';

export const AccountsPage = () => {
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();
  const venue = useTypedSelector((state) => state.venue.venue);
  const { id: meId, role } = useTypedSelector((state) => state.me);
  const { distributors } = useTypedSelector((state) => state.distributors);
  const { affiliates } = useTypedSelector((state) => state.affiliates);
  const { loggedInManager, items } = useTypedSelector((state) => state.VenueManagerSlice);
  const dispatch = useAppDispatch();
  const { isUwmClient } = useClientType();

  useEffect(() => {
    if (venue.id) {
      dispatch(getVenueManagerList({ accountId: venue.id }));
    }
  }, [dispatch, venue.id]);

  useEffect(() => {
    if (items && items.length) {
      const foundManager = items.find((item) => item.id === meId);
      dispatch(setCurrentLoggedInManager(foundManager || null));
    }
  }, [items, dispatch, meId]);

  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;
  const isOwner = loggedInManager?.accountMappingRole === VenueMappingRoles.owner;

  const navigationItems = () => {
    const items = subnavigationItems.filter(
      (item) => !isUwmClient || item.route !== WebsiteRoutes.AccountsOverview,
    );
    if (isSuperAdmin || isOwner) return items;
    return items.filter((item) => {
      return ![WebsiteRoutes.AccountsOverview, WebsiteRoutes.AccountsBilling].includes(item.route);
    });
  };

  const redirectRoute =
    isSuperAdmin || isOwner ? WebsiteRoutes.AccountsOverview : WebsiteRoutes.AccountsCompany;

  return (
    <>
      <TopBar sectionTitle={WorkspaceStrings.AccountDetails}>
        <WebappSubnavigation
          items={navigationItems()}
          onRouteClick={(route) => {
            trackEvent(AccountEventNames.accountDetailsRouteChanged, {
              value: route,
            });
          }}
        />
      </TopBar>
      <div className={classes.root}>
        <Switch>
          <Route exact path={WebsiteRoutes.Accounts}>
            <Redirect to={redirectRoute} />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsOverview}>
            <AccountOverview />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsCompany}>
            <CompanyDetails />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsMembers}>
            <Members />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsBilling}>
            <Billing />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsSmsTracking}>
            <SMSTracking />
          </Route>
          <Route exact path={WebsiteRoutes.AccountsProfile}>
            <AccountProfile />
          </Route>
        </Switch>
      </div>
    </>
  );
};
