import { Dispatch, FC, SetStateAction } from 'react';

import { Card } from '../../../pages/webappSettings/WebAppCards/WebAppCards.helper';
import { Typography, Box, Grid } from '@mui/material';
import { PersonalizationStrings, WebAppPageStrings } from '../../localization/en';

import { useStylesCardBlock } from './CarBlock.style';
import { ConfigStateCard } from '../../../pages/webappSettings/WebAppCards/WebAppCards';
import { BackgroundImageUploader } from '../ImageUploader/uploaders/BackgroundImageUploader';
import { LogoUploader } from '../ImageUploader/uploaders/LogoUploader';
import { CustomColorPickerWithInput } from '../CustomColorPicker/CustomColorPickerWithInput';
import { FormikProps } from 'formik';
import { AnimationStyleOptions } from '../AnimationStyleSelect/AnimationStyleSelect';
import { UploadDndIcon } from '../../assets/UploadDndIcon';

interface CardBlockProps {
  setCard: (card: Card) => void;
  sectionSubtitle?: string;
  color: string;
  setColor: (color: string) => void;
  setConfigCard: Dispatch<SetStateAction<ConfigStateCard>>;

  logo: string;
  onLogoChange: (logoUrl: string) => void;
  backgroundImage: string;
  onBackgroundImageChange: (backgroundUrl: string) => void;
  formik: FormikProps<any>;
  setColorError: (error: boolean) => void;
}

export const CardBlock: FC<CardBlockProps> = ({
  setConfigCard,
  logo,
  onLogoChange,
  backgroundImage,
  onBackgroundImageChange,
  formik,
  setColorError,
  setColor,
}) => {
  const classes = useStylesCardBlock();
  const { setFieldError, errors, values, setFieldValue } = formik;

  return (
    <Grid container flexDirection={'column'} mb="60px">
      <Box>
        <Typography className={classes.uploadLogoTitle}>{WebAppPageStrings.WebAppLogo}</Typography>
        <LogoUploader
          logo={logo}
          onLogoChange={(newLogo) => {
            if (!newLogo) return;
            onLogoChange(newLogo);
          }}
          imagePreviewWidth="96px"
          imagePreviewHeight="96px"
          title=""
          alignment="center"
          imagePadding={1}
          dndTitle={
            <Typography className={classes.dndTitle}>
              {PersonalizationStrings.DragAndDrop}
              <Box>{PersonalizationStrings.OrClick}</Box>
            </Typography>
          }
          dndIcon={
            <Box mt="5px">
              <UploadDndIcon />
            </Box>
          }
          isQuickstart={false}
          subtitle={[
            PersonalizationStrings.UploadImageSubtitle1,
            PersonalizationStrings.UploadImageSubtitle2,
            PersonalizationStrings.UploadImageSubtitle3,
          ]}
        />
      </Box>
      <Box margin="35px 0 10px 10px">
        <Typography className={classes.SectionTitle}>
          {WebAppPageStrings.ColorPickerTitle}
        </Typography>
      </Box>
      <Box maxWidth="460px" mb="16px">
        <CustomColorPickerWithInput
          setFieldError={setFieldError}
          errorText={errors.brandColor as string}
          displayCustomInput
          color={values.brandColor}
          setColorError={setColorError}
          onColorChange={(color) => {
            setColor(color);
            setFieldValue('brandColor', color);
          }}
          title={WebAppPageStrings.ColorPickerSubTitle}
        />
      </Box>
      <Box margin="25px 0 0 10px">
        <Typography className={classes.SectionTitle}>{WebAppPageStrings.UploadMedia}</Typography>
        <BackgroundImageUploader
          backgroundImage={backgroundImage}
          onImageChange={(newImage) => {
            onBackgroundImageChange(newImage || '');
            setConfigCard((prev) => ({ ...prev, isUploadPhoto: true }));

            const launchOptions = values.launchColors.split('_');
            if (!newImage) {
              setFieldValue('launchColors', AnimationStyleOptions.WhiteBrandingLogo);
              return;
            }

            if (logo && newImage) {
              setFieldValue('launchColors', AnimationStyleOptions.WhiteSplashLogo);
              return;
            }

            if (!logo && !newImage) {
              setFieldValue('launchColors', AnimationStyleOptions.WhiteBrandingName);
              return;
            }

            if (launchOptions[1] === 'branding' && newImage) {
              setFieldValue('launchColors', values.launchColors.replace('branding', 'splash'));
              return;
            }

            if (launchOptions[1] === 'splash' && !newImage) {
              setFieldValue('launchColors', values.launchColors.replace('splash', 'branding'));
              return;
            }
          }}
          imagePreviewWidth="140px"
          imagePreviewHeight="240px"
          showEditIcon={true}
          subtitle={[
            PersonalizationStrings.BackgroundSubtitle1,
            PersonalizationStrings.BackgroundSubtitle2,
            PersonalizationStrings.BackgroundSubtitle3,
          ]}
          hideButtons={true}
          alignment="flex-start"
          dndTitle={
            <Typography className={classes.dndTitle}>
              {PersonalizationStrings.DragAndDrop}
              <Box>{PersonalizationStrings.OrClick}</Box>
            </Typography>
          }
          dndDescription={' '}
          dndIcon={
            <Box mb="8px">
              <UploadDndIcon />
            </Box>
          }
          alignCenter={true}
          withBottomRemoveButton={true}
        />
      </Box>
    </Grid>
  );
};
