import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface CustomSelectStyleProps {
  multiline?: boolean;
}

export const useStyles = makeStyles<Theme, CustomSelectStyleProps>((theme) => ({
  label: { color: theme.palette.text.secondary, marginBottom: '14px', fontSize: '16px' },
  TextField: {
    '& label': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      top: '-6px',
      backgroundColor: theme.palette.common.white,
    },
    '& legend': {
      backgroundColor: theme.palette.common.white,
    },
  },
  Select: {
    '& .MuiSelect-select': {
      whiteSpace: (props) => (props.multiline ? 'normal' : 'nowrap'),
    },
  },
}));
