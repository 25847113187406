import i18next, { i18n as i18nInstance } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { ClientTypes } from '../../api/models/common';
import sales from './locales/sales.json';
import marketing from './locales/marketing.json';
import fundraising from './locales/fundraising.json';
import ets from './locales/ets.json';

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next);

  i18n.init({
    lng: ClientTypes.FUNDRAISING,
    fallbackLng: ClientTypes.FUNDRAISING,
    resources: {
      [ClientTypes.SALES]: {
        translation: sales,
      },
      [ClientTypes.FUNDRAISING]: {
        translation: fundraising,
      },
      [ClientTypes.MARKETING]: {
        translation: marketing,
      },
      [ClientTypes.ETS]: {
        translation: ets,
      },
    },
  });

  return i18n;
};

export const i18n = createI18n();
