import { FC } from 'react';

export const PictureIcon: FC = () => (
  <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.01017 38.8648L20.3787 26.4963C21.1212 25.7538 21.4925 25.3825 21.9206 25.2434C22.2972 25.1211 22.7028 25.1211 23.0794 25.2434C23.5075 25.3825 23.8788 25.7538 24.6213 26.4963L36.9073 38.7823M26.25 28.125L31.6287 22.7463C32.3712 22.0038 32.7425 21.6325 33.1706 21.4934C33.5472 21.3711 33.9528 21.3711 34.3294 21.4934C34.7575 21.6325 35.1288 22.0038 35.8713 22.7463L41.25 28.125M18.75 16.875C18.75 18.9461 17.0711 20.625 15 20.625C12.9289 20.625 11.25 18.9461 11.25 16.875C11.25 14.8039 12.9289 13.125 15 13.125C17.0711 13.125 18.75 14.8039 18.75 16.875ZM12.75 39.375H32.25C35.4003 39.375 36.9754 39.375 38.1787 38.7619C39.2371 38.2226 40.0976 37.3621 40.6369 36.3037C41.25 35.1004 41.25 33.5253 41.25 30.375V14.625C41.25 11.4747 41.25 9.89956 40.6369 8.6963C40.0976 7.63789 39.2371 6.77738 38.1787 6.23809C36.9754 5.625 35.4003 5.625 32.25 5.625H12.75C9.5997 5.625 8.02456 5.625 6.8213 6.23809C5.76289 6.77738 4.90238 7.63789 4.36309 8.6963C3.75 9.89956 3.75 11.4747 3.75 14.625V30.375C3.75 33.5253 3.75 35.1004 4.36309 36.3037C4.90238 37.3621 5.76289 38.2226 6.8213 38.7619C8.02456 39.375 9.5997 39.375 12.75 39.375Z"
      stroke="white"
      strokeWidth="3.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
