import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    padding: '24px 14px',
    '& .MuiAccordionSummary-root': {
      ' & .MuiTypography-root': {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '30px',
        letterSpacing: '0.02em',
      },
    },
  },
  title: {
    fontSize: '16px',
    lineHeight: '28px',
    color: '#8B89A0',
    paddingLeft: '15px',
    marginBottom: '25px',
  },
  code: {
    padding: '20px 26px',
    backgroundColor: '#F5F6F8',
    borderRadius: '12px',
  },
  btnWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '10px',
  },
  btn: {
    marginBottom: 0,
  },
  rightSide: {
    padding: '30px',
    '& .MuiAccordionSummary-root': {
      // backgroundColor: 'red',
      // borderRadiusTopLeft: '15px',
      // borderRadiusTopRight: '15px',
    },
  },
  accordionItemWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '16px',
  },
  itemTitle: {
    color: '#1A1538',
    fontSize: '14px',
    lineHeight: '22px',
  },
  numbers: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    border: '1px solid #DCDBE4',
    borderRadius: '50%',
  },
  html: {
    backgroundColor: '#F5F6F8',
    padding: '8px 20px',
    borderRadius: '12px',

    '& p': {
      color: '#7D7D7D',
      fontSize: '12px',
      lineHeight: '26px',
    },
  },
  accordion: {
    bordeRadius: '15px',
    boxShadow: 'none',
    width: '100%',
  },
  padLeft: {
    paddingLeft: '20px',
  },
}));
