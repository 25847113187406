export const PerformanceIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      stroke="inherit"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="4.28125"
        width="18"
        height="14.625"
        rx="3"
        // stroke="inherit"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8 12.9269L10.6666 10.2603L13.3333 12.9269L16 10.2603"
        // stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 21.7188L12 18.9062L14.8125 21.7188"
        // stroke="inherite"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
