import { FC, memo, useMemo, useRef, useState } from 'react';
import { VideoApiModel } from '../../../api/models/videos';
import { useStyles, videoStatusBgColor } from './VideoCard.styles';
import { Box, CardActionArea, CardMedia, Typography } from '@mui/material';
import { TimeIcon } from '../../assets/TimeIcon';
import { CalendarIcon } from '../../assets/CalendarIcon';
import { format } from 'date-fns';
import { PlayIcon } from '../../assets/newDesign/VideoControlsIcons/PlayIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { VideoPlaybackModal } from '../NewDetailsModal/DetailsModalContent/VideoPlaybackModal';
import { DownloadVideoIcon } from '../../assets/DownloadVideoIcon';
import IconButton from '@mui/material/IconButton';
import { LinkVideoIcon } from '../../assets/LinkVideoIcon';
import { Tooltip } from '../Tooltip/Tooltip';
import { SvVideoIcon } from '../../assets/SvVideoIcon';
import { useToasts } from 'react-toast-notifications';
import { VideoDownloadDialog } from '../NewDetailsModal/DetailsModalContent/VideoDownloadDialog/VideoDownloadDialog';
import { DashboardStrings, SettingsPageStrings } from '../../localization/en';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { useTypedSelector } from '../../../store';
import { formatDuration, getDisplayNameAndInitial } from '../../../services/utilities';
import { RewardIcon } from '../../assets/RewardIcon';
import { VideoStatus } from '../VideosDetailHeaderToolbar/VideoStatus';
import { DotsIcon } from '../../assets/DotsIcon';
import { MenuOptions } from './VideoCard.helper';
import CardMenu from './CardMenu/CardMenu';
import { useModal } from '../../../hooks/useModal';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
  video: VideoApiModel;
  svList: SocialVerseAllApiModel[] | null;
  isGridLayout?: boolean;
  openDetailsModal?: (type: MenuOptions, item: VideoApiModel) => void;
  isClipsSlideout?: boolean;
}

export const VideoCard: FC<Props> = memo(
  ({ video, svList, isGridLayout, openDetailsModal, isClipsSlideout }) => {
    const classes = useStyles({ isGridLayout });
    const { addToast } = useToasts();

    const { thumbnailUrl, url, createdAt, shortcode, duration } = video;
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const { subdomain, id: venueId } = useTypedSelector((state) => state.venue.venue);

    const { isOpen: isPlayModalOpen, open: openPlayModal, close: closePlayModal } = useModal();

    const {
      isOpen: isDownloadModalOpen,
      open: openDownloadModal,
      close: closeDownloadModal,
    } = useModal();
    const creationDate = new Date(createdAt);
    const { trackEvent } = useTrackEvent();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenuAnchorEl(null);
    };

    const refClickOutside = useDetectClickOutside({
      onTriggered: () => {
        handleMenuClose();
      },
    });

    const openPlayVideoModal = () => {
      openPlayModal();
      handleMenuClose();
      trackEvent(CreatorsTableEventNames.CreatorDetailsModalStoryClick, {
        selectedUserId: video.userId,
        videoId: video.id,
      });
    };

    const handleCopyUrlToClipboard = () => {
      const videoUrl = `https://${subdomain}${SettingsPageStrings.DomainName}/s?id=${shortcode}`;
      navigator.clipboard.writeText(videoUrl).then(() => {
        addToast(DashboardStrings.VideoLinkCopied, {
          appearance: 'success',
        });
      });
    };

    const listOfSocialVerses = useMemo(() => {
      return svList
        ? svList
            .filter((item) => item.accountId === venueId)
            .filter(
              (item) => item.topVideos && item.topVideos.some((item) => item.videoId === video.id),
            )
        : [];
    }, [svList, venueId, video.id]);

    const isPublished = video.status === 'PUBLISHED' && listOfSocialVerses.length > 0;
    const isPublishedButNoVideos = video.status === 'PUBLISHED' && listOfSocialVerses.length === 0;

    const onOptionSelect = (type: MenuOptions) => {
      openDetailsModal && openDetailsModal(type, video);
      handleMenuClose();
    };

    return (
      <Box className={classes.container}>
        {!isClipsSlideout && (
          <Box
            className={classes.menu}
            onClick={(e) => (isMenuOpen ? handleMenuClose() : handleMenuOpen(e))}
            ref={refClickOutside}
          >
            <DotsIcon />
          </Box>
        )}
        {isMenuOpen && !isClipsSlideout && (
          <Box className={classes.listWrapper}>
            <CardMenu onSelect={onOptionSelect} />
          </Box>
        )}
        <VideoPlaybackModal
          isOpen={isPlayModalOpen}
          isTopGolf={false}
          videoUrl={url}
          onClose={closePlayModal}
          videoId={video.id}
          thumbnailUrl={video.thumbnailUrl}
        />
        <VideoDownloadDialog
          video={video}
          open={isDownloadModalOpen}
          onClose={closeDownloadModal}
        />
        {video && (
          <CardActionArea className={classes.actionArea}>
            <CardMedia
              component="video"
              ref={videoRef}
              className={classes.video}
              src={url}
              onClick={openPlayVideoModal}
              poster={thumbnailUrl}
              preload={isGridLayout ? 'none' : 'metadata'}
            />
            {!isPlayModalOpen && (
              <Box fontSize={48} className={classes.playIcon}>
                <PlayIcon />
              </Box>
            )}
          </CardActionArea>
        )}
        <Box className={classes.detailsWrapper}>
          {isGridLayout && (
            <Box className={classes.dateWrapper}>
              <Typography className={classes.authourText}>
                {getDisplayNameAndInitial(video.userName)}
              </Typography>
              <Typography className={classes.rewardIcon}>
                <Tooltip
                  content={
                    <Box>
                      <Box className={classes.campaignName}>{video.campaignName ?? '---'}</Box>
                    </Box>
                  }
                  icon={
                    <IconButton className={classes.iconButton}>
                      <RewardIcon />
                    </IconButton>
                  }
                  light
                />
              </Typography>
            </Box>
          )}
          <Box className={classes.dateWrapper} style={{ marginTop: !isGridLayout ? '10px' : '0' }}>
            <Typography className={classes.timeText}>
              <TimeIcon className={classes.timeIcon} />
              {formatDuration(duration || 0, { twoDigitsMinutes: true })}
            </Typography>
            <Typography className={classes.timeText}>
              <CalendarIcon className={classes.timeIcon} />
              {format(creationDate, 'PP')}
            </Typography>
          </Box>
          <Box className={classes.buttonsWrapper}>
            <Tooltip
              title={'Download'}
              icon={
                <IconButton className={classes.iconButton} onClick={openDownloadModal}>
                  <DownloadVideoIcon />
                </IconButton>
              }
            />
            <Tooltip
              title={'Copy Link'}
              icon={
                <IconButton className={classes.iconButton} onClick={handleCopyUrlToClipboard}>
                  <LinkVideoIcon />
                </IconButton>
              }
            />
            <Tooltip
              content={
                isPublished ? (
                  <Box>
                    {listOfSocialVerses.length > 0
                      ? listOfSocialVerses.map((item) => (
                          <Box className={classes.svName} key={item.title}>
                            {item.name}
                          </Box>
                        ))
                      : '---'}
                  </Box>
                ) : (
                  <Box>{isPublishedButNoVideos ? 'UNPUBLISHED' : video.status}</Box>
                )
              }
              icon={
                <Box
                  className={classes.svTooltip}
                  style={{
                    justifyContent: isPublished ? 'space-between' : 'center',
                    background: isPublished ? videoStatusBgColor.PUBLISHED : 'none',
                  }}
                >
                  {video.status === 'PUBLISHED' && listOfSocialVerses.length > 0 ? (
                    <SvVideoIcon />
                  ) : isPublishedButNoVideos ? (
                    <VideoStatus status={'UNPUBLISHED'} />
                  ) : (
                    <VideoStatus status={video.status} />
                  )}
                  {isPublished && (
                    <Typography className={classes.svCount}>{listOfSocialVerses.length}</Typography>
                  )}
                </Box>
              }
              light
            />
          </Box>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) => prevProps.video === nextProps.video,
);
