import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';

import { PictureIcon } from '../../../common/assets/PictureIcon';
import { CustomButton } from '../../../common/components/customButton/customButton';
import { CustomTextField } from '../../../common/components/customTextField/CustomTextField';
import { SimpleModal } from '../../../common/components/SimpleModal/SimpleModal';
import { resizeImageForTwilioMMSCommunication } from '../../../services/utilities';

import { CommonStrings, RewardsPageStrings } from '../../../common/localization/en';
import { useTypedSelector } from '../../../store';
import {
  activitiesArr,
  activitiesLiterals,
  IncevtiveCreationValues,
  Radios,
  Tabs,
  validationSchemaCreation,
} from './createIncentiveModal.helper';
import { ErrorCircleIcon } from '../../../common/assets/newDesign/ErrorCircleIcon';
import { useStyles } from './createIncentiveModal.style';
import { RewardFulfillmentMethod } from '../../../api/models/rewardFulfillment';
import { Activity, CampaignActivitiesTypes } from '../../../api/models/campaigns';
import { ToggleButtons } from '../../../common/components/ToggleButtons/ToggleButtons';
import { REWARD_CAMPAIGN_MAX_LENGTH } from '../../../common/constants/defaults';
import { useUploadFileToStorage } from '../../../hooks/useUploadFileToStorage';
import { useRewardFulfillmentApiCalls } from '../../webappSettings/Incentives/useRewardFulfillmentApiCalls';
import { RewardsCard } from './RewardsCard';
import { useClientType } from '../../../services/hooks/useClientType';
import { DEFAULT_VIDEO_CREATE_POINTS } from '../../../common/constants/constants';

interface Props {
  isEdit?: boolean;
  isOpen: boolean;
  onSave: (values: IncevtiveCreationValues | any, activities: Activity[]) => void;
  onCloseCreationModal: () => void;
  initialParams?: IncevtiveCreationValues;
  onOpenEndCampaignModal?: () => void;
  selectedTab?: Tabs;
  setSelectedTab?: (tab: Tabs) => void;
}
export const СreateIncentiveModal: React.FC<Props> = ({
  isOpen,
  onSave,
  onCloseCreationModal,
  isEdit,
  initialParams,
  onOpenEndCampaignModal,
  selectedTab,
  setSelectedTab,
}) => {
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const isActiveCampaign = !!activeCampaign;
  const { isHealthCare } = useClientType();
  const { postRewardFulfillmentConfig } = useRewardFulfillmentApiCalls();
  const { config: rewardFulfillmentConfig } = useTypedSelector((store) => store.rewardFulfillment);

  const [selectedRadio, setSelectedRadio] = React.useState(Radios.RewardCard);

  const inputFile = useRef<HTMLInputElement | null>(null);

  const targetPoints = initialParams?.points;
  const initialFormValues = {
    imageUrl: initialParams?.imageUrl || '',
    name: isEdit ? initialParams?.name : '',
    action: initialParams?.action || '',
    title: initialParams?.title || '',
  };

  const formikProfile = useFormik({
    initialValues: initialFormValues,
    enableReinitialize: true,
    validateOnChange: isEdit ? false : true,
    validateOnBlur: false,
    validationSchema: validationSchemaCreation,
    onSubmit: (values) => {
      if (activeCampaign && isEdit) {
        onCloseCreationModal();
        onOpenEndCampaignModal?.();
      } else {
        const activitiesToSave: Activity[] =
          selectedTab === 'No Incentives'
            ? [
                {
                  points: DEFAULT_VIDEO_CREATE_POINTS,
                  status: true,
                  activityType: CampaignActivitiesTypes.CreateVideo,
                },
              ]
            : initialParams?.activities?.filter((activity) => activity.status) || [];

        if (selectedTab === Tabs.NoReward) {
          postRewardFulfillmentConfig({
            ...rewardFulfillmentConfig,
            method: RewardFulfillmentMethod.MANUAL_SMS,
          });
        }

        onSave({ ...values }, activitiesToSave || []);
      }
    },
  });
  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleChange,
    touched,
    isValid: isFormValid,
    setFieldTouched,
    resetForm,
  } = formikProfile;
  const styles = useStyles();

  const { uploadFileToStorage } = useUploadFileToStorage();
  const [loading, setLoading] = useState(false);

  const onCardImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onCardImageChange(file);
  };

  const onCardImageChange = async (logoFile: File) => {
    setLoading(true);
    const uploadedFile = await uploadFileToStorage(logoFile);
    if (uploadedFile?.downloadUrl) {
      const resizedImage = await resizeImageForTwilioMMSCommunication(uploadedFile.downloadUrl);
      setFieldValue('imageUrl', resizedImage);
    }
    setLoading(false);
  };

  const onCancelHandler = () => {
    onCloseCreationModal();
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  return (
    <SimpleModal openModal={isOpen} paperPadding="0px" toggleModal={onCloseCreationModal}>
      <Grid item xs={12} className={styles.root}>
        <Box px={'30px'}>
          <Typography className={styles.title}>
            {isEdit ? 'Reward Campaign Details' : 'New Reward Campaign'}
          </Typography>
          {!isEdit && (
            <ToggleButtons
              selectedItem={selectedTab}
              items={[
                {
                  id: Tabs.RewardCampaign,
                  title: Tabs.RewardCampaign,
                  onClick: () => {
                    if (selectedTab && setSelectedTab) {
                      setSelectedTab(Tabs.RewardCampaign);
                      setSelectedRadio(Radios.RewardCard);
                    }
                  },
                },
                {
                  id: Tabs.NoReward,
                  title: Tabs.NoReward,
                  onClick: () => {
                    if (selectedTab && setSelectedTab) {
                      setSelectedTab(Tabs.NoReward);
                      setSelectedRadio(Radios.ThankYouCard);
                    }
                  },
                },
              ]}
            />
          )}
          <Box className={styles.nameAndActivities}>
            <Box className={styles.nameInputWrapper}>
              <CustomTextField
                label={RewardsPageStrings.CampaignName}
                value={values.name || ''}
                placeholder={RewardsPageStrings.CampaignName}
                name="name"
                handleChange={handleChange}
                handleTouched={setFieldTouched}
                error={touched.name && errors.name ? errors.name : ''}
                maxLength={REWARD_CAMPAIGN_MAX_LENGTH.name}
                disabled={isActiveCampaign}
                tooltip="This is the name of the rewards campaign name for your records."
                labelTextClassName={styles.labelName}
              />
            </Box>

            <Box className={styles.activitiesWrapper}>
              <Typography>Campaign activities</Typography>
              <Box className={styles.activitiesList}>
                {selectedTab === 'No Incentives'
                  ? activitiesArr['CREATE_VIDEO']?.(
                      DEFAULT_VIDEO_CREATE_POINTS,
                      activitiesLiterals['CREATE_VIDEO'],
                    )
                  : initialParams?.activities?.map((activity) => {
                      if (activity.status !== undefined && !activity.status) return null;
                      return activitiesArr[activity.activityType]?.(
                        activity.points,
                        activitiesLiterals[activity.activityType],
                      );
                    })}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          marginY={values.imageUrl ? '20px !important' : '0'}
          className={clsx(styles.inputWrap, errors.imageUrl ? styles.ImageValidationError : '')}
          style={{
            backgroundImage: values.imageUrl ? `url(${values.imageUrl})` : '',
            width: values.imageUrl ? '425px' : 'auto',
            borderRadius: values.imageUrl ? '20px' : '0',
            margin: values.imageUrl ? '0 auto' : 'inherit',
          }}
        >
          {errors.imageUrl ? (
            <Typography className={styles.ImageValidationCaption}>
              <ErrorCircleIcon />
              {errors.imageUrl}
            </Typography>
          ) : null}
          {values.imageUrl ? null : (
            <RewardsCard
              reward={values.title}
              isNullReward={isEdit ? !!activeCampaign?.isNullReward : selectedTab === Tabs.NoReward}
            />
          )}
          <Box
            className={styles.selectImageIconWrapper}
            sx={{
              cursor: isActiveCampaign ? 'default !important' : '',
              visibility: 'hidden',
            }}
          >
            <IconButton color="primary" component="label" disabled={isActiveCampaign}>
              <input
                hidden
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={onCardImageChangeHandler}
                ref={inputFile}
              />
              <PictureIcon />
            </IconButton>
          </Box>
          {targetPoints && (
            <Box className={styles.targetPoints}>
              <Typography style={{ fontSize: '14px' }}>
                {RewardsPageStrings.TargetPoints}
              </Typography>
              <Typography style={{}}>{targetPoints}</Typography>
            </Box>
          )}
        </Box>
        {!isEdit && (
          <RadioGroup
            row
            defaultValue={Radios.RewardCard}
            name="radio-buttons-group"
            value={selectedRadio}
            onChange={(e) => setSelectedRadio(e.target.value as Radios)}
            className={styles.radioGroup}
          >
            <FormControlLabel
              value={selectedTab === Tabs.RewardCampaign ? Radios.RewardCard : Radios.ThankYouCard}
              control={<Radio />}
              label={selectedTab === Tabs.RewardCampaign ? Radios.RewardCard : Radios.ThankYouCard}
              onClick={() => {
                setFieldValue('imageUrl', '');
                if (inputFile.current?.value) {
                  inputFile.current.value = '';
                }
              }}
            />

            <FormControlLabel
              value={Radios.UploadCard}
              control={
                <Radio
                  onClick={() => {
                    setSelectedRadio(Radios.UploadCard);
                    inputFile.current?.click();
                  }}
                />
              }
              label={Radios.UploadCard}
            />
          </RadioGroup>
        )}
        <Box
          style={{
            padding: '0px 30px 40px 30px',
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box className={styles.form}>
              <CustomTextField
                label={RewardsPageStrings.RewardIncentive}
                value={values.title}
                labelClassName={styles.marginTop}
                name="title"
                handleChange={handleChange}
                handleTouched={setFieldTouched}
                error={touched.title && errors.title ? errors.title : ''}
                maxLength={REWARD_CAMPAIGN_MAX_LENGTH.title}
                disabled={isActiveCampaign}
                tooltip={RewardsPageStrings.RewardIncentiveTooltip.replace(
                  'customer',
                  isHealthCare ? 'patient' : 'customer',
                )}
              />
              <CustomTextField
                label={RewardsPageStrings.CampaignObjective}
                value={values.action}
                labelClassName={styles.marginTop}
                name="action"
                handleChange={handleChange}
                handleTouched={setFieldTouched}
                error={touched.action && errors.action ? errors.action : ''}
                maxLength={REWARD_CAMPAIGN_MAX_LENGTH.action}
                disabled={isActiveCampaign}
                tooltip={RewardsPageStrings.CampaignObjectiveTooltip.replace(
                  'customer',
                  isHealthCare ? 'patient' : 'customer',
                )}
              />

              <Stack marginTop={'30px'} direction={'row'} alignItems={'center'} spacing={1.5}>
                <CustomButton
                  disabled={!isFormValid || loading}
                  variant="contained"
                  color="primary"
                  colorType="primary"
                  type="submit"
                  isLoading={loading}
                  title={isEdit ? CommonStrings.EndCampaign : CommonStrings.AddNewReward}
                />

                {!isEdit && (
                  <Button
                    onClick={onCancelHandler}
                    sx={{ padding: '10px 25px', borderRadius: '100px' }}
                  >
                    {CommonStrings.ResetRewardForm}
                  </Button>
                )}
              </Stack>
            </Box>
          </form>
        </Box>
      </Grid>
    </SimpleModal>
  );
};
