import { Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { RoundedButton } from '../../Button/RoundedButton';
import { GBPSyncModalBase } from './GBPModalBase';
import { GBPSyncUpdateModal } from './GBPSyncUpdateModal';
import { useModal } from '../../../../hooks/useModal';
import { useTypedSelector } from '../../../../store';

interface GBPSyncActiveModalProps {
  show: boolean;
  onClose?: () => void;
  onUnsyncClick?: () => void;
}

export const GBPSyncActiveModal: React.FC<GBPSyncActiveModalProps> = ({
  show,
  onClose,
  onUnsyncClick,
}) => {
  const { isOpen: showSyncModal, open: openSyncModal, close: closeSyncModal } = useModal();

  const accountName = useTypedSelector((state) => state.gpbSlice.connectedAccount?.title);

  return (
    <>
      <GBPSyncUpdateModal
        unsyncMode
        show={showSyncModal}
        onClose={() => {
          closeSyncModal();
          onClose?.();
        }}
        onConfirm={() => {
          closeSyncModal();
          onClose?.();
          onUnsyncClick?.();
        }}
      />

      <GBPSyncModalBase show={!showSyncModal && show} onClose={onClose}>
        <Stack spacing="20px" alignItems="center">
          <Typography
            fontWeight="500"
            fontSize="18px"
            color="#1A1538"
            letterSpacing="0.36px"
            lineHeight="150%"
            width="300px"
            textAlign="center"
          >
            Videos added to this SocialVerse are automatically added to {accountName}’s Google
            Business Profile.
          </Typography>
          <RoundedButton
            title="Close"
            onClick={(e) => {
              e.stopPropagation();
              onClose?.();
            }}
          />

          <Typography
            fontWeight="700"
            fontSize="12px"
            color="#8B89A0"
            letterSpacing="2%"
            lineHeight="150%"
            width="340px"
            textAlign="center"
          >
            To prevent future videos added to this SocialVerse from being added to the Google
            Business Profile{' '}
            <Link
              href="#"
              onClick={() => {
                onClose?.();
                openSyncModal();
              }}
            >
              click here
            </Link>
          </Typography>
        </Stack>
      </GBPSyncModalBase>
    </>
  );
};
