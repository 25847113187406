import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { VideoApiModel } from '../../../api/models/videos';
import { TopGolfVideosApiModel } from '../../../api/models/topgolfVideos';

export const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 160,
  },
  menuItem: {
    marginLeft: '8px',
    textTransform: 'capitalize',
  },
  published: {
    color: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    color: theme.palette.common.ContentTable.unpublished,
  },
  moderated: {
    color: theme.palette.common.ContentTable.moderated,
  },
  moderatedIcon: {
    marginLeft: '12px',
    color: theme.palette.error.main,
  },
}));

export const useIndicatorStyles = makeStyles((theme: Theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  published: {
    backgroundColor: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    backgroundColor: theme.palette.common.ContentTable.unpublished,
  },
  moderated: {
    backgroundColor: theme.palette.common.ContentTable.moderated,
  },
}));
export interface StatusDropdownProps {
  item: VideoApiModel | TopGolfVideosApiModel;
}
export type StatusValues = 'published' | 'unpublished' | 'moderated';
export const statuses: StatusValues[] = ['published', 'unpublished', 'moderated'];
export const topGolfStatuses: StatusValues[] = ['published', 'unpublished'];
