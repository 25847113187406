import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { ListFilterItem } from '../listFiltersComponents/ListFilterItem';
import { useState, MouseEvent, FC } from 'react';
import { FSModal } from '../../modal/Modal';
import { deleteSearchFilterById } from '../../../../store/slices/searchFiltersSlice';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: '20px',
    width: '300px',
    paddingBottom: '20px',
  },
  title: {
    marginLeft: '20px',
    color: theme.palette.common.black,
    fontSize: '16px',
    fontWeight: 'bolder',
    marginBottom: '20px',
  },
  ModalTitle: {
    fontWeight: 500,
    lineHeight: '34px',
    fontSize: '20px',
    color: theme.palette.common.black,
  },
  ModalText: {
    marginTop: '24px',
    fontSize: '12px',
    lineHeight: '24px',
  },
  ModalBtn: {
    marginTop: '22px',
    borderRadius: '50px',
    width: 'fit-content',
    fontSize: '14px',
  },
}));

enum listFilterStrings {
  savedFilters = 'Saved Filters',
}

interface ListFiltersprops {
  handleClose: () => void;
}

export const ListFilters: FC<ListFiltersprops> = ({ handleClose }) => {
  const classes = useStyles();
  const [id, setId] = useState('');
  const { items } = useTypedSelector((state) => state.SearchFilterSlice);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [filterId, setFilterId] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setId(id !== event.currentTarget?.id ? event.currentTarget.id : '');
  };

  const handleDelete = () => {
    if (filterId) {
      dispatch(deleteSearchFilterById(filterId));
      setFilterId(null);
    }
  };

  const renderItems = () => {
    return items.map((item) => {
      return (
        <ListFilterItem
          item={item}
          anchorEl={anchorEl}
          open={id === item.id}
          handleOpen={handleClick}
          handleClose={handleClose}
          handleDeleteIconClick={(id) => setFilterId(id)}
        />
      );
    });
  };
  return (
    <div className={classes.root}>
      {filterId && (
        <FSModal
          onClose={() => setFilterId(null)}
          modalIsOpen={filterId ? true : false}
          width="470px"
        >
          <Typography className={classes.ModalTitle}>Delete this saved filter?</Typography>
          <Typography className={classes.ModalText}>
            You won’t be able to undo this action.
          </Typography>
          <Button
            onClick={handleDelete}
            className={classes.ModalBtn}
            variant="contained"
            size="large"
            color="primary"
          >
            Yes, Delete This Saved Filter
          </Button>
        </FSModal>
      )}
      <p className={classes.title}>{listFilterStrings.savedFilters}</p>
      <div>{renderItems()}</div>
    </div>
  );
};
