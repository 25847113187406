import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect } from 'react';

import { RoundedButton } from '../../../../common/components/Button/RoundedButton';
import { useServiceAgreementLink } from '../../../../hooks/useServiceAgreementLink';
import { redirectToPage } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getServiceAgreement } from '../../../../store/slices/venueSlice';

interface Props {
  onOpenAgreement?: () => void;
}

export const PricingPlanPreview: React.FC<Props> = ({ onOpenAgreement }) => {
  const theme = useTheme();
  const pricingPlan = useTypedSelector((store) => store.BillingSlice.pricingPlan);
  const { id } = useTypedSelector((state) => state.venue.venue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getServiceAgreement({ id }));
    }
  }, [id, dispatch]);

  const { serviceAgreement } = useTypedSelector((state) => state.venue);
  const serviceAgreementLink = useServiceAgreementLink();

  const handleOpenAgreement = () => {
    onOpenAgreement?.();
    const url =
      !serviceAgreement.error && serviceAgreement.value
        ? window.URL.createObjectURL(serviceAgreement.value)
        : serviceAgreementLink;

    redirectToPage(url, true);
  };

  return (
    <Box
      bgcolor={theme.palette.primary.main}
      p="30px"
      width="250px"
      borderRadius="14px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography color="white" fontSize="24px" fontWeight={700} mb="12px" letterSpacing="0.02em">
        Pricing Plan
      </Typography>
      <Typography
        color="white"
        fontSize="24px"
        fontWeight={500}
        textAlign="center"
        mb="4px"
        textTransform="capitalize"
        letterSpacing="0.02em"
      >
        {pricingPlan?.billingPeriod ?? ''} Subscription
      </Typography>
      <Typography color="white" fontSize="10px" mb="4px" letterSpacing="0.02em">
        Minimum 12 month contract
      </Typography>
      <Typography color="white" fontSize="42px" fontWeight={600} mb="14px" letterSpacing="0.02em">
        {pricingPlan?.subscriptionFee}
      </Typography>
      <RoundedButton
        backgroundColor="white"
        title="Open Agreement"
        textProps={{ color: theme.palette.primary.main }}
        onClick={handleOpenAgreement}
      />
    </Box>
  );
};
