import styled from 'styled-components';
import { theme } from '../../../theme';

export const RewardsActivitiesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SubmitBtnWrapper = styled.div`
  position: absolute;
  right: 24px;
  bottom: 24px;
  align-self: flex-end;

  > button:first-child {
    margin-right: 16px;
  }

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;

export const ImgResultWrapper = styled.div`
  height: 297px;
  width: 262px;
  margin: auto;
`;
