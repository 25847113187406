import GridImage from '../../../../../common/assets/newDesign/SocialVerse/previews/gridPreview.png';

export const previewGrids = [
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
  {
    card: GridImage,
    name: 'First L',
  },
];

export const getPreviewGridImages = (numberOfGridRows: number) => {
  const numberOfItems = (numberOfGridRows - 1) * 5 + 10;
  return Array.from({ length: numberOfItems }, (_, index) => previewGrids[index % 10]);
};
