import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideosDetailUserComponentStyles = makeStyles((theme: Theme) => ({
  userSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  userName: {
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  userDate: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
  },
  PluginSection: {
    flex: 8,
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  AwardSection: {
    flex: 1,
    marginTop: '10px',
  },
  AwardBtn: {
    padding: '10px 20px',
    borderRadius: '30px',
  },
  AwardBtnDisabled: {
    padding: '10px 20px',
    borderRadius: '30px',
    '& .MuiButton-startIcon': {
      opacity: 0.26,
    },
  },

  AwardsModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    padding: 24,
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalBtn: {
    padding: '10px 25px',
    fontSize: '14px',
    borderWidth: 1,
    borderRadius: '30px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  CancelBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    marginRight: 20,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));
