import React, { useEffect, useState } from 'react';
import { useStyles } from './StatsBucket.styles';
import { Box, Typography } from '@mui/material';
import { WorkspaceStrings } from '../../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import peekLogo from '../../../assets/peekLogo.png';
import { getConnectedPeekAccount } from '../../../../store/slices/peekSlice';

const StatsBucket = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { countsData, search } = useTypedSelector((state) => state.smsMessages);
  const { connectedAccount } = useTypedSelector((state) => state.peek);
  const {
    venue: { id: accountId },
  } = useTypedSelector((state) => state.venue);
  const [countsDataSearchExcluded, setCountsDataSearchExcluded] = useState(countsData);

  useEffect(() => {
    if (search === '') {
      setCountsDataSearchExcluded(countsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countsData]);

  useEffect(() => {
    dispatch(getConnectedPeekAccount({ accountId }));
  }, [accountId, dispatch]);

  return (
    <Box className={styles.container}>
      <Box className={styles.bucket}>
        <Typography className={styles.bucketTitle}>{WorkspaceStrings.TotalOutgoing}</Typography>
        <Typography className={styles.bucketAmount}>
          {countsDataSearchExcluded.totalDelivered + countsDataSearchExcluded.totalUndelivered}
        </Typography>
      </Box>
      <Box className={styles.bucket}>
        <Typography className={styles.bucketTitle}>{WorkspaceStrings.Delivered}</Typography>
        <Typography className={styles.bucketAmount}>
          {countsDataSearchExcluded.totalDelivered}
        </Typography>
      </Box>
      <Box className={styles.bucket}>
        <Typography className={styles.bucketTitle}>{WorkspaceStrings.Undelivered}</Typography>
        <Typography className={styles.bucketAmount}>
          {countsDataSearchExcluded.totalUndelivered}
        </Typography>
      </Box>
      <Box className={styles.bucket}>
        <Typography className={styles.bucketTitle}>{WorkspaceStrings.OptOut}</Typography>
        <Typography className={styles.bucketAmount}>{countsDataSearchExcluded.optOuts}</Typography>
      </Box>
      {connectedAccount ? (<Box
        className={styles.peekWrapper}
        sx={{ border: connectedAccount ? '1px solid #EAECF0' : '1px solid #5242EA' }}
      >
        <Box className={styles.peekTopWrapper}>
          <Box className={styles.badgeWrapper}>
            <img src={peekLogo} alt="Peek logo" className={styles.peekLogo} />
            {connectedAccount && (
              <Typography className={styles.connectedBadge}>
                {WorkspaceStrings.Connected}
              </Typography>
            )}
          </Box>
        </Box>
        <Typography className={styles.peekInfo}>{WorkspaceStrings.PeekInfo}</Typography>
        <Typography className={styles.accountId}>
          {WorkspaceStrings.AccountId} {accountId}
        </Typography>
      </Box>) : <span></span>}
    </Box>
  );
};

export default StatsBucket;
