import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

import { DetailsSidebarStrings } from '../../../../localization/en';
import { theme } from '../../../../../theme';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
// import { StatusComponent } from './StatusComponent/StatusComponent';
import { UserStatuses } from '../../../../../api/models/users';
import { updateUserById } from '../../../../../store/slices/userDetailsSlice';
import { UserStatusDropDown } from '../../../../UserStatusDropDown/UserStatusDropDown';
import { useToasts } from 'react-toast-notifications';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../../../constants/events/creatorsTableEvents';
import { useParams } from 'react-router-dom';

interface SalesHeaderProps {
  onClose: () => void;
  entity?: 'heroes' | 'campaigns';
}
export const SalesHeader: FC<SalesHeaderProps> = ({ onClose, entity }) => {
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { status: userStatus } = userDetails || {};
  const { addToast } = useToasts();
  const { isHealthCare } = useClientType();

  const params = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();

  // const allStatuses = ['ACTIVE', 'INACTIVE', 'ARCHIVED'] as Array<UserStatuses>;
  // const statusMappingChange = {
  //   ACTIVE: ['ACTIVE', 'ARCHIVED'] as Array<UserStatuses>,
  //   INACTIVE: ['INACTIVE', 'ARCHIVED'] as Array<UserStatuses>,
  //   ARCHIVED: ['ARCHIVED', 'INACTIVE'] as Array<UserStatuses>,
  // };
  // // const statuses = userStatus ? statusMappingChange[userStatus] : allStatuses;
  const dispatch = useAppDispatch();
  const handleStatusUpdate = (newStatus: UserStatuses) => {
    if (!userDetails) {
      return;
    }
    dispatch(updateUserById({ update: { status: newStatus }, id: userDetails.id }));
    const toastMessage =
      newStatus === 'ACTIVE'
        ? DetailsSidebarStrings.ActiveStatusMessage
        : DetailsSidebarStrings.DeactivatedStatusMessage;
    addToast(toastMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });

    trackEvent(
      (newStatus === UserStatuses.active
        ? CreatorsTableEventNames.CreatorDetailsModalChangeStatusActive
        : CreatorsTableEventNames.CreatorDetailsModalChangeStatusDeactive
      ).replace('creators', entity === 'campaigns' ? 'campaigns' : 'creators'),
    );
  };

  const handleClose = () => {
    onClose();
    trackEvent(
      CreatorsTableEventNames.CloseCreatorDetailsModalClick.replace(
        'creators',
        entity === 'campaigns' ? 'campaigns' : 'creators',
      ),
      {
        selectedUserId: params?.id,
      },
    );
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      padding="20px 30px 0 20px"
      position="sticky"
      bgcolor="#fff"
      zIndex="3"
      top={0}
      left={0}
    >
      <Box mr={1} color={theme.palette.common.black}>
        <IconButton onClick={handleClose} size="large">
          <Close
            style={{
              color: '#1A1538',
            }}
          />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto" color={theme.palette.text.primary}>
        {isHealthCare
          ? DetailsSidebarStrings.HeroesDetailsTitle_TruBlu
          : DetailsSidebarStrings.HeroesDetailsTitle}
      </Box>
      <>
        {userDetails && userStatus && (
          // (userStatus === UserStatuses.active ? (
          <UserStatusDropDown
            status={userStatus}
            handleStatusUpdate={handleStatusUpdate}
            id={userDetails.id}
            name={userDetails.name}
            lastInvitationSentAt={userDetails.lastInvitationSentAt}
          />
        )}
      </>
      {/* // ) : (
          //   <SendButton id={userDetails.id} name={userDetails.name} status={userDetails.status} />
          // ))} */}
    </Box>
  );
};
