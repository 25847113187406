import { FC } from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RewardsPageStrings } from '../../../../common/localization/en';
import { IphoneXframeDeprecated } from '../../../../common/components/previews/phonePreviewWrapper';
import { EarningListSection } from './EarningListSection/EarningListSection';
import { RewardCirclePoint } from '../../../../common/components/previews/RewardCirclePoint/RewardCirclePoint';
import { ActivityApiModel } from '../../../../api/models/rewards';
import { getActivitiesListWithIcons } from '../../rewardsActivities/ActivitiesListSection/ActivitiesListSection.helper';
import { theme } from '../../../../theme';
import { LogoSection, MenuBar } from '../../../../common/components/previews/previews.style';
import {
  PreviewContainer,
  Header,
  MainWrapper,
  Title,
  SliderImage,
  SliderContent,
  TopSliderContent,
  RewardName,
  RewardCirclePointWrapper,
  Slide,
  ShadowBox,
  ActivitiesTitle,
} from './Preview.style';

const useStyles = makeStyles((theme: Theme) => ({
  styledButton: (props: { brandColor: string }) => {
    return {
      marginTop: '8px',
      padding: '2px 16px',
      minWidth: '44px',
      borderWidth: '0 !important',
      fontSize: '10px',
      color: theme.palette.common.black,
      backgroundColor: props.brandColor + '!important',
    };
  },
}));

interface PreviewProps {
  brandColor: string;
  rewardImgUrl: string | null;
  logo: string;
  name: string;
  points: number;
  activities: ActivityApiModel[];
}
export const Preview: FC<PreviewProps> = ({
  brandColor,
  rewardImgUrl,
  logo = '',
  name,
  points,
  activities,
}) => {
  const activitiesListWithIcons = getActivitiesListWithIcons(activities);
  const styles = useStyles({ brandColor });
  return (
    <PreviewContainer>
      <IphoneXframeDeprecated>
        <MainWrapper>
          <Header>
            <MenuBar />
            <LogoSection src={logo} />
          </Header>
          <Title color={brandColor}>{RewardsPageStrings.Rewards}</Title>
          <Slide>
            <SliderImage src={rewardImgUrl || ''} />
            <SliderContent>
              <TopSliderContent>
                <RewardName>{name}</RewardName>
                <RewardCirclePointWrapper>
                  <RewardCirclePoint points={points} color={theme.palette.error.main} />
                </RewardCirclePointWrapper>
              </TopSliderContent>
            </SliderContent>
            <ShadowBox />
          </Slide>
          <Button fullWidth className={styles.styledButton} variant="contained" color="primary">
            {RewardsPageStrings.PreviewCreateBtn}
          </Button>
          <ActivitiesTitle color={brandColor}>{RewardsPageStrings.ActivitiesTitle}</ActivitiesTitle>
          <EarningListSection items={activitiesListWithIcons} mainColor={brandColor} />
        </MainWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
