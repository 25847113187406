import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useStyles } from '../ClipsSearchTags.styles';
import { SearchTagsStrings } from '../../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  SearchOperationType,
  VideoAttributeName,
  VideoLengthFilterAtAttribute,
} from '../../../../api/models/searchFilterModels';
import {
  removeVideosLengthFilter,
  setActiveVideoFilterAttributes,
} from '../../../../store/slices/searchFilterSlice';
import { getVideosBySearchFilter } from '../../../../store/slices/videosSlice';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import { convertToMmSsStringFormat } from '../ClipsSearchTags.helpers';
import { TimePicker } from '../../LengthSelect/TimePicker/TimePicker';
import { LengthRange } from '../../LengthSelect/LengthSelect';
import MenuContainer from '../../MenuContainer/MenuContainer';

const LengthTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { activeVideoFilterAttributes, search } = useTypedSelector((state) => state.searchFilter);
  const { page, size, sort } = useTypedSelector((state) => state.videos);
  const {
    venue: { id },
  } = useTypedSelector((state) => state.venue);

  const [lengthRange, setLengthRange] = useState<LengthRange[]>(
    activeVideoFilterAttributes
      .filter((attribute) => attribute.name === VideoAttributeName.Length)
      .map((attribute) => ({
        value: attribute.value,
        operation: attribute.operation,
      })) as LengthRange[],
  );
  const [lengthAnchorEl, setLengthAnchorEl] = useState<null | HTMLElement>(null);
  const isLengthMenuOpen = Boolean(lengthAnchorEl);

  const handleLengthMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setLengthAnchorEl(event.currentTarget);
  };

  const handleLengthMenuClose = (predefinedSelected?: boolean) => {
    setLengthAnchorEl(null);

    if (predefinedSelected) {
      return;
    }

    applyFilterChange();
  };

  const lengthFilterFromIdx = activeVideoFilterAttributes.findIndex(
    (item) =>
      item.name === VideoAttributeName.Length && item.operation === SearchOperationType.GreaterThan,
  );

  const lengthFilterToIdx = activeVideoFilterAttributes.findIndex(
    (item) =>
      item.name === VideoAttributeName.Length && item.operation === SearchOperationType.LessThan,
  );

  const handlePredefinedFilterChange = (value: number) => {
    const range = { value, operation: SearchOperationType.LessThan } as LengthRange;
    setLengthRange([range]);
    handleLengthMenuClose(true);

    const attributes = activeVideoFilterAttributes.filter((attribute) => {
      return !(
        attribute.name === VideoAttributeName.Length &&
        ((attribute.operation === SearchOperationType.LessThan && lengthFilterToIdx > -1) ||
          (attribute.operation === SearchOperationType.GreaterThan && lengthFilterFromIdx > -1))
      );
    });

    dispatch(
      setActiveVideoFilterAttributes([
        ...attributes,
        {
          ...range,
          name: VideoAttributeName.Length,
        },
      ]),
    );
  };

  const applyFilterChange = () => {
    if (lengthRange.length > 0) {
      const lengthAttributes: VideoLengthFilterAtAttribute[] = lengthRange.map((item) => ({
        ...item,
        name: VideoAttributeName.Length,
      }));

      const attributes = activeVideoFilterAttributes.filter((attribute) => {
        return !(
          attribute.name === VideoAttributeName.Length &&
          ((attribute.operation === SearchOperationType.LessThan && lengthFilterToIdx > -1) ||
            (attribute.operation === SearchOperationType.GreaterThan && lengthFilterFromIdx > -1))
        );
      });

      dispatch(setActiveVideoFilterAttributes([...attributes, ...lengthAttributes]));
    }
  };

  const handleRemoveLengthFilter = () => {
    dispatch(removeVideosLengthFilter());

    dispatch(
      getVideosBySearchFilter({
        filter: activeVideoFilterAttributes.filter(
          (filter) => filter.name !== VideoAttributeName.Length,
        ),
        pageable: {
          page,
          size,
          sort,
        },
        venueId: id,
        search: search,
      }),
    );
  };

  const minutesFrom =
    lengthFilterFromIdx !== -1
      ? Math.floor(+activeVideoFilterAttributes[lengthFilterFromIdx].value / 60)
      : 0;
  const secondsFrom =
    lengthFilterFromIdx !== -1
      ? +activeVideoFilterAttributes[lengthFilterFromIdx].value - minutesFrom * 60
      : 0;

  const minutesTo =
    lengthFilterToIdx !== -1
      ? Math.floor(+activeVideoFilterAttributes[lengthFilterToIdx].value / 60)
      : -1;
  const secondsTo =
    lengthFilterToIdx !== -1
      ? +activeVideoFilterAttributes[lengthFilterToIdx].value - minutesTo * 60
      : -1;

  return (
    <>
      <Box
        className={`${styles.tag} ${lengthFilterToIdx !== -1 && styles.active}`}
        onClick={handleLengthMenuOpen}
      >
        {SearchTagsStrings.Length}:{' '}
        {lengthFilterFromIdx > -1
          ? `${convertToMmSsStringFormat(minutesFrom, secondsFrom)} `
          : '00:00 '}
        - {lengthFilterToIdx > -1 ? `${convertToMmSsStringFormat(minutesTo, secondsTo)}` : '--'}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (lengthFilterToIdx !== -1) {
              e.stopPropagation();
              handleRemoveLengthFilter();
            }
          }}
        >
          {lengthFilterToIdx === -1 ? <SmallArrowIcon /> : <PurpleCloseIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={lengthAnchorEl}
        isOpen={isLengthMenuOpen}
        onClose={() => handleLengthMenuClose()}
        padding="4px 0 0 0"
      >
        <Box className={styles.timeOption} onClick={() => handlePredefinedFilterChange(30)}>
          {SearchTagsStrings.LessThan30s}
        </Box>
        <Box className={styles.timeOption} onClick={() => handlePredefinedFilterChange(60)}>
          {SearchTagsStrings.LessThan60s}
        </Box>
        <Typography className={styles.customRangeTitle}>{SearchTagsStrings.CustomRange}</Typography>
        <Box className={styles.timeWrapper}>
          <TimePicker
            onChange={(range) => {
              setLengthRange(range);
            }}
            lengthRange={lengthRange}
            minutesInitialVal={minutesFrom}
            secondsInitialVal={secondsFrom}
            operation={SearchOperationType.GreaterThan}
          />
          <Typography fontSize="12px" color="#000">
            {SearchTagsStrings.To}
          </Typography>
          <TimePicker
            onChange={(range) => {
              setLengthRange(range);
            }}
            lengthRange={lengthRange}
            minutesInitialVal={minutesTo}
            secondsInitialVal={secondsTo}
            operation={SearchOperationType.LessThan}
          />
        </Box>
      </MenuContainer>
    </>
  );
};

export default LengthTag;
