import { httpClient } from '../../../services/httpClient/httpClient';
import { MediaEndpoints, getApiUrlForId } from '../../../api/endpoints';
import { MediaFilePrefix } from '../../../api/models/common';
import axios from 'axios';

export interface UploadUrlApiModel {
  url: string;
  method: string;
  headers: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  downloadUrl: string;
}

interface UploadLogoAsFileRequest {
  options: UploadUrlApiModel;
  data: File;
}

export const uploadFileHandler = (logoFile: File) => {
  return httpClient.uploadFile({
    url: MediaEndpoints.UploadFile,
    requiresToken: true,
    payload: {
      prefix: MediaFilePrefix.Client,
      mediaFile: {
        file: logoFile,
      },
    },
  });
};

export const createUploadUrl = async (id: string, mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: getApiUrlForId(`${MediaEndpoints.CreateUploadSignedUrl}?ext=${mimeType}`, id),
      requiresToken: true,
    });
  } catch (error) {
    return console.log(error);
  }
};

export const uploadLogoAsFile = async ({ options, data }: UploadLogoAsFileRequest) => {
  if (!data) {
    return;
  }

  try {
    const res = await axios.put<typeof options, undefined>(options.url, data, {
      headers: options.headers,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
