import Checkbox from '@mui/material/Checkbox';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '173px',
    height: '100px',
    borderRadius: '12px',
    border: '1px solid #E1E5EE',
    position: 'relative',
    padding: '15px 15px 0 15px',
  },
  span: {
    background: theme.palette.primary.light,
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spanWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontSize: '14px',
    color: theme.palette.text.secondary,
    marginTop: '10px',
    textAlign: 'center',
    width: '100%',
    minWidth: '120px',
  },
}));
interface StatItemProps {
  item: { id: string; title: string; icon: JSX.Element };
  selected: boolean;
  handleChange: (id: string) => void;
}
export const StatItem: FC<StatItemProps> = ({ item, selected, handleChange }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-start" justifyContent="center" flexDirection="row">
        <Grid item xs={10}>
          <div className={classes.spanWrapper}>
            <div className={classes.span}>{item.icon}</div>
            <p className={classes.title}>{item.title}</p>
          </div>
        </Grid>
        <Grid item xs={2}>
          <Checkbox
            color="primary"
            checked={selected}
            onChange={() => handleChange(item.id)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
