import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  campaignNameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  campaignName: {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '28px',
    color: '#101828',
  },
  editButton: {
    width: '22px',
    height: '22px',
    marginLeft: '10px',
    padding: 0,
    backgroundColor: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.6',
    },
  },
  nameEditInput: {
    fontSize: '18px',
    fontWeight: 500,
    color: '#000000',
    padding: '3px 14px',
    background: '#FAFAFA',
    border: '1px solid #DDD',
    borderRadius: '8px',
    width: '353px',
    margin: '0',
  },
  campaignInfo: {
    color: '#4A5466',
    marginTop: '14px',
  },
  progressWrapper: {
    marginTop: '14px',
    marginLeft: '10px',
  },
  tagsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    marginTop: '8px',
  },
  tagWrapper: {
    padding: '2px 10px',
    borderRadius: '6px',
    fontSize: '12px',
    fontWeight: 600,
  },
  activeTag: {
    backgroundColor: '#ECFDF3',
    color: '#027A48',
  },
  inactiveTag: {
    color: theme.palette.common.gray,
    backgroundColor: 'rgb(242, 244, 247)',
  },
  primaryTag: {
    backgroundColor: '#FFFAEB',
    color: '#E0991F',
    height: '23px',
  },
  primaryCampaignWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
  },
  peekIcon: {
    marginRight: '7px',
    height: '24px',
  },
}));
