import { FC } from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RewardsPageStrings } from '../../../../../common/localization/en';
import { downloadUrl } from '../../../../../services/utilities';
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles((theme: Theme) => ({
  styledButton: {
    width: '128px',
    height: '28px',
    color: '#5D5FEF',
    borderRadius: '30px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    background: `${theme.palette.primary.main}1a`,
  },
}));

interface DownloadButtonProps {
  id: string;
  name: string;
}
export const DownloadCampaignsButton: FC<DownloadButtonProps> = ({ id, name }) => {
  const styles = useStyles();
  const downloadTemplateUrl = `${process.env.REACT_APP_NEW_API_URL}/api/v1/incentive-campaigns/${id}/report`;
  const { addToast } = useToasts();

  const onDownloadHandler = () => {
    downloadUrl(downloadTemplateUrl, name);
    addToast(RewardsPageStrings.downloadSummaryMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  return (
    <Button className={styles.styledButton} onClick={onDownloadHandler}>
      {RewardsPageStrings.DownloadCampaignButton}
    </Button>
  );
};
