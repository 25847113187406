import styled from 'styled-components';
import { theme } from '../../theme';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImg from '../../common/assets/SignUpFormBackground.svg';
import { isUWMClient } from '../../utils/isUWMClient';

export const LoginContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // position: relative;
  width: 100%;
  // height: 100%;
  // background-color: var(--black);
`;

export const LoginHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  color: ${theme.palette.primary.dark};
`;

export const LoginSubHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--lightBlue);
`;
export const ForgotPasswordSubHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  color: var(--thinBlue);
`;

export const LoginForm = styled.form`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // @media (min-width: 1200px) {
  //   width: 700px;
  //   height: 450px;
  //   flexwrap: 'wrap';
  // }
  // @media (max-width: 768px) and (orientation: portrait) {
  //   width: 272px;
  //   height: 550px;
  // }
  // @media (max-width: 768px) and (orientation: landscape) {
  //   width: 412px;
  //   height: 450px;
  }
  // width: 272px;
  // height: 412px;
  // padding: 14px;
  // border-radius: 14px;
  box-sizing: border-box;
  // background-color: var(--white);
`;
export const CardMediaStyled = styled.img`
  height: 80%;
  width: 100%;
`;
export const StyledLogo = styled.img`
  max-width: 400px;
  margin-left: 50px;
`;
export const LogoWrapper = styled.div`
  @media (min-width: 768px) {
    left: 40px;
    top: 40px;
  }
  @media (max-width: 768px) and (orientation: portrait) {
    left: 20px;
    top: 20px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    left: 40px;
    top: 40px;
  }
  @media (max-height: 670px) and (orientation: portrait) {
    left: 0px;
    top: 20px;
  }
  position: absolute;
  max-width: 304px;
  max-height: 36.92px;
  display: flex;
  left: 40px;
  top: 40px;
`;

export const useLoginStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: isUWMClient() ? theme.palette.primary.main : 'white',
    height: `${(1 / 0.9) * 100}vh`,
    overflowY: 'auto',
    backgroundPosition: ' 0px -300px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  signUp: {
    height: `${(1 / 0.9) * 100}vh`,
    position: 'absolute',
    overflowY: 'auto',
    backgroundSize: 'cover',
    backgroundImage: isUWMClient() ? undefined : `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  formWrapper: {
    width: '795px',
    minWidth: '360px',

    boxShadow: '0px 18px 30px rgba(64, 77, 104, 0.1)',
    backdropFilter: 'blur(10px)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  Title: {
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '38px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    textAlign: 'center',
  },
  SubTitle: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#475467',
    textAlign: 'center',
  },
  Caption: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    marginBottom: '8px',
    marginTop: '0px',
    color: '#1A1538',
  },
  Button: {
    borderRadius: '100px',
    padding: '10px 40px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  SubmitBtn: {
    width: '100%',
    '& >button:disabled': {
      backgroundColor: '#DCD9FB !important',
      color: '#B3ACF6 !important',
      '& svg': {
        color: '#B3ACF6 !important',
      },
    },
  },
  Logo: {
    margin: '0 auto',
    marginBottom: '30px',
    flexShrink: 0,
    flexGrow: 0,
    '& svg': {
      flexShrink: 0,
      flexGrow: 0,
    },
  },
  helperText: {
    '& p': {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  pt0: {
    paddingTop: '0 !important',
  },
}));
