import { FC, ReactNode } from 'react';
import { Grid, Typography, Dialog, DialogContent, Stack, Box } from '@mui/material';
import { SocialVerseStrings } from '../../../common/localization/en';
import { useSVModalSmallStyles } from './SVModalSmall.style';
import { SVButton } from '../CommonComponents/CommonComponents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';

interface IModalProps {
  openModal: boolean;
  toggleModal: (open: boolean) => void;
  onClickHandler: () => void;
  nameButton: string;
  children?: ReactNode;
  handleCancel?: () => void;
}

export const SVModalDelete: FC<IModalProps> = ({
  openModal,
  toggleModal,
  onClickHandler,
  handleCancel,
  nameButton,
  children,
}) => {
  const classes = useSVModalSmallStyles();
  const { trackEvent } = useTrackEvent();

  const handleCloseModal = () => {
    toggleModal(!openModal);
  };
  const handleDeteteVerse = () => {
    onClickHandler();
    handleCloseModal();
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleCloseModal}
      open={openModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <Grid container>
          <Grid item>
            <Grid container flexDirection="row" className={classes.wrapperMainContent}>
              <Grid container flexDirection="column">
                <Grid item className={classes.body}>
                  {children && <Box className={classes.iconWrapper}>{children}</Box>}

                  <Typography className={classes.modalTitle}>
                    {SocialVerseStrings.DeleteModalTitle}
                  </Typography>
                  <Typography className={classes.modalText}>
                    {SocialVerseStrings.DeleteModalText}
                  </Typography>
                </Grid>
                <Stack direction="row" spacing={2} justifyContent="center" width="100%">
                  <SVButton
                    variant="outlined"
                    sx={{ padding: '10px 30px' }}
                    handleClick={handleDeteteVerse}
                    id={`confirm-delete-socialverse-button`}
                  >
                    <Typography variant="body2">{nameButton}</Typography>
                  </SVButton>
                  <SVButton
                    variant="contained"
                    sx={{ padding: '10px 30px' }}
                    handleClick={() => {
                      (handleCancel || handleCloseModal)();
                      trackEvent(SocialVerseEventNames.cancelDelete);
                    }}
                    id={`cancel-delete-socialverse-button`}
                  >
                    <Typography variant="body2">{SocialVerseStrings.Cancel}</Typography>
                  </SVButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
