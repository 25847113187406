import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    background: '#fff',
    padding: '10px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)',
  },
  tabsContainer: {
    marginBottom: '6px',
    minHeight: '42px',
    height: '42px',
    borderBottom: '1px solid #EAECF0',
  },
  tabItem: {
    marginBottom: '11px',
    padding: '12px 25px',
  },
  tabTitle: {
    textTransform: 'none',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '20px',
  },
  contentWrapper: {
    width: '100%',
  },
}));
