import { FC, useEffect } from 'react';
import { Grid, Button } from '@mui/material';
import {
  Container,
  InstructionWrapper,
  WizardInstructionsTitle,
  InstructionsFrameContainer,
  InstructionsImage,
} from './Venuewizard.style';
import { VenueCreationWizardStrings } from '../../common/localization/en';
import { useTypedSelector, useAppDispatch } from '../../store';
import { generateFilters } from '../../store/slices/venueCreationSlice';

interface WizardInstructionsPageProps {
  setShowInstructions: (show: boolean) => void;
}

export const WizardIntructionsPage: FC<WizardInstructionsPageProps> = ({ setShowInstructions }) => {
  const dispatch = useAppDispatch();
  const { programName, campaignLogoUrl, campaignPrimaryColorHex, campaignSecondaryColorHex } =
    useTypedSelector((state) => state.venueCreation.program_payload);

  useEffect(() => {
    if (campaignLogoUrl && campaignPrimaryColorHex && campaignSecondaryColorHex) {
      dispatch(
        generateFilters({
          logoUrl: campaignLogoUrl,
          primaryColor: campaignPrimaryColorHex,
          secondaryColor: campaignSecondaryColorHex,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLetsGo = () => {
    setShowInstructions(false);
  };

  return (
    <Container>
      <InstructionWrapper>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <InstructionsFrameContainer>
              <InstructionsImage src="/wizardInstructions.png" />
            </InstructionsFrameContainer>
          </Grid>
          <Grid item xs={12} md={8}>
            <WizardInstructionsTitle>
              {VenueCreationWizardStrings.InstructionsTitle}
            </WizardInstructionsTitle>
          </Grid>
          <Grid item xs={12} md={8}>
            <WizardInstructionsTitle>
              {VenueCreationWizardStrings.InstructionsSubtitle.replace(
                ':program_name',
                programName,
              )}
            </WizardInstructionsTitle>
          </Grid>

          <Grid item xs={12} md={8}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={3}>
                <Button variant="contained" color="primary" onClick={handleLetsGo}>
                  {VenueCreationWizardStrings.LetsGo}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </InstructionWrapper>
    </Container>
  );
};
