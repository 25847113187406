import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useMemo, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { DNDIcon } from '../../assets/newDesign/DNDIcon';
import { DataSyncStrings } from '../../localization/en';

export type DropzoneSupportedFileTypes = 'csv' | 'excel';

const acceptOptions: Record<DropzoneSupportedFileTypes, Accept> = {
  csv: {
    'text/csv': ['.csv'],
  },
  excel: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsm'],
    'application/x-msi': ['.xls'],
  },
};

export interface DropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  title?: string;
  description?: string;
  accept?: DropzoneSupportedFileTypes[];
  padding?: string;
  height?: string;
  borderRadius?: string;
  invalidFileMessage?: string;
  isError?: boolean;
  errorComponent?: JSX.Element;
}

export const Dropzone: React.FC<DropzoneProps> = ({
  onDrop,
  title = DataSyncStrings.DNDInputTitle,
  description = DataSyncStrings.DNDInputSubTitle,
  accept,
  padding,
  height,
  borderRadius,
  isError,
  invalidFileMessage,

  errorComponent,
}) => {
  const theme = useTheme();

  const [isDropped, setIsDropped] = useState(false);
  const [successDrop, setSuccessDrop] = useState(false);
  const [latestUploadedFile, setLatestUploadedFile] = useState<File | undefined>();

  const acceptSettings = useMemo<Accept>(() => {
    return (accept || []).reduce((resultAccept: Accept, fileType) => {
      return { ...resultAccept, ...acceptOptions[fileType] };
    }, {});
  }, [accept]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop(acceptedFiles) {
      setIsDropped(true);
      setSuccessDrop(!!acceptedFiles.length);
      onDrop(acceptedFiles);
      setLatestUploadedFile(acceptedFiles[0]);
    },
    accept: acceptSettings,
  });

  const backgroundColor = useMemo(() => {
    if (isDragActive) {
      return theme.palette.primary.light;
    }

    if ((isDropped && !successDrop) || isError) {
      return '#F044380D';
    }

    return theme.palette.common.white;
  }, [
    isDragActive,
    isDropped,
    isError,
    successDrop,
    theme.palette.common.white,
    theme.palette.primary.light,
  ]);
  const border = '1px solid #D6D9DE';

  return (
    <div
      style={{ display: 'block' }}
      {...getRootProps({ className: 'dropzone' })}
      id="file-dropzone"
    >
      <input type="file" className="input-zone" {...getInputProps()} />
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
          padding: padding || '35px 38px',
          borderRadius: borderRadius || '5px',
          background: backgroundColor,
          cursor: isDragActive ? 'url(/fiber-smart-record.svg) !important' : 'pointer',
          border: border,
          borderColor: (isDropped && !successDrop) || isError ? '#F04438' : undefined,
          height: height || 'auto',
        }}
      >
        {!isDropped ? (
          <>
            <Box mr="20px">
              <DNDIcon color={isDragActive ? '#80A4DC' : '#D9D9D9'} />
            </Box>
            <Box>
              <Typography fontWeight="600" fontSize="17px" lineHeight="26px">
                {title}
              </Typography>
              <Typography
                color={theme.palette.primary.main}
                fontWeight="400"
                fontSize="12px"
                lineHeight="26px"
              >
                {description}
              </Typography>
            </Box>
          </>
        ) : isError ? (
          <>{errorComponent}</>
        ) : successDrop ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" alignItems="center">
              <CheckCircleIcon htmlColor="#058901" sx={{ mr: '6px' }} />
              <Typography fontSize="15px" fontWeight="600" color="#1A1538" lineHeight="26px">
                Your File Was Received!
              </Typography>
            </Box>
            <Typography fontSize="11px" color="#00000080">
              {latestUploadedFile?.name}
            </Typography>
          </Box>
        ) : (
          <Typography
            fontWeight="600"
            fontSize="15px"
            lineHeight="26px"
            width="100%"
            textAlign="center"
          >
            {invalidFileMessage}
          </Typography>
        )}
      </Grid>
    </div>
  );
};
