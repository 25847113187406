import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: 'inherit',
  },
  selected: {
    color: `${theme.palette.secondary.main} !important`,
  },
  text: {
    color: theme.palette.common.Text.secondary,
    fontSize: 12,
    lineHeight: 1.455,
  },
  textParent: {
    color: 'inherit',
    fontSize: 12,
    lineHeight: 1.455,
  },
  primary: {
    color: 'inherit',
  },
  textActive: {
    color: theme.palette.secondary.main,
  },
  checkmark: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: '#24B947',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '8px',
  },

  circle: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '1px solid #8B89A0',
    marginRight: '8px',
  },

  listItem: {
    paddingTop: '8px',
    paddingBottom: '8px',
    '@media (max-height: 900px)': {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
}));
