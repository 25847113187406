export interface ColoredTextValues {
  text: string;
  color: string;
}

export function getColoredText(inputValues: ColoredTextValues[]) {
  return (
    <>
      {inputValues.map((val) => (
        <span
          style={{
            color: val.color,
          }}
          key={val.text}
        >
          {val.text}
        </span>
      ))}
    </>
  );
}
