import { FC } from 'react';
interface IFacebookIconDv5Props {
  color?: string;
}
export const FacebookIconDv5: FC<IFacebookIconDv5Props> = ({ color }) => {
  return (
    <svg width="11" height="20" viewBox="0 0 11 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1H7.54545C6.46048 1 5.41994 1.47411 4.65274 2.31802C3.88555 3.16193 3.45455 4.30653 3.45455 5.5V8.2H1V11.8H3.45455V19H6.72727V11.8H9.18182L10 8.2H6.72727V5.5C6.72727 5.26131 6.81347 5.03239 6.96691 4.8636C7.12035 4.69482 7.32846 4.6 7.54545 4.6H10V1Z"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
