import React, { FC, useState } from 'react';
import { useStyles } from './CountPicker.styles';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { CountRange } from '../ClipsCountSelect';
import { SearchOperationType } from '../../../../api/models/searchFilterModels';
import { ExpandIcon } from '../../../assets/ExpandIcon';

interface Props {
  onChange: (range: CountRange[]) => void;
  initialVal: number;
  operation: SearchOperationType.LessThan | SearchOperationType.GreaterThan;
  countRange: CountRange[];
}

export const CountPicker: FC<Props> = ({ onChange, initialVal, countRange, operation }) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialVal);
  const [isOpen, setIsOpen] = useState(false);

  const getRenderArray = () => {
    const rangeStep = 1;
    const maxValue = 15;

    const arraySize = maxValue / rangeStep;
    return [...Array(arraySize + 1).keys()].map((_, index) => {
      const value = index * rangeStep;
      return {
        value,
        selected: value === initialVal,
      };
    });
  };

  const [renderArray, setRenderArray] = useState(getRenderArray());

  const handleChange = (value: number, index: number) => {
    setRenderArray((prev) => {
      const result = prev.map(({ value }) => {
        return {
          value,
          selected: false,
        };
      });

      setValue(value);

      const currRangeIndex = countRange.findIndex((item) => item.operation === operation);
      const resultValue = operation === SearchOperationType.LessThan ? value + 1 : value - 1;

      if (currRangeIndex > -1) {
        const result = [...countRange];
        result[currRangeIndex].value = resultValue;
        onChange(result);
      } else {
        onChange([
          ...countRange,
          {
            value: resultValue,
            operation,
          },
        ]);
      }

      return result;
    });
  };

  return (
    <Box>
      <Select
        multiline={true}
        displayEmpty={true}
        open={isOpen}
        onMouseUp={() => {
          setIsOpen((prev) => !prev);
        }}
        renderValue={() => {
          return <Typography className={classes.text}>{value > -1 ? value : '-'}</Typography>;
        }}
        className={classes.select}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
              maxHeight: '300px',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#fff',
                height: '20px !important',
                maxHeight: '20px !important',
                borderRadius: '12px',
                margin: '5px 0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#EAECF0',
                borderRadius: '5px',
                height: '40px !important',
                cursor: 'pointer',
              },
            },
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        <MenuItem className={classes.menuItem}>
          <Box className={classes.sectionWrapper}>
            {renderArray.map(({ value, selected }, index) => {
              return (
                <Button
                  onClick={() => {
                    handleChange(value, index);
                  }}
                  className={classes.menuButton}
                  sx={{
                    backgroundColor: selected ? '#F9FAFB' : '#FFF',
                  }}
                >
                  {value}
                </Button>
              );
            })}
          </Box>
        </MenuItem>
      </Select>
    </Box>
  );
};
