import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTypedSelector } from '../store';

export const useSegmentPageCall = () => {
  const { pathname } = useLocation();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { id: userId } = useTypedSelector((state) => state.me);
  useEffect(() => {
    (window as any).analytics.page(pathname, { venueId, pathname, userId });
  }, [venueId, pathname, userId]);
};
