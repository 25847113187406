import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './RewardMessageTab.styles';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { ContactMethod } from '../../../../../NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { useSmsTemplateVariables } from '../../../../../../services/hooks/useSmsTemplateVariables';
import { RewardFulfillmentMethod } from '../../../../../../api/models/rewardFulfillment';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { SendTestMessageModal } from '../../../../../../common/components/SMS/SendTestMessageModal';
import { useModal } from '../../../../../../hooks/useModal';
import { useSendTestSms } from '../../../../../../services/hooks/useSendTestSms';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { CustomizeRewardMessageModal } from './CustomizeRewardMessageModal/CustomizeRewardMessageModal';
import { getRewardCardUrl } from '../../../../../../store/slices/campaignsSlice';
import { format } from 'date-fns';
import { RewardCard } from '../../../../../../api/models/campaigns';
import { CustomizationDisplay } from './CustomizationDisplay/CustomizationDisplay';
import { RewardMessagePreview } from './RewardMessagePreview/RewardMessagePreview';

export const RewardMessageTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { phoneNumber, name } = useTypedSelector((store) => store.me);
  const {
    venue: { logo, name: companyName },
  } = useTypedSelector((state) => state.venue);
  const me = useTypedSelector((state) => state.me);

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const {
    isOpen: isCustomizeModalOpen,
    open: openCustomizeModal,
    close: closeCustomizeModal,
  } = useModal();

  const sendTestSMS = useSendTestSms();

  const contactMethodsMap: Record<ContactMethod, string> = {
    PHONE: ElementsStrings.PhoneNumber,
    EMAIL: ElementsStrings.Email,
    WEBSITE: ElementsStrings.URL,
    NONE: ElementsStrings.None,
  };

  const deliveryMethodsMap: Partial<Record<RewardFulfillmentMethod, string>> = {
    MANUAL_SMS: ElementsStrings.Manual,
    AUTOMATIC_SMS: ElementsStrings.Automatic,
  };

  const rewardMessage = replaceMessageBodyWithLocalVariables(
    currentCampaign?.fulfillmentConfig?.sms?.body || '',
    isHealthCare,
    true,
  );

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: currentCampaign?.rewardTitle || '',
  });

  const rewardMessageConstructed = constructMessageFromTemplate(
    rewardMessage,
    templateVariables,
    true,
  );

  if (!currentCampaign) {
    return null;
  }

  const {
    isNullReward,
    cardStyle,
    contact,
    rewardTitle,
    rewardHeader,
    isMultiFulfillment,
  } = currentCampaign;

  return (
    <Box className={classes.container}>
      <CustomizeRewardMessageModal
        isOpen={isCustomizeModalOpen}
        onClose={closeCustomizeModal}
        onSendSampleMessageClick={openTestMessageModal}
      />
      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          closeTestMessageModal();
        }}
        testMessage={rewardMessageConstructed}
        onTestMessageSendClick={async (data) => {
          const imageCardPayload = await dispatch(
            getRewardCardUrl({
              companyName,
              contact,
              logoUrl: logo,
              userName: me.name,
              rewardHeader,
              rewardTitle: rewardTitle || '',
              cardStyle,
              date: format(new Date(), 'P'),
            }),
          );

          const imageUrl = (imageCardPayload.payload as RewardCard).imageUrl;
          const sendData = imageUrl && !isNullReward ? { ...data, mediaUrl: imageUrl } : data;
          sendTestSMS(sendData);
        }}
        phoneNumber={phoneNumber}
        name={name}
      />
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>
          {isNullReward ? ElementsStrings.ThankYouTextMessage : ElementsStrings.RewardTextMessage}
        </Typography>
        <Box className={classes.leftSideContent}>
          <Box className={classes.subtitleContainer}>
            <Typography className={classes.subtitle}>
              {(isHealthCare
                ? ElementsStrings.ReviewRewardTrublu
                : ElementsStrings.ReviewReward
              ).replace(isNullReward ? 'Reward' : '', '')}
            </Typography>
          </Box>

          <Box className={classes.customizationContainer}>
            <Box className={classes.customizationContent}>
              {!isNullReward && (
                <>
                  <CustomizationDisplay
                    title={ElementsStrings.Header}
                    value={rewardHeader}
                    tooltip={ElementsStrings.HeaderTooltip}
                  />

                  <CustomizationDisplay
                    title={ElementsStrings.Contact}
                    value={contactMethodsMap[currentCampaign?.contactMethod as ContactMethod]}
                    tooltip={ElementsStrings.ContactTooltip}
                  />
                </>
              )}
              <CustomizationDisplay
                title={ElementsStrings.Delivery}
                value={
                  deliveryMethodsMap[
                    currentCampaign?.fulfillmentConfig?.method as RewardFulfillmentMethod
                  ] || ''
                }
                tooltip={ElementsStrings.DeliveryTooltip.replace(
                  isNullReward ? ElementsStrings.Reward : '',
                  '',
                )}
              />

              <CustomizationDisplay
                title={ElementsStrings.Frequency}
                value={isMultiFulfillment ? ElementsStrings.EveryClip : ElementsStrings.FirstClip}
                tooltip={ElementsStrings.FrequencyTooltip.replace(
                  isNullReward ? ElementsStrings.Reward : '',
                  '',
                )}
              />
            </Box>

            {!currentCampaign?.endedAt && (
              <Box className={classes.buttonsWrapper}>
                <RoundedButton
                  className={classes.customizeButton}
                  title={ElementsStrings.Customize}
                  onClick={() => {
                    trackEvent(RewardCampaignsEvents.OpenCustomizeRewardModal, {
                      campaignId,
                    });
                    openCustomizeModal();
                  }}
                />

                <RoundedButton
                  className={classes.sendMessageButton}
                  title={ElementsStrings.SendSampleMessage}
                  outlined={true}
                  onClick={() => {
                    openTestMessageModal();
                    trackEvent(RewardCampaignsEvents.OpenRewardTestMessageModal, {
                      campaignId,
                    });
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {/* <Typography className={classes.settingsText}>{ElementsStrings.Settings}</Typography> */}

      <Box className={classes.rightSideWrapper}>
        <RewardMessagePreview title="What it looks like:" />
      </Box>
    </Box>
  );
};
