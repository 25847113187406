import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& thead': {
      '& tr th:first-child > div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& tr th:last-child > div': {
        justifyContent: 'center',
      },
    },
    '& tr:first-child td': {
      paddingLeft: '0',
    },
  },
  detailModalTitle: {
    marginBottom: '24px',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.02em',
  },
  btn: {
    padding: '20px 0',
    '& button': {
      minWidth: '172px',
    },
  },
}));
