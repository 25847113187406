export interface GetTransactionAPIModel {
  id: string;
  venueId: string;
  name: string;
  numRecords: number;
  numValidRecords: number;
  status: string;
  createdAt: string;
  approvedAt: string;
  campaignId: string;
}

export interface GetTransactionBatchesRequest {
  venueId: string;
  page?: number;
  size?: number;
  sort?: string;
}

export interface TransactionRecordRequest {
  page?: number;
  size?: number;
  sort?: string;
  search?: string;
  status?: string;
  hasVideos?: boolean;
}

interface TransactionRecordsAttributes {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}

export enum TransactionRecordStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export interface TransactionRecord {
  id?: string;
  phoneNumber: string;
  firstName: string;
  fullName?: string;
  lastName: string;
  status?: TransactionRecordStatus;
  transactionDate: string;
  derivedTransactionDate?: string;
  email: string | undefined;
  refId: string;
  isValidRecord?: string;
  isValid?: boolean;
  optedIn: boolean;
  attributes: TransactionRecordsAttributes;
  isManuallyAdded?: boolean;
  venueId?: string;
  pushedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  batchId?: string;
  hasVideos?: boolean;
  name?: string;
}

export interface CampaignTransactionRecordResponse {
  items: TransactionRecord[];
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface CampaignInvitationBatchesResponse {
  items: GetTransactionAPIModel[];
  totalPages: number;
  totalItems: number;
  page: number;
  size: number;
}

export interface TransactionCommunicationHistory {
  id: string;
  date: string;
  numOfSent: number;
  numOfDelivered: number;
  numOfUndelivered: number;
  optOuts: number;
}
export interface TransactionCommunicationDetails {
  id: string;
  date: string;
  name: string;
  cell: string;
  type: string;
  optOut: boolean;
}
export type ManualRecord = Omit<TransactionRecord, 'isValidRecord'>;

export type Records = Omit<TransactionRecord, 'isValidRecord' | 'optedIn'>[];
export interface PostTransactionOptions {
  venueId: string;
  name: string | null;
  campaignId?: string;
  records: Records;
}

export interface CreateTransactionBatchResponse extends GetTransactionAPIModel {
  records: TransactionRecord[];
}

export interface UpdateTransactionOptions {
  id: GetTransactionAPIModel['id'];
  name: string;
}

export type UpdateTransactionBatchResponse = CreateTransactionBatchResponse;
