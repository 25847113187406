import { FC } from 'react';
import { Box, Button } from '@mui/material';
import { useBottomToolbarStyles } from './BottomToolbar.style';
import { DownloadIconDv5 } from './DownloadIconDv5';
import { LinkIconDv5 } from './LinkIconDv5';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { DeleteBasketIcon } from '../../assets/DeleteBasketIcon';
import { Tooltip } from '../Tooltip/Tooltip';

interface IBottomTollbarProps {
  handleOpenDownloadVideoDialog: () => void;
  storyPageUrl: string;
  handleDeleteModalOpen: () => void;
  isTopGolf: boolean;
}

export const BottomToolbar: FC<IBottomTollbarProps> = ({
  handleOpenDownloadVideoDialog,
  storyPageUrl,
  handleDeleteModalOpen,
  isTopGolf,
}) => {
  const classes = useBottomToolbarStyles();
  const { trackEvent } = useTrackEvent();

  return (
    <Box className={classes.ButtonsContainer}>
      <Box display="flex">
        <Tooltip
          title="Download"
          icon={
            <Button
              onClick={() => {
                handleOpenDownloadVideoDialog();
                trackEvent(SocialVerseEventNames.downloadVideo, { value: storyPageUrl });
              }}
              className={[classes.Buttons].join(' ')}
            >
              <DownloadIconDv5 color={'#344054'} />
            </Button>
          }
        />

        <Box mr={2} />
        <Tooltip
          title="Copy Link"
          icon={
            <Button
              className={[classes.Buttons].join(' ')}
              onClick={() => {
                trackEvent(SocialVerseEventNames.goToVideoLink, { value: storyPageUrl });
              }}
            >
              <a href={storyPageUrl} target="_blank" rel="noreferrer" style={{ paddingTop: '4px' }}>
                {<LinkIconDv5 color={'#344054'} />}
              </a>
            </Button>
          }
        />
      </Box>
      {!isTopGolf ? (
        <Tooltip
          title="Delete Video"
          icon={
            <Button
              variant="text"
              onClick={() => {
                handleDeleteModalOpen();
                trackEvent(SocialVerseEventNames.deleteUserVideo);
              }}
              className={[classes.DeleteButton].join(' ')}
              style={{ marginRight: '145px' }}
            >
              <DeleteBasketIcon color="#344054" />
            </Button>
          }
        />
      ) : (
        <div></div>
      )}
    </Box>
  );
};
