import { Grid } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { SettingsSidebar } from './SettingsSidebar';
import { SettingsPreview } from './SettingsPreview';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { EditSocialVerseOptions, putEditSocialVerse } from '../../../store/slices/socialVerseSlice';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { useToasts } from 'react-toast-notifications';
import { CustomizationSocialVerse } from '../../../common/localization/en';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { DEFAULT_SV_GLOW_COLOR } from '../../../common/constants/defaults';
import { getValidationSchema } from './SocialVerseSettingsPage.helpers';

export interface FormikValues {
  title: string;
  showTitle: boolean;
  showUserInfo: boolean;
  glowColor: string;
  withGlow: boolean;
  captionEnabled: boolean;
  supportingText: string;
  showSupportingText: boolean;
  numberOfPerspectiveCards: number;
  numberOfGridRows: number;
  withHoverPreview: boolean;
  showVideoCounter: boolean;
  gbpSyncEnabled: boolean;
}

export const SocialVerseSettingsPage: FC = () => {
  const { socialVerse } = useTypedSelector((state) => state.SocialVerseSlice);
  const viewMode = useMemo(() => socialVerse?.viewMode, [socialVerse]);
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const [colorError, setColorError] = useState(false);

  const glowColor = useMemo(
    () => (socialVerse?.details?.glowColor ? socialVerse.details.glowColor : DEFAULT_SV_GLOW_COLOR),
    [socialVerse],
  );
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const onSubmit = useCallback(
    (values: FormikValues) => {
      if (socialVerse) {
        const updateSocialvData: EditSocialVerseOptions = {
          id: socialVerse.id,
          data: {
            name: socialVerse.name,
            viewMode: socialVerse.viewMode,
            title: values.title,
            showTitle: values.showTitle,
            showUserInfo: values.showUserInfo,
            details: {
              withGlow: values.withGlow,
              glowColor: values.withGlow ? values.glowColor : venueBrandColor,
              captionEnabled: values.captionEnabled,
              showSupportingText: values.showSupportingText,
              supportingText: values.supportingText,
              numberOfPerspectiveCards: values.numberOfPerspectiveCards,
              numberOfGridRows: values.numberOfGridRows,
              withHoverPreview: values.withHoverPreview,
              showVideoCounter: values.showVideoCounter,
            },
            keywordFilterEnabled: socialVerse.keywordFilterEnabled,
            gbpSyncEnabled: values.gbpSyncEnabled,
          },
        };
        dispatch(putEditSocialVerse(updateSocialvData));
        addToast(CustomizationSocialVerse.SaveSVChangesMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      }
    },
    [dispatch, socialVerse, addToast, venueBrandColor],
  );

  const withDefaultVal = (val: boolean | null | undefined) => {
    return typeof val === 'boolean' ? val : true;
  };

  const formik = useFormik<FormikValues>({
    initialValues: {
      showTitle: withDefaultVal(socialVerse?.showTitle),
      title: socialVerse?.title || '',
      showUserInfo: withDefaultVal(socialVerse?.showUserInfo),
      withGlow: withDefaultVal(socialVerse && socialVerse.details?.withGlow),
      captionEnabled: withDefaultVal(socialVerse && socialVerse.details?.captionEnabled),
      glowColor,
      supportingText: socialVerse?.details?.supportingText || '',
      showSupportingText: withDefaultVal(socialVerse?.details?.showSupportingText),
      numberOfPerspectiveCards: socialVerse?.details?.numberOfPerspectiveCards || 3,
      numberOfGridRows: socialVerse?.details?.numberOfGridRows || 1,
      withHoverPreview: withDefaultVal(socialVerse?.details?.withHoverPreview),
      showVideoCounter: withDefaultVal(socialVerse?.details?.showVideoCounter),
      gbpSyncEnabled: socialVerse?.gbpSyncEnabled ?? true,
    },
    enableReinitialize: true,
    onSubmit,
    validationSchema: viewMode && getValidationSchema(viewMode),
    validateOnChange: true,
  });

  const { dirty: isFormDirty, isValid, resetForm, submitForm } = formik;
  useEffect(() => {
    setColorError(colorError);
  }, [colorError]);

  const enableSaveBtn = () => {
    return isValid && !colorError;
  };
  return (
    <Grid style={{ display: 'flex' }}>
      <UnsavedChangesModal isUnsavedChangesOnPage={isFormDirty} />
      {viewMode && (
        <SettingsSidebar formik={formik} viewMode={viewMode} setColorError={setColorError} />
      )}
      {viewMode && <SettingsPreview formik={formik} viewMode={viewMode} />}
      <BottomActionBar
        disableSaveButton={!enableSaveBtn()}
        onCancelClick={() => {
          resetForm();
          trackEvent(SocialVerseEventNames.cancelChanges);
        }}
        onSaveClick={() => {
          submitForm();
          trackEvent(SocialVerseEventNames.saveChanges);
        }}
        show={isFormDirty}
        width="100%"
      />
    </Grid>
  );
};
