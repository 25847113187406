import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Button, IconButton } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useStyles } from './nav-alt.helper';
import { SidebarStrings } from '../../../localization/en';
import { SuperAdminRoutes, UnprotectedRoutes, WebsiteRoutes } from '../../../constants/routes';
import { useTypedSelector } from '../../../../store';
import { UserRoles } from '../../../constants/constants';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { AllAccountsEventNames } from '../../../constants/events/allAccountsEvents';
import { isUWMClient } from '../../../../utils/isUWMClient';

interface NavAltProps {
  isOpen: boolean;
}

export const NavAlt: FC<NavAltProps> = ({ isOpen }) => {
  const { role } = useTypedSelector((state) => state.me);
  const { distributors } = useTypedSelector((state) => state.distributors);
  const { affiliates } = useTypedSelector((state) => state.affiliates);
  const classes = useStyles();
  const { pathname } = useLocation();
  const { trackEvent } = useTrackEvent();

  const disabled = pathname === SuperAdminRoutes.AddNewVenue;
  const isSocialVenuAdmin =
    role === UserRoles.admin_role || !!distributors.length || !!affiliates.length;

  const showNewAccountButton = isSocialVenuAdmin && !isUWMClient();

  const getCreateAccountUrl = () => {
    if (role === UserRoles.admin_role || !!distributors.length) {
      return WebsiteRoutes.PricingPlans;
    } else if (!!affiliates.length) {
      return `${UnprotectedRoutes.SignUp}?planCode=${affiliates[0].code}`;
    } else return '/';
  };

  const renderCreateVenuButton = () => {
    return (
      <Box display="flex" justifyContent={isOpen ? 'flex-start' : 'center'}>
        {isOpen ? (
          <Button
            classes={{ root: classes.root, disabled: classes.disabled }}
            startIcon={<AddRoundedIcon />}
            fullWidth
            variant="contained"
            component={Link}
            to={getCreateAccountUrl()}
            disabled={disabled}
            onClick={() => trackEvent(AllAccountsEventNames.createNewAccountClicked)}
          >
            {SidebarStrings.AddAccount}
          </Button>
        ) : (
          <IconButton
            color="inherit"
            size="large"
            component={Link}
            to={SuperAdminRoutes.AddNewVenue}
            aria-label={SidebarStrings.NewOrganization}
          >
            <AddCircleOutlineIcon />
          </IconButton>
        )}
      </Box>
    );
  };
  return <div>{showNewAccountButton ? renderCreateVenuButton() : <></>}</div>;
};
