import { Theme } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { VenueManagerApiModel } from '../../../api/models/venueManagers';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { FC, useState } from 'react';
import formatDate from 'date-fns/format';
import { Box, FormControlLabel, Grid, RadioGroup, Typography } from '@mui/material';
import { formatPhoneNumber } from '../../../services/utilities';
import Radio from '@mui/material/Radio';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { updateVenueMemberRole } from '../../../store/slices/venueManagerSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';

interface MemberInformationProps {
  member: VenueManagerApiModel;
}
export enum MemberInformationStrings {
  title = 'Member Information',
  subtitle1 = 'Team Member Profile',
  subtitle2 = 'Team Member Status',
  LastActivity = 'Last Activity: ',
  TeamMemberStatus = 'Team Member Status',
  Account = 'Account ',
  assigneAccount = 'Assign Account:',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '17px',
    width: '500px',
  },
  label: {
    color: theme.palette.common.black,
    fontSize: '18px',
    fontWeight: 500,
    // width: '100%',
    textAlign: 'center',
    borderBottom: 'solid 1px grey',
    padding: '10px 20px',
    margin: '10px ',
  },
  title: {
    color: theme.palette.common.black,
    fontSize: '18px',
    fontWeight: 500,
    width: '100%',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '14px',
    letterSpacing: '0.02em',
    marginTop: 14,
    marginBottom: 14,
    wordBreak: 'break-all',
    color: '#1A1538',
    width: '100%',
    textTransform: 'capitalize',
  },
  role: {
    height: '50px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    border: '1px solid #DCDBE4',
    padding: '8px',
    width: '100%',
    fontFamily: 'Inter',
  },
  AssignAccount: {
    fontSize: '16px',
    lineHeight: '30px',
    color: '#475467',
    fontFamily: 'Inter',
  },
}));

export const MemberInformation: FC<MemberInformationProps> = ({ member }) => {
  const classes = useStyles();
  const { id } = useTypedSelector((state) => state.venue.venue);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const AccountTypes = {
    OWNER: 'Account Owner',
    MEMBER: 'Account Member',
  };
  const [value, setValue] = useState<'OWNER' | 'MEMBER'>(
    member.accountMappingRole as 'OWNER' | 'MEMBER',
  );
  const handleChange = (e: { target: { value: string } }) => {
    trackEvent(AccountEventNames.accountTypeChanged, {
      memberId: member.id,
    });
    setValue(e.target.value as 'OWNER' | 'MEMBER');
    dispatch(
      updateVenueMemberRole({
        memberId: member.id,
        accountId: id,
        role: e.target.value,
      }),
    );
  };
  return (
    <Grid container alignItems={'center'} justifyContent="center" style={{ width: '500px' }}>
      <Grid item xs={12} className={classes.root}>
        <p className={classes.label}>{MemberInformationStrings.title}</p>

        <p className={classes.title}>{MemberInformationStrings.subtitle1}</p>
        <Box
          sx={{
            fontSize: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon fontSize="inherit" color="primary" sx={{ mr: '10px' }} />
          <Typography className={classes.subtitle}>
            {member.firstName + ' ' + member.lastName}
          </Typography>
        </Box>
        {member?.phoneNumber && (
          <Box
            sx={{
              fontSize: '10px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <FiberManualRecordIcon color="primary" fontSize="inherit" sx={{ mr: '10px' }} />
            <Typography className={classes.subtitle}>
              {formatPhoneNumber(member.phoneNumber || null)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            fontSize: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon color="primary" fontSize="inherit" sx={{ mr: '10px' }} />
          <Typography className={classes.subtitle} sx={{ textTransform: 'lowercase !important' }}>
            {member.email}
          </Typography>
        </Box>
        <p className={classes.title}>{MemberInformationStrings.TeamMemberStatus}</p>
        {member.updatedAt && (
          <Box
            sx={{
              fontSize: '10px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <FiberManualRecordIcon color="primary" fontSize="inherit" sx={{ mr: '10px' }} />
            <Typography className={classes.subtitle}>
              {MemberInformationStrings.LastActivity +
                formatDate(new Date(member.updatedAt), 'MMM d, yyyy')}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            fontSize: '10px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <FiberManualRecordIcon color="primary" fontSize="inherit" sx={{ mr: '10px' }} />
          <Typography className={classes.subtitle}>
            {MemberInformationStrings.Account +
              (member.accountMappingRole?.toLocaleLowerCase() || '')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '10px' }}>
        <p className={classes.AssignAccount}>{MemberInformationStrings.assigneAccount} </p>
      </Grid>
      <Grid item xs={12} style={{ paddingLeft: '10px' }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={value}
          name="radio-buttons-group"
          onChange={handleChange}
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel
                value="MEMBER"
                control={<Radio />}
                label={AccountTypes.MEMBER}
                className={classes.role}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                value="OWNER"
                control={<Radio />}
                label={AccountTypes.OWNER}
                className={classes.role}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Grid>
    </Grid>
  );
};
