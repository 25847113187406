import React from 'react';
import { Box, Grid } from '@mui/material';

import { Distributors } from '../../common/components/Distributors/Distributors';
import { TopBar } from '../../common/components/topBar/TopBar';
import { DistributorsStrings } from '../../common/localization/en';

export const ConnectedAppsPage: React.FC = () => {
  return (
    <Grid container>
      <TopBar sectionTitle={DistributorsStrings.Google} />
      <Box
        sx={{
          mt: '66px',
          ml: '30px',
        }}
      >
        <Distributors />
      </Box>
    </Grid>
  );
};
