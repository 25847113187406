import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import { Box, Grid, Button } from '@mui/material';

import { TextFieldComponent } from '../../../../../common/components/TextField/TextField';
import {
  RewardsPageStrings,
  CommonStrings,
  ErrorPageStrings,
} from '../../../../../common/localization/en';
import { FSModal } from '../../../../../common/components/modal/Modal';
import { ModalTitle } from '../../../../../common/components/modal/Modal.style';
import {
  useStyles,
  InitialValues,
  initialValues,
  validationSchema,
  getError,
} from './CreateUserModal.helper';

interface CreateUserModalProps {
  isModal: boolean;
  error: boolean;
  onClose: () => void;
  onConfirm: (values: InitialValues) => Promise<boolean>;
}
export const CreateUserModal: FC<CreateUserModalProps> = ({
  isModal,
  onClose,
  onConfirm,
  error,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldTouched,
    isValid,
    dirty,
    touched,
    setFieldError,
    resetForm,
  } = useFormik({
    initialValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const response = await onConfirm(values);
      if (response) {
        onCloseHandler();
      }
    },
  });

  const onCloseHandler = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (error) {
      setFieldError('email', ErrorPageStrings.EmailIsTaken);
    }
  }, [error, setFieldError]);

  const getErrorHandler = (name: string) => {
    return getError(name, touched, errors);
  };

  return (
    <FSModal modalIsOpen={isModal} onClose={onClose}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12}>
              <ModalTitle>{RewardsPageStrings.AddNewUser}</ModalTitle>
            </Grid>
            <form onSubmit={handleSubmit}>
              <Grid item xs={12}>
                <Grid container spacing={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                    <TextFieldComponent
                      type="text"
                      name="name"
                      label={RewardsPageStrings.FullName}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      errorText={getErrorHandler('name')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      name="email"
                      label={RewardsPageStrings.Email}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      errorText={getErrorHandler('email')}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextFieldComponent
                      type="text"
                      name="phoneNumber"
                      label={RewardsPageStrings.CellNumber}
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      errorText={getErrorHandler('phoneNumber')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextFieldComponent
                      name="returnUrl"
                      label={RewardsPageStrings.UniqueURL}
                      value={values.returnUrl}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      errorText={getErrorHandler('returnUrl')}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Box mr={3}>
                  <Button color="primary" onClick={onCloseHandler}>
                    {CommonStrings.Cancel}
                  </Button>
                </Box>

                <Button
                  type="submit"
                  className={classes.confirmButton}
                  disabled={!(isValid && dirty)}
                  variant="contained"
                  color="primary"
                >
                  {RewardsPageStrings.CreateUserProfile}
                </Button>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
