import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { matchPropertiesLiteral } from '../../../common/components/DataSync/MatchProperties/MatchProperties';
import { DataSyncStrings } from '../../../common/localization/en';

interface Props {
  smsInvitesLayout?: boolean;
}
export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  root: {
    borderRadius: '12px 12px 0px 0px',
    margin: '0 20px',
  },
  tabs: {
    '& span': {
      maxWidth: '70px',
    },
  },
  tab: {
    margin: '10px 30px 0 0',
    textTransform: 'capitalize',
    paddingLeft: '0',
    paddingRight: '0',
    marginRight: '35px',
  },
  tabPanel: {
    paddingTop: '10px',
    paddingLeft: '0px',
    paddingRight: '0px',
    background: ({ smsInvitesLayout }) =>
      smsInvitesLayout ? 'transparent' : theme.palette.common.white,
    borderRadius: '0 0 12px 12px',
  },
  title: {
    fontWeight: 400,
    fontSize: '21px',
    lineHeight: '26px',
    marginBottom: '20px',
  },
  dNDSectionTitle: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '26px',
  },
  mt40: {
    marginTop: '40px',
  },
  dNDSectionSubTitle: {
    color: '#626C74',
    marginTop: '6px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
  },
  DNDInputSubTitle: {
    color: '#085DFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
  },
  inputSection: {},
  inputIcon: {
    marginRight: '28px',
  },

  stepper: {
    width: 'fit-content',
    margin: '-40px 0 0 30px',
  },
  listWrapper: {
    display: 'flex',
    marginRight: '25px',
    margin: '5px 15px 35px',
  },
  importingText: {
    fontSize: '14px',
    lineHeight: '19px',
    letterSpacing: '0.02em',
    color: '#1A1538',
    marginBottom: '14px',
  },
}));

export const getValidationSchema = () =>
  Yup.object().shape(
    {
      firstName: Yup.string().when('lastName', {
        is: (lastName: string) => !lastName,
        then: Yup.string().required(DataSyncStrings.ValidationNameRequiredError),
        otherwise: Yup.string(),
      }),
      lastName: Yup.string().when('firstName', {
        is: (firstName: string) => !firstName,
        then: Yup.string().required(DataSyncStrings.ValidationNameRequiredError),
        otherwise: Yup.string(),
      }),
      cell: Yup.string().required(DataSyncStrings.ValidationError),
      transactionDate: Yup.string().required(
        DataSyncStrings.ValidationTransactionDateRequiredError,
      ),
    },
    [['firstName', 'lastName']],
  );

export const findMatch = (uploadedCSV: string[], fieldName: string) =>
  uploadedCSV
    ? uploadedCSV.find((el: string) =>
        Object.entries(matchPropertiesLiteral).find(
          (entry) => matchPropertiesLiteral[fieldName] === el && el === entry[1],
        )
          ? true
          : false,
      )
    : '';

export interface HistoryApiUserDetailsModel {
  id: string;
  transactionDate: string;
  name: string;
  cell: string;
  email: string;
  optin: string;
  validUser: boolean;
}
export interface HistoryApiModel {
  id: string;
  fileName: string;
  numberOfRecords: string;
  recordsValidated: string;
  importDate: string;
  status: string;
  users: HistoryApiUserDetailsModel[];
  name: string;
}
