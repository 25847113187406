import styled from 'styled-components';
import { theme } from '../../../../theme';

export const ValueField = styled.p`
  margin-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  color: ${theme.palette.text.primary};
`;
export const RewardWrapper = styled.div`
  padding: 20xp;
  min-height: 100px;
  box-shadow: 0px 2px 12px -2px rgba(7, 27, 134, 0.42);
  border-radius: 8px;
  margin-left: 10px;
`;
export const RewardImage = styled.img`
  width: 70px;
  height: 80px;
  border-radius: 8px;
`;
export const RewardText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: ${theme.palette.text.primary};
  text-transform: capitalize;
`;

export const TextLabel = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 175%;
  color: ${theme.palette.text.secondary};
`;
export const TextValue = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${theme.palette.text.primary};
  overflow-wrap: break-word;
`;

export const UsersBox = styled.div`
  box-shadow: 0px 10px 40px -6px rgba(10, 25, 107, 0.15);
  border-radius: 8px;
  min-height: 100px;
  padding: 10px;
`;

export const BlockTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  color: ${theme.palette.primary.dark};
`;
