import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
  subTitle: {
    fontSize: '14px',
    color: '#344054',
    marginTop: '6px',
    width: '440px',
    textAlign: 'center',
  },
  addCreator: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginTop: '30px',
  },
  select: {
    width: '442px',
    height: '44px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    color: '#667085',
    fontSize: '15px',
    fontWeight: 400,
    padding: '0 20px 0 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '6px',
    cursor: 'pointer',
  },
  campaignsWrapper: {
    width: '443px',
    height: '248px',
    overflowY: 'scroll',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    borderRadius: '15px',
    marginTop: '4px',

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#F5F6F8',
      borderRadius: '8px',
    },
  },
  campaign: {
    cursor: 'pointer',
    width: '168px',
    height: '44px',
    borderRadius: '6px',
    backgroundColor: '#F9FAFB',
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#101828',
    margin: '2px 6px',
    transition: '0.11s',

    '&:first-child': {
      marginTop: '0',
    },

    '&:last-child': {
      marginBottom: '0',
    },

    '&:hover': {
      width: '100%',
      backgroundColor: '#EEECFF',
      margin: '2px 0',
    },
  },
}));
