import { FC } from 'react';
import { Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { HelpersStrings } from '../../../../common/localization/en';

const useStyles = makeStyles((theme: Theme) => ({
  styledButton: (props: { status: boolean }) => ({
    padding: '2px 16px',
    minWidth: '44px',
    borderRadius: ' 50px',
    borderWidth: '0 !important',
    fontSize: '10px',
    color: props.status ? theme.palette.success.dark + '!important' : '#28634E',
    backgroundColor: props.status ? theme.palette.success.light : '#F1F1FA',
  }),
}));
interface CampaignsStatusTableProps {
  status: boolean;
}
export const CampaignsStatusTable: FC<CampaignsStatusTableProps> = ({ status }) => {
  const styles = useStyles({ status });

  return (
    <div>
      <Button className={styles.styledButton} variant="outlined" disabled>
        {/* {status ? CommonStrings.Active : CommonStrings.Deactivate} */}
        {HelpersStrings.NotAvailable}
      </Button>
    </div>
  );
};
