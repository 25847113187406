import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 32px 10px;
  box-sizing: border-box;
  border-radius: 16px;
`;
