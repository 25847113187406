import * as React from 'react';
import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={26} height={26} fill="none" {...props}>
    <path
      stroke={props.color || '#8B89A0'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M12.999 1.333V2.5M2.499 13H1.332m4.083-7.584-.7-.7m15.867.7.7-.7M24.665 13H23.5m-12.834 1.75h4.667m-2.333 0v5.833m4.083-1.897a7 7 0 1 0-8.167 0v2.247c0 1.307 0 1.96.255 2.46.223.438.58.795 1.02 1.019.498.254 1.152.254 2.459.254h.7c1.306 0 1.96 0 2.459-.254.44-.224.796-.58 1.02-1.02.254-.499.254-1.152.254-2.459v-2.247Z"
    />
  </svg>
);
const VideoStyleListInspirationalIcon = memo(SvgComponent);
export default VideoStyleListInspirationalIcon;
