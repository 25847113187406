import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { CampaignsApiModel } from '../../../api/models/campaigns';
import { VenueProperties } from '../../../api/models/venue';
import { ClientTypes } from '../../../api/models/common';
import { SalesCalculationContainer } from './FormSection/SalesCalculationContainer/SalesCalculationContainer';
import { FundraisingCalculationContainer } from './FormSection/FundraisingCalculationContainer/FundraisingCalculationContainer';
import { ETSCalculationContainer } from './FormSection/ETSCalculationContainer/ETSCalculationContainer';
import { MarketingCalculationContainer } from './FormSection/MarketingCalculationContainer/MarketingCalculationContainer';

interface GetCalculationFormProps {
  properties: Partial<VenueProperties> | null;
  activeCampaign: CampaignsApiModel | null;
  onChangeFormHandler: () => void;
  setCanLeave: (value: boolean) => void;
  venueId: string;
  clientType: ClientTypes | '';
  isMobile: boolean;
}

export interface RecommendedValues {
  name: string;
  value: number;
}
export const getConvertedRecommendedValue = (
  mockValues: string[],
  recommendedValues: number[],
): RecommendedValues[] =>
  mockValues.map(
    (mockValue, index): RecommendedValues => ({
      name: mockValue,
      value: recommendedValues[index],
    }),
  );

export const getCalculationForm = ({
  properties,
  onChangeFormHandler,
  setCanLeave,
  venueId,
  isMobile,
  activeCampaign,
  clientType,
}: GetCalculationFormProps): React.ReactNode => {
  switch (clientType) {
    case ClientTypes.SALES: {
      return (
        <SalesCalculationContainer
          properties={properties}
          onChangeForm={onChangeFormHandler}
          setCanLeave={setCanLeave}
          venueId={venueId}
          isMobile={isMobile}
          activeCampaign={activeCampaign}
        />
      );
    }
    case ClientTypes.MARKETING: {
      return (
        <MarketingCalculationContainer
          properties={properties}
          onChangeForm={onChangeFormHandler}
          setCanLeave={setCanLeave}
          venueId={venueId}
          isMobile={isMobile}
          activeCampaign={activeCampaign}
        />
      );
    }
    case ClientTypes.FUNDRAISING: {
      return (
        <FundraisingCalculationContainer
          properties={properties}
          onChangeForm={onChangeFormHandler}
          setCanLeave={setCanLeave}
          venueId={venueId}
          isMobile={isMobile}
          activeCampaign={activeCampaign}
        />
      );
    }
    case ClientTypes.ETS: {
      return (
        <ETSCalculationContainer
          properties={properties}
          onChangeForm={onChangeFormHandler}
          setCanLeave={setCanLeave}
          venueId={venueId}
          isMobile={isMobile}
          activeCampaign={activeCampaign}
        />
      );
    }
    default:
      return (
        <SalesCalculationContainer
          properties={properties}
          onChangeForm={onChangeFormHandler}
          setCanLeave={setCanLeave}
          venueId={venueId}
          isMobile={isMobile}
          activeCampaign={activeCampaign}
        />
      );
  }
};

export const stepperNames = [
  'Rewards.NewCampaignWizard.Step1Title',
  'Rewards.NewCampaignWizard.Step2Title',
  'Rewards.NewCampaignWizard.Step3Title',
];

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
}));
