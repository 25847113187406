import { FC, useEffect, useState } from 'react';
import { Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ClientTypes } from '../../../api/models/common';
import { SearchObject } from '../../../common/components/SearchAccordion/SearchAccordion.helper';
import { useAppDispatch } from '../../../store';
import { reset } from '../../../store/slices/venueCreationSlice';
import { WizardFailure } from './WizardFailure/WizardFailure';
import { OrgSelection } from './OrgSelection/OrgSelection';
import { VenueCreationStepper } from './Stepper/Stepper';
import { WizardSuccess } from './Stepper/Steps/components/WizardSuccess/WizardSuccess';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0 10px 40px -6px rgba(10, 25, 107, 0.08)',
    background: theme.palette.common.white,
    borderRadius: '20px',
    margin: '40px',
    padding: '8px',
    overflowy: 'scoll',
  },
}));

export const AdminVenuWizard: FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const items2 = (Object.keys(ClientTypes) as Array<ClientTypes>).map((key) => ({
    //@ts-ignore
    name: ClientTypes[key],
    //@ts-ignore
    id: ClientTypes[key],
  }));

  const [showStepper, setShowStepper] = useState(false);
  const [market, setMarket] = useState<SearchObject>();
  const [showWizardSuccess, setShowWizardSuccess] = useState(false);
  const [showWizardFailure, setShowWizardFailure] = useState(false);

  useEffect(() => {
    dispatch(reset());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLetsGo = () => {
    setShowStepper(true);
  };
  if (showWizardFailure) {
    return (
      <div className={classes.root}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <WizardFailure
              setShowWizardFailure={setShowWizardFailure}
              setShowWizardSuccess={setShowWizardSuccess}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          {showStepper ? (
            !showWizardSuccess ? (
              market && (
                <VenueCreationStepper
                  market={market.name || ''}
                  setShowWizardSuccess={setShowWizardSuccess}
                  setShowWizardFailure={setShowWizardFailure}
                />
              )
            ) : (
              <WizardSuccess
                setShowWizardFailure={setShowWizardFailure}
                marketName={market?.name}
              />
            )
          ) : (
            <OrgSelection
              market={market}
              setMarket={setMarket}
              markets={items2}
              handleLetsGo={handleLetsGo}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
