export enum UwmEventNames {
  gbpSyncStarted = 'uwm_modal_gbp_sync_started',
  gbpSyncSuccess = 'uwm_modal_gbp_sync_success',
  gbpSyncCancel = 'uwm_modal_gbp_sync_cancel',
  defaultWelcomeVideoSelected = 'uwm_modal_default_welcome_video_selected',
  openImportModal = 'uwm_invites_modal_open_import_modal',
  openIndividualImportModal = 'uwm_invites_modal_open_individual_import_modal',
  videosConfirmClick = 'uwm_review_videos_modal_confirm_btn_click',
  videoSelectClick = 'uwm_review_videos_video_select_click',
}
