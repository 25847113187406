export const CopyLinkIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.53067 12.7811L7.47001 13.8418C6.00554 15.3063 3.63118 15.3063 2.16671 13.8418C0.702243 12.3773 0.702243 10.0029 2.16671 8.53848L3.22737 7.47782M12.7733 8.53848L13.834 7.47782C15.2984 6.01336 15.2984 3.63899 13.834 2.17452C12.3695 0.710055 9.99514 0.710055 8.53067 2.17452L7.47001 3.23518M5.37534 10.6331L10.6253 5.38314"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
