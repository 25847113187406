export enum LaunchpadEventNames {
  StartLaunchpad = 'launchpad_start_flow',
  FinishLaunchpad = 'launchpad_finish_flow',
  NavigateToRoute = 'launchpad_navigate_to_route',
  NavigateToActiveItem = 'launchpad_navigate_to_active_item',
  MoveToNextItem = 'launchpad_move_to_next_item',
  HideQsMarker = 'launchpad_hide_qs_marker',
  MoveToNextTopicStep = 'launchpad_move_to_next_topic_step',
  ShowLaunchpad = 'launchpad_show',
  ResetTopicStep = 'launchpad_reset_topic_step',
}
