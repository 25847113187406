export const PlanBackgroundTopIcon = () => {
  return (
    <svg
      width="315"
      height="159"
      viewBox="0 0 315 159"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M0.306641 0.903564L84.0495 82.3545L157.498 158.095L157.498 0.903558L0.306641 0.903564Z"
        fill="#427BEA"
      />
      <path
        opacity="0.14"
        d="M157.498 79.6239C157.498 89.9291 159.527 100.133 163.471 109.654C167.415 119.175 173.195 127.826 180.482 135.113C187.769 142.399 196.419 148.18 205.94 152.123C215.461 156.067 225.665 158.097 235.97 158.097C246.276 158.097 256.48 156.067 266.001 152.123C275.521 148.18 284.172 142.399 291.459 135.113C298.746 127.826 304.526 119.175 308.47 109.654C312.413 100.133 314.443 89.9291 314.443 79.6239L235.97 79.6239L157.498 79.6239Z"
        fill="#427BEA"
      />
      <path
        opacity="0.28"
        d="M314.445 1.48915C314.445 11.7943 312.415 21.9986 308.471 31.5194C304.528 41.0401 298.748 49.6909 291.461 56.9778C284.174 64.2646 275.523 70.0449 266.002 73.9885C256.482 77.9322 246.277 79.9619 235.972 79.9619C225.667 79.9619 215.463 77.9321 205.942 73.9885C196.421 70.0449 187.77 64.2646 180.483 56.9778C173.197 49.6909 167.416 41.0401 163.473 31.5194C159.529 21.9986 157.499 11.7943 157.499 1.48912L235.972 1.48915L314.445 1.48915Z"
        fill="#427BEA"
      />
    </svg>
  );
};
