import { FC, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useAppDispatch } from '../../../store';
import { useStyles } from './CampaignFilter.styles';
import { DotIcon } from '../../assets/DotIcon';
import { StarIcon } from '../../assets/StarIcon';
import { ExpandIcon } from '../../assets/ExpandIcon';
import IconButton from '@mui/material/IconButton';
import { RemoveIcon } from '../../assets/RemoveIcon';
import { ClipsSearchStrings } from '../../localization/en';
import { removeVideosCampaignFilter } from '../../../store/slices/searchFilterSlice';

interface CampaignItem {
  name: string;
  id: string;
  selected: boolean;
  isActive: boolean;
  isPrimary?: boolean;
  selectedIndex: number;
}

interface Props {
  onCampaignsUpdate: (campaignIds: string[]) => void;
  initialCampaignItems: CampaignItem[];
}

export const CampaignFilter: FC<Props> = ({ onCampaignsUpdate, initialCampaignItems }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [campaignItems, setCampaignItems] = useState<CampaignItem[]>(initialCampaignItems);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const campaignBadgesWrapperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const selectedIds = campaignItems.filter((item) => item.selected).map((item) => item.id);
    setSelectedIds(selectedIds);

    onCampaignsUpdate(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignItems]);

  const addCampaignItem = (itemId: string) => {
    setCampaignItems((prevItems) => {
      return prevItems.map((prevItem) => {
        if (prevItem.id === itemId) {
          const lastSelectedIndex = Math.max(...prevItems.map((item) => item.selectedIndex));
          return {
            ...prevItem,
            selected: !prevItem.selected,
            selectedIndex: lastSelectedIndex + 1,
          };
        } else {
          return prevItem;
        }
      });
    });

    const campaignBadgesWrapper = campaignBadgesWrapperRef.current;
    if (campaignBadgesWrapper) {
      campaignBadgesWrapper.scrollTop = 0;
    }
  };

  const removeCampaignItem = (itemId: string) => {
    setCampaignItems((prev) => {
      return prev.map((prevItem) => {
        return prevItem.id === itemId
          ? { ...prevItem, selected: !prevItem.selected, selectedIndex: 0 }
          : prevItem;
      });
    });
  };

  const clearItems = () => {
    dispatch(removeVideosCampaignFilter());
    setCampaignItems((prev) => {
      return prev.map((prevItem) => {
        return { ...prevItem, selected: false, selectedIndex: 0 };
      });
    });
  };

  return (
    <FormControl className={classes.container}>
      {selectedIds.length > 0 && (
        <Button className={classes.clearButton} onClick={clearItems}>
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        multiple
        value={campaignItems.filter((items) => items.selected)}
        onMouseUp={(event) => {
          if (campaignItems.length === 0) {
            return;
          }

          const target = event.target as HTMLElement;
          const listItemTarget = target.closest('li');

          if (!listItemTarget) {
            setIsOpen((prev) => !prev);
          }
        }}
        displayEmpty={true}
        disabled={campaignItems.length === 0}
        open={isOpen}
        renderValue={(items) => {
          return items.length > 0 ? (
            <Box className={classes.campaignBadgesWrapperRef} ref={campaignBadgesWrapperRef}>
              {items
                .sort((a, b) => {
                  return b.selectedIndex - a.selectedIndex;
                })
                .map((item) => (
                  <Button className={classes.badgeButton}>
                    {item.name.length > 26 ? `${item.name.slice(0, 26)}...` : item.name}
                    <IconButton
                      onMouseDown={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setIsOpen(false);
                      }}
                      onMouseUp={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        setIsOpen(false);

                        removeCampaignItem(item.id);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Button>
                ))}
            </Box>
          ) : (
            <Typography className={classes.placeholder}>Select...</Typography>
          );
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
              maxHeight: '160px',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#fff',
                height: '20px !important',
                maxHeight: '20px !important',
                borderRadius: '12px',
                margin: '5px 0',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#EAECF0',
                borderRadius: '5px',
                height: '40px !important',
                cursor: 'pointer',
              },
            },
          },
        }}
        sx={{
          border: '1px solid #cacae4',
          paddingRight: 0,

          '& .MuiSelect-select': {
            padding: '8px 0 8px 16px !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none!important',
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: 'none!important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            {selectedIds.length === 0 && <ExpandIcon className={classes.expandIcon} />}
          </Box>
        )}
      >
        {campaignItems.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            onClick={() => {
              addCampaignItem(item.id);
            }}
            sx={{
              '& .MuiListItemText-root': {
                flex: 'unset !important',
              },
            }}
          >
            <Checkbox checked={item.selected} className={classes.checkbox} />

            <DotIcon color={item.isActive ? '#12B76A' : '#D31F1F'} />
            <ListItemText
              primary={item.name}
              sx={{
                marginLeft: '5px',
                '& span': {
                  fontSize: '11px',
                  fontWeight: 500,
                  color: '#3B3B3B',
                },
                '& div': {
                  flex: 'unset !important',
                },
              }}
            />
            {item.isPrimary && (
              <Box className={classes.iconWrapper}>
                <StarIcon />
              </Box>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
