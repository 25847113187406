import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0 70px 0 57px',
    width: '100%',
  },
  description: {
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: '#475467',
    marginBottom: '20px',
  },
  iconBadge: {
    background: `linear-gradient(180deg, #D9D5FB 0%, ${theme.palette.primary.main} 100%);`,
    border: '1px solid #67CE67',
    boxShadow: '4px 9px 14px rgba(155, 156, 142, 0.26)',
    borderRadius: '8px 0px 0px 8px',
    width: '124px',
    height: '152px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 0,
    flexShrink: 0,
  },
  icon: {
    width: '120px',
    height: '120px',
    marginTop: '12px',
  },
  bookIcon: {
    position: 'absolute',
    top: '26px',
    left: '12px',
    width: '125px',
    height: '125px',
  },
  stepTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: '#101828',
    marginBottom: '10px',
  },
  stepDescription: {
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '21px',
    letterSpacing: '0.02em',
    color: '#484848',
  },
  previewContainer: {
    height: '500px',
    width: '480px',
    borderRadius: '16px',
    backgroundColor: '#FFF',
    padding: '40px 0px 40px 20px',
    position: 'relative',
    margin: '0 auto',
  },
  previewImg: {
    position: 'absolute',
    top: '40px',
    height: '430px',
    width: '510px',
    objectPosition: 'left',
  },
  dividerWrapper: {
    marginBottom: '28px',
  },
  divider: {
    borderColor: '#DCDBE4',
    maxWidth: '495px',
  },
  collectIconWrapper: {
    height: '160px',
    marginTop: '-4px',
    marginLeft: '-10px',
    width: '136px',
    position: 'relative',
  },
  lightIcon: {
    position: 'absolute',
    top: '21px',
    left: '10px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stepWrapper: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    display: 'flex',
    maxWidth: '550px',
  },
  titleWrapper: {
    padding: '16px 20px 0 40px',
  },
  stepDividerWrapper: {
    marginBottom: '14px',
    width: '40px',
  },
  stepDivider: {
    borderColor: '#F7B409',
  },
}));
