import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { SmsEditor } from '../../integrations/SmsInvite/Sections/AccordionSection/SmsEditor';
import { ErrorsStrings, SmsInviteStrings } from '../../../common/localization/en';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useModal } from '../../../hooks/useModal';
import { SendTestMessageModal } from '../../../common/components/SMS/SendTestMessageModal';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
} from '../../../common/components/SMS/SMS.helper';
import { useSendTestSms } from '../../../services/hooks/useSendTestSms';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../../common/constants/events/newCampaignEvents';
import {
  setMessageFrequency,
  setSMSMethod,
  setSmsRewardMessage,
} from '../../../store/slices/createCampaignSlice';
import HowToRewardCreators from '../HowToRewardCreators/HowToRewardCreators';
import { useStyles } from './AcknowledgementSMSContent.styles';
import { MessageFrequency, RewardFulfillmentMethod } from '../../../api/models/rewardFulfillment';
import { getRewardCardUrl } from '../../../store/slices/campaignsSlice';
import { format } from 'date-fns';

export const AcknowledgementSMSContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const classes = useStyles();
  const me = useTypedSelector((store) => store.me);
  const { name: companyName, logo } = useTypedSelector((store) => store.venue.venue);
  const {
    smsRewardMessage: message,
    isNullReward,
    howToRewardCreators: { rewardName, contact, rewardHeader, cardColor },
    SMSMethod,
    messageFrequency,
  } = useTypedSelector((store) => store.createCampaign);

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: rewardName,
  });

  const {
    isOpen: isTestMessageModalOpen,
    open: openTestMessageModal,
    close: closeTestMessageModal,
  } = useModal();

  const constructedTestPostMessage = constructMessageFromTemplate(message, templateVariables, true);

  const sendTestSMS = useSendTestSms();

  const isManual = SMSMethod === RewardFulfillmentMethod.MANUAL_SMS;
  const isAutomatic = SMSMethod === RewardFulfillmentMethod.AUTOMATIC_SMS;

  const isFirstClip = messageFrequency === MessageFrequency.FIRST;
  const isEveryClip = messageFrequency === MessageFrequency.EVERY;

  const theme = useTheme();

  return (
    <>
      {!isNullReward && <HowToRewardCreators />}

      <SendTestMessageModal
        modalIsOpen={isTestMessageModalOpen}
        onClose={() => {
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageModalCloseClick);
          closeTestMessageModal();
        }}
        testMessage={constructedTestPostMessage}
        onTestMessageSendClick={(data) => {
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageModalSendClick);
          dispatch(
            getRewardCardUrl({
              companyName,
              contact,
              logoUrl: logo,
              userName: me.name,
              rewardHeader,
              rewardTitle: rewardName,
              cardStyle: cardColor,
              date: format(new Date(), 'P'),
            }),
          ).then((res) => {
            //@ts-ignore
            const mediaUrl = res.payload.imageUrl;
            sendTestSMS({ ...data, mediaUrl });
          });
        }}
        phoneNumber={me.phoneNumber}
        name={me.name}
      />
      <SmsEditor
        mt="16px"
        title="Edit Message Text"
        messageLabel=""
        message={message}
        onMessageChange={async (message: string) => {
          dispatch(setSmsRewardMessage(message));
        }}
        templateVariables={templateVariables}
        disabled={false}
        onSendTestClick={() => {
          openTestMessageModal();
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageSendSampleTestClick);
        }}
        errorText={!message.trim() ? ErrorsStrings.FieldIsRequired : ''}
        isMms={!isNullReward}
      />
      <Typography className={classes.messageText}>{SmsInviteStrings.MessageDelivery}</Typography>
      <Typography className={classes.selectText}>{SmsInviteStrings.SelectDelivery}</Typography>
      <Box className={classes.buttonsWrapper}>
        <Button
          className={classes.switchButton}
          onClick={() => {
            if (isAutomatic) {
              dispatch(setSMSMethod(RewardFulfillmentMethod.MANUAL_SMS));
              dispatch(setMessageFrequency(MessageFrequency.FIRST));
            }
          }}
          sx={{
            borderRadius: '50px 0px 0px 50px',
            border: `2px solid ${isManual ? theme.palette.primary.main : '#8B89A0'}`,
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: isManual ? theme.palette.primary.main : '#8B89A0',
            backgroundColor: isManual ? `${theme.palette.primary.main}0a` : '#fff',
            '&:hover': {
              backgroundColor: isManual ? `${theme.palette.primary.main}0a` : '#fff',
            },
          }}
        >
          {SmsInviteStrings.Manual}
        </Button>
        <Button
          className={classes.switchButton}
          onClick={() => {
            if (isManual) {
              dispatch(setSMSMethod(RewardFulfillmentMethod.AUTOMATIC_SMS));
            }
          }}
          sx={{
            borderRadius: '0px 50px 50px 0px',
            border: `2px solid ${isAutomatic ? theme.palette.primary.main : '#8B89A0'}`,
            color: isAutomatic ? theme.palette.primary.main : '#8B89A0',
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            right: '1px',
            backgroundColor: isAutomatic ? `${theme.palette.primary.main}0a` : '#fff',
            '&:hover': {
              backgroundColor: isAutomatic ? `${theme.palette.primary.main}0a` : '#fff',
            },
          }}
        >
          {SmsInviteStrings.Automatic}
        </Button>
      </Box>
      <Typography className={classes.messageSentText}>
        {isAutomatic
          ? SmsInviteStrings.MessageWillBeSentAutomatically
          : SmsInviteStrings.MessageWillBeSentManually}
      </Typography>
      {isAutomatic && (
        <>
          <Typography className={classes.selectText}>
            {SmsInviteStrings.MessageFrequency}
          </Typography>
          <Box className={classes.buttonsWrapper}>
            <Button
              className={classes.switchButton}
              onClick={() => {
                if (isEveryClip) {
                  dispatch(setMessageFrequency(MessageFrequency.FIRST));
                }
              }}
              sx={{
                borderRadius: '50px 0px 0px 50px',
                border: `2px solid ${isFirstClip ? theme.palette.primary.main : '#8B89A0'}`,
                borderRight: `2px solid ${theme.palette.primary.main}`,
                color: isFirstClip ? theme.palette.primary.main : '#8B89A0',
                backgroundColor: isFirstClip ? `${theme.palette.primary.main}0a` : '#fff',
                '&:hover': {
                  backgroundColor: isFirstClip ? `${theme.palette.primary.main}0a` : '#fff',
                },
              }}
            >
              {SmsInviteStrings.FirstClip}
            </Button>
            <Button
              className={classes.switchButton}
              onClick={() => {
                if (isFirstClip) {
                  dispatch(setMessageFrequency(MessageFrequency.EVERY));
                }
              }}
              sx={{
                borderRadius: '0px 50px 50px 0px',
                border: `2px solid ${isEveryClip ? theme.palette.primary.main : '#8B89A0'}`,
                color: isEveryClip ? theme.palette.primary.main : '#8B89A0',
                borderLeft: `2px solid ${theme.palette.primary.main}`,
                right: '1px',
                backgroundColor: isEveryClip ? `${theme.palette.primary.main}0a` : '#fff',
                '&:hover': {
                  backgroundColor: isEveryClip ? `${theme.palette.primary.main}0a` : '#fff',
                },
              }}
            >
              {SmsInviteStrings.EveryClip}
            </Button>
          </Box>
          <Typography className={classes.messageSentText}>
            {isFirstClip ? SmsInviteStrings.FirstClipMessage : SmsInviteStrings.EveryClipMessage}
          </Typography>
        </>
      )}
    </>
  );
};
