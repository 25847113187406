export const FacebookIcon = () => {
  return (
    <>
      <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.85177 1.26361C5.40289 1.26361 4.22831 2.44363 4.22831 3.89927V5.78429C4.22831 6.04116 4.02104 6.2494 3.76535 6.2494H1.75918V7.77523H3.55064C3.80633 7.77523 4.0136 7.98347 4.0136 8.24035V12.7365H5.66418V8.24035C5.66418 7.98347 5.87145 7.77523 6.12714 7.77523H7.7018L8.10439 6.22485H6.23448C5.9788 6.22485 5.77152 6.01661 5.77152 5.75973V4.51942C5.77152 3.57754 6.53151 2.81399 7.46905 2.81399H8.24066V1.26361H6.85177ZM3.30239 3.89927C3.30239 1.92988 4.89151 0.333374 6.85177 0.333374H8.70362C8.95931 0.333374 9.16658 0.541614 9.16658 0.79849V3.27911C9.16658 3.53599 8.95931 3.74423 8.70362 3.74423H7.46905C7.04289 3.74423 6.69745 4.09129 6.69745 4.51942V5.29461H8.70362C8.84701 5.29461 8.98231 5.36137 9.06998 5.47537C9.15765 5.58937 9.18778 5.73774 9.15159 5.87714L8.50745 8.35776C8.45427 8.56256 8.27016 8.70547 8.05948 8.70547H6.5901V13.2016C6.5901 13.4585 6.38283 13.6667 6.12714 13.6667H3.55064C3.29495 13.6667 3.08768 13.4585 3.08768 13.2016V8.70547H1.29621C1.04053 8.70547 0.833252 8.49723 0.833252 8.24035V5.78429C0.833252 5.52741 1.04053 5.31917 1.29621 5.31917H3.30239V3.89927Z"
          fill="#8F94B6"
        />
      </svg>
    </>
  );
};
