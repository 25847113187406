import { FC } from 'react';
export const ArrowIcon: FC<{ color?: string }> = ({ color = '#344054' }) => (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      style={{ transition: 'stroke 0.3s ease-out' }}
      d="M1 11L6 6L1 1"
      stroke={color}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
