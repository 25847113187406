import React, { ChangeEvent, useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { Box, IconButton, Typography } from '@mui/material';
import { useStyles } from './CampaignInfo.styles';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../common/constants/events/rewardCampaignsEvents';
import {
  getCampaignById,
  patchIncentiveCampaign,
} from '../../../../../store/slices/campaignsSlice';
import { useToasts } from 'react-toast-notifications';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { EditIcon } from '../../../../../common/assets/newDesign/EditIcon';
import { RewardsStrings } from '../../../../../common/localization/en';
import { format } from 'date-fns';
import clsx from 'clsx';
import { PeekIcon } from '../../../../../common/assets/PeekIcon';

const CampaignInfo = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { connectedAccount } = useTypedSelector((state) => state.peek);

  const [newCampaignName, setNewCampaignName] = useState(currentCampaign?.name);
  const [isEditNameOpen, setIsEditNameOpen] = useState(false);

  const modifiedAtDate = currentCampaign?.updatedAt
    ? format(new Date(currentCampaign.updatedAt), 'PP')
    : '';

  const handleEditNameOpen = () => {
    setTimeout(() => {
      setIsEditNameOpen(true);
      setNewCampaignName(currentCampaign?.name);
    }, 0);
  };

  const handleNameEditChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewCampaignName(event.target.value);
    trackEvent(RewardCampaignsEvents.RewardCampaignSummaryNameChange, {
      campaignId: currentCampaign?.id,
      value: event.target.value,
    });
  };

  const onInputNameEditEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await saveCampaignName();
      trackEvent(RewardCampaignsEvents.RewardCampaignSummaryNameChangeByKeyboard, {
        campaignId: currentCampaign?.id,
        value: newCampaignName,
      });
    } else if (event.key === 'Escape') {
      setIsEditNameOpen(false);
      trackEvent(RewardCampaignsEvents.RewardCampaignSummaryCancelNameChangeByKeyboard, {
        campaignId: currentCampaign?.id,
      });

      setNewCampaignName(currentCampaign?.name);
    }
  };

  const saveCampaignName = async () => {
    setIsEditNameOpen(false);

    if (!currentCampaign) return;

    if (!newCampaignName) {
      setNewCampaignName(currentCampaign.name);
      addToast(RewardsStrings.CampaignNameError, { appearance: 'error' });
      return;
    }

    if (newCampaignName === currentCampaign.name) {
      return;
    }

    await dispatch(
      patchIncentiveCampaign({
        campaignId: currentCampaign.id,
        values: { name: newCampaignName },
      }),
    ).then(() => dispatch(getCampaignById(currentCampaign.id)));
  };

  const editCampaignNameRef = useDetectClickOutside({
    onTriggered: async () => {
      if (isEditNameOpen) {
        await saveCampaignName();
      }
    },
  });

  return (
    <Box>
      <Box className={classes.campaignNameWrapper}>
        {!isEditNameOpen ? (
          <>
            <Typography className={classes.campaignName}>{currentCampaign?.name}</Typography>
            <IconButton
              className={classes.editButton}
              onClick={handleEditNameOpen}
              id="edit-name-button"
            >
              <EditIcon color="#FFF" width={16} height={16} />
            </IconButton>
          </>
        ) : (
          <input
            type="text"
            value={newCampaignName}
            onChange={handleNameEditChange}
            className={classes.nameEditInput}
            onKeyDown={onInputNameEditEnter}
            maxLength={36}
            ref={editCampaignNameRef}
            id="campaign-name-input"
          />
        )}
      </Box>
      <Typography className={classes.campaignInfo}>
        {`${RewardsStrings.LastSavedBy} ${currentCampaign?.lastModifiedByName} on ${modifiedAtDate}`}
      </Typography>
      <Box className={classes.tagsWrapper}>
        <Box
          className={clsx(
            classes.tagWrapper,
            currentCampaign?.endedAt ? classes.inactiveTag : classes.activeTag,
          )}
        >
          {currentCampaign?.endedAt ? RewardsStrings.Inactive : RewardsStrings.Active}
        </Box>
        {currentCampaign?.isPreferred && (
          <Box className={classes.primaryCampaignWrapper}>
            {connectedAccount && (
              <Box className={classes.peekIcon}>
                <PeekIcon />
              </Box>
            )}
            <Box className={clsx(classes.tagWrapper, classes.primaryTag)}>
              {RewardsStrings.PrimaryCampaign}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CampaignInfo;
