import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useHeaderSVStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '112px',
    backgroundColor: '#fff',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1fr max-content',
    justifyContent: 'space-between',
    padding: '0 46px 0 54px',
    position: 'relative',
    zIndex: 3,
  },
  titleWrapper: {
    alignItems: 'center',
    width: '100%',
  },
  nameText: {
    color: theme.palette.common.gray,
    marginLeft: '26px',
    fontStyle: 'medium',
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '28px',
    color: '#000',
    margin: '0 23px 0 10px',
  },
  typeText: {
    marginLeft: '25px',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    color: '#8B89A0',
  },
  iconBack: {
    fontSize: '28px !important',
  },
  backButton: {
    borderRadius: 12,
    border: '1px solid #8B89A0',
    color: theme.palette.common.deepDark,
    width: '54px',
    height: '42px',
    paddingLeft: '20px',
  },
  button: {
    width: '155px',
    padding: '9px 0',
  },
  divider: {
    height: '20px',
    width: '1px',
    backgroundColor: '#ccc',
    marginRight: '15px',
  },
  navMenueWrapper: {
    display: 'flex',
  },
}));
