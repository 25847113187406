export const PlanBackgroundSideIcon = () => {
  return (
    <svg
      width="161"
      height="319"
      viewBox="0 0 161 319"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M160.07 318.639L77.6102 233.859L0.932153 159.501L160.07 159.501L160.07 318.639Z"
        fill="#427BEA"
      />
      <path
        opacity="0.14"
        d="M80.3752 159.501C69.9424 159.501 59.6118 157.446 49.9731 153.454C40.3344 149.461 31.5765 143.609 24.1994 136.232C16.8223 128.855 10.9705 120.097 6.97802 110.459C2.98556 100.82 0.930663 90.4892 0.930665 80.0564C0.930667 69.6236 2.98556 59.2929 6.97803 49.6543C10.9705 40.0156 16.8223 31.2577 24.1995 23.8806C31.5766 16.5035 40.3345 10.6517 49.9731 6.65921C59.6118 2.66675 69.9424 0.611863 80.3753 0.611863L80.3752 80.0564L80.3752 159.501Z"
        fill="#427BEA"
      />
      <path
        opacity="0.28"
        d="M159.477 0.609861C149.044 0.609861 138.713 2.66476 129.075 6.65722C119.436 10.6497 110.678 16.5015 103.301 23.8786C95.9239 31.2557 90.072 40.0136 86.0796 49.6523C82.0871 59.291 80.0322 69.6216 80.0322 80.0544C80.0322 90.4872 82.0871 100.818 86.0796 110.457C90.0721 120.095 95.9239 128.853 103.301 136.23C110.678 143.607 119.436 149.459 129.075 153.452C138.713 157.444 149.044 159.499 159.477 159.499L159.477 80.0544L159.477 0.609861Z"
        fill="#427BEA"
      />
    </svg>
  );
};
