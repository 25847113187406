import { makeStyles } from '@mui/styles';
import { theme } from '../../../../../theme';
import { ContentDetails } from '../../../../localization/en';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  metricsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  interactionUnit: {
    display: 'flex',
    height: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  dot: {
    display: 'flex',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: '4px',
    marginTop: '6px',
    opacity: 0.75,
  },
  interactionUnitInfo: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  helperText: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    lineHeight: '20px',
  },
}));

interface InteractionUnit {
  value: 'videoViews' | 'referrals' | 'convertedReferrals' | 'conversionRate';
  name: string;
  color: any;
}

export const getInteractionUnits = (): InteractionUnit[] => {
  const { blue, orange, mint } = theme.palette.common.ColoredDots;
  return [
    {
      name: ContentDetails.Interaction1,
      value: 'videoViews',
      color: blue,
    },
    {
      name: ContentDetails.Interaction2,
      value: 'referrals',
      color: orange,
    },
    {
      name: ContentDetails.Interaction4,
      value: 'conversionRate',
      color: mint,
    },
  ];
};
