import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoAuthorInfoStyles = makeStyles((theme: Theme) => ({
  userContacts: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '4px',
    fontWeight: 500,
    marginBottom: '31px',
    gap: '8px',
  },
  email: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      marginLeft: '8px',
    },
  },
}));
