import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0px 20px 16px 20px',
    display: 'flex',
    flexDirection: 'column',
    width: '404px',
  },
  label: {
    marginBottom: '11px',
    lineHeight: '24px',
    color: '#1A1538',
    letterSpacing: '0.24px',
  },
  linkFieldWrapper: {
    position: 'relative',
    width: '100%',
  },
  httpsText: {
    position: 'absolute',
    width: '61px',
    height: '42px',
    background: theme.palette.primary.main,
    borderRadius: '10px 0px 0px 10px',
    color: '#fff',
    letterSpacing: '0.24px',
    lineHeight: '24px',
    zIndex: '10',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
