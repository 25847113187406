import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    zIndex: 3,
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px',
    paddingTop: '14px',
    width: 'fit-content',
    minWidth: '200px',
    height: '100%',
  },
  tab: {
    padding: '0 12px',
    width: '100%',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
    marginBottom: '12px',
  },
  tabName: {
    fontSize: '15px !important',
    fontWeight: '500',
    userSelect: 'none',
    minWidth: 'fit-content',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: '#667085',
  },
  amount: {
    marginLeft: '.5em',
    height: '22px',
    borderRadius: '16px',
    background: '#F2F4F7',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    width: 'fit-content',
    fontWeight: '600',
    fontSize: '12px',
  },
  aboutWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '40px auto 0',
  },
  aboutText: {
    fontSize: '15px',
    fontWeight: 500,
    color: '#000',
  },
  tooltip: {
    width: '403px',
  },
  tooltipTextTitle: {
    fontSize: '12px',
    color: '#344054',
    fontWeight: 600,
    lineHeight: '18px',
  },
  tooltipText: {
    fontSize: '12px',
    color: '#667085',
    fontWeight: 500,
    lineHeight: '18px',
  },
  tooltipIcon: {
    paddingTop: '5px',
    marginLeft: '8px',
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 20px 7px 12px',
    width: '100%',
  },
  totalLabel: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20px',
    color: '#667085',
  },
  totalNumber: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    total: '#344054',
  },
}));
