import React, { FC } from 'react';
import { useStyles } from './SocialVerseLayoutSwitch.styles';
import { Box } from '@mui/material';
import { ListLayoutIcon } from '../../../common/assets/newDesign/ListLayoutIcon';
import { GridLayoutIcon } from '../../../common/assets/newDesign/GridLayoutIcon';
import { SocialVerseStrings } from '../../../common/localization/en';

export enum LayoutType {
  List = 'list',
  Grid = 'grid',
}

interface Props {
  activeLayout: LayoutType;
  onLayoutChange: () => void;
}

const SocialVerseLayoutSwitch: FC<Props> = ({ activeLayout, onLayoutChange }) => {
  const styles = useStyles();

  return (
    <Box className={styles.container} onClick={onLayoutChange}>
      <Box
        className={styles.optionBackground}
        style={{
          left: activeLayout === LayoutType.List ? '4px' : '80px',
          width: activeLayout === LayoutType.List ? '80px' : '86px',
        }}
      />
      <Box
        className={styles.optionWrapper}
        style={{ color: activeLayout === LayoutType.List ? '#344054' : '#667085' }}
      >
        <ListLayoutIcon /> {SocialVerseStrings.List}
      </Box>
      <Box
        className={styles.optionWrapper}
        style={{ color: activeLayout === LayoutType.Grid ? '#344054' : '#667085' }}
      >
        <GridLayoutIcon /> {SocialVerseStrings.Grid}
      </Box>
    </Box>
  );
};

export default SocialVerseLayoutSwitch;
