import { SVGProps, memo } from 'react';
import CouponIcon from '../../QuickStartIcons/CouponIcon';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="25" cy="25" r="24" fill="currentColor" fill-opacity="0.05" />
    <g transform="translate(15, 15)">
      <CouponIcon width="50" height="50" color="#8B89A0" />
    </g>
  </svg>
);

const CouponCircleIcon = memo(SvgComponent);
export default CouponCircleIcon;
