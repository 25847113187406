import { ReactNode } from 'react';
import EqualizerIcon from '@mui/icons-material/Equalizer';

import { KPIApiNames, KPIApiModel, KpiAnalyticsApiNames } from '../../../api/models/analytics';
import {
  AnalyticsRationLabels,
  DashboardStrings,
  DashboardWidgetTab,
} from '../../../common/localization/en';
import { numberWithCommas } from '../../../services/utilities';
import { SharesAnalyticsBlockModel } from '../SharesSubAnalyticsNavigation/SharesAnalytics.helper';
import { UserIconAnalytics } from './../../../common/assets/newDesign/UserIconAnalytics';

import {
  AnalyticsBlockStrings,
  AnalyticsTooltipText,
  AnalyticRationLabelsTopgolf,
} from '../../../common/localization/en';
import { VideosIconAnalytics } from './../../../common/assets/newDesign/VideosIconAnalytics';
import { SharesIconAnalytics } from '../../../common/assets/newDesign/SharesIconAnalytics';
import { ViewsIcon } from '../../../common/assets/newDesign/ViewsIcon';
import { CTAIcon } from './../../../common/assets/newDesign/CTAIcon';
import { SeoAnalyticsIcon } from '../../../common/assets/newDesign/SEOAnalyticsIcon';
import { VenueApiModelWithStats } from '../../../api/models/venue';

export interface AnalyticsBlockModel {
  icon?: ReactNode;
  kpiName: string;
  name: string;
  value: number | string;
  tooltipText: string;
  ratioValue?: string;
  ratioLable?: string;
  apiName?: string;
}

export const widgetInitialAnalyticsData: AnalyticsBlockModel[] = [
  {
    icon: <UserIconAnalytics />,
    kpiName: KPIApiNames.WidgetViews,
    name: DashboardWidgetTab.Block1,
    value: 0,
    tooltipText: DashboardWidgetTab.Block1Tooltip,
  },
  {
    icon: <ViewsIcon />,
    kpiName: KPIApiNames.WidgetVideoViews,
    name: DashboardWidgetTab.Block2,
    value: 0,
    tooltipText: DashboardWidgetTab.Block2Tooltip,
  },
  // {
  //   icon: <CTAIcon />,
  //   kpiName: KPIApiNames.WidgetCtaClicks,
  //   name: 'Dashboard.WidgetTab.Block3',
  //   value: 0,
  //   tooltipText: 'Dashboard.WidgetTab.Block3Tooltip',
  // },
];

export const widgetInitialAnalyticsTBDData: AnalyticsBlockModel = {
  icon: <EqualizerIcon />,
  kpiName: KPIApiNames.WidgetConversionRate,
  name: DashboardStrings.ConversionRate,
  value: DashboardStrings.ComingSoon,
  tooltipText: 'Tooltip Text',
};

export const webInitialAnalyticsData: AnalyticsBlockModel[] = [
  {
    kpiName: KPIApiNames.ActiveCampaigns,
    apiName: KPIApiNames.ActiveCampaigns,
    name: AnalyticsBlockStrings.Campaigns,
    value: 0,
    tooltipText: AnalyticsTooltipText.CampaignsTooltip,
  },
  {
    kpiName: KPIApiNames.Invites,
    apiName: KpiAnalyticsApiNames.CamapignInvitation,
    name: AnalyticsBlockStrings.Invites,
    value: 0,
    tooltipText: AnalyticsTooltipText.InvitesTooltip,
  },
  {
    kpiName: KPIApiNames.Heroes,
    name: AnalyticsBlockStrings.Creators,
    apiName: KPIApiNames.Heroes,
    value: 0,
    tooltipText: AnalyticsTooltipText.CreatorsTooltip,
    ratioLable: AnalyticsRationLabels.Block2RationLable,
    ratioValue: '',
  },
  {
    kpiName: KPIApiNames.Videos,
    name: AnalyticsBlockStrings.Clips,
    apiName: KPIApiNames.Videos,
    value: 0,
    tooltipText: AnalyticsTooltipText.ClipsTooltip,
    ratioLable: AnalyticsRationLabels.Block3RationLable,
    ratioValue: '',
  },
  {
    kpiName: KPIApiNames.Socialverses,
    name: AnalyticsBlockStrings.SocialVerses,
    apiName: KpiAnalyticsApiNames.SocialVerses,
    value: 0,
    tooltipText: AnalyticsTooltipText.SocialVersesTooltip,
    ratioLable: AnalyticsRationLabels.Block4RationLable,
    ratioValue: '',
  },
  {
    kpiName: KPIApiNames.PublishedVideos,
    name: AnalyticsBlockStrings.ClipsOnWebsite,
    apiName: KpiAnalyticsApiNames.LiveSocialVerseVideos,
    value: 0,
    tooltipText: AnalyticsTooltipText.LiveVideosTooltip,
    ratioLable: AnalyticsRationLabels.Block5RationLable,
    ratioValue: '',
  },
];

export const webInitialAnalyticsTopGolfData: AnalyticsBlockModel[] = [
  {
    icon: <SeoAnalyticsIcon />,
    kpiName: KPIApiNames.visitors,
    name: AnalyticsBlockStrings.Visitors,
    value: 0,
    tooltipText: 'The number of user that reach the home screen',
  },
  {
    icon: <UserIconAnalytics />,
    kpiName: KPIApiNames.users,
    name: AnalyticsBlockStrings.Users,
    value: 0,
    ratioValue: '',
    ratioLable: AnalyticRationLabelsTopgolf.Block2RationLable,
    tooltipText: 'The number of users that opt-in and accept the Terms of Service',
  },
  {
    icon: <SharesIconAnalytics />,
    kpiName: KPIApiNames.shares,
    name: AnalyticsBlockStrings.Shares,
    value: 0,
    ratioValue: '',
    ratioLable: AnalyticRationLabelsTopgolf.Block3RationLable,
    tooltipText: 'The cumulative number of shares created by the users',
  },
  {
    icon: <ViewsIcon />,
    kpiName: KPIApiNames.videoViews,
    name: AnalyticsBlockStrings.Views,
    value: 0,
    ratioValue: '',
    ratioLable: AnalyticRationLabelsTopgolf.Block4RationLable,
    tooltipText: 'The total number of times the shared video link page is seen when shared',
  },
  {
    icon: <CTAIcon />,
    kpiName: KPIApiNames.ctaClicks,
    name: AnalyticsBlockStrings.Clicks,
    value: 0,
    ratioValue: '',
    ratioLable: AnalyticRationLabelsTopgolf.Block5RationLable,
    tooltipText: AnalyticsTooltipText.ToolTipsCTA,
  },
  {
    icon: <CTAIcon />,
    kpiName: KPIApiNames.ConvertedReferrals,
    name: AnalyticsBlockStrings.Conversions,
    value: 0,
    tooltipText: AnalyticsTooltipText.ToolTipsConversions,
    ratioLable: AnalyticsRationLabels.Block5RationLable,
    ratioValue: '',
  },
];

export const webInitialAnalyticsSalesData: AnalyticsBlockModel[] = [
  {
    icon: <UserIconAnalytics />,
    kpiName: KPIApiNames.Heroes,
    name: AnalyticsBlockStrings.Users,
    value: 0,
    tooltipText: AnalyticsTooltipText.Block1Tooltip,
  },
  {
    icon: <VideosIconAnalytics />,
    kpiName: KPIApiNames.Videos,
    name: AnalyticsBlockStrings.Videos,
    value: 0,
    tooltipText: AnalyticsTooltipText.Block2Tooltip,
    ratioLable: AnalyticsRationLabels.Block2RationLable,
    ratioValue: '',
  },
  {
    icon: <SharesIconAnalytics />,
    kpiName: KPIApiNames.shares,
    name: AnalyticsBlockStrings.Shares,
    value: 0,
    tooltipText: AnalyticsTooltipText.ToolTipsShares,
    ratioLable: AnalyticsRationLabels.Block3RationLable,
    ratioValue: '',
  },
  {
    icon: <ViewsIcon />,
    kpiName: KPIApiNames.VideoViews,
    name: AnalyticsBlockStrings.Views,
    value: 0,
    tooltipText: AnalyticsTooltipText.Block4Tooltip,
    ratioLable: AnalyticsRationLabels.Block4RationLable,
    ratioValue: '',
  },
  {
    icon: <CTAIcon />,
    kpiName: KPIApiNames.CTAClicks,
    name: AnalyticsBlockStrings.Clicks,
    value: 0,
    tooltipText: AnalyticsTooltipText.ToolTipsCTA,
    ratioLable: AnalyticsRationLabels.Block5RationLable,
    ratioValue: '',
  },
];

export const webInitialAnalyticsTBDData: AnalyticsBlockModel = {
  icon: <EqualizerIcon />,
  kpiName: KPIApiNames.HeroesValue,
  name: DashboardStrings.HeroesValue,
  value: DashboardStrings.ComingSoon,
  tooltipText: 'Tooltip Text',
};

export const getAnalyticsData = (
  apiAnalyticsData: KPIApiModel | VenueApiModelWithStats,
  initialAnalyticsData: AnalyticsBlockModel[] | SharesAnalyticsBlockModel[],
) => {
  return initialAnalyticsData.map(
    (analyticsData: AnalyticsBlockModel | SharesAnalyticsBlockModel) => {
      return {
        ...analyticsData,
        //@ts-ignore
        value: numberWithCommas(apiAnalyticsData[analyticsData.kpiName]),
      };
    },
  );
};

export const getAnalyticsTBDData = (
  initialAnalyticsTBDData: AnalyticsBlockModel,
  value: number,
) => {
  return { ...initialAnalyticsTBDData, value };
};
