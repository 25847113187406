// import { TeamSettingsStrings } from './../../common/localization/en';
import { createSlice } from '@reduxjs/toolkit';
// import { deleteVenueManager } from './venueManagerSlice';

export interface Alert {
  title: string | null;
  message: string | null;
  status: string | null;
}

interface AlertInitialState {
  alerts: Alert[];
}

const initialState: AlertInitialState = {
  alerts: [],
};

export const AlertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.alerts.push({
        title: action.payload.title,
        message: action.payload.message,
        status: action.payload.status,
      });
    },
  },
  //caused toast alert duplication

  // extraReducers: (reducersBuilder) => {
  //   reducersBuilder.addCase(deleteVenueManager.fulfilled, (state) => {
  //     state.alerts.push({
  //       title: TeamSettingsStrings.ToastTitle,
  //       message: TeamSettingsStrings.Deleted,
  //       status: null,
  //     });
  //   });
  //   reducersBuilder.addCase(deleteVenueManager.rejected, (state) => {
  //     state.alerts.push({
  //       title: TeamSettingsStrings.ToastTitle,
  //       status: 'error',
  //       message: TeamSettingsStrings.ErrorDeleting,
  //     });
  //   });
  // },
});

export const { createAlert } = AlertSlice.actions;

export default AlertSlice.reducer;
