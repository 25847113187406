import { RewardStrings } from '../../common/localization/en';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Activity,
  CampaignActivitiesTypes,
  CampaignsApiModel,
  CustomerActivitiesType,
} from '../../api/models/campaigns';
import { activitiesForMarketing } from '../../common/constants/defaults';
import { TransactionRecord } from '../../api/models/transaction';
import { DiscountType } from '../../pages/QuickStart/Rewards/RewardTypeCustomizationSection/RewardTypeCustomizationSection.helper';
import {
  CTA_DEFAULT_TEXT,
  CTA_DEFAULT_URL,
  DEFAULT_DISCOUNT_VAL,
} from '../../common/constants/constants';
import { UserApiModel } from '../../api/models/users';
import { MessageFrequency, RewardFulfillmentMethod } from '../../api/models/rewardFulfillment';
import { TableDTO } from '../storeModels';
import {
  DEFAULT_REWARD_HEADER,
  HowToRewardCreators,
} from '../../pages/NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { VideoStyleTypes } from '../../pages/NewCampaign/VideoStyleTypesSwitch/VideoStyleTypesSwitch';
import { replaceMessageBodyWithLocalVariables } from '../../common/components/SMS/SMS.helper';
import { defaultVideos } from '../../pages/NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import { isUWMClient } from '../../utils/isUWMClient';
import { RewardTypeOptions } from '../../pages/NewCampaign/NewCampaign';

export interface InitialState {
  campaignName: string;
  customerActivityType: CustomerActivitiesType;
  activities: Activity[];
  SMSMethod: RewardFulfillmentMethod;
  activeInviteTabIndex: number;
  campaignUsers: TableDTO<TransactionRecord | UserApiModel>;
  campaignUsersFilteredItems: Array<TransactionRecord | UserApiModel>;
  isNullReward: boolean;
  smsInviteMessage: string;
  smsRewardMessage: string;
  emailRequired: boolean;
  isPreferred: boolean;
  rewardCardUrl: string;
  inviteMediaUrl: string;
  isDeselectModeActive: boolean;
  isSelectAllButtonVisible: boolean;
  campaignObjective: CustomerActivitiesType;
  showWelcomeVideo: boolean;
  welcomeVideoUrl?: string;
  isLocked: boolean;
  recommendedVideoStyle: string;
  howToRewardCreators: HowToRewardCreators;
  isEditMode: boolean;
  isEditDataLoaded: boolean;
  cardEnabled: boolean;
  visitedAccordions: string[];
  ctaButtonText: string;
  ctaButtonLinkUrl: string;
  uploadedWelcomeVideoUrl?: string;
  messageFrequency: MessageFrequency;
  value: RewardTypeOptions | null;
  isError: boolean;
}

export interface SetActivityStatus {
  activityType: CampaignActivitiesTypes;
  status: boolean;
}

export interface SetActivityPoints {
  activityType: CampaignActivitiesTypes;
  points: number;
}

let initialState: InitialState = {
  campaignName: RewardStrings.DefaultIncentiveCampaignName,
  customerActivityType: CustomerActivitiesType.VideoUpload,
  campaignObjective: CustomerActivitiesType.VideoUpload,
  activities: activitiesForMarketing,
  showWelcomeVideo: true,
  SMSMethod: RewardFulfillmentMethod.AUTOMATIC_SMS,
  activeInviteTabIndex: 0,
  isLocked: false,
  isPreferred: false,
  emailRequired: false,
  campaignUsers: {
    items: [],
    page: 0,
    isLoaded: true,
    isLoading: false,
    lastUpdated: '',
    totalItems: 0,
    totalPages: 0,
    size: 0,
    sort: [],
    error: false,
  },
  campaignUsersFilteredItems: [],
  isNullReward: false,
  smsInviteMessage: '',
  smsRewardMessage: '',
  rewardCardUrl: '',
  inviteMediaUrl: '',
  isDeselectModeActive: false,
  isSelectAllButtonVisible: true,
  ctaButtonText: CTA_DEFAULT_TEXT,
  ctaButtonLinkUrl: CTA_DEFAULT_URL,
  howToRewardCreators: {
    cardColor: '',
    rewardHeader: DEFAULT_REWARD_HEADER,
    rewardName: '',
    contact: '',
    contactMethod: '',
    discountType: DiscountType.Amount,
    value: DEFAULT_DISCOUNT_VAL,
    dataForPreview: {
      value: DEFAULT_DISCOUNT_VAL,
      discountType: DiscountType.Amount,
    },
  },

  recommendedVideoStyle: VideoStyleTypes.Inspirational,
  welcomeVideoUrl: defaultVideos.default,
  isEditMode: false,
  isEditDataLoaded: false,
  cardEnabled: true,
  visitedAccordions: [],
  messageFrequency: MessageFrequency.FIRST,
  value: null,
  isError: false,
};

const UWMInitialState = {
  campaignName: 'My Borrowers',
  cardEnabled: false,
  isNullReward: true,
  ctaButtonText: 'Check us out',
  ctaButtonLinkUrl: 'https://socialvenu.com',
  campaignObjective: CustomerActivitiesType.VideoUpload,
  smsInviteMessage:
    "[Customer Name]\n\nThank you for working with [Company Name]. We loved working with you and we're hoping you would be willing to leave a video review we can share with other potential borrowers. Tap to get started ",
  rewardDescription: 'For creating a video',
  messageFrequency: MessageFrequency.FIRST,
  smsRewardMessage:
    '[Customer Name]\n\nThank you for your video review and for working with [Company Name].',
  recommendedVideoStyle: 'inspirational',
  welcomeVideoUrl: defaultVideos.defaultUwm,
};

if (isUWMClient()) {
  initialState = {
    ...initialState,
    ...UWMInitialState,
  };
}

const createCampaignSlice = createSlice({
  name: 'createCampaign',
  initialState,
  reducers: {
    setCampaignName(state, action: PayloadAction<string>) {
      state.campaignName = action.payload;
    },
    setCustomerActivityType(state, action: PayloadAction<CustomerActivitiesType>) {
      state.customerActivityType = action.payload;
    },
    setActivities(state, action: PayloadAction<Activity[]>) {
      state.activities = action.payload;
    },
    setCampaignObjective(state, action: PayloadAction<CustomerActivitiesType>) {
      state.campaignObjective = action.payload;
    },
    setRecommendedVideoStyle(state, action: PayloadAction<string>) {
      state.recommendedVideoStyle = action.payload;
    },
    setActivityStatus(state, action: PayloadAction<SetActivityStatus>) {
      const updateIndex = state.activities.findIndex(
        (activity) => activity.activityType === action.payload.activityType,
      );
      state.activities[updateIndex].status = action.payload.status;
    },

    setActivityPoints(state, action: PayloadAction<SetActivityPoints>) {
      const updateIndex = state.activities.findIndex(
        (activity) => activity.activityType === action.payload.activityType,
      );
      state.activities[updateIndex].points = action.payload.points;
    },
    setSMSMethod(state, action: PayloadAction<RewardFulfillmentMethod>) {
      state.SMSMethod = action.payload;
    },
    setActiveInviteTabIndex(state, action: PayloadAction<number>) {
      state.activeInviteTabIndex = action.payload;
    },
    addCampaignUsers(state, action: PayloadAction<Array<TransactionRecord | UserApiModel>>) {
      state.campaignUsers.items = [...state.campaignUsers.items, ...action.payload];
    },
    updateCampaignUserSearch(state, action: PayloadAction<string>) {
      const inputArray = [...state.campaignUsers.items, ...state.campaignUsersFilteredItems];
      state.campaignUsersFilteredItems = [];
      state.campaignUsers.items = [];

      inputArray.forEach((item) => {
        let fullName = '';

        if ('name' in item && item.name) {
          fullName = item.name;
        } else if ('firstName' in item && 'lastName' in item) {
          fullName = `${item.firstName} ${item.lastName}`;
        }

        if (fullName.toLowerCase().includes(action.payload.toLowerCase())) {
          state.campaignUsers.items.push(item);
        } else {
          state.campaignUsersFilteredItems.push(item);
        }
      });
    },
    deleteCampaignUser(state, action: PayloadAction<string>) {
      state.campaignUsers.items = state.campaignUsers.items.filter(
        (item) => item.id !== action.payload,
      );
    },
    removeAllExistingUsers(state) {
      state.campaignUsers.items = state.campaignUsers.items.filter(
        (item) => (item as TransactionRecord).isManuallyAdded,
      );
    },
    setSmsInviteMessage(state, action: PayloadAction<InitialState['smsInviteMessage']>) {
      state.smsInviteMessage = action.payload;
    },
    setSmsRewardMessage(state, action: PayloadAction<InitialState['smsRewardMessage']>) {
      state.smsRewardMessage = action.payload;
    },
    setRewardCardUrl(state, action: PayloadAction<InitialState['rewardCardUrl']>) {
      state.rewardCardUrl = action.payload;
    },
    setInviteMediaUrl(state, action: PayloadAction<InitialState['inviteMediaUrl']>) {
      state.inviteMediaUrl = action.payload;
    },
    setIsDeselectModeActive(state, action: PayloadAction<boolean>) {
      state.isDeselectModeActive = action.payload;
    },
    setIsSelectAllButtonVisible(state, action: PayloadAction<boolean>) {
      state.isSelectAllButtonVisible = action.payload;
    },
    setRewardCardColor(state, action: PayloadAction<string>) {
      state.howToRewardCreators.cardColor = action.payload;
    },
    setRewardName(state, action: PayloadAction<string>) {
      state.howToRewardCreators.rewardName = action.payload;
    },
    setRewardHeader(state, action: PayloadAction<string>) {
      state.howToRewardCreators.rewardHeader = action.payload;
    },
    setRewardContact(state, action: PayloadAction<string>) {
      state.howToRewardCreators.contact = action.payload;
    },
    setCtaText(state, action: PayloadAction<string>) {
      state.ctaButtonText = action.payload;
    },
    setCtaUrl(state, action: PayloadAction<string>) {
      state.ctaButtonLinkUrl = action.payload;
    },
    setIsLocked(state, action: PayloadAction<boolean>) {
      state.isLocked = action.payload;
    },
    setRequireEmail(state, action: PayloadAction<boolean>) {
      state.emailRequired = action.payload;
    },
    setIsPreferred(state, action: PayloadAction<boolean>) {
      state.isPreferred = action.payload;
    },
    resetNewCampaign() {
      return initialState;
    },
    toggleIsNullReward(state, action: PayloadAction<boolean>) {
      state.isNullReward = action.payload;
    },
    setValue(state, action: PayloadAction<RewardTypeOptions>) {
      state.value = action.payload;
    },
    setEditCampaign(
      state,
      action: PayloadAction<{ campaign: CampaignsApiModel; isHealthCare: boolean }>,
    ) {
      const {
        name,
        isNullReward,
        rewardHeader,
        rewardTitle,
        cardStyle,
        fulfillmentConfig,
        invitationMsgConfig,
        imageUrl,
        cardEnabled,
        contact,
        contactMethod,
        emailRequired,
        isMultiFulfillment,
      } = action.payload.campaign;

      state.campaignName = name;
      state.isNullReward = isNullReward;
      state.emailRequired = emailRequired || false;

      if (!isNullReward) {
        state.howToRewardCreators.rewardHeader = rewardHeader;
        if (cardEnabled && !rewardHeader) {
          state.howToRewardCreators.rewardHeader = DEFAULT_REWARD_HEADER;
        }

        state.howToRewardCreators.rewardName = rewardTitle || '';
        state.howToRewardCreators.cardColor = cardStyle;
        state.howToRewardCreators.contact = contact;
        state.howToRewardCreators.contactMethod = contactMethod;
      }
      state.SMSMethod =
        fulfillmentConfig?.method ||
        (isNullReward ? RewardFulfillmentMethod.AUTOMATIC_SMS : RewardFulfillmentMethod.MANUAL_SMS);

      state.smsRewardMessage = replaceMessageBodyWithLocalVariables(
        fulfillmentConfig?.sms.body || '',
        action.payload.isHealthCare,
        true,
      );
      state.smsInviteMessage = replaceMessageBodyWithLocalVariables(
        invitationMsgConfig?.body || '',
        action.payload.isHealthCare,
        true,
      );
      state.inviteMediaUrl = invitationMsgConfig?.mediaUrl || '';
      state.isEditMode = true;
      state.isEditDataLoaded = true;
      state.cardEnabled = cardEnabled || false;
      state.rewardCardUrl = imageUrl || '';
      state.messageFrequency = isMultiFulfillment ? MessageFrequency.EVERY : MessageFrequency.FIRST;
    },
    setContactMethod(state, { payload }: PayloadAction<string>) {
      state.howToRewardCreators.contactMethod = payload;
    },
    setWelcomeVideoToggle(state, { payload }: PayloadAction<boolean>) {
      state.showWelcomeVideo = payload;
    },
    setWelcomeVideoUrl(state, { payload }: PayloadAction<string | undefined>) {
      state.welcomeVideoUrl = payload;
    },
    visitAccordion(state, { payload }: PayloadAction<string>) {
      state.visitedAccordions = [...state.visitedAccordions, payload];
    },
    setUploadedWelcomeVideoUrl(state, { payload }: PayloadAction<string | undefined>) {
      state.uploadedWelcomeVideoUrl = payload;
    },
    setMessageFrequency(state, { payload }: PayloadAction<MessageFrequency>) {
      state.messageFrequency = payload;
    },
    setIsError(state, { payload }: PayloadAction<boolean>) {
      state.isError = payload;
    },
  },
});

export const {
  setCampaignName,
  setCustomerActivityType,
  setActivityStatus,
  setActivityPoints,
  setActiveInviteTabIndex,
  addCampaignUsers,
  deleteCampaignUser,
  setSMSMethod,
  setSmsInviteMessage,
  setSmsRewardMessage,
  setRewardCardUrl,
  setActivities,
  resetNewCampaign,
  removeAllExistingUsers,
  setIsDeselectModeActive,
  setIsSelectAllButtonVisible,
  setCampaignObjective,
  setRecommendedVideoStyle,
  updateCampaignUserSearch,
  setRewardCardColor,
  setRewardName,
  setRewardHeader,
  setRewardContact,
  toggleIsNullReward,
  setIsLocked,
  setEditCampaign,
  setContactMethod,
  setInviteMediaUrl,
  setRequireEmail,
  setIsPreferred,
  setWelcomeVideoToggle,
  setWelcomeVideoUrl,
  visitAccordion,
  setCtaText,
  setCtaUrl,
  setUploadedWelcomeVideoUrl,
  setMessageFrequency,
  setValue,
  setIsError,
} = createCampaignSlice.actions;
export default createCampaignSlice.reducer;
