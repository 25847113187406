import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useStyles } from './CallToAction.styles';
import { ErrorsStrings, NewCampaignStrings } from '../../../common/localization/en';
import { TextFieldComponent } from '../../../common/components/TextField/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { urlValidationMask } from '../../../common/validation/commonValidation';
import { setCtaText, setCtaUrl } from '../../../store/slices/createCampaignSlice';
import { CTA_MAX_LENGTH } from '../../../common/constants/constants';

export const ctaValidationSchema = Yup.object().shape({
  ctaButtonText: Yup.string()
    .required(ErrorsStrings.FieldIsRequired)
    .min(3, ErrorsStrings.Minlength3characters)
    .max(CTA_MAX_LENGTH, ErrorsStrings.Maxlength30characters),
  ctaButtonLinkUrl: Yup.string()
    .matches(urlValidationMask, ErrorsStrings.URLIsNotValid)
    .required(ErrorsStrings.FieldIsRequired),
});

export const CallToAction: FC = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { ctaButtonText, ctaButtonLinkUrl } = useTypedSelector((state) => state.createCampaign);
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const defaultStoryValues = venueProperties?.['webapp.default-story-details'];

  const { values, errors, handleChange } = useFormik({
    initialValues: {
      ctaButtonText: defaultStoryValues?.ctaBtnText || ctaButtonText,
      ctaButtonLinkUrl:
        defaultStoryValues?.ctaBtnLinkUrl?.replace('https://', '') || ctaButtonLinkUrl,
    },
    enableReinitialize: false,
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: ctaValidationSchema,
    onSubmit: () => {},
  });

  return (
    <Box className={styles.container}>
      <Typography className={styles.label}>{NewCampaignStrings.ButtonTitle}</Typography>
      <TextFieldComponent
        name="ctaButtonText"
        fullWidth={true}
        value={values.ctaButtonText}
        label={NewCampaignStrings.ButtonTitle}
        errorText={errors.ctaButtonText}
        onChange={(event) => {
          handleChange(event);
          dispatch(setCtaText(event.target.value));
        }}
        helperText={true}
        characterLimit={CTA_MAX_LENGTH}
        placeholder=""
      />
      <Typography className={styles.label} mt="20px">
        {NewCampaignStrings.CtaDestination}
      </Typography>
      <Box className={styles.linkFieldWrapper}>
        <Box className={styles.httpsText}> {NewCampaignStrings.Https}</Box>
        <TextFieldComponent
          name="ctaButtonLinkUrl"
          fullWidth={true}
          value={values.ctaButtonLinkUrl}
          errorText={errors.ctaButtonLinkUrl}
          inputStyle={{
            paddingLeft: '74px',
          }}
          helperText={true}
          onChange={(event) => {
            handleChange(event);
            dispatch(setCtaUrl(event.target.value));
          }}
        />
      </Box>
    </Box>
  );
};
