import { SeoAnalyticsIcon } from '../../../../common/assets/newDesign/SEOAnalyticsIcon';
import { UserIconAnalytics } from '../../../../common/assets/newDesign/UserIconAnalytics';
import { VideosIconAnalytics } from '../../../../common/assets/newDesign/VideosIconAnalytics';
import { ViewsIcon } from '../../../../common/assets/newDesign/ViewsIcon';
import { CTAIcon } from '../../../../common/assets/newDesign/CTAIcon';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { theme } from '../../../../theme';
import { SuperAdminPerformance } from '../../../../common/localization/en';

export interface AnalyticsBlockModel {
  icon?: React.ReactNode;
  kpiName: string;
  name: string;
  value: number | string;
  tooltipText: string;
  ratioValue?: string;
  ratioLable?: string;
}

export const venuesInitialAnalyticsData: AnalyticsBlockModel[] = [
  {
    icon: <SeoAnalyticsIcon />,
    kpiName: 'test',
    name: SuperAdminPerformance.Block1,
    value: 0,
    tooltipText: SuperAdminPerformance.Block1Tooltip,
  },
  {
    icon: <UserIconAnalytics />,
    kpiName: 'test',
    name: SuperAdminPerformance.Block2,
    value: 0,
    tooltipText: SuperAdminPerformance.Block2Tooltip,
  },
  {
    icon: <VideosIconAnalytics />,
    kpiName: 'test',
    name: SuperAdminPerformance.Block3,
    value: 0,
    tooltipText: SuperAdminPerformance.Block3Tooltip,
  },
  {
    icon: <ViewsIcon />,
    kpiName: 'test',
    name: SuperAdminPerformance.Block34,
    value: 0,
    tooltipText: SuperAdminPerformance.Block34Tooltip,
  },
  {
    icon: <CTAIcon />,
    kpiName: 'test',
    name: SuperAdminPerformance.Block4,
    value: 0,
    tooltipText: SuperAdminPerformance.Block4Tooltip,
  },
  {
    icon: (
      <EqualizerIcon
        stroke={theme.palette.primary.light}
        fill={theme.palette.primary.light}
        color="primary"
        style={{
          backgroundColor: theme.palette.primary.light,
          width: '50px',
          height: '50px',
          borderRadius: '25px',
          border: '1px solid',
          borderColor: 'rgba(0,0,0,0.1)',
        }}
      />
    ),
    kpiName: 'test',
    name: SuperAdminPerformance.Block5,
    value: 0,
    tooltipText: SuperAdminPerformance.Block5Tooltip,
  },
];
