import { FC } from 'react';
import { FormikProps } from 'formik';
import { useStyles } from './CardsPrevies.styles';
import { Box, Typography } from '@mui/material';
import { CaptionIcon } from '../../../../../common/assets/CaptionIcon';
import { PlayIconView } from '../../../SocialVerseCard/BlockList';
import { FormikValues } from '../../SocialVerseSettingsPage';
import { ArrowSharpIcon } from '../../../../../common/assets/newDesign/ArrowSharpIcon';
import { SocialVerseStrings } from '../../../../../common/localization/en';
import { previewCards } from './CardPreview.helpers';
import { useTypedSelector } from '../../../../../store';
import tinycolor from 'tinycolor2';

interface Props {
  formik: FormikProps<FormikValues>;
  showPlayButtons?: boolean;
}

export const CardsPreview: FC<Props> = ({ formik, showPlayButtons }) => {
  const styles = useStyles();

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>
        {formik.values.showTitle && formik.values.title}
      </Typography>
      {formik.values.showVideoCounter && (
        <Typography
          className={styles.videoCounter}
          style={{ color: formik.values.withGlow ? formik.values.glowColor : venueBrandColor }}
        >
          25 Videos
        </Typography>
      )}
      <Box className={styles.navigationWrapper}>
        <Box
          className={styles.navigation}
          sx={{
            transform: 'rotate(-180deg)',
            backgroundColor: '#D0D5DD',
            '& svg path': {
              stroke: 'rgba(71, 84, 103, 0.5)',
            },
          }}
        >
          <ArrowSharpIcon />
        </Box>
        <Typography className={styles.navText}>{SocialVerseStrings.NavigationGroupText}</Typography>
        <Box
          className={styles.navigation}
          sx={{
            backgroundColor: formik.values.withGlow ? formik.values?.glowColor : venueBrandColor,
            '& svg path': {
              stroke: tinycolor(formik.values.glowColor).isDark() ? 'white' : '#475467',
            },
          }}
        >
          <ArrowSharpIcon />
        </Box>
      </Box>
      <Box className={styles.cardsWrapper}>
        {previewCards.map((card, index) => (
          <div className={styles.card} key={index}>
            <div className={styles.cardGradient}></div>
            <Box className={styles.detailsWrapper}>
              {formik.values.showUserInfo && (
                <Typography className={styles.name}>{card.name}</Typography>
              )}
              <Box className={styles.infoWrapper}>
                <Typography className={styles.time}>0:23</Typography>
                {formik.values.captionEnabled && <CaptionIcon />}
              </Box>
              {showPlayButtons && <PlayIconView />}
            </Box>
            <img src={card.card} alt="preview card" />
          </div>
        ))}
      </Box>
    </Box>
  );
};
