import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  inviteWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '300px',
    boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px -6px 12px -4px rgba(16, 24, 40, 0.10)',
    backgroundColor: '#FFF',
    padding: '54px 25px 35px 25px',
    animation: `$show 500ms ${theme.transitions.easing.easeInOut}`,
  },
  close: {
    position: 'absolute',
    top: '26px',
    right: '26px',
    width: '24px',
    height: '24px',
    color: '#667085',
    cursor: 'pointer',
  },
  inviteTitle: {
    fontSize: '18px',
    color: '#101828',
    fontWeight: 600,
  },
  inviteInfo: {
    fontSize: '14px',
    color: '#475467',
    marginTop: '2px',
  },
  optionsWrapper: {
    display: 'flex',
    gap: '18px',
    width: '100%',
    marginTop: '16px',
  },
  optionTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#344054',
    marginBottom: '6px',
  },
  option: {
    flexBasis: '50%',
  },
  inviteButton: {
    marginTop: '20px',
    height: '44px',
  },

  '@keyframes show': {
    '0%': {
      bottom: '-300px',
    },
    '100%': {
      bottom: 0,
    },
  },
}));
