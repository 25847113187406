import { FC } from 'react';

export const DesktopIcon: FC = () => {
  return (
    <svg width="58" height="34" viewBox="0 0 58 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_8050_145141)">
        <rect x="4" width="50" height="26" rx="13" fill="white" shapeRendering="crispEdges" />
        <path
          d="M24.3667 4.66797H33.6251C36.5917 4.66797 37.3334 5.40964 37.3334 8.36797V13.643C37.3334 16.6096 36.5917 17.343 33.6334 17.343H24.3667C21.4084 17.3513 20.6667 16.6096 20.6667 13.6513V8.36797C20.6667 5.40964 21.4084 4.66797 24.3667 4.66797Z"
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29 17.3516V21.3349"
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.6667 13.832H37.3334"
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M25.25 21.332H32.75"
          stroke="#292D32"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8050_145141"
          x="0"
          y="0"
          width="58"
          height="34"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8050_145141" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8050_145141"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
