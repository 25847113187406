import {
  CreatorFilterAttribute,
  SearchOperationType,
} from '../../../api/models/searchFilterModels';

export const getCountInitinialValues = (attributes: CreatorFilterAttribute[]) => {
  console.log('attributes', attributes);
  const greaterThanAttribute = attributes.find(
    (attribute) => attribute.operation === SearchOperationType.GreaterThan,
  );

  const lessThanAttribute = attributes.find(
    (attribute) => attribute.operation === SearchOperationType.LessThan,
  );

  return {
    greaterThan: greaterThanAttribute ? (greaterThanAttribute.value as number) + 1 : 0,
    lessThan: lessThanAttribute ? (lessThanAttribute.value as number) - 1 : -1,
  };
};
