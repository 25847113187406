import { Box, Typography } from '@mui/material';
import React from 'react';
import RewardCardPreview from '../../../../../../../common/components/RewardCardPreview/RewardCardPreview';
import { useTypedSelector } from '../../../../../../../store';
import { MessageBox } from '../../../../../../../common/components/IPhonePreview/IPhoneSMSPreview';
import {
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../../common/components/SMS/SMS.helper';
import { useClientType } from '../../../../../../../services/hooks/useClientType';
import { useRewardMessagePreviewStyles } from './RewardMessagePreview.styles';

interface RewardMessagePreviewProps {
  title?: string;
}

export const RewardMessagePreview: React.FC<RewardMessagePreviewProps> = ({
  title = 'What it looks like:',
}) => {
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);

  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);

  const { isHealthCare } = useClientType();

  const classes = useRewardMessagePreviewStyles();

  if (!currentCampaign) {
    return null;
  }

  const {
    isNullReward,
    cardStyle,
    contact,
    rewardTitle,
    rewardHeader,
    contactMethod,
  } = currentCampaign;

  const rewardMessage = replaceMessageBodyWithLocalVariables(
    currentCampaign?.fulfillmentConfig?.sms?.body || '',
    isHealthCare,
    true,
  );

  return (
    <Box>
      <Typography className={classes.title}>{title}</Typography>
      <Box className={classes.container}>
        <Box className={classes.innerContainer}>
          {!isNullReward && (
            <RewardCardPreview
              cardColor={cardStyle}
              contact={contact}
              rewardTitle={rewardTitle || ''}
              rewardHeader={rewardHeader}
              logo={logo}
              contactMethod={contactMethod}
              scaleFactor={0.6}
            />
          )}

          <MessageBox
            width="291px"
            fontSize={15}
            message={rewardMessage}
            boldSubstrings={Object.values(LocalMessageVariables)}
            showStartBlock={false}
          />
        </Box>
      </Box>
    </Box>
  );
};
