import { FC, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { TopBar } from '../../common/components/topBar/TopBar';
import { QuickStartStrings, SocialVerseStrings } from '../../common/localization/en';
import { SVButton } from './CommonComponents/CommonComponents';
import { useSocialVerseStyles } from './SocialVerses.style';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../common/constants/routes';
import { SocialVerseCard } from './SocialVerseCard/SocialVerseCard';
import { Add } from '@mui/icons-material';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  deleteSocialVerseById,
  getAllSocialVerse,
  getSocialVerseById,
  GetVideosBySocialVerseId,
} from '../../store/slices/socialVerseSlice';
import { useToasts } from 'react-toast-notifications';
import { SpinnerContainer } from '../../common/components/table/CommonTable.style';
import { Spinner } from '../../common/assets/Spinner';
import { SVModalDelete } from './SVModals/SVModalDelete';
import { DeleteIconCircle } from '../../common/assets/newDesign/SocialVerse/DeleteIconCircle';
import { CreateSocialvModal } from './SVModals/CreateSocialvModal';
import { NameSocialvModal } from './SVModals/NameSocialvModal';
import { TypeSocialVerse } from '../../common/constants/constants';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../common/constants/events/socialVerseEvents';
import { NoSocialVerses } from './NoSocialVerses';
import { getVideos } from '../../store/slices/videosSlice';
import SocialVerseLayoutSwitch, {
  LayoutType,
} from './SocialVerseLayoutSwitch/SocialVerseLayoutSwitch';
import Select, { SelectOption } from '../../common/components/Select/Select';
import { SocialVerseAllApiModel } from '../../api/models/socialVerse';
import { useClientType } from '../../services/hooks/useClientType';
import { useQuery } from '../../hooks/useQuery';
import { useQuickStartLaunchPad } from '../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import AnimatedQsMarker, {
  QSStep,
} from '../../common/components/AnimatedQsMarker/AnimatedQsMarker';

export const SocialVersePage: FC = () => {
  const history = useHistory();
  const { addToast } = useToasts();

  const { trackEvent } = useTrackEvent();

  const dispatch = useAppDispatch();
  const classes = useSocialVerseStyles();
  const query = useQuery();
  const showLiveSocialverces = query.get('live') === 'true';

  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { socialVerseAll: socialVerseCards, isLoading } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );
  const { totalItems } = useTypedSelector((state) => state.videos);
  const [showPopUpDelete, setShowPopUpDelete] = useState({
    id: '',
    isOpen: false,
  });
  const { isHealthCare } = useClientType();
  const { isQSMarkerVisible, hideQSMarker, moveToNextItem } = useQuickStartLaunchPad();

  const [isCreateSocialvModalOpen, setIsCreateSocialvModalOpen] = useState(false);
  const [isNameSocialvModalOpen, setIsNameSocialvModalOpen] = useState(false);
  const [newSocialvViewMode, setNewSocialvViewMode] = useState(TypeSocialVerse.Cards);
  const [activeLayout, setActiveLayout] = useState(LayoutType.List);

  const [sortBy, setSortBy] = useState('updatedDesc');
  const [filterBy, setFilterBy] = useState(showLiveSocialverces ? 'showcased' : 'none');

  const handleSortByChange = (value: string) => {
    setSortBy(value);
    trackEvent(SocialVerseEventNames.changeSortBy, { value: value });
  };

  const handleFilterByChange = (value: string) => {
    setFilterBy(value);
    trackEvent(SocialVerseEventNames.changeFilterBy, { value: value });
  };

  useEffect(() => {
    trackEvent(SocialVerseEventNames.socialVersePageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    venueId && dispatch(getAllSocialVerse({ accountId: venueId, withVideos: true }));
  }, [venueId, dispatch]);

  useEffect(() => {
    if (venueId) {
      dispatch(
        getVideos({
          accountId: venueId,
          pageable: { page: 0, size: 50 },
          search: '',
        }),
      );
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    if (history.location.search === '?new') {
      handleCreateNewSV();
      history.replace(WebsiteRoutes.SocialVerse);
    }
  }, [history]);

  const handleCreateNewSV = () => {
    setTimeout(() => {
      setIsCreateSocialvModalOpen(true);
    }, 0);
  };

  const handleDeleteCard = () => {
    if (showPopUpDelete.id) {
      setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen, id: '' }));
      const deletedCard = socialVerseCards?.find((card) => {
        return card.id === showPopUpDelete.id;
      });
      dispatch(deleteSocialVerseById({ id: showPopUpDelete.id }))
        .then(() => {
          addToast(SocialVerseStrings.Deleted.replace('<Name>', deletedCard?.name || ''), {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        })
        .catch(() => {
          addToast(SocialVerseStrings.ErrorDeletingVideo, {
            appearance: 'error',
          });
        });
      toggleModal();
      trackEvent(SocialVerseEventNames.confirmDelete, { socialVerseId: showPopUpDelete.id });
    }
  };
  const canRender = () => {
    if (socialVerseCards) {
      return socialVerseCards.length > 0 ? true : false;
    }
  };
  const handleAddVideos = async (id: string) => {
    await Promise.race([
      dispatch(getSocialVerseById({ id })),
      dispatch(GetVideosBySocialVerseId({ id })),
    ]);
    history.push(`${WebsiteRoutes.SocialVerseAddVideos}/${id}`);
  };

  const onSelectSocialvViewMode = (viewMode: TypeSocialVerse) => {
    setNewSocialvViewMode(viewMode);
    setIsCreateSocialvModalOpen(false);
    setIsNameSocialvModalOpen(true);
  };

  const handleDeleteModalToggle = (id: string) => {
    setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen, id }));
  };
  const toggleModal = () => {
    setShowPopUpDelete((prev) => ({ ...prev, isOpen: !prev.isOpen }));
  };

  const handleLayoutChange = () => {
    if (activeLayout === LayoutType.List) {
      setActiveLayout(LayoutType.Grid);
      trackEvent(SocialVerseEventNames.changeSocialVerseLayout, { value: LayoutType.Grid });
    } else {
      setActiveLayout(LayoutType.List);
      trackEvent(SocialVerseEventNames.changeSocialVerseLayout, { value: LayoutType.List });
    }
  };

  const getSocialVerseSorting = (a: SocialVerseAllApiModel, b: SocialVerseAllApiModel) => {
    if (sortBy === 'updatedDesc') {
      return a.updatedAt > b.updatedAt ? -1 : 1;
    }

    if (sortBy === 'updatedAsc') {
      return a.updatedAt > b.updatedAt ? 1 : -1;
    }

    if (sortBy === 'createdDesc') {
      return a.createdAt > b.createdAt ? -1 : 1;
    }

    if (sortBy === 'createdAsc') {
      return a.createdAt > b.createdAt ? 1 : -1;
    }

    if (sortBy === 'videosDesc') {
      return a.numVideos > b.numVideos ? -1 : 1;
    }

    if (sortBy === 'videosAsc') {
      return a.numVideos > b.numVideos ? 1 : -1;
    }

    if (sortBy === 'nameDesc') {
      return a.name > b.name ? 1 : -1;
    }

    if (sortBy === 'nameAsc') {
      return a.name > b.name ? -1 : 1;
    }

    return 0;
  };

  const renderSocialVerseCards = () => {
    return (
      <Box
        className={classes.socialverseWrapper}
        style={{ display: activeLayout === LayoutType.List ? 'block' : 'grid' }}
      >
        {socialVerseCards &&
          socialVerseCards.length > 0 &&
          socialVerseCards
            .slice()
            .filter((sv) => {
              if (filterBy === 'showcased') {
                return sv.lastSeenLocationUrl !== null;
              }

              if (filterBy === 'notShowcased') {
                return sv.lastSeenLocationUrl === null;
              }

              if (filterBy !== 'none') {
                return sv.viewMode === filterBy;
              }

              return sv;
            })
            .sort(getSocialVerseSorting)
            .map((item, index) => (
              <SocialVerseCard
                key={item.id}
                socialverse={item}
                handleAddVideo={handleAddVideos}
                handleDeleteModalToggle={handleDeleteModalToggle}
                cardIndex={index}
                isGridLayout={activeLayout === LayoutType.Grid}
              />
            ))}
      </Box>
    );
  };

  const sortByValues: SelectOption<string>[] = [
    { value: 'updatedDesc', name: 'Date Updated (latest)' },
    { value: 'updatedAsc', name: 'Date Updated (earliest)', withBottomDivider: true },
    { value: 'createdDesc', name: 'Date Created (latest)' },
    { value: 'createdAsc', name: 'Date Created (earliest)', withBottomDivider: true },
    { value: 'videosDesc', name: 'Number of Clips (most)' },
    { value: 'videosAsc', name: 'Number of Clips (least)' },
    { value: 'nameDesc', name: 'Name (A to Z)' },
    { value: 'nameAsc', name: 'Name (Z to A)' },
  ];

  const filterByValues: SelectOption<string>[] = [
    { value: 'none', name: 'None', withBottomDivider: true },
    {
      value: 'showcased',
      name: 'Showcased on Website',
      disabled: !socialVerseCards?.find((sv) => sv.lastSeenLocationUrl !== null),
    },
    {
      value: 'notShowcased',
      name: 'Not Showcased',
      withBottomDivider: true,
      disabled: !socialVerseCards?.find((sv) => sv.lastSeenLocationUrl === null),
    },
    {
      value: TypeSocialVerse.Cards,
      name: 'Classic Cards',
      disabled: !socialVerseCards?.find((sv) => sv.viewMode === TypeSocialVerse.Cards),
    },
    {
      value: TypeSocialVerse.Perspective,
      name: 'Perspective',
      disabled: !socialVerseCards?.find((sv) => sv.viewMode === TypeSocialVerse.Perspective),
    },
    {
      value: TypeSocialVerse.Grid,
      name: 'Grids',
      disabled: !socialVerseCards?.find((sv) => sv.viewMode === TypeSocialVerse.Grid),
    },
    {
      value: TypeSocialVerse.Educational,
      name: 'Watch & Learn',
      disabled: !socialVerseCards?.find((sv) => sv.viewMode === TypeSocialVerse.Educational),
    },
    {
      value: TypeSocialVerse.Spheres,
      name: 'Spheres',
      disabled: !socialVerseCards?.find((sv) => sv.viewMode === TypeSocialVerse.Spheres),
    },
  ];

  const qsSteps: QSStep[] = [
    {
      title: QuickStartStrings.SvMultipleTitle,
      text: QuickStartStrings.SvMultipleText,
      onClick: () => {
        moveToNextItem({ skipTracking: true });
      },
      width: '278px',
      isLastStep: true,
    },
  ];

  return (
    <Grid container>
      <CreateSocialvModal
        isOpen={isCreateSocialvModalOpen}
        onClose={() => {
          setIsCreateSocialvModalOpen(false);
        }}
        onSelectSocialvViewMode={onSelectSocialvViewMode}
        totalItems={totalItems}
      />
      <NameSocialvModal
        isModalOpen={isNameSocialvModalOpen}
        onClose={() => {
          setIsNameSocialvModalOpen(false);
        }}
        venueId={venueId}
        viewMode={newSocialvViewMode}
        handleAddVideos={handleAddVideos}
      />
      <TopBar sectionTitle={SocialVerseStrings.SocialVerse} />
      <Grid container className={classes.contentWrapper}>
        {canRender() && (
          <Typography className={classes.showcaseTitle}>
            {isHealthCare
              ? SocialVerseStrings.ShowcaseTitleTrublu
              : SocialVerseStrings.ShowcaseTitle}
          </Typography>
        )}
        <Grid className={classes.headerWrapper}>
          {socialVerseCards && socialVerseCards?.length > 1 && (
            <>
              <Select
                width="253px"
                onChange={handleSortByChange}
                options={sortByValues}
                value={sortBy}
                margin="0 20px 0 0"
                title={SocialVerseStrings.SortBy}
              />
              <Select
                width="253px"
                onChange={handleFilterByChange}
                options={filterByValues}
                value={filterBy}
                title={SocialVerseStrings.FilterBy}
                margin="0 30px 0 0"
              />
              <SocialVerseLayoutSwitch
                activeLayout={activeLayout}
                onLayoutChange={handleLayoutChange}
              />
            </>
          )}

          {canRender() && (
            <Box className={classes.rightSectionWrapper}>
              <Box className={classes.svButtonWrapper}>
                {isQSMarkerVisible && (
                  <Box className={classes.qsMarker}>
                    <AnimatedQsMarker arrowPosition="right" direction="column" steps={qsSteps} />
                  </Box>
                )}
                <SVButton
                  className={classes.svButtonCreate}
                  startIcon={<Add />}
                  variant="contained"
                  handleClick={() => {
                    handleCreateNewSV();
                    trackEvent(SocialVerseEventNames.createNewSocialVerse);
                    if (isQSMarkerVisible) hideQSMarker();
                  }}
                  id="create-sv-button"
                >
                  {SocialVerseStrings.CreateButtonText}
                </SVButton>
              </Box>
            </Box>
          )}
        </Grid>
        {isLoading ? (
          <SpinnerContainer>
            <Spinner color="var(--spinnerColor)" />
          </SpinnerContainer>
        ) : canRender() ? (
          renderSocialVerseCards()
        ) : (
          <NoSocialVerses onSelectSocialvViewMode={onSelectSocialvViewMode} />
        )}
      </Grid>
      <SVModalDelete
        nameButton={SocialVerseStrings.Delete}
        openModal={showPopUpDelete.isOpen}
        toggleModal={toggleModal}
        onClickHandler={handleDeleteCard}
      >
        <DeleteIconCircle />
      </SVModalDelete>
    </Grid>
  );
};
