import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 24,
    lineHeight: 1.3333333333333333,
  },
  subheading: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
  },
  bottomControllers: {
    display: 'flex',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    flexDirection: 'row-reverse',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0px 10px 38px 11px rgba(10, 25, 107, 0.05)',
    boxSizing: 'border-box',
    padding: '24px 58px',
    zIndex: 100,
  },
  actionButton: {
    float: 'right',
    margin: '5px 2px',
    width: '120px',
  },
}));
