import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTooltipStyles = makeStyles((theme: Theme) => ({
  Icon: {
    padding: '2px 8px',
    borderRadius: '16px',
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: '500',
    width: 'fit-content',
    background: '#F3F2FF',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: '#667085',
    },
  },
}));
