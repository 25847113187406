import { FC, useEffect, useRef } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  fetchUserDetails,
  getCampaignUser,
  resetCampaignUser,
} from '../../../store/slices/userDetailsSlice';
import TransitionWrapper from '../TransitionWrapper/TransitionWrapper';
import { SalesDetailsContent } from './DetailsModalContent/SalesDetailsContent';
import { getCampaignById, getCampaigns } from '../../../store/slices/campaignsSlice';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';

interface SalesDetailsModalProps {
  storyId?: string;
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  campaignId?: string;
  entity?: 'heroes' | 'campaigns';
}
export const SalesDetailsModal: FC<SalesDetailsModalProps> = ({
  isOpen,
  handleClose,
  userId,
  handleOpenDeleteModal,
  handleOpenConfirmationModal,
  campaignId,
  entity,
}) => {
  const dispatch = useAppDispatch();
  const { lastUpdated } = useTypedSelector((state) => state.userDetails);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { currUserActiveCampaign } = useTypedSelector((state) => state.campaigns);

  const modal = useRef(null);

  const params = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }

    const campaignUserId = campaignId || currUserActiveCampaign?.id;

    if (campaignUserId && userId) {
      dispatch(getCampaignUser({ userId, campaignId: campaignUserId }));
    } else {
      dispatch(resetCampaignUser());
    }

    if (campaignId) {
      dispatch(getCampaignById(campaignId));
    }
  }, [userId, dispatch, lastUpdated, campaignId, currUserActiveCampaign?.id]);

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ accountId: venueId, pageable: { page: 0, size: 1000 } }));
    }
  }, [venueId, dispatch]);

  useEffect(() => {
    if (isOpen)
      trackEvent(
        entity === 'campaigns'
          ? RewardCampaignsEvents.RewardCampaignsDetails
          : CreatorsTableEventNames.CreatorDetailsModal,
        { selectedUserId: params?.id },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={modal}>
      <TransitionWrapper show={isOpen}>
        <SalesDetailsContent
          userId={userId}
          handleClose={handleClose}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          isOpen={isOpen}
          campaignId={campaignId}
          entity={entity}
        />
      </TransitionWrapper>
    </div>
  );
};
