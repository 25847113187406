import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 5.26257V9.20555C18 13.5986 14.8036 17.7397 10.4579 18.946C10.1526 19.018 9.82941 19.018 9.54209 18.946C7.42312 18.3698 5.55556 17.0735 4.24467 15.3991C2.82604 13.6347 2 11.4561 2 9.20555V5.26257C2 4.50638 2.55668 3.67817 3.23906 3.3901L8.2312 1.33758C9.36251 0.887472 10.6195 0.887472 11.7508 1.33758L13.5466 2.07577L16.7609 3.3901C17.4433 3.67817 18 4.50638 18 5.26257Z"
      fill="#0788F5"
    />
  </svg>
);

const Pending = memo(SvgComponent);
export default Pending;
