import { FC, useEffect, useState, useCallback, useRef, MouseEvent, KeyboardEvent } from 'react';
import {
  Button,
  Box,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useToasts } from 'react-toast-notifications';

import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  createNewUser,
  updateTable,
  updateSortOnInit,
  createNewUsersCSVFile,
} from '../../../../store/slices/heroesSlice';
import { CSVUploadModal } from './CSVUploadModal/CSVUploadModal';
import { CreateUserModal } from './CreateUserModal/CreateUserModal';
import { SettingsPageStrings } from '../../../../common/localization/en';
import { InitialValues } from './CreateUserModal/CreateUserModal.helper';
import { popupMenuList, useStyles } from './AddUsersButton.helper';
import { downloadUrl } from '../../../../services/utilities';
import { HttpStatusCode } from '../../../../api/HttpStatusCodes';

export const AddUsersButton: FC = () => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { isWebAppAvailable } = useTypedSelector((state) => state.venue);
  const { addToast } = useToasts();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isCSVModal, setIsCSVModal] = useState(false);
  const [isCreateUserModal, setIsCreateUserModal] = useState(false);
  const [isCreateUserError, setIsCreateUserError] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const prevOpen = useRef(open);
  const downloadTemplateUrl = `${process.env.REACT_APP_NEW_API_URL}/api/v1/users/import-template`;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | MouseEvent | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: KeyboardEvent) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const toggleCSVModal = useCallback(() => {
    setIsCSVModal(!isCSVModal);
  }, [isCSVModal]);

  const toggleCreateUserModal = useCallback(() => {
    setIsCreateUserModal(!isCreateUserModal);
  }, [isCreateUserModal]);

  const onDownloadTemplate = useCallback(() => {
    downloadUrl(downloadTemplateUrl, 'csv_template.csv');
  }, [downloadTemplateUrl]);

  const onUploadCSVFileHandler = useCallback(
    async (file: File) => {
      try {
        await createNewUsersCSVFile(file, JSON.stringify({ venueId, failIfAnyUserExists: true }));
        addToast(SettingsPageStrings.UsersCreated, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(updateTable());
        setIsCSVModal(false);
      } catch (error) {
        // @ts-ignore
        if (error.response.status === HttpStatusCode.BadRequest) {
          addToast(SettingsPageStrings.UserExists, { appearance: 'error' });
          return;
        }
        addToast(SettingsPageStrings.FileProblem, { appearance: 'error' });
      }
    },
    [addToast, dispatch, venueId],
  );

  const onCreateUserHandler = useCallback(
    async (values: InitialValues) => {
      const { name } = values;
      const [displayName] = name.split(' ');
      try {
        setIsCreateUserError(false);
        await createNewUser({
          accountId: venueId,
          user: { ...values, displayName },
          sendEmailInvitation: true,
        });
        addToast(SettingsPageStrings.UserCreated, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(updateSortOnInit());
        dispatch(updateTable());
        return true;
      } catch (error) {
        setIsCreateUserError(true);
        return false;
      }
    },
    [addToast, dispatch, venueId],
  );

  const onClickMenuItemHandler = (event: MouseEvent, callback: () => void) => {
    handleClose(event);
    callback();
  };

  return (
    <div>
      <CSVUploadModal
        isModal={isCSVModal}
        onClose={toggleCSVModal}
        onConfirm={onUploadCSVFileHandler}
      />
      <CreateUserModal
        isModal={isCreateUserModal}
        onClose={toggleCreateUserModal}
        onConfirm={onCreateUserHandler}
        error={isCreateUserError}
      />

      <Button
        color="primary"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.button}
        disabled={!isWebAppAvailable}
        startIcon={<GroupAddIcon />}
      >
        {SettingsPageStrings.AddUsersBtn}
      </Button>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {popupMenuList({
                    onUploadCSV: toggleCSVModal,
                    onAddCreateUser: toggleCreateUserModal,
                    onDownloadTemplate,
                  }).map((popupMenuItem) => (
                    <MenuItem
                      key={popupMenuItem.title}
                      className={classes.item}
                      onClick={(event) => onClickMenuItemHandler(event, popupMenuItem.onClick)}
                    >
                      <Box className={classes.itemIcon}>{popupMenuItem.icon}</Box>
                      <Box className={classes.itemTextWrapper}>
                        <Box className={classes.itemTitle}>{popupMenuItem.title}</Box>
                        <Box className={classes.itemDescription}>{popupMenuItem.description}</Box>
                      </Box>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
