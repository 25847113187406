import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2px 12px 2px 2px',
    background: 'rgba(221, 218, 255, 0.36)',
    borderRadius: '20px',
  },
  wrapper: {
    padding: '8px 6px',
    borderRadius: '25px',
  },
}));
