import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  inputsWrapper: {
    background: 'white',
  },
  content: {
    padding: '0',
  },
  title: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    textAlign: 'center',
    marginBottom: '4px',
    marginTop: '24px',
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#475467',
  },
  subTitleLink: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#4F96FF',
    textDecoration: 'underline',
  },
  importTable: {
    border: '1px solid #DCDBE4',
    borderRadius: '10px',
    marginTop: '16px',
  },
  headers: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DCDBE4',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelsHeader: {
    padding: '14px 20px 7px 20px',
    width: '100%',
  },
  values: {
    padding: '8px 20px 8px 20px',
    width: '100%',
    '&:not(:last-child)': {
      borderRight: '1px solid #DCDBE4',
    },
    minHeight: '38px',
  },
  stepper: {
    marginTop: '10px',
    marginBottom: '15px',
    padding: '0 72px',
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorTitle: {
    fontSize: '24px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  errorText: {
    fontSize: '15px',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  errorInfo: {
    fontSize: '15px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  tryAgain: {
    fontSize: '15px',
    fontWeight: 500,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
}));
