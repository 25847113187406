import { FC } from 'react';

export const DownloadIcon: FC = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.70508 11.3071V14.3553C2.70508 14.9076 3.15279 15.3553 3.70508 15.3553H15.4206C15.9729 15.3553 16.4206 14.9076 16.4206 14.3553V11.3071"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M6.20752 9.06787L8.85579 11.7161C9.24631 12.1067 9.87948 12.1067 10.27 11.7161L12.9183 9.06787"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M9.56323 3.64502V12.0608"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);
