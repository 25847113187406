import { FC } from 'react';
import { Grid } from '@mui/material';
import { ColorBox, ColorText, LogoBox } from '../Steps.style';
import { useTypedSelector } from '../../../../store';

export const ColorsAndLogoBlock: FC = () => {
  const program_data = useTypedSelector((state) => state.venueCreation.program_payload);
  return (
    <Grid item xs={12}>
      <Grid container alignItems="center" justifyContent="space-between" spacing={3}>
        <Grid item xs={4}>
          <LogoBox src={program_data.campaignLogoUrl} />
        </Grid>
        <Grid item xs={6}>
          <Grid container alignItems="center" justifyContent="center" spacing={3}>
            <Grid item xs={3}>
              <ColorBox color={program_data.campaignPrimaryColorHex} />
            </Grid>
            <Grid item xs={7}>
              <ColorText>{program_data.campaignPrimaryColorHex}</ColorText>
            </Grid>
            <Grid item xs={3}>
              <ColorBox color={program_data.campaignSecondaryColorHex} />
            </Grid>
            <Grid item xs={7}>
              <ColorText>{program_data.campaignSecondaryColorHex}</ColorText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
