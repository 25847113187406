import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '280px',
  },
  inputLabel: {
    backgroundColor: '#FFF',
    top: '-4px',
    fontSize: '14px',
  },
  dotIcon: {
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    marginRight: '12px',
  },
  iconWrapper: {
    height: '24px',
    marginBottom: '5px',
  },
}));
