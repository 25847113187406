import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { canEdit?: boolean; isListEmpty?: boolean }>(
  (theme: Theme) => ({
    root: {
      border: '1px solid #E1E5EE',
      borderRadius: '15px',
      display: 'flex',
      flexWrap: 'wrap',
      '& th': {
        '&:nth-child(2)': {
          textAlign: 'center',
        },
      },
      '& td': {
        borderRight: '1px solid #E1E5EE',
        '&:nth-child(2)>p': {
          textAlign: 'center',
          fontSize: '14px',
        },
      },
    },
    firstRow: {
      display: 'flex',
      backgroundColor: '#F5F6F8',
      color: '#8B89A0',
    },
    name: {
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      wordBreak: 'break-all',
    },
    role: {
      color: '#8B89A0',
      padding: '6px 16px',
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      backgroundColor: '#F3F3F5',
      borderRadius: '60px',
      width: 'min-content',
      margin: '0 auto',
      flexShrink: 0,
      flexGrow: 0,
    },
    tbody: {
      display: 'block',
      maxHeight: '500px',
      overflowY: (props) => (!props.isListEmpty ? 'hidden' : 'scroll'),

      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track': {
        marginBottom: '4px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main,
      },
    },
    selectedRow: {
      backgroundColor: '#D9D4FF',
    },
    row: {
      position: 'relative',
      display: 'flex',
    },
    removeIcon: {
      position: 'absolute',
      right: '30px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    ColumnHeader: {
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
    },
    ColumnDescription: {
      wordBreak: 'break-all',
      fontSize: '10px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
    },
    Cell: {
      width: (props) => (props.canEdit ? '20%' : '25%'),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);
