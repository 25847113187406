import { Box } from '@mui/material';
import React from 'react';
import { IPhonePreview } from './IPhonePreview';
import { ChatName } from './IPhoneSMSPreview';

interface IPhoneHalfPreviewProps {
  chatName?: string;
  height?: number;
}

export const IPhoneHalfPreview: React.FC<IPhoneHalfPreviewProps> = ({
  chatName = '+1 (833) 340-0304',
  height = 500,
  children,
}) => {
  return (
    <Box height={`${height}px`} overflow="hidden">
      <IPhonePreview
        background={{
          src: '/sms-iphone-background.png',
          topOffset: 58,
          bottomOffset: 70,
        }}
        width={418}
        height={860}
      >
        <Box display="flex" justifyContent="center" width="100%" height="24px">
          <ChatName fontSize={16}>{chatName}</ChatName>
        </Box>

        <Box
          position="relative"
          display="flex"
          flexDirection="column"
          pt="8px"
          mx="14px"
          overflow="hidden"
        >
          {children}
        </Box>
      </IPhonePreview>
    </Box>
  );
};
