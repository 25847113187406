import { TableContent, TableRow } from '../../commonTypes';
import { InviteSent } from './ManagerRows/InviteSent';
import { LastActivity } from './ManagerRows/LastActivityRow';
import { ManagerEmail } from './ManagerRows/ManagerEmail';
import { ManagerName } from './ManagerRows/ManagerName';
import { Status } from './ManagerRows/Status';
import { VenueManagerApiModel } from './../../../api/models/venueManagers';

export enum WorkspaceManagersStrings {
  ManagerName = 'Member Name',
  ManagerEmail = 'Member Email',
  LastActivity = 'Last Activity',
  InviteSent = 'Invite Sent',
  Status = 'Status',
  AddManager = 'Add Member',
  ResendInvite = 'Resend Invite',
  Active = 'Active',
  Inactive = 'Inactive',
}
//(id: string, status: boolean)
export interface ManagersContent extends TableRow {
  id: string;
  managerName: JSX.Element;
  managerEmail: JSX.Element;
  lastActivity?: JSX.Element;
  inviteSent?: JSX.Element;
}
interface ManagersMapperProps<T> {
  items: T[];
}
export const createWorkspaceManagers = ({
  items,
}: ManagersMapperProps<VenueManagerApiModel>): TableContent<ManagersContent> => {
  const tableContent: TableContent<ManagersContent> = {
    header: {
      managerName: {
        name: WorkspaceManagersStrings.ManagerName,
        sortable: true,
      },
      managerEmail: {
        name: WorkspaceManagersStrings.ManagerEmail,
        sortable: true,
      },
      lastActivity: {
        name: WorkspaceManagersStrings.LastActivity,
        sortable: true,
      },
      inviteSent: {
        name: WorkspaceManagersStrings.InviteSent,
        sortable: true,
      },
      status: {
        name: WorkspaceManagersStrings.Status,
        sortable: true,
      },
    },
    rows: [],
  };
  if (items.length === 0) {
    return tableContent;
  }
  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      managerName: <ManagerName item={item} />,
      managerEmail: <ManagerEmail item={item} />,
      lastActivity: <LastActivity item={item} />,
      inviteSent: <InviteSent item={item} />,
      status: <Status item={item} />,
    };
  });
  return tableContent;
};

export const itemsWithoutOwnId = (items: Array<VenueManagerApiModel>, id: string) => {
  return items.filter((item) => item.id !== id);
};
export const validateEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const sortItems = (items: VenueManagerApiModel[]) => {
  if (items.length) {
    return [...items].sort((a, b) => {
      return Date.parse(b.createdAt) - Date.parse(a.createdAt);
    });
  } else {
    return [];
  }
};
