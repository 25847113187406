import { Fragment } from 'react';
import { Grid } from '@mui/material';

import { useTypedSelector } from '../../../../store';
import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { TextLabel, TextValue } from './components.style';
import { SectionTitle } from '../Steps.style';
import { createItems } from './InformationBlock.helper';
import { getLocalDateString } from '../../../../services/utilities';

export const InformationBlock = () => {
  const {
    programId,
    programName,
    campaignLogoUrl,
    campaignLogoData,
    campaignId,
    campaignStartDate,
    campaignName,
  } = useTypedSelector((state) => state.venueCreation.program_payload);

  const items = createItems(
    programId,
    programName,
    campaignLogoUrl,
    campaignLogoData,
    campaignId,
    campaignStartDate,
    campaignName,
  );

  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <Fragment key={index}>
          <Grid item xs={6}>
            <TextLabel>{item.label}</TextLabel>
          </Grid>
          <Grid item xs={6}>
            <TextValue>{item.date ? getLocalDateString(item.value, '/') : item.value}</TextValue>
          </Grid>
        </Fragment>
      );
    });
  };

  return (
    <Grid container alignItems="center" justifyContent="center" spacing={1}>
      <Grid item xs={12}>
        <SectionTitle>{VenueWizardPreviewStepStrings.Information}</SectionTitle>
      </Grid>

      {renderItems()}
    </Grid>
  );
};
