import { FC } from 'react';

type iconProps = {
  color?: string;
};

export const DeleteBasketIcon: FC<iconProps> = ({ color }) => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.92969 3.76172H15.2384V16.8081C15.2384 17.9127 14.343 18.8081 13.2384 18.8081H4.92969C3.82512 18.8081 2.92969 17.9127 2.92969 16.8081V3.76172Z"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5752 3.76172H16.5925"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.89502 3.76092V2.19336C6.89502 1.64107 7.34273 1.19336 7.89502 1.19336H10.2734C10.8257 1.19336 11.2734 1.64107 11.2734 2.19336V3.76092"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M7.11255 8.41211L7.11255 14.1561"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
    <path
      d="M11.0837 8.41211L11.0837 14.1561"
      stroke={color || '#8B89A0'}
      strokeWidth="1.3"
      strokeLinecap="round"
    />
  </svg>
);
