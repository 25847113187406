import { FC } from 'react';
import { SVButton } from '../../CommonComponents/CommonComponents';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { SocialVerseAllApiModel, SocialVerseVideos } from '../../../../api/models/socialVerse';
import { setNewVideo } from '../../../../store/slices/socialVerseSlice';
import { PlusIcon } from '../../../../common/assets/newDesign/PlusIcon';
import { useStyles } from './AddVideoToSocialVerse.style';
import { TypeSocialVerse } from '../../../../common/constants/constants';
interface AddVideoTOSVProps {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id: string;
  buttonIndex?: number;
}
export const AddVideoToSocialVerse: FC<AddVideoTOSVProps> = ({ handleClick, id, buttonIndex }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { currentSocialVerseVideos, socialVerse } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  const isEducational = socialVerse?.viewMode === TypeSocialVerse.Educational;

  const card = socialVerse as SocialVerseAllApiModel;

  const onClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClick(event);
    dispatch(setNewVideo(true));
  };

  const getVideoPosition = (id: string) => {
    if (card) {
      return currentSocialVerseVideos
        ? currentSocialVerseVideos.findIndex((item: SocialVerseVideos) => item.videoId === id)
        : -1;
    } else {
      return -1;
    }
  };

  const video = currentSocialVerseVideos.find((v) => v.videoId === id);

  return (
    <SVButton
      sx={{
        marginLeft: '10px',
        fontSize: '13px !important',
        height: '32px',
        width: '56px',
      }}
      disabled={getVideoPosition(id) >= 0}
      variant="outlined"
      handleClick={onClickHandler}
      id={`add-video-button-${buttonIndex}`}
      className={classes.button}
    >
      {getVideoPosition(id) >= 0 && !isEducational ? video?.position : <PlusIcon />}
    </SVButton>
  );
};
