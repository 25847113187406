import { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { setSelectedVenue } from '../../../../../store/slices/venueSlice';

import { WebsiteRoutes } from '../../../../constants/routes';
import { useStylesWorkspace } from './Workspace.style';
import { WorkspaceContextMenu } from './WorkspaceContext';
import { UserRoles } from '../../../../constants/constants';
import { Box } from '@mui/material';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../../../constants/events/accountEvents';
import { useQuickStartLaunchPad } from '../../../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { AccountButtonCollapsedIcon } from '../../../../assets/AccountButtonCollapsedIcon';

interface Props {
  isButtonCollapsed?: boolean;
}

export const WorkspaceSection: FC<Props> = ({ isButtonCollapsed = false }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const { name, id } = useTypedSelector((state) => state.venue.venue);
  const { isStarted: isQuickstartFlow } = useQuickStartLaunchPad();

  const { role, accounts } = useTypedSelector((state) => state.me);
  const { pathname } = location;
  const isMultiAccount = accounts > 1;
  const isSocialVenueAdmin = role === UserRoles.admin;

  const accountsRoutes: string[] = [
    WebsiteRoutes.AccountsOverview,
    WebsiteRoutes.AccountsCompany,
    WebsiteRoutes.AccountsMembers,
    WebsiteRoutes.AccountsBilling,
    WebsiteRoutes.AccountsFAQVideos,
    WebsiteRoutes.AccountsUpdates,
    WebsiteRoutes.AccountsProfile,
    WebsiteRoutes.AccountsSmsTracking,
  ];

  const hideWorkSpaceSection =
    (isMultiAccount || isSocialVenueAdmin) &&
    (pathname === WebsiteRoutes.ProfileAccounts || pathname === WebsiteRoutes.ProfileProfile);

  const { venuesList } = useTypedSelector((state) => state.venue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isWorkspaceRoute = accountsRoutes.includes(location.pathname);

  const classes = useStylesWorkspace({ isActive: isWorkspaceRoute });

  if (hideWorkSpaceSection) {
    return <Box className={classes.placeholder} />;
  }

  if (!id) {
    return <Box className={classes.placeholder} />;
  }

  // const handleOpenContextMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (venueId: string) => {
    dispatch(setSelectedVenue({ id: venueId }));
    history.push(WebsiteRoutes.RewardCampaigns);
    handleClose();
  };
  return (
    <div className={classes.containerWorkspace}>
      {/* <span className={classes.section} /> */}
      <div
        onClick={() => {
          if (isQuickstartFlow) return;

          history.push(WebsiteRoutes.AccountsCompany);
          trackEvent(AccountEventNames.accountDetailsClicked);
        }}
        className={[classes.root, isWorkspaceRoute ? classes.active : ''].join(' ')}
        data-qs-button="false"
        style={{
          justifyContent: isButtonCollapsed ? 'center' : 'space-between',
          margin: isButtonCollapsed ? '0 10px 0 10px' : '0 15px 0 15px',
        }}
      >
        <Box
          className={classes.iconCollapsed}
          style={{
            opacity: isButtonCollapsed ? 1 : 0,
          }}
        >
          <AccountButtonCollapsedIcon />
        </Box>

        <Box
          style={{
            opacity: isButtonCollapsed ? 0 : 1,
          }}
          className={classes.buttonWrapper}
        >
          <p className={classes.title}>{name}</p>
          <p className={classes.subtitle}>Account Details</p>
        </Box>
      </div>

      <WorkspaceContextMenu
        activeVenueId={id}
        venuesList={venuesList}
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        handleClick={handleClick}
      />
    </div>
  );
};
