import { ManagerAccount } from '../../../../../../../store/storeModels';
import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export interface LabelValuePair {
  label: string | undefined;
  value: string;
}

export interface UsersPreviewProps {
  accounts: ManagerAccount[];
}
export const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontsize: '20px',
    lineHeight: '160%',
    color: theme.palette.text.primary,
  },
  textLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
  },
  textValue: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  userBox: {
    boxShadow: ' 0px 10px 40px -6px rgba(10, 25, 107, 0.15)',
    borderRadius: '8px',
    minHeight: '100px',
    padding: '10px',
  },
  blockTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '157%',
    color: theme.palette.primary.dark,
  },
}));
