import React from 'react';

const UnpublishedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1083 7.8916L7.8916 12.1083C7.34994 11.5666 7.0166 10.8249 7.0166 9.99993C7.0166 8.34993 8.34993 7.0166 9.99993 7.0166C10.8249 7.0166 11.5666 7.34994 12.1083 7.8916Z"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8499 4.80832C13.3915 3.70832 11.7249 3.10832 9.99987 3.10832C7.0582 3.10832 4.31654 4.84165 2.4082 7.84165C1.6582 9.01665 1.6582 10.9917 2.4082 12.1667C3.06654 13.2 3.8332 14.0917 4.66654 14.8083"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.0166 16.2751C7.9666 16.6751 8.97493 16.8918 9.99993 16.8918C12.9416 16.8918 15.6833 15.1585 17.5916 12.1585C18.3416 10.9835 18.3416 9.00848 17.5916 7.83348C17.3166 7.40015 17.0166 6.99181 16.7083 6.60848"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9253 10.5835C12.7087 11.7585 11.7503 12.7168 10.5753 12.9335"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.89163 12.1082L1.66663 18.3332"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 1.66667L12.1085 7.89167"
        stroke="#FFB515"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnpublishedIcon;
