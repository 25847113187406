import React from 'react';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { SocialVerseStrings } from '../../../common/localization/en';
import { ClassicIcon } from '../../../common/assets/newDesign/WebAppIcons/ClassicIcon';
import { GridIcon } from '../../../common/assets/newDesign/WebAppIcons/GridIcon';
import { WatchAndLearnIcon } from '../../../common/assets/newDesign/WebAppIcons/WatchAndLearnIcon';
import PerspectiveIcon from '../../../common/assets/newDesign/WebAppIcons/PerspectiveIcon';
import SpheresIcon2 from '../../../common/assets/newDesign/WebAppIcons/SpheresIcon2';

interface ICardTypes {
  id: TypeSocialVerse | string;
  icon: JSX.Element;
  title: SocialVerseStrings;
  description: SocialVerseStrings;
  button: string;
}

export const cardTypes: ICardTypes[] = [
  {
    id: TypeSocialVerse.Cards,
    icon: <ClassicIcon />,
    title: SocialVerseStrings.ClassicCards,
    description: SocialVerseStrings.ClassicCards_Description,
    button: '',
  },
  {
    id: TypeSocialVerse.Perspective,
    icon: <PerspectiveIcon />,
    title: SocialVerseStrings.PerspectiveCards,
    description: SocialVerseStrings.PerspectiveCards_Description,
    button: '',
  },
  {
    id: TypeSocialVerse.Grid,
    icon: <GridIcon />,
    title: SocialVerseStrings.Grid,
    description: SocialVerseStrings.Grid_Description,
    button: '',
  },
  {
    id: TypeSocialVerse.Educational,
    icon: <WatchAndLearnIcon />,
    title: SocialVerseStrings.Educational,
    description: SocialVerseStrings.Educational_Description,
    button: '',
  },
  {
    id: 'subtitle',
    icon: <></>,
    title: SocialVerseStrings.ModalSubtitle,
    description: SocialVerseStrings.Modal_Description,
    button: '',
  },
  {
    id: TypeSocialVerse.Spheres,
    icon: <SpheresIcon2 />,
    title: SocialVerseStrings.Spheres,
    description: SocialVerseStrings.Spheres_Description,
    button: '',
  },
];
