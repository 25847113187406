import * as Yup from 'yup';
import { ErrorsStrings, SubnavigationItems } from '../../../../common/localization/en';

export interface InitialValues {
  row1?: number;
  row2?: number;
  row3?: number;
  row4?: number;
}

export const initialValues: InitialValues = {
  row1: 0,
  row2: 0,
  row3: 0,
  row4: 0,
};

export const validationSchema = () =>
  Yup.object().shape({
    row1: Yup.number().min(0, ErrorsStrings.More0),
    row2: Yup.number().min(0, ErrorsStrings.More0),
    row3: Yup.number().min(0, ErrorsStrings.More0),
    row4: Yup.number().min(0, ErrorsStrings.More0),
  });

export interface ClientTypesFormFields {
  text: string;
  label: string;
  isAdornment: boolean;
}

export const salesFormFields: ClientTypesFormFields[] = [
  {
    text: SubnavigationItems.SalesStep1Row1,
    label: SubnavigationItems.SalesStep1Row1Label,
    isAdornment: false,
  },
  {
    text: SubnavigationItems.SalesStep1Row2,
    label: SubnavigationItems.SalesStep1Row2Label,
    isAdornment: true,
  },
  {
    text: SubnavigationItems.SalesStep1Row3,
    label: SubnavigationItems.SalesStep1Row3Label,
    isAdornment: false,
  },
  {
    text: SubnavigationItems.SalesStep1Row4,
    label: SubnavigationItems.SalesStep1Row4Label,
    isAdornment: false,
  },
];

export const fundraisingFormFields: ClientTypesFormFields[] = [
  {
    text: SubnavigationItems.FundraisingStep1Row1,
    label: SubnavigationItems.FundraisingStep1Row1Label,
    isAdornment: true,
  },
  {
    text: SubnavigationItems.FundraisingStep1Row2,
    label: SubnavigationItems.FundraisingStep1Row2Label,
    isAdornment: false,
  },
];

export const etsFormFields: ClientTypesFormFields[] = [
  {
    text: SubnavigationItems.EtsStep1Row1,
    label: SubnavigationItems.EtsStep1Row1Label,
    isAdornment: true,
  },
  {
    text: SubnavigationItems.EtsStep1Row2,
    label: SubnavigationItems.EtsStep1Row2Label,
    isAdornment: false,
  },
];

export const marketingFormFields: ClientTypesFormFields[] = [
  {
    text: SubnavigationItems.MarketingStep1Row1,
    label: SubnavigationItems.MarketingStep1Row1Label,
    isAdornment: true,
  },
  {
    text: SubnavigationItems.MarketingStep1Row2,
    label: SubnavigationItems.MarketingStep1Row2Label,
    isAdornment: true,
  },
];
