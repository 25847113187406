import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  leftSideDescription: {
    color: theme.palette.common.gray,
    paddingTop: '0px',
    paddingBottom: '14px',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.02em',
  },
  inputTitleWrapper: {
    margin: '32px 0 21px 0',
    display: 'flex',
    alignItems: 'center',
  },
  inputTitle: {
    fontSize: '18px',
    fontWeight: 500,
  },
  companyNameInfo: {
    fontSize: '14px',
    color: '#8B89A0',
    marginTop: '17px',
  },
}));
