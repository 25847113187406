import React, { FC } from 'react';
import { useStyles } from './DateRangeCalendar.styles';
import { DesktopDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';
import { PredefinedFilters, predefinedFilterLabels } from '../datePicker/DateRangeFilter.helper';
import { DateRangeFilterStrings } from '../../localization/en';
import { RangeFilter } from '../../../store/storeModels';

interface Props {
  dateRange: RangeFilter;
  chosenPredefinedFilter: PredefinedFilters | null;
  onChoosePredefinedFilter: (value: PredefinedFilters) => void;
  onSetDateValue: (value: Array<Date | null | undefined>) => void;
  onApplyRangeFilterChanges: () => void;
}

const DateRangeCalendar: FC<Props> = ({
  dateRange,
  chosenPredefinedFilter,
  onChoosePredefinedFilter,
  onSetDateValue,
  onApplyRangeFilterChanges,
}) => {
  const styles = useStyles();

  const now = new Date();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid spacing={0} container direction="row" height="100%">
        <Grid item>
          <Box className={styles.filterContainer} padding={2}>
            <List className={styles.listContainer}>
              {Object.keys(predefinedFilterLabels).map((item) => (
                <ListItem
                  className={`${styles.listItem} ${
                    chosenPredefinedFilter === item && styles.activeListItem
                  }`}
                  key={item as PredefinedFilters}
                  onClick={() => onChoosePredefinedFilter(item as PredefinedFilters)}
                  id={`${item}-date-range-button`}
                >
                  <ListItemText primary={predefinedFilterLabels[item as PredefinedFilters]} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        <Grid item /* md={1} */>
          <Divider className={styles.divider} orientation="vertical" flexItem />
        </Grid>
        <Grid item /* xs={12} md={8} */>
          <Grid container direction="column" className={styles.buttonsCalendarBlock}>
            <Grid item>
              <Box>
                <DesktopDateRangePicker
                  calendars={1}
                  open
                  reduceAnimations
                  value={[dateRange.from, dateRange.to]}
                  onChange={(newValue) => onSetDateValue(newValue)}
                  renderInput={() => <></>}
                  PaperProps={{ elevation: 0 }}
                  showDaysOutsideCurrentMonth
                  PopperProps={{
                    className: 'desktop-date-range-picker',
                    style: { position: 'static' },
                    disablePortal: true,
                    anchorEl: this,
                    placement: undefined,
                    popperOptions: { strategy: 'absolute' },
                  }}
                  maxDate={now}
                />
              </Box>
            </Grid>
            <Grid item>
              <Box padding={2} className={styles.buttonsContainer}>
                <Button
                  className={styles.button}
                  variant="contained"
                  color="primary"
                  onClick={onApplyRangeFilterChanges}
                  id="date-apply-filter-button"
                >
                  {DateRangeFilterStrings.ApplyShort}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default DateRangeCalendar;
