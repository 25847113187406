import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSVModalSmallStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    borderRadius: 30,
  },
  backDrop: {
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  wrapperEducationCards: {
    background: '#F5F6F8',
    height: '100%',
    padding: '90px 0 90px 0',
  },
  wrapperMainContent: {
    background: theme.palette.common.white,
    padding: 50,
    height: '100%',
  },
  body: {
    '&>h3': {
      color: theme.palette.common.black,
      paddingBottom: 25,
    },
    marginBottom: '50px',
  },
  modalText: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#8B89A0',
    
  },
  modalTitle: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.common.deepDark,
    lineHeight: '34px',
    marginBottom: '8px',
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    // height: 50,
    padding: '10px 30px',
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    maxWidth: '400px',
    width: '100%',
    borderRadius: 15,
    overflow: 'visible',
    textAlign: 'center',
  },
  iconWrapper: {
    
  },
}));
