import React from 'react';
import { Box, ClickAwayListener, Fade, IconButton, MenuItem } from '@mui/material';
import { LabelIcon } from '../../assets/LabelIcon';
import { HtmlTooltip } from '../table/Tooltip';
import { useModal } from '../../../hooks/useModal';

export interface MessageBuilderButton {
  title: string;
  value: string;
}

interface AddVariableButtonProps {
  buttons: MessageBuilderButton[];
  onSelect?: (button: MessageBuilderButton) => void;
}

export const AddVariableButton: React.FC<AddVariableButtonProps> = ({ buttons, onSelect }) => {
  const { close, isOpen, toggle } = useModal();

  return (
    <ClickAwayListener onClickAway={close}>
      <Box sx={{ position: 'relative' }}>
        <HtmlTooltip title="Personalize Tags" rounded="5px" padding="5px" light={true}>
          <IconButton
            onClick={toggle}
            aria-haspopup="true"
            aria-expanded={isOpen ? 'true' : undefined}
          >
            <LabelIcon />
          </IconButton>
        </HtmlTooltip>
        <Box sx={{ position: 'absolute', bottom: 40, pointerEvents: isOpen ? undefined : 'none' }}>
          <Fade in={isOpen}>
            <Box sx={{ bgcolor: 'white', borderRadius: '10px', border: `1px solid #e6e6e6` }}>
              {buttons.map((button) => (
                <MenuItem
                  sx={{
                    py: '10px',
                  }}
                  onClick={() => {
                    close();
                    onSelect?.(button);
                  }}
                >
                  {button.title}
                </MenuItem>
              ))}
            </Box>
          </Fade>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};
