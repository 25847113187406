import React from 'react';
import { theme } from '../../theme';

const RadioCheckedIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" fill={theme.palette.primary.main} />
      <rect x="0.5" y="1" width="19" height="19" rx="9.5" stroke="#7F56D9" />
      <path
        d="M14.6666 7L8.24992 13.4167L5.33325 10.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RadioCheckedIcon;
