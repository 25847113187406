import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M36.667 16.5H7.333c-1.012 0-1.833.82-1.833 1.833V22c0 1.012.82 1.833 1.833 1.833h29.334c1.012 0 1.833-.82 1.833-1.833v-3.667c0-1.012-.82-1.833-1.833-1.833ZM34.833 23.833H9.167c-1.013 0-1.834.82-1.834 1.833v11c0 1.013.821 1.834 1.834 1.834h25.666c1.013 0 1.834-.821 1.834-1.834v-11c0-1.012-.821-1.833-1.834-1.833Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M23.833 16.5H27.5A5.5 5.5 0 1 0 22 11v5.5h1.833ZM20.167 16.5H16.5A5.5 5.5 0 1 1 22 11v5.5h-1.833Z"
      stroke="currentColor"
      strokeWidth={2}
    />
    <path
      d="M22.354 26.646a.5.5 0 0 0-.708 0l-3.181 3.182a.5.5 0 1 0 .707.707L22 27.707l2.828 2.828a.5.5 0 1 0 .707-.707l-3.181-3.182ZM22.5 34v-7h-1v7h1Z"
      fill="currentColor"
    />
  </svg>
);

const AutomaticSMSIcon = memo(SvgComponent);
export default AutomaticSMSIcon;
