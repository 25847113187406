export const RewardIcon = () => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60889 1.57422H15.3945V4.28509V6.95194C15.3945 9.92081 12.974 12.3276 10.0017 12.3276C7.02332 12.3276 4.60889 9.92829 4.60889 6.94509V4.25807V1.57422Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60889 1.57422H15.3945V4.28509V6.95194C15.3945 9.92081 12.974 12.3276 10.0017 12.3276C7.02332 12.3276 4.60889 9.92829 4.60889 6.94509V4.25807V1.57422Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M10.0002 13.8311V17.4262" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M10.0002 13.8311V17.4262" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.40356 17.4258H13.5939" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M6.40356 17.4258H13.5939" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M15.3937 3.36621C15.3937 3.36621 17.6407 3.36621 18.0901 3.36621C18.5395 3.36621 18.9889 3.81426 18.9889 4.26232C18.9889 4.71037 19.4229 8.74284 14.929 8.74284"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
      <path
        d="M15.3937 3.36621C15.3937 3.36621 17.6407 3.36621 18.0901 3.36621C18.5395 3.36621 18.9889 3.81426 18.9889 4.26232C18.9889 4.71037 19.4229 8.74284 14.929 8.74284"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
      <path
        d="M4.60595 3.36621C4.60595 3.36621 2.35896 3.36621 1.90956 3.36621C1.46016 3.36621 1.01076 3.81426 1.01076 4.26232C1.01076 4.71037 0.584871 8.74284 5.07886 8.74284"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
      <path
        d="M4.60595 3.36621C4.60595 3.36621 2.35896 3.36621 1.90956 3.36621C1.46016 3.36621 1.01076 3.81426 1.01076 4.26232C1.01076 4.71037 0.584871 8.74284 5.07886 8.74284"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
    </svg>
  );
};
