import { FC } from 'react';

export const LeafIcon: FC = () => {
  return (
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.0851 7.95802C12.187 14.6459 6.05318 15.7447 3.47351 15.458C3.47351 12.0382 3.80353 8.60468 8.12331 4.37389C11.1113 1.44747 15.9202 0.759332 17.6588 1.06743C17.7989 3.32638 17.2356 5.35041 16.9365 6.08005L13.2591 8.15952L16.0851 7.95802Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1394 7.62268C8.24315 9.67437 3.76043 14.8222 1 19.0003"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
