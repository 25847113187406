import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './AdvancedTabContent.styles';
import { AccountsSearchStrings } from '../../../../localization/en';
import { RoundedButton } from '../../../Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  SearchOperationType,
  AccountStatusType,
  AccountAttributeName,
  AccountsCreatedAtFilterAttribute,
  AccountFilterAttribute,
  SetupCompletedFilterAttribute,
  TutorialCompletedFilterAttribute,
  PricingPlanFilterAttribute,
  AccountsMemberLastLoginAtFilterAttribute,
} from '../../../../../api/models/searchFilterModels';
import {
  removeAccountsDateFilter,
  removeAccountsLastLoginDateFilter,
  // resetTags,
  setActiveAccountFilterAttributes,
} from '../../../../../store/slices/searchFilterSlice';
import { DateRange, FilterDatePicker } from '../../../FilterDatePicker/FilterDatePicker';
import { getUTCDateString } from '../../../../../services/utilities';
import { PricingPlanFilterSelect } from '../../../PricingPlanFilterSelect/PricingPlanFilterSelect';
// import { setLoadMore } from '../../../../../store/slices/videosSlice';
import format from 'date-fns/format';
import { DEFAULT_START_DATE, backEndDateFormat } from '../../../../constants/constants';
import { AccountStatusFilterSelect } from '../../../AccountStatusFilterSelect/AccountStatusFilterSelect';

interface Props {
  onSearch: () => void;
}

export const AdvancedTabContent: FC<Props> = ({ onSearch }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [loginDateRange, setLoginDateRange] = useState<DateRange | null>(null);
  const [status, setStatus] = useState<AccountStatusType | null>(null);
  const [pricingPlan, setPircingPlan] = useState<string | null>('');
  const { activeAccountFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const handleSearch = () => {
    const filterAttributes: AccountFilterAttribute[] = [];

    if (dateRange) {
      const dateFrom = new Date(getUTCDateString(dateRange.from));
      const dateTo = new Date(getUTCDateString(dateRange.to));
      dateTo.setHours(23);
      dateTo.setMinutes(59);

      const fromAttribute: AccountsCreatedAtFilterAttribute = {
        name: AccountAttributeName.CreatedAt,
        value: dateFrom.toISOString(),
        operation: SearchOperationType.GreaterThan,
      };

      const toAttribute: AccountsCreatedAtFilterAttribute = {
        name: AccountAttributeName.CreatedAt,
        value: dateTo.toISOString(),
        operation: SearchOperationType.LessThan,
      };

      filterAttributes.push(fromAttribute, toAttribute);
    }

    if (loginDateRange) {
      const dateFrom = new Date(getUTCDateString(loginDateRange.from));
      const dateTo = new Date(getUTCDateString(loginDateRange.to));
      dateTo.setHours(23);
      dateTo.setMinutes(59);

      const fromAttribute: AccountsMemberLastLoginAtFilterAttribute = {
        name: AccountAttributeName.memberLastLoginAt,
        value: dateFrom.toISOString(),
        operation: SearchOperationType.GreaterThan,
      };

      const toAttribute: AccountsMemberLastLoginAtFilterAttribute = {
        name: AccountAttributeName.memberLastLoginAt,
        value: dateTo.toISOString(),
        operation: SearchOperationType.LessThan,
      };

      filterAttributes.push(fromAttribute, toAttribute);
    }

    if (pricingPlan) {
      const statusAttribute1: PricingPlanFilterAttribute = {
        name: AccountAttributeName.PlanId,
        operation: SearchOperationType.Equals,
        value: pricingPlan,
      };
      filterAttributes.push(statusAttribute1);
    }

    if (status === AccountStatusType.Created) {
      const statusAttribute1: SetupCompletedFilterAttribute = {
        name: AccountAttributeName.SetupCompleted,
        operation: SearchOperationType.Equals,
        value: false,
      };
      filterAttributes.push(statusAttribute1);
    }
    if (status === AccountStatusType.SetupCompleted) {
      const statusAttribute1: SetupCompletedFilterAttribute = {
        name: AccountAttributeName.SetupCompleted,
        operation: SearchOperationType.Equals,
        value: true,
      };
      const statusAttribute2: TutorialCompletedFilterAttribute = {
        name: AccountAttributeName.TutorialCompleted,
        operation: SearchOperationType.Equals,
        value: false,
      };
      filterAttributes.push(statusAttribute1);
      filterAttributes.push(statusAttribute2);
    }
    if (status === AccountStatusType.TutorialCompleted) {
      const statusAttribute1: SetupCompletedFilterAttribute = {
        name: AccountAttributeName.SetupCompleted,
        operation: SearchOperationType.Equals,
        value: true,
      };
      const statusAttribute2: TutorialCompletedFilterAttribute = {
        name: AccountAttributeName.TutorialCompleted,
        operation: SearchOperationType.Equals,
        value: true,
      };
      filterAttributes.push(statusAttribute1);
      filterAttributes.push(statusAttribute2);
    }
    // dispatch(resetTags()); ??
    dispatch(setActiveAccountFilterAttributes(filterAttributes));
    onSearch();
  };

  const fromInitialValue = activeAccountFilterAttributes.find(
    (attribute) =>
      attribute.name === AccountAttributeName.CreatedAt &&
      attribute.operation === SearchOperationType.GreaterThan,
  )?.value as string;

  const toInitialValue = activeAccountFilterAttributes.find(
    (attribute) =>
      attribute.name === AccountAttributeName.CreatedAt &&
      attribute.operation === SearchOperationType.LessThan,
  )?.value as string;

  const lastLoginfromInitialValue = activeAccountFilterAttributes.find(
    (attribute) =>
      attribute.name === AccountAttributeName.memberLastLoginAt &&
      attribute.operation === SearchOperationType.GreaterThan,
  )?.value as string;

  const lastLogintoInitialValue = activeAccountFilterAttributes.find(
    (attribute) =>
      attribute.name === AccountAttributeName.memberLastLoginAt &&
      attribute.operation === SearchOperationType.LessThan,
  )?.value as string;

  useEffect(() => {
    if (fromInitialValue && toInitialValue) {
      setDateRange({
        from: format(new Date(fromInitialValue), backEndDateFormat),
        to: format(new Date(toInitialValue), backEndDateFormat),
      });
    }
  }, [fromInitialValue, toInitialValue]);

  const getInitialDate = () => {
    return fromInitialValue && toInitialValue
      ? {
          from: new Date(fromInitialValue),
          to: new Date(toInitialValue),
        }
      : null;
  };

  const getLastLoginInitialDate = () => {
    return lastLoginfromInitialValue && lastLogintoInitialValue
      ? {
          from: new Date(lastLoginfromInitialValue),
          to: new Date(lastLogintoInitialValue),
        }
      : null;
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.selectTitle}>{AccountsSearchStrings.PricingPlan}</Typography>
      <Box className={classes.selectWrapper}>
        <PricingPlanFilterSelect
          onChange={(status) => {
            setPircingPlan(status);
          }}
        />
      </Box>
      <Typography className={classes.selectTitle}>{AccountsSearchStrings.Status}</Typography>
      <Box className={classes.selectWrapper}>
        <AccountStatusFilterSelect
          onChange={(status) => {
            setStatus(status);
          }}
        />
      </Box>
      <Typography className={classes.selectTitle}>{AccountsSearchStrings.CreationDate}</Typography>
      <Box className={classes.selectWrapper}>
        <FilterDatePicker
          updateRange={(range) => {
            setDateRange(range);
          }}
          width="100%"
          initialValue={getInitialDate()}
          onClear={() => {
            dispatch(removeAccountsDateFilter());
            setDateRange(null);
          }}
          anchorOrigin="right"
          beginingDate={DEFAULT_START_DATE}
          shouldClearLabel={true}
        />
      </Box>

      <Typography className={classes.selectTitle}>{AccountsSearchStrings.LastLoginDate}</Typography>
      <Box className={classes.selectWrapper}>
        <FilterDatePicker
          updateRange={(range) => {
            setLoginDateRange(range);
          }}
          width="100%"
          initialValue={getLastLoginInitialDate()}
          onClear={() => {
            dispatch(removeAccountsLastLoginDateFilter());
            setLoginDateRange(null);
          }}
          anchorOrigin="right"
          shouldClearLabel={true}
        />
      </Box>

      <Box className={classes.searchButtonWrapper}>
        <RoundedButton
          title={AccountsSearchStrings.Search}
          onClick={handleSearch}
          borderRadius="8px"
          fontSize="16px"
          fontWeight="600"
        />
      </Box>
    </Box>
  );
};
