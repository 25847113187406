export const DownloadIcon = ({ color }: { color?: string }) => {
  return (
    <>
      <svg
        width="10"
        height="14"
        viewBox="0 0 10 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.28571 8.44671V0.333374H5.71429V8.44671L8.27857 6.06004L9.28571 7.00004L5 11L0.714286 7.00004L1.72143 6.06004L4.28571 8.44671Z"
          fill={color || '#8F94B6'}
        />
        <path d="M10 13.6667V12.3334H0V13.6667H10Z" fill={color || '#8F94B6'} stroke="inherit" />
      </svg>
    </>
  );
};
