import { getApiUrlForId, MediaEndpoints } from '../api/endpoints';
import { httpClient } from '../services/httpClient/httpClient';
import { UploadUrlApiModel } from '../services/utilities';
import axios from 'axios';
import { useState } from 'react';
import { useVenue } from './useVenue';

interface UploadFileRequest {
  options: UploadUrlApiModel;
  file: File;
}

export const useUploadFileToStorage = () => {
  const [loading, setLoading] = useState(false);
  const { venueId, isLoaded: isVenueLoaded } = useVenue();
  /**
   * @param venueId
   * @param ext file extension (not mimetype)
   * @example .jpg
   */
  const createSignedUploadUrl = async (venueId: string, ext: string) => {
    try {
      return httpClient.post<undefined, UploadUrlApiModel>({
        url: getApiUrlForId(`${MediaEndpoints.CreateUploadSignedUrl}?ext=${ext}`, venueId),
        requiresToken: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFile = async ({ options, file }: UploadFileRequest) => {
    try {
      const res = await axios.put<typeof options, undefined>(options.url, file, {
        headers: options.headers,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadFileToStorage = async (file: File) => {
    if (!isVenueLoaded) {
      console.error('venue is not loaded');
      return;
    }

    const fileExtension = file.name.split('.').pop();
    if (!fileExtension) {
      return null;
    }

    const res = await createSignedUploadUrl(venueId, fileExtension);

    setLoading(true);
    if (res) {
      try {
        await uploadFile({ options: res, file });
      } catch (err) {
        console.error(err);
      }
    }
    setLoading(false);

    return res;
  };

  return {
    loading,
    uploadFileToStorage,
  };
};
