import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'fit-content',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
    padding: '12px 24px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    color: '#344054',
    fontWeight: 500,
    lineHeight: '20px',
  },
  button: {
    borderRadius: '8px',
    margin: '32px 24px 0 24px',
    width: '100%',
  },
}));
