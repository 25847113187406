import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  Box,
  PaletteColor,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';

interface AlertProps {
  type: NonNullable<MuiAlertProps['severity']>;
  title: string;
  description?: string;
}

export const Alert: React.FC<AlertProps> = ({ type, title, description, ...restProps }) => {
  const theme = useTheme();

  const activeColorPalette = useMemo<PaletteColor>(() => {
    return theme.palette[type];
  }, [theme.palette, type]);

  const darkColor = activeColorPalette.dark;
  const lightColor = `${activeColorPalette.main}22`; // with opacity

  return (
    <MuiAlert
      iconMapping={{
        info: <InfoIcon style={{ color: darkColor }} />,
        success: <CheckCircleIcon style={{ color: darkColor }} />,
        warning: <WarningIcon style={{ color: darkColor }} />,
        error: <ErrorIcon style={{ color: darkColor }} />,
      }}
      severity={type}
      sx={{
        borderRadius: '15px',
        p: '10px',
        pr: '40px',
        '.MuiAlert-message': {
          p: '0 !important',
        },
        '.MuiAlert-icon': {
          pt: '4px',
        },
        backgroundColor: lightColor,
      }}
      {...restProps}
    >
      <Box>
        <Typography fontSize="16px" fontWeight="500" lineHeight="28px" color={darkColor}>
          {title}
        </Typography>
        {description && (
          <Typography fontSize="11px" lineHeight="24px">
            {description}
          </Typography>
        )}
      </Box>
    </MuiAlert>
  );
};
