import { Box } from '@mui/material';
import React from 'react';
import { Chip } from '../../../common/components/Chip/Chip';

interface PreviewContainerProps {
  title?: string;
}

export const PreviewContainer: React.FC<PreviewContainerProps> = ({ title, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="720px"
      borderRadius="25px"
      bgcolor="white"
      boxShadow="0px 2px 12px rgba(0, 0, 0, 0.07)"
      pt="20px"
    >
      {title && <Chip title={title} mb="25px" />}

      <Box>{children}</Box>
    </Box>
  );
};
