import styled from 'styled-components';
import { ListItemIcon, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SubMenuWrapper = styled.div`
  margin: 0 8px;
`;

export const SubMenuArrowsWrapper = styled.div`
  width: 20px;
  text-align: center;
`;

export const SVListItem = styled(ListItem)`
  padding-right: 7px !important;
`;

export const SVListItemIcon = styled(ListItemIcon)`
  margin-left: 12px;
  margin-right: 8px;
`;

export const topGolfMenuUsersItemStyles = makeStyles(() => ({
  disabled: {
    opacity: '0.5 !important',
  },
}));
