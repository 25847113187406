import { makeStyles } from '@mui/styles';

export const useSignUpStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundColor: '#ffff',
    height: '100vh',
    overflowY: 'scroll',
    backgroundPosition: ' 0px -300px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    zoom: 1.1,
  },
}));
