import { Button } from '@mui/material';
import { useState, MouseEvent, FC } from 'react';
import { SearchOperations } from '../../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../../store';
import {
  removeSearchFilterAttribute,
  updateNewSearchFilterAttributes,
} from '../../../../../store/slices/searchFiltersSlice';
import { CTAIcon } from '../../../../assets/searchFilterIcons/CTAIcon';
import { SharesIcon } from '../../../../assets/searchFilterIcons/SharesIcon';
import { PointsIcon } from '../../../../assets/searchFilterIcons/PointsIcon';
import { RewardsIcon } from '../../../../assets/searchFilterIcons/RewardsIcon';
import { SimpleAccordion } from '../../../accordion/CustomAccordion';
import { CustomPopper, PopperPlacements } from '../../../Popper/Popper';
import { InLineStatItem } from '../InlineStatItem';
import { StatItem } from '../StatItem';
import { useStyles } from './Stats.helper';
import { ViewsIcon } from '../../../../assets/searchFilterIcons/ViewsIcon';

enum UserStatsFilterStrings {
  selectStats = 'Select Stats',
  subtitle = 'Select the stats you wish to filter by',
  buttonText = 'Add Remove Stats',
  enterRange = 'Enter a range to filter by for each stat',
}

export type statItemsIdType =
  | 'rewardsFulfilled'
  | 'shares'
  | 'videoCtaClicks'
  | 'totalPoints'
  | 'campaignPoints'
  | 'points'
  | 'videoViews';
enum statItemsIdEnum {
  rewardsFulfilled = 'rewardsFulfilled',
  totalPoints = 'totalPoints',
  videoCtaClicks = 'videoCtaClicks',
  shares = 'shares',
  campaignPoints = 'campaignPoints',
  points = 'points',
  videoViews = 'videoViews',
}
interface StatsItemsTypes {
  id: statItemsIdType;
  title: string;
  icon: JSX.Element;
}
export const statsItems: StatsItemsTypes[] = [
  {
    id: statItemsIdEnum.shares,
    title: 'Shares',
    icon: <SharesIcon />,
  },
  {
    id: statItemsIdEnum.videoCtaClicks,
    title: 'CTA Clicks',
    icon: <CTAIcon />,
  },
  {
    id: statItemsIdEnum.totalPoints,
    title: 'Total Points',
    icon: <PointsIcon />,
  },
  {
    id: statItemsIdEnum.rewardsFulfilled,
    title: 'Rewards',
    icon: <RewardsIcon />,
  },
  {
    id: statItemsIdEnum.campaignPoints,
    title: 'Campaign Points',
    icon: <PointsIcon />,
  },
  {
    id: statItemsIdEnum.points,
    title: 'Current Points',
    icon: <PointsIcon />,
  },
  {
    id: statItemsIdEnum.videoViews,
    title: 'Video Views',
    icon: <ViewsIcon />,
  },
];

interface CampaignStatsFilterProps {
  handleRemove: () => void;
}
export const CampaignStatsFilter: FC<CampaignStatsFilterProps> = ({ handleRemove }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();
  const [saved, setSaved] = useState(false);
  const [selected, setSelected] = useState<{ [key in statItemsIdEnum]: boolean }>({
    rewardsFulfilled: false,
    shares: false,
    videoCtaClicks: false,
    totalPoints: false,
    campaignPoints: false,
    points: false,
    videoViews: false,
  });
  const initialValue = {
    min: 1,
    max: 10,
  };
  const initialSharesValue = {
    min: 1,
    max: 1000,
  };
  const initialTotalPointsValue = {
    min: 1,
    max: 10000,
  };
  const [values, setValues] = useState<{ [key in statItemsIdEnum]: { min: number; max: number } }>({
    rewardsFulfilled: initialValue,
    shares: initialSharesValue,
    videoCtaClicks: initialValue,
    totalPoints: initialTotalPointsValue,
    campaignPoints: initialTotalPointsValue,
    points: initialTotalPointsValue,
    videoViews: initialTotalPointsValue,
  });
  const handleChangeValues = (id: string, newValue: { min: number; max: number }) => {
    if (newValue.min <= newValue.max) {
      dispatch(
        updateNewSearchFilterAttributes({
          name: id,
          operation: SearchOperations.IN_RANGE,
          value: { min: newValue.min, max: newValue.max },
        }),
      );
      setValues({
        ...values,
        [id]: {
          min: newValue.min,
          max: newValue.max,
        },
      });
    }
  };
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const placement = PopperPlacements.Left;
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleChange = (id: string) => {
    setSelected({
      ...selected,
      [id]: !selected[id as statItemsIdType],
    });
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const selectedIds = Object.keys(selected);
    const addedIds = selectedIds.filter((item) => selected[item as statItemsIdType]);
    const removedIds = selectedIds.filter((item) => !selected[item as statItemsIdType]);
    addedIds.forEach((id) => {
      dispatch(
        updateNewSearchFilterAttributes({
          name: id,
          operation: SearchOperations.IN_RANGE,
          value: { min: initialValue.min, max: values[id as statItemsIdType].max },
        }),
      );
    });
    removedIds.forEach((id) => {
      dispatch(removeSearchFilterAttribute(id));
    });

    setOpen(false);
    setSaved(true);
  };
  const renderStatsItems = (item: { id: string; title: string; icon: JSX.Element }) => {
    return (
      <div>
        <StatItem
          item={item}
          selected={selected[item.id as statItemsIdType]}
          handleChange={handleChange}
        />
      </div>
    );
  };
  return (
    <div>
      <>
        <CustomPopper open={open} anchorEl={anchorEl} placement={placement}>
          <div className={classes.statsPopupRoot}>
            <p className={classes.popupTitle}>{UserStatsFilterStrings.selectStats}</p>
            <div className={classes.statsWrapper}>
              {statsItems.map((item) => renderStatsItems(item))}
            </div>
            <div className={classes.actionButtonsWrapper}>
              <Button
                color="primary"
                variant="contained"
                className={classes.actionButton}
                onClick={handleSave}
                disabled={
                  !selected.rewardsFulfilled &&
                  !selected.totalPoints &&
                  !selected.videoCtaClicks &&
                  !selected.shares &&
                  !selected.campaignPoints &&
                  !selected.points &&
                  !selected.videoViews
                }
              >
                Save
              </Button>
              <Button
                color="primary"
                variant="outlined"
                className={classes.actionButton}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </CustomPopper>

        <SimpleAccordion title={UserStatsFilterStrings.selectStats} handleDelete={handleRemove}>
          {!saved ? (
            <div className={classes.root}>
              <p className={classes.subtitle}>{UserStatsFilterStrings.subtitle}</p>
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={handleClick}
              >
                {UserStatsFilterStrings.buttonText}
              </Button>
            </div>
          ) : (
            <div className={classes.root}>
              <p className={classes.subtitle}>{UserStatsFilterStrings.enterRange}</p>
              {Object.values(statItemsIdEnum).map((item) => {
                const [statItem] = statsItems.filter((temp) => temp.id === item);
                return selected[item] ? (
                  <InLineStatItem
                    item={statItem}
                    value={values[item]}
                    handleChangeValues={handleChangeValues}
                  />
                ) : null;
              })}
              <Button
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={handleClick}
              >
                {UserStatsFilterStrings.buttonText}
              </Button>
            </div>
          )}
        </SimpleAccordion>
      </>
    </div>
  );
};
