import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideosDetailPluginComponentStyles = makeStyles((theme: Theme) => ({
  Button: {
    borderRadius: '25px',
    width: 'fit-content',
    marginTop: '18px',
  },
  VideoPluginContainer: {
    flexGrow: 1,
  },
  TitleContainer: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: theme.palette.common.white,
  },
  Title: {
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    lineHeight: '28px',
  },
  AddPluginBtn: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
  },
  Paper: {
    textAlign: 'center',
    backgroundColor: '#F5F6F8',
    borderRadius: '16px',
    padding: 24,
    marginBottom: 20,
    marginRight: '20px',
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PluginBtn: {
    padding: '2px 8px',
    borderWidth: 1,
    borderColor: theme.palette.primary.light,
    borderRadius: '30px',
    backgroundColor: theme.palette.primary.light,
    marginRight: '5px',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  PluginList: {
    borderRadius: '20px',
    backgroundColor: '#F5F6F8',
    padding: '16px 24px 8px 24px',
  },
  moderatedWrapper: {
    padding: '40px 0',
  },
  moderatedTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.common.deepDark,
  },
  moderatedInfo: {
    fontSize: '12px',
    lineHeight: '22px',
    color: theme.palette.error.main,
  },
}));
