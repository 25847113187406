import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import ArrowRightSmall from '../../../common/assets/ArrowRightSmall';
import { useStyles } from './LeftSideWelcomeLayout.styles';

interface LeftSideLayoutProps {
  name: string;
  onGetStartedClick: () => void;
  onDoThisLaterClick: () => void;
}

export const LeftSideWelcomeLayout: FC<LeftSideLayoutProps> = ({
  name,
  onGetStartedClick,
  onDoThisLaterClick,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.container}>
        <Typography className={classes.welcomeText}>
          {OnboardingWelcomeStrings.PageTitle} <b>{name}</b>!
        </Typography>
        <Typography className={classes.title}>{OnboardingWelcomeStrings.Branding}</Typography>
        <Typography className={classes.description}>
          {OnboardingWelcomeStrings.PageDescription}
        </Typography>
      </Box>
      <Box className={classes.btnWrapper}>
        <RoundedButton
          title="Get Started"
          endIcon={<ArrowRightSmall />}
          fontWeight={400}
          className={classes.btn}
          onClick={onGetStartedClick}
        />
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};
