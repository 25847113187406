import { FC } from 'react';

type MonitorIconProps = {
  color?: string;
};

export const MonitorIcon: FC<MonitorIconProps> = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.36699 1.6665H14.6253C17.592 1.6665 18.3337 2.40817 18.3337 5.3665V10.6415C18.3337 13.6082 17.592 14.3415 14.6337 14.3415H5.36699C2.40866 14.3498 1.66699 13.6082 1.66699 10.6498V5.3665C1.66699 2.40817 2.40866 1.6665 5.36699 1.6665Z"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 14.3501V18.3334"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.66699 10.8335H18.3337"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 18.3335H13.75"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
