import { FC } from 'react';
import { theme } from '../../../theme';

export const ViewsIcon: FC = () => (
  <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_704_20303"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={50}
      height={50}
    >
      <circle cx={25} cy={25} r={25} fill={theme.palette.primary.main} />
    </mask>
    <g mask="url(#mask0_704_20303)">
      <circle opacity={0.1} cx={25} cy={25} r={25} fill={theme.palette.primary.main} />
      <path
        d="M30.0173 51.5566L30.0173 -1.55658"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.9827 51.5566L40.9827 -1.55658"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0173 51.5566L20.0173 -1.55658"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.01733 51.5566L9.01733 -1.55658"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 25C11.8134 19.0926 17.92 15 25 15C32.08 15 38.1866 19.0926 41 25C38.1866 30.9074 32.08 35 25 35C17.92 35 11.8134 30.9074 9 25Z"
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
      />
      <circle cx={25} cy={25} r={6.43826} fill="white" stroke="#1A1538" />
      <circle
        cx={25}
        cy={25.0002}
        r={3.27969}
        fill="#1A1538"
        stroke="white"
        strokeWidth={0.509407}
      />
    </g>
  </svg>
);
