import { FC } from 'react';

export const SubsectionIcon: FC = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 0C4.99 0 0.5 4.49 0.5 10C0.5 15.51 4.99 20 10.5 20C16.01 20 20.5 15.51 20.5 10C20.5 4.49 16.01 0 10.5 0ZM15.28 7.7L9.61 13.37C9.47 13.51 9.28 13.59 9.08 13.59C8.88 13.59 8.69 13.51 8.55 13.37L5.72 10.54C5.43 10.25 5.43 9.77 5.72 9.48C6.01 9.19 6.49 9.19 6.78 9.48L9.08 11.78L14.22 6.64C14.51 6.35 14.99 6.35 15.28 6.64C15.57 6.93 15.57 7.4 15.28 7.7Z"
      fill="white"
    />
  </svg>
);
