import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@material-ui/core';

export const useCreateSocialvModalStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '1235px',
    borderRadius: '16px',
    background: '#fff',
    padding: '27px 38px',
  },
  title: {
    fontSize: '18px',
    lineHeight: '30px',
    color: theme.palette.common.deepDark,
    letterSpacing: '0.36px',
    padding: '10px',
    textAlign: 'center',
  },
  cardsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    padding: '18px 10px 10px 10px',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '32px',
  },
  cardWrapper: {
    height: '280px',
    border: '1px solid #DCDBE4',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px',
  },
  iconWrapper: {
    '& svg': { transform: 'scale(1.5)' },
    minHeight: '50px',
    marginTop: '16px',
    marginBottom: '16px',
  },
  cardLeftSide: {
    width: '45%',
  },
  cardRightSide: {
    width: '55%',
  },
  cardTitleWrapper: {
    background: 'rgba(217, 212, 255, 0.30)',
  },
  divider: {
    height: '170px',
  },
  cardTitle: {
    fontSize: '24px',
    color: theme.palette.common.deepDark,
    textAlign: 'center',
    fontWeight: 600,
    lineHeight: '30px',
    width: '100%',
    marginBottom: '8px',
  },
  cardSubtitle: {
    color: theme.palette.common.gray,
    textAlign: 'center',
    fontSize: '16px',
    letterSpacing: '0.32px',
  },
  modalSubTitle: {
    fontSize: '22px',
    color: theme.palette.common.deepDark,
    textAlign: 'center',
    fontWeight: 600,
    lineHeight: '28px',
    width: '100%',
    marginBottom: '16px',
  },
  modalSubDescription: {
    fontSize: '14px',
    color: theme.palette.common.gray,
    textAlign: 'center',
    lineHeight: '24px',
    width: '100%',
  },
  cardBtn: {
    marginTop: '16px',
    height: '40px !important',
    // width: '220px !important',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)  !important',
    borderRadius: '50px !important',
    padding: '10px 18px !important',
    '& p': {
      fontSize: '16px !important',
      fontWeight: '600 !important',
      lineHeight: '24px !important',
    },
    '&:disabled': {
      backgroundColor: theme.palette.common.white,
      '& p': {
        color: theme.palette.common.deepDark,
      },
    },
  },
  pluginTitle: {
    marginLeft: '70px',
    marginBottom: '5px',
    color: '#000',
    fontSize: '12px',
    letterSpacing: '2%',
    lineHeight: '18px',
    fontWeight: '600',
  },
  createCardImage: {
    marginLeft: '30px',
    width: '530px',
  },
  dividerSpheres: {
    height: '140px',
  },
  spheresPreviewWrapper: {
    width: '50%',
  },
  spheresPreviewTitle: {
    fontSize: '13px',
    lineHeight: '26px',
    textAlign: 'center',
    fontStyle: 'italic',
    marginBottom: '5px',
  },
  spheresIndividual: {
    height: '110px',
    width: '110px',
    margin: '0 auto',
    borderRadius: '50%',
    boxShadow: '1px 3px 20px 0px rgb(0 0 0 / 70%)',
  },
  spheresGroup: {
    width: '166px',
    height: '120px',
    margin: '10px auto 0',
  },
  spheresIconWrapper: {
    position: 'relative',
    width: 'fit-content',
    margin: '0 auto',
  },

  iconPlay: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    paddingLeft: '5px',
    left: '12px',
    top: '0',
    width: '40px',
    height: '40px',
    backgroundColor: alpha('#dcc9c9', 0.2),
    backdropFilter: 'blur(3px)',
  },
}));
