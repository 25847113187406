import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    height: 202,
    backgroundColor: '#fff',
    borderWidth: 0,
    borderRadius: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
    marginTop: '20px',
    width: '100%',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467',
    lineHeight: '24px',
    marginTop: '5px',
    width: '470px',
    textAlign: 'center',
  },
  btn: {
    width: '290px',
    height: '44px',
    marginTop: '30px',
    borderRadius: '8px',
    padding: 0,
  },
}));
