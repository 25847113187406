import { FC } from 'react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { RewardsPageStrings } from '../../../../../common/localization/en';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme: Theme) => ({
  container: (props: { isActive: boolean }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px',
    background: props.isActive ? 'rgba(36, 185, 71, 0.1)' : '#8B89A01A',
    width: '84px',
    height: '28px',
  }),
  circle: (props: { isActive: boolean }) => {
    return {
      marginRight: 8,
      width: 8,
      height: 8,
      background: props.isActive ? '#54D2A5' : '#C7C7D9',
      borderRadius: '50%',
    };
  },
  text: (props: { isActive: boolean }) => {
    return {
      color: props.isActive ? '#24B947' : '#344054',
      fontSize: '12px',
      fontWeight: 400,
    };
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#667085',
    fontSize: '11px',
    fontWeight: 500,
    width: '125px',
    height: '42px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '1px 7px 24px -1px #0000001c',

    '& .MuiTooltip-arrow': {
      color: '#fff',
    },
  },
}));

interface CampaignStatusCellProps {
  isActive: boolean;
  hoverText?: string;
}

export const CampaignStatusCell: FC<CampaignStatusCellProps> = ({ isActive, hoverText }) => {
  const styles = useStyles({ isActive });
  const statusText = isActive
    ? RewardsPageStrings.CampaignsCellStatusActive
    : RewardsPageStrings.CampaignsCellStatusInActive;

  const statusComponent = (
    <Box className={styles.container}>
      <Box className={styles.text}>{statusText}</Box>
    </Box>
  );

  return (
    <Tooltip
      title={hoverText || ''}
      children={statusComponent}
      followCursor={true}
      arrow={true}
      placement="top"
      componentsProps={{ tooltip: { className: styles.tooltip } }}
    />
  );
};
