import { useEffect, useState } from 'react';

interface UseModalConfig {
  updateOnDefaultValueChange: boolean;
  value?: unknown;
}

export const useModal = (openByDefault = false, config?: UseModalConfig) => {
  const [isOpen, setIsOpen] = useState(openByDefault);
  const [modalValue, setModalValue] = useState(config?.value);

  useEffect(() => {
    if (!config?.updateOnDefaultValueChange) return;

    setIsOpen(openByDefault);
  }, [openByDefault, config?.updateOnDefaultValueChange]);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    isOpen,
    open,
    close,
    toggle,
    setIsOpen,
    setModalValue,
    modalValue,
  };
};
