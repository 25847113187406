import { ClientTypes } from '../../../api/models/common';
import { WebsiteRoutes } from '../../constants/routes';

export enum AppMode {
  development,
  production,
}

type ClientSocialv = {
  socialVerseId: string;
  clientType: ClientTypes;
};

interface PreviewDetails {
  [AppMode.development]: ClientSocialv[];
  [AppMode.production]?: ClientSocialv[];
  location: string;
  top?: string;
  left?: string;
}

export const previewOptions: Array<PreviewDetails> = [
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: '65cd1f2c-3b63-45c5-8089-66406653e325',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: 'dd20ab00-0dc8-4abe-860d-30f4389f8cd4',
  //     },
  //   ],
  //   location: '/settings/incentive/points',
  //   left: '42%',
  //   top: '110%',
  // },
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: '5101319a-a63a-4ed3-b1ad-6e6155349b55',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: 'ca993821-59c8-4b42-8afc-11081687655a',
  //     },
  //   ],
  //   location: '/settings/incentive/reward-sms',
  //   left: '42%',
  //   top: '110%',
  // },
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: '7458abed-6d96-442c-99e5-b18213de3644',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: '9dc0aed0-7eb3-4c58-8fe0-001d1d6a289a',
  //     },
  //   ],
  //   location: '/settings/incentive/reward-fulfillment',
  //   left: '42%',
  //   top: '110%',
  // },
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: 'd1b6c59f-fdee-4672-b566-7178c5fa0bfa',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: '728fd4d1-f17d-4a00-bfb8-cf9572237f1a',
  //     },
  //   ],
  //   location: WebsiteRoutes.BrandingStyleTab,
  //   left: '50%',
  //   top: '180%',
  // },
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: '9735ea16-8a84-4acb-b7c3-2b96aa2fb118',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: '89ec382a-209e-49b9-9350-010caf07ff0d',
  //     },
  //   ],
  //   location: WebsiteRoutes.SignUpTab,
  //   left: '50%',
  //   top: '180%',
  // },
  // {
  //   [AppMode.development]: [
  //     {
  //       clientType: ClientTypes.TRUBLU,
  //       socialVerseId: '31fe6c64-40a3-49a7-9a1e-71f530236034',
  //     },
  //     {
  //       clientType: ClientTypes.MARKETING,
  //       socialVerseId: '898c7c74-8fde-45ea-8fc9-05c41b7d0cd1',
  //     },
  //   ],
  //   location: '/settings/webapp/video-branding-playback',
  //   left: '42%',
  //   top: '110%',
  // },
  {
    [AppMode.development]: [
      {
        clientType: ClientTypes.TRUBLU,
        socialVerseId: '64837460-f989-43cf-8dd7-cc98ab6c4818',
      },
      {
        clientType: ClientTypes.MARKETING,
        socialVerseId: 'd65e79de-65f4-454f-b65c-1cebb0e6f07f',
      },
    ],
    location: WebsiteRoutes.TrackingPixelTab,
    left: '42%',
    top: '110%',
  },
  {
    [AppMode.development]: [
      {
        clientType: ClientTypes.TRUBLU,
        socialVerseId: '41622bbb-e868-42e4-92a6-2c79e3fd70a9',
      },
      {
        clientType: ClientTypes.MARKETING,
        socialVerseId: '809ffde7-31df-434d-8135-4c8825be9d5d',
      },
    ],
    location: '/settings/invites/sms',
    left: '46%',
    top: '110%',
  },
  {
    [AppMode.development]: [
      {
        clientType: ClientTypes.TRUBLU,
        socialVerseId: '9c505db4-e006-4e98-bf66-606b646bbe65',
      },
      {
        clientType: ClientTypes.MARKETING,
        socialVerseId: '1cf9d740-96f8-46e8-b4ea-c8cae4ad5965',
      },
    ],
    location: '/settings/invites/qr-code',
    left: '42%',
    top: '110%',
  },
  {
    [AppMode.development]: [
      {
        clientType: ClientTypes.TRUBLU,
        socialVerseId: 'a5ba9b2c-f9ea-40ab-b48d-acbe4da559f3',
      },
      {
        clientType: ClientTypes.MARKETING,
        socialVerseId: '4a999b68-8860-4352-b3a9-24f6f5ad10e8',
      },
    ],
    location: '/settings/invites/emails',
    left: '42%',
    top: '110%',
  },
];
