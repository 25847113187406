import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useClasses = makeStyles<Theme>((theme) => ({
  root: {
    padding: '28px 26px',
  },
  title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '150%',
    letterSpacing: '0.02em',
  },
  name: {
    marginTop: '5px',
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32.4px',
    letterSpacing: '0.02em',
  },
  phone: {
    color: theme.palette.common.gray,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32.4px',
    letterSpacing: '0.02em',
  },
  buttons: {
    marginTop: '16px',
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    borderRadius: '100px',
    textAlign: 'center',
    minWidth: '180px',
  },
  info: {
    marginTop: '20px',
    color: theme.palette.common.gray,
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '162%',
    letterSpacing: '0.02em',
  },
}));
