import { Box } from '@mui/material';
import React from 'react';
import { useStyles } from './UserStatus.styles';

type Props = {
  status: string;
};

export const UserStatus = ({ status }: Props) => {
  const classes = useStyles();
  return <Box className={classes.root}>{status}</Box>;
};
