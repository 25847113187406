import { Avatar, Box, Checkbox } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { VideoApiModel } from '../../../../api/models/videos';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { UserApiModel } from '../../../../api/models/users';
import CheckboxIcon from '../../../../common/assets/CheckboxIcon';
import CheckedCheckboxIcon from '../../../../common/assets/CheckedCheckboxIcon';
import {
  DefaultImage,
  ImagesText,
  TextSpan,
} from '../../../../common/components/table/CommonTable.style';
import { ImageComponent } from '../../../../common/components/table/TableImage';
import { getFullName, getStringValue, getVideoPoster } from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { addCampaignUsers, deleteCampaignUser } from '../../../../store/slices/createCampaignSlice';
import { filterSelectedRows } from '../../../../store/slices/selectedRowsSlice';
import { PlayVideoTooltip } from '../../../../common/components/PlayVideoTooltip/PlayVideoTooltip';
import { UserPhoneNumber } from './UserPhoneNumber';
/* import { filterSelectedRows } from '../../../../store/slices/storyVideosSlice'; */

interface CustomCheckBoxProps {
  video: VideoApiModel | UserApiModel | TopGolfVideosApiModel;
  isInvites?: boolean;
  onDeselect?: (value: boolean) => void;
}

export const CustomCheckBox: FC<CustomCheckBoxProps> = ({ video, isInvites, onDeselect }) => {
  const dispatch = useAppDispatch();
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);
  const { campaignUsers } = useTypedSelector((state) => state.createCampaign);
  const checked = isInvites
    ? campaignUsers.items.find((item) => item.id === video.id)
      ? true
      : false
    : selectedRows?.find((item) => item.id === video.id)
    ? true
    : false;
  const handleChange = () => {
    if (isInvites) {
      if (onDeselect) {
        onDeselect(false);
      }
      if (!checked) {
        dispatch(addCampaignUsers([{ ...(video as UserApiModel) }]));
      } else {
        dispatch(deleteCampaignUser(video.id));
      }
    } else {
      dispatch(filterSelectedRows({ video, checked }));
    }
  };

  return (
    <Checkbox
      style={{ marginLeft: '-10px', marginRight: '10px' }}
      color="primary"
      checked={checked}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckedCheckboxIcon />}
      id={`${(video as UserApiModel).displayName?.split(' ').join('-')}-checkbox`}
    />
  );
};

interface UserNameCellProps {
  video: VideoApiModel;
  renderCheckbox: boolean;
  renderAvatar?: boolean;
  style?: CSSProperties;
  activeFilter?: boolean;
  showPhoneNumber?: boolean;
}
interface HeroesNameCellProps {
  user: UserApiModel;
  renderCheckbox: boolean;
  isInvites?: boolean;
  onDeselect?: (value: boolean) => void;
  disableAvatar?: boolean;
}

export const UserNameCell: FC<UserNameCellProps> = ({
  video,
  renderCheckbox,
  renderAvatar = true,
  style,
  activeFilter,
  showPhoneNumber,
}) => {
  return (
    <Box display="flex" alignItems="center">
      {renderCheckbox && <CustomCheckBox video={video} />}
      {activeFilter && !renderCheckbox && <Box style={{ minWidth: '39px' }} />}
      {renderAvatar ? (
        <Box
          component="img"
          src={getVideoPoster(video.url)}
          width="27px"
          height="48px"
          borderRadius="6px"
          marginRight="16px"
        />
      ) : (
        <>
          <PlayVideoTooltip video={video} title="" style={style} />
        </>
      )}
      <Box display="flex" flexDirection="column">
        <TextSpan
          style={{
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginBottom: showPhoneNumber ? '-5px' : 'none',
          }}
        >
          {getStringValue(video.userName)}
        </TextSpan>
        {showPhoneNumber && <UserPhoneNumber phone={video.userPhoneNumber} fontSize="11px" />}
      </Box>
    </Box>
  );
};

export const HeroesNameCell: FC<HeroesNameCellProps> = ({
  user,
  renderCheckbox,
  isInvites,
  onDeselect,
  disableAvatar,
}) => {
  return (
    <Box component="span" display="flex" alignItems="center">
      {renderCheckbox && (
        <CustomCheckBox video={user} isInvites={isInvites} onDeselect={onDeselect} />
      )}
      {!disableAvatar && (
        <Avatar
          variant="rounded"
          sx={{ width: '27px', height: '48px', mr: '1em' }}
          src={user.photoUrl || ''}
        />
      )}

      <ImagesText
        style={{
          maxWidth: '250px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '13px',
        }}
      >
        {getFullName(user)}
      </ImagesText>
    </Box>
  );
};
export const LeaderboardNameCell: FC<HeroesNameCellProps> = ({ user, renderCheckbox }) => {
  return (
    <span style={{ display: 'flex', alignItems: 'center', paddingLeft: '5px' }}>
      {renderCheckbox && <CustomCheckBox video={user} />}
      {user.photoUrl ? <ImageComponent imageRef={user.photoUrl} alt="user" /> : <DefaultImage />}
      <ImagesText>{getStringValue(user.name)}</ImagesText>
    </span>
  );
};
