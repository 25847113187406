import React, { FC, useEffect, useMemo, useState } from 'react';
import { useStyles } from './LengthSelect.styles';
import { Box, Button, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { ExpandIcon } from '../../assets/ExpandIcon';
import { ClipsSearchStrings } from '../../localization/en';
import { SearchOperationType, VideoAttributeName } from '../../../api/models/searchFilterModels';
import { TimePicker } from './TimePicker/TimePicker';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getTimeInitinialValues } from './TimePicker/LengthSelect.helpers';
import { removeVideosLengthFilter } from '../../../store/slices/searchFilterSlice';

export interface LengthRange {
  value: number;
  operation: SearchOperationType.LessThan | SearchOperationType.GreaterThan;
}

interface SelectItem {
  text: string;
  range: LengthRange[];
}

interface Props {
  onChange: (range: LengthRange[]) => void;
  lengthRange: LengthRange[];
}

export const LengthSelect: FC<Props> = ({ onChange, lengthRange }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const theme = useTheme();

  const appliedAttributes = useMemo(() => {
    return activeVideoFilterAttributes.filter(
      (attribute) => attribute.name === VideoAttributeName.Length,
    );
  }, [activeVideoFilterAttributes]);

  useEffect(() => {
    if (
      appliedAttributes.length === 1 &&
      appliedAttributes[0].operation === SearchOperationType.LessThan &&
      [30, 60].includes(appliedAttributes[0].value as number)
    ) {
      if (appliedAttributes[0].value === 30) {
        setSelectedItemIndex(1);
      }

      if (appliedAttributes[0].value === 60) {
        setSelectedItemIndex(2);
      }
    } else if (appliedAttributes.length > 0) {
      setSelectedItemIndex(3);
    }
    onChange(appliedAttributes as LengthRange[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedAttributes]);

  const selectItems: SelectItem[] = [
    {
      text: ClipsSearchStrings.Select,
      range: [],
    },
    {
      text: ClipsSearchStrings.LessThan30s,
      range: [
        {
          value: 30,
          operation: SearchOperationType.LessThan,
        },
      ],
    },
    {
      text: ClipsSearchStrings.LessThan60s,
      range: [
        {
          value: 60,
          operation: SearchOperationType.LessThan,
        },
      ],
    },
    {
      text: ClipsSearchStrings.CustomRange,
      range: [],
    },
  ];

  const { lessThanValues, greaterThanValues } = getTimeInitinialValues(appliedAttributes);

  return (
    <Box className={classes.container}>
      {selectedItemIndex > 0 && (
        <Button
          className={classes.clearButton}
          onClick={(event) => {
            setSelectedItemIndex(0);
            dispatch(removeVideosLengthFilter());

            onChange([]);
          }}
        >
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        displayEmpty={true}
        renderValue={() => {
          return (
            <Typography
              color={[0, 3].includes(selectedItemIndex) ? '#667085' : theme.palette.primary.main}
              className={classes.text}
            >
              {selectItems[selectedItemIndex].text}
            </Typography>
          );
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
            },
          },
        }}
        sx={{
          width: '100%',
          border: '1px solid #cacae4',
          paddingRight: 0,

          '& .MuiSelect-select': {
            padding: '8px 0 8px 16px !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none!important',
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: 'none!important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        {selectItems.map((item, index) => {
          return (
            index > 0 && (
              <MenuItem
                onClick={() => {
                  setSelectedItemIndex(index);
                  onChange(item.range);
                }}
                className={classes.menuItem}
                key={item.text}
              >
                {item.text}
              </MenuItem>
            )
          );
        })}
      </Select>

      {selectedItemIndex === 3 && (
        <Box className={classes.timePickersWrapper}>
          <TimePicker
            onChange={onChange}
            minutesInitialVal={greaterThanValues.minutes}
            secondsInitialVal={greaterThanValues.seconds}
            operation={SearchOperationType.GreaterThan}
            lengthRange={lengthRange}
          />
          <Typography className={classes.toText}>{ClipsSearchStrings.To}</Typography>
          <TimePicker
            onChange={onChange}
            minutesInitialVal={lessThanValues.minutes}
            secondsInitialVal={lessThanValues.seconds}
            operation={SearchOperationType.LessThan}
            lengthRange={lengthRange}
          />
        </Box>
      )}
    </Box>
  );
};
