import React, { FC } from 'react';
import { useStyles } from './CallToActionModal.styles';
import { FSModal } from '../../../../../common/components/modal/Modal';
import { Box, Divider, Typography } from '@mui/material';
import { NewCampaignStrings } from '../../../../../common/localization/en';
import { TextFieldComponent } from '../../../../../common/components/TextField/TextField';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { useFormik } from 'formik';
import { ctaValidationSchema } from '../../../../NewCampaign/CallToAction/CallToAction';
import { filterLogoPositions } from '../../../../webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { IPhoneVideoPlaybackPreview } from '../../../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { RoundedButton } from '../../../../../common/components/Button/RoundedButton';
import { CampaignsApiModel } from '../../../../../api/models/campaigns';
import { CTA_MAX_LENGTH } from '../../../../../common/constants/constants';
import { patchIncentiveCampaign } from '../../../../../store/slices/campaignsSlice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentCampaign: CampaignsApiModel;
}

export const CallToActionModal: FC<Props> = ({ isOpen, onClose, currentCampaign }) => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const {
    venue: { logo },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const brandColor = venueProperties?.['webapp.config']['primary-color'];

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    dirty: isFormDirty,
  } = useFormik({
    initialValues: {
      ctaButtonText: currentCampaign.ctaButtonText || '',
      ctaButtonLinkUrl: currentCampaign.ctaButtonLinkUrl || '',
    },
    enableReinitialize: true,
    validateOnMount: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: ctaValidationSchema,
    onSubmit: () => {
      dispatch(
        patchIncentiveCampaign({
          campaignId: currentCampaign.id,
          values: {
            ctaButtonLinkUrl: values.ctaButtonLinkUrl,
            ctaButtonText: values.ctaButtonText,
          },
        }),
      );
    },
  });

  return (
    <FSModal
      onClose={() => {
        onClose();
        resetForm();
      }}
      modalIsOpen={isOpen}
      rounded="12px"
      blur
      width="770px"
      padding="0"
    >
      <Box className={styles.container}>
        <Typography className={styles.title}>
          {NewCampaignStrings.CallToActionModalTitle}
        </Typography>
        <Typography className={styles.subtitle}>
          {NewCampaignStrings.CallToActionModalSubTitle}
        </Typography>
        <Divider className={styles.divider} />
        <Box className={styles.contentWrapper}>
          <Box className={styles.inputsWrapper}>
            <Typography className={styles.label}>{NewCampaignStrings.ButtonTitle}</Typography>
            <TextFieldComponent
              name="ctaButtonText"
              fullWidth={true}
              value={values.ctaButtonText}
              label={NewCampaignStrings.ButtonTitle}
              errorText={errors.ctaButtonText}
              onChange={(event) => {
                handleChange(event);
              }}
              helperText={true}
              placeholder=""
              characterLimit={CTA_MAX_LENGTH}
            />
            <Typography className={styles.label} mt="20px">
              {NewCampaignStrings.CtaDestination}
            </Typography>
            <Box className={styles.linkFieldWrapper}>
              <Box className={styles.httpsText}> {NewCampaignStrings.Https}</Box>
              <TextFieldComponent
                name="ctaButtonLinkUrl"
                fullWidth={true}
                value={values.ctaButtonLinkUrl}
                errorText={errors.ctaButtonLinkUrl}
                inputStyle={{
                  paddingLeft: '74px',
                }}
                helperText={true}
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </Box>
          </Box>
          <Box className={styles.previewWrapper}>
            <IPhoneVideoPlaybackPreview
              logoPosition={filterLogoPositions.topLeft}
              tempFilterLogoEnabled={true}
              tempFilterOverlayEnabled={true}
              buttonText={values.ctaButtonText}
              logo={logo}
              brandColor={brandColor}
            />
          </Box>
        </Box>
        <Box className={styles.buttonsWrapper}>
          <RoundedButton
            title={NewCampaignStrings.Cancel}
            variant="outlined"
            textColor="#344054"
            className={styles.button}
            onClick={() => {
              onClose();
              resetForm();
            }}
            sx={{
              marginRight: '12px',
            }}
          />
          <RoundedButton
            title={NewCampaignStrings.Confirm}
            variant="contained"
            textColor="#fff"
            className={styles.button}
            disabled={!isValid || !isFormDirty}
            onClick={() => {
              handleSubmit();
              onClose();
            }}
          />
        </Box>
      </Box>
    </FSModal>
  );
};
