import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '142px 90px 0 90px',
    marginTop: '16px',

    [theme.breakpoints.down(1350)]: {
      padding: '142px 90px 0 40px',
    },
  },
  title: {
    padding: '5px 6px',
    fontSize: '38px',
    color: theme.palette.primary.main,
    fontWeight: 600,
    backgroundColor: '#EBE9FF',
    width: 'fit-content',
    borderRadius: '8px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  welcomeText: {
    fontSize: '40px',
    fontWeight: 600,
    lineHeight: '48px',
    color: '#101828',
    marginTop: '10px',
    marginBottom: '20px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  description: {
    fontSize: '18px',
    fontWeight: 400,
    fontFamily: 'Inter',
    lineHeight: '24px',
    color: '#475467',
    maxWidth: '555px',
  },
  btnWrapper: {
    display: 'flex',
    padding: '0 90px',
    marginTop: '30px',

    [theme.breakpoints.down(1350)]: {
      padding: '0 40px',
    },
  },
  btn: {
    padding: '0 70px',
    width: '259px',
    height: '50px',
  },
  divider: {
    marginTop: '126px',
    borderColor: '#DCDBE4',
  },
}));
