import React, { memo } from 'react';

import { AddButtonWithText } from '../addButtonWithText/AddButtonWithText';

import { Container, PageTitle, PageSubtitle } from './TitleSection.style';

interface AddButtonParams {
  name: string;
  onClickAddBtn: () => void;
}
interface TitleSectionProps {
  title: string;
  subTitle: string;
  addButtonParams?: AddButtonParams;
}
export const TitleSection: React.FC<TitleSectionProps> = memo(
  ({ title, subTitle, addButtonParams }) => {
    const AddButton = addButtonParams && (
      <AddButtonWithText onClick={addButtonParams.onClickAddBtn} text={addButtonParams.name} />
    );

    return (
      <Container>
        <div>
          <PageTitle>{title}</PageTitle>
          <PageSubtitle>{subTitle}</PageSubtitle>
        </div>
        {AddButton}
      </Container>
    );
  },
);
