import * as React from 'react';

export const ActivitiesGoal: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" fill="none" viewBox="0 0 35 25">
      <g clipPath="url(#clip0)">
        <path
          fill="#3C8F7C"
          d="M11.918 20.41s-4.9-2.393-5.743-3.64c-.843-1.248.396-1.319.396-1.319s-2.187-2.105-1.88-2.992c.308-.886 1.441-.073 1.441-.073s-1.406-3.099-.743-3.41c1.235-.58 2.967 2.754 2.967 2.754s-.372-1.449.478-1.402c1.283.071 1.23 3.348 1.23 3.348s.145-1.257.918-.849c1.386.732.936 7.582.936 7.582z"
        ></path>
        <path
          fill="#50BFA5"
          d="M11.261 12.32c-.603-.332-1.205.637-1.205.637s.061-2.559-1.221-2.63c-.85-.047-.478 1.401-.478 1.401S6.625 8.394 5.39 8.974l6.534 11.38c.046-.794.533-7.378-.663-8.034zM29.533 14.643l.215-.515c.167-.379.594-.56.954-.404.908.392 1.303 1.484.882 2.439l-.308.698a.869.869 0 00.094.879.767.767 0 01.078.774l-.163.368a1.473 1.473 0 01-.922.815.893.893 0 00-.557.499l-.23.52c-.254.577-.904.852-1.451.615l-.313-.135c-.548-.237-.786-.895-.532-1.471.411-.69.378-1.203.233-1.552a2.066 2.066 0 01.02-1.627l.032-.073a2.085 2.085 0 011.278-1.145c.248-.079.506-.27.69-.685z"
        ></path>
        <path
          fill="#96D9C9"
          d="M20.656 24.817c-.582-.747-3.772-4.747-5.852-5.414-2.296-.736-.132 2.187-.132 2.187s-4.073-4.41-5.567-4.012c-1.37.365 0 3.098 0 3.098s-1.543-1.514-3.415-1.514c-1.82 0 1.273 5.368 1.44 5.655h13.526z"
        ></path>
        <path
          fill="#256653"
          d="M34.808 8.802s-.587.75-.933 1.045c-.346.295-.675.576-1.116 1.513-.44.937-.428 1.045-.545 1.286-.117.241-.732 1.186-.779 1.375-.046.188-1.05 1.091-1.36 1.55-.312.46-.822 1.1-.889 1.472l.091-.002s.27-.838 1.067-1.565c.797-.726.75-.909 1.529-1.197.779-.288 1.502-1.412 1.502-1.412s.998-1.173 1.13-1.872c.13-.699.458-1.28.303-2.193zM34.198 7.3s-.152.546-.409.833c-.257.286-.458.45-.575.797-.115.347-.443 1.236-1.05 1.567-.606.33-.984 1.654-1.46 1.97-.478.317-.596.597-.689 1.307-.092.71-.079 1.728-.927 2.856l-.052-.341s.639-.942.685-1.569c.046-.627-.073-1.618.152-2.028.225-.41-.209-.6.22-1.2.43-.6.372-.856.878-1.344 0 0 .045-.018.257-.48.212-.463 1.21-1.17 1.644-1.39.435-.22 1.073-.585 1.326-.979z"
        ></path>
        <path
          fill="#3C8F7C"
          d="M32.075 9.691s-.608.638-.726 1.112c-.118.474-.157.872-.53 1.472s-.032 1.112-.243 1.442c-.21.33-.32.753-.392 1.076-.071.324-.825 1.276-1.028 1.798-.204.523-.106.547-.106.547s.25-.694.422-.903c.17-.21.713-.942.87-1.238.156-.296.426-.446.743-.935.317-.489.696-.875.853-1.636.156-.76.651-1.488.137-2.735zM29.455 16.71s.423-.89 1.302-.787c.878.104 1.342.387 2.108-.158.765-.545 1.372-1.55 1.609-1.693 0 0-.268-.224-.883.127s-1.053.625-1.41.83c-.356.204-.552-.028-.903.08-.294.091-.731.45-.932.643a1.065 1.065 0 01-.217.163c-.072.04-.159.09-.216.129-.113.077-.524.654-.524.654l.066.012z"
        ></path>
        <path
          fill="#3C8F7C"
          d="M29.192 16.475s.571-1.076.5-1.683c-.07-.607-.605-1.168-.497-1.514.108-.347.738-1.205.573-1.99 0 0-.274.603-.431.715-.157.112-1.143.483-1.168 1.267-.024.784.763 1.15.915 1.417.152.267.428.33.395.477-.032.147-.104.693-.319 1.198l.032.113z"
        ></path>
        <path
          fill="#327F6B"
          d="M29.702 15.784l.708-1.111s.478-.915.813-1.693c.334-.778.852-3.289.852-3.289s-.608.638-.726 1.112c-.118.474-.157.872-.53 1.472s-.032 1.112-.243 1.442c-.21.33-.32.753-.392 1.076-.071.324-.825 1.276-1.028 1.798-.112.287-.133.423-.13.488.043-.488.676-1.295.676-1.295zM31.96 15.545c-.396-.013-.728-.116-1.255.111-.527.228-.874.52-.874.52s-.286.311-.413.48c-.02.026-.03.042-.03.042l.067.012s.423-.89 1.301-.787c.878.104 1.342.387 2.108-.158s1.372-1.55 1.61-1.693c0 0-.76.348-1.261.695-.5.347-.858.79-1.254.778z"
        ></path>
        <path
          fill="#327F6B"
          d="M29.337 12.003c-.157.112-1.143.483-1.168 1.267-.024.784.763 1.15.915 1.417.152.267.428.33.395.477-.032.147-.104.693-.319 1.198l.014.05a6.87 6.87 0 00.305-.727c.123-.378.097-.552.046-.893-.052-.341-.68-1.156-.797-1.535-.116-.38.586-.807.776-1.048.19-.24.264-.92.264-.92s-.274.602-.431.714z"
        ></path>
        <path
          fill="#96D9C9"
          d="M16.528 24.817c.582-.747 3.772-4.747 5.852-5.414 2.296-.736.132 2.187.132 2.187s4.073-4.41 5.567-4.012c1.37.365 0 3.098 0 3.098s1.543-1.514 3.415-1.514c1.82 0-1.273 5.368-1.44 5.655H16.528z"
        ></path>
        <path
          fill="#256653"
          d="M2.136 22.48c1.7.185 3.282.927 4.805 1.651 1.028.489.976.628 1.185.7.034.012.068-.024.051-.057-.08-.157-.291-.264-.438-.355a5.747 5.747 0 00-.53-.286c-.389-.187-.786-.354-1.182-.524-.804-.347-1.621-.672-2.47-.892a8.753 8.753 0 00-1.42-.25c-.009-.001-.01.012-.001.013z"
        ></path>
        <path
          fill="#256653"
          d="M3.523 21.937c-.011.069-.084.269-.04.467.027.195.172.388.173.378.012 0-.225-.063-.385-.264-.172-.184-.267-.507-.228-.646.04-.293.286-.436.388-.35.105.083.107.262.092.415zM5.019 22.467c-.011.07-.084.27-.039.468.027.195.17.388.172.378.012 0-.225-.064-.385-.264-.172-.185-.266-.507-.228-.646.04-.294.287-.436.388-.35.105.083.108.262.092.414zM6.54 23.138c-.01.07-.083.27-.039.468a.83.83 0 00.172.378c.013 0-.224-.063-.385-.264-.171-.184-.266-.507-.228-.646.04-.294.287-.436.389-.35.104.083.107.262.091.414z"
        ></path>
        <path
          fill="#256653"
          d="M3.075 23.321c.03-.057.09-.245.235-.366a.785.785 0 01.34-.184c.008.008-.202-.077-.433-.017-.23.04-.482.226-.532.35-.136.238-.035.48.089.472.124-.004.226-.134.3-.255zM4.632 23.867c.031-.057.09-.245.235-.366a.783.783 0 01.34-.184c.009.007-.201-.077-.432-.018-.23.041-.482.227-.533.35-.135.238-.034.48.09.473.123-.004.226-.134.3-.255zM1.89 22.76c.026-.032.089-.142.196-.2a.508.508 0 01.238-.07c.004.005-.117-.075-.27-.068-.15-.005-.333.079-.381.15-.117.132-.086.296-.007.308.078.014.16-.054.223-.12z"
        ></path>
        <path
          fill="#256653"
          d="M2.07 22.035c.006.04 0 .167.062.271.05.106.166.19.165.184.007-.003-.138.003-.265-.082-.13-.074-.242-.24-.246-.325-.03-.173.084-.297.157-.267.074.03.108.13.127.219z"
        ></path>
        <path
          fill="#B75742"
          d="M26.693 21.344a11.827 11.827 0 000-16.845C21.994-.152 14.377-.152 9.679 4.5a11.827 11.827 0 000 16.845c4.698 4.651 12.315 4.651 17.014 0z"
        ></path>
        <path
          fill="#F47458"
          d="M25.944 20.602a10.785 10.785 0 000-15.36c-4.285-4.243-11.231-4.243-15.515 0a10.785 10.785 0 000 15.36c4.284 4.242 11.23 4.242 15.515 0z"
        ></path>
        <path
          fill="#FCF5EB"
          d="M24.71 19.38a9.07 9.07 0 000-12.917c-3.604-3.567-9.445-3.567-13.048 0a9.07 9.07 0 000 12.917c3.603 3.567 9.444 3.567 13.047 0z"
        ></path>
        <path
          fill="#F47458"
          d="M23.09 17.776a6.818 6.818 0 000-9.71c-2.708-2.68-7.1-2.68-9.807 0a6.818 6.818 0 000 9.71c2.708 2.681 7.099 2.681 9.807 0z"
        ></path>
        <path
          fill="#FCF5EB"
          d="M21.668 16.369a4.841 4.841 0 000-6.895 4.96 4.96 0 00-6.964 0 4.841 4.841 0 000 6.895 4.96 4.96 0 006.964 0z"
        ></path>
        <path
          fill="#B75742"
          d="M19.442 12.921a1.25 1.25 0 01-1.256 1.244 1.25 1.25 0 01-1.255-1.243 1.25 1.25 0 011.255-1.244 1.25 1.25 0 011.256 1.243z"
        ></path>
        <path
          fill="#184655"
          d="M25.896 6.75l-.044-.808 2.762-2.138-.145 1.17-2.706 2.095.133-.319z"
        ></path>
        <path
          fill="#12353D"
          d="M26.1 7.022l.782.237 2.762-2.138-1.181-.143-2.707 2.096.345-.052z"
        ></path>
        <path
          fill="#184655"
          d="M18.092 13.025c.063.08.179.093.26.031l7.78-6.026-.227-.288-7.781 6.026a.182.182 0 00-.032.257z"
        ></path>
        <path
          fill="#256653"
          d="M11.295 25.011a33.363 33.363 0 00-6.808-6.83c-.027-.02-.063.027-.036.048a33.32 33.32 0 016.703 6.82l.141-.038z"
        ></path>
        <path
          fill="#256653"
          d="M5.986 20.423c.063-.044.224-.21.438-.261a.922.922 0 01.452-.017c.006.012-.17-.184-.442-.242-.262-.075-.623-.012-.74.091-.268.18-.29.484-.157.54.132.06.308-.023.45-.111zM4.892 19.468c.062-.044.223-.209.438-.26a.922.922 0 01.452-.018c.005.012-.17-.184-.443-.242-.262-.075-.622-.012-.74.091-.267.18-.289.484-.156.54.131.06.307-.023.449-.11zM7.83 19.999c-.059.036-.266.108-.361.272a.63.63 0 00-.101.381c.012.01-.167-.219-.178-.476-.034-.256.101-.54.233-.6.242-.158.572-.055.608.081.04.137-.081.255-.202.342zM8.113 16.587a26.429 26.429 0 013.573 8.467l.114-.03a26.6 26.6 0 00-3.56-8.396c-.385-.598-.802-1.306-1.525-1.54-.014-.004-.02.017-.006.022.66.247 1.043.918 1.404 1.477z"
        ></path>
        <path
          fill="#256653"
          d="M7.96 14.99c-.042.063-.206.226-.255.44a.894.894 0 00-.008.447c.013.005-.19-.164-.253-.433-.082-.258-.024-.616.078-.735.175-.268.482-.295.542-.165.063.13-.017.305-.103.447zM7.562 17.287c.062-.043.223-.209.438-.26a.922.922 0 01.452-.018c.005.013-.17-.184-.443-.241-.262-.076-.622-.012-.74.09-.268.18-.289.484-.156.54.131.06.307-.023.449-.11zM8.163 18.294c.062-.044.223-.21.438-.26a.922.922 0 01.452-.018c.005.012-.17-.184-.442-.241-.263-.076-.623-.013-.74.09-.268.18-.29.484-.157.54.132.06.308-.023.45-.11z"
        ></path>
        <path
          fill="#256653"
          d="M8.8 16.13c-.052.054-.24.19-.321.393a.895.895 0 00-.08.44c.012.008-.16-.19-.18-.466-.04-.268.074-.613.193-.714.216-.237.523-.216.562-.078.041.137-.066.298-.173.425zM9.684 17.271c-.074.04-.334.11-.465.303a.775.775 0 00-.156.458c.014.012-.186-.283-.177-.599-.018-.315.171-.651.338-.714.309-.173.704-.018.736.151.037.172-.12.306-.276.401zM10.614 19c-.08.026-.348.053-.51.221a.776.776 0 00-.232.426c.012.014-.134-.31-.072-.62.035-.314.279-.614.454-.647.334-.12.697.099.7.271.008.175-.171.281-.34.35zM6.43 15.396c.035-.029.123-.134.248-.174a.55.55 0 01.266-.03c.004.008-.108-.101-.272-.123-.158-.034-.369.02-.434.086-.15.117-.15.298-.069.326.08.03.181-.027.261-.085z"
        ></path>
        <path
          fill="#256653"
          d="M6.765 14.66c-.002.046-.032.178.012.3.033.123.14.234.14.228.007-.001-.148-.023-.266-.138-.124-.104-.21-.301-.197-.393.003-.19.148-.299.22-.252.073.044.089.158.09.256zM4.103 18.496c.035-.029.123-.134.248-.174a.55.55 0 01.266-.03c.004.007-.108-.101-.272-.123-.159-.034-.37.02-.434.085-.15.118-.15.299-.069.326.08.03.181-.026.261-.084z"
        ></path>
        <path
          fill="#256653"
          d="M4.506 17.754c-.005.045-.046.175-.012.3.022.125.12.244.12.238.008 0-.146-.035-.254-.159-.114-.113-.184-.317-.163-.407.018-.189.172-.287.24-.234.068.05.075.164.07.262z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H34.849V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
