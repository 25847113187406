import styled from 'styled-components';
import { Button } from '@mui/material';
import { theme } from '../../../../theme';

export const PreviewContainer = styled.div`
  width: 100%;
  padding: 16px 24px;
`;

export const MainWrapper = styled.div`
  padding: 8px;
  height: 100%;
  background-color: ${theme.palette.common.black};
  overflow: hidden;
  border-radius: 24px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Slide = styled.div`
  position: relative;
  object-position: center;
  object-fit: cover;
`;

interface TitleProps {
  color: string;
}
export const Title = styled.span<TitleProps>`
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  color: ${(props) => props.color || 'var(--white)'};
`;

export const SliderImage = styled.img`
  height: 90px;
  border-radius: 8px;
`;

export const SliderContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

export const TopSliderContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const RedeemButtonWrapper = styled.div`
  padding: 16px;
`;

interface RedeemButtonStyledProps {
  background: string;
}
export const RedeemButtonStyled = styled(Button)<RedeemButtonStyledProps>`
  background-color: ${(props) => props.background + '!important'};
  font-size: 12px;
`;

export const RewardName = styled.div`
  font-size: 12px;
  color: var(--white);
`;

export const ActivitiesTitle = styled.div`
  margin-top: 8px;
  font-size: 12px;
  color: ${(props) => props.color || 'var(--white)'};
`;

export const RewardCirclePointWrapper = styled.div`
  transform: scale(0.8);
`;

export const ShadowBox = styled.div`
  position: absolute;
  height: 90px;
  width: 100%;
  top: 0;
  background: linear-gradient(180deg, #000000 0, rgba(28, 29, 34, 0) 80%);
`;
