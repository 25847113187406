import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    filter: 'drop-shadow(2px 6px 6px rgba(0, 0, 0, 0.20))',
  },
  textWrapper: {
    width: '90px',
    height: '35px',
    position: 'relative',
    marginLeft: '30px',

    '&::before': {
      content: '""',
      width: '27px',
      height: '27.5px',
      position: 'absolute',
      top: '50.4%',
      left: '-14px',
      borderRadius: '4px',
      transform: 'translateY(-50%) rotate(45deg)',

      zIndex: '-1',
    },
  },
  text: {
    fontSize: '15px',
    fontWeight: 600,
    lineHeight: '20px',
    padding: '8px 0',
    position: 'relative',
  },
  goBack: {
    width: '35px',
    height: '35px',

    borderRadius: '0 5px 5px 0',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
    transition: 'width 0.11s ease-in-out',

    '&:hover': {
      width: '115px',
      color: 'white',
    },
  },
  logoutIcon: {
    width: '24px',
    height: '24px',
    marginLeft: '5px',
  },
  toGuide: {
    fontSize: '15px',
    fontWeight: 600,
    color: 'white',
    lineHeight: '20px',
    position: 'absolute',
    left: '35px',
    textWrap: 'nowrap',
  },
}));
