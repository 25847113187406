import { FC } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { SVButton } from '../../../../pages/SocialVerse/CommonComponents/CommonComponents';
import { CommonStrings, VideoDetailsModalStringsDv5 } from '../../../localization/en';
import { useModalsStyles } from './Modals.style';
import { ICheckedList } from '../VideosDetailUserComponent';

interface ModalAssignProps {
  isDisabled: boolean;

  isOpenModal: boolean;
  listSocialVerse: ICheckedList[];
  toggleModal: () => void;
  handleConfirm: (data: ICheckedList[]) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  handleCancel: () => void;
  type: 'checkbox' | 'radio';
}
export const ModalForAssignVideoToSV: FC<ModalAssignProps> = (props) => {
  const {
    isOpenModal,
    isDisabled,
    listSocialVerse,
    toggleModal,
    handleCancel,
    handleConfirm,
    handleChange,
    type = 'checkbox',
  } = props;
  const classes = useModalsStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={toggleModal}
      open={isOpenModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <Grid container>
          <Grid item sx={{ width: '100%' }}>
            <Grid container flexDirection="row" className={classes.wrapperMainContent}>
              <Grid container flexDirection="column">
                <Grid
                  container
                  justifyContent="flex-start"
                  flexDirection="column"
                  className={classes.modalBody}
                >
                  <Typography className={classes.modalTitle}>
                    {VideoDetailsModalStringsDv5.AssignToASV}
                  </Typography>
                  <Divider sx={{ margin: '15px 0' }} />
                  <FormControl className={classes.formControl}>
                    <form>
                      {/* <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                key={item.id}
                                className={classes.formGroup}
                              >
                                <FormControlLabel
                                  classes={{ root: classes.labelRoot, label: classes.modalLabel }}
                                  label={item.name}
                                  control={
                                    <Radio
                                      checked={item.isChecked}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        handleChange(event, item.id)
                                      }
                                      name={item.name}
                                    />
                                  }
                                  labelPlacement="start"
                                />
                              </RadioGroup> */}
                      {listSocialVerse
                        .slice()
                        .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                        .map((item) => (
                          <FormGroup key={item.id} className={classes.formGroup}>
                            <FormControlLabel
                              classes={{ root: classes.labelRoot, label: classes.modalLabel }}
                              label={item.name}
                              control={
                                type === 'checkbox' ? (
                                  <Checkbox
                                    checked={item.isChecked}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      handleChange(event, item.id)
                                    }
                                    name={item.name}
                                  />
                                ) : (
                                  <Radio
                                    checked={item.isChecked}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                      handleChange(event, item.id)
                                    }
                                    name={item.name}
                                  />
                                )
                              }
                              labelPlacement="start"
                            />
                          </FormGroup>
                        ))}
                    </form>
                  </FormControl>
                  <Divider sx={{ marginBottom: '10px ' }} />
                </Grid>
                <Stack direction="row" spacing={2} justifyContent="start" width="100%">
                  <SVButton
                    disabled={isDisabled}
                    variant="contained"
                    sx={{ padding: '10px 30px' }}
                    handleClick={() => handleConfirm(listSocialVerse)}
                  >
                    <Typography variant="body2">{VideoDetailsModalStringsDv5.Confirm}</Typography>
                  </SVButton>
                  <SVButton
                    variant="outlined"
                    sx={{ padding: '10px 30px' }}
                    handleClick={handleCancel}
                  >
                    <Typography variant="body2">{CommonStrings.Cancel}</Typography>
                  </SVButton>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
