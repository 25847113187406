import { Box, Checkbox, LinearProgress, Table, TableBody, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  getSortDirectionFromSortString,
  getSortFieldFromSortString,
  waitFor,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { toggleSelectAll } from '../../../store/slices/selectedRowsSlice';
import { Spinner } from '../../assets/Spinner';
import { TableContent, TableRow } from '../../commonTypes';
import {
  defaultPagination,
  LAYOUT_CONTAINER_CLASSNAME,
  TypeSocialVerse,
} from '../../constants/constants';
import { TableStrings } from '../../localization/en';
import { useStyles } from './CommonTable.helper';
import {
  CommonTableContainer,
  SpinnerContainer,
  TableHeaderRow,
  TableHeaderStyled,
  TableRowStyled,
  ThSortable,
  ThStyled,
} from './CommonTable.style';
import { Pagination } from './Pagination';
import { Tooltip } from './Tooltip';
import HelpOutlineSharpIcon from '@mui/icons-material/HelpOutlineSharp';
import { ClientTypes } from '../../../api/models/common';
import { SearchBar, SearchBarProps } from '../searchBar/SearchBar';
import { TopSectionContainer } from './CommonTable.style';
// import { NavAlt } from '../../layout/sideBar/nav-alt/nav-alt';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation } from 'react-router-dom';
import { SortIcon } from '../../assets/SortIcon';
import CheckboxIcon from '../../assets/CheckboxIcon';
import CheckedCheckboxIcon from '../../assets/CheckedCheckboxIcon';
import { WebsiteRoutes } from '../../constants/routes';

interface CommonTableProps {
  content: TableContent<TableRow>;
  isLoading?: boolean;
  noContent?: boolean;
  selectedRowId?: string;
  hasShadow?: boolean;
  tablePadding?: string;
  onSortHeaderClick?: (name: string) => void;
  page: number;
  totalItems: number;
  totalPages: number;
  size: number;
  sort?: string[];
  goToPage?: (page: number) => void;
  onSizeChange: (size: number) => void;
  onClickRow?: (id: string) => void;
  hasInfinityScroll?: boolean;
  hasAdditionalRow?: boolean;
  activeFilter?: boolean;
  withSearchBar?: boolean;
  searchBarProps?: SearchBarProps;
  showTopDivider?: boolean;
  disableFullHeight?: boolean;
  isSuperAdmin?: boolean;
  children?: JSX.Element;
  containerClassName?: string;
  topSectionClassName?: string;
  headerCellClassName?: string;
  withCellDivider?: boolean;
  rowClassName?: string;
  withRowDelete?: boolean;
  handleRowDelete?: (id: string) => void;
  withAutoScroll?: boolean;
  deleteButton?: JSX.Element;
  paginationClassName?: string;
  disablePagination?: boolean;
  labelRowsPerPage?: string;
  roundedNavButtons?: boolean;
  onTableRowClick?: (id: string) => void;
  deleteButtonId?: string;
  disableTopNav?: boolean;
  handleCellClick?: (rowId: string, cellIndex: number) => void;
  scrollbarRefProp?: any;
  getRowBackground?: (rowId: string) => string;
  withCustomSearchBar?: boolean;
  socialverseViewMode?: TypeSocialVerse;
  showEmptyTableCustomPreview?: boolean;
  emptyTableCustomPreview?: JSX.Element;
  showLoadingLineIndicator?: boolean;
}

interface CheckedAllCellProps {
  content: TableContent<TableRow>;
  tableSize: number;
  changeSize: (size: number) => void;
  isLoading: boolean;
}

const CheckedAllCell: FC<CheckedAllCellProps> = ({ content, tableSize, changeSize, isLoading }) => {
  const dispatch = useAppDispatch();
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);

  const checkedAll = content.rows.every((row) =>
    selectedRows?.some((selectedRow) => selectedRow.id === row.id),
  );

  const loadingRef = useRef(false);
  useEffect(() => {
    loadingRef.current = isLoading;
  }, [isLoading]);

  const handleChange = async () => {
    if (tableSize !== 100) {
      changeSize(100);

      await waitFor(() => {
        return loadingRef.current;
      });

      await waitFor(() => {
        return !loadingRef.current;
      });
    }

    dispatch(toggleSelectAll(checkedAll));
  };
  return (
    <Checkbox
      color="primary"
      checked={checkedAll}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckedCheckboxIcon />}
    />
  );
};

// const DEFAULT_TABLE_SIZE = 50;

/*
 *  Table accepts TableContent but constraint to the schema, that we pass in parent component.
 *  So, the data, that you pass as content should look like this:
 *
 *  const myContent: TableContent<IMyContent> = { rows: [{...}], ... }
 * */
export const CommonTable: FC<CommonTableProps> = ({
  selectedRowId,
  content,
  tablePadding = '0 var(--sectionPadding)',
  onSortHeaderClick,
  page,
  totalItems,
  totalPages,
  size,
  sort,
  isLoading = false,
  noContent = false,
  goToPage = () => {},
  onSizeChange,
  onClickRow = () => {},
  hasInfinityScroll,
  activeFilter,
  withSearchBar,
  searchBarProps,
  isSuperAdmin,
  showTopDivider,
  disableFullHeight = false,
  children,
  containerClassName,
  topSectionClassName,
  headerCellClassName,
  rowClassName,
  withRowDelete,
  handleRowDelete,
  withCellDivider,
  withAutoScroll = true,
  deleteButton,
  paginationClassName,
  disablePagination,
  labelRowsPerPage,
  roundedNavButtons,
  onTableRowClick,
  deleteButtonId,
  disableTopNav,
  handleCellClick,
  scrollbarRefProp,
  getRowBackground,
  withCustomSearchBar,
  socialverseViewMode,
  showEmptyTableCustomPreview,
  emptyTableCustomPreview,
  showLoadingLineIndicator,
}) => {
  const scrollShow = !isLoading ? true : false;

  const styles = useStyles({
    noScroll: scrollShow,
    disableFullHeight,
    withSearchBar: withSearchBar || withCustomSearchBar,
  });
  const theme = useTheme();

  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const withPagination =
    !disablePagination && totalItems >= defaultPagination.size && !hasInfinityScroll;

  const columnDisabledIndex: Array<number> = [];
  const tableBodyRef = useRef<HTMLTableSectionElement | null>(null);
  const pathname = useLocation().pathname;

  const isSocialVerseAddVideosPage = pathname.includes(WebsiteRoutes.SocialVerseAddVideos);

  const rootPathname = useRef(pathname);

  // unnecessary request
  // useEffect(() => {
  //   console.log('unnecessary request');
  //   onSizeChange(DEFAULT_TABLE_SIZE);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const scrollOnTop = useCallback(() => {
    const scrollContainer = document.querySelector(`.${LAYOUT_CONTAINER_CLASSNAME}`)!;
    const scrollTop = scrollContainer?.scrollTop;
    if (tableBodyRef?.current && withAutoScroll) {
      const tableWrapper = document.querySelector(`#common-table-wrapper`)!;
      tableWrapper.scrollTop = 0;
      scrollContainer?.scroll(0, scrollTop);
    }
  }, [withAutoScroll]);

  useEffect(() => {
    scrollOnTop();
  }, [page, scrollOnTop]);

  useEffect(() => {
    if (!pathname.includes(rootPathname.current)) {
      scrollOnTop();
    }
  }, [pathname, scrollOnTop]);

  const renderTableHeader = () => {
    const headerCells: JSX.Element[] = [];
    const tableHeader = content.header!;

    for (const [index, columnName] of Object.keys(tableHeader!).entries()) {
      const cell = tableHeader[columnName];
      if (cell.disabled) {
        columnDisabledIndex.push(index);
        continue;
      }

      const cellWidth = cell.width || 'auto';
      const TooltipBlock =
        cell.tooltip && !cell.tooltip.fullBlock ? (
          <Tooltip
            padding="16px 16px"
            rounded="15px"
            title={cell.tooltip.title}
            text={cell.tooltip.text}
            icon={
              <HelpOutlineSharpIcon
                style={{ width: '14px', height: '14px', color: theme.palette.primary.main }}
              />
            }
          />
        ) : null;

      if (cell.sortable && onSortHeaderClick) {
        let orderDirection;
        if (getSortFieldFromSortString(sort![0]) === columnName) {
          orderDirection = getSortDirectionFromSortString(sort![0]);
        }
        headerCells.push(
          <ThSortable key={columnName} style={{ width: cellWidth }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={`${cell.justifyContent} !important` || 'start'}
              paddingLeft="10px"
            >
              {(columnName === 'userName' || columnName === 'name') &&
                (activeFilter || isSocialVerseAddVideosPage) &&
                socialverseViewMode !== TypeSocialVerse.Educational && (
                  <CheckedAllCell
                    content={content}
                    tableSize={size}
                    changeSize={onSizeChange}
                    isLoading={isLoading}
                  />
                )}
              <Box
                onClick={() => {
                  const searchColumnName =
                    columnName === 'videoStatus' && isTopgolf ? 'publishedToWebapp' : columnName;
                  onSortHeaderClick(searchColumnName);
                }}
                display="flex"
                alignItems="center"
              >
                {cell.tooltip?.fullBlock ? (
                  <Tooltip
                    padding="20px"
                    rounded="18px"
                    title={cell.tooltip.text}
                    icon={<Box style={{ display: 'flex', padding: '0' }}>{cell.name}</Box>}
                  />
                ) : (
                  cell.name
                )}{' '}
                &nbsp;
                <SortIcon orderDirection={orderDirection} /> &nbsp;
              </Box>
              {TooltipBlock}
            </Box>
          </ThSortable>,
        );
      } else {
        headerCells.push(
          <ThStyled key={columnName} style={{ width: cellWidth }}>
            <Box display="flex" alignItems="center" className={headerCellClassName}>
              {(columnName === 'userName' || columnName === 'name' || columnName === 'video') &&
                activeFilter && (
                  <CheckedAllCell
                    content={content}
                    tableSize={size}
                    changeSize={onSizeChange}
                    isLoading={isLoading}
                  />
                )}
              {cell.tooltip?.fullBlock ? (
                <Tooltip
                  padding="20px"
                  rounded="18px"
                  title={cell.tooltip.text}
                  icon={<Box style={{ display: 'flex', padding: '0' }}>{cell.name}</Box>}
                />
              ) : (
                cell.name
              )}
              &nbsp;
              {TooltipBlock}
            </Box>
          </ThStyled>,
        );
      }
    }
    return (
      <TableHeaderStyled>
        <TableHeaderRow>{headerCells}</TableHeaderRow>
      </TableHeaderStyled>
    );
  };

  const onClickRowHandler = (id: string) => () => {
    onClickRow(id);
    onTableRowClick?.(id);
  };

  const renderTableRowCells = (row: TableRow, rowIndex: number) => {
    const cells: JSX.Element[] = [];
    const tableHeader = content.header;
    for (const [index, columnName] of Object.keys(row).entries()) {
      if (columnDisabledIndex.includes(index - 1)) {
        continue;
      }

      const renderCellWithDelete = withRowDelete && index === Object.keys(row).length - 1;
      const alignment = tableHeader ? (tableHeader[columnName]?.justifyContent as any) : null;

      const cell = row[columnName];
      if (cell && columnName !== 'id' && columnName !== 'error') {
        cells.push(
          <td
            key={columnName}
            onClick={() => {
              handleCellClick?.(row.id as string, index);
            }}
            style={{
              borderRight:
                withCellDivider && index !== Object.keys(row).length - 1
                  ? '1px solid #DCDBE4'
                  : 'none',
              ...(alignment ? { textAlign: alignment } : {}),
              ...(alignment === 'end' ? { paddingRight: '30px' } : {}),
            }}
            className={row.error ? styles.errorColor : ''}
          >
            {renderCellWithDelete ? (
              <div
                style={{
                  position: 'relative',
                }}
              >
                {cell}
                {deleteButton ? (
                  <Box
                    onClick={() => {
                      if (row.id && handleRowDelete) {
                        handleRowDelete(row.id as string);
                      }
                    }}
                    className={styles.deleteButton}
                    id={`${deleteButtonId}-${rowIndex}`}
                  >
                    {deleteButton}
                  </Box>
                ) : (
                  <CloseIcon
                    className={styles.deleteIcon}
                    onClick={() => {
                      if (row.id && handleRowDelete) {
                        handleRowDelete(row.id as string);
                      }
                    }}
                  />
                )}
              </div>
            ) : (
              cell
            )}
          </td>,
        );
      }
    }
    return <>{cells}</>;
  };

  const shouldHideTableContent = isLoading && showLoadingLineIndicator;

  return (
    <CommonTableContainer padding={tablePadding} className={containerClassName}>
      <TopSectionContainer
        showTopDivider={showTopDivider}
        isSliptted={!!children}
        className={topSectionClassName}
      >
        <div>
          {withSearchBar && searchBarProps && (
            <SearchBar
              className={searchBarProps.className}
              entity={searchBarProps.entity}
              updateSearch={searchBarProps.updateSearch}
              adaptiveWidth={searchBarProps.adaptiveWidth}
              disabled={isLoading}
              placeholderText={searchBarProps.placeholderText}
              callUpdateSearchAsFunc={searchBarProps.callUpdateSearchAsFunc}
              getPredictiveSearchItems={searchBarProps.getPredictiveSearchItems}
            />
          )}
        </div>
        {isSuperAdmin || disableTopNav
          ? null
          : withPagination && (
              <Pagination
                totalPages={totalPages}
                page={page}
                onPageClick={goToPage}
                size={size}
                totalItems={totalItems}
                onChangeSize={onSizeChange}
                isPaginationDisabled={isLoading}
              />
            )}
      </TopSectionContainer>
      {children}
      <Box className={styles.box} ref={scrollbarRefProp} id="common-table-wrapper">
        {isLoading &&
          (showLoadingLineIndicator ? (
            <LinearProgress />
          ) : !hasInfinityScroll ? (
            <SpinnerContainer>
              <Spinner color="var(--spinnerColor)" />
            </SpinnerContainer>
          ) : (
            <></>
          ))}

        {noContent && !isLoading && <SpinnerContainer>{TableStrings.NoContent}</SpinnerContainer>}
        {!isLoading && showEmptyTableCustomPreview && emptyTableCustomPreview}
        {!noContent && (
          <Table stickyHeader aria-label="sticky table">
            {content.header && renderTableHeader()}

            {!shouldHideTableContent && (
              <TableBody
                ref={tableBodyRef}
                style={{
                  scrollMarginTop: '85px',
                }}
              >
                {content.rows?.map((row: TableRow, index) => {
                  return (
                    <TableRowStyled
                      selected={!!selectedRowId && row.id === selectedRowId}
                      key={uuidv4()}
                      onClick={onClickRowHandler(row.id as string)}
                      className={rowClassName}
                      data-prevent-slideout-close="true"
                      style={{
                        background: row.error
                          ? '#FC47411A'
                          : getRowBackground?.(row.id as string) || '',
                        color: row.error ? '#FC4741' : 'initial',
                        cursor: 'pointer',
                        fontSize: '13px',
                      }}
                      id={row.id === selectedRowId ? 'table-row-selected' : ''}
                    >
                      {renderTableRowCells(row, index)}
                    </TableRowStyled>
                  );
                })}
              </TableBody>
            )}
          </Table>
        )}
        {isLoading && hasInfinityScroll && <div style={{ textAlign: 'center' }}>Loading...</div>}
      </Box>
      {withPagination && (
        <Pagination
          totalPages={totalPages}
          page={page}
          onPageClick={goToPage}
          size={size}
          totalItems={totalItems}
          onChangeSize={onSizeChange}
          className={paginationClassName}
          labelRowsPerPage={labelRowsPerPage}
          roundedButtons={roundedNavButtons}
          isPaginationDisabled={isLoading}
        />
      )}
    </CommonTableContainer>
  );
};
