import { FC } from 'react';

export const NavPrevIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_909_6882)">
        <path
          d="M17 2L7 12L17 22"
          stroke="#8B89A0"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_909_6882">
          <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
};
