import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { FC } from 'react';

export type PopperPlacementsTypes =
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'left-start'
  | 'left'
  | 'left-end'
  | 'bottom-start'
  | 'bottom-end'
  | 'bottom';

export enum PopperPlacements {
  TopStart = 'top-start',
  Top = 'top',
  TopEnd = 'top-end',
  LeftStart = 'left-start',
  Left = 'left',
  LeftEnd = 'left-end',
  BottomStart = 'bottom-start',
  Bottom = 'bottom',
  BottomEnd = 'bottom-end',
}

interface CustomPopperProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  placement: PopperPlacementsTypes | undefined;
  children?: JSX.Element;
}

export const CustomPopper: FC<CustomPopperProps> = ({ open, anchorEl, placement, children }) => {
  return (
    <div>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>{children}</Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
