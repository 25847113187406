import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="9.50016"
      cy="9.00016"
      r="7.91667"
      stroke={props.color || '#027A48'}
      strokeWidth="1.5"
    />
    <path d="M17.4168 9H1.5835" stroke={props.color || '#027A48'} strokeWidth="1.5" />
    <ellipse
      cx="9.50025"
      cy="9.00016"
      rx="3.7605"
      ry="7.91667"
      stroke={props.color || '#027A48'}
      strokeWidth="1.5"
    />
  </svg>
);

const PublishedIcon = memo(SvgComponent);
export default PublishedIcon;
