import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { StatusMessages } from 'react-media-recorder';
import { VideoContainer } from './VideoContainer';

interface VideoPreviewProps {
  width?: number;
  height?: number;
  status: StatusMessages;
  stream: MediaStream | null;
}

export const RecordingPreview: React.FC<VideoPreviewProps> = ({
  stream,
  width = 290,
  height = 514,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  return (
    <VideoContainer width={width} height={height}>
      <>
        {stream && stream.active ? (
          <video ref={videoRef} width={width} autoPlay />
        ) : (
          <Box sx={{ width: '100%', height: '100%', bgcolor: 'black' }} />
        )}
      </>
    </VideoContainer>
  );
};
