import { FC } from 'react';
import { FSModal } from '../../modal/Modal';
import { useStyles } from './ConcentModal.styles';
import { Box, IconButton, Typography } from '@mui/material';
import { CloseIcon } from '../../../assets/CloseIcon';
import { DistributorsStrings } from '../../../localization/en';
import { BackgroundIcon } from './BackgroundIcon';
import { RoundedButton } from '../../Button/RoundedButton';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../constants/routes';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  videosUploadedCount: number;
}

export const ConsentModal: FC<Props> = ({ isOpen, onClose, videosUploadedCount }) => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="520px"
      padding="0"
    >
      <Box className={styles.iconWrapper}>
        <BackgroundIcon />
        <img src="/congrads.png" className={styles.congradsIcon} alt="congrads" />
        <IconButton onClick={onClose} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.container}>
        <Typography className={styles.title}>{DistributorsStrings.Congratulations}</Typography>
        <Typography className={styles.description}>{DistributorsStrings.Description}</Typography>

        {videosUploadedCount > 0 && (
          <Typography className={styles.videosUploadedText}>
            {DistributorsStrings.VideosUploaded.replace('XX', videosUploadedCount.toString())}
          </Typography>
        )}
        <Typography className={styles.videosTakeText}>
          {DistributorsStrings.VideosMayTake}
        </Typography>
        <img src="/sync-preview.png" className={styles.syncPreview} alt="sync preview" />
        <Typography className={styles.exampleText}>{DistributorsStrings.Example}</Typography>
        <RoundedButton
          title={DistributorsStrings.GoToSv}
          className={styles.goToSvButton}
          onClick={() => {
            history.push(WebsiteRoutes.SocialVerse);
          }}
          borderRadius="8px"
        />
      </Box>
    </FSModal>
  );
};
