import { Link as MuiLink } from '@mui/material';
import React from 'react';

import { LinkProps, linkStyle } from './Link.helper';

export const ExternalLink: React.FC<LinkProps> = ({ title, href, blank, ...rest }) => {
  return (
    <MuiLink href={href} sx={linkStyle} target={blank ? '_blank' : undefined} {...rest}>
      {title}
    </MuiLink>
  );
};
