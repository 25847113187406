import { FC } from 'react';

export const PictureIconDnD: FC<{ color?: string }> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.5 2.99969H7.8C6.11984 2.99969 5.27976 2.99969 4.63803 3.32668C4.07354 3.6143 3.6146 4.07324 3.32698 4.63772C3 5.27946 3 6.11954 3 7.79969V16.1997C3 17.8799 3 18.7199 3.32698 19.3617C3.6146 19.9262 4.07354 20.3851 4.63803 20.6727C5.27976 20.9997 6.11984 20.9997 7.8 20.9997H17C17.93 20.9997 18.395 20.9997 18.7765 20.8975C19.8117 20.6201 20.6204 19.8114 20.8978 18.7762C21 18.3947 21 17.9297 21 16.9997M19 7.99969V1.99969M16 4.99969H22M10.5 8.49969C10.5 9.60426 9.60457 10.4997 8.5 10.4997C7.39543 10.4997 6.5 9.60426 6.5 8.49969C6.5 7.39513 7.39543 6.49969 8.5 6.49969C9.60457 6.49969 10.5 7.39513 10.5 8.49969ZM14.99 11.9178L6.53115 19.6077C6.05536 20.0403 5.81747 20.2565 5.79643 20.4439C5.77819 20.6063 5.84045 20.7673 5.96319 20.8752C6.10478 20.9997 6.42628 20.9997 7.06929 20.9997H16.456C17.8951 20.9997 18.6147 20.9997 19.1799 20.7579C19.8894 20.4544 20.4547 19.8891 20.7582 19.1796C21 18.6144 21 17.8948 21 16.4557C21 15.9714 21 15.7293 20.9471 15.5038C20.8805 15.2205 20.753 14.9551 20.5733 14.7261C20.4303 14.5439 20.2412 14.3926 19.8631 14.0902L17.0658 11.8524C16.6874 11.5496 16.4982 11.3982 16.2898 11.3448C16.1061 11.2977 15.9129 11.3038 15.7325 11.3624C15.5279 11.4288 15.3486 11.5918 14.99 11.9178Z"
      stroke={color || '#475467CC'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
