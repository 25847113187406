import { FC } from 'react';
import { Grid, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { ErrorPageStrings } from '../../common/localization/en';
import { ErrorImage } from './Venuewizard.style';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  text: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '150%',
  },
  button: {
    border: `1px solid ${theme.palette.error.dark}`,
    color: theme.palette.error.dark,
    minWidth: '200px',
    textAlign: 'center',
  },
}));
interface OrgCreationFailureProps {
  onStartAgain: () => void;
}

export const OrgCreationFailure: FC<OrgCreationFailureProps> = ({ onStartAgain }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={12} md={4}>
          <ErrorImage src="/error.png" />
        </Grid>
        <Grid item xs={10} md={5}>
          <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={3}>
            <Grid item xs={10}>
              <p className={classes.text}>{ErrorPageStrings.OrgFailure}</p>
            </Grid>
            <Grid item xs={3}>
              <Button className={classes.button} onClick={onStartAgain}>
                {ErrorPageStrings.startAgain}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
