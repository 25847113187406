import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PeekAccount } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { PeekEndpoints } from '../../api/endpoints';

interface PeekSlice {
  connectedAccount: PeekAccount | null;
}

const initialState: PeekSlice = {
  connectedAccount: null,
};

interface GetConnectedPeekAccountParams {
  accountId: string;
}

export const getConnectedPeekAccount = createAsyncThunk(
  'peek/getAccount',
  async (options: GetConnectedPeekAccountParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetConnectedPeekAccountParams, PeekAccount>({
        url: PeekEndpoints.GetPeekAccount,
        requiresToken: true,
        params: options,
      });
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

interface ConnectAccountParams {
  accountId: string;
  peekAccountId: string;
}

export const connectPeekAccount = createAsyncThunk(
  'peek/connectAccount',
  async (options: ConnectAccountParams, { rejectWithValue }) => {
    try {
      return await httpClient.post<ConnectAccountParams, PeekAccount>({
        url: PeekEndpoints.ConnectAccount,
        requiresToken: true,
        params: options,
      });
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

interface DisconnectAccountParams {
  accountId: string;
}

export const disconnectPeekAccount = createAsyncThunk(
  'peek/disconnectAccount',
  async (options: DisconnectAccountParams, { rejectWithValue }) => {
    try {
      return await httpClient.post<DisconnectAccountParams, { success: string }>({
        url: PeekEndpoints.DisconnectAccount,
        requiresToken: true,
        params: options,
      });
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

const peekSlice = createSlice({
  name: 'storyFilters',
  initialState,
  reducers: {},
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getConnectedPeekAccount.fulfilled, (state, { payload }) => {
      state.connectedAccount = payload;
    });
    reducersBuilder.addCase(getConnectedPeekAccount.rejected, (state) => {
      state.connectedAccount = null;
    });
    reducersBuilder.addCase(connectPeekAccount.fulfilled, (state, { payload }) => {
      state.connectedAccount = payload;
    });
    reducersBuilder.addCase(connectPeekAccount.rejected, (state) => {
      state.connectedAccount = null;
    });
    reducersBuilder.addCase(disconnectPeekAccount.fulfilled, (state) => {
      state.connectedAccount = null;
    });
  },
});

// export const {} = peekSlice.actions;
export default peekSlice.reducer;
