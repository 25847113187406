import { FC, Fragment, useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { Button, Grid, Box, Avatar, TextField } from '@mui/material';

import { UserApiModel, UserStatuses } from '../../../api/models/users';
import { TeamSettingsStrings } from '../../localization/en';
import { getDisplayName, getLocalDateString } from '../../../services/utilities';

import { genererateItems, useStyles } from './TeamDetails.helper';
import { UserValues, validationSchemaAccountUser } from './TeamDetails.helper';
import { updateUserById } from '../../../store/slices/userDetailsSlice';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { sendOneUserInvite } from '../../../store/slices/heroesSlice';
import { useToasts } from 'react-toast-notifications';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ClientTypes } from '../../../api/models/common';

interface TeamDetailsProps {
  userDetails: UserApiModel;
}

export const TeamDetails: FC<TeamDetailsProps> = ({ userDetails }) => {
  const classes = useStyles();
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const [edit, setEdit] = useState(false);
  const [inviteSent, setInviteSet] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();

  const UserAccountFormik = useFormik({
    initialValues: {
      displayName: userDetails.displayName || getDisplayName(userDetails.name),
      name: userDetails.name,
      returnUrl: userDetails.returnUrl || '',
      phoneNumber: userDetails.phoneNumber,
      email: userDetails.email,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchemaAccountUser,
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  useEffect(() => {
    if (inviteSent) {
      setTimeout(() => {
        setInviteSet(false);
      }, 2000);
    }
  }, [inviteSent]);

  const { handleSubmit, values, handleChange, errors } = UserAccountFormik;

  const onEditClick = () => {
    setEdit(true);
  };

  const leaveEdit = () => {
    setEdit(false);
  };

  const hadnleSubmitClick = () => {
    dispatch(updateUserById({ update: values, id: userDetails.id }));
    leaveEdit();
  };

  const submitForm = (values: UserValues) => {
    dispatch(updateUserById({ update: values, id: userDetails.id }));
    leaveEdit();
  };
  const ShouldDisableSubmit = () => {
    if (errors.displayName || errors.email || errors.name || errors.phoneNumber || errors.returnUrl)
      return true;
    return false;
  };

  const sendInvite = async () => {
    const response = await sendOneUserInvite({ id: userDetails.id });
    if (response.id) {
      const message = userDetails.name
        ? TeamSettingsStrings.UserReceivedInvite.replace(':name', userDetails.name)
        : TeamSettingsStrings.InviteSentNoUserName;
      setInviteSet(true);
      addToast(message, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    } else {
      addToast(TeamSettingsStrings.UserFailedInvite, { appearance: 'error' });
      setInviteSet(false);
    }
  };
  const shouldEnableSendInvite = () => {
    return !(userDetails.status === UserStatuses.inactive);
  };

  const items = genererateItems(userDetails);

  const renderInfo = () => {
    return items.map((item) => (
      <Fragment key={item.key}>
        <Grid item xs={3}>
          <p className={classes.Label}>{item.Label}</p>
        </Grid>
        <Grid item xs={9}>
          <p className={classes.Value}>{item.value}</p>
        </Grid>
      </Fragment>
    ));
  };

  const returnUrl = useMemo(() => {
    if (!userDetails.returnUrl) {
      return '';
    }

    if (userDetails.returnUrl.indexOf('http') === -1) {
      return `http://${userDetails.returnUrl}`;
    }

    return userDetails.returnUrl;
  }, [userDetails.returnUrl]);

  const renderEditInfo = () => {
    return items.map((item) => (
      <Fragment key={item.key}>
        <Grid item xs={3}>
          <p className={classes.Label}>{item.Label}</p>
        </Grid>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            id={item.key}
            value={values[item.key]}
            onChange={handleChange}
            helperText={errors[item.key]}
            disabled={item.key === 'email'}
            fullWidth
          />
        </Grid>
      </Fragment>
    ));
  };

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={4}>
            <Box className={classes.avatarRow}>
              <Avatar
                variant="rounded"
                className={classes.avatar}
                src={userDetails.photoUrl || ''}
              />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Grid container justifyContent="center" alignItems="center" spacing={3}>
              <Grid item xs={4}>
                <p className={classes.Label}>{TeamSettingsStrings.JoinedDate}</p>
              </Grid>
              <Grid item xs={8}>
                <p className={classes.Value}>{getLocalDateString(userDetails.createdAt)}</p>
              </Grid>
              <Grid item xs={2}>
                <p className={classes.Label}>{TeamSettingsStrings.Url}</p>
              </Grid>
              {edit ? (
                <Grid item xs={9}>
                  <TextField
                    variant="outlined"
                    id="returnUrl"
                    value={values.returnUrl}
                    onChange={handleChange}
                    error={!!errors.returnUrl}
                    helperText={errors.returnUrl}
                    fullWidth
                  />
                </Grid>
              ) : (
                <Grid item xs={10}>
                  <a target="_blank" rel="noreferrer" href={returnUrl} className={classes.Value}>
                    {userDetails.returnUrl}
                  </a>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center" spacing={5}>
              {edit ? renderEditInfo() : renderInfo()}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {clientType !== ClientTypes.ETS ? (
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={6}>
                  {edit ? (
                    <Button variant="outlined" color="primary" onClick={leaveEdit} fullWidth>
                      {TeamSettingsStrings.Cancel}
                    </Button>
                  ) : (
                    <Button variant="outlined" color="primary" onClick={onEditClick} fullWidth>
                      {TeamSettingsStrings.EditProfile}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={6}>
                  {edit ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      onClick={hadnleSubmitClick}
                      disabled={ShouldDisableSubmit()}
                    >
                      {TeamSettingsStrings.UpdateProfile}
                    </Button>
                  ) : inviteSent ? (
                    <div className={classes.InviteSentWrapper}>
                      <Button
                        className={classes.InviteSent}
                        startIcon={<CheckCircleOutlineIcon className={classes.CheckIcon} />}
                        fullWidth
                      >
                        {TeamSettingsStrings.InviteSentButton}{' '}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={sendInvite}
                      fullWidth
                      disabled={shouldEnableSendInvite()}
                    >
                      {TeamSettingsStrings.SendInvite}
                    </Button>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
