import { FC, SetStateAction, useState } from 'react';
import { Grid, TextField, Button } from '@mui/material';

import {
  CommonStrings,
  VenueCreationWizardStrings,
} from '../../../../../../../common/localization/en';
import { urlMaskRegex } from '../../../../../../../services/utilities';
import { useStyles } from './OrganizationBlock.helper';
import { OrgCreationFailure } from '../../../../../../venuewizard/OrgCreationFailure';

interface OrganizationBlockProps {
  setVenuName: (name: SetStateAction<string>) => void;
  venuName: string;
  setCompanyName: (name: SetStateAction<string>) => void;
  companyName: string;
  teamName: string;
  setTeamName: (name: SetStateAction<string>) => void;
  companyUrl: string;
  setCompanyUrl: (name: SetStateAction<string>) => void;
}

export const OrganizationBlock: FC<OrganizationBlockProps> = ({
  setVenuName,
  venuName,
  // setCompanyName,
  // companyName,
  teamName,
  setTeamName,
  companyUrl,
  setCompanyUrl,
}) => {
  const classes = useStyles();
  // const [tempCompanyName, setTemCompanyName] = useState(companyName);
  const [tempVenuName, setTempVenuName] = useState(venuName);
  const [tempTeamName, setTempTeamName] = useState(teamName);
  const [showWizardFailure, setShowWizardFailure] = useState(false);
  const [tempCompanyUrl, setTempCompanyUrl] = useState(companyUrl);
  const [isUrlValid, setIsUrlValid] = useState(!!companyUrl);

  // const onChangeCompanyName = (e: { target: { value: string } }) => {
  // setTemCompanyName(e.target.value);
  // };
  const onChangeCompanyUrl = (e: { target: { value: string } }) => {
    if (!e.target.value.match(urlMaskRegex)) {
      setIsUrlValid(false);
    } else setIsUrlValid(true);
    setTempCompanyUrl(e.target.value);
  };

  const onChangeVenuName = (e: { target: { value: string } }) => {
    setTempVenuName(e.target.value);
  };

  const handleSubmitClick = () => {
    // setCompanyName(tempCompanyName);
    setVenuName(tempVenuName);
    setTeamName(tempTeamName);
    setCompanyUrl(tempCompanyUrl);
  };

  const onStartAgain = () => {
    setShowWizardFailure(false);
    setTempTeamName('');
    setTempVenuName('');
    // setTemCompanyName('');
    setCompanyUrl('');
  };

  if (showWizardFailure) {
    return (
      <div className={classes.wrapper}>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={10}>
            <OrgCreationFailure onStartAgain={onStartAgain} />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" spacing={3}>
        {/* <Grid item xs={6}>
          <p className={classes.title}>{VenueCreationWizardStrings.Organization}</p>
        </Grid> */}
        <Grid item xs={12}>
          <p className={classes.title}>{VenueCreationWizardStrings.Workspace}</p>
        </Grid>
        {/* <Grid item xs={6}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <p className={classes.label}>{VenueCreationWizardStrings.CompanyName}</p>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="company-name"
                variant="outlined"
                value={tempCompanyName}
                onChange={onChangeCompanyName}
                disabled={!!companyName}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid> */}
        <Grid item xs={6}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item xs={4}>
              <p className={classes.label}>{VenueCreationWizardStrings.WorkspaceName}</p>
            </Grid>
            <Grid item xs={8}>
              <TextField
                id="account-name"
                variant="outlined"
                value={tempVenuName}
                onChange={onChangeVenuName}
                disabled={!!venuName}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container justifyContent="center" alignItems="flex-start" spacing={3}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center" spacing={3}>
                <Grid item xs={4}>
                  <p className={classes.label}>{VenueCreationWizardStrings.CompanyUrl}</p>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    id="company-url"
                    variant="outlined"
                    value={tempCompanyUrl}
                    onChange={onChangeCompanyUrl}
                    disabled={!!companyUrl}
                    error={!isUrlValid}
                    helperText={isUrlValid ? '' : VenueCreationWizardStrings.invalidUrl}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmitClick}
                disabled={(!!venuName && !!companyUrl) || !isUrlValid || !tempVenuName}
              >
                {CommonStrings.Submit}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
