import { VideoDetails } from './models/videos';

export enum ErrorMessages {
  ExpiredJwtException = 'ExpiredJwtException',
}

export const DefaultCTABTNText = {
  ETS: 'Donate',
  FUNDRAISING: 'Donate',
  SALES: "Let's Connect",
  MARKETING: 'Check it out',
};

export const DEFAULT_VIDEO_DETAILS = (): VideoDetails => ({
  ctaBtnLinkUrl: '',
  ctaBtnText: DefaultCTABTNText.MARKETING,
  editable: 'full',
  metaDescription: 'Description',
  metaTitle: '',
  videoText: '',
  videoMessage: '',
});
