import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  fetchUserDetails,
  getCampaignUser,
  resetCampaignUser,
  updateUserById,
} from '../../../store/slices/userDetailsSlice';
import TransitionWrapper from '../TransitionWrapper/TransitionWrapper';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { Box, Typography } from '@mui/material';
import { useStyles } from './CreatorDetailsSlideout.styles';
import { Spinner } from '../../assets/Spinner';
import IconButton from '@mui/material/IconButton';
import { SlideoutCloseIcon } from '../../assets/SlideoutCloseIcon';
import { DetailsSidebarStrings } from '../../localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import { UserStatusDropDown } from '../../UserStatusDropDown/UserStatusDropDown';
import { UserStatuses } from '../../../api/models/users';
import { useToasts } from 'react-toast-notifications';
import { UserDetails } from '../UserDetails/UserDetails';
import { TabItem, TabsSelect } from './TabsSelect/TabsSelect';
import { Clips } from './Clips/Clips';
import { getVideos, reset as resetVideos } from '../../../store/slices/videosSlice';
import { SalesHeroInfoRewards } from '../NewDetailsModal/DetailsModalContent/salesComponents/SalesHeroInfoRewards';
import { isChrome } from 'react-device-detect';
import CampaignActivity from './CampaignActivity/CampaignActivity';
import {
  getUserIncentiveCampaigns,
  getUserInvitedCampaigns,
  resetActiveUserInvitedCampaignsCount,
} from '../../../store/slices/campaignsSlice';

interface Props {
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
  campaignId?: string;
  onUpload?: () => void;
}

export const CreatorDetailsSlideout: FC<Props> = ({
  isOpen,
  handleClose,
  userId,
  campaignId,
  onUpload,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();

  const { isLoadingUserDetails, isLoadingCampaignUser, userDetails } = useTypedSelector(
    (state) => state.userDetails,
  );
  const { isLoading: isLoadingVideos, sort, items } = useTypedSelector((state) => state.videos);
  const isLoading = (isLoadingUserDetails || isLoadingCampaignUser || isLoadingVideos) && isOpen;
  const {
    currUserActiveCampaign,
    page,
    sort: campaignSort,
    activeUserInvitedCampaginsCount,
    userInvitedCampaigns,
  } = useTypedSelector((state) => state.campaigns);
  const [slideoutHeight, setSlideoutHeight] = useState<number>(0);

  const isDisplayed = useRef(false);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const slideout = target.closest('#slideout-root') || target.closest('.MuiModal-root');
    if (!slideout && isDisplayed.current) {
      handleClose();
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }

    const campaignUserId = campaignId || currUserActiveCampaign?.id;

    if (campaignUserId && userId) {
      dispatch(getCampaignUser({ userId, campaignId: campaignUserId }));
    } else {
      dispatch(resetCampaignUser());
    }
  }, [userId, dispatch, campaignId, currUserActiveCampaign?.id]);

  useEffect(() => {
    if (userDetails) {
      const { id: userId, accountId } = userDetails;

      dispatch(
        getVideos({
          accountId,
          userId,
          pageable: { page: 0, size: 10000, sort },
        }),
      );
    }
  }, [dispatch, sort, userDetails]);

  useEffect(() => {
    if (isOpen) {
      trackEvent(CreatorsTableEventNames.CreatorDetailsModal, { selectedUserId: userId });
    }
  }, [isOpen, trackEvent, userId]);

  useEffect(() => {
    if (userDetails) {
      dispatch(getUserIncentiveCampaigns({ userId: userDetails.id, withCampaignDetails: true }));

      if (userDetails.phoneNumber) {
        dispatch(
          getUserInvitedCampaigns({
            accountId: userDetails.accountId,
            phoneNumber: userDetails.phoneNumber,
            pageable: { page, size: 1000, sort: campaignSort },
          }),
        );
      }
    }
  }, [userDetails, dispatch, campaignSort, page]);

  const handleStatusUpdate = (status: UserStatuses) => {
    if (!userDetails) {
      return;
    }
    dispatch(updateUserById({ update: { status }, id: userDetails.id }));
    const isActive = status === UserStatuses.active;

    const toastMessage = isActive
      ? DetailsSidebarStrings.ActiveStatusMessage
      : DetailsSidebarStrings.DeactivatedStatusMessage;
    addToast(toastMessage, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
    trackEvent(CreatorsTableEventNames.CreatorDetailsModalChangeStatus, {
      value: isActive,
    });
  };

  const tabsList: TabItem[] = useMemo(() => {
    return [
      {
        title: DetailsSidebarStrings.Clips,
        count: campaignId
          ? items.filter((video) => video.campaignId === campaignId).length
          : userDetails?.videos,
        content: (
          <Clips
            user={userDetails}
            campaignId={campaignId}
            slideoutHeight={slideoutHeight}
            onUpload={onUpload}
          />
        ),
      },
      {
        title: DetailsSidebarStrings.CampaignActivity,
        count: userDetails ? userDetails.activeCampaigns + activeUserInvitedCampaginsCount : 0,
        content: <CampaignActivity />,
      },
      {
        title: DetailsSidebarStrings.CreatorStats,
        content: (
          <SalesHeroInfoRewards
            isCampaignUser={!!campaignId}
            userInvitedCampaigns={userInvitedCampaigns}
          />
        ),
      },
    ];
  }, [
    campaignId,
    items,
    userDetails,
    slideoutHeight,
    onUpload,
    activeUserInvitedCampaginsCount,
    userInvitedCampaigns,
  ]);

  return (
    <TransitionWrapper
      show={isOpen}
      onChange={(isOpened) => {
        isDisplayed.current = isOpened;

        if (!isOpened) {
          dispatch(resetVideos());
          dispatch(resetActiveUserInvitedCampaignsCount());
        }
      }}
    >
      {isLoading && (
        <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
          <Spinner color="var(--lightBlue)" />
        </Box>
      )}
      <Box
        className={classes.container}
        display={isLoading ? 'none' : 'block'}
        style={{
          // due to total site zoom 0.9. In chrome vh calculation depends on zoom
          height: isChrome ? 'calc(110vh - 80px)' : 'calc(100vh - 80px)',
        }}
        id="slideout-root"
        ref={(element: HTMLElement) => {
          if (element) {
            setSlideoutHeight(element.offsetHeight);
          }
        }}
      >
        <Box
          className={classes.divider}
          style={{
            top: userDetails?.phoneNumber ? '200px' : '184px',
          }}
        />
        <Box className={classes.topWrapper}>
          <Box display="flex" alignItems="center">
            <IconButton type="button" aria-label="Close modal" onClick={handleClose}>
              <SlideoutCloseIcon />
            </IconButton>
            <Typography className={classes.title}>
              {isHealthCare
                ? DetailsSidebarStrings.HeroesDetailsTitle_TruBlu
                : DetailsSidebarStrings.HeroesDetailsTitle}
            </Typography>
          </Box>
          {userDetails && (
            <UserStatusDropDown
              status={userDetails.status}
              handleStatusUpdate={handleStatusUpdate}
              id={userDetails.id}
              name={userDetails.name}
              lastInvitationSentAt={userDetails.lastInvitationSentAt}
            />
          )}
        </Box>
        <Box className={classes.userDetailsWrapper}>
          {userDetails && <UserDetails userDetails={userDetails} />}
        </Box>
        <Box className={classes.tabsWrapper}>
          <TabsSelect tabsList={tabsList} />
        </Box>
      </Box>
    </TransitionWrapper>
  );
};
