import { Route, Switch } from 'react-router-dom';
import { CompanyDetails } from '../accounts/CompanyDetails/CompanyDetails';
import { TopBar } from '../../common/components/topBar/TopBar';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';
import { MyProfile } from '../../common/components/MyProfile/MyProfile';
import { WebsiteRoutes } from '../../common/constants/routes';
import { subnavigationItems, useStyles } from './Workspace.helper';
import { Members } from '../../common/components/WorkspaceManagers/Members';
import { WorkspaceStrings } from '../../common/localization/en';

export const Workspace = () => {
  const classes = useStyles();
  return (
    <>
      <TopBar sectionTitle={WorkspaceStrings.AccountDetails}>
        <WebappSubnavigation items={subnavigationItems} />
      </TopBar>
      <div className={classes.root}>
        <Switch>
          <Route exact path={WebsiteRoutes.WorkspaceCompany}>
            <CompanyDetails />
          </Route>
          <Route exact path={WebsiteRoutes.WorkspaceProfile}>
            <MyProfile />
          </Route>
          <Route exact path={WebsiteRoutes.WorkspaceMembers}>
            <Members />
          </Route>
          <Route exact path={WebsiteRoutes.WorkspaceBilling}>
            <div>Billing</div>
          </Route>
        </Switch>
      </div>
    </>
  );
};
