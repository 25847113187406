import { makeStyles } from '@mui/styles';

import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';

import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';

interface ButtonProps {
  brandColor?: string;
  textColor?: string;
}
interface TitleProps {
  color: string;
}
export const BrandButton = styled.div<ButtonProps>`
  display: inline-block;
  background-color: ${(props) => props.brandColor || 'var(--black)'};
  color: ${(props) => props.textColor || 'var(--black)'};
  border: 1px solid;
  border-color: ${(props) => (!props.brandColor && props.textColor) || 'var(--black)'};
  height: 25px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
  letter-spacing: -1px;
`;

export const CTABrandButton = styled(BrandButton)`
  align-self: center;
  width: auto;
  padding: 0 10px;
  font-size: 10px;
  font-weight: 600;
  line-height: 24px;
  border-color: ${(props) => props.brandColor || 'var(--black)'};
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  border-radius: var(--iphoneXborderRadius) var(--iphoneXborderRadius) var(--iphoneXborderRadius)
    var(--iphoneXborderRadius);
  z-index: 0;
`;

export const MenuDivWrapper = styled.div`
  padding-top: 18px;
  padding-left: 16px;
  height: cal(var(--iphoneXHeight) / 8);
`;

export const MenuBar = styled(MenuIcon)`
  height: 20px;
  width: 20px;
  color: var(--white);
`;

export const EduTitle = styled.span<TitleProps>`
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  color: ${(props) => props.color || 'var(--white)'};
`;

export const EduTitleWrapper = styled.div`
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const LogoSection = styled.img`
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 15px;
  width: 40px;
  height: 40px;
  z-index: 10;
`;

export const SliderImage = styled.img`
  height: 280px;
  border-radius: 5px;
  position: relative;

  &::after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #222;
  }
`;

export const ShadowBox = styled.div`
  position: absolute;
  height: 200px;
  top: 200px;
  width: 100%;
  background: linear-gradient(180deg, #000000 11.98%, rgba(28, 29, 34, 0) 100%);
  transform: rotate(-180deg);
  box-shadow: 0px 10px 40px rgba(10, 25, 107, 0.08);
`;

export const EduText = styled.p`
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);
  width: 180px;
`;

export const EduTextWrapper = styled.div`
  width: 200px;
  padding: 20px;
  position: absolute;
  height: 200px;
  top: 300px;
  width: 100%;
  z-index: 6;
`;

export const ButtonsWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  margin-top: auto;
  padding: 16px;
`;

export const Tab = styled(TabUnstyled).attrs({
  type: 'button',
})`
  color: white;
  cursor: pointer;
  background-color: #c1bcb5;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  margin: 6px 6px;
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: var(--BrandDefault);
  }

  &:focus {
    color: #fff;
    border-radius: 8px;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: var(--BrandDefault);
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    & path {
      fill: var(--white);
    }
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const TabsList = styled(TabsListUnstyled)`
  min-width: 272px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

export const useStyles = makeStyles(() => ({
  iphoneFrameWrapper:{
    padding:'10px',
    paddingTop:'80px',
    background:"url(/iphone_safari_background.jpg)",
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    height:'100%',
    borderRadius: 20,
  },
  urlPreview: {
    position:'absolute',
    fontSize: '9px',
    fontWeight: 400,
    opacity:'.5',
    top:'42px',
    left:'24px',
    color:'white',
    width:'70%',
    overflow:'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:'nowrap'
  },
  caption: {
    fontFamily: `"Poppins, sans-serif`,
    color: 'var(--PreviewSubTitle)',
  },
  SMSContainer: {
    width: 230,
    padding: '0',
  },
  captionVariant: {
    fontStyle: 'italic',
    fontWeight: 600,
    color: 'var(--primaryTextColor)',
    opacity: '24%',
    marginBottom: '8px',
    wordBreak: 'break-all',
  },

  SMSPreview: {
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
    marginBottom: '20px',
  },
  SMSMediaContainer: {
    padding: '0',
    position: 'relative',
    display:'flex'
  },
  PlayButtonPreview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  NoTGSMSPicture:{
    height: '100%',
    width: '100%',
    objectFit:'fill'
  },
  SMSPicture: {
    height: '275px',
    width: '100%',
    paddingBottom: '8px',
  },
  SMSImageContainerNoTG:{
    background:'url(/non-tg-share-preview-background.jpg)',
    display:'flex',
    height:'225px',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    position:'relative'
  },
  SMSImageContainer:{
    display:'flex',
    height:'225px',
    background:'url(/filter_image_preview.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    position:'relative'
    // width:'100%'
  },
  SMSPreviewTitle: {
    fontWeight: 700,
    lineHeight: '19px',
    textAlign: 'left',
    paddingLeft: '8px',
    paddingRight: '8px',
    wordBreak: 'break-all',
  },
  SMSCardContent: {
    paddingBottom: '0 !important',
    marginBottom: 0,
  },
  SMSPreviewCaption: {
    wordBreak: 'break-all',
    paddingLeft: '8px',
    paddingRight: '8px',
    fontSize: '12px',
    lineHeight: '12px',
    textAlign: 'left',
    opacity: '40%',
    marginBottom: '20px',
  },
  SMSCahtMessageBox: {
    width: '253px',
    left: '10px',
    top: '0px',
    borderRadius: '10px 10px 0px 10px',
    background: '#2D5AF0',
    padding: '10px 20px 10px 10px',
    transform: 'translateX(-22px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  SMSCahtMessage: {
    // wordBreak: 'break-all',
    color: '#FFFFFF',
    textAlign: 'left',
    fontSize: '16px',
    lineHeight: '16px',
  },
  SMSPreviewDelivered: {
    textAlign: 'right',
    color: 'var(--primaryTextColor)',
    opacity: '40%',
    fontSize: '13px',
  },

  FacobookContainer: {
    width: 435,
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  FacobookPreview: {
    borderRadius: '0px',
    padding: '0px, 0px, 10px, 0px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
  },
  FacobookMediaContainer: {
    padding: '0',
    position: 'relative',
  },
  FacobookPicture: {
    height: '146px',
    width: '100%',
  },
  FacobookCardContent: {
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: 0,
  },
  FacobookPreviewCaption: {
    wordBreak: 'break-all',
    fontSize: '13px',
    lineHeight: '13px',
    textAlign: 'left',
    opacity: '40%',
    marginBottom: '8px',
  },
  FacobookreviewTitle: {
    wordBreak: 'break-all',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '25px',
    textAlign: 'left',
  },

  TwitterContainer: {
    width: '354px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  TwitterPreview: {
    borderRadius: '10px',
    padding: '0px, 0px, 20px, 0px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
  },
  TwitterMediaContainer: {
    padding: '0',
    position: 'relative',
  },
  TwitterPreviewContent: {
    paddingTop: '8px !important',
    paddingBottom: '20px !important',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  TwitterPreviewTitle: {
    wordBreak: 'break-all',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '25px',
    textAlign: 'left',
  },
  TwitterPreviewCaption: {
    wordBreak: 'break-all',
    fontSize: '13px',
    lineHeight: '13px',
    textAlign: 'left',
    opacity: '40%',
  },

  EmailContainer: {
    margin: 0,
    width: 230,
    padding: '0',
  },
  EmailInput: {
    wordBreak: 'break-all',
    fontSize: '13px !important',
    lineHeight: '24px !important',
    opacity: '60% !important',
    marginBottom: '10px !important',

    '&::after': {
      content: '""',
      display: 'block',
      width: '100%',
      opacity: '20%',
      borderBottom: '0.5px solid #000000',
      height: '2px',
      bottom: '-4px',
    },
  },
  EmailTitle: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000000',
    wordBreak: 'break-all',
  },
  EmailPreview: {
    borderRadius: '0px',
    padding: '0px, 0px, 20px, 0px',
    boxShadow: '',
    marginBottom: '10px',
  },
  EmailMediaContainer: {
    padding: '0',
    position: 'relative',
  },
  EmailPicture: {
    height: '230px',
    width: '100%',
    background:'url(/filter_image_preview.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
  },
  
  EmaitButton: {
    height: '48px',
    width: '230px',
    borderRadius: '8px',
    background: 'var(--TopgolfBlue)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  EmailMessage: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '13px',
    fontWeight: 600,
    wordBreak: 'break-all',
  },

  LinkedInContainer: {
    margin: 0,
    width: '267px',
    padding: '0',
  },
  LinkedInPreview: {
    borderRadius: '5px',
    padding: '0px, 0px, 0px, 0px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
    marginBottom: '10px',
    backgroundColor: 'rgba(235, 237, 251, 1)',
  },
  LinkedInPreviewContainer: {
    margin: 0,
    width: '267px',
    padding: '0',
    paddingTop: '4px',
    position: 'relative',
  },
  NoTGLinkedInPicture: {
    height: '267px',
    width: '100%',
    background:'url(/non-tg-share-preview-background.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
  },
  LinkedInPicture: {
    height: '267px',
    width: '100%',
    background:'url(/filter_image_preview.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
  },
  LinkedInCardContent: {
    paddingTop: '8px',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingBottom: '4px !important',
  },
  LinkedInpreviewTitle: {
    wordBreak: 'break-all',
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '11px',
  },
  LinkedInpreviewCaption: {
    wordBreak: 'break-all',
    fontSize: '9px',
    lineHeight: '9px',
  },

  LinkIconContainer: {
    margin: 0,
    width: '284px',
    padding: '0',
  },
  LinkIconOrganizationCaption: {
    borderRadius: '10px 10px 0px 0px',
    padding: '8px 30px 8px 14px',
    backgroundColor: 'rgba(231, 232, 235, 1)',
    maxWidth: '50%',
    lineHeight: '12px',
    whiteSpace:'nowrap'
  },
  LinkIconOrganization: {
    color: '#000',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
    wordBreak: 'break-all',
  },
  LinkIconPreview: {
    background: 'rgba(231, 232, 235, 1)',
    borderRadius: '0px 0px 20px 20px',
    boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.15)',
    padding: '40px 20px 26px 20px',
  },
  LinkIconTitle: {
    wordBreak: 'break-all',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: '8px',
  },
  LinkIconMediaContainer: {
    height: '244px',
    width: '100%',
    marginBottom: '10px',
    padding: '0',
    position: 'relative',
  },
  NoTGLinkIconPicture: {
    borderRadius: '16px',
    background:'url(/non-tg-share-preview-background.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    height:'225px'
  },
  LinkIconPicture: {
    borderRadius: '16px',
    background:'url(/filter_image_preview.jpg)',
    backgroundSize:'cover',
    backgroundRepeat:'no-repeat',
    height:'225px'
  },
  LinkIconButton: {
    padding: '8px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  LinkIconMessage: {
    wordBreak: 'break-all',
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '13px',
  },
}));
