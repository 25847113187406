import { useEffect, useState, useCallback, FC } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  fetchUserActivities,
  fetchUserMetrics,
  approveReward,
  fetchUserDetails,
  getCampaignUser,
} from '../../../../../store/slices/userDetailsSlice';
import { segmentTrackCall } from '../../../../../utils/segment';
import { segmentActions } from '../../../../constants/constants';
import { WebsiteRoutes } from '../../../../constants/routes';
import {
  HeroDetailsStrings,
  NotFoundPageStrings,
  VenueCreationWizardStrings,
} from '../../../../localization/en';
import { RewardsBlock } from '../../../HeroesDetails/components/RewardsBlock';
import { activeTabsList, useStyles, ActiveTab } from '../../../HeroesDetails/HeroesDetails.helper';
import { FulfillRewardModal } from '../../../FulfillRewardModal/FulfillRewardModal';
import { SalesHeroInfoBlock } from './SalesHeroInfoBlock';
import { SalesVideosBlock } from './SalesVideosBlock/SalesVideosBlock';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../../../constants/events/creatorsTableEvents';
import { RewardCampaignsEvents } from '../../../../constants/events/rewardCampaignsEvents';
import {
  getUserIncentiveCampaigns,
  resetUserIncentiveCampaigns,
} from '../../../../../store/slices/campaignsSlice';

interface SalesHeroDetailsProps {
  userId: string;
  onClickOutsideDisable?: (value: boolean) => void;
  campaignId?: string;
  entity?: 'heroes' | 'campaigns';
}
export const SalesHeroDetails: FC<SalesHeroDetailsProps> = ({
  userId,
  onClickOutsideDisable,
  campaignId,
  entity,
}) => {
  const { addToast } = useToasts();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { currUserActiveCampaign, campaignSummaryUserDetails } = useTypedSelector(
    (state) => state.campaigns,
  );

  const { userDetails, userMetrics, lastUpdated, campaignUser } = useTypedSelector(
    (state) => state.userDetails,
  );

  const { trackEvent } = useTrackEvent();

  let isManageRewardsRedemptionEntry = true;
  if (match.url.includes(WebsiteRoutes.Heroes || WebsiteRoutes.RewardsLeaderboard)) {
    isManageRewardsRedemptionEntry = true;
  }

  const [activeTab, setActiveTab] = useState<ActiveTab>(
    campaignSummaryUserDetails.fromRecentVideos ? ActiveTab.Videos : ActiveTab.Rewards,
  );
  const [isRedeemPointsModal, setIsRedeemPointsModal] = useState(false);

  useEffect(() => {
    if (!currUserActiveCampaign && !campaignId) {
      setActiveTab(ActiveTab.Videos);
    }
  }, [currUserActiveCampaign, campaignId]);

  useEffect(() => {
    dispatch(fetchUserMetrics({ id: userId }));
    dispatch(fetchUserActivities({ id: userId }));
  }, [dispatch, userId, lastUpdated]);

  useEffect(() => {
    segmentTrackCall(segmentActions.HeroesDetails, venueId);
  }, [venueId]);

  useEffect(() => {
    if (history.location.pathname.includes(WebsiteRoutes.Heroes)) {
      dispatch(getUserIncentiveCampaigns({ userId, withCampaignDetails: true }));
    }

    return () => {
      dispatch(resetUserIncentiveCampaigns());
    };
  }, [userId, dispatch, history.location.pathname, currUserActiveCampaign]);

  const VenueActiveTabsList = activeTabsList.filter(
    (item) => item.name !== HeroDetailsStrings.InteractionsTab,
  );

  const toggleRedeemPointsModal = useCallback(async () => {
    setIsRedeemPointsModal(!isRedeemPointsModal);
  }, [isRedeemPointsModal]);

  const approveRewardHandler = useCallback(async () => {
    const response = await dispatch(approveReward({ id: userId, campaignId }));
    dispatch(fetchUserDetails(userId));

    if (campaignId) {
      dispatch(getCampaignUser({ campaignId, userId }));
    }

    if (response.payload) {
      addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      toggleRedeemPointsModal();
      trackEvent(
        entity === 'campaigns'
          ? RewardCampaignsEvents.RewardCampaignsTableDetailsModalRewardTabRewarded
          : CreatorsTableEventNames.CreatorDetailsModalRewardTabRewarded,
        {
          selectedUserId: userId,
        },
      );
      return;
    }
    addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
    toggleRedeemPointsModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, dispatch, toggleRedeemPointsModal, userId, campaignId]);

  const approveRewardClick = () => {
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalRewardTabFulfillRewardClick
        : CreatorsTableEventNames.CreatorDetailsModalRewardTabFulfillRewardClick,
      {
        selectedUserId: userId,
        campaignId,
      },
    );
    toggleRedeemPointsModal();
  };

  const onCloseFulfillRewardModel = () => {
    toggleRedeemPointsModal();
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalRewardTabRewardFulfillModalCloseClick
        : CreatorsTableEventNames.CreatorDetailsModalRewardTabRewardFulfillModalCloseClick,
      {
        selectedUserId: userId,
        campaignId,
      },
    );
  };

  const onConfirm = () => {
    trackEvent(
      entity === 'campaigns'
        ? RewardCampaignsEvents.RewardCampaignsDetailsModalConfirmFulfillRewardClick
        : CreatorsTableEventNames.CreatorDetailsModalConfirmFulfillRewardClick,
      {
        selectedUserId: userId,
        campaignId,
      },
    );
    approveRewardHandler();
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case ActiveTab.Videos: {
        return (
          <SalesVideosBlock
            userData={userDetails!}
            onFileSelect={onClickOutsideDisable}
            campaignUser={campaignUser}
            isCampaignUser={!!campaignId}
            campaignId={campaignId}
            entity={entity}
          />
        );
      }
      case ActiveTab.Rewards: {
        return (
          <>
            <FulfillRewardModal
              isOpen={isRedeemPointsModal}
              onClose={onCloseFulfillRewardModel}
              onConfirm={onConfirm}
            />
            <RewardsBlock
              isManageRewardsRedemptionEntry={isManageRewardsRedemptionEntry}
              approveReward={approveRewardClick}
              user={userDetails}
              onClickOutsideDisable={onClickOutsideDisable}
              isCampaignUser={!!campaignId}
              entity={entity}
            />
          </>
        );
      }
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Box mt={3} />
        <SalesHeroInfoBlock
          userData={userDetails!}
          metrics={userMetrics?.metrics}
          isCampaignUser={!!campaignId}
          entity={entity}
        />
      </Grid>
      <Grid container className={classes.userDetailsTab}>
        <Box className={classes.userDetailsTabRow}>
          {VenueActiveTabsList.map((thisTab) => {
            return (
              <Grid
                item
                key={thisTab.tab}
                xs={12}
                onClick={() => {
                  setActiveTab(thisTab.tab);
                  if (thisTab.name === HeroDetailsStrings.VideosTab) {
                    trackEvent(
                      entity === 'campaigns'
                        ? RewardCampaignsEvents.RewardCampaignsDetailsModalVideosTabClick
                        : CreatorsTableEventNames.CreatorDetailsModalVideosTabClick,
                      {
                        selectedUserId: userId,
                      },
                    );
                  }
                  if (thisTab.name === HeroDetailsStrings.RewardsAndPointsTab) {
                    trackEvent(
                      entity === 'campaigns'
                        ? RewardCampaignsEvents.RewardCampaignsDetailsModalRewardTabClick
                        : CreatorsTableEventNames.CreatorDetailsModalRewardTabClick,
                      {
                        selectedUserId: userId,
                      },
                    );
                  }
                }}
              >
                <Typography
                  className={clsx({
                    [classes.userDetailTabAlt]: true,
                    [classes.userDetailTabActiveColorAlt]: thisTab.tab === activeTab,
                  })}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  {thisTab.name}
                </Typography>
              </Grid>
            );
          })}
        </Box>
      </Grid>
      <Grid>{renderTabContent()}</Grid>
    </Grid>
  );
};
