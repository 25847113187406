import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './AnalyticsTab.styles';
import { InvitesLayoutStrings } from '../../../../common/localization/en';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useTypedSelector } from '../../../../store';

interface Count {
  name: string;
  value: string | number;
}

interface TooltipText {
  title: string;
  description: string;
}

export const AnalyticsTab: FC = () => {
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const {
    inviteCounts: { creators, delivered, visited },
  } = useTypedSelector((state) => state.campaigns);

  const counts: Count[] = [
    {
      value: delivered,
      name: InvitesLayoutStrings.Invited,
    },
    {
      value: visited,
      name: InvitesLayoutStrings.Visited,
    },
    {
      value: creators,
      name: InvitesLayoutStrings.Creators,
    },
  ];

  const tooltipTexts: TooltipText[] = [
    {
      title: InvitesLayoutStrings.Invited,
      description: isHealthCare
        ? InvitesLayoutStrings.InvitedDescrTrublu
        : InvitesLayoutStrings.InvitedDescr,
    },
    {
      title: InvitesLayoutStrings.Visited,
      description: isHealthCare
        ? InvitesLayoutStrings.VisitedDescrTrublu
        : InvitesLayoutStrings.VisitedDescr,
    },
    {
      title: InvitesLayoutStrings.Creators,
      description: isHealthCare
        ? InvitesLayoutStrings.CreatorsDescrTrublu
        : InvitesLayoutStrings.CreatorsDescr,
    },
  ];

  const clicksThroughRate = ((visited + creators) / delivered) * 100;

  return (
    <Box className={classes.container}>
      <Box className={classes.clickThroughWrapper}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.rateText}>{InvitesLayoutStrings.Rate}</Typography>
          <Tooltip
            maxWidth="315px"
            content={
              <Box className={classes.tooltip}>
                <Typography className={classes.tooltipTitle}>
                  {InvitesLayoutStrings.Rate}
                </Typography>
                <Typography className={classes.tooltipDescr}>
                  {InvitesLayoutStrings.Calculation}
                </Typography>
              </Box>
            }
          />
        </Box>
        <Typography className={classes.rateValue}>
          {isFinite(clicksThroughRate) ? `${clicksThroughRate.toFixed(1)}%` : '---'}
        </Typography>
      </Box>
      <Box className={classes.countsWrapper}>
        <Box className={classes.countsTitleWrapper}>
          <Typography className={classes.rateText}>
            {InvitesLayoutStrings.CountsByStatus}
          </Typography>
          <Tooltip
            maxWidth="315px"
            content={
              <Box className={classes.countsTooltip}>
                {tooltipTexts.map((text) => (
                  <Box className={classes.countsTooltipSection}>
                    <Typography className={classes.tooltipTitle}>{text.title}</Typography>
                    <Typography className={classes.tooltipDescr}>{text.description}</Typography>
                  </Box>
                ))}
              </Box>
            }
          />
        </Box>
        <Box className={classes.countsSectionsWrapper}>
          {counts.map((count) => (
            <Box className={classes.countsSection}>
              <Typography className={classes.countsName}>{count.name}</Typography>
              <Typography className={classes.countsValue}>{count.value}</Typography>
              <Box className={classes.divider} />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
