import { LinkedinIcon } from '../../assets/LinkedinIcon';
import { DownloadIcon } from '../../assets/shareAssets/DownloadIcon';
import { FacebookIcon } from '../../assets/shareAssets/FacebookIcon';
import { FBReelsIcon } from '../../assets/shareAssets/FBReelsIcon';
import { InstagramIconDv5 } from '../../assets/shareAssets/InstagramIconDv5';
import { LinkIcon } from '../../assets/shareAssets/LinkIcon';
import { RedditIcon } from '../../assets/shareAssets/RedditIcon';
import { SMSIcon } from '../../assets/shareAssets/SMSIcon';
import { TikTokIcon } from '../../assets/shareAssets/TikTokIcon';
import { TwitterIcon } from '../../assets/shareAssets/TwitterIcon';
import { SharesOptions } from '../../localization/en';

export interface IShareOptions {
  id: string;
  title: string;
  icon: JSX.Element;
}

const shareOptionKey = (value: string) =>
  Object.keys(SharesOptions)[Object.values(SharesOptions).indexOf(value as SharesOptions)];

export const shareOptions: IShareOptions[] = [
  {
    id: shareOptionKey(SharesOptions.SMS),
    title: SharesOptions.SMS,
    icon: <SMSIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Link),
    title: SharesOptions.Link,
    icon: <LinkIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Facebook),
    title: SharesOptions.Facebook,
    icon: <FacebookIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Twitter),
    title: SharesOptions.Twitter,
    icon: <TwitterIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.LinkedIn),
    title: SharesOptions.LinkedIn,
    icon: <LinkedinIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Instagram),
    title: SharesOptions.Instagram,
    icon: <InstagramIconDv5 />,
  },
  {
    id: shareOptionKey(SharesOptions.Tiktok),
    title: SharesOptions.Tiktok,
    icon: <TikTokIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.FacebookReels),
    title: SharesOptions.FacebookReels,
    icon: <FBReelsIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Reddit),
    title: SharesOptions.Reddit,
    icon: <RedditIcon />,
  },
  {
    id: shareOptionKey(SharesOptions.Downloaded),
    title: SharesOptions.Downloaded,
    icon: <DownloadIcon />,
  },
];
