import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 0 0',
    width: '100%',
    maxHeight: '500px',
    overflow: 'hidden',
    maxWidth: '314px',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      height: '20px !important',
      maxHeight: '20px !important',
      borderRadius: '12px',
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#EAECF0',
      borderRadius: '5px',
      height: '40px !important',
      cursor: 'pointer',
    },
  },
  filterWrapper: {
    width: '100%',
    borderTop: '1px solid #EAECF0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 10px 0 18px',
    cursor: 'pointer',
    '&:hover': {
      background: '#FAF9FF',
    },
  },
  filterName: {
    fontWeight: 500,
    color: '#667085',
    textOverflow: 'ellipsis',
    padding: '10px 0',
    width: '250px',
    overFlow: 'hidden',
  },
  title: {
    textAlign: 'center',
    marginBottom: '15px',
    fontSize: '12px',
    fontWeight: 600,
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '11px',
    width: '250px',
    margin: '0 auto',
    color: '#8B89A0',
  },
}));
