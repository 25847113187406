import { FC, SyntheticEvent, useState } from 'react';
import { useStyles } from './TabsSelect.styles';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export interface TabItem {
  title: string;
  count?: number;
  content: JSX.Element;
}

interface Props {
  tabsList: TabItem[];
}

export const TabsSelect: FC<Props> = ({ tabsList }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box>
        <Tabs value={tabIndex} onChange={handleChange} className={classes.tabs}>
          {tabsList.map(({ title, count }, index) => {
            const isActive = tabIndex === index;
            return (
              <Tab
                key={index}
                className={classes.tab}
                style={{
                  borderBottom: isActive ? `3px solid ${theme.palette.primary.main}` : 'none',
                }}
                label={
                  <>
                    <Typography
                      className={classes.title}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      className={classes.count}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                        backgroundColor: isActive ? '#F9F5FF' : '#F2F4F7',
                      }}
                    >
                      {count}
                    </Typography>
                  </>
                }
                id={`${title.split(' ').join('-')}-tab`}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabsList[tabIndex].content}
    </>
  );
};
