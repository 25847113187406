import { Box } from '@mui/material';
import { FC } from 'react';
import { useStyles } from './SMSTracking.helper';
import { BillingPeriodSection } from './BillingPeriodSection/BillingPeriodSection';
import StatsBucket from './StatsBucket/StatsBucket';
import { SmsMessageTable } from '../SmsMessageTables/SmsMessagesTable';

interface SMSTrackingProps {}

const SMSTracking: FC<SMSTrackingProps> = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <BillingPeriodSection />
      <StatsBucket />
      <SmsMessageTable />
    </Box>
  );
};

export default SMSTracking;
