import { FC } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

interface SalesHeroPhoneMailBlockProps {
  blockTitle: string | null;
  blockText: string | null;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
    backgroundColor: '#fff',
    '&:before': {
      content: '',
    },
  },
  profileDetailsBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '30px',
  },
  profileDetailsPoints: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
  avatar: {
    display: 'flex',
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    marginRight: '20px',
  },
  infoBlockRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridRowGap: '20px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '25px',
    borderRadius: '10px',
    padding: '27px 14px',
    border: `1px solid #DCDBE4`,
  },
  infoBlockContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 10px',
  },
  phoneNotAvailable: {
    color: '#DCDBE4',
    fontWeight: '400',
    fontSize: '14px',
  },
  iconContainer: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '8px',
    borderRadius: '30px',
    backgroundColor: '#F5F6F8',
    margin: 'auto 13px auto 0',
  },
  iconSvg: {
    display: 'block',
    width: '20px',
    height: '20px',
    fill: '#8B89A0',
  },
  arrowOutlined: {
    border: '1px solid #8B89A0',
    borderRadius: '50%',
  },
  infoBlockContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
  },
  textSmall: {
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textMedium: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textLarge: {
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    fontWeight: '500',
  },
  primaryTextColor: {
    color: '#1A1538',
    fontWeight: '600',
    fontSize: '14px',
  },
  secondaryTextColor: {
    color: '#1A1538',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: 0,
  },
  outlinedWrapper: {
    border: '1px solid #DCDBE4',
    borderRadius: '15px',
    width: '50%',
    marginLeft: '10px',
    padding: '10px 20px',
  },
  email: {
    fontSize: '14px',
    color: '#8B89A0',
    overflowWrap: 'anywhere',
    width: '100%',
    marginTop: '7px',
  },
}));
export const SalesHeroMailBlock: FC<SalesHeroPhoneMailBlockProps> = ({ blockText, blockTitle }) => {
  const styles = useStyles();

  return (
    <>
      <div className={styles.outlinedWrapper}>
        <Typography marginBottom="10px" className={clsx([styles.text, styles.secondaryTextColor])}>
          {blockTitle}
        </Typography>
        <Typography className={styles.email}>{blockText ? blockText : '---'}</Typography>
      </div>
    </>
  );
};
