import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#8B89A0',
  },
  label: {
    fontSize: 10,
    lineHeight: '14px',
    color: theme.palette.text.secondary,
  },
  stat: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  indicatorStar: {
    display: 'flex',
    padding: '12px 0 4px 8px',
  },

  indicator: {
    display: 'flex',
    marginRight: 5,
  },
  views: {
    backgroundColor: theme.palette.common.lightMint,
  },
  engagements: {
    backgroundColor: theme.palette.error.light,
  },
  conversion: {
    backgroundColor: theme.palette.primary.light,
  },
}));
