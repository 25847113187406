import MenuIcon from '@mui/icons-material/Menu';
import styled from 'styled-components';

import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { DEFAULT_BRAND_COLOR } from '../../../constants/defaults';

import { theme } from '../../../../theme';

interface ButtonProps {
  brandColor?: string;
  textColor?: string;
}
interface TitleProps {
  color: string;
}

export const BrandButton = styled.div<ButtonProps>`
  display: inline-block;
  padding: 12px;
  background-color: ${(props) => props.brandColor || 'var(--black)'};
  color: ${(props) => props.textColor || 'var(--black)'};
  height: 42px;
  width: 100%;
  border-radius: 8px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  // line-height: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
`;
interface ContentWrapperProps {
  noPadding?: boolean;
  browserName?: string;
}
export const ContentWrapper = styled.div<ContentWrapperProps>`
  min-height: 470px;
  position: relative;
  z-index: 0;
  padding-top: ${({ noPadding }) => (noPadding ? 0 : 'var(--iphone-header-height)')};
  // padding-top: '3.829787234%',
  width: 100%;
  height: 100%;
  height: ${({ browserName }) => (browserName?.toLowerCase() === 'safari' ? '93%' : '100%')};
  display: flex;
  flex-direction: column;
  background-color: var(--black);
  border-radius: 24px;
  overflow: hidden;
`;

export const MenuDivWrapper = styled.div`
  padding-left: 16px;
`;

export const MenuBar = styled(MenuIcon)`
  height: 20px;
  width: 20px;
  color: var(--white);
`;

export const EduTitle = styled.span<TitleProps>`
  color: ${(props) => props.color || 'var(--white)'};
  font-size: 12px;
  font-weight: 600;
  line-height: 10px;
`;

export const EduTitleWrapper = styled.div`
  padding-left: 16px;
`;

export const LogoSection = styled.img`
  padding: 15px;
  width: 60px;
  height: 60px;
  z-index: 10;
`;

export const SliderImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-position: bottom;
`;

export const ShadowBox = styled.div`
  position: absolute;
  height: 200px;
  top: 200px;
  width: 100%;
  background: linear-gradient(180deg, #000000 11.98%, rgba(28, 29, 34, 0) 100%);
  transform: rotate(-180deg);
  box-shadow: 0 10px 40px rgba(10, 25, 107, 0.08);
`;

export const EduText = styled.p`
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;
  color: var(--white);
  width: 180px;
`;

export const EduTextWrapper = styled.div`
  width: 200px;
  padding: 20px;
  position: absolute;
  height: 200px;
  top: 300px;
  width: 100%;
  z-index: 6;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  position: absolute;
  top: calc(100% - 120px);
  z-index: 100;
  bottom: 45px;
  width: 100%;
  height: 42px;
  padding-right: 15px;
`;

export const Title = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 3px;
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 111%;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  word-break: break-word;
  text-align: center;
`;

export const Subtitle = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.15px;
  line-height: 1.33;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  word-break: break-word;
`;

export const calculateFontSize = (title?: string, subtitle?: string) => {
  const fonts = {
    title: '32px',
    subtitle: '18px',
  };
  if (title && title?.length > 18) {
    fonts.title = '30px';
    if (title?.length > 26) {
      fonts.title = '28px';
      if (title?.length > 32) {
        fonts.title = '28px';
        if (title?.length > 48) {
          fonts.title = '26px';
          if (title?.length > 64) {
            fonts.title = '22px';
          }
        }
      }
    }
  }
  if (subtitle && subtitle?.length > 32) {
    fonts.subtitle = '20px';
    if (subtitle?.length > 38) {
      fonts.subtitle = '20px';
      if (subtitle?.length > 48) {
        fonts.subtitle = '18px';
        if (subtitle?.length > 72) {
          fonts.subtitle = '16px';
          if (subtitle?.length > 120) {
            fonts.subtitle = '15px';
          }
        }
      }
    }
  }
  return fonts;
};

interface Props {
  link: string;
  brandColor?: string;
}

export const useStyle = makeStyles<Theme, Props>((_theme) => ({
  image: {
    position: 'absolute',
  },
  gr: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  logo: {
    height: '40px',
    marginLeft: '16px',
    objectFit: 'contain',
    objectPosition: 'left',
  },
  UnderScore: (props) => ({
    position: 'absolute',
    width: '44px',
    height: '3px',
    top: '-57px',
    left: '42px',
    background: props.brandColor ? props.brandColor : DEFAULT_BRAND_COLOR,
    zIndex: 1000,
  }),

  BrushesContainer: {
    marginTop: '10px',
    position: 'absolute',
    zIndex: 3,
    width: '100%',
    maxWidth: '670px',
    minWidth: '300px',
    left: '50%',
    overflow: 'hidden',
    transform: 'translateX(-50%)',
  },
  Container: {
    maxWidth: '636px',
    width: '95%',
    marginTop: '10px',
    color: '#fff',
    position: 'relative',
    zIndex: 2,
    padding: '10px 10px 40px 10px',
    paddingTop: '18px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
    backdropFilter: 'blur(3.5px)',
    borderRadius: '12px',
    height: '410px',
    overflow: 'hidden',
  },
  Brush: {
    position: 'absolute',
    zIndex: 1,
    width: '430px',
    height: '211px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5))',
    fill: theme.palette.primary.main,
  },
  BrushText: {
    position: 'absolute',
    zIndex: 3,
    fontWeight: '600',
    fontSize: '20px',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
    color: theme.palette.common.white,
  },
  BrushText2: {
    top: '80px',
    left: '174px',
    transform: 'rotate(1deg)',
    color: theme.palette.common.white,
  },
  BrushText3: {
    top: '53px',
    left: '172px',
    transform: 'rotate(4deg) matrix(-1, 0.08, 0.08, 1, 0, 0)',
    color: theme.palette.common.white,
  },
  BrushText4: {
    top: '63px',
    left: '208px',
    transform: 'rotate(-4deg)',
    color: theme.palette.common.white,
  },
  BrushText5: {
    top: '53px',
    left: '172px',
    transform: 'rotate(4deg) matrix(-1, 0.08, 0.08, 1, 0, 0)',
    color: theme.palette.common.white,
  },
  Brush1: {
    top: '-40px',
    left: '50%',
    transform: 'translateX(-50%) rotate(0.82deg) scale(0.6)',
    zIndex: 3,
    '& img': {
      transform: 'skewY(10deg) scale(1.1)',
      marginTop: '-30px !important',
      marginLeft: '10px',
    },
  },
  Brush2: {
    top: '55px',
    left: '-165px',
    transform: 'rotate(-9.61deg) scale(.5)',
    zIndex: 2,
    '& img': {
      transform: 'rotateZ(14deg)',
    },
  },
  Brush3: {
    top: '100px',
    right: '-90px',
    transform: 'matrix(-1, 0.08, 0.08, 1, 0, 0) rotate(-12deg) scale(.5) translateX(-35%)',
    '& img': {
      transform: 'rotateZ(12deg)',
      marginTop: '-50px !important',
    },
  },
  Brush4: {
    top: '162px',
    left: '-180px',
    transform: 'rotate(18.39deg) scale(.5)',
    zIndex: 2,
    '& img': {
      transform: 'rotateZ(12deg)',
      marginTop: '-40px !important',
    },
  },
  Brush5: {
    top: '220px',
    right: '-90px',
    transform: 'matrix(-1, 0.08, 0.08, 1, 0, 0) rotate(8deg) scale(.5) translateX(-35%)',
    '& img': {
      transform: 'rotateZ(12deg)',
      marginTop: '-40px !important',
    },
  },
  Controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 4,
    transform: 'scale(0.5)',
    marginTop: '-40px',
  },
  Button: {
    width: '92px',
    height: '92px',
    borderRadius: '50%',
    border: '4px solid #A4A4A4',
    boxShadow: 'inset 8px 8px 16px #FFFFFF, inset -8px -8px 16px rgba(148, 169, 200, 0.72)',
    backdropFilter: 'blur(7px)',
    background: theme.palette.common.white,
    marginRight: '24px',
    '&:last-child': {
      marginRight: '0px',
    },
    color: '#344054',
    '&:hover': {
      '& >svg': {
        color: theme.palette.common.white,
      },
    },
  },
  TitledContainer: {
    position: 'absolute',
    zIndex: 2,
    top: '40px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'max-content',
    maxWidth: '345px',
    [`@media (max-width: 380px)`]: {
      maxWidth: '280px',
    },
  },
  Title: {
    marginBottom: '8px',
    fontWeight: '600',
    fontSize: '36px',
    textAlign: 'center',
    letterSpacing: '-0.02em',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
    color: theme.palette.common.white,
    [`@media (max-width: 380px)`]: {
      fontSize: '28px',
    },
  },
  Subtitle: {
    fontSize: '18px',
    textAlign: 'center',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)',
    color: theme.palette.common.white,
    [`@media (max-width: 380px)`]: {
      fontSize: '14px',
    },
  },
  Description: {
    margin: '0 auto',
    marginBottom: '18px',
    marginTop: '320px',
    fontSize: '10px',
    lineHeight: '20px',
    textAlign: 'center',
    width: '220px',
    color: theme.palette.common.white,
  },
  imageWrapper: {
    zIndex: 0,
    '& > div': {
      // height: '110vh',
      height: '850px',
    },
    [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
      '& img': {
        objectPosition: 'center',
      },
    },
    [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
      flexGrow: 1,
      '& img': {
        objectPosition: 'top',
        objectFit: 'cover',
      },
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      height: '850px',
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
  },
  imageBackground: {
    width: '100% !important',
    maxWidth: '670px !important',
    minWidth: 'auto !important',
  },
}));
