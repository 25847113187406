import { FC } from 'react';
import { DefaultToast, ToastProps } from 'react-toast-notifications';
import { useStyles } from './ToastComponent.style';

export const ToastComponent: FC = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DefaultToast {...(props as ToastProps)}>{children}</DefaultToast>
    </div>
  );
};
