import { FC, SetStateAction, useState } from 'react';
import { Grid, Theme, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Color } from 'material-ui-color';

import { MediaEndpoints } from '../../../../../api/endpoints';
import {
  ColorPickerInputLabels,
  VenueCreationWizardStrings,
} from '../../../../../common/localization/en';
import { UploadUrlApiModel } from '../../../../../services/utilities';
import { uploadLogoAsFile } from '../../../../../pages/webappSettings/General/Settings.helper';
import { httpClient } from '../../../../../services/httpClient/httpClient';
import ColorPickerComponent from '../../../../../common/components/colorpicker/ColorPicker';
import { BrandingStylePreview } from '../../../../../common/components/previews/BrandingStylePreview/BrandingStylePreview';
import defaultBg from '../../../../../common/assets/default-filter-bg-2.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  fileinputWrapperUpload: {
    width: '100%',
    maxWidth: '200px',
    height: '240px',
    borderRadius: '8px',
    backgroundImage: 'url(/inputBackground.png)',
    backgroundColor: theme.palette.common.slightlyBlueish,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  fileInputUpload: {
    width: '120%',
    maxWidth: '200px',
    height: '200px',
    borderRadius: '8px',
    opacity: 0,
    cursor: 'pointer',
  },
  uploadButtonWrapperUpload: {
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
  },
  fileInputbuttonUpload: {
    position: 'absolute',
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
    opacity: 0,
  },
  uploadText: {
    color: theme.palette.primary.main,
  },
  previewWrapper: {
    backgroundColor: theme.palette.common.slightlyBlueish,
  },
  loaderWrapper: {
    height: '240px',
    width: '100%',
    position: 'relative',
  },
}));
interface Step2Props {
  // Dispatch<SetStateAction<ColorType | ColorError>>
  venuLogo: string;
  setVenuLogo: (value: SetStateAction<string>) => void;
  brandcolor: Color;
  setBrandcolor: (value: SetStateAction<Color>) => void;
  textColor: Color;
  setTextColor: (value: SetStateAction<Color>) => void;
}
export const Step2: FC<Step2Props> = ({
  venuLogo,
  setVenuLogo,
  brandcolor,
  setBrandcolor,
  textColor,
}) => {
  const classes = useStyles();
  const [isLogoUploading, setIsLogoUploading] = useState(false);

  const createSignedUploadUrl = async (mimeType?: string) => {
    try {
      return httpClient.post<undefined, UploadUrlApiModel>({
        url: `${MediaEndpoints.CreateUploadUrl}?ext=${mimeType}`,
        requiresToken: true,
      });
    } catch (error) {
      return console.log(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onChangeLogoHandler(file);
  };

  const onChangeLogoHandler = (logoFile: File) => {
    if (logoFile && logoFile.name) {
      createSignedUploadUrl(logoFile.name.split('.').pop())
        .then(async (res) => {
          setIsLogoUploading(true);
          if (res) {
            try {
              await uploadLogoAsFile({ options: res, data: logoFile });
              setVenuLogo(res.downloadUrl);
              setIsLogoUploading(false);
            } catch (err) {
              setIsLogoUploading(false);
            }
          } else {
            setIsLogoUploading(false);
          }
        })
        .catch((err) => {
          setIsLogoUploading(false);
        });
    }
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-start" spacing={3}>
      <Grid item xs={11}>
        <p className={classes.header}>{VenueCreationWizardStrings.WebappBrandingStep}</p>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <div
              className={classes.fileinputWrapperUpload}
              style={venuLogo ? { backgroundImage: `url(${venuLogo})` } : {}}
            >
              {isLogoUploading && (
                <div className={classes.loaderWrapper}>
                  <CircularProgress
                    style={{
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      top: 'calc(50% - 15px)',
                      left: 'calc(50% - 15px)',
                    }}
                    color="primary"
                  />
                </div>
              )}
              <input
                className={classes.fileInputUpload}
                id="contained-button-file"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.uploadButtonWrapperUpload}>
              <input
                className={classes.fileInputbuttonUpload}
                id="contained-button-file-button"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />
              <p className={classes.uploadText}>{VenueCreationWizardStrings.UploadLogo}</p>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ColorPickerComponent
              color={brandcolor}
              setColor={setBrandcolor}
              label={ColorPickerInputLabels.BrandColor}
            />
            {/* <ColorPickerComponent
                  color={textColor}
                  setColor={setTextColor}
                  label={ColorPickerInputLabels.TextColor}
                /> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={5}>
        <div className={classes.previewWrapper}>
          <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
            <Grid item xs={10}>
              <p className={classes.header}>{VenueCreationWizardStrings.Preview}</p>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BrandingStylePreview
              brandColor={'#' + brandcolor.hex}
              textColor={'#' + textColor.hex}
              src={defaultBg}
              logo={venuLogo}
            />
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
