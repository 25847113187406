export const LinkVideoIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_515_153306)">
        <rect x="0.5" y="0.5" width="24" height="24" rx="4.5" stroke="#D0D5DD" />
        <path
          d="M13.0268 17.2417L11.9731 18.2955C10.5182 19.7504 8.15935 19.7504 6.70445 18.2955C5.24956 16.8406 5.24956 14.4817 6.70445 13.0268L7.75818 11.9731M17.2417 13.0268L18.2955 11.9731C19.7504 10.5182 19.7504 8.15935 18.2955 6.70445C16.8406 5.24956 14.4817 5.24956 13.0268 6.70445L11.9731 7.75818M9.89211 15.1078L15.1078 9.8921"
          stroke="#344054"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_515_153306"
          x="-4"
          y="-4"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_515_153306" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_515_153306"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
