import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    scale: '0.5',
    transform: 'translateY(-60%)',
  },
  rewardContainer: {
    marginTop: '-2px',
    marginBottom: '4px',
  },
}));
