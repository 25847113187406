import * as React from 'react';
import { SVGProps } from 'react';
import { theme } from '../../../../theme';
const SpheresIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={41}
    height={40}
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1179_33296)">
      <circle
        cx={29.7377}
        cy={17.0058}
        r={9.81489}
        fill="white"
        stroke="#1A1538"
        strokeWidth={0.666667}
      />
      <circle cx={29.7364} cy={17.0065} r={5.60363} fill="#D9D4FF" />
      <circle
        cx={12.9405}
        cy={21.9712}
        r={12.4649}
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
        strokeWidth={0.666667}
      />
      <circle cx={12.9408} cy={21.9736} r={7.85784} fill="white" />
      <path
        d="M15.7595 21.3051C16.2709 21.6004 16.2709 22.3386 15.7595 22.6339L12.0307 24.7867C11.5192 25.082 10.8799 24.7129 10.8799 24.1223L10.8799 19.8167C10.8799 19.2261 11.5192 18.857 12.0307 19.1523L15.7595 21.3051Z"
        fill="#1A1538"
      />
    </g>
    <defs>
      <clipPath id="clip0_1179_33296">
        <rect width={40} height={40} fill="white" transform="translate(0.0136719)" />
      </clipPath>
    </defs>
  </svg>
);
export default SpheresIcon2;
