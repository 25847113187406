import { FC, useEffect, useMemo } from 'react';
import { Grid, Button, Typography, Divider, Box } from '@mui/material';
import { SocialVerseStrings } from '../../localization/en';
import { useHeaderSVStyles } from './HeaderSocialVerse.style';
import { ArrowBack } from '@mui/icons-material';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { ViewMode } from '../ViewMode/ViewMode';
import { SubNavigationItem } from '../SubNavigationItem/SubNavigationItem';
import { WebsiteRoutes } from '../../constants/routes';
import { MenuIconPlay } from '../../assets/newDesign/SocialVerse/MenuIconPlay';
import { useHistory } from 'react-router-dom';
import { WatchIcon } from '../../assets/newDesign/SocialVerse/WatchIcon';
import { MenuIconPlayActive } from '../../assets/newDesign/SocialVerse/MenuIconPlayActive';
import { MenuIconSettings } from '../../assets/newDesign/SocialVerse/MenuIconSettings';
import { MenuIconSettingsActive } from '../../assets/newDesign/SocialVerse/MenuIconSettingsActive';
import { GetVideosBySocialVerseId } from '../../../store/slices/socialVerseSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { GoogleSyncStatus } from '../GoogleBusinessProfile/GoogleSyncStatus/GoogleSyncStatus';

interface Props {
  onClickBack: () => void;
  onOpenPreviewModal: () => void;
}

export const HeaderSocialVerse: FC<Props> = ({ onClickBack, onOpenPreviewModal }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { trackEvent } = useTrackEvent();

  const { socialVerse, currentSocialVerseVideos } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  useEffect(() => {
    if (socialVerse?.id && currentSocialVerseVideos.length === 0) {
      dispatch(GetVideosBySocialVerseId({ id: socialVerse?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialVerse]);

  const isWatchIconEnabled = useMemo(() => {
    return currentSocialVerseVideos?.length > 0 || false;
  }, [currentSocialVerseVideos]);

  const classes = useHeaderSVStyles();
  const id = history.location.pathname.split('/').pop();

  return (
    <Grid container className={classes.container}>
      <Grid container className={classes.titleWrapper}>
        <Button
          startIcon={<ArrowBack className={classes.iconBack} />}
          onClick={() => {
            onClickBack();
            trackEvent(SocialVerseEventNames.backToSocialVerse);
          }}
          className={classes.backButton}
        />
        <Typography className={classes.nameText}>{SocialVerseStrings.Name}</Typography>
        <Typography className={classes.title}>{socialVerse?.name || ''}</Typography>
        <Divider className={classes.divider} />
        {socialVerse && <ViewMode viewMode={socialVerse.viewMode} fontSize="16px" />}
      </Grid>
      <Grid className={classes.navMenueWrapper}>
        <SubNavigationItem
          route={`${WebsiteRoutes.SocialVerseAddVideos}/${id}`}
          text={SocialVerseStrings.AddVideos}
          icon={<MenuIconPlay />}
          iconActive={<MenuIconPlayActive />}
          onClick={() => trackEvent(SocialVerseEventNames.addVideosView, { socialVerseId: id })}
        />
        <SubNavigationItem
          route={`${WebsiteRoutes.SocialVerseSettings}/${id}`}
          text={SocialVerseStrings.EditAppearance}
          icon={<MenuIconSettings />}
          iconActive={<MenuIconSettingsActive />}
          onClick={() =>
            trackEvent(SocialVerseEventNames.configureSettingsView, { socialVerseId: id })
          }
        />
      </Grid>
      <Box display="flex" alignItems="center">
        {socialVerse && (
          <Box ml="16px">
            <GoogleSyncStatus socialVerse={socialVerse} />
          </Box>
        )}
        <Button
          onClick={() => {
            onOpenPreviewModal();
            trackEvent(SocialVerseEventNames.openSocialVersePreview, { socialVerseId: id });
          }}
          disabled={!isWatchIconEnabled}
          style={{
            opacity: isWatchIconEnabled ? '1' : '0.7',
          }}
          id="social-verse-preview-button"
        >
          <WatchIcon />
        </Button>
      </Box>
    </Grid>
  );
};
