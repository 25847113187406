import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@mui/material';

import { NotFoundPageStrings } from '../../common/localization/en';
import { Container, Content, Image, Title, SubTitle, Text } from './NotFound.style';

export const NotFound = () => (
  <Container>
    <Image src="/404.svg" alt="" />
    <Content>
      <Title>{NotFoundPageStrings.Title}</Title>
      <SubTitle>{NotFoundPageStrings.Description}</SubTitle>
      <Text>{NotFoundPageStrings.Description2}</Text>
      <Button color="primary" variant="contained" component={RouterLink} to="/">
        {NotFoundPageStrings.ReturnText}
      </Button>
    </Content>
  </Container>
);
