import { FC } from 'react';

export const ArrowBottomIcon: FC = () => (
  <svg width="14px" height="auto" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00001 1L3.99995 4L1 1"
      stroke="#8B89A0"
      strokeWidth="1.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
