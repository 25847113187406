import React, { useState } from 'react';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import { useStyles } from '../AccountsSearchTags.styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { Box } from '@mui/material';
import { SearchTagsStrings } from '../../../localization/en';
import MenuContainer from '../../MenuContainer/MenuContainer';
import {
  AccountAttributeName,
  AccountFilterAttribute,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import {
  removeAccountPricingPlanFilter,
  setActiveAccountFilterAttributes,
} from '../../../../store/slices/searchFilterSlice';
import { resetPage } from '../../../../store/slices/organizationVenueSlice';

const PricingPlanTag = () => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  const { activeAccountFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  const { pricingPlans } = useTypedSelector((state) => state.pricingPlans);

  const pricingPlanFilterIdx = activeAccountFilterAttributes.findIndex(
    (item) => item.name === AccountAttributeName.PlanId,
  );

  const [rewardedAnchorEl, setRewardedAnchorEl] = useState<null | HTMLElement>(null);
  const isRewardedMenuOpen = Boolean(rewardedAnchorEl);
  const handleRewardedMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setRewardedAnchorEl(event.currentTarget);
  };

  const handleRewardedMenuClose = () => {
    setRewardedAnchorEl(null);
  };

  const handlePlanChange = (value: string) => {
    const filter: AccountFilterAttribute[] = [
      ...activeAccountFilterAttributes.filter((item) => item.name !== AccountAttributeName.PlanId),
      {
        name: AccountAttributeName.PlanId,
        operation: SearchOperationType.Equals,
        value: value,
      },
    ];
    dispatch(setActiveAccountFilterAttributes(filter));

    return;
  };

  const handleRemovePricingPlanFilter = () => {
    dispatch(removeAccountPricingPlanFilter());
  };

  const renderPlans = () => {
    return pricingPlans.map((item) => {
      return (
        <Box
          className={styles.pricingPlanOptions}
          id={item.id}
          onClick={() => {
            handlePlanChange(item.id);
            handleRewardedMenuClose();
            dispatch(resetPage());
          }}
        >
          {item.name}
        </Box>
      );
    });
  };

  const tagText =
    pricingPlanFilterIdx !== -1
      ? pricingPlans.filter(
          (item) => item.id === activeAccountFilterAttributes[pricingPlanFilterIdx].value,
        )[0]?.name || ''
      : '';

  return (
    <>
      <Box
        className={`${styles.tag} ${pricingPlanFilterIdx !== -1 && tagText && styles.active}`}
        onClick={handleRewardedMenuOpen}
      >
        {SearchTagsStrings.PricingPlan}: {tagText}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (pricingPlanFilterIdx !== -1) {
              e.stopPropagation();
              handleRemovePricingPlanFilter();
            }
          }}
        >
          {pricingPlanFilterIdx !== -1 && tagText ? <PurpleCloseIcon /> : <SmallArrowIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={rewardedAnchorEl}
        isOpen={isRewardedMenuOpen}
        onClose={handleRewardedMenuClose}
        padding="4px 0"
        maxHeight={'475px'}
      >
        {renderPlans()}
      </MenuContainer>
    </>
  );
};

export default PricingPlanTag;
