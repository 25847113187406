import { Box, Button, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { SearchTargetsType } from '../../api/models/searchFilter';
import { FSModal } from '../../common/components/modal/Modal';
import { TextFieldComponent } from '../../common/components/TextField/TextField';
import { SavedFiltersPageStrings } from '../../common/localization/en';
import { MAX_LENGTH, validationSchema } from './CreatedFilter.helper';
import { useFiltersStyles } from './Filters.style';

const targetLiteral = {
  VIDEOS: SavedFiltersPageStrings.SaveNewVideosFilter,
  USERS: SavedFiltersPageStrings.SaveNewUsersFilter,
  INCENTIVE_CAMPAIGNS: SavedFiltersPageStrings.SaveNewLeaderboardFilter,
  VENUES: null,
};

interface CreatedFilterModalProps {
  modalIsOpen: boolean;
  handleClose: () => void;
  handleConfirm: (name: string) => void;
  target: SearchTargetsType | null;
}

export const CreatedFilterModal: FC<CreatedFilterModalProps> = ({
  modalIsOpen,
  handleClose,
  handleConfirm,
  target,
}) => {
  const styles = useFiltersStyles();
  const { values, handleChange, dirty, errors } = useFormik({
    initialValues: {
      name: '',
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema,
    validateOnChange: true,
  });

  return (
    <FSModal modalIsOpen={modalIsOpen} onClose={handleClose} width="450px">
      <Typography component="h2" className={styles.ModalTitle}>
        {target && targetLiteral[target]}
      </Typography>
      <Box marginTop="30px">
        <Typography className={styles.Label}>{SavedFiltersPageStrings.FiltersName}</Typography>
        <TextFieldComponent
          placeholder="Filter Name"
          name="name"
          value={values.name}
          onChange={handleChange}
          fullWidth
          label={SavedFiltersPageStrings.FiltersName}
          helperText
          characterLimit={MAX_LENGTH}
          errorText={errors.name}
        />
        <Box mt="25px">
          <Button
            color="primary"
            variant="contained"
            disabled={dirty && values.name.length >= 0 && Object.keys(errors).length !== 0}
            onClick={() => {
              if (values.name.trim().length >= 3) {
                handleConfirm(values.name);
              }
            }}
            className={styles.ModalBtn}
          >
            {SavedFiltersPageStrings.SaveFilter}
          </Button>
        </Box>
      </Box>
    </FSModal>
  );
};
