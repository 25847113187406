import { Box, Modal } from '@mui/material';
import React from 'react';

interface GBPSyncModalBaseProps {
  show: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '500px',
  bgcolor: 'background.paper',
  borderRadius: '15px',
  py: '36px',
  px: '66px',
};

export const GBPSyncModalBase: React.FC<GBPSyncModalBaseProps> = ({ show, onClose, children }) => {
  return (
    <Modal
      open={show}
      onClose={onClose}
      onBackdropClick={(e) => {
        e.stopPropagation();
        onClose?.();
      }}
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};
