import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    padding: '5px',
    minWidth: 'fit-content',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  nameText: {
    color: '#1A1538',
    paddingLeft: '2px',
  },
}));
