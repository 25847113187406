import { Box, Button, Typography, Checkbox, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IPhonePreview } from '../../../common/components/IPhonePreview/IPhonePreview';
import { CheckBoxIconChecked } from '../CheckBox/CheckBox';
import { FakeTextField } from './previewComponents/FakeTextField';
import { useTypedSelector } from '../../../store';
import tinycolor from 'tinycolor2';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { LogoPresentation, LogoPresentationLayout } from '../../../api/models/venue';

const useStyles = makeStyles<Theme, { isDarkTheme?: boolean; isThemeLayoutEnabled?: boolean }>(
  () => ({
    tos: ({ isDarkTheme, isThemeLayoutEnabled }) => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: isThemeLayoutEnabled ? (isDarkTheme ? '#222124' : '#dfdfe2') : '',
      color: isThemeLayoutEnabled ? (isDarkTheme ? '#000' : '#fff') : '#000',
      borderRadius: '5px',
      paddingRight: '5px',
      '& svg': {
        fill: 'white',
      },
    }),
    brush: {
      transform: 'scale(0.65)',
      position: 'absolute',
      top: '-28px',
      left: '-96px',
    },
    btnWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'end',
      height: '100px',
    },
    inputsWrapper: {
      display: 'flex',
      marginBottom: '17px',
      justifyContent: 'space-between',
      padding: '0 15px',
    },
  }),
);

interface IPhoneSignUpPreviewProps {
  phone?: string;
  phoneRequired?: boolean;
  name?: string;
  nameRequired?: boolean;
  email?: string;
  emailRequired?: boolean;
  brandColor?: string;
  logoImage?: string;
  backgroundImage?: string | null;
  bgShape?: 'straight' | 'curved' | 'fade';
  showCompanyName?: boolean;
  logoPresentation?: LogoPresentation;
  layout?: string;
}

export const IPhoneSignUpPreview: React.FC<IPhoneSignUpPreviewProps> = ({
  phone,
  phoneRequired,
  name,
  nameRequired,
  email,
  emailRequired,
  logoImage,
  backgroundImage,
  brandColor = '#42a5f5',
  bgShape,
  showCompanyName,
  logoPresentation,
  layout,
}) => {
  const textColor = tinycolor(brandColor).isDark() ? 'white' : 'black';
  const { isThemeLayoutEnabled, theme: layoutTheme, isDarkTheme } = useThemeLayout();
  const styles = useStyles({ isDarkTheme, isThemeLayoutEnabled });

  const {
    venue: { name: companyName },
  } = useTypedSelector((state) => state.venue);

  const backgroundsMap: Record<LogoPresentation, string> = {
    [LogoPresentation.Default]: 'unset',
    [LogoPresentation.SplashImage]: `center/cover url('${backgroundImage}')`,
    [LogoPresentation.BrandColor]: brandColor,
  };

  return (
    <Box>
      <IPhonePreview>
        {!isThemeLayoutEnabled && backgroundImage && (
          <img style={{ position: 'absolute' }} src={backgroundImage} alt="background" />
        )}
        <Box
          style={{
            background: isThemeLayoutEnabled ? (layoutTheme === 'light' ? '#fff' : '#000') : '#000',
            height: '100%',
            width: '100%',
          }}
        >
          {isThemeLayoutEnabled && (
            <Box style={{ position: 'absolute', height: '145px', width: '100%' }}>
              <Box
                sx={{
                  background:
                    isThemeLayoutEnabled && logoPresentation
                      ? backgroundsMap[logoPresentation]
                      : 'unset',
                }}
                style={{
                  width: '100%',
                  height: ' 100%',
                  clipPath:
                    layout === LogoPresentationLayout.Curved
                      ? 'ellipse(85% 100% at 50% 0%)'
                      : 'unset',
                  left: '0',
                  right: '0',
                  overflow: 'hidden',
                }}
              >
                {layout === LogoPresentationLayout.Fade && (
                  <Box
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      height: '40px',
                      width: '100%',
                      background: `linear-gradient(0deg, ${
                        layoutTheme === 'dark' ? '#000' : '#fff'
                      }, transparent)`,
                      zIndex: '100',
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
          <Box height="100%" display="flex" flexDirection="column" alignItems="center" p="8px">
            <Box
              sx={{
                width: '100%',
                height: '100%',
                zIndex: 1,
                position: 'relative',
                ...(!isThemeLayoutEnabled
                  ? {
                      padding: '25px 12px 12px',
                      background:
                        'linear-gradient(rgba(0, 0, 0, 0.46) 35.94%, rgba(0, 0, 0, 0.46) 100%)',
                      borderRadius: '12px',
                      backdropFilter: 'blur(3.5px)',
                      overflowX: 'hidden',
                    }
                  : {}),
              }}
            >
              <Box
                style={{
                  minHeight: '70px',
                  marginTop: '10px',
                  marginBottom: logoPresentation === LogoPresentation.Default ? '20px' : '40px',
                }}
              >
                {logoImage ? (
                  <img
                    src={logoImage}
                    alt="logo"
                    style={{
                      height: '110px',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                  />
                ) : (
                  <Box height="110px" />
                )}
              </Box>

              {(!logoImage || showCompanyName) && (
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 600,
                    lineHeight: '24px',
                    textAlign: 'center',
                    color: brandColor,
                    marginBottom: '8px',
                  }}
                >
                  {companyName}
                </Typography>
              )}

              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff',
                  lineHeight: '14px',
                  textAlign: 'center',
                  marginBottom: '18px',
                }}
              >
                Welcome! Please enter your details
              </Typography>

              <Box className={styles.inputsWrapper}>
                <FakeTextField
                  title="First Name"
                  color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#767676' : '#fff'}
                  required={true}
                  placeholder=""
                  width="46%"
                />
                <FakeTextField
                  title="Last Name"
                  color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#767676' : '#fff'}
                  required={true}
                  placeholder=""
                  width="46%"
                />
              </Box>

              <Box className={styles.btnWrapper}>
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: brandColor,
                    fontSize: '14px',
                    color: textColor,
                    height: '42px',
                    fontWeight: 600,
                  }}
                  disabled
                >
                  Create Your Clip
                </Button>
                <Box className={styles.tos} marginTop={'8px'}>
                  <Checkbox checked icon={<CheckBoxIconChecked />} sx={{ padding: '4px' }} />
                  <Typography
                    fontSize="8px"
                    color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                  >
                    You agree to our{' '}
                    <Typography
                      sx={{ textDecoration: 'underline' }}
                      component="span"
                      fontSize="8px"
                      color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                      fontWeight="500"
                    >
                      User Terms
                    </Typography>{' '}
                    &amp;{' '}
                    <Typography
                      sx={{ textDecoration: 'underline' }}
                      component="span"
                      fontSize="8px"
                      color={isThemeLayoutEnabled && layoutTheme === 'light' ? '#000' : '#fff'}
                      fontWeight="500"
                    >
                      Privacy Policy
                    </Typography>{' '}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </IPhonePreview>
    </Box>
  );
};
