import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material';
import { theme } from '../../../theme';
import { StatusValues } from './VideoStatusDropdown';

export const useStyles = makeStyles<Theme, { status: StatusValues }>((theme: Theme) => ({
  formControl: {
    width: 220,
    borderRadius: 12,
    padding: '2px 5px',
    height: 36,
    '& .MuiInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiSelect-select': {
      paddingRight: '5px !important',
    },
  },
  select: {
    borderRadius: 12,
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
    },
    '& .MuiSelect-select': {
      paddingRight: '8 !important',
      padding: '8 !important',
    },
  },
  arrow: ({ status }) => ({
    position: 'absolute',
    right: '0',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    paddingRight: '8px',
    '& svg path': {
      stroke: incator[status],
    },
  }),
  menuItem: {
    width: 220,
    height: 36,
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  published: {
    color: theme.palette.common.ContentTable.published,
    borderColor: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    color: theme.palette.common.ContentTable.unpublished,
  },
  moderated: {
    color: theme.palette.common.ContentTable.moderated,
  },
  safe: {
    color: theme.palette.common.ContentTable.unpublished,
  },
  pending_moderation: {
    color: theme.palette.common.ContentTable.pending_moderation,
  },
  moderatedIcon: {
    marginLeft: '12px',
    color: theme.palette.error.main,
  },
}));

export const useIndicatorStyles = makeStyles((theme: Theme) => ({
  published: {
    color: theme.palette.common.ContentTable.published,
  },
  unpublished: {
    backgroundColor: theme.palette.secondary.main,
  },
  moderated: {
    backgroundColor: '#FC4741',
  },
  safe: {
    backgroundColor: theme.palette.secondary.main,
  },
  pending_moderation: {
    backgroundColor: theme.palette.info.main,
  },
}));

const incator = {
  published: theme.palette.common.ContentTable.published,
  unpublished: theme.palette.secondary.main,
  moderated: '#FC4741',
  safe: theme.palette.secondary.main,
  pending_moderation: theme.palette.info.main,
};

export const useSelectStyles = makeStyles((theme: Theme) => ({
  published: {
    backgroundColor: alpha(theme.palette.common.ContentTable.published as string, 0.1),
  },
  unpublished: {
    backgroundColor: 'rgba(255, 181, 21, 0.1)',
  },
  moderated: {
    backgroundColor: 'rgba(252, 71, 65, 0.1);',
  },
  safe: {
    backgroundColor: 'rgba(255, 181, 21, 0.1)',
  },
  pending_moderation: {
    backgroundColor: `${theme.palette.primary.main}1a`,
  },
}));
