import { Box, FormHelperText, OutlinedInput, Typography } from '@mui/material';
import { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyles } from './PlaceAutoComplete.styles';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { isMobile } from 'react-device-detect';

interface Address {
  address: string;
  city: string;
  state: string;
  zip: string;
}

interface PlaceAutoCompleteProps {
  label?: string;
  value: string;
  onChange: (event: React.ChangeEvent<any>, hideValue?: any) => void;
  placeholder?: string;
  onSelect: (address: Address) => void;
  onBlur: (event: React.ChangeEvent<any>) => void;
  error?: string;
}

declare global {
  interface Window {
    google: any;
  }
}

const PlaceAutoComplete: FC<PlaceAutoCompleteProps> = memo(
  ({ label, value, onChange, placeholder, onSelect, onBlur, error }) => {
    const classes = useStyles();
    const ref = useRef<any>(null);
    const [isScriptLoaded, setIsScriptLoaded] = useState(false);

    const loadScript = (url: string, callback: () => void) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = () => callback();

      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    };
    const handleScriptLoad = () => {
      setIsScriptLoaded(true);
    };

    useEffect(() => {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=AIzaSyCNbpvmJXt1ZsrDH7ToeGdRozY5yO7_4G4&v=weekly&libraries=places`,
        () => handleScriptLoad(),
      );
    }, []);

    useEffect(() => {
      if (ref.current && ref.current.childNodes[0] && isScriptLoaded) {
        const autocomplete = new window.google.maps.places.Autocomplete(ref.current.childNodes[0]);

        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace().address_components;

          if (place) {
            const address = place.find((addressComponent: any) =>
              addressComponent.types.includes('street_number'),
            )?.long_name;
            const route = place.find((addressComponent: any) =>
              addressComponent.types.includes('route'),
            )?.short_name;
            const city = place.find((addressComponent: any) =>
              addressComponent.types.includes('locality'),
            )?.long_name;
            const state = place.find((addressComponent: any) =>
              addressComponent.types.includes('administrative_area_level_1'),
            )?.long_name;
            const zip = place.find((addressComponent: any) =>
              addressComponent.types.includes('postal_code'),
            )?.long_name;
            const value = { address: `${address} ${route}`, city, state, zip };
            console.log(value);

            onSelect(value);
          }
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, isScriptLoaded]);

    return (
      <Box>
        {label && (
          <Typography className={[classes.label, isMobile ? classes.mobileLabel : ''].join(' ')}>
            {label}
          </Typography>
        )}
        <OutlinedInput
          ref={ref}
          className={isMobile ? classes.input : ''}
          placeholder={placeholder}
          id="pac-input"
          name={'address'}
          value={value}
          type="text"
          onChange={onChange}
          fullWidth
          onBlur={(e) => {
            onBlur(e);
          }}
          error={!!error}
          autoComplete="off"
        />
        {error && (
          <FormHelperText
            className={classes.errorWrapper}
            id="input-meta-title-helper-text"
            error
            sx={{ fontSize: '11.5px' }}
          >
            <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />
            {error}
          </FormHelperText>
        )}
      </Box>
    );
  },
);

export default PlaceAutoComplete;
