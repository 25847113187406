import axios from 'axios';
import { MediaEndpoints, getApiUrlForId } from '../../../api/endpoints';
import { httpClient } from '../../../services/httpClient/httpClient';
import { UploadUrlApiModel, removeFilterFromUrl } from '../../../services/utilities';
import { Frame } from './EditThumbnail';

export const createUploadThumbnailUrl = async (mimeType: string, venueId: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: getApiUrlForId(`${MediaEndpoints.CreateUploadSignedUrl}?ext=${mimeType}`, venueId),
      requiresToken: true,
    });
  } catch (error) {
    console.log('uploaded');
    return console.log(error);
  }
};
interface UploadThumbnail {
  options: UploadUrlApiModel;
  data: string;
}
export const uploadThumbnailAsString = async ({ options, data }: UploadThumbnail) => {
  if (!data) {
    return;
  }
  try {
    const file = await fetch(data).then((r) => r.blob());
    return await axios.put<typeof options, undefined>(options.url, file, {
      headers: options.headers,
    });
  } catch (error) {
    console.log(error);
  }
};

export const extractFramesFromCloudinary = async (
  videoUrl: string,
  options?: { removeFilter?: boolean; interval?: number },
): Promise<Frame[]> => {
 

  const url = options?.removeFilter ? removeFilterFromUrl(videoUrl) : videoUrl;

  const video = document.createElement('video');
  const videoBlob = await fetch(url).then((r) => r.blob());
  const videoObjectUrl = URL.createObjectURL(videoBlob);
  video.src = videoObjectUrl;

  await new Promise<void>((resolve) => {
    video.addEventListener(
      'loadeddata',
      function () {
        resolve();
      },
      false,
    );
  });

  const addIntervalShift = options && options.interval ? options.interval : 0;

  const duration = video.duration;

  const interval = Number((duration / 5).toFixed(1));
  const generateInvervals = (shift: number) => {
    if (shift < duration) {
      //shift with minor increase
      return shift;
    }
    //just a random number for shift
    return Number((Math.random() * duration).toFixed(2));
  };
  const intervals = [
    generateInvervals(0 + addIntervalShift),
    generateInvervals(interval + addIntervalShift),
    generateInvervals(interval * 2 + addIntervalShift),
    generateInvervals(interval * 3 + addIntervalShift),
    generateInvervals(interval * 4 + addIntervalShift),
  ];
  return await getCloudinaryFrames(url, intervals);
};

export const getCloudinaryFrames = (url: string, intervals: number[]): Promise<Frame[]> => {
  const [first, second] = url.split('/upload/');
  // add a check see if second is defined
  return Promise.all(
    intervals.map(async (currTime) => {
      return new Promise<Frame>((resolve) => {
        const transformed = `${first}/upload/so_${currTime}/${second}`;
        fetch(transformed).then(() => {
          const thumbnail = new Image();
          thumbnail.src = getImageJPG(transformed);
          resolve({
            thumbnail: thumbnail,
            time: currTime,
          });
        });
      });
    }),
  );
};
export const getImageJPG = (url: string) => {
  let l = url.split('.');
  l.pop();
  let b = l.join('.');
  return b + '.jpg';
};
export const googleStorageVideoUrlToCloudinaryUrl = (url: string) => {
  let l = url.split('?')[0] || '';
  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace(
      'https://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/video/upload/v1/',
    );
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace(
      'http://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/video/upload/v1/',
    );
  }
  return l;
};
export const getVideoWithoutText = (url: string) => {
  if (!url) return '';
  const [first, second] = url.split('co_rgb:fff');
  if (!second) return url;
  let [, ...rest] = second.split('/');
  return `${first}${rest.join('/')}`;
};
export const googleStorageToCloudinary = (url: string) => {
  let l = url.split('?')[0] || '';
  if (l.includes('https://storage.googleapis.com/')) {
    l = l.replace(
      'https://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  if (l.includes('http://storage.googleapis.com/')) {
    l = l.replace(
      'http://storage.googleapis.com/',
      'https://res.cloudinary.com/socialvenu/image/upload/v1/',
    );
  }
  return l;
};

// Copypaste from web-app
interface UploadThumbnailRequest {
  options: UploadUrlApiModel;
  data: string;
}

export const uploadThumbnail = async ({ options, data }: UploadThumbnailRequest) => {
  if (!data) {
    return;
  }
  try {
    const file = await fetch(data).then((r) => r.blob());
    return await axios.put<typeof options, undefined>(options.url, file, {
      headers: options.headers,
    });
  } catch (error) {
    console.log(error);
  }
};
