import { FC, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  CreateTransactionBatchResponse,
  TransactionRecord,
  TransactionRecordStatus,
} from '../../../api/models/transaction';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { IEventTracker } from '../../../hooks/useTrackEvent';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { postTransactionBatch, putTransactionBatch } from '../../../store/slices/dataSyncSlice';

interface Props {
  record: TransactionRecord;
  handleTrackEvent: IEventTracker;
}

export const ResendInviteBtn: FC<Props> = ({ record, handleTrackEvent }) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useAppDispatch();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);

  const resendInvite = async () => {
    setIsLoading(true);

    handleTrackEvent(
      RewardCampaignsEvents.RewardCampaignsInvitedCustomersTableResendInviteClickClick /* {sel} */,
    );

    try {
      const batch = await dispatch(
        postTransactionBatch({
          venueId: record.venueId!,
          campaignId: currentCampaign?.id,
          name: currentCampaign?.name!,
          records: [record],
        }),
      );

      await dispatch(
        putTransactionBatch({ id: (batch.payload as CreateTransactionBatchResponse).id }),
      ).then(() => {
        setIsLoading(false);
        addToast('Invite Sent', {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      });
    } catch (error) {
      setIsLoading(false);
      addToast('Invite Failed', { appearance: 'error', autoDismissTimeout: 2500 });
    }
  };
  return (
    <RoundedButton
      title="Resend Invite"
      onClick={resendInvite}
      loading={isLoading}
      disabled={record.status === TransactionRecordStatus.Active}
      fontWeight={600}
      fontSize="9px"
      sx={{
        minWidth: '110px',
        height: '20px',
        padding: '14px',
        '& p': {
          color: 'white',
        },
      }}
      id={`${record.fullName?.split(' ').join('-')}-resend-invite-button`}
    />
  );
};
