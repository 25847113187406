import { FC } from 'react';

type PlayFillIconProps = {
  color?: string;
};

export const PlayOutlinedIcon: FC<PlayFillIconProps> = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.13026 12.4613V10.9813C9.13026 7.46098 9.13026 7.46168 12.1303 9.25126L13.4103 9.99126L14.6903 10.7313C17.0303 12.4613 17.5303 12.4613 14.6903 14.1913L13.4103 14.9313L12.1303 15.6713C8.53027 17.4609 9.13028 17.4608 9.13026 13.9413V12.4613Z"
        fill="none"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0303 22.4614C17.5531 22.4614 22.0303 17.9843 22.0303 12.4614C22.0303 6.93858 17.5531 2.46143 12.0303 2.46143C6.50743 2.46143 2.03027 6.93858 2.03027 12.4614C2.03027 17.9843 6.50743 22.4614 12.0303 22.4614Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
