import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './AdvancedTabContent.styles';
import { ClipsSearchStrings, CreatorsSearchStrings } from '../../../../localization/en';
import { RoundedButton } from '../../../Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  ClipsCountFilterAttribute,
  CreatorAttributeName,
  CreatorFilterAttribute,
  IsOptedOutFilterAttribute,
  LastVideoCreatedAtFilterAttribute,
  RewardedFilterAttribute,
  SearchOperationType,
  UserStatusFilterAttribute,
  VerifiedFilterAttribute,
} from '../../../../../api/models/searchFilterModels';
import { DateRange, FilterDatePicker } from '../../../FilterDatePicker/FilterDatePicker';
import { VerificationStatusFilterSelect } from '../../../VerificationStatusFilterSelect/VerificationStatusFilterSelect';
import {
  removeCreatorDateFilter,
  resetTags,
  setActiveCreatorFilterAttributes,
} from '../../../../../store/slices/searchFilterSlice';
import { getUTCDateString } from '../../../../../services/utilities';
import { ClipsCountSelect, CountRange } from '../../../ClipsCountSelect/ClipsCountSelect';
import { RewardRange, RewardSelect } from '../../../RewardSelect/RewardSelect';
import format from 'date-fns/format';
import { backEndDateFormat } from '../../../../constants/constants';

interface Props {
  onSearch: () => void;
}

export const AdvancedTabContent: FC<Props> = ({ onSearch }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [lastClipDateRange, setLastClipDateRange] = useState<DateRange | null>(null);

  const [countRange, setCountRange] = useState<CountRange[]>([]);
  const [rewardRange, setRewardRange] = useState<RewardRange[]>([]);
  const [verificationStatusAttribute, setVerificationStatusAttribute] = useState<
    VerifiedFilterAttribute | IsOptedOutFilterAttribute | null
  >(null);

  const { activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  const handleSearch = () => {
    const filterAttributes: CreatorFilterAttribute[] = [];

    const statusAttribute = activeCreatorFilterAttributes.find(
      (attribute) => attribute.name === CreatorAttributeName.Status,
    ) as UserStatusFilterAttribute;

    if (statusAttribute) {
      filterAttributes.push(statusAttribute);
    }

    if (verificationStatusAttribute) {
      filterAttributes.push(verificationStatusAttribute);
    }
    if (lastClipDateRange) {
      const dateFrom = new Date(getUTCDateString(lastClipDateRange.from));
      const dateTo = new Date(getUTCDateString(lastClipDateRange.to));
      dateTo.setHours(23);
      dateTo.setMinutes(59);

      const fromAttribute: LastVideoCreatedAtFilterAttribute = {
        name: CreatorAttributeName.LastVideoCreatedAt,
        value: dateFrom.toISOString(),
        operation: SearchOperationType.GreaterThan,
      };

      const toAttribute: LastVideoCreatedAtFilterAttribute = {
        name: CreatorAttributeName.LastVideoCreatedAt,
        value: dateTo.toISOString(),
        operation: SearchOperationType.LessThan,
      };

      filterAttributes.push(fromAttribute, toAttribute);
    }

    if (countRange.length > 0) {
      const countAttributes: ClipsCountFilterAttribute[] = countRange.map((item) => ({
        ...item,
        name: CreatorAttributeName.ClipsCount,
      }));

      filterAttributes.push(...countAttributes);
    }

    if (rewardRange.length > 0) {
      const rewardAttributes: RewardedFilterAttribute[] = rewardRange.map((item) => ({
        ...item,
        name: CreatorAttributeName.RewardsFulfilled,
      }));

      filterAttributes.push(...rewardAttributes);
    }

    dispatch(resetTags());
    dispatch(setActiveCreatorFilterAttributes(filterAttributes));
    onSearch();
  };

  const from = activeCreatorFilterAttributes.find(
    (attribute) =>
      attribute.name === CreatorAttributeName.LastVideoCreatedAt &&
      attribute.operation === SearchOperationType.GreaterThan,
  )?.value as string;

  const to = activeCreatorFilterAttributes.find(
    (attribute) =>
      attribute.name === CreatorAttributeName.LastVideoCreatedAt &&
      attribute.operation === SearchOperationType.LessThan,
  )?.value as string;

  useEffect(() => {
    if (from && to) {
      setLastClipDateRange({
        from: format(new Date(from), backEndDateFormat),
        to: format(new Date(to), backEndDateFormat),
      });
    }
  }, [from, to]);

  const getInitialDate = () => {
    return from && to
      ? {
          from: new Date(from),
          to: new Date(to),
        }
      : null;
  };

  return (
    <Box className={classes.container}>
      <Typography className={classes.selectTitle}>{CreatorsSearchStrings.Verification}</Typography>
      <Box className={classes.selectWrapper}>
        <VerificationStatusFilterSelect
          onChange={(status) => {
            setVerificationStatusAttribute(status);
          }}
        />
      </Box>

      <Typography className={classes.selectTitle}>{CreatorsSearchStrings.LastClip}</Typography>
      <Box className={classes.selectWrapper}>
        <FilterDatePicker
          updateRange={(range) => {
            setLastClipDateRange(range);
          }}
          width="100%"
          initialValue={getInitialDate()}
          onClear={() => {
            dispatch(removeCreatorDateFilter());
            setLastClipDateRange(null);
          }}
          shouldClearLabel={true}
        />
      </Box>

      <Typography className={classes.selectTitle}>{CreatorsSearchStrings.ClipCount}</Typography>
      <Box className={classes.selectWrapper}>
        <ClipsCountSelect
          onChange={(range) => {
            setCountRange(range);
          }}
          countRange={countRange}
        />
      </Box>
      <Typography className={classes.selectTitle}>{CreatorsSearchStrings.Rewarded}</Typography>
      <Box className={classes.selectWrapper}>
        <RewardSelect
          onChange={(range) => {
            setRewardRange(range);
          }}
        />
      </Box>
      <Box className={classes.searchButtonWrapper}>
        <RoundedButton
          title={ClipsSearchStrings.Search}
          onClick={handleSearch}
          borderRadius="8px"
          fontSize="16px"
          fontWeight="600"
        />
      </Box>
    </Box>
  );
};
