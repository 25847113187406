import { FC } from 'react';
import { Box } from '@mui/material';
import { VideoApiModel } from '../../../api/models/videos';
import { useStyles } from './videoStatustics.helper';
import { PointsStarIcon } from '../../assets/PointsStarIcon';
import { EyeIcon, TochAppIcon } from '../../assets/newDesign/UserDetails/index';

interface VideoStatisticsProps {
  video: VideoApiModel;
}
export const VideoStatistics: FC<VideoStatisticsProps> = ({ video }) => {
  const styles = useStyles();
  const { views, ctaClicks } = video;

  let conversion = 0;
  if (ctaClicks > 0 && views > 0) {
    conversion = (ctaClicks / views) * 100;
  }

  return (
    <div className={styles.root}>
      <Box className={styles.stat}>
        <span className={styles.indicator}>
          <EyeIcon />
        </span>
        <Box>
          <Box>{views}</Box>
        </Box>
      </Box>
      <Box className={styles.stat}>
        <span className={styles.indicator}>
          <TochAppIcon />
        </span>
        <Box>
          <Box>{ctaClicks}</Box>
        </Box>
      </Box>
      <Box className={styles.stat}>
        <span className={styles.indicatorStar}>
          <PointsStarIcon color="#8B89A0" />
        </span>
        <Box>
          <Box>{conversion.toFixed()} %</Box>
        </Box>
      </Box>
    </div>
  );
};
