import React, { useState } from 'react';
import { useFormik } from 'formik';

import { numberWithCommas, setFieldNumberValue } from '../../../../services/utilities';

import { TextFieldComponent } from '../../../../common/components/TextField/TextField';

import {
  initialValues,
  InitialValues,
  validationSchema,
  ClientTypesFormFields,
} from './FormSection.helper';
import {
  Container,
  FormField,
  FormFieldText,
  SubmitButton,
  TextFieldWrapper,
  BottomSection,
  RecommendedField,
  RecommendedFieldText,
  RecommendedFieldValue,
  RecommendedFieldWrapper,
} from './FormSection.style';
import { RecommendedValues } from '../RewardsWizard.helper';
import { useTypedSelector } from '../../../../store';
import { RewardsLeaderboardDetails } from '../../../../common/localization/en';

interface FormSectionProps {
  onSubmit: (values: InitialValues) => void;
  onChangeForm: () => void;
  // reset: string;
  initValues: InitialValues | null;
  isMobile: boolean;
  campaignCreationInProcessing?: boolean;
  recommendedRewardsValue: RecommendedValues[];
  formFields: ClientTypesFormFields[];
}
export const FormSection: React.FC<FormSectionProps> = ({
  onSubmit,
  initValues,
  onChangeForm,
  isMobile,
  recommendedRewardsValue,
  formFields,
}) => {
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const [disabledBtn, setDisabledBtn] = useState(true);
  const formInitValues = initValues || initialValues;

  const formikRewards = useFormik({
    initialValues: formInitValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      onSubmitHandler(values);
    },
  });
  const { handleSubmit, values, errors, isValid, setFieldValue } = formikRewards;

  const submitBtnName = RewardsLeaderboardDetails.Step1CTA;

  const onSubmitHandler = (values: InitialValues) => {
    onSubmit(values);
    setDisabledBtn(true);
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldNumberValue(event, setFieldValue);
    setDisabledBtn(false);
    onChangeForm();
  };

  const getKeyValue = <T extends object, U extends keyof T>(key: U, obj: T) => obj[key];

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        {formFields.map((formField, index) => {
          const formName = `row${index + 1}` as keyof InitialValues;
          const formValue = getKeyValue(formName, values);
          const adornment = formField.isAdornment ? '$' : '';
          return (
            <FormField>
              <FormFieldText>{formField.text}</FormFieldText>
              <TextFieldWrapper>
                <TextFieldComponent
                  name={formName}
                  label={formField.label}
                  value={numberWithCommas(formValue)}
                  onChange={onChangeHandler}
                  errorText={errors[formName]}
                  adornment={adornment}
                  disabled={!!activeCampaign}
                  fullWidth
                />
              </TextFieldWrapper>
            </FormField>
          );
        })}

        <RecommendedFieldWrapper>
          {recommendedRewardsValue.map((recommendedValue) => (
            <RecommendedField isValue={!!recommendedValue.value}>
              <RecommendedFieldText isValue={!!recommendedValue.value}>
                {recommendedValue.name}
              </RecommendedFieldText>
              {recommendedValue.value && (
                <RecommendedFieldValue isValue>${recommendedValue.value}</RecommendedFieldValue>
              )}
            </RecommendedField>
          ))}
        </RecommendedFieldWrapper>

        <BottomSection>
          <SubmitButton
            disabled={!(isValid && !disabledBtn) || !!activeCampaign}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth={isMobile}
          >
            {submitBtnName}
          </SubmitButton>
        </BottomSection>
      </form>
    </Container>
  );
};
