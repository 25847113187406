import styled from 'styled-components';
import { theme } from '../../../../theme';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Points = styled.div`
  position: absolute;
  color: ${theme.palette.common.white};
  z-index: 1;
`;

interface WrapperProps {
  position?: string;
}
export const Wrapper = styled.div<WrapperProps>`
  ${(props) => `transform: rotate(${props.position})`};
`;
