import styled from 'styled-components';

export const ButtonClose = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  background-color: black;
  border-radius: 50%;
`;

export const ModalInner = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    min-width: 420px;
  }

  @media (max-width: 767px) {
    min-width: 240px;
    width: 100%;
  }
`;

export const ImgWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
`;

interface ModalTitleProps {
  center?: boolean;
}

export const ModalTitle = styled.h2<ModalTitleProps>`
  margin-bottom: 10px;
  display: flex;
  justify-content: ${(props) => props.center ? "center" : "left"};
  color: #1a1538;
  font-size: 24px;
`;

export const ModalText = styled.p<ModalTitleProps>`
  font-size: 15px;
  margin-top: 12px;
  color: #1a1538;
  text-align: ${(props) => props.center ? "center" : "left"};
  color: #1a1538;
`;

export const ModalBtnWrapper = styled.div`
  margin-top: 30px;
  align-self: flex-end;

  > button:first-child {
    margin-right: 16px;
  }
`;

export const ModalVideoContainer = styled.div`
  display: flex;
`;
