import React from 'react';

const ArrowLeft = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8346 9.99984L4.16797 9.99984M4.16797 9.99984L10.0013 15.8332M4.16797 9.99984L10.0013 4.1665"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeft;
