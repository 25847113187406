import { FC } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { HighlighterComponent } from './Transcript.helper';
import { useTranscriptStyles } from './Transcript.style';

interface ITranscriptProps {
  trancsriptText: string;
  keywords: string[];
  keywordsScore: number;
}

export const Transcript: FC<ITranscriptProps> = ({ trancsriptText, keywords, keywordsScore }) => {
  const classes = useTranscriptStyles();
  return (
    <Box className={classes.TranscriptContainer}>
      <Grid className={classes.TranscriptTitleContainer}>
        <Typography className={classes.TranscriptTitle}>
          {VideoDetailsModalStringsDv5.Transcript}
        </Typography>

        {keywordsScore > 0 && (
          <Box className={classes.TranscriptScore}>
            Keyword Score:&nbsp;
            <Box className={classes.TranscriptScoreBold}>{(keywordsScore * 100).toFixed(0)}%</Box>
          </Box>
        )}
      </Grid>

      {trancsriptText ? (
        <Typography className={classes.TranscriptText}>
          "
          {
            <HighlighterComponent
              textToHighlight={trancsriptText}
              searchWords={keywords}
              highlightClassName={classes.HighlightTag}
              highlightTag={'em'}
              autoEscape={true}
            />
          }
          "
        </Typography>
      ) : null}
    </Box>
  );
};
