import { FC, MouseEvent as ReactMouseEvent, SetStateAction, useState } from 'react';
import { Box, Button, Grid, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useFormik } from 'formik';

import { Title, Wrapper, Label, DeleteButton } from './UsersBlock.style';
import { validationSchemaAccountBox, AccountBoxValues } from './UsersBlock.helper';
import { ManagerAccount } from '../../../../../../../store/storeModels';
import {
  VenueCreationWizardStrings,
  LoginPageStrings,
} from '../../../../../../../common/localization/en';
import {
  TextFieldComponent,
  AdornmentPosition,
} from '../../../../../../../common/components/TextField/TextField';
import { getError } from '../../../../../../../services/utilities';

export interface AccountSetingsProps extends ManagerAccount {
  submited: boolean;
}

interface AccountsBoxProps {
  account: AccountSetingsProps;
  index: number;
  setUsers: (value: SetStateAction<ManagerAccount[]>) => void;
  additionalTopBlock: JSX.Element | null;
  handleDelete: (value: number) => void;
  isDeleteBtn: boolean;
  canEdit: boolean;
}

export const Usersblock: FC<AccountsBoxProps> = ({
  account,
  index,
  setUsers,
  additionalTopBlock,
  handleDelete,
  isDeleteBtn,
  canEdit,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    values,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    isValid,
    dirty,
    touched,
  } = useFormik({
    initialValues: {
      password: account.password,
      role: account.role,
      name: account.name,
      email: account.email,
      confirmationPassword: '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: validationSchemaAccountBox,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  const calculateUpdate = (a: AccountSetingsProps[], values: AccountBoxValues) => {
    const result = [...a];
    result[index] = {
      ...account,
      password: values.password,
      submited: true,
      name: values.name,
      email: values.email,
      role: values.role,
    };

    return result;
  };

  const submitForm = (values: AccountBoxValues) => {
    setUsers((prevAccounts: AccountSetingsProps[]) => calculateUpdate(prevAccounts, values));
  };

  const handleMouseDownPassword = (event: ReactMouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const getErrorHandler = (name: string) => {
    return getError(name, touched, errors);
  };

  return (
    <Wrapper>
      {additionalTopBlock}
      <form onSubmit={handleSubmit}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          component="fieldset"
          border="none"
          padding={0}
          disabled={canEdit}
        >
          <Grid item xs={12}>
            <Title>
              {VenueCreationWizardStrings.OwnerId.replace(':id', (index + 1).toString())}
            </Title>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
              <Grid item xs={2}>
                <Label>{VenueCreationWizardStrings.UserName}</Label>
              </Grid>
              <Grid item xs={4}>
                <TextFieldComponent
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  errorText={getErrorHandler('name')}
                  disabled={account.submited}
                  placeholder={VenueCreationWizardStrings.UserName}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextFieldComponent
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  errorText={getErrorHandler('password')}
                  disabled={account.submited}
                  placeholder={LoginPageStrings.Password}
                  label={LoginPageStrings.Password}
                  adornmentPosition={AdornmentPosition.End}
                  adornment={
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
              <Grid item xs={2}>
                <Label>{VenueCreationWizardStrings.UserEmail}</Label>
              </Grid>
              <Grid item xs={4}>
                <TextFieldComponent
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  errorText={getErrorHandler('email')}
                  disabled={account.submited}
                  placeholder={LoginPageStrings.Email}
                  fullWidth
                />
              </Grid>
              <Grid item xs={5}>
                <TextFieldComponent
                  name="confirmationPassword"
                  type={showPassword ? 'text' : 'password'}
                  value={values.confirmationPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  errorText={getErrorHandler('confirmationPassword')}
                  disabled={account.submited}
                  placeholder={LoginPageStrings.ConfirmationPassword}
                  label={LoginPageStrings.ConfirmationPassword}
                  adornmentPosition={AdornmentPosition.End}
                  adornment={
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={4} width="100%" display="flex" justifyContent="end">
            {isDeleteBtn ? (
              <DeleteButton startIcon={<DeleteOutlineIcon />} onClick={() => handleDelete(index)}>
                {VenueCreationWizardStrings.DeleteUser}
              </DeleteButton>
            ) : (
              <></>
            )}
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={!(isValid && dirty && !account.submited)}
            >
              {account.submited
                ? VenueCreationWizardStrings.Submitted
                : VenueCreationWizardStrings.Submit}
            </Button>
          </Box>
        </Grid>
      </form>
    </Wrapper>
  );
};
