import { FC } from 'react';

interface EmailIconProps {
  color?: string;
}
export const EmailIcon: FC<EmailIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width="16"
        height="12"
        viewBox="0 0 16 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.84615 0.166626C1.10269 0.166626 0.5 0.756358 0.5 1.48383V10.5161C0.5 11.2436 1.10269 11.8333 1.84615 11.8333H14.1538C14.8973 11.8333 15.5 11.2436 15.5 10.5161V1.48383C15.5 0.756358 14.8973 0.166626 14.1538 0.166626H1.84615ZM1.65385 1.70488V10.5161C1.65385 10.62 1.73995 10.7043 1.84615 10.7043H14.1538C14.2601 10.7043 14.3462 10.62 14.3462 10.5161V1.70488L8.39458 7.16449C8.17267 7.36806 7.82734 7.36806 7.60542 7.16449L1.65385 1.70488ZM13.1052 1.29566H2.89481L8 5.97885L13.1052 1.29566Z"
          fill="#8F94B6"
          stroke={color}
        />
      </svg>
    </>
  );
};
