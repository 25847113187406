import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
  },
  clearButton: {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#344054',
    position: 'absolute',
    top: '-34px',
    right: '0',
    minWidth: '54px',
    padding: '5px 1px',
  },
  expandIconWrapper: {
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '-10px',
    left: '-30px',
  },
  selectedItemIcon: {
    paddingRight: '8px',
    paddingTop: '6px',
  },
  selectedItemText: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
  },

  select: {
    width: '100%',
    border: '1px solid #cacae4',
    paddingRight: 0,

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none!important',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: 'none!important',
    },
  },

  menuItem: {
    backgroundColor: '#fff !important',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },

  menuIcon: {
    paddingRight: '8px',
    paddingTop: '4px',
  },

  menuItemText: {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
  },
}));
