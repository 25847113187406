import { FC } from 'react';
import { useStyles } from './CreatorName.styles';
import { Box, Button, Typography } from '@mui/material';
import { PlayIcon } from '../../assets/PlayIcon';

interface Props {
  creatorName: string;
  showPlayIcon?: boolean;
}

export const CreatorName: FC<Props> = ({ creatorName, showPlayIcon }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {showPlayIcon && <Button startIcon={<PlayIcon />} className={classes.btn} />}

      <Typography className={classes.nameText}>{creatorName}</Typography>
    </Box>
  );
};
