import {
  PostRewardFulfillmentConfigRequest,
  PostRewardFulfillmentTestSMSRequest,
  PostRewardFulfillmentTestWebhookRequest,
} from '../../../api/models/rewardFulfillment';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getRewardFulfillmentConfig,
  postRewardFulfillmentConfig,
  postRewardFulfillmentTestSMS,
  postRewardFulfillmentTestWebhook,
} from '../../../store/slices/rewardFulfillmentSlice';

export const useRewardFulfillmentApiCalls = () => {
  const venueId = useTypedSelector((state) => state.venue.venue.id);

  const dispatch = useAppDispatch();

  return {
    getRewardFulfillmentConfig: async () => {
      return dispatch(getRewardFulfillmentConfig({ venueId }));
    },
    postRewardFulfillmentConfig: async (payload: PostRewardFulfillmentConfigRequest['payload']) => {
      return dispatch(postRewardFulfillmentConfig({ venueId, payload }));
    },
    postRewardFulfillmentTestSMS: async (
      payload: PostRewardFulfillmentTestSMSRequest['payload'],
    ) => {
      return dispatch(postRewardFulfillmentTestSMS({ venueId, payload }));
    },
    postRewardFulfillmentTestWebhook: async (
      payload: PostRewardFulfillmentTestWebhookRequest['payload'],
    ) => {
      return dispatch(postRewardFulfillmentTestWebhook({ venueId, payload }));
    },
  };
};
