import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    borderRadius: '16px',
    boxShadow: '24px',
    width: '835px',
    height: '700px',
    padding: '24px',
  },
  title: {
    fontWeight: '600',
    fontSize: '30px',
    color: theme.palette.primary.main,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
  },
  contentContainer: {
    display: 'flex',
    marginTop: '16px',
    height: '500px',
  },
  sectionsContainer: {
    minWidth: '450px',
  },
  previewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  iphonePreviewContainer: {
    height: '400px',
  },
  actionButtonsContainer: {
    display: 'flex',
    gap: '12px',
  },
}));
