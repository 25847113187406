import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme';

export const useManagerSwitchStyles = makeStyles((theme: Theme) => ({
  ManagerSwitch: {
    '& .MuiSwitch-root': {
      width: 80,
      height: 48,
      padding: 8,
    },
    '& .MuiSwitch-switchBase': {
      padding: 11,
      color: '#ff6a00',
    },
    '& .MuiSwitch-thumb': {
      width: 26,
      height: 26,
      backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
      background: 'linear-gradient(to right, #ee0979, #ff6a00)',
      opacity: '1 !important',
      borderRadius: 20,
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        textAlign: 'center',
      },
      '&:before': {
        content: '"on"',
        left: 4,
        opacity: 0,
      },
      '&:after': {
        content: '"off"',
        right: 4,
      },
    },
    '& .Mui-checked': {
      '& .MuiSwitch-switchBase': {
        color: '#185a9d',
        transform: 'translateX(32px)',
        '&:hover': {
          backgroundColor: 'rgba(24,90,257,0.08)',
        },
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: '#fff',
      },
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(to right, #43cea2, #185a9d)',
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        },
      },
    },
  },
}));

export const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 24,
    lineHeight: 1.3,
  },
  subheading: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
  },
  form: {
    display: 'grid',
    gap: 20,
    gridTemplateColumns: 'auto 1fr 1fr auto',
  },
  switch: {
    alignSelf: 'center',
  },
  bottomControllers: {
    display: 'flex',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    flexDirection: 'row-reverse',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0px 10px 38px 11px rgba(10, 25, 107, 0.05)',
    boxSizing: 'border-box',
    padding: '24px 58px',
    zIndex: 100,
  },
  actionButton: {
    float: 'right',
    margin: '5px 2px',
    width: '120px',
  },
  box: {
    position: 'absolute',
    left: 0,
    top: '115px',
    backgroundColor: theme.palette.common.white,
    padding: '24px 24px 16px',
    borderRadius: '15px',
    marginLeft: 24,
    marginRight: 24,
    width: 'calc(100% - 40px)',

    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-18px',
      marginRight: '8px',
      marginLeft: '8px',
    },
    '& .MuiTableCell-head:last-child > .MuiBox-root': {
      justifyContent: 'flex-end',
      paddingRight: '44px',
    },
    '& .MuiTableCell-head:first-child > .MuiBox-root': {
      paddingLeft: '62px',
    },
  },
}));
