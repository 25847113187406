import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '24px 0',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828',
    lineHeight: '28px',
  },
  subtitle: {
    marginTop: '10px',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '#8B89A0',
  },
  divider: {
    width: '100%',
    marginTop: '21px',
  },
  contentWrapper: {
    padding: '20px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    height: '500px',
  },
  inputsWrapper: {
    width: '404px',
    paddingTop: '70px',
  },
  label: {
    marginBottom: '11px',
    lineHeight: '24px',
    color: '#1A1538',
    letterSpacing: '0.24px',
    fontSize: '12px',
  },
  linkFieldWrapper: {
    position: 'relative',
    width: '100%',
  },
  httpsText: {
    position: 'absolute',
    width: '61px',
    height: '42px',
    background: theme.palette.primary.main,
    borderRadius: '10px 0px 0px 10px',
    color: '#fff',
    letterSpacing: '0.24px',
    lineHeight: '24px',
    zIndex: '10',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  previewWrapper: {
    transform: 'scale(0.78) translate(40px, -70px)',
  },
  buttonsWrapper: {
    display: 'flex',
    width: '100%',
    paddingLeft: '22px',
  },
  button: {
    borderRadius: '8px',
    '& p': {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
}));
