import { init } from 'emoji-mart';
import { useCallback } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { setEmojiState } from '../../../store/slices/uiSlice';

export const useInitEmoji = () => {
  const dispatch = useAppDispatch();
  const { emoji } = useTypedSelector((state) => state.ui);

  const initializeEmoji = useCallback(async () => {
    if (emoji.initialized || emoji.loading) return;

    dispatch(setEmojiState({ initialized: false, loading: true }));
    try {
      const importedData = await import('@emoji-mart/data');
      // convert module to an object
      const data = { ...importedData };
      init({ data });
      dispatch(setEmojiState({ initialized: true, loading: false }));
    } catch (error) {
      console.error('emoji initialization error:', error);
      dispatch(setEmojiState({ initialized: false, loading: false }));
    }
  }, [dispatch, emoji.initialized, emoji.loading]);

  return { initialized: emoji.initialized, loading: emoji.loading, initializeEmoji };
};
