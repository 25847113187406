import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    minHeight: '99px',
    marginTop: '18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  clickThroughWrapper: {
    width: '190px',
    height: '85px',
    border: '1px solid #F3B03E',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  tooltip: {
    width: '315px',
  },
  rateText: {
    color: '#000',
    fontSize: '15px',
    fontWeight: 500,
  },
  tooltipTitle: {
    color: '#344054',
    fontSize: '12px',
    fontWeight: 500,
  },
  tooltipDescr: {
    color: '#667085',
    fontSize: '12px',
    fontWeight: 500,
  },
  rateValue: {
    color: '#101828',
    fontSize: '36px',
    fontWeight: 600,
  },
  countsWrapper: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    minWidth: '360px',
  },
  countsTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  countsTooltip: {
    width: '315px',
  },
  countsTooltipSection: {
    marginBottom: '14px',
  },
  countsSectionsWrapper: {
    display: 'flex',
  },
  countsSection: {
    display: 'flex',
    alignItems: 'end',
  },
  countsName: {
    color: '#475467',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.45px',
    marginRight: '14px',
  },
  countsValue: {
    color: '#101828',
    fontSize: '34px',
    fontWeight: 600,
    lineHeight: '100%',
    marginRight: '12px',
  },
  divider: {
    height: '44px',
    borderRight: '1px solid #EAECF0',
    marginRight: '14px',
  },
}));
