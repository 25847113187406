import styled from 'styled-components';
import { FormControl } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const textColor = '#1A1538';

export const useStyles = makeStyles<Theme>((theme) => ({
  topbarGraphContainer: {
    margin: '20px 0',
    width: '100%',
  },
  root: {
    width: 'inherit',
    padding: 5,
  },
  textTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: '#000',
  },
  chacked: {
    transition: 'all .3s easy',
    background: `${theme.palette.primary.contrastText}`,
  },
  input: {
    display: 'none',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  label: {
    margin: '0',
    borderRadius: 40,
    padding: '3px 15px',
    '&>span': {
      fontWeight: 400,
      fontSize: '12px !important',
      lineHeight: '24px',
      color: `${textColor}`,
    },
    '&:hover': {
      transition: 'all .3s',

      background: '#FFFFFF',
      boxShadow: ' 0px 18px 30px rgba(64, 77, 104, 0.1)',
    },
  },
}));

export const FormControlFilterUser = styled(FormControl)`
  width: 196px;
  height: 40px;
  background: #f5f6f8;
  border-radius: 40px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
`;
