import { FC } from 'react';
import clsx from 'clsx';
import { UserStatuses } from '../../../../../../api/models/users';
import { IndicatorWrapper } from './StatusComponent.style';

interface StatusComponentNoEditProps {
  status: UserStatuses;
  small?: boolean;
}
export const StatusComponentNoEdit: FC<StatusComponentNoEditProps> = ({ status, small }) => {
  return (
    <IndicatorWrapper
      className={clsx({
        'active-i': status === UserStatuses.active,
        'inactive-i': status === UserStatuses.inactive,
        'archived-i': status === UserStatuses.archived,
        'small-i': small,
      })}
    >
      {status === UserStatuses.active && 'Active'}
      {status === UserStatuses.inactive && 'Inactive'}
      {status === UserStatuses.archived && 'Archived'}
    </IndicatorWrapper>
  );
};
