import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import format from 'date-fns/format';

import { DateRangeFilterStrings } from '../../localization/en';
import { frontEndDateFormat } from '../../constants/constants';

export const DefaultWidth = '280px';

export enum PredefinedFilters {
  SinceBeginning = 'since_beginning',
  Today = 'today',
  Yesterday = 'yesterday',
  LastWeek = 'last_week',
  ThisMonth = 'this_month',
  LastMonth = 'last_month',
  LastThreeMonths = 'last_three_months',
  LastSixMonths = 'last_six_months',
  Custom = 'custom',
}

export const dateRangeFilterDefaultValues = {
  dateRange: {},
  chosenPredefinedFilter: PredefinedFilters.Custom,
};

export const predefinedFilterLabels = {
  [PredefinedFilters.SinceBeginning]: DateRangeFilterStrings.SinceBeginning,
  [PredefinedFilters.Today]: DateRangeFilterStrings.Today,
  [PredefinedFilters.Yesterday]: DateRangeFilterStrings.Yesterday,
  [PredefinedFilters.LastWeek]: DateRangeFilterStrings.LastWeek,
  [PredefinedFilters.ThisMonth]: DateRangeFilterStrings.ThisMonth,
  [PredefinedFilters.LastMonth]: DateRangeFilterStrings.LastMonth,
  [PredefinedFilters.LastThreeMonths]: DateRangeFilterStrings.LastThreeMonth,
  [PredefinedFilters.LastSixMonths]: DateRangeFilterStrings.LastSixMonth,
  [PredefinedFilters.Custom]: DateRangeFilterStrings.Custom,
};

export const createLabelFromDateRange = (from?: Date | null, to?: Date | null): string => {
  const startLabel = from ? format(from, frontEndDateFormat) : '';
  const endLabel = to ? format(to, frontEndDateFormat) : '';
  return `${startLabel} - ${endLabel}`;
};

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  selectContainer: {
    border: '1px solid #D0D5DD',
    height: '40px',
    width: '100%',
    borderRadius: '8px',
    paddingRight: '14px',
    cursor: 'pointer',
    padding: '0 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  clearButton: {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    color: '#344054',
    position: 'absolute',
    top: '-34px',
    right: '0',
    minWidth: '54px',
    padding: '5px 1px',
  },
  labelText: {
    fontSize: '11px',
  },
  inputWrapper: {
    height: '40px',
    width: '100%',
    background: 'red',
  },

  paperRoot: {
    height: 'auto',
    width: 'max-content',
    borderRadius: 20,
    zoom: '0.9',
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '210px',
  },
  divider: {
    height: '100%',
    marginLeft: 'auto',
  },
  buttonsCalendarBlock: {
    height: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    padding: '10px 20px',

    '& > .MuiButton-contained': {
      backgroundColor: theme.palette.primary.main,
    },

    '& > button:first-child': {
      color: theme.palette.common.black,
    },

    '& > button': {
      minWidth: 64,
      padding: '9px 30px 10px',
      borderRadius: 100,
      color: theme.palette.common.white,
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '26px',
    },
  },
  listContainer: {
    width: '100%',
  },
  listItem: {
    padding: '8px 16px',
    marginBottom: '8px',
    borderRadius: '8px',
    '& .MuiTypography-root': {
      color: theme.palette.common.black,
      width: 'max-content',
    },
  },
  activeListItem: {
    color: theme.palette.primary.main,
    borderRadius: '8px',
    background: theme.palette.primary.light,
    '& .MuiTypography-root': {
      fontWeight: '500',
      color: theme.palette.primary.main,
    },
  },
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  onlyOneHighlight: {
    extend: 'highlight',
    borderRadius: '50%',
  },
  button: {
    padding: '9px 30px 10px',
  },
  resetButton: {
    cursor: 'pointer',
  },
}));
