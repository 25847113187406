import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useModal } from '../../../hooks/useModal';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getMe, patchMe } from '../../../store/slices/meSlice';
import { AccountProfileStrings, CommonStrings, WorkspaceStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { TextFieldComponent } from '../TextField/TextField';
import { useStyles, validationSchema } from './AccountProfile.helper';
import { PasswordModal } from './PasswordModal/PasswordModal';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';
import { PhoneField } from '../PhoneField/PhoneField';
import clsx from 'clsx';

export const AccountProfile = () => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const {
    isOpen: isPasswordModalOpen,
    open: openPasswordModal,
    close: closePasswordModal,
  } = useModal(false);

  const { firstName, lastName, email, isLoading, id, photoUrl, phoneNumber } = useTypedSelector(
    (state) => state.me,
  );

  useEffect(() => {
    dispatch(getMe({ id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const initialValues = {
    firstName: firstName || '',
    lastName: lastName || '',
    cell: phoneNumber || '',
    email: email || '',
  };

  const {
    values,
    submitForm,
    handleChange,
    handleBlur,
    setFieldTouched,
    dirty,
    errors,
    isSubmitting,
    resetForm,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      await dispatch(
        patchMe({
          id,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          photoUrl,
          phoneNumber: values.cell,
        }),
      )
        .then(() => {
          addToast(WorkspaceStrings.SuccessfullyUpdatedProfile, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        })
        .catch(() => {
          addToast(WorkspaceStrings.FailedProfileUpdate, {
            appearance: 'error',
            autoDismissTimeout: 2500,
          });
        });
    },
  });
  const getFieldError = (field: keyof typeof initialValues) => {
    return touched[field] ? (errors[field] as string) : undefined;
  };

  return (
    <Box className={styles.root}>
      <PasswordModal
        openModal={isPasswordModalOpen}
        toggleModal={closePasswordModal}
        isLoading={isLoading}
        id={id}
      />

      <Box className={styles.profileSection}>
        <Grid className={styles.topbap}>
          <Box>
            <Typography className={styles.pageTitle}>{WorkspaceStrings.Profile}</Typography>
          </Box>
          <Box className={styles.toolbar}>
            <RoundedButton
              title={CommonStrings.Cancel}
              onClick={() => {
                resetForm();
                trackEvent(AccountEventNames.profileButtonCancelClicked);
              }}
              outlined
              sx={{ mr: '15px' }}
              disabled={isSubmitting}
              id="cancel-changes-button"
            />
            <RoundedButton
              title={WorkspaceStrings.SaveChange}
              onClick={() => {
                submitForm();
                trackEvent(AccountEventNames.profileButtonSaveClicked);
              }}
              disabled={!dirty || !!Object.keys(errors).length}
              loading={isSubmitting}
              id="save-changes-button"
            />
          </Box>
        </Grid>
        <Divider color="#DCDBE4" className={styles.top__divider} />
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>First Name</Typography>
            <Typography className={styles.profileRowSubTitle}>
              Update the first name on your account
            </Typography>
          </Grid>
          <Box>
            <TextFieldComponent
              placeholder={AccountProfileStrings.FirstName}
              name="firstName"
              value={values.firstName}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileFirstNameTyped, {
                  value: event.target.value,
                });
              }}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errorText={errors.firstName}
              helperText
              className={styles.textfield}
            />
          </Box>
        </Grid>
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>Last Name</Typography>
            <Typography className={styles.profileRowSubTitle}>
              Update the last name on your account
            </Typography>
          </Grid>
          <Box>
            <TextFieldComponent
              placeholder={AccountProfileStrings.LastName}
              name="lastName"
              value={values.lastName}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileLastNameTyped, {
                  value: event.target.value,
                });
              }}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errorText={errors.lastName}
              helperText
              className={styles.textfield}
            />
          </Box>
        </Grid>
        <Grid className={clsx(styles.profileRow, styles.cellRowWrapper)}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.CellPhone}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateThePhone}
            </Typography>
          </Grid>
          <Box className={styles.cellWrapper}>
            <PhoneField
              name="cell"
              value={values.cell}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileCellPhoneTyped, {
                  value: event.target.value,
                });
              }}
              placeholder={AccountProfileStrings.EnterCell}
              error={getFieldError('cell')}
              className={clsx(styles.textfield, styles.cellField)}
              onBlur={handleBlur}
              setFieldValue={(value: string) => {
                setFieldValue('cell', value);
              }}
              isInternationalPhoneNumberChecked={
                phoneNumber === undefined || phoneNumber === null
                  ? false
                  : phoneNumber?.substring(0, 2) !== '+1'
              }
            />
          </Box>
        </Grid>
        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.Email}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateTheEmail}
            </Typography>
          </Grid>
          <Box>
            <TextFieldComponent
              placeholder={AccountProfileStrings.YourEmail}
              name="email"
              value={values.email || ''}
              onChange={(event) => {
                handleChange(event);
                trackEvent(AccountEventNames.profileEmailTyped, {
                  value: event.target.value,
                });
              }}
              onBlur={handleBlur}
              setFieldTouched={setFieldTouched}
              errorText={errors.email || undefined}
              helperText
              className={styles.textfield}
              disabled
            />
          </Box>
        </Grid>

        <Grid className={styles.profileRow}>
          <Grid xs={4}>
            <Typography className={styles.profileRowTitle}>
              {' '}
              {AccountProfileStrings.Password}
            </Typography>
            <Typography className={styles.profileRowSubTitle}>
              {AccountProfileStrings.UpdateThePassword}
            </Typography>
          </Grid>
          <Button
            onClick={() => {
              openPasswordModal();
              trackEvent(AccountEventNames.profileButtonChangePasswordClicked);
            }}
            variant="outlined"
            size="large"
            color="primary"
            sx={{
              borderRadius: '10px',
              border: '1px solid #E1E5EE',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '26px',
              minWidth: '420px',
            }}
          >
            {CommonStrings.Change}
          </Button>
          {/* <Box>
            <Typography>Last changed: Oct 9, 2022</Typography>
          </Box> */}
        </Grid>
      </Box>
    </Box>
  );
};
