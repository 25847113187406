import { FC } from "react";

export const InvitesIcon:FC  = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4163 16.875H12.083C11.7413 16.875 11.458 16.5917 11.458 16.25C11.458 15.9083 11.7413 15.625 12.083 15.625H15.4163C15.758 15.625 16.0413 15.9083 16.0413 16.25C16.0413 16.5917 15.758 16.875 15.4163 16.875Z"
      fill="#8B89A0"
    />
    <path
      d="M13.75 18.5404C13.4083 18.5404 13.125 18.257 13.125 17.9154V14.582C13.125 14.2404 13.4083 13.957 13.75 13.957C14.0917 13.957 14.375 14.2404 14.375 14.582V17.9154C14.375 18.257 14.0917 18.5404 13.75 18.5404Z"
      fill="#8B89A0"
    />
    <path
      d="M10.1333 9.68464C10.1083 9.68464 10.0916 9.68464 10.0666 9.68464C10.0249 9.6763 9.96659 9.6763 9.91659 9.68464C7.49992 9.60964 5.67492 7.70964 5.67492 5.36797C5.66659 4.21797 6.11658 3.13464 6.93325 2.31797C7.74992 1.5013 8.83325 1.04297 9.99159 1.04297C12.3749 1.04297 14.3166 2.98464 14.3166 5.36797C14.3166 7.70964 12.4916 9.6013 10.1583 9.68464C10.1499 9.68464 10.1416 9.68464 10.1333 9.68464ZM9.99159 2.29297C9.16659 2.29297 8.39992 2.61797 7.81659 3.19297C7.24159 3.7763 6.92492 4.54297 6.92492 5.35964C6.92492 7.0263 8.22492 8.3763 9.88325 8.4263C9.93325 8.41797 10.0416 8.41797 10.1499 8.4263C11.7916 8.3513 13.0666 7.00964 13.0666 5.35964C13.0666 3.6763 11.6833 2.29297 9.99159 2.29297Z"
      fill="#8B89A0"
    />
    <path
      d="M9.99147 18.801C8.29147 18.801 6.68314 18.3594 5.46647 17.5427C4.30814 16.7677 3.6748 15.7094 3.6748 14.5677C3.6748 13.426 4.31647 12.376 5.46647 11.6094C7.95814 9.94271 12.0081 9.94271 14.4998 11.6094C14.7831 11.801 14.8665 12.1927 14.6748 12.476C14.4831 12.7677 14.0915 12.8427 13.8081 12.651C11.7331 11.2677 8.23314 11.2677 6.15814 12.651C5.35814 13.1844 4.9248 13.8594 4.9248 14.5677C4.9248 15.276 5.35814 15.9677 6.15814 16.501C7.16647 17.176 8.5248 17.5427 9.98314 17.5427C10.3248 17.5427 10.6081 17.826 10.6081 18.1677C10.6081 18.5094 10.3331 18.801 9.99147 18.801Z"
      fill="#8B89A0"
    />
  </svg>
);

