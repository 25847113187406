import { FC } from 'react';

export const FacebookIconDropdown: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 3H14.5455C13.4605 3 12.4199 3.47411 11.6527 4.31802C10.8856 5.16193 10.4545 6.30653 10.4545 7.5V10.2H8V13.8H10.4545V21H13.7273V13.8H16.1818L17 10.2H13.7273V7.5C13.7273 7.26131 13.8135 7.03239 13.9669 6.8636C14.1204 6.69482 14.3285 6.6 14.5455 6.6H17V3Z"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
