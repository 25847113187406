import { FC } from 'react';

export const DownloadIconDropdown: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.94 14.4095V18.8071C2.94 19.3594 3.38772 19.8071 3.94 19.8071H20.2274C20.7797 19.8071 21.2274 19.3594 21.2274 18.8071V14.4095"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M7.6098 11.4236L11.3765 15.1903C11.7671 15.5808 12.4002 15.5808 12.7907 15.1903L16.5575 11.4236"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
    <path
      d="M12.084 4.19305V15.4141"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
  </svg>
);
