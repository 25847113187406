import { FC } from 'react';

export const LogoutIcon2: FC = () => (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7969 19.1368C17.4869 22.7368 15.6369 24.2068 11.5869 24.2068L11.4569 24.2068C6.98688 24.2068 5.19687 22.4168 5.19687 17.9468L5.19687 11.4268C5.19687 6.95679 6.98687 5.16679 11.4569 5.16679L11.5869 5.16679C15.6069 5.16679 17.4569 6.61679 17.7869 10.1568"
      stroke="#8B89A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6967 14.6968L23.0767 14.6968"
      stroke="#8B89A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.8468 18.0469L24.1968 14.6969L20.8468 11.3469"
      stroke="#8B89A0"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
