import { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CommonStrings } from '../../../../common/localization/en';
import { FSModal } from '../../../../common/components/modal/Modal';

const useStyles = makeStyles((theme) => ({
  confirmText: {
    fontSize: theme.spacing(3),
    fontWeight: 400,
    textAlign: 'center',
  },
}));

interface ApproveModalProps {
  handleCloseModal: () => void;
  isOpenModal: boolean;
  handleConfirm: (id: string) => void;
  id: string;
  name: string;
}

export const ApproveModal: FC<ApproveModalProps> = ({
  isOpenModal,
  id,
  name,
  handleCloseModal,
  handleConfirm,
}) => {
  const classes = useStyles();
  const confirm = () => {
    handleConfirm(id);
  };

  const getConfirmationDialogString = () => {
    return CommonStrings.ConfirmRedeemRequest.replace(/{name}/, name);
  };

  return (
    <FSModal onClose={handleCloseModal} modalIsOpen={isOpenModal}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={10}>
          <Typography className={classes.confirmText}>{getConfirmationDialogString()}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Button color="primary" onClick={handleCloseModal}>
                {CommonStrings.Cancel}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={confirm}>
                {CommonStrings.Approve}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
