export const CTAIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.12735 13.3905L6.64948 11.6485C6.10264 11.2641 5.36243 11.3097 4.86696 11.7584V11.7584C4.36258 12.2152 4.24988 12.9641 4.59748 13.5491L7.69592 18.7635C8.88825 20.7701 11.0497 22 13.3838 22V22C17.0378 22 20 19.0378 20 15.3838V8.92905C20 8.23212 19.435 7.66715 18.7381 7.66715V7.66715C18.0412 7.66715 17.4762 8.23212 17.4762 8.92905V10.8766V8.30213C17.4762 7.55261 16.8686 6.94499 16.119 6.94499V6.94499C15.3695 6.94499 14.7619 7.55261 14.7619 8.30213V10.8766V7.90133C14.7619 7.13197 14.1382 6.50828 13.3689 6.50828V6.50828C12.5995 6.50828 11.9758 7.13197 11.9758 7.90133V10.8766V3.42422C11.9758 2.63765 11.3382 2 10.5516 2V2C9.765 2 9.12735 2.63765 9.12735 3.42422V13.3905Z"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
