import * as React from 'react';
import { SVGProps, memo } from 'react';
import { theme } from '../../../theme';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={59} height={60} fill="none" {...props}>
    <mask
      id="mask0_2080_94334"
      width={59}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <ellipse cx={29.5} cy={30} fill="#D9D4FF" rx={29.5} ry={30} />
    </mask>
    <g mask="url(#mask0_2080_94334)">
      <path
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
        strokeWidth={1.6}
        d="M58.2 30c0 16.14-12.862 29.2-28.7 29.2S.8 46.14.8 30 13.662.8 29.5.8 58.2 13.86 58.2 30Z"
      />
      <ellipse
        cx={20.014}
        cy={20.354}
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.4}
        rx={20.014}
        ry={20.354}
        transform="matrix(1 -.00217 .0021 1 9.212 9.62)"
      />
      <path
        fill={theme.palette.primary.main}
        d="M36.5 24.25a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5ZM24.855 25.375l-7.432 10.737c-.704 1.016-1.056 1.524-1.036 1.947.017.368.197.71.49.934.335.257.954.257 2.19.257H37.75l-9.606-13.875c-.552-.798-.828-1.197-1.173-1.337a1.251 1.251 0 0 0-.942 0c-.345.14-.621.54-1.174 1.337Z"
      />
      <path
        stroke="#A69DF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M37.75 39.25h1.263c1.214 0 1.82 0 2.155-.253a1.25 1.25 0 0 0 .494-.922c.025-.42-.312-.924-.985-1.934l-3.763-5.645c-.556-.834-.835-1.252-1.185-1.397a1.25 1.25 0 0 0-.958 0c-.35.145-.629.563-1.185 1.397l-.93 1.396m5.094 7.358-9.606-13.875c-.552-.798-.828-1.197-1.173-1.337a1.251 1.251 0 0 0-.942 0c-.345.14-.621.54-1.174 1.337l-7.432 10.737c-.704 1.016-1.056 1.524-1.036 1.947.017.368.197.71.49.934.335.257.954.257 2.19.257H37.75Zm2.5-18.75a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
      />
    </g>
  </svg>
);
const VideoStyleExperientialIconActive = memo(SvgComponent);
export default VideoStyleExperientialIconActive;
