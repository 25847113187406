import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FiltersEndpoints, getApiUrlForId } from '../../api/endpoints';
import { VideoFilterApiModel } from '../../api/models/filters';
import { httpClient } from '../../services/httpClient/httpClient';
import { VideoFiltersDTO } from '../storeModels';

interface FiltersSlice {
  videoFilters: VideoFiltersDTO;
  isLoading: boolean;
  activeFilterId: string;
  isUpdating: boolean;
  updateSuccess: boolean;
  hasNewFilters: boolean;
}

const initialState: FiltersSlice = {
  videoFilters: [],
  isLoading: false,
  activeFilterId: '',
  isUpdating: false,
  updateSuccess: false,
  hasNewFilters: false,
};

interface GenerateFiltersOptions {
  logoUrl: string;
  primaryColor: string;
  secondaryColor: string;
}
interface GenerateFiltersRequest {
  venueId: string;
  options: GenerateFiltersOptions;
}

interface GenerateFiltersPayload {
  filterUrls: string[];
}
export const generateFilters = createAsyncThunk(
  'filters/generateFilters',
  async (options: GenerateFiltersRequest, { rejectWithValue }): Promise<any> => {
    try {
      const { filterUrls } = await httpClient.post<GenerateFiltersOptions, GenerateFiltersPayload>({
        url: FiltersEndpoints.GenerateFilters,
        requiresToken: true,
        payload: options.options,
      });
      return Promise.all(
        filterUrls.map(async (url) => {
          return await createNewFilter({ venueId: options.venueId, name: url, url });
        }),
      );
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface CreateNewFilterOption {
  venueId: string;
  name: string;
  url: string;
}

export const createNewFilter = async (
  options: CreateNewFilterOption,
): Promise<VideoFilterApiModel> => {
  try {
    return await httpClient.post<CreateNewFilterOption, VideoFilterApiModel>({
      url: FiltersEndpoints.CreateNewFilter,
      requiresToken: true,
      payload: options,
    });
  } catch (err) {
    return err;
  }
};

export const getVideoFilters = createAsyncThunk(
  'storyFilters/getStoryFilters',
  async (options: { venueId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, VideoFilterApiModel[]>({
        url: FiltersEndpoints.GetVideoFilters,
        requiresToken: true,
        params: options,
      });
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

type updateActiveFilterOptions = {
  current?: string;
  newActive?: string;
};
export const updateActiveFilter = createAsyncThunk(
  'storuFilters/updateActiveFilter',
  async (options: updateActiveFilterOptions, { rejectWithValue }) => {
    try {
      if (options.current) {
        await httpClient.post<{ id: string }, { success: boolean }>({
          url: getApiUrlForId(FiltersEndpoints.DeactivateFilter, options.current),
          requiresToken: true,
        });
      }
      if (options.newActive) {
        await httpClient.post<{ id: string }, { success: boolean }>({
          url: getApiUrlForId(FiltersEndpoints.ActivateFilter, options.newActive),
          requiresToken: true,
        });
      }
      return options.newActive || '';
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  },
);

const filtersSlice = createSlice({
  name: 'storyFilters',
  initialState,
  reducers: {
    resetSuccessStatus: (state) => {
      state.updateSuccess = false;
    },
    resetHasNewFilters: (state) => {
      state.hasNewFilters = false;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVideoFilters.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getVideoFilters.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.videoFilters = payload;
      let activeFilter = payload.find((i) => i.isActive);
      state.activeFilterId = activeFilter ? activeFilter.id : '';
    });
    reducersBuilder.addCase(updateActiveFilter.pending, (state) => {
      state.isUpdating = true;
    });
    reducersBuilder.addCase(updateActiveFilter.fulfilled, (state, { payload }) => {
      state.isUpdating = false;
      state.activeFilterId = payload;
      state.updateSuccess = true;
    });
    reducersBuilder.addCase(generateFilters.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(generateFilters.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.hasNewFilters = true;
    });
    reducersBuilder.addCase(generateFilters.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { resetSuccessStatus, resetHasNewFilters } = filtersSlice.actions;
export default filtersSlice.reducer;
