import { GetVideosRequestWithSearchFilterOptions } from './../../api/models/videos';
import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { TableDTO } from '../storeModels';
import { UserApiModel } from '../../api/models/users';
import { httpClient } from '../../services/httpClient/httpClient';
import { UsersEndpoints, getApiUrlForId } from '../../api/endpoints';
import { InitialValues } from '../../pages/webappSettings/TeamSettings/AddUsersButton/CreateUserModal/CreateUserModal.helper';
import {
  GetTableDataRequest,
  GetTableDataResponse,
  GetTableDataWithSearchRequest,
} from '../../api/models/common';
import { tableLoadFulFilled, tableLoadPending, tableLoadRejected, getTableSorting } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { GetVideosRequest } from '../../api/models/videos';
import { fillEmptyUserFields } from '../../services/utilities';

interface InitialState {
  shouldRefetch?: boolean;
}
const initialState: TableDTO<UserApiModel> & InitialState = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastVideosCount,
  lastUpdated: new Date().toISOString(),
  itemsSplit: {
    active: [],
    pending: [],
    deactivated: [],
  },
  activeUsersCount: 0,
  pendingUsersCount: 0,
  deactivatedUsersCount: 0,
  shouldRefetch: false,
};

export const fetchUsersBySearchFilterApiCall = async (
  options: GetVideosRequestWithSearchFilterOptions,
) => {
  const result = await httpClient.post<GetVideosRequest, GetTableDataResponse<UserApiModel>>({
    url: UsersEndpoints.getUsersBySearchFilter,
    requiresToken: true,
    payload: {
      attributes:
        'id' in options.filter ? [...options.filter.filter.attributes] : [...options.filter],
    },
    params: {
      accountId: options.venueId,
      page: options.pageable.page?.toString(),
      size: options.pageable.size?.toString(),
      sort: options.pageable.sort?.toString(),
      hasVideos: options.hasVideos,
      search: options.search,
      withTranscript: options.withTranscript,
    },
  });

  result.items = result.items.map((item) => ({
    ...item,
    displayName: item.displayName || `${item.firstName} ${item.lastName}`,
  }));

  return result;
};

export const getUsersBySearchFilter = createAsyncThunk(
  'users/getUsersBySearchFilter',
  async (options: GetVideosRequestWithSearchFilterOptions, { rejectWithValue }) => {
    try {
      return fetchUsersBySearchFilterApiCall(options);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getHeroesData = async (options: GetTableDataWithSearchRequest) => {
  try {
    const response = await httpClient.get<GetVideosRequest, GetTableDataResponse<UserApiModel>>({
      url: UsersEndpoints.GetUsersStats,
      requiresToken: true,
      params: {
        accountId: options.accountId,
        page: options.pageable.page?.toString(),
        size: options.pageable.size?.toString(),
        sort: options.pageable.sort?.toString(),
        search: options.search,
        status: options.status,
        hasVideos: options.hasVideos,
      },
    });
    return { response, hasInfiniteScroll: options.hasInfiniteScroll };
  } catch (error) {
    return Promise.reject(error.response.data.message);
  }
};
export const getHeroes = createAsyncThunk('heroes/getHeroes', getHeroesData);

export const getUsers = createAsyncThunk(
  'heroes/getUsers',
  async (options: GetTableDataRequest, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetTableDataRequest, GetTableDataResponse<UserApiModel>>({
        url: UsersEndpoints.GetUsers,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface CreateUser {
  accountId: string;
  user: InitialValues;
  sendEmailInvitation?: boolean;
}
export const sendInvites = async (options: { venueId: string }) => {
  try {
    return await httpClient.post<{ venueId: string }, { success: boolean }>({
      url: UsersEndpoints.PostAllUsers,
      requiresToken: true,
      payload: options,
    });
  } catch (err) {
    return { success: false };
  }
};
export const sendOneUserInvite = async (options: { id: string }) => {
  try {
    return await httpClient.post<{}, UserApiModel>({
      url: getApiUrlForId(UsersEndpoints.InviteOneUser, options.id),
      requiresToken: true,
    });
  } catch (err) {
    return { id: 0 };
  }
};

export const createNewUser = async (options: CreateUser) => {
  try {
    return await httpClient.post<CreateUser, UserApiModel>({
      url: UsersEndpoints.PostUser,
      requiresToken: true,
      payload: options,
    });
  } catch (err) {
    throw new Error();
  }
};

export const createNewUsersCSVFile = async (file: File, payload: string) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('payload', payload);
  try {
    return await httpClient.post<FormData, UserApiModel>({
      url: UsersEndpoints.PostUsersCSVFile,
      requiresToken: true,
      payload: formData,
    });
  } catch (err) {
    throw err;
  }
};
export const updateUserById = async (options: { update: Partial<UserApiModel>; id: string }) => {
  try {
    return await httpClient.put<Partial<UserApiModel>, UserApiModel>({
      url: getApiUrlForId(UsersEndpoints.updateUserById, options.id),
      requiresToken: true,
      payload: options.update,
    });
  } catch (err) {
    throw new Error();
  }
};
export interface UsersIds {
  ids: string[];
}
export const AssignBonusPoints = async (payload: UsersIds) => {
  try {
    return await httpClient.post<UsersIds, UserApiModel>({
      url: UsersEndpoints.PostBatchUsersPoints,
      requiresToken: true,
      payload: payload,
    });
  } catch (err) {
    throw err;
  }
};

interface CreateUserData {
  venueId: string;
  firstName: string;
  lastName: string;
  email: string | undefined;
  phoneNumber: string;
  accountId?: string;
}

export const createUser = async (options: CreateUserData) => {
  try {
    return await httpClient.post<CreateUserData, UserApiModel>({
      url: UsersEndpoints.CreateUser,
      requiresToken: true,
      payload: options,
    });
  } catch (err) {
    throw new Error(err.response.data.message);
  }
};

interface CountData {
  active: number;
  deactivated: number;
  pending: number;
}

export const getUserCounts = createAsyncThunk(
  'heroes/getCounts',
  async ({ accountId }: { accountId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ accountId: string }, CountData>({
        url: UsersEndpoints.GetUserCounts,
        requiresToken: true,
        params: {
          accountId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const heroesSlice = createSlice({
  name: 'heroes',
  initialState,
  reducers: {
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setUserDataSorting(state, action: PayloadAction<string>) {
      state.sort = getTableSorting(current(state), action.payload);
    },
    updateSortOnInit(state) {
      state.sort = initialState.sort;
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = initialState.page;
    },
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },
    updateTable(state) {
      state.lastUpdated = new Date().toISOString();
    },
    setRefetch(state, action: PayloadAction<boolean>) {
      state.shouldRefetch = action.payload;
    },
    resetPage(state) {
      state.page = 0;
    },
    reset: (state) => {
      state.page = 0;
      state.search = '';
      state.sort = defaultPagination.sortByLastVideosCount;
      state.items = [];
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getUsersBySearchFilter.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getUsersBySearchFilter.fulfilled, (state, { payload }) => {
      const { items } = payload;

      return tableLoadFulFilled(state, {
        ...payload,
        items: items.map((hero) => fillEmptyUserFields(hero)),
      });
    });

    reducersBuilder.addCase(getHeroes.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getHeroes.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getHeroes.fulfilled, (state, { payload, meta }) => {
      let items: UserApiModel[] = payload.response.items;

      if (payload.hasInfiniteScroll && state.page !== 0) {
        items = [...state.items, ...payload.response.items];
      }

      payload.response.items = items.map((hero) => fillEmptyUserFields(hero));
      return tableLoadFulFilled(state, payload.response);
    });
    reducersBuilder.addCase(getUsers.rejected, (state) => {
      return tableLoadRejected(state);
    });
    reducersBuilder.addCase(getUsers.pending, (state) => {
      return tableLoadPending(state);
    });
    reducersBuilder.addCase(getUsers.fulfilled, (state, { payload }) => {
      return tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(getUserCounts.rejected, (state) => {
      state.error = true;
      state.isLoading = false;
    });
    reducersBuilder.addCase(getUserCounts.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getUserCounts.fulfilled, (state, { payload }) => {
      state.isLoading = true;
      const { active, deactivated, pending } = payload;
      state.activeUsersCount = active;
      state.pendingUsersCount = pending;
      state.deactivatedUsersCount = deactivated;
    });
  },
});

export const {
  goToSelectedPage,
  setUserDataSorting,
  updateTable,
  updateSearch,
  updateSize,
  updateSortOnInit,
  setRefetch,
  reset,
  resetPage,
} = heroesSlice.actions;
export default heroesSlice.reducer;
