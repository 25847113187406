import { Box, Button, Grid, Typography } from '@mui/material';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { importHistoryDetailsTableContent } from '../../../../services/helpers/tableMappers';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  resetSelectedRecord,
  goToSelectedDetailsPage,
  updateDetailsSize,
} from '../../../../store/slices/dataSyncSlice';
import { WebsiteRoutes } from '../../../constants/routes';
import { CommonTable } from '../../table/CommonTable';
import { useStyles } from './ImportDetailsTable.helper';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { DataSyncEventNames } from '../../../constants/events/dataSyncEvents';

interface ImportDetailsTableProps {
  onClose?: () => void;
}

const ImportDetailsTable: FC<ImportDetailsTableProps> = ({ onClose }) => {
  const history = useHistory();
  const styles = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();
  const { items } = useTypedSelector((state) => state.dataSyncSlice.data);
  const {
    items: detailsItems,
    isLoading,
    error,
    page,
    size,
    totalItems,
    totalPages,
    pagination,
  } = useTypedSelector((state) => state.dataSyncSlice.details);
  const handleClose = () => {
    trackEvent(DataSyncEventNames.importDetailsClose, {
      transactionId: history.location.search.split('id=')[1],
    });
    onClose ? onClose() : history.replace(WebsiteRoutes.DataSync);
  };

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= Math.ceil(detailsItems?.length / size)) {
      return;
    }

    dispatch(goToSelectedDetailsPage(convertedTargetPage));
  };

  const changeSize = (size: number) => {
    dispatch(updateDetailsSize(size));
  };

  const content = importHistoryDetailsTableContent({
    items: pagination.items || [],
  });

  useEffect(() => {
    return () => {
      dispatch(resetSelectedRecord());
    };
  }, [dispatch]);

  return (
    <Box className={styles.root}>
      <Typography className={styles.detailModalTitle}>
        {items.find((item) => item.id === history.location.search.split('id=')[1])?.name}
      </Typography>
      <CommonTable
        content={content}
        page={convertApiPageToFrontEndPage(page)}
        totalItems={totalItems}
        totalPages={totalPages}
        isLoading={isLoading}
        noContent={error}
        tablePadding="0"
        goToPage={onGoToPage}
        size={size}
        onSizeChange={changeSize}
        disableFullHeight
      />
      <Grid xs={12} className={styles.btn} container justifyContent={'center'}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ borderRadius: '25px' }}
          onClick={handleClose}
        >
          Close
        </Button>
      </Grid>
    </Box>
  );
};

export default ImportDetailsTable;
