import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useUserStatusMarkerStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    display: 'flex',
    padding: '5px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '0px solid black',
    borderRadius: 30,
    marginRight: 30,
    width: '90px',
  },
  statusText: {
    textTransform: 'capitalize',
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '0.02em',
  },
  ACTIVE: {
    color: '#24B947',
    borderColor: 'rgba(36, 185, 71, 0.1)',
    backgroundColor: 'rgba(36, 185, 71, 0.1)',
  },
  ARCHIVED: {
    color: '#8B89A0',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  INACTIVE: {
    color: '#8B89A0',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));
