import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  image: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '133.4%',
    color: theme.palette.text.primary,
  },
  label: {
    fontSize: '20px',
    lineHeight: '160%',
    color: theme.palette.text.primary,
  },
  borders: {
    position: 'relative',
    border: '1px solid #EEEEF6;',
    borderRadius: '12px',
    boxSizing: 'border-box',
  },
  bottomNav: {
    height: '100px',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  innerNav: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
  },
  button: {
    width: '120px',
    margin: theme.spacing(3),
  },
  firstSearchWrapper: {
    '& > div': {
      zIndex: 5,
    },
  },
}));
