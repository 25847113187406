import { Box, Button, Modal, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './ExistingNumberModal.styles';
import { useClientType } from '../../../../services/hooks/useClientType';
import { InviteCreatorModalStrings } from '../../../../common/localization/en';
import { WarningAmberRounded } from '@mui/icons-material';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ExistingNumberModal: FC<Props> = ({ isOpen, onClose }) => {
  const styles = useStyles();

  const { isHealthCare } = useClientType();

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        background: 'rgba(26, 21, 56, 0.5)',
        backdropFilter: 'blur(2.5px)',
        width: '100%',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <Box className={styles.container}>
        <WarningAmberRounded color="warning" fontSize="large" sx={{ marginBottom: '10px' }} />
        <Typography fontWeight={600}>
          {isHealthCare
            ? InviteCreatorModalStrings.ExistingNumberInfoTrublu
            : InviteCreatorModalStrings.ExistingNumberInfo}
        </Typography>
        <Typography>{InviteCreatorModalStrings.UpdateNumber}</Typography>
        <Button variant="contained" className={styles.button} onClick={onClose}>
          {InviteCreatorModalStrings.Okay}
        </Button>
      </Box>
    </Modal>
  );
};

export default ExistingNumberModal;
