import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { isGridLayout?: boolean }>((theme) => ({
  container: ({ isGridLayout }) => ({
    width: '189px',
    height: '285px',
    borderRadius: '16px',
    border: '1px solid #EAECF0',
    overflow: 'hidden',
    position: 'relative',
  }),
  menu: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    width: '24px',
    height: '24px',
    borderRadius: '6px',
    background: '#FFFFFF4D',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '10',
    cursor: 'pointer',
    '& path': {
      stroke: 'white',
    },
  },
  listWrapper: {
    position: 'absolute',
    top: '40px',
    right: '12px',
    zIndex: '1',
    transformOrigin: 'top right',
    animation: '200ms cubic-bezier(0.4, 0, 0.2, 1) $show',
  },
  '@keyframes show': {
    from: {
      opacity: 0,
      transform: 'scale(0)',
    },
    to: {
      opacity: 1,
      transform: 'scale(1)',
    },
  },
  list: {
    width: '174px',
    background: 'white',
    borderRadius: '8px',
    border: '1px solid #EAECF0',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    '& li p': {
      width: '174px',
      color: '#101828',
      fontSize: '14px',
      fontWeight: '400',
      lineHeihgt: '24px',
    },
  },

  actionArea: ({ isGridLayout }) => ({
    overflow: 'hidden',
    height: isGridLayout ? '205px' : '220px',
  }),
  video: {
    aspectRatio: '9/16',
    width: '100%',
    borderRadius: '16px',
    height: 'auto',
    objectFit: 'contain',
  },
  playIcon: {
    position: 'absolute',
    color: 'white',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    pointerEvents: 'none',
    background: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '100%',
    backdropFilter: 'blur(30px)',
    height: '48px',
    width: '48px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsWrapper: ({ isGridLayout }) => ({
    height: isGridLayout ? '80px' : '65px',
    background: '#fff',
    padding: '0px 10px 0px 10px',
    borderBottomRightRadius: '16px',
    borderBottomLeftRadius: '16px',
  }),
  authourText: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '28px',
    color: '#101828',
    textTransform: 'uppercase',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rewardIcon: {},
  campaignName: {
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    padding: '2px 10px',
    borderRadius: '16px',
  },
  dateWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  timeIcon: {
    marginRight: '5px',
  },
  timeText: {
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '10px',
    color: '#8B89A0',
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '7px',
  },
  iconButton: {
    padding: 0,
    marginRight: '5px',
    '&:hover': {
      backgroundColor: 'unset',
    },
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  svTooltip: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '38px',
    height: '24px',
    borderRadius: '16px',
    background: '#ECFDF3',
    justifyContent: 'space-between',
    padding: '0 4px',
    marginLeft: '13px',
    '& div': {
      height: '20px',
    },
  },
  svCount: {
    color: '#027A48',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: '19px',
    marginLeft: '4px',
  },
  svName: {
    padding: '2px 10px',
    borderRadius: '16px',
    background: '#ECEAFF',
    marginBottom: '4px',
    color: theme.palette.primary.main,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },
}));

export const videoStatusBgColor: Record<string, string> = {
  PUBLISHED: '#ECFDF3',
  UNPUBLISHED: '#FCF6EA',
  MODERATED: '#FEF3F2',
  PENDING_MODERATION: '#EFF8FF',
};
