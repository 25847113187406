import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAccountsSidebarStyles } from './AccountsSidebar.styles';

import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useAccountsSidebar } from './useAccountsSidebar';
import { AccountSidebarButton } from './components/AccountSidebarButton/AccountSidebarButton';
import { DistributorsSelector } from './components/DistributorsSelector/DistributorsSelector';
import { SidebarBrandLogo } from './components/SidebarBrandLogo/SidebarBrandLogo';
import { getVenueListApiCall } from '../../../store/slices/organizationVenueSlice';
import { SidebarMenuStrings } from '../../localization/en';
import { SvMenuIcon } from '../../assets/newDesign/SvMenuIcon';
import { SvHealthMenuIcon } from '../../assets/newDesign/SvHealthMenuIcon';
import { Brand360MenuIcon } from '../../assets/newDesign/Brand360MenuIcon';
import { TestMenuIcon } from '../../assets/newDesign/TestMenuIcon';

export const AccountsSidebar: React.FC = () => {
  const {
    handleAllAccountsClick,
    handleArchivedAccountClick,
    activeTab,
    activeDistributor,
    openSidebar,
    closeSidebar,
    isSidebarOpen,
    distributors,
    handleDistributorClick,
  } = useAccountsSidebar();

  const [hasArchiveAccounts, setHasArchiveAccounts] = useState(false);

  useEffect(() => {
    getVenueListApiCall({ deactivated: true, params: { size: 1 } }).then((data) => {
      setHasArchiveAccounts(data.totalItems > 0);
    });
  }, []);

  const classes = useAccountsSidebarStyles({ isSidebarOpen });
  const sv = distributors.find((d) => d.code === 'SV001');
  const brand360 = distributors.find((d) => d.code === 'B360');
  const svHealth = distributors.find((d) => d.code === 'SVH01');
  const test = distributors.find((d) => d.code === '_TESTSV');

  const distributorsMap = [
    {
      distributor: sv,
      menuTitle: SidebarMenuStrings.SocialV,
      icon: <SvMenuIcon />,
    },
    {
      distributor: svHealth,
      menuTitle: SidebarMenuStrings.SocialVHealth,
      icon: <SvHealthMenuIcon />,
    },
    {
      distributor: brand360,
      menuTitle: SidebarMenuStrings.Brand360,
      icon: <Brand360MenuIcon />,
    },
    {
      distributor: test,
      menuTitle: SidebarMenuStrings.Test,
      icon: <TestMenuIcon />,
    },
  ].filter((item) => item.distributor);

  return (
    <Box className={classes.root}>
      <Box
        className={classes.container}
        onMouseEnter={openSidebar}
        onMouseMove={() => {
          if (!isSidebarOpen) {
            openSidebar();
          }
        }}
        onMouseLeave={closeSidebar}
      >
        <Box className={classes.buttonsContainer}>
          <AccountSidebarButton isActive={activeTab === 'all'} onClick={handleAllAccountsClick}>
            <AppsOutlinedIcon />
            {SidebarMenuStrings.AllAccounts}
          </AccountSidebarButton>
          {distributorsMap.map((item) => {
            const isActive = activeDistributor?.id === item?.distributor?.id;
            return (
              <AccountSidebarButton
                isActive={isActive}
                onClick={() => {
                  handleDistributorClick(item.distributor);
                }}
              >
                <Box
                  className={classes.iconWrapper}
                  sx={{
                    '& svg path': {
                      fill: isActive ? '#fff' : '#667085',
                    },
                    '& svg path:nth-child(2)': {
                      strokeWidth: 0.1,
                      stroke:
                        item.menuTitle === SidebarMenuStrings.Brand360
                          ? isActive
                            ? '#fff'
                            : '#667085'
                          : 'none',
                    },
                    '& svg path:last-child': {
                      stroke:
                        item.menuTitle === SidebarMenuStrings.Brand360
                          ? isActive
                            ? '#fff'
                            : '#667085'
                          : 'none',
                    },
                  }}
                >
                  {item.icon}
                </Box>
                {item.menuTitle}
              </AccountSidebarButton>
            );
          })}
          {hasArchiveAccounts && (
            <AccountSidebarButton
              isActive={activeTab === 'archived'}
              onClick={handleArchivedAccountClick}
            >
              <Inventory2OutlinedIcon />
              {SidebarMenuStrings.Archived}
            </AccountSidebarButton>
          )}
          {distributors.length > 0 && (
            <DistributorsSelector
              mainDistributorIds={distributorsMap.map((item) => item?.distributor?.id || '')}
            />
          )}
        </Box>

        <Box>
          <SidebarBrandLogo />
        </Box>
      </Box>
    </Box>
  );
};
