import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  expandIconWrapper: {
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '-10px',
    left: '-30px',
  },
  text: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.primary.main,
    marginTop: '2px',
  },
  menuItem: {
    display: 'flex',
    backgroundColor: '#fff !important',
    position: 'relative',
    padding: '0px !important',
  },
  divider: {
    height: '40px',
    borderRight: '1px solid #D0D5DD',
    width: '1px',
    position: 'absolute',
    left: '50%',
    zIndex: 20,
  },
  menuButton: {
    fontSize: '11px',
    color: '#000',
    fontWeight: 400,
    borderRadius: 0,
  },

  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '180px',
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#fff',
      height: '20px !important',
      maxHeight: '20px !important',
      borderRadius: '12px',
      margin: '5px 0',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#EAECF0',
      borderRadius: '5px',
      height: '40px !important',
      cursor: 'pointer',
    },
  },
}));
