import { Box, Typography } from '@mui/material';
import React, { FC, cloneElement, CSSProperties } from 'react';
import { useStyles } from './RewardTypeButton.helper';
import clsx from 'clsx';

interface RewardTypeButtonProps {
  icon: JSX.Element;
  title: string;
  isActive?: boolean;
  onClick: () => void;
  className?: string;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
  titleStyle?: CSSProperties;
}

const RewardTypeButton: FC<RewardTypeButtonProps> = ({
  className,
  icon,
  title,
  isActive,
  style,
  iconStyle,
  onClick,
  titleStyle,
}) => {
  const classes = useStyles();
  return (
    <Box
      onClick={onClick}
      className={clsx(classes.root, className, { [classes.active]: isActive })}
      style={{ ...style }}
      id={`${title.split(' ').join('-')}-button`}
    >
      <Box className={classes.icon} style={{ ...iconStyle }}>
        {cloneElement(icon, { isActive: className && isActive })}
      </Box>
      <Typography className={classes.title} style={{ ...titleStyle }}>
        {title}
      </Typography>
    </Box>
  );
};

export default RewardTypeButton;
