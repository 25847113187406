import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '280px',
    height: '134px',

    borderRadius: '0px 20px 20px 0px',
    position: 'fixed',
    zIndex: 100000,
    top: '490px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px 0 12px',
    transition: 'all 0.35s ease-in-out',
  },
  title: {
    color: '#fff',
    fontWeight: 600,
    lineHeight: '20px',
    fontSize: '14px',
    position: 'absolute',
    top: '10px',
  },
  subtitle: {
    color: '#fff',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
  },
  remainingTopics: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700,
    paddingTop: '3px',
  },
  actionButton: {
    fontWeight: 600,
    fontSize: '14px',

    marginLeft: '-8px',
    marginTop: '15px',
    height: '10px',
    '&:active': {
      transform: 'scale(0.99)',
    },
  },
  congradsIcon: {
    width: '50px',
    height: '50px',
  },
}));
