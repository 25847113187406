import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '210px',
  },
  listContainer: {
    width: '100%',
  },
  activeListItem: {
    background: `${theme.palette.primary.main}2b`,

    '& .MuiTypography-root': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
  listItem: {
    padding: '7px 15px',
    marginBottom: '8px',
    borderRadius: '8px',
    cursor: 'pointer',

    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '12px',
      color: '#000',
      width: 'max-content',
    },
  },
  divider: {
    height: '100%',
    marginLeft: 'auto',
  },
  buttonsCalendarBlock: {
    height: '100%',
    justifyContent: 'space-between',
  },
  buttonsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    padding: '10px 20px',
  },
  button: {
    minWidth: 64,
    padding: '9px 30px 10px',
    borderRadius: 100,
    color: theme.palette.common.white,
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
    boxShadow: 'none',
  },
}));
