import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

/* interface Props {
  size?: 'sm' | 'md';
} */

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'grid',
    margin: '0 auto',
    gridTemplate: '85px 85px 42.5px / repeat(4, 85px)',
    gap: '3px',
    height: '100%',
    width: 'fit-content',
    overflowY: 'hidden',
  },
  storiesWrapper: {
    maxHeight: '206px',
    maxWidth: '440px',
    margin: '0 auto',
  },
  story: {
    boxSizing: 'content-box',
    position: 'relative',
    width: '85px',
    height: '85px',
    maxHeight: '85px',
    maxWidth: '85px',
    overflow: 'hidden',
    /* background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 40%)',
    '& img': {
      width: '85px',
      height: '85px',
    }, */
  },
  mockStory: {
    /*   background: '#D9D9D9', */
  },
  mockBg: {
    background: '#D9D9D9',
    width: '85px',
    height: '85px',
  },
  info: {
    position: 'absolute',
    top: '0',
    height: '38px',
    width: '100%',
    zIndex: '10',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0) 100%)',
  },
  author: {
    fontFamily: 'HelveticaNeue',
    fontSize: '8px',
    fontWeight: '400',
    lineHeight: '7px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: theme.palette.common.white,
    margin: '10px 8px',
  },
  duration: {
    fontFamily: 'Poppins',
    fontSize: '7px',
    fontWeight: '400',
    lineHeight: '7px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: theme.palette.common.white,
    marginLeft: '8px',
  },
}));
