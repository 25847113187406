import {
  CreateIncentiveCampaignApiModel,
  CustomerActivitiesType,
  GetCampaignsAPIModel,
} from '../../api/models/campaigns';
import { replaceMessageBodyWithApiVariables } from '../../common/components/SMS/SMS.helper';
import { useSetDefaultSMSTemplates } from '../NewCampaign/CustomizeSMS/DefaultSMSTemplates/useSetDefaultSMSTemplates';
import { RewardFulfillmentMethod } from '../../api/models/rewardFulfillment';
import { RewardStrings } from '../../common/localization/en';
import {
  CTA_DEFAULT_TEXT,
  // CTA_DEFAULT_URL,
  DEFAULT_POINTS_GOAL,
} from '../../common/constants/constants';
import { VideoStyleTypes } from '../NewCampaign/VideoStyleTypesSwitch/VideoStyleTypesSwitch';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getCampaigns,
  postIncentiveCampaign,
  setIsDefaultCampaignCreating,
} from '../../store/slices/campaignsSlice';
import { GetTableDataResponse } from '../../api/models/common';
import { isUWMClient } from '../../utils/isUWMClient';
import { defaultVideos } from '../NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';

export const useCreateDefaultCampaign = () => {
  const {
    defaultInviteMessage,
    defaultRewardMessage,
    defaultFollowupMessages,
  } = useSetDefaultSMSTemplates(true);
  const dispatch = useAppDispatch();

  const { page, size, sort, search = '', isDefaultCampaignCreating } = useTypedSelector(
    (state) => state.campaigns,
  );

  const getDefaultCampaignValues = (
    venueId: string,
    websiteUrl?: string,
  ): CreateIncentiveCampaignApiModel => {
    return {
      accountId: venueId,
      name: RewardStrings.DefaultAwarenessCampaignName,
      imageUrl: '',
      points: DEFAULT_POINTS_GOAL,
      isLocked: false,
      rewardTitle: '',
      rewardDescription: RewardStrings.IsNullRewardDefaultDescription,
      campaignObjective: CustomerActivitiesType.VideoUpload,
      couponEnabled: true,
      isNullReward: true,
      isPreferred: true,
      fulfillmentConfig: {
        method: RewardFulfillmentMethod.AUTOMATIC_SMS,
        sms: {
          mediaUrl: '',
          body: replaceMessageBodyWithApiVariables(defaultRewardMessage, true),
        },
      },
      invitationMsgConfig: {
        mediaUrl: '',
        body: replaceMessageBodyWithApiVariables(defaultInviteMessage, true),
      },
      recommendedVideoStyle: VideoStyleTypes.Inspirational,
      ctaButtonLinkUrl: websiteUrl || '',
      ctaButtonText: CTA_DEFAULT_TEXT,
      showWelcomeVideo: true,
      welcomeVideoUrl: isUWMClient() ? defaultVideos.defaultUwm : defaultVideos.default,
      followUpMessages: defaultFollowupMessages,
    };
  };

  const createDefaultCampaign = async (venueId: string, websiteUrl?: string) => {
    if (isDefaultCampaignCreating) return;

    dispatch(setIsDefaultCampaignCreating(true));

    return dispatch(getCampaigns({ search, accountId: venueId, pageable: { page, size, sort } }))
      .then((data) => {
        if (data.meta.requestStatus === 'rejected') return;

        const campaignsTotal = (data.payload as GetTableDataResponse<GetCampaignsAPIModel>)
          .totalItems;

        if (campaignsTotal > 0) return;

        return dispatch(postIncentiveCampaign(getDefaultCampaignValues(venueId, websiteUrl)));
      })
      .finally(() => {
        dispatch(setIsDefaultCampaignCreating(false));
      });
  };

  const createFirstCampaign = async (venueId: string, websiteUrl?: string) => {
    return dispatch(postIncentiveCampaign(getDefaultCampaignValues(venueId, websiteUrl)));
  };

  return { getDefaultCampaignValues, createDefaultCampaign, createFirstCampaign };
};
