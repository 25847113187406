import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import { ColoredTextValues, getColoredText } from '../../../utils/get-colored-text';

enum CodeColors {
  tagColor = '#FA9C45',
  optionColor = '#36B0E4',
  valueColor = '#7D7D7D',
}

export function getHeaderColoredText() {
  const headerColoredTextList1: ColoredTextValues[] = [
    {
      text: '<script ',
      color: CodeColors.tagColor,
    },
    {
      text: 'type',
      color: CodeColors.optionColor,
    },
    {
      text: "='module' ",
      color: CodeColors.valueColor,
    },
    {
      text: 'src',
      color: CodeColors.optionColor,
    },
    {
      text: "='https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.esm.js'",
      color: CodeColors.valueColor,
    },
    {
      text: '></script>',
      color: CodeColors.tagColor,
    },
  ];

  const headerColoredTextList2: ColoredTextValues[] = [
    {
      text: '<script ',
      color: CodeColors.tagColor,
    },
    {
      text: 'nomodule src',
      color: CodeColors.optionColor,
    },
    {
      text: "='https://storage.googleapis.com/gidget-static/v.latest/gidget/gidget.js'",
      color: CodeColors.valueColor,
    },
    {
      text: '></script>',
      color: CodeColors.tagColor,
    },
  ];

  return (
    <>
      {getColoredText(headerColoredTextList1)}
      <br />
      {getColoredText(headerColoredTextList2)}
    </>
  );
}

export function getBodyColoredText(socialverse: SocialVerseAllApiModel) {
  const bodyColoredTextList1: ColoredTextValues[] = [
    {
      text: '<gidget-component ',
      color: CodeColors.tagColor,
    },
  ];

  const bodyColoredTextList2: ColoredTextValues[] = [
    {
      text: 'widget-type',
      color: CodeColors.optionColor,
    },
    {
      text: `='${socialverse.viewMode.replace('blocks', 'block')}'`,
      color: CodeColors.valueColor,
    },
  ];

  const bodyColoredTextList3: ColoredTextValues[] = [
    {
      text: ' venue-id',
      color: CodeColors.optionColor,
    },
    {
      text: `='${socialverse.accountId}' `,
      color: CodeColors.valueColor,
    },
  ];

  const bodyColoredTextList4: ColoredTextValues[] = [
    {
      text: 'heading',
      color: CodeColors.optionColor,
    },
    {
      text: `='${socialverse.showTitle ? socialverse.title : ''}' `,
      color: CodeColors.valueColor,
    },
  ];
  const bodyColoredTextList5: ColoredTextValues[] = [
    {
      text: 'socialverse-id',
      color: CodeColors.optionColor,
    },
    {
      text: `='${socialverse.id}' `,
      color: CodeColors.valueColor,
    },
  ];
  const bodyColoredTextList6: ColoredTextValues[] = [
    {
      text: 'show-block-hero-name',
      color: CodeColors.optionColor,
    },
    {
      text: `='${socialverse.showUserInfo}'`,
      color: CodeColors.valueColor,
    },
    {
      text: ` />`,
      color: CodeColors.tagColor,
    },
  ];

  return (
    <>
      {getColoredText(bodyColoredTextList1)}
      <br />
      {getColoredText(bodyColoredTextList2)}
      <br />
      {getColoredText(bodyColoredTextList3)}
      <br />
      {getColoredText(bodyColoredTextList4)}
      <br />
      {getColoredText(bodyColoredTextList5)}
      <br />
      {getColoredText(bodyColoredTextList6)}
    </>
  );
}

export function getHtmlColoredText(socialverse: SocialVerseAllApiModel) {
  const htmlColoredTextList1: ColoredTextValues[] = [
    {
      text: '<',
      color: CodeColors.tagColor,
    },
    {
      text: '!DOCTYPE ',
      color: CodeColors.valueColor,
    },
    {
      text: 'html>',
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList2: ColoredTextValues[] = [
    {
      text: '<',
      color: CodeColors.tagColor,
    },
    {
      text: 'htmllang',
      color: CodeColors.optionColor,
    },
    {
      text: '="en"',
      color: CodeColors.valueColor,
    },
    {
      text: '>',
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList3: ColoredTextValues[] = [
    {
      text: `<head>`,
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList4: ColoredTextValues[] = [
    {
      text: `</head>`,
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList5: ColoredTextValues[] = [
    {
      text: `<body>`,
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList6: ColoredTextValues[] = [
    {
      text: `</body>`,
      color: CodeColors.tagColor,
    },
  ];

  const htmlColoredTextList7: ColoredTextValues[] = [
    {
      text: `</html>`,
      color: CodeColors.tagColor,
    },
  ];

  return (
    <>
      {getColoredText(htmlColoredTextList1)}
      <br />
      {getColoredText(htmlColoredTextList2)}
      <br />
      {getColoredText(htmlColoredTextList3)}
      <div style={{ marginLeft: '20px' }}>{getHeaderColoredText()}</div>
      {getColoredText(htmlColoredTextList4)}
      <br />
      {getColoredText(htmlColoredTextList5)}
      <br />
      <div style={{ marginLeft: '20px' }}>{getBodyColoredText(socialverse)}</div>
      {getColoredText(htmlColoredTextList6)}
      <br />
      {getColoredText(htmlColoredTextList7)}
      <br />
    </>
  );
}
