import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  stepper: {
    '& .MuiStepIcon-root': {
      color: '#EEECFC',
      fontSize: '30px',
    },
    '& .MuiStepIcon-text': {
      fill: '#1A1538',
    },
    '& .MuiStepIcon-root.Mui-active': {
      color: theme.palette.primary.main,
    },
    '& .MuiStepIcon-root.Mui-active > .MuiStepIcon-text': {
      fill: '#D9D9D9',
    },

    '& .MuiStepContent-root, .MuiStepConnector-root': {
      marginLeft: '13px',
      borderColor: theme.palette.primary.main,
      borderLeftWidth: '4px',
    },
    '& .MuiStepConnector-lineVertical': {
      borderColor: '#DCDBE4',
      borderLeftWidth: '4px',
    },
    '& .Mui-completed + div > span': {
      borderColor: '#67CE67',
    },
    '& .Mui-completed .MuiStepIcon-root': {
      color: '#67CE67',
    },
    '& .MuiStep-root:has(.MuiCollapse-entered) + div > span': {
      borderColor: theme.palette.primary.main,
    },
  },
  step: {
    position: 'relative',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  stepLabel: {
    position: 'relative',
    width: '100%',

    '& .MuiStepLabel-label': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '30px',
    },
  },
  labelText: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#1A1538',
  },
  expandIcon: {
    cursor: 'pointer',
    transform: 'rotate(270deg)',
  },

  stepDivider: {
    borderBottom: '1px solid #DCDBE4 !important',
    position: 'absolute',
    width: 'calc(100% - 25px)',
    left: '40px',
    bottom: '-15px',
  },
  tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    margin: '0 0 7px 12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));
