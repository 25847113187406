import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Color, createColor } from 'material-ui-color';
import { SocialVerseStrings } from '../../localization/en';
import { ColorPalette } from './components/ColorPalette';
import { ColorInput } from './components/ColorInput';
import { useTypedSelector } from '../../../store';
import { CustomColorTooltip } from '../LearnMoreTooltip/LearnMoreTooltip';

interface ICustomColorPickerProps {
  color: string;
  onColorChange: (color: string) => void;
  palette?: string[][];
  title?: string;
  warningMessage?: string;
  colorFormat?: string;
  displayCustomInput?: boolean;
  errorText?: string;
  setFieldError?: (field: string, value: string | undefined) => void;
  setColorError: (value: boolean) => void;
  tooltipText?: string;
  disabled?: boolean;
}

export type ColorFormatType = 'hex' | 'rgb';

export const defaultColorPalette = [
  ['#D1D1D1', '#737373', '#333333', '#000000'],
  ['#72C0F9', '#45A1F8', '#3075B5', '#1D4C7C'],
  ['#97F9EA', '#6BE3CF', '#4CA88F', '#2D6A65'],
  ['#A5F66A', '#81D553', '#54AD32', '#306F1D'],
  ['#FDF071', '#F9DA58', '#F3B03E', '#E3782E'],
  ['#F19B91', '#ED6D57', '#DB3B26', '#A62A17'],
  ['#F19BC8', '#EC549F', '#C23275', '#8A2152'],
];

export const CustomColorPickerWithInput: FC<ICustomColorPickerProps> = ({
  color,
  onColorChange,
  palette = defaultColorPalette,
  title = SocialVerseStrings.ColorPalette,
  warningMessage,
  colorFormat = 'hex',
  displayCustomInput,
  errorText,
  setFieldError,
  setColorError,
  tooltipText,
  disabled,
}) => {
  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const initialDisplayingPalette = defaultColorPalette
    .flat()
    .find((color) => color === venueBrandColor);
  const [activeColor, setActiveColor] = useState<Color>(createColor(color));
  const [displayPalette, setDisplayPalette] = useState(!!initialDisplayingPalette);
  const styles = useCustomColorPickerStyles();

  const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let color = event.target.value;
    if (colorFormat === 'hex' && !color.startsWith('#')) {
      color = `#${color}`;
    }
    onColorChange(color);
  };

  useEffect(() => {
    setActiveColor(createColor(color));
  }, [color]);

  return (
    <Box
      sx={
        disabled
          ? {
              transition: 'all 0.2s ease',
              opacity: '0.5',
              pointerEvents: 'none',
            }
          : undefined
      }
    >
      <Box border="1px solid #E1E5EE" borderRadius="10px" p="16px">
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
          {displayPalette ? (
            <Typography fontSize="12px" marginBottom="8px">
              {title}
            </Typography>
          ) : (
            <Typography className={styles.Title}>Custom Hex</Typography>
          )}
          {displayCustomInput && (
            <CustomColorTooltip
              onClick={() => {
                setDisplayPalette(!displayPalette);
              }}
              text={displayPalette ? 'Custom Hex Code' : 'Color Palette'}
              title={
                displayPalette
                  ? tooltipText
                    ? tooltipText
                    : `Click here to enter your brand's HEX code`
                  : `Click here to choose from our color palette presets`
              }
            />
          )}
        </Box>

        {warningMessage && (
          <Typography fontSize="12px" marginBottom="8px" color="#8B89A0">
            {warningMessage}
          </Typography>
        )}
        {!displayPalette && (
          <Typography className={styles.CustomColor}>
            Make it stand out with a custom color you like!
          </Typography>
        )}
        {displayPalette ? (
          <ColorPalette
            palette={palette}
            activeColor={activeColor.raw as string}
            onSelect={onColorChange}
          />
        ) : (
          <ColorInput
            errorText={errorText}
            setColorError={setColorError}
            setFieldError={setFieldError}
            color={color}
            colorFormat={'hex'}
            onInputChange={handleChangeInput}
            onFormatChange={() => {}}
            onColorPick={(color) => {
              onColorChange('#' + color.hex);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export const useCustomColorPickerStyles = makeStyles((theme: Theme) => ({
  Title: {
    fontSize: '12px',
    fontWeight: '500',
    color: theme.palette.primary.main,
    letterSpacing: '0.02em',
  },
  CustomColor: {
    fontSize: '9px',
    color: theme.palette.common.black,
    letterSpacing: '0.02em',
    marginBottom: '4px',
  },
}));
