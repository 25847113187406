import { FC } from 'react';
import { CardMedia, Divider, MenuItem, Typography, Menu } from '@mui/material';
import { SidebarStrings } from '../../../../localization/en';
import { VenuesListDTO } from '../../../../../store/storeModels';
import { useStylesMenu } from './Workspace.style';

interface PropsW {
  open: boolean;
  anchorEl: null | HTMLElement;
  venuesList: VenuesListDTO[];
  activeVenueId: string;
  handleClick: (id: string) => void;
  handleClose: () => void;
}
export const WorkspaceContextMenu: FC<PropsW> = ({
  open,
  anchorEl,
  handleClose,
  handleClick,
  venuesList,
  activeVenueId,
}) => {
  const classes = useStylesMenu();

  return (
    <Menu
      classes={{ paper: classes.root }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Typography className={classes.fonts} classes={{ root: classes.title }}>
        {SidebarStrings.WorkSpace}
      </Typography>
      <Divider sx={{ my: 0.5 }} />
      {venuesList.map((item) => (
        <MenuItem
          key={item.venueId}
          className={activeVenueId === item.venueId ? classes.activeManager : ''}
          classes={{ root: classes.menuItem }}
          onClick={() => handleClick(item.venueId)}
          disableRipple
        >
          <CardMedia className={classes.img} image={item.venueLogo} />

          <Typography className={classes.fonts} classes={{ root: classes.text }}>
            {item.venueName}
          </Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
