import { useEffect, useState, useRef } from 'react';

// mui imports
import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';

// icons
import { DownloadIcon } from '../../../common/assets/shareAssets/DownloadIcon';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';

import { ClientTypes } from '../../../api/models/common';
import { FilterLogoPosition } from '../../../api/models/venue';
import {
  InputWrapper,
  PicturedInput,
} from '../../../common/components/NewDetailsModal/DetailsModalContent/components/NewCampaign.helper';
import { Toggle } from '../../../common/components/toggle/CustomToggle';
import { CommonStrings, DownloadedVideoScreenStrings } from '../../../common/localization/en';
import { createUploadUrl, uploadLogoAsFile } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { AddLogoDetails } from './AddLogoDetails';
import { useDownloadedVideoStyles } from './DownloadedVideo.styles';
import { IPhoneVideoPlaybackPreview } from '../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { FTEModal } from '../FTEModal/FTEModal';
import { Tooltip } from '../../../common/components/table/Tooltip';
import { useControlModal } from '../../../services/hooks/useControlModal';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';

const positionsnormal = {
  'top-left': { dx: 20, dy: 20 },
  'top-right': { dx: 320, dy: 20 },
  'bottom-left': { dx: 20, dy: 420 },
  'bottom-right': { dx: 320, dy: 420 },
};

const topgolfPositions = {
  'top-left': { dx: 20, dy: 20 },
  'top-right': { dx: 340, dy: 20 },
  'bottom-left': { dx: 20, dy: 360 },
  'bottom-right': { dx: 340, dy: 360 },
};

export enum filterLogoPositions {
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

const uploadOverlay = async (
  tempFilterOverlayUrl: string,
  tempFilterLogo: string,
  logoPosition: string,
  venueId: string,
  setImageUrl: (url: string) => void,
  isTopgolf: boolean,
) => {
  let ctx: CanvasRenderingContext2D | null;
  let canvas = document.createElement('canvas');
  canvas.width = 400;
  canvas.height = 500;
  const positions = isTopgolf ? topgolfPositions : positionsnormal;
  if (canvas) {
    ctx = canvas.getContext('2d');

    let imageObj1 = new Image();
    let imageObj2 = new Image();
    imageObj1.crossOrigin = 'Anonymous';
    imageObj2.crossOrigin = 'Anonymous';

    const generateUrlToFileAndPassToCallback = () => {
      canvas.setAttribute('crossorigin', 'anonymous');
      const url = (canvas as HTMLCanvasElement).toDataURL('image/png');
      urltoFile(url, 'image.png', 'image/png').then(function (file) {
        createUploadUrl(venueId, 'png').then((res) => {
          if (res) {
            uploadLogoAsFile({ options: res, data: file }).then(() => {
              setImageUrl(res.downloadUrl);
            });
          }
        });
        return file;
      });
    };
    function scaleIt(source: HTMLImageElement, scaleFactor: number) {
      var c = document.createElement('canvas');
      var ctx = c.getContext('2d');
      var w = source.width * scaleFactor;
      var h = source.height * scaleFactor;
      c.width = w;
      c.height = h;
      if (ctx) {
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(source, 0, 0, w, h);
      }
      return c;
    }

    if (tempFilterOverlayUrl && tempFilterLogo) {
      imageObj1.src = tempFilterOverlayUrl;
      imageObj1.onload = function () {
        if (ctx) {
          ctx.drawImage(imageObj1, 0, 0, 400, 500);
          imageObj2.src = tempFilterLogo;
          imageObj2.onload = function () {
            if (ctx) {
              var c1 = scaleIt(imageObj2, 1);

              const logoPos = positions[logoPosition as FilterLogoPosition];
              ctx.drawImage(c1, logoPos.dx, logoPos.dy, 30, 30);
              generateUrlToFileAndPassToCallback();
            }
          };
        }
      };
    } else if (tempFilterOverlayUrl && !tempFilterLogo) {
      imageObj1.src = tempFilterOverlayUrl;
      imageObj1.onload = function () {
        if (ctx) {
          ctx.drawImage(imageObj1, 0, 0, 400, 350);
          generateUrlToFileAndPassToCallback();
        }
      };
    } else if (!tempFilterOverlayUrl && tempFilterLogo) {
      imageObj2.src = tempFilterLogo;
      imageObj2.onload = function () {
        if (ctx) {
          const placedLogoSize = 60;
          const logoW = imageObj2.width;
          const logoH = imageObj2.height;
          const scaleFactor = placedLogoSize / Math.max(logoW, logoH);

          var c1 = scaleIt(imageObj2, scaleFactor);

          const logoPos = positions[logoPosition as FilterLogoPosition];
          const posX = logoPos.dx + (placedLogoSize - scaleFactor * logoW) / 2;
          const posY = logoPos.dy + (placedLogoSize - scaleFactor * logoH) / 2;
          ctx.drawImage(c1, posX, posY, scaleFactor * logoW, scaleFactor * logoH);
          generateUrlToFileAndPassToCallback();
        }
      };
    } else if (!tempFilterOverlayUrl && !tempFilterLogo) {
      imageObj1.src = '';
      if (ctx) {
        ctx.drawImage(imageObj1, 0, 0, 400, 350);
        generateUrlToFileAndPassToCallback();
      }
    }
  }
};

function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

//Usage example:

export const DownloadedVideo = () => {
  const classes = useDownloadedVideoStyles();
  const dispatch = useAppDispatch();
  // const inputFile1 = useRef<HTMLInputElement | null>(null);
  const inputFile2 = useRef<HTMLInputElement | null>(null);
  const { id: venueId, logo, clientType } = useTypedSelector((state) => state.venue.venue);
  const venueProperties = useTypedSelector((state) => state.venue.venueAttributes.properties);
  const isTopgolf = clientType === ClientTypes.TOPGOLF;
  const filterLogoPosition =
    typeof venueProperties?.['webapp.config']['filter-logo-position'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-logo-position']
      : filterLogoPositions.bottomRight;

  const defaultIncentiveConfig = venueProperties?.['webapp.firstTimeOnboardingFinished.config'];
  const previewButtonText = venueProperties?.['webapp.default-story-details']?.ctaBtnText;

  // const filterLogo = venueProperties?.['webapp.config']['filter-logo'];
  const filterLogoEnabled =
    typeof venueProperties?.['webapp.config']['filter-logo-enabled'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-logo-enabled']
      : true;
  const filterOverlay =
    typeof venueProperties?.['webapp.config']['filter-overlay-url'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-overlay-url']
      : '';
  const filterOverlayEnabled =
    typeof venueProperties?.['webapp.config']['filter-overlay-enabled'] !== 'undefined'
      ? venueProperties?.['webapp.config']['filter-overlay-enabled']
      : false;
  const [tempFilterOverlayEnabled, setTempFilterOverlayEnabled] = useState<boolean>(
    (filterOverlayEnabled && isTopgolf) || false,
  );
  const [tempFilterLogoEnabled, setTempFilterLogoEnabled] = useState<boolean>(
    filterLogoEnabled === false || isTopgolf ? false : true,
  );
  const [tempFilterOverlayUrl, setTempFilterOverlayUrl] = useState<string>(filterOverlay || '');
  // const [tempFilterLogo, setTempFilterLogo] = useState<string>(filterLogo || '');
  const [logoPosition, setLogoPosition] = useState<FilterLogoPosition>(
    filterLogoPosition || filterLogoPositions.bottomRight,
  );
  const [imageUrl, setImageUrl] = useState('');

  const [hideFirstTimeActionBar, setHideFirstTimeActionBar] = useState(true);
  const [isOpenModal, toggleModal] = useControlModal();

  const handleActionBarClicked = () => {
    dispatch(
      patchVenueProperties({
        id: venueId,
        update: {
          name: 'webapp.firstTimeOnboardingFinished.config',
          value: {
            ...defaultIncentiveConfig,
            webapp: {
              ...defaultIncentiveConfig?.webapp,
              webapp_download: true,
            },
          },
        },
      }),
    );
  };

  useEffect(() => {
    if (
      venueProperties &&
      (!venueProperties?.['webapp.firstTimeOnboardingFinished.config'] ||
        !venueProperties?.['webapp.firstTimeOnboardingFinished.config']?.webapp?.webapp_download)
    ) {
      setHideFirstTimeActionBar(false);
    }
  }, [venueProperties]);

  useEffect(() => {
    if (venueProperties) {
      setTempFilterOverlayUrl(venueProperties?.['webapp.config']['filter-overlay-url'] || '');
      setTempFilterOverlayEnabled(
        (venueProperties?.['webapp.config']['filter-overlay-enabled'] && isTopgolf) || false,
      );
      setTempFilterLogoEnabled(
        (filterLogoEnabled && !isTopgolf) || (hideFirstTimeActionBar ? false : true),
      );
      setLogoPosition(
        venueProperties?.['webapp.config']['filter-logo-position'] ||
          filterLogoPositions.bottomRight,
      );
      // setTempFilterLogo(venueProperties?.['webapp.config']['filter-logo'] || logo);
    }
  }, [venueProperties, logo, isTopgolf, filterLogoEnabled, hideFirstTimeActionBar]);

  useEffect(() => {
    if (imageUrl) {
      dispatch(
        patchVenueProperties({
          id: venueId,
          update: {
            name: 'webapp.config',
            value: {
              ...(venueProperties?.['webapp.config'] || {}),
              'filter-logo-position': logoPosition,
              'filter-logo-enabled': tempFilterLogoEnabled,
              'filter-overlay-enabled': tempFilterOverlayEnabled,
              'filter-overlay-url': tempFilterOverlayUrl,
              // 'filter-logo': tempFilterLogo,
              'filter-url': imageUrl,
            },
          },
        }),
      );
      if (hideFirstTimeActionBar === false) {
        setHideFirstTimeActionBar(true);
        handleActionBarClicked();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl]);

  // const onButtonClick1 = () => {
  //   if (inputFile1 && inputFile1.current) {
  //     (inputFile1.current as any).click();
  //   }
  // };
  const onButtonClick2 = () => {
    if (inputFile2 && inputFile2.current) {
      (inputFile2.current as any).click();
    }
  };

  const handleSubmit = async (e?: any) => {
    e?.preventDefault();
    handleUpload();
  };
  const handleReset = () => {
    setTempFilterOverlayUrl(venueProperties?.['webapp.config']['filter-overlay-url'] || '');
    setTempFilterOverlayEnabled(
      (venueProperties?.['webapp.config']['filter-overlay-enabled'] && isTopgolf) || false,
    );
    setTempFilterLogoEnabled(
      (venueProperties?.['webapp.config']['filter-logo-enabled'] && !isTopgolf) || false,
    );
    setLogoPosition(
      venueProperties?.['webapp.config']['filter-logo-position'] || filterLogoPositions.bottomRight,
    );
    // setTempFilterLogo(venueProperties?.['webapp.config']['filter-logo'] || logo);

    if (hideFirstTimeActionBar === false) {
      setHideFirstTimeActionBar(true);
      handleActionBarClicked();
    }
  };

  const handleSetupLater = (e?: any) => {
    e?.preventDefault();
    handleUpload();
  };
  // const onCardImageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   event.stopPropagation();
  //   const target = event.target as HTMLInputElement;
  //   const file: File = (target.files as FileList)[0];

  //   createUploadUrl(venueId, file?.name.split('.').pop()).then((res) => {
  //     if (res) {
  //       uploadLogoAsFile({ options: res, data: file }).then(() => {
  //         setTempFilterLogo(res.downloadUrl);
  //       });
  //     }
  //   });
  // };
  const onChangeTempFilterOverlay = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    createUploadUrl(venueId, file?.name.split('.').pop()).then((res) => {
      if (res) {
        uploadLogoAsFile({ options: res, data: file }).then(() => {
          setTempFilterOverlayUrl(res.downloadUrl);
        });
      }
    });
  };

  const canSave = () => {
    return (
      filterLogoPosition !== logoPosition ||
      filterLogoEnabled !== tempFilterLogoEnabled ||
      filterOverlay !== tempFilterOverlayUrl ||
      filterOverlayEnabled !== tempFilterOverlayEnabled
    );
  };

  const handleUpload = async () => {
    if (logoPosition && venueId) {
      return await uploadOverlay(
        tempFilterOverlayEnabled ? tempFilterOverlayUrl : '',
        tempFilterLogoEnabled ? logo : '',
        logoPosition,
        venueId,
        setImageUrl,
        isTopgolf,
      );
    } else {
      return Promise.resolve('');
    }
  };

  return (
    <>
      <FTEModal openModal={isOpenModal} toggleModal={toggleModal} />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6} md={6} style={{ backgroundColor: '#fff' }}>
            <Grid container mb={2} justifyContent="space-between" spacing={3} alignItems="center">
              <Grid item xs={12} md={12} alignItems="center">
                <p className={classes.ColumnTitle}>{DownloadedVideoScreenStrings.SectionTitle2}</p>
                <Divider />
              </Grid>

              {!isTopgolf ? (
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  style={{ paddingTop: '0px', paddingLeft: '56px' }}
                  className={classes.DisableAccordion}
                >
                  <Grid className={classes.SummaryWithTooltip} item>
                    <Grid
                      container
                      item
                      alignItems="center"
                      wrap="nowrap"
                      sx={{ paddingBottom: '15px' }}
                    >
                      <Toggle
                        onChange={() => {
                          setTempFilterLogoEnabled(!tempFilterLogoEnabled);
                        }}
                        value={tempFilterLogoEnabled}
                        pending={false}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      <Typography className={classes.Title}>
                        {DownloadedVideoScreenStrings.Logo}
                      </Typography>

                      <Tooltip
                        padding="23px 25px"
                        rounded="15px"
                        title={DownloadedVideoScreenStrings.FirstStageTooltip}
                        icon={<QuestionMarkRoundedIcon className={classes.Tooltip} />}
                      />
                    </Grid>
                    <Grid item>
                      <Box display={'flex'} flexDirection="column" alignItems="flex-start">
                        <AddLogoDetails
                          logoPosition={logoPosition}
                          setLogoPosition={setLogoPosition}
                          disabled={!tempFilterLogoEnabled}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ paddingTop: '0px', paddingLeft: '56px' }}>
                  <Accordion expanded={true} className={classes.Accordion}>
                    <AccordionSummary className={classes.AccordionSummary} style={{ width: '90%' }}>
                      <Grid container flexWrap="nowrap">
                        <Grid className={classes.AccordionSummary} item xs={10}>
                          <Grid container alignItems="center" wrap="nowrap">
                            <>
                              <Toggle
                                onChange={() => {
                                  setTempFilterOverlayEnabled(!tempFilterOverlayEnabled);
                                }}
                                value={tempFilterOverlayEnabled}
                                pending={false}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </>
                            <Typography className={classes.Title}>
                              {DownloadedVideoScreenStrings.SecondStepTitle2}
                            </Typography>

                            <Tooltip
                              padding="23px 25px"
                              rounded="15px"
                              title={DownloadedVideoScreenStrings.SecondStageTooltip}
                              icon={<QuestionMarkRoundedIcon className={classes.Tooltip} />}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingBottom: '25px', paddingLeft: '0' }}>
                      <>
                        <Grid container>
                          <Grid item xs={12}>
                            <div className={classes.NotesWrapper}>
                              <span className={classes.NotesTitle}>
                                {DownloadedVideoScreenStrings.Notes}
                              </span>
                              <span className={classes.NotesText}>
                                {DownloadedVideoScreenStrings.NotesDescription}
                              </span>
                            </div>

                            <div className={classes.DownloadButtonWrapper}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DownloadIcon />}
                              >
                                <a
                                  className={classes.DisableColoration}
                                  href={isTopgolf ? '/TGFilterTemplate.png' : '/FilterTemplate.png'}
                                  download="filter template"
                                >
                                  {DownloadedVideoScreenStrings.DownloadTemplate}
                                </a>
                              </Button>
                            </div>
                          </Grid>
                          <Grid item xs={6} className={classes.imgChanger}>
                            <InputWrapper
                              className={
                                isTopgolf ? classes.imageWrapperForTopGolf : classes.imageWrapper
                              }
                              backgroundImage={tempFilterOverlayUrl || 'test'}
                            >
                              <PicturedInput
                                ref={inputFile2}
                                id="contained-button-file"
                                type="file"
                                accept="image/png"
                                onChange={onChangeTempFilterOverlay}
                              />
                            </InputWrapper>
                            <Box className={classes.textWrapper}>
                              <Button className={classes.changeLogoButton} onClick={onButtonClick2}>
                                {CommonStrings.ChangeImage}
                              </Button>
                              <Typography className={classes.text}>
                                {CommonStrings.DragAndDrop}
                              </Typography>
                              <Typography className={classes.text}>
                                {'(' + CommonStrings.Size}
                                {isTopgolf ? ' 1080px * 1280px' : ' 864px * 1080px'}
                                {')'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.StageDescription}></Typography>
                          </Grid>
                        </Grid>
                        <Box marginTop="8px" marginBottom="8px">
                          <Typography className={classes.StageTitle}></Typography>
                        </Box>
                      </>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Grid>

            <BottomActionBar
              show={canSave()}
              firstTimeExperience={!hideFirstTimeActionBar}
              onSaveClick={handleSubmit}
              onCancelClick={handleReset}
              onSetupLaterClick={handleSetupLater}
            />

            {/* )} */}
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            //  className={classes.new}
          >
            <Box display="flex" justifyContent="end" width="100%">
              <IconButton
                onClick={toggleModal}
                style={{
                  backgroundColor: '#fff',
                  color: '#8B89A0',
                  marginTop: '30px',
                  marginRight: '30px',
                }}
              >
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Box>
            <IPhoneVideoPlaybackPreview
              tempFilterOverlayUrl={tempFilterOverlayUrl}
              logoPosition={logoPosition}
              tempFilterLogoEnabled={tempFilterLogoEnabled}
              tempFilterOverlayEnabled={tempFilterOverlayEnabled}
              buttonText={previewButtonText || ''}
              logo={logo}
              isViewForTopGolf={isTopgolf}
            />
          </Grid>
        </Grid>
        <FTEModal openModal={isOpenModal} toggleModal={toggleModal} />
      </form>
    </>
  );
};
