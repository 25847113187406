import { FC } from 'react';

interface Props {
  color?: string;
}

export const ClicksIconMedium: FC<Props> = (props) => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.12735 12.3905L3.64948 10.6485C3.10264 10.2641 2.36243 10.3097 1.86696 10.7584V10.7584C1.36258 11.2152 1.24988 11.9641 1.59748 12.5491L4.69592 17.7635C5.88825 19.7701 8.04967 21 10.3838 21V21C14.0378 21 17 18.0378 17 14.3838V7.92905C17 7.23212 16.435 6.66715 15.7381 6.66715V6.66715C15.0412 6.66715 14.4762 7.23212 14.4762 7.92905V9.87665V7.30213C14.4762 6.55261 13.8686 5.94499 13.119 5.94499V5.94499C12.3695 5.94499 11.7619 6.55261 11.7619 7.30213V9.87665V6.90133C11.7619 6.13197 11.1382 5.50828 10.3689 5.50828V5.50828C9.59949 5.50828 8.9758 6.13197 8.9758 6.90133V9.87665V2.42422C8.9758 1.63765 8.33815 1 7.55158 1V1C6.765 1 6.12735 1.63765 6.12735 2.42422V12.3905Z"
      stroke="#8B89A0"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={props.color || 'white'}
    />
  </svg>
);
