import { FC } from 'react';

type iconProps = {
  color: string;
};

export const AchievementIcon: FC<iconProps> = ({ color }) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00684 3.49951H17.992V6.51189V9.47537C17.992 12.7744 15.3023 15.4489 11.9994 15.4489C8.68981 15.4489 6.00684 12.7828 6.00684 9.46776V6.48187V3.49951Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path d="M12 17.1195V21.1146" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    <path d="M8.00488 21.1145H15.995" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M17.992 5.49109C17.992 5.49109 20.4889 5.49109 20.9883 5.49109C21.4877 5.49109 21.9871 5.98898 21.9871 6.48686C21.9871 6.98475 22.4694 11.4657 17.4756 11.4657"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M6.00672 5.49109C6.00672 5.49109 3.50981 5.49109 3.01042 5.49109C2.51104 5.49109 2.01166 5.98898 2.01166 6.48686C2.01166 6.98475 1.5384 11.4657 6.53223 11.4657"
      stroke={color}
      strokeWidth="1.5"
    />
  </svg>
);
