import { Box, Typography } from '@mui/material';
import { FC } from 'react';

import { useStyles } from './CallToActionPreview.styles';
import { IPhoneVideoPlaybackPreview } from '../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { filterLogoPositions } from '../../webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { useTypedSelector } from '../../../store';
import { NewCampaignStrings } from '../../../common/localization/en';

export const CallToActionPreview: FC = () => {
  const styles = useStyles();
  const {
    venue: { logo },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { ctaButtonText } = useTypedSelector((state) => state.createCampaign);
  const brandColor = venueProperties?.['webapp.config']['primary-color'];

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>{NewCampaignStrings.VideoPlaybackTitle}</Typography>
      <Typography className={styles.subtitle}>
        {NewCampaignStrings.VideoPlaybackSubtitle}
      </Typography>
      <IPhoneVideoPlaybackPreview
        logoPosition={filterLogoPositions.topLeft}
        tempFilterLogoEnabled={true}
        tempFilterOverlayEnabled={true}
        buttonText={ctaButtonText}
        logo={logo}
        brandColor={brandColor}
      />
    </Box>
  );
};
