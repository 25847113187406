import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { FilterOptionsType } from './filterOptions.helper';
import { SimpleAccordion } from '../../accordion/CustomAccordion';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // background: theme.palette.primary.light,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  optionWrapper: {
    cursor: 'pointer',
    display: 'flex',
    height: '36px',
    padding: '6px',
    borderRadius: '50px',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '5px',
    marginRight: '6px',
    gap: '12px',
    stroke: theme.palette.primary.main,
    width: '36px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}33`,
    },
    backgroundColor: '#F5F6F8',
  },
  optionTitle: {
    textAlign: 'center',
    fontSize: '14px',
    color: theme.palette.primary.main,
  },
  selectedOption: {
    color: theme.palette.common.white,
    stroke: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  active: {
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));

interface FilterShareTypeOptionsProps {
  selectedOptions: string[];
  handleClick: (id: string) => void;
  filterOptions: FilterOptionsType[];
  title: string;
  primary?: boolean;
  expanded?: boolean;
  handleChange?: () => void;
  handleDelete?: () => void;
}

export const FilterShareTypeOptions: FC<FilterShareTypeOptionsProps> = ({
  selectedOptions,
  handleClick,
  filterOptions,
  title,
  primary,
  expanded,
  handleChange,
  handleDelete,
}) => {
  const classes = useStyles();
  const renderOptions = (options: FilterOptionsType[], tempSelectedOptions: string[]) => {
    return options.map((item) => {
      const isSelected = tempSelectedOptions.includes(item.id);
      return (
        <div
          className={[
            classes.optionWrapper,
            isSelected ? [classes.selectedOption, classes.active].join(' ') : '',
          ].join(' ')}
          onClick={() => handleClick(item.id)}
        >
          {item.icon}
        </div>
      );
    });
  };

  return (
    <SimpleAccordion
      title={title}
      primary={primary}
      expanded={expanded}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      <div className={classes.root}>{renderOptions(filterOptions, selectedOptions)}</div>
    </SimpleAccordion>
  );
};
