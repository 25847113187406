import { Box, Grid } from '@mui/material';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SubNavigationTab, useStyles, WebAppSubnaviationItem } from './TopBarTabs.helper';

interface TopBarTabsProps {
  tabs: SubNavigationTab[];
  onTabChange?: (newTab: SubNavigationTab) => void;
}

/**
 * @author
 * @function @TopBarTabs
 *  Usage: <TopBar
       
        tabs={[
          {
            text: 'Branding Style',
            route: WebsiteRoutes.BrandingStyleTab,
          },
          {
            text: 'Sign Up',
            route: WebsiteRoutes.SignUpTab,
          },
          {
            text: 'Clip Playback',
            route: WebsiteRoutes.VideoPlaybackTab,
          },
          {
            text: 'Tracking Pixel',
            route: WebsiteRoutes.TrackingPixelTab,
          },
        ]}
      />
 **/

interface NavBarProps {
  item: WebAppSubnaviationItem;
  onClick: () => void;
  className: string;
}
export const NavBarTab: FC<NavBarProps> = ({ item, onClick, className }) => {
  return (
    <Box onClick={onClick} className={className}>
      {item.text}
    </Box>
  );
};

export const TopBarTabs: FC<TopBarTabsProps> = ({ tabs, onTabChange }) => {
  const styles = useStyles();
  const { push } = useHistory();
  const location = useLocation();
  const [activeTabIndex, setActiveTabIndex] = useState(
    tabs.findIndex((t) => t.route === location.pathname) ?? 0,
  );
  return (
    <Grid container width={'fit-content'} pt="12px">
      {tabs.map((item, i) => (
        <NavBarTab
          item={item}
          onClick={() => {
            onTabChange?.(item);
            setActiveTabIndex(i);
            push(item.route);
          }}
          className={`${styles.root} ${i === activeTabIndex ? styles.active : null}`}
        />
      ))}
    </Grid>
  );
};
