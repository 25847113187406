import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import {
  MessageFrequency,
  RewardFulfillmentMethod,
} from '../../../../../../../../../api/models/rewardFulfillment';
import { SmsInviteStrings } from '../../../../../../../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../../store';
import {
  setMessageFrequency,
  setSMSMethod,
} from '../../../../../../../../../store/slices/createCampaignSlice';
import { useStyles } from './DeliveryOptionsSection.styles';

export const DeliveryOptionsSection: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { SMSMethod, messageFrequency } = useTypedSelector((store) => store.createCampaign);

  const isManual = SMSMethod === RewardFulfillmentMethod.MANUAL_SMS;
  const isAutomatic = SMSMethod === RewardFulfillmentMethod.AUTOMATIC_SMS;

  const isFirstClip = messageFrequency === MessageFrequency.FIRST;
  const isEveryClip = messageFrequency === MessageFrequency.EVERY;

  const theme = useTheme();

  return (
    <Box>
      <Typography className={classes.messageText}>{SmsInviteStrings.MessageDelivery}</Typography>
      <Typography className={classes.selectText}>{SmsInviteStrings.SelectDelivery}</Typography>
      <Box className={classes.buttonsWrapper}>
        <Button
          className={classes.switchButton}
          onClick={() => {
            if (isAutomatic) {
              dispatch(setSMSMethod(RewardFulfillmentMethod.MANUAL_SMS));
              dispatch(setMessageFrequency(MessageFrequency.FIRST));
            }
          }}
          sx={{
            borderRadius: '50px 0px 0px 50px',
            border: `2px solid ${isManual ? theme.palette.primary.main : '#8B89A0'}`,
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: isManual ? theme.palette.primary.main : '#8B89A0',
            backgroundColor: isManual ? `${theme.palette.primary.main}0a` : '#fff',
            '&:hover': {
              backgroundColor: isManual ? `${theme.palette.primary.main}0a` : '#fff',
            },
          }}
        >
          {SmsInviteStrings.Manual}
        </Button>
        <Button
          className={classes.switchButton}
          onClick={() => {
            if (isManual) {
              dispatch(setSMSMethod(RewardFulfillmentMethod.AUTOMATIC_SMS));
            }
          }}
          sx={{
            borderRadius: '0px 50px 50px 0px',
            border: `2px solid ${isAutomatic ? theme.palette.primary.main : '#8B89A0'}`,
            color: isAutomatic ? theme.palette.primary.main : '#8B89A0',
            borderLeft: `2px solid ${theme.palette.primary.main}`,
            right: '1px',
            backgroundColor: isAutomatic ? `${theme.palette.primary.main}0a` : '#fff',
            '&:hover': {
              backgroundColor: isAutomatic ? `${theme.palette.primary.main}0a` : '#fff',
            },
          }}
        >
          {SmsInviteStrings.Automatic}
        </Button>
      </Box>
      <Typography className={classes.messageSentText}>
        {isAutomatic
          ? SmsInviteStrings.MessageWillBeSentAutomatically
          : SmsInviteStrings.MessageWillBeSentManually}
      </Typography>
      {isAutomatic && (
        <>
          <Typography className={classes.selectText}>
            {SmsInviteStrings.MessageFrequency}
          </Typography>
          <Box className={classes.buttonsWrapper}>
            <Button
              className={classes.switchButton}
              onClick={() => {
                if (isEveryClip) {
                  dispatch(setMessageFrequency(MessageFrequency.FIRST));
                }
              }}
              sx={{
                borderRadius: '50px 0px 0px 50px',
                border: `2px solid ${isFirstClip ? theme.palette.primary.main : '#8B89A0'}`,
                borderRight: `2px solid ${theme.palette.primary.main}`,
                color: isFirstClip ? theme.palette.primary.main : '#8B89A0',
                backgroundColor: isFirstClip ? `${theme.palette.primary.main}0a` : '#fff',
                '&:hover': {
                  backgroundColor: isFirstClip ? `${theme.palette.primary.main}0a` : '#fff',
                },
              }}
            >
              {SmsInviteStrings.FirstClip}
            </Button>
            <Button
              className={classes.switchButton}
              onClick={() => {
                if (isFirstClip) {
                  dispatch(setMessageFrequency(MessageFrequency.EVERY));
                }
              }}
              sx={{
                borderRadius: '0px 50px 50px 0px',
                border: `2px solid ${isEveryClip ? theme.palette.primary.main : '#8B89A0'}`,
                color: isEveryClip ? theme.palette.primary.main : '#8B89A0',
                borderLeft: `2px solid ${theme.palette.primary.main}`,
                right: '1px',
                backgroundColor: isEveryClip ? `${theme.palette.primary.main}0a` : '#fff',
                '&:hover': {
                  backgroundColor: isEveryClip ? `${theme.palette.primary.main}0a` : '#fff',
                },
              }}
            >
              {SmsInviteStrings.EveryClip}
            </Button>
          </Box>
          <Typography className={classes.messageSentText}>
            {isFirstClip ? SmsInviteStrings.FirstClipMessage : SmsInviteStrings.EveryClipMessage}
          </Typography>
        </>
      )}
    </Box>
  );
};
