import React, { useEffect } from 'react';

import { useAppDispatch, useTypedSelector } from '../../../../store';

import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../../services/utilities';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../../common/constants/constants';

import { CommonTable } from '../../../../common/components/table/CommonTable';

import {
  getCampaigns,
  goToSelectedPage,
  setCampaignsSorting,
  updateRequestsSize,
} from '../../../../store/slices/campaignsSlice';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { CreateRewardsCampaignsTableContent, Styles } from './TableSection.helper';
import { Box } from '@mui/material';
import { useIncentiveTablesStyles } from './TableSection.style';

interface TableSectionProps {
  onEditCampaign: (campaign: CampaignsApiModel) => void;
  onEndCampaign: () => void;
}
export const TableSection: React.FC<TableSectionProps> = ({ onEditCampaign, onEndCampaign }) => {
  const dispatch = useAppDispatch();
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;

  const styles = Styles();
  const classes = useIncentiveTablesStyles();

  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
    lastUpdated,
  } = useTypedSelector((state) => state.campaigns);

  const venueId = useTypedSelector((state) => state.venue.venue.id);

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ search, accountId: venueId, pageable: { page, size, sort } }));
    }
  }, [dispatch, page, size, sort, search, venueId, lastUpdated]);

  const tableContents = CreateRewardsCampaignsTableContent({
    items,
    handleOpenEditModal: onEditCampaign,
    handleOpenEndModal: onEndCampaign,
    isEditButton: true,
    isMobile,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setCampaignsSorting(name));
  };

  const changeSize = (size: number) => {
    dispatch(updateRequestsSize(size));
  };

  return (
    <Box className={styles.TableWrapper}>
      {/* here */}
      <div className={classes.IncentiveTableStyles}>
        <CommonTable
          content={tableContents}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
        />
      </div>
    </Box>
  );
};
