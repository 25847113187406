import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PricingPlanEndpoints, ReferralCodeEndpoints } from '../../api/endpoints';
import { httpClient } from '../../services/httpClient/httpClient';
import {
  GetPricingPlanByCodeRequest,
  GetPricingPlanByCodeResponse,
  GetPricingPlanByIdOrCodeRequest,
  GetPricingPlanByIdOrCodeResponse,
  GetPricingPlansRequest,
  GetPricingPlansResponse,
  PostPricingPlanRequest,
  PostPricingPlanResponse,
  PricingPlanModel,
  PutPricingPlanResponse,
  PutPricingPlansRequest,
} from '../../api/models/pricingPlans';

interface PricingPlansState {
  pricingPlans: PricingPlanModel[];
  activePricingPlan: PricingPlanModel | null;
  planDetails: {
    value: PricingPlanModel | null;
    isError: boolean;
  };
  isLoading: boolean;
  isError: boolean;
}

const initialState: PricingPlansState = {
  pricingPlans: [],
  activePricingPlan: null,
  planDetails: {
    value: null,
    isError: false,
  },
  isLoading: false,
  isError: false,
};

interface GetPricingPlansArgs {
  distributorIds: Array<GetPricingPlansRequest['distributorId']>;
}

export const getPricingPlansByDistributorIds = createAsyncThunk(
  'pricingPlans/getPricingPlans',
  async ({ distributorIds }: GetPricingPlansArgs, { rejectWithValue }) => {
    try {
      const promises = distributorIds.map((distributorId) => {
        return httpClient.get<GetPricingPlansRequest, GetPricingPlansResponse>({
          url: PricingPlanEndpoints.GetPricingPlans,
          requiresToken: true,
          params: {
            distributorId,
          },
        });
      });

      const res = await Promise.all(promises);
      return res.flat();
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getPricingPlanByIdOrCode = createAsyncThunk(
  'pricingPlan/getPricingPlanByIdOrCode',
  async ({ idOrCode }: GetPricingPlanByIdOrCodeRequest, { rejectWithValue }) => {
    try {
      return httpClient.get<GetPricingPlanByIdOrCodeRequest, GetPricingPlanByIdOrCodeResponse>({
        url: PricingPlanEndpoints.GetPricingPlanByIdOrCode.replace(':idOrCode', idOrCode),
        requiresToken: true,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

// Public endpoint
export const getPricingPlanByReferralCode = createAsyncThunk(
  'pricingPlan/getPricingPlanByReferralCode',
  async ({ code }: GetPricingPlanByCodeRequest, { rejectWithValue }) => {
    try {
      return httpClient.get<GetPricingPlanByCodeRequest, GetPricingPlanByCodeResponse>({
        url: ReferralCodeEndpoints.GetPlanByCode.replace(':code', code),
        requiresToken: false,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const createNewPricingPlan = createAsyncThunk(
  'pricingPlans/createNewPricingPlan',
  async ({ payload }: PostPricingPlanRequest, { rejectWithValue }) => {
    try {
      return httpClient.post<PostPricingPlanRequest['payload'], PostPricingPlanResponse>({
        url: PricingPlanEndpoints.PostPricingPlans,
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updatePricingPlan = createAsyncThunk(
  'pricingPlans/updatePricingPlan',
  async ({ id, payload }: PutPricingPlansRequest, { rejectWithValue }) => {
    try {
      return httpClient.put<PutPricingPlansRequest['payload'], PutPricingPlanResponse>({
        url: PricingPlanEndpoints.PutPricingPlans.replace(':id', id),
        requiresToken: true,
        payload,
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const pricingPlansSlice = createSlice({
  name: 'pricingPlans',
  initialState,
  reducers: {
    reset(state) {
      state = initialState;
    },
    resetPlanDetailsError(state) {
      state.planDetails.isError = false;
    },
    resetPlanDetail(state) {
      state.planDetails = {
        isError: false,
        value: null,
      };
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getPricingPlansByDistributorIds.pending, (state) => {
      state.isLoading = true;
    });
    reducersBuilder.addCase(getPricingPlansByDistributorIds.rejected, (state) => {
      state.isLoading = false;
      state.isError = true;
    });
    reducersBuilder.addCase(getPricingPlansByDistributorIds.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isError = false;
      state.pricingPlans = payload;
    });

    reducersBuilder.addCase(getPricingPlanByReferralCode.pending, (state) => {
      state.isLoading = true;
      state.planDetails = {
        isError: false,
        value: null,
      };
    });
    reducersBuilder.addCase(getPricingPlanByReferralCode.rejected, (state) => {
      state.isLoading = false;
      state.planDetails = {
        isError: true,
        value: null,
      };
    });
    reducersBuilder.addCase(getPricingPlanByReferralCode.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.planDetails = {
        isError: false,
        value: payload,
      };
    });
  },
});

export const { reset, resetPlanDetailsError, resetPlanDetail } = pricingPlansSlice.actions;

export default pricingPlansSlice.reducer;
