import { FC, useState } from 'react';
import { SearchOperations } from '../../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../../store';
import { updateNewSearchFilterAttributes } from '../../../../../store/slices/searchFiltersSlice';
import { SearchFilterStrings } from '../../../../localization/en';
import { FilterOptionsContainer } from '../FilterOptionsContainer';

enum VideoStatusStrings {
  published = 'Published Videos',
  unpublished = 'Unpublished Videos',
  archived = 'Archived Videos',
}

export const VideoStatuses = [
  {
    id: 'PUBLISHED',
    title: VideoStatusStrings.published,
    icon: <></>,
  },
  {
    id: 'UNPUBLISHED',
    title: VideoStatusStrings.unpublished,
    icon: <></>,
  },
  {
    id: 'ARCHIVED',
    title: VideoStatusStrings.archived,
    icon: <></>,
  },
];

interface VideoStatusFilterProps {
  handleRemove: () => void;
}

export const videoStatusPropertyName= 'status'

export const VideoStatusFilter: FC<VideoStatusFilterProps> = ({ handleRemove }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleClick = (id: string) => {
    dispatch(
        updateNewSearchFilterAttributes({
          name: videoStatusPropertyName,
          operation: SearchOperations.EQUALS,
          value: id,
        }),
      );
    setSelectedOption(id);
    setExpanded(false);
  };
  const handleDelete = () => {
    setSelectedOption('');
    handleRemove();
  };
  const status =
    selectedOption && selectedOption.length
      ? VideoStatuses.find((item) => item.id === selectedOption)
      : null;
  return (
    <>
      <FilterOptionsContainer
        title={status?.title || SearchFilterStrings.SelectVideoStatus}
        filterOptions={VideoStatuses}
        selectedOptions={[]}
        handleClick={handleClick}
        expanded={expanded}
        handleChange={() => setExpanded(!expanded)}
        handleDelete={handleDelete}
      />
    </>
  );
};
