import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../common/constants/routes';
import { FirstTimeOnboardingConfig, VenueProperties } from '../api/models/venue';
import { useVenueProperty } from './useVenueProperty';

interface FTERoute {
  route: WebsiteRoutes;
  configSectionName: keyof FirstTimeOnboardingConfig;
  configValues: Partial<FirstTimeOnboardingConfig[keyof FirstTimeOnboardingConfig]>;
}

const fteRoutesConfig: Array<FTERoute> = [
  {
    route: WebsiteRoutes.BrandingStyleTab,
    configSectionName: 'webapp',
    configValues: {
      modal: true,
      branding_style: true,
    },
  },
  {
    route: WebsiteRoutes.SignUpTab,
    configSectionName: 'webapp',
    configValues: {
      webapp_signup_config: true,
    },
  },
  {
    route: WebsiteRoutes.VideoPlaybackTab,
    configSectionName: 'webapp',
    configValues: {
      webapp_sharing: true,
    },
  },
];

const FINISH_FTE_ROUTE = WebsiteRoutes.IntegrationsSmsInvite;

export const useFTE = () => {
  const history = useHistory();
  const currentRoute = history.location.pathname as WebsiteRoutes;

  const { update: updateFTEValues, value: FTEValues } = useVenueProperty<
    VenueProperties['webapp.firstTimeOnboardingFinished.config']
  >({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });

  const onFinishFte = useCallback(() => {
    const fteFinishedState = fteRoutesConfig.reduce((acc, curr) => {
      const currValues = acc[curr.configSectionName] ? acc[curr.configSectionName] : {};
      return {
        ...acc,
        [curr.configSectionName]: {
          ...currValues,
          ...curr.configValues,
        },
      };
    }, {} as FirstTimeOnboardingConfig);

    fteFinishedState.webapp_modal = {
      modal: true,
    };

    fteFinishedState.invites = {
      email: true,
      modal: true,
      sms: true,
      qr: true,
    };

    return updateFTEValues(fteFinishedState);
  }, [updateFTEValues]);

  const moveToNextRoute = useCallback(async () => {
    const routeIndex = fteRoutesConfig.findIndex((route) => route.route === currentRoute);
    const currRoute = fteRoutesConfig[routeIndex];

    if (currRoute && FTEValues) {
      const currSectionValues = FTEValues[currRoute.configSectionName];
      await updateFTEValues({
        ...FTEValues,
        [currRoute.configSectionName]: {
          ...currSectionValues,
          ...currRoute.configValues,
        },
      });
    }

    const nextRoute = fteRoutesConfig[routeIndex + 1]?.route || FINISH_FTE_ROUTE;
    if (nextRoute) {
      setTimeout(() => {
        history.push(nextRoute);
      }, 0);
    }
  }, [FTEValues, currentRoute, history, updateFTEValues]);

  return {
    moveToFirstRoute: () => {
      history.push(fteRoutesConfig[0].route);
    },
    moveToNextRoute,
    onFinishFte,
    isFteFinished: FTEValues?.webapp_modal?.modal,
  };
};
