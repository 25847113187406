import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { cardColor: string }>((theme: Theme) => ({
  container: {
    width: '280px',
    height: '528px',
    position: 'relative',
    overflow: 'hidden',
  },
  companyLogo: {
    margin: '48px auto 0 auto',
    width: 'auto',
    maxWidth: '100%',
    maxHeight: '128px',
    objectFit: 'contain',
    position: 'relative',
  },
  venueName: {
    textAlign: 'center',
    fontWeight: 800,
    color: ({ cardColor }) => cardColor,
    height: 'fit-content',
    fontFamily: 'Inter',
    wordWrap: 'break-word',
    padding: '0 8px',
  },
  headerText: {
    whiteSpace: 'nowrap',
    width: 'fit-content',
    margin: '0 auto',
    // fontSize: '30px',
    fontWeight: 800,
    color: theme.palette.common.white,
    marginTop: '128px',
    height: '40px',
    fontFamily: 'Inter',
  },
  customerName: {
    fontSize: '20px',
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.common.white,
    margin: '0 auto',
    marginTop: '8px',
    fontFamily: 'Inter',
  },
  rewardTitle: {
    color: theme.palette.common.white,
    fontWeight: 700,
    textShadow: '0px 1px 1px #4C4C4C',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    margin: '0 auto',
    marginTop: '20px',
    padding: '0 8px',
  },
  infoText: {
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'center',
    color: ({ cardColor }) => cardColor,
  },
  infoWrapper: {
    position: 'absolute',
    zIndex: 2,
    bottom: '12px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  contactWrapper: {
    marginTop: '4px',
    marginBottom: '6px',
  },
  contactHeader: {
    fontSize: '12px',
    color: '#8B89A0',
    fontWeight: 500,
  },
  contact: {
    minHeight: '18px',
    fontSize: '12px',
    fontWeight: 700,
    color: ({ cardColor }) => cardColor,
  },
}));
