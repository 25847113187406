import { Box, Button, ListItemButton, Popover, Stack, Typography } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import { useModal } from '../../../hooks/useModal';
import { ArrowIcon } from '../../assets/newDesign/ArrowIcon';
import { addMonths, format, lastDayOfMonth } from 'date-fns';

const WIDTH = '196px';

const getMonthsBetweenDates = (startDate: Date, endDate: Date): Date[] => {
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return []; // Input validation: Ensure that both startDate and endDate are Date objects.
  }

  const months: Date[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    if (!months.includes(currentDate)) {
      months.push(currentDate);
    }
    currentDate = addMonths(currentDate, 1);
  }

  return months;
};

interface MonthPickerProps {
  startDate?: Date;
  endDate?: Date;
  dateFormat?: string;

  value: Date;
  onChange: (selectedMonth: Date) => void;
}

export const MonthPicker: React.FC<MonthPickerProps> = ({
  startDate = new Date(2023, 0, 1), // January 2023
  endDate = new Date(),
  dateFormat = 'MMMM yyyy',
  value,
  onChange,
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const months = useMemo(() => {
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // first day of start month
    const end = lastDayOfMonth(endDate); // last day of end month

    return getMonthsBetweenDates(start, end).reverse();
  }, [endDate, startDate]);

  const { isOpen, open, close } = useModal(false);

  return (
    <Box
      sx={{ zoom: 'calc(1 / 0.9)' }} // revert global zoom property to fix popover position
    >
      <Button
        ref={anchorRef}
        onClick={() => {
          open();
        }}
        variant="outlined"
        sx={{
          width: WIDTH,
          border: '1px solid #EAECF0',
          background: 'white',
          color: 'black',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100% !important' }}>
          <Typography fontSize="16px" fontWeight="500">
            {format(value, dateFormat)}
          </Typography>
          <Box
            sx={{
              rotate: '90deg',
              transform: `rotateY(${isOpen ? 180 : 0}deg)`,
              transition: 'transform 0.2s ease-in-out',
            }}
          >
            <ArrowIcon />
          </Box>
        </Box>
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: '4px',
          maxHeight: '184px',
        }}
      >
        <Stack
          sx={{
            width: WIDTH,
            padding: '6px 0',
          }}
        >
          {months.map((month) => {
            const isSelected = format(month, dateFormat) === format(value, dateFormat);

            return (
              <ListItemButton
                onClick={() => {
                  if (!isSelected) {
                    onChange(month);
                  }
                  close();
                }}
                autoFocus={isSelected}
                sx={{ bgcolor: isSelected ? '#F2F2F2' : undefined, p: '4px 13px' }}
              >
                <Typography fontSize="18px" fontWeight={isSelected ? '500' : undefined}>
                  {format(month, dateFormat)}
                </Typography>
              </ListItemButton>
            );
          })}
        </Stack>
      </Popover>
    </Box>
  );
};
