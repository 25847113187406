import { useTrackEvent } from '../../hooks/useTrackEvent';
import { ChangeEvent, useCallback } from 'react';
import { camelToSnakeCase } from '../../utils/helpers';

export function useHandleChangeWithTracking(handleChange: (event: ChangeEvent<any>) => void) {
  const { trackEvent } = useTrackEvent();

  const handleChangeWithTracking = useCallback(
    (event: ChangeEvent<any>, hideValue = false) => {
      handleChange(event);
      const value = hideValue ? event.target.value.replace(/./g, '*') : event.target.value;
      trackEvent(camelToSnakeCase(event.target.name) + '_typed', { value });
    },
    [handleChange, trackEvent],
  );

  return { handleChangeWithTracking };
}
