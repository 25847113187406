import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from './NoCreators.styles';
import { NoCampaignCreatorsStrings } from '../../../../../common/localization/en';
import { Link, useParams } from 'react-router-dom';
import { WebsiteRoutes } from '../../../../../common/constants/routes';
import { getApiUrlForId } from '../../../../../api/endpoints';
import { isUWMClient } from '../../../../../utils/isUWMClient';

const NoCreators = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const isUwmClient = isUWMClient();

  return (
    <Box className={classes.container}>
      <Box
        className={classes.titleWrapper}
        style={{ background: isUwmClient ? 'rgb(195, 216, 229)' : '#EBE9FF' }}
      >
        <Typography className={classes.title}>{NoCampaignCreatorsStrings.Title}</Typography>
        <Typography className={classes.subTitle}>{NoCampaignCreatorsStrings.SubTitle}</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Typography className={classes.infoTitle}>{NoCampaignCreatorsStrings.InfoTitle}</Typography>
      <Typography className={classes.info}>{NoCampaignCreatorsStrings.Info}</Typography>
      <Link
        to={getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, id)}
        className={classes.addCustomerButton}
      >
        {NoCampaignCreatorsStrings.AddCustomers}
      </Link>
    </Box>
  );
};

export default NoCreators;
