import { FC, SVGProps } from 'react';
import { theme } from '../../../../theme';

interface Props {}

export const BackgroundIcon: FC<Props> = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="230"
      height="179"
      viewBox="0 0 230 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.2"
        d="M143.411 -79.0028L120.406 -0.191475L101.719 71.5623L47.2825 -24.5658L143.411 -79.0028Z"
        fill={theme.palette.primary.main}
      />
      <path
        opacity="0.2"
        d="M74.5443 23.5747C78.1131 29.8767 80.4057 36.82 81.2912 44.008C82.1767 51.196 81.6377 58.488 79.705 65.4677C77.7724 72.4475 74.4839 78.9782 70.0273 84.687C65.5707 90.3958 60.0334 95.1709 53.7314 98.7397C47.4294 102.309 40.4861 104.601 33.2981 105.487C26.1101 106.372 18.8181 105.833 11.8384 103.9C4.85866 101.968 -1.67204 98.6792 -7.38085 94.2226C-13.0897 89.7661 -17.8648 84.2287 -21.4336 77.9267L26.5554 50.7507L74.5443 23.5747Z"
        fill="#1B60BA"
      />
      <path
        opacity="0.6"
        d="M-48.4935 30.1448C-44.9247 36.4468 -40.1496 41.9842 -34.4408 46.4407C-28.7319 50.8973 -22.2012 54.1858 -15.2215 56.1185C-8.24181 58.0511 -0.949773 58.5901 6.23824 57.7047C13.4263 56.8192 20.3695 54.5266 26.6715 50.9578C32.9735 47.389 38.5108 42.6139 42.9674 36.905C47.424 31.1962 50.7125 24.6655 52.6451 17.6858C54.5778 10.7061 55.1168 3.41406 54.2313 -3.77395C53.3458 -10.962 51.0532 -17.9052 47.4844 -24.2072L-0.504531 2.96881L-48.4935 30.1448Z"
        fill="#FDB927"
      />
    </svg>
  );
};
