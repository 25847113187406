import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useQRSectionStyles = makeStyles((theme: Theme) => ({
  SectionTitle: {
    paddingTop: '25px',
    paddingBottom: '25px',
    fontSize: '18px',
    fontWeight: '500',
    letterSpacing: '0.02em',
    color: theme.palette.common.deepDark,
  },
  DownloadQRCodeBtn: {
    '& >div': {
      padding: 0,
      alignItems: 'flex-start',
      '& button': {
        marginTop: '0px',
      },
      '& >div': {
        height: '0px !important',
        '& svg': {
          opacity: 0,
        },
      },
    },
  },
  container: {
    marginBottom: '40px',
  },
  contentWrapper: {
    background: '#fff',
    height: 'calc(100vh - 170px)',
    padding: '20px 30px',
  },
  text: {
    color: theme.palette.common.gray,
    paddingBottom: '20px',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.02em',
  },

  qrContainerWrapper: {
    marginTop: '0',
    borderRadius: '15px',
    background: '#fff',
    width: '433px',
    height: '100%',
    padding: '15px 0 35px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrBox: {
    height: '330px',
    width: '330px',
    borderRadius: '12px',
    backgroundSize: '90% !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center !important',
  },
  hover: {
    color: theme.palette.common.white,

    '&:hover': {
      color: theme.palette.primary.main,
      '&>.MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  formGroup: {
    paddingTop: '10px',
  },
  downloadBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  downloadBtn: {
    borderRadius: '100px',
    minWidth: '220px',
    marginTop: '10px',
    '& > .MuiTypography-root': {
      fontWeight: 600,
      fontSize: '15px',
    },
  },
  createBtn: {
    borderRadius: '100px',
    background: theme.palette.primary.light,
    color: theme.palette.primary.main,
    minWidth: '220px',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
    },
  },
  linkInput: {
    marginTop: '4px',
    marginBottom: '29px',
    '& input': {
      background: '#F5F6F8',
      borderRadius: '8px',
      color: theme.palette.common.black,
    },
    '& fieldset': {
      border: 'none',
    },
  },
  linkBox: {},
  uploadWrapper: {
    marginTop: '4px',
    marginBottom: '20px',
  },
  inputWrapper: {
    position: 'relative',
    maxWidth: '190px',
  },
  input: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    opacity: 0,
    display: 'none',
  },
  btnContained: {
    flexShrink: 0,
    flexGrow: 0,
    borderRadius: '30px',
    background: '#45A1F8',
    color: 'white',
    minWidth: '148px',
    marginBottom: '5px',
    height: '42px',
    '&:focus': {
      outline: 0,
    },

    '&:hover': {
      opacity: 0.9,
      background: '#45A1F8',
    },
  },
  btnOutlined: {
    flexShrink: 0,
    flexGrow: 0,
    border: '1px solid #45A1F8',
    color: '#45A1F8',
    marginBottom: '4px',
    borderRadius: '30px',
    minWidth: '148px',
    height: '42px',
    '&:focus': {
      outline: 0,
    },
  },
  imageWrapper: {
    borderRadius: '10px',
    border: `1px solid ${theme.palette.common.imageSelectorBackground}`,
    backgroundColor: theme.palette.common.imageSelectorBackground,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '70px',
    height: '70px',
    marginRight: '25px',
  },
  image: {
    width: '80px',
    height: '80px',
    padding: 5,
    objectFit: 'contain',
  },
  label: {
    color: theme.palette.common.deepDark,
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    paddingBottom: '6px',
  },
  topbarWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 112,
    bottom: -20,
    right: 0,
    width: '90%',
    position: 'fixed',
    backgroundColor: '#fff',
    padding: '24px 58px 36px 58px',
    zIndex: 100,
    background: theme.palette.common.white,
    flexDirection: 'column',
    alignItems: 'end',
  },

  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '14px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  loaderWrapper: {
    width: '100%',
    height: '200px',
    paddingTop: '50px',
    display: 'flex',
    justifyContent: 'center',
  },
}));
