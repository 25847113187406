import styled from 'styled-components';

export const MediaWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const WelcomePopupImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  border-radius: var(--iphoneXborderRadius) var(--iphoneXborderRadius) 0 0;
  object-position: bottom;
`;

export const WelcomePopupVideo = styled.video`
  position: relative;
  max-width: 100%;
  object-fit: contain;
  height: auto;
  background-color: var(--black);
  border-radius: var(--iphoneXborderRadius) var(--iphoneXborderRadius) 0px 0px;
`;
export const WelcomePopupCTAContainer = styled.div`
  margin-top: auto;
  padding: 12px;
`;

interface WPTitleProps {
  color: string;
}

export const WPTitle = styled.span<WPTitleProps>`
  color: ${(props) => props.color || 'var(--white)'};
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
`;

export const WPTitleWrapper = styled.div`
  padding-left: 5px;
`;

export const Dot = styled.div<WPTitleProps>`
  display: inline-block;
  background-color: ${(props) => props.color || 'var(--white)'};
  color: ${(props) => props.color || 'var(--white)'};
  border-radius: 50%;
  height: 5px;
  width: 5px;
  margin-right: 10px;
`;

export const BulletPoint = styled.span<WPTitleProps>`
  color: ${(props) => props.color || 'var(--white)'};
  font-weight: 100;
  font-size: 10px;
  line-h
  eight: 10px;
`;
export const TOSLink = styled.span<WPTitleProps>`
  color: ${(props) => props.color || 'var(--white)'};
  font-weight: 100;
  font-size: 6px;
  line-height: 5px;
`;
interface ButtonProps {
  brandColor?: string;
  textColor?: string;
}

export const BrandButton = styled.div<ButtonProps>`
  display: block;
  background-color: ${(props) => props.brandColor || 'var(--black)'};
  color: ${(props) => props.textColor || 'var(--black)'};
  height: 25px;
  width: 100%;
  border-radius: 6px;
  font-weight: 600;
  font-size: 10px;
  line-height: 25px;
  text-align: center;
`;

export const LogoSection = styled.img`
  padding: 15px;
  width: 60px;
  height: 60px;
  object-fit: contain;
  z-index: 10;
`;
