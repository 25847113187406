import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '738px',
    display: 'flex',
  },
  leftSideWrapper: {
    width: '438px',
    paddingTop: '10px',
  },
  rightSideWrapper: {
    width: '300px',
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '72px',
    letterSpacing: '-0.6px',
  },
  descrText: {
    color: '#475467',
    fontFamily: 'Poppins',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    width: '425px',
  },
  settingText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#101828',
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: '24px',
  },
  tooltipWrapper: {
    width: '250px',
  },
  tooltipList: {
    listStyleType: 'disk',
    listStylePosition: 'inside',
  },
  tooltipItem: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#667085',
    lineHeight: '140%',
    padding: 0,
    listStyleType: 'disc',
    display: 'list-item',
    marginLeft: '5px',
  },
  tooltipTitle: {
    fontSize: '12px',
    fontWeight: 600,
    color: '#667085',
  },
  defaultWrapper: {
    marginTop: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  defaultText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '18px',
    fontWeight: 600,
  },
  defaultVideoText: {
    color: '#475467',
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '20px',
    fontWeight: 400,
    width: '380px',
    marginTop: '6px',
  },
  customizeButton: {
    fontFamily: 'Inter',
    width: '116px',
    height: '40px',
    padding: '0',
    borderRadius: '8px',
    marginTop: '24px',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'scale(0.99)',
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.main,
    },

    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  howAppearText: {
    color: '#475467',
    fontSize: '13px',
    fontFamily: 'Inter',
    lineHeight: '18px',
    fontWeight: 400,
    width: '290px',
    textAlign: 'center',
  },
  iphonePreview: {
    transform: 'scale(0.8)',
    marginTop: '-50px',
  },
}));
