import React, { FC, useEffect, useState } from 'react';
import { AccountAttributeName } from '../../../api/models/searchFilterModels';
import { useStyles } from './PricingPlanFilterSelect.styles';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { ExpandIcon } from '../../assets/ExpandIcon';
import { ClipsSearchStrings } from '../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { removeVideosStatusFilter } from '../../../store/slices/searchFilterSlice';
import { getPricingPlansByDistributorIds } from '../../../store/slices/pricingPlansSlice';
import { PricingPlanModel } from '../../../api/models/pricingPlans';

interface Props {
  onChange: (status: string | null) => void;
}

export const PricingPlanFilterSelect: FC<Props> = ({ onChange }) => {
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const { activeAccountFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  const { pricingPlans } = useTypedSelector((state) => state.pricingPlans);
  const { activeDistributor } = useTypedSelector((state) => state.distributors);

  useEffect(() => {
    dispatch(
      getPricingPlansByDistributorIds({
        distributorIds: activeDistributor ? [activeDistributor.id] : [''],
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusItems = pricingPlans.map((item: PricingPlanModel) => {
    return {
      title: item.name,
      status: item.id,
      icon: <></>,
    };
  });
  // const statusItems = [
  //   {
  //     title: ClipsSearchStrings.Published,
  //     status: VideoStatusType.Published,
  //     icon: <PublishedIcon />,
  //   },
  //   {
  //     title: ClipsSearchStrings.Unpublished,
  //     status: VideoStatusType.Unpublished,
  //     icon: <UnpublishedIcon />,
  //   },
  //   {
  //     title: ClipsSearchStrings.Moderated,
  //     status: VideoStatusType.Moderated,
  //     icon: <ModeratedIcon />,
  //   },
  //   {
  //     title: ClipsSearchStrings.PendingModeration,
  //     status: VideoStatusType.PendingModeration,
  //     icon: <PendingModerationIcon />,
  //   },
  // ];

  useEffect(() => {
    const appliedAttribute = activeAccountFilterAttributes.find(
      (attribute) => attribute.name === AccountAttributeName.PlanId,
    );

    if (appliedAttribute) {
      setSelectedItemIndex(statusItems.findIndex((item) => item.status === appliedAttribute.value));
      onChange(appliedAttribute.value as string);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountFilterAttributes]);

  return (
    <Box className={classes.container}>
      {selectedItemIndex > -1 && (
        <Button
          className={classes.clearButton}
          onClick={() => {
            setSelectedItemIndex(-1);
            onChange(null);
            dispatch(removeVideosStatusFilter());
          }}
        >
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        displayEmpty={true}
        disabled={!pricingPlans.length}
        renderValue={() => {
          if (selectedItemIndex > -1) {
            return (
              <Typography className={classes.selectedItemText} display="inline">
                <Box className={classes.selectedItemIcon}>
                  {statusItems[selectedItemIndex].icon}
                </Box>
                {statusItems[selectedItemIndex].title}
              </Typography>
            );
          } else {
            return (
              <Typography className={classes.selectedItemText}>
                {ClipsSearchStrings.Select}
              </Typography>
            );
          }
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
            maxHeight: '576px',

            zIndex: 10,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
            },
          },
        }}
        sx={{
          width: '100%',
          border: '1px solid #cacae4',
          paddingRight: 0,
          overflow: 'scroll',

          '& .MuiSelect-select': {
            padding:
              selectedItemIndex > -1 ? '3px 0 2px 16px !important' : '8px 0 8px 16px !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none!important',
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: 'none!important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        {statusItems.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                setSelectedItemIndex(index);
                onChange(item.status);
              }}
              className={classes.menuItem}
              key={item.title}
            >
              <Box className={classes.menuIcon}>{item.icon}</Box>
              <Typography className={classes.menuItemText}>{item.title}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
