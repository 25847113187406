import { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

interface UseCopyToClipboardOptions {
  resetStateAfterMS?: number;
}

const defaultOptions = {
  resetStateAfterMS: 2500,
};

export const useCopyToClipboard = (options?: UseCopyToClipboardOptions) => {
  const { resetStateAfterMS } = { ...defaultOptions, ...options };

  const { addToast } = useToasts();
  const [isCopied, setIsCopied] = useState(false);

  const timeoutRef = useRef<number | null>(null);

  const copyToClipboard = (value: string, showToast = true) => {
    navigator.clipboard.writeText(value);
    if (showToast) {
      addToast('Copied successfully', { appearance: 'success' });
    }
    setIsCopied(true);

    timeoutRef.current = setTimeout(() => {
      setIsCopied(false);
    }, resetStateAfterMS);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return { copyToClipboard, isCopied };
};
