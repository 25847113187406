import React from 'react';

const ModeratedIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 7.5V11.6667"
        stroke="#FC4740"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 17.8415H4.95023C2.05856 17.8415 0.850229 15.7748 2.25023 13.2498L4.85023 8.56651L7.30023 4.16651C8.78356 1.49151 11.2169 1.49151 12.7002 4.16651L15.1502 8.57484L17.7502 13.2582C19.1502 15.7832 17.9336 17.8498 15.0502 17.8498H10.0002V17.8415Z"
        stroke="#FC4740"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99585 14.1668H10.0033"
        stroke="#FC4740"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ModeratedIcon;
