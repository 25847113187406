import { parse, isValid } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as Yup from 'yup';
import { CommonStrings } from '../../../localization/en';
export const useStyles = makeStyles<
  Theme,
  { smsInvitesLayout?: boolean; quickStartLayout?: boolean }
>((theme: Theme) => ({
  root: {
    padding: '15px 0 20px',
  },
  title: {
    fontWeight: 500,
    fontSize: ({ quickStartLayout }) => (quickStartLayout ? '16px' : '21px'),
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.deepDark,
    paddingLeft: ({ quickStartLayout }) => (quickStartLayout ? '20px' : '0'),
  },
  matchTitle: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  matchSubTitle: {
    marginTop: '6px',
    maxWidth: '700px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: '#626C74',
    letterSpacing: '0.02em',
  },
  infoIcon: {
    marginRight: '5px',
  },
  label: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '26px',
    marginBottom: '-6px',
  },
  matchProperties: {
    paddingLeft: '20px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#1A1538',
    marginTop: '8px',
  },
  matchSection: {
    background: ({ smsInvitesLayout }) =>
      smsInvitesLayout ? 'transparent' : theme.palette.common.white,
    borderRadius: ({ quickStartLayout }) => (quickStartLayout ? '20px' : '12px 12px 0px 0px'),
    padding: ({ quickStartLayout }) => (quickStartLayout ? '15px 0 0 0' : '24px 49px 20px 38px'),
  },
  quickStartMatchSection: {
    background: theme.palette.common.white,
    borderRadius: '12px 12px 0px 0px',
    padding: '15px 0 27px 0',
  },
  matchForm: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',

    marginTop: ({ quickStartLayout }) => (quickStartLayout ? '7px' : '20px'),
    padding: ({ quickStartLayout }) => (quickStartLayout ? '0 28px 22px 33px' : '45px 61px 22px'),

    background: ({ smsInvitesLayout, quickStartLayout }) =>
      smsInvitesLayout || quickStartLayout
        ? theme.palette.common.white
        : theme.palette.common.pageBackground,
    border: ({ smsInvitesLayout, quickStartLayout }) =>
      smsInvitesLayout || quickStartLayout ? 'none' : '1px solid #E6E9ED',
    borderRadius: ({ smsInvitesLayout, quickStartLayout }) =>
      smsInvitesLayout || quickStartLayout ? '20px' : '5px',
  },
  colTitle: {
    fontWeight: ({ quickStartLayout }) => (quickStartLayout ? 500 : 600),
    fontSize: ({ quickStartLayout }) => (quickStartLayout ? '14px' : '20px'),
    color: ({ quickStartLayout }) => (quickStartLayout ? '#8B89A0' : 'auto'),
    lineHeight: '26px',
  },
  input: {
    background: theme.palette.common.white,
    color: ({ quickStartLayout }) => (quickStartLayout ? '#1A1538' : 'auto'),

    borderRadius: '5px !important',
    '& >div': {
      borderRadius: '5px !important',
      fontWeight: ({ quickStartLayout }) => (quickStartLayout ? 700 : 'auto'),
    },

    '& .MuiOutlinedInput-input': {
      fontWeight: ({ quickStartLayout }) => (quickStartLayout ? 700 : 'auto'),
      '-webkit-text-fill-color': ({ quickStartLayout }) => (quickStartLayout ? '#1A1538' : 'auto'),
    },
    marginBottom: ({ smsInvitesLayout }) => (smsInvitesLayout ? '10px' : 0),
  },
  formControl: {
    '& p': {
      marginLeft: '-2px',
      width: 'max-content',
    },
  },
  error: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: ({ smsInvitesLayout }) => (smsInvitesLayout ? '-12px' : '-21px'),
    color: theme.palette.error.main,
    fontSize: '12px',
    lineHeight: '26px',
  },
  modalRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalTitle: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '30px',
    width: '270px',
  },
  description: {
    fontSize: '12px',
    color: '#8B89A0',
    marginTop: '15px',
  },
  modalLabel: {
    marginTop: '38px',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  modalLabelLimit: {
    padding: '6px 20px',
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    background: '#FCFAFF',
    color: theme.palette.primary.main,
    border: '1px solid #C8C2FF',
    borderRadius: '8px',
  },
  modalInfo: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    marginTop: '24px',
  },
  modalAgreement: {
    '& p': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
      letterSpacing: '0.02em',
      color: theme.palette.common.gray,
    },
  },
  modalBtn: {
    borderRadius: '8px',
    marginTop: '24px',
    width: '60%',
    background: `${theme.palette.primary.main} !important`,
  },
  number: {
    fontWeight: 700,
    textDecorationLine: 'underline',
    marginRight: '5px',
  },
  agreementWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '475px',
  },
  agreementText: {
    fontSize: '12px',
    color: '#8B89A0',
    marginLeft: '5px',
  },
  btnBack: {
    borderRadius: '8px',
    width: '45px !important',
    height: '45px !important',
    background: theme.palette.primary.main,
    minWidth: 'auto',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      background: 'rgb(22, 62, 192)',
    },
    '& span': {
      marginRight: 0,
    },
  },
}));

export const MAX_LENGTH = 42;

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(4, 'Name must be at least 4 char length')
      .max(MAX_LENGTH, CommonStrings.ExceededCharLimit)
      .required('Name is required field'),
    agreement: Yup.boolean().oneOf([true], 'You must check').required(),
  });

export const stringIsDate = (date: string) => {
  try {
    const parsedDate = parse(date, 'MM/dd/yyyy', new Date(), { locale: enGB });

    const isValidDate = isValid(parsedDate);
    return isValidDate;
  } catch (error) {
    return false;
  }
};

export const stringIsEmail = (email: string) =>
  email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

export const stringIsCell = (cell: string) =>
  cell.match(
    // eslint-disable-next-line no-useless-escape
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
  );
