import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#FFF',
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    width: '196px',
    padding: '24px 0 20px 24px',
  },
  socialVLogo: {
    clipPath: 'polygon(40% 0, 100% 0, 100% 100%, 10% 100%)',
    transform: 'translateX(-40px)',
    marginLeft: '8px',
  },
  inpartnershipText: {
    marginLeft: '8px',
    marginTop: '2px',
    fontSize: '14px',
    color: 'black',
  },
  divider: {
    width: '100%',
    color: theme.palette.grey[300],
  },
  contentWrapper: {
    display: 'flex',
    padding: '20px 24px 24px 24px',
  },
  sideWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    color: theme.palette.common.deepDark,
    padding: '0 20px',
  },
  info: {
    fontWeigt: 500,
    color: theme.palette.common.gray,
    padding: '15px 20px 0 20px',
  },
  text: {
    color: theme.palette.common.gray600,
    fontSize: '20px',
    padding: '0 20px',
  },
  button: {
    borderRadius: '18px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    justifySelf: 'flex-end',

    '& p': {
      color: theme.palette.primary.main,
      fontWeight: 600,
      fontSize: '14px',
    },
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '12px',
    marginTop: '80px',
    padding: '0 24px 24px 24px',
  },
}));
