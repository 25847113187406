import { FC } from 'react';

export const OptedOutIcon: FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.73268 4.10964L16.516 15.893M18.9577 10.0013C18.9577 14.6037 15.2267 18.3346 10.6243 18.3346C6.02198 18.3346 2.29102 14.6037 2.29102 10.0013C2.29102 5.39893 6.02198 1.66797 10.6243 1.66797C15.2267 1.66797 18.9577 5.39893 18.9577 10.0013Z"
        stroke="#FF0000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
