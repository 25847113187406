import { FC } from 'react';

import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import background from '../../../common/assets/postPrevBack.jpg';
import backgroundGolf from '../../../common/assets/postPrevBackTopGolf.jpg';
import { InfoIcon } from '../../../common/assets/InfoIcon';
import { SettingsPageStrings } from '../../../common/localization/en';
import { LikeIcon } from '../../../common/assets/LikeIcon';
import { MessageIconPreview } from '../../../common/assets/MessageIconPreview';
import { SharesIconPreview } from '../../../common/assets/SharesIconPreview';

export const useShareThumbnailPreviewStyles = makeStyles(() => ({
  Container: {
    width: '500px',
    borderRadius: '8px',
    backgroundColor: theme.palette.common.white,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
    alignItems: 'center',
  },
  Header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 20px 14px 20px',
  },
  postText: {
    fontSize: '12px',
    color: theme.palette.common.black,
    padding: '0 20px 14px 20px',
    width: '100%',
  },
  LogoContainer: {
    display: 'flex',
  },
  incentiveImg: {
    marginRight: '10px',
    borderRadius: '9px',
    objectFit: 'contain',
    maxHeight: '36px',
    width: '36px',
  },
  Venue: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginRight: '8px',
  },
  Date: {
    fontWeight: 200,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  VenueIcon: {
    display: 'flex',
  },
  CheckedIcon: {
    width: '12px',
    height: '12px',
    color: '#5B85F0',
  },
  Image: {
    position: 'relative',
  },
  background: {
    //
  },
  InfoIcon: {
    position: 'absolute',
    top: 'calc(100% - 12px)',
    left: 'calc(100% - 34px)',
  },
  URL: {
    padding: '30px 20px 20px',
    backgroundColor: '#F0F2F5',
    width: '100%',
  },
  URLText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    textTransform: 'uppercase',
  },
  BottomBar: {
    width: '100%',
    padding: '20px 20px 20px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  LikeComment: {
    display: 'flex',
    alignItems: 'center',
  },
  IconComment: {
    display: 'flex',
    alignItems: 'center',
  },
  IconText: {
    marginLeft: '10px',
    marginRight: '10px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
}));

interface IShareThumbnailPreviewProps {
  logo?: string;
  venue?: string;
  url?: string;
  isTopGolf: boolean;
  postText?: string;
}

export const ShareThumbnailPreview: FC<IShareThumbnailPreviewProps> = ({
  logo,
  venue,
  url,
  isTopGolf,
  postText,
}) => {
  const styles = useShareThumbnailPreviewStyles();
  return (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <div className={styles.LogoContainer}>
          <div className={''}>
            {logo && <img className={styles.incentiveImg} src={logo} alt={'logo'} />}
          </div>
          <div>
            <div className={styles.VenueIcon}>
              <p className={styles.Venue}>{venue}</p>
              <div className={styles.CheckedIcon}>
                <CheckCircleIcon color="inherit" fontSize="inherit" />
              </div>
            </div>
            <p className={styles.Date}>Today</p>
          </div>
        </div>
        <MoreHorizIcon />
      </div>
      {postText && <div className={styles.postText}>{postText}</div>}
      <div className={styles.Image}>
        <img
          className={styles.background}
          src={!isTopGolf ? background : backgroundGolf}
          alt={'background'}
        />
        <div className={styles.InfoIcon}>
          <InfoIcon />
        </div>
      </div>
      <div className={styles.URL}>
        <p className={styles.URLText}>{`${url}${SettingsPageStrings.DomainName}`}</p>
      </div>
      <div className={styles.BottomBar}>
        <div className={styles.LikeComment}>
          <div className={styles.IconComment}>
            <LikeIcon /> <span className={styles.IconText}>Like</span>
          </div>
          <div className={styles.IconComment}>
            <MessageIconPreview /> <span className={styles.IconText}>Comment</span>
          </div>
        </div>
        <div className={styles.IconComment}>
          <SharesIconPreview /> <span className={styles.IconText}>Share</span>
        </div>
      </div>
    </div>
  );
};
