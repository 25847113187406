import React, { useState } from 'react';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import { useStyles } from '../CreatorsSearchTags.styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { Box } from '@mui/material';
import { SearchTagsStrings } from '../../../localization/en';
import MenuContainer from '../../MenuContainer/MenuContainer';
import {
  CreatorAttributeName,
  CreatorFilterAttribute,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import {
  removeCreatorRewardedFilter,
  setActiveCreatorFilterAttributes,
  updateCreatorRewardedFilter,
} from '../../../../store/slices/searchFilterSlice';

const RewardedTag = () => {
  const styles = useStyles();

  const dispatch = useAppDispatch();

  const { activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const rewardedFilterIdx = activeCreatorFilterAttributes.findIndex(
    (item) => item.name === CreatorAttributeName.RewardsFulfilled,
  );

  const [rewardedAnchorEl, setRewardedAnchorEl] = useState<null | HTMLElement>(null);
  const isRewardedMenuOpen = Boolean(rewardedAnchorEl);
  const handleRewardedMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setRewardedAnchorEl(event.currentTarget);
  };

  const handleRewardedMenuClose = () => {
    setRewardedAnchorEl(null);
  };

  const handleRewardedChange = (value: 'Yes' | 'No') => {
    if (rewardedFilterIdx === -1) {
      const filter: CreatorFilterAttribute =
        value === 'Yes'
          ? {
              name: CreatorAttributeName.RewardsFulfilled,
              operation: SearchOperationType.GreaterThan,
              value: 0,
            }
          : {
              name: CreatorAttributeName.RewardsFulfilled,
              operation: SearchOperationType.LessThan,
              value: 1,
            };

      dispatch(setActiveCreatorFilterAttributes([...activeCreatorFilterAttributes, filter]));

      return;
    }

    dispatch(updateCreatorRewardedFilter(value));
  };

  const handleRemoveRewardedFilter = () => {
    dispatch(removeCreatorRewardedFilter());
  };

  const tagText =
    rewardedFilterIdx !== -1
      ? activeCreatorFilterAttributes[rewardedFilterIdx].operation ===
        SearchOperationType.GreaterThan
        ? 'Yes'
        : 'No'
      : '';

  return (
    <>
      <Box
        className={`${styles.tag} ${rewardedFilterIdx !== -1 && styles.active}`}
        onClick={handleRewardedMenuOpen}
      >
        {SearchTagsStrings.Rewarded}: {tagText}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (rewardedFilterIdx !== -1) {
              e.stopPropagation();
              handleRemoveRewardedFilter();
            }
          }}
        >
          {rewardedFilterIdx !== -1 ? <PurpleCloseIcon /> : <SmallArrowIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={rewardedAnchorEl}
        isOpen={isRewardedMenuOpen}
        onClose={handleRewardedMenuClose}
        padding="4px 0"
      >
        <Box
          className={styles.verification}
          onClick={() => {
            handleRewardedChange('Yes');
            handleRewardedMenuClose();
          }}
        >
          {SearchTagsStrings.Yes}
        </Box>
        <Box
          className={styles.verification}
          onClick={() => {
            handleRewardedChange('No');
            handleRewardedMenuClose();
          }}
        >
          {SearchTagsStrings.No}
        </Box>
      </MenuContainer>
    </>
  );
};

export default RewardedTag;
