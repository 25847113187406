import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: () => ({}),
  tab: {
    padding: '0 12px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    minWidth: 'fit-content',
    width: '100%',
    justifyContent: 'space-between',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    '&:not(:first-child)': {
      marginTop: '18px',
    },
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
  },
  tabName: {
    fontSize: '15px !important',
    fontWeight: '500',
    userSelect: 'none',
    minWidth: 'fit-content',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: '#667085',
  },
  amount: {
    marginLeft: '.5em',
    height: '22px',
    borderRadius: '8px',
    background: '#F2F4F7',
    color: '#344054',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    width: 'fit-content',
    fontWeight: '600',
    fontSize: '12px',
  },
}));
