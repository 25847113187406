import { FC, ReactNode } from 'react';

import { SideBar } from './sideBar/SideBar';
import { Container, ContentContainer } from './Layout.style';
import { LAYOUT_CONTAINER_CLASSNAME } from '../constants/constants';
import { useAppDispatch, useTypedSelector } from '../../store';
import { setIsMenuExpanded } from '../../store/slices/uiSlice';
import { AccountsSidebar } from './AccountsSidebar/AccountsSidebar';
import { WebsiteRoutes } from '../constants/routes';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isTabletView, isMenuExpanded } = useTypedSelector((state) => state.ui);
  const { pathname } = useLocation();
  const isAccountsPage = pathname === WebsiteRoutes.ProfileAccounts;

  return (
    <Container>
      {isAccountsPage ? <AccountsSidebar /> : <SideBar />}
      <ContentContainer
        className={LAYOUT_CONTAINER_CLASSNAME}
        style={{
          paddingLeft: isTabletView && !isAccountsPage ? '65px' : '',
        }}
        onMouseOver={() => {
          if (isTabletView && isMenuExpanded) {
            dispatch(setIsMenuExpanded(false));
          }
        }}
      >
        {children}
      </ContentContainer>
    </Container>
  );
};
