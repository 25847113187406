import React, { FC, useRef } from 'react';
import { Button, CircularProgress } from '@mui/material';
import uploadVideo from '../../../../common/assets/UploadAdImg.png';
import { makeStyles } from '@mui/styles';
import { RewardAdStrings } from '../../../../common/localization/en';
import { useUploadFileToStorage } from '../../../../hooks/useUploadFileToStorage';

const useStyles = makeStyles(() => ({
  uploadImage: {
    width: 230,
    height: 230,
    objectFit: 'cover',
    borderRadius: 16,
  },
  uploadButton: {
    margin: '20px 0 15px 0',
    backgroundColor: 'rgb(219, 239, 255)',
    width: '100%',
  },
  isDisabled: {
    opacity: '.5',
  },
}));

interface UploadButtonProps {
  style?: string;
  image: string;
  setImage: (image: string) => void;
  isActiveAd: boolean;
}

export const UploadButton: FC<UploadButtonProps> = ({ style, image, setImage, isActiveAd }) => {
  const styles = useStyles();
  const imageRef = useRef<any>();

  const { loading: isLogoUploading, uploadFileToStorage } = useUploadFileToStorage();

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const res = await uploadFileToStorage(file);
    if (res) {
      setImage(res.downloadUrl);
    }
  };

  return (
    <>
      <div className={style}>
        <img
          src={image || uploadVideo}
          alt="Upload"
          onClick={() => {
            if (imageRef && imageRef.current) {
              imageRef.current.click();
            }
          }}
          className={[styles.uploadImage, isActiveAd ? '' : styles.isDisabled].join(' ')}
        />
        {isLogoUploading && (
          <CircularProgress
            style={{
              width: 30,
              height: 30,
              position: 'absolute',
              top: 'calc(50% - 15px)',
              left: 'calc(50% - 15px)',
            }}
            color="secondary"
          />
        )}
      </div>
      <input
        style={{ display: 'none' }}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        onChange={handleFileChange}
        ref={imageRef}
        id="select-image"
        disabled={!isActiveAd}
        required
      />
      <label htmlFor="select-image">
        <Button
          disabled={!isActiveAd}
          className={styles.uploadButton}
          color="primary"
          component="span"
        >
          {RewardAdStrings.UploadButton}
        </Button>
      </label>
    </>
  );
};
