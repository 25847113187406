import { Box, Divider, IconButton, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { FSModal } from '../modal/Modal';
import { useStyles } from './ConfirmSyncModal.styles';
import { useTypedSelector } from '../../../store';
import { RoundedButton } from '../Button/RoundedButton';
import { DistributorsStrings } from '../../localization/en';
import { CloseIcon } from '../../assets/CloseIcon';
import { SvToggle, SvToggleItem } from './SelectItem/SvToggle';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  url: string;
  svToggleItems: SvToggleItem[];
  setSvToggleItems: Dispatch<SetStateAction<SvToggleItem[]>>;
}

export const ConfirmSyncModal: FC<Props> = ({
  isOpen,
  onClose,
  onConfirm,
  url,
  svToggleItems,
  setSvToggleItems,
}) => {
  const styles = useStyles();
  const { name } = useTypedSelector((state) => state.me);
  const { name: businessName } = useTypedSelector((state) => state.venue.venue);
  const isLiveSvPresent = svToggleItems.length > 0;

  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width={isLiveSvPresent ? '525px' : '450px'}
      padding="0 5px"
    >
      <Box className={styles.iconWrapper}>
        <IconButton onClick={onClose} className={styles.closeIcon}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={styles.container}>
        <img
          src="/GoogleProfileLarge.png"
          className={styles.googleProfileIcon}
          alt="google icon"
          style={{
            marginBottom: isLiveSvPresent ? '0' : '15px',
          }}
        />
        {isLiveSvPresent && (
          <Typography className={styles.syncVideosText}>
            {DistributorsStrings.SyncExisting}
          </Typography>
        )}
        <Typography className={styles.title}>{`Hello ${name.split(' ')[0]},`}</Typography>
        <Typography
          className={styles.title}
        >{` To add clips to ${businessName}'s ${DistributorsStrings.ConcentGoogle}`}</Typography>

        {isLiveSvPresent && (
          <>
            <Divider sx={{ marginTop: '15px !important' }} className={styles.divider} />
            <Typography className={styles.selectSvText}>{DistributorsStrings.SelectSv}</Typography>
            <Box className={styles.selectWrapper}>
              {svToggleItems.map((item, index) => {
                const isLastItem = index === svToggleItems.length - 1;
                return (
                  <>
                    <Box mb={isLastItem ? '0' : '8px'}>
                      <SvToggle
                        toggleItem={item}
                        toggleItemIndex={index}
                        setToggleItems={setSvToggleItems}
                      />
                    </Box>
                    {!isLastItem && <Divider sx={{ marginBottom: '8px' }} />}
                  </>
                );
              })}
            </Box>
            <Typography className={styles.deselectedText}>
              {DistributorsStrings.DeselectedSv}
            </Typography>
          </>
        )}
        <Typography className={styles.description}>{DistributorsStrings.VideosSync}</Typography>

        <RoundedButton
          href={url}
          title="I Agree"
          className={styles.btn}
          onClick={() => {
            onConfirm?.();
          }}
        />
      </Box>
    </FSModal>
  );
};
