import { Grid, Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FC } from 'react';

interface StyleProps {
  backgroundColor?: string;
  activeBackgroundColor?: string;
  activeTextColor?: string;
  disabledTextColor?: string;
  gapSize?: number | string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  Root: {
    background: (props) => props.backgroundColor || '#F5F6F8',
    borderRadius: '40px',
    padding: '5px',
    width: 'fit-content',
    gap: (props) => props.gapSize || '7px',
    margin: '0 auto',
    userSelect: 'none',
  },
  Title: {
    padding: '3px 15px',
    borderRadius: '400px',
    fontSize: '12px',
    lineHeight: '24px',
    cursor: 'pointer',
  },
  Active: {
    background: (props) => props.activeBackgroundColor || theme.palette.common.white,
    boxShadow: '0px 18px 30px rgba(64, 77, 104, 0.1)',
    color: (props) => props.activeTextColor || theme.palette.text.primary,
  },
  Disabled: {
    color: (props) => props.disabledTextColor || theme.palette.text.disabled,
    cursor: 'not-allowed',
  },
}));

interface ToggleItem {
  id?: string | number;
  title: string;
  onClick: () => void;
  disabled?: boolean;
}

interface ToggleButtonsProps extends StyleProps {
  items: Array<ToggleItem>;
  selectedItem?: string;
}

export const ToggleButtons: FC<ToggleButtonsProps> = ({
  items,
  selectedItem,
  ...restStyleProps
}) => {
  const styles = useStyles(restStyleProps);

  const handleClick = (item: ToggleItem) => {
    if (item.disabled) return;

    item.onClick();
  };

  return (
    <Grid container className={styles.Root}>
      {items.map((item) => (
        <Typography
          onClick={() => handleClick(item)}
          className={clsx(
            styles.Title,
            !!selectedItem && [item.title, item.id].includes(selectedItem) && styles.Active,
            item.disabled && styles.Disabled,
          )}
          key={`${item.id}${item.title}`}
        >
          {item.title}
        </Typography>
      ))}
    </Grid>
  );
};
