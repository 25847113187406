import { FC, SetStateAction } from 'react';
import { Grid, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import {
  CommonStrings,
  ContactUsString,
  ErrorPageStrings,
} from '../../../../common/localization/en';
import { resetInitiated } from '../../../../store/slices/venueCreationSlice';
import { useAppDispatch } from '../../../../store';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
  },
  contentWrapper: {
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '14px',
    borderRadius: '14px',
    backgroundColor: theme.palette.common.white,
  },
  errorImage: {
    transform: 'scale(1.2)',
    marginBottom: '50px',
    marginTop: '20px',
  },
  errorTitle: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: ' 32.02px',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginBottom: '20px',
  },
  errorDescription: {
    fontSize: '16px',
    lineHeight: '150%',
    fontWeight: 400,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  bottomNav: {
    height: '100px',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    backgroundColor: theme.palette.common.white,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    zIndex: 5,
  },
  innerNav: {
    position: 'relative',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    height: '100%',
  },
  button: {
    marginRight: theme.spacing(3),
    width: '220px',
    height: '70px',
  },
  buttonText: {
    color: theme.palette.common.white,
  },
}));

interface WizardFailureProps {
  setShowWizardFailure: (value: SetStateAction<boolean>) => void;
  setShowWizardSuccess: (value: SetStateAction<boolean>) => void;
}

export const WizardFailure: FC<WizardFailureProps> = ({
  setShowWizardFailure,
  setShowWizardSuccess,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const handleBackClick = () => {
    dispatch(resetInitiated());
    setShowWizardFailure(false);
    setShowWizardSuccess(false);
  };
  return (
    <div className={classes.container}>
      <div className={classes.contentWrapper}>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} md={6}>
            <img className={classes.errorImage} src="/error.png" alt="error.png" />
          </Grid>
          <Grid item xs={12} md={12}>
            <p className={classes.errorTitle}>{ErrorPageStrings.CreationFailureTitle}</p>
            <p className={classes.errorDescription}>{ErrorPageStrings.CreationFailureDesc}</p>
          </Grid>
          <Grid item xs={10} md={5}>
            <Button color="primary" fullWidth>
              <a
                href={`mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.EmailSubject}`}
              >
                {ErrorPageStrings.ReportIssue}
              </a>
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.bottomNav}>
        <div className={classes.innerNav}>
          <Button variant="contained" color="primary" className={classes.button}>
            <a
              className={classes.buttonText}
              href={`mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.EmailSubject}`}
            >
              {CommonStrings.EmailSupport}
            </a>
          </Button>
          <Button variant="outlined" className={classes.button} onClick={handleBackClick}>
            {CommonStrings.Back}
          </Button>
        </div>
      </div>
    </div>
  );
};
