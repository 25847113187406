import { ChevronLeftOutlined } from '@mui/icons-material';
import { Box, ClickAwayListener, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ShortcutsMenuStrings } from '../../localization/en';
import { useStyles } from './ShortcutsMenu.styles';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../constants/events/common';
import QuickstartIcon from '../../assets/newDesign/Shortcuts/QuickstartIcon';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';

const ShortcutsMenu = () => {
  const styles = useStyles();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { trackEvent } = useTrackEvent();

  const { startLaunchPadFlow } = useQuickStartLaunchPad();

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    trackEvent(CommonEventNames.shortcuts_menu_open_click);
    setIsMenuOpen(true);
  };

  const handleClose = () => {
    trackEvent(CommonEventNames.shortcuts_menu_close_click);
    setIsMenuOpen(false);
  };

  const handleLanuchPadOpen = async () => {
    await startLaunchPadFlow();
  };

  return (
    <Box className={styles.shortcutsWrapper}>
      <Box className={styles.shrotcutsButton} onClick={handleOpen}>
        {ShortcutsMenuStrings.Shortcuts}{' '}
        <ChevronLeftOutlined
          sx={{
            transform: isMenuOpen ? 'rotate(90deg)' : 'rotate(270deg)',
            transition: '0.11s',
          }}
        />
      </Box>
      {isMenuOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box className={styles.menuWrapper}>
            <Box className={styles.distributionWrapper}>
              <Box className={styles.action} onClick={handleLanuchPadOpen}>
                <QuickstartIcon />
                <Box>
                  <Typography className={styles.actionText}>
                    {ShortcutsMenuStrings.TopicsGuide}
                  </Typography>
                  <Typography className={styles.actionInfo}>
                    {ShortcutsMenuStrings.OurGuide}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default ShortcutsMenu;
