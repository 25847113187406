import React, { useEffect, useState } from 'react';
import { CheckIconGreen } from '../../../../common/assets/newDesign/SocialVerse/CheckIconGreen';
import { HalfFilledCircle } from '../../../../common/assets/newDesign/HalfFilledCircle';
import { MinusCircledIcon } from '../../../../common/assets/newDesign/MinusCircledIcon';
import { Box, Typography } from '@mui/material';
import { theme } from '../../../../theme';
import { useStyles } from './Navigation.styles';

interface NavigationProps {
  step: number;
}

type State = {
  first: VisitedState;
  second: VisitedState;
  third: VisitedState;
};
type VisitedState = 'unvisited' | 'visited' | 'passed';

const defaultsDependingOnStep: { [key: number]: State } = {
  0: {
    first: 'visited',
    second: 'unvisited',
    third: 'unvisited',
  },
  1: {
    first: 'passed',
    second: 'visited',
    third: 'unvisited',
  },
  2: {
    first: 'passed',
    second: 'passed',
    third: 'visited',
  },
  3: {
    first: 'passed',
    second: 'passed',
    third: 'passed',
  },
};

const Navigation: React.FC<NavigationProps> = ({ step }) => {
  const styles = useStyles();
  const [visited, setVisited] = useState<State>({
    first: 'visited',
    second: 'unvisited',
    third: 'unvisited',
  });

  const getIcon = (step: keyof State) => {
    const isVisited = visited[step] === 'visited';
    const isPassed = visited[step] === 'passed';

    if (isVisited) {
      return <HalfFilledCircle color={theme.palette.primary.main} />;
    }
    if (isPassed) {
      return (
        <Box className={styles.icon}>
          {' '}
          <CheckIconGreen />
        </Box>
      );
    }

    return <MinusCircledIcon />;
  };

  useEffect(() => {
    const newState = defaultsDependingOnStep[step];
    setVisited(newState);

    return () => {
      setVisited({
        first: 'visited',
        second: 'unvisited',
        third: 'unvisited',
      });
    };
  }, [step]);

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-even',
        alignItems: 'center',
      }}
    >
      <Box className={styles.item}>
        <Box>{getIcon('first')}</Box>
        <Typography
          className={styles.text}
          style={{ color: step === 0 ? theme.palette.primary.main : '#344054' }}
        >
          Upload XLS(or CSV)
        </Typography>
      </Box>
      <Box className={`${styles.line} ${styles.lineLeft}`} />
      <Box className={styles.item}>
        <Box>{getIcon('second')}</Box>
        <Typography
          className={styles.text}
          style={{ color: step === 1 ? theme.palette.primary.main : '#344054' }}
        >
          Map Fields
        </Typography>
      </Box>
      <Box className={`${styles.line} ${styles.lineRight}`} />
      <Box className={styles.item}>
        <Box>{getIcon('third')}</Box>
        <Typography
          className={styles.text}
          style={{ color: step === 2 ? theme.palette.primary.main : '#344054' }}
        >
          Review & Add
        </Typography>
      </Box>
    </Box>
  );
};

export default Navigation;
