import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useParams, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';

import { InteractionsBlock } from '../NewDetailsModal/DetailsModalContent/components/InteractionsBlock';
import { UserInfoBlock } from '../NewDetailsModal/DetailsModalContent/components/UserInfoBlock';
import { VideosBlock } from './components/VideosBlock';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  approveReward,
  fetchUserActivities,
  fetchUserDetails,
  fetchUserMetrics,
  updateUserDetails,
} from '../../../store/slices/userDetailsSlice';
import { ActiveTab, activeTabsList, useStyles } from './HeroesDetails.helper';
import { RewardsBlock } from './components/RewardsBlock';
import { WebsiteRoutes } from '../../constants/routes';
import { ApproveModal } from '../../../pages/rewards/redeemRequests/ApproveModal/ApproveModal';
import { updateTable } from '../../../store/slices/heroesSlice';
import { segmentActions } from '../../constants/constants';
import { segmentTrackCall } from '../../../utils/segment';

export const HeroesDetails: React.FC = () => {
  const params = useParams<{ id: string }>();
  const match = useRouteMatch();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const [approveModalData, setApproveModalData] = useState<{ id: string; name: string } | null>(
    null,
  );
  const { userDetails, userMetrics, lastUpdated } = useTypedSelector((state) => state.userDetails);

  useEffect(() => {
    segmentTrackCall(segmentActions.HeroesDetails, venueId);
  }, [venueId]);

  const closeModal = () => {
    setApproveModalData(null);
  };

  const handleApproveReward = (id: string) => {
    dispatch(approveReward({ id }));
    setTimeout(() => {
      dispatch(updateTable());
      dispatch(updateUserDetails());
      dispatch(fetchUserDetails(id));
      setApproveModalData(null);
    }, 200);
  };

  let isManageRewardsRedemptionEntry = true;
  if (match.url.includes(WebsiteRoutes.RedeemRequests)) {
    isManageRewardsRedemptionEntry = true;
  }
  const [activeTab, setActiveTab] = useState<ActiveTab>(
    isManageRewardsRedemptionEntry ? ActiveTab.Rewards : ActiveTab.Videos,
  );

  useEffect(() => {
    dispatch(fetchUserMetrics({ id: params.id }));
    dispatch(fetchUserActivities({ id: params.id }));
  }, [dispatch, params.id, lastUpdated]);

  const handleOpenApproveRewardModal = (id: string, name: string) => {
    setApproveModalData({ id, name });
  };

  const getActiveTabMarkerLeftMargin = (): string => {
    switch (activeTab) {
      case ActiveTab.Videos: {
        return '0';
      }
      case ActiveTab.Engagements: {
        return '33%';
      }
      case ActiveTab.Rewards: {
        return '66%';
      }
    }
  };

  const selectActiveTab = (tab: ActiveTab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case ActiveTab.Videos: {
        return <VideosBlock userData={userDetails!} />;
      }
      case ActiveTab.Engagements: {
        return <InteractionsBlock metricsData={userMetrics?.metrics} withHeader={false} />;
      }
      case ActiveTab.Rewards: {
        return (
          <RewardsBlock
            isManageRewardsRedemptionEntry={isManageRewardsRedemptionEntry}
            approveReward={handleOpenApproveRewardModal}
            user={userDetails}
          />
        );
      }
    }
  };
  const VenueActiveTabsList = activeTabsList;

  return (
    <Grid container direction="column">
      <ApproveModal
        handleCloseModal={closeModal}
        isOpenModal={!!approveModalData}
        handleConfirm={handleApproveReward}
        id={approveModalData ? approveModalData.id : ''}
        name={approveModalData ? approveModalData.name : ''}
      />
      <Grid item xs={12}>
        <Box mt={4} />
        <UserInfoBlock userData={userDetails!} />
      </Grid>
      <Grid item xs={12}>
        <Grid container className={classes.tabsContainer}>
          <Box className={classes.tabsRow}>
            {VenueActiveTabsList.map((thisTab) => (
              <Grid item key={thisTab.tab} xs={4} onClick={() => selectActiveTab(thisTab.tab)}>
                <Typography
                  variant="subtitle2"
                  className={clsx({
                    [classes.tab]: true,
                    [classes.tabActiveColor]: thisTab.tab === activeTab,
                  })}
                >
                  {thisTab.name}
                </Typography>
              </Grid>
            ))}
          </Box>
          <Box className={classes.tabCursor} ml={getActiveTabMarkerLeftMargin()} />
        </Grid>
      </Grid>

      <Grid item>{renderTabContent()}</Grid>
    </Grid>
  );
};
