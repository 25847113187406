import { FC } from 'react';
import { Box } from '@mui/material';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';

import { getColorsParams, getInitials } from './iconWithInitials.helper';

import { useStyles } from './iconWithInitials.style';

interface Props {
  name?: string;
  width?: number;
  height?: number;
  index?: number;
}

export const IconWithInitials: FC<Props> = ({ name, width = 35, height = 35, index = 0 }) => {
  const classes = useStyles();
  let initials = getInitials(name);
  const colorsData = getColorsParams(index);

  return (
    <Box
      style={{ width: width + 'px', height: height + 'px', ...colorsData }}
      component="span"
      className={classes.root}
    >
      {initials || <PersonRoundedIcon />}
    </Box>
  );
};
