import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Typography, TypographyProps, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

const SECONDARY_COLOR = '#E6E3FF';

export interface RoundedButtonProps extends LoadingButtonProps {
  title: string;
  secondary?: boolean;
  outlined?: boolean;
  textVariant?: boolean;
  /**
   * error will force outlined state (if not textVariant)
   */
  error?: boolean;
  height?: string;
  fontSize?: TypographyProps['fontSize'];
  fontWeight?: TypographyProps['fontWeight'];
  lineHeight?: TypographyProps['lineHeight'];
  letterSpacing?: TypographyProps['letterSpacing'];
  textProps?: TypographyProps;
  backgroundColor?: string;
  textColor?: string;
  borderRadius?: string;
  hide?: boolean;
  borderWidth?: string;
  padding?: string;
}

export const RoundedButton: React.FC<RoundedButtonProps> = ({
  title,
  secondary,
  outlined,
  textVariant,
  error,
  loading,
  height = '45px',
  fontSize = '14px',
  fontWeight = '400',
  lineHeight = '26px',
  letterSpacing = '0.02em',
  borderWidth = '1px',
  padding = '10px 30px',
  textProps,
  backgroundColor: backgroundColorProp,
  disabled,
  onClick,
  borderRadius = '100px',
  textColor: textColorProp,
  hide,
  sx,
  ...restButtonProps
}) => {
  const theme = useTheme();

  const buttonColor = useMemo<LoadingButtonProps['color']>(() => {
    if (error) return 'error';
    return 'primary';
  }, [error]);

  const buttonVariant = useMemo<LoadingButtonProps['variant']>(() => {
    if (textVariant) return 'text';
    if (error) return 'outlined';
    if (outlined) return 'outlined';
    if (secondary) return 'outlined';

    return 'contained';
  }, [error, outlined, secondary, textVariant]);

  const textColor = useMemo<string>(() => {
    if (disabled) {
      if (outlined) return theme.palette.text.disabled;
      return theme.palette.common.white;
    }
    if (error) return theme.palette.error.main;
    if (textColorProp) return textColorProp;

    if (secondary) return theme.palette.primary.main;
    if (outlined) return theme.palette.primary.main;
    if (textVariant) return theme.palette.primary.main;
    return theme.palette.common.white;
  }, [disabled, error, outlined, secondary, theme, textColorProp, textVariant]);

  const backgroundColor = useMemo(() => {
    if (backgroundColorProp) return backgroundColorProp;
    if (secondary) return SECONDARY_COLOR;
  }, [secondary, backgroundColorProp]);

  const borderColor = useMemo(() => {
    if (secondary) return SECONDARY_COLOR;
  }, [secondary]);

  if (hide) {
    return null;
  }

  return (
    <LoadingButton
      onClick={onClick}
      color={buttonColor}
      variant={buttonVariant}
      sx={{
        borderRadius,
        borderWidth,
        height: height,
        width: restButtonProps.fullWidth ? undefined : 'fit-content',
        padding,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        '&:disabled': {
          background: `${theme.palette.primary.main}4d`,
        },
        '&:hover': {
          borderWidth,
        },
        ...sx,
      }}
      loading={loading}
      disabled={disabled}
      {...restButtonProps}
    >
      <Typography
        color={textColor}
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        width="max-content"
        visibility={loading ? 'hidden' : 'visible'}
        // whiteSpace={'no-wrap'}
        {...textProps}
      >
        {title}
      </Typography>
    </LoadingButton>
  );
};
