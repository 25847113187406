import React from 'react';

import { DataSync } from '../../../../../webappSettings/DataSync/DataSync';

interface ImportExcelProps {}

export const ImportExcel: React.FC<ImportExcelProps> = () => {
  return (
    <DataSync
      hideTabs
      hideImportHistory
      showScheduleDeliveryStep={false}
      showStepper
      autoApprove
      acceptFiletypes={['excel', 'csv']}
      smsInvitesLayout
    />
  );
};
