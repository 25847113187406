import styled from 'styled-components';

export const DragAndDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 180px;
  border: 2px dashed var(--blue);
  border-radius: 14px;
`;

export const UploaderWithoutFiles = styled.label`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const InnerText = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
`;

export const UploadManually = styled.label`
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-size: 14px;
  color: var(--blue);
`;

export const PhotosUploadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
`;

export const PhotoContainer = styled.div`
  display: flex;
`;

export const SelectedImage = styled.img`
  width: 38px;
  height: 38px;
`;

export const SelectedImageName = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 16px;
  width: 200px;

  p:nth-child(1) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    font-weight: bold;
  }
  p:nth-child(2) {
    font-size: 8px;
    font-weight: normal;
  }
`;

interface CheckIconProps {
  error: boolean;
}
export const CheckIcon = styled.div<CheckIconProps>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: ${(props) => (props.error ? 'var(--activeColor)' : 'var(--lightBlue)')};
  width: 38px;
  height: 38px;
`;
