import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  iconWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '25px',
  },
  root: {
    padding: 0,
    borderRadius: 30,
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  wrapperEducationSection: {
    padding: '100px 8px',
    background: '#F5F6F8',
  },
  wrapperEducationCards: {
    background: '#F5F6F8',
    height: '100%',
    padding: '90px 0 90px 0',
  },
  wrapperMainContent: {
    background: theme.palette.common.white,
    padding: 0,
    height: '100%',
  },
  title: {
    color: '#1A1538',
    marginTop: '15px',
    fontSize: '18px',
    lineHeight: '150%',
    fontWeight: '600',
    textAlign: 'center',
    letterSpacing: '0.36px',
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '150%',
    color: '#667085',
    textAlign: 'center',
    letterSpacing: '0.24px',
    marginTop: '5px',
  },

  svNameInput: {
    width: '360px',
    height: '50px',
    marginTop: '18px',
    '& input': {
      textAlign: 'center',
      borderRadius: '10px',
      fontSize: '18px',
      height: '32px',
      fontWeight: 500,
      color: '#667085',
    },
  },

  paper: {
    maxWidth: '520px',
    width: '100%',
    borderRadius: 30,
    overflow: 'visible',
  },
  close: {
    position: 'absolute',
    top: '-10px',
    right: '-7px',
    width: 50,
    height: 50,
    background: theme.palette.common.white,
    color: theme.palette.common.deepDark,
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '15px',
    marginBottom: '40px',
  },
  buttonConfirm: {
    width: '220px',
    height: '45px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    padding: '10px 40px',
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  modalImage: {
    '&>img': {
      objectFit: 'contain',
      maxHeight: '450px',
      height: '100%',
    },
  },
  buttonCancel: {
    width: '112px',
    height: '45px',
    borderRadius: 100,
    padding: '10px 40px',
    '& p': {
      color: `${theme.palette.primary.main} !important`,
    },
  },
}));
