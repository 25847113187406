import * as React from 'react';
import { SVGProps, memo } from 'react';
import { theme } from '../../../theme';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="84"
    height="84"
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_1398_24839)">
      <path d="M84 58.2581V84H67.7419L84 58.2581Z" fill="#9E96ED" />
      <path
        d="M72.7278 59.1794L75.871 54.1936L84 58.2581L67.7419 84L59.6129 79.9355L62.2413 75.7897L72.7278 59.1794Z"
        fill="#EDF4FA"
      />
      <path
        d="M17.0574 38.7348C17.5452 38.2336 18.2226 37.9355 18.9677 37.9355C20.7426 37.9355 22.4768 38.4368 23.9671 39.3852L36.5806 47.4194H30.4839C29.4813 47.4194 28.5464 47.731 27.7877 48.2729L16.4884 40.6452C16.4206 40.591 16.3393 40.5503 16.2716 40.4961C16.2987 39.8052 16.5968 39.1955 17.0574 38.7348Z"
        fill="#D9D4FF"
      />
      <path
        d="M28.4516 56.9032C28.4516 56.9032 16.3258 47.2026 10.798 42.7858C10.378 42.4471 9.9445 42.1626 9.48386 41.8916V41.6342C9.48386 40.3336 10.6084 39.2903 12.0039 39.2903H12.1935C13.711 39.2903 15.0387 39.7103 16.2716 40.4961C16.3393 40.5503 16.4206 40.591 16.4884 40.6452L27.7877 48.2729C27.5574 48.4355 27.3406 48.6116 27.1374 48.8148C26.2703 49.6684 25.7419 50.8607 25.7419 52.1613C25.7419 54.7761 27.869 56.9032 30.4839 56.9032H28.4516Z"
        fill="#D9D4FF"
      />
      <path
        d="M10.7981 42.7858C16.3258 47.2026 28.4516 56.9032 28.4516 56.9032H30.4839C27.869 56.9032 25.7419 54.7761 25.7419 52.1613C25.7419 50.8607 26.2703 49.6684 27.1374 48.8148C27.3406 48.6116 27.5574 48.4355 27.7877 48.2729C28.5465 47.731 29.4813 47.4194 30.4839 47.4194H55.5484C57.2826 47.4194 58.9897 47.7716 60.5613 48.4219C62.1329 49.0723 63.569 50.0207 64.8019 51.2536L72.7277 59.1794L62.2413 75.7897L60.9677 74.5161H37.5155C33.4103 74.5161 29.4542 73.0394 26.3652 70.3568L1.53097 48.8148C0.555484 47.9207 0 46.6607 0 45.3465V45.2245C0 42.691 2.04581 40.6452 4.57936 40.6452H4.67419C6.38129 40.6452 8.03419 41.0787 9.48387 41.8916C9.94452 42.1626 10.3781 42.4471 10.7981 42.7858Z"
        fill="#EEECFD"
      />
      <path
        d="M51.4839 52.8387H31.1613C29.0613 52.8387 27.2594 51.6329 26.3516 49.8716C25.9723 50.549 25.7419 51.3213 25.7419 52.1613C25.7419 54.7761 27.869 56.9032 30.4839 56.9032H55.5484C55.5484 54.6542 53.7329 52.8387 51.4839 52.8387Z"
        fill="#9E96ED"
      />
      <path
        d="M69.3948 55.8465L65.7503 61.5639C62.7697 66.2516 57.5942 69.0968 52.0394 69.0968H35.3613C33.5187 69.0968 31.7303 68.4736 30.2942 67.3219L4.94516 47.1077C2.9671 45.6039 2.34387 43.0568 3.15677 40.889C1.32774 41.4852 0 43.1923 0 45.2245V45.3465C0 46.6607 0.555484 47.9206 1.53097 48.8148L26.3652 70.3568C29.4542 73.0394 33.4103 74.5161 37.5155 74.5161H60.9677L62.2413 75.7897L72.7277 59.1794L69.3948 55.8465Z"
        fill="#9E96ED"
      />
      <path
        d="M25.7419 52.1613C25.7419 50.8606 26.2703 49.6684 27.1374 48.8148C27.3406 48.6116 27.5574 48.4355 27.7877 48.2729L26.7987 47.609C25.0239 48.8961 22.6122 49.0045 20.7155 47.7987L10.9064 41.5529C10.2968 41.16 10.1477 40.3877 10.4729 39.7781C9.87676 40.2116 9.48386 40.8755 9.48386 41.6342V41.8916C9.9445 42.1626 10.378 42.4471 10.798 42.7858C16.3258 47.2026 28.4516 56.9032 28.4516 56.9032H30.4839C27.869 56.9032 25.7419 54.7761 25.7419 52.1613Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M30.6329 46.0645C29.2103 46.0645 27.8013 45.6852 26.5684 44.9806L19.6181 40.9703C18.48 40.4013 17.9787 39.1955 18.1819 38.0574C17.7484 38.1929 17.369 38.4097 17.0574 38.7213C16.5968 39.1819 16.2987 39.7916 16.2716 40.4826C16.3393 40.5368 16.4206 40.5774 16.4884 40.6316L27.7877 48.2594C28.5464 47.7174 29.4813 47.4058 30.4839 47.4058H36.5806L34.4535 46.051H30.6329V46.0645Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M80.5452 77.2258C78.4587 77.2258 77.1581 74.9768 78.1877 73.1748L84 63V58.2581L67.7419 84H84V77.2258H80.5452Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M79.9626 56.2393L68.8665 73.3916C67.4845 75.5323 64.7884 76.3994 62.4174 75.5052L62.2413 75.7897L59.6129 79.9355L67.7419 84L84 58.2581L79.9626 56.2393Z"
        fill="#D6E0EB"
      />
      <path
        d="M62.3226 20.3226C62.3226 21.7994 59.1929 22.8968 58.8813 24.2652C58.5697 25.6336 60.9136 28.0723 60.3174 29.3458C59.7213 30.6194 56.4155 30.24 55.5348 31.3645C54.6542 32.489 55.7652 35.6865 54.6677 36.5807C53.5703 37.4748 50.7794 35.6594 49.5058 36.2826C48.2323 36.9058 47.9071 40.2794 46.5116 40.6045C45.1161 40.9297 43.4226 38.0439 41.9865 38.0439C40.5503 38.0439 38.8161 40.9297 37.4613 40.6045C36.1065 40.2794 35.7271 36.9058 34.4671 36.2826C33.2071 35.6594 30.4026 37.4748 29.3052 36.5807C28.2077 35.6865 29.3187 32.489 28.4381 31.3645C27.5574 30.24 24.2787 30.6465 23.6555 29.3458C23.0323 28.0452 25.4032 25.6878 25.0916 24.2652C24.78 22.8426 21.6503 21.7858 21.6503 20.3226C21.6503 18.8594 24.78 17.7484 25.0916 16.38C25.4032 15.0116 23.0594 12.5729 23.6555 11.2994C24.2516 10.0258 27.5574 10.4052 28.4381 9.28065C29.3187 8.15614 28.2077 4.95872 29.3052 4.06453C30.4026 3.17033 33.1936 4.98582 34.4671 4.36259C35.7407 3.73937 36.0929 0.352268 37.4748 0.0271068C38.8568 -0.298054 40.5639 2.58775 42 2.58775C43.4361 2.58775 45.1703 -0.284506 46.5252 0.0271068C47.88 0.33872 48.2594 3.72582 49.5194 4.34904C50.7794 4.97227 53.5839 3.15678 54.6813 4.05098C55.7787 4.94517 54.6678 8.14259 55.5484 9.26711C56.429 10.3916 59.7077 9.98517 60.331 11.2858C60.9542 12.5865 58.5832 14.9439 58.8948 16.3665C59.2065 17.789 62.3361 18.8458 62.3361 20.309L62.3226 20.3226Z"
        fill="#5C35CB"
      />
      <path
        d="M58.2581 20.3226C58.2581 21.5013 55.7516 22.3819 55.5078 23.4794C55.2639 24.5768 57.1336 26.5142 56.6594 27.5439C56.1852 28.5736 53.5432 28.2619 52.8387 29.1561C52.1342 30.0503 53.0284 32.611 52.1478 33.329C51.2671 34.0471 49.0452 32.5839 48.0155 33.0987C46.9858 33.6136 46.7419 36.2961 45.6174 36.5536C44.4929 36.811 43.1516 34.4942 42 34.4942C40.8484 34.4942 39.4529 36.7974 38.3826 36.5536C37.3123 36.3097 37.0007 33.6 35.9845 33.0987C34.9684 32.5974 32.7329 34.0471 31.8523 33.329C30.9716 32.611 31.8523 30.0503 31.1613 29.1561C30.4703 28.2619 27.8284 28.5871 27.3407 27.5439C26.8529 26.5007 28.7361 24.6174 28.4923 23.4794C28.2484 22.3413 25.7419 21.5013 25.7419 20.3226C25.7419 19.1439 28.2484 18.2632 28.4923 17.1658C28.7361 16.0684 26.8665 14.131 27.3407 13.1013C27.8148 12.0716 30.4568 12.3832 31.1613 11.489C31.8658 10.5948 30.9716 8.0342 31.8523 7.31614C32.7329 6.59807 34.9548 8.0613 35.9845 7.54646C37.0142 7.03162 37.2581 4.34904 38.3826 4.09162C39.5071 3.8342 40.8484 6.15098 42 6.15098C43.1516 6.15098 44.5471 3.84775 45.6174 4.09162C46.6877 4.33549 46.9994 7.04517 48.0155 7.54646C49.0316 8.04775 51.2671 6.59807 52.1478 7.31614C53.0284 8.0342 52.1478 10.5948 52.8387 11.489C53.5297 12.3832 56.1716 12.0581 56.6594 13.1013C57.1471 14.1445 55.2639 16.0277 55.5078 17.1658C55.7516 18.3039 58.2581 19.1439 58.2581 20.3226Z"
        fill="#9E96ED"
      />
      <path
        d="M33.871 29.8065C33.5187 29.8065 33.18 29.671 32.909 29.4136C32.3807 28.8852 32.3807 28.0316 32.909 27.5032L49.1671 11.2452C49.6955 10.7168 50.549 10.7168 51.0774 11.2452C51.6058 11.7736 51.6058 12.6271 51.0774 13.1555L34.8194 29.4136C34.5484 29.6845 34.2097 29.8065 33.8574 29.8065H33.871Z"
        fill="#FBFBFB"
      />
      <path
        d="M46.0645 31.1613C43.829 31.1613 42 29.3323 42 27.0968C42 24.8613 43.829 23.0323 46.0645 23.0323C48.3 23.0323 50.129 24.8613 50.129 27.0968C50.129 29.3323 48.3 31.1613 46.0645 31.1613ZM46.0645 25.7419C45.3194 25.7419 44.7097 26.3516 44.7097 27.0968C44.7097 27.8419 45.3194 28.4516 46.0645 28.4516C46.8097 28.4516 47.4194 27.8419 47.4194 27.0968C47.4194 26.3516 46.8097 25.7419 46.0645 25.7419Z"
        fill="#FBFBFB"
      />
      <path
        d="M37.9355 17.6129C35.7 17.6129 33.871 15.7839 33.871 13.5484C33.871 11.3129 35.7 9.48389 37.9355 9.48389C40.171 9.48389 42 11.3129 42 13.5484C42 15.7839 40.171 17.6129 37.9355 17.6129ZM37.9355 12.1936C37.1903 12.1936 36.5806 12.8032 36.5806 13.5484C36.5806 14.2936 37.1903 14.9032 37.9355 14.9032C38.6806 14.9032 39.2903 14.2936 39.2903 13.5484C39.2903 12.8032 38.6806 12.1936 37.9355 12.1936Z"
        fill="#FBFBFB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1398_24839">
        <rect width="84" height="84" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PercentAmountIconBlue = memo(SvgComponent);
export default PercentAmountIconBlue;
