import { FC } from 'react';

export const PenIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 4C0 1.79086 1.79086 0 4 0H23V19C23 21.2091 21.2091 23 19 23H0V4Z"
        fill="#C5C5C5"
      />
      <g clipPath="url(#clip0_384_9538)">
        <path
          d="M15.25 4.25L17.75 6.75M5.25 16.75L6.04775 13.8249C6.0998 13.6341 6.12582 13.5387 6.16577 13.4497C6.20124 13.3707 6.24483 13.2956 6.29582 13.2256C6.35326 13.1467 6.42319 13.0768 6.56306 12.9369L13.0214 6.47855C13.1452 6.3548 13.2071 6.29292 13.2784 6.26974C13.3412 6.24934 13.4088 6.24934 13.4716 6.26974C13.5429 6.29292 13.6048 6.3548 13.7286 6.47855L15.5214 8.27145C15.6452 8.3952 15.7071 8.45708 15.7303 8.52843C15.7507 8.5912 15.7507 8.6588 15.7303 8.72157C15.7071 8.79292 15.6452 8.8548 15.5214 8.97855L9.06306 15.4369C8.92319 15.5768 8.85326 15.6467 8.77443 15.7042C8.70443 15.7552 8.62933 15.7988 8.55032 15.8342C8.46134 15.8742 8.36592 15.9002 8.17509 15.9522L5.25 16.75Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_384_9538">
          <rect width="15" height="15" fill="white" transform="translate(4 3)" />
        </clipPath>
      </defs>
    </svg>
  );
};
