import React, { FC } from 'react';

interface Props {
  stroke?: string;
}

const ArrowRightSmall: FC<Props> = ({ stroke = 'white' }) => {
  return (
    <svg width="6" height="12" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 13L7.00013 6.99991L1 1"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightSmall;
