import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ClientTypes } from '../../../api/models/common';
import { VideoApiModel } from '../../../api/models/videos';
import { NewDetailsModal } from '../../../common/components/NewDetailsModal/NewDetailsModal';
import { VideoStatus } from '../../../common/constants/constants';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { CommonStrings, ContentSubnavigationStrings } from '../../../common/localization/en';
import { useSegmentPageCall } from '../../../hooks/useSegment';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getActiveIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import { updateVideoById } from '../../../store/slices/videosSlice';
import { updateTopgolfVideoById } from '../../../store/slices/topGolfVideosSlice';
import { ConfirmationModal } from '../../content/ConfirmationModel/confirmationModel';
import { DeleteModal } from '../../content/DeleteModal/DeleteModal';
import { TableSection } from '../../content/tableSection/TableSection';
import { WidgetTagCreationModal } from '../../content/WidgetTagCreationModal/WidgetTagCreationModal';
import { useVideoTableIdContext } from '../SocialVerse.helper';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
interface ContentTableUserVideosProps {
  handleAddVideosToCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  handleVideoReplace: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
}
export const ContentTableUserVideos: FC<ContentTableUserVideosProps> = ({
  handleAddVideosToCard,
  handleVideoReplace,
}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();
  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const { activeFilter, newFilterAttributes } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );

  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const dispatch = useAppDispatch();
  const stories = useTypedSelector((state) => state.videos.items);
  const topgolfVideos = useTypedSelector((state) => state.TopgGolfVideos.items);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isAddNewTagModalOpen, setisAddNewTagModalOpen] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [archived, setArchived] = useState(false);
  const [storyUpdate] = isNotTopgolf
    ? stories.filter((item) => item.id === videoId)
    : topgolfVideos.filter((item) => item.id === videoId);

  const { storyVideoId, setStoryVideoId, setWithEditThumbnailOpen } = useVideoTableIdContext();
  const [story] = isNotTopgolf
    ? stories.filter((item) => item.id === storyVideoId)
    : topgolfVideos.filter((item) => item.id === storyVideoId);
  const isDetailsModalOpen =
    !!storyVideoId &&
    !VideoStatus.includes(storyVideoId) &&
    storyVideoId !== ContentSubnavigationStrings.Archived.toLowerCase();

  useEffect(() => {
    if (venueId) {
      dispatch(getActiveIncentiveCampaign(venueId));
    }
  }, [dispatch, venueId]);

  useSegmentPageCall();

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
  };
  const handleCloseAddnewTagModal = () => {
    setisAddNewTagModalOpen(false);
  };

  const close = () => {
    setStoryVideoId('');
    setWithEditThumbnailOpen?.(false);
  };

  const handleOpenDeleteModal = (id: string, deleted: boolean) => {
    setIsDeleteModalOpen(true);
    setVideoId(id);
    setArchived(deleted);
  };

  const handleOpenConfirmationModal = (id: string) => {
    setIsConfirmationModalOpen(true);
    setVideoId(id);
  };

  const handleOpenAddNewTagModal = (id: string) => {
    setisAddNewTagModalOpen(true);
    setVideoId(id);
  };

  const handleConfirm = () => {
    if (videoId) {
      isNotTopgolf
        ? dispatch(updateVideoById({ id: videoId, update: { featured: !storyUpdate.featured } }))
        : dispatch(
            updateTopgolfVideoById({ id: videoId, update: { featured: !storyUpdate.featured } }),
          );
      handleCloseConfirmationModal();
    }
  };

  const handleDelete = () => {
    if (videoId) {
      if (isNotTopgolf) {
        dispatch(updateVideoById({ id: videoId, update: { deleted: !archived } }));
      } else {
        dispatch(
          updateTopgolfVideoById({
            id: videoId,
            update: { status: storyUpdate.status === 'ARCHIVED' ? 'PUBLISHED' : 'ARCHIVED' },
          }),
        );
      }

      handleCloseDeleteModal();
    }
    if (isDetailsModalOpen) {
      close();
    }
  };

  const getCurrentStatus = () => VideoStatus.find((status) => pathname.split('/')[2] === status);
  const archivedFilter = pathname.includes(ContentSubnavigationStrings.Archived.toLowerCase());
  const statusFilter = getCurrentStatus();
  const isAddVideoPage = history.location.pathname.includes(WebsiteRoutes.SocialVerse);
  return (
    <Grid>
      <WidgetTagCreationModal
        handleCloseModal={handleCloseAddnewTagModal}
        onConfirm={() => {
          // tags were removed
        }}
        isModalOpen={isAddNewTagModalOpen}
      />
      <ConfirmationModal
        handleCloseModal={handleCloseConfirmationModal}
        onConfirm={handleConfirm}
        isModalOpen={isConfirmationModalOpen}
        message={
          storyUpdate?.featured
            ? CommonStrings.RemoveFromWidgetConfirmation
            : CommonStrings.AddToWidgetConfirmation
        }
      />
      <DeleteModal
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleDelete={handleDelete}
        isDeleteModalOpen={isDeleteModalOpen}
        archived={archived}
      />

      <TableSection
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        handleOpenAddNewTagModal={handleOpenAddNewTagModal}
        handleAddVideoToCard={handleAddVideosToCard}
        handleVideoReplace={handleVideoReplace}
        statusFilter={statusFilter}
        archivedFilter={archivedFilter}
        showActionBar={activeFilter && !newFilterAttributes ? true : false}
        isSocialVerseAddVideo={isAddVideoPage}
        onVideoKeywordsClick={() =>
          trackEvent(SocialVerseEventNames.videoKeywords, { socialVerseId: params.id })
        }
      />

      <NewDetailsModal
        userId={isNotTopgolf ? (story as VideoApiModel)?.userId || '' : ''}
        isOpen={isDetailsModalOpen}
        videoId={storyVideoId}
        handleClose={close}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleAddVideoToCard={handleAddVideosToCard}
        onDeleteVideoClick={() =>
          trackEvent(SocialVerseEventNames.deleteUserVideo, { videoId: storyVideoId })
        }
      />
    </Grid>
  );
};
