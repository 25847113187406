import React from 'react';

import { MessageBox } from '../../../common/components/IPhonePreview/IPhoneSMSPreview';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { Box } from '@mui/material';
import {
  constructMessageFromTemplate,
  LocalMessageVariables,
} from '../../../common/components/SMS/SMS.helper';
import { useSmsTemplateVariables } from '../../../services/hooks/useSmsTemplateVariables';
import { useWebAppLink } from '../../../hooks/useWebAppLink';
import { PreviewContainer } from '../components/PreviewContainer';
import { IPhoneHalfPreview } from '../../../common/components/IPhonePreview/IPhoneHalfPreview';
import { CommonStrings, SmsInviteStrings } from '../../../common/localization/en';
import { ImageUploader } from '../../../common/components/ImageUploader/ImageUploader';
import { useUploadFileToStorage } from '../../../hooks/useUploadFileToStorage';
import { useToasts } from 'react-toast-notifications';
import { resizeImageForTwilioMMSCommunication } from '../../../services/utilities';
import { setInviteMediaUrl } from '../../../store/slices/createCampaignSlice';
import { PictureIconDnD } from '../../../common/assets/newDesign/PictureIconDnD';
import { theme } from '../../../theme';

export const CustomizeSMSPreview: React.FC = () => {
  const { uploadFileToStorage, loading: isFileLoading } = useUploadFileToStorage();
  const {
    inviteMediaUrl,
    smsInviteMessage,
    howToRewardCreators: { rewardName },
  } = useTypedSelector((state) => state.createCampaign);

  const dispatch = useAppDispatch();

  const { addToast } = useToasts();

  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    if (!['image/svg+xml', 'image/jpeg', 'image/png'].includes(file.type)) {
      addToast(CommonStrings.OnError, { appearance: 'error' });
      return;
    }
    const fileUrl = (await uploadFileToStorage(file))?.downloadUrl;
    if (fileUrl) {
      const resizedImageUrl = await resizeImageForTwilioMMSCommunication(fileUrl);
      dispatch(setInviteMediaUrl(resizedImageUrl));
    }
  };

  const templateVariables = useSmsTemplateVariables();
  const currentEditableVariables: Record<LocalMessageVariables, string> = {
    ...templateVariables,
    'Reward Name': rewardName,
  };

  const constructedMessage = constructMessageFromTemplate(
    smsInviteMessage,
    currentEditableVariables,
    true,
  );
  const { webAppLink } = useWebAppLink();

  const handleRemoveImage = () => {
    dispatch(setInviteMediaUrl(''));
  };

  return (
    <Box display="flex" justifyContent="center" position="sticky" top="80px" height="fit-content">
      <PreviewContainer title={SmsInviteStrings.InvitationSMS}>
        <IPhoneHalfPreview height={650}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            zIndex={'2'}
            style={{ pointerEvents: 'all' }}
          >
            <ImageUploader
              file={inviteMediaUrl}
              onUpload={handleUpload}
              accept={['jpeg', 'png']}
              dndDescription="JPG or PNG (recommended size 1:1 aspect ratio)"
              dndTitle="Drag or Drop"
              dndIcon={<PictureIconDnD color={theme.palette.primary.main} />}
              loading={isFileLoading}
              imagePreviewFit="contain"
              imagePreviewWidth={'240px'}
              imagePreviewHeight={'150px'}
              alignment={'flex-start'}
              imagePadding={0}
              warnBackgroundNotTransparent={false}
              onRemoveClick={handleRemoveImage}
              displayButtons={false}
              displayInfo={false}
              zIndex={2}
              isCreateNewCampaignPreview
            />
            <MessageBox
              width="280px"
              fontSize={16}
              message={constructedMessage}
              linkTitle={webAppLink}
              boldSubstrings={Object.values(currentEditableVariables)}
              showStartBlock={false}
            />
          </Box>
        </IPhoneHalfPreview>
      </PreviewContainer>
    </Box>
  );
};
