import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import * as Yup from 'yup';
import { ErrorsStrings, RewardStrings } from '../../../../common/localization/en';
import CustomRewardIcon from '../../../../common/assets/QuickStartIcons/CustomRewardIcon';
import PercentAmountIconBlue from '../../../../common/assets/QuickStartIcons/PercentAmountIconBlue';
import SaleAmountIconBlue from '../../../../common/assets/QuickStartIcons/SaleAmonutIconBlue';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    justfifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: '18px',
    marginTop: '25px',
  },
  rewardTypes: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: '23px',
    '& > div': {
      '&:last-child': {
        marginRight: '0px',
      },
      marginRight: '19px',
    },
  },
  rewardInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  textFieldWrapper: {
    '& .charsLimit': {
      fontSize: '12px !important',
      top: '-30px !important',
    },
  },
  textfield: {
    paddingLeft: '0px !important',
  },
  IsZeroRewardToggleLabel: { marginRight: '12px' },
  textfieldPercent: { paddingRight: '0px !important' },
  textfieldRightAdornment: {
    paddingRight: '100px !important',
  },

  label: {
    marginBottom: '6px',
    color: '#344054',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '20px',
  },
  btnNext: {
    position: 'absolute',
    bottom: '-4px',
    right: '0',
  },
}));

export const validationSchema = () =>
  Yup.object().shape({
    discount: Yup.string().required(ErrorsStrings.FieldIsRequired),
    /*  cell: Yup.string().required(ErrorsStrings.Required),
    email: Yup.string().email().required(), */
  });

export const selectedTypeLiteral = {
  amount: RewardStrings.DiscountAmount,
  percent: RewardStrings.DiscountPercent,
  custom: RewardStrings.DiscountCustom,
};

export enum DiscountType {
  Amount = 'amount',
  Percent = 'percent',
  Custom = 'custom',
}

export const rewardButtons = [
  { title: selectedTypeLiteral.amount, type: DiscountType.Amount, icon: <SaleAmountIconBlue /> },
  {
    title: selectedTypeLiteral.percent,
    type: DiscountType.Percent,
    icon: <PercentAmountIconBlue />,
  },
  { title: selectedTypeLiteral.custom, type: DiscountType.Custom, icon: <CustomRewardIcon /> },
];

export const textfieldAdornment = {
  [DiscountType.Amount]: '$',
  [DiscountType.Percent]: '%',
  [DiscountType.Custom]: undefined,
};

const getDefaultDiscountNumericVal = (discount: string) => {
  return discount.replace(RewardStrings.DefaultRewardName, '').replace(/[$% ]/g, '');
};

export const getDiscountData = (discount: string, discountType: DiscountType) => {
  let displayDiscountVal = '';
  if (discountType === DiscountType.Custom) {
    displayDiscountVal = discount;
  } else {
    displayDiscountVal = getDefaultDiscountNumericVal(discount);
  }

  return {
    displayDiscountVal,
    discountType,
  };
};
