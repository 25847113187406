import { FC, useState } from 'react';
import { Grid, Button, Theme, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FSModal } from '../../../common/components/modal/Modal';
import { CommonStrings, ContentPagesStrings } from '../../../common/localization/en';

interface WidgetTagCreationModalProps {
  handleCloseModal: () => void;
  isModalOpen: boolean;
  onConfirm: (tag: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'center',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
  },
  confirmButton: {
    width: '170px',
    height: '50px',
  },
}));

export const WidgetTagCreationModal: FC<WidgetTagCreationModalProps> = ({
  handleCloseModal,
  isModalOpen,
  onConfirm,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const handleChange = (e: { target: { value: string } }) => {
    setValue(e.target.value);
  };
  return (
    <FSModal onClose={handleCloseModal} modalIsOpen={isModalOpen}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={10}>
          <p className={classes.title}>{ContentPagesStrings.CreateNewWidgetTag}</p>
        </Grid>
        <Grid item xs={10}>
          <p className={classes.subtitle}>{ContentPagesStrings.CanFilter}</p>
        </Grid>
        <Grid item xs={10}>
          <TextField value={value} onChange={handleChange} placeholder="Enter Tag Name" fullWidth />
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Button color="primary" onClick={handleCloseModal}>
                {CommonStrings.Cancel}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                className={classes.confirmButton}
                variant="contained"
                color="primary"
                onClick={() => onConfirm(value)}
              >
                {ContentPagesStrings.CreateNewTag}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
