import { FC } from 'react';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { useDeleteVideoModalStyles } from './DeleteVideoModal.style';
import { DeleteModalVideoStrings } from '../../localization/en';

interface IDeleteVideoModalProps {
  openDeleteModal: boolean;
  handleDeleteModalClose: () => void;
  handleDeleteModalConfirm: () => void;
}

export const DeleteVideoModal: FC<IDeleteVideoModalProps> = ({
  openDeleteModal,
  handleDeleteModalClose,
  handleDeleteModalConfirm,
}) => {
  const styles = useDeleteVideoModalStyles();
  return (
    <Modal
      open={openDeleteModal}
      onClose={handleDeleteModalClose}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <Box className={styles.DeleteModal}>
        <div style={{ textAlign: 'end', fontSize: '24px' }}>
          <IconButton onClick={handleDeleteModalClose}>
            <CloseRoundedIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Typography className={styles.ModalTitle}>{DeleteModalVideoStrings.ModalTitle}</Typography>
        <Typography className={styles.ModalText}>{DeleteModalVideoStrings.ModalText}</Typography>
        <div style={{ textAlign: 'end' }}>
          <Button
            variant="outlined"
            className={styles.ModalBtn}
            onClick={handleDeleteModalClose}
            style={{ marginRight: '20px' }}
          >
            {DeleteModalVideoStrings.Cancel}
          </Button>
          <Button
            variant="outlined"
            className={[styles.ModalBtn, styles.ConfirmBtn].join(' ')}
            onClick={handleDeleteModalConfirm}
          >
            {DeleteModalVideoStrings.Confirm}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
