import { FC } from 'react';

interface ILinkedinIconIconProps {
  color?: string;
}

export const LinkedinIcon: FC<ILinkedinIconIconProps> = ({ color }) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333252 2.30233C0.333252 1.03079 1.36404 0 2.63558 0C3.90712 0 4.9379 1.03079 4.9379 2.30233C4.9379 3.57386 3.90712 4.60465 2.63558 4.60465C1.36404 4.60465 0.333252 3.57386 0.333252 2.30233ZM2.63558 1.25581C2.05761 1.25581 1.58907 1.72435 1.58907 2.30233C1.58907 2.8803 2.05761 3.34884 2.63558 3.34884C3.21355 3.34884 3.68209 2.8803 3.68209 2.30233C3.68209 1.72435 3.21355 1.25581 2.63558 1.25581Z"
        fill={color || '#8B89A0'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333252 6.48837C0.333252 6.14159 0.614375 5.86047 0.961159 5.86047H4.31C4.65678 5.86047 4.9379 6.14159 4.9379 6.48837V17.3721C4.9379 17.7189 4.65678 18 4.31 18H0.961159C0.614375 18 0.333252 17.7189 0.333252 17.3721V6.48837ZM1.58907 7.11628V16.7442H3.68209V7.11628H1.58907Z"
        fill={color || '#8B89A0'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6821 5.86047C9.56104 5.86047 7.03093 8.39058 7.03093 11.5116V17.3721C7.03093 17.7189 7.31205 18 7.65883 18H11.0077C11.3545 18 11.6356 17.7189 11.6356 17.3721V11.5116C11.6356 10.9336 12.1041 10.4651 12.6821 10.4651C13.2601 10.4651 13.7286 10.9336 13.7286 11.5116V17.3721C13.7286 17.7189 14.0097 18 14.3565 18H17.7053C18.0521 18 18.3333 17.7189 18.3333 17.3721V11.5116C18.3333 8.39058 15.8031 5.86047 12.6821 5.86047ZM8.28674 11.5116C8.28674 9.08415 10.2546 7.11628 12.6821 7.11628C15.1096 7.11628 17.0774 9.08415 17.0774 11.5116V16.7442H14.9844V11.5116C14.9844 10.2401 13.9537 9.2093 12.6821 9.2093C11.4105 9.2093 10.3798 10.2401 10.3798 11.5116V16.7442H8.28674V11.5116Z"
        fill={color || '#8B89A0'}
      />
    </svg>
  );
};
