import { DownloadCampaignsButton } from './DownloadButton/DownloadButton';
import { Box, Typography } from '@mui/material';
import { CampaignStatusCell } from './CampaignStatusCell/CampaignStatusCell';
import { TableContent, TableRow } from '../../../../common/commonTypes';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import {
  getStringValue,
  getLocaleNumberValue,
  getDateString,
} from '../../../../services/utilities';
import { ImageComponent } from '../../../../common/components/table/TableImage';
import { RewardsPageStrings } from '../../../../common/localization/en';

import { Theme } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { EndCampaignButton } from './EndButton/EndButton';
import { RewardsCardIcon } from './RewardsCardIcon';
import { InfoIcon } from './InfoIcon';

interface CampaignMapperProps<T> {
  items: T[];
  handleOpenEditModal: (campaign: T) => void;
  handleOpenEndModal: () => void;
  isEditButton: boolean;
  isMobile: boolean;
}

export const Styles = makeStyles((theme: Theme) => ({
  Cell: {
    paddingLeft: '10px',
    color: theme.palette.common.deepDark,
  },
  TableWrapper: {
    '& table th:first-child': {
      paddingLeft: '15px !important',
    },
  },
}));

interface RedeemCampaignsContent extends TableRow {
  id: string;
  img?: JSX.Element;
  name: string | JSX.Element;
  status: JSX.Element;
  createdAt?: string;
  startedAt?: JSX.Element;
  endedAt: JSX.Element;
  achievements: JSX.Element;
  downloadButton: JSX.Element | string;
  editButton: JSX.Element;
}

export const CreateRewardsCampaignsTableContent = (
  props: CampaignMapperProps<CampaignsApiModel>,
): TableContent<RedeemCampaignsContent> => {
  const theme = useTheme();
  const styles = Styles();
  const { items, handleOpenEditModal } = props;
  const tableContent: TableContent<RedeemCampaignsContent> = {
    header: {
      name: {
        name: RewardsPageStrings.CampaignsHeaderNameReward,
        sortable: false,
      },
      status: {
        name: RewardsPageStrings.CampaignsHeaderStatus,
        sortable: false,
      },
      startedAt: {
        name: RewardsPageStrings.CampaignsHeaderStartDate,
        sortable: true,
      },
      endedAt: {
        name: RewardsPageStrings.CampaignsHeaderEndDate,
        sortable: true,
      },
      numAchievements: {
        name: RewardsPageStrings.CampaignsHeaderAchievements,
        sortable: true,
      },
      downloadButton: {
        name: RewardsPageStrings.CampaignsHeaderActions,
        sortable: false,
      },
      editButton: {
        name: '',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item) => {
    return {
      id: item.id,
      name: (
        <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }}>
          {item.imageUrl ? (
            <ImageComponent borderRadius="15px" imageRef={item.imageUrl} alt="Campaign image" />
          ) : (
            <RewardsCardIcon />
          )}
          <span>{getStringValue(item.name)} </span>
        </div>
      ),
      status: <CampaignStatusCell isActive={!item.endedAt} />,
      startedAt: <Typography className={styles.Cell}>{getDateString(item.startedAt)} </Typography>,
      endedAt: (
        <Typography className={styles.Cell}>
          {item.endedAt ? getDateString(item.endedAt) : '---'}
        </Typography>
      ),
      achievements: (
        <Typography className={styles.Cell}>
          {getLocaleNumberValue(item.numAchievements)}
        </Typography>
      ),
      downloadButton: item.endedAt ? (
        <DownloadCampaignsButton id={item.id} name={item.name} />
      ) : (
        <EndCampaignButton onClick={props.handleOpenEndModal} />
      ),
      editButton: !item.endedAt ? (
        <Box
          sx={{
            paddingRight: '10px',
            '& > svg': { display: 'block' },
            color: theme.palette.primary.main,
          }}
        >
          <InfoIcon
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleOpenEditModal(item)}
          />
        </Box>
      ) : (
        <></>
      ),
    };
  });

  return tableContent;
};
