import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SearchFilterInitialState {
  tabIndex: number;
  forceHideQsMarker: boolean;
}

const initialState: SearchFilterInitialState = {
  tabIndex: 0,
  forceHideQsMarker: false,
};

const launchPadSlice = createSlice({
  name: 'launchPad',
  initialState,
  reducers: {
    setTabIndex(state, action: PayloadAction<number>) {
      state.tabIndex = action.payload;
    },
    setForceHideQSmarker(state, action: PayloadAction<boolean>) {
      state.forceHideQsMarker = action.payload;
    },
  },
  extraReducers: (reducersBuilder) => {},
});

export const { setTabIndex, setForceHideQSmarker } = launchPadSlice.actions;
export default launchPadSlice.reducer;
