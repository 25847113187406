export const convertToMmSsStringFormat = (minutes: number, seconds: number): string => {
  let newMinutes = minutes.toString();
  let newSeconds = seconds.toString();
  if (minutes < 10) {
    newMinutes = `0${minutes}`;
  }

  if (seconds < 10) {
    newSeconds = `0${seconds}`;
  }

  return `${newMinutes}:${newSeconds}`;
};
