import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '114px',
    height: '20px',

    borderRadius: '10px',
    display: 'flex',
    gap: '2px',
  },
  progressItem: {
    height: '100%',
    width: '100%',
  },
}));
