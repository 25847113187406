import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './TableFilterSelect.styles';
import { RewardsPageStrings } from '../../../../common/localization/en';
import { IEventTracker } from '../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../common/constants/events/rewardCampaignsEvents';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  filter?: { status?: string; hasVideos?: boolean };
  className?: string;
  disabled?: boolean;
}

interface Props {
  tabsList: TabItem[];
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  handleTrackEvent: IEventTracker;
  type: 'active' | 'invited';
  isIncentiveCampaign?: boolean;
  flex?: boolean;
  totalNumber?: number;
}

const TableFilterSelect: FC<Props> = ({
  tabsList,
  activeTabIndex,
  setActiveTabIndex,
  handleTrackEvent,
  type,
  isIncentiveCampaign,
  flex,
  totalNumber,
}) => {
  const styles = useStyles({ isIncentiveCampaign, flex });

  const replaceEventNameByTableType = RewardCampaignsEvents.RewardCampaginActiveCustomersTableViewAllTabClick.replace(
    'tab',
    `${type}_tab`,
  );
  const theme = useTheme();

  return (
    <Box className={styles.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = activeTabIndex === index;
        return (
          <button
            className={styles.tab}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              cursor: isActiveTab || tabItem.disabled ? 'auto' : 'pointer',
              border: tabItem.disabled ? 'none' : undefined,
            }}
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              handleTrackEvent(
                replaceEventNameByTableType
                  .replace('subtab', `${tabItem.tabName}_tab`)
                  .toLowerCase(),
              );
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter active-tab`}
            disabled={tabItem.disabled}
          >
            <Typography
              className={styles.tabName}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography
              className={styles.amount}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
                background: tabItem.disabled ? 'inherit' : undefined,
              }}
            >
              {tabItem.usersCount}
            </Typography>
          </button>
        );
      })}
      <Box className={styles.total}>
        <Box className={styles.totalLabel}>{RewardsPageStrings.ViewAll}</Box>
        <Box className={styles.totalNumber}>{totalNumber ? totalNumber : '---'}</Box>
      </Box>
    </Box>
  );
};

export default TableFilterSelect;
