import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStylesWorkspace = makeStyles<Theme, { isActive: boolean }>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    background: theme.palette.common.sidebar.accountButton.background,

    borderRadius: '10px',
    padding: '10px 20px',
    color: theme.palette.common.sidebar.accountButton.text,
    '&:hover': {
      background: theme.palette.common.sidebar.accountButton.activeBackground,
      color: theme.palette.common.sidebar.accountButton.activeText,
      cursor: 'pointer',
    },
  },
  active: {
    background: theme.palette.common.sidebar.accountButton.activeBackground,
    color: theme.palette.common.sidebar.accountButton.activeText,
  },
  logo: {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
    marginRight: '8px',
  },
  title: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.02em',
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subtitle: {
    fontSize: '12px',
    color: theme.palette.common.sidebar.accountButton.detailsText,
    fontWeight: 400,
    width: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  section: {
    paddingTop: '22px',
    borderTop: '1px solid',
    borderColor: '#292447',
    height: '1px',
    display: 'block',
  },
  containerWorkspace: {
    position: 'relative',
    minHeight: '55px',
    // paddingBottom: 18,
  },
  arrow: {
    color: 'rgba(139, 137, 160, 1)',
    fontSize: '14px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    position: 'absolute',
    top: '45%',
    right: 28,
    // padding: 5,
    minWidth: 10,
  },
  iconCollapsed: {
    marginTop: '3px',
    marginBottom: '-3px',
    transition: 'all 0.2s ease-out',
    position: 'absolute',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'all 0.1s ease-in',
  },
  placeholder: {
    height: '54px',
  },
}));

export const useStylesMenu = makeStyles((theme: Theme) => ({
  root: {
    margin: '15px !important',
    borderRadius: '6px !important',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      width: 310,
    },
    '& .MuiMenuItem-root': {
      '&:hover': {
        backgroundColor: '#F1F3FA',
        pointerEvents: 'all !important',
        cursor: 'pointer !important',
      },
    },
  },
  fonts: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#8F94B6',
  },
  title: {
    padding: '7px 10px',
    fontWeight: 500,
  },
  text: {
    fontWeight: 400,
  },
  menuItem: {
    padding: '10px 0px',
  },
  img: {
    width: '20px',
    height: '20px',
    margin: '0 15px',
  },
  activeManager: {
    //   cursor: 'pointer',
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
}));
