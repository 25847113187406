import { FC } from 'react';
interface ITikTokIconProps {
  color?: string;
}

export const TikTokIcon: FC<ITikTokIconProps> = ({ color }) => {
  return (
    <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.9173 1C12.2059 3.41238 13.5913 4.85062 16 5.00363V7.71692C14.6041 7.84952 13.3814 7.40581 11.9593 6.56938V11.644C11.9593 18.0907 4.72799 20.1052 1.82078 15.4845C-0.0473913 12.5111 1.0966 7.2936 7.08945 7.0845V9.94569C6.6329 10.0171 6.14487 10.1293 5.69881 10.2772C4.3659 10.7158 3.61024 11.5369 3.82015 12.9854C4.22422 15.7599 9.4614 16.581 9.02584 11.1595V1.0051H11.9173V1Z"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
      />
    </svg>
  );
};
