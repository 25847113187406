import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useEditThumbnailStyles = makeStyles((theme: Theme) => ({
  ChangeTumbnailContainer: {
    // margin: 20,
    marginTop: 20,
  },
  ChangeTumbnailContainerDisplayed: {
    display: 'block',
    transition: 'all 0.5s ease-in-out',
  },
  ChangeTumbnailContainerNotDisplayed: {
    display: 'none',
    transition: 'all 0.5s ease-in-out',
  },
  ChangeTumbnailAccordion: {
    boxShadow: 'none',
    backgroundColor: '#F5F6F8',
    padding: '9px 10px 0',
    borderRadius: '10px !important',
    cursor: 'default',
    '& .MuiTypography-root': {
      fontWeight: 500,
      fontSize: '14px',
      letterSpacing: '0.02em',
      color: theme.palette.common.black,
      marginBottom: 4,
      marginLeft: 14,
      cursor: 'default',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 30,
      cursor: 'default',
      '&:hover': {
        cursor: 'default',
      },
    },
    '& .MuiAccordionDetails': {},
  },
  ChangeTumbnailAccordionDetails: {
    padding: 0,
  },
  Divider: {
    color: '#DDDCE4',
  },
  VideoPluginCaption: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#1A1538',
    marginBottom: 10,
  },
  FromContainer: {
    width: 350,
    marginBottom: 20,
  },
  EditThumbnailContainer: {
    // paddingTop: 12,
  },
  EditThumbnailButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& button': {
      marginLeft: '12px',
    },
    marginBottom: 6,
  },
  IconEditThumbnailContainer: {
    display: 'flex',
    marginLeft: '-20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  ThumbnailPreview: {
    cursor: 'pointer',
    height: 136,
    width: 104,
    borderRadius: '10px',
  },
  ButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    boxShadow: '0px -10px 34px rgba(23, 25, 78, 0.05)',
    padding: 28,
    position: 'absolute',
    width: '100%',
    left: 0,
    marginBottom: 18,
  },
  Buttons: {
    boxShadow: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    padding: '10px 25px',
    borderRadius: '100px',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  ButtonsFilled: {
    backgroundColor: theme.palette.common.white,
    color: '#344054',
    '&:disabled': {
      backgroundColor: '#D0D5DD !important',
      color: `#344054 !important`,
    },
    height: '28px',
    fontSize: '11px',
    fontWeight: '600',
    border: '1px solid #D0D5DD',
  },
  ThumbnailButton: {
    padding: '4px 14px 4px 14px',
    marginLeft: '6px',
  },
  RefreshButton: {
    height: '28px',
    fontSize: '11px',
    fontWeight: '600',
    backgroundColor: theme.palette.common.white,
    color: '#344054',
    padding: '4px 14px 4px 14px',
    borderRadius: '50px',
    border: '1px solid #D0D5DD',
  },
}));
