import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: '406px',
    width: '250px',
  },
  actionArea: {
    position: 'relative',
  },
  video: {
    height: '310px',
  },
  topgolfRoot: {
    height: '356px',
    width: '250px',
  },
  topgolfVideo: {
    height: '250px',
  },
  contentCard: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 'unset',
  },
  box: {
    display: 'flex',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    position: 'absolute',
    color: 'white',
    top: '118px',
    left: '106px',
    pointerEvents: 'none',
  },
  favouriteButton: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    borderRadius: '15px',
    top: '16px',
    right: '16px',
    backgroundColor: theme.palette.common.white,
  },
  smallText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  smallTextPrimary: {
    color: theme.palette.text.primary,
  },
  smallTextSecondary: {
    color: theme.palette.text.secondary,
  },
  public: {
    fontSize: '14px',
  },
}));
