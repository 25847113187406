import { useEffect } from 'react';
import { GlobalAnalyticsNames, KPIApiNames } from '../../../../api/models/analytics';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getGlobalAnalytics } from '../../../../store/slices/analyticsSlice';
import { venuesInitialAnalyticsData } from './SuperAdminAnalyticsBlock.helper';
import { AnalyticsBlocks } from '../../../dashboard/AnalyticsSection/AnalyticsBlocks/AnalyticsBlocks';
import { useClientType } from '../../../../services/hooks/useClientType';

export const SuperAdminAnalyticsBlock = () => {
  const onClick = (kpiName: KPIApiNames, name: string) => {};
  const dispatch = useAppDispatch();
  const { isHealthCare, isTopGolfClient } = useClientType();

  const { groupId, isArchivedAccount } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { activeDistributor } = useTypedSelector((state) => state.distributors);
  const { activeAffiliate } = useTypedSelector((state) => state.affiliates);

  useEffect(() => {
    dispatch(
      getGlobalAnalytics({
        groupId: activeDistributor
          ? activeDistributor.groupId
          : activeAffiliate
          ? activeAffiliate.groupId
          : groupId,
        deactivated: isArchivedAccount,
      }),
    );
  }, [activeAffiliate, activeDistributor, dispatch, groupId, isArchivedAccount]);

  const { globalAnalytics, isLoading } = useTypedSelector((state) => state.analytics);

  const roundComasToNearestTwoDigitsDicimal = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const venueAnalyticsDataValues = [
    globalAnalytics[GlobalAnalyticsNames.accounts],
    globalAnalytics[GlobalAnalyticsNames.billableAccounts],
    globalAnalytics[GlobalAnalyticsNames.liveCampaigns],
    globalAnalytics[GlobalAnalyticsNames.videos],
    globalAnalytics[GlobalAnalyticsNames.socialVerses],
    globalAnalytics[GlobalAnalyticsNames.gbpAccounts],
    globalAnalytics[GlobalAnalyticsNames.ctaClicks],
  ];
  const venueAnalyticsData = venuesInitialAnalyticsData.map((item, index) => {
    return {
      ...item,
      value: roundComasToNearestTwoDigitsDicimal(venueAnalyticsDataValues[index]),
    };
  });

  return (
    <AnalyticsBlocks
      onClick={onClick}
      items={venueAnalyticsData}
      isLoading={isLoading}
      isHealthCare={isHealthCare}
      isTopgolf={isTopGolfClient}
      accountsPage={true}
    />
  );
};
