import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';
import { WebsiteRoutes } from '../../common/constants/routes';

export const GoogleSyncRedirect: FC = () => {
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const state = JSON.parse(query.get('state') || '');
    const campaignId = state?.campaign;
    if (campaignId) {
      history.push(`${WebsiteRoutes.RewardCampaigns}/${campaignId}${location.search}`);
    } else {
      history.push(`${WebsiteRoutes.Google}/${location.search}`);
    }
  }, [history, location.search, query]);
  return <></>;
};
