import { Box, Typography } from '@mui/material';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { getApiUrlForId } from '../../../api/endpoints';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { CopyIcon } from '../../../common/assets/newDesign/CopyIcon';
import QRCodeIconSmall from '../../../common/assets/newDesign/QRCodeIconSmall';
import { PlayFillIcon } from '../../../common/assets/PlayFillIcon';
import { StarIcon } from '../../../common/assets/StarIcon';
import { StarIconGray } from '../../../common/assets/StarIconGray';
import { TableContent, TableRow } from '../../../common/commonTypes';
import { Tooltip } from '../../../common/components/Tooltip/Tooltip';
import { DEFAULT_BRAND_COLOR } from '../../../common/constants/defaults';
import { WebsiteRoutes } from '../../../common/constants/routes';
import {
  RewardsPageStrings,
  RewardsStrings,
  ShortcutsMenuStrings,
} from '../../../common/localization/en';
import { getDateString, getInitial, getStringValue } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { isUWMClient } from '../../../utils/isUWMClient';
import ActionMenu from '../../rewards/CampaignSummary/ActionMenu/ActionMenu';
import { CustomTooltip } from '../../rewards/CampaignSummary/CampaignSummary.helper';
import { useStyles } from './Campaigns.styles';
import { LogoutIcon2 } from '../../../common/assets/newDesign/LogoutIcon2';
import { postEndIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import peekLogo from '../../../common/assets/peekLogo.png';

interface CampaignMapperProps<T> {
  items: T[];
  onOpenCampaign: () => void;
  isEditButton: boolean;
  isMobile: boolean;
  onSetPrimaryCampaign: (campaignId: string) => void;
  isPeekAccountConnected: boolean;
}

interface RedeemCampaignsContent extends TableRow {
  id: string;
  img?: JSX.Element;
  name: string | JSX.Element;
  videos: string | JSX.Element;
  creators: string | JSX.Element;
  rewardTitle: string | JSX.Element;
  createdByName: string | JSX.Element;
  summaryButton: JSX.Element | string;
}

type modeType = 'default' | 'branded';

export const CreateCampaignsTableContent = ({
  items,
  onSetPrimaryCampaign,
  isPeekAccountConnected,
}: CampaignMapperProps<CampaignsApiModel>): TableContent<RedeemCampaignsContent> => {
  const {
    venue,
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueLogo = venueProperties?.['dashboard.QRCode.config']?.logo;
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const qrColor = venueProperties?.['dashboard.QRCode.config']?.brandColor || venueBrandColor;
  const styles = useStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const qrOptions = (campaignLink: string, mode: modeType): Partial<Options> => {
    return {
      width: 430,
      height: 430,
      image: mode === 'branded' ? venueLogo : '',
      type: 'svg',
      dotsOptions: {
        color: mode === 'branded' ? qrColor || DEFAULT_BRAND_COLOR : '#000',
        type: 'extra-rounded',
      },
      cornersSquareOptions: {
        type: 'extra-rounded',
      },
      data: campaignLink,
      margin: 20,
      imageOptions: {
        crossOrigin: 'anonymous',
        imageSize: 0.5,
      },
    };
  };

  const handleDownload = (item: CampaignsApiModel, mode: modeType) => {
    const campaignLink = `https://${venue.subdomain}.socialv.io?cid=${item.shortcode}&qrcode=true`;

    const RESOLUTION = 1024;
    new QRCodeStyling({
      ...qrOptions(campaignLink, mode),
      width: RESOLUTION,
      height: RESOLUTION,
    }).download({ name: 'qr', extension: 'png' });
    addToast(RewardsPageStrings.QRCodeDownloaded, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  const actionMenu = (item: CampaignsApiModel) => {
    const options = [
      {
        label: ShortcutsMenuStrings.CopyLink,
        onClick: () => {
          //copy link
          const campaignLink = `https://${venue.subdomain}.socialv.io/?cid=${item.shortcode}`;
          navigator.clipboard.writeText(campaignLink);
          //event tracked by menu item click
          // add toast
          addToast(RewardsPageStrings.CampaignLinkCopied, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        },
        showForEndedCampaigns: true,
        icon: (
          <Box sx={{ color: '#8B89A0' }}>
            <CopyIcon />
          </Box>
        ),
      },
      {
        icon: (
          <Box sx={{ color: '#8B89A0' }}>
            <QRCodeIconSmall />
          </Box>
        ),
        label: ShortcutsMenuStrings.DownloadQrCode,
        onClick: () => {},
        showForEndedCampaigns: true,
        subItems: [
          {
            label: 'Default',
            onClick: () => {
              handleDownload(item, 'default');
            },
          },
          {
            label: 'Branded',
            onClick: () => {
              handleDownload(item, 'branded');
            },
          },
        ],
      },
    ];

    if (!(item.isPreferred || item.endedAt || isUWMClient())) {
      options.push({
        label: ShortcutsMenuStrings.SetPrimaryCampaign,
        onClick: () => {
          onSetPrimaryCampaign(item.id);
        },
        showForEndedCampaigns: false,
        icon: <StarIconGray />,
      });
    }

    if (!item.endedAt && !item.isPreferred) {
      options.push({
        label: ShortcutsMenuStrings.EndCampaign,
        onClick: () => {
          dispatch(postEndIncentiveCampaign(item.id)).then((res: any) => {
            if (res.error)
              return addToast(RewardsStrings.endCampaignErrorMessage, {
                appearance: 'error',
                autoDismissTimeout: 2500,
              });
            addToast(RewardsStrings.endCampaignMessage, {
              appearance: 'success',
              autoDismissTimeout: 2500,
            });
          });
        },
        showForEndedCampaigns: false,
        icon: <LogoutIcon2 />,
      });
    }

    return options;
  };

  const onNameClick = (id: string) => {
    history.push(getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, id));
  };

  const tableContent: TableContent<RedeemCampaignsContent> = {
    header: {
      name: {
        name: RewardsPageStrings.Name,
        sortable: false,
      },
      rewardTitle: {
        name: RewardsPageStrings.RewardTitle,
        sortable: true,
        justifyContent: 'start',
      },
      deliveredInvites: {
        name: RewardsPageStrings.DeliveredInvites,
        sortable: true,
        justifyContent: 'end',
      },
      creators: {
        name: RewardsPageStrings.Creators,
        sortable: true,
        justifyContent: 'end',
      },
      videos: {
        name: (
          <Tooltip
            title="Clips"
            icon={
              <Typography padding={0} marginBottom="-4px" fontWeight="600">
                <PlayFillIcon color="#8B89A0" />
              </Typography>
            }
          />
        ),
        sortable: true,
        justifyContent: 'end',
      },

      createdByName: {
        name: RewardsPageStrings.Owner,
        sortable: true,
        justifyContent: 'end',
      },

      actions: {
        name: '',
        sortable: false,
      },
    },
    rows: [],
  };

  if (items.length === 0) {
    return tableContent;
  }

  tableContent.rows = items.map((item, index) => {
    return {
      id: item.id,
      name: (
        <Box style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
          {item.isPreferred && (
            <>
              <CustomTooltip
                content={
                  <Box>
                    <Typography
                      color="inherit"
                      style={{ fontSize: '11px', fontWeight: '700', textAlign: 'left' }}
                    >
                      {RewardsStrings.PrimaryCampaignToolTipTitle}
                    </Typography>
                    <Typography
                      color="inherit"
                      style={{
                        textAlign: 'left',
                        fontSize: '11px',
                        fontWeight: '500',
                        marginTop: '8px',
                      }}
                    >
                      {RewardsStrings.PrimaryCampaignTooltip}
                    </Typography>
                  </Box>
                }
                icon={
                  <Box mt="7px">
                    <StarIcon />
                  </Box>
                }
              />
              {isPeekAccountConnected && (
                <Box width="25px" mr="5px">
                  <img src={peekLogo} alt="Peek logo" />
                </Box>
              )}
            </>
          )}
          <Box>
            <Typography
              onClick={() => onNameClick(item.id)}
              className={`${styles.cell} ${styles.name}`}
            >
              {getStringValue(item.name)}
            </Typography>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={styles.status}>
                {item.endedAt ? 'Inactive' : 'Active'} | &nbsp;
              </Typography>
              <Typography className={styles.date}>
                {`${!item.endedAt ? 'Started ' : ''}${getDateString(item.startedAt)}`}
                {item.endedAt ? ` - ${getDateString(item.endedAt)}` : ''}
              </Typography>
            </Box>
          </Box>
        </Box>
      ),
      rewardTitle: (
        <Typography fontSize={'13px'} paddingLeft="20px">
          {item.isNullReward ? '---' : item.rewardTitle || '---'}
        </Typography>
      ),
      deliveredInvites: item.deliveredInvites?.toString() || '---',
      creators: item.creators?.toString() || '---',
      videos: item.videos?.toString() || '---',

      createdByName: (
        <Box
          style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'end' }}
        >
          <CustomTooltip
            content={
              <Box>
                <Typography
                  color="inherit"
                  style={{ fontSize: '13px', fontWeight: '400', textAlign: 'left' }}
                >
                  {item.createdByName}
                </Typography>
              </Box>
            }
            icon={
              <Box className={styles.userCell}>
                <Typography display={'contents'} fontSize={'10px'} fontWeight={'500'}>
                  {item.createdByName ? getInitial(item.createdByName) : '---'}
                </Typography>
              </Box>
            }
            light={false}
          />
        </Box>
      ),
      summaryButton: item.endedAt ? (
        <Box></Box>
      ) : (
        <Box display={'flex'} alignItems={'center'} justifyContent="center" pr="32px">
          <ActionMenu
            title={''}
            items={actionMenu(item)}
            campaignId={item.id}
            isActiveCampaign
            isCampaignsTable
            howerWidth="5px"
            toggleOnClick={false}
          />
        </Box>
      ),
    };
  });

  return tableContent;
};
