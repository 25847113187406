import { FC } from 'react';
import { Grid, Typography } from '@mui/material';

import { PreviewContainer } from '../previews.style';
import { IphoneXframeDeprecated } from '../phonePreviewWrapper';
import { useEducationalCardsPreviewStyles } from './EducationCardsPreviewTopgolfTimeSlots.style';
import { TimeSlotsCaptionsValues } from '../../../../pages/webappSettings/WebAppCards/WebAppCards.helper';

export interface Cardprops {
  src: string;
  text: string;
  selected: boolean;
}

interface EducationCardsPreviewTopgolfTimeSlotsProps {
  image?: string;
  activeSlotsTime: string;
}

export const EducationCardsPreviewTopgolfTimeSlots: FC<
  EducationCardsPreviewTopgolfTimeSlotsProps
> = ({ image, activeSlotsTime = '10' }) => {
  const styles = useEducationalCardsPreviewStyles();

  return (
    <PreviewContainer className={styles.SlotPreviewContainer}>
      <IphoneXframeDeprecated>
        <div className={styles.ContentWrapperTopGolfTimeSlotsSection}>
          <Grid container className={styles.SlotContainer}>
            <Grid item className={styles.Slot}>
              <Typography className={styles.TypographySlot}>
                {TimeSlotsCaptionsValues[activeSlotsTime][0]}
              </Typography>
            </Grid>
            <Grid item className={[styles.Slot, styles.ActiveSlot].join(' ')}>
              <Typography className={styles.TypographySlot}>
                {TimeSlotsCaptionsValues[activeSlotsTime][1]}
              </Typography>
            </Grid>
            <Grid item className={styles.Slot}>
              <Typography className={styles.TypographySlot}>
                {TimeSlotsCaptionsValues[activeSlotsTime][2]}
              </Typography>
            </Grid>
          </Grid>
          {image && <img className={styles.SliderImage} src={image} alt="Preview" />}
        </div>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
