import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { alpha } from '@material-ui/core';
import { tooltipClasses } from '@mui/material/Tooltip';

export const useSocialVerseCardStyles = makeStyles((theme: Theme) => ({
  socialverse: {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      marginTop: '75px',
      marginLeft: '4px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.main,
    },
  },
  containerSV: {
    position: 'relative',
    marginBottom: '10px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '15px',
    height: 'fit-content',
    padding: '21px 20px 21px 40px',
    border: '1px solid #DCDBE4',
    overflow: 'visible',
  },
  innerContainerSV: {
    width: '100%',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  svButton: {
    padding: '10px 25px',
    borderRadius: '100px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  containedBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid transparent',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      '&>.MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  outlinedBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&>.MuiTypography-root': {
        color: theme.palette.common.white,
      },
    },
  },
  titleSV: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '40px',
    color: '#000000',
    whiteSpace: 'nowrap',
    letterSpacing: '0,02em',
  },
  blockTitle: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#000000',
    letterSpacing: '0,02em',
    lineHeight: '18px',
    marginBottom: '15px',
    textAlign: 'center',
  },
  subTitleSV: {
    fontSize: '14px',
    marginTop: -10,
  },
  btnsWrapper: {
    display: 'flex',
    alignItems: 'center',
    bottom: '0',
    left: '0',
    width: '100%',
  },
  cardBtn: {
    fontWeight: 400,
    fontSize: 14,
    whiteSpace: 'nowrap',
    minWidth: '120px',
    height: '46px',
    borderRadius: '25px',
  },
  storiesWrapper: {
    width: '100%',
    paddingTop: '25px',
  },
  divider: {
    height: 'auto',
    width: '1px',
    margin: '20px 0',
    backgroundColor: '#ccc',
  },
  blockWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  EmptyBoxSection: {
    padding: '5px 0 0 48px',
    overflowX: 'auto',
  },
  navigIcon: {
    position: 'absolute',
    top: '73px',
  },
  leftIcon: {
    left: '-10px',
  },
  rightIcon: {
    right: '-5px',
  },
  spheresContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  emptySpheres: {
    width: '132px',
    height: '132px',
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.primary.main}80`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '40px',
    color: theme.palette.primary.main,
    fontWeight: '400',
    cursor: 'pointer',
  },
  spherePreview: {
    width: 132,
    height: 132,
    borderRadius: '100%',
    position: 'relative',
    border: '1px solid transparent',
    background: '#ccc',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: '35px',
  },
  spheresPlayIcon: {
    position: 'absolute',
    top: '10px',
    left: '40px',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 52,
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: alpha('#fff', 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
    boxShadow: '0px 0px 4px 4px rgb(0 0 0 / 10%)',
  },
  previewText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '26px',
    color: '#000000',
  },
  storiesIcon: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
  },
  statusIsActive: {
    position: 'absolute',
    bottom: '-2px',
    right: '0px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    // backgroundColor: '#DDD4C8',
    backgroundColor: '#000',
    border: '1px solid #fff',
  },
  previewContentWrapper: {
    width: '100%',
    position: 'relative',
    borderRadius: '15px',
    maxHeight: '170px',
    height: '170px',
    border: '1px solid #ccc',
    '&:hover': {
      // transform: 'scale(1.1)',
      height: '190px',
    },
  },
  PreviewText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  EmptyBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    marginRight: '4px',
    border: `1px dashed ${theme.palette.primary.main}80`,
    borderRadius: '9px',
    fontSize: '35px',
    fontWeight: 400,
    lineHeight: '100%',
  },
  EmptyCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F8F8FC',
    color: theme.palette.primary.main,
    marginRight: '12px',
    border: `1px dashed ${theme.palette.primary.main}80`,
    borderRadius: '50%',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },

  editButton: {
    padding: 0,
    marginTop: 10,
    width: 50,
    height: 50,
    marginLeft: 0,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  nameEditInput: {
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '40px',
    color: '#000000',
    padding: '6px 24px',
    background: '#FAFAFA',
    border: '1px solid #DDDDDD',
    borderRadius: '8px',
    width: '350px',
  },
  lastSeenText: {
    fontSize: '11px',
    color: '#484848',
  },
  lastSeenUrl: {
    fontSize: '11px',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  tooltip: {
    backgroundColor: '#fff',
    padding: '15px 20px 10px 20px',
    maxWidth: '515px',
    boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
    borderRadius: '10px',
    color: '#667085',

    [`& .${tooltipClasses.arrow}`]: {
      color: '#fff',
      fontSize: '20px',
    },
  },
}));
