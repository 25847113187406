import { FC, MouseEvent, useEffect, useState } from 'react';
import { FormControl, Select, MenuItem, Box, Grid, SelectChangeEvent } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import clsx from 'clsx';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { updateVideoById } from '../../../store/slices/videosSlice';
import {
  useStyles,
  useIndicatorStyles,
  StatusValues,
  statuses,
  topGolfStatuses,
} from './StatusDropdown.Helper';
import { ClientTypes } from '../../../api/models/common';
import { updateTopgolfVideoById } from '../../../store/slices/topGolfVideosSlice';
import { UpdateVideoRequestOptions } from '../../../api/models/videos';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../constants/events/topGolfEvents';

export type Status = 'published' | 'unpublished' | 'moderated';

interface StatusDropdownProps {
  id: string;
  TG: boolean;
  publishedToWebapp: Status;
  webAppStatus?: boolean;
}

export const StatusDropdown: FC<StatusDropdownProps> = ({
  id,
  TG,
  publishedToWebapp,
  webAppStatus,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const indicatorClasses = useIndicatorStyles();
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const { trackEvent } = useTrackEvent();
  const isTopGolfVenue = clientType === ClientTypes.TOPGOLF;
  const [status, setStatus] = useState(publishedToWebapp);
  const updateVideo = (options: UpdateVideoRequestOptions) => {
    if (isTopGolfVenue) {
      dispatch(updateTopgolfVideoById(options));
      trackEvent(topGolfEvents.top_golf_video_slide_out_update_video_status, {
        videoId: options.id,
        value: options.update.publishedToWebapp ? 'published' : 'unpublished',
      });
    } else {
      dispatch(updateVideoById(options));
    }
  };
  const handleChange = (e: SelectChangeEvent) => {
    e.stopPropagation();
    const value = e.target.value;
    if (webAppStatus) {
      updateVideo({
        id,
        update: { publishedToWebapp: value === 'unpublished' ? false : true },
      });
    } else if (value !== publishedToWebapp) {
      if (value === 'unpublished' || value === 'moderated') {
        updateVideo({
          id: id,
          update: { status: `${value}`.toUpperCase(), featured: false },
        });

        return;
      }
      updateVideo({ id, update: { status: `${value}`.toUpperCase() } });
    }
    // else {
    // setStatus(e.target.value as Status);
    // }
  };
  useEffect(() => {
    // if (publishedToWebapp !== status) {
    setStatus(publishedToWebapp);
    // }
  }, [publishedToWebapp]);

  // const disableOptionIfPrivate = (isPublic = true, status: StatusValues): boolean => {
  //   return status === disabledOptionIfPrivate && !isPublic;
  // };

  const renderItemWithIndicator = (value: StatusValues) => {
    return (
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        className={classes[value]}
        spacing={2}
      >
        {status.toUpperCase() === value.toUpperCase() && (
          <Grid item xs={1}>
            <Box
              height={6}
              width={6}
              borderRadius="50%"
              justifyItems="center"
              alignItems="center"
              className={indicatorClasses[value]}
            ></Box>
          </Grid>
        )}

        <Grid item xs={10}>
          <span>
            {value}
            {value === 'moderated' && <ErrorOutlineIcon className={classes.moderatedIcon} />}{' '}
          </span>
        </Grid>
      </Grid>
    );
  };

  const options = TG ? topGolfStatuses : statuses;

  return (
    <FormControl
      variant="standard"
      className={classes.formControl}
      onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
    >
      <Select
        className={classes.formControl}
        value={status.toLowerCase()}
        onChange={handleChange}
        renderValue={(value) => renderItemWithIndicator(value as StatusValues)}
        onMouseUp={(e) => e.stopPropagation()}
        MenuProps={{
          sx: {
            zoom: 0.9,
            marginLeft: location.pathname === WebsiteRoutes.ContentAll ? '80px' : '',
          },
        }}
      >
        {options.map((status: StatusValues) => (
          <MenuItem
            key={status}
            className={clsx(classes.menuItem, [classes[status]])}
            onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
            value={status}
          >
            {renderItemWithIndicator(status as StatusValues)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
