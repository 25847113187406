import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '13px 32px 28px 20px',
    width: '100%',
    position: 'relative',
  },
}));
