export const RewardsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.00684 3.5H17.992V6.51238V9.47586C17.992 12.7749 15.3023 15.4494 11.9994 15.4494C8.68981 15.4494 6.00684 12.7832 6.00684 9.46824V6.48236V3.5Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M12 17.1201V21.1152" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.00488 21.1152H15.995" stroke="#8B89A0" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M17.992 5.49121C17.992 5.49121 20.4889 5.49121 20.9883 5.49121C21.4877 5.49121 21.9871 5.9891 21.9871 6.48698C21.9871 6.98487 22.4694 11.4659 17.4756 11.4659"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
      <path
        d="M6.00672 5.49121C6.00672 5.49121 3.50981 5.49121 3.01042 5.49121C2.51104 5.49121 2.01166 5.9891 2.01166 6.48698C2.01166 6.98487 1.5384 11.4659 6.53223 11.4659"
        stroke="#8B89A0"
        strokeWidth="1.5"
      />
    </svg>
  );
};
