import * as React from 'react';

export const ActivitiesEmails: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        fill="#7085CC"
        d="M0 8.095L11.473.322a1.802 1.802 0 012.054 0L25 8.095v12.67L0 19.346V8.095z"
      ></path>
      <path fill="#DBE6FC" d="M22.848 2.483H2.15v20.243h20.697V2.483z"></path>
      <path
        fill="#ABBEEF"
        d="M3.078 3.587c.109-.051.16-.192.115-.315s-.17-.181-.278-.13c-.108.05-.16.191-.115.314s.17.182.278.13zM21.777 3.365c0-.133.096-.241.213-.241.118 0 .213.108.213.24 0 .134-.095.242-.213.242-.117 0-.213-.108-.213-.241z"
      ></path>
      <path fill="#ABBEEF" d="M21.99 3.338H2.996v.053H21.99v-.053z"></path>
      <path
        fill="#ABBEEF"
        d="M3.02 3.268h-.047v21.498h.047V3.268zM22.021 3.268h-.046v21.498h.046V3.268z"
      ></path>
      <path fill="#8D9DD6" d="M25 8.06V25H0V8.06l12.5 8.47L25 8.06z"></path>
      <path fill="#7085CC" d="M0 25l11.45-9.291a1.633 1.633 0 012.1 0L25 25H0z"></path>
      <path
        fill="#ABBEEF"
        d="M16.316 5.286H8.771c-.11 0-.2-.102-.2-.226 0-.124.09-.225.2-.225h7.545c.11 0 .2.101.2.225s-.09.226-.2.226zM18.26 6.191H6.825c-.11 0-.199-.101-.199-.225s.09-.226.2-.226h11.432c.11 0 .2.102.2.226 0 .124-.09.225-.2.225zM16.777 8.997H8.505c-.08 0-.145-.073-.145-.163 0-.09.065-.163.145-.163h8.272c.079 0 .144.073.144.163 0 .09-.065.163-.144.163zM9.335 9.85H7.003c-.08 0-.145-.074-.145-.164 0-.09.065-.163.145-.163h2.332c.08 0 .144.073.144.163 0 .09-.065.163-.144.163zM12.853 9.85h-2.818c-.08 0-.144-.074-.144-.164 0-.09.065-.163.144-.163h2.818c.079 0 .144.073.144.163 0 .09-.065.163-.144.163zM18.278 9.85h-4.871c-.08 0-.144-.074-.144-.164 0-.09.065-.163.144-.163h4.871c.08 0 .145.073.145.163 0 .09-.065.163-.145.163zM12.871 10.84h-4.53c-.08 0-.145-.074-.145-.164 0-.09.065-.163.144-.163h4.531c.08 0 .145.074.145.163 0 .09-.065.163-.145.163zM16.94 10.84h-3.568c-.08 0-.145-.074-.145-.164 0-.09.066-.163.145-.163h3.569c.079 0 .144.074.144.163 0 .09-.065.163-.145.163z"
      ></path>
      <path
        fill="#6980CC"
        d="M14.144 12.854c-.272.256-.564.47-.871.665a.899.899 0 00.043-.274c-.005-.117-.088-.168-.17-.217a1.205 1.205 0 01-.06-.038.13.13 0 00.028-.139c-.035-.089-.133-.074-.167.008a.167.167 0 00-.01.044.105.105 0 01-.033-.028 5.44 5.44 0 00.142-.327c.01-.025-.003-.054-.02-.07-.073-.062-.173-.041-.231.037-.07.092-.052.233-.019.338l.008.022c-.065.132-.135.26-.21.384l-.02-.024a.514.514 0 01-.06-.08 1.28 1.28 0 00.065-.318c.004-.05-.04-.074-.076-.059-.101.043-.155.159-.142.278.004.041.016.08.033.117-.023.055-.05.109-.081.16a.06.06 0 00.018.084.887.887 0 01-.154.342c-.062.073-.178.13-.253.042-.048-.055-.054-.147-.06-.22a4.534 4.534 0 01-.023-.547.94.94 0 01.037-.253c.016-.05.038-.126.06-.04.01.043-.004.109-.008.152a.053.053 0 00.106.009c.008-.09.043-.28-.047-.337-.075-.047-.144.02-.179.091-.125.254-.075.603-.056.879.015.22.087.49.336.42.233-.066.32-.4.373-.629a.068.068 0 00-.002-.038.06.06 0 01.003-.006l.03.033a.679.679 0 01.039.043 4.51 4.51 0 01-.075.115c-.015.021-.01.053.004.073.078.107.22.028.217-.104a.221.221 0 00-.018-.082c.074-.12.143-.244.207-.371a.157.157 0 00.138.057c.027.032.061.06.093.08.03.018.064.03.092.056.05.046.035.1.024.166-.015.087-.039.174-.062.259-.106.07-.212.157-.248.296-.032.127-.003.261.093.34.029.024.067.004.08-.03a6.513 6.513 0 00.165-.528c.095-.06.194-.114.287-.177.247-.165.482-.354.704-.563.053-.05-.018-.14-.07-.09zm-1.29-.16c0-.048.022-.136.071-.135a5.254 5.254 0 01-.069.161.395.395 0 01-.001-.026zm.135 1.388c-.044-.095.02-.212.093-.289-.029.097-.06.194-.093.289z"
      ></path>
      <path fill="#AFCDFB" d="M3.184 18.515l2.85-1.828-2.6.174.2.497-.536.673.086.484z"></path>
      <path fill="#fff" d="M6.034 16.687l-2.987.62-2.175 1.71 5.162-2.33z"></path>
      <path fill="#fff" d="M3.434 16.86l2.6-.173-2.72-.902.12 1.076z"></path>
      <path
        fill="#FFB27D"
        d="M1.653 4.863s.34-.146.378-.246c.039-.1.15-.144.285-.214.135-.07.216-.196.235-.179.02.018.024.105-.03.17-.054.066-.283.524-.37.576-.085.053-.498-.107-.498-.107z"
      ></path>
      <path
        fill="#DBE6FC"
        d="M5.954 24H1.646c-.24 0-.435-.221-.435-.493v-1.33c0-.272.195-.492.435-.492h4.308c.24 0 .435.22.435.492v1.33c0 .272-.195.493-.435.493z"
      ></path>
      <path
        fill="#ABBEEF"
        d="M5.077 22.26H1.833c-.037 0-.066-.034-.066-.076 0-.04.03-.074.066-.074h3.244c.036 0 .066.033.066.075 0 .04-.03.074-.066.074zM2.408 22.581h-.575c-.037 0-.066-.033-.066-.075 0-.04.03-.074.066-.074h.575c.036 0 .066.033.066.075 0 .04-.03.074-.066.074zM4.22 22.58H2.636c-.036 0-.066-.032-.066-.074 0-.04.03-.074.066-.074h1.586c.036 0 .066.033.066.074 0 .041-.03.075-.066.075zM5.077 23.176H1.833c-.037 0-.066-.034-.066-.075 0-.041.03-.075.066-.075h3.244c.036 0 .066.034.066.075 0 .041-.03.075-.066.075zM3.01 23.498H1.833c-.037 0-.066-.033-.066-.075 0-.04.03-.074.066-.074H3.01c.036 0 .066.033.066.074 0 .041-.03.075-.066.075zM4.443 23.497H3.265c-.036 0-.066-.033-.066-.074 0-.041.03-.075.066-.075h1.178c.036 0 .066.034.066.075 0 .04-.03.074-.066.074z"
      ></path>
    </svg>
  );
};
