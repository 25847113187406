import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchTargetsType } from '../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../store';
import {
  resetNewFilter,
  setTarget,
  updateFilter,
} from '../../../../store/slices/searchFiltersSlice';
import { SearchFilterStrings } from '../../../localization/en';
import { DateRangeFilter, ReturnedDate } from '../../datePicker/DateRangeFilter';
import { FilterItems } from '../components/FilterItems';
import { FilterOptions } from '../components/filterOptions.helper';
import { FilterOptionsContainer } from '../components/FilterOptionsContainer';
import { ConfirmFilterCreationModal } from '../ConfirmModal/ConfirmFilterCreationModal';

const getSearchTarget: { [key: string]: SearchTargetsType } = {
  '/creators': 'USERS',
  '/rewards/leaderboard': 'INCENTIVE_CAMPAIGNS',
  '/content/all': 'VIDEOS',
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '360px',
    padding: '20px 20px 30px',
    zIndex: 200,
  },
  title: {
    fontSize: '18px',
    textAlign: 'start',
    color: theme.palette.common.black,
  },
  Button: {
    width: '140px',
    height: '45px',
    borderRadius: '100px',
    marginRight: '8px',
  },
}));

interface NewFilterProps {
  handleCancel: () => void;
  target: SearchTargetsType;
}

export const NewFilter: FC<NewFilterProps> = ({ handleCancel, target }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const updateFilterDate = (date: ReturnedDate | null) => {
    dispatch(updateFilter(date));
  };
  const handleOptionClick = (id: string) => {
    if (!selectedOptions.includes(id)) {
      setSelectedOptions([...selectedOptions, id]);
    }
  };
  const handleRemove = (id: string) => {
    setSelectedOptions([...selectedOptions.filter((item) => item !== id)]);
  };

  const handleClose = () => {
    dispatch(resetNewFilter());
    handleCancel();
  };

  const handleSave = () => {
    /* setIsConfirmModalOpen(true); */
    dispatch(setTarget(getSearchTarget[history.location.pathname]));
    history.push('/filters/id', { from: history.location.pathname });
  };
  const handleCloseConfirmationModal = () => {
    setIsConfirmModalOpen(false);
    handleClose();
  };

  return (
    <div className={classes.root}>
      <ConfirmFilterCreationModal
        open={isConfirmModalOpen}
        onClose={handleCloseConfirmationModal}
        target={target}
      />
      <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
        <Grid item xs={12}>
          <p className={classes.title}>{SearchFilterStrings.Filters}</p>
        </Grid>
        <Grid item xs={2}>
          <p>Dates</p>
        </Grid>
        <Grid item xs={9}>
          <DateRangeFilter updateRange={updateFilterDate} />
        </Grid>
        <Grid item xs={12}>
          <FilterItems selectedOptions={selectedOptions} handleRemove={handleRemove} />
        </Grid>
        <Grid item xs={12}>
          <FilterOptionsContainer
            filterOptions={FilterOptions[target]}
            selectedOptions={selectedOptions}
            handleClick={handleOptionClick}
            title="Add Filter Options"
            primary
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className={classes.Button}
            onClick={handleSave}
          >
            {SearchFilterStrings.ApplyFilter}
          </Button>
          <Button color="warning" className={classes.Button} onClick={handleClose}>
            {SearchFilterStrings.Cancel}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
