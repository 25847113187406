import { Box, Typography } from '@mui/material';
import React from 'react';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';
import { RewardsPageStrings } from '../../../../common/localization/en';
import { useTypedSelector } from '../../../../store';
import { useStyles } from './Stats.style';

type Props = {};

export const Stats = (_props: Props) => {
  const { campaignStats } = useTypedSelector((state) => state.campaigns);
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.conversionRate}>
        <Typography className={classes.conversionRateTitle}>
          {RewardsPageStrings.ConversionRate}
          <Box className={classes.tooltip}>
            <Tooltip
              width="283px important"
              rounded="12px"
              padding="12px"
              maxWidth="283px"
              content={
                <Box width="283px">
                  <Typography fontSize="12px" fontWeight="600" color="#344054" textAlign="start">
                    {RewardsPageStrings.ConversionRateTooltip}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="500" color="#667085">
                    {RewardsPageStrings.ConversionRateTooltipSubTitle}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Typography>
        <Typography className={classes.conversionRateValue}>
          {campaignStats?.cvr ? (Number(campaignStats?.cvr) * 100).toFixed(1) : '0.0'}%
        </Typography>
      </Box>
      <Box className={classes.conversionRateBySource}>
        <Typography className={classes.conversionRateTitle} style={{ minWidth: '234px' }}>
          {RewardsPageStrings.ConversionRatebySource}
          <Box className={classes.tooltip}>
            <Tooltip
              rounded="12px"
              padding="12px"
              width="343px"
              maxWidth="343px"
              content={
                <Box width="343px">
                  <Typography fontSize="12px" fontWeight="500" color="#667085" textAlign="start">
                    <Typography className={classes.tooltipBold}>
                      {RewardsPageStrings.SMS}
                    </Typography>{' '}
                    {RewardsPageStrings.SMSDescription}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="500" color="#667085">
                    <Typography className={classes.tooltipBold}>
                      {RewardsPageStrings.QRCode}
                    </Typography>{' '}
                    {RewardsPageStrings.QRCodeDescription}
                  </Typography>
                  <Typography fontSize="12px" fontWeight="500" color="#667085">
                    <Typography className={classes.tooltipBold}>
                      {RewardsPageStrings.URL}
                    </Typography>{' '}
                    {RewardsPageStrings.URLDescription}
                  </Typography>
                </Box>
              }
            />
          </Box>
        </Typography>
        <Box className={classes.conversionRateBySourceContainer}>
          <Box className={classes.conversionRateBySourceItem}>
            <Typography className={classes.conversionRateSub}>SMS</Typography>
            <Typography className={classes.conversionRateValue}>
              {!campaignStats?.cvrBySource?.SMS?.cvr
                ? '0.0'
                : (campaignStats?.cvrBySource?.SMS?.cvr * 100).toFixed(1)}
              %
            </Typography>
          </Box>
          <Box className={classes.conversionRateBySourceItem}>
            <Typography className={classes.conversionRateSub}>QR Code</Typography>
            <Typography className={classes.conversionRateValue}>
              {!campaignStats?.cvrBySource['QR_CODE']?.cvr
                ? '0.0'
                : (campaignStats?.cvrBySource['QR_CODE']?.cvr * 100).toFixed(1)}
              %
            </Typography>
          </Box>
          <Box className={classes.conversionRateBySourceItem}>
            <Typography className={classes.conversionRateSub}>URL</Typography>
            <Typography className={classes.conversionRateValue}>
              {!campaignStats?.cvrBySource?.URL?.cvr
                ? '0.0'
                : (campaignStats?.cvrBySource?.URL?.cvr * 100).toFixed(1)}
              %
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
