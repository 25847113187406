import { FC } from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useVideoStatusModalStyle } from './ChangeVideoStatusModal.style';
import { VideoStatusChangeModal } from '../../localization/en';

export interface ChangeStatusModalOptions {
  title: string;
  subtitle: string;
  descrPlaceholder: string;
}

interface ChangeVideoStatusModalProps {
  isModalOpen: boolean;
  handleCancel: () => void;
  handleConfirm: () => void;
  options: ChangeStatusModalOptions;
  approvalReason: string;
  setApprovalReason: (text: string) => void;
}

export const ChangeVideoStatusModal: FC<ChangeVideoStatusModalProps> = ({
  isModalOpen,
  handleCancel,
  handleConfirm,
  options,
  approvalReason,
  setApprovalReason,
}) => {
  const styles = useVideoStatusModalStyle();

  return (
    <Modal
      open={isModalOpen}
      onClose={handleCancel}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <Box className={styles.Modal}>
        <Typography className={styles.ModalTitle}>{options.title}</Typography>
        <Typography className={styles.ModalText}>{options.subtitle}</Typography>
        <textarea
          className={styles.DescriptionTextArea}
          name="description"
          placeholder={options.descrPlaceholder}
          onChange={(event) => setApprovalReason(event.target.value)}
          value={approvalReason}
        />

        <div className={styles.BtnWrapper}>
          <Button
            variant="outlined"
            className={[styles.ModalBtn, styles.ConfirmBtn].join(' ')}
            onClick={handleConfirm}
          >
            {VideoStatusChangeModal.ButtonConfirm}
          </Button>
          <Button variant="outlined" className={styles.ModalBtn} onClick={handleCancel}>
            {VideoStatusChangeModal.ButtonCancel}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
