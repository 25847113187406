import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '65px',
    marginLeft: '90px',

    [theme.breakpoints.down(1600)]: {
      marginLeft: 0,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    background: '#EBE9FF',
    borderRadius: '8px',
    width: 'fit-content',
    padding: '0 12px',

    [theme.breakpoints.down(1400)]: {
      height: 'fit-content',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '38px',
    fontWeight: 600,
    lineHeight: '50px',
    letterSpacing: '-0.76px',
    marginLeft: '8px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',

    [theme.breakpoints.down(1400)]: {
      textWrap: 'initial',
      whiteSpace: 'initial',
    },
  },
  welcomeText: {
    marginTop: '10px',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '48px',
    color: '#101828',
  },
  featuresText: {
    marginTop: '20px',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '162%',
    color: '#475467',
    maxWidth: '654px',
  },
  letsGoBtn: {
    marginTop: '40px',
    width: '259px',
    height: '54px',
    '& p': {
      letterSpacing: '0.28px',
    },
  },
}));
