import { FC, SetStateAction, useEffect, useState } from 'react';
import { Button, Grid, Theme, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';

import { Container } from './Venuewizard.style';
import { PreviewStep } from './wizardSteps/PreviewStep';
import { RewardsStep } from './wizardSteps/RewardsStep';
import { FiltersStep } from './wizardSteps/FiltersStep';
import { AccountSettingsStep } from './wizardSteps/AccountSettingsStep';
import { StepperWrapper } from './Venuewizard.style';
import { useAppDispatch, useTypedSelector } from '../../store';
import { WizardConfirmationModal } from './WizardConfirmationModal';
import { WizardCreationRoute } from '../../common/constants/routes';
import { createVenue } from '../../store/slices/venueCreationSlice';
import { StepperStrings } from '../../common/localization/en';
import {
  ValidationSchemaType,
  NewRewardSchema,
  mapProgramDataToVenueCreationOptions,
  AccountSetingsProps,
  FilterProps,
} from './WizzardStepperHelper';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [
    StepperStrings.AddUsers,
    StepperStrings.ChooseFilter,
    StepperStrings.SetReward,
    StepperStrings.Preview,
  ];
}

const generateSubdomain = (subdomain: string) => {
  //remove special characters except space and split by space
  let words = subdomain.replace(/[^a-zA-Z ]/g, '').split(' ');
  return words
    .map((item, index) => {
      if (!index) return item.toLowerCase();
      else {
        return item.toLowerCase().charAt(0);
      }
    })
    .join('');
};

function getStepContent(
  step: number,
  step1State: AccountSetingsProps[],
  setStep1State: (value: SetStateAction<AccountSetingsProps[]>) => void,
  filterStepState: FilterProps[],
  setFilterStepState: (value: SetStateAction<FilterProps[]>) => void,
  rewardStepState: NewRewardSchema,
  setRewardsStepState: (value: SetStateAction<NewRewardSchema>) => void,
  subdomain: string,
  setSubdomain: (value: SetStateAction<string>) => void,
) {
  switch (step) {
    case 0:
      return <AccountSettingsStep accounts={step1State} setStep1State={setStep1State} />;
    case 1:
      return (
        <FiltersStep filterStepState={filterStepState} setFilterStepState={setFilterStepState} />
      );
    case 2:
      return (
        <RewardsStep setRewardsStepState={setRewardsStepState} rewardStepState={rewardStepState} />
      );
    case 3:
      return (
        <PreviewStep
          rewardStepState={rewardStepState}
          filterStepState={filterStepState}
          subdomain={subdomain}
          setSubdomain={setSubdomain}
        />
      );
    default:
      return <div></div>;
  }
}

export const WizardStepper: FC = () => {
  const history = useHistory();
  const program_data = useTypedSelector((state) => state.venueCreation.program_payload);
  const subdomainData = useTypedSelector((state) => state.venueCreation.subdomain);
  const filters = useTypedSelector((state) => state.venueCreation.filterUrls);
  const step1Props = program_data.userAccounts.map((account) => {
    return {
      ...account,
      submited: false,
      password: '',
    };
  });

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [step1State, setStep1State] = useState<AccountSetingsProps[]>(step1Props);
  const [rewardStepState, setRewardsStepState] = useState({
    image: '',
    name: '',
  });
  const [filterStepState, setFilterStepState] = useState<FilterProps[]>([
    { url: filters[0] || '', isActive: false },
    { url: filters[1] || '', isActive: true },
    { url: filters[2] || '', isActive: false },
  ]);
  const [subdomain, setSubdomain] = useState(generateSubdomain(program_data.programName));
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const steps = getSteps();

  useEffect(() => {
    let newFilters = [
      ...filterStepState.map((item, index) => ({
        ...item,
        url: filters[index] || '',
      })),
    ];
    setFilterStepState(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleNext = () => {
    if (activeStep === 3) {
      handleOpenConfirmationModal();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const validateStep1 = () => {
    if (step1State.filter((el) => !el.submited && !el.isRegistered).length) {
      return false;
    }
    return true;
  };

  const validateStep2 = () => {
    return true;
  };

  const validateStep3 = () => {
    return rewardStepState.name && rewardStepState.image ? true : false;
  };

  const validateStep4 = () => {
    return subdomainData.checked && subdomainData.available;
  };

  const validationSchema = {
    0: validateStep1,
    1: validateStep2,
    2: validateStep3,
    3: validateStep4,
  };

  const validateSteps = (step: 0 | 1 | 2 | 3, validationSchema: ValidationSchemaType) => {
    let validation = validationSchema[step];
    return validation();
  };

  const handleCloseModal = () => {
    setIsConfirmationModalOpen(false);
  };

  const handleConfirmCreateVenu = () => {
    // do something with data collected then
    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    let options = mapProgramDataToVenueCreationOptions({
      program_data,
      step1State,
      rewardStepState,
      filterStepState,
      subdomain,
    });
    dispatch(createVenue(options));
    handleCloseModal();
    history.push(WizardCreationRoute.WizardSuccess);
  };

  const handleOpenConfirmationModal = () => {
    setIsConfirmationModalOpen(true);
  };

  return (
    <Container>
      <StepperWrapper>
        <WizardConfirmationModal
          handleConfirm={handleConfirmCreateVenu}
          isModalOpen={isConfirmationModalOpen}
          handleCloseModal={handleCloseModal}
        />
        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
          <Grid item xs={12}>
            <div className={classes.root}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              <div>
                {activeStep === steps.length ? (
                  <div>
                    <Typography className={classes.instructions}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Button onClick={handleReset} className={classes.button}>
                      Reset
                    </Button>
                  </div>
                ) : (
                  <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
                    <Grid item xs={11}>
                      {getStepContent(
                        activeStep,
                        step1State,
                        setStep1State,
                        filterStepState,
                        setFilterStepState,
                        rewardStepState,
                        setRewardsStepState,
                        subdomain,
                        setSubdomain,
                      )}
                    </Grid>
                    <Grid item xs={10}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={!validateSteps(activeStep as 0 | 1 | 2 | 3, validationSchema)}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </StepperWrapper>
    </Container>
  );
};
