import { FC, useEffect, useRef, useState } from 'react';
import { CircularProgress, InputAdornment, TextField } from '@mui/material';
import { SearchBarContainer } from './SearchBar.style';
import { useAppDispatch, useTypedSelector } from '../../../store';
import CloseIcon from '@mui/icons-material/Close';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';
import { RewardCampaignsEvents } from '../../constants/events/rewardCampaignsEvents';
import { MagnifierIcon } from '../../assets/MagnifierIcon';
import getProperty from 'lodash/get';
import { SearchResultsList } from '../SearchResultsList/SearchResultsList';
import { getDebouncedFunc } from '../../../services/utilities';

type Entity =
  | 'heroes'
  | 'videos'
  | 'campaigns'
  | 'OrganizationVenueSlice'
  | 'TopgGolfVideos'
  | 'smsMessages'
  | 'campaigns.campaignActiveInvitations'
  | 'campaigns.campaignUnsentInvitations'
  | 'campaigns.campaignQueuedInvitations';

export interface SearchBarProps {
  entity: Entity;
  updateSearch: Function;
  selfSearch?: string;
  adaptiveWidth?: boolean;
  disabled?: boolean;
  placeholderText?: string;
  callUpdateSearchAsFunc?: boolean;
  borderColor?: string;
  clearSearch?: string | number | boolean;
  onSearchChanged?: (search: string) => void;
  className?: string;
  getPredictiveSearchItems?: (search: string) => Promise<string[]>;
}

export const SearchBar: FC<SearchBarProps> = ({
  entity,
  updateSearch,
  selfSearch,
  adaptiveWidth,
  disabled,
  placeholderText,
  callUpdateSearchAsFunc,
  borderColor = '#F2F4F7',
  clearSearch,
  onSearchChanged,
  className,
  getPredictiveSearchItems,
}) => {
  const dispatch = useAppDispatch();

  const entityStore = useTypedSelector((state) => getProperty(state, entity));
  const search = entityStore.search || '';

  const containerRef = useRef<HTMLFormElement | null>(null);

  const [searchValue, setSearchValue] = useState('');
  const [predictiveSearchItems, setPredictiveSearchItems] = useState<string[]>([]);
  const [isPredictiveItemsLoading, setIsPredictiveItemsLoading] = useState(false);

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    setSearchValue(selfSearch || search);
  }, [search, selfSearch]);

  const callSearch = (val: string) => {
    if (callUpdateSearchAsFunc) {
      updateSearch(val);
    } else {
      dispatch(updateSearch(val));
    }
  };

  const getPredictiveSearchItemsDebounced = getDebouncedFunc(async (value) => {
    if (!value) {
      setPredictiveSearchItems([]);
      return;
    }

    setIsPredictiveItemsLoading(true);

    const predictiveSearchItems = getPredictiveSearchItems
      ? await getPredictiveSearchItems(value)
      : [];

    setIsPredictiveItemsLoading(false);
    setPredictiveSearchItems([...new Set(predictiveSearchItems)]);
  }, 800);

  const changeSearchValue = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value);
    getPredictiveSearchItemsDebounced(value);
    onSearchChanged?.(value);

    if (value.length === 0) {
      callSearch('');
      setPredictiveSearchItems([]);
    }

    const entitiesLiteral: Record<Entity, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarTyped,
      campaigns: CreatorsTableEventNames.CampaignsTableSearchbarTyped,
      videos: CreatorsTableEventNames.VideosTableSearchbarTyped,
      OrganizationVenueSlice: CreatorsTableEventNames.VenuesTableSearchbarTyped,
      TopgGolfVideos: CreatorsTableEventNames.TopgGolfVideosTableSearchbarTyped,
      smsMessages: CreatorsTableEventNames.SmsMessagesTableSearchbarTyped,
      'campaigns.campaignActiveInvitations': CreatorsTableEventNames.InviteesTableSearchbarTyped,
      'campaigns.campaignUnsentInvitations':
        CreatorsTableEventNames.SavedForLaterTableSearchbarTyped,
      'campaigns.campaignQueuedInvitations':
        CreatorsTableEventNames.QueuedInvitationsTableSearchbarTyped,
    };

    trackEvent(entitiesLiteral[entity], { value });
  };

  const submitSearch = () => {
    callSearch(searchValue);

    const entitiesLiteral: Record<string, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarSubmitClick,
      campaigns: RewardCampaignsEvents.RewardCampaignsTableSearchbarSubmitClick,
    };

    if (entitiesLiteral[entity]) trackEvent(entitiesLiteral[entity], { value: searchValue });
  };

  const resetSearch = () => {
    setSearchValue('');
    callSearch('');
    getPredictiveSearchItemsDebounced('');

    const entitiesLiteral: Record<string, string> = {
      heroes: CreatorsTableEventNames.CreatorTableSearchbarResetClick,
      campaigns: RewardCampaignsEvents.RewardCampaignsTableSearchbarResetClick,
    };

    if (entitiesLiteral[entity] && searchValue) trackEvent(entitiesLiteral[entity]);
  };

  return (
    <SearchBarContainer
      noValidate
      adaptiveWidth={adaptiveWidth}
      onSubmit={(event) => {
        //for Safari browser, will not be executed on Chrome
        event.preventDefault();
        submitSearch();
      }}
      disabled={disabled}
      className={className}
      id="search-input"
      ref={containerRef}
    >
      {/* <fieldset disabled={disabled}> */}
      <TextField
        disabled={disabled}
        value={searchValue}
        onChange={(e) => {
          changeSearchValue(e);
        }}
        variant="filled"
        placeholder={placeholderText || 'Search'}
        size={'small'}
        sx={{
          fontSize: '14px !important',
          fontWeight: '400 !important',
          '& ::placeholder': {
            fontSize: '14px',
            fontWeight: '400',
          },
        }}
        style={borderColor ? { border: `1px solid ${borderColor}`, borderRadius: '100px' } : {}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MagnifierIcon />
            </InputAdornment>
          ),
          endAdornment: isPredictiveItemsLoading ? (
            <CircularProgress size={20} sx={{ position: 'absolute', top: '12px', right: '18px' }} />
          ) : (
            searchValue && (
              <InputAdornment
                onClick={resetSearch}
                position="start"
                style={{
                  cursor: 'pointer',
                }}
              >
                <CloseIcon style={{ fontSize: '16px' }} />
              </InputAdornment>
            )
          ),
        }}
      />

      <SearchResultsList
        anchorEl={containerRef.current}
        searchItems={predictiveSearchItems}
        clearItems={() => {
          setPredictiveSearchItems([]);
        }}
        onClick={(searchText) => {
          setSearchValue(searchText);
          callSearch(searchText);
        }}
        topOffset="47px"
      />

      {/* </fieldset> */}
    </SearchBarContainer>
  );
};
