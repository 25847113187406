import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1001,
  },
  counter: {
    width: '32px',
    height: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F2F4F7',
    color: '#5242EA',
    fontSize: '12px',
    fontWeight: 500,
    borderRadius: '16px',
    paddingTop: '1px',
  },
  button: {
    padding: 0,
    borderRadius: '50%',
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  svListWrapper: {
    position: 'absolute',
    bottom: '52px',
    right: '-30px',
  },
  svList: {
    position: 'relative',
    background: '#fff',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    width: 'fit-content',
    padding: '12px',
    borderRadius: '8px',
    minWidth: '280px',
  },
  arrow: {
    borderTop: '10px solid #fff',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',
    position: 'absolute',
    width: '0',
    height: '0',
    right: '40px',
    bottom: '-9px',
    zIndex: 1001,
  },
  linkWrapper: {
    minWidth: '230px',
  },
  svLink: {
    width: 'fit-content',
    fontSize: '11px',
    color: '#5242EA',
    fontWeight: 700,
    display: 'block !important',
    lineHeight: '22px',
  },
}));
