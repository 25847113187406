import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';

import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  OutlinedInput,
  Typography,
} from '@mui/material';

import { VideoEditingStrings, VideoSharedScreenStrings } from '../../../common/localization/en';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { getVideoFilters } from '../../../store/slices/filtersSlice';
import { patchVenueProperties } from '../../../store/slices/venueSlice';

import { ClientTypes } from '../../../api/models/common';
import { DefaultVideoDetailsEditable } from '../../../api/models/venue';

// import { EnterTagComponent } from '../../../common/components/EnterTagComponent/EnterTagComponent';
import { AccordionNumbered } from '../../../common/components/AccordionNumbered/AccordionNumbered';
import { useControlModal } from '../../../services/hooks/useControlModal';
import { filterLogoPositions } from '../DownloadedVideo/DownloadVideo';
import { FTEModal } from '../FTEModal/FTEModal';
import { IPhoneVideoPlaybackPreview } from '../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { ShareThumbnailPreview } from './ShareThumbnailPreview';
import { useStyles, validationSchema } from './VideoEditing.helper';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';

export const VideoEditing: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    venue: { id, logo, clientType },
    venueAttributes: { properties: venueProperties, isLoading },
  } = useTypedSelector((state) => state.venue);
  const venue = useTypedSelector((state) => state.venue);

  const [hideFirstTimeActionBar, setHideFirstTimeActionBar] = useState(true);

  const defaultStoryValues = venueProperties?.['webapp.default-story-details'];
  const defaultIncentiveConfig = venueProperties?.['webapp.firstTimeOnboardingFinished.config'];
  const filterOverlayEnabled = venueProperties?.['webapp.config']['filter-overlay-enabled'];
  const filterLogoPosition = venueProperties?.['webapp.config']['filter-logo-position'];
  const filterOverlay = venueProperties?.['webapp.config']['filter-overlay-url'];
  const filterLogoEnabled = venueProperties?.['webapp.config']['filter-logo-enabled'];
  const isTopgolf = clientType === ClientTypes.TOPGOLF;

  const initalValue = {
    metaTitle: defaultStoryValues?.webAppSettings?.metaTitle || false,
    videoText: defaultStoryValues?.webAppSettings?.videoText || false,
    ctaBtnText: defaultStoryValues?.webAppSettings?.ctaBtnText || false,
    ctaBtnLinkUrl: defaultStoryValues?.webAppSettings?.ctaBtnLinkUrl || false,
    videoMessage: defaultStoryValues?.webAppSettings?.videoMessage || false,
  };

  const [state, setState] = useState(initalValue);
  const [isOpenModal, toggleModal] = useControlModal();
  // const [accordionsState, setAccordionsState] = useState<AccordionsState>({
  //   firstStage: true,
  //   secondStage: false,
  //   thirdStage: true,
  // });

  useEffect(() => {
    if (id) {
      dispatch(getVideoFilters({ venueId: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setState({
      metaTitle: defaultStoryValues?.webAppSettings?.metaTitle || false,
      videoText: defaultStoryValues?.webAppSettings?.videoText || false,
      ctaBtnText: defaultStoryValues?.webAppSettings?.ctaBtnText || false,
      ctaBtnLinkUrl: defaultStoryValues?.webAppSettings?.ctaBtnLinkUrl || false,
      videoMessage: defaultStoryValues?.webAppSettings?.videoMessage || false,
    });
  }, [venueProperties, defaultStoryValues]);

  useEffect(() => {
    if (
      venueProperties &&
      (!venueProperties?.['webapp.firstTimeOnboardingFinished.config'] ||
        !venueProperties?.['webapp.firstTimeOnboardingFinished.config']?.webapp?.webapp_sharing)
    ) {
      setHideFirstTimeActionBar(false);
    }
  }, [venueProperties]);

  const { handleSubmit, values, handleChange, dirty, errors, resetForm } = useFormik({
    initialValues: {
      editable: defaultStoryValues?.editable ?? DefaultVideoDetailsEditable.Full,
      metaTitle: defaultStoryValues?.metaTitle ?? VideoEditingStrings.DefaultMetaTitle,
      videoText: defaultStoryValues?.videoText ?? VideoEditingStrings.DefaultVideoText,
      ctaBtnText: defaultStoryValues?.ctaBtnText ?? VideoEditingStrings.DefaultCTABtnText,
      ctaBtnLinkUrl: defaultStoryValues?.ctaBtnLinkUrl ?? VideoEditingStrings.DefaultCTABtnLink,
      videoMessage: defaultStoryValues?.videoMessage ?? '',
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit: (values) => {
      dispatch(
        patchVenueProperties({
          id,
          update: {
            name: 'webapp.default-story-details',
            value: { ...values, webAppSettings: state, ...transformedUrl },
          },
        }),
      );
      if (hideFirstTimeActionBar === false) {
        setHideFirstTimeActionBar(true);
        handleActionBarClicked();
      }
    },
  });

  const transformedUrl = {
    ctaBtnLinkUrl: values.ctaBtnLinkUrl.includes('http')
      ? values.ctaBtnLinkUrl
      : `https://${values.ctaBtnLinkUrl}`,
  };

  const handleActionBarClicked = () => {
    dispatch(
      patchVenueProperties({
        id,
        update: {
          name: 'webapp.firstTimeOnboardingFinished.config',
          value: {
            ...defaultIncentiveConfig,
            webapp: {
              ...defaultIncentiveConfig?.webapp,
              webapp_sharing: true,
            },
          },
        },
      }),
    );
    history.push('/settings/webapp/downloaded-video');
  };

  const resetAll = () => {
    resetForm();
    setState(initalValue);
    if (hideFirstTimeActionBar === false) {
      setHideFirstTimeActionBar(true);
      handleActionBarClicked();
    }
  };

  const isError =
    errors.ctaBtnLinkUrl?.length ||
    errors.ctaBtnText?.length ||
    errors.metaTitle?.length ||
    errors.videoMessage?.length
      ? true
      : false;

  // const [checked, setChecked] = useState(false);
  // const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <>
      <FTEModal openModal={isOpenModal} toggleModal={toggleModal} />
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={6} md={6} className={classes.wrapper}>
            <Grid container>
              <Grid item xs={12} md={12} alignItems="center">
                <p className={classes.ColumnTitle}>{VideoSharedScreenStrings.Intro}</p>
                <Divider />
              </Grid>

              <Grid
                item
                xs={12}
                className={[classes.DisableAccordion, classes.contentWrapper].join(' ')}
              >
                <AccordionNumbered
                  tooltip={VideoSharedScreenStrings.FirstStageTooltip}
                  number={null}
                  title={VideoSharedScreenStrings.SecondStage}
                  isExpanded={true}
                  margin="10px"
                  toggleAccordion={() => {
                    // setAccordionsState((prevState) => ({
                    //   ...prevState,
                    //   secondStage: !prevState.secondStage,
                    // }))
                  }}
                >
                  <Grid>
                    <Box>
                      <Typography style={{ marginTop: '-8px' }} className={classes.StageTitle}>
                        {VideoSharedScreenStrings.ButtonTitle}
                      </Typography>
                      <OutlinedInput
                        className={classes.input}
                        value={values.ctaBtnText}
                        error={!!errors.ctaBtnText}
                        name="ctaBtnText"
                        onChange={handleChange}
                        fullWidth
                      />
                      {!!errors.ctaBtnText && (
                        <FormHelperText error id="ctaBtnText-error">
                          {errors.ctaBtnText}
                        </FormHelperText>
                      )}
                    </Box>
                    <Typography className={classes.StageTitle}>
                      {VideoSharedScreenStrings.CTADestination}
                    </Typography>

                    <OutlinedInput
                      className={classes.input}
                      id="input-cta-url"
                      name="ctaBtnLinkUrl"
                      value={values.ctaBtnLinkUrl}
                      error={!!errors.ctaBtnLinkUrl}
                      onChange={handleChange}
                      fullWidth
                    />
                    {errors.ctaBtnLinkUrl && (
                      <FormHelperText id="input-cta-url-helper-text" error>
                        {errors.ctaBtnLinkUrl}
                      </FormHelperText>
                    )}

                    <Box className={classes.EditWrapper}></Box>
                  </Grid>
                </AccordionNumbered>
              </Grid>
              {/* hided video caption */}
              {/* <Grid
                item
                xs={12}
                style={{ paddingTop: '0px', paddingLeft: '38px', paddingRight: '8px' }}
                ref={fieldRef}
                className={classes.DisableAccordion}
              >
                <AccordionNumbered
                  tooltip={VideoSharedScreenStrings.SecondStageTooltip}
                  number={2}
                  margin="10px"
                  title={VideoSharedScreenStrings.ThirdStage}
                  // isExpanded={accordionsState.thirdStage}
                  isExpanded={true}
                  toggleAccordion={() => {
                    // setAccordionsState((prevState) => ({
                    //   ...prevState,
                    //   thirdStage: !prevState.thirdStage,
                    // }));
                  }}
                >
                  <>
                    <CustomTextField
                      value={values.videoMessage}
                      error={errors.videoMessage}
                      name="videoMessage"
                      handleChange={handleChange}
                      charcherLimit={20}
                      label={''}
                    />

                    <Box className={classes.EditWrapper} style={{ justifyContent: 'end' }}>
                      <Typography>{VideoEditingStrings.MaximumCharacters20}</Typography>
                    </Box>
                  </>
                </AccordionNumbered>
              </Grid> */}
            </Grid>

            <BottomActionBar
              show={dirty}
              firstTimeExperience={!hideFirstTimeActionBar}
              onSaveClick={handleSubmit}
              onCancelClick={resetAll}
              disableSaveButton={isError || !values.ctaBtnLinkUrl}
              loading={isLoading}
            />
          </Grid>

          <Grid
            item
            xs={6}
            md={6}
            //  className={classes.new}
          >
            <Box display="flex" justifyContent="end" width="100%" position="relative">
              {/* hided switch between previews */}
              {/* <ToggleSwitchPreview status={checked} handleToggle={handleCheckedChange} /> */}
              <IconButton
                onClick={toggleModal}
                style={{
                  backgroundColor: '#fff',
                  color: '#8B89A0',
                  marginTop: '30px',
                  marginRight: '30px',
                }}
              >
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Box>
            <>
              {false ? (
                <ShareThumbnailPreview
                  logo={logo}
                  venue={venue.venue.name}
                  url={venue.venue.subdomain}
                  isTopGolf={isTopgolf}
                  postText={values.videoMessage}
                />
              ) : (
                <div>
                  <IPhoneVideoPlaybackPreview
                    tempFilterOverlayUrl={filterOverlay || 'cfc'}
                    logoPosition={filterLogoPosition || filterLogoPositions.bottomRight}
                    tempFilterLogoEnabled={filterLogoEnabled || false}
                    tempFilterOverlayEnabled={filterOverlayEnabled || false}
                    buttonText={values.ctaBtnText}
                    logo={logo}
                    isViewForTopGolf={isTopgolf}
                  />
                </div>
              )}
            </>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
