import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
  },
  stepper: {
    '& .MuiStepIcon-root': {
      color: 'white',
      fontSize: '30px',
      border: '1px solid #DCDBE4',
      borderRadius: '50%',
    },
    '& .MuiStepIcon-text': {
      fill: '#1A1538',
    },

    '& .MuiStepContent-root, .MuiStepConnector-root': {
      border: 'none',
    },
    '& .MuiStepConnector-lineVertical': {
      border: 'none',
    },
  },
  step: {
    position: 'relative',
    '& :hover': {
      cursor: 'pointer',
    },
  },
  stepLabel: {
    position: 'relative',
    width: '100%',

    '& .MuiStepLabel-label': {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  labelText: {
    fontSize: '16px',
    fontWeight: '500',
    // letterSpacing: '0.02em',
    color: '#1A1538',
    lineHeight: '30px',
  },
  expandIcon: {
    cursor: 'pointer',
    transform: 'rotate(270deg)',
    marginRight: '10px',
  },

  stepDivider: {
    borderBottom: '1px solid #DCDBE4 !important',
    position: 'absolute',
    width: '100%',
    left: '0',
    bottom: '-15px',
  },
  tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    margin: '0 0 -2px 10px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  stepContent: {
    paddingLeft: 0,
    marginLeft: 0,
  },
}));
