import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useNoSocialVersesStyles = makeStyles((theme: Theme) => ({
  containerNewSV: {
    border: '1px solid #DCDBE4',
    borderRadius: '15px',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    padding: '45px 0',
  },
  wrapperNewSV: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  titleCreateNewSV: {
    fontSize: '25px',
    fontWeight: 500,
    lineHeight: '30px',
    letterSpacing: '0.5px',
    color: theme.palette.common.deepDark,
    marginBottom: 15,
  },
  textContentNewSV: {
    fontSize: '16px',
    lineHeight: '162%',
    letterSpacing: '0.32px',
    color: theme.palette.common.gray,
    marginBottom: '30px',
  },
  imageContainer: {
    width: '782px',
    marginBottom: '25px',
  },
  socialvImage: {
    objectFit: 'contain',
  },
  textContentCustomers: {
    color: '#475467',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    textAlign: 'center',
    marginBottom: '11px',
  },
  tabTitle: {
    fontSize: '23px',
    fontWeight: '500',
    lineHeight: '24px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    marginBottom: '11px',
  },
  SVTab: {
    textTransform: 'none',
    padding: '24px 16px 0px 16px',
    gap: '20px',
    flex: '1 0 0',
    color: '#101828',
    fontWeight: 600,
    borderTop: '4px solid #F2F4F7',
  },
  CarouselWrapper: {
    '& >div': {
      paddingBottom: '0px',
      '& >div': {
        display: 'none',
        '&:nth-child(3)': {
          '& >div': {
            minHeight: 'initial !important',
          },
        },
      },
      '&:nth-child(1)': {
        '& img': {
          objectFit: 'contain',
        },
      },
    },
  },
  CarouselTab: {
    transition: 'all 0.5s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
    width: '782px',
    padding: '10px',
    gap: '11px',
    borderRadius: '15px',
    background: '#F5F6F8',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '25px',
  },
  svButtonCreate: {
    padding: '10px 25px 10px 20px',
    width: '248px',
  },
  noVideosButton: {
    color: '#344054',
    fontWeight: 600,
    padding: '10px 18px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    borderRadius: '50px',
  },
  svButtonWrapper: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  carouselWrapper: {
    position: 'relative',
  },
  qsMarkerWrapper: {
    textAlign: 'left',
    position: 'absolute',
    right: '100%',
    bottom: '10px',
  },
}));
