import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '675px',
    height: '280px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    overflow: 'hidden',
  },
  modalWrapper: {
    width: '100%',
    height: '100%',
    background: '#fff',
    transition: 'all 0.2s ease-in-out',
    boxShadow:
      '0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px -12px 24px -6px rgba(16, 24, 40, 0.18)',
  },
  closeIconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px 40px 0 0',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  title: {
    marginLeft: '56px',
    color: '#101828',
    fontWeight: 600,
    lineHeight: '28px',
    fontSize: '18px',
  },
  select: {
    width: '400px',
    height: '44px',
    background: '#fff',
    marginLeft: '56px',
    marginRight: '20px',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& svg': {
      marginRight: '10px',
      width: '20px',
    },
    '& .MuiSelect-select': {
      color: '#667085',
      fontSize: '16px',
      fontWeight: '400',
    },
  },
  menuWrapper: {
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
    transform: 'translate(0px, 103px) !important',
    height: '100px',
    overflow: 'auto',
    zoom: '0.9',
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '5px',
    },
  },
  selectItem: {
    background: 'white !important',
    padding: '10px 30px',
    color: 'gray',
    '&:hover': {
      background: '#F5F6F8 !important',
    },
  },
  dropdownIcon: {
    top: '9px',
    right: '7px',
  },
  dropdownIconOpen: {
    top: '7px',
    right: '20px',
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
  },
  loadingWrapper: {
    width: '560px',
    margin: '0 auto',
    padding: '16px',
    position: 'relative',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '12px',
  },
  fileName: {
    fontSize: '14px',
    color: '#344054',
    fontWeight: 500,
    maxWidth: '500px',
    overflowWrap: 'anywhere',
  },
  uploadTitle: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
    margin: '0 0 20px 50px',
  },
  fileSize: {
    fontSize: '14px',
    color: '#475467',
    fontWeight: '400',
  },
  progressWrapper: {
    marginTop: '10px',
  },
  progress: {
    width: '100%',
    height: '8px',
    borderRadius: '4px',
    backgroundColor: '#EAECF0',
  },
  tryAgainButton: {
    fontSize: '14px',
    fontWeight: '600',
    padding: '0',
    marginTop: '4px',
  },
  completedIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  deleteIcon: {
    position: 'absolute',
    top: '8px',
    right: '8px',
  },
}));
