import { FC } from 'react';

import { PreviewContainer } from '../previews.style';
import {
  WelcomePopupImage,
  WelcomePopupCTAContainer,
  BrandButton,
  WelcomePopupVideo,
  LogoSection,
} from './BrandingStylePreview.style';
import { IphoneXframeDeprecated } from '../phonePreviewWrapper';
import { BrandStylingPreviewStrings } from '../../../localization/en';
import { ContentWrapper } from '../EducationalCardsPreview/EducationalCardsPreview.style';

interface BrandingStylePreviewProps {
  src?: string;
  brandColor?: string;
  textColor?: string;
  logo?: string;
  bulletPoints?: string[];
}

export const BrandingStylePreview: FC<BrandingStylePreviewProps> = ({
  src,
  brandColor,
  textColor,
  logo,
}) => {
  return (
    <PreviewContainer>
      <IphoneXframeDeprecated>
        <ContentWrapper>
          {logo && <LogoSection src={logo} />}
          {src?.includes('.mp4') ? (
            <WelcomePopupVideo src={src} />
          ) : (
            <WelcomePopupImage src={src} />
          )}
          <WelcomePopupCTAContainer>
            <BrandButton brandColor={brandColor || 'blue'} textColor={textColor}>
              {BrandStylingPreviewStrings.ButtonJoin}
            </BrandButton>
          </WelcomePopupCTAContainer>
        </ContentWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
