import { FC } from 'react';

export const UnverifiedIcon: FC = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.625 19.75C15.4575 19.75 19.375 15.8325 19.375 11C19.375 6.16751 15.4575 2.25 10.625 2.25C5.79251 2.25 1.875 6.16751 1.875 11C1.875 15.8325 5.79251 19.75 10.625 19.75Z"
        fill="#D0D5DD"
      />
      <path
        d="M7.125 11H14.125"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
