import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { FC } from 'react';

import {
  Tooltip as TableTooltip,
  TooltipTitleProps,
  TooltipContentProps,
} from './../table/Tooltip';

import { useTooltipStyles } from './Tooltip.style';
import clsx from 'clsx';

type Props = TooltipTitleProps | TooltipContentProps;

export const Tooltip: FC<Props> = ({
  title,
  content,
  text,
  fontSize,
  fontWeight,
  padding,
  iconClassName,
  ...rest
}) => {
  const styles = useTooltipStyles();

  return title ? (
    <TableTooltip
      title={title}
      text={text}
      fontSize={fontSize || '12px'}
      fontWeight={fontWeight || 400}
      icon={<QuestionMarkRoundedIcon className={clsx(styles.Icon, iconClassName)} />}
      {...rest}
    />
  ) : content ? (
    <TableTooltip
      content={content}
      text={text}
      fontSize={fontSize || '12px'}
      fontWeight={fontWeight || 400}
      icon={<QuestionMarkRoundedIcon className={clsx(styles.Icon, iconClassName)} />}
      {...rest}
    />
  ) : (
    <></>
  );
};
