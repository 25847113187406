import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  color: var(--primaryTextColor);
  margin-bottom: 8px;
`;

export const PageSubtitle = styled.h3`
  font-size: 14px;
  line-height: 143%;
  color: var(--secondaryColor);
`;

interface IconWrapperProps {
  bgrColor: string;
}
export const IconWrapper = styled.div<IconWrapperProps>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.bgrColor};
  border-radius: 8px;
`;
