import { FC } from 'react';
import { theme } from '../../../theme';

export const CTAIcon: FC = () => (
  <svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_704_20318"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={50}
      height={50}
    >
      <circle cx={25} cy={25} r={25} fill="#EEECFD" />
    </mask>
    <g mask="url(#mask0_704_20318)">
      <circle cx={25} cy={25} r={25} fill="#EEECFD" />
      <circle cx={22.6624} cy={15.5752} r={7.78805} fill="white" stroke="#1A1538" />
      <circle cx={22.6624} cy={15.5758} r={4.94202} fill="#1A1538" />
      <path
        d="M17.7216 27.9393L21.0832 30.3311V16.5533C21.0832 15.4808 21.9526 14.6113 23.0251 14.6113C24.0976 14.6113 24.967 15.4808 24.967 16.5533V22.7326C24.967 21.6835 25.8174 20.8331 26.8665 20.8331C27.9155 20.8331 28.7659 21.6835 28.7659 22.7326V23.2863C28.7659 22.2643 29.5944 21.4358 30.6164 21.4358C31.6383 21.4358 32.4668 22.2643 32.4668 23.2863V24.1531C32.4668 23.2028 33.2372 22.4325 34.1874 22.4325C35.1377 22.4325 35.9081 23.2028 35.9081 24.1531V33.2119C35.9081 38.1831 31.8781 42.2131 26.9069 42.2131C23.7145 42.2131 20.7608 40.522 19.1446 37.7689L14.8944 30.5292C14.4264 29.7319 14.5788 28.7161 15.2603 28.0914C15.9421 27.4665 16.968 27.403 17.7216 27.9393Z"
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
      />
      <path
        d="M24.9694 24.5279V28.4665M28.7724 23.958V28.9424M32.459 23.958V29.8486"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
      />
    </g>
  </svg>
);
