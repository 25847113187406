import React, { FC, useEffect, useRef } from 'react';
import { useStyles } from './RecordVideo.styles';
import { Box } from '@mui/material';
import { useReactMediaRecorder } from 'react-media-recorder';
import { VideoRecordButton } from '../../../../../../common/components/RecordVideoComponent/VideoRecordButton/VideoRecordButton';
import RecordedPreview from './RecordedPreview';

interface Props {
  onVideoRecorded: (blob: string) => void;
  recordedVideo: string | null;
}

const RecordVideo: FC<Props> = ({ onVideoRecorded, recordedVideo }) => {
  const styles = useStyles();

  const videoRef = useRef<HTMLVideoElement>(null);

  const { status, startRecording, stopRecording, previewStream } = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    onStop: (blobUrl) => {
      onVideoRecorded(blobUrl);
    },
  });

  useEffect(() => {
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  return (
    <Box className={styles.videoWrapper}>
      {recordedVideo ? (
        <RecordedPreview src={recordedVideo} />
      ) : (
        <>
          {previewStream && previewStream.active && <video ref={videoRef} width={283} autoPlay />}
          <Box className={styles.recordButtonWrapper}>
            <VideoRecordButton
              handleStart={startRecording}
              handleStop={stopRecording}
              isActive={status === 'recording'}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default RecordVideo;
