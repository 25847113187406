import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { Box, Divider, FormGroup, Grid, IconButton, Typography } from '@mui/material';
import { FC, useEffect } from 'react';

import {
  InviteQRCodeSectionString,
  SidebarStrings,
  WebAppSignUpConfigStrings,
} from '../../../common/localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getProperties, patchVenueProperties } from '../../../store/slices/venueSlice';
import { useQRSectionStyles } from './QRSection.style';

import { useFormik } from 'formik';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { useToasts } from 'react-toast-notifications';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { useWebAppLink } from '../../../hooks/useWebAppLink';
import { useClientType } from '../../../services/hooks/useClientType';
import { QRCode } from '../../../common/components/QrCode/QRCode';
import { FieldSetting } from '../../../common/components/FieldSetting/FieldSetting';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';
import { TopBar } from '../../../common/components/topBar/TopBar';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';
import { WebappSubnavigation } from '../../../common/components/webappSubnavigation/WebappSubnavigation';
import { webappTabs } from '../../webapp/tabs';
import { VideoAppCopyUrl } from '../../../common/components/VideoAppCopyUrl/VideoAppCopyUrl';
import { QRCodeStyleSwitch } from './QRCodeStyleSwitch';
import { QrCodeType } from '../../../api/models/venue';

export const QRSection: FC = () => {
  const classes = useQRSectionStyles();
  const dispatch = useAppDispatch();
  const {
    venue: selectedVenue,
    venueAttributes: { isLoading, properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const { webAppLink } = useWebAppLink({ qrCode: true });
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();
  const {
    venue: { logo },
  } = useTypedSelector((state) => state.venue);

  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const qrColor = venueProperties?.['dashboard.QRCode.config']?.brandColor || venueBrandColor;
  const venueLogo = venueProperties?.['dashboard.QRCode.config']?.logo || logo;
  const isEmailRequired = venueProperties?.['webapp.signUp.config']
    ? venueProperties?.['webapp.signUp.config'].emailRequired
    : false;

  const qrCodeType = venueProperties?.['dashboard.QRCode.config']?.qrCodeType || QrCodeType.Branded;

  const { isHealthCare } = useClientType();

  const isBranded = qrCodeType === QrCodeType.Branded;

  const initialValues = {
    imageUrl: isBranded ? venueLogo : '',
    brandColor: isBranded ? qrColor : '#000',
    emailRequired: isEmailRequired,
    qrCodeType,
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    resetForm,
    dirty: isFormDirty,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async () => {
      dispatch(
        patchVenueProperties({
          id: selectedVenue.id,
          update: {
            name: 'dashboard.QRCode.config',
            value: {
              ...(venueProperties?.['dashboard.QRCode.config'] || {}),
              logo: values.imageUrl,
              brandColor: values.brandColor,
              qrCodeType: values.qrCodeType,
            },
          },
          alert: true,
        }),
      )
        .then(() => {
          dispatch(
            patchVenueProperties({
              id: selectedVenue.id,
              update: {
                name: 'webapp.signUp.config',
                value: {
                  ...{
                    phoneRequired: true,
                    nameRequired: true,
                  },
                  emailRequired: values.emailRequired,
                },
              },
              alert: true,
            }),
          );
        })
        .then(() => {
          dispatch(getProperties({ id: selectedVenue.id }));
          addToast(InviteQRCodeSectionString.nextStepMessage, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        });
      trackEvent(SettingsEvents.qr_code_save_button_click);
    },
    validateOnChange: true,
  });

  useEffect(() => {
    const isBranded = values.qrCodeType === QrCodeType.Branded;

    setFieldValue('imageUrl', isBranded ? venueLogo : '');
    setFieldValue('brandColor', isBranded ? venueBrandColor : '#000');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.qrCodeType]);

  const onCancel = () => {
    resetForm();
    trackEvent(SettingsEvents.qr_code_cancel_button_click);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('qrCodeType', (event.target as HTMLInputElement).value as QrCodeType);
    trackEvent(SettingsEvents.qr_code_displaying_was_updated, { value: values.imageUrl });
  };

  const onRouteClick = (route: string) => {
    const subRoute = route.split('/').reverse()[0].replaceAll('-', '_');
    const eventName = `${subRoute}_${SettingsEvents.video_app_sub_navigation_tab_click}`;
    trackEvent(eventName);
  };

  return (
    <>
      <TopBar
        sectionTitle={SidebarStrings.WebApp}
        onTabChange={(tab) => {
          trackEvent(WebAppSettingsEvents.NavigationTabChange, { value: tab.text });
        }}
      >
        <WebappSubnavigation items={webappTabs} onRouteClick={onRouteClick} />
      </TopBar>
      <Grid container className={classes.container}>
        <UnsavedChangesModal isUnsavedChangesOnPage={isFormDirty} />
        <Grid item lg={5} sm={12}>
          <Grid className={classes.contentWrapper}>
            <Typography className={classes.text}>
              {isHealthCare ? InviteQRCodeSectionString.TextTB : InviteQRCodeSectionString.TextSV}
            </Typography>
            <Divider />
            <Box mt="20px" mb="20px">
              <FieldSetting
                title={WebAppSignUpConfigStrings.EmailTitle}
                description={
                  isHealthCare
                    ? WebAppSignUpConfigStrings.EmailDescription_TruBlu
                    : WebAppSignUpConfigStrings.EmailDescription
                }
                tooltip={
                  isHealthCare
                    ? WebAppSignUpConfigStrings.EmailTooltip_TruBlu
                    : WebAppSignUpConfigStrings.EmailTooltip
                }
                value={values.emailRequired}
                onChange={(v) => {
                  setFieldValue('emailRequired', v);
                  trackEvent(SettingsEvents.email_required_set, { value: v + '' });
                }}
              />
            </Box>
            <Divider />
            <Box className={classes.linkBox}>
              <FormGroup className={classes.formGroup}>
                <Typography classes={{ root: classes.label }}>
                  {InviteQRCodeSectionString.QRCodeLink}
                </Typography>
                <VideoAppCopyUrl isQRCodeLink />
                <Divider sx={{ marginTop: '16px' }} />
              </FormGroup>
            </Box>
            <Typography className={classes.SectionTitle}>
              {InviteQRCodeSectionString.DownloadQRCode}
            </Typography>
            <Typography className={classes.text}>
              {isHealthCare
                ? InviteQRCodeSectionString.SwitchQrCodeStyleDescription_Trublu
                : InviteQRCodeSectionString.SwitchQrCodeStyleDescription}
            </Typography>
            <QRCodeStyleSwitch value={values.qrCodeType} handleChange={handleChange} />
            <Divider sx={{ my: '25px' }} />
            <Box className={classes.DownloadQRCodeBtn}>
              <QRCode
                content={webAppLink}
                color={values.brandColor}
                logo={values.imageUrl}
                withDownloadButton={true}
                btnClassName={classes.downloadBtn}
                btnTitle={InviteQRCodeSectionString.DownloadQRCode}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item lg={7} sm={12} flexDirection="column">
          <Grid item lg={12} sm={12}>
            <Box display="flex" justifyContent="flex-end" width="100%" height="32px">
              <IconButton
                style={{
                  backgroundColor: '#fff',
                  color: '#8B89A0',
                  marginTop: '25px',
                  marginRight: '30px',
                  display: 'none',
                }}
                onClick={() => {
                  trackEvent(SettingsEvents.invites_welcome_modal_open);
                }}
              >
                <HelpOutlineRoundedIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid container item lg={12} sm={12} justifyContent="center">
            <QRCode
              content={webAppLink}
              color={values.brandColor}
              logo={values.imageUrl}
              btnClassName={classes.downloadBtn}
              btnTitle={InviteQRCodeSectionString.Download}
            />
          </Grid>
        </Grid>
        <BottomActionBar
          show={isFormDirty}
          onSaveClick={handleSubmit}
          onCancelClick={onCancel}
          onSaveAndNextClick={handleSubmit}
          loading={isLoading}
        />
      </Grid>
    </>
  );
};
