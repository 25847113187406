import { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { TypeSocialVerse } from '../../constants/constants';
import { SpheresIcon } from '../../assets/newDesign/WebAppIcons/SpheresIcon';
import { checkSocialVerseViewMode } from '../../../pages/SocialVerse/SocialVerse.helper';
import { useStyles } from './ViewMode.style';
import { GridIcon } from '../../assets/newDesign/WebAppIcons/GridIcon';
import { ClassicIcon } from '../../assets/newDesign/WebAppIcons/ClassicIcon';
import { WatchAndLearnIcon } from '../../assets/newDesign/WebAppIcons/WatchAndLearnIcon';

interface Props {
  viewMode?: TypeSocialVerse;
  fontSize?: string;
}

export const ViewMode: FC<Props> = ({ viewMode, fontSize }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.container}>
      {viewMode === TypeSocialVerse.Cards && <ClassicIcon />}
      {viewMode === TypeSocialVerse.Spheres && <SpheresIcon />}
      {viewMode === TypeSocialVerse.Perspective && (
        <img style={{ maxWidth: '40px' }} src="/perspective.svg" alt="" />
      )}
      {viewMode === TypeSocialVerse.Grid && <GridIcon />}
      {viewMode === TypeSocialVerse.Educational && <WatchAndLearnIcon />}
      <Typography
        sx={{
          fontSize: fontSize || '14px',
          color: '#8B89A0',
          marginLeft: '16px',
        }}
      >
        {checkSocialVerseViewMode(viewMode || '')}
      </Typography>
    </Grid>
  );
};
