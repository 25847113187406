import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoStyleTypesSwitchStyles = makeStyles((theme: Theme) => ({
  rewardTypes: {
    marginLeft: '36px',
    marginTop: '12px',
    marginBottom: '20px',
    display: 'flex',

    '& > div': {
      '&:last-child': {
        marginRight: '0px',
      },
      marginRight: '15px',
    },
  },
  button: {
    borderRadius: '10px',
    height: '139px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '12px',

    maxWidth: '108px',
    '& div': {
      transform: 'scale(1)',
      maxWidth: '64px',
      maxHeight: '62px',
      marginBottom: '10px',
    },
    '& p': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',

      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.02em',
      color: theme.palette.common.gray,
    },
  },
  active: {
    fontWeight: '600',
    color: theme.palette.primary.main,
  },
}));
