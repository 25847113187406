export enum PurchaseType {
  PastPurchase = 'pastPurchase',
  FuturePurchase = 'futurePurchase',
}

export interface SMSInviteFormValues {
  mediaUrl: string | undefined;
  preMessage?: {
    body: string;
    daysShift: number;
    enabled: boolean;
  };
  postMessage: {
    body: string;
    daysShift: number;
    enabled: boolean;
  };
}
