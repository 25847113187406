import React, { FC } from 'react';
import { useStyles } from './EducationalPreview.styles';
import { Box, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { FormikValues } from '../../SocialVerseSettingsPage';
import { useTypedSelector } from '../../../../../store';
import { EducationalPlayIcon } from '../../../../../common/assets/newDesign/SocialVerse/EducationalPlayIcon';
import tinycolor from 'tinycolor2';
import { getTextWidth } from '../../../../../services/utilities';

interface Props {
  formik: FormikProps<FormikValues>;
  isMobile?: boolean;
}

const EducationalPreview: FC<Props> = ({ formik, isMobile }) => {
  const styles = useStyles({ isMobile });

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const titleWidth = getTextWidth(formik.values.title, 'Poppins');
  const descriptionWidth = getTextWidth(formik.values.supportingText || '', 'Poppins');

  return (
    <Box className={styles.container}>
      <Box
        className={styles.wrapper}
        style={{
          backgroundColor: formik.values.withGlow ? formik.values.glowColor : venueBrandColor,
        }}
      >
        <Box className={styles.playIcon}>
          <EducationalPlayIcon
            color={tinycolor(formik.values.glowColor).isDark() ? 'white' : 'black'}
          />
        </Box>
        <Box>
          <Typography
            className={styles.title}
            style={{ color: tinycolor(formik.values.glowColor).isDark() ? 'white' : 'black' }}
          >
            {formik.values.title}
          </Typography>
          {formik.values.showSupportingText && (
            <Typography
              className={styles.supportingText}
              style={{
                color: tinycolor(formik.values.glowColor).isDark() ? 'white' : 'black',
                width: descriptionWidth / titleWidth > 2 ? '150px' : 'auto',
              }}
            >
              {formik.values.supportingText}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EducationalPreview;
