import { FC, useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useTypedSelector, useAppDispatch } from '../../../store';
import { useSegmentPageCall } from '../../../hooks/useSegment';
import { useStyles } from './Managers.helper';
import {
  addEmptyManager,
  createVenueManager,
  getVenueManagerList,
  removeEmptyManager,
  sendInviteVenueManager,
  updateVenueManagerStatus,
} from '../../../store/slices/venueManagerSlice';
import { AdminVenueManagementStrings } from '../../../common/localization/en';
import { ManagerItem } from '../../../common/components/managerItem/ManagerItem';

export const SettingsManagers: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { items, isLoading } = useTypedSelector((state) => state.VenueManagerSlice);
  const venue = useTypedSelector((state) => state.venue.venue);
  const [newManager, setNewManager] = useState({
    email: '',
    name: '',
  });
  const [isValidationError, setIsValidationError] = useState(false);

  useEffect(() => {
    if (venue.id) {
      dispatch(getVenueManagerList({ accountId: venue.id }));
    }
  }, [dispatch, venue.id]);

  useSegmentPageCall();

  const handleAddOrgManager = () => {
    dispatch(
      addEmptyManager({
        id: '',
        email: '',
        role: '',
        firstName: '',
        lastName: '',
        photoUrl: '',
        refId: '',
        status: '',
        createdAt: '',
        updatedAt: '',
        accountMappingRole: '',
      }),
    );
  };

  const handleManagerCancel = () => {
    dispatch(removeEmptyManager());
    setNewManager({ email: '', name: '' });
  };

  const handleManagerSave = (item: any) => {
    dispatch(createVenueManager({ accountId: venue.id, item }));
  };

  const handleSendInvite = (id: string) => {
    dispatch(sendInviteVenueManager({ accountId: venue.id, id }));
  };

  const handleStatusChange = (id: string, status: boolean) => {
    dispatch(updateVenueManagerStatus({ venueId: venue.id, id, activate: status }));
  };
  const showBottomNav = () => {
    // check if there is a manager with a null id
    return items.filter((item) => !item.id).length ? true : false;
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box component="section" bgcolor="#fff" margin="20px 16px 24px" borderRadius="16px">
          <Box padding={3}>
            <Box paddingTop={2} marginBottom={4} display="flex" justifyContent="space-between">
              <h2 className={classes.subheading}>{AdminVenueManagementStrings.VenueManagers}</h2>

              <Button
                onClick={handleAddOrgManager}
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
                type="button"
                disabled={showBottomNav()}
              >
                {AdminVenueManagementStrings.AddVenueManager}
              </Button>
            </Box>

            {items.map((item, index) => (
              <ManagerItem
                key={item.id}
                id={item.id}
                name={`${item.firstName} ${item.lastName}`}
                index={index}
                status={item.status}
                loading={isLoading}
                email={item.email}
                save={handleManagerSave}
                cancel={handleManagerCancel}
                sendInvite={() => handleSendInvite(item.id)}
                statusUpdate={(status) => handleStatusChange(item.id, status)}
                error={item.error}
                hideActions={true}
                setNewManager={setNewManager}
                setIsValidationError={setIsValidationError}
                lastInvitationSentAt={item.lastInvitationSentAt}
              />
            ))}
          </Box>
        </Box>
      </Box>
      {showBottomNav() ? (
        <div className={classes.bottomControllers}>
          <Button
            color="primary"
            className={classes.actionButton}
            onClick={() => handleManagerSave(newManager)}
            variant="contained"
            disabled={isValidationError || !newManager.name || !newManager.email}
          >
            {AdminVenueManagementStrings.Save}
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={handleManagerCancel}
            className={classes.actionButton}
          >
            {AdminVenueManagementStrings.Cancel}
          </Button>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};
