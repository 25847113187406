import React, { FC, useState } from 'react';
import { SocialVerseDeploymentApiModel } from '../../../../api/models/socialVerse';
import { Box, IconButton, Link } from '@mui/material';
import { useStyles } from './SvDeployments.styles';
import { LivePreviewIcon } from '../../../../common/assets/newDesign/SocialVerse/LivePreviewIcon';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
  svDeployments: SocialVerseDeploymentApiModel[];
}

const MAX_URL_LENGTH = 45;

export const SvDeployments: FC<Props> = ({ svDeployments }) => {
  const classes = useStyles();
  const [isSvListOpen, setIsSvListOpen] = useState(false);

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsSvListOpen(false);
    },
  });

  return (
    <Box className={classes.container} ref={refDetectClickOutside}>
      <Box className={classes.counter}>{`+${svDeployments.length}`}</Box>
      <IconButton
        className={classes.button}
        onClick={() => {
          setIsSvListOpen(true);
        }}
      >
        <LivePreviewIcon />
      </IconButton>
      {isSvListOpen && (
        <Box className={classes.svListWrapper}>
          <Box className={classes.svList}>
            {svDeployments.map((sv) => {
              const url =
                sv.websiteUrl.length > MAX_URL_LENGTH
                  ? `${sv.websiteUrl.slice(0, MAX_URL_LENGTH - 3)}...`
                  : sv.websiteUrl;
              return (
                <Box className={classes.linkWrapper}>
                  <Link className={classes.svLink} href={sv.websiteUrl} target="_blank">
                    {url}
                  </Link>
                </Box>
              );
            })}
            <Box className={classes.arrow}></Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
