import { Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { matchPath } from 'react-router';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { TopBar } from '../../common/components/topBar/TopBar';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';
import { WebsiteRoutes } from '../../common/constants/routes';
import { useSegmentPageCall } from '../../hooks/useSegment';

import { SettingsManagers } from './Managers/Managers';
import { TeamSettings } from './TeamSettings/TeamSettings';

import {
  getNestedPath,
  subnavigationItems,
  SubnavigationKeys,
  subnavigationSectionTitles,
} from './WebappSettings.helper';
import { SettingsLayout } from './WebappSettings.style';
import { History } from '../integrations/History/History';
import { AddToWebsite } from '../integrations/Integration/addToWebsite/addToWebsite';
import { QRSection } from '../integrations/QRSection/QRSection';
import { SmsInvite } from '../integrations/SmsInvite/SmsInvite';

import { DataSync } from './DataSync/DataSync';
import { DownloadedVideo } from './DownloadedVideo/DownloadVideo';

import { VideoEditing } from './VideoEditing/VideoEditing';
import { VideoSharing } from './VideoSharing/VideoSharing';

import { SettingsEvents } from '../../common/constants/events/settingsEvents';
import { useTrackEvent } from '../../hooks/useTrackEvent';

export const WebappSettings: FC = () => {
  const { trackEvent } = useTrackEvent();
  useSegmentPageCall();
  const { pathname } = useLocation();
  const match = matchPath<any>(pathname, {
    path: [
      WebsiteRoutes.IntegrationsWebWidgetWithId,
      WebsiteRoutes.IntegrationsAddToWebsite + '/:id',
    ],
    exact: false,
  });
  const id = match?.params.id;
  const nestedPath = getNestedPath(pathname, 'settings') as SubnavigationKeys;

  const subnavigation = subnavigationItems(id);

  const sectionTitle = useMemo(() => {
    return subnavigationSectionTitles[nestedPath];
  }, [nestedPath]);
  const onRouteClick = (route: string) => {
    const subRoute = route.split('/').reverse()[0].replaceAll('-', '_');
    const eventName = `${subRoute}_${SettingsEvents.video_app_sub_navigation_tab_click}`;
    trackEvent(eventName);
  };

  return (
    <Box>
      <TopBar sectionTitle={sectionTitle}>
        <WebappSubnavigation items={subnavigation[nestedPath]} onRouteClick={onRouteClick} />
      </TopBar>

      <Route exact path={WebsiteRoutes.Invites}>
        <Redirect to={WebsiteRoutes.IntegrationsSmsInvite} />
      </Route>

      <Route exact path={WebsiteRoutes.IntegrationsQRCode}>
        <QRSection />
      </Route>
      {/* <SettingsLayout> */}

      <Switch>
        <Route exact path={WebsiteRoutes.VideoEditing}>
          {/*  <SettingsLayout>
            <VideoEditing />
          </SettingsLayout> */}
          <VideoEditing />
          {/*  <VideoSharing editable /> */}
        </Route>
        <Route exact path={WebsiteRoutes.VideoSharing}>
          <VideoSharing /* editable={false} */ />
        </Route>
        <Route exact path={WebsiteRoutes.DownloadedVideo}>
          <DownloadedVideo />
        </Route>
        <Route exact path={WebsiteRoutes.IntegrationsSmsInvite}>
          <SmsInvite />
        </Route>
        <Route exact path={WebsiteRoutes.EducationCards}>
          <Redirect to={WebsiteRoutes.WebAppCards} />
        </Route>
        <Route exact path={WebsiteRoutes.SettingsManagers}>
          <SettingsLayout>
            <SettingsManagers />
          </SettingsLayout>
        </Route>
        <Route exact path={WebsiteRoutes.DataSync}>
          <DataSync />
        </Route>
        <Switch>
          <Route path={WebsiteRoutes.IntegrationsAddToWebsite + '/:id'}>
            <AddToWebsite />
          </Route>
        </Switch>
        <Route exact path={WebsiteRoutes.IntegrationsHistory}>
          <SettingsLayout>
            <History />
          </SettingsLayout>
        </Route>

        <Route path={`${WebsiteRoutes.TeamSettings}/:id?`}>
          <SettingsLayout>
            <TeamSettings />
          </SettingsLayout>
        </Route>
        <Route exact path={WebsiteRoutes.Tips}>
          <SettingsLayout>
            <Redirect to={`${WebsiteRoutes.WebAppCards}?step=1`} />
          </SettingsLayout>
        </Route>
      </Switch>
      {/* </SettingsLayout> */}
    </Box>
  );
};
