import { FC, SetStateAction } from 'react';
import { Color } from 'material-ui-color';
import { Grid } from '@mui/material';

import {
  Title,
  Subtitle,
  ScrollBoxWrapp,
  ScrollBox,
} from '../../../../venuewizard/wizardSteps/Steps.style';
import { SubdomainBlock } from '../../../../venuewizard/wizardSteps/components/SubdomainBlock';
import { VenueWizardPreviewStepStrings } from '../../../../../common/localization/en';
import { FilterProps } from '../../../../venuewizard/WizzardStepperHelper';
import { ManagerAccount } from '../../../../../store/storeModels';
import { UsersPreview } from './components/UsersPreview/UsersPreview';
import { PreviewOrgInfo } from './components/PreviewOrgInfo/PreviewOrgInfo';
import defaultBg from '../../../../../common/assets/default-filter-bg-2.jpg';
import { WepAppBrandingAndFilterPreview } from './components/WepAppBrandingAndFilterPreview/WepAppBrandingAndFilterPreview';

interface Step4Props {
  venuLogo: string;
  brandcolor: Color;
  textColor: Color;
  filterPrimaryColor: Color;
  filterSecondaryColor: Color;
  filters: FilterProps[];
  venuName: string;
  accounts: ManagerAccount[];
  companyName: string;
  subdomain: string;
  setSubdomain: (value: SetStateAction<string>) => void;
  teamName: string;
}

export const Step4: FC<Step4Props> = ({
  venuLogo,
  brandcolor,
  textColor,
  filters,
  venuName,
  accounts,
  companyName,
  setSubdomain,
  subdomain,
}) => {
  const selectedFilter = filters.find((item) => item.isActive) as FilterProps;

  return (
    <ScrollBox>
      <ScrollBoxWrapp>
        <Grid container alignItems="stretch" justifyContent="center" spacing={3}>
          <Grid item lg={6}>
            <Grid item xs={12}>
              <Title>{VenueWizardPreviewStepStrings.Preview}</Title>
              <Subtitle>{VenueWizardPreviewStepStrings.PreviSubtitle}</Subtitle>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <SubdomainBlock subdomain={subdomain} setSubdomain={setSubdomain} />
              </Grid>
              <Grid item xs={12}>
                <PreviewOrgInfo companyName={companyName} teamName={venuName} />
              </Grid>
              <Grid item xs={12}>
                <UsersPreview accounts={accounts} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={5}>
            <WepAppBrandingAndFilterPreview
              brandColor={'#' + brandcolor.hex}
              textColor={'#' + textColor.hex}
              brandingBgr={defaultBg}
              logo={venuLogo}
              filterImg={selectedFilter.url}
            />
          </Grid>
        </Grid>
      </ScrollBoxWrapp>
    </ScrollBox>
  );
};
