import * as React from 'react';

export const Donations: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="26" fill="none" viewBox="0 0 38 26">
      <path
        fill="#40447E"
        d="M37.64 21.506L.396 21.182v.96c0 .26.163.482.383.521l10.531 1.89c.125.022.252.028.378.018l25.547-2.155c.228-.02.405-.242.405-.51v-.4z"
      ></path>
      <path
        fill="#40447E"
        d="M25.65 19.95L.806 21.706A.74.74 0 010 20.96V3.2c0-.444.361-.804.806-.804h24.845c.366 0 .662.325.662.726v16.056c0 .4-.296.746-.662.772z"
      ></path>
      <path
        fill="#40447E"
        d="M26.047 19.95L1.202 21.706a.74.74 0 01-.806-.746V3.2c0-.444.362-.804.806-.804h24.845c.366 0 .662.325.662.726v16.056c0 .4-.296.746-.662.772z"
      ></path>
      <path
        fill="#fff"
        d="M25.272 18.818l-23.14 1.525a.606.606 0 01-.655-.612V4.181a.66.66 0 01.655-.657l23.14-.096c.303-.001.547.265.547.594v14.163c0 .33-.244.613-.547.633zM14.502 2.883c0 .165-.128.299-.286.3a.292.292 0 01-.286-.3c0-.165.128-.299.286-.299.158 0 .286.133.286.299z"
      ></path>
      <path
        fill="#40447E"
        d="M25.427 19.87L.397 21.718l10.913 1.959c.125.022.252.028.378.018l25.952-2.19-11.815-1.622a1.901 1.901 0 00-.398-.013z"
      ></path>
      <path
        fill="#40447E"
        d="M4.048 21.765l20.838-1.562 6.45.879-20.66 1.669-6.628-.986z"
        opacity="0.3"
      ></path>
      <path
        fill="#B0B8E9"
        d="M25.822 6.434l-24.348.303v-2.75c0-.31.25-.56.558-.56h23.231c.308 0 .559.25.559.56v2.447z"
      ></path>
      <path
        fill="#D8DEE8"
        d="M16.738 13.803l-13.765.57V7.87l13.765-.231v6.164zM24.57 13.48l-7.02.29V7.626l7.02-.118v5.972zM24.57 17.913L2.974 19.199v-3.978l21.598-.961v3.653z"
      ></path>
      <path
        fill="#444B8C"
        d="M3.219 4.965c0 .173-.151.315-.337.316-.187.002-.338-.138-.338-.312 0-.173.151-.315.338-.316.186 0 .337.14.337.312zM4.438 4.958c0 .17-.15.308-.334.309-.184.001-.334-.135-.334-.305 0-.17.15-.308.334-.309.185 0 .334.136.334.305zM5.646 4.95c0 .17-.148.307-.33.308-.184.002-.333-.134-.333-.303 0-.17.149-.307.332-.308.183 0 .33.136.33.304z"
      ></path>
      <path
        fill="#fff"
        d="M20.061 15.079c-1.915-1.03-3.421.275-4.875-1.013-.88-.782-.448-1.364-1.532-2.531-1.201-1.293-1.983-.858-3.19-2.022-1.526-1.471-.763-2.616-2.077-3.672-1.595-1.282-3.314-.096-4.832-1.324-.655-.53-1.014-1.301-1.202-2.12H.868c-.26 0-.472.21-.472.47v18.411c0 .26.212.457.472.438l25.32-1.797a.408.408 0 00.306-.187c-1.854-.067-2.992-.689-3.72-1.331-1.424-1.257-1.177-2.496-2.713-3.322z"
        opacity="0.48"
      ></path>
      <path
        fill="#AB316D"
        d="M35.872 5.92l-2.771 4.534-8.455-.095-3.75 7.254-9.68.34-5.023 7.74a.532.532 0 01-.401.24l-.656.055c-.298.047-.934-.043-.837-.329l3.402-7.697L10.5 16.71l9.412-.36 3.56-7.132 8.65-.095 2.898-4.403.853 1.2zM30.716 6.225l-1.554-.714 1.732-.353-.178 1.067z"
      ></path>
      <path fill="#AB316D" d="M36.637 4.72l.813 1.2h-1.804l-.61-.762 1.6-.438z"></path>
      <path fill="#AB316D" d="M37.64.558L36.888 0l-.327 4.523.327.58.563.817.19-5.362z"></path>
      <path
        fill="url(#paint0_linear_12058_43879)"
        d="M16.46 15.827l3.904 1.066 3.75-7.254-3.903-1.067-3.75 7.255z"
      ></path>
      <path
        fill="url(#paint1_linear_12058_43879)"
        d="M1.496 25.146l2.923.799a.736.736 0 00.811-.31l5.453-8.402-3.903-1.067-5.485 8.452a.35.35 0 00.201.528z"
      ></path>
      <path
        fill="url(#paint2_linear_12058_43879)"
        d="M6.78 16.166l3.903 1.067 9.68-.34-3.903-1.066-9.68.339z"
      ></path>
      <path
        fill="url(#paint3_linear_12058_43879)"
        d="M20.21 8.572l3.903 1.067 8.455.095-3.903-1.066-8.455-.096z"
      ></path>
      <path
        fill="url(#paint4_linear_12058_43879)"
        d="M29.163 5.51l1.48-.077-1.978 3.235 3.904 1.067 2.78-4.55 1.538-.081V0l-7.724 5.51z"
      ></path>
      <path
        fill="url(#paint5_linear_12058_43879)"
        d="M17.823 15.564c1.121-.9 2.14-.353 2.857-1.089.613-.629-.022-1.142.68-2.694.571-1.263 1.41-1.845 1.578-1.959.216-.145.425-.254.612-.337l-3.34-.913-3.75 7.255.87.238c.13-.166.291-.34.493-.501z"
        opacity="0.3"
      ></path>
      <path
        fill="url(#paint6_linear_12058_43879)"
        d="M24.598 8.761c-.713.049-.842.076-.843.109-.005.1 1.142.296 2.313.326 1.042.027 1.224-.1 2.34-.027.726.047 1.212.138 1.224.082.015-.067-.66-.314-1.387-.463-.99-.203-1.89-.147-3.647-.027z"
        opacity="0.3"
      ></path>
      <path
        fill="url(#paint7_linear_12058_43879)"
        d="M4.762 21.523c.604-.985.826-1.988 1.877-2.476.805-.373 1.353-.1 2.068-.571.557-.368.86-.952 1.03-1.502l-2.957-.808-5.267 8.116c1.818-.881 2.74-1.928 3.249-2.759z"
        opacity="0.3"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_12058_43879"
          x1="22.461"
          x2="18.216"
          y1="8.739"
          y2="16.539"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_12058_43879"
          x1="9.3"
          x2="1.387"
          y1="15.812"
          y2="28.031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_12058_43879"
          x1="19.267"
          x2="2.669"
          y1="15.521"
          y2="18.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_12058_43879"
          x1="26.56"
          x2="26.233"
          y1="7.485"
          y2="10.677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_12058_43879"
          x1="30.858"
          x2="37.678"
          y1="8.063"
          y2="-0.608"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_12058_43879"
          x1="22.134"
          x2="17.889"
          y1="8.562"
          y2="16.362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_12058_43879"
          x1="27.312"
          x2="26.124"
          y1="7.923"
          y2="10.106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
        <linearGradient
          id="paint7_linear_12058_43879"
          x1="8.965"
          x2="1.052"
          y1="15.595"
          y2="27.814"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085"></stop>
          <stop offset="1" stopColor="#FB6FBB"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};
