import { FC } from 'react';
interface IViewIconDv5Props {
  color?: string;
}
export const ViewIconDv5: FC<IViewIconDv5Props> = ({ color }) => {
  return (
    <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2585 5.93539C20.7788 6.71034 20.7788 7.72147 20.2585 8.49642C19.381 9.80323 18.2335 10.9143 16.8872 11.7512C15.12 12.8497 13.0808 13.4318 11 13.4318C8.91923 13.4318 6.87997 12.8497 5.11278 11.7512C3.76646 10.9143 2.61896 9.80323 1.74153 8.49642C1.2212 7.72147 1.2212 6.71034 1.74153 5.93539C2.61896 4.62858 3.76646 3.51747 5.11278 2.68061C6.87997 1.58214 8.91923 1 11 1C13.0808 1 15.12 1.58214 16.8872 2.68061C18.2335 3.51747 19.381 4.62858 20.2585 5.93539Z"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.001 10.2158C12.6578 10.2158 14.001 8.87267 14.001 7.21582C14.001 5.55897 12.6578 4.21582 11.001 4.21582C9.34412 4.21582 8.00098 5.55897 8.00098 7.21582C8.00098 8.87267 9.34412 10.2158 11.001 10.2158Z"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
