import { Dispatch, FC, SetStateAction } from 'react';
import { SocialVerseAllApiModel } from '../../../../api/models/socialVerse';
import { useStyles } from './SvToggle.styles';
import { Box, Typography } from '@mui/material';
import { Toggle } from '../../toggle/CustomToggle';

export interface SvToggleItem {
  socialVerse: SocialVerseAllApiModel;
  isEnabled: boolean;
}

interface Props {
  toggleItem: SvToggleItem;
  toggleItemIndex: number;
  setToggleItems: Dispatch<SetStateAction<SvToggleItem[]>>;
}

export const SvToggle: FC<Props> = ({ toggleItem, toggleItemIndex, setToggleItems }) => {
  const styles = useStyles();
  const { name, numVideos } = toggleItem.socialVerse;
  const isEnabled = toggleItem.isEnabled;
  console.log('isEnabled', isEnabled);

  return (
    <Box className={styles.container}>
      <Toggle
        onChange={(value) => {
          setToggleItems((prev) => {
            const result = [...prev];

            result[toggleItemIndex].isEnabled = value;
            return result;
          });
        }}
        value={toggleItem.isEnabled}
      />
      <Typography className={styles.svName} color={isEnabled ? '#1A1538' : '#8B89A0'}>
        {name}
      </Typography>
      <Typography className={styles.divider}>&nbsp;|&nbsp;</Typography>
      <Typography
        className={styles.numberOfVideos}
        color={isEnabled ? '#54AD32' : '#8B89A0'}
      >{`${numVideos} clips`}</Typography>
    </Box>
  );
};
