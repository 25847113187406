import { FC } from 'react';
import { theme } from '../../theme';

export const CancelIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58885 0 0 3.58885 0 8C0 12.4112 3.58885 16 8 16C12.4112 16 16 12.4112 16 8C16 3.58885 12.4112 0 8 0ZM10.8965 10.0265C10.9561 10.0831 11.0037 10.151 11.0366 10.2263C11.0695 10.3016 11.087 10.3827 11.088 10.4648C11.0891 10.5469 11.0737 10.6284 11.0427 10.7045C11.0118 10.7806 10.9659 10.8497 10.9078 10.9078C10.8497 10.9659 10.7806 11.0118 10.7045 11.0427C10.6284 11.0737 10.5469 11.0891 10.4648 11.088C10.3827 11.087 10.3016 11.0695 10.2263 11.0366C10.151 11.0037 10.0831 10.9561 10.0265 10.8965L8 8.87039L5.97346 10.8965C5.85712 11.0071 5.70219 11.0678 5.54172 11.0657C5.38126 11.0637 5.22794 10.999 5.11446 10.8855C5.00098 10.7721 4.93632 10.6187 4.93427 10.4583C4.93221 10.2978 4.99293 10.1429 5.10346 10.0265L7.12962 8L5.10346 5.97346C4.99293 5.85712 4.93221 5.70219 4.93427 5.54172C4.93632 5.38126 5.00098 5.22794 5.11446 5.11446C5.22794 5.00098 5.38126 4.93632 5.54172 4.93427C5.70219 4.93221 5.85712 4.99293 5.97346 5.10346L8 7.12962L10.0265 5.10346C10.1429 4.99293 10.2978 4.93221 10.4583 4.93427C10.6187 4.93632 10.7721 5.00098 10.8855 5.11446C10.999 5.22794 11.0637 5.38126 11.0657 5.54172C11.0678 5.70219 11.0071 5.85712 10.8965 5.97346L8.87039 8L10.8965 10.0265Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
