import { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { FSModal } from '../modal/Modal';
import { BadgeCustomBell } from './BadgeCustomBell';
import { AdminVenueManagementStrings } from '../../localization/en';

interface OneOnlyNotifiedModalProps {
  closeModal: () => void;
  isOpenModal: boolean;
}

export const OneOnlyNotifiedModal: FC<OneOnlyNotifiedModalProps> = ({
  closeModal,
  isOpenModal,
}) => {
  return (
    <FSModal
      onClose={closeModal}
      modalIsOpen={isOpenModal}
      width="435px"
      backgroundColor={theme.palette.common.white}
      padding="16px"
      rounded="15px"
      closeBtn={false}
      blur
    >
      <Stack sx={{ alignItems: 'center' }}>
        <BadgeCustomBell />
        <>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 500,
              color: theme.palette.common.black,
              fontSize: '18px',
              lineHeight: '27px',
              letterSpacing: '0.02em',
              marginTop: '16px',
            }}
          >
            {AdminVenueManagementStrings.OnlyOneManagerText}
          </Typography>
          <Button
            sx={{ borderRadius: '25px', p: '10px 30px', mt: '30px' }}
            variant="contained"
            onClick={closeModal}
          >
            {AdminVenueManagementStrings.Done}
          </Button>
        </>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 400,
            color: '#8B89A0',
            fontSize: '13px',
            lineHeight: '21px',
            letterSpacing: '0.02em',
            marginTop: '21px',
          }}
        >
          {AdminVenueManagementStrings.RegularSMS}
        </Typography>
      </Stack>
    </FSModal>
  );
};
