import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { DistributorTile } from './components/DistributorTile';
import { PageWithHeaderLayout } from '../../common/layout/PageWithHeaderLayout/PageWithHeaderLayout';
import { PlanTile } from './components/PlanTile';
import { usePricingPlans } from '../../hooks/usePricingPlans';
import { useDistributors } from '../../hooks/useDistributors';
import { DistributorModel } from '../../api/models/distributors';
import { PricingPlanModel } from '../../api/models/pricingPlans';
import { useAffiliates } from '../../hooks/useAffiliates';
import { AffiliateModel } from '../../api/models/affiliates';
import { TileList } from './components/TileList';
import { useTypedSelector } from '../../store';
import { UserRoles } from '../../common/constants/constants';
import { stringToBase64 } from '../../services/utilities';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { PlansEventNames } from '../../common/constants/events/plansEvents';
// import { PricingScreenStrings } from '../../common/localization/en';

interface SignUpFormParams {
  promo?: string;
  dist?: string;
  muid?: string;
}

interface PlansPageProps {
  signUpRoute: string;
}

export enum EnterprisePLanStrings {
  EnterPriseId = 'ENTERPRISE',
  EnterPriseName = 'Enterprise',
  monthlyFee = '$-',
  setupFee = '$-',
  distributorId = '',
  code = 'ENTERPRISE',
}

export const SVenterPrisePlan = {
  id: `SV-${EnterprisePLanStrings.EnterPriseId}`,
  name: `SV-${EnterprisePLanStrings.EnterPriseName}`,
  code: `SV-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
  subscriptionFee: '0',
  billingPeriod: '0',
  position: 100,
};
export const TBenterPrisePlan = {
  id: `TB-${EnterprisePLanStrings.EnterPriseId}`,
  name: `TB-${EnterprisePLanStrings.EnterPriseName}`,
  code: `TB-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
};
export const SVHealthenterPrisePlan = {
  id: `SVH-${EnterprisePLanStrings.EnterPriseId}`,
  name: `SVH-${EnterprisePLanStrings.EnterPriseName}`,
  code: `SVH-${EnterprisePLanStrings.code}`,
  distributorId: EnterprisePLanStrings.distributorId,
  monthlyFee: EnterprisePLanStrings.monthlyFee,
  setupFee: EnterprisePLanStrings.setupFee,
};

export const PlansPage: React.FC<PlansPageProps> = ({ signUpRoute }) => {
  const history = useHistory();

  const [selectedDistributor, setSelectedDistributor] = useState<DistributorModel['id']>();
  const [selectedPlan, setSelectedPlan] = useState<PricingPlanModel['id']>();
  const [selectedAffiliate, setSelectedAffiliate] = useState<AffiliateModel['id']>();
  const { role, id } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;
  const showCopyIcon = isSVAdmin;
  const { trackEvent } = useTrackEvent();

  const {
    distributors,
    fetchDistributors,
    isDistributorsLoading,
    activeDistributor,
    getDistributorById,
  } = useDistributors();

  const sortedDistributors = useMemo(() => {
    if (activeDistributor) {
      // keep only active distributor
      return [activeDistributor];
    }
    const result = [...distributors]
      .sort((d1, d2) => d2.name.localeCompare(d1.name))
      .filter((d) => d.name.toLocaleLowerCase() !== 'test'); // remove test distributors
    //set sv as first item
    const svIndex = result.findIndex(
      (distributor) => distributor.name.toLocaleLowerCase() === 'sv',
    );

    if (svIndex > 0) {
      const firstItem = result[0];
      result[0] = result[svIndex];
      result[svIndex] = firstItem;
    }

    return result;
  }, [activeDistributor, distributors]);

  const isTestDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.name.toLocaleLowerCase() === 'test';
  const isSVHDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.name.toLocaleLowerCase() ===
    'sv health';
  const isTBDistributor =
    distributors.find((d) => d.id === selectedDistributor)?.name.toLocaleLowerCase() ===
    'socialsmiles';

  const enterPrisePlan = isSVHDistributor
    ? SVHealthenterPrisePlan
    : isTBDistributor
    ? TBenterPrisePlan
    : SVenterPrisePlan;

  const { pricingPlans, fetchPricingPlans, isPricingPlansLoading, getPlanById } = usePricingPlans();
  const {
    affiliates,
    fetchAffiliates,
    isAffiliatesLoading,
    getAffiliateById,
    activeAffiliate,
  } = useAffiliates();

  const enterprisePlans = [SVenterPrisePlan, SVHealthenterPrisePlan, TBenterPrisePlan];

  useEffect(() => {
    if (activeAffiliate) {
      setSelectedAffiliate(activeAffiliate.id);
    }
  }, [activeAffiliate]);

  useEffect(() => {
    if (activeDistributor) {
      setSelectedDistributor(activeDistributor.id);
    }
  }, [activeDistributor]);

  const currentDistributorPricingPlans = useMemo(() => {
    return pricingPlans
      .filter(({ distributorId }) => distributorId === selectedDistributor)
      .sort((p1, p2) => p1.position - p2.position);
  }, [pricingPlans, selectedDistributor]);

  // Get affiliates with associated pricing-plans
  const currentDistributorAffiliates = useMemo(() => {
    return affiliates
      .filter(({ distributorId }) => distributorId === selectedDistributor)
      .map((affiliate) => ({
        ...affiliate,
        plan: pricingPlans.find((pricingPlan) => pricingPlan.id === affiliate.planId),
      }));
  }, [affiliates, pricingPlans, selectedDistributor]);

  const fetchData = async () => {
    await fetchDistributors();

    fetchPricingPlans();
    fetchAffiliates();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Select distributor by default
  useEffect(() => {
    if (!selectedDistributor && sortedDistributors.length > 0) {
      setSelectedDistributor(sortedDistributors[0].id);
    }
  }, [selectedDistributor, sortedDistributors]);

  const resetPlansAffiliatesState = () => {
    setSelectedAffiliate(undefined);
    setSelectedPlan(undefined);
  };

  const canGoToSignUp = !!selectedAffiliate || !!selectedPlan;

  const getSearchParams = (planId: string | undefined, selectedPlan: string | undefined) => {
    if (!planId) {
      console.error('Plan not found');
      return;
    }
    const enterprisePlan = selectedPlan && enterprisePlans.find((elem) => selectedPlan === elem.id);
    const promo = selectedPlan
      ? enterprisePlan
        ? enterprisePlan.code
        : getPlanById(planId)?.code
      : selectedAffiliate
      ? getAffiliateById(planId)?.code
      : getDistributorById(planId)?.code;

    if (!promo) {
      console.error('Promo code not found');
      return;
    }

    const isTrubluPlan =
      distributors.find((distr) => distr.id === selectedDistributor)?.name === 'SocialSmiles';

    const promoCode =
      promo === EnterprisePLanStrings.code && isTrubluPlan
        ? `TB-${EnterprisePLanStrings.code}`
        : promo;
    const distributorCode = distributors.find((distr) => distr.id === selectedDistributor)?.code;
    const params: SignUpFormParams = distributorCode
      ? {
          promo: stringToBase64(promoCode),
          muid: stringToBase64(id),
          dist: stringToBase64(distributorCode),
        }
      : { promo: stringToBase64(promoCode), muid: stringToBase64(id) };
    return params;
  };

  const getPlanLinkUrl = (planId: string) => {
    const params = getSearchParams(planId, planId);
    return `${window.location.origin}${signUpRoute}?${new URLSearchParams(
      (params as unknown) as Record<string, string>,
    ).toString()}`;
  };
  const handleCopyToClipboard = (planId: string) => {
    const data = getPlanLinkUrl(planId);
    navigator.clipboard.writeText(data);
  };

  const redirectToSignUp = () => {
    const params = getSearchParams(
      selectedPlan || selectedAffiliate || selectedDistributor,
      selectedPlan,
    );
    history.push({
      pathname: signUpRoute,
      search: new URLSearchParams((params as unknown) as Record<string, string>).toString(),
    });
  };

  const isLoading = isDistributorsLoading || isPricingPlansLoading || isAffiliatesLoading;

  if (isLoading) {
    return null;
  }

  const renderSVTestPlans = () => {
    //SV Pay → SV Enterprise → SV Health Pay → SV Health Enterprise
    //TB Pay → TB Enterprise
    const order = [];

    const SVPay = currentDistributorPricingPlans.find(({ name }) => name === 'SV Pay');
    const HealthPay = currentDistributorPricingPlans.find(({ name }) => name === 'Health Pay');
    const TBPay = currentDistributorPricingPlans.find(({ name }) => name === 'TB Pay');

    if (SVPay) order.push(SVPay);
    order.push(enterprisePlans[0]);
    if (HealthPay) order.push(HealthPay);
    order.push(enterprisePlans[1]);
    if (TBPay) order.push(TBPay);
    order.push(enterprisePlans[2]);

    return order.map((plan: any) => {
      return (
        <PlanTile
          showCopyIcon={false}
          handleCopyToClipboard={() => handleCopyToClipboard(plan.id)}
          key={plan.id}
          name={plan.name}
          monthlyFee={plan.monthlyFee || plan.subscriptionFee}
          setupFee={plan.setupFee}
          promoCode={plan.code}
          selected={selectedPlan === plan.id}
          onClick={() => {
            resetPlansAffiliatesState();
            setSelectedPlan(plan.id);
            trackEvent(PlansEventNames.planTileClicked, { planId: plan.id });
          }}
        />
      );
    });
  };

  return (
    <PageWithHeaderLayout
      header={{
        title: 'Choose A Subscription Plan',
        leftButton: {
          outlined: true,
          title: 'Go Back',
          onClick: () => {
            history.goBack();
            trackEvent(PlansEventNames.backButtonClicked);
          },
        },
        rightButton: {
          title: 'Go to Sign Up Form',
          endIcon: <ChevronRightIcon style={{ fill: 'white' }} />,
          disabled: !canGoToSignUp,
          onClick: () => {
            redirectToSignUp();

            trackEvent(PlansEventNames.goToSignUpFormButtonClicked);
          },
        },
      }}
      isFixed={true}
    >
      <Box px="40px" pt="70px" mt="60px" zIndex="1">
        <Box display="flex" gap="16px" mb="30px">
          {sortedDistributors.map(({ id, name, logoUrl }) => (
            <DistributorTile
              key={id}
              selected={selectedDistributor === id}
              name={name}
              logoUrl={logoUrl}
              onClick={() => {
                if (selectedDistributor === id) return; // click on selected
                resetPlansAffiliatesState();
                setSelectedDistributor(id);
                trackEvent(PlansEventNames.distributorTileClicked, { distributorId: id });
              }}
            />
          ))}
        </Box>

        <Box display="flex" flexDirection="column" gap="16px">
          {currentDistributorPricingPlans.length > 0 && (
            <TileList title="Plans">
              {!isTestDistributor &&
                currentDistributorPricingPlans.map(
                  ({ id, name, code, subscriptionFee, setupFee, billingPeriod }) => (
                    <PlanTile
                      showCopyIcon={showCopyIcon}
                      handleCopyToClipboard={() => handleCopyToClipboard(id)}
                      key={id}
                      name={name}
                      monthlyFee={subscriptionFee}
                      setupFee={setupFee}
                      promoCode={code}
                      selected={selectedPlan === id}
                      billingPeriod={billingPeriod}
                      onClick={() => {
                        resetPlansAffiliatesState();
                        setSelectedPlan(id);
                        trackEvent(PlansEventNames.planTileClicked, { planId: id });
                      }}
                    />
                  ),
                )}
              {isSVAdmin && !isTestDistributor && (
                <PlanTile
                  showCopyIcon={false}
                  handleCopyToClipboard={() => handleCopyToClipboard(enterPrisePlan.id)}
                  key={enterPrisePlan.id}
                  name={'ENTERPRISE'}
                  monthlyFee={enterPrisePlan.monthlyFee}
                  setupFee={enterPrisePlan.setupFee}
                  promoCode={enterPrisePlan.code}
                  selected={selectedPlan === enterPrisePlan.id}
                  onClick={() => {
                    resetPlansAffiliatesState();
                    setSelectedPlan(enterPrisePlan.id);
                    trackEvent(PlansEventNames.enterprisePlanTileClicked, {
                      value: enterPrisePlan.code,
                    });
                  }}
                />
              )}
              {isSVAdmin && isTestDistributor && renderSVTestPlans()}
            </TileList>
          )}

          {currentDistributorAffiliates.length > 0 && (
            <TileList title="Affiliates">
              {currentDistributorAffiliates.map(({ id, name, code, plan }) => (
                <PlanTile
                  showCopyIcon={showCopyIcon}
                  handleCopyToClipboard={() => handleCopyToClipboard(id)}
                  key={id}
                  name={name}
                  monthlyFee={plan?.subscriptionFee}
                  setupFee={plan?.setupFee}
                  promoCode={code}
                  selected={selectedAffiliate === id}
                  onClick={() => {
                    resetPlansAffiliatesState();
                    setSelectedAffiliate(id);
                    trackEvent(PlansEventNames.planTileClicked, { planId: id });
                  }}
                />
              ))}
            </TileList>
          )}
        </Box>
      </Box>
    </PageWithHeaderLayout>
  );
};
