import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './LeftSideCompleteLayout.styles';
import { OnboardingWelcomeStrings } from '../../../../common/localization/en';
import { RoundedButton } from '../../../../common/components/Button/RoundedButton';
import ArrowRightSmall from '../../../../common/assets/ArrowRightSmall';
import { BookIcon } from '../../../../common/assets/BookIcon';

interface Props {
  onClick: () => void;
}

const LeftSideCompleteLayout: FC<Props> = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.titleWrapper}>
        <BookIcon />
        <Typography className={classes.title}>
          {OnboardingWelcomeStrings.CollectVideoClips}
        </Typography>
      </Box>

      <Typography className={classes.featuresText}>{OnboardingWelcomeStrings.Features}</Typography>
      <RoundedButton
        title={OnboardingWelcomeStrings.LetsGo}
        endIcon={<ArrowRightSmall />}
        onClick={onClick}
        className={classes.letsGoBtn}
      />
    </Box>
  );
};

export default LeftSideCompleteLayout;
