import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MIN_TABLE_HEIGHT } from '../../common/constants/constants';

const TABLE_HEIGHT = 'calc(100vh - 240px)';

export const useFiltersStyles = makeStyles((theme: Theme) => ({
  Container: {
    width: '100%',
    // height: '100vh',
    overflow: 'auto',
  },
  Header: {
    height: 64,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  HeaderBtn: {
    borderColor: theme.palette.grey[500],
    borderRadius: 12,
    padding: '9px 15px',
    marginLeft: 46,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    '& svg': {
      color: theme.palette.common.black,
    },
  },
  Title: {
    marginLeft: '30px',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    display: 'flex',
    alignItems: 'center',
  },
  Sidebar: {
    width: 280,
    backgroundColor: theme.palette.common.white,
    height: '100vh',
    padding: '0 25px',
  },
  Content: {
    padding: '20px 30px',
    background: '#f5f6f8',
    flexGrow: 1,
  },
  Search: {
    width: '30%',
  },
  TotalNumberSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  TotalNumberSectionText: {
    marginLeft: '12px',
    fontSize: '16px',
    lineHeight: '40px',
    fontWeight: '500',
  },
  Table: {
    marginTop: '20px',
    borderRadius: '10px',
    marginBottom: '80px',
    '& #common-table-wrapper': {
      height: TABLE_HEIGHT,
      maxHeight: TABLE_HEIGHT,
      minHeight: MIN_TABLE_HEIGHT,
    },
  },
  PageDescription: {
    marginTop: 40,
    width: '100%',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.deepDark,
  },
  BulkList: {
    marginTop: '30px',
  },
  Main: {
    padding: '20px 30px 25px',
  },
  ApplyBtn: {
    borderRadius: 40,
    padding: '9px 20px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    marginRight: 20,
    minWidth: '180px',
    '& svg': {
      marginRight: '8px',
    },
  },
  DeleteBtn: {
    marginRight: '42px',
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: theme.palette.common.white,
    borderRadius: 15,
    padding: '30px 25px',
  },
  ModalTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '34px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  Label: {
    marginBottom: '10px',
    fontSize: '14px',
    lineHeight: '26px',
    color: theme.palette.common.gray,
  },
  ModalDescription: {
    marginTop: '8px',
    marginBottom: '52px',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  ModalBtn: {
    borderRadius: 40,
    padding: '9px 20px',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    marginRight: 20,
  },
  Message: {
    paddingTop: 20,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  HeaderCell: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  TableCell: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  ActionCell: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  UnsavedFilter: {
    borderRadius: '12px',
    border: ` 1px solid ${theme.palette.common.gray}`,
    padding: '9px 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '288px',
    position: 'relative',
    cursor: 'pointer',
  },
  Icon: {
    display: 'flex',
    alignItems: 'center',
  },
  IconSettings: {
    marginLeft: 'auto',
  },
  UnsavedFilterText: {
    color: theme.palette.common.black,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    padding: '0 7px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
  },
  SaveBtn: {
    borderRadius: '12px',
    minHeight: '48px',
    marginLeft: '14px',
  },
  Btn: {
    borderRadius: '25px',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
    '&.Mui-disabled': {
      backgroundColor: `${theme.palette.primary.main}33`,
      color: `${theme.palette.primary.main}4d`,
      '& svg': {
        stroke: `${theme.palette.primary.main}4d`,
      },
    },
  },
}));
