import React, { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { useStyles } from './UploadModal.styles';
import { Box, Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { CloseIcomModal } from '../../../assets/newDesign/CloseIconModal';
import { CampaignsPageStrings, CreatorSlideoutStrings } from '../../../localization/en';
import { ArrowBottomIcon } from '../../../assets/newDesign/ArrowBottomIcon';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { getCampaigns } from '../../../../store/slices/campaignsSlice';
import { RoundedButton } from '../../Button/RoundedButton';
import { getVideoAspectRatio, getVideoDuration, isVideoARCloseToTargetAR } from '../../../../services/utilities';
import {
  getDefaultStoryDetails,
  getFileSizeInMb,
  getUpdatedVideoUrl,
  getUserStoryDetails,
  getVideoPosterWithCloudinary,
} from '../../VideoUploader/VideoUplader.helpers';
import { LinearProgressWithLabel } from '../../LinearProgressBar/LinearProgress.helper';
import { CompletedIcon } from '../../../assets/CompletedIcon';
import { CancelIcon } from '../../../assets/CancelIcon';
import { CreatorsTableEventNames } from '../../../constants/events/creatorsTableEvents';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import {
  CreateStory,
  createUploadUrl,
  reset,
  setProgress,
  uploadVideoAsFile,
} from '../../../../store/slices/uploadVideoSlice';
import { getVideos } from '../../../../store/slices/videosSlice';
import { useOnUploadProgress } from '../../../../services/hooks/useOnUploadProgress';
import {
  useAttributesFilterUrl,
  useLogoFilterEnabled,
} from '../../../../services/hooks/useAttributesFilterUrl';
import { DEFAULT_VIDEO_DETAILS } from '../../../../api/constants';
import { useClientType } from '../../../../services/hooks/useClientType';
import { fetchUserDetails } from '../../../../store/slices/userDetailsSlice';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  userId?: string;
  campaignId?: string;
  onUpload?: () => void;
}

export const UploadModal: FC<Props> = ({ isOpen, onClose, userId, campaignId, onUpload }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const onUploadProgress = useOnUploadProgress({});
  const logoEnabled = useLogoFilterEnabled();
  const attributesFilterUrl = useAttributesFilterUrl();

  const { id: accountId } = useTypedSelector((state) => state.venue.venue);
  const { items: campaigns, sort } = useTypedSelector((state) => state.campaigns);
  const { latestActiveCampaign } = useTypedSelector((state) => state.userDetails);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(campaignId || null);

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isFileUploading, setIsFileUploading] = useState(true);
  const isCancelUpload = useRef(false);
  const timerRef = useRef<number | null>(null);
  const { isAthleticSolutionsClient } = useClientType();

  const [isUploadError, setIsUploadError] = useState(false);
  const { uploadingProgress } = useTypedSelector((state) => state.uploadVideo);
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { venue, venueAttributes } = useTypedSelector((state) => state.venue);

  useEffect(() => {
    if (accountId) {
      dispatch(getCampaigns({ accountId, pageable: { page: 0, size: 1000, sort } }));
    }
  }, [dispatch, accountId, sort]);

  const cancelUploadingHandler = () => {
    isCancelUpload.current = true;
    setIsFileUploading(false);
    setIsUploadError(false);
    setFile(null);

    trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingCancelClick, {
      selectedUserId: userId,
    });
  };

  useEffect(() => {
    if (!isOpen) {
      cancelUploadingHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const storyDetails = useMemo(() => {
    const properties = getDefaultStoryDetails(
      userDetails?.defaultVideoDetails || null,
      venueAttributes.properties?.['webapp.default-story-details'],
      DEFAULT_VIDEO_DETAILS(),
      userDetails?.returnUrl || undefined,
    );
    const userName = userDetails?.displayName!;

    return getUserStoryDetails(properties, userName, venue.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue, venueAttributes]);

  const onFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target?.files?.[0];

    if (!file) {
      setIsFileUploading(false);
      return;
    }

    isCancelUpload.current = false;
    setFile(file);
    setIsFileUploading(true);
    setFile(file);
    const duration = await getVideoDuration(file);

    dispatch(reset());

    trackEvent(CreatorsTableEventNames.CreatorDetailsUploadFileClick, { selectedUserId: userId });

    createUploadUrl(userId)
      .then((res) => {
        if (res) {
          trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploading, {
            selectedUserId: userId,
          });
          dispatch(
            uploadVideoAsFile({
              options: res,
              data: file,
              onUploadProgress,
            }),
          ).then(async () => {
            const videoAr = await getVideoAspectRatio(file);
            const shouldFill = !isVideoARCloseToTargetAR(videoAr);
            const url = await getUpdatedVideoUrl(
              res.downloadUrl,
              logoEnabled ? attributesFilterUrl : undefined,
              undefined,
              shouldFill
            );

            const urlWitoutFilter = await getUpdatedVideoUrl(res.downloadUrl, undefined, undefined, shouldFill);

            if (isCancelUpload.current) {
              isCancelUpload.current = false;
              return;
            }

            dispatch(
              CreateStory({
                categoryId: '',
                url: url,
                isPublic: true,
                duration,
                thumbnailUrl: getVideoPosterWithCloudinary(url, true),
                altThumbnailUrl: getVideoPosterWithCloudinary(urlWitoutFilter, true),
                details: {
                  ...storyDetails,
                  ctaBtnLinkUrl: isAthleticSolutionsClient
                    ? userDetails?.returnUrl || null
                    : storyDetails.ctaBtnLinkUrl || null,
                },
                userId: userId!,
                campaignId: selectedCampaignId || undefined,
              }),
            ).then((action: any) => {
              if (action.payload.id) {
                trackEvent(CreatorsTableEventNames.CreatorDetailsModalStoryCreated, {
                  selectedUserId: userId,
                  videoId: action.payload.id,
                });
              }

              const timer = setTimeout(() => {
                setIsFileUploading(false);
                dispatch(setProgress({ value: 0 }));
                dispatch(
                  getVideos({
                    accountId,
                    userId,
                    pageable: { page: 0, size: 10000, sort },
                  }),
                );
                if (userId) {
                  dispatch(fetchUserDetails(userId));
                }
                onUpload?.();
              }, 1500);
              timerRef.current = timer;
            });
          });
        }
      })
      .catch(() => {
        setIsUploadError(true);
        trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingError, {
          selectedUserId: userId,
        });
      });
  };

  const tryAgainHandler = () => {
    setIsFileUploading(false);
    setIsUploadError(false);
    setFile(null);

    trackEvent(CreatorsTableEventNames.CreatorDetailsModalFileUploadingTryAgainClick, {
      selectedUserId: userId,
    });
  };

  return (
    <Box
      className={classes.container}
      sx={{
        zIndex: isOpen ? 10 : -1,
      }}
    >
      <input
        type="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept="video/mp4,video/x-m4v,video/*"
        onChange={onFileSelect}
      />
      <Box
        className={classes.modalWrapper}
        sx={{
          transform: `translateY(${isOpen ? '30px' : '100%'})`,
        }}
      >
        <Box className={classes.closeIconWrapper}>
          <Box onClick={onClose} className={classes.closeIcon}>
            <CloseIcomModal />
          </Box>
        </Box>

        {isFileUploading && file ? (
          <>
            <Typography className={classes.uploadTitle}>{`${
              CreatorSlideoutStrings.ClipUploadingTo
            } ${
              selectedCampaignId
                ? campaigns.find((campaign) => campaign.id === selectedCampaignId)?.name
                : latestActiveCampaign?.name ||
                  campaigns.find((campaign) => campaign.isPreferred)?.name
            }...`}</Typography>
            <Box className={classes.loadingWrapper}>
              <Typography className={classes.fileName}>{file.name}</Typography>
              <Typography className={classes.fileSize}>{getFileSizeInMb(file.size)} MB</Typography>
              {!isUploadError && (
                <div className={classes.progressWrapper}>
                  <LinearProgressWithLabel value={uploadingProgress} className={classes.progress} />
                </div>
              )}
              {isUploadError && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    tryAgainHandler();
                  }}
                  className={classes.tryAgainButton}
                >
                  Try again
                </Button>
              )}
              {uploadingProgress >= 100 && (
                <Box className={classes.completedIcon}>
                  <CompletedIcon />
                </Box>
              )}
              {uploadingProgress < 100 && (
                <IconButton
                  className={classes.deleteIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelUploadingHandler();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography className={classes.title}>
              {campaignId
                ? `${CreatorSlideoutStrings.CurrentCampaign} ${
                    campaigns.find((campaign) => campaign.id === campaignId)?.name
                  }`
                : CreatorSlideoutStrings.SelectCampaign}
            </Typography>
            <Box className={classes.selectWrapper}>
              <Select
                className={classes.select}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  transformOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                  },
                  marginThreshold: 0,
                  PaperProps: {
                    className: classes.menuWrapper,
                  },
                }}
                classes={{
                  icon: classes.dropdownIcon,
                  iconOpen: classes.dropdownIconOpen,
                }}
                disableUnderline={true}
                IconComponent={(props) => (
                  <Box {...props}>
                    <ArrowBottomIcon />
                  </Box>
                )}
                value={selectedCampaignId || 1}
                displayEmpty={false}
                disabled={!!campaignId}
              >
                <MenuItem
                  className={classes.selectItem}
                  value={1}
                  sx={{
                    display: 'none',
                  }}
                >
                  {CampaignsPageStrings.CampaignName}
                </MenuItem>
                {campaigns
                  .filter((campaign) => !campaign.endedAt)
                  .map((campaign) => (
                    <MenuItem
                      className={classes.selectItem}
                      key={campaign.id}
                      value={campaign.id}
                      onClick={() => {
                        setSelectedCampaignId(campaign.id);
                      }}
                    >
                      <span style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                        {campaign.name}
                      </span>
                    </MenuItem>
                  ))}
              </Select>
              <RoundedButton
                title={CreatorSlideoutStrings.BrowseFiles}
                borderRadius="10px"
                onClick={() => {
                  inputFile?.current?.click?.();
                }}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
