import { FC } from 'react';

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

export const PlayIcon: FC<Props> = ({ height, width, color }) => (
  <svg
    width={width || 24}
    height={height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 7.22V16.79C5 18.75 7.13 19.98 8.83 19L12.98 16.61L17.13 14.21C18.83 13.23 18.83 10.78 17.13 9.8L12.98 7.4L8.83 5.01C7.13 4.03 5 5.25 5 7.22Z"
      fill={color ? color : 'white'}
    />
  </svg>
);
