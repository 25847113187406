import { ReactElement } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { CSVIcon } from '../../../../common/assets/Settings/CSVIcon';
import { UserIcon } from '../../../../common/assets/Settings/UserIcon';
import { TemplateIcon } from '../../../../common/assets/Settings/TemplateIcon';
import { TeamSettingsStrings } from '../../../../common/localization/en';

export const useStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: 5,
  },
  paper: {
    padding: '16px 0',
    width: 367,
  },
  button: {
    margin: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  item: {
    padding: '8px 16px',
    whiteSpace: 'normal',
  },
  itemIcon: {
    marginRight: theme.spacing(2),
  },
  itemTextWrapper: {},
  itemTitle: { fontWeight: 600, fontSize: 14 },
  itemDescription: { fontSize: 14 },
}));

interface PopupMenuListValues {
  title: string;
  description: string;
  icon: ReactElement;
  onClick: () => void;
}
interface PopupMenuListProps {
  onUploadCSV: () => void;
  onAddCreateUser: () => void;
  onDownloadTemplate: () => void;
}
export const popupMenuList = ({
  onUploadCSV,
  onAddCreateUser,
  onDownloadTemplate,
}: PopupMenuListProps): PopupMenuListValues[] => {
  return [
    {
      title: TeamSettingsStrings.UploadFileTitle,
      description: TeamSettingsStrings.UploadFileDescription,
      icon: <CSVIcon />,
      onClick: onUploadCSV,
    },
    {
      title: TeamSettingsStrings.AddManuallyTitle,
      description: TeamSettingsStrings.AddManuallyDescription,
      icon: <UserIcon />,
      onClick: onAddCreateUser,
    },
    {
      title: TeamSettingsStrings.DownloadTemplateTitle,
      description: TeamSettingsStrings.DownloadTemplateDescription,
      icon: <TemplateIcon />,
      onClick: onDownloadTemplate,
    },
  ];
};
