import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: () => ({
    minWidth: '500px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    border: '3px solid #F3B03E',
    borderRadius: '8px',
    backgroundColor: '#FFF',
    marginBottom: '33px',
    position: 'relative',
  }),
  title: {
    color: '#FFA800',
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '20px',
  },
  rewardName: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    lineHeight: '20px',
    marginTop: '10px',
  },
  info: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#444E50',
    lineHeight: '16px',
    textAlign: 'center',
    margin: '10px 0',
    whiteSpace: 'nowrap',
  },
  button: {
    width: '300px',
    height: '40px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
  qsMarkerWrapper: {
    position: 'absolute',
    right: '100%',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
