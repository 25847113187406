import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSocialVerseFilterAppBarStyles = makeStyles((theme: Theme) => ({
  ToolBar: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  VideosAmount: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
    flexShrink: 0,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '40px',
    letterSpacing: ' 0.02em',
    color: theme.palette.common.black,
  },
  ApplyBtn: {
    flexShrink: 0,
    padding: '10px 15px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '1em',
    backgroundColor: theme.palette.common.white,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: ' 0.02em',
    color: theme.palette.common.black,
    borderRadius: '12px',
    border: '1px solid',
    borderColor: '#8B89A0',
    // width: 290, long name filter 4556677777788899999999999
    minWidth: 290,
  },
  Btn: {
    flexShrink: 0,
    padding: '10px 15px',
    borderRadius: '12px',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: ' 0.02em',
    marginLeft: '1em',
  },
}));
