import { FC, memo, useState } from 'react';
import { Box } from '@mui/material';
import { ContentModalHeader } from './ContentModalHeader';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { TopGolfModalContentInfo } from './TopGolfContentModalInfo';
import { useTypedSelector } from '../../../../store';
import { SettingsPageStrings } from '../../../localization/en';
import { TopgolfVideoDownloadDialog } from './TopgolfVideoDownloadDialog/TopgolfVideoDownloadDialog';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { topGolfEvents } from '../../../constants/events/topGolfEvents';

interface DetailsModalContentProps {
  story?: TopGolfVideosApiModel;
  handleClose: () => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
}

export const TopGolfDetailsModalContent: FC<DetailsModalContentProps> = memo(
  ({ handleClose, story, handleOpenDeleteModal, handleOpenConfirmationModal }) => {
    const { subdomain } = useTypedSelector((state) => state.venue.venue);
    const { trackEvent } = useTrackEvent();
    const [videoDownloadDialogOpen, setVideoDownloadDialogOpen] = useState(false);
    const handleDownloadClose = () => {
      setVideoDownloadDialogOpen(false);
      trackEvent(topGolfEvents.top_golf_video_slide_out_open_download_modal_close, {
        videoId: story?.id,
      });
    };

    const handleOpenDownloadVideoDialog = () => {
      setVideoDownloadDialogOpen(true);
      trackEvent(topGolfEvents.top_golf_video_slide_out_open_download_button_click, {
        videoId: story?.id,
      });
    };
    const handleTrackOpenVideoApp = () => {
      if (story) {
        trackEvent(topGolfEvents.top_golf_video_slide_out_open_video_app_button_click, {
          videoId: story.id,
        });
      }
    };

    if (story) {
      return (
        <Box
          bgcolor="#fff"
          borderRadius="8px 0 0 8px"
          height="calc(100vh - 80px)"
          overflow="scroll"
        >
          <ContentModalHeader
            storyLink={`https://${subdomain}${SettingsPageStrings.DomainName}/s?id=${story.shortcode}`}
            shortCode={story.shortcode}
            onOpenVideoApp={handleTrackOpenVideoApp}
            onClose={() => {
              handleClose();
              trackEvent(topGolfEvents.top_golf_video_slide_out_close_button_click, {
                videoId: story.id,
              });
            }}
          />

          <TopGolfModalContentInfo
            video={story}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
            handleOpenDownloadVideoDialog={handleOpenDownloadVideoDialog}
          />

          <TopgolfVideoDownloadDialog
            open={videoDownloadDialogOpen}
            video={story}
            onClose={handleDownloadClose}
          />
        </Box>
      );
    }

    return <div></div>;
  },
);
