import React, { FC, useState } from 'react';
import { useStyles } from './AnimatedQsMarker.styles';
import { Box, Button, Typography } from '@mui/material';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { QuickStartStrings } from '../../localization/en';
import { Logout } from '@mui/icons-material';
import { useClientType } from '../../../services/hooks/useClientType';

export interface QSStep {
  title: string;
  text: string;
  onClick: () => void;
  isLastStep?: boolean;
  width?: string;
  hideMarkerOnClick?: boolean;
  translate?: string;
}

interface Props {
  arrowPosition: 'right' | 'top' | 'bottom' | 'left';
  steps: QSStep[];
  direction: 'row' | 'column';
  isEssentialsFlow?: boolean;
}

const AnimatedQsMarker: FC<Props> = ({ arrowPosition, steps, direction, isEssentialsFlow }) => {
  const [isMarkerHiding, setIsMarkerHiding] = useState(false);
  const [isStepChanging, setIsStepChanging] = useState(false);

  const styles = useStyles();

  const { currentTopicStep } = useQuickStartLaunchPad();
  const [translate, setTranslate] = useState(steps[currentTopicStep]?.translate || '');
  const [isTranslateAnimation, setIsTranslateAnimation] = useState(false);

  const { isUwmClient } = useClientType();

  return (
    <Box
      className={styles.container}
      onAnimationEnd={() => {
        if (steps[currentTopicStep]?.translate) {
          setTranslate(steps[currentTopicStep]?.translate!);
          setIsTranslateAnimation(false);
        }
      }}
      sx={{
        marginTop: arrowPosition === 'top' ? '14px' : 0,
        marginRight: arrowPosition === 'right' ? '14px' : 0,
        marginBottom: arrowPosition === 'bottom' ? '14px' : 0,
        flexDirection: direction,
        width: steps[currentTopicStep]?.width || 'fit-content',
        animation: isTranslateAnimation
          ? 'translate 0.6s !important'
          : isMarkerHiding
          ? 'hideQsMarker 0.5s !important'
          : '',

        backgroundColor: isUwmClient ? '#005F9E' : '#3B6EED',
        borderLeft:
          arrowPosition === 'left' ? '' : isUwmClient ? '5px solid #A1CE57' : '5px solid #FFB515',

        borderRight:
          arrowPosition !== 'left' ? '' : isUwmClient ? '5px solid #A1CE57' : '5px solid #FFB515',

        animationFillMode: 'forwards',
        transform: `translate(${translate})`,

        '@keyframes translate': {
          '0%': {
            opacity: 1,
            transform: `translate(${translate})`,
          },

          '50%': {
            opacity: 0,
            transform: `translate(${translate})`,
          },
          '51%': {
            opacity: 0,
            visibility: 'hidden',
            transform: `translate(${steps[currentTopicStep]?.translate})`,
          },
          '100%': {
            opacity: 1,
            transform: `translate(${steps[currentTopicStep]?.translate})`,
          },
        },
      }}
    >
      <Box
        className={styles.arrow}
        sx={{
          top:
            arrowPosition === 'right' || arrowPosition === 'left'
              ? '50%'
              : arrowPosition === 'bottom'
              ? '100%'
              : 0,
          left: arrowPosition === 'left' ? '0' : arrowPosition === 'right' ? '100%' : '50%',
          backgroundColor: isUwmClient ? '#005F9E' : '#3B6EED',
          transform: `translate(${isEssentialsFlow ? '-120px' : '-50%'}, -50%) rotate(45deg)`,
        }}
      />
      <Box>
        <Typography
          className={styles.title}
          sx={{
            animation: isStepChanging ? 'hideQsMarker 0.3s !important' : '',
            visibility: isStepChanging ? 'hidden' : 'visible',
          }}
        >
          {steps[currentTopicStep]?.title}
        </Typography>
        <Typography
          className={styles.text}
          sx={{
            animation: isStepChanging ? 'hideQsMarker 0.3s !important' : '',
            visibility: isStepChanging ? 'hidden' : 'visible',
          }}
        >
          {steps[currentTopicStep]?.text}
        </Typography>
      </Box>
      {steps[currentTopicStep]?.isLastStep ? (
        <Button
          className={styles.button}
          onClick={steps[currentTopicStep]?.onClick}
          sx={{
            animation: isStepChanging ? 'hideQsMarker 0.3s !important' : '',
            marginLeft: direction === 'row' ? 'auto' : '',
            visibility: isStepChanging ? 'hidden' : 'visible',
            border: isUwmClient ? '2px solid #A1CE57' : '2px solid #F2B03E',
          }}
          data-qs-button="true"
        >
          <Logout className={styles.logoutIcon} />
          {QuickStartStrings.Guide}
        </Button>
      ) : (
        <Button
          className={styles.button}
          onClick={() => {
            if (steps[currentTopicStep]?.hideMarkerOnClick) {
              setIsMarkerHiding(true);
              setTimeout(() => {
                steps[currentTopicStep]?.onClick();
              }, 0);

              return;
            }
            steps[currentTopicStep]?.onClick();
            if (steps[currentTopicStep]?.translate) {
              setIsTranslateAnimation(true);
              return;
            }

            setIsStepChanging(true);

            setTimeout(() => {
              setIsStepChanging(false);
            }, 300);
          }}
          sx={{
            marginLeft: direction === 'row' ? '14px' : 0,
            animation: isStepChanging ? 'hideQsMarker 0.3s !important' : '',
            visibility: isStepChanging ? 'hidden' : 'visible',
            border: isUwmClient ? '2px solid #A1CE57' : '2px solid #F2B03E',
          }}
          data-qs-button="true"
        >
          {QuickStartStrings.GotItNext}
        </Button>
      )}
    </Box>
  );
};

export default AnimatedQsMarker;
