import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: '#344054',
    background: theme.palette.common.white,
    border: '1px solid #D0D5DD',
    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: '16px',
    paddingRight: '22px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menu: {
    border: '1px solid #EAECF0',
    boxShadow: '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
    borderRadius: '8px',
  },
  item: {
    minHeight: '40px',
    padding: '10px 16px',
    background: theme.palette.common.white,
    color: '#667085',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
  },
  value: {
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: '700',
    marginLeft: 'auto',
  },
  icon: {
    height: '18px',
    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
}));
