import { FC } from "react";

export const WebAppicon:FC = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4997 18.9596H7.49967C3.82467 18.9596 2.70801 17.843 2.70801 14.168V5.83463C2.70801 2.15964 3.82467 1.04297 7.49967 1.04297H12.4997C16.1747 1.04297 17.2913 2.15964 17.2913 5.83463V14.168C17.2913 17.843 16.1747 18.9596 12.4997 18.9596ZM7.49967 2.29297C4.51634 2.29297 3.95801 2.85964 3.95801 5.83463V14.168C3.95801 17.143 4.51634 17.7096 7.49967 17.7096H12.4997C15.483 17.7096 16.0413 17.143 16.0413 14.168V5.83463C16.0413 2.85964 15.483 2.29297 12.4997 2.29297H7.49967Z"
      fill="#8B89A0"
    />
    <path
      d="M11.6663 5.20703H8.33301C7.99134 5.20703 7.70801 4.9237 7.70801 4.58203C7.70801 4.24036 7.99134 3.95703 8.33301 3.95703H11.6663C12.008 3.95703 12.2913 4.24036 12.2913 4.58203C12.2913 4.9237 12.008 5.20703 11.6663 5.20703Z"
      fill="#8B89A0"
    />
    <path
      d="M9.99967 16.5482C8.94134 16.5482 8.08301 15.6898 8.08301 14.6315C8.08301 13.5732 8.94134 12.7148 9.99967 12.7148C11.058 12.7148 11.9163 13.5732 11.9163 14.6315C11.9163 15.6898 11.058 16.5482 9.99967 16.5482ZM9.99967 13.9565C9.63301 13.9565 9.33301 14.2565 9.33301 14.6232C9.33301 14.9898 9.63301 15.2898 9.99967 15.2898C10.3663 15.2898 10.6663 14.9898 10.6663 14.6232C10.6663 14.2565 10.3663 13.9565 9.99967 13.9565Z"
      fill="#8B89A0"
    />
  </svg>
);

