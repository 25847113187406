import { FC } from 'react';

export const LinkIcon: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4197 7.54438C11.1658 7.79822 11.1658 8.20978 11.4197 8.46362C11.6735 8.71746 12.0851 8.71746 12.3389 8.46362L11.4197 7.54438ZM15.9532 12.0779C15.6994 12.3318 15.6994 12.7433 15.9532 12.9972C16.2071 13.251 16.6186 13.251 16.8725 12.9972L15.9532 12.0779ZM18.4086 9.62254L15.9532 12.0779L16.8725 12.9972L19.3278 10.5418L18.4086 9.62254ZM12.3389 8.46362L14.7943 6.00825L13.8751 5.08901L11.4197 7.54438L12.3389 8.46362ZM18.4086 6.00825C19.4066 7.00631 19.4066 8.62448 18.4086 9.62254L19.3278 10.5418C20.8336 9.03604 20.8336 6.59475 19.3278 5.08901L18.4086 6.00825ZM19.3278 5.08901C17.8221 3.58327 15.3808 3.58327 13.8751 5.08901L14.7943 6.00825C15.7924 5.01019 17.4105 5.01019 18.4086 6.00825L19.3278 5.08901Z"
      fill="currentColor"
    />
    <path
      d="M13.4122 16.4557C13.6661 16.2018 13.6661 15.7903 13.4122 15.5364C13.1584 15.2826 12.7468 15.2826 12.493 15.5364L13.4122 16.4557ZM8.87869 11.9221C9.13254 11.6683 9.13254 11.2567 8.87869 11.0029C8.62485 10.7491 8.2133 10.7491 7.95946 11.0029L8.87869 11.9221ZM6.42332 14.3775L8.87869 11.9221L7.95946 11.0029L5.50408 13.4583L6.42332 14.3775ZM12.493 15.5364L10.0376 17.9918L10.9569 18.9111L13.4122 16.4557L12.493 15.5364ZM6.42332 17.9918C5.42526 16.9938 5.42526 15.3756 6.42332 14.3775L5.50408 13.4583C3.99834 14.964 3.99834 17.4053 5.50408 18.9111L6.42332 17.9918ZM5.50408 18.9111C7.00982 20.4168 9.45111 20.4168 10.9569 18.9111L10.0376 17.9918C9.03956 18.9899 7.42138 18.9899 6.42332 17.9918L5.50408 18.9111Z"
      fill="currentColor"
    />
    <path
      d="M14.9026 9.51343L9.86177 14.5542"
      stroke="currentColor"
      strokeWidth={1.3}
      strokeLinecap="round"
    />
  </svg>
);
