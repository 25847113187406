import { createElement } from 'react';

export type Chunk = {
  highlight: boolean;
  start: number;
  end: number;
};

/**
 * Creates an array of chunk objects representing both higlightable
 * and non highlightable pieces of text that match each search word.
 * @return Array of "chunks" (where a Chunk is { start:number, end:number, highlight:boolean })
 */
export const findAll = ({
  autoEscape,
  caseSensitive = false,
  findChunks = defaultFindChunks,
  sanitize,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  findChunks?: typeof defaultFindChunks;
  sanitize?: typeof defaultSanitize;
  searchWords: Array<string>;
  textToHighlight: string;
}): Array<Chunk> =>
  fillInChunks({
    chunksToHighlight: combineChunks({
      chunks: findChunks({
        autoEscape,
        caseSensitive,
        sanitize,
        searchWords,
        textToHighlight,
      }),
    }),
    totalLength: textToHighlight ? textToHighlight.length : 0,
  });

/**
 * Examine text for any matches.
 * If we find matches, add them to the returned array as a "chunk" object ({start:number, end:number}).
 * @return {start:number, end:number}[]
 */
const defaultFindChunks = ({
  autoEscape,
  caseSensitive,
  sanitize = defaultSanitize,
  searchWords,
  textToHighlight,
}: {
  autoEscape?: boolean;
  caseSensitive?: boolean;
  sanitize?: typeof defaultSanitize;
  searchWords: Array<string>;
  textToHighlight: string;
}): Array<Chunk> => {
  textToHighlight = sanitize(textToHighlight);

  return searchWords
    .filter((searchWord) => searchWord) // Remove empty words
    .reduce((chunks: Chunk[], searchWord) => {
      searchWord = sanitize(searchWord);

      if (autoEscape) {
        searchWord = escapeRegExpFn(searchWord);
      }

      const regex = new RegExp(searchWord, caseSensitive ? 'g' : 'gi');

      let match;
      while ((match = regex.exec(textToHighlight))) {
        let start = match.index;
        let end = regex.lastIndex;
        // We do not return zero-length matches
        if (end > start) {
          chunks.push({ highlight: false, start, end });
        }

        // Prevent browsers like Firefox from getting stuck in an infinite loop
        // See http://www.regexguru.com/2008/04/watch-out-for-zero-length-matches/
        if (match.index === regex.lastIndex) {
          regex.lastIndex++;
        }
      }

      return chunks;
    }, []);
};
// Allow the findChunks to be overridden in findAll,
// but for backwards compatibility we export as the old name
export { defaultFindChunks as findChunks };

function defaultSanitize(string: string): string {
  return string;
}

/**
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param chunksToHighlight {start:number, end:number}[]
 * @param totalLength number
 * @return {start:number, end:number, highlight:boolean}[]
 */
export const fillInChunks = ({
  chunksToHighlight,
  totalLength,
}: {
  chunksToHighlight: Array<Chunk>;
  totalLength: number;
}): Array<Chunk> => {
  const allChunks: { start: number; end: number; highlight: boolean }[] = [];
  const append = (start: number, end: number, highlight: boolean) => {
    if (end - start > 0) {
      allChunks.push({
        start,
        end,
        highlight,
      });
    }
  };

  if (chunksToHighlight.length === 0) {
    append(0, totalLength, false);
  } else {
    let lastIndex = 0;
    chunksToHighlight.forEach((chunk) => {
      append(lastIndex, chunk.start, false);
      append(chunk.start, chunk.end, true);
      lastIndex = chunk.end;
    });
    append(lastIndex, totalLength, false);
  }
  return allChunks;
};

function escapeRegExpFn(string: string): string {
  // eslint-disable-next-line
  return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

/**
 * Takes an array of {start:number, end:number} objects and combines chunks that overlap into single chunks.
 * @return {start:number, end:number}[]
 */
export const combineChunks = ({ chunks }: { chunks: Chunk[] }): Chunk[] => {
  const newChunks: Chunk[] = chunks
    .sort((first, second) => first.start - second.start)
    .reduce((processedChunks: Chunk[], nextChunk: Chunk) => {
      // First chunk just goes straight in the array...
      if (processedChunks.length === 0) {
        return [nextChunk];
      } else {
        // ... subsequent chunks get checked to see if they overlap...
        const prevChunk = processedChunks.pop();
        if (nextChunk.start <= prevChunk!.end) {
          // It may be the case that prevChunk completely surrounds nextChunk, so take the
          // largest of the end indeces.
          const endIndex = Math.max(prevChunk!.end, nextChunk.end);
          processedChunks.push({ highlight: false, start: prevChunk!.start, end: endIndex });
        } else {
          processedChunks.push(prevChunk!, nextChunk);
        }
        return processedChunks;
      }
    }, []);

  return newChunks;
};

/**
 * From react-highlight-words
 * Given a set of chunks to highlight, create an additional set of chunks
 * to represent the bits of text between the highlighted text.
 * @param searchWords string[]
 * @param textToHighlight string
 * @param activeClassName string The class name to be applied to an active match. Use along with activeIndex
 * @param activeIndex number Specify the match index that should be actively highlighted. Use along with activeClassName
 * @param activeStyle React.CSSProperties The inline style to be applied to an active match. Use along with activeIndex
 * @param autoEscape boolean Escape characters in searchWords which are meaningful in regular expressions
 * @param caseSensitive boolean Search should be case sensitive; defaults to false
 * @param className string CSS class name applied to the outer/wrapper <span>
 * @param findChunks Function Use a custom function to search for matching chunks. This makes it possible to use arbitrary logic when looking for matches. See the default findChunks function in highlight-words-core for signature. Have a look at the custom findChunks example on how to use it.
 * @param highlightClassName String or Object. CSS class name applied to highlighted text or object mapping search term matches to class names.
 * @param highlightStyle Object. Inline styles applied to highlighted text
 * @param highlightTag string. Type of tag to wrap around highlighted matches; defaults to em but can also be a React element (class or functional)
 * @param sanitize Function. Process each search word and text to highlight before comparing (eg remove accents); signature (text: string): string
 * @param unhighlightTag string. Tag for unhighlighted text
 * @param unhighlightClassName string. CSS class name applied to unhighlighted text
 * @param unhighlightStyle React.CSSProperties. Inline styles applied to unhighlighted text
 * @return ReactNode
 */

interface IHighlighterProps {
  searchWords: string[];
  textToHighlight: string;
  activeClassName?: string;
  activeIndex?: number;
  activeStyle?: React.CSSProperties;
  autoEscape?: boolean;
  caseSensitive?: boolean;
  className?: string;
  findChunks?: typeof defaultFindChunks;
  highlightClassName?: string | { [key: string]: string };
  highlightStyle?: React.CSSProperties;
  highlightTag?: string;
  sanitize?: typeof defaultSanitize;
  unhighlightTag?: string;
  unhighlightClassName?: string;
  unhighlightStyle?: React.CSSProperties;
}

export const HighlighterComponent: React.FC<IHighlighterProps> = ({
  activeClassName = '',
  activeIndex = -1,
  activeStyle,
  autoEscape,
  caseSensitive = false,
  className,
  findChunks,
  highlightClassName = '',
  highlightStyle = {},
  highlightTag = 'em',
  sanitize,
  searchWords,
  textToHighlight,
  unhighlightTag = 'span',
  unhighlightClassName = '',
  unhighlightStyle,
  ...rest
}) => {
  const chunks = findAll({
    autoEscape,
    caseSensitive,
    findChunks,
    sanitize,
    searchWords,
    textToHighlight,
  });
  const HighlightTag = highlightTag;

  let highlightIndex = -1;
  let highlightClassNames = '';
  let highlightStyles;

  const lowercaseProps = (object: { [key: string]: string }) => {
    const mapped: { [key: string]: string } = {};
    for (let key in object) {
      mapped[key.toLowerCase()] = object[key];
    }
    return mapped;
  };
  const memoizedLowercaseProps = lowercaseProps;

  return createElement('span', {
    className,
    ...rest,
    children: chunks.map((chunk, index) => {
      const text = textToHighlight.substr(chunk.start, chunk.end - chunk.start);
      if (chunk.highlight) {
        highlightIndex++;
        let highlightClass;
        if (typeof highlightClassName === 'object') {
          if (!caseSensitive) {
            highlightClassName = memoizedLowercaseProps(highlightClassName);
            highlightClass = highlightClassName[text.toLowerCase()];
          } else {
            highlightClass = highlightClassName[text];
          }
        } else {
          highlightClass = highlightClassName;
        }
        const isActive = highlightIndex === +activeIndex;
        highlightClassNames = `${highlightClass} ${isActive ? activeClassName : ''}`;
        highlightStyles =
          isActive === true && activeStyle != null
            ? Object.assign({}, highlightStyle, activeStyle)
            : highlightStyle;
        const props: Iprops = {
          children: text,
          className: highlightClassNames,
          key: index,
          style: highlightStyles,
        };
        if (typeof HighlightTag !== 'string') {
          props.highlightIndex = highlightIndex;
        }
        return createElement(HighlightTag, props);
      } else {
        return createElement(unhighlightTag, {
          children: text,
          className: unhighlightClassName,
          key: index,
          style: unhighlightStyle,
        });
      }
    }),
  });
};

interface Iprops {
  children: string;
  className: string;
  key: number;
  style: React.CSSProperties;
  highlightIndex?: number;
}
