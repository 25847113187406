import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme, { editInvitationModal?: boolean }>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  buttonsWrapper: ({ editInvitationModal }) => ({
    display: editInvitationModal ? 'block' : 'flex',
  }),
  browseFilesButton: ({ editInvitationModal }) => ({
    height: '45px',
    padding: '16px 28px',

    '& p': {
      fontSize: '18px',
    },
    borderRadius: editInvitationModal ? '20px' : '100px',
  }),
  removeButton: ({ editInvitationModal }) => ({
    marginTop: '15px',
    marginLeft: editInvitationModal ? '0' : '30px',
  }),
  skipWrapper: {
    position: 'relative',
  },
  skipButton: {
    padding: '16px 28px',
    height: '45px',
    marginTop: '15px',
    borderRadius: '32px',
    fontSize: '18px',
    fontWeight: 500,
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  skipInfo: {
    position: 'absolute',
    bottom: '-10px',
    left: '28px',
    width: '250px',
    fontSize: '12px',
    color: theme.palette.common.gray600,
    fontFamily: 'Inter, sans-serif',
  },
  title: ({ editInvitationModal }) => ({
    fontWeight: '600',
    fontSize: editInvitationModal ? '14px' : '20px',
    color: '#344054',
    textAlign: 'start',
  }),
  subtitleWrapper: {
    width: '100%',
  },
  subTitle: {
    fontSize: '14px',
    color: '#475467',
    textAlign: 'start',
    fontFamily: 'Inter',
    lineHeight: '140%',
    fontWeight: 400,
    margin: '2px 0',
  },
  warning: {
    fontSize: '12px',
    color: 'red',
    textAlign: 'start',
    width: '300px',
  },
  listItem: {
    alignItems: 'flex-start',
  },
  bulletIcon: {
    minWidth: '15px',
    paddingTop: '8px',
  },
  subTitleIcon: {
    width: '5px',
    height: '5px',
    backgroundColor: '#475467',
    borderRadius: '100%',
  },
}));
