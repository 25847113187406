import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './UwmTransitionModal.styles';
import { LoginPageStrings } from '../../localization/en';
import { FC, useEffect, useState } from 'react';

const SESSION_TIME_OUT = 5000;

interface Props {
  showTimeoutImmediately?: boolean;
}

export const UwmTransitionModalContent: FC<Props> = ({ showTimeoutImmediately = false }) => {
  const styles = useStyles();
  const [isSessionTimeOut, setIsSessionTimeOut] = useState(showTimeoutImmediately);

  useEffect(() => {
    setTimeout(() => {
      setIsSessionTimeOut(true);
    }, SESSION_TIME_OUT);
  }, []);

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>
        {isSessionTimeOut ? LoginPageStrings.SessionTimeOut : LoginPageStrings.OneMomentPlease}
      </Typography>
      <Typography className={styles.subtitle} marginBottom={isSessionTimeOut ? '0' : '60px'}>
        {isSessionTimeOut ? LoginPageStrings.PleaseSingBack : LoginPageStrings.Transferring}
      </Typography>

      {isSessionTimeOut && (
        <Button className={styles.btn} href="https://brand360.uwm.com/#/">
          {LoginPageStrings.OpenBrand360}
        </Button>
      )}
    </Box>
  );
};
