import { Box, Button, ListItemButton, Popover, Stack, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useModal } from '../../../../hooks/useModal';

interface SelectorItem {
  title: string;
  value: string;
}

interface SelectorDropdownProps {
  selectedValue: SelectorItem['value'];
  items: SelectorItem[];
  onChange: (item: SelectorItem) => void;
  width?: string;
}

export const SelectorDropdown: React.FC<SelectorDropdownProps> = ({
  items,
  selectedValue,
  onChange,
  width = '128px',
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { isOpen, open, close } = useModal(false);

  const selectedItemTitle = items.find((item) => item.value === selectedValue)?.title;

  return (
    <Box
      sx={{ zoom: 'calc(1 / 0.9)' }} // revert global zoom property to fix popover position
    >
      <Button ref={anchorRef} onClick={open} variant="text">
        <Typography fontSize="14px" fontWeight="400">
          {selectedItemTitle}
        </Typography>
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorRef.current}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          mt: '4px',
        }}
      >
        <Stack
          sx={{
            width,
            padding: '6px 0',
          }}
        >
          {items.map((item) => {
            const isSelected = item.value === selectedValue;

            return (
              <ListItemButton
                key={item.title}
                onClick={() => {
                  if (!isSelected) {
                    onChange(item);
                  }
                  close();
                }}
                autoFocus={isSelected}
              >
                <Typography fontSize="14px" fontWeight="400" color="#101828">
                  {item.title}
                </Typography>
              </ListItemButton>
            );
          })}
        </Stack>
      </Popover>
    </Box>
  );
};
