import { NewCampaign } from '../NewCampaign/NewCampaign';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getCampaignById } from '../../store/slices/campaignsSlice';
import { setEditCampaign } from '../../store/slices/createCampaignSlice';
import { useClientType } from '../../services/hooks/useClientType';
import { Loader } from '../../common/components/Loader/Loader';
import { Box } from '@mui/material';
import { useStyles } from './EditCampaign.styles';

export const EditCampaign = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const { id: currentCampaignId } = useParams<{ id: string }>();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { isEditDataLoaded } = useTypedSelector((state) => state.createCampaign);

  useEffect(() => {
    if (currentCampaignId) {
      dispatch(getCampaignById(currentCampaignId));
    }
  }, [currentCampaignId, dispatch]);

  useEffect(() => {
    if (currentCampaign) {
      dispatch(
        setEditCampaign({
          campaign: currentCampaign,
          isHealthCare,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCampaign, dispatch]);

  return !isEditDataLoaded ? (
    <Box className={classes.loaderWrapper}>
      <Loader />
    </Box>
  ) : (
    <NewCampaign isEditMode={true} editCampaignId={currentCampaignId} />
  );
};
