import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useStyles } from './SearchPopUp.styles';
import { ClipsSearchStrings } from '../../../localization/en';
import { AdvancedTabContent } from './AdvancedTabContent/AdvancedTabContent';
import { SavedSearchesTabContent } from './SavedSearchesTabContent/SavedSearchesTabContent';
import { useAppDispatch } from '../../../../store';
import { getSearchFilters } from '../../../../store/slices/searchFilterSlice';
import { SearchTarget } from '../../../../api/models/searchFilterModels';

interface TabItem {
  title: string;
  tabContent: JSX.Element;
}

interface Props {
  onSearch: () => void;
  onApplyFilter: () => void;
}

export const SearchPopUp: FC<Props> = ({ onSearch, onApplyFilter }) => {
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getSearchFilters({
        target: SearchTarget.ACCOUNTS,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabItems: TabItem[] = useMemo(() => {
    return [
      {
        title: ClipsSearchStrings.Advanced,
        tabContent: <AdvancedTabContent onSearch={onSearch} />,
      },
      {
        title: ClipsSearchStrings.SavedSearches,
        tabContent: <SavedSearchesTabContent onApplyFilter={onApplyFilter} />,
      },
    ];
  }, [onApplyFilter, onSearch]);

  return (
    <Box className={classes.container}>
      <Tabs value={activeTabIndex} className={classes.tabsContainer}>
        {tabItems.map((item, index) => (
          <Tab
            className={classes.tabItem}
            onClick={() => {
              setActiveTabIndex(index);
            }}
            label={
              <Typography
                className={classes.tabTitle}
                color={activeTabIndex === index ? theme.palette.primary.main : '#667085'}
              >
                {item.title}
              </Typography>
            }
          />
        ))}
      </Tabs>

      {tabItems.map(({ tabContent }, index) => (
        <Box
          display={index === activeTabIndex ? 'block' : 'none'}
          className={classes.contentWrapper}
        >
          {tabContent}
        </Box>
      ))}
    </Box>
  );
};
