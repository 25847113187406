import { FC } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './UwmSessionTimeout.styles';
import { UwmTransitionModalContent } from '../../common/components/UwmTransitionModal/UwmTransitionModal';
import { BRAND_360_LOGO } from '../../common/constants/constants';

export const UwmSessionTimeout: FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.modal}>
        <Box component="img" src={BRAND_360_LOGO} alt="logo" className={classes.logo} />
        <UwmTransitionModalContent showTimeoutImmediately={true} />
      </Box>
    </Box>
  );
};
