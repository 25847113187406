import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RewardsActivitiesDTO, RewardsListEditItemDTO, RewardsListDTO } from '../storeModels';
import {
  RewardsListApiModel,
  RewardsCalculationApiModel,
  RewardsCalculationOptions,
  PostRewardRequestModel,
  ActivityApiModel,
} from '../../api/models/rewards';
import { ClientTypes } from '../../api/models/common';
import { httpClient } from '../../services/httpClient/httpClient';
import { RewardsEndpoints, getApiUrlForId } from '../../api/endpoints';

interface RewardsSlice {
  activities: RewardsActivitiesDTO;
  rewardsList: RewardsListDTO;
  activeReward: RewardsListApiModel | null;
}

const initialState: RewardsSlice = {
  activities: {
    activities: [],
    calculationResult: null,
    isLoading: false,
    error: false,
    lastUpdated: new Date().toISOString(),
  },
  rewardsList: {
    initialItems: [],
    editItem: null,
    editItems: [],
    addedItems: [],
    isLoading: false,
    error: false,
    lastUpdated: new Date().toISOString(),
  },
  activeReward: null,
};

export const getActivities = createAsyncThunk(
  'rewards/getActivities',
  async (_options: { clientType: ClientTypes }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ clientType: ClientTypes }, ActivityApiModel[]>({
        url: RewardsEndpoints.GetActivities,
        requiresToken: true,
        params: _options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getRewardsList = createAsyncThunk(
  'rewards/getRewardsList',
  async (options: { venueId: string }, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, RewardsListApiModel[]>({
        url: RewardsEndpoints.GetRewardsList,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const putRewardsItems = createAsyncThunk(
  'rewards/putRewardsItems',
  async (payload: Set<RewardsListEditItemDTO>, { dispatch }) => {
    dispatch(setRewardsListLoading(true));
    const httpClientRewardsItems = [];

    for (let reward of payload) {
      httpClientRewardsItems.push(
        httpClient.put<RewardsListApiModel, RewardsListApiModel>({
          url: getApiUrlForId(RewardsEndpoints.PutRewardById, reward.id),
          requiresToken: true,
          payload: reward,
        }),
      );
    }

    return await Promise.all(httpClientRewardsItems);
  },
);

export const postAddedRewardsItems = createAsyncThunk(
  'rewards/postAddedRewardsItems',
  async (options: { payload: RewardsListApiModel[]; venueId: string }, { dispatch }) => {
    dispatch(setRewardsListLoading(true));
    const httpClientRewardsItems = [];

    for (let reward of options.payload) {
      httpClientRewardsItems.push(
        httpClient.post<PostRewardRequestModel, RewardsListApiModel>({
          url: RewardsEndpoints.PostReward,
          requiresToken: true,
          payload: { ...reward, venueId: options.venueId },
        }),
      );
    }

    return await Promise.all(httpClientRewardsItems);
  },
);

export const getActiveReward = createAsyncThunk(
  'rewards/getActiveReward',
  async (_options: string, { rejectWithValue }) => {
    try {
      return await httpClient.get<{ venueId: string }, RewardsListApiModel>({
        url: getApiUrlForId(RewardsEndpoints.GetActiveReward, _options),
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const postRewardsCalculation = createAsyncThunk(
  'rewards/postRewardsCalculation',
  async (options: RewardsCalculationOptions, { rejectWithValue }) => {
    try {
      return await httpClient.post<
        | { venueId: string }
        | {
            rewardBudget: number;
            numberOfHeroes: number;
            rewardValue: number;
          },
        RewardsCalculationApiModel
      >({
        url: RewardsEndpoints.PostRewardCalculation,
        requiresToken: true,
        payload: options.payload,
        params: { venueId: options.venueId },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

//TODO: don't forget to add reset to logout
const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    revertActivities(state) {
      state.activities = initialState.activities;
    },
    setRewardsListLoading(state, action: PayloadAction<boolean>) {
      state.rewardsList.isLoading = action.payload;
    },
    setEditRewardsListItem(state, action: PayloadAction<RewardsListEditItemDTO | null>) {
      state.rewardsList.editItem = action.payload;
    },
    saveEditRewardsListItem(state, action: PayloadAction<RewardsListEditItemDTO[]>) {
      state.rewardsList.editItems = action.payload;
    },
    saveAddedRewardsListItem(state, action: PayloadAction<RewardsListEditItemDTO[]>) {
      state.rewardsList.addedItems = action.payload;
    },
    revertAllRewardsListItem(state) {
      state.rewardsList.editItems = state.rewardsList.initialItems;
    },
    updateRewardsList(state) {
      state.rewardsList.lastUpdated = new Date().toISOString();
    },
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getRewardsList.pending, (state) => {
      state.rewardsList.isLoading = true;
    });
    reducersBuilder.addCase(
      getRewardsList.fulfilled,
      (state, action: PayloadAction<RewardsListApiModel[]>) => {
        state.rewardsList.initialItems = action.payload;
        state.rewardsList.editItems = action.payload;
        state.rewardsList.isLoading = false;
      },
    );
    reducersBuilder.addCase(putRewardsItems.pending, (state) => {
      state.rewardsList.isLoading = true;
    });
    reducersBuilder.addCase(
      putRewardsItems.fulfilled,
      (state, action: PayloadAction<RewardsListApiModel[]>) => {
        // state.rewardsList.initialItems = state.rewardsList.editItems;
        state.rewardsList.lastUpdated = new Date().toISOString();
        state.rewardsList.isLoading = false;
      },
    );
    reducersBuilder.addCase(postAddedRewardsItems.pending, (state) => {
      state.rewardsList.isLoading = true;
    });
    reducersBuilder.addCase(postAddedRewardsItems.fulfilled, (state) => {
      state.rewardsList.lastUpdated = new Date().toISOString();
      state.rewardsList.isLoading = false;
      state.rewardsList.addedItems = [];
    });
    reducersBuilder.addCase(postRewardsCalculation.pending, (state) => {
      state.activities.isLoading = true;
    });
    reducersBuilder.addCase(
      postRewardsCalculation.fulfilled,
      (state, action: PayloadAction<RewardsCalculationApiModel>) => {
        state.activities.isLoading = false;
        state.activities.calculationResult = action.payload;
      },
    );
    reducersBuilder.addCase(getActivities.pending, (state) => {
      state.activities.isLoading = true;
    });
    reducersBuilder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.activities.activities = payload;
    });
    reducersBuilder.addCase(getActiveReward.fulfilled, (state, { payload }) => {
      state.activeReward = payload;
    });
  },
});

export const {
  reset,
  setEditRewardsListItem,
  saveEditRewardsListItem,
  saveAddedRewardsListItem,
  revertAllRewardsListItem,
  setRewardsListLoading,
  updateRewardsList,
  revertActivities,
} = rewardsSlice.actions;
export default rewardsSlice.reducer;
