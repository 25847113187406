import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SavedFiltersPageStrings, SearchFilterStrings } from '../../localization/en';
import { useStyles } from './CreateSearchFilterComponent.helper';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useState, FC, useCallback } from 'react';
import { CustomPopper, PopperPlacementsTypes } from '../Popper/Popper';
import { NewFilter } from './FilterPoppers/NewFilterPopper';
import { ListFilters } from './FilterPoppers/ListFiltersPopper';
import {
  SearchFilterApiModel,
  SearchTargets,
  SearchTargetsType,
} from '../../../api/models/searchFilter';
import { CreateUserFilterModal } from '../CreateUserFilterModal/CreateUserFilterModal';
import { CreateVideoFilterModal } from '../CreateVideoFilterModal/CreateVideoFilterModal';
import { useHistory, useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { SocialVerseFilterAppBar } from '../SocialVerseFilterAppBar/SocialVerseFilterAppBar';
import { setTarget } from '../../../store/slices/searchFiltersSlice';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';

enum searchFilterPopperTypes {
  create = 'create',
  list = 'list',
}

interface CreateSearchFilterComponentProps {
  target: SearchTargetsType;
  isSocailVerse: boolean;
  videosAmount?: number;
  onCreateFilterClick?: () => void;
  onSavedFiltersClick?: () => void;
}

export const CreateSearchFilterComponent: FC<CreateSearchFilterComponentProps> = ({
  target,
  isSocailVerse,
  videosAmount,
  onCreateFilterClick,
  onSavedFiltersClick,
}) => {
  const history = useHistory();
  const location = useLocation();
  const [anchorEl] = useState<HTMLElement | null>(null);
  const [type] = useState('');
  const [placement] = useState<PopperPlacementsTypes>();
  const { trackEvent } = useTrackEvent();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { items } = useTypedSelector((state) => state.SearchFilterSlice);
  const { newFilterAttributes, activeFilter } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );

  const dispatch = useAppDispatch();
  const [isCreationModal, setIsCreationModal] = useState(false);

  const toggleCreationModal = useCallback(() => {
    setIsCreationModal(!isCreationModal);
  }, [isCreationModal]);

  const [isNewFilterCreated, setisNewFilterCreated] = useState('');

  const filterToDisplay = () => {
    let filter = undefined as unknown as SearchFilterApiModel;
    if (newFilterAttributes.length && !isNewFilterCreated) {
      filter = {
        name: SavedFiltersPageStrings.UnsavedFilter,
        target: SearchTargets.VIDEOS,
        filter: {
          attributes: [...newFilterAttributes],
        },
      } as SearchFilterApiModel;
    } else if (isNewFilterCreated) {
      filter = {
        name: isNewFilterCreated,
        target: SearchTargets.VIDEOS,
        filter: {
          attributes: [...newFilterAttributes],
        },
      } as SearchFilterApiModel;
    } else if (activeFilter) {
      filter = activeFilter;
    }
    return filter;
  };

  return (
    <div className={classes.root}>
      {(newFilterAttributes.length || activeFilter) && isSocailVerse ? (
        <SocialVerseFilterAppBar
          videosAmount={videosAmount}
          toggleCreationModal={toggleCreationModal}
          isCreatedNewFilter={setisNewFilterCreated}
        />
      ) : (
        <>
          <CustomPopper open={open} anchorEl={anchorEl} placement={placement}>
            {type === searchFilterPopperTypes.create ? (
              <NewFilter handleCancel={() => setOpen(false)} target={target} />
            ) : (
              <ListFilters handleClose={() => setOpen(false)} />
            )}
          </CustomPopper>
          <Button
            variant="outlined"
            name={searchFilterPopperTypes.create}
            startIcon={<AddIcon />}
            className={classes.Button}
            onClick={() => {
              trackEvent(CreatorsTableEventNames.CreateFilterClick);
              toggleCreationModal();
              onCreateFilterClick?.();
            }}
          >
            {SearchFilterStrings.CreateFilter}
          </Button>
          {/* <Button
        variant="outlined"
        name={searchFilterPopperTypes.list}
        startIcon={
          <span className={[classes.Badge, items.length ? classes.ActiveBadge : ''].join(' ')}>
            <p className={classes.BadgeContent}>{items.length}</p>
          </span>
        }
        className={classes.Button}
        onClick={handleClick}
      >
        {SearchFilterStrings.SavedFilters}
      </Button> */}
          <Button
            variant="outlined"
            name={searchFilterPopperTypes.list}
            startIcon={
              <span className={[classes.Badge, items.length ? classes.ActiveBadge : ''].join(' ')}>
                <p className={classes.BadgeContent}>{items.length}</p>
              </span>
            }
            className={classes.Button}
            onClick={() => {
              history.push(WebsiteRoutes.Filters, { prevLocation: location.pathname });
              dispatch(setTarget(target));
              trackEvent(CreatorsTableEventNames.SavedFiltersOpen);
              onSavedFiltersClick?.();
            }}
          >
            {SearchFilterStrings.SavedFilters}
          </Button>
        </>
      )}
      {target === SearchTargets.VIDEOS ? (
        <CreateVideoFilterModal
          isCreationModal={isCreationModal}
          toggleCreationModal={toggleCreationModal}
          target={target}
          isSocailVerse={isSocailVerse}
          savedFilter={filterToDisplay()}
          onDateUpdate={(value) => {
            trackEvent(VideosEventNames.newFilterDateRangeUpdated, {
              value,
            });
          }}
        >
          <></>
        </CreateVideoFilterModal>
      ) : (
        <CreateUserFilterModal
          isCreationModal={isCreationModal}
          toggleCreationModal={toggleCreationModal}
          target={target}
          isSocailVerse={isSocailVerse}
        >
          <></>
        </CreateUserFilterModal>
      )}
    </div>
  );
};
