import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { FC, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import * as xlsx from 'xlsx';
import { CreateTransactionBatchResponse } from '../../../api/models/transaction';

import ImportModalExceedLimit from '../../../common/components/DataSync/MatchProperties/ImportModalExceedLimit';
// import { MatchProperties } from '../../../common/components/DataSync/MatchProperties/MatchProperties';
import { Dropzone, DropzoneProps } from '../../../common/components/Dropzone/Dropzone';
import { FSModal } from '../../../common/components/modal/Modal';
import { Step, Stepper } from '../../../common/components/Stepper/Stepper';
import { DataSyncStrings, InviteStrings, SmsInviteStrings } from '../../../common/localization/en';
import { useModal } from '../../../hooks/useModal';
import { useStep } from '../../../hooks/useStep';
import { useClientType } from '../../../services/hooks/useClientType';
import { getFileExtension } from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getTransactionBatches,
  putTransactionBatch,
  updateTransactionBatch,
} from '../../../store/slices/dataSyncSlice';
import { findMatch, useStyles, getValidationSchema } from './DataSync.helper';
import { ImportHistoryTableSection } from './ImportHistoryTableSection/ImportHistoryTableSection';
import { ScheduleDelivery } from './ScheduleDelivery/ScheduleDelivery';
import ApproveImportModal from '../../integrations/SmsInvite/Sections/ImportUsers/ImportModal/ApproveImportModal';
import { CommonEventNames } from '../../../common/constants/events/common';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { DataSyncEventNames } from '../../../common/constants/events/dataSyncEvents';
import { FieldItem, FieldsList } from '../../QuickStart/Invite/InviteLeftSection/FieldsList';
import { createInvitationBatch } from '../../../store/slices/campaignsSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className } = props;

  return (
    <div role="tabpanel" hidden={value !== index} aria-labelledby={`simple-tab-${index}`}>
      {value === index && (
        <Box className={className}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export enum DataSyncSteps {
  IMPORT_DATA = 'IMPORT_DATA',
  MATCH_PROPERTIES = 'MATCH_PROPERTIES',
  SCHEDULE_DELIVERY = 'SCHEDULE_DELIVERY',
  SUBMIT_MODAL = 'SUBMIT_MODAL',
}

interface DataSyncProps {
  hideTabs?: boolean;
  hideImportHistory?: boolean;
  showScheduleDeliveryStep?: boolean;
  showStepper?: boolean;
  autoApprove?: boolean;
  acceptFiletypes?: DropzoneProps['accept'];
  smsInvitesLayout?: boolean;
  isQuickStart?: boolean;
  onStepChange?: (step: DataSyncSteps) => void;
  onDataImported?: () => void;
}

export const DataSync: FC<DataSyncProps> = ({
  hideTabs,
  hideImportHistory,
  showScheduleDeliveryStep,
  showStepper,
  autoApprove,
  acceptFiletypes = ['csv', 'excel'],
  smsInvitesLayout,
  isQuickStart,
  onStepChange,
  onDataImported,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useTypedSelector((state) => state.venue.venue);
  const {
    error,
    items,
    page,
    size,
    sort,
    totalItems,
    totalPages,
    isLoading,
    approveLoading,
  } = useTypedSelector((state) => state.dataSyncSlice.data);
  const { trackEvent } = useTrackEvent();

  const { isHealthCare } = useClientType();

  const steps = useMemo<Step[]>(() => {
    const stepsData = [
      { value: DataSyncSteps.IMPORT_DATA, title: 'Import Data' },
      { value: DataSyncSteps.MATCH_PROPERTIES, title: 'Match Properties' },
      { value: DataSyncSteps.SCHEDULE_DELIVERY, title: 'Schedule Delivery' },
      { value: DataSyncSteps.SUBMIT_MODAL, title: '', hidden: true },
    ];

    if (!showScheduleDeliveryStep) {
      return stepsData.filter((step) => step.value !== DataSyncSteps.SCHEDULE_DELIVERY);
    }

    return stepsData;
  }, [showScheduleDeliveryStep]);

  const stepsValues = useMemo(() => {
    return steps.map((step) => step.value);
  }, [steps]);

  const { currentStep, moveToNextStep, moveToPrevStep, resetStep } = useStep(stepsValues);

  useEffect(() => {
    onStepChange?.(currentStep as DataSyncSteps);
  }, [currentStep, onStepChange]);

  const {
    isOpen: isImportEventsModalOpen,
    close: closeImportEventsModal,
    open: openImportEventsModal,
  } = useModal();

  const {
    isOpen: isImportEventsModalExceedLimit,
    close: closeImportEventsModalExceedLimit,
    open: openImportEventsModalExceedLimit,
  } = useModal();

  const [tab, setTab] = useState(0);
  const [uploadedCSVHeaders, setUploadedCSVHeaders] = useState<string[] | null>(null);
  const [uploadedCSVData, setUploadedCSVData] = useState<string[][] | null>(null);
  const [
    createdTransactionalBatch,
    setCreatedTransactionalBatch,
  ] = useState<CreateTransactionBatchResponse | null>(null);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue);
    trackEvent(DataSyncEventNames.changeTableTab);
  };

  const styles = useStyles({ smsInvitesLayout });
  const { addToast } = useToasts();

  const parseCsv = (csvData: string) => {
    const processedData = csvData.replace(/"/g, ''); // remove quotes from strings (for example, from links)
    const lines = processedData.split(/\r?\n|\r|\n/g);

    const [headers, ...tableRows] = lines.map((line) => line.split(','));
    setUploadedCSVHeaders(headers);
    setUploadedCSVData(tableRows);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      trackEvent(CommonEventNames.data_sync_file_uploaded, { value: acceptedFiles });
      const file = acceptedFiles[0];
      const fileExtension = getFileExtension(file.name);

      const isExcelFile = fileExtension ? ['xlsx', 'xls'].includes(fileExtension) : false;
      const isCsv = file.type === 'text/csv';

      if (!isExcelFile && !isCsv) {
        addToast(DataSyncStrings.SelectedWrongFileType, {
          appearance: 'error',
          autoDismiss: true,
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = function (e: any) {
        const data = e.target.result;

        let csvData = data;

        // convert excel file to csv
        if (isExcelFile) {
          const wb = xlsx.read(data, { type: 'binary' });
          const ws = wb.Sheets[wb.SheetNames[0]]; // Get first worksheet
          csvData = xlsx.utils.sheet_to_csv(ws);
        }

        parseCsv(csvData);
        moveToNextStep();
      };

      return file;
    },
    [addToast, moveToNextStep, trackEvent],
  );

  const formik = useFormik({
    initialValues: {
      firstName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'firstName') : '',
      lastName: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'lastName') : '',
      cell: uploadedCSVHeaders ? findMatch(uploadedCSVHeaders, 'cell') : '',
    },
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: getValidationSchema,
    validateOnChange: true,
  });

  // const getFields = useMemo(() => {
  //   const fieldsEntries = Object.entries(getValidationSchema().describe().fields);

  //   return [
  //     fieldsEntries
  //       .filter(
  //         ([key, _]) =>
  //           (getValidationSchema().describe().fields[key] as any).tests.findIndex(
  //             ({ name }: { name: string }) => name === 'required',
  //           ) >= 0,
  //       )
  //       .map((el) => el[0]),
  //     fieldsEntries
  //       .filter(
  //         ([key, _]) => (getValidationSchema().describe().fields[key] as any).tests.length === 0,
  //       )
  //       .map((el) => el[0]),
  //   ];
  // }, []);

  const getRecords = () => {
    if (!uploadedCSVData || !uploadedCSVHeaders) {
      return;
    }
    const findCorrespondingHeaderIndex = (name: string) => uploadedCSVHeaders.indexOf(name);
    return (uploadedCSVData[uploadedCSVData.length - 1].length === 1
      ? uploadedCSVData.slice(0, -1)
      : uploadedCSVData
    ).map((row: string[]) => ({
      phoneNumber: formik.values.cell ? row[findCorrespondingHeaderIndex(formik.values.cell)] : '',
      firstName: formik.values.firstName
        ? row[findCorrespondingHeaderIndex(formik.values.firstName)]
        : '',
      lastName: formik.values.lastName
        ? row[findCorrespondingHeaderIndex(formik.values.lastName)]
        : '',
    }));
  };

  const createTransactionalBatch = async (name: string) => {
    const records = getRecords();
    if (!records) {
      return;
    }

    const { payload } = await dispatch(
      createInvitationBatch({
        groupName: name,
        campaignId: '',
        invitations: records,
      }),
    );
    return payload as CreateTransactionBatchResponse;
  };

  const moveToSubmitModal = async () => {
    // if autoApprove is set -> should create transactional batch on import modal open
    let response;
    if (autoApprove) {
      const batchName = isQuickStart
        ? InviteStrings.DefaultUserImport
        : `${new Date().toDateString()} import`;

      const batch = await createTransactionalBatch(batchName);
      response = batch;
      if (!batch) return;

      setCreatedTransactionalBatch(batch);

      if (isQuickStart) {
        handleSendImportedReport(InviteStrings.DefaultUserImport, batch);
      }
    }

    if (response && response.numValidRecords > 750) {
      openImportEventsModalExceedLimit();
      return;
    }

    if (!isQuickStart) {
      openImportEventsModal();
    }
  };

  useEffect(() => {
    if (currentStep === DataSyncSteps.SUBMIT_MODAL) {
      moveToSubmitModal();
      return;
    }
    closeImportEventsModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const handleSendImportedReport = async (
    name: string,
    batch?: CreateTransactionBatchResponse | null,
  ) => {
    if ((createdTransactionalBatch || batch) && autoApprove) {
      const id = (createdTransactionalBatch?.id || batch?.id)!;
      // update created batch name and approve
      await dispatch(updateTransactionBatch({ id, name }));
      await dispatch(putTransactionBatch({ id }));
    } else {
      // default data-sync -> create new transactional batch
      await createTransactionalBatch(name);
    }

    setUploadedCSVHeaders(null);
    setUploadedCSVData(null);

    resetStep();
    onDataImported?.();
    addToast(DataSyncStrings.ToastSuccess, { appearance: 'success' });
  };

  useEffect(() => {
    if (!id) return;
    dispatch(
      getTransactionBatches({
        venueId: id,
        page,
        size,
        sort: sort.toString(),
      }),
    );
  }, [id, page, size, sort, dispatch]);

  const handleCloseImportEventsModalExceedLimit = () => {
    closeImportEventsModalExceedLimit();
    openImportEventsModal();
  };

  const dropZoneHeight = isQuickStart ? '300px' : 'auto';
  const dropZonePadding = isQuickStart ? '0' : '';

  // const handleImportClick = () => {
  //   moveToNextStep();
  //   trackEvent(CommonEventNames.data_sync_import_data_click);
  // };

  const fieldItems: FieldItem[][] = [
    [
      {
        text: SmsInviteStrings.FirstName,
        isBold: true,
      },
      {
        text: SmsInviteStrings.OptIn,
        isBold: false,
      },
    ],
    [
      {
        text: SmsInviteStrings.LastName,
        isBold: true,
      },
      {
        text: SmsInviteStrings.EmailAddress,
        isBold: false,
      },
    ],
    [
      {
        text: SmsInviteStrings.CellNumber,
        isBold: true,
      },
      {
        text: SmsInviteStrings.TransactionDate,
        isBold: false,
      },
    ],
  ];

  return (
    <Box className={styles.root}>
      {!hideTabs && !uploadedCSVHeaders && (
        <Tabs className={styles.tabs} value={tab} onChange={handleChangeTab}>
          <Tab className={styles.tab} label={DataSyncStrings.TabTitle} />
          <Tab disabled className={styles.tab} label="Automatic (Coming Soon)" />
        </Tabs>
      )}
      {showStepper && (
        <Box display="flex" justifyContent="center" mt="-72px">
          <Stepper currentStep={currentStep} steps={steps} newCampaignLayout={false} />
        </Box>
      )}
      {currentStep === DataSyncSteps.IMPORT_DATA && (
        <>
          <TabPanel value={tab} index={0} className={styles.tabPanel}>
            {(smsInvitesLayout || isQuickStart) && (
              <Box
                className={styles.inputSection}
                onClick={() => {
                  trackEvent(CommonEventNames.data_sync_drop_zone_click);
                }}
              >
                {!isQuickStart && (
                  <Box>
                    <Typography
                      color="#1A1538"
                      fontWeight="500"
                      fontSize="18px"
                      mb={'10px'}
                      letterSpacing="0.02em"
                    >
                      {isHealthCare
                        ? SmsInviteStrings.ImportExcelTitleTrublu
                        : SmsInviteStrings.ImportExcelTitle}
                    </Typography>
                    <Typography className={styles.importingText}>
                      {isHealthCare
                        ? SmsInviteStrings.WhenImporting_Trublu
                        : SmsInviteStrings.WhenImporting}
                    </Typography>
                    <Box className={styles.listWrapper}>
                      {fieldItems.map((item) => (
                        <FieldsList
                          items={item}
                          color="#8B89A0"
                          fontSize="14px"
                          margin="0 15px 0 0"
                        />
                      ))}
                    </Box>
                  </Box>
                )}
                <Dropzone
                  onDrop={onDrop}
                  accept={acceptFiletypes}
                  padding={dropZonePadding}
                  height={dropZoneHeight}
                  borderRadius={''}
                />
              </Box>
            )}
            {!hideImportHistory && (
              <>
                <ImportHistoryTableSection
                  items={items}
                  page={page}
                  size={size}
                  totalItems={totalItems}
                  totalPages={totalPages}
                  sort={sort}
                  isLoading={isLoading}
                  error={error}
                  btnIsLoading={approveLoading!}
                  addToast={addToast}
                />
              </>
            )}
          </TabPanel>
          <TabPanel value={tab} index={1} className={styles.tabPanel}>
            {DataSyncStrings.AutomaticComingSoon}
          </TabPanel>
        </>
      )}

      {/* {currentStep === DataSyncSteps.MATCH_PROPERTIES && (
        <MatchProperties
          onImportClick={handleImportClick}
          formik={formik}
          uploadedCSV={uploadedCSVHeaders ?? []}
          getFields={getFields}
          smsInvitesLayout={smsInvitesLayout}
          quickStartLayout={isQuickStart}
          newCampaignLayout={false}
          moveToPrevStep={moveToPrevStep}
          isLoading={isLoading}
        />
      )} */}
      {currentStep === DataSyncSteps.SCHEDULE_DELIVERY && (
        <ScheduleDelivery onSubmit={moveToNextStep} />
      )}
      <ApproveImportModal
        isModalOpen={isImportEventsModalOpen}
        onClose={() => {
          closeImportEventsModal();
          moveToPrevStep();
        }}
        usersCount={uploadedCSVData?.length || 0}
        handleImport={handleSendImportedReport}
        loading={Boolean(approveLoading)}
      />
      <FSModal
        onClose={handleCloseImportEventsModalExceedLimit}
        modalIsOpen={isImportEventsModalExceedLimit}
        rounded="16px"
        blur
        width="500px"
        padding="16px"
      >
        <ImportModalExceedLimit close={handleCloseImportEventsModalExceedLimit} />
      </FSModal>
    </Box>
  );
};
