import { FC } from 'react';
import { useStyles } from './RewardProgress.styles';
import { Box } from '@mui/material';

interface Props {
  goal: number;
  progress: number;
}

export const RewardProgress: FC<Props> = ({ goal, progress }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      sx={{
        background: goal > 1 ? '#fff' : goal === 1 && progress === 1 ? '#9B8AFB' : '#E1E5EE',
      }}
    >
      {goal > 1 &&
        Array(goal)
          .fill(0)
          .map((_, index) => (
            <Box
              className={classes.progressItem}
              sx={{
                borderRadius:
                  index === 0 ? '10px 0  0 10px ' : index === goal - 1 ? '0 10px 10px 0 ' : '',
                background: index < progress ? '#9B8AFB' : '#E1E5EE',
              }}
            ></Box>
          ))}
    </Box>
  );
};
