import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { LinearProgressWithLabel } from '../LinearProgressBar/LinearProgress.helper';
import { useStyles } from './ProgressBarWithCompletion.styles';
import CompleteIcon from '../../assets/CompleteIcon';

interface Props {
  progress: number;
  className?: string;
  text: string;
}

const ProgressBarWithCompletion: FC<Props> = ({ progress, className, text }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {progress === 100 && (
        <Box className={classes.completeWrapper}>
          <Box className={classes.iconWrapper}>
            <CompleteIcon />
          </Box>
          <Typography className={classes.completeText}>{text}</Typography>
        </Box>
      )}
      <LinearProgressWithLabel value={progress} className={className} />
    </Box>
  );
};

export default ProgressBarWithCompletion;
