import { Box, Checkbox, Dialog, FormControlLabel, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useStyles, validationSchema } from './ApproveImport.modal.helper';
import { SmsImportUserStrings } from '../../../../../../common/localization/en';
import { TextFieldComponent } from '../../../../../../common/components/TextField/TextField';
import { CheckIconGreen } from '../../../../../../common/assets/newDesign/SocialVerse/CheckIconGreen';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { LoadingButton } from '@mui/lab';
import { useHandleChangeWithTracking } from '../../../../../../services/hooks/useHandleChangeWithTracking';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../../../../../common/constants/events/common';

interface ImportModalProps {
  isModalOpen: boolean;
  onClose: () => void;
  usersCount: number;
  handleImport: (name: string) => Promise<void>;
  loading: boolean;
}

const ApproveImportModal: FC<ImportModalProps> = ({
  handleImport,
  isModalOpen,
  onClose,
  usersCount,
  loading,
}) => {
  const styles = useStyles();
  const { trackEvent } = useTrackEvent();
  const { values, handleChange, errors, dirty, setFieldTouched, handleBlur, resetForm } = useFormik(
    {
      initialValues: {
        name: '',
        agreement: false,
      },
      enableReinitialize: true,
      onSubmit: () => {},
      validationSchema,
      validateOnChange: true,
    },
  );
  const { handleChangeWithTracking } = useHandleChangeWithTracking(handleChange);
  const [isLoading, setIsLoading] = useState(loading);

  const { isHealthCare } = useClientType();

  const getUsersText = () => {
    const user = isHealthCare ? SmsImportUserStrings.Patient : SmsImportUserStrings.Customer;
    const endChar = usersCount > 1 ? 's' : '';
    return `${user}${endChar} ${SmsImportUserStrings.WillBeInvited}`;
  };

  return (
    <Dialog
      onClose={() => {
        onClose();
        trackEvent(CommonEventNames.approved_data_modal_agreement_close);
      }}
      open={isModalOpen}
    >
      <Box className={styles.modalRoot}>
        <Typography className={styles.modalTitle}>
          {SmsImportUserStrings.ImportModalTitle}
        </Typography>
        <Box className={styles.infoWrapper}>
          <Box className={styles.iconWrapper}>
            <CheckIconGreen />
          </Box>
          <Typography className={styles.usersCount}>{usersCount}</Typography>
          <Typography className={styles.usersText}>{getUsersText()}</Typography>
        </Box>
        <Typography className={styles.modalLabel}>
          {SmsImportUserStrings.ImportModalLabel}
        </Typography>
        <TextFieldComponent
          className={styles.input}
          placeholder={SmsImportUserStrings.ImportModalPlaceholder}
          name="name"
          label=""
          value={values.name}
          onChange={handleChangeWithTracking}
          onBlur={handleBlur}
          setFieldTouched={setFieldTouched}
          errorText={errors.name}
          fullWidth
          helperText
        />
        <Grid className={styles.modalAgreement}>
          <FormControlLabel
            label={<Typography>{SmsImportUserStrings.ImportModalAgreement}</Typography>}
            control={
              <Checkbox
                checked={values.agreement}
                name="agreement"
                onChange={(e) => {
                  handleChange(e);
                  trackEvent(CommonEventNames.approved_data_modal_agreement_checked, {
                    value: e.target.value ? 'checked' : 'unchecked',
                  });
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <LoadingButton
            className={styles.modalBtn}
            loading={isLoading}
            disabled={!!Object.keys(errors).length || !dirty || isLoading}
            variant="contained"
            color="primary"
            size="large"
            onClick={async () => {
              setIsLoading(true);
              await handleImport(values.name);
              setIsLoading(false);
              resetForm();
              trackEvent(CommonEventNames.approved_data_modal_agreement_button_click);
            }}
          >
            {SmsImportUserStrings.ImportModalBtn}
          </LoadingButton>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ApproveImportModal;
