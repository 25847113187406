import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useMembersStyles = makeStyles((theme: Theme) => ({
  AddManagerButton: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 25px',
    borderRadius: '100px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.primary.light,
    },
    '& svg': {
      color: theme.palette.common.white,
    },
  },
}));
