import { Box, Typography } from '@mui/material';
import { useTypedSelector } from '../../../store';
import { useTotalClicksStyles } from './TotalClicks.style';
import { RewardAdStrings } from '../../localization/en';
import { numberWithCommas } from '../../../services/utilities';

export const TotalClicks = () => {
  const styles = useTotalClicksStyles();
  const ctaClicks = useTypedSelector((state) => state.analytics.kpis.incentiveCtaClicks);

  const formatedNumber = ctaClicks ? numberWithCommas(ctaClicks) : numberWithCommas(0);

  return (
    <>
      <Box textAlign="center">
        <Typography className={styles.Title}>{RewardAdStrings.TotalClicks}</Typography>
        <Typography className={styles.Clicks}>{formatedNumber}</Typography>
      </Box>
    </>
  );
};
