import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
}));
