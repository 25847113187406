import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const pictureHeight = '310px';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    position: 'relative',
    backgroundColor: '#fff',
    // borderRadius: '16px',
    minHeight: '600px',
    width: '600px',
    overflowX: 'hidden',
  },
  title: {
    marginTop: '24px',
    marginBottom: '21px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    fontSize: '24px',
    fontWidth: '500',
    lineHeight: '40px',
  },
  nameAndActivities: {
    marginTop: '28px',
  },
  labelName: {
    color: `${theme.palette.common.deepDark} !important`,
  },
  nameInputWrapper: {
    width: '344px',
  },
  activitiesWrapper: {
    marginTop: '20px',
  },
  form: {
    marginTop: '8px',
  },
  inputWrap: {
    position: 'relative',
    display: 'block',
    backgroundPosition: 'center center',
    width: '100%',
    height: pictureHeight,
    objectFit: 'fill',
    marginBottom: '0px',
    // borderRadius: '16px 16px 0 0',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    // cursor: 'pointer',
  },
  targetPoints: {
    position: 'absolute',
    left: '22px',
    top: '15px',
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    padding: '4px 12px 10px 12px',
    textAlign: 'center',

    '& p': {
      color: '#8B89A0',

      '&:first-child': {
        fontSize: '14px',
      },

      '&:nth-child(2)': {
        lineHeight: '32px',
        fontSize: '32px',
      },
    },
  },
  ImageValidationError: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.error.main,
    position: 'relative',
  },
  ImageValidationCaption: {
    color: theme.palette.error.main,
    fontSize: '12px',
    position: 'relative',
    left: '30px',
    top: pictureHeight,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '8px',
    },
  },
  selectImageIconWrapper: {
    background: theme.palette.common.white,
    backgroundColor: '#F5F6F8',
    padding: '12px',
    position: 'absolute',
    top: ' 86%',
    left: '6%',
    transform: 'translateY(-50%) translateX(-50%)',
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    zIndex: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pictureInput: {
    width: '100%',
    height: pictureHeight,
    borderRadius: '16px 16px 0 0',
    opacity: 0,
    cursor: 'pointer',
    border: '1px solid red',
    zIndex: 10,
  },
  radioGroup: {
    width: '425px',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  marginTop: {
    marginTop: '6px',
  },
  activitiesList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: '10px',
    gap: '10px',
  },
}));
