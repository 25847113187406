import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    borderRadius: 16,
    padding: '40px  30px 30px',
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 18,
    textAlign: 'center',
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.gray,
    marginBottom: 24,
    textAlign: 'center',
  },
}));
