import { Box } from '@mui/material';
import { HowToEarnPointsStrings, IncentivesStrings } from '../../../common/localization/en';
import { DollarCircleIcon } from '../../../common/assets/DollarCircleIcon';
import { SharesIcon } from '../../../common/assets/SharesIcon';
import { PointsFrame } from '../../../common/components/pointsFrame/pointsFrame';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import {
  Tooltip,
  TooltipTitleProps,
  TooltipContentProps,
} from '../../../common/components/table/Tooltip';
import { FC } from 'react';

export const activitiesLiterals = {
  CREATE_VIDEO: IncentivesStrings.CreateVideos,
  CONVERSION: HowToEarnPointsStrings.Conversions,
  SHARE: HowToEarnPointsStrings.Shares,
  CALL_TO_ACTION: HowToEarnPointsStrings.CallToAction,
  BONUS_POINTS: HowToEarnPointsStrings.BonusPoints,
};

export type IndividualType = 'individual' | 'upload';
export type UploadVideoSelectOption = 'Yes' | 'No';

export const activitiesArr = {
  CREATE_VIDEO: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <PlayCircleOutlineIcon
          style={{ marginRight: '10px', transform: 'translate(0, 4px)', color: '#8B89A0' }}
        />
      }
      points={points}
      name={name}
    />
  ),
  SHARE: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <Box sx={{ transform: 'translate(0, 6px)' }}>
          <SharesIcon color={'#8B89A0'} />
        </Box>
      }
      points={points}
      name={name}
    />
  ),
  CALL_TO_ACTION: (points: number, name?: string) => (
    <PointsFrame
      icon={<img style={{ width: '24px', marginRight: '10px' }} src="/cursor.svg" alt="" />}
      points={points}
      name={name}
    />
  ),
  CONVERSION: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <Box sx={{ transform: 'translate(0, 2px)', opacity: '0.7' }}>
          <DollarCircleIcon color={'#8B89A0'} />
        </Box>
      }
      points={points}
      name={name}
    />
  ),
  BONUS_POINTS: (points: number, name?: string) => (
    <PointsFrame
      icon={<img style={{ width: '24px', marginRight: '10px' }} src="/star.svg" alt="" />}
      points={points}
      name={name}
    />
  ),
};

type TooltipProps = TooltipContentProps | TooltipTitleProps;

export const CustomTooltip: FC<TooltipProps> = ({ title, content, icon, ...rest }) => {
  return title ? (
    <Tooltip rounded="18px" title={title} icon={icon} {...rest} />
  ) : content ? (
    <Tooltip rounded="18px" content={content} icon={icon} {...rest} />
  ) : (
    <></>
  );
};
