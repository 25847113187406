import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const usePreviewVideoStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '430px',
    height: '430px',
    '&:hover button': {
      opacity: '1 !important',
    },
  },
  cardMedia: {
    borderRadius: '16px',
    overflow: 'hidden',
    border: '2px solid #3B6EED',
    cursor: 'pointer',
  },
  iconButton: {
    position: 'absolute',
    width: '70px',
    height: '70px',
    backgroundColor: 'unset',
    scale: '2',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));
