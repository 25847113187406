import { FC } from 'react';

interface Props {
  height?: number;
}

export const WelcomeScreenIcon: FC<Props | undefined> = ({ height }) => {
  return (
    <svg
      width={45}
      height={height || 56}
      viewBox="0 0 45 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44 55.9992V7C44 3.68629 41.3137 1 38 1H7C3.68629 1 1 3.68629 1 7V55.9992"
        stroke="currentColor"
      />
      <path
        d="M20.4453 4.77832H28.4453"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={17.3008} cy={4.77832} r={0.75} fill="currentColor" />
      <path
        opacity={0.1}
        d="M37.3307 8.33398H7.66406C6.00721 8.33398 4.66406 9.67713 4.66406 11.334V55.9999H40.3307V11.334C40.3307 9.67713 38.9876 8.33398 37.3307 8.33398Z"
        fill="currentColor"
      />
      <circle opacity={0.25} cx={22.5} cy={26.4199} r={11} fill="currentColor" />
      <circle
        cx={22.5}
        cy={23.6704}
        r={2.5}
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.2832 31.6693C27.2832 29.0276 25.1417 26.8861 22.5 26.8861C19.8583 26.8861 17.7168 29.0276 17.7168 31.6693"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect opacity={0.25} x={9.5} y={42.8594} width={26} height={7} rx={3.5} fill="currentColor" />
      <path
        d="M40.3307 55.9999V11.334C40.3307 9.67713 38.9876 8.33398 37.3307 8.33398H7.66406C6.00721 8.33398 4.66406 9.67713 4.66406 11.334V55.9999"
        stroke="currentColor"
      />
    </svg>
  );
};
