import { createTheme } from '@mui/material';
import { ThemeOptions, Theme } from '@mui/material/styles';

import { palette, components, typography, breakpointValues } from './base.theme';

const overridePalette = (palette: Theme['palette']) => {
  const _palette = { ...palette };

  _palette.primary.main = '#005F9E';
  _palette.primary.dark = '#213A51';
  _palette.primary.light = '#C3D8E5';

  _palette.secondary.main = '#00A39D';
  _palette.secondary.dark = '#C3E5E4';
  _palette.secondary.light = '#F3FFFF';

  _palette.common.sidebar.background = '#FFFFFF';
  _palette.common.sidebar.item.icon = '#8B89A0';
  _palette.common.sidebar.item.text = '#555555';
  _palette.common.sidebar.item.activeText = _palette.primary.main;
  _palette.common.sidebar.item.activeBackground = _palette.primary.light;
  _palette.common.sidebar.accountButton.text = _palette.secondary.main;
  _palette.common.sidebar.accountButton.detailsText = _palette.secondary.main;
  _palette.common.sidebar.accountButton.background = _palette.secondary.dark;
  _palette.common.sidebar.accountButton.activeBackground = _palette.primary.main;
  _palette.common.sidebar.accountButton.hoverBackground = _palette.primary.main;
  _palette.common.sidebar.accountButton.activeText = _palette.secondary.light;
  _palette.common.campaignSummary.primaryCampaignBorder = '#A1CE57';

  _palette.common.sidebar.accountsMenu.activeButton.background = _palette.primary.main;
  _palette.common.sidebar.accountsMenu.activeButton.text = '#FFFFFF';
  _palette.common.sidebar.accountsMenu.inactiveButton.background = '#FFFFFF00';
  _palette.common.sidebar.accountsMenu.inactiveButton.text = '#213A51';
  _palette.common.sidebar.accountsMenu.inactiveButton.hoverBackground = '#C3D8E5';
  _palette.common.sidebar.accountsMenu.inactiveButton.hoverText = '#152738';
  _palette.common.sidebar.accountsMenu.popover.background = '#F5F6F8';
  _palette.common.sidebar.accountsMenu.background = '#FFFFFF';

  _palette.background.default = '#005F9E';

  return _palette;
};

const overrideTypography = (typography: Theme['typography']) => {
  const _typography = { ...typography };

  _typography.fontFamily = 'Roboto, system-ui, sans-serif';

  return _typography;
};

export const uwmTheme = createTheme({
  palette: overridePalette(palette as any),
  components,
  typography: overrideTypography(typography as any),
  breakpoints: { values: breakpointValues },
} as ThemeOptions);
