import { LinkProps as MuiLinkProps, SxProps, Theme } from '@mui/material';

export interface LinkProps extends MuiLinkProps {
  title: string;
  href?: string;
  blank?: boolean;
}

export const linkStyle: SxProps<Theme> = (theme) => ({
  textDecoration: 'none',
  fontWeight: 500,
  color: theme.palette.primary.main,
  cursor: 'pointer',
});
