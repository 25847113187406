import React from 'react';
import { theme } from '../../theme';

const CheckedCheckboxIcon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1.29199" width="19" height="19" rx="4.5" fill={theme.palette.primary.main} />
      <path
        d="M6 10.9667L8.64844 13.6152C8.72655 13.6933 8.85318 13.6933 8.93129 13.6152L14.7183 7.82812"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <rect x="0.5" y="1.29199" width="19" height="19" rx="4.5" stroke="#E1E5EE" />
    </svg>
  );
};

export default CheckedCheckboxIcon;
