import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    padding: '63px 58px 81px 58px',
  },
  title: {
    textAlign: 'center',
    color: '#101828',
    fontSize: '36px',
    fontWeight: 600,
  },
  supportingText: {
    textAlign: 'center',
    color: '#475467',
    fontSize: '20px',
  },
  navigationWrapper: {
    margin: '10px auto 10px auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
  },
  navText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#475467',
    margin: '0 20px',
  },
  navigation: {
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
  cardsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  card: {
    width: '200px',
    height: '323px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  cardGradient: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0) 30%)',
  },
  detailsWrapper: {
    position: 'absolute',
    top: '22px',
    width: '100%',
    padding: '0 24px',
  },
  name: {
    color: '#fff',
    fontWeight: 500,
  },
  time: {
    fontSize: '14px',
    color: '#fff',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  videoCounter: {
    textAlign: 'center',
    fontWeight: 500,
  },
}));
