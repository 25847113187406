import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '62px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '44px',
    letterSpacing: '-0.64px',
  },
  subtitle: {
    color: '#00A39D',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    marginTop: '6px',
    textAlign: 'center',
  },
  btn: {
    marginTop: '62px',
    width: '164px',
    height: '44px',
    background: theme.palette.primary.main,
    color: '#fff',
    '&:active': {
      transform: 'scale(0.98)',
    },

    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
}));
