import { FC } from 'react';
import { Box, Grid } from '@mui/material';
import { useStyles } from './VideoBlock.helper';
import { TopGolfVideosApiModel } from '../../../../../api/models/topgolfVideos';
import { TopGolfVideoCard } from './TopGolfVideoCard';

interface VideoBlockProps {
  video: TopGolfVideosApiModel;
  handleOpenConfirmationModal?: (id: string) => void;
}

export const TopGolfVideoBlock: FC<VideoBlockProps> = ({ video, handleOpenConfirmationModal }) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Box className={classes.root}>
        <Grid item xs={12}>
          <Box className={classes.videoContainer}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <TopGolfVideoCard
                  video={video}
                  handleOpenConfirmationModal={handleOpenConfirmationModal}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
};
