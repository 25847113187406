import { Box, Skeleton, Typography } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { VideoAppTheme } from '../../../../api/models/venue';
import { useTypedSelector } from '../../../../store';
import { IPhonePreview } from '../../IPhonePreview/IPhonePreview';
import { FakeButton } from '../../IPhonePreview/previewComponents/FakeButton';
import { CoverType, getCoverBackground, getRevealBackground } from './CreateVideoPreview.helper';
import { useStyles } from './CreateVideoPreview.styles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { CustomizationSocialVerse, WebAppPageStrings } from '../../../localization/en';
import AnimationStyleSelect, {
  AnimationStyleOptions,
} from '../../AnimationStyleSelect/AnimationStyleSelect';
import { PlayIcon } from '../../../assets/newDesign/VideoControlsIcons/PlayIcon';
import { PauseCircle } from '@mui/icons-material';
import { useClientType } from '../../../../services/hooks/useClientType';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../../constants/routes';
import QSMarker from '../../QSMarker/QSMarker';
import { useQuickStartLaunchPad } from '../../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { Tooltip } from '../../Tooltip/Tooltip';

interface Props {
  image: string;
  brandColor?: string;
  logo: string | undefined;
  theme?: VideoAppTheme;
  isDisplayWelcomeVideo?: boolean;
  selectedAnimation?: string;
  buttonText?: string;
  reveal: 'branding' | 'splash';
  cover?: CoverType;
  launchScreenType: 'name' | 'logo';
  showAnimationStyleSelect?: boolean;

  handleAnimationStyleChange?: (style: AnimationStyleOptions) => void;
}

const CreateVideoPreview: FC<Props> = ({
  image,
  brandColor = '#42a5f5',
  logo,
  theme,
  isDisplayWelcomeVideo,
  selectedAnimation,
  buttonText,
  reveal,
  cover,
  launchScreenType,
  handleAnimationStyleChange,
  showAnimationStyleSelect,
}) => {
  const styles = useStyles();
  const [animation, setAnimation] = useState('ellipse(0% 0% at 100% 100%)');
  const [showPlayIcon, setShowPlayIcon] = useState(false);
  const [pauseClicked, setPauseClicked] = useState(false);
  const { isTrubluClient } = useClientType();
  const playIconTimer = useRef<any>(null);
  const firstAnimationTimer = useRef<any>(null);
  const secondAnimationTimer = useRef<any>(null);
  const thirdAnimationTimer = useRef<any>(null);
  const location = useLocation();
  const pathname = location.pathname;

  const {
    venue: { name: companyName },
  } = useTypedSelector((state) => state.venue);
  const { isQSMarkerVisible, hideQSMarker } = useQuickStartLaunchPad();

  const revealValue = reveal;

  const coverBackground = getCoverBackground(cover, brandColor);
  const revealBackground = getRevealBackground(reveal, brandColor, image);

  const clearAnimation = () => {
    setAnimation('ellipse(0% 0% at 100% 100%)');
    setShowPlayIcon(false);
    clearTimeout(firstAnimationTimer.current);
    clearTimeout(playIconTimer.current);
    clearTimeout(secondAnimationTimer.current);
    clearTimeout(thirdAnimationTimer.current);
  };

  useEffect(() => {
    const startAnimation = () => {
      firstAnimationTimer.current = setTimeout(() => {
        setAnimation('ellipse(140% 160% at 100% 100%)');
      }, 3400);

      if (!buttonText) {
        secondAnimationTimer.current = setTimeout(() => {
          setAnimation('skeleton');
        }, 5400);
      }

      thirdAnimationTimer.current = setTimeout(
        () => {
          setAnimation('ellipse(0% 0% at 100% 100%)');
        },
        !buttonText ? 8400 : 5400,
      );
    };

    if (!pauseClicked) {
      startAnimation();
    }

    const animationInterval = setInterval(
      () => {
        if (!pauseClicked) {
          startAnimation();
        }
      },
      !buttonText ? 8400 : 5400,
    );

    if (pauseClicked) {
      return () => {
        clearInterval(animationInterval);
        clearAnimation();
      };
    }

    return () => {
      console.log('clearing interval on umount');
      clearInterval(animationInterval);
      clearAnimation();
    };
  }, [selectedAnimation, logo, brandColor, image, pauseClicked, buttonText]);

  useEffect(() => {
    if (animation === 'ellipse(140% 160% at 100% 100%)') {
      setTimeout(() => {
        setShowPlayIcon(true);
      }, 320);
    } else {
      setShowPlayIcon(false);
    }
  }, [animation]);

  const handlePlayPauseClick = () => {
    setPauseClicked(!pauseClicked);
  };

  const isPreviewBGBlack = selectedAnimation?.includes('black');
  const shouldShowAnimationStyle = pathname.includes(WebsiteRoutes.BrandingStyleTab)
    ? true
    : showAnimationStyleSelect;

  return (
    <Box className={styles.container}>
      {!buttonText && shouldShowAnimationStyle && (
        <Box className={styles.infoWrapper}>
          <Typography className={styles.title}>
            {WebAppPageStrings.CreateVideoPreviewPageTitle}
          </Typography>
          {isQSMarkerVisible && !location.pathname.includes(WebsiteRoutes.QuickStart) && (
            <QSMarker />
          )}
        </Box>
      )}
      {!buttonText && shouldShowAnimationStyle && (
        <Box className={styles.animationInfoWrapper}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {handleAnimationStyleChange && (
              <AnimationStyleSelect
                splashImage={image}
                logo={logo || ''}
                value={selectedAnimation as AnimationStyleOptions}
                onChange={(value) => {
                  handleAnimationStyleChange(value);
                }}
                onClick={() => {
                  if (isQSMarkerVisible) hideQSMarker();
                }}
              />
            )}
            <Box sx={{ marginLeft: '10px' }}>
              <Tooltip
                content={<Box>{WebAppPageStrings.AnimationSelectTooltip}</Box>}
                iconClassName={styles.tooltipIcon}
              />
            </Box>
          </Box>
          <Typography className={styles.infoText}>
            {WebAppPageStrings.CreateVideoPreviewPageSubTitle}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          position: 'relative',
        }}
        className={styles.preview}
      >
        <Box className={styles.playIconWrapper}>
          {pauseClicked ? (
            <Box onClick={handlePlayPauseClick} className={styles.playIcon}>
              <PlayIcon />
            </Box>
          ) : (
            <Box onClick={handlePlayPauseClick} className={styles.pauseIcon}>
              <PauseCircle />
            </Box>
          )}
        </Box>
        <IPhonePreview height={632} width={312}>
          <Box
            className={styles.contentWrapper}
            sx={{ background: animation === 'skeleton' ? '#fff' : coverBackground }}
          >
            {animation !== 'skeleton' ? (
              launchScreenType === 'name' ? (
                <Typography className={styles.companyName} sx={{ color: brandColor }}>
                  {companyName}
                </Typography>
              ) : logo ? (
                <img
                  src={logo}
                  alt="Company logo"
                  style={{ objectFit: 'contain', width: '255px', maxHeight: '120px' }}
                />
              ) : (
                <Typography className={styles.companyName} sx={{ color: brandColor }}>
                  {companyName}
                </Typography>
              )
            ) : (
              false
            )}
            {buttonText && showPlayIcon && (
              <Box fontSize={45} className={styles.PlayIcon}>
                <PlayArrowRoundedIcon fontSize="inherit" color="inherit" />
              </Box>
            )}

            {animation === 'skeleton' ? (
              <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
                <Box
                  className={styles.skeleton}
                  width={'96%'}
                  height={42}
                  sx={{
                    top: '2px',
                    left: '4px',
                    background: isPreviewBGBlack ? '#717171' : '#71717133',
                  }}
                />
                <Skeleton
                  className={styles.skeleton}
                  variant="rectangular"
                  width={'90%'}
                  height={14}
                  sx={{
                    top: '10px',
                    left: '12px',
                    background: isPreviewBGBlack ? '#818181' : '#71717199',
                  }}
                />
                <Skeleton
                  className={styles.skeleton}
                  variant="rectangular"
                  width={'90%'}
                  height={4}
                  sx={{
                    top: '32px',
                    left: '12px',
                    background: isPreviewBGBlack ? '#818181' : '#71717199',
                  }}
                />
                <Skeleton
                  className={styles.skeleton}
                  variant="rectangular"
                  width={80}
                  height={20}
                  sx={{
                    bottom: '2px',
                    left: '4px',
                    background: isPreviewBGBlack ? '#717171' : '#71717112',
                  }}
                />
                <Skeleton
                  className={styles.skeleton}
                  variant="rectangular"
                  width={80}
                  height={20}
                  sx={{
                    bottom: '2px',
                    left: '93px',
                    background: isPreviewBGBlack ? '#717171' : '#71717121',
                  }}
                />
                <Skeleton
                  className={styles.skeleton}
                  variant="rectangular"
                  width={80}
                  height={20}
                  sx={{
                    bottom: '2px',
                    right: '4px',
                    background: isPreviewBGBlack ? '#717171' : '#71717133',
                  }}
                />
              </Box>
            ) : (
              <Box
                className={styles.revealWrapper}
                sx={{
                  background: buttonText
                    ? `url(/${
                        isTrubluClient
                          ? 'trublunewShareVideoPreview.jpg'
                          : 'newShareVideoPreview-2.png'
                      })`
                    : revealValue === 'branding'
                    ? revealBackground
                    : `url(${revealBackground})`,
                  clipPath: animation,
                  transition:
                    animation === 'ellipse(140% 160% at 100% 100%)'
                      ? `clip-path 0.6s ease-in-out`
                      : `clip-path 0.01s linear`,
                }}
              >
                {!buttonText && (
                  <Typography
                    className={styles.companyName}
                    sx={{
                      textShadow: revealValue === 'splash' ? `2px 4px 0px ${brandColor}` : 'unset',
                      color: '#FFF',
                    }}
                  >
                    {CustomizationSocialVerse.LetsGo}
                  </Typography>
                )}
                {buttonText && (
                  <Box className={styles.buttonWrapper}>
                    <FakeButton
                      text={buttonText}
                      color={brandColor}
                      minWidth="184px"
                      marginTop="10px"
                      textAlign={'center'}
                      width="fit-content"
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </IPhonePreview>
      </Box>
    </Box>
  );
};

export default CreateVideoPreview;
