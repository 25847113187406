import { FC } from 'react';
import { Grid, Box, Typography, Dialog, DialogContent, Button } from '@mui/material';
import { SocialVerseConfigurationString } from '../../../common/localization/en';
import { LogoModalIcon } from '../../../common/assets/newDesign/WebAppIcons/LogoModal';
import { useSVmodalStyles } from './SVModal.style';
interface IFTEModalProps {
  openModal: boolean;
  toggleModal: (open: boolean) => void;
  closeWithoutSendingConfig?: boolean;
}

export const SVModal: FC<IFTEModalProps> = ({
  openModal,
  toggleModal,
  closeWithoutSendingConfig,
}) => {
  const classes = useSVmodalStyles();
  const handleCloseModal = () => {
    toggleModal(!openModal);
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleCloseModal}
      open={openModal}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <Grid container>
          <Grid item xs={7}>
            <Grid container flexDirection="row" className={classes.wrapperMainContent}>
              <Grid item container sx={{ height: '70px' }}>
                <Grid item xs={2}>
                  <LogoModalIcon />
                </Grid>
              </Grid>
              <Grid container flexDirection="column" sx={{ height: 'calc(100% - 60px)' }}>
                <Grid item className={classes.body} sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h3">{SocialVerseConfigurationString.ModalTitle}</Typography>
                  <Typography className={classes.textModal}>
                    {SocialVerseConfigurationString.ModalContent1}
                  </Typography>
                  <Typography className={classes.textModal}>
                    {SocialVerseConfigurationString.ModalContent2}
                  </Typography>
                  <Typography className={classes.textModal}>
                    {SocialVerseConfigurationString.ModalContent3}
                  </Typography>
                </Grid>
                <Grid item sx={{ flex: '0 0 auto' }}>
                  <Button variant="contained" className={classes.button} onClick={handleCloseModal}>
                    <Typography variant="body2">
                      {SocialVerseConfigurationString.SetupSocialVerse}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} className={classes.wrapperEducationSection}>
            <Box className={classes.wrapperEducationCards}>
              <Grid className={classes.modalImage}>
                <img src={'/social-verse-modal-GoogleIcon.png'} alt="" />
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
