import * as Yup from 'yup';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { ErrorsStrings } from '../../../common/localization/en';

export const MAX_TITLE_LENGTH = 42;
export const MAX_SUPPORTING_TEXT_LENGTH = 42;

const validationSchemaCards = () =>
  Yup.object().shape({
    title: Yup.string().max(MAX_TITLE_LENGTH, 'Title must be at most 42 characters'),
    supportingText: Yup.string().max(
      MAX_SUPPORTING_TEXT_LENGTH,
      'Supporting text must be at most 42 characters',
    ),
  });

const validationSchemaSpheres = () => Yup.object().shape({});

const validationSchemaEducational = () =>
  Yup.object().shape({
    title: Yup.string()
      .max(MAX_TITLE_LENGTH, 'Title must be at most 42 characters')
      .required(ErrorsStrings.FieldIsRequired),
    supportingText: Yup.string().max(
      MAX_SUPPORTING_TEXT_LENGTH,
      'Supporting text must be at most 42 characters',
    ),
  });

export const getValidationSchema = (type: TypeSocialVerse) => {
  const validationSchemas: Record<TypeSocialVerse, typeof validationSchemaSpheres> = {
    [TypeSocialVerse.Cards]: validationSchemaCards,
    [TypeSocialVerse.Circles]: validationSchemaSpheres,
    [TypeSocialVerse.Spheres]: validationSchemaSpheres,
    [TypeSocialVerse.Perspective]: validationSchemaCards,
    [TypeSocialVerse.Grid]: validationSchemaCards,
    [TypeSocialVerse.Educational]: validationSchemaEducational,
  };

  return validationSchemas[type];
};
