import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '80px',
    padding: '0 75px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
    marginBottom: '1px',
  },
  navBtn: {
    width: '184px !important',
    height: '54px !important',

    '&:disabled p': {
      color: theme.palette.common.white,
    },
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '14px',
    color: '#000000',
    marginLeft: '13px',
  },
  tabItem: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    textDecoration: 'none',
  },
  tabTitle: {
    fontSize: '14px',
    lineHeight: '26px',
    marginLeft: '6px',
  },
}));
