import { FC } from 'react';

import { SmallImageContainer } from './CommonTable.style';

interface ImageComponentProps {
  imageRef: string;
  borderRadius?: string;
  border?: string;
  imgViewBottom?: boolean;
  alt?: string;
}

export const ImageComponent: FC<ImageComponentProps> = ({
  imageRef,
  alt = '',
  borderRadius = '6px',
  border,
  imgViewBottom,
}) => {
  return (
    <SmallImageContainer imgViewBottom={imgViewBottom} borderRadius={borderRadius} border={border}>
      <img alt={alt} src={imageRef} />
    </SmallImageContainer>
  );
};
