import { FC, useMemo } from 'react';
import { useTypedSelector } from '../../../store';
import { Dialog } from '@mui/material';
import { getAgreementsHtmlText } from './AgreementsModal.helper';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AgreementsModal: FC<Props> = ({ isOpen, onClose }) => {
  const {
    venueAttributes: { properties },
  } = useTypedSelector((state) => state.venue);

  const agreementAttributes = useMemo(() => {
    return (properties && properties['agreement_attributes']) || null;
  }, [properties]);

  if (!agreementAttributes) {
    return <></>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        width: '1200px',
        margin: '0 auto',
      }}
    >
      <div
        dangerouslySetInnerHTML={{ __html: `${getAgreementsHtmlText(agreementAttributes)}` }}
        style={{
          overflowX: 'hidden',
        }}
      />
    </Dialog>
  );
};
