import { FC } from 'react';
import { AnalyticsBlockModel } from '../AnalyticsSection.helper';
import { Box, Typography } from '@mui/material';
import { useStyles } from './AnalyticsBucket.styles';
import { useClientType } from '../../../../services/hooks/useClientType';
import { getTextSplit } from './AnalyticsBucket.helper';
import { KpiAnalyticsApiNames, KPIApiNames } from '../../../../api/models/analytics';
import { useTypedSelector } from '../../../../store';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';

interface Props {
  analyticItem: AnalyticsBlockModel;
  onClick: (kpiName: KPIApiNames, name: string) => void;
}

export const AnalyticsBucket: FC<Props> = ({ analyticItem, onClick }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isHealthCare, isTopGolfClient } = useClientType();
  const { activeAnalyticsBlock } = useTypedSelector((state) => state.analytics);
  const [titleFirst, titleSecond] = getTextSplit(
    isHealthCare ? analyticItem.name.replace('Creators', 'Patients') : analyticItem.name,
  );

  const kpiName = isTopGolfClient ? analyticItem.kpiName : analyticItem.apiName;

  return (
    <Box
      className={classes.container}
      onClick={() => {
        if (kpiName === KPIApiNames.ActiveCampaigns) {
          history.push(WebsiteRoutes.RewardCampaigns);
        }

        if (kpiName === KpiAnalyticsApiNames.SocialVerses) {
          history.push(`${WebsiteRoutes.SocialVerse}?live=true`);
        }

        onClick(kpiName as KPIApiNames, analyticItem.name);
      }}
      style={{
        border: kpiName === activeAnalyticsBlock.main ? '1px solid rgb(82, 66, 234)' : 'unset',
      }}
    >
      <Box className={classes.tooltip}>
        <Tooltip
          title={
            isHealthCare && analyticItem.kpiName
              ? analyticItem.tooltipText.replace('creators', 'patients')
              : analyticItem.tooltipText
          }
          padding="12px"
          rounded="12px"
          light={true}
          iconClassName={classes.tooltipIcon}
        />
      </Box>
      <Box className={classes.topWrapper}>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.title}>{titleFirst}</Typography>
          <Typography className={classes.title}>{titleSecond}</Typography>
        </Box>
      </Box>
      <Typography className={classes.value}>{analyticItem.value}</Typography>
    </Box>
  );
};
