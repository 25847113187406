export enum SmsMessageType {
  Auth = 'AUTH',
  Invite = 'INVITE',
  Reward = 'REWARD',
  Test = 'TEST',
  Incoming = 'INCOMING',
  UploadNotification = ' UPLOAD_NOTIFICATION',
  Other = 'OTHER',
  ManagerNotification = 'MANAGER_NOTIFICATION',
}

export enum SmsMessageStatus {
  Accepted = 'ACCEPTED',
  Queued = 'QUEUED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Failed = 'FAILED',
  Delivered = 'DELIVERED',
  Undelivered = 'UNDELIVERED',
  Receiving = 'RECEIVING',
  Received = 'RECEIVED',
  OptOut = 'OPTED_OUT',
  Canceled = 'CANCELED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Scheduled = 'SCHEDULED',
  Read = 'READ',
}

export interface SmsMessageModel {
  id: string;
  fromNumber: string;
  toNumber: string;
  message: string;
  status: SmsMessageStatus;
  communicationType: SmsMessageType;
  mediaUrl: string;
  createdAt: string;
  displayName: string;
  optOut: string;
  errorCode: number;
  errorMessage: string;
  isExcess: boolean;
}

export interface SmsMessagesTotalCountsData {
  totalOutgoing: number;
  totalDelivered: number;
  totalUndelivered: number;
  totalOptOut: number;
}

export interface SmsMessagesCountsData {
  deliveredInvites: number;
  undeliveredInvites: number;
  deliveredAcknowledgements: number;
  undeliveredAcknowledgements: number;
  optOuts: number;
  totalDelivered: number;
  totalUndelivered: number;
}
