import { FC } from 'react';
import { Grid, Button } from '@mui/material';

import { FSModal } from '../../../common/components/modal/Modal';
import { CommonStrings } from '../../../common/localization/en';
import { StyledConfirmText } from './DeleteModal.style';

interface DeleteModalProps {
  handleCloseDeleteModal: () => void;
  isDeleteModalOpen: boolean;
  handleDelete: () => void;
  archived: boolean;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  handleCloseDeleteModal,
  isDeleteModalOpen,
  handleDelete,
  archived,
}) => {
  return (
    <FSModal onClose={handleCloseDeleteModal} modalIsOpen={isDeleteModalOpen}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={3}>
        <Grid item xs={10}>
          <StyledConfirmText>
            {archived ? CommonStrings.ConfirmUnarchive : CommonStrings.ConfirmDelete}
          </StyledConfirmText>
        </Grid>
        <Grid item xs={8}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Button color="primary" onClick={handleCloseDeleteModal}>
                {CommonStrings.Cancel}
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" color="primary" onClick={handleDelete}>
                {archived ? CommonStrings.Unarchive : CommonStrings.Archive}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FSModal>
  );
};
