import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    gap: '24px',
    margin: '20px 0',
  },
  bucket: {
    backgroundColor: '#fff',
    width: '205px',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    padding: '24px',
  },
  bucketTitle: {
    color: '#475467',
    fontWeight: 500,
  },
  bucketAmount: {
    fontSize: '36px',
    fontWeight: 600,
    color: '#000',
    lineHeight: '38px',
    marginTop: '5px',
  },
  peekWrapper: {
    padding: '14px 9px 12px 24px',
    borderRadius: '12px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    backgroundColor: '#fff',
    maxWidth: '340px',
  },
  peekTopWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  badgeWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  peekLogo: {
    width: '24px',
    marginRight: '8px',
  },
  connectedBadge: {
    padding: '2px 6px',
    borderRadius: '8px',
    backgroundColor: '#E4E1FF',
    fontSize: '12px',
    color: theme.palette.primary.main,
    fontWeight: 500,
    lineHeight: '20px',
  },
  peekInfo: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    color: theme.palette.common.gray600,
    margin: '6px 0',
  },
  accountId: {
    fontSize: '11px',
    lineHeight: '17px',
    color: theme.palette.common.Text.secondary,
  },
  moreButton: {
    minWidth: 'unset',
    width: '20px',
    padding: 0,
  },
  moreIcon: {
    color: '#344054',
  },
  disconnectButton: {
    border: '1px solid #EAECF0',
    backgroundColor: '#FFF',
    color: '#344054',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    width: '220px',
    padding: '15px 16px',
    textAlign: 'left',
    display: 'block',
    marginTop: '20px',

    '&:hover': {
      backgroundColor: '#fdfdfd',
    },
  },
}));
