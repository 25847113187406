import { FC } from 'react';

export const SwapIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2798 11.1993C17.0898 11.1993 16.8997 11.1292 16.7497 10.9792C16.4597 10.6892 16.4597 10.2092 16.7497 9.91922L19.9397 6.72922L16.7497 3.53922C16.4597 3.24922 16.4597 2.76922 16.7497 2.47922C17.0397 2.18922 17.5197 2.18922 17.8097 2.47922L21.5298 6.19925C21.6698 6.33925 21.7497 6.52922 21.7497 6.72922C21.7497 6.92922 21.6698 7.11925 21.5298 7.25925L17.8097 10.9792C17.6597 11.1192 17.4698 11.1993 17.2798 11.1993Z"
      fill="#292D32"
    />
    <path
      d="M21 7.48047H3C2.59 7.48047 2.25 7.14047 2.25 6.73047C2.25 6.32047 2.59 5.98047 3 5.98047H21C21.41 5.98047 21.75 6.32047 21.75 6.73047C21.75 7.14047 21.41 7.48047 21 7.48047Z"
      fill="#292D32"
    />
    <path
      d="M6.71997 21.75C6.52997 21.75 6.34 21.68 6.19 21.53L2.46997 17.81C2.32997 17.67 2.25 17.48 2.25 17.28C2.25 17.08 2.32997 16.89 2.46997 16.75L6.19 13.03C6.48 12.74 6.96 12.74 7.25 13.03C7.54 13.32 7.54 13.8 7.25 14.09L4.06 17.28L7.25 20.4699C7.54 20.7599 7.54 21.24 7.25 21.53C7.11 21.68 6.91997 21.75 6.71997 21.75Z"
      fill="#292D32"
    />
    <path
      d="M21 18.0195H3C2.59 18.0195 2.25 17.6795 2.25 17.2695C2.25 16.8595 2.59 16.5195 3 16.5195H21C21.41 16.5195 21.75 16.8595 21.75 17.2695C21.75 17.6795 21.41 18.0195 21 18.0195Z"
      fill="#292D32"
    />
  </svg>
);
