import * as React from 'react';
import { SVGProps, memo } from 'react';
import { theme } from '../../../theme';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} fill="none" {...props}>
    <mask
      id="mask0_2080_94300"
      width={60}
      height={60}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}
    >
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
    </mask>
    <g mask="url(#mask0_2080_94300)">
      <circle cx={30} cy={30} r={30} fill="#D9D4FF" />
      <circle cx={30} cy={30} r={29.3} stroke={theme.palette.primary.main} strokeWidth={1.4} />
      <circle
        cx={29.765}
        cy={29.929}
        r={20.354}
        fill="#EEECFD"
        stroke="#A69DF7"
        strokeWidth={1.4}
        transform="rotate(-.122 29.765 29.93)"
      />
      <path fill={theme.palette.primary.main} d="M30 37.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z" />
      <path
        stroke="#A69DF7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M27.5 37.073V40a2.5 2.5 0 0 0 5 0v-2.927M30 17.5v1.25M18.75 30H17.5m4.375-8.125-.75-.75m17 .75.75-.75M42.5 30h-1.25m-3.75 0a7.5 7.5 0 1 1-15 0 7.5 7.5 0 0 1 15 0Z"
      />
    </g>
  </svg>
);
const VideoStyleInspirationalIconActive = memo(SvgComponent);
export default VideoStyleInspirationalIconActive;
