import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { SearchTargets } from '../../../api/models/searchFilter';
import { ActionBar, ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { activeModalLiteral } from '../../../common/components/ActionBar/ActionBarModals';
import { useAppDispatch, useTypedSelector } from '../../../store';

import { getSearchFilters } from '../../../store/slices/searchFiltersSlice';

import { HeroesPageStrings } from '../../../common/localization/en';
import { HeroesTable } from './heroesTable/HeroesTable';
import { ContentContainer } from './TableSection.style';
import { useTabs } from '../../../common/components/Tabs/useTabs';
import {
  CreatorAttributeName,
  CreatorFilterAttribute,
  CreatorStatus,
  SearchOperationType,
} from '../../../api/models/searchFilterModels';
import {
  setActiveCreatorFilterAttributes,
  setFilterSearch,
  setHasVideos,
} from '../../../store/slices/searchFilterSlice';
import { reset } from '../../../store/slices/heroesSlice';
import { CreatorsTabsSelect } from '../../../common/components/CreatorsTabsSelect/CreatorsTabsSelect';

interface TableSectionProps {
  showActionBar: boolean;
}

export const TableSection: FC<TableSectionProps> = ({ showActionBar }) => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { selectedRows } = useTypedSelector((state) => state.videos);

  const { activeUsersCount, pendingUsersCount, deactivatedUsersCount } = useTypedSelector(
    (state) => state.heroes,
  );

  const { activeTab, setActiveTab } = useTabs(2);

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  useEffect(() => {
    if (venueId) {
      dispatch(getSearchFilters({ accountId: venueId, target: SearchTargets.USERS }));
    }
  }, [venueId, dispatch]);

  const handleBtnClick = (name: typeof activeModal) => {
    setActiveModal(name);
  };

  const handleClose = () => {
    setActiveModal(null);
  };

  const headers = [
    { label: 'User Name', key: 'name' },
    { label: 'Status', key: 'status' },
    { label: 'Last activity', key: 'lastVideoCreatedAt' },
    { label: 'Videos', key: 'shares' },
    { label: 'Clicks', key: 'videoCtaClicks' },
    { label: 'Total Points', key: 'totalPoints' },
    { label: 'Rewards owed', key: 'rewardsFulfilled' },
  ];

  const hasActiveCampaignBonusPoints = false;
  const isAwardsBonusPointsActive = activeCampaign && hasActiveCampaignBonusPoints ? true : false;

  const tabsList = [
    {
      tabName: HeroesPageStrings.Active,
      usersCount: activeUsersCount?.toString(),
      filters: [
        {
          name: CreatorAttributeName.Status,
          operation: SearchOperationType.Equals,
          value: CreatorStatus.Active,
        },
      ],
      hasVideos: true,
      tabContent: (
        <HeroesTable showActionBar={!showActionBar} activeTab={activeTab} tableType="active" />
      ),
    },
    {
      tabName: HeroesPageStrings.PendingCreators,
      usersCount: pendingUsersCount?.toString(),
      filters: [],
      hasVideos: false,
      tabContent: (
        <HeroesTable showActionBar={!showActionBar} activeTab={activeTab} tableType="pending" />
      ),
    },
    {
      tabName: HeroesPageStrings.Deactivated,
      usersCount: deactivatedUsersCount?.toString(),
      filters: [
        {
          name: CreatorAttributeName.Status,
          operation: SearchOperationType.Equals,
          value: CreatorStatus.Inactive,
        },
      ],
      tabContent: (
        <HeroesTable showActionBar={!showActionBar} activeTab={activeTab} tableType="deactivated" />
      ),
      showEmpty: false,
    },
  ];

  useEffect(() => {
    dispatch(setHasVideos(tabsList[0].hasVideos));
    dispatch(setActiveCreatorFilterAttributes(tabsList[0].filters as CreatorFilterAttribute[]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTabChange = (index: number) => {
    setActiveTab(index);

    dispatch(setHasVideos(tabsList[index].hasVideos));
    dispatch(reset());
    dispatch(setFilterSearch(''));
    dispatch(setActiveCreatorFilterAttributes(tabsList[index].filters as CreatorFilterAttribute[]));
  };

  return (
    <ContentContainer>
      <Box>
        {showActionBar && (
          <ActionBar
            setActiveModal={(name) => handleBtnClick(name)}
            selectedRowsLength={selectedRows?.length}
            isAwardsBonusPointsActive={isAwardsBonusPointsActive}
          />
        )}
        {/* could be refactored in the end ↓ */}
        {activeModal === 'DOWNLOAD_CSV' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, headers)}
        {activeModal === 'ASSIGN_BONUS_POINTS' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose, selectedRows ?? [])}
        {activeModal === 'DOWNLOAD_CSV_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_TO_PLUGIN_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}
        {activeModal === 'ASSIGN_BONUS_POINTS_LIMIT' &&
          activeModalLiteral[activeModal](Boolean(activeModal), handleClose)}

        {!showActionBar && <CreatorsTabsSelect tabsList={tabsList} onTabChange={onTabChange} />}
      </Box>
    </ContentContainer>
  );
};
