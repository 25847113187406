import { Button, Box } from '@mui/material';
import { FC, useState } from 'react';
import { BrandingStylePreview } from '../../../../../../../common/components/previews/BrandingStylePreview/BrandingStylePreview';
import PaletteIcon from '@mui/icons-material/Palette';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { VenueWizardPreviewStepStrings } from '../../../../../../../common/localization/en';
import { FilterPreview } from '../../../../../../../common/components/previews/FilterPreview/FilterPreview';

interface WepAppBrandingAndFilterPreviewProps {
  brandingBgr: string;
  brandColor: string;
  textColor: string;
  filterImg: string;
  logo: string;
}
export const WepAppBrandingAndFilterPreview: FC<WepAppBrandingAndFilterPreviewProps> = ({
  brandingBgr,
  brandColor,
  textColor,
  logo,
  filterImg,
}) => {
  const [activeBrandingPreview, setActiveBrandingPreview] = useState(true);

  const toggleButtonHandler = () => {
    setActiveBrandingPreview(!activeBrandingPreview);
  };

  return (
    <Box pt={4} bgcolor="var(--pageBackgroundColor)" height="100%" borderRadius="16px">
      <Box display="flex" justifyContent="center">
        <Button
          variant={activeBrandingPreview ? 'contained' : 'outlined'}
          color="primary"
          onClick={toggleButtonHandler}
          startIcon={<PaletteIcon />}
        >
          {VenueWizardPreviewStepStrings.WebAppBranding}
        </Button>
        <Box marginLeft="-10px">
          <Button
            variant={!activeBrandingPreview ? 'contained' : 'outlined'}
            color="primary"
            onClick={toggleButtonHandler}
            startIcon={<CropOriginalIcon />}
          >
            {VenueWizardPreviewStepStrings.WebAppFilter}
          </Button>
        </Box>
      </Box>
      {activeBrandingPreview ? (
        <BrandingStylePreview
          brandColor={brandColor}
          textColor={textColor}
          src={brandingBgr}
          logo={logo}
        />
      ) : (
        <FilterPreview filterImg={filterImg} />
      )}
    </Box>
  );
};
