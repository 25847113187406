import { FC } from 'react';

interface Props {
  className?: string;
  transform?: string;
}

export const AccordionArrow: FC<Props> = ({ className, transform }) => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={{
      transform,
    }}
  >
    <path
      d="M1.5 1.49998L7.50013 7.50009L1.5 13.5"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
