import { useCallback, useMemo, useState } from 'react';

import { VenueProperties } from '../api/models/venue';
import { useAppDispatch, useTypedSelector } from '../store';
import { patchVenueProperties } from '../store/slices/venueSlice';

interface UseVenuePropertyArgs {
  property: keyof VenueProperties;
}

interface UpdateFunctionConfig {
  patchObjectValue: boolean;
}

type ValueOf<T> = T[keyof T];

export const useVenueProperty = <T extends ValueOf<VenueProperties>>({
  property,
}: UseVenuePropertyArgs) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const dispatch = useAppDispatch();
  const {
    venue: { id: venueId, isLoading: isVenueLoading },
    venueAttributes: { properties: venueProperties, isLoading: isVenueAttributesIsLoading },
  } = useTypedSelector((state) => state.venue);

  const isLoading = isVenueLoading || isVenueAttributesIsLoading;

  const value = useMemo(() => {
    if (!venueProperties) {
      return null;
    }

    return venueProperties[property] as T;
  }, [property, venueProperties]);

  /**
   * @param newValue value to save to venue properties
   * @param config config of update function
   * @param config.patchObjectValue provided value will be merged with old one when set to *true
   */
  const update = useCallback(
    async (newValue: Object, config: UpdateFunctionConfig = { patchObjectValue: false }) => {
      setIsUpdating(true);

      const canDestructObject = value && typeof typeof value === 'object' && !Array.isArray(value);
      const shouldPatchValue = config.patchObjectValue && canDestructObject;

      const updatedValue = shouldPatchValue
        ? {
            ...(value as object),
            ...newValue,
          }
        : newValue;

      try {
        await dispatch(
          patchVenueProperties({
            id: venueId,
            update: {
              name: property,
              value: updatedValue,
            },
            alert: false,
          }),
        );
      } finally {
        setIsUpdating(false);
      }
    },
    [dispatch, property, value, venueId],
  );

  return {
    value,
    isLoading,
    update,
    isUpdating,
  };
};
