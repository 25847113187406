import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { SearchOperationsType } from '../../../../api/models/searchFilter';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignContent: 'center',
    backgroundColor: '#F5F6F8',
    borderRadius: '12px',
    padding: '5px 0px',
    height: '52px',
    margin: '10px 0',
  },
  icon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputsRoot: {
    display: 'flex',
  },
  input: {
    background: '#EDEDEE',
    width: '50px',
    height: '40px',
    borderRadius: '12px',
    padding: '0px',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
interface ReadOnlyInlineStatItemProps {
  item: { name: string; operation: SearchOperationsType; value: any };
  icon: JSX.Element;
  label: string;
}
export const ReadOnlyInLineStatItem: FC<ReadOnlyInlineStatItemProps> = ({ item, icon, label }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.labelRoot}>
        <span className={classes.icon}>{icon}</span>
        <span className={classes.label}>{label}</span>
      </div>
      <div className={classes.inputsRoot}>
        <TextField
          className={classes.input}
          name="min"
          id="min"
          variant="outlined"
          type="number"
          value={item.value?.min}
          disabled
        />
        <span className={classes.label}>{'to'}</span>
        <TextField
          className={classes.input}
          name="max"
          id="max"
          variant="outlined"
          type="number"
          value={item.value?.max}
          disabled
        />
      </div>
    </div>
  );
};
