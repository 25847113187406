import { FC, useState } from 'react';
import { Button, Grid } from '@mui/material';

import { VenueCreationWizardStrings } from '../../../../common/localization/en';
import { SearchAccordion } from '../../../../common/components/SearchAccordion/SearchAccordion';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../../common/constants/constants';
import { SearchObject } from '../../../../common/components/SearchAccordion/SearchAccordion.helper';
import { useStyles } from './OrgSelection.helper';

interface OrgSelectionProps {
  market?: SearchObject;
  setMarket: (val: SearchObject) => void;
  markets: SearchObject[];
  handleLetsGo: () => void;
}
export const OrgSelection: FC<OrgSelectionProps> = ({
  market,
  setMarket,
  markets,
  handleLetsGo,
}) => {
  const classes = useStyles();
  const layout = useWindowResize();
  const [searchMarketValue, setSearchMarketValue] = useState('');

  const onChangeMarketSearch = (name: string) => {
    setSearchMarketValue(name);
  };
  const selectMarketValue = (value: SearchObject) => {
    setMarket(value);
  };
  return (
    <>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={8}>
          <Grid container justifyContent="center" alignItems="center" spacing={3}>
            <Grid item md={5} xs={4}>
              <p className={classes.title}>{VenueCreationWizardStrings.VenuWizardStart}</p>
            </Grid>
            <Grid item xs={8}>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={6}>
                  <p className={classes.label}>{VenueCreationWizardStrings.SelectMarket}</p>
                </Grid>
                <Grid item xs={6}>
                  <SearchAccordion
                    placeholder={market?.name || 'Market'}
                    onChangeSearch={onChangeMarketSearch}
                    selectValue={selectMarketValue}
                    items={markets}
                    searchValue={searchMarketValue}
                    layout={layout || LayoutMode.Desktop}
                    chosenValue={market}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <img className={classes.image} alt="wizard start page" src="/wizardstart.png" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.bottomNav}>
        <div className={classes.innerNav}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleLetsGo}
            disabled={!market}
            className={classes.button}
          >
            {VenueCreationWizardStrings.LetsGo}
          </Button>
        </div>
      </div>
    </>
  );
};
