import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { SocialVerseAllApiModel } from '../../../api/models/socialVerse';
import {
  formatDuration,
  getDisplayNameAndInitial,
  getVideoPoster,
} from '../../../services/utilities';

import { useStyles } from './GridView.styles';

interface Props {
  socialverse: SocialVerseAllApiModel;
}

export const GridView: FC<Props> = ({ socialverse }) => {
  const classes = useStyles();
  const mockedStories =
    12 - (socialverse?.topVideos?.length || 0) > 0 ? 12 - (socialverse?.topVideos?.length || 0) : 0;
  return (
    <Box className={classes.container}>
      {socialverse?.topVideos?.slice(0, 12).map((video) => (
        <Grid className={classes.story} key={video.videoId}>
          <Box className={classes.info}>
            <Typography className={classes.author}>
              {getDisplayNameAndInitial(video.userName)}
            </Typography>
            <Typography className={classes.duration}>
              {formatDuration(video.duration || 0, { twoDigitsMinutes: true })}
            </Typography>
          </Box>
          <img src={video.thumbnailUrl || getVideoPoster(video.url)} alt="" />
        </Grid>
      ))}
      {[...Array(mockedStories)].map((s, index) => (
        <Grid className={`${classes.story} ${classes.mockStory}`} sm={2.2} item key={index}>
          <Box className={classes.info}>
            <Typography className={classes.author}>{'First L'}</Typography>
            <Typography className={classes.duration}>{'0:23'}</Typography>
          </Box>
          <Box className={classes.mockBg} />
        </Grid>
      ))}
    </Box>
  );
};
