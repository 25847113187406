import { FC } from 'react';
import { Box } from '@mui/material';
import { Coupon } from '../../../common/components/Coupon/Coupon';
import { useVenue } from '../../../hooks/useVenue';
import { useTypedSelector } from '../../../store';

interface RewardsCardProps {
  reward: string;
  isNullReward: boolean;
}

export const RewardsCard: FC<RewardsCardProps> = ({ reward, isNullReward }) => {
  const me = useTypedSelector((state) => state.me);
  const { logo: logoImage } = useVenue();
  return (
    <Box sx={{ transform: 'scale(0.85)' }}>
      <Coupon
        companyLogo={logoImage || ''}
        cardOwnerFirstName={me.firstName || me.name.split(' ')?.[0] || ''}
        cardOwnerLastName={me.lastName || me.name.split(' ')?.[1] || ''}
        reward={reward || ''}
        isNullReward={isNullReward}
      />
    </Box>
  );
};
