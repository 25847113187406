import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '400px',
    cursor: 'pointer',
  },
  list: {},
  item: {
    border: `1px solid ${theme.palette.common.gray}`,
    color: theme.palette.common.gray,
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '24px',
    '&:first-of-type': {
      borderRadius: '50px 0px 0px 50px',
    },
    '&:last-of-type': {
      borderRadius: '0px 50px 50px 0px',
    },
    flex: '1',
    textAlign: 'center',
    padding: '5.5px 0',
    textTransform: 'capitalize',
  },
  active: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
  },
}));
