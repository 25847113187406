import { Box } from '@mui/material';
import React from 'react';

import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../../../../common/constants/events/quickstartEvents';
import { CustomColorPickerWithInput } from '../../../../../common/components/CustomColorPicker/CustomColorPickerWithInput';
import { SocialVerseStrings } from '../../../../../common/localization/en';

interface BrandColorSectionProps {
  color: string;
  onColorChange?: (color: string) => void;
  setColorError: (value: boolean) => void;
}

export const BrandColorSection: React.FC<BrandColorSectionProps> = ({
  color,
  onColorChange,
  setColorError,
}) => {
  const { trackEvent } = useTrackEvent();
  return (
    <Box display="flex" alignItems="center">
      <Box width="460px">
        <CustomColorPickerWithInput
          color={color}
          onColorChange={(newColor) => {
            onColorChange?.(newColor);
            trackEvent(QuickStartEventNames.color_palette_click, { value: newColor });
          }}
          displayCustomInput
          title={SocialVerseStrings.ColorPalette}
          setColorError={setColorError}
        />
      </Box>
      {/* <Typography ml="10px">This will change the buttons, text & QR colors</Typography> */}
    </Box>
  );
};
