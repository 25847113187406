import { SharesIconNew } from '../../assets/searchFilterIcons/SharesIconNew';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { PointerIconDv5 } from './PointerIconDv5';
import { ViewIconDv5 } from './ViewIconDv5';

export interface IVideoData {
  icon: React.ReactNode;
  label: string;
  value: number | string;
}

export const createVideoData = (views: number, shares: number, ctaClicks: number): IVideoData[] => {
  return [
    {
      icon: <ViewIconDv5 />,
      label: VideoDetailsModalStringsDv5.Views,
      value: views,
    },
    {
      icon: <SharesIconNew />,
      label: VideoDetailsModalStringsDv5.Shares,
      value: shares,
    },
    {
      icon: <PointerIconDv5 />,
      label: VideoDetailsModalStringsDv5.CTA,
      value: ctaClicks,
    },
  ];
};
