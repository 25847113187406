import { FC } from 'react';
import { theme } from '../../../theme';

export const BeaconIcon: FC = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_6269_105425"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="70"
    >
      <circle cx="35" cy="35" r="35" fill="#EEECFC" />
    </mask>
    <g mask="url(#mask0_6269_105425)">
      <mask
        id="mask1_6269_105425"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="70"
        height="70"
      >
        <circle cx="35" cy="35" r="35" fill="#EEECFD" />
      </mask>
      <g mask="url(#mask1_6269_105425)">
        <circle cx="35" cy="35" r="35" fill="#EEECFD" />
        <path
          d="M42.5 -2.17871L42.5 72.1798"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.5 -2.17993L57.5 72.1786"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.5 -2.1792L27.5 72.1793"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 -2.17871L12.5 72.1798"
          stroke="#D9D4FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="19.6387"
          y="25.2773"
          width="30.7228"
          height="30.7228"
          rx="5.36443"
          fill="white"
          stroke={theme.palette.primary.main}
        />
        <path
          d="M41.1295 33.5013L26.7488 39.5047C26.1304 39.7629 26.1902 40.6579 26.8375 40.8314L33.045 42.4961L34.7927 48.8103C34.9713 49.4556 35.8658 49.5088 36.1197 48.8892L42.0501 34.4158C42.2873 33.8371 41.7067 33.2603 41.1295 33.5013Z"
          fill="#D9D4FF"
          stroke="#1A1538"
          strokeLinejoin="round"
        />
        <path
          d="M41.1164 20.1328C39.551 18.5674 37.3884 17.5991 34.9996 17.5991C32.6108 17.5991 30.4481 18.5674 28.8827 20.1328"
          stroke="#1A1538"
          strokeLinecap="round"
        />
        <path
          d="M24.3589 15.6078C27.0823 12.8844 30.8445 11.2 35.0003 11.2C39.156 11.2 42.9183 12.8844 45.6416 15.6078"
          stroke="#1A1538"
          strokeLinecap="round"
        />
      </g>
    </g>
  </svg>
);
