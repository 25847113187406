import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './RewardSelect.styles';
import { Box, Button, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { ExpandIcon } from '../../assets/ExpandIcon';
import { ClipsSearchStrings, CreatorsSearchStrings } from '../../localization/en';
import { CreatorAttributeName, SearchOperationType } from '../../../api/models/searchFilterModels';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { removeCreatorRewardedFilter } from '../../../store/slices/searchFilterSlice';

export interface RewardRange {
  value: number;
  operation: SearchOperationType.LessThan | SearchOperationType.GreaterThan;
}

interface SelectItem {
  text: string;
  range: RewardRange[];
}

interface Props {
  onChange: (range: RewardRange[]) => void;
}

export const RewardSelect: FC<Props> = ({ onChange }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const theme = useTheme();

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const { activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const selectItems: SelectItem[] = [
    {
      text: ClipsSearchStrings.Select,
      range: [],
    },
    {
      text: CreatorsSearchStrings.Yes,
      range: [
        {
          value: 0,
          operation: SearchOperationType.GreaterThan,
        },
      ],
    },
    {
      text: CreatorsSearchStrings.No,
      range: [
        {
          value: 1,
          operation: SearchOperationType.LessThan,
        },
      ],
    },
  ];

  useEffect(() => {
    const appliedAttribute = activeCreatorFilterAttributes.find(
      (attribute) => attribute.name === CreatorAttributeName.RewardsFulfilled,
    );
    if (
      appliedAttribute?.operation === SearchOperationType.GreaterThan &&
      appliedAttribute?.value === 0
    ) {
      const index = 1;
      setSelectedItemIndex(index);
      onChange(selectItems[index].range);
    }
    if (
      appliedAttribute?.operation === SearchOperationType.LessThan &&
      appliedAttribute?.value === 1
    ) {
      const index = 2;
      setSelectedItemIndex(index);
      onChange(selectItems[index].range);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCreatorFilterAttributes, onChange]);

  return (
    <Box className={classes.container}>
      {selectedItemIndex > 0 && (
        <Button
          className={classes.clearButton}
          onClick={() => {
            setSelectedItemIndex(0);
            onChange([]);
            dispatch(removeCreatorRewardedFilter());
          }}
        >
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        displayEmpty={true}
        renderValue={() => {
          return (
            <Typography
              color={[0, 3].includes(selectedItemIndex) ? '#667085' : theme.palette.primary.main}
              className={classes.text}
            >
              {selectItems[selectedItemIndex].text}
            </Typography>
          );
        }}
        className={classes.select}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
            },
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        {selectItems.map((item, index) => {
          return (
            index > 0 && (
              <MenuItem
                onClick={() => {
                  setSelectedItemIndex(index);
                  onChange(item.range);
                }}
                className={classes.menuItem}
                key={item.text}
              >
                {item.text}
              </MenuItem>
            )
          );
        })}
      </Select>
    </Box>
  );
};
