import React, { FC, SetStateAction, useState } from 'react';
import { Button, Grid, Theme, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Color } from 'material-ui-color';

import { MediaEndpoints } from '../../../../../api/endpoints';
import {
  ColorPickerInputLabels,
  VenueCreationWizardStrings,
} from '../../../../../common/localization/en';
import { UploadUrlApiModel } from '../../../../../services/utilities';
import { uploadLogoAsFile } from '../../../../../pages/webappSettings/General/Settings.helper';
import { httpClient } from '../../../../../services/httpClient/httpClient';
import ColorPickerComponent from '../../../../../common/components/colorpicker/ColorPicker';
import { FiltersStep } from './components/FiltersStep/FiltersStep';
import { FilterProps } from '../../../../venuewizard/WizzardStepperHelper';
import { useAppDispatch } from '../../../../../store';
import { generateFilters } from '../../../../../store/slices/venueCreationSlice';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '133.4%',
  },
  fileinputWrapper: {
    width: '100%',
    maxWidth: '200px',
    height: '240px',
    borderRadius: '8px',
    backgroundImage: 'url(/inputBackground.png)',
    backgroundColor: theme.palette.common.slightlyBlueish,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
  },
  fileInput: {
    width: '120%',
    maxWidth: '200px',
    height: '200px',
    borderRadius: '8px',
    opacity: 0,
    cursor: 'pointer',
  },
  uploadButtonWrapper: {
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
  },
  fileInputbutton: {
    position: 'absolute',
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
    opacity: 0,
  },
  uploadText: {
    color: theme.palette.primary.main,
  },
  loaderWrapper: {
    height: '240px',
    width: '100%',
    position: 'relative',
  },
}));
interface Step3Props {
  filterPrimaryColor: Color;
  setFilterPrimaryColor: (value: SetStateAction<Color>) => void;
  filterSecondaryColor: Color;
  setFilterSecondaryColor: (value: SetStateAction<Color>) => void;
  venuLogo: string;
  filterLogo: string | undefined;
  setFilterLogo: (value: SetStateAction<string | undefined>) => void;
  setVenuLogo: (value: SetStateAction<string>) => void;
  filters: FilterProps[];
  setFilters: (value: SetStateAction<FilterProps[]>) => void;
}
export const Step3: FC<Step3Props> = ({
  venuLogo,
  filterLogo = venuLogo,
  setFilterLogo,
  filterPrimaryColor,
  setFilterPrimaryColor,
  filterSecondaryColor,
  setFilterSecondaryColor,
  filters,
  setFilters,
}) => {
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const noFilters = [
    { url: '', isActive: false },
    { url: '', isActive: false },
    { url: '', isActive: false },
  ];

  const createSignedUploadUrl = async (mimeType?: string) => {
    try {
      return httpClient.post<undefined, UploadUrlApiModel>({
        url: `${MediaEndpoints.CreateUploadUrl}?ext=${mimeType}`,
        requiresToken: true,
      });
    } catch (error) {
      return console.log(error);
    }
  };

  const handleGenerateFilters = () => {
    dispatch(
      generateFilters({
        logoUrl: filterLogo,
        secondaryColor: `#${filterPrimaryColor.hex}`,
        primaryColor: `#${filterSecondaryColor.hex}`,
      }),
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onChangeLogoHandler(file);
  };

  const onChangeLogoHandler = (logoFile: File) => {
    if (logoFile && logoFile.name) {
      createSignedUploadUrl(logoFile.name.split('.').pop())
        .then(async (res) => {
          setIsLogoUploading(true);
          if (res) {
            try {
              await uploadLogoAsFile({ options: res, data: logoFile });
              setFilterLogo(res.downloadUrl);
              setIsLogoUploading(false);
            } catch (err) {
              setIsLogoUploading(false);
            }
          } else {
            setIsLogoUploading(false);
          }
        })
        .catch((err) => {
          setIsLogoUploading(false);
        });
    }
  };

  return (
    <Grid container justifyContent="flex-end" alignItems="flex-start" spacing={3}>
      <Grid item xs={5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <p className={classes.header}>{VenueCreationWizardStrings.FiltersBrandingStep}</p>
          </Grid>
          <Grid item xs={12} justifyContent="flex-start" alignItems="flex-start" spacing={3}>
            <div
              className={classes.fileinputWrapper}
              style={filterLogo ? { backgroundImage: `url(${filterLogo})` } : {}}
            >
              {isLogoUploading && (
                <div className={classes.loaderWrapper}>
                  <CircularProgress
                    style={{
                      width: 30,
                      height: 30,
                      position: 'absolute',
                      top: 'calc(50% - 15px)',
                      left: 'calc(50% - 15px)',
                    }}
                    color="primary"
                  />
                </div>
              )}
              <input
                className={classes.fileInput}
                id="contained-button-file"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Button className={classes.uploadButtonWrapper}>
              <input
                className={classes.fileInputbutton}
                id="contained-button-file-button"
                type="file"
                accept="image/png, image/gif, image/jpeg"
                onChange={handleFileChange}
              />
              <p className={classes.uploadText}>{VenueCreationWizardStrings.UploadLogo}</p>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ColorPickerComponent
              color={filterPrimaryColor}
              setColor={setFilterPrimaryColor}
              label={ColorPickerInputLabels.PrimaryColor}
            />
            <ColorPickerComponent
              color={filterSecondaryColor}
              setColor={setFilterSecondaryColor}
              label={ColorPickerInputLabels.SecondaryColor}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              disabled={!filterLogo || !filterPrimaryColor || !filterSecondaryColor}
              onClick={handleGenerateFilters}
            >
              {VenueCreationWizardStrings.CreateNewFilter}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={11}>
            <p className={classes.header}>{VenueCreationWizardStrings.AvailableFilters}</p>
          </Grid>
          <Grid item xs={11}>
            <FiltersStep
              filterStepState={filters.length ? filters : noFilters}
              setFilterStepState={setFilters}
              hideLogoAndColors={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
