export enum AllAccountsEventNames {
  affiliateAccountsButtonClicked = 'affiliateAccountsButtonClicked',
  distributorAccountsButtonClicked = 'distributorAccountsButtonClicked',
  archivedAccountsButtonClicked = 'archivedAccountsButtonClicked',
  allAccountsButtonClicked = 'allAccountsButtonClicked',
  tableVenueSelected = 'tableVenueSelected',
  venuesTableSizeChanged = 'venuesTableSizeChanged',
  venuesTableSortClicked = 'venuesTableSortClicked',
  venuesTablePageChanged = 'venuesTablePageChanged',
  createNewAccountClicked = 'createNewAccountClicked',
}
