import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  isUwmTable: boolean;
}

// @ts-ignore
export const useVenueTablesStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  AccountsTable: ({ isUwmTable }) => ({
    '& th': {
      height: '58px',
      '&:nth-child(1)': {
        paddingLeft: '24px !important',
        '& > div': {
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
          paddingBottom: '0px',
        },
        '& > div > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: isUwmTable ? 'start' : 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: isUwmTable ? '0' : '5px',
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '14px',
        color: theme.palette.common.gray,
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '14px',
        color: theme.palette.common.gray,
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
        },
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: isUwmTable ? '0' : '5px',
        fontSize: '11px',
        fontWeight: '700',
        lineHeight: '14px',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        '& > div': {
          '& > div': {
            justifyContent: 'center',
          },
        },
      },
      '&:nth-child(5) div': {
        display: 'block',
        margin: '0 auto',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(6)': {
        textAlign: 'center',
      },
      '&:nth-child(6) div': {
        display: 'block',
        margin: '0 auto',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(10) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(11) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(12) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',
        width: '120px',
        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
      '&:nth-child(13) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        textAlign: 'center',
        paddingBottom: '5px',

        '& > div': {
          display: 'flex',
          fontSize: '11px',
          fontWeight: '700',
          lineHeight: '14px',
          color: theme.palette.common.gray,
        },
      },
    },

    '& td': {
      minHeight: '48px',
      '&:nth-child(1)': {
        paddingLeft: '24px !important',
      },
      '&:nth-child(2)': {
        textAlign: isUwmTable ? 'start !important' : 'center !important',
        paddingLeft: isUwmTable ? '10px' : 0,
      },
      '&:nth-child(2)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '12px',
      },
      '&:nth-child(3)': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '12px',
      },
      '&:nth-child(4)': {
        paddingLeft: '0',
      },
      '&:nth-child(4)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '12px',
      },
      '&:nth-child(5)': {
        paddingLeft: 0,
        textAlign: 'center',
      },
      '&:nth-child(5)>p': {
        textAlign: 'center',
        paddingLeft: 0,
        fontSize: '12px',
      },

      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(8)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(9)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(10)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(11)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(12)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
      '&:nth-child(13)': {
        textAlign: 'center',
        paddingLeft: '0px',
      },
    },

    '& #common-table-wrapper': {
      height: 'calc(100vh - 250px) !important',
      maxHeight: 'calc(100vh - 250px) !important',
    },
    '@media not all and (min-resolution: 0.001dpcm)': {
      '& #common-table-wrapper': {
        height: 'calc(100vh - 300px) !important',
        maxHeight: 'calc(100vh - 300px) !important',
      },
    },
  }),
}));
