import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  sectionTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontsize: '20px',
    lineHeight: '160%',
    color: theme.palette.text.primary,
  },
  textLabel: {
    paddingBottom: 8,
    fontSize: '16px',
    color: theme.palette.text.secondary,
  },
  textValue: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    overflowWrap: 'break-word',
  },
  userBox: {
    boxShadow: ' 0px 10px 40px -6px rgba(10, 25, 107, 0.15)',
    borderRadius: '8px',
    minHeight: '100px',
    padding: '10px',
  },
  logo: {
    width: 100,
    height: 100,
    border: '1px solid #EEEEF6',
    boxSizing: 'border-box',
    borderRadius: 10,
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '157%',
    color: theme.palette.text.primary,
  },
}));
