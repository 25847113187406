import { useMemo } from 'react';
import { useTypedSelector } from '../../../../../../store';

export const useSendInvitesTab = () => {
  const {
    lastSendBatchStats,
    campaignUnsentInvitations: { totalItems: unsentInvitesCount },
  } = useTypedSelector((state) => state.campaigns);

  const calculatePreparedInvites = () => {
    return unsentInvitesCount <= 300 ? unsentInvitesCount : 300;
  };

  const lastSendDate = useMemo(() => {
    // return new Date('2024-07-01');
    if (!lastSendBatchStats || !lastSendBatchStats.sentAt) return null;
    return new Date(lastSendBatchStats.sentAt);
  }, [lastSendBatchStats]);

  return {
    preparedInvites: calculatePreparedInvites(),
    availableCustomers: unsentInvitesCount,
    lastSendDate,
  };
};
