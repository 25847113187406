export const BellIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ scale: '2', marginRight: '10px', marginTop: '-30px' }}
    >
      <path
        d="M15.1459 17.0153C15.5747 18.6157 14.625 20.2607 13.0246 20.6895C11.4242 21.1183 9.77918 20.1686 9.35036 18.5682M1.55683 10.6138C1.16734 9.21562 1.57238 7.70398 2.60878 6.68786M11.6463 4.74134C11.9505 4.19404 12.0507 3.53254 11.8759 2.87994C11.5185 1.54627 10.1477 0.754819 8.814 1.11217C7.48034 1.46953 6.68888 2.84037 7.04624 4.17404C7.2211 4.82663 7.63863 5.3494 8.17573 5.67128M19.819 5.72049C19.4572 4.31488 18.3506 3.20828 16.945 2.8465M16.9906 8.44577C16.6337 7.11364 15.6808 5.99967 14.3415 5.34894C13.0022 4.69821 11.3863 4.56401 9.84922 4.97587C8.31214 5.38773 6.97981 6.31191 6.14533 7.54509C5.31085 8.77828 5.04258 10.2195 5.39952 11.5516C5.9901 13.7557 5.87542 15.514 5.50247 16.8502C5.0774 18.373 4.86487 19.1345 4.92228 19.2873C4.98798 19.4623 5.03549 19.5103 5.20973 19.5778C5.36202 19.6368 6.00179 19.4654 7.28133 19.1225L19.1464 15.9433C20.4259 15.6004 21.0657 15.429 21.1681 15.3018C21.2852 15.1562 21.3024 15.0908 21.2718 14.9065C21.2451 14.7454 20.6803 14.1922 19.5508 13.0859C18.5597 12.1153 17.5812 10.6498 16.9906 8.44577Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
