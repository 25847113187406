import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  isIncentiveCampaign?: boolean;
  flex?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: ({ isIncentiveCampaign, flex }) => ({
    zIndex: 3,
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: flex ? 'flex' : 'block',
    alignItems: 'center',
    padding: flex ? '4px !important' : '14px 8px !important',
    width: isIncentiveCampaign || flex ? 'fit-content' : '256px',
    height: flex ? 'auto' : '690px',
    margin: '30px 10px 16px 14px',
  }),
  tab: ({ flex }) => ({
    padding: '0 12px',
    cursor: 'pointer',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(!flex && {
      '&:not(:first-child)': {
        marginTop: '18px',
      },
    }),
    width: '100%',
  }),
  tabName: {
    fontsize: '15px',
    fontWeight: '500',
    userSelect: 'none',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  amount: {
    marginLeft: '.5em',
    height: '22px',
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    width: 'fit-content',
    fontWeight: '500',
  },
}));
