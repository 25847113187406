import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ImportTable: {
    '& table': {
      borderSpacing: '0 !important',
    },
    '& tr': {
      height: '45px',
      lineHeight: '1',
    },
    '& th': {
      fontSize: '12px',
      backgroundColor: theme.palette.common.white,
      '&:nth-child(1)': {
        paddingLeft: '15px !important',
        width: 'auto !important',
      },
      '&:nth-child(2)': {
        paddingLeft: '0 !important',
        width: '17% !important',
      },
      '&:nth-child(2) div': {
        paddingLeft: '0 !important',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
      '&:nth-child(3)': {
        paddingLeft: '0 !important',
        width: '17% !important',
      },
      '&:nth-child(3) div': {
        paddingLeft: '0 !important',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
      '&:nth-child(4)': {
        paddingLeft: '0 !important',
        width: '17% !important',
      },
      '&:nth-child(4) div': {
        paddingLeft: '0 !important',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        width: '17% !important',
      },
      '&:nth-child(5) div': {
        display: 'block',
        margin: '0 auto',
      },
    },
    '& td': {
      fontSize: '12px',
      '&:nth-child(1)': {
        paddingLeft: '35px !important',
      },
      '&:nth-child(2)': {
        paddingLeft: 0,
      },
      '&:nth-child(3)': {
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        paddingLeft: '35px !important',
        paddingRight: '35px !important',
        textAlign: 'center',
      },
    },
  },

  root: {
    '& thead': {
      '& tr th:first-child > div': {
        paddingLeft: '10px !important',
        justifyContent: 'flex-start',
      },
      '& tr th:not(first-child) > div': {
        justifyContent: 'center',
      },
    },
  },
  modal: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    fontSize: '28px',
    lineHeight: '42px',
    marginBottom: '14px',
  },
  subtitle: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
    color: theme.palette.common.gray,
    marginTop: '8px',
  },
  btnSubmit: {
    marginBottom: '12px',
  },
  deliveryTitle: {
    lineHeight: '26px',
    marginBottom: '15px',
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '420px',
    marginBottom: '25px',
  },
  selectDescr: {
    color: '#1A1538',
    opacity: '0.5',
  },
  select: {
    width: '240px',
    height: '44px',
    background: theme.palette.common.white,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
    '& svg': {
      marginRight: '10px',
      width: '20px',
    },
    '& .MuiSelect-select': {
      color: '#1A1538',
      fontSize: '16px',
      fontWeight: '500',
    },
  },
  menuWrapper: {
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
  },
  selectItem: {
    width: '235px',
    background: 'white !important',
    padding: '10px 30px',
    color: theme.palette.common.gray,
    '&:hover': {
      background: '#F5F6F8 !important',
    },
  },
}));
