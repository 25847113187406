export const RewardedIcon = () => {
  return (
    <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0282 5.06697C10.4646 4.01324 11.6924 3.53719 12.7251 4.02135L13.312 4.29654C13.8211 4.53522 14.4071 4.5486 14.9265 4.3334L15.5254 4.08527C16.5791 3.64873 17.7839 4.18031 18.1718 5.25286L18.3922 5.86249C18.5834 6.39123 18.9883 6.81507 19.5078 7.03019L20.1067 7.27822C21.1605 7.7146 21.6365 8.94241 21.1523 9.97506L20.8772 10.562C20.6385 11.0711 20.6251 11.6571 20.8403 12.1765L21.0884 12.7754C21.525 13.8291 20.9934 15.0339 19.9208 15.4218L19.3112 15.6422C18.7825 15.8334 18.3586 16.2383 18.1435 16.7578L17.8955 17.3567C17.4591 18.4105 16.2313 18.8865 15.1986 18.4023L14.6117 18.1272C14.1026 17.8885 13.5166 17.8751 12.9972 18.0903L12.3983 18.3384C11.3446 18.775 10.1398 18.2434 9.75194 17.1708L9.53149 16.5612C9.3403 16.0325 8.93537 15.6086 8.41591 15.3935L7.81697 15.1455C6.76324 14.7091 6.28719 13.4813 6.77135 12.4486L7.04654 11.8617C7.28522 11.3526 7.2986 10.7666 7.0834 10.2472L6.83527 9.64826C6.39873 8.5946 6.93031 7.38978 8.00286 7.00194L8.61249 6.78149C9.14123 6.5903 9.56507 6.18537 9.78019 5.66591L10.0282 5.06697Z"
        stroke="#8B89A0"
        strokeWidth="1.3"
      />
      <path
        d="M7.83594 15.7354L4.82879 20.9439L8.14186 20.7015L9.54347 23.6659L12.5506 18.4574"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8164 15.7354L22.8236 20.9439L19.5105 20.7015L18.1089 23.6659L15.1017 18.4574"
        stroke="#8B89A0"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="14" cy="11.25" r="2.725" stroke="#8B89A0" strokeWidth="1.3" />
    </svg>
  );
};
