import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#000',
    fontSize: '24px',
    fontWeight: 500,
    letterSpacing: '0.48px',
  },
  subtitle: {
    fontSize: '16px',
    letterSpacing: '0.32px',
    lineHeight: '160%',
    color: '#8B89A0',
    width: '380px',
    marginTop: '8px',
  },
  undoneText: {
    fontWeight: 600,
    color: '#8B89A0',
  },
  buttonsWrapper: {
    marginTop: '32px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  goBackBtn: {},
  cancelInvitesBtn: {
    width: '320px',
  },
}));
