import { FC } from 'react';

export const TemplateIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#FFEABC" rx="8"></rect>
      <path
        fill="#FFB515"
        d="M27 13v14H13V13h14zm1.1-2H11.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V11.9c0-.5-.5-.9-.9-.9zM19 15h6v2h-6v-2zm0 4h6v2h-6v-2zm0 4h6v2h-6v-2zm-4-8h2v2h-2v-2zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z"
      ></path>
    </svg>
  );
};
