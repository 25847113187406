import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../constants/events/common';
import { CampaignInvitesStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { useStyles } from './ConfirmPrimaryModal.styles';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm?: () => void;
}

export const ConfirmPrimaryModal: React.FC<Props> = ({ isOpen, onClose, onCancel, onConfirm }) => {
  const classes = useStyles();

  const { trackEvent } = useTrackEvent();

  return (
    <Modal
      open={isOpen}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onClose={() => {
        trackEvent(CommonEventNames.confirm_primary_modal_close);
        onCancel();
        onClose();
      }}
    >
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {CampaignInvitesStrings.ConfirmPrimaryTitle}
        </Typography>
        <Typography className={classes.description}>
          {CampaignInvitesStrings.ConfirmPrimaryDescr}
        </Typography>
        <Box display="flex" justifyContent="center" gap="10px">
          <RoundedButton
            title={CampaignInvitesStrings.ConfirmPrimaryBtnCancel}
            onClick={() => {
              onCancel();
              onClose();
              trackEvent(CommonEventNames.confirm_primary_modal_cancel_button_click);
            }}
            outlined
            className={classes.btn}
            fontSize="16px"
            fontWeight={600}
            textColor="#000"
          />
          <RoundedButton
            title={CampaignInvitesStrings.ConfirmPrimaryBtnConfirm}
            onClick={() => {
              trackEvent(CommonEventNames.confirm_primary_modal_confirm_button_click);
              onConfirm?.();
              onClose();
            }}
            className={classes.btn}
            fontSize="16px"
            fontWeight={600}
          />
        </Box>
      </Box>
    </Modal>
  );
};
