import { FC } from 'react';

export const UploadDndIcon: FC = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.83337 18.6667L14.5 14M14.5 14L19.1667 18.6667M14.5 14V24.5M23.8334 19.5333C25.2585 18.3564 26.1667 16.5759 26.1667 14.5833C26.1667 11.0395 23.2939 8.16667 19.75 8.16667C19.4951 8.16667 19.2566 8.03366 19.1272 7.81403C17.6058 5.23231 14.7969 3.5 11.5834 3.5C6.75088 3.5 2.83337 7.41751 2.83337 12.25C2.83337 14.6605 3.80806 16.8433 5.38481 18.4258"
        stroke="#475467"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
