import { Box, Checkbox, ListItemText, MenuItem, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../ClipsSearchTags.styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { DotIcon } from '../../../assets/DotIcon';
import { SmallStarIcon } from '../../../assets/SmallStarIcon';
import {
  removeVideosCampaignFilter,
  setActiveVideoFilterAttributes,
  updateVideosCampaignFilter,
} from '../../../../store/slices/searchFilterSlice';
import { SearchOperationType, VideoAttributeName } from '../../../../api/models/searchFilterModels';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import { SearchTagsStrings } from '../../../localization/en';
import MenuContainer from '../../MenuContainer/MenuContainer';

interface CampaignItem {
  name: string;
  id: string;
  selected: boolean;
  isActive: boolean;
  isPrimary?: boolean;
}

const CampaignTag = () => {
  const styles = useStyles();
  const theme = useTheme();

  const dispatch = useAppDispatch();

  const [campaignItems, setCampaignItems] = useState<CampaignItem[]>([]);
  const { items } = useTypedSelector((state) => state.campaigns);
  const { activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const [campaignAnchorEl, setCampaignAnchorEl] = useState<null | HTMLElement>(null);
  const isCampaignMenuOpen = Boolean(campaignAnchorEl);

  const handleCampaignMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setCampaignAnchorEl(event.currentTarget);
  };

  const handleCampaignMenuClose = () => {
    setCampaignAnchorEl(null);
  };

  const campaignFilterIdx = activeVideoFilterAttributes.findIndex(
    (item) => item.name === VideoAttributeName.CampaignId,
  );
  const campaignsInFilter =
    campaignFilterIdx !== -1
      ? (activeVideoFilterAttributes[campaignFilterIdx].value as string[])
      : [];

  useEffect(() => {
    setCampaignItems(
      items.map<CampaignItem>((item) => ({
        name: item.name,
        id: item.id,
        selected: campaignsInFilter.includes(item.id),
        isActive: !item.endedAt,
        isPrimary: item.isPreferred,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, activeVideoFilterAttributes]);

  const setActiveCampaign = (item: CampaignItem) => {
    setCampaignItems((prev) => {
      return prev.map((prevItem) => {
        return prevItem.id === item.id ? { ...prevItem, selected: !prevItem.selected } : prevItem;
      });
    });

    const selectedCampaignsIds = campaignItems
      .filter((campaign) => campaign.selected)
      .map((campaign) => campaign.id);

    if (campaignFilterIdx === -1) {
      dispatch(
        setActiveVideoFilterAttributes([
          ...activeVideoFilterAttributes,
          {
            name: VideoAttributeName.CampaignId,
            operation: SearchOperationType.In,
            value: [item.id],
          },
        ]),
      );
      return;
    }

    if (item.selected && selectedCampaignsIds.length === 1) {
      dispatch(removeVideosCampaignFilter());
      return;
    }

    if (item.selected) {
      dispatch(
        updateVideosCampaignFilter(
          selectedCampaignsIds.filter((campaignId) => campaignId !== item.id),
        ),
      );
      return;
    }

    dispatch(updateVideosCampaignFilter([...selectedCampaignsIds, item.id]));
  };

  const removeCampaignFilter = () => {
    setCampaignItems((prev) => {
      return prev.map((prevItem) => {
        return prevItem.selected ? { ...prevItem, selected: !prevItem.selected } : prevItem;
      });
    });

    dispatch(removeVideosCampaignFilter());
  };

  return (
    <>
      <Box
        className={`${styles.tag} ${campaignFilterIdx !== -1 && styles.active}`}
        onClick={handleCampaignMenuOpen}
      >
        {SearchTagsStrings.Campaign}:{' '}
        {campaignFilterIdx === -1 ? (
          ''
        ) : campaignsInFilter.length > 1 ? (
          <Box
            sx={{
              color: '#FFF',
              backgroundColor: `${theme.palette.primary.main}B2`,
              display: 'grid',
              placeItems: 'center',
              width: '16px',
              height: '16px',
              borderRadius: '50%',
              marginLeft: '4px',
              fontWeight: 700,
            }}
          >
            {campaignsInFilter.length}
          </Box>
        ) : (
          campaignItems.find((campaign) => campaign.id === campaignsInFilter[0])?.name
        )}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (campaignFilterIdx !== -1) {
              e.stopPropagation();
              removeCampaignFilter();
            }
          }}
        >
          {campaignFilterIdx === -1 ? <SmallArrowIcon /> : <PurpleCloseIcon />}
        </Box>
      </Box>
      <MenuContainer
        isOpen={isCampaignMenuOpen}
        anchorElement={campaignAnchorEl}
        onClose={handleCampaignMenuClose}
        padding="10px 0"
        maxHeight="220px"
      >
        {campaignItems.map((item) => (
          <MenuItem
            key={item.id}
            value={item.id}
            onClick={() => setActiveCampaign(item)}
            sx={{
              height: '28px',
              padding: '6px 16px 6px 0',

              '& .MuiListItemText-root': {
                flex: 'unset !important',
                marginRight: '10px',
              },
            }}
          >
            <Checkbox checked={item.selected} />

            <DotIcon color={item.isActive ? '#12B76A' : '#D31F1F'} />
            <ListItemText
              primary={item.name}
              sx={{
                marginLeft: '10px',

                '& span': {
                  fontSize: '11px',
                },
              }}
            />
            {item.isPrimary && <SmallStarIcon />}
          </MenuItem>
        ))}
      </MenuContainer>
    </>
  );
};

export default CampaignTag;
