import { makeStyles } from '@mui/styles';
import { WebsiteRoutes } from '../../common/constants/routes';
import { WorkspaceStrings } from '../../common/localization/en';

export interface SubnavigationItemValues {
  route: WebsiteRoutes;
  text: string;
  disabled?: boolean;
}

export const subnavigationItems: SubnavigationItemValues[] = [
  {
    route: WebsiteRoutes.AccountsCompany,
    text: WorkspaceStrings.CompanyProfile,
  },
  {
    route: WebsiteRoutes.AccountsOverview,
    text: WorkspaceStrings.Billing,
  },
  {
    route: WebsiteRoutes.AccountsSmsTracking,
    text: WorkspaceStrings.SMSTracking,
  },
  {
    route: WebsiteRoutes.AccountsMembers,
    text: WorkspaceStrings.Members,
  },
  {
    route: WebsiteRoutes.AccountsProfile,
    text: WorkspaceStrings.Profile,
  },
];

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '16px 24px',
    display: 'grid',
  },
}));
