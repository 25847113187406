import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './TableFilterSelect.styles';
import { RewardCampaignsEvents } from '../../../../common/constants/events/rewardCampaignsEvents';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { InvitesLayoutStrings } from '../../../../common/localization/en';
import { Tooltip } from '../../../../common/components/Tooltip/Tooltip';
import { TooltipInfoIcon } from '../../../../common/assets/TooltipInfoIcon';
import { InviteeTableTab } from '../InviteesTable';

export interface TabItem {
  tabIndex: InviteeTableTab;
  tabName: string;
  usersCount?: string | number;
  filter?: { status?: string; hasVideos?: boolean };
  disabled?: boolean;
}

interface Props {
  tabsList: TabItem[];
  activeTabIndex: number;
  setActiveTabIndex: (value: number) => void;
  type: 'active' | 'invited';
  totalCount?: string;
}

const TableFilterSelect: FC<Props> = ({
  tabsList,
  activeTabIndex,
  setActiveTabIndex,
  type,
  totalCount,
}) => {
  const classes = useStyles();

  const { trackEvent } = useTrackEvent();

  const replaceEventNameByTableType = RewardCampaignsEvents.RewardCampaginActiveCustomersTableViewAllTabClick.replace(
    'tab',
    `${type}_tab`,
  );
  const theme = useTheme();

  return (
    <Box className={classes.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = activeTabIndex === index;
        return (
          <button
            className={classes.tab}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              cursor: isActiveTab || tabItem.disabled ? 'auto' : 'pointer',
              border: tabItem.disabled ? 'none' : undefined,
            }}
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              trackEvent(
                replaceEventNameByTableType
                  .replace('subtab', `${tabItem.tabName}_tab`)
                  .toLowerCase(),
              );
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter`}
            disabled={tabItem.disabled}
          >
            <Typography
              className={classes.tabName}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography
              className={classes.amount}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
                background: tabItem.disabled ? 'inherit' : undefined,
              }}
            >
              {tabItem.usersCount}
            </Typography>
          </button>
        );
      })}
      <Box className={classes.total}>
        <Box className={classes.totalLabel}>Total</Box>
        <Box className={classes.totalNumber}>{totalCount}</Box>
      </Box>
      <Box className={classes.aboutWrapper}>
        <Typography className={classes.aboutText}>{InvitesLayoutStrings.AboutCounts}</Typography>
        <Tooltip
          maxWidth="425px"
          content={
            <Box className={classes.tooltip}>
              <Typography className={classes.tooltipTextTitle} fontWeight="600">
                {InvitesLayoutStrings.DeliveredColumn}{' '}
                <Typography className={classes.tooltipText} display="inline">
                  {InvitesLayoutStrings.CarrierConfirmed}
                </Typography>
              </Typography>
              <Typography className={classes.tooltipTextTitle} fontWeight="600" mt="4px">
                {InvitesLayoutStrings.UnDeliveredColumn}{' '}
                <Typography className={classes.tooltipText} display="inline">
                  {InvitesLayoutStrings.InvalidCellNumber}
                </Typography>
              </Typography>
            </Box>
          }
          icon={
            <Box className={classes.tooltipIcon}>
              <TooltipInfoIcon />
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

export default TableFilterSelect;
