import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  progressWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '300px',
  },
  noUsersWrapper: {
    marginTop: '180px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleWrapper: {
    width: '960px',
    minHeight: '114px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '8px',
  },
  tracksText: {
    color: theme.palette.primary.main,
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '160%',
  },
  invitedText: {
    color: theme.palette.primary.main,
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '160%',
  },
  encourageText: {
    color: '#475467',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '162%',
    marginTop: '60px',
  },
  invitingText: {
    color: '#475467',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '162%',
    marginTop: '14px',
    width: '706px',
  },
  addButton: {
    borderRadius: '8px',
    marginTop: '24px',
    '& p': {
      fontWeight: 600,
      fontSize: '16px',
    },
  },
}));
