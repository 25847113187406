import { Box } from '@mui/material';
import { ReactNode } from 'react';

import {
  ActivityApiModel,
  ActivitiesTypes,
  RewardsCalculationActivityModel,
} from '../../../../api/models/rewards';

import {
  ContentMapperProps,
  ListRow,
  ListContent,
} from '../../../../common/components/list/CommonList.helper';
import { numberWithCommas } from '../../../../services/utilities';

import { CreateASaleIcon } from '../../../../common/assets/newDesign/Rewards/CreateASaleIcon';
import { CreateStoryIcon } from '../../../../common/assets/newDesign/Rewards/CreateStoryIcon';
import { DriveViewsIcon } from '../../../../common/assets/newDesign/Rewards/DriveViewsIcon';
import { GetFeaturedIcon } from '../../../../common/assets/newDesign/Rewards/GetFeaturedIcon';
import { ReferralIcon } from '../../../../common/assets/newDesign/Rewards/ReferralIcon';
import { ActivitiesFirstDonation } from '../../../../common/assets/newDesign/Rewards/ActivitiesFirstDonation';
import { Donations } from '../../../../common/assets/newDesign/Rewards/Donations';
import { ActivitiesEmails } from '../../../../common/assets/newDesign/Rewards/ActivitiesEmails';
import { ActivitiesGoal } from '../../../../common/assets/newDesign/Rewards/ActivitiesGoal';
import { ActivitiesComputer } from '../../../../common/assets/newDesign/Rewards/ActivitiesComputer';
import { HitVideosCreatedGoalIcon } from '../../../../common/assets/newDesign/Rewards/HitVideosCreatedGoalIcon';
import { HitCalendarEngagementGoal } from '../../../../common/assets/newDesign/Rewards/HitCalendarEngagementGoal';
import { theme } from '../../../../theme';

export interface ActivityDTOModel extends ActivityApiModel {
  icon: ReactNode;
  bgColor: ActivitiesColors;
}
export interface MockActivitiesValues {
  [key: string]: {
    icon: ReactNode;
    bgColor: ActivitiesColors;
  };
}

export enum ActivitiesColors {
  Create = '#6A8DFF',
  Share = '#FFDF99',
  Meetings = '#B7DDAC',
}

export const mockActivities: MockActivitiesValues = {
  [ActivitiesTypes.CreateVideo]: {
    icon: <CreateStoryIcon />,
    bgColor: ActivitiesColors.Create,
  },
  [ActivitiesTypes.CreateVideo]: {
    icon: <CreateStoryIcon />,
    bgColor: ActivitiesColors.Create,
  },
  [ActivitiesTypes.CallToAction]: {
    icon: <CreateStoryIcon />,
    bgColor: ActivitiesColors.Create,
  },
  [ActivitiesTypes.BonusPoints]: {
    icon: <CreateStoryIcon />,
    bgColor: ActivitiesColors.Create,
  },
  [ActivitiesTypes.Donation]: {
    icon: <Donations />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.EnterEnoughEmails]: {
    icon: <ActivitiesEmails />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.FirstDonation]: {
    icon: <ActivitiesFirstDonation />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.HitFundraisingGoal]: {
    icon: <ActivitiesGoal />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.ReferVisitor]: {
    icon: <ReferralIcon />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.SendAutomatedEmails]: {
    icon: <ActivitiesComputer />,
    bgColor: ActivitiesColors.Meetings,
  },
  [ActivitiesTypes.VideoView]: {
    icon: <DriveViewsIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.Featured]: {
    icon: <GetFeaturedIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.ReferPurchaser]: {
    icon: <CreateASaleIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.HitVideosCreatedGoal]: {
    icon: <HitVideosCreatedGoalIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.HitCalendarEngagementGoal]: {
    icon: <HitCalendarEngagementGoal />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.Conversions]: {
    icon: <CreateASaleIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.Engagement]: {
    icon: <ReferralIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideoCreated]: {
    icon: <CreateStoryIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideosViewed]: {
    icon: <DriveViewsIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideosFeatured]: {
    icon: <GetFeaturedIcon />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideoCreationGoals]: {
    icon: <ActivitiesGoal />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideoEngagementGoals]: {
    icon: <Donations />,
    bgColor: ActivitiesColors.Share,
  },
  [ActivitiesTypes.VideoShared]: {
    icon: <ReferralIcon />,
    bgColor: ActivitiesColors.Share,
  },
};

export const getActivitiesListWithIcons = (activities: ActivityApiModel[]): ActivityDTOModel[] => {
  const activitiesListWithIcons = activities.map((activity) => {
    const { icon, bgColor } = mockActivities[activity.type];
    return {
      ...activity,
      icon,
      bgColor,
    };
  });

  return activitiesListWithIcons.sort(function (a, b) {
    return a.displayOrder - b.displayOrder;
  });
};

export const getCalculationActivities = (
  activities: ActivityDTOModel[],
  calculationValues?: RewardsCalculationActivityModel,
): ActivityDTOModel[] => {
  if (!calculationValues) {
    return activities;
  }

  return activities.map((activity) => {
    return {
      ...activity,
      projections: calculationValues[activity.type],
      oneHero: calculationValues[activity.type],
      ptsEarned: calculationValues[activity.type],
    };
  });
};

export interface ContentDataContent extends ListRow {
  id: string;
  name: ReactNode;
  points: ReactNode;
  projections: ReactNode;
}

export const createRewardsActivitiesContent = (
  props: ContentMapperProps<ActivityDTOModel>,
): ListContent<ContentDataContent> => {
  const { items } = props;
  const listContent: ListContent<ContentDataContent> = {
    itemWidth: ['15%', '65%', '20%'],
    header: {
      points: 'Rewards.NewCampaignWizard.Step2ActivitiesColumn2',
      name: 'Rewards.NewCampaignWizard.Step2ActivitiesColumn1',
      projections: 'Rewards.NewCampaignWizard.Step2ActivitiesColumn3',
    },
    rows: [],
  };

  listContent.rows = items.map((item) => {
    return {
      id: item.id,
      points: (
        <Box>
          <Box
            position="absolute"
            top="0"
            left="0"
            width="72px"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="8px 0 0 8px"
            bgcolor="#EAF4FF"
          >
            {numberWithCommas(item.points)}
          </Box>
        </Box>
      ),
      name: (
        <Box display="flex" alignItems="center">
          {item.icon}
          <Box ml="20px">{item.title}</Box>
        </Box>
      ),
      projections: (
        <Box
          display="flex"
          alignItems="center"
          height="52px"
          paddingLeft="16px"
          bgcolor={theme.palette.success.light}
          border={`1px solid ${theme.palette.common.ContentTable.published}`}
          borderRadius="10px"
        >
          {numberWithCommas(item.projections)}
        </Box>
      ),
    };
  });

  return listContent;
};
