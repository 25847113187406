import { Box, Button, useTheme } from '@mui/material';
import React from 'react';
import { CommonEventNames } from '../../../constants/events/common';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';

export interface ImageGallerySelectorProps {
  images: string[];
  activeImage?: string;
  onSelect?: (image: string) => void;

  imagePreviewHeight?: string;
  imagePreviewWidth?: string;

  imageBorderRadius?: string;
  selectedImageBorderRadius?: string;
  selectedImageBorderSize?: string;

  containerProps?: React.ComponentProps<typeof Box>;
}

export const ImageGallerySelector: React.FC<ImageGallerySelectorProps> = ({
  images,
  activeImage,
  onSelect,
  imagePreviewHeight = '120px',
  imagePreviewWidth = '67px',
  imageBorderRadius = '10px',
  selectedImageBorderRadius = '18px',
  selectedImageBorderSize = '6px',
  containerProps,
}) => {
  const theme = useTheme();
  const { trackEvent } = useTrackEvent();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      py="4px"
      bgcolor="#F9F9F9"
      border={`1px solid ${theme.palette.common.imageSelectorBackground}`}
      borderRadius={imageBorderRadius}
      {...containerProps}
    >
      {images.map((image, index) => (
        <Button
          variant="text"
          onClick={() => {
            onSelect?.(image);
            trackEvent(CommonEventNames.background_image_library_selector_click);
          }}
          sx={{
            border: `${selectedImageBorderSize} solid ${
              activeImage === image ? theme.palette.primary.main : 'transparent'
            }`,
            borderRadius: selectedImageBorderRadius,
            p: 0,
          }}
        >
          <Box
            component="img"
            src={image}
            alt={`background-${index + 1}`}
            borderRadius={imageBorderRadius}
            width={imagePreviewWidth}
            height={imagePreviewHeight}
          />
        </Button>
      ))}
    </Box>
  );
};
