import React, { FC, useRef } from 'react';
import { useStyles } from './WelcomeVideoModal.styles';
import { FSModal } from '../../../../../common/components/modal/Modal';
import { CampaignsApiModel } from '../../../../../api/models/campaigns';
import { Box, Divider, Typography } from '@mui/material';
import { WelcomeVideoModalStrings } from '../../../../../common/localization/en';
import { useVideoControls } from '../../../../../common/components/VideoPlayer/PlayerControls.helper';
import EditWelcomeVideo from './EditWelcomeVideo/EditWelcomeVideo';
import { isUWMClient } from '../../../../../utils/isUWMClient';

interface Props {
  currentCampaign: CampaignsApiModel;
  isOpen: boolean;
  onClose: () => void;
}

const WelcomeVideoModal: FC<Props> = ({ currentCampaign, isOpen, onClose }) => {
  const styles = useStyles();

  const handleClose = () => {
    onClose();
    setTimeout(() => {
      setPlayerState({ ...playerState, isPlaying: false });
    }, 100);
  };

  const refVideo = useRef<HTMLVideoElement | null>(null);

  const { playerState, setPlayerState } = useVideoControls(refVideo, false);

  return (
    <FSModal
      onClose={handleClose}
      modalIsOpen={isOpen}
      rounded="12px"
      blur
      width="770px"
      padding="0"
    >
      <Box className={styles.container}>
        <Typography className={styles.title}>{WelcomeVideoModalStrings.Title}</Typography>
        <Typography className={styles.subTitle} sx={{ padding: isUWMClient() ? '0 34px' : 0 }}>
          {WelcomeVideoModalStrings.SubTitle}
        </Typography>
        <Divider className={styles.divider} />
        <EditWelcomeVideo
          currentCampaign={currentCampaign}
          onClose={handleClose}
          onCancel={handleClose}
        />
      </Box>
    </FSModal>
  );
};

export default WelcomeVideoModal;
