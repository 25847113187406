export const NewCalendarIcon = () => {
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.5 3H19.5V2C19.5 1.45 19.05 1 18.5 1C17.95 1 17.5 1.45 17.5 2V3H7.5V2C7.5 1.45 7.05 1 6.5 1C5.95 1 5.5 1.45 5.5 2V3H4.5C3.4 3 2.5 3.9 2.5 5V21C2.5 22.1 3.4 23 4.5 23H20.5C21.6 23 22.5 22.1 22.5 21V5C22.5 3.9 21.6 3 20.5 3ZM19.5 21H5.5C4.95 21 4.5 20.55 4.5 20V8H20.5V20C20.5 20.55 20.05 21 19.5 21Z"
          fill="#026AA2"
        />
      </svg>
    </>
  );
};
