import { FC, SyntheticEvent, useState } from 'react';
import { useStyles } from './CreatorsTabsSelect.styles';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  tabContent: JSX.Element;
  showEmpty?: boolean;
}

interface Props {
  tabsList: TabItem[];
  tabWidth?: string;
  onTabChange?: (index: number) => void;
}

export const CreatorsTabsSelect: FC<Props> = ({ tabsList, tabWidth, onTabChange }) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme();

  const handleTabChange = (_: SyntheticEvent, index: number) => {
    setTabIndex(index);
    onTabChange && onTabChange(index);
  };

  return (
    <Box className={classes.container}>
      <Box>
        <Tabs value={tabIndex} onChange={handleTabChange} className={classes.tabs}>
          {tabsList.map((tab, index) => {
            const isActive = tabIndex === index;
            return (
              <Tab
                key={index}
                style={{
                  backgroundColor: '#FFFFFF',

                  borderBottom: isActive ? `1px solid ${theme.palette.primary.main}` : '',
                  textTransform: 'none',
                  minWidth: tabWidth,
                  display:
                    tab.showEmpty === false && Number(tab?.usersCount) <= 0 ? 'none' : 'flex',
                }}
                label={
                  <>
                    <Typography
                      className={classes.tabName}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                      }}
                    >
                      {tab.tabName}
                    </Typography>
                    <Typography
                      className={classes.usersCount}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                        backgroundColor: isActive ? '#F9F5FF' : '#F2F4F7',
                      }}
                    >
                      {tab.usersCount}
                    </Typography>
                  </>
                }
                id={`${tab.tabName.split(' ').join('-')}-tab`}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box className={classes.divider}></Box>
      <Box className={classes.contentWrapper}>{tabsList[tabIndex].tabContent}</Box>
    </Box>
  );
};
