import { Dispatch, useReducer } from 'react';

export interface LoginState {
  activeSubmitButton: boolean;
}

const initialLoginState: LoginState = {
  activeSubmitButton: true,
};

export enum LoginActionTypes {
  SET_ACTIVE_SUBMIT_BUTTON = 'SET_ACTIVE_SUBMIT_BUTTON',
}

export type LoginAction = { type: LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON; payload: boolean };

export const useLoginReducer = (): [LoginState, Dispatch<LoginAction>] => {
  const loginReducer = (state: LoginState, action: LoginAction): LoginState => {
    switch (action.type) {
      case LoginActionTypes.SET_ACTIVE_SUBMIT_BUTTON:
        return { ...state, activeSubmitButton: action.payload };
      default:
        return state;
    }
  };

  const [data, dispatch] = useReducer(loginReducer, initialLoginState);
  return [data, dispatch];
};
