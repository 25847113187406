import styled from 'styled-components';
import { theme } from '../../theme';
import { Button } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--black);
`;
export const LoginHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 24px;
  font-weight: normal;
  color: var(--darkBlue);
`;

export const LoginSubHeader = styled.div`
  display: flex-start;
  position: relative;
  width: 100%;
  justify-content: center;
  font-size: 16px;
  font-weight: normal;
  color: var(--darkBlue);
`;
export const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 700px;
    height: 450px;
    flexwrap: 'wrap';
  }
  @media (max-width: 768px) and (orientation: portrait) {
    width: 272px;
    height: 550px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    width: 412px;
    height: 450px;
  }
  width: 272px;
  height: 412px;
  padding: 14px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: var(--white);
`;

export const HelpText = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: ${theme.palette.error.main};
`;

export const ErrorTitle = styled.p`
  font-size: 24px;
  font-weight: 400;
  line-height: 32.02px;
  text-align: center;
  color: ${theme.palette.primary.dark};
  margin-bottom: 20px;
`;
export const ErrorDescription = styled.p`
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  text-align: center;
  color: ${theme.palette.text.secondary};
`;
export const ContactSupportButton = styled(Button)`
  color: ${theme.palette.primary.dark};
  box-shadow: 0px 4px 7px -2px rgba(10, 25, 107, 0.15);
  border-radius: 8px;
`;

export const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    width: 900px;
    height: 800px;
    flexwrap: 'wrap';
  }
  @media (max-width: 768px) and (orientation: portrait) {
    width: 272px;
    height: 550px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    width: 412px;
    height: 450px;
  }
  width: 272px;
  height: 412px;
  padding: 14px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: var(--white);
`;

export const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    width: 900px;
    height: 800px;

    flexwrap: 'wrap';
  }
  @media (max-width: 768px) and (orientation: portrait) {
    width: 272px;
    height: 550px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    width: 412px;
    height: 450px;
  }
  width: 272px;
  height: 412px;
  padding: 14px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: var(--white);
`;

export const ContentWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 272px;
  height: 412px;
  @media (min-width: 768px) {
    width: 800px;
    height: 600px;
    flexwrap: 'wrap';
  }
  @media (max-width: 768px) and (orientation: portrait) {
    width: 272px;
    height: 550px;
  }
  @media (max-width: 768px) and (orientation: landscape) {
    width: 412px;
    height: 450px;
  }

  padding: 14px;
  border-radius: 14px;
  box-sizing: border-box;
  background-color: var(--white);
`;

export const WizardInstructionsTitle = styled.p`
  font-weight: normal;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
  color: ${theme.palette.text.primary};
`;
export const WizardInstructionsSubtitle = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  color: ${theme.palette.text.secondary};
`;
export const InstructionsFrameContainer = styled.div`
  min-height: 200px;
`;

export const StyledConfirmText = styled.p`
  color: var(--darkBlue);
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;

export const ModalInfoText = styled.p`
  color: ${theme.palette.text.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
`;
export const SuccessTitle = styled.p`
  color: ${theme.palette.text.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 133.4%;
  text-align: center;
`;
export const SuccessLabel = styled.p`
  color: ${theme.palette.text.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  overflow-wrap: break-word;
  width: 100%;
`;
export const SuccessSubtitle = styled.p`
  color: ${theme.palette.text.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.15px;
  overflow-wrap: break-word;
  width: 100%;
`;
export const SuccessValue = styled.p`
  color: ${theme.palette.text.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  letter-spacing: 0.15px;
  overflow-wrap: break-word;
  width: 100%;
`;

export const SuccessImage = styled.img`
  width: 340px;
  width: 280px;
`;
export const InstructionsImage = styled.img`
  transform: scale(1.5);
  margin-bottom: 30px;
`;
export const ErrorImage = styled.img`
  transform: scale(1.2);
  margin-bottom: 50px;
  margin-top: 20px;
`;
