import { FC } from 'react';
import { Grid } from '@mui/material';

import { SectionTitle, FilterImage, ImgContainer } from '../Steps.style';
import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { FilterProps } from '../../WizzardStepperHelper';
import defaultBg from '../../../../common/assets/default-filter-bg.jpg';

export const SelectedFilterSection: FC<{ filterStepState: FilterProps }> = ({
  filterStepState,
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <SectionTitle>{VenueWizardPreviewStepStrings.FilterTitle}</SectionTitle>
      </Grid>
      <Grid item xs={6}>
        <ImgContainer>
          <FilterImage src={defaultBg} />
          <FilterImage src={filterStepState.url} />
        </ImgContainer>
      </Grid>
    </Grid>
  );
};
