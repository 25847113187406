import { ReactElement } from 'react';
import { WebsiteRoutes } from '../../common/constants/routes';
import {
  IntegrationsSubNavigationStrings,
  SettingsSubnavigationStrings,
  WebAppPageStrings,
} from '../../common/localization/en';
import { SubnavigationItemValues } from '../../common/components/webappSubnavigation/WebappSubnavigation.helper';
import { LaptopIcon } from '../../common/assets/newDesign/SocialVerse/LaptopIcon';
import { LaptopWithPuzleIcon } from '../../common/assets/newDesign/SocialVerse/LaptopWithPuzleIcon';
import {
  // DownloadedVideoIcon,
  VideoSharedScreenIcon,
  WelcomeScreenIcon,
} from '../../common/assets/newDesign/WebAppIcons/index';

import { InvitesSMSIcon } from '../../common/assets/newDesign/WebAppIcons/InvitesSMSIcon';
import { InvitesQRIcon } from '../../common/assets/newDesign/WebAppIcons/InvitesQRIcon';
import { TrackingPixelNavIcon } from '../../common/assets/newDesign/WebAppIcons/TrackingPixelNavIcon';

export enum SubnavigationKeys {
  webapp = 'webapp',
  team = 'team',
  socialverse = 'socialverse',
  // beacons = 'beacons',
  api = 'api',
  incentive = 'incentive',
  invites = 'invites',
  widget = 'widget',
  addToYourWebsite = 'addToYourWebsite',
  keywords = 'keywords',
  sync = 'sync',
}

export interface INavItems {
  icon: ReactElement;
  title: string;
}

export const navItems: INavItems[] = [
  {
    icon: <WelcomeScreenIcon />,
    title: WebAppPageStrings.BrandingStyle,
  },
  {
    icon: <VideoSharedScreenIcon />,
    title: WebAppPageStrings.VideoBrandingPlayback,
  },
  {
    icon: <TrackingPixelNavIcon />,
    title: WebAppPageStrings.TrackingPixel,
  },
  /*   {
    icon: <DownloadedVideoIcon />,
    title: WebAppPageStrings.DownloadedVideo,
  }, */
];

export const subnavigationSectionTitles: { [key in SubnavigationKeys]: string } = {
  webapp: 'Video App',
  team: 'Teams',
  socialverse: 'SocialVerse',
  // beacons: 'Tracking Pixel',
  api: 'API',
  incentive: 'Rewards',
  invites: 'Invites',
  widget: 'Widget',
  addToYourWebsite: 'Add To Your Website',
  keywords: 'Keywords',
  sync: 'Data Sync',
};

export const subnavigationItems = (
  id?: string,
): { [key in SubnavigationKeys]: SubnavigationItemValues[] } => {
  return {
    // {
    //   route: WebsiteRoutes.SettingsManagers,
    //   text: SettingsSubnavigationStrings.Managers,
    // },
    // {
    //   route: WebsiteRoutes.TeamSettings,
    //   text: SettingsSubnavigationStrings.Team,
    // },
    webapp: [
      // {
      //   icon: <WelcomeScreenIcon />,
      //   route: WebsiteRoutes.BrandingStyle,
      //   text: WebAppPageStrings.BrandingStyle,
      // },
      // {
      //   icon: <SharingScreenIcon />,
      //   route: WebsiteRoutes.VideoSharing,
      //   text: `${WebAppPageStrings.Sharing} ${WebAppPageStrings.Screen}`,
      // },
      // {
      //   icon: <VideoSharedScreenIcon />,
      //   route: WebsiteRoutes.VideoEditing,
      //   text: WebAppPageStrings.VideoPlaybackScreen,
      // },
      // {
      //   icon: <DownloadedVideoIcon />,
      //   route: WebsiteRoutes.DownloadedVideo,
      //   text: WebAppPageStrings.VideoBranding,
      // },
      // {
      //   icon: <VideoSharedScreenIcon />,
      //   route: WebsiteRoutes.VideoBrandingPlayback,
      //   text: WebAppPageStrings.VideoBrandingPlayback,
      // },
      // {
      //   icon: <TrackingPixelNavIcon />,
      //   route: WebsiteRoutes.TrackingPixel,
      //   text: WebAppPageStrings.TrackingPixel,
      // },
    ],
    team: [
      // {
      //   route: WebsiteRoutes.TeamSettings,
      //   text: SettingsSubnavigationStrings.Team,
      // },
    ],
    socialverse: [
      {
        route: WebsiteRoutes.IntegrationsWebWidget,
        text: IntegrationsSubNavigationStrings.SocialVerseDesign,
        icon: <LaptopIcon />,
      },
      {
        route: WebsiteRoutes.IntegrationsAddToWebsite,
        text: IntegrationsSubNavigationStrings.AddToYourWebsite,
        icon: <LaptopWithPuzleIcon />,
      },
    ],
    keywords: [],
    widget: [
      {
        route: WebsiteRoutes.IntegrationsWebWidget + '/' + id,
        text: IntegrationsSubNavigationStrings.SocialVerseDesign,
        icon: <LaptopIcon />,
      },
      {
        route: WebsiteRoutes.IntegrationsAddToWebsite + '/' + id,
        text: IntegrationsSubNavigationStrings.AddToYourWebsite,
        icon: <LaptopWithPuzleIcon />,
      },
    ],
    addToYourWebsite: [
      {
        route: WebsiteRoutes.IntegrationsWebWidget + '/' + id,
        text: IntegrationsSubNavigationStrings.SocialVerseDesign,
        icon: <LaptopIcon />,
      },
      {
        route: WebsiteRoutes.IntegrationsAddToWebsite + '/' + id,
        text: IntegrationsSubNavigationStrings.AddToYourWebsite,
        icon: <LaptopWithPuzleIcon />,
      },
    ],
    // beacons: [
    //   // {
    //   //   route: WebsiteRoutes.Beacons,
    //   //   text: SettingsSubnavigationStrings.Beacons,
    //   // },
    // ],
    api: [
      // {
      //   route: WebsiteRoutes.API,
      //   text: SettingsSubnavigationStrings.API,
      // },
    ],
    incentive: [
      // {
      //   route: WebsiteRoutes.ConfirgurationIncentivePoints,
      //   text: RewardsStrings.ConfirgurationRewardsPoints,
      //   icon: <PointsIcon />,
      // },
      // {
      //   route: WebsiteRoutes.ConfirgurationIncentiveRewardSMS,
      //   text: RewardsStrings.ConfirgurationRewardSMS,
      //   icon: <RewardSMSIcon />,
      // },
      // {
      //   route: WebsiteRoutes.ConfirgurationIncentiveRewardFulfillment,
      //   text: RewardsStrings.ConfirgurationRewardFulfillment,
      //   icon: <RewardFulfillmentIcon />,
      // },
    ],
    invites: [
      {
        icon: <InvitesSMSIcon />,
        route: WebsiteRoutes.IntegrationsSmsInvite,
        text: SettingsSubnavigationStrings.SMS,
      },
      {
        icon: <InvitesQRIcon />,
        route: WebsiteRoutes.IntegrationsQRCode,
        text: SettingsSubnavigationStrings.QRCode,
      },
    ],
    sync: [
      {
        route: WebsiteRoutes.SyncData,
        text: SettingsSubnavigationStrings.DataSync,
      },
      {
        route: WebsiteRoutes.HistorySync,
        text: SettingsSubnavigationStrings.HistorySync,
        disabled: true,
      },
    ],
  };
};

export const getNestedPath = (route: string, mainPath: string) => {
  const subpaths = route.split('/');
  const subPath1 = subpaths.pop();
  const subPath2 = subpaths.pop();
  return subPath2 === mainPath ? subPath1 : subPath2;
};
