import React, { useState } from 'react';
import { useStyles } from '../ClipsSearchTags.styles';
import { Box } from '@mui/material';
import {
  SearchOperationType,
  VideoAttributeName,
  VideoStatusType,
} from '../../../../api/models/searchFilterModels';
import PendingModerationIcon from '../../../assets/newDesign/PendingModerationIcon';
import PublishedIcon from '../../../assets/ClipsDetailsIcons/PublishedIcon';
import UnpublishedIcon from '../../../assets/ClipsDetailsIcons/UnpublishedIcon';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import {
  removeVideosStatusFilter,
  setActiveVideoFilterAttributes,
  updateVideosStatusFilter,
} from '../../../../store/slices/searchFilterSlice';
import { getVideosBySearchFilter } from '../../../../store/slices/videosSlice';
import ModeratedIcon from '../../../assets/newDesign/ModeratedIcon';
import { SearchTagsStrings } from '../../../localization/en';
import MenuContainer from '../../MenuContainer/MenuContainer';

const StatusTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { activeVideoFilterAttributes, search } = useTypedSelector((state) => state.searchFilter);
  const { page, size, sort } = useTypedSelector((state) => state.videos);
  const {
    venue: { id },
  } = useTypedSelector((state) => state.venue);
  const [statusAnchorEl, setStatusAnchorEl] = useState<null | HTMLElement>(null);
  const isStatusMenuOpen = Boolean(statusAnchorEl);

  const handleStatusMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null);
  };

  const statusFilterIdx = activeVideoFilterAttributes.findIndex(
    (item) => item.name === VideoAttributeName.Status,
  );

  const statusMap = [
    {
      name: 'Published',
      value: VideoStatusType.Published,
      icon: <PublishedIcon />,
    },
    {
      name: 'Unpublished',
      value: VideoStatusType.Unpublished,
      icon: <UnpublishedIcon />,
    },
    {
      name: 'Moderated',
      value: VideoStatusType.Moderated,
      icon: <ModeratedIcon />,
    },
    {
      name: 'Pending Moderation',
      value: VideoStatusType.PendingModeration,
      icon: <PendingModerationIcon />,
    },
  ];

  const handleStatusChange = (value: VideoStatusType) => {
    if (statusFilterIdx === -1) {
      dispatch(
        setActiveVideoFilterAttributes([
          ...activeVideoFilterAttributes,
          { name: VideoAttributeName.Status, operation: SearchOperationType.Equals, value },
        ]),
      );

      dispatch(
        getVideosBySearchFilter({
          filter: [
            ...activeVideoFilterAttributes,
            { name: VideoAttributeName.Status, operation: SearchOperationType.Equals, value },
          ],
          pageable: {
            page,
            size,
            sort,
          },
          venueId: id,
          search: search,
        }),
      );

      return;
    }

    dispatch(updateVideosStatusFilter(value));

    dispatch(
      getVideosBySearchFilter({
        filter: activeVideoFilterAttributes.map((filter) =>
          filter.name === VideoAttributeName.Status ? { ...filter, value } : filter,
        ),
        pageable: {
          page,
          size,
          sort,
        },
        venueId: id,
        search: search,
      }),
    );
  };

  const handleRemoveStatusFilter = () => {
    dispatch(removeVideosStatusFilter());

    dispatch(
      getVideosBySearchFilter({
        filter: activeVideoFilterAttributes.filter(
          (filter) => filter.name !== VideoAttributeName.Status,
        ),
        pageable: {
          page,
          size,
          sort,
        },
        venueId: id,
        search: search,
      }),
    );
  };

  return (
    <>
      <Box
        className={`${styles.tag} ${statusFilterIdx !== -1 && styles.active}`}
        onClick={handleStatusMenuOpen}
      >
        {SearchTagsStrings.Status}:{' '}
        {statusFilterIdx === -1 ? (
          ''
        ) : (
          <Box
            sx={{
              marginLeft: '4px',
              display: 'flex',
            }}
          >
            {
              statusMap.find(
                (status) => status.value === activeVideoFilterAttributes[statusFilterIdx].value,
              )?.icon
            }
          </Box>
        )}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (statusFilterIdx !== -1) {
              e.stopPropagation();
              handleRemoveStatusFilter();
            }
          }}
        >
          {statusFilterIdx === -1 ? <SmallArrowIcon /> : <PurpleCloseIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={statusAnchorEl}
        isOpen={isStatusMenuOpen}
        onClose={handleStatusMenuClose}
        padding="4px 0"
      >
        {statusMap.map((status) => (
          <Box
            key={status.value}
            className={styles.status}
            onClick={() => {
              handleStatusChange(status.value);
              handleStatusMenuClose();
            }}
          >
            {status.icon} {status.name}
          </Box>
        ))}
      </MenuContainer>
    </>
  );
};

export default StatusTag;
