import React, { FC } from 'react';
import { FSModal } from '../modal/Modal';
import { Box, Divider, Typography } from '@mui/material';
import { useStyles } from './UWMConfirmSyncModal.styles';
import { BRAND_360_LOGO } from '../../constants/constants';
import { useTypedSelector } from '../../../store';
import { DistributorsStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import SocialVenuLogo from '../../assets/newDesign/SV Logo.png';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  url: string;
}

const UWMConfirmSyncModal: FC<Props> = ({ isOpen, onClose, onConfirm, url }) => {
  const styles = useStyles();

  const { name } = useTypedSelector((state) => state.me);

  return (
    <FSModal
      modalIsOpen={isOpen}
      onClose={onClose}
      rounded="12px"
      padding="0"
      backdropColor="#F1F4F8"
      width="1039px"
    >
      <Box className={styles.container}>
        <Box className={styles.logoWrapper}>
          <img src={BRAND_360_LOGO} alt="Brand360 logo" className={styles.logo} />
          <Typography className={styles.inpartnershipText}>in partnership with </Typography>
          <Box
            component={'img'}
            src={SocialVenuLogo}
            alt="logo"
            width={146}
            className={styles.socialVLogo}
          />
        </Box>
        <Divider className={styles.divider} />
        <Box className={styles.contentWrapper}>
          <Box className={styles.sideWrapper}>
            <Typography className={styles.title}>{`Hello ${name.split(' ')[0]},`}</Typography>
            <Typography className={styles.title}>
              <span style={{ color: '#2023E1' }}>SocialV</span>’s Video Platform has partnered with
              Brand 360 to deliver Video Reviews.
            </Typography>
            <Typography className={styles.info}>
              Google requires you to authorize{' '}
              <span style={{ fontWeight: '500', color: '#1A1538' }}>Brand360</span> &{' '}
              <span style={{ color: '#2023E1' }}>SocialV</span> to showcase your videos on your
              Google Business Profile™.
            </Typography>
          </Box>
          <Box className={styles.sideWrapper}>
            <Typography className={styles.text}>
              <span style={{ color: '#2023E1', fontWeight: 700 }}>SocialV</span> will
              <span style={{ fontWeight: 700 }}> ONLY:</span>
            </Typography>
            <Typography className={styles.text} marginBottom="10px">
              <span style={{ fontWeight: 700 }}> EDIT</span> your Google Business Profile by
              automatically showcasing your client videos.
            </Typography>

            <Typography className={styles.text} marginBottom="10px">
              <span style={{ color: '#2023E1', fontWeight: 700 }}>SocialV</span>{' '}
              <span style={{ fontWeight: 700 }}>will NOT:</span>
            </Typography>

            <Typography className={styles.text} marginBottom="10px">
              <span style={{ fontWeight: 700 }}>DELETE</span> your Google Business Profile
            </Typography>

            <Typography className={styles.text}>
              <span style={{ fontWeight: 700 }}>CHANGE</span> or{' '}
              <span style={{ fontWeight: 700 }}>REMOVE</span> your access to your Google Business
              Profile
            </Typography>
          </Box>
        </Box>
        <Box className={styles.buttonsWrapper}>
          <RoundedButton
            title={DistributorsStrings.Cancel}
            onClick={onClose}
            variant="outlined"
            fullWidth
            className={styles.button}
            sx={{ borderColor: '#D0D5DD' }}
          />
          <RoundedButton
            title={DistributorsStrings.IAgree}
            href={url}
            onClick={() => onConfirm?.()}
            variant="outlined"
            fullWidth
            className={styles.button}
          />
        </Box>
      </Box>
    </FSModal>
  );
};

export default UWMConfirmSyncModal;
