import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useStyles } from '../AccountsSearchTags.styles';
import {
  AccountAttributeName,
  AccountFilterAttribute,
  AccountStatusType,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { AccountsSearchStrings, SearchTagsStrings } from '../../../localization/en';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import MenuContainer from '../../MenuContainer/MenuContainer';
import {
  removeAccountSetupCompletedFilter,
  removeAccountTutorialCompletedFilter,
  setActiveAccountFilterAttributes,
} from '../../../../store/slices/searchFilterSlice';
import { InitialStatusIcon } from '../../../assets/Statuses/InitialStatusIcon';
import { PendingStatusIcon } from '../../../assets/Statuses/PendingStatusIcon';
import { CircularCheckMarkIcon } from '../../../assets/newDesign/CircularCheckMark';
import { resetPage } from '../../../../store/slices/organizationVenueSlice';

const AccountStatusTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { activeAccountFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  // const { page, size, sort } = useTypedSelector((state) => state.videos);

  const [statusValue, setStatusValue] = useState('');

  const [verificationAnchorEl, setVerificationAnchorEl] = useState<null | HTMLElement>(null);
  const isVerificationMenuOpen = Boolean(verificationAnchorEl);

  const handleVerificationMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setVerificationAnchorEl(event.currentTarget);
  };

  const handleVerificationMenuClose = () => {
    setVerificationAnchorEl(null);
  };
  useEffect(() => {
    const setupCompleted = activeAccountFilterAttributes.find(
      (attribute) =>
        attribute.name === AccountAttributeName.SetupCompleted && attribute.value === true,
    );
    const tutorialCompleted = activeAccountFilterAttributes.find(
      (attribute) =>
        attribute.name === AccountAttributeName.TutorialCompleted && attribute.value === true,
    );
    const tutorialNotCompleted = activeAccountFilterAttributes.find(
      (attribute) =>
        attribute.name === AccountAttributeName.TutorialCompleted && attribute.value === false,
    );
    const setupNotCompleted = activeAccountFilterAttributes.find(
      (attribute) =>
        attribute.name === AccountAttributeName.SetupCompleted && attribute.value === false,
    );
    const status: AccountStatusType | undefined = tutorialCompleted
      ? AccountStatusType.TutorialCompleted
      : setupCompleted && tutorialNotCompleted
      ? AccountStatusType.SetupCompleted
      : setupNotCompleted
      ? AccountStatusType.Created
      : undefined;
    if (status) {
      setStatusValue(StatusMap.filter((item) => item.value === status)[0].name);
    } else {
      setStatusValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccountFilterAttributes]);

  const StatusMap: {
    name: string;
    value: AccountStatusType;
    icon: JSX.Element;
  }[] = [
    {
      name: AccountsSearchStrings.AccountActivated,
      value: AccountStatusType.Created,
      icon: <InitialStatusIcon />,
    },
    {
      name: AccountsSearchStrings.AccountSetup,
      value: AccountStatusType.SetupCompleted,
      icon: <PendingStatusIcon />,
    },
    {
      name: AccountsSearchStrings.TutotialCompleted,
      value: AccountStatusType.TutorialCompleted,
      icon: <CircularCheckMarkIcon color={'#FFB515'} />,
    },
  ];

  const setupCompletedFilterIdx = activeAccountFilterAttributes.findIndex(
    (item) => item.name === AccountAttributeName.SetupCompleted,
  );

  const tutorialCompletedFilterIdx = activeAccountFilterAttributes.findIndex(
    (item) => item.name === AccountAttributeName.TutorialCompleted,
  );

  const handleAccountStatusChange = (value: AccountStatusType) => {
    if (value === AccountStatusType.Created) {
      // if (setupCompletedFilterIdx !== -1 || tutorialCompletedFilterIdx !== -1) {
      //   dispatch(removeAccountSetupCompletedFilter());
      //   dispatch(removeAccountTutorialCompletedFilter());
      // }

      const filter: AccountFilterAttribute[] = [
        ...activeAccountFilterAttributes
          .filter((filter) => filter.name !== AccountAttributeName.SetupCompleted)
          .filter((filter) => filter.name !== AccountAttributeName.TutorialCompleted),

        {
          name: AccountAttributeName.SetupCompleted,
          operation: SearchOperationType.Equals,
          value: false,
        },
      ];

      dispatch(setActiveAccountFilterAttributes(filter));
      dispatch(resetPage());

      return;
    } else if (value === AccountStatusType.SetupCompleted) {
      const filter: AccountFilterAttribute[] = [
        ...activeAccountFilterAttributes
          .filter((filter) => filter.name !== AccountAttributeName.SetupCompleted)
          .filter((filter) => filter.name !== AccountAttributeName.TutorialCompleted),

        {
          name: AccountAttributeName.SetupCompleted,
          operation: SearchOperationType.Equals,
          value: true,
        },
        {
          name: AccountAttributeName.TutorialCompleted,
          operation: SearchOperationType.Equals,
          value: false,
        },
      ];

      dispatch(setActiveAccountFilterAttributes(filter));
      dispatch(resetPage());

      return;
    } else if (value === AccountStatusType.TutorialCompleted) {
      const filter: AccountFilterAttribute[] = [
        ...activeAccountFilterAttributes
          .filter((filter) => filter.name !== AccountAttributeName.SetupCompleted)
          .filter((filter) => filter.name !== AccountAttributeName.TutorialCompleted),

        {
          name: AccountAttributeName.SetupCompleted,
          operation: SearchOperationType.Equals,
          value: true,
        },
        {
          name: AccountAttributeName.TutorialCompleted,
          operation: SearchOperationType.Equals,
          value: true,
        },
      ];

      dispatch(setActiveAccountFilterAttributes(filter));
      dispatch(resetPage());

      return;
    }
  };

  const handleRemoveStatusFilter = () => {
    dispatch(removeAccountSetupCompletedFilter());
    dispatch(removeAccountTutorialCompletedFilter());
    dispatch(resetPage());
  };

  return (
    <>
      <Box
        className={`${styles.tag} ${
          (setupCompletedFilterIdx !== -1 || tutorialCompletedFilterIdx !== -1) && styles.active
        }`}
        onClick={handleVerificationMenuOpen}
      >
        {SearchTagsStrings.Status}:{' '}
        {
          <Box
            sx={{
              marginLeft: '4px',
              display: 'flex',
            }}
          >
            {statusValue}
          </Box>
        }
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (setupCompletedFilterIdx !== -1 || tutorialCompletedFilterIdx !== -1) {
              e.stopPropagation();
              handleRemoveStatusFilter();
            }
          }}
        >
          {tutorialCompletedFilterIdx !== -1 || setupCompletedFilterIdx !== -1 ? (
            <PurpleCloseIcon />
          ) : (
            <SmallArrowIcon />
          )}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={verificationAnchorEl}
        isOpen={isVerificationMenuOpen}
        onClose={handleVerificationMenuClose}
        padding="4px 0"
      >
        {StatusMap.map((status) => (
          <Box
            key={status.value}
            className={styles.statusOption}
            onClick={() => {
              handleAccountStatusChange(status.value);
              handleVerificationMenuClose();
            }}
          >
            {status.icon} {status.name}
          </Box>
        ))}
      </MenuContainer>
    </>
  );
};

export default AccountStatusTag;
