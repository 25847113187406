import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  socialSmilesText: {
    fontSize: '33px',
    fontWeight: '600',
    color: '#fff',
    marginTop: '21px',
  },
  prefPricingText: {
    minHeight: '30px',
    fontSize: '18px',
    fontWeight: 500,
    color: '#fff',
    margin: '24px 0 40px',
  },
  planDescription: {
    color: '#8B89A0',
    fontSize: '14px',
  },
  MobileplanDescription: {
    color: '#8B89A0',
    fontSize: '20px',
  },
  sectionItem: {
    marginBottom: '20px',
  },
  sectionTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitle: {
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: '600',
    color: '#fff',
  },
  sectionDescription: {
    color: '#fff',
    fontSize: '12px',
    fontWeight: '400',
    marginLeft: '34px',
    opacity: '0.7',
  },
}));
