import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    marginTop: '46px',
    maxHeight: 'calc(100vh - 350px)',
    overflowX: 'hidden',

    '&::-webkit-scrollbar': {
      width: '8px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EDEDED',
      width: '8px',
      borderRadius: '30px',
    },
  },
  table: {
    '& thead': {
      '& tr': {
        '& th': {
          fontSize: '12px',

          '&:nth-child(1)': {
            width: '213px !important',
          },
          '&:nth-child(2)': {
            width: '122px !important',
          },
          '&:nth-child(3)': {
            width: '99px !important',
          },
          '&:nth-child(4)': {
            width: '157px !important',
          },

          '& div': {
            color: '#475467 !important',
            justifyContent: 'center !important',
          },

          '&:nth-child(1) div': {
            justifyContent: 'flex-start !important',
          },
        },
      },
    },
  },
  row: {
    '& td': {
      paddingLeft: '0 !important',
      padding: '16px 0',
      cursor: 'auto',

      '&:first-child': {
        paddingLeft: '10px !important',

        '& div p': {
          width: '213px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

      '&:last-child': {},
    },
  },
  inviteButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 0',
    borderTop: '1px solid #EBEBEB',
    backgroundColor: '#FFF',
  },
  inviteButton: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#344054',
    padding: '10px 16px',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    fontSize: '14px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
  },
}));
