import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Typography, useTheme } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import React, { useMemo } from 'react';
import { useStyles } from './Stepper.helper';

interface StepComponentProps extends BoxProps {
  title: string;
  passed: boolean;
  number: number;
  active: boolean;
  newCampaignLayout?: boolean;
}

const StepComponent: React.FC<StepComponentProps> = ({
  title,
  passed,
  number,
  active,
  newCampaignLayout,
  ...boxProps
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const iconColor = useMemo(() => {
    if (passed) {
      return theme.palette.common.green;
    }
    return theme.palette.text.secondary;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passed]);

  const textColor = useMemo(() => {
    if (passed || active) {
      return theme.palette.primary.main;
    }
    return theme.palette.text.secondary;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passed, active]);

  return (
    <Box
      className={active ? classes.root : undefined}
      display="flex"
      alignItems="center"
      {...boxProps}
    >
      <CheckCircleIcon sx={{ mr: '8px', color: iconColor }} />
      <Typography
        color={textColor}
        sx={{
          fontWeight: '500',
          fontSize: newCampaignLayout ? '12px' : '16px',
        }}
      >
        {number}. {title}
      </Typography>
    </Box>
  );
};

export interface Step {
  title: string;
  value: string;
  hidden?: boolean;
}

export interface StepperProps {
  steps: Step[];
  currentStep: Step['value'];
  newCampaignLayout?: boolean;
}

export const Stepper: React.FC<StepperProps> = ({ steps, currentStep, newCampaignLayout }) => {
  const classes = useStyles();
  // Should display only steps that not hidden
  const displaySteps = useMemo(() => {
    return steps.filter((step) => !step.hidden);
  }, [steps]);

  const currentStepIndex = useMemo(() => {
    return displaySteps.findIndex((step) => step.value === currentStep);
  }, [currentStep, displaySteps]);

  return (
    <Box
      className={classes.wrapper}
      display="flex"
      alignItems="center"
      my={newCampaignLayout ? '' : '16px'}
      style={{
        background: newCampaignLayout ? '#F5F6F8' : 'white',
      }}
    >
      {displaySteps.map(({ title, value }, index) => (
        <StepComponent
          key={value}
          title={title}
          number={index + 1}
          passed={currentStepIndex > index}
          active={currentStepIndex === index}
          newCampaignLayout={newCampaignLayout}
          marginRight="6px"
        />
      ))}
    </Box>
  );
};
