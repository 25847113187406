import styled from 'styled-components';
import { theme } from '../../../../theme';
import { Button } from '@mui/material';

export const Container = styled.div`
  > form {
    display: flex;
    flex-direction: column;
  }
`;
export const FormField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const IncentiveFormField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 25px;
  margin-top: -50px;
`;
export const IncentiveFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width:100%;
  min-width:400px;
  align-items: center;
`;
export const TextFieldWrapper = styled.div`
  width: 240px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 80px;
  }
`;
export const FormFieldText = styled.span`
  color: ${theme.palette.secondary.contrastText};
  font-size: 16px;

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-size: 14px;
  }
`;

export const BottomSection = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: end;
  justify-content: end;
`;

export const RecommendedFieldWrapper = styled.div`
  margin-top: 24px;
`;

interface RecommendedFieldProps {
  isValue: boolean;
}
export const RecommendedField = styled.div<RecommendedFieldProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 8px;
  padding: 0 24px;
  height: 85px;
  background-color: ${theme.palette.success.light};
  border: 1px solid ${theme.palette.common.ContentTable.published};
  border-radius: 10px;
`;

interface RecommendedFieldTextProps {
  isValue: boolean;
}
export const RecommendedFieldText = styled.span<RecommendedFieldTextProps>`
  color: ${(props) => theme.palette.text.primary};
`;

interface RecommendedFieldValueProps {
  isValue: boolean;
}
export const RecommendedFieldValue = styled.span<RecommendedFieldValueProps>`
  width: 240px;
  padding-left: 34px;
  font-size: 24px;
  color: ${(props) => theme.palette.success.dark};

  @media (max-width: ${theme.breakpoints.values.md}px) {
    width: 80px;
  }
`;

export const SubmitButton = styled(Button)``;
