import { Box, useTheme } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useModal } from '../../../hooks/useModal';
import { RoundedButton } from '../Button/RoundedButton';
import { ImageGallerySelectorProps, ImageGallerySelector } from './components/ImageGallerySelector';
import { ImageUploaderInfo } from './ImageUploader';
import { ImageUploaderBase, ImageUploaderBaseProps } from './components/ImageUploaderBase';
import { CommonEventNames } from '../../constants/events/common';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

export type Alignment = 'flex-start' | 'center' | 'flex-end';

export interface ImageUploaderWithGalleryProps extends ImageUploaderBaseProps {
  title: string;
  subtitle: string | string[];

  images: ImageGallerySelectorProps['images'];
  onGalleryImageSelect?: ImageGallerySelectorProps['onSelect'];
  onGallerySaveClick?: (activeImage: string) => void;
  onGalleryClose?: () => void;
  alignment?: Alignment;
  withGallery?: boolean;
  onRemove: () => void;
  showEditIcon?: boolean;
  hideButtons?: boolean;
  dndIcon?: JSX.Element;
  alignCenter?: boolean;
  withBottomRemoveButton?: boolean;
}

export const ImageUploaderWithGallery: React.FC<ImageUploaderWithGalleryProps> = ({
  file,
  onUpload,
  onRemove,
  accept,
  loading,
  title,
  subtitle,
  images,
  onGalleryImageSelect,
  onGallerySaveClick,
  onGalleryClose,
  alignment,
  withGallery,
  showEditIcon,
  hideButtons,
  dndIcon,
  alignCenter,
  withBottomRemoveButton,
  ...restProps
}) => {
  const {
    isOpen: isBackgroundSelectorOpen,
    open: openBackgroundSelector,
    close: closeBackgroundSelector,
  } = useModal();

  const theme = useTheme();
  const { trackEvent } = useTrackEvent();
  const [galleryActiveImage, setGalleryActiveImage] = useState<string | undefined>(file);

  useEffect(() => {
    setGalleryActiveImage(file);
  }, [file]);

  const disableGallerySaveButton = useMemo(() => {
    if (!galleryActiveImage) return true;
    return !images.includes(galleryActiveImage as string);
  }, [images, galleryActiveImage]);

  return (
    <ImageUploaderBase
      file={file}
      onUpload={onUpload}
      accept={accept}
      loading={loading}
      alignment={alignment}
      showEditIcon={showEditIcon}
      dndIcon={dndIcon}
      alignCenter={alignCenter}
      onRemove={onRemove}
      withBottomRemoveButton={withBottomRemoveButton}
      renderRightSide={({ openFileSelector }) => (
        <Box display="flex" flexDirection="column" alignItems="start">
          {isBackgroundSelectorOpen ? (
            <>
              <ImageGallerySelector
                images={images}
                activeImage={galleryActiveImage}
                onSelect={(image) => {
                  setGalleryActiveImage(image);
                  onGalleryImageSelect?.(image);
                }}
              />
              <Box display="flex" mt="10px">
                <RoundedButton
                  title="Save"
                  disabled={disableGallerySaveButton}
                  onClick={() => {
                    closeBackgroundSelector();
                    onGallerySaveClick?.(galleryActiveImage as string);
                    onGalleryClose?.();
                    trackEvent(CommonEventNames.background_image_library_select_button_click);
                  }}
                  sx={{ mr: '10px' }}
                />
                <RoundedButton
                  title="Cancel"
                  textVariant
                  error
                  onClick={() => {
                    closeBackgroundSelector();
                    onGalleryClose?.();
                    trackEvent(CommonEventNames.background_image_library_cancel_button_click);
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <ImageUploaderInfo title={title} subtitle={subtitle} />
              <Box display="flex" mt="20px" gap="15px">
                {withGallery && (
                  <RoundedButton
                    title="Image Library"
                    onClick={() => {
                      openBackgroundSelector();
                      trackEvent(CommonEventNames.background_image_library_open_button_click);
                    }}
                    loading={loading}
                    height="40px"
                    sx={{
                      height: '34px',
                      width: '120px',
                      padding: '10px 10px',
                      '& p': {
                        fontSize: '12px',
                      },
                    }}
                  />
                )}
                {!hideButtons && (
                  <RoundedButton
                    title="Browse files"
                    onClick={() => {
                      openFileSelector();
                      trackEvent(CommonEventNames.background_image_library_upload_button_click);
                    }}
                    loading={loading}
                    variant={withGallery ? 'outlined' : 'contained'}
                    outlined={withGallery}
                    sx={{
                      height: withGallery ? '34px' : '45px',
                      width: withGallery ? '120px' : '170px',
                      padding: withGallery ? '10px 10px' : '8.5px 28px',
                      '& p': {
                        fontSize: withGallery ? '12px' : '18px',
                      },
                      border: `2px solid ${theme.palette.primary.main}`,
                    }}
                  />
                )}
              </Box>
              {file && !hideButtons && (
                <RoundedButton
                  title="Remove"
                  onClick={() => {
                    onRemove();
                    trackEvent(CommonEventNames.background_image_library_upload_button_click);
                  }}
                  variant="text"
                  sx={{
                    marginTop: '10px',
                    height: '45px',
                    width: '170px',
                    padding: '8.5px 28px',
                    '& p': {
                      fontSize: '18px',
                      color: '#ED2E38',
                    },
                  }}
                />
              )}
            </>
          )}
        </Box>
      )}
      {...restProps}
    />
  );
};
