import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    minHeight: '200px',
    borderBottom: '1px solid #CED6D8',
    display: 'flex',
    gap: '10px',
    paddingTop: '30px',
    position: 'relative',
  },
  leftSideWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  qsMarkerWrapper: {
    position: 'absolute',
    bottom: '-50px',
    left: 'calc(50% - 475px)',
    transform: 'translateX(-50%)',
  },
}));
