import { Grid } from '@mui/material';

import { TopBar } from '../../../common/components/topBar/TopBar';
import { AnalyticsBlock } from './analyticsBlock/AnalyticsBlock';
import { VenuesTable } from './tableSection/VenuesTable';
import { MarketFilter } from './marketFilter/MarketFilter';
import { useStyles } from './performance.helper';

export const Performance = () => {
  const classes = useStyles();

  return (
    <>
      <TopBar />
      <div className={classes.container}>
        <MarketFilter />

        <Grid container alignContent="center" justifyContent="center" spacing={3}>
          <Grid item xs={12}>
            <AnalyticsBlock />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.tableWrapper}>
              <VenuesTable />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
