import { List, ListItem, ListItemButton, Typography } from '@mui/material';
import { FC } from 'react';
import { MenuOptions, menuOptions } from '../VideoCard.helper';
import { useStyles } from '../VideoCard.styles';

interface CardMenuProps {
  onSelect: (type: MenuOptions) => void;
}

const CardMenu: FC<CardMenuProps> = ({ onSelect }) => {
  const classes = useStyles({ isGridLayout: true });

  return (
    <List className={classes.list}>
      {menuOptions.map((option) => (
        <ListItem disablePadding onClick={() => onSelect(option.value)}>
          <ListItemButton style={{ width: '167px' }}>
            <Typography>{option.label}</Typography>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default CardMenu;
