import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTopGolfVideoInfoStyles = makeStyles((theme: Theme) => ({
  Container: {
    paddingLeft: 20,
  },
  Text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
  },
}));
