import { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import { useTypedSelector, useAppDispatch } from '../../../store';
import { getActivities } from '../../../store/slices/rewardsSlice';

import { LayoutMode } from '../../../common/constants/constants';
import { useWindowResize } from '../../../services/hooks/useWindowResize';

import { CommonStrings, RewardsNewCampaignWizard } from '../../../common/localization/en';

import { TitleSection } from '../TitleSection/TitleSection';

import { RouteLeavingGuard } from '../../../common/components/RouteLeavingGuard/RouteLeavingGuard';

import { RewardsActivities } from '../rewardsActivities/RewardsActivities';
import { AddRewardSection } from './AddRewardSection/AddRewardSection';
import { getCalculationForm } from './RewardsWizard.helper';

import { ControlsBlock, SectionDivider } from '../Rewards.style';
import { RewardsActivitiesWrapper } from './RewardsWizard.style';

interface RewardsWizardProps {
  // campaign: null | CampaignsApiModel; it doesn't used
  height?: string;
  onCloseCreationModal: () => void;
  onOpenEndCampaignModal?: () => void;
}
export const RewardsWizard: FC<RewardsWizardProps> = ({
  height,
  onCloseCreationModal,
  onOpenEndCampaignModal,
}) => {
  const dispatch = useAppDispatch();
  const {
    venue: { id: venueId, clientType },
    venueAttributes: { properties },
  } = useTypedSelector((state) => state.venue);
  const { activities } = useTypedSelector((state) => state.rewards.activities);
  const { activeCampaign, calculationResult } = useTypedSelector((state) => state.campaigns);
  const containerHeight = height || 'calc(100vh - 430px';
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;

  const [canLeave, setCanLeave] = useState(false);
  const [isActiveAddRewardForm, setIsActiveAddRewardForm] = useState(false);

  const onChangeFormHandler = () => {
    setCanLeave(true);
  };

  const CalculationForm = getCalculationForm({
    properties,
    onChangeFormHandler,
    setCanLeave,
    venueId,
    isMobile,
    activeCampaign,
    clientType,
  }) as React.ReactNode;

  useEffect(() => {
    if (clientType) {
      dispatch(getActivities({ clientType }));
    }
  }, [dispatch, clientType]);

  useEffect(() => {
    if (activeCampaign) {
      setIsActiveAddRewardForm(!!activeCampaign);
    }
  }, [activeCampaign]);

  return (
    <>
      <RouteLeavingGuard
        when={canLeave}
        titleText={CommonStrings.LeaveWithouTSaving}
        contentText={CommonStrings.LeaveWithoutSavingVerbose}
      />
      {!activeCampaign && (
        <Grid
          container
          spacing={2}
          style={{
            height: containerHeight,
            minHeight: 600,
          }}
        >
          <Grid item xs={12} lg={6}>
            <ControlsBlock minHeight="auto">
              <TitleSection title={RewardsNewCampaignWizard.Step1Title} />
              <SectionDivider />

              {CalculationForm}
            </ControlsBlock>
          </Grid>

          <Grid item xs={12} lg={6} style={{ height: '100%' }}>
            <ControlsBlock>
              <RewardsActivitiesWrapper>
                <RewardsActivities
                  activities={activities}
                  calculation={calculationResult?.activityProjections}
                />
              </RewardsActivitiesWrapper>
            </ControlsBlock>
          </Grid>
        </Grid>
      )}

      <Grid item xs={12} style={!activeCampaign ? { marginTop: 16 } : {}}>
        <AddRewardSection
          isMobile={isMobile}
          isActive={isActiveAddRewardForm}
          onCloseCreationModal={onCloseCreationModal}
          onOpenEndCampaignModal={onOpenEndCampaignModal}
        />
      </Grid>
    </>
  );
};
