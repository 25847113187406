import { MutableRefObject } from 'react';
import * as Yup from 'yup';

import { PhoneValidationState } from '../components/PhoneField/PhoneField';
import { ErrorsStrings } from '../localization/en';

export const getYupValidationWithPhone = (
  yupShape: Record<string, unknown>,
  config?: {
    phoneRequired?: boolean;
    phoneFieldName?: string;
  },
) => {
  // return a function in that we can pass phone's validation state
  return ({
    phoneValidationRef,
  }: {
    // we should use ref to get the latest validation state
    phoneValidationRef: MutableRefObject<PhoneValidationState>;
  }) => {
    const yupPhoneValidation = Yup.lazy(() => {
      let baseValidation = Yup.string();
      if (config?.phoneRequired) {
        baseValidation = baseValidation.required(ErrorsStrings.FieldIsRequired);
      }

      if (!phoneValidationRef.current.isValid) {
        return baseValidation.max(0, ErrorsStrings.PhoneIsNotValid); // set instant error;
      }
      return baseValidation.min(10, ErrorsStrings.WriteFullNumber);
    });

    // @ts-ignore
    return Yup.object().shape({
      ...yupShape,
      [`${config?.phoneFieldName || 'phone'}`]: yupPhoneValidation,
    });
  };
};

export const emailValidationSchema = Yup.string().email(ErrorsStrings.WriteCorrectEmail);

export const urlValidationSchema = (errorMessage?: string) =>
  Yup.string().url(errorMessage ?? ErrorsStrings.URLIsNotValid);

export const urlValidationMask =
  /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9-]+([.]{1}[a-z0-9-]+)*\.[a-z]{1,5}(:[0-9]{1,5})?(\/.*)?$/gim;
