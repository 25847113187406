import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import ReactDOM from 'react-dom';

const TEMP_ELEMENT_ID = 'use_json_to_csv_temp_element';

export const useJsonToCSV = () => {
  const jsonToCSV = ({
    data,
    headers,
    filename,
  }: {
    data: Data;
    headers: Headers;
    filename: string;
  }) => {
    const tempElement = document.createElement('div');
    tempElement.setAttribute('id', TEMP_ELEMENT_ID);

    ReactDOM.render(<CSVLink data={data} headers={headers} filename={filename} />, tempElement);

    (tempElement.firstElementChild as HTMLElement).click();

    tempElement.parentNode?.removeChild(tempElement);
  };

  return {
    jsonToCSV,
  };
};
