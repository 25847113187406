import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: 72,
    paddingBottom: 24,
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 400,
    lineHeight: 1,
  },
  titleText: {
    paddingRight: 13.375,
    paddingLeft: 13.375,

    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      paddingRight: 8,
      paddingLeft: 8,
    },
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  content: {
    padding: '0 131px 16px',

    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
      paddingRight: 32,
      paddingLeft: 32,
    },
  },
  actions: {
    padding: '24px 62px',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 40,
  },
  paper: {
    maxWidth: 700,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 36,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  radio: {},
}));
