import { FC } from 'react';

type IProfile2userIconProps = {
  color?: string;
};

export const Profile2userIcon: FC<IProfile2userIconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z"
      fill={color || '#D4D2F5'}
    />
    <path
      d="M14.0809 14.15C11.2909 12.29 6.74094 12.29 3.93094 14.15C2.66094 15 1.96094 16.15 1.96094 17.38C1.96094 18.61 2.66094 19.75 3.92094 20.59C5.32094 21.53 7.16094 22 9.00094 22C10.8409 22 12.6809 21.53 14.0809 20.59C15.3409 19.74 16.0409 18.6 16.0409 17.36C16.0309 16.13 15.3409 14.99 14.0809 14.15Z"
      fill={color || '#D4D2F5'}
    />
    <path
      d="M19.9894 7.34001C20.1494 9.28001 18.7694 10.98 16.8594 11.21C16.8494 11.21 16.8494 11.21 16.8394 11.21H16.8094C16.7494 11.21 16.6894 11.21 16.6394 11.23C15.6694 11.28 14.7794 10.97 14.1094 10.4C15.1394 9.48001 15.7294 8.10001 15.6094 6.60001C15.5394 5.79001 15.2594 5.05001 14.8394 4.42001C15.2194 4.23001 15.6594 4.11001 16.1094 4.07001C18.0694 3.90001 19.8194 5.36001 19.9894 7.34001Z"
      fill={color || '#D4D2F5'}
    />
    <path
      d="M21.9902 16.59C21.9102 17.56 21.2902 18.4 20.2502 18.97C19.2502 19.52 17.9902 19.78 16.7402 19.75C17.4602 19.1 17.8802 18.29 17.9602 17.43C18.0602 16.19 17.4702 15 16.2902 14.05C15.6202 13.52 14.8402 13.1 13.9902 12.79C16.2002 12.15 18.9802 12.58 20.6902 13.96C21.6102 14.7 22.0802 15.63 21.9902 16.59Z"
      fill={color || '#D4D2F5'}
    />
  </svg>
);
