import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    boxShadow: '0px 10px 40px -6px rgba(10, 25, 107, 0.15)',
    borderRadius: '8px',
    minHeight: '100px',
    padding: '20px',
  },
  title: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '157%',
    color: theme.palette.primary.dark,
  },
  label: {
    fontStyle: 'normal',
    fontweight: 'normal',
    fontsize: '16px',
    lineHeight: '175%',
    color: theme.palette.text.secondary,
  },
  fileinputWrapper: {
    width: '100%',
    maxWidth: '200px',
    height: '240px',
    borderRadius: '8px',
    backgroundImage: 'url(/inputBackground.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer',
    backgroundPosition: 'center'
  },
  fileInput: {
    width: '120%',
    maxWidth: '200px',
    height: '200px',
    borderRadius: '8px',
    opacity: 0,
    cursor: 'pointer',
  },
  uploadButtonWrapper: {
    marginTop: '8px',
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
  },
  fileInputbutton: {
    position: 'absolute',
    padding: ' 8px 11px',
    borderRadius: '4px',
    backgroundColor: theme.palette.common.slightlyBlueish,
    width: '200px',
    height: '30px',
    opacity: 0,
  },
  uploadText: {
    color: theme.palette.primary.main,
  },
}));
