import { FC, useEffect, useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { SignUpModals } from '../../common/localization/en';

import { SvIconLarge } from '../../common/assets/newDesign/SvIconLarge';
import { TrubluIconLarge } from '../../common/assets/newDesign/TrubluIconLarge';
import { LinearProgress } from '@material-ui/core';
import { RoundedButton } from '../../common/components/Button/RoundedButton';
import { useHistory } from 'react-router-dom';
import { UnprotectedRoutes } from '../../common/constants/routes';
import { useLogout } from '../../services/hooks/useLogout';

export const useStyles = makeStyles((theme: Theme) => ({
  Background: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
  },
  Modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 739,
    borderRadius: '20px',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 18px 30px rgba(64, 77, 104, 0.1)',
    backdropFilter: 'blur(20px)',
    paddingTop: 144,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  Title: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '34px',
    lineHeight: '44px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  description: {
    margin: '141px 0 15px',
    color: 'rgba(234, 16, 45, 0.5)',
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
  },
  errorDescription: {
    width: '290px',
    marginTop: '20px',
    textAlign: 'center',
    fontSize: '20px',
    color: '#475467',
    lineHeight: '30px',
  },
  progress: {
    width: '202px',
    height: '1px !important',
    marginTop: '29px',
  },
  errorButton: {
    margin: '47px 0 85px',
    padding: '24px 30px !important',
    '& p': {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
    },
  },
}));

interface ICongratsModalProps {
  isError: boolean;
  isTrubluClient: boolean;
}

export const CongratsModal: FC<ICongratsModalProps> = ({ isError, isTrubluClient }) => {
  const styles = useStyles();
  const history = useHistory();
  const logout = useLogout();

  useEffect(() => {
    if (isError) {
      logout();
    }
  }, [isError, logout]);

  const handleErrorBtn = () => {
    history.push(UnprotectedRoutes.Login);
  };

  const [isTruBlu, setIsTruBlu] = useState<boolean>(isTrubluClient);
  useEffect(() => {
    setIsTruBlu(isTrubluClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={styles.Modal}>
      <Box display="flex" justifyContent="center">
        {isTruBlu ? <TrubluIconLarge /> : <SvIconLarge />}
      </Box>
      <Typography
        variant="h6"
        component="h2"
        className={styles.Title}
        mt={isError ? '46px' : '66px'}
      >
        {isError ? SignUpModals.SomethingWentWrong : SignUpModals.PreparingAccount}
      </Typography>
      {!isError && <LinearProgress className={styles.progress} />}
      {!isError && (
        <Typography className={styles.description}>{SignUpModals.DontRefresh}</Typography>
      )}
      {isError && (
        <>
          <Typography className={styles.errorDescription}>{SignUpModals.ErrorDescr}</Typography>
          <RoundedButton
            title={SignUpModals.ContinueToDashboard}
            className={styles.errorButton}
            onClick={handleErrorBtn}
          />
        </>
      )}
    </Box>
  );
};
