import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { LearnMoreStrings } from '../../localization/en';

import { Tooltip as TableTooltip, TooltipTitleProps } from '../table/Tooltip';

import { useTooltipStyles } from './LearnMoreTooltip.style';

export const LearnMoreTooltip: FC<TooltipTitleProps> = ({ title, ...rest }) => {
  const styles = useTooltipStyles();

  return (
    <TableTooltip
      padding="20px"
      rounded="18px"
      title={title}
      icon={<Box className={styles.Icon}>{LearnMoreStrings.LearnMore}</Box>}
      {...rest}
    />
  );
};

export const ButtonTooltip: FC<TooltipTitleProps> = ({ title, icon, ...rest }) => {
  return <TableTooltip padding="20px" rounded="18px" title={title} icon={icon} light {...rest} />;
};

interface CustomColorTooltipTitleProps extends TooltipTitleProps {
  onClick: () => void;
}

export const CustomColorTooltip: FC<CustomColorTooltipTitleProps> = ({
  title,
  text,
  onClick,
  ...rest
}) => {
  return (
    <TableTooltip
      padding="20px"
      rounded="18px"
      title={title}
      icon={
        <Button sx={{ fontSize: '11px' }} variant="outlined" onClick={onClick}>
          {text}
        </Button>
      }
      {...rest}
    />
  );
};
