import { FC, useState } from 'react';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';

import { Indicator, IndicatorWrapper } from './DopDownStatusFilter.style';
import { FilterStatuses } from '../../constants/constants';

interface StatusComponentProps {
  status: string;
  small?: boolean;
  handleStatusUpdate: (value: string) => void;
  statuses: Array<FilterStatuses>;
}
export const DropDownStatusFilter: FC<StatusComponentProps> = ({
  status: userStatus,
  small,
  handleStatusUpdate,
  statuses,
}) => {
  const [status, setStatus] = useState<string>(userStatus);
  const handleChange = (e: SelectChangeEvent) => {
    e.stopPropagation();
    if (e.target.value !== status) {
      handleStatusUpdate(e.target.value as string);
      setStatus(e.target.value as string);
    } else {
      setStatus(e.target.value);
    }
  };
  const renderValue = (stat: string) => {
    return (
      <div>
        <Indicator
          className={clsx({
            'active-i': stat === FilterStatuses.active,
            'inactive-i': stat === FilterStatuses.inactive,
            'archived-i': stat === FilterStatuses.archived,
            'all-i': stat === FilterStatuses.all,
          })}
        />
        {stat === FilterStatuses.active && 'Active'}
        {stat === FilterStatuses.inactive && 'Inactive'}
        {stat === FilterStatuses.archived && 'Archived'}
        {stat === FilterStatuses.all && 'All Users'}
      </div>
    );
  };

  return (
    <IndicatorWrapper
      className={clsx({
        'active-i': status === FilterStatuses.active,
        'inactive-i': status === FilterStatuses.inactive,
        'archived-i': status === FilterStatuses.archived,
        'all-i': status === FilterStatuses.all,
        'small-i': small,
      })}
    >
      <FormControl variant="standard" fullWidth>
        <Select
          value={status?.toLowerCase() || ''}
          onChange={handleChange}
          renderValue={(value) => renderValue(status)}
          fullWidth
        >
          {statuses.map((stat, index) => (
            <MenuItem key={index} value={stat}>
              {renderValue(stat)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </IndicatorWrapper>
  );
};
