import React, { useRef, useState } from 'react';
import { VideoContainer } from '../VideoContainer';
import { Box } from '@mui/material';
import { PlayButton } from './PlayButton';

interface RecordedVideoPreviewProps {
  videoSrc: string;
  width?: number;
  height?: number;
}

export const RecordedVideoPreview: React.FC<RecordedVideoPreviewProps> = ({
  videoSrc,
  width = 290,
  height = 514,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [showPlayButton, setShowPlayButton] = useState(false);

  return (
    <VideoContainer height={height} width={width}>
      <video ref={videoRef} src={videoSrc} />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        {!showPlayButton && (
          <PlayButton
            onClick={() => {
              const video = videoRef.current;
              if (!video) return;

              video.controls = true;
              video.play();
              setShowPlayButton(true);
            }}
          />
        )}
      </Box>
    </VideoContainer>
  );
};
