import { theme } from '../../theme';

export const BadgeIconActive = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.42223 6.60477L5.20957 8.24182C5.14919 8.70667 4.98379 9.15168 4.72591 9.54313L3.87673 10.8321C3.55406 11.3219 3.38208 11.8956 3.38208 12.4821C3.38208 14.1386 4.72489 15.4814 6.38132 15.4814H14.6515C16.2901 15.4814 17.6184 14.153 17.6184 12.5145C17.6184 11.9081 17.4326 11.3162 17.086 10.8187L16.2118 9.56365C15.9301 9.15933 15.7499 8.69315 15.6865 8.20451L15.4786 6.60477C15.1504 4.07817 12.9983 2.1875 10.4504 2.1875C7.90261 2.1875 5.75045 4.07817 5.42223 6.60477Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M8.30542 17.426C8.69604 18.2459 9.53237 18.8125 10.501 18.8125C11.4697 18.8125 12.306 18.2459 12.6967 17.426"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="11.5"
        y="1"
        width="8.5"
        height="8.5"
        rx="4.25"
        fill={theme.palette.primary.main}
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};
