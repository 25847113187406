import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
// import { emailMaskRegex } from '../../../services/utilities';

export const useStyles = makeStyles(() => ({
  form: {
    display: 'grid',
    gap: 24,
    gridTemplateColumns: '58px 80px 1fr 1fr 186px 140px',
    marginBottom: 32,
  },
  deleteIcon: {
    color: '#c7c7d9',
  },
}));

export interface ManagerItemProps {
  id: string;
  status: string;
  index: number;
  name: string;
  email: string;
  save: (item: any) => void;
  cancel: () => void;
  sendInvite: () => void;
  statusUpdate: (active: boolean) => void;
  error?: boolean;
  loading?: boolean;
  hideActions?: boolean;
  setNewManager?: (value: { name: string; email: string }) => void;
  setIsValidationError?: (value: boolean) => void;
  lastInvitationSentAt?: Date | null;
}

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string()
      .email('Email is not valid')
      .required('Email is required'),
  });

export const getStatusTitle = (status: string): string => {
  if (status === 'ACTIVE') {
    return 'Active';
  }
  if (status === 'INACTIVE') {
    return 'Inactive';
  }
  if (status === 'PENDING') {
    return 'Pending';
  }

  return '';
};

export const getStatusColor = (status: string): string => {
  if (status === 'ACTIVE') {
    return '#54d2a5';
  }
  if (status === 'INACTIVE') {
    return '#c7c7d9';
  }
  if (status === 'PENDING') {
    return '#ffb515';
  }

  return '';
};
