import { FC } from 'react';

export const MenuIconSettings: FC = () => (
  <svg width="56" height="47" viewBox="0 0 56 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M50 0.5H6C2.96243 0.5 0.5 2.96243 0.5 6V41C0.5 44.0376 2.96243 46.5 6 46.5H50C53.0376 46.5 55.5 44.0376 55.5 41V6C55.5 2.96243 53.0376 0.5 50 0.5Z"
      fill="#8B89A0"
      fill-opacity="0.1"
      stroke="#8B89A0"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.9471 11.9453C32.7649 12.1867 33.5466 12.5118 34.2823 12.9107L34.1988 14.5356C34.1095 16.279 35.4547 17.7646 37.2034 17.8536C37.3112 17.8591 37.4191 17.8591 37.5269 17.8536L39.1566 17.7705C39.5567 18.5039 39.8827 19.2833 40.1249 20.0987L38.9127 21.1893C37.613 22.3592 37.5106 24.358 38.684 25.6538C38.7563 25.7336 38.8326 25.8097 38.9127 25.8818L40.1249 26.9725C39.8827 27.7878 39.5567 28.5672 39.1566 29.3007L37.5269 29.2175C35.7782 29.1285 34.2882 30.4697 34.1988 32.2131C34.1933 32.3206 34.1933 32.4282 34.1988 32.5356L34.2823 34.1605C33.5466 34.5594 32.7649 34.8844 31.9471 35.1259L30.8531 33.9173C29.6798 32.6215 27.675 32.5194 26.3753 33.6893C26.2952 33.7614 26.2189 33.8375 26.1466 33.9173L25.0527 35.1259C24.2349 34.8844 23.4532 34.5594 22.7175 34.1605L22.8009 32.5356C22.8902 30.7921 21.545 29.3066 19.7963 29.2175C19.6886 29.212 19.5806 29.212 19.4729 29.2175L17.8431 29.3007C17.4431 28.5672 17.117 27.7878 16.8749 26.9725L18.087 25.8818C19.3867 24.712 19.4891 22.7132 18.3158 21.4174C18.2435 21.3375 18.1671 21.2614 18.087 21.1893L16.8749 20.0987C17.117 19.2833 17.4431 18.5039 17.8431 17.7705L19.4729 17.8536C21.2216 17.9427 22.7116 16.6015 22.8009 14.858C22.8064 14.7506 22.8064 14.643 22.8009 14.5356L22.7175 12.9107C23.4532 12.5118 24.2349 12.1867 25.0527 11.9453L26.1466 13.1539C27.32 14.4497 29.3248 14.5517 30.6244 13.3819C30.7045 13.3098 30.7809 13.2337 30.8531 13.1539L31.9471 11.9453Z"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.5002 27.7495C30.8348 27.7495 32.7274 25.8626 32.7274 23.5349C32.7274 21.2072 30.8348 19.3203 28.5002 19.3203C26.1655 19.3203 24.2729 21.2072 24.2729 23.5349C24.2729 25.8626 26.1655 27.7495 28.5002 27.7495Z"
      stroke="#8B89A0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
