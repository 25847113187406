import { KPIApiNames, MarketType } from '../../../../api/models/analytics';
import { useTypedSelector } from '../../../../store';
import { AnalyticsBlocks } from '../../../dashboard/AnalyticsSection/AnalyticsBlocks/AnalyticsBlocks';
import { venuesInitialAnalyticsData } from './AnalyticsBlock.helper';
import { useClientType } from '../../../../services/hooks/useClientType';

export const AnalyticsBlock = () => {
  const { isHealthCare, isTopGolfClient } = useClientType();
  const onClick = (kpiName: KPIApiNames, name: string) => {};
  const { analytics, totalItems } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const roundComasToNearestTwoDigitsDicimal = (num: number) => {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  const venueAnalyticsDataValues = [
    totalItems,
    analytics[MarketType.AverageUsers],
    analytics[MarketType.AverageVideos],
    analytics[MarketType.AverageVideoViews],
    analytics[MarketType.AverageEngagements],
    analytics[MarketType.AverageConversionRate],
  ];

  const venueAnalyticsData = venuesInitialAnalyticsData.map((item, index) => {
    return {
      ...item,
      value: roundComasToNearestTwoDigitsDicimal(venueAnalyticsDataValues[index]),
    };
  });

  return (
    <AnalyticsBlocks
      onClick={onClick}
      items={venueAnalyticsData}
      isHealthCare={isHealthCare}
      isTopgolf={isTopGolfClient}
    />
  );
};
