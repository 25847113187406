import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  messageText: {
    fontSize: '15px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.3px',
    color: '#344054',
    marginTop: '30px',
  },
  selectText: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '162%',
    letterSpacing: '0.26px',
    color: '#344054',
    marginTop: '15px',
  },
  buttonsWrapper: {
    display: 'flex',
    marginTop: '12.5px',
  },
  switchButton: {
    width: '169px',
    height: '35px',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '24px',
  },
  messageSentText: {
    marginTop: '12.5px',
    marginBottom: '19px',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '162%',
    color: '#8B89A0',
  },
}));
