import { FC, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import clsx from 'clsx';
import { useTypedSelector } from '../../../store';
import { AdminVenueManagementStrings, TeamSettingsStrings } from '../../localization/en';
import { VenueManagerApiModel } from './../../../api/models/venueManagers';
import { UserRoles, VenueMappingRoles } from '../../constants/constants';
import { useStyles } from './WorkspaceMembersList.style';
import { VideoNotifications } from '../VideoNotifications/VideoNotifications';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../constants/routes';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { AccountEventNames } from '../../constants/events/accountEvents';

interface Props {
  headCellsNames: Array<string>;
  items?: Array<VenueManagerApiModel>;
}

export const WorkspaceMembersList: FC<Props> = ({ headCellsNames, items }) => {
  const history = useHistory();
  const { trackEvent } = useTrackEvent();

  const { id: meId, role } = useTypedSelector((state) => state.me);

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { distributors } = useTypedSelector((state) => state.distributors);
  const { affiliates } = useTypedSelector((state) => state.affiliates);
  const { loggedInManager } = useTypedSelector((state) => state.VenueManagerSlice);

  const [activeItem, setActiveItem] = useState<VenueManagerApiModel | null>(null);
  const isListEmpty = items && items?.length > 0;
  const isSuperAdmin =
    ([UserRoles.admin_role] as string[]).includes(role) ||
    !!distributors.length ||
    !!affiliates.length;

  const canEdit = isSuperAdmin || loggedInManager?.accountMappingRole === 'OWNER';
  const classes = useStyles({ canEdit, isListEmpty });
  //prev condition
  // const editEnable =
  //   loggedInManager?.venueMappingRole === VenueMappingRoles.owner || myRole === UserRoles.admin;

  const handleSelectItem = (item: VenueManagerApiModel) => {
    trackEvent(AccountEventNames.memberRowClicked, {
      memberId: item.id,
    });
    setActiveItem({ ...item });
  };

  const selectRole = (role?: string) => {
    if (role === UserRoles.admin || role === VenueMappingRoles.owner) {
      return TeamSettingsStrings.WorkspaceOwner;
    } else {
      return TeamSettingsStrings.WorkspaceMember;
    }
  };

  const handleEdit = (id: string) => {
    trackEvent(AccountEventNames.memberEditClicked, {
      memberId: id,
    });
    history.push(WebsiteRoutes.WorkspaceMembersEdit.replace(':id', id));
  };

  return (
    <TableContainer className={classes.root}>
      <Table>
        <TableHead>
          <TableRow className={classes.firstRow}>
            {headCellsNames.map((el, i) => {
              const alignCenter = 0 === i ? 'left' : 'center';
              return (
                <TableCell
                  style={{
                    width: el === AdminVenueManagementStrings.CellPhone ? '20%' : '25%',
                    color: !isListEmpty ? '#8B89A0' : '',
                    minHeight: '60px',
                  }}
                  align={alignCenter}
                  key={i}
                >
                  <Typography className={classes.ColumnHeader}>{el}</Typography>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tbody}>
          {items?.length ? (
            items.map((el, i) => {
              const activeIndex = activeItem?.id === el.id;
              const callback = () => {
                handleSelectItem(el);
              };
              const isMyId = meId === el.id;
              return (
                <TableRow
                  key={el.id}
                  onClick={callback}
                  className={clsx(classes.row, {
                    [classes.selectedRow]: activeIndex,
                  })}
                >
                  <TableCell className={classes.Cell} sx={{ justifyContent: 'left  !important' }}>
                    <Typography className={classes.name}>
                      {`${el.firstName} ${el.lastName}` || el.email}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.Cell}>
                    <Typography className={classes.name}>
                      {el.phoneNumber ? el.phoneNumber : ''}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.Cell}>
                    <Typography
                      className={classes.role}
                      sx={{
                        color: el.accountMappingRole === 'OWNER' ? '#175CD3 !important' : '',
                        backgroundColor:
                          el.accountMappingRole === 'OWNER' ? '#EFF8FF !important' : '',
                      }}
                    >
                      {selectRole(el.accountMappingRole).split(' ')[1]}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.Cell}>
                    <VideoNotifications
                      activeManager={el}
                      managers={items}
                      venueId={venueId}
                      isSuperAdmin={isSuperAdmin}
                      isMyId={isMyId}
                    />
                  </TableCell>
                  {canEdit && (
                    <TableCell className={classes.Cell} sx={{ borderRight: 'none !important' }}>
                      <EditIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleEdit(el.id)}
                      ></EditIcon>
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow className={classes.row}>
              <TableCell
                sx={{
                  textAlign: 'center',
                  padding: '24px',
                  pl: '72px',
                  color: '#D1D1D1',
                  fontSize: '16px',
                  lineHeight: '26px',
                  letterSpacing: '0.02em',
                }}
              >
                {TeamSettingsStrings.UseInvite}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
