import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useUserStatusDropDownStyles = makeStyles((theme: Theme) => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
  },
  InviteContainer: {
    flexShrink: 0,
    marginRight: 4,
  },
  InviteSentText: {
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    lineHeight: '26px',
    textAlign: 'center',
  },
  InviteSent: {
    flexShrink: 0,
    padding: '4px 15px',
    width: 'fit-content',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.02em',
    lineHeight: '26px',
    textAlign: 'center',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '30px',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      borderColor: theme.palette.common.gray,
    },
  },
  InviteSentWrapper: {
    flexShrink: 0,
    padding: '0px',
    width: 'fit-content',
    justifyContent: 'center',
    alignContent: 'center',
    borderRadius: '30px',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 0px 0px rgba(10, 25, 107, 0.15)',
  },
  CheckIcon: {
    color: theme.palette.primary.main,
  },
  Select: {
    flexShrink: 0,
    width: '160px',
    height: 'fit-content',
    borderRadius: 30,
    '& svg': {
      position: 'relative',
      left: '-20px',
    },
    '& .MuiOutlinedInput-input': {
      fontSize: 14,
      height: 'min-content',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '5px',
      paddingBottom: '5px',
      paddingLeft: '15px !important',
      paddingRight: '15px !important',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'inherit',
    },
  },
  Active: {
    '&.MuiOutlinedInput-input': {
      color: 'green !important',
    },
  },
  Deactivated: {
    '&.MuiOutlinedInput-input': {
      color: 'grey !important',
    },
  },
  DropDownList: {
    textAlign: 'center',
    marginLeft: '-27px',
    marginTop: '-5px',
    boxShadow: 'none',
    border: '1px solid #DCDBE4',
    color: theme.palette.success.main,
  },
  MenuItem: {
    justifyContent: 'center',
    fontSize: '14px',
    '&.Mui-selected': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export type StatusValues = 'Active' | 'Deactivated';
