import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSidebarBrandLogoStyles = makeStyles((theme: Theme) => ({
  logoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexShrink: 0,
    flexGrow: 0,
    marginBottom: '20px',
    marginTop: '20px',
    overflow: 'hidden',
    '& div > svg': {
      width: 150,
      height: 50,
      flexShrink: 0,
      flexGrow: 0,
    },
    paddingLeft: '4px',
  },
  brandLogoUwm: {
    objectPosition: 'left',
    transition: 'all 0.3s ease-in',
    height: '24px',
  },
  brandLogoSv: {
    transition: 'all 0.3s ease-in',
    overflow: 'hidden',
  },
  trubluLogoWrapper: {
    position: 'relative',
    width: '204px',
    overflow: 'hidden',
  },
  brandLogoTrublu: {
    transition: 'all 0.3s ease-in',
    position: 'absolute',
    height: '112px',
  },
}));
