import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface TileListProps {
  title?: string;
}

export const TileList: React.FC<TileListProps> = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box>
      {title && (
        <Typography fontSize="20px" color={theme.palette.common.deepDark} mb="8px">
          {title}
        </Typography>
      )}
      <Box display="grid" gap="30px" gridTemplateColumns="repeat(4, min-content)" width="100%">
        {children}
      </Box>
    </Box>
  );
};
