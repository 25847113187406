import React, { FC, useState } from 'react';
import { useStyles } from './CampaignSelect.styles';
import { Box, Typography } from '@mui/material';
import { RewardsPageStrings } from '../../../../localization/en';
import ArrowRightSmall from '../../../../assets/ArrowRightSmall';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  addUsersToCampaign,
  getUserActiveCampaign,
} from '../../../../../store/slices/campaignsSlice';
import { UserApiModel } from '../../../../../api/models/users';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { useDetectClickOutside } from 'react-detect-click-outside';

interface Props {
  user: UserApiModel | null;
}

const CampaignSelect: FC<Props> = ({ user }) => {
  const styles = useStyles();

  const [activeCampaign, setActiveCampaign] = useState('');
  const [isCampaignListOpen, setIsCampaignListOpen] = useState(false);

  const { isTrubluClient } = useClientType();

  const { items: campaigns } = useTypedSelector((state) => state.campaigns);
  const dispatch = useAppDispatch();

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsCampaignListOpen(false);
    },
  });

  const handleCampaignListOpen = () => {
    setIsCampaignListOpen(!isCampaignListOpen);
  };

  const handleSetActiveCampaign = async (campaignId: string, campaignName: string) => {
    if (user) {
      setActiveCampaign(campaignName);
      handleCampaignListOpen();
      await dispatch(
        addUsersToCampaign({ campaignId: campaignId, payload: { userIds: [user.id] } }),
      );
      await dispatch(getUserActiveCampaign({ userId: user.id }));
    }
  };

  const activeCampaigns = campaigns
    .filter((campaign) => !campaign.endedAt)
    .sort((a, b) => {
      return new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime();
    });

  return (
    <Box className={styles.container}>
      <Typography className={styles.title}>
        {RewardsPageStrings.ActiveCampaignsAvailable}
      </Typography>
      <Typography className={styles.subTitle}>
        {isTrubluClient ? RewardsPageStrings.CampaignInfoTrublu : RewardsPageStrings.CampaignInfo}
      </Typography>
      <Typography className={styles.addCreator}>
        {isTrubluClient
          ? RewardsPageStrings.AddCreatorToCampaignTrublu
          : RewardsPageStrings.AddCreatorToCampaign}
      </Typography>
      <Box ref={refDetectClickOutside}>
        <div className={styles.select} onClick={handleCampaignListOpen}>
          {activeCampaign ? activeCampaign : RewardsPageStrings.DefaultCampaignSelectOption}
          <Box style={{ transform: isCampaignListOpen ? 'rotate(90deg)' : 'none' }}>
            <ArrowRightSmall stroke="#667085" />
          </Box>
        </div>
        {isCampaignListOpen && (
          <Box className={styles.campaignsWrapper}>
            {activeCampaigns.map((campaign) => (
              <div
                className={styles.campaign}
                onClick={() => handleSetActiveCampaign(campaign.id, campaign.name)}
              >
                {campaign.name}
              </div>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CampaignSelect;
