import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    background: 'rgba(0, 0, 0, 0.5)',
    outline: 'none',
  },
  container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxWidth: '1100px',
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    borderRadius: 12,
    boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
    padding: '24px',
    maxHeight: '99vh',
    overflow: 'auto',
    outline: 'none',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  brandIcon: {
    width: '196px',
    height: 'auto',
    marginTop: '-15px',
  },
  titleWrapper: {
    marginLeft: '16px',
  },
  title: {
    color: '#101828',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    fontFamily: 'Inter',
  },
  subtitleWrapper: {
    position: 'relative',
  },
  subtitle: {
    color: '#475467',
    fontSize: '14px',
    fontWeight: 4600,
    lineHeight: '20px',
    fontFamily: 'Inter',
  },
  closeIcon: {
    position: 'absolute',
    right: '15px',
    top: '15px',
  },
  divider: {
    borderTop: '1px solid #EAECF0',
    width: 'calc(100% - 2px)',
    position: 'absolute',
    left: '0',
    top: '94px',
  },
  contentWrapper: {
    margin: '0 auto',
    width: '100%',
    maxWidth: '1008px',
    marginTop: '35px',
    borderTop: '1px solid #EAECF0',
  },
  bottomWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '15px',
    marginTop: '8px',
  },
  returnButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  returnText: {
    marginLeft: '5px',
    fontWeight: '600',
    fontSize: '14px',
    color: '#101828',
    fontFamily: 'Inter',
  },
}));
