import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.90308 4.40308L15.0972 15.5972M17.4168 10.0002C17.4168 14.3724 13.8724 17.9168 9.50016 17.9168C5.12791 17.9168 1.5835 14.3724 1.5835 10.0002C1.5835 5.62791 5.12791 2.0835 9.50016 2.0835C13.8724 2.0835 17.4168 5.62791 17.4168 10.0002Z"
      stroke="#FC4740"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const ModeratedIcon = memo(SvgComponent);
export default ModeratedIcon;
