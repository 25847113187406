import { EmailIcon } from '../../../../assets/shareAssets/EmailIcon';
import { SMSIcon } from '../../../../assets/shareAssets/SMSIcon';
import { TwitterIcon } from '../../../../assets/shareAssets/TwitterIcon';
import { DownloadIconDv5 } from '../../../SharedVideo/DownloadIconDv5';
import { FacebookIconDv5 } from '../../../SharedVideo/FacebookIconDv5';
import { InstagramIconDv5 } from '../../../SharedVideo/InstagramIconDv5';
import { LinkIconDv5 } from '../../../SharedVideo/LinkIconDv5';
import { TikTokIcon } from '../../../SharedVideo/TikTokIcon';

import { FC, useState } from 'react';
import { SearchOperations } from '../../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../../store';
import { updateNewSearchFilterAttributes } from '../../../../../store/slices/searchFiltersSlice';
import { SearchFilterStrings } from '../../../../localization/en';
import { FilterShareTypeOptions } from '../FilterShareTypeOptions';

enum ShareTypeStrings {
  sms = 'SMS',
  facebook = 'Facebook',
  instagram = 'Instagram',
  email = 'Email',
  twitter = 'Twitter',
  link = 'Link',
  download = 'Download',
  tiktok = 'Tiktok',
  youtube = 'YouTube',
}

export const ShareTypes = [
  {
    id: 'SMS',
    title: ShareTypeStrings.sms,
    icon: <SMSIcon color={'none'} />,
  },
  {
    id: 'FACEBOOK',
    title: ShareTypeStrings.facebook,
    icon: <FacebookIconDv5 />,
  },
  {
    id: 'INSTAGRAM',
    title: ShareTypeStrings.instagram,
    icon: <InstagramIconDv5 />,
  },
  {
    id: 'EMAIL',
    title: ShareTypeStrings.email,
    icon: <EmailIcon color={'none'} />,
  },
  {
    id: 'TWITTER',
    title: ShareTypeStrings.twitter,
    icon: <TwitterIcon color={'none'} />,
  },
  {
    id: 'LINK',
    title: ShareTypeStrings.link,
    icon: <LinkIconDv5 />,
  },
  {
    id: 'DOWNLOAD',
    title: ShareTypeStrings.download,
    icon: <DownloadIconDv5 />,
  },
  {
    id: 'TIKTOK',
    title: ShareTypeStrings.tiktok,
    icon: <TikTokIcon />,
  },
];

interface ShareTypeFilterProps {
  handleRemove: () => void;
}

export const shareTypePropertyName = 'sharePlatform';

export const ShareTypeFilter: FC<ShareTypeFilterProps> = ({ handleRemove }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleClick = (id: string) => {
    dispatch(
      updateNewSearchFilterAttributes({
        name: shareTypePropertyName,
        operation: SearchOperations.EQUALS,
        value: id,
      }),
    );
    setSelectedOption(id);
    setExpanded(false);
  };
  const handleDelete = () => {
    setSelectedOption('');
    handleRemove();
  };
  const status =
    selectedOption && selectedOption.length
      ? ShareTypes.find((item) => item.id === selectedOption)
      : null;
  return (
    <>
      <FilterShareTypeOptions
        title={status?.title || SearchFilterStrings.SelectShareType}
        filterOptions={ShareTypes}
        selectedOptions={[]}
        handleClick={handleClick}
        expanded={expanded}
        handleChange={() => setExpanded(!expanded)}
        handleDelete={handleDelete}
      />
    </>
  );
};
