import { FC } from 'react';
import { useStyles } from './QSNavBar.styles';
import { Box, Button, Typography } from '@mui/material';
import { UnprotectedRoutes, WebsiteRoutes } from '../../constants/routes';
import { useLocation } from 'react-router-dom';
import { QSProgress } from '../../assets/QSProgress';
import { QSNavBarStrings } from '../../localization/en';
import { subtitlesList } from './QSNavBar.helper';
import { QSActionIcon } from '../../assets/QSActionIcon';
import { useQuickStartLaunchPad } from '../../../pages/QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { QuickStartEventNames } from '../../constants/events/quickstartEvents';
import { isUWMClient } from '../../../utils/isUWMClient';
import { useTypedSelector } from '../../../store';

export const QSNavBar: FC = () => {
  const classes = useStyles();
  const pathname = useLocation().pathname;
  const { trackEvent } = useTrackEvent();
  const isUwmClient = isUWMClient();
  const { forceHideQsMarker } = useTypedSelector((state) => state.launchPad);

  const hiddenRoutes = [
    WebsiteRoutes.ProfileAccounts,
    WebsiteRoutes.SocialVerseAddVideos,
    WebsiteRoutes.SocialVerseSettings,
    UnprotectedRoutes.Login,
    WebsiteRoutes.PricingPlans,
    WebsiteRoutes.QuickStart,
    WebsiteRoutes.NewCampaign,
    WebsiteRoutes.EditCampaign,
    UnprotectedRoutes.SignUp,
  ];

  const {
    isStarted,
    tabIndex: launchPadTabIndex,
    moveToNextItem,
    isLaunchpadVisible,
  } = useQuickStartLaunchPad();
  const stepNumber = launchPadTabIndex + 1;
  const isFlowFinished = stepNumber > subtitlesList.length;
  const isLastStep = stepNumber === subtitlesList.length;

  const handleClick = () => {
    trackEvent(QuickStartEventNames.QuickStartNavBarNextClick);
    moveToNextItem({
      skipTracking: true,
    });
  };

  const isHidden =
    hiddenRoutes.some((route) => pathname.includes(route)) ||
    !isStarted ||
    isLaunchpadVisible ||
    forceHideQsMarker;

  const subtitleItem = subtitlesList[launchPadTabIndex];

  if (isFlowFinished || !subtitleItem) {
    return <></>;
  }

  return (
    <Box
      className={classes.container}
      left={isHidden ? '-490px' : '0'}
      sx={{
        background: isUwmClient ? '#005F9E' : '#3B6EED',
      }}
    >
      <Box>
        <Typography className={classes.title} mb={'4px'}>
          {QSNavBarStrings.MasterVideos}
        </Typography>
        <Typography className={classes.subtitle}>
          {subtitleItem.line1}
          {!isFlowFinished && (
            <Typography
              display="inline"
              className={classes.remainingTopics}
              sx={{
                color: isUwmClient ? '#A1CE57' : '#FFB515',
              }}
            >
              {subtitlesList.length - stepNumber}
            </Typography>
          )}
        </Typography>
        <Typography className={classes.subtitle}>{subtitleItem.line2}</Typography>

        <Button
          className={classes.actionButton}
          endIcon={<QSActionIcon color={isUwmClient ? '#A1CE57' : '#FFB515'} />}
          onClick={handleClick}
          sx={{
            color: isUwmClient ? '#A1CE57' : '#FFB515',
          }}
          data-qs-button="true"
        >
          {isLastStep ? QSNavBarStrings.EndYourTour : QSNavBarStrings.KeepLearning}
        </Button>
      </Box>
      <QSProgress stepNumber={stepNumber} fillColor={isUwmClient ? '#A1CE57' : '#FFB515'} />
    </Box>
  );
};
