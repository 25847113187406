import { eventProps, trackEvent as globalTrackEvent } from '../utils/analytics';
import { useTypedSelector } from '../store';

export type IEventTracker = (event: string, props?: Omit<eventProps, 'venueId' | 'action'>) => void;

export const useTrackEvent = () => {
  const { id, name, email } = useTypedSelector((state) => state.me);
  const venueId = useTypedSelector((state) => state.venue.venue.id);

  const trackEvent: IEventTracker = (event, props) => {
    globalTrackEvent({
      action: event,
      location: window.location.href,
      venueId,
      email,
      name,
      userId: id,
      ...props,
    });
  };

  return { trackEvent };
};
