import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { theme } from '../../../theme';
import { DownloadIconSVG } from '../../assets/DownloadIcon';
import { MedalIcon } from '../../assets/newDesign/MedalIcon';
import { SavedFiltersPageStrings } from '../../localization/en';

export type ActiveModal =
  | 'DOWNLOAD_CSV'
  | 'ASSIGN_TO_PLUGIN'
  | 'ASSIGN_BONUS_POINTS'
  | 'DOWNLOAD_CSV_LIMIT'
  | 'ASSIGN_TO_PLUGIN_LIMIT'
  | 'ASSIGN_BONUS_POINTS_LIMIT';

const useStyles = makeStyles((theme: Theme) => ({
  Btn: {
    borderRadius: '25px',
    border: '1px solid black',
    color: theme.palette.common.black,
    '& svg': {
      marginRight: '8px',
    },
  },
}));

interface ActionBarProps {
  setActiveModal: (name: ActiveModal) => void;
  selectedRowsLength: number | undefined;
  isAwardsBonusPointsActive: boolean;
}

export const ActionBar: FC<ActionBarProps> = ({
  setActiveModal,
  selectedRowsLength,
  isAwardsBonusPointsActive,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={'10px'} justifyContent="center" width="100%">
      <Grid item>
        <Button
          onClick={() => {
            if (selectedRowsLength && selectedRowsLength <= 100) {
              setActiveModal('DOWNLOAD_CSV');
            } else {
              setActiveModal('DOWNLOAD_CSV_LIMIT');
            }
          }}
          variant="outlined"
          size="large"
          className={classes.Btn}
          disabled={!selectedRowsLength}
        >
          <span
            style={{
              color: !selectedRowsLength
                ? theme.palette.text.secondary
                : theme.palette.common.black,
              display: 'flex',
            }}
          >
            <DownloadIconSVG />
          </span>
          Download CSV
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            if (selectedRowsLength && selectedRowsLength <= 10) {
              setActiveModal('ASSIGN_TO_PLUGIN');
            } else {
              setActiveModal('ASSIGN_TO_PLUGIN_LIMIT');
            }
          }}
          variant="outlined"
          size="large"
          className={classes.Btn}
          disabled={!selectedRowsLength}
        >
          <span
            style={{
              color: !selectedRowsLength
                ? theme.palette.text.secondary
                : theme.palette.common.black,
              display: 'flex',
            }}
          >
            <CalendarTodayIcon height="12px" width="12px" />
          </span>
          {SavedFiltersPageStrings.AssignToSocialVerse}
        </Button>
      </Grid>
      <Grid item>
        <Button
          onClick={() => {
            if (selectedRowsLength && selectedRowsLength <= 10) {
              setActiveModal('ASSIGN_BONUS_POINTS');
            } else {
              setActiveModal('ASSIGN_BONUS_POINTS_LIMIT');
            }
          }}
          variant="outlined"
          size="large"
          className={classes.Btn}
          disabled={!selectedRowsLength || !isAwardsBonusPointsActive}
        >
          <span
            style={{
              stroke: !selectedRowsLength
                ? theme.palette.text.secondary
                : theme.palette.common.black,
              display: 'flex',
            }}
          >
            <MedalIcon />
          </span>
          Assign Bonus Points
        </Button>
      </Grid>
    </Grid>
  );
};
