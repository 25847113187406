import { FC } from 'react';

type iconProps = {
  color?: string;
};

export const DollarCircleIcon: FC<iconProps> = ({ color = '#1A1538' }) => (
  <svg width="29" height="25" viewBox="0 0 29 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M10.6719 14.8298C10.6719 16.1198 11.6619 17.1598 12.8919 17.1598H15.4019C16.4719 17.1598 17.3419 16.2498 17.3419 15.1298C17.3419 13.9098 16.8119 13.4798 16.0219 13.1998L11.9919 11.7998C11.2019 11.5198 10.6719 11.0898 10.6719 9.86984C10.6719 8.74984 11.5419 7.83984 12.6119 7.83984H15.1219C16.3519 7.83984 17.3419 8.87984 17.3419 10.1698"
        stroke={color || '#1A1538'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 6.5V18.5"
        stroke={color || '#1A1538'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0711 20.0687C17.1678 23.9719 10.8322 23.9719 6.92893 20.0687C3.0257 16.1654 4.57427 9.85098 4.57427 9.85098M4.57427 9.85098L7.77039 13.0471M4.57427 9.85098L1.03873 13.3865M6.92893 5.92654C10.8322 2.02331 17.1396 1.99502 21.0711 5.92654C25.7875 10.6429 24.2106 16.9291 24.2106 16.9291M24.2106 16.9291L27.7462 13.3936M24.2106 16.9291L21.0711 13.7896"
        stroke={color || '#292D32'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
