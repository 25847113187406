import styled from 'styled-components';
import { theme } from '../../theme';
import { TABLET_MAX_WIDTH } from '../../common/constants/constants';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateFilterMobileContainer = styled.div`
  display: flex;
  margin: 24px 24px 0;
  border-radius: 12px;
  background-color: white;
`;

export const WebappSubnavigationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ConversionLabel = styled.div`
  position: absolute;
  top: 24px;
  right: 40px;
  display: inline-flex;
  justify-content: space-between;
  padding: 7px 23px 7px 17px;
  background-color: #fff;
  border: 1px solid;
  border-radius: 8px;
  color: ${theme.palette.primary.dark};
  font-size: 16px;
  font-weight: 400;

  @media (min-width: ${TABLET_MAX_WIDTH}px) {
    min-width: 260px;
  }
`;
