import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { UserApiModel } from '../../../api/models/users';
import { TeamSettingsStrings } from '../../localization/en';
import { emailMaskRegex } from '../../../common/constants/constants';
import { getDisplayName } from '../../../services/utilities';

export interface UserValues {
  name: string | null;
  returnUrl: string | null;
  phoneNumber: string | null;
  email: string | null;
}

export const initialValuesAccountUser: UserValues = {
  name: '',
  returnUrl: '',
  phoneNumber: '',
  email: '',
};

export const validationSchemaAccountUser = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(3, 'password should be min 5 characters long')
      .max(25, 'max length 25 characters')
      .nullable(),
    returnUrl: Yup.string().url('Enter a Valid URL, please include https:// '),
    phoneNumber: Yup.string()
      .min(10, `not a valid phone number`)
      .max(10, 'not a valid phone number')
      .nullable(),
    email: Yup.string()
      .matches(emailMaskRegex, 'Invalid Email')
      .required('Required'),
  });

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(4),
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  avatarRow: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
  },
  avatar: {
    display: 'flex',
    height: '100px',
    width: '100px',
    borderRadius: '10px',
    marginRight: theme.spacing(2),
  },
  Label: {
    fontSize: '14px',
    fontHeight: '170%',
    color: theme.palette.text.secondary,
    textAlign: 'left',
  },
  Value: {
    fontSize: '16px',
    fontHeight: '160%',
    color: theme.palette.text.primary,
    textAlign: 'left',
  },
  InviteSent: {
    color: theme.palette.common.deepDark,
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '26px',
    textAlign: 'center',
  },
  InviteSentWrapper: {
    width: '100%',
    boxShadow: '0px 4px 7px -2px rgba(10, 25, 107, 0.15)',
    borderRadius: '8px',
    justifyContent: 'center',
    alignContent: 'center',
  },
  CheckIcon: {
    color: theme.palette.common.green,
  },
}));
export interface UserFields {
  key: 'name' | 'displayName' | 'email' | 'phoneNumber' | 'returnUrl';
  value: string | null;
  Label: string;
}

export const genererateItems = (userDetails: UserApiModel): UserFields[] => [
  {
    key: 'displayName',
    Label: TeamSettingsStrings.DisplayName,
    value: userDetails.displayName ?? getDisplayName(userDetails.name),
  },
  {
    key: 'name',
    Label: TeamSettingsStrings.FullName,
    value: userDetails.name,
  },
  {
    key: 'email',
    Label: TeamSettingsStrings.FormEmail,
    value: userDetails.email,
  },
  {
    key: 'phoneNumber',
    Label: TeamSettingsStrings.Phone,
    value: userDetails.phoneNumber,
  },
];
