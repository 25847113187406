import { useTheme } from '@mui/material';
import { SVGProps } from 'react';

export const KeywordIcon = (props: SVGProps<SVGSVGElement>) => {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={26} height={25} {...props} fill="none">
      <path
        stroke={props.fill || theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M5.734 16h2.291m4.973-12.038H6.58c-1.778 0-2.667 0-3.346.346a3.175 3.175 0 0 0-1.388 1.388C1.5 6.375 1.5 7.264 1.5 9.042v6.562c0 .984 0 1.477.108 1.88a3.175 3.175 0 0 0 2.246 2.245c.403.109.895.109 1.88.109v2.47c0 .565 0 .848.116.993a.53.53 0 0 0 .413.199c.186 0 .406-.177.846-.53l2.525-2.019c.516-.413.773-.62 1.06-.766a3.25 3.25 0 0 1 .807-.283c.316-.064.645-.064 1.307-.064h2.663c1.778 0 2.667 0 3.346-.347a3.175 3.175 0 0 0 1.388-1.387c.346-.68.346-1.569.346-3.347v-2.5M5.734 12.5h4.5m.46 3.5h4.5m-2.386-3.5H15.1M5.733 8.794h2.292m2.475 0H15"
      />
      <path
        fill={props.fill || theme.palette.primary.main}
        fillRule="evenodd"
        d="m20.42.5-1.748 3.407-3.78.609 2.7 2.715-.589 3.783 3.416-1.73 3.416 1.73-.588-3.783 2.7-2.715-3.78-.61L20.419.5Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
