import { FC, SetStateAction, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import { LinearProgressWithLabel } from './LinearProgress.helper';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

interface LinearProgressBarProps {
  setProgress: (num: SetStateAction<number>) => void;
  progress: number;
}

export const LinearProgressBar: FC<LinearProgressBarProps> = ({ progress, setProgress }) => {
  const classes = useStyles();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 10));
    }, 800);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={progress} />
    </div>
  );
};
