import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useAddToWebsiteModalStyles = makeStyles((theme: Theme) => ({
  titleWrapper: {
    padding: '25px 10px 25px 46px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
  },
  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  socialvName: {
    marginRight: '23px',
  },
  sectionsWrapper: {
    backgroundColor: '#F5F6F8',
  },
  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 14px 25px 30px',
    marginTop: '21px',
    background: '#fff',
    '& .MuiAccordionSummary-root': {
      ' & .MuiTypography-root': {
        fontWeight: '500',
        fontSize: '18px',
        lineHeight: '30px',
        letterSpacing: '0.02em',
      },
    },
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    lineHeight: '28px',
    color: 'black',
    fontWeight: 500,
    fontSize: '18px',
  },
  subtitle: {
    margin: '25px 0',
    color: '#8B89A0',
  },
  code: {
    padding: '20px 26px',
    backgroundColor: '#F5F6F8',
    borderRadius: '12px',
  },
  btnWrap: {
    margin: '10px auto 0',
  },
  btn: {
    fontWeight: 400,
    width: '440px',
    height: '45px',
    background: '#1A1538',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '16px',
    marginTop: '25px',
    marginBottom: '13px',
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
  },
  number: {
    width: 30,
    height: 30,
    marginRight: '14px',
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    borderRadius: '50%',
  },
  Tooltip: {
    width: '16px',
    height: '16px',
    color: '#8B89A0',
    marginLeft: '12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  rightSide: {
    padding: '0 30px 0',
    height: '840px',
    marginTop: '55px',
  },
  accordionItemWrap: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginBottom: '16px',
  },
  numbers: {
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    border: '1px solid #DCDBE4',
    borderRadius: '50%',
  },
  itemTitle: {
    color: '#1A1538',
    fontSize: '14px',
    lineHeight: '22px',
  },
  html: {
    backgroundColor: '#F5F6F8',
    padding: '8px 20px',
    borderRadius: '12px',
    fontSize: '12px',
    lineHeight: '26px',
    fontWeight: 400,
  },
  padLeft: {
    paddingLeft: '20px',
  },
}));
