import { FC } from 'react';

type IRedditIconProps = {
  color?: string;
};

export const RedditIcon: FC<IRedditIconProps> = ({ color }) => (
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 12.1928C28 10.5542 26.6201 9.25301 24.9391 9.25301C24.1111 9.25301 23.3584 9.56627 22.8065 10.0723C20.6989 8.62651 17.8136 7.68675 14.6022 7.56627L16.0072 1.25301L20.5735 2.19277C20.6237 3.30121 21.5771 4.19277 22.7563 4.19277C23.9606 4.19277 24.9391 3.25301 24.9391 2.09639C24.9391 0.93976 23.9606 0 22.7563 0C21.9032 0 21.1505 0.481928 20.7993 1.18072L15.7061 0.14458C15.5556 0.120483 15.405 0.144579 15.3047 0.216868C15.1792 0.289157 15.1039 0.409639 15.0789 0.554218L13.5233 7.59036C10.2616 7.68675 7.32617 8.62651 5.19355 10.0964C4.64158 9.59036 3.88889 9.27711 3.06093 9.27711C1.35484 9.27711 0 10.6024 0 12.2169C0 13.4217 0.752689 14.4337 1.80645 14.8916C1.75627 15.1807 1.73118 15.4699 1.73118 15.7831C1.73118 20.3133 7.22581 24 14 24C20.7742 24 26.2688 20.3373 26.2688 15.7831C26.2688 15.494 26.2437 15.1807 26.1936 14.8916C27.2473 14.4337 28 13.3976 28 12.1928ZM6.97491 14.2892C6.97491 13.1325 7.95341 12.1928 9.15771 12.1928C10.362 12.1928 11.3405 13.1325 11.3405 14.2892C11.3405 15.4458 10.362 16.3855 9.15771 16.3855C7.95341 16.4096 6.97491 15.4458 6.97491 14.2892ZM19.1936 19.8554C17.6882 21.3012 14.828 21.3976 14 21.3976C13.147 21.3976 10.2867 21.2771 8.80645 19.8554C8.58065 19.6386 8.58065 19.3012 8.80645 19.0843C9.03226 18.8675 9.38351 18.8675 9.60932 19.0843C10.5627 20 12.5699 20.3133 14 20.3133C15.4301 20.3133 17.4624 20 18.3907 19.0843C18.6165 18.8675 18.9677 18.8675 19.1936 19.0843C19.3943 19.3012 19.3943 19.6386 19.1936 19.8554ZM18.7921 16.4096C17.5878 16.4096 16.6093 15.4699 16.6093 14.3133C16.6093 13.1566 17.5878 12.2169 18.7921 12.2169C19.9964 12.2169 20.9749 13.1566 20.9749 14.3133C20.9749 15.4458 19.9964 16.4096 18.7921 16.4096Z"
      fill={color || '#8B89A0'}
    />
  </svg>
);
