import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<
  Theme,
  {
    enableSearch: boolean;
    countryListMaxHeight?: number;
    isFocused?: boolean;
    error?: boolean;
    disabled?: boolean;
  }
>((theme) => ({
  root: {},
  field: {},
  label: {
    color: '#8F94B6',
    fontSize: '16px',
    marginBottom: '10px',
  },
  container: {
    '& .flag-dropdown': {
      border: (props) =>
        props.isFocused
          ? `${props.error ? '1px' : '2px'} solid ${
              props.error ? theme.palette.error.main : theme.palette.primary.main
            } !important`
          : '1px solid #E1E5EE',
      borderRadius: '12px !important',
      background: 'white !important',

      '& .selected-flag': {
        paddingLeft: (props) => (props.isFocused ? '7px' : '8px'), // 1px for border
      },

      '& .selected-flag:hover, .selected-flag:focus': {
        border: 'none',
        borderRadius: '12px !important',
        background: theme.palette.primary.light,
        transition: 'background-color 0.2s ease',
      },

      '& .open': {
        border: ``,
        borderRadius: '12px !important',
      },
    },
  },
  inputClassName: {
    display: 'block',
    width: '100%',
    minWidth: '250px',
    height: '46px',
    paddingLeft: (props) => (props.isFocused ? '59px' : '60px'), // 1px for border
    color: ({ disabled }) => (disabled ? theme.palette.text.disabled : '#464c71'),
    borderRadius: '12px',
    border: (props) =>
      props.isFocused ? `2px solid ${theme.palette.primary.main}` : '1px solid #E1E5EE',

    // TODO: improve placeholder styling
    '&::placeholder': {
      color: theme.palette.grey[400],
      fontSize: '12px',
    },
  },
  countryList: {
    borderRadius: '12px !important',
    overflowY: 'auto',
    height: (props) => (props.countryListMaxHeight ? `${props.countryListMaxHeight}px` : 'auto'),
    maxHeight: (props) => (props.countryListMaxHeight ? `${props.countryListMaxHeight}px` : 'auto'),
  },
  error: {
    border: '1px solid #ff6340 !important',
  },
  search: {
    display: (props) => (props.enableSearch ? 'block' : 'none'),
    height: '56px',

    '& .search-emoji': {
      fontSize: '16px',
    },

    '& .search-box': {
      borderRadius: '12px !important',
      height: '36px',
      padding: '0px 12px !important',
      width: '230px',
    },
  },
}));
