import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { theme } from '../../../theme';

export interface AccordionsState {
  firstStage: boolean;
  secondStage: boolean;
  thirdStage: boolean;
}

export const useStyles = makeStyles(() => ({
  contentWrapper: {
    padding: '0 30px',
  },
  input: {},
  group: {
    display: 'flex',
    columnGap: 24,
    marginTop: 32,
    marginBottom: 40,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    flex: 1,
    padding: 12,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    cursor: 'pointer',
    boxShadow: '0 2px 12px -2px rgba(7, 27, 134, 0.08)',
  },
  labelImg: {
    marginBottom: 12,
    width: 40,
    height: 40,
    padding: 8,
    borderRadius: 5,

    '&:first-child': {
      backgroundColor: '#fee6dc',
    },
    '&:last-child': {
      backgroundColor: '#e4d8fc',
    },
  },
  radio: {
    position: 'absolute',
    top: 3,
    right: 3,
  },
  title: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    letterSpacing: 0.1,
  },
  desc: {
    display: 'inline-flex',
    alignItems: 'center',
    flexGrow: 1,
    color: '#8f94b6',
    fontSize: 14,
    letterSpacing: 0.15,
    lineHeight: 1.7,
  },
  bottomControllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    paddingLeft: '260px',
    paddingTop: '20px',
    zIndex: 100,
  },
  actionButton: {
    margin: '5px 2px',
    width: '120px',
  },
  inputLabel: {
    color: theme.palette.text.secondary,
    marginBottom: '10px',
    fontSize: '16px',
  },
  ColumnTitle: {
    color: theme.palette.common.gray,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    padding: '25px 30px 20px',
  },
  HelperText: {
    color: theme.palette.text.secondary,
    fontSize: '15px',
    display: 'inline',
  },
  HelperTextEditable: {
    color: theme.palette.text.primary,
    fontSize: '15px',
    display: 'inline',
  },
  BottomHint: {
    background: 'linear-gradient(0deg, #F9F9FC, #F9F9FC), #FFFFFF',
    height: '50px',
    justifyItems: 'center',
  },
  HintText: {
    color: theme.palette.text.secondary,
    fontSize: '17px',
    textAlign: 'center',
    justifyContent: 'center',
  },
  StageTitle: {
    marginTop: 15,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  StageDescription: {
    textAlign: 'end',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.grey[500],
  },
  EditWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '11px',
    '& p': {
      fontSize: '12px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
    },
  },
  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  wrapper: {
    height: '100vh ',
    backgroundColor: theme.palette.common.white,
  },
  DisableAccordion: {
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },
  },
}));

export const validationSchema = () =>
  Yup.object().shape({
    editable: Yup.string().oneOf(['full', 'limited']),
    metaTitle: Yup.string()
      .max(82, 'Video title character limit: 82 characters')
      .required('Video title is required'),
    videoText: Yup.string()
      .min(5, 'Video text should be min 5 characters')
      .max(100, 'Video text max length 100 characters'),
    ctaBtnText: Yup.string()
      .max(24, 'Button text max length 24 characters')
      .required('Button text is required'),
    ctaBtnLinkUrl: Yup.string()
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([.]{1}[a-z0-9]+)\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim,
        'Enter valid url. E.g. orgName.socialvenu.com',
      )
      .required('required'),
    videoMessage: Yup.string().max(82, 'Video text max length 82 characters'),
  });
