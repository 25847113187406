import React from 'react';
// import {
//   VideoSharedScreenIcon,
//   WelcomeScreenIcon,
// } from '../../common/assets/newDesign/WebAppIcons';

import { WebsiteRoutes } from '../../common/constants/routes';
import { WebAppPageStrings } from '../../common/localization/en';
// import { SignUpFieldsIcon } from '../../common/assets/newDesign/WebAppIcons/SignUpFieldsIcon';

export const webappTabs = [
  {
    text: WebAppPageStrings.BrandingStyle,
    route: WebsiteRoutes.BrandingStyleTab,
    icon: <></>,
    // icon: <WelcomeScreenIcon />,
  },
  // {
  //   text: WebAppPageStrings.SignUpFields,
  //   route: WebsiteRoutes.SignUpTab,
  //   icon: <SignUpFieldsIcon />,
  // },
  {
    text: WebAppPageStrings.VideoBrandingPlayback,
    route: WebsiteRoutes.VideoPlaybackTab,
    icon: <></>,
    // icon: <VideoSharedScreenIcon />,
  },
  // {
  //   text: WebAppPageStrings.TrackingPixel,
  //   route: WebsiteRoutes.TrackingPixelTab,
  //   icon: <TrackingPixelNavIcon />,
  // },
  // icon: <TrackingPixelNavIcon />,
  // VideoSharedScreenIcon
];
