import { FC, SetStateAction, useState } from 'react';
import { FormControl, MenuItem, SelectChangeEvent, Select } from '@mui/material';
import clsx from 'clsx';

import { UserStatuses } from '../../../../../../api/models/users';
import { Indicator, IndicatorDiv, IndicatorWrapper } from './StatusComponent.style';
import { StatusComponentNoEdit } from './StatusComponentNoEdit';
import { ArrowBottomIcon } from '../../../../../assets/newDesign/ArrowBottomIcon';

interface StatusComponentProps {
  status: UserStatuses;
  small?: boolean;
  handleStatusUpdate: (value: UserStatuses) => void;
  statuses: Array<UserStatuses>;
  noEdit?: boolean;
}
export const StatusComponent: FC<StatusComponentProps> = ({
  status: userStatus,
  small,
  handleStatusUpdate,
  statuses,
  noEdit,
}) => {
  const [status, setStatus] = useState<UserStatuses>(userStatus);
  const handleChange = (e: SelectChangeEvent) => {
    if (e.target.value !== status) {
      handleStatusUpdate(e.target.value as UserStatuses);
      setStatus(e.target.value as SetStateAction<UserStatuses>);
    } else {
      setStatus(e.target.value as SetStateAction<UserStatuses>);
    }
  };
  const renderValue = (stat: UserStatuses) => {
    return (
      <IndicatorDiv
        className={clsx({
          'active-i': stat === UserStatuses.active,
          'inactive-i': stat === UserStatuses.inactive,
          'archived-i': stat === UserStatuses.archived,
        })}
      >
        <Indicator
          className={clsx({
            'active-i': stat === UserStatuses.active,
            'inactive-i': stat === UserStatuses.inactive,
            'archived-i': stat === UserStatuses.archived,
          })}
        />
        {stat === UserStatuses.active && 'Active'}
        {stat === UserStatuses.inactive && 'Inactive'}
        {stat === UserStatuses.archived && 'Archived'}
      </IndicatorDiv>
    );
  };
  if (noEdit) {
    return <StatusComponentNoEdit status={userStatus} />;
  }
  return (
    <IndicatorWrapper
      className={clsx({
        'active-i': status === UserStatuses.active,
        'inactive-i': status === UserStatuses.inactive,
        'archived-i': status === UserStatuses.archived,
        'small-i': small,
      })}
    >
      <FormControl variant="standard" fullWidth>
        <Select
          value={status?.toLowerCase() || ''}
          onChange={handleChange}
          renderValue={() => renderValue(status)}
          fullWidth
          IconComponent={ArrowBottomIcon}
        >
          {statuses.map((stat, index) => (
            <MenuItem key={index} value={stat}>
              {renderValue(stat)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </IndicatorWrapper>
  );
};
