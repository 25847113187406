import * as React from 'react';

export const NumberOfStoriesIcon: React.FC = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 7.38272H5.33333V0.3125H0V7.38272ZM0 13.0389H5.33333V8.79677H0V13.0389ZM6.66667 13.0389H12V5.96868H6.66667V13.0389ZM6.66667 0.3125V4.55463H12V0.3125H6.66667Z"
        fill="white"
      />
    </svg>
  );
};
