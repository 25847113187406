import { Box } from '@mui/material';
import { TopBar } from '../../../common/components/topBar/TopBar';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { Beacon } from '../../webappSettings/Beacon/Beacon';
import { WebappSubnavigation } from '../../../common/components/webappSubnavigation/WebappSubnavigation';
import { webappTabs } from '../../../pages/webapp/tabs';
import { SidebarStrings } from '../../../common/localization/en';

export const TrackingPixel = () => {
  const { trackEvent } = useTrackEvent();

  return (
    <Box
      sx={{
        '& >div': {
          '& >div': {
            ':nth-child(2)': {
              maxHeight: '100vh',
            },
          },
        },
      }}
    >
      <TopBar
        sectionTitle={SidebarStrings.WebApp}
        onTabChange={(tab) => {
          trackEvent(WebAppSettingsEvents.NavigationTabChange, { value: tab.text });
        }}
      >
        <WebappSubnavigation items={webappTabs} onRouteClick={() => {}} />
      </TopBar>
      <Beacon />
    </Box>
  );
};
