import styled from 'styled-components';
import { Badge, List } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const SVList = styled(List)`
  display: inherit;
  flex-direction: inherit;
  flex-grow: inherit;
  margin-top: 40px;
  margin-bottom: 72px;
  width: 100%;
`;

export const SVBadge = styled(Badge)`
  margin-right: 23px;

  .MuiBadge-badge {
    background-color: #f59f1e;
  }
`;
