import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {},
  tabsWrapper: {},
  tabs: {
    '& .MuiButtonBase-root': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      marginRight: '6px',
      borderRadius: '10px 10px 0px 0px',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabName: {
    marginRight: '8px',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
  },
  usersCount: {
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '18px',
    padding: '2px 8px',
    borderRadius: '16px',
  },
  contentWrapper: {},
}));
