import { FC, ReactNode, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useCreateFilterModalStyles } from './CreateUserFilterModal.style';
import { SearchFilterStrings } from '../../localization/en';
import {
  headCellsNames,
  tableUserFilterRows,
  tableNumberRows,
  createUserFilterInputsRows,
  IFilterOption,
  stateToAtrributes,
  createUserFilterPreview,
  filtersResultRoutes,
} from './CreateUserFilterModal.helper';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { addSeveralFilterAttributes, setTarget } from '../../../store/slices/searchFiltersSlice';
import {
  SearchFilterApiModel,
  SearchFilterAttributes,
  SearchTargetsType,
} from '../../../api/models/searchFilter';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CreatorsTableEventNames } from '../../constants/events/creatorsTableEvents';

interface ICreateFilterModalProps {
  isCreationModal: boolean;
  toggleCreationModal: () => void;
  children: ReactNode;
  savedFilter?: SearchFilterApiModel | SearchFilterAttributes[];
  target: SearchTargetsType;
  isSocailVerse?: boolean;
}

export const CreateUserFilterModal: FC<ICreateFilterModalProps> = ({
  isCreationModal,
  toggleCreationModal,
  children,
  savedFilter,
  target,
  isSocailVerse,
}) => {
  const styles = useCreateFilterModalStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { createdAt } = useTypedSelector((state) => state.venue.venue);
  const { isHealthCare } = useClientType();

  const { trackEvent } = useTrackEvent();

  const initialUserFilterState: {
    [key: string]: null | IFilterOption;
  } = {
    lastVideoCreatedAt: null,
    videos: {
      option: '',
      from: 0,
      to: 10,
    },
    shares: {
      option: '',
      from: 0,
      to: 10,
    },
    videoViews: {
      option: '',
      from: 0,
      to: 10,
    },
    videoCtaClicks: {
      option: '',
      from: 0,
      to: 10,
    },
    conversions: {
      option: '',
      from: 0,
      to: 10,
    },
    totalPoints: {
      option: '',
      from: 0,
      to: 10,
    },
    status: {
      option: '',
      from: 0,
      to: 0,
    },
  };

  const [filter, setFilter] = useState<typeof initialUserFilterState>({
    ...initialUserFilterState,
  });

  const handleDateUpdate = (filterName: string, value: any) => {
    if (!value) {
      setFilter((prevState) => {
        let newState = _.set(prevState, filterName, null);
        return { ...newState };
      });
    } else {
      setFilter((prevState) => {
        let newState = _.set(prevState, `${filterName}.GREATER_THAN`, value?.from || null);
        newState = _.set(prevState, `${filterName}.LESS_THAN`, value?.to || null);
        return { ...newState };
      });
    }
  };

  const handleOptionUpdate = (filterName: string, value: any) => {
    setFilter((prevState) => {
      const newState = _.set(prevState, `${filterName}.option`, value);
      return { ...newState };
    });
  };

  const handleRangeFiltersUpdate = (
    filterName: string,
    option: string,
    from: number,
    to: number,
  ) => {
    if (Number.isNaN(from)) {
      from = 1;
    }
    if (option === 'Range' && from <= to) {
      setFilter((prevState) => {
        let newState = _.set(prevState, `${filterName}.from`, from);
        newState = _.set(prevState, `${filterName}.to`, to);
        return { ...newState };
      });
    } else if (option === 'Range' && (to.toString() === '' || +to <= 1)) {
      setFilter((prevState) => {
        let newState = _.set(prevState, `${filterName}.from`, from);
        newState = _.set(newState, `${filterName}.to`, to);
        return { ...newState };
      });
    } else {
      setFilter((prevState) => {
        const newState = _.set(prevState, `${filterName}.from`, from);
        return { ...newState };
      });
    }
  };

  const handleReset = () => {
    setFilter(initialUserFilterState);
  };

  const rangeIsOk = () => {
    let canSave = true;
    Object.entries(filter).map(([key, value]) => {
      if (value?.option === 'Range' && value?.from >= value?.to) {
        canSave = false;
      }
      return { key, value };
    });
    return canSave;
  };

  const tableUserFilterRowsWithTrublu = tableUserFilterRows(isHealthCare);

  return (
    <Modal
      open={isCreationModal}
      onClose={() => {
        toggleCreationModal();
        trackEvent(CreatorsTableEventNames.CreateFilterModalClose);
      }}
      style={{
        background: 'rgba(16, 14, 24, 0.8)',
        backdropFilter: 'blur(20px)',
        overflowY: 'auto',
      }}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'transparent !important',
        },
      }}
    >
      <div className={styles.Container}>
        <IconButton
          onClick={() => {
            toggleCreationModal();
            trackEvent(CreatorsTableEventNames.CreateFilterModalClose);
          }}
          className={styles.CloseButton}
        >
          <CloseRoundedIcon />
        </IconButton>
        <div>
          <>
            {children}
            {savedFilter ? (
              <Box display="flex" alignItems="baseline">
                <Typography component="h2" className={styles.Title}>
                  {isHealthCare ? 'Patient Filter' : SearchFilterStrings.UserFilter}
                </Typography>
                <Typography component="h3" className={styles.SubTitle}>
                  {'name' in savedFilter && savedFilter.name}
                </Typography>
              </Box>
            ) : (
              <Typography component="h2" className={styles.Title}>
                {isHealthCare
                  ? SearchFilterStrings.CreateUserfilter_TruBlu
                  : SearchFilterStrings.CreateUserfilter}
              </Typography>
            )}
            <TableContainer className={''}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow className={styles.TableRow}>
                    {headCellsNames.map((el, i) => {
                      let width = 'auto';
                      if (i === 0) width = '70px';
                      if (i === 1) width = '190px';
                      return (
                        <TableCell style={{ width: width }} key={i}>
                          {el}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
              </Table>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableBody className={''}>
                  {tableUserFilterRowsWithTrublu?.length
                    ? tableNumberRows.map((el, i) => {
                        return (
                          <TableRow key={i} className={styles.TableRow}>
                            <TableCell style={{ width: '70px' }}>
                              <Typography className={styles.RowNumber}>{i + 1}</Typography>
                            </TableCell>
                            <TableCell style={{ width: '190px' }}>
                              <Typography className={''}>
                                {tableUserFilterRowsWithTrublu[i]}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ width: 'auto' }}>
                              {!savedFilter
                                ? createUserFilterInputsRows({
                                    filter,
                                    handleDateUpdate,
                                    handleRangeFiltersUpdate,
                                    handleOptionUpdate,
                                  })[i]
                                : createUserFilterPreview({
                                    attributes:
                                      'name' in savedFilter
                                        ? savedFilter.filter.attributes
                                        : savedFilter,
                                  })[i]}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
            <Box className={styles.ButtonsContainer}>
              {savedFilter ? (
                <Box display="flex" justifyContent="center" width="100%">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      trackEvent(CreatorsTableEventNames.SavedFiltersClosePreview);
                      toggleCreationModal();
                    }}
                    className={[styles.Buttons].join(' ')}
                  >
                    {SearchFilterStrings.ClosePreview}
                  </Button>
                </Box>
              ) : (
                <Box display="flex">
                  <Button
                    disabled={!rangeIsOk()}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      trackEvent(CreatorsTableEventNames.CreateFilterModalApplyClick, {
                        value: JSON.stringify(filter),
                      });
                      toggleCreationModal();
                      dispatch(addSeveralFilterAttributes(stateToAtrributes(filter, createdAt)));
                      dispatch(setTarget(target));
                      !isSocailVerse && history.push(`${filtersResultRoutes[target]}/:filterId`);
                      handleReset();
                    }}
                    className={[styles.Buttons].join(' ')}
                  >
                    {SearchFilterStrings.ApplyFilter}
                  </Button>
                  <Box mr={2} />
                  <Button
                    variant="outlined"
                    className={[styles.Buttons].join(' ')}
                    onClick={() => {
                      handleReset();
                      trackEvent(CreatorsTableEventNames.CreateFilterModalResetAllClick);
                    }}
                  >
                    {SearchFilterStrings.ResetAll}
                  </Button>
                </Box>
              )}
            </Box>
          </>
        </div>
      </div>
    </Modal>
  );
};
