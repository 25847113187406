import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import { useHistory, useLocation } from 'react-router';

import {
  LoginFormValues,
  initialValuesLoginForm,
  validationSchemaLoginForm,
} from './LoginWizardHelper';
import { Container, LoginForm, LoginHeader, LoginSubHeader, HelpText } from './Venuewizard.style';
import { VenueCreationWizardStrings, LoginPageStrings } from '../../common/localization/en';
import { useAppDispatch } from '../../store';
import { authRequest } from '../../store/slices/meSlice';
import { UnprotectedRoutes } from '../../common/constants/routes';
import { setCampaignId } from '../../store/slices/venueCreationSlice';

export const LoginWizard = () => {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const compaignId = query.get('campaignId');
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (!compaignId) {
      history.push(UnprotectedRoutes.UnAuthorized);
    } else {
      dispatch(setCampaignId({ id: compaignId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginFormik = useFormik({
    initialValues: initialValuesLoginForm,
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationSchemaLoginForm,
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  const submitForm = (values: LoginFormValues) => {
    // TODO
    // do something with values
    dispatch(authRequest({ username: values.email, password: values.password }));
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { handleSubmit, values, handleChange, errors } = LoginFormik;
  return (
    <Container>
      <LoginForm onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12} md={8}>
            <LoginHeader>{VenueCreationWizardStrings.VenuCreationWizard}</LoginHeader>
          </Grid>
          <Grid item xs={12} md={8}>
            <LoginSubHeader>{VenueCreationWizardStrings.PoweredBySocialVenu}</LoginSubHeader>
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              name="email"
              value={values.email}
              id="email"
              label={LoginPageStrings.Email}
              variant="outlined"
              placeholder={LoginPageStrings.UserNameLabel}
              onChange={handleChange}
              helperText={errors.email && LoginPageStrings.EmailValidationError}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <FormControl variant="outlined" error={errors.password ? true : false} fullWidth>
              <InputLabel htmlFor="password">{LoginPageStrings.Password}</InputLabel>

              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                placeholder={LoginPageStrings.Password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              {errors.password && (
                <FormHelperText id="standard-weight-helper-text">
                  {LoginPageStrings.InvalidPassword}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item xs={4} md={6}>
                <HelpText>{VenueCreationWizardStrings.ContactSupport}</HelpText>
              </Grid>
              <Grid item xs={4} md={2}>
                <Button type="submit" variant="contained" color={'primary'}>
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LoginForm>
    </Container>
  );
};
