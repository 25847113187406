import { DistributorModel } from '../api/models/distributors';
import { useAppDispatch, useTypedSelector } from '../store';
import { getDistributors } from '../store/slices/distributorsSlice';

export const useDistributors = () => {
  const dispatch = useAppDispatch();

  const { isLoading, distributors, activeDistributor } = useTypedSelector(
    (store) => store.distributors,
  );

  const fetchDistributors = async () => {
    const { payload } = await dispatch(getDistributors());
    return payload as DistributorModel[];
  };

  const getDistributorById = (id: string) => {
    return distributors.find((item) => item.id === id);
  };

  return {
    isDistributorsLoading: isLoading,
    // remove test distributor
    distributors,
    fetchDistributors,
    activeDistributor,
    getDistributorById,
  };
};
