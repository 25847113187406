import { FC, useState } from 'react';
import { SearchOperations } from '../../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../../store';
import { updateNewSearchFilterAttributes } from '../../../../../store/slices/searchFiltersSlice';
import { SearchFilterStrings } from '../../../../localization/en';
import { FilterOptionsContainer } from '../FilterOptionsContainer';

enum UserStatusStrings {
  active = 'Active',
  deactivated = 'Deactivated',
}

export const UserStatuses = [
  {
    id: 'ACTIVE',
    title: UserStatusStrings.active,
    icon: <></>,
  },
  {
    id: 'INACTIVE',
    title: UserStatusStrings.deactivated,
    icon: <></>,
  },
];

interface UserStatusFilterProps {
  handleRemove: () => void;
}

export const userStatusPropertyName = 'status';

export const UserStatusFilter: FC<UserStatusFilterProps> = ({ handleRemove }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleClick = (id: string) => {
    dispatch(
      updateNewSearchFilterAttributes({
        name: userStatusPropertyName,
        operation: SearchOperations.EQUALS,
        value: id,
      }),
    );
    setSelectedOption(id);
    setExpanded(false);
  };
  const handleDelete = () => {
    setSelectedOption('');
    handleRemove();
  };
  const status =
    selectedOption && selectedOption.length
      ? UserStatuses.find((item) => item.id === selectedOption)
      : null;
  return (
    <>
      <FilterOptionsContainer
        title={status?.title || SearchFilterStrings.SelectUserStatus}
        filterOptions={UserStatuses}
        selectedOptions={[]}
        handleClick={handleClick}
        expanded={expanded}
        handleChange={() => setExpanded(!expanded)}
        handleDelete={handleDelete}
      />
    </>
  );
};
