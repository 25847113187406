import { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useStyles } from './InviteTables.styles';
import { InviteesTable } from '../../../InviteesTable/InviteesTable';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';
import { InvitesLayoutStrings } from '../../../../../common/localization/en';
import { useClientType } from '../../../../../services/hooks/useClientType';
import { RoundedButton } from '../../../../../common/components/Button/RoundedButton';
import { useHistory } from 'react-router-dom';
import { getApiUrlForId } from '../../../../../api/endpoints';
import { WebsiteRoutes } from '../../../../../common/constants/routes';
import { setOpenAddCustomersModalTrigger } from '../../../../../store/slices/campaignsSlice';
import { isUWMClient } from '../../../../../utils/isUWMClient';

export const InviteTables: FC = () => {
  const classes = useStyles();
  const { isHealthCare } = useClientType();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isUwmClient = isUWMClient();

  const { currentCampaign, invitations, isInvitesLoading } = useTypedSelector(
    (state) => state.campaigns,
  );
  const { trackEvent } = useTrackEvent();

  if (isInvitesLoading) {
    return (
      <Box className={classes.progressWrapper}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {invitations.length > 0 ? (
        <InviteesTable handleTrackEvent={trackEvent} />
      ) : (
        <Box className={classes.noUsersWrapper}>
          <Box
            className={classes.titleWrapper}
            style={{
              background: isUwmClient ? 'rgb(195, 216, 229)' : '#EBE9FF',
            }}
          >
            <Typography className={classes.tracksText}>
              {isHealthCare
                ? InvitesLayoutStrings.TracksProgressTrublu
                : InvitesLayoutStrings.TracksProgress}
            </Typography>
            <Typography className={classes.invitedText}>
              {InvitesLayoutStrings.WhetherInvited}
            </Typography>
          </Box>
          <Typography className={classes.encourageText}>
            {isHealthCare
              ? InvitesLayoutStrings.EncouragePatients
              : InvitesLayoutStrings.EncourageCustomers}
          </Typography>
          <Typography className={classes.invitingText}>
            {isHealthCare
              ? InvitesLayoutStrings.InvitingPatients
              : InvitesLayoutStrings.InvitingCustomers}
          </Typography>
          <RoundedButton
            title={
              isHealthCare ? InvitesLayoutStrings.AddPatients : InvitesLayoutStrings.AddCustomers
            }
            className={classes.addButton}
            onClick={() => {
              history.push(
                getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, currentCampaign?.id!),
              );
              dispatch(setOpenAddCustomersModalTrigger(true));
            }}
          />
        </Box>
      )}
    </Box>
  );
};
