import {
  SearchOperationType,
  VideoFilterAttribute,
} from '../../../../api/models/searchFilterModels';

const getMinutesSeconds = (value: number) => {
  return {
    minutes: Math.floor(value / 60),
    seconds: value % 60,
  };
};

export const getTimeInitinialValues = (attributes: VideoFilterAttribute[]) => {
  const greaterThanAttribute = attributes.find(
    (attribute) => attribute.operation === SearchOperationType.GreaterThan,
  );

  const lessThanAttribute = attributes.find(
    (attribute) => attribute.operation === SearchOperationType.LessThan,
  );

  const greaterThanValues = greaterThanAttribute
    ? getMinutesSeconds(greaterThanAttribute.value as number)
    : {
        minutes: 0,
        seconds: 0,
      };

  const lessThanValues = lessThanAttribute
    ? getMinutesSeconds(lessThanAttribute.value as number)
    : {
        minutes: -1,
        seconds: -1,
      };

  return {
    greaterThanValues,
    lessThanValues,
  };
};
