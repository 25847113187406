import { FC, memo, useEffect } from 'react';

import TransitionWrapper from '../TransitionWrapper/TransitionWrapper';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { fetchUserDetails, getCampaignUser } from '../../../store/slices/userDetailsSlice';
import { DetailsModalContent } from './DetailsModalContent/DetailsModalContent';
import { getVideoSharesById, getVideoById } from '../../../store/slices/videosSlice';
import { ClientTypes } from '../../../api/models/common';

import {
  getTopGolfVideoById,
  getTopGolfVideoSharesById,
} from '../../../store/slices/topGolfVideosSlice';
import { TopGolfDetailsModalContent } from './DetailsModalContent/TopGolfDetailsModalContent';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import { getUserActiveCampaign } from '../../../store/slices/campaignsSlice';

interface NewDetailsModalProps {
  videoId?: string;
  userId: string;
  isOpen: boolean;
  handleClose: () => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  onDeleteVideoClick?: () => void;
  activeTabIndex?: number;
  onUpdateThumbnail?: () => void;
}

export const NewDetailsModal: FC<NewDetailsModalProps> = memo(
  ({
    isOpen,
    handleClose,
    userId,
    videoId,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    handleAddVideoToCard,
    activeTabIndex,
    onUpdateThumbnail,
  }) => {
    const dispatch = useAppDispatch();

    const { clientType } = useTypedSelector((state) => state.venue.venue);
    const { activeCampaign } = useTypedSelector((state) => state.campaigns);
    const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
    const { items: videos, isLoading } = useTypedSelector((state) => state.videos);
    const { items: topGolfVideos, isLoading: isTgLoading } = useTypedSelector(
      (state) => state.TopgGolfVideos,
    );
    const { lastUpdated } = useTypedSelector((state) => state.userDetails);
    let [video] = videos.filter((item) => item.id === videoId);
    let [topGolfVideo] = topGolfVideos.filter((item) => item.id === videoId);

    const { trackEvent } = useTrackEvent();

    useEffect(() => {
      if (userId) {
        dispatch(getUserActiveCampaign({ userId })).then((result) => {
          // @ts-ignore
          dispatch(getCampaignUser({ campaignId: result?.payload?.id, userId }));
        });
      }
    }, [dispatch, userId]);

    useEffect(() => {
      if (videoId) {
        if (isNotTopgolf && !video && !isLoading) {
          dispatch(getVideoById({ id: videoId }));
          dispatch(getVideoSharesById({ id: videoId }));
        } else if (!isNotTopgolf && !topGolfVideo && !isTgLoading) {
          dispatch(getTopGolfVideoById({ id: videoId }));
          dispatch(getTopGolfVideoSharesById({ id: videoId }));
        }
      }
    }, [
      videoId,
      dispatch,
      video,
      isNotTopgolf,
      topGolfVideo,
      topGolfVideos,
      videos,
      isTgLoading,
      isLoading,
    ]);

    useEffect(() => {
      if (videoId) {
        if (isNotTopgolf) {
          dispatch(getVideoSharesById({ id: videoId }));
        } else {
          dispatch(getTopGolfVideoSharesById({ id: videoId }));
        }
      }
    }, [videoId, dispatch, isNotTopgolf]);

    useEffect(() => {
      if (userId) {
        dispatch(fetchUserDetails(userId));
      }
    }, [userId, dispatch, lastUpdated]);

    if (!isNotTopgolf) {
      return (
        <TransitionWrapper show={isOpen}>
          <TopGolfDetailsModalContent
            handleClose={handleClose}
            story={topGolfVideo}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenConfirmationModal={handleOpenConfirmationModal}
          />
        </TransitionWrapper>
      );
    }

    return (
      <TransitionWrapper show={isOpen} isVideoClipSlideout>
        <DetailsModalContent
          activeCampaign={activeCampaign}
          isTopgolf={!isNotTopgolf}
          userId={userId}
          handleClose={() => {
            handleClose();
            trackEvent(VideosEventNames.closeVideoDetailsModal, {
              videoId,
            });
          }}
          story={!isNotTopgolf ? topGolfVideo : video}
          // story={story}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleAddVideoToCard={handleAddVideoToCard}
          activeTabIndex={activeTabIndex}
          onUpdateThumbnail={onUpdateThumbnail}
        />
      </TransitionWrapper>
    );
  },
);
