import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    background: '#EFEFEF',
    padding: '56px 14px 0',
  },
  desktopWrapper: {
    width: '100%',
    borderRadius: '12px',
    background: theme.palette.common.white,
    position: 'relative',
  },
  sectionIcon: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: '-18px',
  },
  mobileWrapper: {
    background: theme.palette.common.white,
    borderRadius: '12px',
    position: 'relative',
    margin: '43px auto 20px',
    paddingTop: '22px',
  },
}));
