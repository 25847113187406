import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MIN_TABLE_HEIGHT } from '../../../../common/constants/constants';

const TABLE_HEIGHT = 'calc(100vh - 220px)';

export const useUsersTablesStyles = makeStyles((theme: Theme) => ({
  UsersTableStyles: {
    paddingTop: '8px',
    '& #common-table-wrapper': {
      height: TABLE_HEIGHT,
      maxHeight: TABLE_HEIGHT,
      minHeight: MIN_TABLE_HEIGHT,
    },
    '& table': {
      borderSpacing: '0 !important',
    },
    '& th': {
      '& > div': {
        padding: '10px !important',
        fontSize: '13px',
      },

      '&:nth-child(1) div': {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '15px',
        minWidth: '220px',
      },
      '&:nth-child(2) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(3) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(4) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(5) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(6) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(7) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '&:nth-child(9) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '15px',
      },
      '&:nth-child(10) div': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: '15px',
      },
    },
    '& tr': {
      height: '48px !important',
      fontSize: '2px !important',
      borderSpacing: 'none !important',
    },
    '& td': {
      fontSize: '13px !important',
      '& span': { fontSize: '13px !important' },
      '& p': { fontSize: '11px !important' },
      '& > div': {
        /*  padding: '5px 0 5px 5px', */
        fontSize: '13px !important',
      },
      '&:nth-child(1)': {
        paddingLeft: '18px !important',
        minWidth: '220px',
      },
      '&:nth-child(2)': { paddingLeft: 0, textAlign: 'center' },
      '&:nth-child(2) div': {
        margin: '0 auto',
      },
      '&:nth-child(3)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(4)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(5)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(6)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(7)': {
        textAlign: 'center',
        paddingLeft: 0,
      },
      '&:nth-child(8)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(8) div': {
        display: 'flex',
        justifyContent: 'center',
      },
      '&:nth-child(8) span': {
        paddingLeft: '0',
        display: 'block',
        margin: '0 auto',
      },
      '&:nth-child(9)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
      '&:nth-child(10)': {
        paddingLeft: '0',
        textAlign: 'center',
      },
    },
  },
  contentWrapper: {
    background: '#fff',
  },
  line: {
    marginTop: '1px',
  },
  searchWrapper: {
    paddingLeft: '19px',
    paddingTop: '10px',
  },
  topBarWrapper: {
    width: '100%',
    position: 'relative',
    zIndex: 20,
  },
  buttonsWrapper: {
    display: 'flex',
    marginRight: '15px',
  },
  addCreatorButton: {
    backgroundColor: theme.palette.common.white,
    color: '#1A1538',
    fontWeight: 400,
    fontSize: '14px',
    border: '0',
    height: '44px',
    position: 'absolute',
    right: 0,

    '& svg': {
      color: theme.palette.grey[500],
    },
  },
}));
