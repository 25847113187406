import { makeStyles } from '@mui/styles';
import { alpha, Theme } from '@mui/material';

const widthVideo = 510;
export const usePlaybackVideoModal = makeStyles((theme: Theme) => ({
  paper: {
    borderRadius: 15,
    background: theme.palette.common.white,
    overflow: 'visible',
    boxShadow: 'none',
  },
  dialogText: {},
  closeModal: {
    color: '#8B89A0',
    position: 'absolute',
    right: '-27px',
    top: '-20px',
    width: 50,
    height: 50,
    borderRadius: '50%',
    background: theme.palette.common.white,
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.1)',
    zIndex: 999999,
    '&:hover': {
      color: theme.palette.common.black,
      background: theme.palette.common.white,
    },
  },
  TopGolfVideoCard: {
    width: '100%',
    height: 'inital',
  },
  VideoCard: {
    maxWidth: widthVideo,
    width: '378px',
    aspectRatio: '9/16',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  boxControlContainer: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  MouseOverIcon: {
    backgroundColor: alpha(theme.palette.common.white, 0.6),
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
}));
