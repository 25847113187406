import { FC, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { useClasses } from '../../../../HeroesDetails/components/VideosBlock.helper';
import { useAppDispatch, useTypedSelector } from '../../../../../../store';
import { getVideos, updateVideoById } from '../../../../../../store/slices/videosSlice';
import { CampaignUserApiModel, UserApiModel } from '../../../../../../api/models/users';
import { CommonStrings } from '../../../../../localization/en';
import { SalesHeroVideoCard } from './SalesHeroVideoCard';
import { ConfirmationModal } from '../../../../../../pages/content/ConfirmationModel/confirmationModel';
import VideoUploader from '../../../../VideoUploader/VideoUploader';

interface SalesVideoBlockProps {
  userData: UserApiModel;
  onFileSelect?: (value: boolean) => void;
  campaignUser?: CampaignUserApiModel | null;
  isCampaignUser?: boolean;
  campaignId?: string;
  entity?: 'heroes' | 'campaigns';
}
export const SalesVideosBlock: FC<SalesVideoBlockProps> = ({
  userData,
  onFileSelect,
  campaignUser,
  isCampaignUser,
  campaignId,
  entity,
}) => {
  const classes = useClasses();
  const dispatch = useAppDispatch();
  const [videoId, setVideoId] = useState('');
  const [videoUpdate] = useTypedSelector((state) =>
    state.videos.items.filter((item) => item.id === videoId),
  );
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const disableFileUploader = currentCampaign && !!currentCampaign.endedAt;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { id, accountId: venueId } = userData;
  const { items, size, page, sort, search, lastUpdated, isLoading, totalItems } = useTypedSelector(
    (state) => state.videos,
  );

  useEffect(() => {
    if (totalItems) {
      dispatch(
        getVideos({
          accountId: venueId,
          userId: id,
          pageable: { page: page, size: totalItems, sort: sort },
          search: search,
        }),
      );
    } else {
      dispatch(
        getVideos({
          accountId: venueId,
          userId: id,
          pageable: { page: page, size: size, sort: sort },
          search: search,
        }),
      );
    }
  }, [dispatch, page, size, sort, id, venueId, search, lastUpdated, totalItems]);

  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setVideoId('');
  };
  const handleOpenConfirmationModal = (id: string) => {
    setIsConfirmationModalOpen(true);
    setVideoId(id);
  };
  const handleConfirm = () => {
    if (videoId) {
      dispatch(updateVideoById({ id: videoId, update: { featured: !videoUpdate.featured } }));
      handleCloseConfirmationModal();
    }
  };

  if (isLoading) {
    return (
      <Box className={classes.root}>
        <Box mb={2} width="100%" />
        <Typography variant="subtitle1">Loading...</Typography>
      </Box>
    );
  }

  const videosCount = isCampaignUser && campaignUser != null ? campaignUser?.videos : totalItems;

  return (
    <Box
      className={classes.root}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
    >
      <Box className={classes.videosTitle} component="span">
        {`${videosCount} ${videosCount === 1 ? 'Clip' : 'Clips'}`}
      </Box>
      <Box sx={{ opacity: disableFileUploader ? 0.5 : 1 }}>
        <VideoUploader
          userId={userData.id}
          onFileSelect={onFileSelect}
          isCampaignUser={isCampaignUser}
          campaignId={campaignId}
          disabled={!!disableFileUploader}
        />
      </Box>
      <Box marginTop={3} className={classes.videosContainer}>
        {items
          .filter((story) =>
            isCampaignUser ? story.campaignId === campaignUser?.campaignId : true,
          )
          .map((video) => (
            <Box display="flex" flexDirection="column" key={video.id} id={video.id}>
              <SalesHeroVideoCard
                video={video}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
                entity={entity}
              />
              <Box mb={1} />
            </Box>
          ))}
      </Box>
      <ConfirmationModal
        handleCloseModal={handleCloseConfirmationModal}
        onConfirm={handleConfirm}
        isModalOpen={isConfirmationModalOpen}
        message={
          videoUpdate && videoUpdate.featured
            ? CommonStrings.RemoveFromWidgetConfirmation
            : CommonStrings.AddToWidgetConfirmation
        }
      />
    </Box>
  );
};
