import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { CampaignActivitiesTypes } from '../../../api/models/campaigns';
import {
  BonusPointsModalDataContent,
  createContentDataTablePointsModalContent,
} from '../../../services/helpers/tableMappers';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTypedSelector } from '../../../store';
import { AssignBonusPoints } from '../../../store/slices/heroesSlice';
import { theme } from '../../../theme';
import { WebsiteRoutes } from '../../constants/routes';
import {
  CommonStrings,
  SavedFiltersHeaderStrings,
  VideoDetailsModalStringsDv5,
} from '../../localization/en';
import { FSModal } from '../modal/Modal';
import { CommonTable } from '../table/CommonTable';
import { ModalForAssignVideoToSV } from '../VideosDetailUserComponent/ModalsVideoDetailuser/ModalForAssignVideoToSV';
import { ICheckedList } from '../VideosDetailUserComponent/VideosDetailUserComponent';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import DownloadIconRounded from '../../assets/DownloadIconRounded';
import { useDownloadCSVTablesStyles } from '../../../pages/filters/Results/DownloadCSVTables.style';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  Btn: {
    borderRadius: '25px',
    width: 'fit-content',
    fontSize: '14px',
  },
}));

const DownloadCSVButton = () => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      size="large"
      color="primary"
      className={classes.Btn}
      startIcon={<DownloadIconRounded />}
    >
      Download CSV
    </Button>
  );
};

interface Header {
  label: string;
  key: string;
}

const DownloadCSV = (props: any) => {
  const history = useHistory();
  const styles = useDownloadCSVTablesStyles();
  const { trackEvent } = useTrackEvent();
  const { open, onClose, headers, content, setSelectedRows, filterName } = props;
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);
  const { addToast } = useToasts();
  const { isTopGolfClient, isHealthCare } = useClientType();

  useEffect(() => {
    if (content && setSelectedRows) {
      setSelectedRows(selectedRows);
    }

    return () => {
      setSelectedRows(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows]);

  const isVideoFilterCSVModal = history.location.pathname === WebsiteRoutes.VideosFilter;
  const isCreatorFilterCSVModal = history.location.pathname === WebsiteRoutes.UsersFilter;
  const isIncentivesFilterCSVModal = history.location.pathname === WebsiteRoutes.IncentivesFilter;

  const middlePart = filterName
    ? filterName.replaceAll(' ', '-')
    : isVideoFilterCSVModal
    ? 'Videos'
    : isCreatorFilterCSVModal
    ? isHealthCare
      ? 'Patients'
      : 'Creators'
    : 'Filter';
  const csvFileName = `SocialV_${middlePart}_${format(new Date(), 'MMM-dd-y')}`;

  return (
    <FSModal onClose={onClose} modalIsOpen={open} width="90%" padding="0">
      <div
        style={{
          overflowY: 'hidden',
        }}
        className={
          isVideoFilterCSVModal
            ? isTopGolfClient
              ? styles.TopGolfVideoFilterTableStyles
              : styles.VideoFilterTableStyles
            : isIncentivesFilterCSVModal
            ? styles.LeaderboardFilterResultsTable
            : styles.UsersFilterTableStyles
        }
      >
        <CommonTable
          content={content}
          page={1}
          totalItems={selectedRows && selectedRows?.length ? selectedRows.length : 0}
          totalPages={1}
          tablePadding="0"
          size={selectedRows && selectedRows?.length ? selectedRows.length : 0}
          onSizeChange={() => {}}
          hasInfinityScroll
        />
      </div>
      <Grid container className={styles.toolbar}>
        <CSVLink
          filename={csvFileName}
          data={selectedRows ?? []}
          headers={headers}
          onClick={() => {
            onClose();
            trackEvent(VideosEventNames.confirmCSVDownloadButtonClicked);
            addToast(SavedFiltersHeaderStrings.DownloadCSVMessage, {
              appearance: 'success',
              autoDismissTimeout: 2500,
            });
          }}
        >
          <DownloadCSVButton />
        </CSVLink>
        <Button
          sx={{ borderRadius: '25px', fontSize: '14px' }}
          onClick={() => {
            onClose();
            trackEvent(VideosEventNames.cancelCSVDownloadButtonClicked);
          }}
          className={styles.Btn}
          variant="text"
          size="large"
        >
          {CommonStrings.Cancel}
        </Button>
      </Grid>
    </FSModal>
  );
};

interface AssignBonusPointsModalProps {
  open: boolean;
  onClose: () => void;
  bonusPoints?: number;
}

const AssignBonusPointsModal: React.FC<AssignBonusPointsModalProps> = ({
  open,
  onClose,
  bonusPoints = 0,
}) => {
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);
  const { isHealthCare } = useClientType();
  const { addToast } = useToasts();

  const contentDataTableContents = createContentDataTablePointsModalContent({
    items: selectedRows ?? [],
    bonusPoints,
    isHealthCare,
  });
  const getIds = (array: BonusPointsModalDataContent[]) => {
    const ArrayIds: string[] = [];
    array.map((item: any) => ArrayIds.push(item.id));

    AssignBonusPoints({ ids: ArrayIds });
    onClose();
  };

  return (
    <FSModal onClose={onClose} modalIsOpen={open} width="478px" padding="0">
      <Box display="flex" flexDirection="column" py="14px" px="25px" gap="10px">
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
          {VideoDetailsModalStringsDv5.AwardBonusPoints}
        </Typography>
        <Box height="360px" overflow="auto">
          <CommonTable
            content={contentDataTableContents}
            page={0}
            totalItems={selectedRows?.length ?? 0}
            totalPages={1}
            tablePadding="0"
            size={selectedRows?.length ?? 0}
            onSizeChange={() => {}}
            showTopDivider={false}
            disableFullHeight
          />
        </Box>

        <Box mt="10px" mb="15px">
          <Divider />
        </Box>

        <Grid container sx={{ paddingLeft: '20px' }}>
          <Button
            sx={{ borderRadius: '25px' }}
            onClick={() => {
              getIds(contentDataTableContents.rows);
              onClose();
              addToast(SavedFiltersHeaderStrings.AssignedPointsMessage, {
                appearance: 'success',
                autoDismissTimeout: 2500,
              });
            }}
            variant="contained"
            size="large"
            color="primary"
          >
            <Typography px="8px" fontSize="12px" color={theme.palette.common.white}>
              {VideoDetailsModalStringsDv5.AwardBonusPoints}
            </Typography>
          </Button>

          <Button onClick={onClose} variant="text">
            <Typography fontSize="12px" color={theme.palette.common.gray}>
              {CommonStrings.Cancel}
            </Typography>
          </Button>
        </Grid>
      </Box>
    </FSModal>
  );
};

export const activeModalLiteral = {
  DOWNLOAD_CSV: (
    open: boolean,
    onClose: () => void,
    headers: Header[],
    filterName?: string,
    content?: any,
    setSelectedRows?: (selected: any[]) => void,
  ) => (
    <DownloadCSV
      open={open}
      onClose={onClose}
      headers={headers}
      content={content}
      setSelectedRows={setSelectedRows}
      filterName={filterName}
    />
  ),
  ASSIGN_BONUS_POINTS: (
    open: boolean,
    onClose: () => void,
    selectedRows: any[],
    bonusPoints: number = 0,
  ) => {
    const {
      venueAttributes: { properties: venueProperties },
    } = useTypedSelector((state) => state.venue);
    const defaultIncentiveConfig = venueProperties?.['webapp.incentive.config'];
    const bonusPointsInStore = defaultIncentiveConfig?.activities.find(
      (activity) => activity.activityType === CampaignActivitiesTypes.BonusPoints,
    );

    return (
      <AssignBonusPointsModal
        open={open}
        onClose={onClose}
        bonusPoints={bonusPoints || bonusPointsInStore?.points}
      />
    );
  } /* {
    const contentDataTableContents = createContentDataTablePointsModalContent({
      items: selectedRows,
      bonusPoints,
    });
    const getIds = (array: BonusPointsModalDataContent[]) => {
      const ArrayIds: string[] = [];
      array.map((item: any) => ArrayIds.push(item.id));

      AssignBonusPoints({ ids: ArrayIds });
      onClose();
    };
    return (
      <FSModal onClose={onClose} modalIsOpen={open} width="700px">
        <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
          {VideoDetailsModalStringsDv5.AwardBonusPoints}
        </Typography>
        <Box>
          <CommonTable
            content={contentDataTableContents}
            page={0}
            totalItems={selectedRows.length}
            totalPages={1}
            tablePadding="0"
            size={selectedRows.length}
            onSizeChange={() => {}}
          />
        </Box>
        <Grid container sx={{ paddingLeft: '20px' }}>
          <Button
            sx={{ borderRadius: '25px' }}
            onClick={() => {
              getIds(contentDataTableContents.rows);
            }}
            variant="contained"
            size="large"
            color="primary"
          >
            {VideoDetailsModalStringsDv5.AwardBonusPoints}
          </Button>

          <Button sx={{ borderRadius: '25px' }} onClick={onClose} variant="text" size="large">
            {CommonStrings.Cancel}
          </Button>
        </Grid>
      </FSModal>
    );
  } */,
  ASSIGN_TO_PLUGIN: (
    open: boolean,
    onClose: () => void,
    checkedList: ICheckedList[],
    handleConfirmAddingVideos: (data: ICheckedList[]) => void,
    handleChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void,
  ) => (
    <ModalForAssignVideoToSV
      isDisabled={false}
      listSocialVerse={checkedList}
      isOpenModal={open}
      toggleModal={onClose}
      handleConfirm={handleConfirmAddingVideos}
      handleChange={handleChangeCheckbox}
      handleCancel={onClose}
      type="radio"
    />
  ),
  DOWNLOAD_CSV_LIMIT: (open: boolean, onClose: () => void) => {
    return (
      <FSModal onClose={onClose} modalIsOpen={open} width="500px">
        <Typography sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 500 }}>
          100 items Limit
        </Typography>
        <Typography sx={{ marginTop: '24px', marginBottom: '26px', fontSize: '12px' }}>
          Please select up to 100 items to download to CSV file
        </Typography>

        <Button
          color={'primary'}
          variant="contained"
          size="large"
          sx={{ borderRadius: '25px', fontSize: '12px', width: 'fit-content' }}
          onClick={onClose}
        >
          I Understand
        </Button>
      </FSModal>
    );
  },
  ASSIGN_BONUS_POINTS_LIMIT: (open: boolean, onClose: () => void) => {
    const { trackEvent } = useTrackEvent();

    return (
      <FSModal onClose={onClose} modalIsOpen={open} width="400px" rounded="15px">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="8px"
          pt="15px"
          px="15px"
        >
          <Typography fontSize="20px" lineHeight="34px" fontWeight={500}>
            10 Item Limit
          </Typography>
          <Typography
            mb="26px"
            fontSize="16px"
            lineHeight="28px"
            width="260px"
            color={theme.palette.common.gray}
            textAlign="center"
          >
            Select up to 10 items to award bonus points to.
          </Typography>
          <Button
            color="primary"
            variant="contained"
            sx={{
              borderRadius: '25px',
              fontSize: '14px',
              width: '240px',
              height: '45px',
              fontWeight: 400,
            }}
            onClick={() => {
              onClose();
              trackEvent(VideosEventNames.gotItButtonClicked);
            }}
          >
            I got it
          </Button>
        </Box>
      </FSModal>
    );
  },
  ASSIGN_TO_PLUGIN_LIMIT: (open: boolean, onClose: () => void) => (
    <FSModal onClose={onClose} modalIsOpen={open} width="500px">
      <Typography sx={{ fontSize: '20px', lineHeight: '24px', fontWeight: 500 }}>
        10 items Limit
      </Typography>
      <Typography sx={{ marginTop: '24px', marginBottom: '26px', fontSize: '12px' }}>
        Please select up to 10 items to assign to a SocialVerse Plugin.
      </Typography>
      <Button
        color={'primary'}
        variant="contained"
        size="large"
        sx={{ borderRadius: '25px', fontSize: '12px', width: 'fit-content' }}
        onClick={onClose}
      >
        I Understand
      </Button>
    </FSModal>
  ),
};
