import { FC } from 'react';
import clsx from 'clsx';
import { useUserStatusMarkerStyles } from './UserStatusMarker.style';

export type StatusValues = 'ARCHIVED' | 'INACTIVE' | 'ACTIVE';

interface IUserStatusProps {
  status: StatusValues;
}

export const UserStatusMarker: FC<IUserStatusProps> = ({ status }) => {
  const classes = useUserStatusMarkerStyles();
  return (
    <div className={clsx(classes.statusContainer, [classes[status as StatusValues]])}>
      {status && (
        <span className={classes.statusText}>
          {status === 'ARCHIVED' || status === 'INACTIVE' ? 'Deactivated' : status.toLowerCase()}
        </span>
      )}
    </div>
  );
};
