import { Box, Typography } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { Check } from '@mui/icons-material';

interface FakeRecaptchaProps extends BoxProps {}

export const FakeRecaptcha: React.FC<FakeRecaptchaProps> = ({ ...boxProps }) => {
  return (
    <Box
      bgcolor="#fff"
      border="0.5px solid #D3D3D3"
      borderRadius="4px"
      display="flex"
      alignItems="center"
      margin="0 auto"
      {...boxProps}
    >
      <Box
        sx={{
          width: '20px',
          height: '20px',
          background: '#fff',
          borderRadius: '5px',
          ml: '10px',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Check sx={{ fill: '#67CE67', width: '15px', height: '15px' }} />
      </Box>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '9px',
          letterSpacing: '-0.04em',
          color: '#A6A6A6',
          margin: '0 auto',
        }}
      >
        I’m not a robot
      </Typography>
      <Box
        component="img"
        src="/Recaptcha.png"
        alt="Fake Recaptcha"
        sx={{ width: '30px', height: '30px', ml: '7px' }}
      />
    </Box>
  );
};
