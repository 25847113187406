import { FC, SyntheticEvent } from 'react';
import { useStyles } from './TabsSelect.styles';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SmsMessageStatus } from '../../../../api/models/smsMessages';

export interface TabItem {
  tabName: string;
  count?: number;
  messageStatus?: SmsMessageStatus;
}

interface Props {
  tabsList: TabItem[];
  onTabChange?: (i: number) => void;
  campaignVideos?: number;
  tabIndex: number;
  setTabIndex: (value: number) => void;
}

export const TabsSelect: FC<Props> = ({
  tabsList,
  onTabChange,
  tabIndex,
  setTabIndex,
  campaignVideos,
}) => {
  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    onTabChange && onTabChange(newValue);
  };

  const classes = useStyles();

  const theme = useTheme();

  return (
    <Box className={classes.container}>
      <Box className={classes.tabsWrapper}>
        <Tabs value={tabIndex} onChange={handleTabChange} className={classes.tabs}>
          {tabsList.map((tab, index) => {
            const isActive = tabIndex === index;
            return (
              <Tab
                key={index}
                style={{
                  backgroundColor: isActive ? '#FFFFFF' : '#F9FAFB',
                  borderTop: isActive ? `1px solid ${theme.palette.primary.main}` : 'none',
                  boxShadow: isActive ? 'none' : '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  textTransform: 'none',
                  width: '206px',
                  height: '44px',
                }}
                label={
                  <>
                    <Typography
                      className={classes.tabName}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                      }}
                    >
                      {tab.tabName}
                    </Typography>
                    <Typography
                      className={classes.usersCount}
                      style={{
                        color: isActive ? theme.palette.primary.main : '#667085',
                        backgroundColor: isActive ? '#F9F5FF' : '#F2F4F7',
                      }}
                    >
                      {tab.count}
                    </Typography>
                  </>
                }
                id={`${tab.tabName.split(' ').join('-')}-tab`}
              />
            );
          })}
        </Tabs>
      </Box>
    </Box>
  );
};
