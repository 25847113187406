import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { httpClient } from '../../services/httpClient/httpClient';
import { GbpEndpoints, getApiUrlForId } from '../../api/endpoints';

export interface GbpAccount {
  accountName: string;
  name: string;
  type: string;
  verificationState: string;
  vettedState: string;
}

interface InitialState {
  authUrl: string;
  isLoading: boolean;
  isConfirmLocationError: boolean;
  error: boolean;
  connectedAccount: ConnectedAccount | null;
  gbpAccounts: Array<GbpAccount>;
  gbpLocations: Array<GbpLocation>;
  confirmedLocation: ConfirmedLocationResult | null;
}

const initialState: InitialState = {
  authUrl: '',
  isLoading: false,
  error: false,
  isConfirmLocationError: false,
  gbpAccounts: [],
  gbpLocations: [],
  confirmedLocation: null,
  connectedAccount: null,
};

interface GbpAuthorizeUrlParams {
  accountId: string;
  campaignId?: string;
}

export const getGbpAuthorizeUrl = createAsyncThunk(
  'gbp/authorize',
  async (_options: GbpAuthorizeUrlParams, { rejectWithValue }) => {
    try {
      return await httpClient.post<GbpAuthorizeUrlParams, { url: string }>({
        url: GbpEndpoints.GetAuthorizeUrl,
        requiresToken: true,
        params: _options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GoogleOauthParams {
  code: string;
  state: string;
}

export const googleOauth2 = createAsyncThunk(
  'gbp/oauth2',
  async (_options: GoogleOauthParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<GoogleOauthParams, string>({
        url: GbpEndpoints.Oauth2,
        requiresToken: true,
        params: _options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GbpAccountsParams {
  accountId: string;
}

export const getGbpAccounts = createAsyncThunk(
  'gbp/accounts',
  async (_options: GbpAccountsParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<GbpAccountsParams, Array<GbpAccount>>({
        url: GbpEndpoints.Accounts,
        requiresToken: true,
        params: _options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GbpLocationsParams {
  gbpAccountsId: string[];
  accountId: string;
}

interface LocationAddress {
  addressLines: string[];
  administrativeArea: string;
  postalCode: string;
  regionCode: string;
  locality: string;
}

export interface GbpLocation {
  name: string;
  accountId: string;
  phoneNumbers: { primaryPhone: string };
  storeCode: string;
  storefrontAddress: LocationAddress;
  title: string;
  websiteUri: string;
}

export const getGbpLocations = createAsyncThunk(
  'gbp/locations',
  async ({ accountId, gbpAccountsId }: GbpLocationsParams, { rejectWithValue }) => {
    try {
      const result = [];

      for (const gbpAccountId of gbpAccountsId) {
        const locations = await httpClient.get<{ accountId: string }, Array<GbpLocation>>({
          url: getApiUrlForId(GbpEndpoints.Locations, gbpAccountId),
          requiresToken: true,
          params: { accountId },
        });

        result.push(
          ...locations.map((loc) => ({
            ...loc,
            accountId: gbpAccountId,
          })),
        );
      }
      return result;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface ConfirmLocationParams {
  gbpAccountId: string;
  locationId: string;
  accountId: string;
}

interface ConfirmedLocationResult {
  id: string;
  accountId: string;
  gbpAccountId: string;
  locationId: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  lastModifiedBy: string;
}

export const confirmLocation = createAsyncThunk(
  'gbp/confirmLocation',
  async ({ accountId, gbpAccountId, locationId }: ConfirmLocationParams, { rejectWithValue }) => {
    try {
      return httpClient.post<Omit<ConfirmLocationParams, 'accountId'>, ConfirmedLocationResult>({
        url: GbpEndpoints.ConfirmLocation,
        requiresToken: true,
        payload: { gbpAccountId, locationId },
        params: {
          //@ts-ignore
          accountId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.message);
    }
  },
);

interface GetAccountParams {
  accountId: string;
}

export interface ConnectedAccount {
  id: string;
  accountId: string;
  accountName: string;
  gbpAccountId: string;
  locationId: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  lastModifiedBy: string;
  websiteUri: string;
  title: string;
  storefrontAddress: LocationAddress;
}

interface GetAccountParams {
  accountId: string;
}

export const getConnectedAccount = createAsyncThunk(
  'gbp/getConnectedAccount',
  async ({ accountId: venueId }: GetAccountParams, { rejectWithValue }) => {
    try {
      return httpClient.get<GetAccountParams, ConnectedAccount>({
        url: GbpEndpoints.ConnectedAccount,
        requiresToken: true,
        params: {
          accountId: venueId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface DisconnectAccountParams {
  accountId: string;
}

export const disconnectAccount = createAsyncThunk(
  'gbp/disconnectAccount',
  async ({ accountId }: DisconnectAccountParams, { rejectWithValue }) => {
    try {
      return httpClient.post<DisconnectAccountParams, { success: string }>({
        url: GbpEndpoints.DisconnectAccount,
        requiresToken: true,
        params: {
          accountId,
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const gbpSlice = createSlice({
  name: 'gbp',
  initialState,
  reducers: {
    clearGbpAuthUrl: (state) => {
      state.authUrl = '';
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getGbpAuthorizeUrl.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getGbpAuthorizeUrl.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(
      getGbpAuthorizeUrl.fulfilled,
      (state, action: PayloadAction<{ url: string }>) => {
        state.isLoading = false;
        state.error = false;
        state.authUrl = action.payload.url;
      },
    );
    reducersBuilder.addCase(googleOauth2.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(googleOauth2.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(googleOauth2.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = false;
      localStorage.setItem('gbpAuthToken', action.payload);
    });
    reducersBuilder.addCase(getGbpAccounts.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getGbpAccounts.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(
      getGbpAccounts.fulfilled,
      (state, action: PayloadAction<Array<GbpAccount>>) => {
        state.isLoading = false;
        state.error = false;
        state.gbpAccounts = action.payload;
      },
    );

    reducersBuilder.addCase(getGbpLocations.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getGbpLocations.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(
      getGbpLocations.fulfilled,
      (state, action: PayloadAction<Array<GbpLocation>>) => {
        state.isLoading = false;
        state.error = false;
        state.gbpLocations = action.payload;
      },
    );

    reducersBuilder.addCase(confirmLocation.pending, (state) => {
      state.isLoading = true;
      state.isConfirmLocationError = false;
    });
    reducersBuilder.addCase(confirmLocation.rejected, (state, error) => {
      state.isLoading = false;
      state.isConfirmLocationError = true;
    });
    reducersBuilder.addCase(
      confirmLocation.fulfilled,
      (state, action: PayloadAction<ConfirmedLocationResult>) => {
        state.isLoading = false;
        state.isConfirmLocationError = false;
        state.confirmedLocation = action.payload;
      },
    );

    reducersBuilder.addCase(getConnectedAccount.pending, (state) => {
      state.isLoading = true;
      state.error = false;
      state.isConfirmLocationError = false;
    });
    reducersBuilder.addCase(getConnectedAccount.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
      state.connectedAccount = null;
    });
    reducersBuilder.addCase(
      getConnectedAccount.fulfilled,
      (state, action: PayloadAction<ConnectedAccount>) => {
        state.isLoading = false;
        state.error = false;
        state.connectedAccount = action.payload;
      },
    );

    reducersBuilder.addCase(disconnectAccount.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(disconnectAccount.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(
      disconnectAccount.fulfilled,
      (state, action: PayloadAction<{ success: string }>) => {
        state.isLoading = false;
        state.error = false;
        state.connectedAccount = null;
      },
    );
  },
});

export const { clearGbpAuthUrl } = gbpSlice.actions;
export default gbpSlice.reducer;
