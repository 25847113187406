import React, { FC } from 'react';
import { FSModal } from '../../../../../../common/components/modal/Modal';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { useStyles } from './LargeFileModal.styles';
import { CampaignSummaryIntroVideoStrings } from '../../../../../../common/localization/en';
import { CloseIcon } from '../../../../../../common/assets/CloseIcon';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const LargeFileModal: FC<Props> = ({ isOpen, onClose }) => {
  const classes = useStyles();

  return (
    <FSModal onClose={onClose} modalIsOpen={isOpen} rounded="12px" blur width="560px" padding="0">
      <Box className={classes.container}>
        <Box className={classes.topWrapper}>
          <Typography className={classes.title}>
            {CampaignSummaryIntroVideoStrings.FileTooLarge}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography className={classes.text}>
          {CampaignSummaryIntroVideoStrings.PleaseUpload}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          {CampaignSummaryIntroVideoStrings.Okay}
        </Button>
      </Box>
    </FSModal>
  );
};

export default LargeFileModal;
