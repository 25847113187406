import { FC } from 'react';
import { Link } from 'react-router-dom';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

import { WebsiteRoutes } from '../../../constants/routes';
import { Container, UserInfo, UserName } from './UserLogoSection.style';

interface UserLogoSectionProps {
  collapsed: boolean;
  isSuperAdmin: boolean;
  imageUrl?: string;
  firstName: string;
  email: string;
}

export const UserLogoSection: FC<UserLogoSectionProps> = ({
  firstName,
  collapsed,
  isSuperAdmin,
}) => {
  const UserInfoComponent = (
    <UserInfo>
      <EmojiEmotionsIcon />
      <UserName>{firstName}</UserName>
    </UserInfo>
  );

  if (collapsed) {
    return (
      <Container collapsed={collapsed}>
        {isSuperAdmin ? (
          <EmojiEmotionsIcon />
        ) : (
          <Link to={WebsiteRoutes.Profile} style={{ color: 'inherit' }}>
            <EmojiEmotionsIcon />
          </Link>
        )}
      </Container>
    );
  }

  return (
    <Container collapsed={collapsed}>
      {isSuperAdmin ? (
        UserInfoComponent
      ) : (
        <Link to={WebsiteRoutes.Profile} style={{ color: 'inherit' }}>
          {UserInfoComponent}
        </Link>
      )}
    </Container>
  );
};
