import { makeStyles } from '@mui/styles';
import { theme } from '../../../theme';

export const useVideoBrandingPlaybackStyles = makeStyles(() => ({
  leftSideDescription: {
    color: theme.palette.common.gray,
    paddingTop: '0px',
    paddingBottom: '14px',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.02em',
  },
  MainContainer: {
    backgroundColor: '#fff',
    marginBottom: '-60px',
    height: 'calc(100vh - 170px)',
  },
  bottomControllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    paddingLeft: '260px',
    paddingTop: '20px',
    zIndex: 100,
  },
  ColumnTitle: {
    color: theme.palette.common.gray,
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
    padding: '25px 30px 20px',
  },
  StageTitle: {
    marginTop: 15,
    paddingBottom: 10,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  StageDescription: {
    textAlign: 'end',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.grey[500],
  },
  EditWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '11px',
    '& p': {
      fontSize: '12px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
    },
  },
  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  DisableAccordion: {
    '& svg': {
      cursor: 'pointer',
    },
    '& .MuiAccordionSummary-expandIconWrapper > svg': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      cursor: 'default !important',
    },
  },
  DisableColoration: {
    color: 'inherit',
    '&:visited': {
      color: 'inherit',
    },
    '&:hover': {
      color: 'inherit',
    },
    '&:active': {
      color: 'inherit',
    },
  },
  DownloadButtonWrapper: {
    marginBottom: '30px',
    stroke: 'white',
  },

  NotesWrapper: {
    marginBottom: '10px',
  },
  NotesTitle: {
    color: theme.palette.primary.main,
    fontSize: '14px',
  },
  NotesText: {
    color: theme.palette.common.black,
    fontSize: '14px',
  },
  imageWrapperForTopGolf: {
    maxWidth: '100px !important',
    height: '100px !important',
  },
  imageWrapper: {
    width: '70px !important',
    height: '100px !important',
  },
  imgChanger: {
    display: 'flex',
  },
  textWrapper: {
    paddingLeft: 25,
  },
  text: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: theme.palette.common.gray,
  },
  changeLogoButton: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.57,
    letterSpacing: 0.1,
    borderRadius: '40px',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
  },
  SectionWrapper: {
    paddingBottom: '20px',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  Title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  SummaryWithTooltip: {
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 0',
    marginBottom: '10px',
  },
  Accordion: {
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  AccordionSummary: {
    display: 'flex',
    dlexDirection: 'row',
    paddingLeft: 0,
    paddingRight: 0,
  },
  Tooltip: {
    width: '14px',
    height: '14px',
    color: '#8B89A0',
    marginLeft: '12px',
    borderRadius: '50%',
    border: '1px solid',
    borderColor: '#8B89A0',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  new: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '50%',
      backgroundColor: 'black',
      // height: '100px',
      height: '100vh',
      width: '2px',
    },
  },
  PageTitle: {
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    marginBottom: '2px',
    color: theme.palette.common.black,
  },
  PageSubtitle: {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '28px',
    color: theme.palette.common.gray,
  },
}));
