import { FC } from 'react';
import { Box, IconButton } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { theme } from '../../../../../theme';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { UserStatuses } from '../../../../../api/models/users';
import { StatusComponent } from '../salesComponents/StatusComponent/StatusComponent';
import { updateUserById } from '../../../../../store/slices/userDetailsSlice';
import { ClientTypes } from '../../../../../api/models/common';
import { SettingsTeamDetails } from '../../../../localization/en';

const statusMappingChange = {
  ACTIVE: ['ACTIVE', 'ARCHIVED'] as Array<UserStatuses>,
  INACTIVE: ['INACTIVE', 'ARCHIVED'] as Array<UserStatuses>,
  ARCHIVED: ['ARCHIVED', 'INACTIVE'] as Array<UserStatuses>,
};

interface SalesHeaderProps {
  onClose: () => void;
}
export const TeamHeader: FC<SalesHeaderProps> = ({ onClose }) => {
  const allStatuses = ['ACTIVE', 'INACTIVE', 'ARCHIVED'] as Array<UserStatuses>;
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { clientType } = useTypedSelector((state) => state.venue.venue);
  const dispatch = useAppDispatch();
  const { status: userStatus } = userDetails || {};
  const statuses = userStatus ? statusMappingChange[userStatus] : allStatuses;
  const handleStatusUpdate = (newStatus: UserStatuses) => {
    if (!userDetails) {
      return;
    }
    dispatch(updateUserById({ update: { status: newStatus }, id: userDetails.id }));
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      borderBottom={`1px solid ${theme.palette.divider}`}
      paddingX={4}
      paddingY="20px"
    >
      <Box mr={2}>
        <IconButton color="primary" onClick={onClose} size="large">
          <ArrowForward />
        </IconButton>
      </Box>
      <Box fontSize="14" mr="auto" color={theme.palette.text.primary}>
        {SettingsTeamDetails.UserDetails}
      </Box>

      {userStatus && (
        <StatusComponent
          handleStatusUpdate={handleStatusUpdate}
          status={userStatus || 'ACTIVE'}
          statuses={statuses}
          noEdit={clientType === ClientTypes.ETS}
        />
      )}
    </Box>
  );
};
