import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme, { blur: boolean }>((theme) => ({
  styledLink: (props) => ({
    color: theme.palette.text.primary,
    fontSize: '15px',
    lineHeight: '26px',
    marginRight: '40px',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
    filter: props.blur ? 'blur(4px)' : 'none',
  }),
  activeLink: {
    color: `${theme.palette.primary.main} !important`,
    '&:after': {
      display: 'inline-block',
      height: '2px',
      // width: theme.spacing(4),
      width: '100%',
      content: '""',
      borderRadius: '10px',
      background: theme.palette.primary.main,
      position: 'absolute',
      left: 0,
      top: 'calc(100% + 14px)',
    },
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  mobileStyledLink: {
    color: theme.palette.text.primary,
    fontSize: '9px',
    lineHeight: '16px',
    marginRight: '12px',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));
