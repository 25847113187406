import { Box, Button } from '@mui/material';
import React, { ChangeEvent, FC, useRef } from 'react';
import { useStyles } from './AddVideo.styles';
import { RoundedButton } from '../../../../../../../common/components/Button/RoundedButton';
import { CampaignSummaryIntroVideoStrings } from '../../../../../../../common/localization/en';
import { useOnUploadProgress } from '../../../../../../../services/hooks/useOnUploadProgress';
import { useAppDispatch, useTypedSelector } from '../../../../../../../store';
import {
  uploadVideoAsFile,
  reset,
  createStorageUploadUrl,
} from '../../../../../../../store/slices/uploadVideoSlice';
import { getUpdatedVideoUrl } from '../../../../../../../common/components/VideoUploader/VideoUplader.helpers';
import { MediaFilePrefix } from '../../../../../../../api/models/common';
import ProgressBarWithCompletion from '../../../../../../../common/components/ProgressBarWithCompletion/ProgressBarWithCompletion';
import {
  getVideoAspectRatio,
  isVideoARCloseToTargetAR,
} from '../../../../../../../services/utilities';
import { useTrackEvent } from '../../../../../../../hooks/useTrackEvent';
import { IntroVideoEventNames } from '../../IntroVideo';

const MAX_VIDEO_SIZE_IN_MB = 400;

interface Props {
  onIntroVideoUrlChange: (url: string | null) => void;
  videoUrl: string | null | undefined;
  onRecordClick: () => void;
  isRecordMode: boolean;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  openLargeFileModal: () => void;
}

const AddVideo: FC<Props> = ({
  onIntroVideoUrlChange,
  onRecordClick,
  isRecordMode,
  videoUrl,
  isUploading,
  setIsUploading,
  openLargeFileModal,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const onUploadProgress = useOnUploadProgress({ videoUpload: true });
  const { uploadingProgress } = useTypedSelector((state) => state.uploadVideo);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const isCancelUpload = useRef(false);

  const uploadFile = async (file: File) => {
    setIsUploading(true);
    dispatch(reset());
    const fileSizeInMB = file.size / 1048576;
    if (fileSizeInMB > MAX_VIDEO_SIZE_IN_MB) {
      openLargeFileModal();
      setIsUploading(false);
      return;
    }
    createStorageUploadUrl({ ext: 'mp4', prefix: MediaFilePrefix.Intro }).then((res) => {
      if (res) {
        dispatch(
          uploadVideoAsFile({
            options: res,
            data: file,
            onUploadProgress,
          }),
        ).then(async () => {
          const videoAr = await getVideoAspectRatio(file);
          const shouldFill = !isVideoARCloseToTargetAR(videoAr);
          const url = await getUpdatedVideoUrl(res.downloadUrl, undefined, undefined, shouldFill);

          onIntroVideoUrlChange(url);
          setIsUploading(false);
          dispatch(reset());

          if (isCancelUpload.current) {
            isCancelUpload.current = false;
            return;
          }
        });
      }
    });
  };

  const clearInput = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (!file) {
      clearInput();
      return;
    }
    trackEvent(IntroVideoEventNames.fileSelected, { size: file.size });

    const videoElement = document.createElement('video');
    videoElement.src = URL.createObjectURL(file);

    videoElement.addEventListener('loadedmetadata', () => {
      uploadFile(file);
    });
  };

  const handleInputClick = (e: React.MouseEvent<HTMLElement>) => {
    trackEvent(IntroVideoEventNames.uploadButtonClick);
    if (inputRef?.current && e.detail === 1) {
      inputRef.current.click();
    }
  };

  return (
    <>
      {isUploading && (
        <Box className={classes.progressWrapper}>
          <ProgressBarWithCompletion
            progress={uploadingProgress}
            className={classes.progress}
            text={CampaignSummaryIntroVideoStrings.CompletedUploadText}
          />
        </Box>
      )}
      <Box className={classes.container} sx={{ marginTop: '14px' }}>
        {!videoUrl && (
          <RoundedButton
            title={CampaignSummaryIntroVideoStrings.RecordClip}
            height="44px"
            padding="10px 18px"
            fontWeight={600}
            disabled={isUploading || isRecordMode}
            onClick={onRecordClick}
          />
        )}
        <Button
          className={classes.uploadBtn}
          onClick={handleInputClick}
          variant="outlined"
          size="large"
          color="primary"
          disabled={isUploading || isRecordMode}
        >
          <input
            className={['visually-hidden'].join(' ')}
            type="file"
            accept="video/mp4,video/webm,video/quicktime"
            ref={inputRef}
            id="upload-input"
            name="upload-input"
            onChange={onChangeHandler}
          />
          {CampaignSummaryIntroVideoStrings.BrowseFiles}
        </Button>
        {videoUrl && (
          <Button
            className={classes.removeButton}
            onClick={() => {
              onIntroVideoUrlChange(null);
              clearInput();
            }}
            disabled={isUploading}
          >
            {CampaignSummaryIntroVideoStrings.Remove}
          </Button>
        )}
      </Box>
    </>
  );
};

export default AddVideo;
