import { FC, useEffect, useMemo, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { useStyles } from './SmsMessagesTable.styles';
import { TabItem, TabsSelect } from './TabsSelect/TabsSelect';
import { SmsMessageTablesStrings } from '../../localization/en';
import { SubTabItem, SubTabSelect } from './SubTabSelect/SubTabSelect';
import { SearchBar } from '../searchBar/SearchBar';
import { SmsMessageStatus, SmsMessageType } from '../../../api/models/smsMessages';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { createSmsMessagesTableContent } from './SmsMessagesTable.helper';
import { CommonTable } from '../table/CommonTable';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import {
  clearSmsMessageItems,
  getSmsMessages,
  getSmsMessagesCounts,
  updateSmsMessageCommunicationType,
  updateSmsMessagesLoading,
  updateSmsMessagesTablePage,
  updateSmsMessagesTableSearch,
  updateSmsMessagesTableSize,
  updateSmsMessagesTableSorting,
  updateSmsMessageStatus,
} from '../../../store/slices/smsMessagesSlice';

export const SmsMessageTable: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [subTabIndex, setSubTabIndex] = useState(0);
  const {
    countsData: {
      deliveredAcknowledgements,
      deliveredInvites,
      undeliveredAcknowledgements,
      undeliveredInvites,
      optOuts,
      totalDelivered,
      totalUndelivered,
    },
    totalItems,
    items,
    page,
    size,
    sort,
    search,
    isLoading,
    totalPages,
    dateFilter: { from, to },
    messageStatus,
    communicationType,
  } = useTypedSelector((state) => state.smsMessages);
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        tabName: SmsMessageTablesStrings.Delivered,
        count: totalDelivered,
        messageStatus: SmsMessageStatus.Delivered,
      },
      {
        tabName: SmsMessageTablesStrings.Undelivered,
        count: totalUndelivered,
        messageStatus: SmsMessageStatus.Undelivered,
      },
      {
        tabName: SmsMessageTablesStrings.OptOut,
        count: optOuts,
        messageStatus: SmsMessageStatus.OptOut,
      },
    ],
    [optOuts, totalDelivered, totalUndelivered],
  );

  const subTabItems: SubTabItem[] = useMemo(
    () => [
      {
        tabName: SmsMessageTablesStrings.ViewAll,
        count: tabIndex === 0 ? totalDelivered : totalUndelivered,
      },
      {
        tabName: SmsMessageTablesStrings.Invites,
        count: tabIndex === 0 ? deliveredInvites : undeliveredInvites,
        communicationType: SmsMessageType.Invite,
      },
      {
        tabName: SmsMessageTablesStrings.Acknowledgements,
        count: tabIndex === 0 ? deliveredAcknowledgements : undeliveredAcknowledgements,
        communicationType: SmsMessageType.Reward,
      },
    ],
    [
      deliveredAcknowledgements,
      deliveredInvites,
      tabIndex,
      totalDelivered,
      totalUndelivered,
      undeliveredAcknowledgements,
      undeliveredInvites,
    ],
  );

  useEffect(() => {
    setSubTabIndex(0);
  }, [tabIndex]);

  useEffect(() => {
    // to avoid counts flickering on subtab change
    dispatch(updateSmsMessagesLoading(true));
  }, [dispatch, subTabIndex]);

  useEffect(() => {
    dispatch(updateSmsMessageStatus(tabItems[tabIndex].messageStatus));
  }, [dispatch, tabIndex, tabItems]);

  useEffect(() => {
    dispatch(updateSmsMessageCommunicationType(subTabItems[subTabIndex].communicationType));
  }, [dispatch, subTabIndex, subTabItems]);

  useEffect(() => {
    dispatch(clearSmsMessageItems());
  }, [dispatch, tabIndex, subTabIndex]);

  useEffect(() => {
    if (venueId) {
      dispatch(
        getSmsMessages({
          search,
          accountId: venueId,
          from,
          to,
          page,
          size,
          sort,
          messageStatus,
          communicationType,
        }),
      );
    }
  }, [dispatch, page, size, sort, search, venueId, from, to, messageStatus, communicationType]);

  useEffect(() => {
    dispatch(updateSmsMessagesTableSearch(''));
  }, [tabIndex, subTabIndex, dispatch]);

  useEffect(() => {
    if (venueId) {
      dispatch(
        getSmsMessagesCounts({
          accountId: venueId,
          from,
          to,
          search,
        }),
      );
    }
  }, [dispatch, venueId, from, to, search]);

  const smsMessageTableContent = createSmsMessagesTableContent({
    items,
  });

  const goToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);
    if (convertedTargetPage < totalPages) {
      dispatch(updateSmsMessagesTablePage(convertedTargetPage));
    }
  };

  const handleSort = (name: string) => {
    dispatch(updateSmsMessagesTableSorting(name));
  };

  const changeSize = (size: number) => {
    dispatch(updateSmsMessagesTableSize(size));
    dispatch(updateSmsMessagesTablePage(0));
  };

  return (
    <Box className={classes.container}>
      <TabsSelect
        tabsList={tabItems}
        onTabChange={() => {}}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
      />

      <Box className={classes.tableWrapper}>
        <Box className={classes.searchBarWrapper}>
          <Box>
            {tabIndex < 2 && (
              <SubTabSelect
                tabsList={subTabItems}
                ohChange={() => {}}
                tabIndex={subTabIndex}
                setTabIndex={setSubTabIndex}
              />
            )}
          </Box>
          <SearchBar
            entity={'smsMessages'}
            updateSearch={updateSmsMessagesTableSearch}
            adaptiveWidth={true}
            disabled={false}
            placeholderText={SmsMessageTablesStrings.SearchPlaceholder}
            borderColor={'#F2F4F7'}
          />
        </Box>

        <Box className={classes.table}>
          <CommonTable
            content={smsMessageTableContent}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            tablePadding="0"
            goToPage={goToPage}
            onSortHeaderClick={handleSort}
            hasInfinityScroll={false}
            size={size}
            onSizeChange={changeSize}
            withSearchBar={true}
            roundedNavButtons={false}
            disableTopNav={false}
            getRowBackground={(rowId) => {
              return items.find((item) => item.id === rowId)?.isExcess
                ? `${theme.palette.primary.main}1a`
                : '';
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
