interface IOptions {
  defaultFontSize?: number;
  string: string;
}

export const resizeCompanyNameFont = ({ defaultFontSize, string }: IOptions): number => {
  let fontSize = defaultFontSize || 20;
  const length = string.length;
  if (length <= 22) fontSize = 20;
  if (length > 22 && length <= 33) fontSize = 18.8;
  if (length > 33 && length <= 39) fontSize = 17;
  if (length > 39 && length <= 42) fontSize = 16;
  // if (length > 42 && length <= 48) fontSize = 13.8;
  if (length > 42) fontSize = 13.8;

  return fontSize;
};

export const resizeNameFont = ({ defaultFontSize, string }: IOptions): number => {
  let fontSize = defaultFontSize || 32;
  const length = string.length;
  if (length <= 22) fontSize = 32;
  if (length > 12 && length <= 22) fontSize = 18;
  // if (length > 22 && length <= 48) fontSize = 16.6;
  if (length > 22) fontSize = 16.6;

  return fontSize;
};

export const resizeRewardFont = ({ defaultFontSize, string }: IOptions): number => {
  let fontSize = defaultFontSize || 37;
  const length = string.length;
  if (length <= 12) fontSize = 37;
  if (length > 12 && length <= 20) fontSize = 33;
  if (length > 20 && length <= 22) fontSize = 31.2;
  if (length > 22) fontSize = 23;
  // if (length > 36 && length <= 40) fontSize = 25;
  // if (length > 40 && length <= 47) fontSize = 23;
  // if (length > 47 && length <= 65) fontSize = 22;
  // if (length > 64 && length <= 84) fontSize = 20;
  // if (length > 65) fontSize = 18;

  return fontSize;
};
// WWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW

export const resizeFullNameFont = ({ defaultFontSize, string }: IOptions): number => {
  let fontSize = defaultFontSize || 22;
  const length = string.length;
  if (length <= 30) fontSize = 22;
  // if (length > 30 && length <= 48) fontSize = 15;
  if (length > 30) fontSize = 15;
  return fontSize;
};
