import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { Close } from '@mui/icons-material';
import { theme } from '../../../theme';

interface SimpleAccordionProps {
  children?: JSX.Element;
  title: string;
  primary?: boolean;
  expanded?: boolean;
  handleChange?: () => void;
  handleDelete?: () => void;
}

export const SimpleAccordion: FC<SimpleAccordionProps> = ({
  children,
  title,
  primary,
  expanded,
  handleChange,
  handleDelete,
}) => {
  const deleteItem = (event: any) => {
    if (typeof handleDelete === 'function') {
      event.stopPropagation();
      handleDelete();
    }
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      style={
        primary
          ? {
              background: theme.palette.primary.light,
              borderRadius: '12px',
              border: `1px solid ${theme.palette.primary.light}`,
            }
          : { borderRadius: '12px' }
      }
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {typeof handleDelete === 'function' && (
          <Close
            style={{
              color: theme.palette.text.secondary,
              transform: 'scale(0.8)',
              marginRight: '10px',
            }}
            onClick={deleteItem}
          />
        )}
        <span
          style={
            primary
              ? { fontSize: '14px', fontWeight: 'bold', color: theme.palette.primary.main }
              : { color: theme.palette.primary.main }
          }
        >
          {title}
        </span>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
