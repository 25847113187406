import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12px',
    borderRadius: '8px',
    position: 'relative',
    display: 'flex',
    minWidth: '170px',
    zIndex: 100000,
  },
  arrow: {
    position: 'absolute',
    width: '12px',
    height: '12px',
  },
  title: {
    fontSize: '15px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    color: theme.palette.common.white,
    animation: '$show 0.5s',
  },
  text: {
    fontSize: '12px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 500,
    color: theme.palette.common.white,
    margin: '2px 0 10px 0',
    animation: '$show 0.5s',
  },
  button: {
    padding: '8px 16px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    color: '#3B6EED',
    fontSize: '14px',
    fontWeight: 500,
    width: 'fit-content',
    height: '36px',
    transition: 'color 0.11s',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    animation: '$show 0.5s',

    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: '#2d66f5',
    },
  },
  logoutIcon: {
    width: '17px',
    marginRight: '8px',
  },

  '@keyframes show': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));
