import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  conversionRate: {
    borderRadius: '12px',
    border: '1px solid #F3B03E',
    padding: '10px 23px',
    position: 'relative',
  },
  conversionRateTitle: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '500',
    lineHeight: '15px',
    textAlign: 'left',
    display: 'flex',
  },
  conversionRateValue: {
    fontFamily: 'Poppins',
    fontSize: '34px',
    fontWeight: '600',
    lineHeight: '34px',
    marginTop: '14px',
    color: '#475467',
    textAlign: 'center',
  },
  conversionRateBySource: {
    position: 'relative',
    padding: '10px',
  },
  conversionRateBySourceContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: '12.5px',
  },

  conversionRateBySourceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    padding: '5px 12px 5px 0',
    borderRight: '1px solid #989898',
    marginLeft: '12px',
    marginRight: '14px',
    '&:last-child': {
      borderRight: 'none',
    },
    '&:first-child': {
      marginRight: '0',
    },
  },
  conversionRateSub: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    color: '#8A8A8A',
    marginRight: '14px',
  },
  tooltip: {
    width: '0',
    height: '0',
    marginLeft: '4px',
    marginRight: '10px',
  },
  tooltipBold: {
    display: 'inline',
    fontSize: '12px',
    fontWeight: '600',
    color: '#344054',
    textAlign: 'start',
  },
}));
