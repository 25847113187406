import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useReviewInvitesModalStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '40px',
    color: 'black',
    textAlign: 'center',
    marginBottom: '20px',
  },
}));
