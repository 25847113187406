import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    paddingLeft: '48px',
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    position: 'absolute',
    top: '20%',
    left: 0,
    transform: 'translateY(-50%)',
  },
  title: {
    fontWeight: 600,
    color: '#1A1538',
    wordBreak: 'break-all',
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  subTitle: {
    wordBreak: 'break-all',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: '#717171',
  },
  displayCorrectPhone: {
    display: 'flex',
    '& .react-tel-input :disabled': {
      cursor: 'text',
    },
    '& .react-tel-input .selected-flag': {
      display: 'none',
    },
    '& .react-tel-input .form-control': {
      border: 'none',
      padding: '0px',
      height: 'auto',
      width: 'auto',
      color: '#717171',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
      backgroundColor: 'transparent',
    },
    '& .react-tel-input .flag-dropdown': {
      display: 'none',
    },
  },
  date: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: '#939090',
  },
}));
