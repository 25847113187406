// import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { BoxProps } from '@material-ui/core';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import React, { FC, useMemo, useRef, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

import { UploadIcon } from '../../../assets/UploadIcon';
import { Alignment } from '../ImageUploaderWithGallery';
import { CommonEventNames } from '../../../constants/events/common';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { PenIcon } from '../../../assets/PenIcon';
import { useStyles } from './ImageUploaderBase.styles';
import { RoundedButton } from '../../Button/RoundedButton';

export type ImageUploaderSupportedFileTypes = 'jpeg' | 'png' | 'svg';

const acceptOptions: Record<ImageUploaderSupportedFileTypes, Accept> = {
  jpeg: {
    'image/jpeg': ['.jpeg', '.jpg'],
  },
  png: {
    'image/png': ['.png'],
  },
  svg: {
    'image/svg+xml': ['.svg'],
  },
};

export interface ImageUploaderBaseProps extends BoxProps {
  file: string | undefined;
  onUpload: (file: File | undefined) => void;
  accept?: ImageUploaderSupportedFileTypes[];
  loading?: boolean;
  renderRightSide?: (props: { openFileSelector: () => void }) => React.ReactNode;
  renderBottomBlock?: (props: { openFileSelector: () => void }) => React.ReactNode;
  onRemoveClick?: () => void;
  dndDescription?: string;
  dndTitle?: string | JSX.Element;
  dndWidth?: string;
  dndHeight?: string;
  imagePreviewWidth?: string;
  imagePreviewHeight?: string;
  alignment?: Alignment;
  imagePadding?: number;
  /**
   * "cover" -> image will take full size of container and trimmed - useful for logos
   * "contain" -> display full image with background as borders - useful for background images
   */
  imagePreviewFit?: 'cover' | 'contain';
  isCreateNewCampaignPreview?: boolean;
  dndIcon?: JSX.Element;
  showEditIcon?: boolean;
  alignCenter?: boolean;
  onRemove?: () => void;
  withBottomRemoveButton?: boolean;
}

export const ImageUploaderBase: FC<ImageUploaderBaseProps> = ({
  file,
  onUpload,
  accept,
  loading,
  renderRightSide,
  renderBottomBlock,
  onRemoveClick,
  dndDescription,
  dndTitle,
  imagePreviewWidth = '180px',
  imagePreviewHeight = '180px',
  dndWidth = imagePreviewWidth,
  dndHeight = imagePreviewHeight,
  imagePreviewFit = 'cover',
  alignment,
  imagePadding,
  isCreateNewCampaignPreview,
  dndIcon,
  showEditIcon,
  alignCenter,
  onRemove,
  withBottomRemoveButton,
  ...boxProps
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const { trackEvent } = useTrackEvent();
  const acceptDropzoneSettings = useMemo<Accept | undefined>(() => {
    return (accept || []).reduce((resultAccept: Accept, fileType) => {
      return { ...resultAccept, ...acceptOptions[fileType] };
    }, {});
  }, [accept]);

  const [showInvalidTypeError, setShowInvalidTypeError] = useState(false);

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    isDragActive,
  } = useDropzone({
    disabled: loading,
    multiple: false,
    onDrop: (files) => onUpload(files[0]),
    accept: acceptDropzoneSettings,
    onDropRejected: () => {
      setShowInvalidTypeError(true);
    },
    onDropAccepted: () => {
      setShowInvalidTypeError(false);
    },
  });

  const renderImagePreview = () => {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={imagePreviewWidth}
        height={imagePreviewHeight}
        position="relative"
        onClick={() => {
          trackEvent(CommonEventNames.image_uploader_area_click);
        }}
        padding={imagePadding || 0}
        border={isCreateNewCampaignPreview ? 'none' : `1px solid #C5C5C5`}
        borderRadius="12px"
      >
        <img
          src={file}
          alt="selected-file"
          style={{
            borderRadius: '6px',
            width: '100%',
            height: '100%',
            objectFit: imagePreviewFit,
            cursor: 'pointer',
          }}
        />

        {showEditIcon && (
          <Box
            className={classes.penIcon}
            onClick={(event) => {
              event.stopPropagation();
              openFileSelector();
            }}
          >
            <PenIcon />
          </Box>
        )}
        {onRemoveClick && (
          <IconButton
            aria-label="remove"
            size="small"
            sx={{
              position: 'absolute',
              top: isCreateNewCampaignPreview ? '-15px' : '0px',
              right: isCreateNewCampaignPreview ? '-20px' : '0px',
              bgcolor: isCreateNewCampaignPreview ? '#AFAFAF' : '#00000020',
              width: isCreateNewCampaignPreview ? '32px' : '20px',
              height: isCreateNewCampaignPreview ? '32px' : '20px',
              m: '4px',
            }}
            onClick={(e) => {
              e.stopPropagation();
              onRemoveClick();
            }}
          >
            <CloseIcon
              sx={{
                color: 'white',
                width: '16px',
                height: '16px',
              }}
            />
          </IconButton>
        )}
      </Box>
    );
  };

  const renderDND = () => {
    return (
      <Box
        border="1px solid #EAECF0"
        borderRadius="12px"
        height={dndHeight}
        width={dndWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent={alignCenter ? 'center' : 'space-around'}
        style={{
          cursor: 'pointer',
        }}
      >
        {dndIcon ? dndIcon : <UploadIcon />}
        <Box>
          <Typography fontSize="11px" color="#475467" textAlign="center" mb="4px" fontWeight="500">
            {dndTitle || 'Drag and drop'}
          </Typography>
          {dndDescription && (
            <Typography maxWidth="120px" fontSize="12px" color="#475467" textAlign="center">
              {dndDescription}
            </Typography>
          )}
        </Box>
        <input {...getDropzoneInputProps()} />
      </Box>
    );
  };

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const handleHiddenInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileUploaded = event.target.files?.[0];
    onUpload(fileUploaded);
  };

  const acceptHiddenInputSettings = Object.keys(acceptDropzoneSettings || {}).join(', ');

  const openFileSelector = () => {
    hiddenFileInput.current?.click();
  };

  const renderInvalidTypeError = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width={imagePreviewWidth}
        height={imagePreviewHeight}
        textAlign="center"
        color="black"
        fontSize="14px"
        fontWeight="600"
        border="1px solid red"
        borderRadius="12px"
        bgcolor="#FEF6F5"
        p="4px"
      >
        <span>Invalid File Type.</span>
        <span>Accepted file types are:</span>
        <br />
        <span>
          {accept?.reduce((acc, type, index) => {
            const getPrefix = () => {
              if (index === 0) return '';
              if (index === accept.length - 1) return ' or ';
              return ', ';
            };

            return acc + getPrefix() + type.toUpperCase();
          }, '')}
        </span>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" alignItems={alignment || 'flex-end'} {...boxProps}>
        <Box>
          <Box
            {...getDropzoneRootProps()}
            mr="26px"
            border={`1px dashed ${isDragActive ? theme.palette.primary.main : '#EAECF0'}`}
            bgcolor="#F2F4F7"
            borderRadius="12px"
            mt={dndIcon ? '6px' : '0'}
          >
            {showInvalidTypeError
              ? renderInvalidTypeError()
              : file
              ? renderImagePreview()
              : renderDND()}
          </Box>
          {withBottomRemoveButton && file && (
            <RoundedButton
              title="Remove"
              onClick={(event) => {
                onRemove?.();
                event.stopPropagation();
                trackEvent(CommonEventNames.background_image_library_upload_button_click);
              }}
              variant="text"
              sx={{
                height: '45px',
                marginLeft: '3px',
                '& p': {
                  fontSize: '18px',
                  color: '#ED2E38',
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          )}
        </Box>
        <Box>{renderRightSide?.({ openFileSelector })}</Box>
      </Box>
      <input
        type="file"
        accept={acceptHiddenInputSettings}
        ref={hiddenFileInput}
        onChange={handleHiddenInputChange}
        style={{ display: 'none' }}
      />
      <Box>{renderBottomBlock?.({ openFileSelector })}</Box>
    </>
  );
};
