import { FC } from 'react';

import './Loader.style.css';

export const Loader: FC = () => {
  return (
    <div style={{}}>
      <div className="snippet" data-title=".dot-flashing">
        <div className="stage">
          <div className="dot-flashing"></div>
        </div>
      </div>
    </div>
  );
};
