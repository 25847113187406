export const IncentiveIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      stroke="inherit"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.00708 1.49951H16.9923V4.51189V7.47537C16.9923 10.7744 14.3026 13.4489 10.9997 13.4489C7.69006 13.4489 5.00708 10.7828 5.00708 7.46776V4.48187V1.49951Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M10.9995 15.1195V19.1146" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M7.00464 19.1145H14.9948" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M16.9923 3.49109C16.9923 3.49109 19.4892 3.49109 19.9886 3.49109C20.488 3.49109 20.9873 3.98898 20.9873 4.48686C20.9873 4.98475 21.4697 9.46573 16.4758 9.46573"
        strokeWidth="1.5"
      />
      <path
        d="M5.00696 3.49109C5.00696 3.49109 2.51005 3.49109 2.01067 3.49109C1.51128 3.49109 1.0119 3.98898 1.0119 4.48686C1.0119 4.98475 0.538641 9.46573 5.53247 9.46573"
        strokeWidth="1.5"
      />
    </svg>
  );
};
