import { Button, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { formatPhoneNumber } from '../../../services/utilities';
import { BellIcon } from '../../assets/BellIcon';

import { AdminVenueManagementStrings, SmsInviteStrings } from '../../localization/en';
import { useClasses } from './SendTestWithPopulatedValuesMessageModal.helpre';

interface SendTestWithPopulatedValuesMessageModalProps {
  phoneNumber: string;
  name?: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const SendTestWithPopulatedValuesMessageModal: FC<SendTestWithPopulatedValuesMessageModalProps> = ({
  name,
  phoneNumber,
  onSubmit,
  onCancel,
}) => {
  const classes = useClasses();
  const formattedNumber =
    phoneNumber.substring(0, 2) === '+1'
      ? formatPhoneNumber(phoneNumber, { showPlusOne: false })
      : phoneNumber;

  return (
    <Grid
      className={classes.root}
      container
      flexDirection={'column'}
      alignItems="center"
      justifyContent={'center'}
    >
      <Grid>
        <BellIcon />
      </Grid>
      <Typography className={classes.title}>
        {SmsInviteStrings.TestMessagePopulatedTitle}
      </Typography>
      <Typography className={classes.name}>{name}</Typography>
      <Typography className={classes.phone}>{formattedNumber}</Typography>
      <Grid
        paddingX={'16px'}
        className={classes.buttons}
        container
        alignItems="center"
        justifyContent={'center'}
      >
        <Button onClick={onCancel} variant="outlined" className={classes.button}>
          {SmsInviteStrings.No}
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          variant="contained"
          className={classes.button}
        >
          {SmsInviteStrings.Yes}
        </Button>
      </Grid>
      <Typography className={classes.info}>{AdminVenueManagementStrings.RegularSMS}</Typography>
    </Grid>
  );
};

export default SendTestWithPopulatedValuesMessageModal;
