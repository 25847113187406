export const Reward = () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25781 0.937012H19.7412V4.32594V7.65985C19.7412 11.3713 16.7152 14.3801 12.9995 14.3801C9.27616 14.3801 6.25781 11.3807 6.25781 7.65129V4.29217V0.937012Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path d="M12.9995 16.2595V20.754" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M8.50513 20.7539H17.494" stroke="white" strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M19.7412 3.17749C19.7412 3.17749 22.5502 3.17749 23.112 3.17749C23.6738 3.17749 24.2356 3.73761 24.2356 4.29774C24.2356 4.85786 24.7782 9.89896 19.1602 9.89896"
        stroke="white"
        strokeWidth="1.5"
      />
      <path
        d="M6.25793 3.17749C6.25793 3.17749 3.4489 3.17749 2.88709 3.17749C2.32529 3.17749 1.76348 3.73761 1.76348 4.29774C1.76348 4.85786 1.23106 9.89896 6.84912 9.89896"
        stroke="white"
        strokeWidth="1.5"
      />
    </svg>
  );
};
