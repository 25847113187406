import { FC } from 'react';

export const RectangleSelectedLast: FC = () => (
  <svg width="233" height="56" viewBox="0 0 233 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_2516_28346)">
      <path
        d="M8.5 6.5H201.035C202.083 6.5 203.098 6.86546 203.905 7.53339L222.027 22.5334C224.202 24.333 224.202 27.667 222.027 29.4666L203.905 44.4666C203.098 45.1345 202.083 45.5 201.035 45.5H13C10.5147 45.5 8.5 43.4853 8.5 41V6.5Z"
        stroke="#D1D1D1"
      />
      <path
        d="M8.5 6.5H201.035C202.083 6.5 203.098 6.86546 203.905 7.53339L222.027 22.5334C224.202 24.333 224.202 27.667 222.027 29.4666L203.905 44.4666C203.098 45.1345 202.083 45.5 201.035 45.5H13C10.5147 45.5 8.5 43.4853 8.5 41V6.5Z"
        stroke="#5242EA"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2516_28346"
        x="0"
        y="0"
        width="232.158"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2516_28346" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2516_28346"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
