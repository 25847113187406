import React, { useRef, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import clsx from 'clsx';

import { VideCardStrings } from '../../../../../common/localization/en';
import { useStyles } from './VideoCardNew.helper';
import { VideoApiModel } from '../../../../../api/models/videos';
import { Status, StatusDropdown } from '../../../StatusDropdown/StatusDropdown';
import { FavoriteButton } from '../../../favoriteButton/FavoriteButton';
import { getLocalDateString } from '../../../../../services/utilities';

interface VideoCardProps {
  video: VideoApiModel;
  handleOpenConfirmationModal?: (id: string) => void;
}

// TODO: I think this component should be used instead of VideoCard component.
//  Also it's very important to clean up surrounding components and structures
export const VideoCardNew: React.FC<VideoCardProps> = ({ video, handleOpenConfirmationModal }) => {
  const classes = useStyles();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playPauseSwitch = () => {
    if (!refVideo || !refVideo.current) return;
    const videoElement = refVideo.current;

    if (videoElement.paused) {
      videoElement.play().then(() => setIsPlaying(true));
    } else {
      videoElement.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          component="video"
          ref={refVideo}
          className={classes.video}
          src={video.url}
          onClick={() => playPauseSwitch()}
        />
        {!isPlaying && (
          <Box fontSize={40} className={classes.icon}>
            <PlayCircleOutlineIcon fontSize="inherit" color="inherit" />
          </Box>
        )}
        {handleOpenConfirmationModal && (
          <Box className={classes.favouriteButton}>
            <FavoriteButton
              checked={video.featured}
              id={video.id}
              handleOpenConfirmationModal={handleOpenConfirmationModal}
            />
          </Box>
        )}
      </CardActionArea>
      <CardContent className={classes.contentCard}>
        <Box className={classes.box} paddingX={2}>
          <Typography className={clsx([classes.smallText, classes.smallTextSecondary])}>
            Created
          </Typography>
          <Box mr={2} />
          <Typography className={clsx([classes.smallText, classes.smallTextPrimary])}>
            {getLocalDateString(video.createdAt)}
          </Typography>
          <Box mr={2} />
          <Typography className={classes.public} color="primary">
            {video.isPublic ? VideCardStrings.Public : VideCardStrings.Private}
          </Typography>
        </Box>
        <StatusDropdown id={video.id} TG={false} publishedToWebapp={video.status as Status} />
      </CardContent>
    </Card>
  );
};
