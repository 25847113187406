import React from 'react';
import { CSVLink } from 'react-csv';
import ImportIcon from './ImportIcon';
import { IconButton } from '@mui/material';
import { InvalidImportRecord } from '../../../../../../pages/rewards/CampaignSummary/Modals/ImportFileModal';

interface ImportInvalidRecordsButtonProps {
  invalidRecords: InvalidImportRecord[];
}

export const ImportInvalidRecordsButton: React.FC<ImportInvalidRecordsButtonProps> = ({
  invalidRecords,
}) => {
  const data = invalidRecords.map(({ record, reason, tip }) => {
    return [
      record.firstName,
      record.lastName,
      record.phoneNumber,
      record.transactionDate,
      reason,
      tip,
    ];
  });

  return (
    <CSVLink
      data={[
        ['First Name', 'Last Name', 'Cell Phone', 'Transaction Date', 'Reason', 'Tip'],
        ...data,
      ]}
      filename="undeliverable_invitations"
    >
      <IconButton>
        <ImportIcon />
      </IconButton>
    </CSVLink>
  );
};
