import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { TableDTO } from '../../../store/storeModels';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { useAppDispatch } from '../../../store';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { createCampaignsTableContent } from '../../../services/helpers/tableMappers';
import { CommonTable } from '../../../common/components/table/CommonTable';
import {
  goToSelectedPage,
  setCampaignsSorting,
  updateRequestsSize,
} from '../../../store/slices/campaignsSlice';

export const TableSection: FC<TableDTO<CampaignsApiModel> & { selectedItemId?: string }> = ({
  isLoading,
  error,
  items,
  totalPages,
  totalItems,
  page,
  size,
  sort,
  search = '',
  selectedItemId,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const tableContents = createCampaignsTableContent({
    items,
  });

  const onGoToPage = (targetPage: number) => {
    dispatch(goToSelectedPage(convertFrontEndPageToApiPage(targetPage)));
  };

  const onSort = (name: string) => {
    dispatch(setCampaignsSorting(name));
  };

  const onClickRow = (id: string) => {
    history.push(`${WebsiteRoutes.Campaigns}/${id}`);
  };

  const changeSize = (size: number) => {
    dispatch(updateRequestsSize(size));
  };

  return (
    <>
      <CommonTable
        hasShadow={false}
        selectedRowId={selectedItemId}
        content={tableContents}
        page={convertApiPageToFrontEndPage(page)}
        sort={sort}
        totalItems={totalItems}
        totalPages={totalPages}
        isLoading={isLoading}
        noContent={error}
        tablePadding="0"
        goToPage={onGoToPage}
        onSortHeaderClick={onSort}
        onClickRow={onClickRow}
        size={size}
        onSizeChange={changeSize}
      />
    </>
  );
};
