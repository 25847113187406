import React from 'react';

const CheckedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="1" width="26" height="26" rx="5.5" fill="#F9F5FF" />
      <rect x="0.75" y="1" width="26" height="26" rx="5.5" stroke="#005F9E" />
      <path
        d="M20.05 9.2749L11.3875 17.9374L7.44998 13.9999"
        stroke="#005F9E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckedIcon;
