import { FC, useState } from 'react';
import { WizardStepper } from './WizardStepper';
import { WizardIntructionsPage } from './WizardInstructionsPage';

export const VenueWizard: FC = () => {
  const [showInstructions, setShowInstructions] = useState(true);

  return (
    <>
      {showInstructions ? (
        <WizardIntructionsPage setShowInstructions={setShowInstructions} />
      ) : (
        <WizardStepper />
      )}
    </>
  );
};
