import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'fit-content',
    margin: '0 auto',
    padding: '40px 0 38px 0',
    display: 'grid',
    gridTemplate: '26px auto / 1fr 1fr',
    placeItems: 'center',
  },
  title: {
    fontSize: '12px',
    textAlign: 'center',
    fontStyle: 'italic',
  },
  passiveSphere: {
    width: '96px',
    height: '96px',
    borderRadius: '50%',
    position: 'relative',
    boxShadow: '0 0 4px #000',
  },
  playIcon: {
    borderRadius: '50%',
    position: 'absolute',
    display: 'grid',
    placeItems: 'center',
    background: 'rgba(255, 255, 255, 0.20)',
    backdropFilter: 'blur(5px)',
  },
  smallIcon: {
    width: '38px',
    height: '38px',
    top: 0,
    left: 0,
  },
  largeIcon: {
    width: '60px',
    height: '60px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  hoverSphere: {
    width: '303px',
    height: '303px',
    borderRadius: '50%',
    position: 'relative',
    marginTop: '25px',
  },
  userInfo: {
    fontSize: '16px',
    position: 'absolute',
    zIndex: '20',
    color: theme.palette.common.white,
    left: '128px',
    bottom: '20px',
  },

  mobileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px',
  },
}));
