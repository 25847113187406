import { Close } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FC, MouseEvent } from 'react';
import { SearchFilterApiModel } from '../../../../api/models/searchFilter';
import { useAppDispatch } from '../../../../store';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import { ViewsIcon } from '../../../assets/searchFilterIcons/ViewsIcon';
import { CustomPopper, PopperPlacements } from '../../Popper/Popper';
import { ListFilterItemPopper } from './ListFilterItemPopper';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignContent: 'center',
    padding: '5px 0px',
    height: '36px',
    marginTop: '8px',
    marginBottom: '8px',
    '&:hover': {
      backgroundColor: '#EEEEEE',
      cursor: 'pointer',
      '& div:nth-child(2)': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  icon: {
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '5px',
    marginRight: '5px',
  },
  inputsRoot: {
    // display: 'flex',
    paddingRight: '10px',
    display: 'none',
  },
  input: {
    background: '#EDEDEE',
    width: '50px',
    height: '40px',
    borderRadius: '12px',
    padding: '0px',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '10px',
  },
}));
interface ListFilterItemProps {
  item: SearchFilterApiModel;
  anchorEl: HTMLElement | null;
  open: boolean;
  handleOpen: (event: MouseEvent<HTMLSpanElement>) => void;
  handleClose: () => void;
  handleDeleteIconClick: (filterId: string) => void;
}
export const ListFilterItem: FC<ListFilterItemProps> = ({
  item,
  open,
  anchorEl,
  handleOpen,
  handleClose,
  handleDeleteIconClick,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleDelete = (e: any) => {
    /* dispatch(deleteSearchFilterById(item.id)); */
    handleDeleteIconClick(item.id);
    e.stopPropagation();
  };

  const handleClick = () => {
    dispatch(setActiveFilter(item));
    handleClose();
  };

  return (
    <>
      <CustomPopper open={open} anchorEl={anchorEl} placement={PopperPlacements.Left}>
        <ListFilterItemPopper item={item} />
      </CustomPopper>
      <div className={classes.root} onClick={handleClick}>
        <div className={classes.labelRoot}>
          <span className={classes.icon} id={item.id} onClick={handleOpen}>
            <ViewsIcon />
          </span>
          <span className={classes.label}>{item.name}</span>
        </div>
        <div className={classes.inputsRoot}>
          <Close onClick={handleDelete} />
        </div>
      </div>
    </>
  );
};
