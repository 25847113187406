import { FC } from 'react';

interface CheckmarkIconProps {
  className?: string;
}
export const CheckmarkIcon: FC<CheckmarkIconProps> = ({ className }) => {
  return (
    <>
      <svg
        className={className}
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.21094 3.23187L3.08433 5.10526C3.16243 5.18337 3.28906 5.18337 3.36717 5.10526L7.50722 0.96521"
          stroke="white"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
