import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { segmentTrackLink } from '../../../utils/segment';
import { useTypedSelector } from '../../../store';
import { LayoutMode, segmentActions } from '../../constants/constants';
import { useStyles } from './SubnavigationItem.helper';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { WebsiteRoutes } from '../../constants/routes';

interface SubNavigationItemProps {
  text: string;
  route: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  iconActive?: React.ReactElement;
  blur?: boolean;
  onClick?: (route: string) => void;
}

export const SubNavigationItem: FC<SubNavigationItemProps> = ({
  text,
  route,
  disabled,
  icon,
  iconActive,
  blur = false,
  onClick,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const isActive = !!pathname.includes(route);
  const classes = useStyles({ blur });
  let id = route.split('/').join('_');
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;
  useEffect(() => {
    segmentTrackLink(id, segmentActions.Tabs + id, venueId);
  }, [id, venueId]);

  const redirectToLink = () => {
    onClick?.(route);
    history.push(route);
  };
  const isWiderTab =
    (location && location.pathname.includes('/settings/invites')) ||
    location.pathname.includes(WebsiteRoutes.WebAppSignUpConfig) ||
    location.pathname.includes(WebsiteRoutes.BrandingStyleTab) ||
    location.pathname.includes(WebsiteRoutes.VideoPlaybackTab) ||
    location.pathname.includes(WebsiteRoutes.QRCode) ||
    location.pathname.includes(WebsiteRoutes.TrackingPixelTab);

  return (
    <div
      style={{
        textAlign: isWiderTab ? 'center' : undefined,
        marginRight: isWiderTab ? '10px' : '',
        paddingLeft: isWiderTab ? '20px' : '',
        paddingRight: isWiderTab ? '20px' : '',
        whiteSpace: 'nowrap',
      }}
      className={clsx({
        [classes[isMobile ? 'mobileStyledLink' : 'styledLink']]: true,
        [classes.activeLink]: isActive,
        [classes.disabled]: disabled,
      })}
      onClick={redirectToLink}
    >
      <div style={{ textAlign: 'center' }}>{isActive && iconActive ? iconActive : icon}</div>
      <div>{text}</div>
    </div>
  );
};
