import { FC } from "react";

export const APIIcon:FC = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.50046 14.7907C7.40879 14.7907 7.30879 14.7657 7.22546 14.724C6.58379 14.399 6.03379 13.9157 5.63379 13.3157C5.30046 12.8157 5.30046 12.174 5.63379 11.674C6.03379 11.074 6.58379 10.5907 7.22546 10.274C7.53379 10.1157 7.90879 10.249 8.06712 10.5574C8.22546 10.8657 8.10046 11.2407 7.78379 11.399C7.33379 11.624 6.95046 11.9657 6.67546 12.3824C6.62546 12.4574 6.62546 12.5574 6.67546 12.6407C6.95046 13.0574 7.33379 13.399 7.78379 13.624C8.09212 13.7824 8.21712 14.1574 8.06712 14.4657C7.95046 14.6657 7.72546 14.7907 7.50046 14.7907Z"
      fill="#8B89A0"
    />
    <path
      d="M12.6747 14.792C12.4414 14.792 12.2247 14.667 12.1164 14.4503C11.958 14.142 12.083 13.767 12.3997 13.6087C12.8497 13.3837 13.233 13.042 13.508 12.6253C13.558 12.5503 13.558 12.4503 13.508 12.367C13.233 11.9503 12.8497 11.6087 12.3997 11.3837C12.0914 11.2253 11.9664 10.8503 12.1164 10.542C12.2747 10.2337 12.6497 10.1087 12.958 10.2587C13.5997 10.5837 14.1497 11.067 14.5497 11.667C14.883 12.167 14.883 12.8087 14.5497 13.3087C14.1497 13.9087 13.5997 14.392 12.958 14.7087C12.858 14.767 12.7664 14.792 12.6747 14.792Z"
      fill="#8B89A0"
    />
    <path
      d="M12.5003 18.9596H7.50032C2.97533 18.9596 1.04199 17.0263 1.04199 12.5013V7.5013C1.04199 2.9763 2.97533 1.04297 7.50032 1.04297H12.5003C17.0253 1.04297 18.9587 2.9763 18.9587 7.5013V12.5013C18.9587 17.0263 17.0253 18.9596 12.5003 18.9596ZM7.50032 2.29297C3.65866 2.29297 2.29199 3.65963 2.29199 7.5013V12.5013C2.29199 16.343 3.65866 17.7096 7.50032 17.7096H12.5003C16.342 17.7096 17.7087 16.343 17.7087 12.5013V7.5013C17.7087 3.65963 16.342 2.29297 12.5003 2.29297H7.50032Z"
      fill="#8B89A0"
    />
    <path
      d="M1.8584 7.3013C1.51673 7.3013 1.2334 7.01797 1.2334 6.6763C1.2334 6.33464 1.5084 6.0513 1.8584 6.0513L17.8751 6.04297C18.2167 6.04297 18.5001 6.3263 18.5001 6.66797C18.5001 7.00964 18.2251 7.29297 17.8751 7.29297L1.8584 7.3013Z"
      fill="#8B89A0"
    />
  </svg>
);

