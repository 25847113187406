import { FC } from 'react';

export const CaptionIcon: FC = () => {
  return (
    <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.25 0H2.25C1.145 0 0.25 0.839062 0.25 1.875V13.125C0.25 14.1609 1.145 15 2.25 15H16.25C17.355 15 18.25 14.1609 18.25 13.125V1.875C18.25 0.839062 17.355 0 16.25 0ZM8.25 6.5625H6.75V6.09375H4.75V8.90625H6.75V8.4375H8.25V9.375C8.25 9.62364 8.14464 9.8621 7.95711 10.0379C7.76957 10.2137 7.51522 10.3125 7.25 10.3125H4.25C3.98478 10.3125 3.73043 10.2137 3.54289 10.0379C3.35536 9.8621 3.25 9.62364 3.25 9.375V5.625C3.25 5.37636 3.35536 5.1379 3.54289 4.96209C3.73043 4.78627 3.98478 4.6875 4.25 4.6875H7.25C7.51522 4.6875 7.76957 4.78627 7.95711 4.96209C8.14464 5.1379 8.25 5.37636 8.25 5.625V6.5625ZM15.25 6.5625H13.75V6.09375H11.75V8.90625H13.75V8.4375H15.25V9.375C15.25 9.62364 15.1446 9.8621 14.9571 10.0379C14.7696 10.2137 14.5152 10.3125 14.25 10.3125H11.25C10.9848 10.3125 10.7304 10.2137 10.5429 10.0379C10.3554 9.8621 10.25 9.62364 10.25 9.375V5.625C10.25 5.37636 10.3554 5.1379 10.5429 4.96209C10.7304 4.78627 10.9848 4.6875 11.25 4.6875H14.25C14.5152 4.6875 14.7696 4.78627 14.9571 4.96209C15.1446 5.1379 15.25 5.37636 15.25 5.625V6.5625Z"
        fill="white"
      />
    </svg>
  );
};
