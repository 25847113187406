import styled from 'styled-components';
import { theme } from '../../theme';

export const RewardsContainer = styled.div`
  width: 100%;
  padding: 16px 24px 48px;
`;

interface ControlsBlockProps {
  minHeight?: string;
  noPadding?: boolean;
}
export const ControlsBlock = styled.div<ControlsBlockProps>`
  height: 100%;
  position: relative;
  background-color: #fff;
  border-radius: 16px;
  min-height: ${(props) => props.minHeight || '600px'};
  padding: ${(props) => (props.noPadding ? 0 : '32px 24px')};

  @media (max-width: ${theme.breakpoints.values.md}px) {
    padding: ${theme.spacing(2)};
  }
`;

export const SectionDivider = styled.div`
  width: calc(100% + 48px);
  border-bottom: 1px solid #eeeef6;
  margin-left: -24px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const FieldLabel = styled.div`
  margin-top: 15px;
  width: 100%;
  color: ${theme.palette.secondary.contrastText};
`;

export const ImageBlock = styled.img`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  object-fit: contain;
`;

export const ButtonsControlWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;

  > button:first-child {
    margin-right: 24px;
  }
`;
