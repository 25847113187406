import styled from 'styled-components';
import { ListItemIcon, ListItem } from '@mui/material';

export const Container = styled.div`
  background: var(--sidebarActiveBgr);
  border-radius: 4px;
`;

export const SubMenuArrowsWrapper = styled.div`
  padding-right: 6px;
`;

export const SVListItem = styled(ListItem)``;

export const SVListItemIcon = styled(ListItemIcon)`
  margin-left: 8px;
  margin-right: 15px;
`;

export const SubItemIcon = styled(ListItemIcon)`
  margin-left: 17px;
  margin-right: 6px;
`;
