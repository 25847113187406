import { Box } from '@mui/material';
import { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useStyles } from './accountMainInfo.style';

interface DisplayedPhoneProps {
  phone: string;
  c?: boolean;
}

export const DisplayedPhone: FC<DisplayedPhoneProps> = ({ phone, c }) => {
  const classes = useStyles();
  return (
    <Box className={classes.subTitle}>
      <div className={classes.displayCorrectPhone}>
        {c ? <span style={{ marginRight: '8px' }}>c:</span> : null}
        <PhoneInput value={phone} disabled />
      </div>
    </Box>
  );
};
