import { FC } from 'react';
import { IphoneXframeDeprecated } from '../../../../common/components/previews/phonePreviewWrapper';
import { ContentWrapper } from '../../../../common/components/previews/EducationalCardsPreview/EducationalCardsPreview.style';
import { PreviewContainer } from '../../rewardsList/Preview/Preview.style';
import { makeStyles } from '@mui/styles';
import home from '../../../../common/assets/Home.png';

interface IncentiveCardPreviewProps {
  brandColor?: string;
  textColor?: string;
  logo?: string;
  image?: string | undefined;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  isActiveAd: boolean;
}

const useStyles = makeStyles(() => ({
  topGolfHome: {
    width: '100%',
    backgroundImage: `url(${home})`,
    height: '100%',
    minHeight: '300px',
    backgroundPosition: '0px 10px',
    backgroundRepeat: ' no-repeat',
    backgroundSize: 'contain',
  },
  contentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: '10px 20px 20px',
    background: 'linear-gradient(180deg, #273e7a59, #0e121e00)',
  },
  imageBlock: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '74px',
    maxHeight: '74px',
    height: '74px',
    width: '74px',
    minWidth: '74px',
  },
  textBtnBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '5px',
  },
  textBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
  },
  incentiveImg: {
    borderRadius: '9px',
    objectFit: 'contain',
    maxHeight: '100%',
    width: '100%',
  },
  titleText: {
    fontFamily: 'Avenir',
    fontSize: '12px',
    fontWeight: '800',
    margin: 0,
    color: '#C1BCB5',
  },
  contentText: {
    fontFamily: 'Avenir',
    color: '#C1BCB5',
    fontSize: '8px',
  },
  delightBtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    fontSize: '10px',
    fontWeight: '600',
    border: '0',
    cursor: 'pointer',
    color: '#fff',
    background: '#F4B93B',
    borderRadius: '8px',
    width: '104px',
  },
}));

export const IncentiveAdPreview: FC<IncentiveCardPreviewProps> = ({
  buttonText,
  image,
  title,
  subtitle,
  isActiveAd,
}) => {
  const styles = useStyles();
  return (
    <PreviewContainer>
      <IphoneXframeDeprecated>
        <ContentWrapper>
          <div className={styles.topGolfHome}>
            <div className={styles.contentContainer}>
              {isActiveAd && (
                <>
                  <div className={styles.imageBlock}>
                    {image && <img className={styles.incentiveImg} src={image} alt={''} />}
                  </div>
                  <div className={styles.textBtnBlock}>
                    <span className={styles.textBlock}>
                      <p className={styles.titleText}>{title}</p>
                    </span>
                    <span className={styles.contentText}>{subtitle}</span>
                    <button className={styles.delightBtn}>{buttonText}</button>
                  </div>
                </>
              )}
            </div>
          </div>
        </ContentWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
