import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './EmptyResultsPreview.style';

interface Props {
  title: string;
  subtitle: string;
}

export const EmptyResultsPreview: FC<Props> = ({ title, subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.subtitle}>{subtitle}</Typography>
      </Box>
    </Box>
  );
};
