import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  penIcon: {
    position: 'absolute',
    cursor: 'pointer',
    bottom: '-1.5px',
    right: '-1.5px',
    height: '24px',
    width: '24px',
    overflow: 'hidden',
    '&:hover': {
      '& svg path': {
        fill: '#5242EA',
      },
    },
  },
}));
