import styled from 'styled-components';
import { theme } from '../../../theme';

export const DescriptionBlock = styled.span`
  font-size: 10px;
  color: ${theme.palette.secondary.contrastText};
`;

export const TitleRightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

export const TitleRightBlockText = styled.div`
  margin-right: 20px;
  color: ${theme.palette.secondary.contrastText};
  font-size: 14px;
`;

export const TitleRightBlockNumber = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${theme.palette.primary.main};

  span {
    font-size: 14px;
  }
`;
