import { FC } from 'react';

interface Props {
  height?: number;
}

export const TrackingPixelNavIcon: FC<Props | undefined> = ({ height }) => (
  <svg
    width="45"
    height={height || 56}
    viewBox="0 0 45 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 1.5H38V0.5H7V1.5ZM43.5 7V55.9992H44.5V7H43.5ZM1.5 55.9992V7H0.5V55.9992H1.5ZM38 1.5C41.0376 1.5 43.5 3.96243 43.5 7H44.5C44.5 3.41015 41.5899 0.5 38 0.5V1.5ZM7 0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5C1.5 3.96243 3.96243 1.5 7 1.5V0.5Z"
      fill="currentColor"
    />
    <path
      d="M20.4453 4.27734C20.1692 4.27734 19.9453 4.5012 19.9453 4.77734C19.9453 5.05349 20.1692 5.27734 20.4453 5.27734V4.27734ZM28.4453 5.27734C28.7215 5.27734 28.9453 5.05349 28.9453 4.77734C28.9453 4.5012 28.7215 4.27734 28.4453 4.27734V5.27734ZM20.4453 5.27734H28.4453V4.27734H20.4453V5.27734Z"
      fill="currentColor"
    />
    <circle
      cx="17.3008"
      cy="4.77734"
      r="0.5"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.5"
    />
    <g opacity="0.1">
      <path
        d="M37.3307 8.33398H7.66406C6.00721 8.33398 4.66406 9.67713 4.66406 11.334V55.9999H40.3307V11.334C40.3307 9.67713 38.9876 8.33398 37.3307 8.33398Z"
        fill="currentColor"
      />
      <path
        d="M40.3307 55.9999V56.4999H40.8307V55.9999H40.3307ZM4.66406 55.9999H4.16406V56.4999H4.66406V55.9999ZM7.66406 8.83398H37.3307V7.83398H7.66406V8.83398ZM39.8307 11.334V55.9999H40.8307V11.334H39.8307ZM5.16406 55.9999V11.334H4.16406V55.9999H5.16406ZM4.66406 56.4999H40.3307V55.4999H4.66406V56.4999ZM37.3307 8.83398C38.7114 8.83398 39.8307 9.95327 39.8307 11.334H40.8307C40.8307 9.40099 39.2637 7.83398 37.3307 7.83398V8.83398ZM7.66406 7.83398C5.73107 7.83398 4.16406 9.40099 4.16406 11.334H5.16406C5.16406 9.95327 6.28335 8.83398 7.66406 8.83398V7.83398Z"
        fill="currentColor"
      />
    </g>
    <path
      d="M7.66406 8.83398H37.3307V7.83398H7.66406V8.83398ZM39.8307 11.334V55.9999H40.8307V11.334H39.8307ZM5.16406 55.9999V11.334H4.16406V55.9999H5.16406ZM37.3307 8.83398C38.7114 8.83398 39.8307 9.95327 39.8307 11.334H40.8307C40.8307 9.40099 39.2637 7.83398 37.3307 7.83398V8.83398ZM7.66406 7.83398C5.73107 7.83398 4.16406 9.40099 4.16406 11.334H5.16406C5.16406 9.95327 6.28335 8.83398 7.66406 8.83398V7.83398Z"
      fill="currentColor"
    />
    <rect
      opacity="0.25"
      x="10.0742"
      y="43.2188"
      width="25"
      height="6"
      rx="3"
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      opacity="0.25"
      x="10.5"
      y="16.5"
      width="25"
      height="23"
      rx="3.5"
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M27.1666 36.125H26.3333C25.9916 36.125 25.7083 35.8417 25.7083 35.5C25.7083 35.1583 25.9916 34.875 26.3333 34.875H27.1666C29.3166 34.875 30.7083 33.4833 30.7083 31.3333V24.6667C30.7083 22.5167 29.3166 21.125 27.1666 21.125H18.8333C16.6833 21.125 15.2916 22.5167 15.2916 24.6667V25.5C15.2916 25.8417 15.0083 26.125 14.6666 26.125C14.325 26.125 14.0416 25.8417 14.0416 25.5V24.6667C14.0416 21.8 15.9666 19.875 18.8333 19.875H27.1666C30.0333 19.875 31.9583 21.8 31.9583 24.6667V31.3333C31.9583 34.2 30.0333 36.125 27.1666 36.125Z"
      fill="currentColor"
    />
    <path
      d="M23.2418 35.5502C22.9334 35.5502 22.6668 35.3169 22.6251 35.0086C22.1668 31.4419 19.5668 28.8336 15.9918 28.3753C15.6501 28.3336 15.4084 28.0169 15.4501 27.6752C15.4918 27.3336 15.8084 27.0919 16.1501 27.1336C20.3001 27.6669 23.3334 30.6919 23.8584 34.8419C23.9001 35.1836 23.6584 35.5002 23.3168 35.5419C23.3001 35.5502 23.2668 35.5502 23.2418 35.5502Z"
      fill="currentColor"
    />
    <path
      d="M20.4416 36.4407C20.1333 36.4407 19.8666 36.2074 19.8249 35.8991C19.4999 33.3574 17.6416 31.4991 15.0999 31.1741C14.7583 31.1324 14.5166 30.8157 14.5583 30.4741C14.5999 30.1324 14.9166 29.8907 15.2583 29.9324C18.3833 30.3324 20.6583 32.6157 21.0583 35.7324C21.0999 36.0741 20.8583 36.3907 20.5166 36.4324C20.4916 36.4407 20.4666 36.4407 20.4416 36.4407Z"
      fill="currentColor"
    />
    <path
      d="M17.2752 36.9754C16.9669 36.9754 16.7002 36.742 16.6585 36.4337C16.5168 35.3087 15.6919 34.4837 14.5669 34.342C14.2252 34.3004 13.9835 33.9837 14.0252 33.642C14.0668 33.3004 14.3835 33.0587 14.7252 33.1004C16.4085 33.317 17.6835 34.592 17.9002 36.2754C17.9419 36.617 17.7002 36.9337 17.3585 36.9754C17.3335 36.9754 17.3085 36.9754 17.2752 36.9754Z"
      fill="currentColor"
    />
  </svg>
);
