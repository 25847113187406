import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import React, { useEffect, useState } from 'react';

import EditIcon from './EditIcon';
import { getFullName } from '../../../services/utilities';

const ACTION_BUTTON_SIZE = '28px';
const INPUT_WIDTH = '120px';

interface EditableNameProps {
  firstName: string;
  lastName: string;
  onChange: (fullName: { firstName: string; lastName: string }) => void;
}

export const EditableName: React.FC<EditableNameProps> = ({ firstName, lastName, onChange }) => {
  const theme = useTheme();

  const [isEditState, setIsEditState] = useState(false);

  const [_firstName, _setFirstName] = useState(firstName);
  const [_lastName, _setLastName] = useState(lastName);

  useEffect(() => {
    _setFirstName(firstName);
    _setLastName(lastName);
  }, [firstName, lastName]);

  const saveEditedText = () => {
    if (!isEditState || !_firstName) return;

    onChange({
      firstName: _firstName,
      lastName: _lastName,
    });
    setIsEditState(false);
  };

  const cancelEdit = () => {
    _setFirstName(firstName);
    _setLastName(lastName);
    setIsEditState(false);
  };

  const handleKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      saveEditedText();
      return;
    }

    if (event.key === 'Escape') {
      cancelEdit();
      return;
    }
  };

  return (
    <Stack direction="row" spacing="4px" alignItems="center" height="fit-content">
      {isEditState ? (
        <>
          <input
            type="text"
            autoFocus
            value={_firstName}
            onChange={(e) => _setFirstName(e.target.value)}
            style={{
              padding: '0 8px',
              lineHeight: '24px',
              fontSize: '16px',
              color: '#667085',
              border: '1px solid #DCDBE4',
              borderRadius: '8px',
              width: INPUT_WIDTH,
            }}
            onKeyDown={handleKeyPress}
          />

          <input
            type="text"
            autoFocus
            value={_lastName}
            onChange={(e) => _setLastName(e.target.value)}
            style={{
              padding: '0 8px',
              lineHeight: '24px',
              fontSize: '16px',
              color: '#667085',
              border: '1px solid #DCDBE4',
              borderRadius: '8px',
              width: INPUT_WIDTH,
            }}
            onKeyDown={handleKeyPress}
          />

          <IconButton
            onClick={(e) => {
              e.preventDefault();
              saveEditedText();
            }}
            sx={{
              padding: 0,
              marginLeft: '11px',
              width: ACTION_BUTTON_SIZE,
              height: ACTION_BUTTON_SIZE,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <CheckRoundedIcon sx={{ height: '16px', width: '16px', color: 'white' }} />
          </IconButton>

          <IconButton
            onClick={(e) => {
              e.preventDefault();
              cancelEdit();
            }}
            sx={{
              padding: 0,
              marginLeft: '3px',
              width: ACTION_BUTTON_SIZE,
              height: ACTION_BUTTON_SIZE,
              backgroundColor: '#CECECE',
              opacity: 1,
              '&:hover': {
                backgroundColor: '#CECECEAA',
              },
            }}
          >
            <CloseRoundedIcon sx={{ height: '16px', width: '16px', color: 'white' }} />
          </IconButton>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                overflowWrap: 'anywhere',
                color: '#101828',
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '32px',
              }}
            >
              {getFullName({ firstName, lastName })}
            </Typography>
          </Box>
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              setIsEditState(true);
            }}
            sx={{
              padding: 0,
              marginLeft: '11px',
              width: ACTION_BUTTON_SIZE,
              height: ACTION_BUTTON_SIZE,
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            <EditIcon height={16} width={16} />
          </IconButton>
        </>
      )}
    </Stack>
  );
};
