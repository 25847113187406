import styled from 'styled-components';
import { theme } from '../../../../../../theme';

export const Container = styled.div`
  position: relative;
  margin-top: 14px;
  padding-right: 35px;
  border-bottom: 1px solid rgb(46, 48, 57);
`;

export const RewardCirclePointWrapper = styled.div`
  transform: scale(0.8);
  position: absolute;
  top: -9px;
  right: 0;
  display: flex;
  font-weight: 600;
  font-size: 13px;
  z-index: 1;
`;

export const ContentWrapper = styled.div``;
interface TextWrapperProps {
  revert: boolean;
}
export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  ${(props) =>
    props.revert
      ? 'flex-direction: column'
      : 'flex-direction: column-reverse; justify-content: flex-end;'}
`;
export const TitleWrapper = styled.div`
  display: flex;
`;
export const Title = styled.div`
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 11px;
  color: ${theme.palette.primary.contrastText};
`;
export const Text = styled.div`
  margin-bottom: 4px;
  letter-spacing: 0.15px;
  color: #bcbccc;
  font-size: 10px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  margin: 0 7px 1px 0;
  width: 35px;
  height: 25px;

  img {
    object-fit: contain;
  }
`;
