import { Box, ListItem as MuiListItem, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useRef } from 'react';

import { ExternalLink } from '../../common/components/Link/ExternalLink';
import {
  SubSectionTitle,
  SubSectionTitleProps,
} from '../../common/components/SubSectionTitle/SubSectionTitle';
import { useServiceAgreementLink } from '../../hooks/useServiceAgreementLink';
import { useVenue } from '../../hooks/useVenue';
import { redirectToPage } from '../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  getBillingSubscriptionDetailsSimplified,
  getCustomerPortalSession,
} from '../../store/slices/BillingSlice';
import {
  deactivateVenue,
  getServiceAgreement,
  reactivateVenue,
} from '../../store/slices/venueSlice';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { AccountEventNames } from '../../common/constants/events/accountEvents';
import { PaymentIcon } from '../../common/assets/newDesign/WebAppIcons/PaymentIcon';
import { RoundedButton } from '../../common/components/Button/RoundedButton';
import { BillingStrings } from '../../common/localization/en';
import { Toggle } from '../../common/components/toggle/CustomToggle';
import { useModal } from '../../hooks/useModal';
import { ArchiveModal } from './ArchiveModal/ArchiveModal';
import { UserRoles } from '../../common/constants/constants';
import { getApiUrlForId, VenueEndpoints } from '../../api/endpoints';
import { useToasts } from 'react-toast-notifications';
import { UploadIcon } from '../../common/assets/shareAssets/UploadIcon';

const SectionTitle: React.FC<SubSectionTitleProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <SubSectionTitle
      title={title}
      titleProps={{
        fontSize: '24px',
        fontWeight: 700,
      }}
      bgcolor={theme.palette.common.pageBackground}
      py="6px"
      px="14px"
      borderRadius="9px"
      mb="16px"
    />
  );
};

const ListItem: React.FC = ({ children }) => {
  return (
    <MuiListItem sx={{ display: 'list-item', fontWeight: '400', p: 0, my: '4px', ml: '20px' }}>
      {children}
    </MuiListItem>
  );
};

export const AccountOverview: React.FC = () => {
  const venue = useVenue();
  const serviceAgreementLink = useServiceAgreementLink();
  const { subscriptionDetails, pricingPlan, isLoading } = useTypedSelector(
    (state) => state.BillingSlice,
  );
  const { id } = useTypedSelector((state) => state.venue.venue);
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const { addToast } = useToasts();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();

  useEffect(() => {
    if (id) {
      dispatch(getServiceAgreement({ id }));
      dispatch(getBillingSubscriptionDetailsSimplified({ accountId: id }));
    }
  }, [id, dispatch]);

  const { serviceAgreement } = useTypedSelector((state) => state.venue);

  const handleOpenAgreement = () => {
    const url =
      !serviceAgreement.error && serviceAgreement.value
        ? window.URL.createObjectURL(serviceAgreement.value)
        : serviceAgreementLink;

    redirectToPage(url, true);
    trackEvent(AccountEventNames.viewAgreement);
  };

  const planName = 'Premium';
  const subscriptionPrice = pricingPlan?.subscriptionFee;

  // FIXME: use real values in nextPaymentDate and planUpdateLink
  const nextPaymentDate = subscriptionDetails?.currentPeriodEnd
    ? format(new Date(subscriptionDetails?.currentPeriodEnd), 'MMM d, yyyy')
    : null;
  const billingPeriod = pricingPlan?.billingPeriod === 'monthly' ? 'monthly' : 'yearly';
  // const planUpdateLink = '#';

  const redirectToManagePaymentPage = async () => {
    const data = await dispatch(
      getCustomerPortalSession({
        payload: {
          accountId: venue.venueId,
          successUrl: window.location.origin,
        },
      }),
    );

    const redirectUrl = data?.payload.redirectUrl;
    if (redirectUrl) {
      window.open(redirectUrl, '_blank');
    }
  };

  const uploadFileToStorage = async (file: FormData) => {
    try {
      const baseUrl = process.env.REACT_APP_NEW_API_URL;
      const res = await fetch(
        `
      ${baseUrl}${getApiUrlForId(VenueEndpoints.UploadServiceAgreement, id)}`,
        {
          method: 'POST',
          body: file,
          headers: {
            'X-Auth-Token': `Bearer ${localStorage.getItem('authToken')}`,
          },
        },
      );

      if (res?.ok) {
        addToast(BillingStrings.NewAgreementUploaded, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(getServiceAgreement({ id }));
      }

      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const uploadNewAgreement = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await uploadFileToStorage(formData);
    }
  };

  const onUploadClick = () => {
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const openMail = () => {
    window.location.href = 'mailto:feedback@socialvenu.com?subject=Plan%20Information';
  };

  const isArchived = venue.deactivatedAt !== null;
  const { isOpen: isArchiveModalOpen, close: closeArchiveModal, open: openArchiveModal } = useModal(
    false,
  );

  const { role } = useTypedSelector((state) => state.me);
  const isSVAdmin = role === UserRoles.admin;

  const me = useTypedSelector((state) => state.me);
  const accountDeactivationAllowed = isSVAdmin && (me.details?.permissions as any)?.deactivate;
  const UpdateAgreementEnabled = isSVAdmin && (me.details?.permissions as any)?.updateAgreement;

  return (
    <Box width="100%" bgcolor="white" borderRadius="15px" p="30px">
      <Box mb="46px">
        <Box position="relative">
          <SectionTitle title="Your Plan" />
          {accountDeactivationAllowed && (
            <Box
              sx={{
                position: 'absolute',
                top: '0',
                right: '16px',
                display: 'flex',
                alignItems: 'center',
                height: '100%',

                '& .MuiFormControlLabel-root': {
                  margin: 0,
                },
                '& .MuiSwitch-root': {
                  height: '28px',
                  width: '52px',
                },
                '& .MuiSwitch-thumb': {
                  height: '23px',
                  width: '23px',
                },
                '& .Mui-checked': {
                  transform: 'translateX(23px) !important',
                },
                '& .MuiSwitch-switchBase': {
                  padding: '3px 0 0 3px',
                },
              }}
            >
              <Typography fontSize="14px" mr="10px" color={theme.palette.common.gray}>
                Archive
              </Typography>

              <ArchiveModal
                isArchived={isArchived}
                isOpen={isArchiveModalOpen}
                onClose={closeArchiveModal}
                onConfirm={() => {
                  if (isArchived) {
                    dispatch(reactivateVenue(id));
                  } else {
                    dispatch(deactivateVenue(id));
                  }
                  closeArchiveModal();
                }}
              />
              <Toggle value={isArchived} onChange={openArchiveModal} />
            </Box>
          )}
        </Box>

        {pricingPlan && (
          <ListItem>
            Your account is on the{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {planName} plan
            </Typography>{' '}
            (<ExternalLink title="View Agreement" onClick={handleOpenAgreement} />
            ), paying {billingPeriod}.
          </ListItem>
        )}
        {subscriptionPrice && pricingPlan ? (
          <ListItem>
            Your{' '}
            <Typography component="span" color="black" fontWeight={400}>
              {billingPeriod}
            </Typography>{' '}
            subscription will be billed on{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {nextPaymentDate},
            </Typography>{' '}
            for{' '}
            <Typography component="span" color="black" fontWeight={700}>
              {subscriptionDetails?.subscriptionFee}
            </Typography>{' '}
            + tax.
            {nextPaymentDate && (
              <Typography component="span" color="black" fontWeight={700}></Typography>
            )}
          </ListItem>
        ) : (
          <ListItem>
            Contact SocialVenu for information about your plan{' '}
            <ExternalLink title="Contact" onClick={openMail} />
          </ListItem>
        )}
        <input
          style={{ display: 'none' }}
          type="file"
          accept="application/pdf"
          onChange={uploadNewAgreement}
          ref={fileInputRef}
          id="select-file"
        />
        {UpdateAgreementEnabled && (
          <label htmlFor="select-file">
            <RoundedButton
              title={BillingStrings.UploadNewAgreement}
              outlined={true}
              startIcon={<UploadIcon />}
              onClick={onUploadClick}
              loading={isLoading}
              sx={{
                width: '325px !important',
                height: '45px',
                whiteSpace: 'nowrap',
                marginTop: '15px',
              }}
            />
          </label>
        )}
      </Box>
      <Box mb="46px">
        <SectionTitle title="Billing Actions" />

        {pricingPlan ? (
          <>
            <ListItem>Future charges will be billed to the card we have in our system.</ListItem>
            <ListItem>Credit Card information and prior invoices provided by Stripe:</ListItem>
            <RoundedButton
              title={BillingStrings.AccessPaymentInfo}
              outlined={true}
              startIcon={<PaymentIcon />}
              onClick={redirectToManagePaymentPage}
              loading={isLoading}
              sx={{
                width: '325px !important',
                height: '45px',
                whiteSpace: 'nowrap',
                marginTop: '15px',
              }}
            />{' '}
          </>
        ) : (
          <ListItem>
            Contact SocialVenu for information about your plan{' '}
            <ExternalLink title="Contact" onClick={openMail} />
          </ListItem>
        )}
      </Box>
    </Box>
  );
};
