import React, { useEffect } from 'react';
import { defaultPagination } from '../../../common/constants/constants';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getActiveIncentiveCampaign } from '../../../store/slices/campaignsSlice';
import { setUserDataSorting } from '../../../store/slices/heroesSlice';
import { TableSection } from './TableSection/TableSection';

interface RedeemRequestsProps {}
export const RedeemRequests: React.FC<RedeemRequestsProps> = () => {
  const dispatch = useAppDispatch();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  dispatch(setUserDataSorting(defaultPagination.sortByCampaignPoints[0]));

  useEffect(() => {
    if (venueId) {
      dispatch(getActiveIncentiveCampaign(venueId));
    }
  }, [dispatch, venueId]);

  return (
    <>
      <TableSection isLeaderBoard />
    </>
  );
};
