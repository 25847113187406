import * as React from 'react';
import { SVGProps } from 'react';
const SVGComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1}
      y={1}
      width={4.23815}
      height={4.23815}
      rx={1}
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={17.9961}
      y={1}
      width={4.23815}
      height={4.23815}
      rx={1}
      transform="rotate(90 17.9961 1)"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 9.45703H3.11909"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.42188 9.45703H13.7595"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53906 1L9.53906 3.11909"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53906 7.33789L9.53906 9.45698"
      stroke="CURRENTCOLOR"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9961 9.45703L17.9961 10.8347"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.53906 13.7578L9.53906 17.996"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.7578 9.51953L13.7578 13.7577"
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={17.0886}
      cy={17.0867}
      r={3.91284}
      stroke="CURRENTCOLOR"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9297 17.3105L16.7009 18.5679L15.4715 17.3099"
      stroke="CURRENTCOLOR"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0898 18.5684L17.0898 15.6032"
      stroke="CURRENTCOLOR"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={1}
      y={13.7578}
      width={4.23815}
      height={4.23815}
      rx={1}
      stroke="CURRENTCOLOR"
      strokeWidth={1.4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SVGComponent;
