import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.secondary,
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 16,
  },
  text: {
    fontFamily: 'Open Sans',
    fontWeight: 400,
    fontSize: 16,
  },
}));
