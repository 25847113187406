import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SubnavigationItemValues } from '../../common/components/webappSubnavigation/WebappSubnavigation.helper';
import { WebsiteRoutes } from '../../common/constants/routes';
export enum DashboardSubnavigationStrings {
  SocialVideos = 'SocialVideos',
  SocialVerse = 'SocialVerse',
}

export const subnavigationItems: SubnavigationItemValues[] = [
  {
    route: WebsiteRoutes.DashboardHeroes,
    text: '',
  },
];

export const topGolfsubnavigationItems: SubnavigationItemValues[] = [];
export type PlatformType =
  | 'FACEBOOK'
  | 'SMS'
  | 'EMAIL'
  | 'TWITTER'
  | 'DOWNLOAD'
  | 'LINK'
  | ''
  | 'INSTAGRAM'
  | 'LINKEDIN'
  | 'TIKTOK'
  | 'REELS'
  | 'REDDIT'
  | 'PINTEREST';

export const useStyles = makeStyles((theme: Theme) => ({
  chartWrapper: {
    padding: '15px',
    margin: '0 20px',
    background: theme.palette.common.white,
    borderRadius: '10px',
  },
}));
