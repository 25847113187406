import { FC } from 'react';

interface Props {
  className?: string;
}

export const CalendarIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_515_153319)">
        <path
          d="M9.625 4.58464H1.375M7.33333 0.917969V2.7513M3.66667 0.917969V2.7513M3.575 10.0846H7.425C8.19507 10.0846 8.58011 10.0846 8.87424 9.93477C9.13296 9.80294 9.34331 9.59259 9.47513 9.33387C9.625 9.03974 9.625 8.65471 9.625 7.88464V4.03464C9.625 3.26456 9.625 2.87953 9.47513 2.5854C9.34331 2.32668 9.13296 2.11633 8.87424 1.9845C8.58011 1.83464 8.19507 1.83464 7.425 1.83464H3.575C2.80493 1.83464 2.41989 1.83464 2.12576 1.9845C1.86704 2.11633 1.65669 2.32668 1.52487 2.5854C1.375 2.87953 1.375 3.26456 1.375 4.03464V7.88464C1.375 8.65471 1.375 9.03974 1.52487 9.33387C1.65669 9.59259 1.86704 9.80294 2.12576 9.93477C2.41989 10.0846 2.80493 10.0846 3.575 10.0846Z"
          stroke="#8B89A0"
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_515_153319">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
