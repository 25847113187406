import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UnprotectedRoutes, WebsiteRoutes } from '../../common/constants/routes';
import { httpClient } from '../../services/httpClient/httpClient';

export const Home = () => {
  const history = useHistory();
  const isLoggedUser = httpClient.isLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      history.push(UnprotectedRoutes.Login);
    } else {
      history.push(WebsiteRoutes.ProfileAccounts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
};
