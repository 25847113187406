import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '112px',
    border: '1px solid #EAECF0',
    borderRadius: '12px',
    boxShadow: '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
    padding: '24px 0 0 12px',
    cursor: 'pointer',
    background: '#fff',
    position: 'relative',
    '&:hover': {
      border: '1px solid rgb(82, 66, 234) !important',
    },
  },
  tooltip: {
    position: 'absolute',
    top: '5px',
    right: '10px',
  },
  topWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
  },
  titleWrapper: {
    width: '100%',
  },
  title: {
    paddingRight: '10px',
    color: '#475467',
    fontSize: '12px',
    fontWeight: 500,
  },
  tooltipIcon: {
    width: '14px',
    height: '14px',
  },
  value: {
    color: '#101828',
    fontSize: '30px',
    lineHeight: '38px',
    fontWeight: 600,
    marginTop: '8px',
  },
}));
