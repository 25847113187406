export const LinkIcon = () => {
  return (
    <>
      <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.6324 1.71926H3.58112C2.35382 1.71926 1.35889 2.74036 1.35889 3.99996C1.35889 5.25956 2.35382 6.28066 3.58112 6.28066H5.6324V7.33329H3.58112C1.78737 7.33329 0.333252 5.84091 0.333252 3.99996C0.333252 2.15901 1.78737 0.666626 3.58112 0.666626H5.6324V1.71926Z"
          fill="#8F94B6"
        />
        <path
          d="M10.4187 1.71926H8.36744V0.666626H10.4187C12.2125 0.666626 13.6666 2.15901 13.6666 3.99996C13.6666 5.84091 12.2125 7.33329 10.4187 7.33329H8.36744V6.28066H10.4187C11.646 6.28066 12.6409 5.25956 12.6409 3.99996C12.6409 2.74036 11.646 1.71926 10.4187 1.71926Z"
          fill="#8F94B6"
        />
        <path d="M9.73496 4.52628H4.26488V3.47364H9.73496V4.52628Z" fill="#8F94B6" />
      </svg>
    </>
  );
};
