import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
}

export const ExpandIconGray: FC<Props> = ({ className }) => (
  <Box className={className}>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 8L10.5 13L15.5 8"
        stroke="#344054"
        strokeOpacity="0.3"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Box>
);
