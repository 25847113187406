import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { CommonEventNames } from '../../constants/events/common';
import { UnsavedChangesModalStrings } from '../../localization/en';
import { RoundedButton } from '../Button/RoundedButton';
import { useStyles } from './UnsavedChangesModal.style';

interface UnsavedChangesModalContentProps {
  show: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  onCancel?: () => void;
}

export const UnsavedChangesModalContent: React.FC<UnsavedChangesModalContentProps> = ({
  show,
  onClose,
  onConfirm,
  onCancel,
}) => {
  const styles = useStyles();

  const { trackEvent } = useTrackEvent();

  return (
    <Modal
      open={show}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onClose={() => {
        trackEvent(CommonEventNames.unsaved_changes_modal_close);
        onClose?.();
      }}
    >
      <Box className={styles.Modal}>
        <Typography className={styles.ModalTitle}>
          {UnsavedChangesModalStrings.ModalTitle}
        </Typography>
        <Typography className={styles.ModalText}>{UnsavedChangesModalStrings.ModalText}</Typography>
        <Box display="flex" justifyContent="center" gap="10px">
          <RoundedButton
            title={UnsavedChangesModalStrings.LeavePage}
            onClick={() => {
              trackEvent(CommonEventNames.unsaved_changes_modal_leave_button_click);
              onConfirm?.();
            }}
            outlined
            fontSize="12px"
          />
          <RoundedButton
            title={UnsavedChangesModalStrings.StayOnPage}
            onClick={() => {
              trackEvent(CommonEventNames.unsaved_changes_modal_stay_on_page_button_click);
              onCancel?.();
            }}
            fontSize="12px"
          />
        </Box>
      </Box>
    </Modal>
  );
};
