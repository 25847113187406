import * as React from 'react';
import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.45245 4.2436C9.79113 4.19353 10.1405 4.16667 10.5003 4.16667C14.7545 4.16667 17.5461 7.9207 18.4839 9.40569C18.5974 9.58542 18.6542 9.67528 18.6859 9.81389C18.7098 9.91799 18.7098 10.0822 18.6859 10.1863C18.6541 10.3249 18.597 10.4154 18.4827 10.5963C18.2328 10.9918 17.8518 11.5476 17.3471 12.1504M6.1036 5.59586C4.30187 6.81808 3.07871 8.51615 2.51759 9.4044C2.40357 9.58489 2.34656 9.67514 2.31478 9.81373C2.29091 9.91783 2.2909 10.082 2.31476 10.1861C2.34652 10.3247 2.40328 10.4146 2.51678 10.5943C3.45462 12.0793 6.24618 15.8333 10.5003 15.8333C12.2157 15.8333 13.6932 15.223 14.9073 14.3972M3.00035 2.5L18.0003 17.5M8.73258 8.23223C8.28017 8.68464 8.00035 9.30964 8.00035 10C8.00035 11.3807 9.11963 12.5 10.5003 12.5C11.1907 12.5 11.8157 12.2202 12.2681 11.7678"
      stroke="#E6A417"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const UnpublishedIcon = memo(SvgComponent);
export default UnpublishedIcon;
