export const TestMenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_500_19107)">
        <path
          d="M20.54 17.73L15 11V5H16C16.55 5 17 4.55 17 4C17 3.45 16.55 3 16 3H8C7.45 3 7 3.45 7 4C7 4.55 7.45 5 8 5H9V11L3.46 17.73C3.14 18.12 3 18.56 3 19C3.01 20.03 3.82 21 5 21H19C20.19 21 21 20.03 21 19C21 18.56 20.86 18.12 20.54 17.73Z"
          fill="#667085"
        />
      </g>
      <defs>
        <clipPath id="clip0_500_19107">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
