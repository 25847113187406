import { makeStyles, withStyles } from '@mui/styles';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';

import { theme } from '../../../theme';
import avatar1 from './widgets/avatar1.png';
import avatar2 from './widgets/avatar2.png';
import avatar3 from './widgets/avatar3.png';

export const useClasses = makeStyles((theme) => ({
  root: {
    borderRadius: theme.spacing(2),
    margin: '24px 16px',
    minHeight: '200px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px',
    minHeight: '200px',
  },
  code: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.darkBlue,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(2),
    },
    wordWrap: 'break-word',
    minHeight: '200px',
  },
  codeMobile: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.darkBlue,
    padding: theme.spacing(2),
    wordWrap: 'break-word',
    minHeight: '200px',
    borderRadius: theme.spacing(2),
  },
  copyButtonContainer: {
    display: 'flex',
    width: '100%',
    height: '32px',
    justifyContent: 'flex-end',
  },
  copyButton: {
    display: 'flex',
    height: theme.spacing(4),
    width: theme.spacing(4),
    backgroundColor: theme.palette.primary.main,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  icon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
}));

export enum OpenedDocs {
  Bubble = 1,
  Card,
  Block,
}

export const stories = [
  {
    name: 'Andrew Daniels',
    image: avatar1,
    id: '11',
  },
  {
    name: 'Mathew Croll',
    image: avatar2,
    id: '12',
  },
  {
    name: 'Josh Dun',
    image: avatar3,
    id: '13',
  },
  {
    name: 'Drake Ramore',
    image: avatar1,
    id: '14',
  },
  {
    name: 'Random Dude',
    image: avatar2,
    id: '15',
  },
];

export const Accordion = withStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #dfe1ea',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.06)',
    borderRadius: 3,

    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {},
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 400,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '16px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({}))(MuiAccordionDetails);
