import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, TextField } from '@mui/material';
import { useStyles } from './ClipsSearchTags.styles';
import { useAppDispatch, useTypedSelector } from '../../../store';
import CampaignTag from './CampaignTag/CampaignTag';
import DateTag from './DateTag/DateTag';
import LengthTag from './LengthTag/LengthTag';
import StatusTag from './StatusTag/StatusTag';
import { SearchTagsStrings } from '../../localization/en';
import PurpleConfirmIcon from '../../assets/PurpleConfirmIcon';
import CancelIconBig from '../../assets/CancelIconBig';
import { getSearchFilters, postSearchFilter } from '../../../store/slices/searchFilterSlice';
import { SearchTarget } from '../../../api/models/searchFilterModels';
import { useToasts } from 'react-toast-notifications';
import MenuContainer from '../MenuContainer/MenuContainer';
import { setLoadMore } from '../../../store/slices/videosSlice';

const ClipsSearchTags = () => {
  const styles = useStyles();

  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  const { activeVideoFilterAttributes, search } = useTypedSelector((state) => state.searchFilter);
  const {
    venue: { id },
  } = useTypedSelector((state) => state.venue);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [saveAnchorEl, setSaveAnchorEl] = useState<null | HTMLElement>(null);
  const isSaveMenuOpen = Boolean(saveAnchorEl);

  const handleSaveMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSaveAnchorEl(event.currentTarget);
  };

  const handleSaveMenuClose = () => {
    setSaveAnchorEl(null);
    setName('');
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(e.target.value);

    if (nameError) {
      setNameError(false);
    }
  };

  const handleSave = async () => {
    if (name === '') {
      setNameError(true);
      return;
    }

    handleSaveMenuClose();
    await dispatch(
      postSearchFilter({
        accountId: id,
        filter: { attributes: activeVideoFilterAttributes },
        name,
        searchText: search,
        target: SearchTarget.VIDEOS,
      }),
    );
    await dispatch(getSearchFilters({ accountId: id, target: SearchTarget.VIDEOS }));

    addToast(`${name} ${SearchTagsStrings.SavedInfo}`, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  useEffect(() => {
    return () => {
      dispatch(setLoadMore(false));
    };
  }, [dispatch]);

  return (
    <Box className={styles.container}>
      <CampaignTag />
      <DateTag />
      <LengthTag />
      <StatusTag />
      <Button onClick={handleSaveMenuOpen} className={styles.saveButton}>
        {SearchTagsStrings.SaveSearch}
      </Button>
      <MenuContainer
        anchorElement={saveAnchorEl}
        isOpen={isSaveMenuOpen}
        onClose={handleSaveMenuClose}
        padding="11px 8px"
      >
        <Box className={styles.saveWrapper}>
          <TextField
            type="text"
            className={styles.nameInput}
            value={name}
            onChange={handleNameChange}
            sx={{
              '& div': {
                borderColor: nameError ? 'red' : '#EEEEF6',
              },
            }}
          />
          <IconButton
            sx={{
              padding: '2px',
              marginLeft: '8px',
            }}
            onClick={handleSave}
          >
            <PurpleConfirmIcon />
          </IconButton>
          <IconButton sx={{ padding: '2px' }} onClick={handleSaveMenuClose}>
            <CancelIconBig />
          </IconButton>
        </Box>
      </MenuContainer>
    </Box>
  );
};

export default ClipsSearchTags;
