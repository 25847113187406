export const TextMessageIcon = () => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 8.56934H12.5M7.5 12.0693H15.5M7.5 18.0693V20.4048C7.5 20.9377 7.5 21.2041 7.60923 21.341C7.70422 21.46 7.84827 21.5292 8.00054 21.529C8.17563 21.5288 8.38367 21.3624 8.79976 21.0295L11.1852 19.1212C11.6725 18.7313 11.9162 18.5364 12.1875 18.3978C12.4282 18.2748 12.6844 18.1849 12.9492 18.1306C13.2477 18.0693 13.5597 18.0693 14.1837 18.0693H16.7C18.3802 18.0693 19.2202 18.0693 19.862 17.7424C20.4265 17.4547 20.8854 16.9958 21.173 16.4313C21.5 15.7896 21.5 14.9495 21.5 13.2693V7.86934C21.5 6.18918 21.5 5.3491 21.173 4.70736C20.8854 4.14288 20.4265 3.68394 19.862 3.39632C19.2202 3.06934 18.3802 3.06934 16.7 3.06934H8.3C6.61984 3.06934 5.77976 3.06934 5.13803 3.39632C4.57354 3.68394 4.1146 4.14288 3.82698 4.70736C3.5 5.3491 3.5 6.18918 3.5 7.86934V14.0693C3.5 14.9993 3.5 15.4643 3.60222 15.8458C3.87962 16.8811 4.68827 17.6897 5.72354 17.9671C6.10504 18.0693 6.57003 18.0693 7.5 18.0693Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
