import { FC, useEffect } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box, Button, Grid, IconButton, SxProps, Typography, Tabs, Tab } from '@mui/material';
import clsx from 'clsx';
import { useSocialVerseStyles } from '../SocialVerses.style';
import { Add } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useTypedSelector } from '../../../store';

import AccountMenu from '../../../common/components/AccountMenu/AccountMenu';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { useIsFteFinished } from '../../../hooks/useIsFteFinished';
import { getPrimaryCampaign } from '../../../store/slices/campaignsSlice';
import ShortcutsMenu from '../../../common/components/ShortcutsMenu/ShortcutsMenu';
import { useLocation } from 'react-router-dom';
import Carousel, { CarouselItem } from '../../../common/components/Carousel/Carousel';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { SocialVerseStrings } from '../../../common/localization/en';

import poster_classic from '../SVPreviews/sv_classic_cards.png';
import poster_perspective from '../SVPreviews/sv_perspective.png';
import poster_grids from '../SVPreviews/sv_grids.png';
import poster_educational from '../SVPreviews/sv_educational.png';
import poster_spheres from '../SVPreviews/sv_spheres.png';
import { useNoSocialVersesStyles } from '../NoSocialVerses.style';
import { isEducationalView } from '../../../services/utilities';
import { useQuickStartLaunchPad } from '../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import { useClientType } from '../../../services/hooks/useClientType';

export const SVTabs = [
  {
    type: TypeSocialVerse.Cards,
    poster: poster_classic,
    text: SocialVerseStrings.Classic,
    subtitle: SocialVerseStrings.SeeWhatCustomersSay,
    info: SocialVerseStrings.ClassicInfo,
    content: (
      <Box sx={{ padding: '2px' }}>
        <img
          src={poster_classic}
          alt={`SocialV Create ${SocialVerseStrings.Classic}`}
          width={'765px'}
          height={'300px'}
          loading="lazy"
        />
      </Box>
    ),
  },
  {
    type: TypeSocialVerse.Perspective,
    poster: poster_perspective,
    text: SocialVerseStrings.Perspective,
    subtitle: SocialVerseStrings.SeeWhatCustomersSay,
    info: SocialVerseStrings.PerspectiveInfo,
    content: (
      <img
        src={poster_perspective}
        alt={`SocialV Create ${SocialVerseStrings.Perspective}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    type: TypeSocialVerse.Grid,
    poster: poster_grids,
    text: SocialVerseStrings.Grids,
    subtitle: SocialVerseStrings.SeeWhatCustomersSay,
    info: SocialVerseStrings.GridInfo,
    content: (
      <img
        src={poster_grids}
        alt={`SocialV Create ${SocialVerseStrings.Grids}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    type: TypeSocialVerse.Educational,
    poster: poster_educational,
    text: SocialVerseStrings.WatchLearn,
    subtitle: SocialVerseStrings.EducationalSubtitle,
    info: SocialVerseStrings.EducationalInfo,
    content: (
      <img
        src={poster_educational}
        alt={`SocialV Create ${SocialVerseStrings.WatchLearn}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
  {
    type: TypeSocialVerse.Spheres,
    poster: poster_spheres,
    text: SocialVerseStrings.Spheres,
    subtitle: SocialVerseStrings.SpheresSubtitle,
    info: SocialVerseStrings.SpheresInfo,
    content: (
      <img
        src={poster_spheres}
        alt={`SocialV Create ${SocialVerseStrings.Spheres}`}
        width={'765px'}
        height={'300px'}
        loading="lazy"
      />
    ),
  },
];

//// SVButton
interface ISVButtonProps {
  disabled?: boolean;
  startIcon?: React.ReactNode;
  sx?: SxProps;
  variant: 'contained' | 'outlined';
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  children?: string | React.ReactNode | ReactJSXElement;
  isDisabled?: (id: string) => boolean;
  id?: string;
}

export const SVButton: FC<ISVButtonProps> = (props) => {
  const { sx, variant, startIcon, className, handleClick, children, disabled = false, id } = props;
  const classes = useSocialVerseStyles();

  return (
    <Button
      disabled={disabled}
      sx={sx}
      variant={variant}
      startIcon={startIcon}
      classes={{ disabled: classes.disabldedButton, root: className }}
      className={clsx(classes.svButton, {
        [classes.containedBtn]: variant === 'contained',
        [classes.outlinedBtn]: variant === 'outlined',
      })}
      onClick={handleClick}
      id={id}
    >
      {children}
    </Button>
  );
};

////// Empty card Add video
interface AddNewVideoProps {
  handleAddVideo: () => void;
  viewMode: string;
}

export const AddNewVideoCard: FC<AddNewVideoProps> = ({ viewMode, handleAddVideo }) => {
  const classes = useSocialVerseStyles();
  const renderCards = () => (
    <Grid container item md={12} gap={1} alignItems="flex-end">
      <Grid item md={2}>
        <Box
          sx={{ maxHeight: '170px !important', height: '170px !important' }}
          className={classes.previewEmptyWrapper}
        >
          <IconButton
            onClick={() => {
              handleAddVideo();
            }}
            className={classes.addButton}
          >
            <>+</>
          </IconButton>
        </Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.5' }}></Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.3' }}></Box>
      </Grid>
      <Grid item md={2}>
        <Box className={classes.previewEmptyWrapper} sx={{ opacity: '0.1' }}></Box>
      </Grid>
    </Grid>
  );
  const renderBubbles = () => (
    <Grid container item md={12} sx={{ width: '100%' }}>
      <Grid item className={classes.previewEmptyBubbles}>
        <IconButton
          onClick={() => {
            handleAddVideo();
          }}
          className={classes.addButton}
        >
          <Add />
        </IconButton>
      </Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.5' }}></Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.3' }}></Grid>
      <Grid item className={classes.previewEmptyBubbles} sx={{ opacity: '0.1' }}></Grid>
    </Grid>
  );
  const renderContent = () => {
    if (viewMode === 'blocks') {
      return renderCards();
    } else if (viewMode === 'circles') {
      return renderBubbles();
    } else {
      <></>;
    }
  };
  return <>{renderContent()}</>;
};

///// Open webapp component

interface Props {
  blur?: boolean;
}

export const useOpenWebAppStyles = makeStyles<Theme, Props>((theme) => ({
  Container: (props) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexWrap: 'nowrap',
    filter: props.blur ? 'blur(4px)' : 'none',
    pointerEvent: props.blur ? 'none' : 'auto',
  }),
  Badge: {
    '& .MuiBadge-badge': {
      backgroundColor: '#EA3475',
      color: theme.palette.common.white,
      fontSize: '10px',
      fontWeight: 900,
    },
  },
  Link: {
    color: theme.palette.primary.main,
    '& :hover': {
      // outline: '1px solid',
    },
  },
}));

interface IOpenWebAppProps {
  messages?: number;
}

export const OpenWebApp: FC<IOpenWebAppProps> = ({ messages = 0 }) => {
  // const handleClickBell = () => {};
  const {
    venue: { id: venueId },
  } = useTypedSelector((state) => state.venue);
  const FTEIsFinished = useIsFteFinished();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { isStarted: isQuickstartFlow } = useQuickStartLaunchPad();

  const isOnProfilePage =
    pathname === WebsiteRoutes.ProfileAccounts || pathname === WebsiteRoutes.ProfileProfile;
  const styles = useOpenWebAppStyles({ blur: !FTEIsFinished && !isOnProfilePage });

  const { isTopGolfClient } = useClientType();

  useEffect(() => {
    if (venueId) {
      dispatch(
        getPrimaryCampaign({
          venueId,
        }),
      );
    }
  }, [dispatch, venueId]);

  return (
    <Grid container className={styles.Container}>
      {!isOnProfilePage && !isQuickstartFlow && !isTopGolfClient && <ShortcutsMenu />}
      <Grid
        item
        sx={{
          paddingLeft: '2px',
          pointerEvents: isOnProfilePage ? 'auto' : FTEIsFinished ? 'auto' : 'none',
        }}
      >
        <AccountMenu />
      </Grid>
      {/* <Grid item sx={{ paddingLeft: '2px' }}>
        <LogoutSection isTopbarPlaced />
      </Grid> */}
      {/* <Grid item sx={{ paddingLeft: '2px' }}>
        <IconButton onClick={handleClickBell} disabled>
          <Badge badgeContent={messages} className={styles.Badge}>
            <BellIcon />
          </Badge>
        </IconButton>
      </Grid> */}
    </Grid>
  );
};

interface SocialverseFormatsCarouselProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  setValue: (value: number) => void;
  isHealthCare: boolean;
  title: string;
  description: string;
  viewMode?: TypeSocialVerse;
}

export const SocialverseFormatsCarousel: FC<SocialverseFormatsCarouselProps> = ({
  value,
  handleChange,
  setValue,
  isHealthCare,
  title,
  description,
  viewMode,
}) => {
  const classes = useNoSocialVersesStyles();
  const { currentSocialVerseVideos } = useTypedSelector((state) => state.SocialVerseSlice);
  const displayedSVTabs = viewMode
    ? SVTabs.filter(
        (item) =>
          item.type !== viewMode &&
          (currentSocialVerseVideos.length <= 1 || !isEducationalView(item.type)),
      )
    : SVTabs;
  return (
    <>
      <Typography className={classes.titleCreateNewSV}>{title}</Typography>
      <Typography variant="body1" className={classes.textContentNewSV}>
        {description}
      </Typography>
      <Grid container className={classes.imageContainer}>
        <Box>
          <Box></Box>
          <Box className={classes.CarouselTab}>
            <Typography className={classes.tabTitle}>{displayedSVTabs[value].text}</Typography>
            <Typography className={classes.textContentCustomers}>
              {isHealthCare
                ? displayedSVTabs[value].subtitle.replace('customers', 'patients')
                : displayedSVTabs[value].subtitle}
            </Typography>
            <Box className={classes.CarouselWrapper}>
              <Carousel
                tabs={displayedSVTabs}
                activeTabIndex={value}
                onActiveTabUpdate={(newTab) => {
                  setValue(newTab);
                }}
                onAutoScroll={() => {}}
                handleTabClick={(newTab) => {}}
              >
                {displayedSVTabs.map((tab) => (
                  <CarouselItem key={tab.text}>{tab.content}</CarouselItem>
                ))}
              </Carousel>
            </Box>
          </Box>

          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              sx={{
                '.MuiTabs-indicator': {
                  top: '0px',
                  height: '4px',
                },
              }}
            >
              {displayedSVTabs.map((item, index) => {
                return <Tab label={item.text} className={classes.SVTab} key={item.type} />;
              })}
            </Tabs>
          </Box>
        </Box>
      </Grid>
      <Typography className={classes.textContentNewSV} style={{ marginBottom: '15px' }}>
        {displayedSVTabs[value].info}
      </Typography>
    </>
  );
};
