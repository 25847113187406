import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { TypeSocialVerse } from '../../constants/constants';

export const useVideoCardStyles = makeStyles<Theme, { viewMode?: string }>((theme: Theme) => ({
  videoCardWrapper: {
    position: 'relative',
    padding: '10px',
    borderRadius: 15,
    cursor: 'move',
    minHeight: '80px',
  },
  videoCard: (props) => ({
    width: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? 150
      : 100,
    height: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? 150
      : '177px',
    aspectRatio: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? '16/16'
      : '9/16',
    borderRadius: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? '50%'
      : 15,
    border: '1px solid transparent',
    background: '#ccc',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }),
  icon: {
    width: 35,
    height: 35,
    borderRadius: '50%',
    color: '#fff',
  },
  closeButton: (props) => ({
    position: 'absolute',
    top: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? '12px'
      : '-2px',
    right: [TypeSocialVerse.Circles, TypeSocialVerse.Spheres].includes(
      props.viewMode as TypeSocialVerse,
    )
      ? '12px'
      : '-2px',
    border: '1px solid transparent',
    backgroundColor: '#8B89A0',
    '&:hover': {
      color: '#FFF',
      backgroundColor: '#8B89A0 !important',
      border: `1px solid ${theme.palette.primary.main}`,
    },
  }),
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(30px)',
  },
  thumbnailButton: {
    position: 'absolute',
    bottom: '15px',
    right: '15px',
    background: 'rgba(0, 0 , 0, 0.2)',
    backdropFilter: 'blur(30px)',
    padding: '4px',
    borderRadius: '8px',

    '& svg': {
      width: '18px',
      height: '18px',
    },
  },
  startMoving: {
    opacity: '0.3',
    border: '1px dashed #ccc',
  },
  stopMoving: {
    opacity: 1,
    border: 'none',
  },
}));
