import { FC } from 'react';
import {
  useRouteLeavingGuard,
  UseRouteLeavingGuardOptions,
} from '../../../hooks/useRouteLeavingGuard';
import { UnsavedChangesModalContent } from './UnsavedChangesModalContent';

interface UnsavedChangesModalProps {
  isUnsavedChangesOnPage: boolean;
  ignoreSubroutes?: UseRouteLeavingGuardOptions['ignoreSubroutes'];
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  isUnsavedChangesOnPage,
  ignoreSubroutes,
}) => {
  const { renderGuardComponent, confirmNavigation, cancelNavigation, showGuard } =
    useRouteLeavingGuard(isUnsavedChangesOnPage, { ignoreSubroutes });

  return (
    <>
      {renderGuardComponent()}
      <UnsavedChangesModalContent
        show={showGuard}
        onClose={cancelNavigation}
        onConfirm={confirmNavigation}
        onCancel={cancelNavigation}
      />
    </>
  );
};
