import { FC, useEffect } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './SearchResultsList.styles';

interface Props {
  anchorEl: HTMLElement | null;
  searchItems: string[];
  clearItems: () => void;
  onClick: (searchText: string) => void;
  topOffset?: string;
}

export const SearchResultsList: FC<Props> = ({
  anchorEl,
  searchItems,
  clearItems,
  onClick,
  topOffset,
}) => {
  const classes = useStyles();

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const container = target.closest('#search-list');
    const anchorId = anchorEl?.id || anchorEl?.firstElementChild?.id;

    const anchorElement = anchorId ? target.closest(`#${anchorId}`) : null;

    if (!container && !anchorElement) {
      clearItems();
    }
  };

  useEffect(() => {
    if (anchorEl) {
      window.addEventListener('mousedown', handleClickOutside);
    }
    return () => window.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);

  return (
    <Box
      className={classes.container}
      style={{
        top: topOffset || '45px',
      }}
      id="search-list"
    >
      {searchItems.map((item, index) => (
        <Box
          className={classes.listItem}
          key={index}
          onClick={() => {
            onClick(item);
            clearItems();
          }}
        >
          {item}
        </Box>
      ))}
    </Box>
  );
};
