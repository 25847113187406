import PerspectiveImage1 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective1.png';
import PerspectiveImage2 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective2.png';
import PerspectiveImage3 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective3.png';
import PerspectiveImage4 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective4.png';
import PerspectiveImage5 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective5.png';
import PerspectiveImage6 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective6.png';
import PerspectiveImage7 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective7.png';
import PerspectiveImage8 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective8.png';
import PerspectiveImage9 from '../../../../../common/assets/newDesign/SocialVerse/previews/perspective9.png';
import previewImage from '../../../../../common/assets/newDesign/SocialVerse/previews/previewPlaceholder.png';

export const previewPerspective = [
  {
    card: PerspectiveImage1,
    name: 'First L',
  },
  {
    card: PerspectiveImage2,
    name: 'First L',
  },
  {
    card: PerspectiveImage3,
    name: 'First L',
  },
  {
    card: PerspectiveImage4,
    name: 'First L',
  },
  {
    card: PerspectiveImage5,
    name: 'First L',
  },
  {
    card: PerspectiveImage6,
    name: 'First L',
  },
  {
    card: PerspectiveImage7,
    name: 'First L',
  },
  {
    card: PerspectiveImage8,
    name: 'First L',
  },
  {
    card: PerspectiveImage9,
    name: 'First L',
  },
];

export const previewPerspectiveMobile = [
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
];

export const getPreviewPerspectiveCards = (numberOfCards: number) => {
  const previewCards: typeof previewPerspective = [];
  const firstIndex = Math.floor(4 - (numberOfCards / 2 - 1));

  for (let i = firstIndex; i < firstIndex + numberOfCards; i++) {
    previewCards.push(previewPerspective[i]);
  }

  return previewCards;
};
