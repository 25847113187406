import { FC, MouseEvent } from 'react';
import { Dialog, DialogContent, IconButton, Card, CardActionArea } from '@mui/material';
import { usePlaybackVideoModal } from './components/VideoPlaybackModal.style';
import { CloseIcomModal } from '../../../assets/newDesign/CloseIconModal';
import { PlayerControls } from '../../VideoPlayer/PlayerControls';
import { removeFilterFromUrl } from '../../../../services/utilities';

interface VideoPlaybackModalProps {
  isOpen: boolean;
  storyUrl: string;
  isTopGolf: boolean;
  onClose: () => void;
  storyId?: string;
  entity?: 'heroes' | 'campaigns';
}
export const TooltipVideoPlaybackModal: FC<VideoPlaybackModalProps> = ({
  isOpen,
  storyUrl,
  isTopGolf,
  onClose,
  storyId,
  entity,
}) => {
  const classes = usePlaybackVideoModal();
  const url = isTopGolf ? storyUrl : removeFilterFromUrl(storyUrl);
  const handleCloseModal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={handleCloseModal}
      open={isOpen}
      onMouseUp={(e: MouseEvent) => e.stopPropagation()}
    >
      <IconButton
        className={classes.closeModal}
        type="button"
        aria-label="Close modal"
        onClick={handleCloseModal}
        size="large"
      >
        <CloseIcomModal />
      </IconButton>
      <DialogContent
        classes={{ root: classes.dialogText }}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Card className={isTopGolf ? classes.TopGolfVideoCard : classes.VideoCard}>
          <CardActionArea className={classes.actionArea}>
            <PlayerControls
              isTopgolf={isTopGolf}
              videoUrl={url}
              videoId={storyId}
              entity={entity}
              preventDefaults
            />
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};
