import { FC } from 'react';
import { theme } from '../../theme';

export const RemoveImageIcon: FC = () => {
  return (
    <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 1L1 7M1 1L7 7"
        stroke={theme.palette.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
