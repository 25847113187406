import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme, { isMobile?: boolean }>((theme: Theme) => ({
  container: {
    padding: ({ isMobile }) => (isMobile ? '250px 0' : '122px 0'),
    position: 'relative',
  },
  wrapper: {
    width: 'fit-content',
    minWidth: '170px',
    height: '72px',
    display: 'flex',
    alignItems: 'center',
    padding: '13px',
    paddingRight: '20px',
    borderRadius: '35px',
    margin: '0 auto',
    overflow: 'hidden',

    '&:hover': {
      '& > div:last-child': {
        display: 'block',
      },
    },
  },
  playIcon: {
    marginRight: '13px',
    minWidth: '48px',
    height: '48px',
  },
  title: {
    fontWeight: 700,
    lineHeight: '120%',
    letterSpacing: '0.32px',
  },
  supportingText: {
    fontSize: '12px',
    width: '150px',
    maxHeight: '36px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));
