import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { SocialVerseStrings } from '../../common/localization/en';
import { TypeSocialVerse } from '../../common/constants/constants';

interface IContext {
  setStoryVideoId: Dispatch<SetStateAction<string>>;
  storyVideoId?: string | undefined;
  withEditThumbnailOpen?: boolean;
  setWithEditThumbnailOpen?: Dispatch<SetStateAction<boolean>>;
}

export const SocialVerseTableIdContext = createContext<IContext>({} as any);
export const useVideoTableIdContext = () => useContext(SocialVerseTableIdContext);

export const checkSocialVerseViewMode = (mode: string) => {
  switch (mode) {
    case TypeSocialVerse.Cards:
      return SocialVerseStrings.Classic;
    case TypeSocialVerse.Perspective:
      return SocialVerseStrings.Perspective;
    case TypeSocialVerse.Grid:
      return SocialVerseStrings.Grid;
    case TypeSocialVerse.Educational:
      return SocialVerseStrings.WatchAndLearn;
    case TypeSocialVerse.Spheres:
      return SocialVerseStrings.Spheres;
    default:
      return '';
  }
};
