export const LinkNewIcon = () => {
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.0272 13.4201C10.681 12.0739 10.681 9.89129 12.0272 8.5451L15.1348 5.4375C16.481 4.09131 18.6636 4.09131 20.0098 5.4375C21.356 6.7837 21.356 8.96631 20.0098 10.3125L17.8367 12.4855"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M14.2971 11.1509C15.6432 12.4971 15.6432 14.6798 14.2971 16.0259L11.1895 19.1335C9.84326 20.4797 7.66065 20.4797 6.31445 19.1335C4.96826 17.7873 4.96826 15.6047 6.31445 14.2585L8.48748 12.0855"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M9.99017 6.52686L9.99023 4.10049"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M4.2787 9.4282L6.70508 9.42813"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M8.09702 7.73112L6.25786 5.89196"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M15.9571 18.1736L15.957 20.6"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M21.6686 15.2722L19.2422 15.2723"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
        <path
          d="M17.8502 16.9693L19.6894 18.8085"
          stroke="inherit"
          strokeWidth="1.3"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};
