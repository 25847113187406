import { FC, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { SearchFilterStrings } from '../../localization/en';
import { useTypedSelector } from '../../../store';
import { useStyles } from './CampaignFilterSelect.styles';
import { DotIcon } from '../../assets/DotIcon';
import { StarIcon } from '../../assets/StarIcon';

interface CampaignItem {
  name: string;
  id: string;
  selected: boolean;
  isActive: boolean;
  isPrimary?: boolean;
}

interface Props {
  onCampaignsUpdate: (campaignIds: string[]) => void;
}

export const CampaignFilterSelect: FC<Props> = ({ onCampaignsUpdate }) => {
  const classes = useStyles();
  const [campaignItems, setCampaignItems] = useState<CampaignItem[]>([]);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { items } = useTypedSelector((state) => state.campaigns);

  useEffect(() => {
    setCampaignItems(
        items.map<CampaignItem>((item) => ({
          name: item.name,
          id: item.id,
          selected: false,
          isActive: !item.endedAt,
          isPrimary: item.isPreferred,
        })),
    );
  }, [items]);

  useEffect(() => {
    const selectedIds = campaignItems.filter((item) => item.selected).map((item) => item.id);
    setSelectedIds(selectedIds);
    onCampaignsUpdate(selectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignItems]);

  return (
      <FormControl className={classes.container}>
        <InputLabel className={classes.inputLabel}>{SearchFilterStrings.SelectCampaigns}</InputLabel>
        <Select
            multiple
            value={campaignItems.filter((items) => items.selected)}
            renderValue={(items) => {
              return items
                  .filter((item) => item.selected)
                  .map((item) => item.name)
                  .join(', ')
                  .trim();
            }}
            onOpen={() => {
              setIsSelectOpen(true);
            }}
            onClose={() => {
              setIsSelectOpen(false);
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  borderRadius: '12px',
                  maxHeight: '400px',
                  '&::-webkit-scrollbar': {
                    width: '10px',
                  },
                  '&::-webkit-scrollbar-track': {
                    background: '#fff',
                    height: '20px !important',
                    maxHeight: '20px !important',
                    borderRadius: '12px',
                    margin: '5px 0',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    background: '#d9d4ff',
                    borderRadius: '5px',
                    height: '40px !important',
                    cursor: 'pointer',
                  },
                },
              },
            }}
            sx={{
              border: isSelectOpen
                  ? '2px solid blue'
                  : selectedIds.length > 0
                      ? '1px solid blue'
                      : '1px solid #cacae4',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none!important',
              },
              '& .MuiOutlinedInput-notchedOutline:focus': {
                border: 'none!important',
              },
            }}
        >
          {campaignItems.map((item) => (
              <MenuItem
                  key={item.id}
                  value={item.id}
                  onClick={() => {
                    setCampaignItems((prev) => {
                      return prev.map((prevItem) => {
                        return prevItem.id === item.id
                            ? { ...prevItem, selected: !prevItem.selected }
                            : prevItem;
                      });
                    });
                  }}
                  sx={{
                    '& .MuiListItemText-root': {
                      flex: 'unset !important',
                      marginRight: '10px',
                    },
                  }}
              >
                <Checkbox checked={item.selected} />

                <DotIcon color={item.isActive ? '#12B76A' : '#D31F1F'} />
                <ListItemText
                    primary={item.name}
                    sx={{
                      marginLeft: '10px',
                      '& div': {
                        flex: 'unset !important',
                      },
                    }}
                />
                {item.isPrimary && (
                    <Box className={classes.iconWrapper}>
                      <StarIcon />
                    </Box>
                )}
              </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};
