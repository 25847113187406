import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { WebsiteRoutes } from '../../../common/constants/routes';
import { NewDetailsModal } from '../../../common/components/NewDetailsModal/NewDetailsModal';
import { TeamContainer } from './TeamSettings.style';
import { TableSection } from './TableSection/TableSection';
import { useSegmentPageCall } from '../../../hooks/useSegment';
import { useAppDispatch } from '../../../store';
import { setUserDataSorting } from '../../../store/slices/heroesSlice';
import { defaultPagination } from '../../../common/constants/constants';

export const TeamSettings: FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useAppDispatch();

  dispatch(setUserDataSorting(defaultPagination.sortByLastCreated[0]));

  useSegmentPageCall();

  const close = () => {
    history.push(WebsiteRoutes.TeamSettings);
  };

  return (
    <TeamContainer>
      <TableSection />
      <NewDetailsModal isOpen={!!params.id} userId={params.id} handleClose={close} />
    </TeamContainer>
  );
};
