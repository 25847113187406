import { Stack, Typography } from '@mui/material';
import React from 'react';
import { RoundedButton } from '../../Button/RoundedButton';
import { GBPSyncModalBase } from './GBPModalBase';

interface GBPSyncUpdateModalProps {
  show: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  unsyncMode?: boolean;
}

export const GBPSyncUpdateModal: React.FC<GBPSyncUpdateModalProps> = ({
  show,
  onClose,
  onConfirm,
  unsyncMode,
}) => {
  return (
    <GBPSyncModalBase show={show} onClose={onClose}>
      <Stack spacing="10px" alignItems="center">
        <Typography
          fontWeight="500"
          fontSize="18px"
          color="#1A1538"
          letterSpacing="0.36px"
          lineHeight="150%"
          width="300px"
          textAlign="center"
        >
          Would you like to {unsyncMode ? 'unsync' : 'sync'} this SocialVerse?
        </Typography>
        <Typography
          fontWeight="700"
          fontSize="12px"
          color="#8B89A0"
          letterSpacing="0.24px"
          lineHeight="150%"
          textAlign="center"
        >
          NOTE: A synced SocialVerse could improve your Business Profile’s local ranking in Google
          search results.
        </Typography>
        <Stack direction="row" spacing="10px">
          <RoundedButton
            outlined
            title="Cancel"
            onClick={(e) => {
              e.stopPropagation();
              onClose?.();
            }}
          />
          <RoundedButton
            title="Yes"
            onClick={(e) => {
              e.stopPropagation();
              onConfirm?.();
            }}
          />
        </Stack>
      </Stack>
    </GBPSyncModalBase>
  );
};
