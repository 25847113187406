import styled from 'styled-components';
import { theme } from '../../../../../../theme';

export const IndicatorWrapper = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  border-radius: 38px;
  background-color: #f9f9fc;
  border: 1px solid #dcdbe4;
  padding: 0 16px;
  &.active-i {
    color: ${theme.palette.success.dark};
  }
  &.inactive-i {
    color: ${theme.palette.text.primary};
  }
  &.archived-i {
    color: ${theme.palette.secondary.dark};
  }
  &.small-i {
    width: auto;
    border: none;
    background-color: transparent;
    padding: 0;
  }
`;
export const IndicatorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.active-i {
    color: #24b947;
  }
  &.inactive-i {
    color: #8b89a0;
  }
  &.archived-i {
    color: ${theme.palette.secondary.dark};
  }
`;

export const Indicator = styled.span`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin-right: 8px;
  &.active-i {
    background-color: ${theme.palette.success.main};
  }
  &.inactive-i {
    background-color: ${theme.palette.grey[300]};
  }
  &.archived-i {
    background-color: ${theme.palette.secondary.main};
  }
`;
