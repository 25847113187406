import React, { FC } from 'react';
import { useStyles } from './InviteLeftSection.styles';
import { List, ListItem } from '@mui/material';

export interface FieldItem {
  text: string;
  isBold: boolean;
}

interface Props {
  items: FieldItem[];
  color?: string;
  fontSize?: string;
  margin?: string;
}

export const FieldsList: FC<Props> = ({ items, color, fontSize, margin }) => {
  const classes = useStyles();

  return (
    <List
      sx={{ listStyleType: 'disc' }}
      className={classes.headersList}
      style={{
        margin: margin || '0 0 0 15px',
      }}
    >
      {items.map((item) => {
        return (
          <ListItem
            sx={{
              display: 'list-item',
              fontWeight: item.isBold ? 600 : 300,
              color: color || '#101828',
              marginLeft: '15px',
              fontSize: fontSize || '16px',
            }}
            key={item.text}
          >
            {item.text}
          </ListItem>
        );
      })}
    </List>
  );
};
