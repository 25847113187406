import { FC } from 'react';
interface IChangeThumnailIconProps {
  color?: string;
}
export const ChangeThumnailIcon: FC<IChangeThumnailIconProps> = ({ color }) => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.14258 10.1428C10.2471 10.1428 11.1426 9.24739 11.1426 8.14282C11.1426 7.03825 10.2471 6.14282 9.14258 6.14282C8.03801 6.14282 7.14258 7.03825 7.14258 8.14282C7.14258 9.24739 8.03801 10.1428 9.14258 10.1428Z"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1426 2.14282H9.14258C4.14258 2.14282 2.14258 4.14282 2.14258 9.14282V15.1428C2.14258 20.1428 4.14258 22.1428 9.14258 22.1428H15.1426C20.1426 22.1428 22.1426 20.1428 22.1426 15.1428V10.1428"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2825 2.73273L15.6525 6.36273C15.5125 6.50273 15.3725 6.77273 15.3525 6.97273L15.1525 8.36273C15.0825 8.86273 15.4325 9.21273 15.9325 9.14273L17.3225 8.94273C17.5125 8.91273 17.7925 8.78273 17.9325 8.64273L21.5625 5.01273C22.1925 4.38273 22.4825 3.66273 21.5625 2.74273C20.6325 1.80273 19.9125 2.10273 19.2825 2.73273Z"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7627 3.25293C19.0727 4.35293 19.9327 5.21293 21.0327 5.52293"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.8125 19.0929L7.7425 15.7829C8.5325 15.2529 9.6725 15.3129 10.3825 15.9229L10.7125 16.2129C11.4925 16.8829 12.7525 16.8829 13.5325 16.2129L17.6925 12.6429C18.4725 11.9729 19.7325 11.9729 20.5125 12.6429L22.1425 14.0429"
        stroke={color || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
