import { FC } from 'react';

export const MedalIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 15C15.7279 15 18.75 12.0899 18.75 8.5C18.75 4.91015 15.7279 2 12 2C8.27208 2 5.25 4.91015 5.25 8.5C5.25 12.0899 8.27208 15 12 15Z"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.51974 13.52L7.50977 20.9C7.50977 21.8 8.13977 22.24 8.91977 21.87L11.5998 20.6C11.8198 20.49 12.1898 20.49 12.4098 20.6L15.0998 21.87C15.8698 22.23 16.5098 21.8 16.5098 20.9V13.34"
      stroke="inherit"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
