import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface PhonePreviewStyleProps {
  color: 'white' | 'black';
  width?: string;
}

export const useStyles = makeStyles<Theme, PhonePreviewStyleProps>(() => ({
  root: (props) => ({
    '--device-silver': '#fff',
    '--device-silver-dark': '#e6e6e6',
    '--device-silver-panel': '#fff',
    '--iphone-header-height': '18px',
    '--iphone-inner-padding': '16px',
    '--naw-width': 'calc(100% - 32px)',

    position: 'relative',
    margin: '0 auto',
    maxWidth: props.width ? 295 : 250,
    userSelect: 'none',
    minHeight: '500px',
    // filter: 'drop-shadow(0px 0px 4px black)',
  }),
  frame: {
    background: 'var(--device-silver-panel)',
    borderRadius: 40,
    boxShadow: 'inset 0 0 2px 2px var(--device-silver-dark), inset 0 0 0 7px var(--device-silver)',
    width: '100%',
    padding: 'var(--iphone-inner-padding)',
    overflow: 'hidden',
    aspectRatio: '428 / 868',
    minHeight: '500px',
  },
  content: {
    borderRadius: 24,
    minHeight: '500px',
    height: '100%',
    width: '100%',
  },
  stripe: {
    '&::after, &::before': {
      border: 'solid rgba(51, 51, 51, 0.25)',
      borderWidth: '0 4px',
      content: '""',
      height: 4,
      left: 0,
      position: 'absolute',
      width: '100%',
      zIndex: 9,
    },
    '&::after': {
      top: 51,
    },
    '&::before': {
      bottom: 51,
    },
  },
  header: {
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    height: 'var(--iphone-header-height)',
    left: '50%',
    marginLeft: -61,
    position: 'absolute',
    top: 'var(--iphone-inner-padding)',
    width: 122,
    zIndex: 3,

    '&::after, &::before': {
      content: '""',
      width: 6,
      height: 6,
      position: 'absolute',
      top: 0,
    },
    '&::after': (props) => ({
      background:
        props.color === 'white'
          ? 'radial-gradient(circle at bottom left, transparent 0, transparent 75%, var(--device-silver-panel) 75%, var(--device-silver-panel) 100%)'
          : 'var(--black)',
      left: -6,
    }),
    '&::before': (props) => ({
      background:
        props.color === 'white'
          ? 'radial-gradient(circle at bottom right, transparent 0, transparent 75%, var(--device-silver-panel) 75%, var(--device-silver-panel) 100%)'
          : 'var(--black)',
      right: -6,
    }),
  },
  sensors: {
    '&::after, &::before': {
      content: '""',
      position: 'absolute',
      zIndex: 3,
    },
    '&::after': {
      background: '#444',
      borderRadius: 3,
      height: 4,
      left: '50%',
      marginLeft: -15,
      top: 19,
      width: 30,
    },
    '&::before': {
      background: '#444',
      borderRadius: '50%',
      height: 8,
      left: '50%;',
      marginLeft: 24,
      top: 17,
      width: 8,
    },
  },

  btns: {
    // background: 'var(--device-silver-dark)',
    height: 20,
    left: '-4px',
    position: 'absolute',
    top: 70,
    width: 3,
    backgroundColor: '#000',
    borderRadius: '4px 0 0 4px',

    '&::after, &::before': {
      backgroundColor: '#000',
      content: '" "',
      height: 38,
      left: 0,
      position: 'absolute',
      width: 3,
      borderRadius: '4px 0 0 4px',
    },
    '&::after': {
      top: 36,
    },
    '&::before': {
      top: 84,
    },
  },

  volume: {
    background: '#000',
    position: 'absolute',
    top: 120,
    right: '-4px',
    width: 3,
    height: 60,
    borderRadius: '0 4px 4px 0',
  },
  topBar: {
    zIndex: 2,
    position: 'absolute',
    top: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'var(--naw-width)',
    height: '40px',
    backgroundColor: 'rgb(55 55 55)',
    borderRadius: '16px 16px 0 0',
  },
  time: {
    position: 'absolute',
    top: '4px',
    left: '12px',
    color: '#fff',
    fontSize: '10px',
  },
  webSite: {
    position: 'absolute',
    bottom: '4px',
    left: '0',
    width: '100%',
    color: '#fff',
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 400,
  },
  connection: {
    position: 'absolute',
    top: '4px',
    right: '10px',
    color: '#fff',
    fontSize: '10px',
    '& svg': {
      fontSize: '10px',
    },
  },
  navBtns: {
    position: 'absolute',
    bottom: '16px',
    left: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 'var(--naw-width)',
    height: '44px',
    backgroundColor: 'rgb(55 55 55)',
    borderRadius: '0 0 16px 16px',
    padding: '0 10px',

    '& svg': {
      fontSize: '14px',
      color: 'rgb(42 125 192)',
    },

    '&:before': {
      position: 'absolute',
      zIndex: 4,
      bottom: '2px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '70px',
      height: '2px',
      backgroundColor: '#fff',
      content: '"."',
      color: 'transparent',
      borderRadius: '4px',
    },
  },
  arrRight: {
    transform: 'rotate(180deg)',
    color: '#8B89A0 !important',
  },
}));
