import React from 'react';

const WebAppIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.875 22.3995H9.12498C4.89873 22.3995 3.61456 21.1153 3.61456 16.8891V7.30575C3.61456 3.0795 4.89873 1.79533 9.12498 1.79533H14.875C19.1012 1.79533 20.3854 3.0795 20.3854 7.30575V16.8891C20.3854 21.1153 19.1012 22.3995 14.875 22.3995ZM9.12498 3.23283C5.69415 3.23283 5.05206"
        fill="none"
      />
      <path
        d="M13.9166 6.587H10.0833C9.6904 6.587 9.36456 6.26116 9.36456 5.86825C9.36456 5.47533 9.6904 5.1495 10.0833 5.1495H13.9166C14.3096 5.1495 14.6354 5.47533 14.6354 5.86825C14.6354 6.26116 14.3096 6.587 13.9166 6.587Z"
        fill="rgb(85, 85, 85)"
      />

      <path
        d="M12 19.6299C10.7829 19.6299 9.79584 18.6428 9.79584 17.4257C9.79584 16.2087 10.7829 15.2216 12 15.2216C13.2171 15.2216 14.2042 16.2087 14.2042 17.4257C14.2042 18.6428 13.2171 19.6299 12 19.6299ZM12 16.6495C11.5783 16.6495 11.2333 16.9945 11.2333   "
        fill="none"
      />
    </svg>
  );
};

export default WebAppIcon;
