import { FC } from 'react';

interface DNDIconProps {
  color: string;
}
export const DNDIcon: FC<DNDIconProps> = ({ color }) => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34 29.0007C37.912 29.0007 41.0834 25.8293 41.0834 21.9173C41.0834 18.0053 37.912 14.834 34 14.834C30.088 14.834 26.9167 18.0053 26.9167 21.9173C26.9167 25.8293 30.088 29.0007 34 29.0007Z"
      stroke={color}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M45.5 36.5C43 34.5 40.7151 33.25 34.0001 33.25C27.2851 33.25 21.8309 37.6842 21.8309 43.1667"
      stroke={color}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.6666 32.0006V24.0006C58.6666 10.6673 53.3333 5.33398 40 5.33398H24C10.6666 5.33398 5.33331 10.6673 5.33331 24.0006V40.0006C5.33331 53.334 10.6666 58.6673 24 58.6673H32"
      stroke={color}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M55.8933 47.574L51.5467 49.0407C50.3467 49.4407 49.3866 50.374 48.9866 51.6006L47.52 55.9473C46.2666 59.7073 40.9867 59.6273 39.8133 55.8673L34.88 40.0006C33.92 36.854 36.8266 33.9206 39.9466 34.9073L55.84 39.8406C59.5733 41.014 59.6266 46.3206 55.8933 47.574Z"
      stroke={color}
      strokeWidth="3.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
