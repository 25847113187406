import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: 'unset',
    margin: '0 auto',
    height: '718px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '15px',
  },
  subheading: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
    textAlign: 'left',
    width: '100%',
    height: '80px',
    paddingTop: '20px',
    borderBottom: '1px solid #DCDBE4',
  },
  paymentWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '242px',
    marginTop: '120px',
    maxWidth: '1000px',
    borderRadius: '10px',
    border: '1px solid #DCDBE4',
    padding: '47px 0 44px',
  },
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: '700',
    textAlign: 'center',
  },
  sectionSubTitle: {
    fontSize: '18px',
    textAlign: 'center',
  },
  btnOpen: {
    width: '250px !important',
    height: '45px',
  },
  btnPayment: {
    width: '325px !important',
    height: '45px',
    whiteSpace: 'nowrap',
  },
  footer: {
    width: '100%',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid #DCDBE4',
  },
  footerPanel: {
    width: '504px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerLink: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#DCDBE4',
  },
}));
