import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideoComponentStyles = makeStyles((theme: Theme) => ({
  TopGolfVideoCard: {
    height: 281,
    marginLeft: 20,
  },
  VideoCard: {
    height: 281,
  },
  actionArea: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
    pointerEvents: 'none',
    cursor: 'pointer',
    background: 'rgba(178, 178, 178, 0.2)',
    backdropFilter: 'blur(4px)',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  MouseOverIcon: {
    backgroundColor: 'rgba(178, 178, 178, 0.65)',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  ChangeThumnailIcon: {
    position: 'absolute',
    bottom: '3.5%',
    right: '3.5%',
    width: 56,
    height: 56,
    borderRadius: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    backgroundColor: 'rgba(178, 178, 178, 0.2)',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  ChangeThumnailIconOver: {
    backgroundColor: 'rgba(178, 178, 178, 0.65)',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  actionButtonWrapper: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '7px 6px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionButton: {
    color: '#fff',
    width: '25px',
    height: '25px',
    borderRadius: '5px',
    backgroundColor: 'rgba(178, 178, 178, 0.20)',
    backdropFilter: 'blur(2px)',
    display: 'grid',
    placeItems: 'center',
  },
}));
