import { Box } from '@mui/material';
import React from 'react';
import { Loader } from '../components/Loader/Loader';

interface LayoutLoaderProps {
  leftOffset?: string;
  topOffset?: string;
}

export const LayoutLoader: React.FC<LayoutLoaderProps> = ({
  topOffset = '260px', // default top bar height + actionBar height
  leftOffset = '260px', // default sidebar width
}) => {
  return (
    <Box
      height={`calc(100vh - ${topOffset})`}
      width={`calc(100vw - ${leftOffset})`}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Loader />
    </Box>
  );
};
