import { FC } from 'react';

import { CommonList } from '../../../../common/components/list/CommonList';
import { createRewardsActivitiesContent, ActivityDTOModel } from './ActivitiesListSection.helper';

interface ActivitiesListSectionProps {
  items: ActivityDTOModel[];
}
export const ActivitiesListSection: FC<ActivitiesListSectionProps> = ({ items }) => {
  const contentDataTableContents = createRewardsActivitiesContent({
    items,
  });

  return <CommonList content={contentDataTableContents} />;
};
