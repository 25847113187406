import { Box, Grid, Typography } from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';

import { ActivitiesTypes } from '../../../../api/models/rewards';

export const useClasses = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  pointsInfoText: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.primary.main,
  },
  pointsBarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
  pointsTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '31px',
    borderRadius: '12px 12px 0px 0px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    gap: '10px',
  },
  pointsBlockWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0',
    borderRadius: '0px 0px 12px 12px',
    border: '1px solid var(--pageBackgroundColor)',
    backgroundColor: '#F0EFFA',
    height: '160px',
  },
  rewardPointsWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
    background: `${theme.palette.primary.main}1a`,
    borderRadius: 12,
    padding: '20px 30px',
  },
  pointsBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '6px',
  },
  points: {
    fontSize: '32px',
    color: ' #1A1538',
    fontWeight: '600',
    marginTop: '-8px',
  },
  targetPoints: {
    fontSize: '32px',
    color: theme.palette.primary.main,
    fontWeight: '600',
  },
  fullFilledPointsBlock: {
    maxWidth: '128px',
    padding: '15px 5px',
    borderRadius: '12px',
    backgroundColor: theme.palette.common.white,
  },
  fullFilledPoints: {
    color: theme.palette.common.deepDark,
  },
  fullfillBtn: {
    // width: 190,
    padding: '10px 25px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    gap: '7px',
    fontSize: '14px',
    lineHeight: '26px',
  },

  pointsInfoTextWarning: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.deepDark,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  },
  disabled: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: '#fff!important',
    opacity: 0.3,
  },

  tableHeader: {
    color: '#8B89A0',
    fontSize: '12px',
    fontWeight: 400,
  },
  activityImage: {
    height: '30px',
    width: '30px',
  },
  activityLogTableHeader: {
    border: '1px solid #DCDBE4',
    borderRadius: '10px 10px 0 0',
    borderBottom: '0',
    padding: '15px 25px',
  },
  inactiveCampaignWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
    padding: '20px',
    borderRadius: '12px',
    gap: '20px',
  },
  inactiveTitle: {
    color: theme.palette.primary.main,
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '28px',
  },
  inactiveText: {
    color: '#344054',
    fontSize: '14px',
    marginTop: '6px',
    width: '440px',
  },
  inactiveButton: {
    height: '54px',
    width: '357px',
  },
  activityTableItem: {
    border: '1px solid #DCDBE4',
    color: '#1A1538',
    borderBottom: '0',
    borderRight: '0',
    borderLeft: '0',
  },
  buttonsWrapper: {
    marginTop: '22px',
    width: '100%',
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rewardButton: {
    //  backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    width: '514px',
    '&:disabled': {
      '& p': {
        color: 'white',
      },
    },
  },
}));

type ColumnValue = string | number;
type ItemDate = string | Date;

export const tableConstructor = (
  classes: ClassNameMap,
  columnOne: ActivitiesTypes | ItemDate,
  columnTwo: ColumnValue,
  columnThree: ColumnValue,
  isHeader?: boolean,
  key?: string,
) => {
  if (isHeader) {
    return (
      <Grid container key={key} className={classes.activityLogTableHeader}>
        <Grid item xs={4}>
          <Typography className={classes.tableHeader}>Date</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography className={classes.tableHeader}>{columnTwo}</Typography>
        </Grid>
        <Grid item xs={4} paddingLeft="15px">
          <Typography className={classes.tableHeader}>{columnThree}</Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container key={key} className={classes.activityLogTable}>
      <Grid item xs={4}></Grid>
      <Grid item xs={4} paddingY="15px" className={classes.activityTableItem}>
        <Typography variant="body2">{columnTwo}</Typography>
      </Grid>
      <Grid item xs={4} paddingLeft="15px" paddingY="15px" className={classes.activityTableItem}>
        <Box display="flex">
          <Typography variant="body2">{columnThree}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
