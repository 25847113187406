import React, { FC, SetStateAction, useState } from 'react';
import { Button, FormHelperText, Grid, IconButton } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';

import { LoginPageStrings, VenueCreationWizardStrings } from '../../localization/en';
import { Title, Wrapper, TextField, Label } from './AccountsBox.style';
import { validationSchemaAccountBox, AccountBoxValues } from './AccountsBox.helper';
import { AccountsDTO } from '../../../api/models/venueCreation';
import { AdornmentPosition, TextFieldComponent } from '../TextField/TextField';

export interface AccountSetingsProps extends AccountsDTO {
  password: string;
  submited: boolean;
}

interface AccountsBoxProps {
  account: AccountSetingsProps;
  index: number;
  setStep1State: (value: SetStateAction<AccountSetingsProps[]>) => void;
}

export const AccountsBox: FC<AccountsBoxProps> = ({ account, index, setStep1State }) => {
  const [showPassword, setShowPassword] = useState(false);
  const PasswordSetFormik = useFormik({
    initialValues: { password: account.password },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: validationSchemaAccountBox,
    onSubmit: (values) => {
      submitForm(values);
    },
  });
  const calculateUpdate = (a: AccountSetingsProps[], values: AccountBoxValues) => {
    const result = [...a];
    result[index] = {
      ...account,
      password: values.password,
      submited: true,
    };
    return result;
  };
  const submitForm = (values: AccountBoxValues) => {
    setStep1State((prevAccounts: AccountSetingsProps[]) => calculateUpdate(prevAccounts, values));
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const { handleSubmit, values, handleChange, errors } = PasswordSetFormik;
  return (
    <Wrapper>
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
          <Grid item xs={9}>
            <Title>
              {VenueCreationWizardStrings.UserId.replace(':id', (index + 1).toString())}
            </Title>
          </Grid>
          <Grid item xs={4}>
            <Label>{VenueCreationWizardStrings.UserName}</Label>
          </Grid>
          <Grid item xs={5}>
            <TextField>{account.userAccountName}</TextField>
          </Grid>
          <Grid item xs={4}>
            <Label>{VenueCreationWizardStrings.UserEmail}</Label>
          </Grid>
          <Grid item xs={5}>
            <TextField>{account.userAccountEmail}</TextField>
          </Grid>
          <Grid item xs={4}>
            <Label>{VenueCreationWizardStrings.UserRoleTitle}</Label>
          </Grid>
          <Grid item xs={5}>
            <TextField>{account.userAccountRoleName}</TextField>
          </Grid>
          <Grid item xs={9}>
            <TextFieldComponent
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange}
              placeholder={LoginPageStrings.Password}
              label={LoginPageStrings.Password}
              disabled={account.submited || account.isRegistered}
              adornmentPosition={AdornmentPosition.End}
              adornment={
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              }
              fullWidth
            />

            {errors.password && (
              <FormHelperText id="standard-weight-helper-text">
                {LoginPageStrings.InvalidPassword}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={3}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={errors.password || account.submited || account.isRegistered ? true : false}
            >
              {account.isRegistered
                ? 'Registered'
                : account.submited
                ? VenueCreationWizardStrings.Submitted
                : VenueCreationWizardStrings.Submit}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Wrapper>
  );
};
