import { ClientTypes } from '../../api/models/common';
import { useTypedSelector } from '../../store';

export enum ClientCategory {
  Sv = 'SV',
  Uwm = 'UWM',
  SvHealth = 'SV_HEALTH',
  Travel = 'TRAVEL',
}

export function useClientType() {
  const { clientType, isLoading } = useTypedSelector((state) => state.venue.venue);

  const isTrubluClient = clientType === ClientTypes.TRUBLU;
  const isEtsClient = clientType === ClientTypes.ETS;
  const isFundraisingClient = clientType === ClientTypes.FUNDRAISING;
  const isSalesClient = clientType === ClientTypes.SALES;
  const isMarketingClient = clientType === ClientTypes.MARKETING;
  const isTopGolfClient = clientType === ClientTypes.TOPGOLF;
  const isAthleticSolutionsClient = clientType === ClientTypes.ATHSOL;
  const isSvHealth = clientType === ClientTypes.SV_HEALTH;
  const isUwmClient = clientType === ClientTypes.BRAND360;
  const isHealthCare = isTrubluClient || isSvHealth;

  let clientCategory: ClientCategory;

  if (isUwmClient) {
    clientCategory = ClientCategory.Uwm;
  } else if (isHealthCare) {
    clientCategory = ClientCategory.SvHealth;
  } else if (isMarketingClient) {
    clientCategory = ClientCategory.Sv;
  } else {
    clientCategory = ClientCategory.Travel;
  }

  return {
    isTrubluClient,
    isEtsClient,
    isFundraisingClient,
    isSalesClient,
    isMarketingClient,
    isTopGolfClient,
    isAthleticSolutionsClient,
    isSvHealth,
    isHealthCare,
    isLoading,
    isUwmClient,
    clientCategory,
  };
}
