import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactElement } from 'react';
import { WelcomeScreenIcon } from '../../assets/newDesign/WebAppIcons';
import AutomaticSMSIcon from '../../assets/QuickStartIcons/AutomaticSMSIcon';
import CouponIcon from '../../assets/QuickStartIcons/CouponIcon';
import ManualSMSIcon from '../../assets/QuickStartIcons/ManualSMSIcon';
import RewardSMSIcon from '../../assets/QuickStartIcons/RewardSMSIcon';
import {
  RewardFulfillmentStrings,
  RewardStrings,
  SettingsSubnavigationStrings,
} from '../../localization/en';

export const useClasses = makeStyles((theme: Theme) => ({
  carousel: {
    overflow: 'hidden',
    height: '100%',
    paddingBottom: '30px',
  },
  inner: {
    whiteSpace: 'nowrap',
    transition: 'transform 0.3s',
  },
  carouselItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: theme.palette.common.black,
    whiteSpace: 'normal',
  },
  indicators: {
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      margin: '5px',
    },
  },
}));

export interface ITab {
  text: string;
  disabled?: boolean;
  icon?: ReactElement;
}

export const IconWrapper: React.FC = ({ children }) => {
  return (
    <Box height="40px" width="40px" display="flex" justifyContent="center" alignItems="center">
      {children}
    </Box>
  );
};

const WrapIcon = (icon: JSX.Element) => {
  return (
    <Box
      sx={{
        background: '#EFEFF1',
        borderRadius: '50%',
        width: '40px',
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {icon}
    </Box>
  );
};

const WrapFulfillmentIcon = (icon: JSX.Element) => {
  return <Box sx={{ color: '#000', opacity: '0.3', mb: '-10px' }}>{icon}</Box>;
};

export const getRewardTypeTabs = ({ isHealthCare }: { isHealthCare: boolean }) => {
  return [
    {
      icon: WrapIcon(<CouponIcon />),
      text: RewardStrings.Coupon,
    },
    {
      icon: WrapIcon(<RewardSMSIcon />),
      text: isHealthCare ? RewardStrings.RewardSMS_TruBlu : RewardStrings.RewardSMS,
    },
    {
      icon: <WelcomeScreenIcon height={38} />,
      text: SettingsSubnavigationStrings.WebApp,
    },
  ];
};

export const smsTypeTabs = [
  {
    icon: WrapFulfillmentIcon(<AutomaticSMSIcon />),
    text: RewardFulfillmentStrings.AutomaticSMS,
  },
  {
    icon: WrapFulfillmentIcon(<ManualSMSIcon />),
    text: RewardFulfillmentStrings.ManualSMS,
  },
];
