import { WebsiteRoutes } from '../../common/constants/routes';
import { SubnavigationItemValues } from '../../common/components/webappSubnavigation/WebappSubnavigation.helper';
import { SubnavigationItems } from '../../common/localization/en';

export const getSubnavigationItems = (
  isLeaderBoardDisabled: boolean,
): SubnavigationItemValues[] => {
  return [
    {
      route: WebsiteRoutes.RewardsCampaigns,
      text: SubnavigationItems.RewardCampaigns,
    },
    {
      route: WebsiteRoutes.RewardsLeaderboard,
      text: SubnavigationItems.Leaderboard_Trublu,
      disabled: isLeaderBoardDisabled,
    },
  ];
};
