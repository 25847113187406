import { FC } from 'react';

export const DownloadIconSVG: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.2222 8V14.2222H1.77778V8H0V14.2222C0 15.2 0.8 16 1.77778 16H14.2222C15.2 16 16 15.2 16 14.2222V8H14.2222ZM8.88889 8.59556L11.1911 6.30222L12.4444 7.55556L8 12L3.55556 7.55556L4.80889 6.30222L7.11111 8.59556V0H8.88889V8.59556Z"
        fill="#C7C7D9"
      />
    </svg>
  );
};
