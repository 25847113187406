import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useCustomizationDisplayStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: '600',
    color: '#5242EA',
    fontSize: '14px',
    lineHeight: '20px',
  },
  value: {
    fontWeight: '600',
    color: '#667085',
    fontSize: '14px',
    lineHeight: '20px',
  },
  tooltip: {
    width: '240px',
  },
  tooltipIcon: {
    marginLeft: '8px',
  },
}));
