import previewImage from '../../../../../common/assets/newDesign/SocialVerse/previews/previewPlaceholder.png';

export const previewCards = [
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
];

export const previewCardsMobile = [
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
  {
    card: previewImage,
    name: 'First L',
  },
];
