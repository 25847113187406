import { FC, SyntheticEvent, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { FSModal } from '../../../common/components/modal/Modal';
import { useClientType } from '../../../services/hooks/useClientType';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { useStyles } from './ChangeViewModeModal.style';
import { CustomizationSocialVerse, SocialVerseStrings } from '../../../common/localization/en';
import { SVButton, SVTabs, SocialverseFormatsCarousel } from '../CommonComponents/CommonComponents';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { putEditSocialVerse } from '../../../store/slices/socialVerseSlice';
import { useToasts } from 'react-toast-notifications';
import { isEducationalView } from '../../../services/utilities';

interface ChangeViewModeModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export enum socialverseChangeEventNames {
  changesoCialverseType = 'socialverse_appearance_change_type',
}

export const ChangeViewModeModal: FC<ChangeViewModeModalProps> = ({ onClose, isOpen }) => {
  const classes = useStyles();
  const { socialVerse, isLoading, currentSocialVerseVideos } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );
  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const { isHealthCare } = useClientType();
  const [value, setValue] = useState(0);
  const { trackEvent } = useTrackEvent();
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const tabName = SVTabs[newValue].text;
    const eventName = `${tabName}_${SocialVerseEventNames.social_verse_tab_click}`;
    trackEvent(eventName);
  };
  const currentTabs = SVTabs.filter(
    (item) =>
      item.type !== socialVerse?.viewMode &&
      (currentSocialVerseVideos.length <= 1 || !isEducationalView(item.type)),
  );
  const handleSave = () => {
    trackEvent(socialverseChangeEventNames.changesoCialverseType, {
      socialVerseId: socialVerse?.id,
      viewMode: currentTabs[value].type,
    });
    if (socialVerse) {
      dispatch(
        putEditSocialVerse({
          id: socialVerse.id,
          data: {
            ...socialVerse,
            viewMode: currentTabs[value].type,
          },
        }),
      ).then((res: any) => {
        if (res.error) {
          addToast(CustomizationSocialVerse.SaveSVChangeError, {
            appearance: 'error',
            autoDismissTimeout: 2500,
          });
        } else {
          addToast(CustomizationSocialVerse.SaveSVChangesMessage, {
            appearance: 'success',
            autoDismissTimeout: 2500,
          });
        }
        onClose();
      });
    }
  };
  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="865px"
      padding="0"
    >
      <Grid container className={classes.containerNewSV}>
        <Stack className={classes.wrapperNewSV}>
          <SocialverseFormatsCarousel
            value={value}
            setValue={setValue}
            handleChange={handleChange}
            isHealthCare={isHealthCare}
            title={SocialVerseStrings.ChangeFormatTitle}
            description={SocialVerseStrings.ChangeFormatDescription}
            viewMode={socialVerse?.viewMode}
          />
          <Grid container justifyContent="center" alignItems="center" gap="16px" marginTop="10px">
            <SVButton
              handleClick={onClose}
              variant="outlined"
              className={classes.saveAndCancelbutton}
              disabled={isLoading}
            >
              {SocialVerseStrings.Cancel}
            </SVButton>
            <SVButton
              variant="contained"
              handleClick={handleSave}
              className={classes.saveAndCancelbutton}
              disabled={isLoading}
            >
              {SocialVerseStrings.Save}
            </SVButton>
          </Grid>
        </Stack>{' '}
      </Grid>
    </FSModal>
  );
};
