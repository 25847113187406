import React from 'react';
import { useHistory } from 'react-router-dom';

import { useIdleTimer } from '../../../hooks/useIdleTimer';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;

const IDLE_TIMEOUT = 3 * HOUR;

export const IdleHandler: React.FC = ({ children }) => {
  const history = useHistory();

  useIdleTimer({
    timeoutMs: IDLE_TIMEOUT,
    onIdle: () => {
      history.push('/');
    },
  });

  return <>{children}</>;
};
