import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '830px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: '20px',
  },
  sendMessageWrapper: {
    width: '793px',
    height: '137px',
    background: '#F0EEFF',
    borderRadius: '14px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    boxSizing: 'border-box',
  },
  rowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  divider: {
    height: '1px',
    backgroundColor: '#D9D9D9',
    border: 0,
  },
  title: {
    color: theme.palette.primary.main,
    fontFamily: 'Poppins',
    fontSize: '26px',
    fontWeight: '600',
    letterSpacing: '-0.52px',
    marginBottom: '4px',
  },
  subtitle: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: '#475467',
    lineHeight: '162%',
  },
  linkText: {
    fontSize: '18px',
    fontFamily: 'Poppins',
    color: theme.palette.primary.main,
    lineHeight: '162%',
    fontWeight: 600,
  },
  rightWrapper: {
    width: '336px',
    height: '102px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0 24px',
  },
  sendIndividuallyText: {
    fontSize: '14px',
    fontFamily: 'Poppins',
    color: '#525252',
    fontWeight: 500,
    lineHeight: '20px',
    marginBottom: '10px',
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '12px',
  },
  inviteButton: {
    fontFamily: 'Inter',
    height: '40px',
    padding: '10px 16px',
    borderRadius: '8px',
    background: '#fff',
    border: '1px solid #D0D5DD',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',

    '&:hover': {
      background: '#fff',
      border: '1px solid #D0D5DD',
    },
    '&:active': {
      boxShadow: 'none',
      transform: 'scale(0.99)',
    },
    '& p': {
      fontSize: '14px',
      fontWeight: 600,
      color: '#344054',
    },
  },
  uploadList: {
    fontFamily: 'Inter',
    height: '40px',
    padding: '10px 16px',
    borderRadius: '8px',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',

    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      transform: 'scale(0.99)',
    },
    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  qrSectionWrapper: {
    width: '793px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    padding: '10px 20px',
    boxSizing: 'border-box',
  },
  qrSelect: {
    width: '145px',
    height: '24px',
    border: '1px solid #D2D2D2',
    background: '#F5F6F8',
    borderRadius: '6px',
    paddingRight: 0,
    '& .MuiSelect-select': {
      padding: '8px 0 8px 16px !important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none!important',
    },
    '& .MuiOutlinedInput-notchedOutline:focus': {
      border: 'none!important',
    },
  },
  brandSelectText: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '24px',
    textTransform: 'capitalize',
    paddingLeft: '25px',
  },
  expandIconWrapper: {
    position: 'relative',
  },
  expandIcon: {
    position: 'absolute',
    top: '-10px',
    left: '-30px',
  },
  selectMenu: {
    zoom: '0.9',
    marginTop: '-7px',
    padding: '9px 14px !important',
    paddingRight: 0,
  },
  selectPaper: {
    borderRadius: '12px',
  },
  menuItem: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    backgroundColor: '#fff !important',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
  },
  selectedStyleText: {
    marginTop: '14px',
    marginBottom: '10px',
    fontFamily: 'Poppins',
    color: '#525252',
    fontWeight: 500,
  },
  qrLeftSectionWrapper: {
    width: '448px',
  },
  qrRightSectionWrapper: {
    width: '305px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  downloadText: {
    marginBottom: '30px',
    color: '#525252',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
  },
  qrCodeWrapper: {
    transform: 'scale(1.35)',
    borderRadius: '16px',
    '&:hover': {
      transform: 'scale(1.37)',
    },
  },
  inviteLinkWrapper: {
    width: '793px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '14px',
    padding: '10px 20px',
    boxSizing: 'border-box',
  },
  linkLeftSectionWrapper: {
    width: '390px',
  },
  linkRightSectionWrapper: {
    width: '415px',
    paddingTop: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  viewLiveButton: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
}));
