import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { useToasts } from 'react-toast-notifications';
import { getAMPMTime } from '../../../services/utilities';
import { TopGolfVideoInfoString } from '../../localization/en';
import { useTopGolfVideoInfoStyles } from './TopGolfVideoInfo.style';

interface ITopGolfVideoInfoProps {
  shotTime: string;
  teeTime: string;
  shortcode: string;
}

export const TopGolfVideoInfo: FC<ITopGolfVideoInfoProps> = ({ shotTime, teeTime, shortcode }) => {
  const styles = useTopGolfVideoInfoStyles();
  const { addToast } = useToasts();
  const handleCopyClick = () => {
    navigator.clipboard.writeText(shortcode);
    addToast(TopGolfVideoInfoString.Copied, {
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  return (
    <div className={styles.Container}>
      {shotTime && (
        <Typography className={styles.Text}>
          {TopGolfVideoInfoString.ShotDate}: {format(new Date(shotTime), 'PP')}
        </Typography>
      )}
      {teeTime && (
        <Typography className={styles.Text}>
          {TopGolfVideoInfoString.ShotTime}: {getAMPMTime(shotTime)}
        </Typography>
      )}
      <Typography className={styles.Text} onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
        {TopGolfVideoInfoString.VideoID}: {shortcode}
      </Typography>
    </div>
  );
};
