import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { AppBar, Toolbar, Grid, Box, Button } from '@mui/material';
// import HelpOutline from '@mui/icons-material/HelpOutline';
// import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { useHistory, useLocation } from 'react-router';

// import { SearchObject } from '../../SearchAccordion/SearchAccordion.helper';
// import { SearchAccordion } from '../../SearchAccordion/SearchAccordion';
// import { setSelectedVenue } from '../../../../store/slices/venueSlice';
import { LayoutMode } from '../../../constants/constants';
import { useStyles } from './TopBarDesktop.helper';
import { TopBarBreadcrumbs } from '../topBarBreadcrumbs/topBarBreadCrumbs';
// import { CopyUrlToggle } from '../../CopyUrlToggle/CopyUrlToggle';
import { OpenWebApp } from '../../../../pages/SocialVerse/CommonComponents/CommonComponents';
import { SocialvenueManagerRoutes, WebsiteRoutes } from '../../../constants/routes';
import { SpheresPreview } from '../../SvCustomizationPreview/SpheresPreview';
import { SubNavigationTab } from '../topBarTabs/TopBarTabs.helper';
import { TopBarTabs } from '../topBarTabs/TopBarTabs';
import { ClipsAdvancedSearch } from '../../ClipsAdvancedSearch/ClipsAdvancedSearch';
import { CreatorsAdvancedSearch } from '../../CreatorsAdvancedSearch/CreatorsAdvancedSearch';
import { useClientType } from '../../../../services/hooks/useClientType';
import { West } from '@mui/icons-material';
import { useAppDispatch } from '../../../../store';
import { setCreatorsTableActiveTabIndex } from '../../../../store/slices/campaignsSlice';

interface TopBarDesktopProps {
  onHelpChange: () => void;
  layout: LayoutMode;
  children?: ReactNode;
  sectionTitle?: string;
  multiline?: boolean;
  tabs?: SubNavigationTab[];
  onTabChange?: (tab: SubNavigationTab) => void;
  withBackButton?: boolean;
  backButtonText?: string;
  backButtonPath?: string;
}

export const TopBarDesktop: FC<TopBarDesktopProps> = ({
  children,
  sectionTitle,
  multiline = true,
  tabs,
  onTabChange,
  withBackButton,
  backButtonText,
  backButtonPath,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isAdmin, setIsAdmin] = useState(false);
  const { isTopGolfClient, isLoading } = useClientType();

  useEffect(() => {
    setIsAdmin(location && location.pathname.includes('admin'));
  }, [location, setIsAdmin]);

  const isLinkToWebapp = useMemo(() => {
    const isIncludesPath =
      location &&
      (location.pathname.includes(WebsiteRoutes.Workspace) ||
        location.pathname.includes(SocialvenueManagerRoutes.Performance) ||
        location.pathname.includes(WebsiteRoutes.ContentAll) ||
        location.pathname.includes(WebsiteRoutes.Settings) ||
        location.pathname.includes(WebsiteRoutes.Heroes) ||
        location.pathname.includes(WebsiteRoutes.Accounts) ||
        location.pathname.includes(WebsiteRoutes.Profile) ||
        location.pathname.includes(WebsiteRoutes.WebAppSettings) ||
        // location.pathname !== WebsiteRoutes.RewardCampaigns) ||
        location.pathname === WebsiteRoutes.SocialVerse ||
        location.pathname.includes(WebsiteRoutes.Google) ||
        location.pathname.includes(WebsiteRoutes.Rewards) ||
        location.pathname.includes(WebsiteRoutes.RewardCampaigns));
    return isIncludesPath;
  }, [location]);

  const isStickyTopbar = location && location.pathname.includes(WebsiteRoutes.WebAppCards);
  const isCreatorsPage = location.pathname.startsWith(WebsiteRoutes.Heroes);

  return (
    <AppBar
      position="static"
      className={classes.appBar}
      sx={{
        position: isStickyTopbar ? 'sticky' : '',
        top: 0,
        zIndex: 20,
      }}
    >
      <Toolbar
        className={classes.toolBar}
        style={{
          borderBottom: isCreatorsPage ? '' : '1px solid #DCDBE4',
        }}
      >
        <SpheresPreview />

        <Grid container spacing={1} className={classes.contentWrapper}>
          <Grid item xs={!children || isLinkToWebapp ? 4 : 12}>
            <Grid
              className={multiline ? classes.Multiline : classes.Singleline}
              container
              justifyContent={'space-between'}
              style={{ position: 'relative' }}
            >
              <Grid container alignItems={'center'}>
                {sectionTitle && <div className={classes.sectionTitle}>{sectionTitle}</div>}
                {withBackButton && (
                  <Button
                    variant="text"
                    className={classes.backButton}
                    onClick={() => {
                      backButtonPath ? history.push(backButtonPath) : history.goBack();
                      dispatch(setCreatorsTableActiveTabIndex(0));
                    }}
                  >
                    <West sx={{ color: '#5D5FEF', fontSize: '16px' }} />
                    {backButtonText}
                  </Button>
                )}
                {location.pathname.includes(WebsiteRoutes.ContentAll) &&
                  !isTopGolfClient &&
                  !isLoading && (
                    <Box className={classes.searchWrapper} left="87px">
                      <ClipsAdvancedSearch />
                    </Box>
                  )}
                {isCreatorsPage && (
                  <Box className={classes.searchWrapper} left="125px">
                    <CreatorsAdvancedSearch />
                  </Box>
                )}
                {tabs && <TopBarTabs tabs={tabs} onTabChange={onTabChange} />}
              </Grid>
              <div>{children}</div>
            </Grid>
          </Grid>

          {!children
            ? isLinkToWebapp && (
                <Grid container item xs={8}>
                  {isAdmin && <TopBarBreadcrumbs />}
                  <div className={classes.grow} style={{ paddingTop: '0px' }} />
                  <div style={{ padding: '6px' }}>
                    {isLinkToWebapp ? <OpenWebApp /> : <OpenWebApp />}
                  </div>
                </Grid>
              )
            : null}
          {children
            ? isLinkToWebapp && (
                <Grid container item xs={8}>
                  {isAdmin && <TopBarBreadcrumbs />}
                  <div className={classes.grow} style={{ paddingTop: '0px' }} />
                  <div
                    style={{
                      padding: '6px',
                    }}
                  >
                    {isLinkToWebapp ? <OpenWebApp /> : <OpenWebApp />}
                  </div>
                </Grid>
              )
            : null}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
