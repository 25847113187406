import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .react-toast-notifications__toast__icon-wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '& .react-toast-notifications__toast__content': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
  },
}));
