export const InvitesSMSIcon = () => {
  return (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24.5" cy="24" r="24" fill="currentColor" fill-opacity="0.05" />
      <path
        d="M11.4999 31.5519L10.8998 31.5519L10.9 31.5612L11.4999 31.5519ZM13.0531 32.3285L13.4057 32.8141L13.416 32.8063L13.0531 32.3285ZM16.3459 29.8278L16.7088 30.3056C16.7141 30.3016 16.7194 30.2974 16.7247 30.2932L16.3459 29.8278ZM16.9361 29.6259L16.9218 30.2257C16.9266 30.2258 16.9313 30.2259 16.9361 30.2259V29.6259ZM10.8996 14.6719L10.8999 31.5519L12.0999 31.5519L12.0996 14.6719L10.8996 14.6719ZM10.9 31.5612C10.9045 31.8502 10.9882 32.1323 11.1421 32.3769L12.1579 31.7381C12.121 31.6794 12.1009 31.6118 12.0998 31.5425L10.9 31.5612ZM11.1421 32.3769C11.2959 32.6215 11.5139 32.8192 11.7724 32.9484L12.309 31.8751C12.2471 31.8441 12.1948 31.7967 12.1579 31.7381L11.1421 32.3769ZM11.7724 32.9484C12.0308 33.0777 12.3198 33.1335 12.6078 33.1098L12.5094 31.9138C12.4403 31.9195 12.371 31.9061 12.309 31.8751L11.7724 32.9484ZM12.6078 33.1098C12.8958 33.0861 13.1717 32.9838 13.4056 32.814L12.7007 31.8429C12.6446 31.8836 12.5784 31.9081 12.5094 31.9138L12.6078 33.1098ZM13.416 32.8063L16.7088 30.3056L15.983 29.35L12.6902 31.8506L13.416 32.8063ZM16.7247 30.2932C16.7802 30.2479 16.8501 30.224 16.9218 30.2257L16.9505 29.0261C16.5931 29.0175 16.2444 29.1368 15.9672 29.3625L16.7247 30.2932ZM16.9361 30.2259H30.3534V29.0259H16.9361V30.2259ZM33.9534 26.6259V14.6719H32.7534V26.6259H33.9534ZM30.3534 11.0719H14.4996V12.2719H30.3534V11.0719ZM33.9534 14.6719C33.9534 12.6836 32.3417 11.0719 30.3534 11.0719V12.2719C31.6789 12.2719 32.7534 13.3464 32.7534 14.6719H33.9534ZM30.3534 30.2259C32.3417 30.2259 33.9534 28.6141 33.9534 26.6259H32.7534C32.7534 27.9514 31.6789 29.0259 30.3534 29.0259V30.2259ZM12.0996 14.6719C12.0995 13.3464 13.1741 12.2719 14.4996 12.2719V11.0719C12.5113 11.0719 10.8995 12.6837 10.8996 14.6719L12.0996 14.6719Z"
        fill="currentColor"
      />
      <path
        d="M22.4264 22.6836H18.7681"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.0836 18.4414H18.7681"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.4996 19.2031V35.4962C37.4982 35.6506 37.4542 35.8017 37.3724 35.9327C37.2906 36.0637 37.1742 36.1696 37.036 36.2387C36.8979 36.3078 36.7433 36.3373 36.5895 36.3242C36.4356 36.311 36.2883 36.2556 36.1639 36.1641L33.3215 34.0051C33.1727 33.8962 32.9935 33.8364 32.809 33.8343H19.498"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
