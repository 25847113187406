import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImg from '../../common/assets/SignUpFormBackground.svg';

export const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  QuicksignUpWrapper: {
    height: '111vh',
    width: '100%',
    position: 'absolute',
    overflowY: 'auto',
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'nowrap',
    zoom: '1.2',
  },
  content: {
    filter: `drop-shadow(0px 12px 24px ${theme.palette.primary.main}1a)`,
    width: '700px',
    height: '580px',
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 600,
    fontSize: '44px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    margin: '20px',
  },
  description: {
    fontWeight: 400,
    fontSize: '17px',
    lineHeight: '26px',
    color: theme.palette.common.black,
    margin: '20px',
  },
  paragraph: {
    fontWeight: 500,
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  paragraphLoaded: {
    clipPath: 'inset(0 3% 0 0)',
  },
  paragraphLoading: {
    clipPath: 'inset(0 3% 0 0)',
    animation: 'loadingDots 1s steps(3) infinite',
  },

  '@keyframes loading': {
    '0%': {
      clipPath: ' inset(0 0% 0 0)',
    },
    '100%': {
      clipPath: ' inset(0 -3% 0 0)',
    },
  },
  '@-webkit-keyframes lading': {
    '0%': {
      clipPath: ' inset(0 0% 0 0)',
    },
    '100%': {
      clipPath: ' inset(0 -3% 0 0)',
    },
  },
  boldparagraph: {
    fontWeight: 700,
    fontSize: '18px',
    textAlign: 'center',
  },
  completePorfileButton: {
    width: '375px',
    height: '68px',
    padding: '24px 60px 24px 60px',
    gap: '10px',
    borderRadius: '7px',
    opacity: '0px',
    marginTop: '32px',
  },
}));
/* HTML: <div class="loader"></div> */
