import * as Yup from 'yup';

export interface AccountBoxValues {
  password: string;
  role: string;
  name: string;
  email: string;
  confirmationPassword: string;
}

export const initialValuesAccountBox: AccountBoxValues = {
  password: '',
  role: '',
  name: '',
  email: '',
  confirmationPassword: '',
};

export const validationSchemaAccountBox = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(5, 'password should be min 5 characters long')
      .max(25, 'max length 25 characters')
      .required('Required'),
    confirmationPassword: Yup.string()
      .when('password', (password: string) => {
        return Yup.string()
          .min(5, 'password should be min 5 characters long')
          .test(
            'on equal',
            'Confirm password must be equal to password',
            (confirmationPassword) => confirmationPassword === password,
          );
      })
      .required('Required'),
    role: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    email: Yup.string().required('Required'),
  });
