import React, { FC, useState, ReactNode, useEffect } from 'react';
import {
  CreatorAttributeName,
  IsOptedOutFilterAttribute,
  SearchOperationType,
  VerifiedFilterAttribute,
} from '../../../api/models/searchFilterModels';
import { useStyles } from './VerificationStatusFilterSelect.styles';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { ExpandIcon } from '../../assets/ExpandIcon';
import { ClipsSearchStrings, CreatorsSearchStrings } from '../../localization/en';
import { VerifiedIcon } from '../../assets/VerifiedIcon';
import { UnverifiedIcon } from '../../assets/UnverifiedIcon';
import { OptedOutIcon } from '../../assets/OptedOutIcon';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { removeCreatorVerificationFilter } from '../../../store/slices/searchFilterSlice';

interface Props {
  onChange: (status: VerifiedFilterAttribute | IsOptedOutFilterAttribute | null) => void;
}

interface StatusItem {
  title: string;
  getAttribute: () => VerifiedFilterAttribute | IsOptedOutFilterAttribute;
  icon: ReactNode;
}

export const VerificationStatusFilterSelect: FC<Props> = ({ onChange }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);

  const statusItems: StatusItem[] = [
    {
      title: CreatorsSearchStrings.Verified,
      getAttribute: () => ({
        name: CreatorAttributeName.PhoneNumberVerified,
        operation: SearchOperationType.Equals,
        value: true,
      }),
      icon: <VerifiedIcon />,
    },
    {
      title: CreatorsSearchStrings.Unverified,
      getAttribute: () => ({
        name: CreatorAttributeName.PhoneNumberVerified,
        operation: SearchOperationType.Equals,
        value: false,
      }),
      icon: <UnverifiedIcon />,
    },
    {
      title: CreatorsSearchStrings.OptedOut,
      getAttribute: () => ({
        name: CreatorAttributeName.OptedIn,
        operation: SearchOperationType.Equals,
        value: false,
      }),
      icon: <OptedOutIcon />,
    },
  ];

  useEffect(() => {
    const appliedAttribute = activeCreatorFilterAttributes.find((attribute) =>
      [CreatorAttributeName.PhoneNumberVerified, CreatorAttributeName.OptedIn].includes(
        attribute.name,
      ),
    );

    if (appliedAttribute) {
      if (appliedAttribute.name === CreatorAttributeName.PhoneNumberVerified) {
        setSelectedItemIndex(appliedAttribute.value ? 0 : 1);
      } else if (
        appliedAttribute.name === CreatorAttributeName.OptedIn &&
        !appliedAttribute.value
      ) {
        setSelectedItemIndex(2);
      }

      onChange(appliedAttribute as VerifiedFilterAttribute | IsOptedOutFilterAttribute);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCreatorFilterAttributes]);

  return (
    <Box className={classes.container}>
      {selectedItemIndex > -1 && (
        <Button
          className={classes.clearButton}
          onClick={() => {
            setSelectedItemIndex(-1);
            onChange(null);
            dispatch(removeCreatorVerificationFilter());
          }}
        >
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        displayEmpty={true}
        className={classes.select}
        renderValue={() => {
          if (selectedItemIndex > -1) {
            return (
              <Typography className={classes.selectedItemText} display="inline">
                <Box className={classes.selectedItemIcon}>
                  {statusItems[selectedItemIndex].icon}
                </Box>
                {statusItems[selectedItemIndex].title}
              </Typography>
            );
          } else {
            return (
              <Typography className={classes.selectedItemText}>
                {ClipsSearchStrings.Select}
              </Typography>
            );
          }
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
            },
          },
        }}
        sx={{
          '& .MuiSelect-select': {
            padding:
              selectedItemIndex > -1 ? '3px 0 2px 16px !important' : '8px 0 8px 16px !important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        {statusItems.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                setSelectedItemIndex(index);
                onChange(item.getAttribute());
              }}
              className={classes.menuItem}
              key={item.title}
            >
              <Box className={classes.menuIcon}>{item.icon}</Box>
              <Typography className={classes.menuItemText}>{item.title}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
