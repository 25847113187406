import React from 'react';
import { useStyles } from './RightSideCompleteLayout.styles';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { OnboardingWelcomeStrings } from '../../../../common/localization/en';
import { ShapeIcon } from '../../../../common/assets/QuickStartIcons/ShapeIcon';

const RightSideCompleteLayout = () => {
  const styles = useStyles();
  const steps = [
    {
      icon: (
        <Box className={styles.iconBadge}>
          <img src="/qs-success-icon.png" alt="qs success" className={styles.icon} />
        </Box>
      ),
      title: OnboardingWelcomeStrings.Method1Steps1Title,
      description: OnboardingWelcomeStrings.Method1Steps1Description,
    },
    {
      icon: (
        <Box className={styles.collectIconWrapper}>
          <ShapeIcon />
          <img src="/qs-book-icon.png" alt="qs book" className={styles.bookIcon} />
        </Box>
      ),
      title: OnboardingWelcomeStrings.Method1Steps2Title,
      description: OnboardingWelcomeStrings.Method1Steps2Description,
    },
  ];

  return (
    <Box className={styles.container}>
      <Typography className={styles.description}>{OnboardingWelcomeStrings.QuickSetup}</Typography>
      <Box className={styles.dividerWrapper}>
        <Divider className={styles.divider} />
      </Box>
      <Stack spacing={3}>
        {steps.map((step, index) => {
          return (
            <Box className={styles.stepWrapper}>
              {step.icon}
              <Box className={styles.titleWrapper}>
                <Typography className={styles.stepTitle}>{step.title}</Typography>
                <Box className={styles.stepDividerWrapper}>
                  <Divider
                    className={styles.divider}
                    sx={{ borderColor: index === 0 ? '#67CE67 !important' : '#F7B409 !important' }}
                  />
                </Box>
                <Typography className={styles.stepDescription}>{step.description}</Typography>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default RightSideCompleteLayout;
