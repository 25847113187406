import * as Yup from 'yup';
import { FilterLogoPosition } from '../../../api/models/venue';
import { CommonStrings, ErrorsStrings } from '../../../common/localization/en';
import { urlValidationSchema } from '../../../common/validation/commonValidation';
import { createUploadUrl, uploadLogoAsFile } from '../../../services/utilities';

export interface AccordionsState {
  firstStage: boolean;
  secondStage: boolean;
  thirdStage: boolean;
}

export const MAX_LENGTH = 30;

export const videoBrandingPlaybackValidationSchema = () =>
  Yup.object().shape({
    editable: Yup.string().oneOf(['full', 'limited']),
    metaTitle: Yup.string()
      .max(82, 'Video title character limit: 82 characters')
      .required('Video title is required'),
    videoText: Yup.string()
      .min(5, 'Video text should be min 5 characters')
      .max(100, 'Video text max length 100 characters'),
    ctaBtnText: Yup.string()
      .max(MAX_LENGTH, CommonStrings.ExceededCharLimit)
      .matches(
        /^([a-zA-Z0-9_., \-!?$€£%#@`'"^&*()[\]{}\\/:;]{2,30})$/gm,
        ErrorsStrings.InvalidCharacters,
      )

      .required('Button text is required'),
    ctaBtnLinkUrl: urlValidationSchema(
      'Your button link must be a valid URL starting with "https://"',
    ).required(ErrorsStrings.FieldIsRequired),
    videoMessage: Yup.string().max(82, 'Video text max length 82 characters'),
  });
const positionsnormal = {
  'top-left': { dx: 30, dy: 30 },
  'top-right': { dx: 400, dy: 20 },
  'bottom-left': { dx: 20, dy: 820 },
  'bottom-right': { dx: 400, dy: 820 },
};

const topgolfPositions = {
  'top-left': { dx: 20, dy: 20 },
  'top-right': { dx: 340, dy: 20 },
  'bottom-left': { dx: 20, dy: 360 },
  'bottom-right': { dx: 340, dy: 360 },
};

export enum filterLogoPositions {
  topLeft = 'top-left',
  topRight = 'top-right',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
}

export const uploadOverlay = async (
  tempFilterOverlayUrl: string,
  tempFilterLogo: string,
  logoPosition: string,
  venueId: string,
  isTopgolf: boolean,
) => {
  let ctx: CanvasRenderingContext2D | null;
  let canvas = document.createElement('canvas');
  canvas.width = 540;
  canvas.height = 960;
  const positions = isTopgolf ? topgolfPositions : positionsnormal;

  if (canvas) {
    ctx = canvas.getContext('2d');

    let imageObj1 = new Image();
    let imageObj2 = new Image();
    imageObj1.crossOrigin = 'Anonymous';
    imageObj2.crossOrigin = 'Anonymous';

    const generateUrlToFileAndPassToCallback = (resolveCallback: (result: string) => void) => {
      canvas.setAttribute('crossorigin', 'anonymous');
      const url = (canvas as HTMLCanvasElement).toDataURL('image/png');
      urltoFile(url, 'image.png', 'image/png').then(function (file) {
        createUploadUrl(venueId, 'png').then((res) => {
          if (res) {
            uploadLogoAsFile({ options: res, data: file }).then(() => {
              resolveCallback(res.downloadUrl);
            });
          }
        });
      });
    };

    function scaleIt(source: HTMLImageElement, scaleFactor: number) {
      var c = document.createElement('canvas');
      var ctx = c.getContext('2d');
      var w = source.width * scaleFactor;
      var h = source.height * scaleFactor;
      c.width = w;
      c.height = h;
      if (ctx) {
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(source, 0, 0, w, h);
      }
      return c;
    }

    return new Promise<string>((resolve) => {
      if (tempFilterOverlayUrl && tempFilterLogo) {
        imageObj1.src = tempFilterOverlayUrl;
        imageObj1.onload = function () {
          if (ctx) {
            ctx.drawImage(imageObj1, 0, 0, 400, 500);
            imageObj2.src = tempFilterLogo;
            imageObj2.onload = function () {
              if (ctx) {
                var c1 = scaleIt(imageObj2, 1);

                const logoPos = positions[logoPosition as FilterLogoPosition];
                ctx.drawImage(c1, logoPos.dx, logoPos.dy, 30, 30);
                generateUrlToFileAndPassToCallback(resolve);
              }
            };
          }
        };
      } else if (tempFilterOverlayUrl && !tempFilterLogo) {
        imageObj1.src = tempFilterOverlayUrl;
        imageObj1.onload = function () {
          if (ctx) {
            ctx.drawImage(imageObj1, 0, 0, 400, 350);
            generateUrlToFileAndPassToCallback(resolve);
          }
        };
      } else if (!tempFilterOverlayUrl && tempFilterLogo) {
        imageObj2.src = tempFilterLogo;
        imageObj2.onload = function () {
          if (ctx) {
            const horizontalOffset = 20;

            var logoW = imageObj2.width;
            var logoH = imageObj2.height;

            if (logoW > logoH) {
              logoW = logoW > 210 ? 210 : logoW;
              logoH = Math.floor(logoW / (imageObj2.width / imageObj2.height));
              logoH = logoH > 100 ? 100 : logoH;
              logoW = logoH * (imageObj2.width / imageObj2.height);
            } else {
              logoH = 100;
              logoW = logoH * (imageObj2.width / imageObj2.height);
            }

            const scaleFactor = logoH / imageObj2.height;

            var c1 = scaleIt(imageObj2, scaleFactor);

            const logoPos = positions[logoPosition as FilterLogoPosition];

            const posX = logoPosition.includes('right')
              ? canvas.width - logoW - horizontalOffset
              : logoPos.dx;

            const posY = logoPos.dy;

            ctx.drawImage(c1, posX, posY, logoW, logoH);
            generateUrlToFileAndPassToCallback(resolve);
          }
        };
      } else if (!tempFilterOverlayUrl && !tempFilterLogo) {
        imageObj1.src = '';
        if (ctx) {
          ctx.drawImage(imageObj1, 0, 0, 400, 350);
          generateUrlToFileAndPassToCallback(resolve);
        }
      }
    });
  }
};

function urltoFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
