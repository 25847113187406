import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#EFF8FF',
    borderRadius: '30px',
    color: '#175CD3',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '20px',
    padding: '0 14px',
    maxWidth: '86px',
  },
}));
