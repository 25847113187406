import { makeStyles } from '@mui/styles';

export const useClasses = makeStyles((theme) => ({
  root: {
    borderRadius: '16px',
    margin: '24px 16px',
    minHeight: '200px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 24px',
    minHeight: '200px',
  },
  imageSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.slightlyBlueish,
    padding: '32px',
    minHeight: '200px',
  },
  bulletContainer: {
    display: 'flex',
    flexShrink: 0,
    marginRight: theme.spacing(1),
    width: theme.spacing(3),
    height: theme.spacing(3),
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItem: {
    display: 'flex',
  },
  textWrap: {
    wordWrap: 'break-word',
  },
}));

export interface Release {
  id: string;
  content: string;
  status: string;
  title: string;
  type: 'page' | 'blogpost';
}

export interface Content {
  title: string;
  content: string;
}
