import styled from 'styled-components';

import { theme } from '../../../theme';

interface ListItemStyledProps {
  bgColor: string;
}
export const ListItemStyled = styled.li<ListItemStyledProps>`
  position: relative;
  margin-top: 4px;
  padding: 26px 22px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${theme.palette.text.primary};
  border-radius: 8px;
  box-shadow: 0px 2px 12px -2px rgba(7, 27, 134, 0.08);

  ${({ bgColor }) =>
    bgColor &&
    `overflow: hidden;
    
    &:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${bgColor};
    
  }`};
`;

export const HeaderCells = styled.div`
  padding: 0 22px;
  display: flex;
  justify-content: space-between;
`;

export const HeaderCell = styled.div`
  font-size: 12px;
  color: ${theme.palette.secondary.contrastText};
`;
