import { FC } from 'react';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

import { CampaignsPageStrings } from '../../../common/localization/en';
import { IphoneXframeDeprecated } from '../../../common/components/previews/phonePreviewWrapper';
import DefaultImg from '../../../common/assets/newDesign/DefaultImg.png';
import { LogoSection, MenuBar } from '../../../common/components/previews/previews.style';
import {
  PreviewContainer,
  Header,
  MainWrapper,
  Title,
  SubTitle,
  SliderImage,
  SliderContent,
  Slide,
  SliderFilterImage,
  RecordButton,
  PhotoLibraryIconWrapper,
} from './Preview.style';

interface PreviewProps {
  brandColor: string;
  filterUrl?: string;
  logo: string;
}
export const Preview: FC<PreviewProps> = ({ brandColor, filterUrl, logo = '' }) => {
  return (
    <PreviewContainer>
      <IphoneXframeDeprecated>
        <MainWrapper>
          <Header>
            <MenuBar />
            <LogoSection src={logo} />
          </Header>
          <Title color={brandColor}>{CampaignsPageStrings.PreviewTitle}</Title>
          <SubTitle color={brandColor}>{CampaignsPageStrings.PreviewSubTitle}</SubTitle>
          <Slide>
            <SliderImage src={DefaultImg || ''} />
            <SliderContent>
              {filterUrl && <SliderFilterImage src={filterUrl || ''} />}
            </SliderContent>
          </Slide>
          <RecordButton />
          <PhotoLibraryIconWrapper>
            <PhotoLibraryIcon />
          </PhotoLibraryIconWrapper>
        </MainWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
