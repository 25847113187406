import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useRewardMessagePreviewStyles = makeStyles((theme: Theme) => ({
  title: {
    color: '#667085',
    fontSize: '14px',
    fontWeight: '500',
    textAlign: 'center',
    marginBottom: '4px',
  },
  container: {
    borderRadius: '16px',
    border: '1px solid #8B89A0',
    borderBottom: '1px solid transparent',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    height: '420px',
    width: '315px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  innerContainer: {
    padding: '8px',
  },
}));
