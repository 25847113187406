import React, { useEffect, useRef, useState } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useUploadFileToStorage } from '../../../../hooks/useUploadFileToStorage';
import {
  googleStorageToCloudinary,
  isImageBackgroundTransparent,
  setPropertyToCloudinaryUrl,
} from '../../../../services/utilities';
import { CommonStrings, PersonalizationStrings } from '../../../localization/en';
import { ImageUploader, ImageUploaderProps } from '../ImageUploader';
import { Alignment } from '../ImageUploaderWithGallery';

interface LogoUploaderProps {
  logo: string | undefined;
  onLogoChange?: (image: string | undefined) => void;
  title?: string;
  subtitle?: string | string[];

  imagePreviewWidth?: ImageUploaderProps['imagePreviewWidth'];
  imagePreviewHeight?: ImageUploaderProps['imagePreviewHeight'];
  alignment?: Alignment;
  imagePadding?: number;
  isQuickstart?: boolean;
  dndTitle?: string | JSX.Element;
  dndIcon?: JSX.Element;
  customInfo?: JSX.Element;
}

export const LogoUploader: React.FC<LogoUploaderProps> = ({
  logo,
  onLogoChange,
  title = 'Try to use a PNG',
  subtitle = 'PNGs have a transparent background which improves the aesthetic of the home screen',
  imagePreviewWidth = '160px',
  imagePreviewHeight = '160px',
  imagePadding,
  alignment,
  isQuickstart,
  dndTitle,
  dndIcon,
  customInfo,
}) => {
  const { uploadFileToStorage, loading: isFileLoading } = useUploadFileToStorage();
  const [warnBackgroundNotTransparent, setWarnBackgroundNotTransparent] = useState(false);
  const initialLogo = useRef(logo).current;

  const { addToast } = useToasts();

  useEffect(() => {
    if (initialLogo === logo) {
      setWarnBackgroundNotTransparent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      addToast(CommonStrings.OnError, { appearance: 'error' });
      return;
    }
    const uploadedFile = await uploadFileToStorage(file);
    const url = uploadedFile?.downloadUrl;
    if (!url) return;

    const cloudinaryLogoLink = googleStorageToCloudinary(url);
    // Remove background from PNG
    const logoUrl = setPropertyToCloudinaryUrl(cloudinaryLogoLink, 'c_fit,h_400,w_400');

    const isBakgroundTransparent = await isImageBackgroundTransparent(url);
    setWarnBackgroundNotTransparent(!isBakgroundTransparent);
    onLogoChange?.(logoUrl);
  };

  return (
    <ImageUploader
      file={logo}
      onUpload={handleUpload}
      accept={['jpeg', 'png']}
      title={title}
      subtitle={subtitle}
      dndDescription={isQuickstart ? PersonalizationStrings.SvgPng : ''}
      buttonTitle={PersonalizationStrings.BrowseFiles}
      loading={isFileLoading}
      imagePreviewFit="contain"
      imagePreviewWidth={imagePreviewWidth}
      imagePreviewHeight={imagePreviewHeight}
      alignment={alignment}
      imagePadding={imagePadding}
      rightSideContainerProps={{
        maxWidth: '400px',
      }}
      warnBackgroundNotTransparent={warnBackgroundNotTransparent}
      isQuickstart={isQuickstart}
      dndTitle={dndTitle}
      dndIcon={dndIcon}
      displayButtons={isQuickstart}
      customInfo={customInfo}
      showEditIcon={!isQuickstart}
    />
  );
};
