import { FC } from 'react';
interface Props {
  color?: string;
}

export const DotIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="13" height="13" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="3" fill={color || '#12B76A'} />
    </svg>
  );
};
