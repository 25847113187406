import React, { FC, useEffect, useState } from 'react';
import { VideoAttributeName, VideoStatusType } from '../../../api/models/searchFilterModels';
import PublishedIcon from '../../assets/ClipsDetailsIcons/PublishedIcon';
import UnpublishedIcon from '../../assets/ClipsDetailsIcons/UnpublishedIcon';
import ModeratedIcon from '../../assets/ClipsDetailsIcons/ModeratedIcon';
import PendingModerationIcon from '../../assets/newDesign/PendingModerationIcon';
import { useStyles } from './StatusFilterSelect.styles';
import { Box, Button, MenuItem, Select, Typography } from '@mui/material';
import { ExpandIcon } from '../../assets/ExpandIcon';
import { ClipsSearchStrings } from '../../localization/en';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { removeVideosStatusFilter } from '../../../store/slices/searchFilterSlice';

interface Props {
  onChange: (status: VideoStatusType | null) => void;
}

export const StatusFilterSelect: FC<Props> = ({ onChange }) => {
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const { activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const statusItems = [
    {
      title: ClipsSearchStrings.Published,
      status: VideoStatusType.Published,
      icon: <PublishedIcon />,
    },
    {
      title: ClipsSearchStrings.Unpublished,
      status: VideoStatusType.Unpublished,
      icon: <UnpublishedIcon />,
    },
    {
      title: ClipsSearchStrings.Moderated,
      status: VideoStatusType.Moderated,
      icon: <ModeratedIcon />,
    },
    {
      title: ClipsSearchStrings.PendingModeration,
      status: VideoStatusType.PendingModeration,
      icon: <PendingModerationIcon />,
    },
  ];

  useEffect(() => {
    const appliedAttribute = activeVideoFilterAttributes.find(
      (attribute) => attribute.name === VideoAttributeName.Status,
    );

    if (appliedAttribute) {
      setSelectedItemIndex(statusItems.findIndex((item) => item.status === appliedAttribute.value));
      onChange(appliedAttribute.value as VideoStatusType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVideoFilterAttributes]);

  return (
    <Box className={classes.container}>
      {selectedItemIndex > -1 && (
        <Button
          className={classes.clearButton}
          onClick={() => {
            setSelectedItemIndex(-1);
            onChange(null);
            dispatch(removeVideosStatusFilter());
          }}
        >
          {ClipsSearchStrings.Clear}
        </Button>
      )}
      <Select
        displayEmpty={true}
        renderValue={() => {
          if (selectedItemIndex > -1) {
            return (
              <Typography className={classes.selectedItemText} display="inline">
                <Box className={classes.selectedItemIcon}>
                  {statusItems[selectedItemIndex].icon}
                </Box>
                {statusItems[selectedItemIndex].title}
              </Typography>
            );
          } else {
            return (
              <Typography className={classes.selectedItemText}>
                {ClipsSearchStrings.Select}
              </Typography>
            );
          }
        }}
        MenuProps={{
          style: {
            zoom: '0.9',
            marginBottom: '3px',
            padding: '9px 14px !important',
            paddingRight: 0,
          },
          PaperProps: {
            sx: {
              borderRadius: '12px',
            },
          },
        }}
        sx={{
          width: '100%',
          border: '1px solid #cacae4',
          paddingRight: 0,

          '& .MuiSelect-select': {
            padding:
              selectedItemIndex > -1 ? '3px 0 2px 16px !important' : '8px 0 8px 16px !important',
          },

          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none!important',
          },
          '& .MuiOutlinedInput-notchedOutline:focus': {
            border: 'none!important',
          },
        }}
        IconComponent={() => (
          <Box className={classes.expandIconWrapper}>
            <ExpandIcon className={classes.expandIcon} />
          </Box>
        )}
      >
        {statusItems.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                setSelectedItemIndex(index);
                onChange(item.status);
              }}
              className={classes.menuItem}
              key={item.title}
            >
              <Box className={classes.menuIcon}>{item.icon}</Box>
              <Typography className={classes.menuItemText}>{item.title}</Typography>
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};
