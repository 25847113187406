import { FC, useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { SocialVerseAllApiModel } from '../../../../api/models/socialVerse';
// import { VideoApiModel } from '../../../../api/models/videos';
import { CommonTable } from '../../../../common/components/table/CommonTable';
import {
  defaultPagination,
  LayoutMode,
  MIN_TABLE_HEIGHT,
  TypeSocialVerse,
} from '../../../../common/constants/constants';
import { usePrevious } from '../../../../hooks/common';
import {
  createContentDataTableContent,
  createMobileContentDataTableContentFirstSlide,
  createMobileContentDataTableContentSecondSlide,
} from '../../../../services/helpers/tableMappers';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
  // getItemsFromPageAndSize,
} from '../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { setActiveFilter } from '../../../../store/slices/searchFiltersSlice';
import {
  getVideosBySearchFilter,
  goToSelectedPage,
  reset,
  setVideosSorting,
  updateSearch,
  updateSize,
  setRefetch,
  setLoadMore,
  resetPage,
} from '../../../../store/slices/videosSlice';
import { updateSearch as updateTopgolfSearch } from '../../../../store/slices/videosSlice';
import { useVideoTableIdContext } from '../../../SocialVerse/SocialVerse.helper';
import { ClientTypes } from '../../../../api/models/common';
import {
  NoFilterResultsStrings,
  SavedFiltersHeaderStrings,
  VideoStatusStrings,
} from '../../../../common/localization/en';
import { getKeywordsByVenueId } from '../../../../store/slices/keywordsSlice';
import { useClientType } from '../../../../services/hooks/useClientType';
import { getGeneralKPIs } from '../../../../store/slices/analyticsSlice';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../../common/constants/events/videosEvents';
import { makeStyles } from '@mui/styles';
import { Box, Button } from '@mui/material';
import { isFirefox } from 'react-device-detect';
import { useRef } from 'react';
import { setScrollY } from '../../../../store/slices/uiSlice';
import {
  BatchVideosToSocialVerse,
  GetVideosBySocialVerseId,
} from '../../../../store/slices/socialVerseSlice';
import { clearSelectedRows, resetSelectedRows } from '../../../../store/slices/selectedRowsSlice';
import { useToasts } from 'react-toast-notifications';
import { WebsiteRoutes } from '../../../../common/constants/routes';
import GridContentTable from '../gridContentTable/GridContentTable';
import { EmptyResultsPreview } from '../../../../common/components/EmptyResultsPreview/EmptyResultsPreview';
import { theme } from '../../../../theme';
import {
  CampaignVideosApiModel,
  GetCampaignVideosApiModel,
} from '../../../../api/models/campaigns';
import { openCampaignSummaryVideoDetails } from '../../../../store/slices/campaignsSlice';

const TABLE_HEIGHT = 'calc(100vh - 300px)';

export const useStyles = makeStyles(() => ({
  table: {
    position: 'relative',
    overflowX: 'auto',
    width: 'auto',
    '& #common-table-wrapper': {
      height: TABLE_HEIGHT,
      maxHeight: TABLE_HEIGHT,
      minHeight: MIN_TABLE_HEIGHT,
    },
    '& tr > td:first-child': {
      minWidth: '220px',
    },
    '& td > *': {
      fontSize: '13px !important',
    },
    '& th': {
      '&:nth-child(3) > div': {
        justifyContent: 'flex-start !important',
        paddingLeft: '30px',
      },
    },
    '& td': {
      '&:nth-child(3)': {
        textAlign: 'start !important',
        paddingLeft: '40px !important',
      },
      '&:nth-child(9)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(10)': {
        paddingLeft: '0px !important',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '48px',
        paddingRight: '12px',
      },
    },
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.light,
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.main,
    },
  },
  addBtn: {
    position: 'absolute',
    top: '10px',
    left: '20px',
    zIndex: '99',
  },
  totalClips: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#1A1538',
  },
  loadMoreBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  '@keyframes rotate': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
  loadMoreBtn: {
    borderRadius: '32px',
    border: '1px solid #DCDBE4',
    height: '32px',
    padding: '7px 12px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#1A1538',
    '& svg': {
      animation: '$rotate 2s linear infinite',
      color: theme.palette.primary.main,
    },
  },
  emptyFilterResults: {
    height: 'calc(100vh - 300px)',
  },
}));

interface ContentTableProps {
  handleOpenDeleteModal: (id: string, deleted: boolean) => void;
  statusFilter?: string;
  handleOpenConfirmationModal: (id: string) => void;
  handleOpenAddNewTagModal: (id: string) => void;
  archivedFilter: boolean;
  isSocialVersePage?: boolean;
  isCampaignSummaryPage?: boolean;
  handleAddVideoToCard: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  socialVerseCards?: SocialVerseAllApiModel[];
  onRowClick?: (id: string) => void;
  handleVideoReplace?: (addIdParams: {
    event: React.MouseEvent<HTMLButtonElement>;
    id: string;
    addUsingRowBtn: boolean;
  }) => void;
  isGridLayout?: boolean;
  videoStateOverride?: GetCampaignVideosApiModel;
  disablePagination?: boolean;
  onSortingUpdate?: (sorting: string) => void;
  onUpdateThumbnail?: () => void;
}

export const ContentTable: FC<ContentTableProps> = ({
  handleOpenDeleteModal,
  statusFilter,
  handleOpenConfirmationModal,
  handleOpenAddNewTagModal,
  handleAddVideoToCard,
  archivedFilter,
  isSocialVersePage = false,
  isCampaignSummaryPage = false,
  onRowClick,
  handleVideoReplace,
  isGridLayout,
  videoStateOverride,
  disablePagination,
  onSortingUpdate,
  onUpdateThumbnail,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const scrollbarRefProp = useRef<HTMLDivElement | null>(null);

  const isSocialVerseAddVideosPage = history.location.pathname.includes(
    WebsiteRoutes.SocialVerseAddVideos,
  );

  const videoState = useTypedSelector((state) => state.videos);
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    shouldRefetch: shouldRefetchVideos,
    loadMore,
  } = { ...videoState, ...videoStateOverride };
  const { trackEvent } = useTrackEvent();
  const { addToast } = useToasts();

  const { campaignSummaryVideoDetails } = useTypedSelector((state) => state.campaigns);
  const { selectedRows } = useTypedSelector((state) => state.selectedRows);

  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);
  const { scrollY } = useTypedSelector((state) => state.ui);

  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const params = useParams<{ id: string }>();
  const device = useWindowResize();
  const match = useRouteMatch();
  const visibleItems = isSocialVersePage
    ? (items as any[]).filter((item) => item.status !== VideoStatusStrings.Moderated.toUpperCase())
    : items;

  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const { newFilterAttributes } = useTypedSelector((state) => state.SearchFilterSlice);
  const { search, activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const prevStatus = usePrevious<string | undefined>(statusFilter);
  const prevArchived = usePrevious<boolean>(archivedFilter);

  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const entity = isNotTopgolf ? 'videos' : 'TopgGolfVideos';
  const { isHealthCare } = useClientType();

  const { kpis } = useTypedSelector((state) => state.analytics);
  const isConversionsHidden = kpis.convertedReferrals === 0;

  const { currentSocialVerseVideos, socialVerse } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  const withPagination = totalItems >= defaultPagination.size;

  const shouldResetPagination = useMemo(() => {
    if (prevArchived !== archivedFilter || prevStatus !== statusFilter) {
      return defaultPagination.page;
    }
    return page;
  }, [prevArchived, archivedFilter, prevStatus, statusFilter, page]);

  useEffect(() => {
    dispatch(
      getGeneralKPIs({
        accountId: venueId,
      }),
    );

    return () => {
      dispatch(setActiveFilter(null));
      dispatch(reset());
      dispatch(resetSelectedRows());
      dispatch(resetPage());
    };
  }, [dispatch, venueId]);

  useEffect(() => {
    if (venueId) {
      dispatch(getKeywordsByVenueId({ accountId: venueId }));
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    if (venueId) {
      if (isSocialVersePage) {
        dispatch(
          getVideosBySearchFilter({
            venueId,
            filter:
              newFilterAttributes.length > 0
                ? newFilterAttributes
                : activeFilter?.filter.attributes || [],
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
            },
            search,
          }),
        );
      } else {
        dispatch(
          getVideosBySearchFilter({
            venueId,
            filter: activeVideoFilterAttributes,
            pageable: {
              page: shouldResetPagination,
              size,
              sort,
            },
            search,
            loadMore,
          }),
        );
      }

      if (shouldRefetchVideos) {
        dispatch(setRefetch(false));
      }
    }

    // dispatch(getHeroes({ search, venueId, pageable: { page, size, sort } }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    page,
    size,
    sort,
    search,
    venueId,
    statusFilter,
    archivedFilter,
    activeFilter,
    newFilterAttributes,
    isSocialVersePage,
    keywords,
    shouldRefetchVideos,
    activeVideoFilterAttributes,
    loadMore,
  ]);

  useEffect(() => {
    if (scrollbarRefProp.current && scrollY) {
      scrollbarRefProp.current.scrollTo(0, scrollY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onRowClick]);
  const tableConfig = {
    items: visibleItems,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    handleOpenAddNewTagModal,
    handleAddVideoToCard,
    handleVideoReplace,
    renderCheckbox:
      (activeFilter || isSocialVerseAddVideosPage) &&
      socialVerse?.viewMode !== TypeSocialVerse.Educational
        ? true
        : false,
    isSocialVerse: isSocialVersePage,
    isHealthCare,
    isConversionsHidden,
    totalKeywords: keywords.length,
    isFirefox,
    replace:
      currentSocialVerseVideos.length > 0 && socialVerse?.viewMode === TypeSocialVerse.Educational,
    currentSocialVerseVideos,
    activeFilter: !!activeFilter || isSocialVersePage,
  };

  const contentDataTableContents = createContentDataTableContent(tableConfig);

  const mobileContentDataTableSecondSlide = createMobileContentDataTableContentSecondSlide({
    items: visibleItems,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    handleOpenAddNewTagModal,
  });

  const mobileContentDataTableFirstSlide = createMobileContentDataTableContentFirstSlide({
    items: visibleItems,
  });

  const onGoToPage = (targetPage: number) => {
    trackEvent(VideosEventNames.videosTablePageChanged, {
      value: targetPage.toString(),
    });

    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }
    dispatch(setScrollY(undefined));
    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onLoadMore = () => {
    const convertedTargetPage = page + 1;
    trackEvent(VideosEventNames.videosTablePageChanged, {
      value: convertedTargetPage.toString(),
    });
    dispatch(setLoadMore(true));
    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    trackEvent(VideosEventNames.videosTableSortClicked, {
      value: name,
    });
    dispatch(setScrollY(undefined));
    onSortingUpdate?.(name);
    dispatch(setVideosSorting(name));
  };

  const { storyVideoId, setStoryVideoId, setWithEditThumbnailOpen } = useVideoTableIdContext();

  const onClickRow = (id: string) => {
    if (scrollbarRefProp?.current) {
      dispatch(setScrollY(scrollbarRefProp.current.scrollTop));
    }

    onRowClick?.(id);
    if (isSocialVersePage) {
      setStoryVideoId(id);
      setWithEditThumbnailOpen?.(false);
    } else if (isCampaignSummaryPage) {
      const video = (items as CampaignVideosApiModel[]).find((video) => video.id === id);
      if (video) {
        dispatch(openCampaignSummaryVideoDetails({ userId: video.userId, videoId: id }));
      }
    } else {
      history.push(match.path.replace(':id', id));
    }
  };

  const changeSize = (size: number) => {
    trackEvent(VideosEventNames.videosTableSizeChanged, {
      value: size.toString(),
    });
    dispatch(setScrollY(undefined));
    dispatch(updateSize(size));
  };

  const handleAddBulkVideos = () => {
    if (socialVerse)
      dispatch(
        BatchVideosToSocialVerse({
          videoIds: [...selectedRows].reverse().map((v) => v.id),
          id: socialVerse.id,
        }),
      ).then(() => {
        dispatch(clearSelectedRows());
        dispatch(GetVideosBySocialVerseId({ id: params.id }));
        addToast(
          SavedFiltersHeaderStrings.AssignedSVMessage.replace(
            '<SocialVerse Name>',
            socialVerse.name,
          ),
          {
            appearance: 'success',
            autoDismissTimeout: 2500,
          },
        );
      });
  };

  const isLoadMoreBtnDisabled = isLoading || totalItems <= items.length;
  const isEmptyFilterResults =
    (activeVideoFilterAttributes.length > 0 || search.length > 0) && items.length === 0;

  if (device === LayoutMode.Mobile) {
    const sliderSettings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      pauseOnFocus: true,
      cssEase: 'linear',
    };

    return (
      <Slider {...sliderSettings}>
        <div key={1}>
          <CommonTable
            content={mobileContentDataTableFirstSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            socialverseViewMode={socialVerse?.viewMode}
          />
        </div>
        <div key={2}>
          <CommonTable
            content={mobileContentDataTableSecondSlide}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            socialverseViewMode={socialVerse?.viewMode}
          />
        </div>
      </Slider>
    );
  }

  return (
    <>
      <Box className={classes.table}>
        {(Boolean(activeFilter) || isSocialVersePage) &&
          socialVerse?.viewMode !== TypeSocialVerse.Educational && (
            <Box
              className={classes.addBtn}
              style={{
                position: withPagination && !isLoading ? 'absolute' : 'static',
                margin: withPagination && !isLoading ? '0' : '10px 0 0 18px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ borderRadius: '8px' }}
                disabled={!selectedRows.length}
                onClick={handleAddBulkVideos}
              >
                Add Selected
              </Button>
            </Box>
          )}
        {isGridLayout ? (
          <>
            <GridContentTable
              items={items as any}
              handleOpenConfirmationModal={handleOpenConfirmationModal}
              handleOpenDeleteModal={handleOpenDeleteModal}
              isEmptyFilterResults={isEmptyFilterResults}
              onLoadMore={onLoadMore}
              isLoading={isLoading}
              isLoadMoreBtnDisabled={isLoadMoreBtnDisabled}
              onUpdateThumbnail={onUpdateThumbnail}
            />
            {isEmptyFilterResults && (
              <Box height={TABLE_HEIGHT}>
                <EmptyResultsPreview
                  title={NoFilterResultsStrings.NoFilterResultsClipsTitle}
                  subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
                />
              </Box>
            )}
          </>
        ) : (
          <CommonTable
            selectedRowId={
              isSocialVersePage
                ? storyVideoId || ''
                : isCampaignSummaryPage
                ? campaignSummaryVideoDetails.videoId
                : params?.id || ''
            }
            content={contentDataTableContents}
            page={convertApiPageToFrontEndPage(page)}
            sort={sort}
            totalItems={totalItems}
            totalPages={totalPages}
            isLoading={isLoading}
            noContent={error}
            tablePadding="0"
            goToPage={onGoToPage}
            onSortHeaderClick={onSort}
            onClickRow={onClickRow}
            size={size}
            onSizeChange={changeSize}
            activeFilter={Boolean(activeFilter)}
            withSearchBar={isNotTopgolf ? false : true}
            searchBarProps={{
              adaptiveWidth: true,
              entity,
              updateSearch: isNotTopgolf ? updateSearch : updateTopgolfSearch,
            }}
            scrollbarRefProp={scrollbarRefProp}
            withCustomSearchBar
            socialverseViewMode={socialVerse?.viewMode}
            emptyTableCustomPreview={
              <EmptyResultsPreview
                title={NoFilterResultsStrings.NoFilterResultsClipsTitle}
                subtitle={NoFilterResultsStrings.NoFilterResultsSubTitle}
              />
            }
            showEmptyTableCustomPreview={isEmptyFilterResults}
            disablePagination={disablePagination}
          />
        )}
      </Box>
    </>
  );
};
