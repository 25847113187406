import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  isIncentiveCampaign?: boolean;
  flex?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: ({ isIncentiveCampaign, flex }) => ({
    zIndex: 3,
    border: '1px solid #F2F4F7',
    borderRadius: '8px',
    background: '#F9FAFB',
    display: flex ? 'flex' : 'block',
    alignItems: 'center',
    padding: flex ? '4px !important' : '14px 8px !important',
    width: isIncentiveCampaign || flex ? 'fit-content' : '256px',
    height: flex ? 'auto' : '690px',
    margin: '30px 10px 16px 14px',
  }),
  tab: ({ flex }) => ({
    padding: '0 12px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ...(!flex && {
      '&:not(:first-child)': {
        marginTop: '18px',
      },
    }),
    width: '100%',
    border: '1px solid #D9D9D9',
    borderRadius: '6px',
  }),
  tabName: {
    fontsize: '15px',
    fontWeight: '500',
    userSelect: 'none',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
    color: '#667085',
  },
  amount: {
    textWrap: 'nowrap',
    marginLeft: '.5em',
    height: '22px',
    borderRadius: '8px',
    background: '#F2F4F7',
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    width: 'fit-content',
    fontWeight: '600',
    fontSize: '12px',
  },
  total: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '7px 20px 7px 12px',
    marginTop: '12px',
  },
  totalLabel: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: '700',
    lineHeight: '20px',
    color: '#667085',
  },
  totalNumber: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '700',
    lineHeight: '18px',
    total: '#344054',
  },
}));
