import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    padding: '24px 0 38px',
  },
  titleText: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '28px',
  },
  close: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  content: {
    padding: '0 71px 0',
  },
  actions: {
    padding: '24px 62px',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: 40,
  },
  paper: {
    maxWidth: 810,
  },
  group: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    flex: 1,
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  radio: {
    margin: 0,

    '& .MuiFormControlLabel-label': {
      fontSize: 12,
    },
  },
  download: {
    width: '144px',
    height: '44px',
  },
}));
