import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { TableDTO } from '../storeModels';
import {
  SmsMessageModel,
  SmsMessagesCountsData,
  SmsMessageStatus,
  SmsMessageType,
  SmsMessagesTotalCountsData,
} from '../../api/models/smsMessages';
import { defaultPagination } from '../../common/constants/constants';
import { GetTableDataResponse } from '../../api/models/common';
import { httpClient } from '../../services/httpClient/httpClient';
import { SmsMessageEndpoints } from '../../api/endpoints';
import { getTableSorting } from './utils';

import formatDate from 'date-fns/format';
import addMonthsToDate from 'date-fns/addMonths';

export const DATE_FORMAT = 'yyyy-MM-dd';
export const BILLING_START_DATE = new Date(2023, 0, 1); // January 2023

const startOfMonth = new Date().setDate(1);

interface InitialStateParams {
  totalCountsData: SmsMessagesTotalCountsData;
  countsData: SmsMessagesCountsData;
  isCountsDataLoading: boolean;
  dateFilter: {
    from: string;
    to: string;
  };
  communicationType?: SmsMessageType;
  messageStatus?: SmsMessageStatus;
}

const initialState: TableDTO<SmsMessageModel> & InitialStateParams = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  totalCountsData: {
    totalDelivered: 0,
    totalOptOut: 0,
    totalOutgoing: 0,
    totalUndelivered: 0,
  },
  countsData: {
    deliveredAcknowledgements: 0,
    deliveredInvites: 0,
    optOuts: 0,
    undeliveredAcknowledgements: 0,
    undeliveredInvites: 0,
    totalDelivered: 0,
    totalUndelivered: 0,
  },
  isCountsDataLoading: false,
  dateFilter: {
    from: formatDate(startOfMonth, DATE_FORMAT),
    to: formatDate(addMonthsToDate(startOfMonth, 1), DATE_FORMAT),
  },
};

export interface GetSmsMessagesRequestParams {
  accountId: string;
  communicationType?: SmsMessageType;
  messageStatus?: SmsMessageStatus;
  from: string;
  to: string;
  search?: string;
  page: number;
  size: number;
  sort: string[];
}

export const getSmsMessages = createAsyncThunk(
  'messages/getSmsMessages',
  async (options: GetSmsMessagesRequestParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<
        GetSmsMessagesRequestParams,
        GetTableDataResponse<SmsMessageModel>
      >({
        url: SmsMessageEndpoints.GetSmsMessages,
        requiresToken: true,
        params: {
          ...options,
          //@ts-ignore
          sort: options.sort.toString(),
        },
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetSmsMessagesTotalCountsRequestParams {
  accountId: string;
}

export const getSmsMessagesTotalCounts = createAsyncThunk(
  'messages/getSmsMessagesTotalCounts',
  async (options: GetSmsMessagesTotalCountsRequestParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<
        GetSmsMessagesTotalCountsRequestParams,
        SmsMessagesTotalCountsData
      >({
        url: SmsMessageEndpoints.GetMessagesTotalCounts,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

interface GetSmsMessagesCountsRequestParams {
  accountId: string;
  communicationType?: SmsMessageType;
  toNumber?: string;
  messageStatus?: SmsMessageStatus;
  from?: string;
  to?: string;
  search?: string;
}

export const getSmsMessagesCounts = createAsyncThunk(
  'messages/getSmsMessagesCounts',
  async (options: GetSmsMessagesCountsRequestParams, { rejectWithValue }) => {
    try {
      return await httpClient.get<GetSmsMessagesCountsRequestParams, SmsMessagesCountsData>({
        url: SmsMessageEndpoints.GetSmsMessagesCounts,
        requiresToken: true,
        params: options,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const smsMessagesSlice = createSlice({
  name: 'smsMessages',
  initialState,
  reducers: {
    clearSmsMessageItems(state) {
      state.items = [];
    },
    updateSmsMessagesTableSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
    },
    updateSmsMessagesTableSearch(state, action: PayloadAction<string>) {
      state.page = 0;
      state.search = action.payload;
    },
    updateSmsMessagesTablePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    updateSmsMessagesTableSorting(state, action: PayloadAction<string>) {
      state.sort = getTableSorting(current(state), action.payload);
    },
    updateSmsMessageCommunicationType(state, action: PayloadAction<SmsMessageType | undefined>) {
      state.communicationType = action.payload;
    },
    updateSmsMessageStatus(state, action: PayloadAction<SmsMessageStatus | undefined>) {
      state.messageStatus = action.payload;
    },
    updateSmsMessagesLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    updateDateFilter(state, action: PayloadAction<{ from: Date; to: Date }>) {
      state.dateFilter = {
        from: formatDate(action.payload.from, DATE_FORMAT),
        to: formatDate(action.payload.to, DATE_FORMAT),
      };
    },
    resetDateFilter(state) {
      state.dateFilter = initialState.dateFilter;
    },
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getSmsMessages.rejected, (state) => {
      state.isLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getSmsMessages.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getSmsMessages.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.items = payload.items;
      state.page = payload.page;
      state.size = payload.size;
      state.totalItems = payload.totalItems;
      state.totalPages = payload.totalPages;
    });

    reducersBuilder.addCase(getSmsMessagesTotalCounts.rejected, (state) => {
      state.isCountsDataLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getSmsMessagesTotalCounts.pending, (state) => {
      state.isCountsDataLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getSmsMessagesTotalCounts.fulfilled, (state, { payload }) => {
      state.isCountsDataLoading = false;
      state.error = false;
      state.totalCountsData = payload;
    });

    reducersBuilder.addCase(getSmsMessagesCounts.rejected, (state) => {
      state.isCountsDataLoading = false;
      state.error = true;
    });
    reducersBuilder.addCase(getSmsMessagesCounts.pending, (state) => {
      state.isCountsDataLoading = true;
      state.error = false;
    });
    reducersBuilder.addCase(getSmsMessagesCounts.fulfilled, (state, { payload }) => {
      state.isCountsDataLoading = false;
      state.error = false;
      state.countsData = payload;
    });
  },
});

export const {
  updateDateFilter,
  updateSmsMessagesTableSize,
  updateSmsMessagesTablePage,
  updateSmsMessagesTableSearch,
  updateSmsMessagesTableSorting,
  updateSmsMessageCommunicationType,
  updateSmsMessageStatus,
  updateSmsMessagesLoading,
  clearSmsMessageItems,
  resetDateFilter,
} = smsMessagesSlice.actions;

export default smsMessagesSlice.reducer;
