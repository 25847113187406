import * as React from 'react';

export const ActivitiesComputer: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="25" fill="none" viewBox="0 0 35 25">
      <g clipPath="url(#clip0)">
        <path fill="#B0B8E9" d="M31.384 1.025H3.617v20.963h27.767V1.025z"></path>
        <path fill="#474374" d="M31.182 1.28H3.818v20.452h27.364V1.28z"></path>
        <path fill="#CACEE7" d="M35 23.56H0V25h35v-1.44z"></path>
        <path fill="#C3CAF7" d="M0 23.56l3.617-1.571h27.766L35 23.56H0z"></path>
        <path fill="#474374" d="M3.94 23.003l2.333-1.014h22.453l2.335 1.014H3.939z"></path>
        <path fill="#fff" d="M29.636 2.834H5.366V20.18h24.27V2.834z"></path>
        <path fill="#B0B8E9" d="M29.635 2.834H5.344v.668h24.291v-.668z"></path>
        <path
          fill="#C7C7C7"
          d="M5.815 3.168c0 .091-.066.165-.147.165-.081 0-.147-.074-.147-.165 0-.092.066-.166.147-.166.081 0 .147.075.147.166z"
        ></path>
        <path
          fill="#fff"
          d="M6.413 3.168c0 .091-.065.165-.146.165-.081 0-.147-.074-.147-.165 0-.092.066-.166.147-.166.08 0 .146.075.146.166z"
        ></path>
        <path
          fill="#4080FE"
          d="M7.012 3.168c0 .091-.066.165-.147.165-.08 0-.146-.074-.146-.165 0-.092.065-.166.146-.166.08 0 .147.075.147.166z"
        ></path>
        <path fill="#B0B8E9" d="M29.635 3.501h-.43v16.677h.43V3.501z"></path>
        <path
          fill="#B0B8E9"
          d="M29.42 9.284c-.062 0-.111-.056-.111-.126V3.725c0-.07.05-.126.111-.126.062 0 .112.057.112.126v5.433c0 .069-.05.126-.112.126z"
        ></path>
        <path fill="#fff" d="M25.443 4.55H12.36v.562h13.083v-.561z"></path>
        <path fill="#EEF0FB" d="M25.447 5.12H12.355v-.573h13.092v.572z"></path>
        <path fill="#4080FE" d="M25.447 4.545h-.508v.574h.508v-.574z"></path>
        <path
          fill="#fff"
          d="M25.352 4.99l-.046.04-.085-.128-.032-.046a.084.084 0 00.042-.046l.034.05.087.13z"
        ></path>
        <path
          fill="#fff"
          d="M25.261 4.691c-.042-.061-.121-.074-.177-.026a.154.154 0 00-.023.199.117.117 0 00.177.026c.01-.01.019-.02.026-.03a.156.156 0 00-.003-.169zm-.054.154a.07.07 0 01-.018.011.071.071 0 01-.088-.027.092.092 0 01.014-.119c.033-.028.08-.02.106.016.017.025.02.057.01.085a.08.08 0 01-.024.034z"
        ></path>
        <path fill="#C7C7C7" d="M12.493 4.662h-.03v.34h.03v-.34z"></path>
        <path
          fill="#4080FE"
          d="M28.33 5.134a.254.254 0 01-.297 0 .317.317 0 01-.126-.195c-.038-.17.056-.343.207-.385.152-.042.305.063.342.234a.335.335 0 01-.126.346z"
        ></path>
        <path
          fill="#fff"
          d="M28.27 4.826c0 .054-.039.098-.087.098-.048 0-.086-.044-.086-.098 0-.054.038-.097.086-.097.048 0 .087.044.087.097zM28.33 5.095v.04a.254.254 0 01-.297 0v-.04c0-.092.067-.168.149-.168.083 0 .149.075.149.168z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M27.35 5.182h-.196v-.078a.013.013 0 00-.006-.01l-.021-.015c-.004-.002-.008-.003-.011 0l-.06.039-.099-.192.06-.04c.004-.001.005-.005.005-.01v-.014-.013c0-.005-.001-.01-.005-.012l-.06-.039.098-.192.06.039a.01.01 0 00.012 0l.021-.015a.012.012 0 00.006-.01v-.08h.196v.08c0 .004.003.008.006.01l.022.014c.004.003.008.003.011 0l.06-.038.099.192-.06.04c-.004.001-.006.006-.006.01v.028c0 .005.002.009.005.01l.06.04-.098.192-.06-.039c-.003-.002-.007-.002-.011.001l-.022.014a.013.013 0 00-.006.01v.078zm-.147-.054h.099v-.024c0-.025.012-.048.032-.06l.017-.01a.053.053 0 01.063-.002l.018.012.049-.097-.018-.012a.069.069 0 01-.03-.062v-.02a.068.068 0 01.03-.063l.019-.012-.05-.096-.017.011a.053.053 0 01-.062-.002.112.112 0 00-.017-.01.07.07 0 01-.033-.06v-.024h-.099v.024a.068.068 0 01-.032.06l-.018.01a.053.053 0 01-.062.002l-.017-.011-.05.096.019.012c.02.012.03.036.03.061V4.872a.068.068 0 01-.03.063l-.018.012.049.096.018-.012a.053.053 0 01.062.002c.006.004.01.008.017.01.02.012.033.036.033.06v.025h-.002zm.049-.103c-.08 0-.145-.073-.145-.164 0-.09.065-.164.145-.164.08 0 .145.074.145.164.001.091-.064.164-.145.164zm0-.272c-.053 0-.096.049-.096.108 0 .06.043.109.096.109.053 0 .096-.049.096-.109s-.043-.108-.096-.108z"
        ></path>
        <path fill="#EEF0FB" d="M29.204 7.428H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 7.834h-.308v.348h.308v-.348zM13.505 8.182h-.308l.126-.175-.126-.173h.309l.125.173-.126.175z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 7.834l.05.115.112.018-.08.089.018.126-.1-.06-.1.06.019-.126-.081-.089.112-.018.05-.115z"
        ></path>
        <path
          fill="#474374"
          d="M16.31 7.937h-2.457v.142h2.457v-.142zM27.856 7.937h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#B0B8E9" d="M24.688 7.937H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#fff"
          stroke="#EEF0FB"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M12.195 8.993h-.308v.348h.308v-.348zM13.505 9.342h-.308l.126-.175-.126-.174h.309l.125.174-.126.175z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 8.993l.05.115.112.018-.08.09.018.126-.1-.06-.1.06.019-.126-.081-.09.112-.018.05-.115z"
        ></path>
        <path
          fill="#474374"
          d="M16.31 9.097h-2.457v.142h2.457v-.142zM27.856 9.097h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#B0B8E9" d="M24.688 9.097H17.06v.142h7.627v-.142z"></path>
        <path fill="#EEF0FB" d="M29.204 9.747H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 10.152h-.308v.349h.308v-.348zM13.505 10.5h-.308l.126-.174-.126-.174h.309l.125.174-.126.174zM12.713 10.152l.05.115.112.018-.08.09.018.125-.1-.059-.1.06.019-.126-.081-.09.112-.018.05-.114z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 10.255h-2.457v.142h2.457v-.142zM27.856 10.255h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 10.255H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#fff"
          stroke="#EEF0FB"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M12.195 11.311h-.308v.349h.308v-.349zM13.505 11.66h-.308l.126-.175-.126-.174h.309l.125.174-.126.174z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 11.311l.05.115.112.018-.08.09.018.125-.1-.059-.1.06.019-.126-.081-.09.112-.018.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 11.414h-2.457v.142h2.457v-.142zM27.856 11.414h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 11.414H17.06v.142h7.627v-.142z"></path>
        <path fill="#EEF0FB" d="M29.204 12.065H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 12.47h-.308v.35h.308v-.35zM13.505 12.818h-.308l.126-.174-.126-.174h.309l.125.174-.126.174z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 12.47l.05.115.112.018-.08.09.018.125-.1-.06-.1.06.019-.125-.081-.09.112-.018.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 12.573h-2.457v.142h2.457v-.142zM27.856 12.573h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 12.573H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#fff"
          stroke="#EEF0FB"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M12.195 13.63h-.308v.349h.308v-.349zM13.505 13.978h-.308l.126-.174-.126-.174h.309l.125.174-.126.174zM12.713 13.63l.05.115.112.018-.08.09.018.125-.1-.06-.1.06.019-.125-.081-.09.112-.018.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 13.733h-2.457v.142h2.457v-.142zM27.856 13.733h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 13.733H17.06v.142h7.627v-.142z"></path>
        <path fill="#EEF0FB" d="M29.204 14.384H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 14.789h-.308v.348h.308v-.348zM13.505 15.137h-.308l.126-.173-.126-.175h.309l.125.175-.126.173z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 14.789l.05.115.112.018-.08.09.018.125-.1-.06-.1.06.019-.126-.081-.089.112-.018.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 14.892h-2.457v.142h2.457v-.142zM27.856 14.892h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 14.892H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#fff"
          stroke="#EEF0FB"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M12.195 15.948h-.308v.348h.308v-.348zM13.505 16.297h-.308l.126-.174-.126-.175h.309l.125.175-.126.174zM12.713 15.948l.05.115.112.018-.08.09.018.126-.1-.06-.1.06.019-.127-.081-.089.112-.018.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 16.051h-2.457v.142h2.457v-.142zM27.856 16.051h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 16.051H17.06v.142h7.627v-.142z"></path>
        <path fill="#EEF0FB" d="M29.204 16.702H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 17.107h-.308v.349h.308v-.349zM13.505 17.456h-.308l.126-.175-.126-.174h.309l.125.174-.126.175zM12.713 17.107l.05.115.112.018-.08.09.018.126-.1-.06-.1.06.019-.127-.081-.09.112-.017.05-.115z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 17.21h-2.457v.142h2.457v-.142zM27.856 17.21h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 17.21H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#fff"
          stroke="#EEF0FB"
          strokeMiterlimit="10"
          strokeWidth="0.5"
          d="M12.195 18.267h-.308v.349h.308v-.35zM13.505 18.615h-.308l.126-.174-.126-.174h.309l.125.174-.126.174zM12.713 18.267l.05.114.112.019-.08.09.018.125-.1-.06-.1.06.019-.126-.081-.09.112-.018.05-.114z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 18.37h-2.457v.142h2.457v-.142zM27.856 18.37h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 18.37H17.06v.142h7.627v-.142z"></path>
        <path fill="#EEF0FB" d="M29.204 19.02H11.084v1.16h18.121v-1.16z"></path>
        <path
          fill="#fff"
          d="M12.195 19.426h-.308v.349h.308v-.349zM13.505 19.774h-.308l.126-.174-.126-.174h.309l.125.174-.126.174z"
        ></path>
        <path
          fill="#4080FE"
          d="M12.713 19.426l.05.114.112.019-.08.09.018.125-.1-.06-.1.06.019-.126-.081-.09.112-.018.05-.114z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M16.31 19.529h-2.457v.142h2.457v-.142zM27.856 19.529h-2.231v.142h2.231v-.142z"
        ></path>
        <path fill="#C7C7C7" d="M24.688 19.529H17.06v.142h7.627v-.142z"></path>
        <path
          fill="#B0B8E9"
          d="M16.311 6.382h-3.43v.415h3.43v-.415zM12.354 6.382h-.467v.415h.467v-.415z"
        ></path>
        <path
          fill="#fff"
          d="M12.355 6.382l-.058.068-.057.066-.06.068-.003.002-.054.06-.002.002-.003-.002-.054-.06-.002-.002-.06-.068-.058-.066-.057-.068.06.06a5.62 5.62 0 01.124.133l.05.053.048-.053.066-.07.059-.062.06-.06z"
        ></path>
        <path
          fill="#fff"
          d="M12.073 6.575h-.001l-.008.011-.036.05-.045.057a1.228 1.228 0 01-.095.105 1.04 1.04 0 01.044-.062l.045-.057.047-.054a.736.736 0 01.039-.04l.009-.009v-.001zM12.354 6.797a1.719 1.719 0 01-.095-.104l-.046-.058a1.009 1.009 0 01-.036-.05l-.007-.01h-.001l.01.01a1.708 1.708 0 01.085.094l.046.057.044.061z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M22.084 6.382h-3.43v.415h3.43v-.415zM17.871 6.522c0 .049-.034.088-.078.088-.043 0-.078-.039-.078-.088 0-.048.035-.088.078-.088.044.001.078.04.078.088zM17.927 6.765v.031h-.269v-.031c0-.084.06-.152.135-.152.074 0 .134.068.134.152zM18.072 6.47c0 .049-.035.088-.079.088-.043 0-.078-.039-.078-.088 0-.048.035-.088.078-.088.044 0 .079.04.079.088z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M18.128 6.712v.032h-.27v-.032c0-.083.06-.15.135-.15.074-.002.135.067.135.15zM27.857 6.382h-3.43v.415h3.43v-.415zM23.9 6.383h-.467v.415h.467l.14-.207-.14-.208zm.004.239c-.015 0-.028-.014-.028-.031 0-.018.013-.032.028-.032.015 0 .027.014.027.032 0 .017-.012.03-.027.03z"
        ></path>
        <path fill="#4080FE" d="M11.083 3.501h-5.74v16.677h5.74V3.501z"></path>
        <path
          fill="#fff"
          d="M10.28 5.971H6.147v1.237h4.135V5.971zM6.723 4.545h-.577v.105h.577v-.105zM6.723 5.014h-.577v.105h.577v-.105zM6.723 4.782h-.577v.105h.577v-.105z"
        ></path>
        <path fill="#EEF0FB" d="M8.07 4.545h-.8v.574h.8v-.574z"></path>
        <path
          fill="#EEF0FB"
          d="M8.07 4.545v.574h-.8v-.574c.034.022.067.046.102.068a5.328 5.328 0 01.214.148h0l.084.06.084-.06h-.001l.113-.079.102-.069.103-.068z"
        ></path>
        <path
          fill="#fff"
          d="M8.07 4.545c-.031.026-.065.05-.097.076-.033.024-.066.05-.099.073l-.104.076-.003.002-.092.067-.005.003-.004-.003-.093-.067-.003-.002-.104-.076a8.914 8.914 0 01-.098-.073l-.098-.076c.034.022.067.046.102.068a5.328 5.328 0 01.214.148l.084.06.084-.06.112-.079.102-.069.103-.068z"
        ></path>
        <path
          fill="#fff"
          d="M7.587 4.76h-.001l-.013.013a1.535 1.535 0 01-.14.119 2.209 2.209 0 01-.162.116l.075-.068a2.253 2.253 0 01.224-.17l.016-.01h.001zM8.069 5.009a1.48 1.48 0 01-.163-.117 2.251 2.251 0 01-.14-.119l-.012-.012h-.001.001l.016.01c.023.014.044.03.066.045a3.553 3.553 0 01.158.125c.025.02.05.044.075.068z"
        ></path>
        <path fill="#140A7F" d="M11.083 8.287h-5.74v.88h5.74v-.88z" opacity="0.1"></path>
        <path
          fill="#fff"
          d="M9.475 8.476H6.146v.517h3.329v-.517zM9.475 9.488H6.146v.517h3.329v-.517zM9.475 10.5H6.146v.517h3.329V10.5zM9.475 11.512H6.146v.517h3.329v-.517z"
        ></path>
        <path
          fill="#B0B8E9"
          d="M10.18 12.524H6.854v.517h3.328v-.517zM10.18 13.537H6.854v.516h3.328v-.516z"
        ></path>
        <path
          fill="#fff"
          d="M9.475 14.548H6.146v.517h3.329v-.517zM9.475 15.56H6.146v.517h3.329v-.517z"
        ></path>
        <path
          fill="#D7E7FF"
          d="M32.29 4.29C32.7 2.434 31.698.557 30.053.1c-1.646-.46-3.31.674-3.72 2.53-.408 1.856.595 3.733 2.24 4.19 1.645.46 3.31-.674 3.719-2.53z"
        ></path>
        <path fill="#fff" d="M31.402 1.964h-4.188v2.998h4.188V1.964z"></path>
        <path
          fill="#A6C7F9"
          d="M31.403 1.964c-.169.182-.341.357-.512.537a75.032 75.032 0 01-1.06 1.066l-.016.017-.482.472-.024.022-.024-.022-.481-.473-.017-.016a57.085 57.085 0 01-1.06-1.066c-.17-.18-.343-.354-.512-.537.18.156.357.32.535.481a60.189 60.189 0 011.12 1.052l.44.422c.145-.14.292-.283.438-.422l.002-.002a74.243 74.243 0 011.118-1.05c.179-.16.355-.324.535-.481z"
        ></path>
        <path
          fill="#A6C7F9"
          d="M28.875 3.565l-.005.005-.066.063c-.105.1-.212.196-.322.289a13.58 13.58 0 01-.824.65 7.374 7.374 0 01-.432.294c.127-.126.259-.243.392-.357a14.336 14.336 0 01.825-.651 7.496 7.496 0 01.426-.289l.006-.004zM31.393 4.866a7.178 7.178 0 01-.432-.294 13.58 13.58 0 01-.825-.65 7.48 7.48 0 01-.321-.288l-.067-.064-.005-.005a.028.028 0 00.007.003l.081.053c.117.075.231.155.344.237a13.42 13.42 0 01.825.65c.134.115.266.232.393.358z"
        ></path>
        <path
          fill="#FFC727"
          d="M31.165 2.683c.353-.136.54-.57.42-.967-.122-.399-.507-.611-.86-.475s-.54.57-.42.968c.122.398.507.61.86.474z"
        ></path>
        <path
          fill="#fff"
          d="M30.87 2.131v-.62c0-.046.034-.085.075-.085.041 0 .074.039.074.085v.62c0 .048-.033.085-.074.085-.04 0-.075-.037-.075-.085zm.075.37c-.04 0-.075-.037-.075-.084v-.03c0-.047.034-.085.075-.085.041 0 .074.038.074.084v.03c0 .048-.033.085-.074.085z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H35V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
