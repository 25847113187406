import { FC } from 'react';
import { PreviewContainer } from '../previews.style';
import { Box } from '@mui/material';
import { IphoneXframeDeprecated } from '../phonePreviewWrapper';
import defaultBg from '../../../assets/default-filter-bg-2.jpg';

import { useStyles } from './FilterPreview.helper';
import { ContentWrapper } from '../EducationalCardsPreview/EducationalCardsPreview.style';

interface FilterPreviewProps {
  filterImg: string;
}

export const FilterPreview: FC<FilterPreviewProps> = ({ filterImg }) => {
  const classes = useStyles();
  return (
    <PreviewContainer>
      <IphoneXframeDeprecated>
        <ContentWrapper>
          <img className={classes.backgroundImg} src={defaultBg} alt="background" />
          <Box className={classes.filterImgWrapper}>
            {filterImg ? <img className={classes.filterImg} src={filterImg} alt="filter" /> : null}
          </Box>
        </ContentWrapper>
      </IphoneXframeDeprecated>
    </PreviewContainer>
  );
};
