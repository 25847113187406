import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '32px',
    backgroundColor: '#F5F6F8',
    borderRadius: '12px',
    marginTop: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 23px',
    cursor: 'pointer',
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#1A1538',
  },
  tableWrapper: {
    marginTop: '20px',
    padding: '0 5px',
  },
  tableHeadCell: {
    color: '#8B89A0',
  },
  tableRowCell: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 16px',
  },
  borderedCell: {
    border: '1px solid #475467',
    borderRadius: '16px',
    width: 'fit-content',
    margin: '0 auto',
    padding: '0 10px',
    color: '#344054',
  },
}));
