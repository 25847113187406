import { Box, Grid, Typography } from '@mui/material';
import sortBy from 'lodash/sortBy';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { SocialVerseVideos } from '../../../api/models/socialVerse';
import { reorderArray } from '../../../services/utilities';
import { getVideoByIdForSocialVerse } from '../../../store/slices/videosSlice';
import { getTopGolfVideoByIdForSocialVerse } from '../../../store/slices/topGolfVideosSlice';
import { SocialVerseStrings } from '../../localization/en';
import { VideoCardComponent } from '../VideoCardComponent/VideoCardComponent';
import { useAppDispatch, useTypedSelector } from './../../../store/index';
import {
  DeleteVideoFromSocialVerse,
  deleteVideoFromSocialVerseAll,
  GetVideosBySocialVerseId,
  UpdateVideoPositionInSocialVerse,
} from './../../../store/slices/socialVerseSlice';
import { useAddedVideoConfigStyle } from './AddedVideoConfiguration.style';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../constants/events/socialVerseEvents';
import { TypeSocialVerse } from '../../constants/constants';

interface IAdedVideoConfigurationProps {
  handlePlayVideo: (videoUrl: string) => void;
}

export const AdedVideoConfiguration: FC<IAdedVideoConfigurationProps> = ({ handlePlayVideo }) => {
  const { currentSocialVerseVideos, socialVerse } = useTypedSelector(
    (state) => state.SocialVerseSlice,
  );

  const [isReordering, setIsReordering] = useState(false);

  const { addToast } = useToasts();
  const dispatch = useAppDispatch();
  const params = useParams<{ id: string }>();
  const [videoList, setVideoList] = useState<SocialVerseVideos[]>(currentSocialVerseVideos);
  const [newVideos, setNewVideos] = useState<SocialVerseVideos[]>();

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    setVideoList(currentSocialVerseVideos);

    if (videoList.length > 0 && videoList.length !== currentSocialVerseVideos.length) {
      setNewVideos(
        currentSocialVerseVideos.filter((v1) => !videoList.find((v2) => v1.videoId === v2.videoId)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSocialVerseVideos]);

  const { isTopGolfClient } = useClientType();

  const isScroll = videoList.length > 0 ? (videoList.length + 10) * 170 : 2000;

  const classes = useAddedVideoConfigStyle({
    isScroll,
    viewMode: socialVerse?.viewMode,
  });

  const videoListSortedByPosition = useMemo(
    () => sortBy(videoList, (video) => video.position),
    [videoList],
  );

  const handleDrag = useCallback(
    async (dropResult: DropResult) => {
      trackEvent(SocialVerseEventNames.socialVerseVideoDragged);

      const dragBoxIndex = dropResult.source.index;
      const dropBoxIndex = dropResult.destination?.index;

      if (typeof dropBoxIndex === 'undefined') return;

      setIsReordering(true);

      const reorderedVideoList = reorderArray(
        videoListSortedByPosition,
        dragBoxIndex,
        dropBoxIndex,
      ).map((video, index) => {
        const newPosition = index + 1;

        return {
          ...video,
          position: newPosition,
        };
      });

      setVideoList(reorderedVideoList);

      await dispatch(
        UpdateVideoPositionInSocialVerse({
          id: params.id,
          videoId: dropResult.draggableId,
          position: (dropResult.destination?.index || 0) + 1,
        }),
      ).then(() => {
        addToast(SocialVerseStrings.savingMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        dispatch(GetVideosBySocialVerseId({ id: params.id }));
        setIsReordering(false);
      });
    },
    [dispatch, params.id, videoListSortedByPosition, addToast, trackEvent],
  );

  const isEducational = socialVerse?.viewMode === TypeSocialVerse.Educational;

  const renderEmpty = () => {
    if (videoList) {
      const needAddEmpty = videoList.length ? 0 : 1;
      const length = isEducational ? needAddEmpty : videoList.length > 10 ? 1 : 10;

      const tempArr = new Array(length).fill(0);
      return tempArr.map((_, index) => (
        <Grid>
          <Box key={index + 1} className={classes.cardHolderWrapper}></Box>
          {!isEducational && (
            <Box className={classes.videoNumber}>{videoList.length + index + 1}</Box>
          )}
        </Grid>
      ));
    }
  };

  const handleDelete = (id: string) => {
    dispatch(DeleteVideoFromSocialVerse({ id: params.id, videoId: id })).then(() => {
      dispatch(deleteVideoFromSocialVerseAll({ id: params.id, videoId: id }));
      addToast(SocialVerseStrings.savingMessage, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      dispatch(GetVideosBySocialVerseId({ id: params.id }));
      if (isTopGolfClient) {
        dispatch(getTopGolfVideoByIdForSocialVerse({ id }));
      } else {
        dispatch(getVideoByIdForSocialVerse({ id }));
      }
    });

    trackEvent(SocialVerseEventNames.removeSocialVerseVideo, {
      socialVerseId: params.id,
      videoId: id,
    });
  };

  const handleCardAnimation = (item: SocialVerseVideos) => {
    setNewVideos((videos) => videos?.filter((v) => v.videoId === item.videoId));
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid container className={classes.wrapper}>
          <Grid item md={12}>
            <Typography classes={{ root: classes.title }}>
              {isEducational
                ? SocialVerseStrings.AddVideoTitleEducational
                : SocialVerseStrings.AddVideoTitle}
            </Typography>
            <Typography classes={{ root: classes.subTitle }}>
              {isEducational
                ? SocialVerseStrings.AddVideoSubtitleEducational
                : SocialVerseStrings.AddVideoSubtitle}
            </Typography>
          </Grid>
          <Grid item className={classes.videoScroll} md={12}>
            <Grid container className={classes.videoWrapper}>
              {isEducational ? (
                <Box>
                  {videoListSortedByPosition[0] && (
                    <VideoCardComponent
                      id={videoListSortedByPosition[0].videoId}
                      videoUrl={videoListSortedByPosition[0].url}
                      thumbnailUrl={videoListSortedByPosition[0].thumbnailUrl}
                      handlePlayVideo={handlePlayVideo}
                      handleDelete={handleDelete}
                      playMountAnimation={!!newVideos?.includes(videoListSortedByPosition[0])}
                      onMountAnimationPlay={() => handleCardAnimation(videoListSortedByPosition[0])}
                      viewMode={socialVerse?.viewMode}
                    />
                  )}
                </Box>
              ) : (
                <DragDropContext onDragEnd={handleDrag}>
                  <Droppable
                    droppableId="droppable"
                    direction="horizontal"
                    isDropDisabled={isReordering}
                  >
                    {(provided) => (
                      <Box
                        style={{ zIndex: '2', position: 'relative' }}
                        ref={provided.innerRef}
                        display="flex"
                        {...provided.droppableProps}
                      >
                        {videoListSortedByPosition.map((item, index) => (
                          <Draggable
                            key={item.videoId}
                            draggableId={item.videoId}
                            index={index}
                            isDragDisabled={isReordering}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={provided.draggableProps.style}
                              >
                                <VideoCardComponent
                                  id={item.videoId}
                                  videoUrl={item.url}
                                  thumbnailUrl={item.thumbnailUrl}
                                  handlePlayVideo={handlePlayVideo}
                                  handleDelete={handleDelete}
                                  playMountAnimation={!!newVideos?.includes(item)}
                                  onMountAnimationPlay={() => handleCardAnimation(item)}
                                  viewMode={socialVerse?.viewMode}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        <Grid container className={classes.videoNumberWrapper}>
                          {videoList.map((_, i) => (
                            <Box className={classes.videoNumber}>{i + 1}</Box>
                          ))}
                        </Grid>
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
              {renderEmpty()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
