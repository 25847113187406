import * as React from 'react';

export const UploadFileIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="107"
      height="95"
      fill="none"
      viewBox="0 0 107 95"
    >
      <path
        fill="#407BFF"
        d="M5.329 41.827l-.446.001.019 6.766h.445l-.018-6.767zM5.352 51.106l-.446.001.01 3.414.445-.001-.01-3.414zM37.127 31.67l-.354-1.517a4.742 4.742 0 00-4.634-3.667l-21.004.056a4.746 4.746 0 00-4.731 4.759l.134 51.115a3.257 3.257 0 003.263 3.247l75.153-.2a3.257 3.257 0 003.247-3.263l-.118-44.19a3.257 3.257 0 00-3.263-3.246l-43.64.115a4.155 4.155 0 01-4.053-3.21z"
      ></path>
      <path
        fill="#407BFF"
        d="M41.71 9.965L17.742 11.96l2.747 33.02 23.966-1.994-2.747-33.02z"
      ></path>
      <path
        fill="#fff"
        d="M41.71 9.965L17.742 11.96l2.747 33.02 23.966-1.994-2.747-33.02z"
        opacity="0.7"
      ></path>
      <g fill="#407BFF" opacity="0.4">
        <path
          d="M37.688 17.381l-14.763 1.227a1.301 1.301 0 01-1.402-1.189 1.301 1.301 0 011.188-1.402l14.763-1.226a1.301 1.301 0 011.402 1.188c.06.71-.47 1.34-1.188 1.402zM38.145 22.839l-14.763 1.226a1.301 1.301 0 01-1.402-1.189 1.301 1.301 0 011.189-1.402l14.763-1.226a1.301 1.301 0 011.402 1.188 1.301 1.301 0 01-1.189 1.403zM38.598 28.297l-14.763 1.227a1.301 1.301 0 01-1.402-1.189 1.301 1.301 0 011.189-1.402l14.762-1.226a1.301 1.301 0 011.402 1.188 1.301 1.301 0 01-1.188 1.402zM39.05 33.76l-14.762 1.227a1.301 1.301 0 01-1.402-1.189 1.301 1.301 0 011.188-1.402l14.763-1.226a1.301 1.301 0 011.402 1.188 1.298 1.298 0 01-1.189 1.402zM35.47 39.554l-10.725.892a1.301 1.301 0 01-1.402-1.189 1.301 1.301 0 011.188-1.402l10.726-.892a1.301 1.301 0 011.402 1.188 1.301 1.301 0 01-1.188 1.403z"
          opacity="0.4"
        ></path>
      </g>
      <path fill="#407BFF" d="M75.808 7.559l-24.044.535.737 33.126 24.044-.535-.737-33.126z"></path>
      <path
        fill="#fff"
        d="M75.808 7.559l-24.044.535.737 33.126 24.044-.535-.737-33.126z"
        opacity="0.7"
      ></path>
      <g fill="#407BFF" opacity="0.4">
        <path
          d="M71.346 14.712l-14.81.329a1.306 1.306 0 01-1.33-1.273 1.306 1.306 0 011.272-1.33l14.81-.33a1.306 1.306 0 011.33 1.273 1.303 1.303 0 01-1.272 1.33zM71.468 20.188l-14.81.33a1.306 1.306 0 01-1.331-1.273 1.306 1.306 0 011.272-1.331l14.81-.329a1.301 1.301 0 11.058 2.603zM60.862 25.905l-4.079.092a1.306 1.306 0 01-1.33-1.273 1.306 1.306 0 011.272-1.33l4.079-.092a1.3 1.3 0 11.058 2.603zM68.774 25.727l-4.08.092a1.306 1.306 0 01-1.33-1.272 1.306 1.306 0 011.272-1.331l4.08-.092a1.301 1.301 0 11.058 2.604zM71.714 31.143l-14.81.33a1.306 1.306 0 01-1.33-1.273 1.306 1.306 0 011.272-1.331l14.81-.329a1.3 1.3 0 11.058 2.603zM67.787 36.71l-10.762.237a1.306 1.306 0 01-1.33-1.272 1.306 1.306 0 011.272-1.331l10.761-.238a1.301 1.301 0 11.058 2.603z"
          opacity="0.4"
        ></path>
      </g>
      <path fill="#407BFF" d="M52.924 9.769l-24.04-.646-.89 33.12 24.04.647.89-33.121z"></path>
      <path
        fill="#fff"
        d="M52.924 9.769l-24.04-.646-.89 33.12 24.04.647.89-33.121z"
        opacity="0.8"
      ></path>
      <g fill="#407BFF" opacity="0.4">
        <path
          d="M48.118 16.697l-14.81-.396a1.304 1.304 0 01-1.267-1.336 1.304 1.304 0 011.335-1.267l14.81.396c.719.021 1.284.617 1.267 1.336a1.299 1.299 0 01-1.335 1.267zM47.971 22.171l-14.81-.396a1.304 1.304 0 01-1.267-1.335 1.304 1.304 0 011.336-1.267l14.81.396c.718.02 1.283.617 1.267 1.336a1.299 1.299 0 01-1.336 1.266zM47.824 27.648l-14.81-.396a1.304 1.304 0 01-1.267-1.336 1.304 1.304 0 011.336-1.266l14.81.396c.718.02 1.283.616 1.267 1.335a1.3 1.3 0 01-1.336 1.267zM47.677 33.128l-14.81-.396a1.304 1.304 0 01-1.267-1.335 1.304 1.304 0 011.336-1.267l14.81.396c.718.02 1.283.617 1.267 1.336a1.305 1.305 0 01-1.336 1.266zM43.483 38.493l-10.762-.29a1.304 1.304 0 01-1.267-1.335 1.304 1.304 0 011.335-1.267l10.763.29c.718.02 1.283.616 1.267 1.335a1.3 1.3 0 01-1.336 1.267z"
          opacity="0.4"
        ></path>
      </g>
      <path
        fill="#407BFF"
        d="M82.115 11.485L58.12 9.91l-2.17 33.061 23.996 1.576 2.17-33.062z"
      ></path>
      <path
        fill="#fff"
        d="M82.115 11.485L58.12 9.91l-2.17 33.061 23.996 1.576 2.17-33.062z"
        opacity="0.8"
      ></path>
      <g fill="#407BFF" opacity="0.4">
        <path
          d="M77.049 18.222l-14.785-.969a1.302 1.302 0 01-1.213-1.382 1.302 1.302 0 011.382-1.213l14.785.969a1.3 1.3 0 11-.17 2.595zM76.687 23.69l-14.784-.968a1.302 1.302 0 01-1.213-1.382 1.302 1.302 0 011.382-1.213l14.784.969a1.299 1.299 0 11-.17 2.595zM65.62 28.456l-4.074-.268a1.302 1.302 0 01-1.212-1.382 1.302 1.302 0 011.381-1.213l4.074.268c.715.046 1.26.666 1.213 1.382a1.299 1.299 0 01-1.382 1.213zM73.518 28.975l-4.074-.267a1.302 1.302 0 01-1.213-1.382 1.302 1.302 0 011.383-1.213l4.073.267a1.3 1.3 0 11-.17 2.595zM75.97 34.624l-14.785-.969a1.302 1.302 0 01-1.213-1.382 1.302 1.302 0 011.383-1.213l14.784.97c.715.046 1.26.666 1.213 1.381a1.296 1.296 0 01-1.382 1.213zM71.57 39.825L60.83 39.12a1.302 1.302 0 01-1.213-1.382 1.302 1.302 0 011.382-1.213l10.742.705c.715.046 1.259.667 1.212 1.382a1.293 1.293 0 01-1.382 1.213z"
          opacity="0.4"
        ></path>
      </g>
      <path
        fill="#407BFF"
        d="M43.64 33.356l-.22-1.47c-.306-2.087-2.092-3.56-4.299-3.554l-21.004.056c-2.62.008-4.93 2.069-5.158 4.609L8.492 82.506c-.154 1.74 1.174 3.148 2.972 3.143l75.153-.2c1.798-.006 3.383-1.42 3.538-3.16l3.862-42.798c.155-1.74-1.174-3.148-2.971-3.143l-43.64.115c-1.934.007-3.496-1.283-3.767-3.107z"
      ></path>
      <path
        fill="#000"
        d="M43.64 33.356l-.22-1.47c-.306-2.087-2.092-3.56-4.299-3.554l-21.004.056c-2.62.008-4.93 2.069-5.158 4.609L8.492 82.506c-.154 1.74 1.174 3.148 2.972 3.143l75.153-.2c1.798-.006 3.383-1.42 3.538-3.16l3.862-42.798c.155-1.74-1.174-3.148-2.971-3.143l-43.64.115c-1.934.007-3.496-1.283-3.767-3.107z"
        opacity="0.3"
      ></path>
      <path
        fill="#407BFF"
        d="M104.472 60.765L107 33.345l-5.391-4.129-16.462-1.515-2.856 31.02 22.181 2.044z"
      ></path>
      <path
        fill="#fff"
        d="M104.472 60.765L107 33.345l-5.391-4.129-16.462-1.515-2.856 31.02 22.181 2.044z"
        opacity="0.8"
      ></path>
      <path
        fill="#407BFF"
        d="M107 33.346l-5.391-4.13 1.41 4.942 3.981-.812zM84.217 63.516a6.723 6.723 0 100-13.446 6.723 6.723 0 000 13.446z"
      ></path>
      <path
        fill="#fff"
        d="M87.541 61.002a5.36 5.36 0 10-6.646-8.413 5.36 5.36 0 006.646 8.413z"
      ></path>
      <path
        fill="#407BFF"
        d="M87.53 56.326l-2.366-.28.28-2.365-1.694-.197-.28 2.362-2.366-.275-.196 1.694 2.361.28-.275 2.365 1.694.197.28-2.366 2.366.28.196-1.695z"
      ></path>
      <path
        fill="#407BFF"
        d="M102.971 39.029a1.836 1.836 0 01-1.995 1.659l-12.005-1.105a1.835 1.835 0 01.336-3.654l12.005 1.105a1.83 1.83 0 011.659 1.995zM98.885 43.984a1.835 1.835 0 01-1.995 1.66l-8.39-.773a1.836 1.836 0 01.335-3.654l8.39.772a1.835 1.835 0 011.66 1.995z"
        opacity="0.4"
      ></path>
    </svg>
  );
};
