import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { getSocialVerseById } from '../../../store/slices/socialVerseSlice';
import { AppMode, previewOptions } from './SpheresPreview.helper';

export const SpheresPreview = () => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { socialVerse } = useTypedSelector((state) => state.SocialVerseSlice);
  const containerId = 'spheres-socialv-preview';

  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const appMode = ['staging', 'development'].includes(process.env.REACT_APP_BUILD_ENV as string)
    ? AppMode.development
    : AppMode.production;

  const { socialVerseId, top, left } = useMemo(() => {
    const currPreview = previewOptions.find((detail) => detail.location === pathname);

    const socialVerseId = currPreview
      ? currPreview[appMode]?.find((sv) => sv.clientType === clientType)?.socialVerseId || ''
      : '';

    return {
      top: currPreview?.top || 0,
      left: currPreview?.left || 0,
      socialVerseId,
    };
  }, [pathname, clientType, appMode]);

  useEffect(() => {
    if (socialVerseId) {
      dispatch(getSocialVerseById({ id: socialVerseId }));
    }
  }, [socialVerseId, dispatch]);

  const getGidgetComponent = useCallback(() => {
    const component = document.createElement('section');

    if (socialVerse) {
      const venueId = socialVerse.accountId;
      const socialVerseId = socialVerse.id;
      const showHeroName = socialVerse.showUserInfo;

      component.innerHTML = `<gidget-component widget-type='spheres' venue-id='${venueId}'
                    socialverse-id='${socialVerseId}' show-block-hero-name='${
        showHeroName || false
      }' spheres-title='Ask an expert'}
                   ></gidget-component>`;
      document.documentElement.style.setProperty('--swiper-navigation-size', '0');
    }

    return component;
  }, [socialVerse]);

  useEffect(() => {
    setTimeout(async () => {
      const container = document.getElementById(containerId);
      if (container && container.hasChildNodes()) {
        container.innerHTML = '';
      }
      container?.prepend(getGidgetComponent());
    }, 0);
  }, [getGidgetComponent]);

  if (!socialVerseId) {
    return null;
  }

  return (
    <Box
      id={containerId}
      sx={{
        zIndex: 1000,
        position: 'absolute',
        top,
        left,
      }}
    ></Box>
  );
};
