import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '70px auto 0',
    width: '614px',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(82,66,234,0.05) 100%)',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 0 40px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '28px',
    color: '#101828',
  },
  subtitle: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '21px',
    color: '#8B89A0',
    marginBottom: '32px',
  },
}));
