import React, { FC } from 'react';
import { Box, Container, Modal, Typography } from '@mui/material';
import { useCreateSocialvModalStyles } from './CreateSocialvModal.style';
import { CustomizationSocialVerse, SocialVerseStrings } from '../../../common/localization/en';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { useClientType } from '../../../services/hooks/useClientType';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { cardTypes } from './CreateSocialvModal.helper';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelectSocialvViewMode: (viewMode: TypeSocialVerse) => void;
  totalItems: number;
}

export const CreateSocialvModal: FC<Props> = ({
  isOpen,
  onClose,
  onSelectSocialvViewMode,
  totalItems,
}) => {
  const classes = useCreateSocialvModalStyles();
  const { isHealthCare } = useClientType();
  const { trackEvent } = useTrackEvent();

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      onClose();
    },
  });

  return (
    <Modal
      open={isOpen}
      onMouseUp={(event) => {
        event.stopPropagation();
      }}
    >
      <Container className={classes.container} ref={refDetectClickOutside}>
        <Typography className={classes.title}>
          {CustomizationSocialVerse.CreateModalTitle}
        </Typography>
        <Box className={classes.cardsGrid}>
          {cardTypes.map((item) => {
            if (item.id === 'subtitle') {
              return (
                <Box
                  className={[classes.cardWrapper, classes.cardTitleWrapper].join(' ')}
                  key={item.id}
                >
                  <Typography className={classes.modalSubTitle}>{item.title}</Typography>
                  <Typography className={classes.modalSubDescription}>
                    {isHealthCare
                      ? item.description.replace('customer', 'patient')
                      : item.description}
                  </Typography>
                </Box>
              );
            }
            let isBtnDisabled = false;
            let btnText = SocialVerseStrings.Create;
            if (item.id === TypeSocialVerse.Perspective) {
              if (totalItems < 3) {
                isBtnDisabled = true;
                btnText = SocialVerseStrings.Minimum_3;
              }
            }
            if (item.id === TypeSocialVerse.Grid) {
              if (totalItems < 5) {
                isBtnDisabled = true;
                btnText = SocialVerseStrings.Minimum_5;
              }
            }
            return (
              <Box className={classes.cardWrapper} key={item.id}>
                <Box className={classes.iconWrapper}>{item.icon}</Box>
                <Typography className={classes.cardTitle}>{item.title}</Typography>
                <Typography className={classes.cardSubtitle}>{item.description}</Typography>
                <RoundedButton
                  title={btnText}
                  className={classes.cardBtn}
                  onClick={() => {
                    onSelectSocialvViewMode(item.id as TypeSocialVerse);
                    trackEvent(SocialVerseEventNames.createNewSocialVerseCards);
                  }}
                  id="create-sv-card-btn"
                  disabled={isBtnDisabled}
                />
              </Box>
            );
          })}
        </Box>
      </Container>
    </Modal>
  );
};
