import { Box, useTheme } from '@mui/material';
import React from 'react';
import { MessageBuilder } from '../../../../../../../../../common/components/MessageBuilder/MessageBuilder';
import {
  LocalMessageVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../../../../../../../common/components/SMS/SMS.helper';
import { ErrorsStrings } from '../../../../../../../../../common/localization/en';
import { useClientType } from '../../../../../../../../../services/hooks/useClientType';
import { useSmsTemplateVariables } from '../../../../../../../../../services/hooks/useSmsTemplateVariables';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../../store';
import { setSmsRewardMessage } from '../../../../../../../../../store/slices/createCampaignSlice';
import { MESSAGE_MAX_CHARACTERS } from '../../../../../../../../integrations/SmsInvite/SmsInvite.helper';
export const MessageTextSection: React.FC = () => {
  const dispatch = useAppDispatch();

  const { smsRewardMessage, isNullReward } = useTypedSelector((store) => store.createCampaign);

  const { isHealthCare } = useClientType();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);

  const templateVariables = useSmsTemplateVariables({
    ignoreVariables: currentCampaign?.isNullReward ? [LocalMessageVariables.RewardName] : [],
    rewardName: currentCampaign?.rewardTitle || '',
  });

  const inviteMessage = replaceMessageBodyWithLocalVariables(
    smsRewardMessage || '',
    isHealthCare,
    true,
  );

  const theme = useTheme();

  return (
    <Box>
      <MessageBuilder
        buttons={Object.keys(templateVariables).map((key) => ({ title: key, value: key }))}
        message={inviteMessage}
        disabled={false}
        onMessageChange={async (message: string) => {
          dispatch(setSmsRewardMessage(message));
        }}
        borderRadius={'5px'}
        background={`${theme.palette.primary.main}0f`}
        squareBrackets
        errorText={!smsRewardMessage.trim() ? ErrorsStrings.FieldIsRequired : ''}
        limit={MESSAGE_MAX_CHARACTERS}
        withUploadImageButton={false}
        isMms={!isNullReward}
        maxHeight="220px"
      />
    </Box>
  );
};
