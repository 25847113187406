import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useColorInputStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    border: '1px solid #E1E5EE',
    borderRadius: '10px',
    transition: 'height 0.25s ease-out',
    overflow: 'hidden',
  },
  colorSelectInput: {
    // position: 'absolute',
    // right: 0,
    '&>div>.ColorPicker-MuiButtonBase-root, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiButton-label>div, &>div>.ColorPicker-MuiButtonBase-root>.ColorPicker-MuiTouchRipple-root':
      {
        outline: 'none',
        borderRadius: '8px',
        width: '58px',
        height: '24px',
        cursor: 'pointer !important',
      },
  },
  colorInputWrapper: {
    width: '100%',
    display: 'flex',

    alignItems: 'flex-start',
    position: 'relative',
  },
  select: {
    border: 'none !important',
    '&>.MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  inputText: {
    padding: '8px 20px',
    fontWeight: 400,
    fontSize: '12px',
    color: theme.palette.common.deepDark,
    border: '1px solid #E1E5EE',
    borderRadius: '8px',
    height: '25px',
    width: '180px',
  },
  selectWrapper: {
    display: 'flex',
  },
  colorBox: {
    width: '109px',
    height: '30px',
    borderRadius: '8px 0px 0px 8px',
  },
  selectIconWrapper: {},
  colorSelect: {
    backgroundColor: '#fff',
    borderRadius: '0px 8px 8px 0px',
    height: '30px',
    border: '1px solid #9999',

    '&:hover': {
      backgroundColor: '#fff',
      opacity: '0.9',
    },
  },
}));
