import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';

export const useSuperAdminAccountsPageStyles = makeStyles((theme: Theme) => ({
  tableWrapper: {
    // padding: '15px 30px',
    padding: '0px',
  },
}));
