import { FC, useState } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import clsx from 'clsx';

import { UserApiModel, UserMetricsAPIModel } from '../../../../../api/models/users';
import { HelpersStrings, HeroesListView } from '../../../../localization/en';
import { getFullName, getLocalDateString } from '../../../../../services/utilities';
import { makeStyles } from '@mui/styles';
import { SalesHeroInfoRewards } from './SalesHeroInfoRewards';
import { SalesHeroMailBlock } from './SalesHeroMailBlock';
import { SalesHeroPhoneBlock } from './SalesHeroPhoneBlock';
import { useAppDispatch } from '../../../../../store';
import { updateUserById } from '../../../../../store/slices/userDetailsSlice';
import { EditableName } from '../../../EditableText/EditableName';
import { setRefetch } from '../../../../../store/slices/heroesSlice';

interface SalesHeroInfoBlockProps {
  userData: UserApiModel;
  metrics: UserMetricsAPIModel | null | undefined;
  isCampaignUser?: boolean;
  entity?: 'heroes' | 'campaigns';
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
    backgroundColor: '#fff',
    '&:before': {
      content: '',
    },
  },
  profileDetailsBlock: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '30px',
  },
  profileDetailsPoints: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
  avatar: {
    display: 'flex',
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    marginRight: '20px',
  },
  infoBlockRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridRowGap: '20px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '25px',
    borderRadius: '10px',
    padding: '27px 14px',
    border: `1px solid #DCDBE4`,
  },
  infoBlockContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 10px',
  },
  phoneNotAvailable: {
    color: '#DCDBE4',
    fontWeight: '400',
    fontSize: '14px',
  },
  iconContainer: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '8px',
    borderRadius: '30px',
    backgroundColor: '#F5F6F8',
    margin: 'auto 13px auto 0',
  },
  iconSvg: {
    display: 'block',
    width: '20px',
    height: '20px',
    fill: '#8B89A0',
  },
  arrowOutlined: {
    border: '1px solid #8B89A0',
    borderRadius: '50%',
  },
  infoBlockContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
  },
  textSmall: {
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textMedium: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textLarge: {
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    fontWeight: '500',
    maxWidth: '480px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  primaryTextColor: {
    color: '#1A1538',
    fontWeight: '400',
    fontSize: '12px',
  },
  secondaryTextColor: {
    color: '#8B89A0',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: 0,
  },
  outlinedWrapper: {
    border: '1px solid #dcdbe466',
    minWidth: '235px',
    padding: '10px 20px',
  },
  infoBlockWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statusToggle: {
    '&.MuiSwitch-root': {
      height: '34px',
      width: '54px',
    },
    '& .MuiSwitch-thumb': {
      height: '28px !important',
      width: '28px !important',
    },
    '& .Mui-checked': {
      transform: 'translateX(18px) !important',
    },
    '& .MuiSwitch-switchBase': {
      padding: '3px 0 0 3px',
    },
  },
  datesWrapper: {
    marginRight: '25px',
    display: 'flex',
    flexDirection: 'column',
    height: '53px',
    justifyContent: 'space-between',
  },
  smsStatusText: {
    fontWeight: 400,
    letterSpacing: '0.02em',
    fontSize: '12px',
    lineHeight: '18px',
    textTransform: 'uppercase',
    borderRadius: '16px',
    padding: '4px 10px',
    marginTop: '6px',
    paddingTop: '5px',
  },
}));
export const SalesHeroInfoBlock: FC<SalesHeroInfoBlockProps> = ({
  userData,

  isCampaignUser,
  entity,
}) => {
  const styles = useStyles();
  const avatar = userData?.photoUrl || undefined;
  const [smsStatus] = useState(true);
  const dispatch = useAppDispatch();

  return (
    <>
      <Grid container direction="column" className={styles.root}>
        <div>
          <div className={styles.profileDetailsBlock}>
            <div>
              <Avatar variant="rounded" className={styles.avatar} src={avatar} />
            </div>
            <div>
              <div>
                {userData.name ? (
                  <EditableName
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    onChange={async ({ firstName, lastName }) => {
                      await dispatch(
                        updateUserById({
                          update: {
                            firstName,
                            lastName,
                            displayName: getFullName({ firstName, lastName }),
                          },
                          id: userData.id,
                        }),
                      );
                      dispatch(setRefetch(true));
                    }}
                  />
                ) : (
                  <Typography className={clsx([styles.textLarge, styles.primaryTextColor])}>
                    {HelpersStrings.NotAvailable}
                  </Typography>
                )}
              </div>
              <Box className={styles.infoBlockWrapper}>
                <Box className={styles.datesWrapper}>
                  <div className={styles.profileDetailsPoints}>
                    <Typography className={clsx([styles.textSmall, styles.secondaryTextColor])}>
                      {HeroesListView.Column10}
                    </Typography>
                    <Typography className={clsx([styles.textMedium, styles.primaryTextColor])}>
                      {getLocalDateString(userData.createdAt)}
                    </Typography>
                  </div>
                  <div className={styles.profileDetailsPoints}>
                    <Typography className={clsx([styles.textSmall, styles.secondaryTextColor])}>
                      {HeroesListView.Column11}
                    </Typography>
                    <Typography className={clsx([styles.textMedium, styles.primaryTextColor])}>
                      {getLocalDateString(userData.lastVideoCreatedAt)}
                    </Typography>
                  </div>
                </Box>
                <div>
                  <div className={styles.profileDetailsPoints}>
                    <Typography className={clsx([styles.textSmall, styles.secondaryTextColor])}>
                      {HeroesListView.LastTransaction}
                    </Typography>
                    <Typography className={clsx([styles.textMedium, styles.primaryTextColor])}>
                      {getLocalDateString(userData.lastInvitationSentAt)}
                    </Typography>
                  </div>
                  <div className={styles.profileDetailsPoints}>
                    <Typography
                      className={clsx([styles.textSmall, styles.secondaryTextColor])}
                      mt="5px"
                    >
                      {HeroesListView.OptInStatus}
                    </Typography>
                    <Typography
                      className={styles.smsStatusText}
                      style={{
                        background: smsStatus
                          ? 'linear-gradient(0deg, #E0EBFD, #E0EBFD), #FF5858'
                          : '#FCECE7',
                        color: smsStatus ? '#1F55C5' : '#CC4525',
                      }}
                    >
                      {userData.smsOptedIn ? HeroesListView.OptedIn : HeroesListView.OptedOut}
                    </Typography>
                  </div>
                </div>
              </Box>
            </div>
          </div>
          <Box display="flex" marginBottom="15px" justifyContent="space-between">
            <SalesHeroPhoneBlock
              blockText={userData.phoneNumber}
              blockTitle={HeroesListView.Data3}
            />
            <SalesHeroMailBlock blockText={userData.email} blockTitle={HeroesListView.Data6} />
          </Box>
          <SalesHeroInfoRewards isCampaignUser={isCampaignUser} />
        </div>
      </Grid>
    </>
  );
};
