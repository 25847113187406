import styled from 'styled-components';

import { theme } from '../../theme';
import { TABLET_MAX_WIDTH } from '../../common/constants/constants';

export const Container = styled.div`
  display: inherit;
  flex-grow: 1;
  padding: 82px;

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    padding: 47px;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    padding: 3.375rem 1.125rem 1.75rem;
  }
`;

export const Content = styled.div`
  @media (max-width: 767px) {
    text-align: center;
  }

  @media (min-width: 768px) {
    margin-left: 240px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;

  @media (min-width: ${TABLET_MAX_WIDTH}px) {
    max-width: 624px;
    width: 43%;
  }
`;

export const Title = styled.h1`
  margin-bottom: 2rem;
  color: ${theme.palette.error.dark};
  font-size: 96px;
  font-weight: 300;
  line-height: 113px;

  @media (max-width: ${TABLET_MAX_WIDTH}px) {
    letter-spacing: -1.5px;
  }

  @media (max-width: 767px) {
    margin-bottom: 1.5rem;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: -1.5px;
  }
`;

export const SubTitle = styled.h2`
  margin-bottom: 1.5rem;
  color: ${theme.palette.text.primary};
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;

  @media (max-width: 767px) {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    line-height: 28px;
  }
`;

export const Text = styled.p`
  margin-bottom: 54px;
  color: ${theme.palette.text.primary};
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }
`;
