import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  videoText: {
    marginBottom: 10,
    padding: '2px 6px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: 4,
    color: theme.palette.common.white,
    fontSize: 12,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
  },
  webAppUrl: {
    position: 'absolute',
    fontSize: '9px',
    fontWeight: 400,
    opacity: '.5',
    top: '30px',
    left: '12px',
    color: 'white',
    width: '70%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px',
  },
  iconContainer: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    background: '#1C1D22',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    fill: '#BCBCCC',
    zIndex: 100,
  },
  nonTGiconContainer: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '16px',
    height: '20px',
    background: 'transparent',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    fill: '#BCBCCC',
    zIndex: 100,
    transform: 'scale(0.8)',
  },
  contentwrapper: {
    background: 'url("/video_sharing_preview_background.jpg") no-repeat ',
    backgroundSize: 'cover',
    height: '100%',
    borderRadius:
      'var(--iphoneXborderRadius) var(--iphoneXborderRadius) var(--iphoneXborderRadius) var(--iphoneXborderRadius)',
  },
  iconsWrapper: {
    position: 'absolute',
    top: '310px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  EditIcon: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '25%',
    right: '-10px',
    zIndex: 10,
    padding: '5px',
    cursor: 'pointer',
  },
  EditIconActive: { background: theme.palette.primary.main },
  EditIconInactive: { background: theme.palette.common.white },
  filterImage: {
    position: 'absolute',
    top: '-215px',
    zIndex: 200,
    left: '8px',
    width: '94%',
    objectFit: 'cover',
    height: '200px',
    borderRadius: '5px',
  },
  tgiconContainer: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    color: 'white',
    borderRadius: '5px',
    cursor: 'pointer',
    fill: '#BCBCCC',
    zIndex: 100,
  },
  blackDiv: {
    height: '95px',
    width: '95%',
    backgroundColor: 'black',
    position: 'absolute',
    top: 335,
    left: 2,
  },
}));
