import React, { useCallback, useState } from 'react';
import { UserIncentiveCampaignsApiModel } from '../../../../api/models/campaigns';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { Box, Button } from '@mui/material';
import TableFilterSelect, { TabItem } from '../../TableFilterSelect/TableFilterSelect';
import {
  DetailsSidebarStrings,
  NotFoundPageStrings,
  VenueCreationWizardStrings,
} from '../../../localization/en';
import { IEventTracker, useTrackEvent } from '../../../../hooks/useTrackEvent';
import { CommonTable } from '../../table/CommonTable';
import { userCampaignActivityTableContent } from '../../../../services/helpers/tableMappers';
import { useStyles } from './CampaignActivity.styles';
import { approveReward } from '../../../../store/slices/userDetailsSlice';
import { useToasts } from 'react-toast-notifications';
import {
  getUserIncentiveCampaigns,
  setActiveUserInvitedCampaignsCount,
} from '../../../../store/slices/campaignsSlice';
import { MessageIcon } from '../../../assets/MessageIcon';
import CampaignInvite from './CampaignInvite/CampaignInvite';

const CampaignActivity = () => {
  const styles = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [isRewardLoading, setIsRewardLoading] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);

  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const { trackEvent } = useTrackEvent();

  const { userIncentiveCampaigns, userInvitedCampaigns, items } = useTypedSelector(
    (state) => state.campaigns,
  );
  const { userDetails } = useTypedSelector((state) => state.userDetails);

  let userActiveCampaigns: UserIncentiveCampaignsApiModel[] = [],
    endedCampaigns: UserIncentiveCampaignsApiModel[] = [],
    activeUserInvitedCampaigns: UserIncentiveCampaignsApiModel[] = [];

  userIncentiveCampaigns.forEach((info) => {
    const campaign = info.campaign;

    if (campaign.endedAt) {
      endedCampaigns.push(info);
    } else {
      userActiveCampaigns.push(info);
    }
  });

  userInvitedCampaigns?.forEach((campaign) => {
    // skip invited campaigns that user created video to
    if (
      userActiveCampaigns.some(
        (activeCampaign) => activeCampaign.campaignId === campaign.campaignId,
      )
    ) {
      return;
    }

    activeUserInvitedCampaigns.push({
      campaignId: campaign.campaignId,
      videos: 0,
      userStatus: 'INVITED',
      campaign: {
        name: campaign.campaignName,
      },
    } as UserIncentiveCampaignsApiModel);
  });

  dispatch(setActiveUserInvitedCampaignsCount(activeUserInvitedCampaigns.length));

  userActiveCampaigns = userActiveCampaigns.concat(activeUserInvitedCampaigns);

  userActiveCampaigns.sort(
    (a: UserIncentiveCampaignsApiModel, b: UserIncentiveCampaignsApiModel) => {
      if (a.lastVideoCreatedAt < b.lastVideoCreatedAt) return 1;
      if (a.lastVideoCreatedAt > b.lastVideoCreatedAt) return -1;
      return 0;
    },
  );

  const tabs: TabItem[] = [
    {
      tabName: DetailsSidebarStrings.LiveCampaigns,
      usersCount: userActiveCampaigns.length.toString(),
    },
    {
      tabName: DetailsSidebarStrings.EndedCampaigns,
    },
  ];

  const handleChangeActiveTab = (value: number) => {
    setActiveTab(value);
  };

  const handleTrackEvent: IEventTracker = (event, props) => {
    trackEvent(event, {
      userId: userActiveCampaigns[0].userId,
      ...props,
    });
  };

  const handleRewardClick = useCallback(
    async (campaignId: string) => {
      if (!userDetails) return;

      setIsRewardLoading(true);
      const response = await dispatch(approveReward({ id: userDetails.id, campaignId }));
      await dispatch(
        getUserIncentiveCampaigns({ userId: userDetails.id, withCampaignDetails: true }),
      );
      setIsRewardLoading(false);

      if (response.payload) {
        addToast(VenueCreationWizardStrings.RewardApprovedMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
      } else {
        addToast(NotFoundPageStrings.Description2, { appearance: 'error' });
      }
    },
    [addToast, dispatch, userDetails],
  );

  const handleInviteOpen = () => {
    setIsInviteOpen((prevState) => !prevState);
  };

  const userDataTableContents = userCampaignActivityTableContent({
    campaigns: activeTab === 0 ? userActiveCampaigns : endedCampaigns,
    onRewardClick: handleRewardClick,
    isRewardLoading,
  });

  const availableCampaigns = items
    .filter((campaign) => !campaign.endedAt)
    .filter(
      (campaign) =>
        !userActiveCampaigns.find((userCampagin) => userCampagin.campaignId === campaign.id),
    );

  return (
    <Box>
      <TableFilterSelect
        tabsList={tabs}
        activeTabIndex={activeTab}
        setActiveTabIndex={handleChangeActiveTab}
        type="active"
        handleTrackEvent={handleTrackEvent}
        flex
      />
      <Box className={styles.tableWrapper}>
        <CommonTable
          content={userDataTableContents}
          page={1}
          totalItems={1}
          totalPages={1}
          isLoading={false}
          noContent={false}
          tablePadding="0"
          size={1}
          onSizeChange={() => null}
          withSearchBar={false}
          roundedNavButtons={false}
          disableTopNav={true}
          containerClassName={styles.table}
          rowClassName={styles.row}
          disableFullHeight
        />
      </Box>
      {availableCampaigns.length > 0 && (
        <Box className={styles.inviteButtonWrapper}>
          <Button className={styles.inviteButton} onClick={handleInviteOpen}>
            <MessageIcon />
            {DetailsSidebarStrings.InviteToCampaign}
          </Button>
        </Box>
      )}
      {isInviteOpen && (
        <CampaignInvite handleClose={handleInviteOpen} availableCampaigns={availableCampaigns} />
      )}
    </Box>
  );
};

export default CampaignActivity;
