export const SvHealthMenuIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.762123 9.81297C1.97912 10.0373 3.22095 10.1619 4.46278 10.1619C6.69807 10.1868 8.93337 9.7382 11.0196 8.89082V13.0529L12.485 13.7508L12.9817 14V8.89082C15.068 9.7382 17.3033 10.1619 19.5386 10.137C20.7804 10.137 22.0223 10.0124 23.2393 9.78804C23.6118 9.71328 23.9098 9.4142 23.9844 9.04036C24.0589 8.6416 23.8602 8.26775 23.5373 8.06837C21.8981 7.14623 20.5072 5.85024 19.4393 4.30503C19.2406 4.00595 18.9425 3.80657 18.5948 3.75673C18.2471 3.70688 17.8746 3.78165 17.6013 4.00595C16.2105 5.00287 14.6706 5.8004 13.0314 6.32377V4.82841C14.2981 4.2801 14.8942 2.80966 14.3478 1.5386C13.7765 0.24261 12.3111 -0.330615 11.0445 0.192764C9.77781 0.716143 9.18174 2.21151 9.72814 3.48257C9.97651 4.08072 10.4484 4.55426 11.0445 4.80348V6.29885C9.40527 5.77547 7.8654 5.00287 6.44971 3.98103C6.17651 3.75673 5.80396 3.68196 5.45624 3.7318C5.10853 3.78165 4.81049 3.98103 4.6118 4.2801C3.54382 5.85024 2.12814 7.12131 0.464084 8.06837C0.116372 8.26775 -0.0574848 8.66652 0.0170251 9.04036C0.0915349 9.43913 0.389574 9.7382 0.762123 9.81297Z"
        fill="#667085"
      />
      <path
        d="M13 23.1561V19.7806L11 19V23.1561C11 23.6203 11.45 24 12 24C12.55 24 13 23.6203 13 23.1561Z"
        fill="#667085"
      />
      <path
        d="M14 14.0629L15.9467 15C16.9624 14.3898 17.3009 13.0604 16.7085 12.0143C16.4969 11.6438 16.1795 11.3387 15.7986 11.1425C15.4177 10.881 14.8887 10.99 14.6348 11.3823C14.3809 11.7746 14.4867 12.3194 14.8676 12.5809C14.931 12.6245 14.9945 12.6681 15.0792 12.6899C15.2908 12.7989 15.3966 13.0822 15.2908 13.3001C15.2484 13.3873 15.1638 13.4745 15.0792 13.518L14 14.0629Z"
        fill="#667085"
      />
      <path
        d="M8.60188 21.9412C9.19436 22.1367 9.84326 21.8296 10.0408 21.2433C10.21 20.7408 10.0125 20.2104 9.56113 19.9312C9.279 19.7917 9.13793 19.4287 9.279 19.1496C9.33542 19.0379 9.44828 18.9262 9.56113 18.8704L11 18.2004L8.40439 17C7.05016 17.7817 6.59875 19.4846 7.38871 20.8246C7.64263 21.2992 8.09404 21.69 8.60188 21.9412Z"
        fill="#667085"
      />
      <path
        d="M9.1554 12.977C9.65114 12.7996 9.93442 12.242 9.76917 11.7097C9.60392 11.1775 9.08458 10.8733 8.58884 11.0507C8.51802 11.0761 8.42359 11.1268 8.35277 11.1775C7.17244 11.7858 6.6767 13.2811 7.21965 14.5484C7.45572 15.0807 7.85703 15.5115 8.35277 15.765L11.0675 17.1336L14.8446 19.0599C15.0807 19.1866 15.1987 19.5161 15.0807 19.7696C15.0335 19.8709 14.939 19.9723 14.8446 20.023C14.3489 20.2004 14.0656 20.758 14.2308 21.2903C14.3961 21.8225 14.9154 22.1267 15.4112 21.9493C15.482 21.9239 15.5764 21.8732 15.6472 21.8225C16.8276 21.2142 17.3233 19.7189 16.7804 18.4516C16.5443 17.9193 16.143 17.4885 15.6472 17.235L11.8702 15.3341L9.1554 13.9655C8.91933 13.8387 8.8013 13.5092 8.91933 13.2558C8.96654 13.1291 9.03736 13.0277 9.1554 12.977Z"
        fill="#667085"
      />
    </svg>
  );
};
