import { makeStyles } from '@mui/styles';
import { MIN_TABLE_HEIGHT } from '../../../common/constants/constants';

const TABLE_HEIGHT = 'calc(100vh - 210px)';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '20px 70px 0 40px',
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  table: {
    marginTop: '24px',
    '& #common-table-wrapper': {
      height: TABLE_HEIGHT,
      maxHeight: TABLE_HEIGHT,
      minHeight: MIN_TABLE_HEIGHT,
    },
    '& td': {
      cursor: 'default !important',
    },
    '& th': {
      fontSize: '12px !important',
      '&:nth-child(1)': {
        paddingLeft: '15px !important',
      },
      '&:nth-child(2)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(3)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(4)': {
        paddingLeft: '0px !important',
        '& div': {
          paddingLeft: '0px !important',
        },
      },
      '&:nth-child(5)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(6)': {
        paddingLeft: '0px !important',
      },
      '&:nth-child(7)': {
        '& div': {
          display: 'block',
          padding: '0px !important',
          margin: '0 auto !important',
          textAlign: 'start !important',
        },
      },
      '&:nth-child(8)': {
        '& div': {
          justifyContent: 'center',
          paddingRight: '42px',
        },
      },
    },
    '& tr': {
      '& td': {},
      '& td:nth-child(1)': {
        paddingLeft: '16px !important',
      },
      '& td:nth-child(2)': {
        textAlign: 'center',
        paddingLeft: '0px !important',
      },
      '& td:nth-child(3)': {
        textAlign: 'center',
      },
      '& td:nth-child(4)': {
        paddingLeft: '2px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          margin: '0 auto !important',
          paddingLeft: '0px !important',
          fontSize: '13px',
        },
      },
      '& td:nth-child(5)': {
        paddingLeft: '0px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          margin: '0 auto !important',
          paddingLeft: '0px !important',
          textAlign: 'end',
        },
      },
      '& td:nth-child(6)': {
        paddingLeft: '0px !important',
        display: 'table-cell',
        '& p': {
          height: '100%',
          paddingLeft: '0px !important',
          textAlign: 'center',
        },
      },
      '& td:nth-child(7)': {
        paddingLeft: '0px !important',
      },
      '& td:last-child': {
        paddingLeft: '0px !important',
        '& div': {
          paddingLeft: '0px !important',
        },
      },
    },
  },
  cell: {
    fontSize: '13px',
    paddingLeft: '10px',
    color: theme.palette.common.deepDark,
  },
  usersCell: {
    fontSize: '11px',
    display: 'flex !important',
    justifyContent: 'center !important',
    width: '100px',
  },
  btnSummary: {
    width: '128px',
    height: '18px',
    color: '#5D5FEF',
    borderRadius: '30px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    background: `${theme.palette.primary.main}1a`,
  },
  name: {
    fontSize: '14px !important',
    fontWeight: '600',
    paddingLeft: '0',
    color: theme.palette.primary.main,
    marginBottom: '6px',
    cursor: 'pointer !important',
  },
  status: {
    fontSize: '12px !important',
    fontWeight: '600',
    color: '#8B89A0',
  },
  date: {
    fontSize: '12px !important',
    fontWeight: '400',
    color: '#8B89A0',
  },
  userCell: {
    background: '#F2F4F7',
    width: '40px',
    height: '40px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
  },
}));
