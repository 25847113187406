import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useSocialVerseStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    justifyContent: 'center',
    padding: '44px 60px 60px 60px',
  },
  mainTitle: {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '34px',
    color: '#000',
  },
  containerNewSV: {
    borderRadius: '15px',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    padding: '45px 0',
  },
  wrapperNewSV: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  //
  headerWrapper: {
    paddingBottom: 40,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerSV: {
    position: 'relative',
    marginBottom: '25px',
    backgroundColor: theme.palette.common.white,
    borderRadius: '15px',
  },
  svButton: {
    padding: '7px 25px',
    borderRadius: '100px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '26px',
  },
  containedBtn: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    border: '1px solid transparent',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.primary.main}`,
      '&>.MuiTypography-root': {
        color: theme.palette.primary.main,
      },
    },
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  outlinedBtn: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&>.MuiTypography-root': {
        color: theme.palette.common.white,
      },
    },
  },
  titleVS: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '40px',
    color: '#000000',
  },
  storiesWrapper: {
    width: '100%',
    paddingTop: '25px',
  },
  divider: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%)',
    height: '170px',
    width: '1px',
    backgroundColor: '#ccc',
  },
  previewText: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '26px',
    color: '#000000',
  },
  storiesIcon: {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.primary.main}`,
    position: 'relative',
  },
  statusIsActive: {
    position: 'absolute',
    bottom: '-2px',
    right: '0px',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    // backgroundColor: '#DDD4C8',
    backgroundColor: '#000',
    border: '1px solid #fff',
  },
  previewContentWrapper: {
    width: '100%',
    position: 'relative',
    borderRadius: '15px',
    maxHeight: '170px',
    height: '170px',
    border: '1px solid #ccc',
    '&:hover': {
      height: '190px',
    },
  },
  previewEmptyWrapper: {
    width: '100%',
    position: 'relative',
    borderRadius: '15px',
    maxHeight: '150px',
    height: '150px',
    backgroundColor: '#F8F8FC',
    border: `1px dashed ${theme.palette.primary.main}`,
  },
  addButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%) ',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    fontSize: '50px',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  disabldedButton: {
    background: `${theme.palette.primary.main}33 !important`,
    color: `${theme.palette.primary.main}4d !important`,
  },
  previewEmptyBubbles: {
    position: 'relative',
    width: 36,
    height: 36,
    borderRadius: '50%',
    border: `1px dashed ${theme.palette.primary.main}`,
    margin: 5,
  },
  imageContainer: {
    width: '750px',
    textAlign: 'start',
    marginBottom: '55px',
  },
  textContentCustomers: {
    color: theme.palette.common.deepDark,
    fontWeight: '500',
    fontSize: 12,
    marginLeft: '60px',
  },
  socialvImage: {
    objectFit: 'contain',
  },
  svButtonCreate: {
    padding: '10px 25px 10px 20px',
  },
  socialverseWrapper: {
    width: '100%',
    gridTemplateColumns: '1fr 1fr',
    gap: '24px',
  },
  showcaseTitle: {
    width: '100%',
    fontSize: '20px',
    color: '#8B89A0',
    marginBottom: '57px',
  },
  rightSectionWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  svButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    position: 'relative',
  },
  qsMarker: {
    position: 'absolute',
    right: '240px',
  },
}));
