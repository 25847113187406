export const SvMenuIcon = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
    >
      <path
        d="M5.36508 8.29836L11.7698 14.1262L18.3706 7.8448L18.7315 7.5L22 7.5217L21.6611 7.84914L11.7698 17.5L2 8.29836H5.36508Z"
        fill="#8F94B6"
      />
      <path
        d="M4.97152 0.5L0 5.08862L1.52914 6.5H21.6498L23 5.07858L22.3148 4.33042H4.34275L6.38388 2.40448H17.1614L17.8654 3.07518H21.0451L18.4869 0.56702L4.97152 0.5Z"
        fill="#667085"
      />
    </svg>
  );
};
