import { useState } from 'react';

export const useTabs = (tabsCount: number, initialTabIndex = 0) => {
  const [activeTab, setActiveTab] = useState(initialTabIndex);

  const moveToNextTab = () => {
    setActiveTab((current) => {
      const newIndex = current + 1;
      return newIndex >= tabsCount ? newIndex - tabsCount : newIndex;
    });
  };

  const resetActiveTab = () => {
    setActiveTab(initialTabIndex);
  };

  return {
    activeTab,
    setActiveTab,
    moveToNextTab,
    resetActiveTab,
  };
};
