import { Box } from '@mui/material';
import { SocialVenuLogo } from '../../../common/assets/newDesign/Sidebar/SocialVenuLogo';
import { useStyles } from '../signupSuccess.helper';
import { useEffect } from 'react';
import { useLogout } from '../../../services/hooks/useLogout';

// const APIresponse = {
//   account: {
//     id: '77879b16-8afb-45e7-aa31-18925f077b8e',
//     name: 'Account Signup PWD R Token',
//     subdomain: 'lwaoi',
//     clientType: 'MARKETING',
//     logo: '',
//     teamName: 'Account Signup PWD R Token',
//     launchedAt: null,
//     websiteUrl: 'https://',
//     industry: null,
//     planId: '717980a5-e97a-498a-b240-46d225f5bb48',
//     refId: null,
//     billingAccountId: '83fbbc18-4d8f-4837-914c-73ba0ec047d7',
//     businessAddress: ' ,  ',
//     active: true,
//     deactivatedAt: null,
//     deactivatedBy: null,
//     billable: true,
//     setupCompleted: true,
//     tutorialCompleted: null,
//     createdAt: '2024-10-23T11:14:27.562252490Z',
//     updatedAt: '2024-10-23T11:14:27.562252631Z',
//     createdBy: null,
//     lastModifiedBy: null,
//   },
//   token: '3tii4IirG3Thhi9ioqw7288gGNFn6fHJ',
// };

export const MobileAccessBlockComponent = () => {
  const classes = useStyles();
  const logout = useLogout();
  //https://storage.googleapis.com/sv-dev-assets/14eef41f-8001-4c43-9a02-838dfecdf837/7b92f74f-2ff5-47ab-844a-838ff8550b12.jpg

  // TODO: Both useEffects will trigger for enterprise plan, race condition fix this.

  ///complete-profile?token=EV0Aja1wmo3IuEJ3dtwVkg1I2hw7N1xd&customerName=Yassine+AB&accountName=Account+Signup+update&email=yassine%2Baccountsignupupdate%40socialvenu.com&phoneNumber=%2B19783491570

  //   if (!isSVAdmin && !checkoutSessionId) {
  //     return null;
  //   }

  /*  const handleContactUsClick = () => {
    const a = document.createElement('a');
    a.href = `mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.ErrorSignUp}`;
    a.click();
  };*/

  useEffect(() => {
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={classes.QuicksignUpWrapper}>
        <Box
          style={{
            borderRadius: '15px',
            background: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '142px 40px 42px 40px',
            margin: '40px',
            width: '408px',
            marginTop: '-500px',
            scale: '1.4',
          }}
        >
          <div style={{ marginBottom: '20px', marginTop: '-100px' }}>
            <SocialVenuLogo />
          </div>
          <p className={classes.boldparagraph}>Start customizing this account to your business!</p>
          <p className={classes.paragraph}>
            Please use a laptop or desktop to access the dashboard:
          </p>
          <p className={classes.boldparagraph}>https://dashboard.socialv.io/</p>
        </Box>
      </div>
    </>
  );
};
