import { FC } from 'react';
interface IDownloadIconDv5Props {
  color?: string;
}
export const DownloadIconDv5: FC<IDownloadIconDv5Props> = ({ color }) => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.939453 11.4095V15.8071C0.939453 16.3594 1.38717 16.8071 1.93945 16.8071H18.2269C18.7792 16.8071 19.2269 16.3594 19.2269 15.8071V11.4095"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M5.60938 8.42358L9.3761 12.1903C9.76662 12.5808 10.3998 12.5808 10.7903 12.1903L14.557 8.42358"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M10.084 1.19312V12.4142"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};
