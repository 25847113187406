import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  containerNewSV: {
    border: '1px solid #DCDBE4',
    borderRadius: '15px',
    textAlign: 'center',
    width: '100%',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    padding: '45px 0',
  },
  wrapperNewSV: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  saveAndCancelbutton: {
    width: '320px',
  },
}));
