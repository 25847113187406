import { FC } from 'react';

export const BillableIcon: FC = () => (
  <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_83_1089" maskUnits="userSpaceOnUse" x="0" y="0" width="51" height="50">
      <circle cx="25.8333" cy="25" r="25" fill="#F1F1F1" />
    </mask>
    <g mask="url(#mask0_83_1089)">
      <circle cx="25.8333" cy="25" r="25" fill="#EEECFD" />
      <path
        d="M0.911926 17.0669L46.9093 43.6235"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M-4.57074 26.5632L41.4267 53.1198"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.91193 8.40665L51.9093 34.9633"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4119 -1.11963L57.4093 25.437"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M25.3333 38.3333C32.6971 38.3333 38.6666 32.3638 38.6666 25C38.6666 17.6362 32.6971 11.6667 25.3333 11.6667C17.9695 11.6667 12 17.6362 12 25C12 32.3638 17.9695 38.3333 25.3333 38.3333Z"
      fill="white"
      stroke="black"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_83_1089)">
      <path
        d="M21.2043 27.5806C21.2043 29.2909 22.5908 30.6774 24.3011 30.6774H27.3978C29.1081 30.6774 30.4946 29.2909 30.4946 27.5806C30.4946 25.8703 29.1081 24.4839 27.3978 24.4839H24.3011C22.5908 24.4839 21.2043 23.0974 21.2043 21.3871C21.2043 19.6768 22.5908 18.2903 24.3011 18.2903H27.3978C29.1081 18.2903 30.4946 19.6768 30.4946 21.3871M25.8494 16.7419V32.2258"
        stroke="#5242EA"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_83_1089">
        <rect
          width="18.5806"
          height="18.5806"
          fill="white"
          transform="translate(16.5591 15.1935)"
        />
      </clipPath>
    </defs>
  </svg>
);
