import { BoxProps } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import React from 'react';
import tinycolor from 'tinycolor2';

interface FakeButtonProps extends BoxProps {
  color: string;
  text?: string;
  icon?: React.ReactNode;
  iconSize?: string;
  showBorder?: boolean;
}

export const FakeButton: React.FC<FakeButtonProps> = ({
  text,
  color,
  icon,
  iconSize = '16px',
  showBorder,
  ...boxProps
}) => {
  const textColor = tinycolor(color).isDark() ? 'white' : 'black';

  function getTextWidth(text: string, font: string) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }
    return 0;
  }
  const textWidth = getTextWidth(text || '', 'normal 12px Poppins');

  return (
    <Box
      bgcolor={color}
      borderRadius="12px"
      display="flex"
      justifyContent="center"
      alignItems="center"
      overflow="hidden"
      height="44px"
      px="16px"
      width="100%"
      border={showBorder ? '3px solid white' : undefined}
      {...boxProps}
    >
      {icon && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mr={text ? '8px' : 0}
          width={iconSize}
          height={iconSize}
        >
          {icon}
        </Box>
      )}
      {text && (
        <Typography color={textColor} fontWeight="500" fontSize={textWidth > 190 ? '11px' : '12px'}>
          {text}
        </Typography>
      )}
    </Box>
  );
};
