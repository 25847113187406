import { Box, Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';

import { RoundedButton, RoundedButtonProps } from '../../components/Button/RoundedButton';
import { ContentContainer } from '../Layout.style';

interface HeaderProps {
  title: string;
  leftButton?: RoundedButtonProps;
  rightButton?: RoundedButtonProps | JSX.Element;
  icon?: JSX.Element;
  maxWidth?: string;
  filter?: string;
  height?: string;
  iconHeight?: string;
  titleSmall?: boolean;
}

interface PageWithHeaderLayoutProps {
  header: HeaderProps;
  bgcolor?: string;
  isFixed?: boolean;
}

export const PageWithHeaderLayout: React.FC<PageWithHeaderLayoutProps> = ({
  header,
  children,
  bgcolor,
  isFixed,
}) => {
  const theme = useTheme();
  const backgroundColor = bgcolor ?? theme.palette.common.pageBackground;

  return (
    <ContentContainer disablePadding>
      <Box
        height="100%"
        width="100%"
        overflow="auto"
        bgcolor={backgroundColor}
        display="flex"
        flexDirection="column"
      >
        <Grid
          container
          component="header"
          maxWidth={header.maxWidth || 'auto'}
          width="100%"
          px="30px"
          bgcolor={theme.palette.common.white}
          alignItems="center"
          zIndex="10"
          height={header.height || '80px'}
          style={{
            position: isFixed ? 'fixed' : 'static',
            filter: header.filter || 'drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25))',
          }}
        >
          <Grid item xs={3} display="flex" justifySelf="flex-start">
            {header.leftButton && <RoundedButton {...header.leftButton} />}
          </Grid>
          <Grid xs={6} display="flex" justifyContent="center" alignItems="center">
            {header.icon ? (
              <Box sx={{ pr: '16px', height: header.iconHeight ? header.iconHeight : 'auto' }}>
                {header.icon}
              </Box>
            ) : null}
            <Typography
              fontWeight={header.titleSmall ? 400 : 500}
              fontSize={header.titleSmall ? '14px' : '18px'}
              lineHeight="30px"
              color={theme.palette.common.deepDark}
              sx={{ wordBreak: 'break-all' }}
            >
              {header.title}
            </Typography>
          </Grid>
          <Grid xs={3} display="flex" justifyContent="flex-end">
            {header.rightButton &&
              (React.isValidElement(header.rightButton) ? (
                header.rightButton
              ) : (
                <RoundedButton {...(header.rightButton as RoundedButtonProps)} />
              ))}
          </Grid>
        </Grid>
        <Divider />
        {children}
      </Box>
    </ContentContainer>
  );
};
