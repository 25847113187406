import { FC } from 'react';

export const PreviewIcon: FC = () => {
  return (
    <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="18.5" cy="19" r="18" stroke="#8B89A0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 19.5C8 19.5 11 12.5 18 12.5C25 12.5 28 19.5 28 19.5C28 19.5 25 26.5 18 26.5C11 26.5 8 19.5 8 19.5Z"
        stroke="#8B89A0"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M18 22.5C19.6569 22.5 21 21.1569 21 19.5C21 17.8431 19.6569 16.5 18 16.5C16.3431 16.5 15 17.8431 15 19.5C15 21.1569 16.3431 22.5 18 22.5Z"
        stroke="#8B89A0"
        strokeWidth="2"
      />
      <path d="M18 12.5V10.5" stroke="#8B89A0" strokeWidth="2" strokeLinecap="round" />
      <path d="M24 14L25 12.5" stroke="#8B89A0" strokeWidth="2" strokeLinecap="round" />
      <path d="M27 17.5L28.5 16.5" stroke="#8B89A0" strokeWidth="2" strokeLinecap="round" />
      <path d="M7.5 16.5L9 17.5" stroke="#8B89A0" strokeWidth="2" strokeLinecap="round" />
      <path d="M12 14L11 12.5" stroke="#8B89A0" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
