import { FC } from 'react';
interface ILinkIconDv5Props {
  color?: string;
}
export const LinkIconDv5: FC<ILinkIconDv5Props> = ({ color }) => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4198 4.54438C8.16596 4.79822 8.16596 5.20978 8.4198 5.46362C8.67364 5.71746 9.0852 5.71746 9.33904 5.46362L8.4198 4.54438ZM12.9533 9.07792C12.6995 9.33176 12.6995 9.74332 12.9533 9.99716C13.2072 10.251 13.6187 10.251 13.8726 9.99716L12.9533 9.07792ZM15.4087 6.62254L12.9533 9.07792L13.8726 9.99716L16.3279 7.54178L15.4087 6.62254ZM9.33904 5.46362L11.7944 3.00825L10.8752 2.08901L8.4198 4.54438L9.33904 5.46362ZM15.4087 3.00825C16.4068 4.00631 16.4068 5.62448 15.4087 6.62254L16.3279 7.54178C17.8337 6.03604 17.8337 3.59475 16.3279 2.08901L15.4087 3.00825ZM16.3279 2.08901C14.8222 0.583267 12.3809 0.583266 10.8752 2.08901L11.7944 3.00825C12.7925 2.01019 14.4107 2.01019 15.4087 3.00825L16.3279 2.08901Z"
        fill={color || '#8B89A0'}
        stroke={color || 'none'}
      />
      <path
        d="M10.4122 13.4557C10.6661 13.2019 10.6661 12.7903 10.4122 12.5365C10.1584 12.2827 9.74683 12.2827 9.49299 12.5365L10.4122 13.4557ZM5.87869 8.9222C6.13254 8.66836 6.13254 8.25681 5.87869 8.00297C5.62485 7.74913 5.2133 7.74913 4.95946 8.00297L5.87869 8.9222ZM3.42332 11.3776L5.87869 8.9222L4.95946 8.00297L2.50408 10.4583L3.42332 11.3776ZM9.49299 12.5365L7.03762 14.9919L7.95686 15.9111L10.4122 13.4557L9.49299 12.5365ZM3.42332 14.9919C2.42526 13.9938 2.42526 12.3756 3.42332 11.3776L2.50408 10.4583C0.99834 11.9641 0.99834 14.4054 2.50408 15.9111L3.42332 14.9919ZM2.50408 15.9111C4.00982 17.4169 6.45111 17.4169 7.95686 15.9111L7.03762 14.9919C6.03956 15.9899 4.42138 15.9899 3.42332 14.9919L2.50408 15.9111Z"
        fill={color || '#8B89A0'}
        stroke={color || 'none'}
      />
      <path
        d="M11.9023 6.51343L6.86153 11.5542"
        stroke={color || '#8B89A0'}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
    </svg>
  );
};
