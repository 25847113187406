import React, { FC, useState } from 'react';
import { FormControlLabel, FormGroup, FormHelperText, Switch } from '@mui/material';
import { useToggleStyle } from './CustomToggle.style';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

interface ToggleProps {
  // onChange: ((status: boolean) => void) | ((e: React.ChangeEvent<HTMLInputElement>) => void);
  onChange: (status: boolean) => void;
  label?: string;
  value?: boolean;
  pending?: boolean;
  name?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  error?: string;
  id?: string;
}

export const Toggle: FC<ToggleProps> = ({
  onChange,
  label,
  value,
  className,
  pending,
  disabled,
  onClick,
  error,
  id,
}) => {
  const [checked, setChecked] = useState(value);
  const classes = useToggleStyle();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            classes={classes}
            className={className}
            checked={pending ? checked : value}
            onChange={handleChange}
            name={label + 'Toggle'}
            onClick={onClick}
            disabled={disabled}
            id={id}
          />
        }
        label={label || ''}
        className={classes.label}
      />
      {error && (
        <FormHelperText
          className={classes.errorWrapper}
          id="input-meta-title-helper-text"
          error
          sx={{ fontSize: '11.5px' }}
        >
          <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />
          {error}
        </FormHelperText>
      )}
    </FormGroup>
  );
};
