import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundColor: '#005F9E',
    height: `${(1 / 0.9) * 100}vh`,
    overflowY: 'auto',
    backgroundPosition: ' 0px -300px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: '703px',
    height: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '4px',
  },
  logo: {
    width: '250px',
    height: '37px',
    marginTop: '85px',
  },
}));
