export enum topGolfEvents {
  top_golf_video_slide_out_close_button_click = 'top_golf_video_slide_out_close_button_click',
  top_golf_video_slide_out_open_video_app_button_click = 'top_golf_video_slide_out_open_video_app_button_click',
  top_golf_video_slide_out_video_play = 'top_golf_video_slide_out_video_play',
  top_golf_video_slide_out_video_modal_close = 'top_golf_video_slide_out_video_modal_close',
  top_golf_video_slide_out_feature_modal_open = 'top_golf_video_slide_out_feature_modal_open',
  top_golf_video_slide_out_feature_modal_close = 'top_golf_video_slide_out_feature_modal_close',
  top_golf_video_slide_out_feature_video = 'top_golf_video_slide_out_feature_video',
  top_golf_video_slide_out_update_video_status = 'top_golf_video_slide_out_update_video_status',
  top_golf_video_slide_out_archive_button_click = 'top_golf_video_slide_out_archive_button_click',
  top_golf_video_slide_out_archive_modal_close = 'top_golf_video_slide_out_archive_modal_close',
  top_golf_video_slide_out_archive_video_status_changed = 'top_golf_video_slide_out_archive_video_status_changed',
  top_golf_video_slide_out_open_download_button_click = 'top_golf_video_slide_out_open_download_button_click',
  top_golf_video_slide_out_open_download_modal_close = 'top_golf_video_slide_out_open_download_modal_close',
  top_golf_video_slide_out_download_modal_video_play = 'top_golf_video_slide_out_download_modal_video_play',
  top_golf_video_slide_out_download_modal_video_pause = 'top_golf_video_slide_out_download_modal_video_pause',
  top_golf_video_slide_out_open_download_video = 'top_golf_video_slide_out_open_download_video',

  top_golf_reward_activation_change = 'top_golf_reward_activation_change',
  top_golf_rewards_page_change_reward_img = 'top_golf_rewards_page_change_reward_img',

  top_golf_rewards_page_cancel_button_click = 'top_golf_rewards_page_cancel_button_click',
  top_golf_rewards_page_save_button_click = 'top_golf_rewards_page_save_button_click',

  top_golf_video_playback_download_template_button_click = 'top_golf_video_playback_download_template_button_click',
  top_golf_video_playback_change_template = 'top_golf_video_playback_change_template',
}
