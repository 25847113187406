import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    backgroundColor: '#F5F6F8',
    marginTop: '16px',
    padding: '8px 0 25px 0',
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    borderBottom: '1px solid #DDDCE4',
    padding: '0 24px 9px 12px',
  },
  title: {
    fontSize: '14px',
    fontWeight: 500,
    color: theme.palette.common.deepDark,
  },
  buttonsWrapper: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
  },
  thumbnailsWrapper: {
    padding: '0 10px',
  },
  button: {
    height: '28px',
    fontSize: '11px',
    fontWeight: '600',
    backgroundColor: theme.palette.common.white,
    color: '#344054',
    padding: '4px 14px 4px 14px',
    borderRadius: '50px',
    border: '1px solid #D0D5DD',
  },
}));
