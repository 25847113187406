import { Box, useTheme } from '@mui/material';

export interface IconsProps {
  fill?: string;
  stroke?: string;
  hoverColor?: string;
  fillOpacity?: number;
  withoutOutline?: boolean;
  width?: number;
  height?: number;
}

const FILL_COLOR = '#ffffff';

export const EditIcon = (props: IconsProps) => {
  const theme = useTheme();

  const HOVER_COLOR = `${theme.palette.primary.main}e0`;

  return (
    <Box
      component="svg"
      width={props.width || 80}
      height={props.height || 80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        '&:hover': {
          '& .icon-fill': {
            fill: props.hoverColor || HOVER_COLOR,
          },
        },
      }}
    >
      <g filter="url(#filter0_bd_7565_132700)">
        <circle
          className="icon-fill"
          cx="40"
          cy="31"
          r="20"
          fill={props.fill || FILL_COLOR}
          fillOpacity={props.fillOpacity || 0.8}
        />
        <circle cx="40" cy="31" r="19.5" stroke={theme.palette.primary.main} />
      </g>
      <path
        d="M46.8495 30.9105V36.832C46.8495 37.9365 45.9541 38.832 44.8495 38.832H34C32.8954 38.832 32 37.9365 32 36.832V25.9824C32 24.8779 32.8954 23.9824 34 23.9824H40.1676"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.0956 22.7071C45.4862 22.3166 46.1193 22.3166 46.5098 22.7071L48.1248 24.322C48.5153 24.7126 48.5153 25.3457 48.1248 25.7363L41.808 32.0531C41.6627 32.1983 41.4762 32.2953 41.2739 32.3309L38.6125 32.7983C38.271 32.8583 37.9736 32.5609 38.0335 32.2194L38.501 29.558C38.5365 29.3557 38.6335 29.1692 38.7788 29.0239L45.0956 22.7071Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <defs>
        <filter
          id="filter0_bd_7565_132700"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_7565_132700"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />

          <feOffset dy="9" />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />

          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0769157 0 0 0 0 0.08335 0 0 0 0 0.237772 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_7565_132700"
            result="effect2_dropShadow_7565_132700"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_7565_132700"
            result="shape"
          />
        </filter>
      </defs>
    </Box>
  );
};
