import { FC } from 'react';

import { RewardsCircle } from '../../../assets/newDesign/RewardsCircle';
import { RewardsCircleSmall } from '../../../assets/newDesign/RewardsCircleSmall';
import { Container, Points, Wrapper } from './RewardCirclePoint.style';

interface DirectionsValues {
  [key: string]: string;
}
const directions: DirectionsValues = {
  right: '0',
  left: '90deg',
};

interface RewardCirclePointProps {
  points: number;
  color?: string;
  position?: string;
  size?: 'normal' | 'small';
}
export const RewardCirclePoint: FC<RewardCirclePointProps> = ({
  points,
  color,
  position = 'right',
  size = 'normal',
}) => {
  const Image =
    size === 'normal' ? <RewardsCircle color={color} /> : <RewardsCircleSmall color={color} />;
  return (
    <Container>
      <Points>{points}</Points>
      <Wrapper position={directions[position]}>{Image}</Wrapper>
    </Container>
  );
};
