import React, { useEffect, useState } from 'react';
import { Grid, TextField, Button, CircularProgress } from '@mui/material';
import {
  Title,
  Subtitle,
  PreviewWrapper,
  RewardWrapper,
  RewardBox,
  InputWrapper,
  PicturedInput,
  DefaultRewardPreviewWrapper,
} from './Steps.style';
import { VenueCreationWizardStrings } from '../../../common/localization/en';
import { IphoneXFrame } from '../../../common/components/previews/previews.style';
import { DefaultRewardFrame } from '../../../common/assets/DefaultRewardFrame';

import { ClientTypes } from '../../../api/models/common';
import { getActivities } from '../../../store/slices/rewardsSlice';

import { NewRewardSchema, RewardsProps } from '../WizzardStepperHelper';
import { httpClient } from '../../../services/httpClient/httpClient';
import { MediaEndpoints } from '../../../api/endpoints';
import { uploadLogoAsFile } from '../../webappSettings/General/Settings.helper';
import { Preview } from '../../rewards/rewardsList/Preview/Preview';
import { useTypedSelector, useAppDispatch } from '../../../store';
import { DefaultReward } from '../../../common/constants/constants';

// import {Preview} from '../'

interface UploadUrlApiModel {
  url: string;
  method: string;
  headers: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  downloadUrl: string;
}

const createSignedUploadUrl = async (mimeType?: string) => {
  try {
    return httpClient.post<undefined, UploadUrlApiModel>({
      url: `${MediaEndpoints.CreateUploadUrl}?ext=${mimeType}`,
      requiresToken: true,
    });
  } catch (error) {
    return console.log(error);
  }
};

export const RewardsStep: React.FC<RewardsProps> = ({ setRewardsStepState, rewardStepState }) => {
  const dispatch = useAppDispatch();
  const program_data = useTypedSelector((state) => state.venueCreation.program_payload);
  const { activities } = useTypedSelector((state) => state.rewards.activities);
  const [isLogoUploading, setIsLogoUploading] = useState(false);

  useEffect(() => {
    dispatch(getActivities({ clientType: ClientTypes.ETS }));
  }, [dispatch]);

  const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setRewardsStepState({
      ...rewardStepState,
      name: e.target.value,
    } as NewRewardSchema);
  };

  const onChangeLogoHandler = (logoFile: File) => {
    if (logoFile && logoFile.name) {
      createSignedUploadUrl(logoFile.name.split('.').pop())
        .then(async (res) => {
          setIsLogoUploading(true);
          if (res) {
            try {
              await uploadLogoAsFile({ options: res, data: logoFile });
              setRewardsStepState({ ...rewardStepState, image: res.downloadUrl });
              setIsLogoUploading(false);
            } catch (err) {
              setIsLogoUploading(false);
            }
          } else {
            setIsLogoUploading(false);
          }
        })
        .catch((err) => {
          setIsLogoUploading(false);
        });
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    onChangeLogoHandler(file);
  };
  const handleSetDefault = () => {
    const defaultReward = {
      image: DefaultReward.src,
      name: DefaultReward.name,
    };
    setRewardsStepState(defaultReward);
  };

  return (
    <RewardWrapper>
      <Grid container alignItems="flex-start" justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Title>{VenueCreationWizardStrings.Rewards}</Title>
          <Subtitle>{VenueCreationWizardStrings.RewardsSubtitle}</Subtitle>
        </Grid>
        <Grid item xs={7}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RewardBox>
                <Grid container alignItems="center" justifyContent="flex-start" spacing={1}>
                  <Grid item xs={4}>
                    {isLogoUploading ? (
                      <div style={{ height: '120px', width: '100%', position: 'relative' }}>
                        <CircularProgress
                          style={{
                            width: 30,
                            height: 30,
                            position: 'absolute',
                            top: 'calc(50% - 15px)',
                            left: 'calc(50% - 30px)',
                          }}
                          color="primary"
                        />
                      </div>
                    ) : (
                      <InputWrapper>
                        <PicturedInput
                          id="contained-button-file"
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          onChange={handleFileChange}
                        />
                      </InputWrapper>
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      id="name"
                      label={VenueCreationWizardStrings.RewardName}
                      onChange={handleChange}
                      variant="outlined"
                      value={rewardStepState.name}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </RewardBox>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSetDefault}>
                {VenueCreationWizardStrings.setDefaultReward}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {!rewardStepState.name || !rewardStepState.image ? (
            <PreviewWrapper>
              <IphoneXFrame>
                <DefaultRewardPreviewWrapper>
                  <DefaultRewardFrame></DefaultRewardFrame>
                </DefaultRewardPreviewWrapper>
              </IphoneXFrame>
            </PreviewWrapper>
          ) : (
            <Preview
              brandColor={program_data.campaignPrimaryColorHex}
              rewardImgUrl={rewardStepState.image}
              logo={program_data.campaignLogoUrl}
              name={rewardStepState.name}
              points={1000}
              activities={activities}
            />
          )}
        </Grid>
      </Grid>
    </RewardWrapper>
  );
};
