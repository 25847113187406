import { Box } from '@mui/material';
import { FC } from 'react';
import { FilterLogoPosition } from '../../../api/models/venue';
import { PreviewPositionBottomLeft } from '../../../common/assets/PreviewPositionBottomLeft';
import { PreviewPositionBottomRight } from '../../../common/assets/PreviewPositionBottomRight';
import { PreviewPositionIcon } from '../../../common/assets/PreviewPositionIcon';
import { PreviewPositionTopRight } from '../../../common/assets/Previewpositiontopright';
import { useAddLogoDetailsStyles } from './AddLogoDetails.style';
import { filterLogoPositions } from './DownloadVideo';

interface AddLogoDetailsProps {
  logoPosition: FilterLogoPosition;
  setLogoPosition: (value: FilterLogoPosition) => void;
  disabled?: boolean;
}

export const AddLogoDetails: FC<AddLogoDetailsProps> = ({
  logoPosition,
  setLogoPosition,
  disabled,
}) => {
  const styles = useAddLogoDetailsStyles();
  const handleClick = (value: FilterLogoPosition) => {
    if (disabled) {
      return;
    }
    setLogoPosition(value);
  };
  return (
    <>
      <div className={styles.wrapper}>
        <p className={styles.logoposition}>{'Logo Positions'}</p>
      </div>
      <div className={styles.wrapper}>
        <Box display="flex" alignItems="center" style={{ opacity: disabled ? 0.5 : 1 }}>
          <span
            className={[
              styles.PositionButton,
              logoPosition === filterLogoPositions.topLeft ? styles.selected : '',
            ].join(' ')}
            onClick={() => handleClick(filterLogoPositions.topLeft)}
            style={{
              backgroundColor: logoPosition === filterLogoPositions.topLeft ? 'blue' : 'inherit',
            }}
          >
            <span>
              <PreviewPositionIcon />
            </span>
          </span>

          <span
            className={[
              styles.PositionButton,
              logoPosition === filterLogoPositions.topRight ? styles.selected : '',
            ].join(' ')}
            onClick={() => handleClick(filterLogoPositions.topRight)}
            style={{
              backgroundColor: logoPosition === filterLogoPositions.topRight ? 'blue' : 'inherit',
            }}
          >
            <span>
              <PreviewPositionTopRight />
            </span>
          </span>
          <span
            className={[
              styles.PositionButton,
              logoPosition === filterLogoPositions.bottomRight ? styles.selected : '',
            ].join(' ')}
            onClick={() => handleClick(filterLogoPositions.bottomRight)}
            style={{
              backgroundColor:
                logoPosition === filterLogoPositions.bottomRight ? 'blue' : 'inherit',
            }}
          >
            <span>
              <PreviewPositionBottomRight />
            </span>
          </span>
          <span
            className={[
              styles.PositionButton,
              logoPosition === filterLogoPositions.bottomLeft ? styles.selected : '',
            ].join(' ')}
            onClick={() => handleClick(filterLogoPositions.bottomLeft)}
            style={{
              backgroundColor: logoPosition === filterLogoPositions.bottomLeft ? 'blue' : 'inherit',
            }}
          >
            <span>
              <PreviewPositionBottomLeft />
            </span>
          </span>
        </Box>
      </div>
    </>
  );
};
