import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { FormControl, Select, MenuItem, Box, SelectChangeEvent } from '@mui/material';
import clsx from 'clsx';

import { useAppDispatch, useTypedSelector } from '../../../store';
import { updateVideoById } from '../../../store/slices/videosSlice';
import { useStyles, useSelectStyles } from './VideoStatusDropdown.Helper';
import { VideoStatusChangeModal, VideoStatusStrings } from '../../localization/en';
import { ArrowBottomIcon } from '../../assets/newDesign/ArrowBottomIcon';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  ChangeStatusModalOptions,
  ChangeVideoStatusModal,
} from '../ChangeVideoStatusModal/ChangeVideoStatusModal';
import { VideoApiModel } from '../../../api/models/videos';
import { TopGolfVideosApiModel } from '../../../api/models/topgolfVideos';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../constants/events/videosEvents';
import PublishedIcon from '../../assets/ClipsDetailsIcons/PublishedIcon';
import UnpublishedIcon from '../../assets/ClipsDetailsIcons/UnpublishedIcon';
import Pending from '../../assets/ClipsDetailsIcons/Pending';
import SafeIcon from '../../assets/ClipsDetailsIcons/SafeIcon';
import ErrorIcon from '../../assets/ClipsDetailsIcons/ErrorIcon';

interface VideoStatusDropdownProps {
  video: VideoApiModel | TopGolfVideosApiModel;
}

export type StatusValues =
  | 'published'
  | 'unpublished'
  | 'moderated'
  | 'safe'
  | 'pending_moderation';

type SelectStatusValues = 'moderated' | 'safe';

const statusMap: Record<StatusValues, string> = {
  pending_moderation: VideoStatusStrings.PendingModeration,
  moderated: VideoStatusStrings.Moderated,
  published: VideoStatusStrings.Published,
  unpublished: VideoStatusStrings.Unpublished,
  safe: VideoStatusStrings.Safe,
};

const displayStatusMap: Record<StatusValues, StatusValues[]> = {
  pending_moderation: ['safe', 'moderated'],
  moderated: ['safe'],
  unpublished: ['moderated'],
  published: ['moderated'],
  safe: ['moderated'],
};

const confirmModalMap: Record<
  SelectStatusValues,
  Record<keyof ChangeStatusModalOptions, string>
> = {
  moderated: {
    title: VideoStatusChangeModal.TitleModerated,
    subtitle: VideoStatusChangeModal.SubtitleModerated,
    descrPlaceholder: VideoStatusChangeModal.PlaceholderModerated,
  },
  safe: {
    title: VideoStatusChangeModal.TitleSafe,
    subtitle: VideoStatusChangeModal.SubtitleSafe,
    descrPlaceholder: VideoStatusChangeModal.PlaceholderSafe,
  },
};

export const VideoStatusDropdown: FC<VideoStatusDropdownProps> = ({ video }) => {
  const { id, status: storyStatus } = video;
  const status = storyStatus.toLowerCase() as StatusValues;
  const { trackEvent } = useTrackEvent();

  const { addToast } = useToasts();

  const dispatch = useAppDispatch();
  const classes = useStyles({ status });
  const selectClasses = useSelectStyles();

  const [options, setOptions] = useState(displayStatusMap[status]);

  const params = useParams<{ id: string }>();
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);

  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);

  const listOfSocialVerses = useMemo(() => {
    return socialVerseAll ? socialVerseAll.filter((item) => item.accountId === venueId) : [];
  }, [socialVerseAll, venueId]);

  const isVideoExistsInSocialV =
    listOfSocialVerses &&
    listOfSocialVerses.filter(
      (item) => item.topVideos && item.topVideos.some((item) => item.videoId === params.id),
    ).length > 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOptions, setModalOptions] = useState<ChangeStatusModalOptions>(
    confirmModalMap['safe'],
  );
  const [selectedStatus, setSelectedStatus] = useState<SelectStatusValues>('safe');
  const [approvalReason, setApprovalReason] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    event.stopPropagation();

    const status = event.target.value as SelectStatusValues;
    trackEvent(VideosEventNames.changeVideoStatus, {
      value: status,
    });
    setSelectedStatus(status);

    setModalOptions(confirmModalMap[status]);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    trackEvent(VideosEventNames.cancelVideoStatusChange);
    setIsModalOpen(false);
    setApprovalReason('');
    setTimeout(() => {
      // @ts-ignore
      document?.activeElement?.blur();
    }, 0);
  };

  const handleConfirm = () => {
    let status = selectedStatus as StatusValues;
    trackEvent(VideosEventNames.confirmVideoStatusChange, {
      value: status,
    });
    if (status === 'safe') {
      status = isVideoExistsInSocialV ? 'published' : 'unpublished';
    }
    dispatch(
      updateVideoById({
        id,
        update: {
          status: `${status}`.toUpperCase(),
          details: {
            ...video.details,
            approvalReason,
          },
        },
      }),
    ).then(() => {
      const toastText =
        status === 'moderated'
          ? VideoStatusStrings.ModeratedStatusMessage
          : VideoStatusStrings.StatusMessage;

      addToast(toastText, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
      closeModal();
    });
  };

  useEffect(() => {
    setOptions(displayStatusMap[status]);
  }, [status]);

  const renderItemWithIndicator = (status: StatusValues) => {
    const statusIconMap: Record<StatusValues, JSX.Element> = {
      moderated: <ErrorIcon />,
      published: <PublishedIcon />,
      unpublished: <UnpublishedIcon />,
      pending_moderation: <Pending />,
      safe: <SafeIcon />,
    };
    return (
      <Box display="flex" alignItems="center" justifyContent="center" width={'100%'}>
        <Box style={{ position: 'absolute', left: '12px', top: '6px' }}>
          {statusIconMap[status]}
        </Box>
        <Box
          component="span"
          ml="6px"
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '23px',
            color: '#667085',
          }}
        >
          {statusMap[status]}
        </Box>
      </Box>
    );
  };

  // const shouldBlockDropdown = status === 'pending_moderation';
  const shouldBlockDropdown = false;

  return (
    <>
      <ChangeVideoStatusModal
        isModalOpen={isModalOpen}
        handleConfirm={handleConfirm}
        handleCancel={closeModal}
        options={modalOptions}
        approvalReason={approvalReason}
        setApprovalReason={setApprovalReason}
      />
      <FormControl
        variant="standard"
        className={clsx(classes.formControl, selectClasses[status])}
        onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
      >
        {shouldBlockDropdown ? (
          <Box
            className={clsx(classes[status])}
            fontWeight="400"
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            borderRadius="10px"
          >
            {renderItemWithIndicator(status)}
          </Box>
        ) : (
          <Select
            IconComponent={() => (
              <span className={classes.arrow}>
                <ArrowBottomIcon />
              </span>
            )}
            className={clsx(classes[status], classes.select)}
            value={status}
            style={{
              borderRadius: '10px',
            }}
            onChange={handleChange}
            onMouseUp={(event) => {
              event.stopPropagation();
            }}
            fullWidth
            disableUnderline
            renderValue={(value) => renderItemWithIndicator(value as StatusValues)}
          >
            {options.map((status: StatusValues) => (
              <MenuItem
                key={status}
                className={clsx(classes.menuItem, classes[status])}
                onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}
                value={status}
              >
                {renderItemWithIndicator(status)}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </>
  );
};
