export const BadgeIconDeactivated = () => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.90591 5.9757L4.7135 7.45684C4.65887 7.87742 4.50922 8.28005 4.2759 8.63422L3.5076 9.80047C3.21566 10.2436 3.06006 10.7626 3.06006 11.2933C3.06006 12.792 4.27498 14.0069 5.77365 14.0069H13.2562C14.7387 14.0069 15.9406 12.8051 15.9406 11.3226C15.9406 10.7739 15.7724 10.2385 15.4588 9.78827L14.6679 8.65278C14.413 8.28697 14.25 7.86519 14.1926 7.42309L14.0046 5.9757C13.7076 3.68973 11.7604 1.97913 9.45524 1.97913C7.15006 1.97913 5.20287 3.68973 4.90591 5.9757Z"
        stroke="#F3F3F5"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7.51465 15.7664C7.86806 16.5082 8.62474 17.0208 9.50116 17.0208C10.3776 17.0208 11.1343 16.5082 11.4877 15.7664"
        stroke="#F3F3F5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="10" y="0.5" width="8.5" height="8.5" rx="4.25" fill="#8B89A0" stroke="white" />
    </svg>
  );
};
