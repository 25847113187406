import { FC } from 'react';

interface TwitterIconProps {
  color?: string;
}
export const TwitterIcon: FC<TwitterIconProps> = ({ color }) => {
  return (
    <>
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22 4.00897C21.1294 4.61128 20.1656 5.07195 19.1455 5.37324C18.5979 4.75576 17.8703 4.3181 17.0609 4.11947C16.2516 3.92083 15.3995 3.9708 14.6201 4.26261C13.8406 4.55442 13.1713 5.07399 12.7027 5.75105C12.2341 6.42812 11.9888 7.23001 12 8.04827V8.93995C10.4024 8.98058 8.81934 8.63305 7.39183 7.92829C5.96431 7.22354 4.73665 6.18345 3.81818 4.90065C3.81818 4.90065 0.181818 12.9258 8.36364 16.4925C6.49139 17.739 4.26105 18.364 2 18.2758C10.1818 22.7342 20.1818 18.2758 20.1818 8.02152C20.181 7.77315 20.1566 7.52539 20.1091 7.28143C21.0369 6.38395 21.6917 5.25082 22 4.00897Z"
          stroke={color || '#8F94B6'}
          strokeWidth={1.8}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};
