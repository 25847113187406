import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Box, Card, Grid, Hidden, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { getWikiContentById } from '../../../services/utilities';
import { Accordion, AccordionDetails, AccordionSummary } from '../Integration/Integration.helper';
import { Content, Release, useClasses } from './History.helper';
import { IntegrationsHistoryImage } from '../../../common/assets/IntegrationsHistoryImage';
import { ContentStyled } from '../Integration/Integration.style';

export const History: FC = () => {
  const classes = useClasses();
  const wikiPageId = '552501249';
  const [releases, setReleases] = useState<Release[]>([]);
  const [content, setContent] = useState<Content>({ title: '', content: '' });

  useEffect(() => {
    getWikiContentById(wikiPageId).then((data: any) => {
      setReleases(data.data.children.page.results);

      setContent({ title: data.data.title, content: '' });
    });
  }, []);

  const handleChange = (id: string) => (event: ChangeEvent<{}>, newExpanded: boolean) => {
    let releaseIndex: number;

    const release = releases.find((release, index) => {
      if (release.id === id) {
        releaseIndex = index;
      }

      return release.id === id;
    });

    if (release && !release.content) {
      getWikiContentById(id).then((data: any) => {
        release.content = data.data.body.export_view.value;
        const newReleases = [...releases];

        newReleases[releaseIndex] = release;

        setReleases(newReleases);
      });
    }
  };

  return (
    <Card elevation={0} className={classes.root}>
      <Grid container>
        <Grid item lg={8} xs={12}>
          <Box className={classes.body}>
            <Typography variant="h6">{content.title}</Typography>
            {content?.content && (
              <ContentStyled dangerouslySetInnerHTML={{ __html: content.content }}></ContentStyled>
            )}
            <Box mb={3} />
            {releases.map((release, index) => (
              <Accordion key={release.id} square onChange={handleChange(release.id)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <Typography>{release.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ContentStyled
                    dangerouslySetInnerHTML={{ __html: release.content }}
                  ></ContentStyled>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Grid>
        <Hidden lgDown>
          <Grid item lg={4}>
            <Box className={classes.imageSide}>
              <IntegrationsHistoryImage width="300" />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </Card>
  );
};
