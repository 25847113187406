import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './VideoSharingTab.styles';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { useTypedSelector } from '../../../../../../store';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { CallToActionModal } from '../../../../../rewards/CampaignSummary/Modals/CallToActionModal/CallToActionModal';
import { useModal } from '../../../../../../hooks/useModal';
import { IPhoneVideoPlaybackPreview } from '../../../../../../common/components/IPhonePreview/IPhoneVideoPlaybackPreview';
import { filterLogoPositions } from '../../../../../webappSettings/VideoBrandingPlayback/VideoBrandingPlayback.helper';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { useClientType } from '../../../../../../services/hooks/useClientType';

export const VideoSharingTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();

  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const {
    venue: { logo },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);
  const brandColor = venueProperties?.['webapp.config']['primary-color'];

  const {
    isOpen: isCallToActionModalOpen,
    open: openCallToActionModal,
    close: closeCallToActionModal,
  } = useModal(false);

  return (
    <Box className={classes.container}>
      {currentCampaign && (
        <CallToActionModal
          currentCampaign={currentCampaign}
          isOpen={isCallToActionModalOpen}
          onClose={closeCallToActionModal}
        />
      )}
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>{ElementsStrings.VideoSharing}</Typography>
        <Typography className={classes.subtitle}>
          {isHealthCare ? ElementsStrings.WhenSharesTrublu : ElementsStrings.WhenShares}
        </Typography>
        <Typography className={classes.settingsText}>{ElementsStrings.Settings}</Typography>
        <Box className={classes.sectionWrapper}>
          <Typography className={classes.sectionTitle} mr="75px">
            {ElementsStrings.ButtonTitle}
          </Typography>
          <Typography className={classes.sectionValue}>{currentCampaign?.ctaButtonText}</Typography>
        </Box>
        <Box className={classes.sectionWrapper}>
          <Typography className={classes.sectionTitle} mr="25px">
            {ElementsStrings.ButtonDestination}
          </Typography>
          <Typography className={classes.sectionValue}>
            {currentCampaign?.ctaButtonLinkUrl}
          </Typography>
        </Box>
        {!currentCampaign?.endedAt && (
          <RoundedButton
            className={classes.customizeButton}
            title={ElementsStrings.Customize}
            onClick={() => {
              openCallToActionModal();
              trackEvent(RewardCampaignsEvents.OpenCallToActionModal, {
                campaignId,
              });
            }}
          />
        )}
      </Box>
      <Box className={classes.rightSideWrapper}>
        <Typography className={classes.howAppearText}>
          {ElementsStrings.HowSharedVideoAppear}
        </Typography>
        <Box className={classes.previewWrapper}>
          <IPhoneVideoPlaybackPreview
            logoPosition={filterLogoPositions.topLeft}
            tempFilterLogoEnabled={true}
            tempFilterOverlayEnabled={true}
            buttonText={currentCampaign?.ctaButtonText}
            logo={logo}
            brandColor={brandColor}
          />
        </Box>
      </Box>
    </Box>
  );
};
