import { FC, ChangeEvent as ReactChangeEvent, SetStateAction } from 'react';
import { Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';

import {
  Title,
  Subtitle,
  ColorBox,
  ColorText,
  LogoBox,
  FiltersWrapper,
  FilterImage,
  FiltersStepWrapper,
  ImgContainer,
} from './Steps.style';
import { VenueCreationWizardStrings } from '../../../common/localization/en';
import { useTypedSelector } from '../../../store';
import { FilterProps } from '../WizzardStepperHelper';
import defaultBg from '../../../common/assets/default-filter-bg.jpg';
import { FiltersError } from './FiltersError';

interface FilterStepProps {
  filterStepState: FilterProps[];
  setFilterStepState: (value: SetStateAction<FilterProps[]>) => void;
  hideLogoAndColors?: boolean;
}

export const FiltersStep: FC<FilterStepProps> = ({
  filterStepState,
  setFilterStepState,
  hideLogoAndColors,
}) => {
  const handleChange = (event: ReactChangeEvent<HTMLInputElement>) => {
    const newFilters = [...filterStepState].map((item) => {
      return { ...item, isActive: false };
    });
    newFilters[parseInt((event.target as HTMLInputElement).value)].isActive = true;
    setFilterStepState(newFilters);
  };
  // filter creation api not working properly
  const filtersUrl = useTypedSelector((state) => state.venueCreation.filterUrls);
  const program_data = useTypedSelector((state) => state.venueCreation.program_payload);
  const renderFilterSelection = () => {
    const fitlerArray = filtersUrl.length ? filtersUrl : new Array(3).fill('');
    return fitlerArray.map((item, index) => {
      return (
        <Grid item xs={3} key={index}>
          <ImgContainer>
            <FilterImage src={defaultBg} />
            <FilterImage src={item} />
          </ImgContainer>
          <FormControlLabel
            value={index}
            control={<Radio />}
            label={VenueCreationWizardStrings.FilterId.replace(':id', (index + 1).toString())}
          />
        </Grid>
      );
    });
  };
  return (
    <FiltersStepWrapper>
      <Grid container alignItems="center" justifyContent="center" spacing={3}>
        {!hideLogoAndColors && (
          <>
            <Grid item xs={12}>
              <Title>{VenueCreationWizardStrings.FilterStepTitle}</Title>
              <Subtitle>{VenueCreationWizardStrings.FilterStepSubtitle}</Subtitle>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" justifyContent="flex-start">
                <Grid item xs={4}>
                  <LogoBox src={program_data.campaignLogoUrl} />
                </Grid>
                <Grid item xs={4}>
                  <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
                    <Grid item xs={3}>
                      <ColorBox color={program_data.campaignPrimaryColorHex} />
                    </Grid>
                    <Grid item xs={7}>
                      <ColorText>{program_data.campaignPrimaryColorHex}</ColorText>
                    </Grid>
                    <Grid item xs={3}>
                      <ColorBox color={program_data.campaignSecondaryColorHex} />
                    </Grid>
                    <Grid item xs={7}>
                      <ColorText>{program_data.campaignSecondaryColorHex}</ColorText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {filterStepState.length ? (
          <Grid item xs={12}>
            <FiltersWrapper>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={filterStepState.findIndex((item) => item.isActive)}
                  onChange={handleChange}
                >
                  <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                    {renderFilterSelection()}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </FiltersWrapper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Divider />
            <FiltersError />
          </Grid>
        )}
      </Grid>
    </FiltersStepWrapper>
  );
};
