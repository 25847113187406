import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './IntroVideo.styles';
import { CampaignSummaryIntroVideoStrings } from '../../../../../common/localization/en';
import { useModal } from '../../../../../hooks/useModal';
import IntroVideoModal from './IntroVideoModal/IntroVideoModal';
import { useVenueProperty } from '../../../../../hooks/useVenueProperty';
import { VenueProperties } from '../../../../../api/models/venue';
import { useTrackEvent } from '../../../../../hooks/useTrackEvent';

export enum IntroVideoEventNames {
  openIntroVideoModal = 'intro_video_open_modal',
  closeIntroVideoModal = 'intro_video_close_modal',
  addIntroVideo = 'intro_video_add_button_click',
  removeIntroVideo = 'intro_video_remove_button_click',
  cancelIntroVideoUpdate = 'intro_video_cancel_button_click',
  confirmIntroVideo = 'intro_video_confirm_button_click',
  uploadButtonClick = 'intro_video_upload_button_click',
  recordButtonClick = 'intro_video_record_buttonClick',
  fileSelected='intro_video_file_selected'
}

const IntroVideo = () => {
  const classes = useStyles();
  const { value: introVideo, update: updateVideoUrl } = useVenueProperty<
    VenueProperties['webapp.uwm.intro-video']
  >({
    property: 'webapp.uwm.intro-video',
  });

  const { isOpen, close, open } = useModal();
  const { trackEvent } = useTrackEvent();
  const handleClose = () => {
    close();
    trackEvent(IntroVideoEventNames.closeIntroVideoModal);
  };

  return (
    <>
      {isOpen && (
        <IntroVideoModal
          isOpen={isOpen}
          onClose={handleClose}
          videoUrl={introVideo?.videoUrl}
          thumbnailUrl={introVideo?.thumbnailUrl}
          updateVideoUrl={updateVideoUrl}
        />
      )}
      <Box className={classes.container}>
        <Typography className={classes.title}>
          {CampaignSummaryIntroVideoStrings.IntroVideo}
        </Typography>
        <Typography className={classes.text}>
          {CampaignSummaryIntroVideoStrings.ForMortgageProfile}
        </Typography>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={() => {
            open();
            trackEvent(IntroVideoEventNames.openIntroVideoModal);
          }}
        >
          {introVideo?.videoUrl
            ? CampaignSummaryIntroVideoStrings.Customize
            : CampaignSummaryIntroVideoStrings.AddVideo}
        </Button>
      </Box>
    </>
  );
};

export default IntroVideo;
