import {
  SMSInviteSendTestMessageApiPayload,
  SMSInviteUpdateConfigApiPayload,
} from '../../api/models/smsInvite';
import { useAppDispatch, useTypedSelector } from '../../store';
import {
  fetchMessageVariables,
  fetchMessagingConfig,
  sendTestMessage,
  updateMessagingConfig,
} from '../../store/slices/smsInvitesSlice';

export const useSMSInviteApiCalls = () => {
  const venueId = useTypedSelector((state) => state.venue.venue.id);

  const dispatch = useAppDispatch();

  return {
    fetchMessagingConfig: () => {
      return dispatch(fetchMessagingConfig({ accountId: venueId }));
    },
    fetchMessageVariables: () => {
      return dispatch(fetchMessageVariables({ accountId: venueId }));
    },
    updateMessagingConfig: (payload: SMSInviteUpdateConfigApiPayload) => {
      return dispatch(updateMessagingConfig({ accountId: venueId, ...payload }));
    },
    sendTestMessage: (payload: SMSInviteSendTestMessageApiPayload) => {
      return dispatch(sendTestMessage({ accountId: venueId, ...payload }));
    },
  };
};
