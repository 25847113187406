import { FC } from 'react';
import { useStyles } from './TabsSelect.styles';
import { Box, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';

export interface TabItem {
  tabName: string;
  usersCount?: string;
  tabContent: JSX.Element;
  showEmpty?: boolean;
  className?: string;
  disabled?: boolean;
}

interface Props {
  tabsList: TabItem[];
  activeTabIndex: number;
  onTabChange: (index: number) => void;
}

export const TabsSelect: FC<Props> = ({ tabsList, activeTabIndex, onTabChange }) => {
  const theme = useTheme();
  const { trackEvent } = useTrackEvent();

  const events = [
    RewardCampaignsEvents.RewardCampaginActiveCustomersTabClick,
    RewardCampaignsEvents.RewardCampaignPendingTabClick,
    RewardCampaignsEvents.RewardCampaginInvitedTabClick,
  ];

  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = activeTabIndex === index;
        return (
          <button
            className={classes.tab}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              cursor: isActiveTab || tabItem.disabled ? 'auto' : 'pointer',
              border: tabItem.disabled ? 'none' : undefined,
            }}
            key={index}
            onClick={() => {
              onTabChange(index);
              trackEvent(events[index]);
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter active-tab`}
            disabled={tabItem.disabled}
          >
            <Typography
              className={classes.tabName}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography
              className={classes.amount}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
                background: tabItem.disabled ? 'inherit' : undefined,
              }}
            >
              {tabItem.usersCount}
            </Typography>
          </button>
        );
      })}
    </Box>
  );
};
