import { colorsForIcons } from './iconWhitInitials.const';

export const getInitials = (name?: string) => {
  if (name) {
    const arr = name?.split(' ');
    let res = '';
    for (let i = 0; i <= 1 && i < arr.length; i++) {
      const letter = arr[i][0];
      res = letter ? res + letter : res;
    }
    return res.toUpperCase();
  }
};

export const getColorsParams = (i?: number) => {
  if (!i && i !== 0) {
    return colorsForIcons[0];
  }
  const index = (i % 5) + 1;
  return colorsForIcons[index];
};
