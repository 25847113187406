import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ClientTypes } from '../../../api/models/common';
import { SearchFilterAttributes, SearchTargetsType } from '../../../api/models/searchFilter';
import { VideoApiModel } from '../../../api/models/videos';
import { DownloadIconSVG } from '../../../common/assets/DownloadIcon';
import { MedalIcon } from '../../../common/assets/newDesign/MedalIcon';
import { UsersIcon } from '../../../common/assets/sideBarAssets/UsersIcon';
import { ActiveModal } from '../../../common/components/ActionBar/ActionBar';
import { NewDetailsModal } from '../../../common/components/NewDetailsModal/NewDetailsModal';
import { SearchBar } from '../../../common/components/searchBar/SearchBar';
import { CommonTable } from '../../../common/components/table/CommonTable';
import { LAYOUT_CONTAINER_CLASSNAME } from '../../../common/constants/constants';
import { CommonStrings, SavedFiltersPageStrings } from '../../../common/localization/en';
import { useClientType } from '../../../services/hooks/useClientType';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  getSearchFilterById,
  postSearchFilters,
  resetNewFilter,
} from '../../../store/slices/searchFiltersSlice';
import {
  goToSelectedPage as goToSelectedRowsPage,
  resetPage,
  resetSelectedRows,
  updateSelectedPageSize,
} from '../../../store/slices/selectedRowsSlice';
import { getAllSocialVerse } from '../../../store/slices/socialVerseSlice';
import {
  getVideosBySearchFilter,
  goToSelectedPage as goToSelectedStoryPage,
  reset as resetStory,
  setRefetch,
  updateSize as updateStoryTableSize,
} from '../../../store/slices/videosSlice';

import {
  getVideosBySearchFilter as getTGVideosBySearchFilter,
  goToSelectedPage as goToSelectedTopGolfVideos,
  reset as resetTGStory,
  updateSize as updateTopGolfVideosTableSize,
  updateSearch as updateTopGolfVideosSearch,
} from '../../../store/slices/topGolfVideosSlice';
import { theme } from '../../../theme';
import {
  backButtonRoutes,
  generateEntity,
  getDownloadCSVTopGolfTableConfig,
  getHeaders,
  getModalTableConfig,
  getTableConfig,
  getTopGolfTableConfig,
  Header,
  Modals,
  updateSearch,
} from '../CreatedFilter.helper';
import { CreatedFilterModal } from '../CreatedFilterMdal';
import { useFiltersStyles } from '../Filters.style';
import { useFilterTablesStyles } from './TablesStyles';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { VideosEventNames } from '../../../common/constants/events/videosEvents';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { useUnsavedFilter } from '../useUnsavedFilter';

export const VideosFilter = () => {
  const styles = useFiltersStyles();
  const classes = useFilterTablesStyles();
  const { trackEvent } = useTrackEvent();

  const history: any = useHistory();
  const dispatch = useAppDispatch();
  const { clientType, id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { selectedRows: selected } = useTypedSelector((state) => state.selectedRows);
  const { socialVerseAll } = useTypedSelector((state) => state.SocialVerseSlice);
  const [selectedRows, setSelectedRows] = useState<any[] | null>(null);
  const { addToast } = useToasts();

  const {
    items: storyItems,
    page: storyPage,
    totalItems: storyTotalItems,
    totalPages: storyTotalPages,
    isLoading,
    size: storySize,
    sort: storySort,
    search: storySearch = '',
  } = useTypedSelector((state) => state.videos);
  const {
    items: topGolfVideosItems,
    page: topGolfVideosPage,
    totalItems: topGolfVideosTotalItems,
    totalPages: topGolfVideosTotalPages,
    isLoading: topGolfVideosIsLoading,
    size: topGolfVideosSize,
    sort: topGolfVideosSort,
    search: topGolfVideosSearch = '',
  } = useTypedSelector((state) => state.TopgGolfVideos);
  const { shouldRefetch } = useTypedSelector((state) => state.videos);
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const [open, setOpen] = useState(false);
  const { activeFilter, target, newFilterAttributes } = useTypedSelector(
    (state) => state.SearchFilterSlice,
  );
  const { isHealthCare } = useClientType();
  const params = useParams<{ id: string; filterId: string }>();

  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const isTopgolf = clientType === ClientTypes.TOPGOLF;

  const handleClose = () => {
    setActiveModal(null);
  };

  const isFilterTargetVideos = target === 'VIDEOS' || activeFilter?.target === 'VIDEOS';
  const filterTarget = activeFilter?.target || target;

  useUnsavedFilter({ page: 'videos' });

  useEffect(() => {
    if (isFilterTargetVideos && !isTopgolf) {
      let filters =
        newFilterAttributes.length > 0
          ? newFilterAttributes
          : activeFilter?.filter.attributes ?? [];

      let keywordsFilter: SearchFilterAttributes | undefined = filters.find(
        (f) => f.name === 'keywordScore',
      );
      if (keywordsFilter && keywordsFilter.value) {
        const divideBy = (value: string) => (value.length === 1 ? 10 : 100);
        filters = filters.filter((f) => f.name !== 'keywordScore'); //filters without keywordScore
        const transformKeywordsScore =
          typeof keywordsFilter.value === 'string'
            ? parseInt(keywordsFilter.value) / divideBy(keywordsFilter.value)
            : {
                min: parseInt(keywordsFilter.value.min) / divideBy(keywordsFilter.value.min),
                max: parseInt(keywordsFilter.value.max) / divideBy(keywordsFilter.value.max),
              };
        keywordsFilter = { ...keywordsFilter, value: transformKeywordsScore };
        filters = [...filters, keywordsFilter];
      }

      dispatch(
        getVideosBySearchFilter({
          venueId,
          filter: filters,
          pageable: {
            page: storyPage,
            size: storySize,
            sort: storySort,
          },
          search: storySearch,
        }),
      );

      if (shouldRefetch) {
        dispatch(setRefetch(false));
      }
    }
  }, [
    activeFilter,
    dispatch,
    target,
    storyPage,
    storySearch,
    storySize,
    storySort,
    venueId,
    isTopgolf,
    newFilterAttributes,
    shouldRefetch,
    isFilterTargetVideos,
  ]);

  useEffect(() => {
    if (venueId) {
      dispatch(getSearchFilterById({ id: params.filterId }));
    }
  }, [venueId, dispatch, params.filterId]);

  const { keywords } = useTypedSelector((state) => state.KeywordsSlice);
  const tableConfig = getTableConfig(
    selectedRows ? selectedRows : isTopgolf ? topGolfVideosItems || [] : storyItems || [],
    isHealthCare,
    keywords.length,
  );

  const modalTableConfig = getModalTableConfig(
    selectedRows ? selectedRows : isTopgolf ? topGolfVideosItems || [] : storyItems || [],
    isHealthCare,
    keywords.length,
  );

  const topGolfTableConfig = getTopGolfTableConfig(
    selectedRows ? selectedRows : isTopgolf ? topGolfVideosItems || [] : storyItems || [],
    // keywords.length,
  );

  const topGolfDownloadCSVTableConfig = getDownloadCSVTopGolfTableConfig(
    selectedRows ? selectedRows : isTopgolf ? topGolfVideosItems || [] : storyItems || [],
    // keywords.length,
  );

  useEffect(() => {
    if (isFilterTargetVideos && isTopgolf) {
      dispatch(
        getTGVideosBySearchFilter({
          venueId,
          filter:
            newFilterAttributes.length > 0
              ? newFilterAttributes
              : activeFilter?.filter.attributes ?? [],
          pageable: {
            page: topGolfVideosPage,
            size: topGolfVideosSize,
            sort: topGolfVideosSort,
          },
          search: topGolfVideosSearch,
        }),
      );
      if (shouldRefetch) {
        dispatch(setRefetch(false));
      }
    }
  }, [
    activeFilter,
    dispatch,
    target,
    topGolfVideosPage,
    topGolfVideosSearch,
    topGolfVideosSize,
    topGolfVideosSort,
    venueId,
    isTopgolf,
    newFilterAttributes,
    shouldRefetch,
    isFilterTargetVideos,
  ]);

  const handleGoBack = () => {
    dispatch(resetNewFilter());
    dispatch(resetSelectedRows());
    dispatch(resetPage());

    dispatch(resetStory());
    dispatch(resetTGStory());
    history.push(backButtonRoutes[filterTarget ?? 'VIDEOS']);
  };

  const handleSaveBtn = () => {
    setOpen(!open);
  };

  const handleConfirm = (name: string) => {
    trackEvent(VideosEventNames.videosApplyFilter, {
      value: name,
    });
    if (newFilterAttributes.length && target) {
      dispatch(
        postSearchFilters({
          accountId: venueId,
          name,
          target,
          filter: {
            attributes: newFilterAttributes,
          },
          searchText: '',
        }),
      ).then(() =>
        addToast(CommonStrings.NewFilterSuccesfullySaved, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        }),
      );
      setOpen(false);
    }
  };

  const onGoToStoryPage = (targetPage: number) => {
    trackEvent(VideosEventNames.videosTablePageChanged, {
      page: targetPage.toString(),
    });
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (!isTopgolf) {
      if (convertedTargetPage >= storyTotalPages) {
        return;
      }

      dispatch(goToSelectedStoryPage(convertedTargetPage));
      dispatch(goToSelectedRowsPage(convertedTargetPage));
    } else {
      if (convertedTargetPage >= topGolfVideosTotalPages) {
        return;
      }

      dispatch(goToSelectedTopGolfVideos(convertedTargetPage));
      dispatch(goToSelectedRowsPage(convertedTargetPage));
    }
  };

  const changeStoryTableSize = (size: number) => {
    trackEvent(VideosEventNames.videosTableSizeChanged, {
      value: size.toString(),
    });
    if (isTopgolf) {
      dispatch(updateTopGolfVideosTableSize(size));
    } else {
      dispatch(updateStoryTableSize(size));
    }

    dispatch(updateSelectedPageSize(size));
  };

  const updateSearchHandler = (search: string) => {
    if (isTopgolf) {
      return updateTopGolfVideosSearch(search);
    }
    trackEvent(VideosEventNames.videosTableSearchUpdated, {
      value: search,
    });

    const filters =
      newFilterAttributes.length > 0 ? newFilterAttributes : activeFilter?.filter.attributes ?? [];

    return updateSearch(
      search,
      getVideosBySearchFilter,
      filters,
      venueId,
      storyPage,
      storySize,
      storySort,
    );
  };

  const handleRowClick = (id: string) => {
    trackEvent(VideosEventNames.videoRowClicked, {
      videoId: id,
    });
    history.push(`${history.location.pathname}/${id}`);
  };

  const close = () => {
    history.push(history.location.pathname.replace(`/${params.id}`, ''));
  };
  const topgolfVideos = useTypedSelector((state) => state.TopgGolfVideos.items);

  const isNotTopgolf = clientType !== ClientTypes.TOPGOLF;
  const [story] = isNotTopgolf
    ? storyItems.filter((item) => history.location.pathname.includes(item.id))
    : topgolfVideos.filter((item) => history.location.pathname.includes(item.id));
  const storyVideoId = params?.id;

  useEffect(() => {
    return () => {
      dispatch(resetNewFilter());
      dispatch(resetSelectedRows());
      dispatch(resetPage());
      dispatch(resetStory());
      dispatch(resetTGStory());
    };
  }, [dispatch]);

  useEffect(() => {
    venueId && dispatch(getAllSocialVerse({ accountId: venueId, withVideos: true }));
  }, [venueId, dispatch]);

  const isAssignToPluginBtnDisabled = !selected || !selected.length || !socialVerseAll?.length;
  return (
    <div className={[styles.Container, LAYOUT_CONTAINER_CLASSNAME].join(' ')}>
      {activeModal && filterTarget && (
        <Modals
          activeModal={activeModal}
          handleClose={handleClose}
          headers={getHeaders[filterTarget as SearchTargetsType]}
          content={isTopgolf ? topGolfDownloadCSVTableConfig : modalTableConfig}
          storyItems={isTopgolf ? topGolfVideosItems : storyItems}
          setSelectedRows={(selected) => setSelectedRows(selected)}
          filterName={activeFilter ? activeFilter.name : ''}
        />
      )}
      <Header
        handleGoBack={handleGoBack}
        handleSaveBtn={handleSaveBtn}
        target={filterTarget ?? 'VIDEOS'}
        showSaveBtn={activeFilter ? false : true}
        filterName={activeFilter?.name}
      />
      <main>
        <Grid container>
          <Grid item className={styles.Sidebar}>
            <Box width="90%" marginLeft="auto" marginRight="auto">
              <Divider />
            </Box>
            <Typography className={styles.PageDescription}>
              {SavedFiltersPageStrings.BulkDescription}
            </Typography>
            <Grid spacing={3} container direction="column" className={styles.BulkList}>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    trackEvent(VideosEventNames.downloadCsvFilterResultsButtonClicked, {
                      filterId: activeFilter?.id,
                    });
                    if (selected && selected.length <= 100) {
                      setActiveModal('DOWNLOAD_CSV');
                    } else {
                      setActiveModal('DOWNLOAD_CSV_LIMIT');
                    }
                  }}
                  variant="contained"
                  size="large"
                  className={styles.Btn}
                  disabled={!selected.length}
                >
                  <span
                    style={{
                      color: isTopgolf
                        ? !selected || !selected.length
                          ? theme.palette.text.secondary
                          : theme.palette.common.black
                        : !selected || !selected.length
                        ? theme.palette.text.secondary
                        : theme.palette.common.black,
                      display: 'flex',
                    }}
                  >
                    <div className={styles.Icon} style={{ stroke: theme.palette.common.white }}>
                      <DownloadIconSVG />
                    </div>
                  </span>
                  {SavedFiltersPageStrings.DownloadCSV}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    trackEvent(VideosEventNames.assignBonusPointsButtonClicked, {
                      filterId: activeFilter?.id,
                    });

                    if (selected && selected.length <= 10) {
                      setActiveModal('ASSIGN_BONUS_POINTS');
                    } else {
                      setActiveModal('ASSIGN_BONUS_POINTS_LIMIT');
                    }
                  }}
                  variant="contained"
                  size="large"
                  className={styles.Btn}
                  disabled={!activeCampaign || !selected || !selected.length}
                >
                  <span
                    style={{
                      stroke: isTopgolf
                        ? !selected || !selected.length
                          ? theme.palette.text.secondary
                          : theme.palette.common.black
                        : !selected || !selected.length
                        ? theme.palette.text.secondary
                        : theme.palette.common.black,
                      display: 'flex',
                    }}
                  >
                    <div className={styles.Icon} style={{ stroke: theme.palette.common.white }}>
                      <MedalIcon />
                    </div>
                  </span>
                  {SavedFiltersPageStrings.RewardNow}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    trackEvent(VideosEventNames.assignToSocialVerseButtonClicked, {
                      filterId: activeFilter?.id,
                    });
                    if (selected && selected.length <= 10) {
                      setActiveModal('ASSIGN_TO_PLUGIN');
                    } else {
                      setActiveModal('ASSIGN_TO_PLUGIN_LIMIT');
                    }
                  }}
                  variant="contained"
                  size="large"
                  className={styles.Btn}
                  disabled={isAssignToPluginBtnDisabled}
                >
                  <span
                    style={{
                      stroke: isTopgolf
                        ? !selected || !selected.length
                          ? theme.palette.text.secondary
                          : theme.palette.common.black
                        : !selected || !selected.length
                        ? theme.palette.text.secondary
                        : theme.palette.common.black,
                      display: 'flex',
                    }}
                  >
                    <div style={{ stroke: theme.palette.common.white }}>
                      <MedalIcon />
                    </div>
                  </span>
                  {SavedFiltersPageStrings.AssignToSocialVerse}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={styles.Content}>
            <Grid container alignItems="center" justifyContent="space-between">
              {filterTarget && (
                <Box className={styles.Search}>
                  <SearchBar
                    entity={generateEntity[filterTarget as SearchTargetsType]}
                    updateSearch={updateSearchHandler}
                    onSearchChanged={(value) => {
                      trackEvent(VideosEventNames.videosTableSearchTyped, {
                        value,
                      });
                    }}
                    placeholderText={CommonStrings.SearchByName}
                  />
                </Box>
              )}
              <Box className={styles.TotalNumberSection}>
                <span style={{ stroke: theme.palette.common.gray, paddingTop: '6px' }}>
                  <UsersIcon />
                </span>
                <Typography className={styles.TotalNumberSectionText}>
                  {isTopgolf ? `${topGolfVideosTotalItems} Clips` : `${storyTotalItems} Clips`}
                </Typography>
              </Box>
            </Grid>

            {isFilterTargetVideos && isTopgolf && (
              <Grid item className={styles.Table}>
                <div className={classes.TopGolfVideoFilterTableStyles}>
                  <CommonTable
                    selectedRowId={params?.id || ''}
                    content={topGolfTableConfig}
                    page={convertApiPageToFrontEndPage(topGolfVideosPage)}
                    sort={topGolfVideosSort}
                    totalItems={topGolfVideosTotalItems}
                    totalPages={topGolfVideosTotalPages}
                    isLoading={topGolfVideosIsLoading}
                    tablePadding="0"
                    goToPage={onGoToStoryPage}
                    size={topGolfVideosSize}
                    onSizeChange={changeStoryTableSize}
                    noContent={!Boolean(topGolfVideosItems.length)}
                    activeFilter
                    onClickRow={handleRowClick}
                  />
                </div>
              </Grid>
            )}

            {isFilterTargetVideos && !isTopgolf && (
              <Grid item className={styles.Table}>
                <div className={classes.VideoFilterTableStyles}>
                  <CommonTable
                    selectedRowId={params?.id || ''}
                    content={tableConfig}
                    page={convertApiPageToFrontEndPage(storyPage)}
                    sort={storySort}
                    totalItems={storyTotalItems}
                    totalPages={storyTotalPages}
                    isLoading={isLoading}
                    tablePadding="0"
                    goToPage={onGoToStoryPage}
                    size={storySize}
                    onSizeChange={changeStoryTableSize}
                    noContent={!Boolean(storyItems.length)}
                    activeFilter
                    onClickRow={handleRowClick}
                  />
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </main>
      <CreatedFilterModal
        modalIsOpen={open}
        handleClose={handleSaveBtn}
        handleConfirm={(name) => {
          handleConfirm(name);
          // handleGoBack();
        }}
        target={filterTarget as SearchTargetsType}
      />
      <NewDetailsModal
        userId={(story as VideoApiModel)?.userId || ''}
        isOpen={!!params.id}
        videoId={params.id}
        handleClose={close}
        onDeleteVideoClick={() =>
          trackEvent(SocialVerseEventNames.deleteUserVideo, { videoId: storyVideoId })
        }
      />
    </div>
  );
};
