import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface Props {
  noScroll?: boolean;
  disableFullHeight?: boolean;
  withSearchBar?: boolean;
}

export const useStyles = makeStyles<Theme, Props>((theme) => {
  const SEARCH_BAR_HEIGHT = 80;
  const PAGINATION_BAR_HEIGHT = 100;

  const tableHeightWithSearchBar = window.innerHeight - PAGINATION_BAR_HEIGHT - SEARCH_BAR_HEIGHT;
  const tableHeight = window.innerHeight - PAGINATION_BAR_HEIGHT;

  return {
    box: {
      height: (props) =>
        props.disableFullHeight
          ? 'auto'
          : props.withSearchBar
          ? tableHeightWithSearchBar
          : tableHeight,
      maxHeight: (props) =>
        props.disableFullHeight
          ? 'auto'
          : props.withSearchBar
          ? tableHeightWithSearchBar
          : tableHeight,
      overflowY: (props) => (props.noScroll ? 'auto' : 'inherit'),

      '& table': {
        borderSpacing: 0,
        '& tbody tr:first-child td': {
          borderTop: '0 !important',
        },
      },

      '& .MuiTableCell-stickyHeader': {
        borderBottom: '1px solid #e1e5ee',
      },
      '&::-webkit-scrollbar': {
        width: '6px',
        height: '6px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-track': {
        marginTop: '75px',
        marginBottom: (props) => (props.noScroll ? '4px' : '75px'),
        marginLeft: '4px',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: theme.palette.primary.light,
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: theme.palette.primary.main,
      },
    },
    deleteIcon: {
      position: 'absolute',
      zIndex: '100',
      right: '12px',
      top: '-3px',
      cursor: 'pointer',
      display: 'none',
      color: '#ACACAC',
    },
    deleteButton: {
      fontSie: '13px !important',
    },
    errorColor: {
      color: '#FC4741',
      '& > p': {
        color: '#FC4741',
      },
    },
  };
});
