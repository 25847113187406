export enum AccountEventNames {
  viewAgreement = 'view_agreement_click',
  subscriptionUpdate = 'subscription_update_click',
  companyNameUpdate = 'company_name_update_click',
  companyWebsiteUpdate = 'company_website_update_click',
  managePaymentMethod = 'manage_payment_method_click',
  companyDetailsActionBarSave = 'company_details_action_bar_save_click',
  companyDetailsActionBarCancel = 'company_details_action_bar_cancel_click',
  companyNameTyped = 'company_name_typed',
  practiceNameTyped = 'practice_name_typed',
  companyAddressTyped = 'company_address_typed',
  cityTyped = 'city_typed',
  companyWebsiteTyped = 'company_website_typed',
  stateChanged = 'state_changed',
  zipCodeTyped = 'zip_code_typed',
  openAgreementClicked = 'open_agreement_clicked',
  memberSearchTyped = 'member_search_typed',
  buttonAddMemberClicked = 'add_member_clicked',
  memberRowClicked = 'member_row_clicked',
  notificationAlertChangeClicked = 'notification_alert_change_clicked',
  memberEditClicked = 'member_edit_clicked',
  buttonBackClicked = 'button_back_clicked',
  buttonDeleteClicked = 'button_delete_clicked',
  buttonCancelDeleteClicked = 'button_cancel_delete_clicked',
  accountTypeChanged = 'account_type_changed',
  memberNameTyped = 'member_name_typed',
  memberLastNameTyped = 'member_last_name_typed',
  memberEmailTyped = 'member_email_typed',
  memberPhoneTyped = 'member_phone_typed',
  profileButtonCancelClicked = 'profile_button_cancel_clicked',
  profileButtonSaveClicked = 'profile_button_save_clicked',
  profileFirstNameTyped = 'profile_first_name_typed',
  profileLastNameTyped = 'profile_last_name_typed',
  profileCellPhoneTyped = 'profile_cell_phone_typed',
  profileEmailTyped = 'profile_email_typed',
  profileButtonChangePasswordClicked = 'profile_button_change_password_clicked',
  oldPasswordTyped = 'old_password_typed',
  newPasswordTyped = 'new_password_typed',
  confirmPasswordTyped = 'confirm_password_typed',
  savePasswordClicked = 'save_password_clicked',
  cancelPasswordClicked = 'cancel_password_clicked',
  accountDetailsClicked = 'account_details_clicked',
  accountDetailsRouteChanged = 'account_details_route_changed',
  accountMenuOpenClicked = 'account_menu_open_clicked',
  logoutButtonClicked = 'logout_button_clicked',
  profileOpenButtonClicked = 'profile_open_button_clicked',
  memberNotificationEnabled = 'member_notification_enabled',
  memberNotificationDisabled = 'member_notification_disabled',
}
