import { Box, CardMedia, Grid } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { usePlayerControlStyles } from './PlayerControls.style';
import { Spinner } from '../../assets/Spinner';

interface PayerControlProps {
  videoUrl: string;
  isTopgolf: boolean;
  videoId?: string;
  entity?: 'heroes' | 'campaigns';
  preventDefaults?: boolean;
  thumbnailUrl?: string;
}

export const PlayerControls: FC<PayerControlProps> = ({
  videoUrl,
  isTopgolf,
  videoId,
  entity,
  preventDefaults,
  thumbnailUrl,
}) => {
  const classes = usePlayerControlStyles();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const video = refVideo.current;
    if (!video) return;

    const handleLoading = () => {
      setIsLoading(false);
    };

    video.addEventListener('loadeddata', handleLoading);

    return () => video.removeEventListener('loadeddata', handleLoading);
  }, [refVideo]);

  return (
    <Grid>
      {isLoading && (
        <Box className={classes.loaderWrapper}>
          <Box className={classes.spinnerWrapper}>
            <Spinner color="#fff" />
          </Box>
          {thumbnailUrl && <img src={thumbnailUrl} alt="loader" className={classes.loaderImage} />}
        </Box>
      )}
      <CardMedia
        autoPlay
        component="video"
        ref={refVideo}
        src={videoUrl}
        classes={{
          root: classes.mediaRoot,
          media: isTopgolf ? classes.videoMediaQueryTopgolf : classes.videoMediaQueryNotTopgolf,
        }}
        controls
        poster={thumbnailUrl}
      />
    </Grid>
  );
};
