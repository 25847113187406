import { theme } from '../../../../theme';

export const WelcomeLogoModal = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_4876_94197"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="70"
      height="70"
    >
      <circle cx="35" cy="35" r="35" fill="#EEECFC" />
    </mask>
    <g mask="url(#mask0_4876_94197)">
      <circle cx="35" cy="35" r="35" fill="#EEECFC" />
      <path
        d="M0 42.5L69.9999 42.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 57.5L69.9999 57.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 27.5L69.9999 27.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0 12.5L69.9999 12.5"
        stroke="#D9D4FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="mask1_4876_94197"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="16"
        y="15"
        width="40"
        height="40"
      >
        <path
          d="M35.9753 15.0391C41.2536 15.0391 46.3204 17.1141 50.0825 20.8164C51.9522 22.6636 53.4377 24.8627 54.4533 27.2868C55.469 29.7109 55.9946 32.3121 56 34.9404C56.0053 37.5687 55.4902 40.172 54.4845 42.6002C53.4787 45.0284 52.0021 47.2335 50.1399 49.0882C47.1898 52.05 43.3834 54.0097 39.2588 54.6903C35.1342 55.3709 30.8999 54.7379 27.1547 52.8808L18.7559 53.855C17.7945 53.9665 16.9807 53.1518 17.0932 52.1905L18.0756 43.8017C16.2185 40.0564 15.5855 35.8222 16.266 31.6976C16.9466 27.573 18.9063 23.7666 21.8681 20.8164C25.6302 17.1141 30.6969 15.0391 35.9753 15.0391Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_4876_94197)">
        <path
          d="M35.9753 15.0391C41.2536 15.0391 46.3204 17.1141 50.0825 20.8164C51.9522 22.6636 53.4377 24.8627 54.4533 27.2868C55.469 29.7109 55.9946 32.3121 56 34.9404C56.0053 37.5687 55.4902 40.172 54.4845 42.6002C53.4787 45.0284 52.0021 47.2335 50.1399 49.0882C47.1898 52.05 43.3834 54.0097 39.2588 54.6903C35.1342 55.3709 30.8999 54.7379 27.1547 52.8808L18.7559 53.855C17.7945 53.9665 16.9807 53.1518 17.0932 52.1905L18.0756 43.8017C16.2185 40.0564 15.5855 35.8222 16.266 31.6976C16.9466 27.573 18.9063 23.7666 21.8681 20.8164C25.6302 17.1141 30.6969 15.0391 35.9753 15.0391Z"
          fill="#D9D4FF"
        />
        <path
          d="M38.9753 16.0391C44.2536 16.0391 49.3204 18.1141 53.0825 21.8164C54.9522 23.6636 56.4377 25.8627 57.4533 28.2868C58.469 30.7109 58.9946 33.3121 59 35.9404C59.0053 38.5687 58.4902 41.172 57.4845 43.6002C56.4787 46.0284 55.0021 48.2335 53.1399 50.0882C50.1898 53.05 46.3834 55.0097 42.2588 55.6903C38.1342 56.3709 33.8999 55.7379 30.1547 53.8808L21.7559 54.855C20.7945 54.9665 19.9807 54.1518 20.0932 53.1905L21.0756 44.8017C19.2185 41.0564 18.5855 36.8222 19.266 32.6976C19.9466 28.573 21.9063 24.7666 24.8681 21.8164C28.6302 18.1141 33.6969 16.0391 38.9753 16.0391Z"
          fill="white"
        />
      </g>
      <path
        d="M35.9753 15.0391C41.2536 15.0391 46.3204 17.1141 50.0825 20.8164C51.9522 22.6636 53.4377 24.8627 54.4533 27.2868C55.469 29.7109 55.9946 32.3121 56 34.9404C56.0053 37.5687 55.4902 40.172 54.4845 42.6002C53.4787 45.0284 52.0021 47.2335 50.1399 49.0882C47.1898 52.05 43.3834 54.0097 39.2588 54.6903C35.1342 55.3709 30.8999 54.7379 27.1547 52.8808L18.7559 53.855C17.7945 53.9665 16.9807 53.1518 17.0932 52.1905L18.0756 43.8017C16.2185 40.0564 15.5855 35.8222 16.266 31.6976C16.9466 27.573 18.9063 23.7666 21.8681 20.8164C25.6302 17.1141 30.6969 15.0391 35.9753 15.0391Z"
        stroke={theme.palette.primary.main}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.0246 32.8022L32.8384 29.5935C33.1063 28.5373 33.0773 26.8231 32.0246 26.542C30.8649 26.2323 29.7254 27.0064 29.4719 28.1349C29.2695 29.0359 28.9765 30.1222 28.5767 31.2331C28.4708 31.5275 28.2896 31.8925 28.0644 32.3465C27.9491 32.579 27.8222 32.8347 27.6879 33.1162C27.3056 33.9177 26.894 34.872 26.6389 35.9103C26.1233 38.0093 26.2504 40.464 28.4872 42.7009C30.2415 44.4552 32.6878 45.0724 34.4592 44.8969C36.8518 44.6598 38.6536 43.2857 39.9778 41.9615C40.0091 41.9302 40.0352 41.8956 40.0559 41.8588L45.2614 36.6533C45.9916 35.923 45.9916 34.7391 45.2614 34.0089C45.025 33.7724 44.7409 33.6125 44.4401 33.5292L45.4063 32.5631C46.1365 31.8328 46.1365 30.6489 45.4063 29.9187C45.0934 29.6058 44.6971 29.4269 44.289 29.3822C44.4564 28.7579 44.2952 28.0638 43.8053 27.5739C43.1375 26.9061 42.0903 26.849 41.3576 27.4026C41.2759 27.0945 41.1143 26.8032 40.8727 26.5616C40.1424 25.8314 38.9585 25.8314 38.2283 26.5616L32.0246 32.8022Z"
        fill="#D9D4FF"
      />
      <path
        d="M36.4475 32.9441L40.7966 28.595C41.3693 28.0223 41.3693 27.0939 40.7966 26.5212V26.5212C40.224 25.9486 39.2955 25.9486 38.7229 26.5212L31.5454 33.6988"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.5224 35.0166L43.8816 29.6575C44.4542 29.0848 44.4542 28.1564 43.8816 27.5837V27.5837C43.3089 27.0111 42.3805 27.0111 41.8079 27.5837L36.4487 32.9429"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.0164 37.6662L45.5642 32.1184C46.1368 31.5458 46.1368 30.6173 45.5642 30.0447V30.0447C44.9916 29.472 44.0631 29.472 43.4905 30.0447L38.5228 35.0123"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8429 41.992L45.4119 36.4231C45.9845 35.8504 45.9845 34.922 45.4119 34.3494V34.3494C44.8392 33.7767 43.9108 33.7767 43.3381 34.3494L40.7592 36.9283"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.8546 42.0042C38.4932 43.3656 36.7012 44.7146 34.3555 44.9471C32.6263 45.1185 30.2066 44.5101 28.4782 42.7818C24.0837 38.3873 27.9712 33.2401 28.6892 31.2451C29.1181 30.0535 29.4316 28.8906 29.648 27.9273C29.8574 26.995 30.7924 26.3791 31.7156 26.6257V26.6257C32.5582 26.8507 33.0633 27.7113 32.8489 28.5567L31.5526 33.6678"
        stroke="#1A1538"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
