import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useCreateFilterModalStyles = makeStyles((theme: Theme) => ({
  Container: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    // height: '745px',
    outline: 'none',
    backgroundColor: theme.palette.common.white,
    borderRadius: '15px',
    '@media(max-height: 890px)': {
      top: '0',
      transform: 'translate(-50%, 0%)',
    },
  },
  CloseButton: {
    position: 'absolute',
    top: '-15px',
    right: '-15px',
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    width: '50px',
    height: '50px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.1)',
  },
  Title: {
    paddingTop: 22,
    paddingLeft: 32,
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  SubTitle: {
    marginLeft: 10,
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '40px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  TableRow: {
    borderBottom: '1.1px solid #E1E5EE',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  RowNumber: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginLeft: 30,
  },
  ButtonsContainer: {
    marginTop: 20,
    borderBottomLeftRadius: '15px',
    borderBottomRightRadius: '15px',
    // position: 'absolute',
    zIndex: 1205,
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px -10px 34px rgba(23, 25, 78, 0.05)',
    padding: 28,
    width: '100%',
    left: 0,
  },
  Buttons: {
    boxShadow: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    padding: '10px 25px',
    borderRadius: '100px',
  },
}));
