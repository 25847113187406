import React, { useState } from 'react';
import { useUploadFileToStorage } from '../../../../hooks/useUploadFileToStorage';
import { QUICKSTART_BACKGROUND_GALLERY_IMAGES } from '../../../constants/constants';
import {
  Alignment,
  ImageUploaderWithGallery,
  ImageUploaderWithGalleryProps,
} from '../ImageUploaderWithGallery';
import { useBackgroundImage } from '../../../../hooks/webApp/useBackgroundImage';
import { useLocation } from 'react-router-dom';
import { WebsiteRoutes } from '../../../constants/routes';
import { PersonalizationStrings } from '../../../localization/en';

interface BackgroundImageUploaderProps {
  backgroundImage: string | undefined;
  onImageChange?: (image: string | undefined) => void;
  onPreviewChange?: (image: string | undefined) => void;

  imagePreviewWidth?: ImageUploaderWithGalleryProps['imagePreviewWidth'];
  imagePreviewHeight?: ImageUploaderWithGalleryProps['imagePreviewHeight'];
  title?: ImageUploaderWithGalleryProps['title'];
  subtitle?: ImageUploaderWithGalleryProps['subtitle'];
  showEditIcon?: boolean;
  hideButtons?: boolean;
  alignment?: Alignment;
  dndTitle?: string | JSX.Element;
  dndDescription?: string;
  dndIcon?: JSX.Element;
  alignCenter?: boolean;
  withBottomRemoveButton?: boolean;
}

export const BackgroundImageUploader: React.FC<BackgroundImageUploaderProps> = ({
  backgroundImage,
  onImageChange,
  onPreviewChange,
  imagePreviewWidth = '130px',
  imagePreviewHeight = '215px',
  title = '',
  subtitle = 'Portrait images look best when displayed on a phone.',
  showEditIcon,
  alignment,
  hideButtons,
  dndTitle,
  dndDescription,
  dndIcon,
  alignCenter,
  withBottomRemoveButton,
}) => {
  const [galleryImagePreview, setGalleryImagePreview] = useState<string>();

  const { uploadFileToStorage, loading: isFileLoading } = useUploadFileToStorage();
  const backgroundImageConfig = useBackgroundImage();
  const location = useLocation();

  const handleUpload = async (file: File | undefined) => {
    if (!file) return;
    const uploadedFile = await uploadFileToStorage(file);
    const url = uploadedFile?.downloadUrl;
    onImageChange?.(url);
  };

  const handleBackgroundRemove = () => {
    if (!location.pathname.includes(WebsiteRoutes.BrandingStyleTab)) {
      backgroundImageConfig.updateBackgroundImage('');
    }
    onPreviewChange?.(undefined);
    onImageChange?.(undefined);
  };

  return (
    <ImageUploaderWithGallery
      file={galleryImagePreview || backgroundImage}
      onUpload={handleUpload}
      onRemove={handleBackgroundRemove}
      accept={['jpeg', 'png']}
      title={title}
      subtitle={subtitle}
      dndDescription={dndDescription || PersonalizationStrings.RecommendedSize}
      imagePreviewWidth={imagePreviewWidth}
      imagePreviewHeight={imagePreviewHeight}
      loading={isFileLoading}
      images={QUICKSTART_BACKGROUND_GALLERY_IMAGES}
      alignment={alignment || 'center'}
      onGalleryImageSelect={(image) => {
        setGalleryImagePreview(image);
        onPreviewChange?.(image);
      }}
      onGallerySaveClick={(image) => {
        onImageChange?.(image);
        onPreviewChange?.(undefined);
      }}
      onGalleryClose={() => {
        setGalleryImagePreview(undefined);
        onPreviewChange?.(undefined);
      }}
      showEditIcon={showEditIcon}
      hideButtons={hideButtons}
      dndTitle={dndTitle}
      dndIcon={dndIcon}
      alignCenter={alignCenter}
      withBottomRemoveButton={withBottomRemoveButton}
    />
  );
};
