import { FC, useEffect, useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './AdvancedTabContent.styles';
import { ClipsSearchStrings } from '../../../../localization/en';
import { RoundedButton } from '../../../Button/RoundedButton';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  CampaignsFilterAtAttribute,
  CreatedAtFilterAttribute,
  SearchOperationType,
  VideoAttributeName,
  VideoFilterAttribute,
  VideoLengthFilterAtAttribute,
  VideoStatusFilterAtAttribute,
  VideoStatusType,
} from '../../../../../api/models/searchFilterModels';
import {
  removeVideosDateFilter,
  resetTags,
  setActiveVideoFilterAttributes,
} from '../../../../../store/slices/searchFilterSlice';
import { CampaignFilter } from '../../../CampaignFilter/CampaignFilter';
import { DateRange, FilterDatePicker } from '../../../FilterDatePicker/FilterDatePicker';
import { getUTCDateString } from '../../../../../services/utilities';
import { LengthRange, LengthSelect } from '../../../LengthSelect/LengthSelect';
import { StatusFilterSelect } from '../../../StatusFilterSelect/StatusFilterSelect';
import { setLoadMore } from '../../../../../store/slices/videosSlice';
import format from 'date-fns/format';
import { backEndDateFormat } from '../../../../constants/constants';

interface Props {
  onSearch: () => void;
}

export const AdvancedTabContent: FC<Props> = ({ onSearch }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [campaignIds, setCampaignIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);
  const [lengthRange, setLengthRange] = useState<LengthRange[]>([]);
  const [status, setStatus] = useState<VideoStatusType | null>(null);
  const { activeVideoFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  const { items } = useTypedSelector((state) => state.campaigns);

  const handleCampaignsUpdate = (campaignIs: string[]) => {
    setCampaignIds(campaignIs);
  };

  const handleSearch = () => {
    const filterAttributes: VideoFilterAttribute[] = [];

    if (campaignIds.length > 0) {
      const campaignsFilterAttribute: CampaignsFilterAtAttribute = {
        name: VideoAttributeName.CampaignId,
        operation: SearchOperationType.In,
        value: campaignIds,
      };

      filterAttributes.push(campaignsFilterAttribute);
    }

    if (dateRange) {
      const dateFrom = new Date(getUTCDateString(dateRange.from));
      const dateTo = new Date(getUTCDateString(dateRange.to));
      dateTo.setHours(23);
      dateTo.setMinutes(59);

      const fromAttribute: CreatedAtFilterAttribute = {
        name: VideoAttributeName.CreatedAt,
        value: dateFrom.toISOString(),
        operation: SearchOperationType.GreaterThan,
      };

      const toAttribute: CreatedAtFilterAttribute = {
        name: VideoAttributeName.CreatedAt,
        value: dateTo.toISOString(),
        operation: SearchOperationType.LessThan,
      };

      filterAttributes.push(fromAttribute, toAttribute);
    }

    if (lengthRange.length > 0) {
      const lengthAttributes: VideoLengthFilterAtAttribute[] = lengthRange.map((item) => ({
        ...item,
        name: VideoAttributeName.Length,
      }));

      filterAttributes.push(...lengthAttributes);
    }

    if (status) {
      const statusAttribute: VideoStatusFilterAtAttribute = {
        name: VideoAttributeName.Status,
        operation: SearchOperationType.Equals,
        value: status,
      };

      filterAttributes.push(statusAttribute);
    }

    dispatch(resetTags());
    dispatch(setActiveVideoFilterAttributes(filterAttributes));
    onSearch();
    dispatch(setLoadMore(false));
  };

  const fromInitialValue = activeVideoFilterAttributes.find(
    (attribute) =>
      attribute.name === VideoAttributeName.CreatedAt &&
      attribute.operation === SearchOperationType.GreaterThan,
  )?.value as string;

  const toInitialValue = activeVideoFilterAttributes.find(
    (attribute) =>
      attribute.name === VideoAttributeName.CreatedAt &&
      attribute.operation === SearchOperationType.LessThan,
  )?.value as string;

  useEffect(() => {
    if (fromInitialValue && toInitialValue) {
      setDateRange({
        from: format(new Date(fromInitialValue), backEndDateFormat),
        to: format(new Date(toInitialValue), backEndDateFormat),
      });
    }
  }, [fromInitialValue, toInitialValue]);

  const getInitialDate = () => {
    return fromInitialValue && toInitialValue
      ? {
          from: new Date(fromInitialValue),
          to: new Date(toInitialValue),
        }
      : null;
  };

  const initialCampaignItems = useMemo(() => {
    const appliedFilterCampaigns =
      (activeVideoFilterAttributes.find(
        (attribute) => attribute.name === VideoAttributeName.CampaignId,
      )?.value as string[]) || [];

    return items.map(({ id, name, endedAt, isPreferred }) => ({
      name: name,
      id: id,
      selected: appliedFilterCampaigns.includes(id),
      isActive: !endedAt,
      isPrimary: isPreferred,
      selectedIndex: 0,
    }));
  }, [activeVideoFilterAttributes, items]);

  return (
    <Box className={classes.container}>
      <Typography className={classes.selectTitle}>{ClipsSearchStrings.Campaign}</Typography>
      <Box className={classes.selectWrapper}>
        <CampaignFilter
          onCampaignsUpdate={handleCampaignsUpdate}
          initialCampaignItems={initialCampaignItems}
        />
      </Box>
      <Typography className={classes.selectTitle}>{ClipsSearchStrings.CreationDate}</Typography>
      <Box className={classes.selectWrapper}>
        <FilterDatePicker
          updateRange={(range) => {
            setDateRange(range);
          }}
          width="100%"
          initialValue={getInitialDate()}
          onClear={() => {
            dispatch(removeVideosDateFilter());
            setDateRange(null);
          }}
          shouldClearLabel={true}
        />
      </Box>
      <Typography className={classes.selectTitle}>{ClipsSearchStrings.Length}</Typography>
      <Box className={classes.selectWrapper}>
        <LengthSelect
          onChange={(range) => {
            setLengthRange(range);
          }}
          lengthRange={lengthRange}
        />
      </Box>
      <Typography className={classes.selectTitle}>{ClipsSearchStrings.Status}</Typography>
      <Box className={classes.selectWrapper}>
        <StatusFilterSelect
          onChange={(status) => {
            setStatus(status);
          }}
        />
      </Box>

      <Box className={classes.searchButtonWrapper}>
        <RoundedButton
          title={ClipsSearchStrings.Search}
          onClick={handleSearch}
          borderRadius="8px"
          fontSize="16px"
          fontWeight="600"
        />
      </Box>
    </Box>
  );
};
