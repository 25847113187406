import { SearchTargets } from '../../api/models/searchFilter';
import { WebsiteRoutes } from '../../common/constants/routes';

export const detectFilterTarget = (prevLocation: string) => {
  let target;
  if (prevLocation === WebsiteRoutes.ContentAll) target = SearchTargets.VIDEOS;
  if (prevLocation === WebsiteRoutes.Heroes) target = SearchTargets.USERS;
  if (prevLocation === WebsiteRoutes.RewardsLeaderboard) target = SearchTargets.INCENTIVE_CAMPAIGNS;
  return target;
};
