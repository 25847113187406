import { Badge, Box } from '@mui/material';
import { BellIcon } from '../../assets/newDesign/SocialVerse/BellIcon';

export const BadgeCustomBell = () => {
  return (
    <Box>
      <Badge
        badgeContent={''}
        variant="dot"
        color="primary"
        sx={{
          '& .MuiBadge-badge': {
            right: 6,
            top: 6,
            width: 12,
            height: 12,
            borderRadius: '50%',
          },
        }}
      >
        <BellIcon />
      </Badge>
    </Box>
  );
};
