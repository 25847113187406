import { FC } from 'react';
import { Select, MenuItem, SelectProps, Typography } from '@mui/material';

export interface SelectOption {
  value: string | number;
  content: string | number;
}

interface SelectTextFieldProps extends SelectProps {
  options: Array<SelectOption>;
  value: SelectOption['value'] | undefined;
  menuItemClassName?: string;
}

export const SelectTextField: FC<SelectTextFieldProps> = ({
  options,
  value,
  placeholder,
  name,
  onChange,
  menuItemClassName,
  ...rest
}) => {
  return (
    <Select
      name={name}
      value={value ?? ''}
      onChange={onChange}
      placeholder={placeholder}
      displayEmpty
      renderValue={(value) => {
        if (!value) {
          return (
            <Typography fontSize="12px" color="#8B89A0" py="4px">
              {placeholder}
            </Typography>
          );
        }

        return <>{value}</>;
      }}
      {...rest}
    >
      {options.map((option) => {
        return (
          <MenuItem value={option.value} key={option.value} className={menuItemClassName}>
            {option.content}
          </MenuItem>
        );
      })}
    </Select>
  );
};
