import { useAppDispatch } from '../../store';
import { httpClient } from '../httpClient/httpClient';
import {
  reset as resetDistributorsSlice,
  reset as resetDistributors,
} from '../../store/slices/distributorsSlice';
import {
  reset as resetAffiliateSlice,
  reset as resetAffiliates,
} from '../../store/slices/affiliatesSlice';
import { reset as resetHeroes } from '../../store/slices/heroesSlice';
import { reset as resetVenue } from '../../store/slices/venueSlice';
import { reset as resetUi } from '../../store/slices/uiSlice';
import { reset as resetStoryVideos } from '../../store/slices/videosSlice';
import { reset as resetMe } from '../../store/slices/meSlice';
import { reset as resetCampaignsStoryVideos } from '../../store/slices/campaignsStoryVideosSlice';
import { reset as resetCampaigns } from '../../store/slices/campaignsSlice';
import { reset as resetAnalytics } from '../../store/slices/analyticsSlice';
import { reset as resetVenueCreation } from '../../store/slices/venueCreationSlice';
import { reset as resetOrganizationVenuSlice } from '../../store/slices/organizationVenueSlice';
import { reset as resetSmsInviteSlice } from '../../store/slices/smsInvitesSlice';
import { reset as resetRewardFulfillmentSlice } from '../../store/slices/rewardFulfillmentSlice';
import { reset as resetPricingPlansSlice } from '../../store/slices/pricingPlansSlice';
import { resetActiveVideoFilterAttributes } from '../../store/slices/searchFilterSlice';

export function useLogout() {
  const dispatch = useAppDispatch();

  return () => {
    httpClient.logout();
    dispatch(resetDistributors());
    dispatch(resetAffiliates());
    dispatch(resetHeroes());
    dispatch(resetVenue());
    dispatch(resetUi());
    dispatch(resetStoryVideos());
    dispatch(resetMe());
    dispatch(resetCampaignsStoryVideos());
    dispatch(resetCampaigns());
    dispatch(resetAnalytics());
    dispatch(resetVenueCreation());
    dispatch(resetOrganizationVenuSlice());
    dispatch(resetSmsInviteSlice());
    dispatch(resetRewardFulfillmentSlice());
    dispatch(resetAffiliateSlice());
    dispatch(resetDistributorsSlice());
    dispatch(resetPricingPlansSlice());
    dispatch(resetActiveVideoFilterAttributes())
    localStorage.setItem('selectedVenue', '');
    localStorage.setItem('selectedDistributor', '');
  };
}
