import { FC, useEffect, useState } from 'react';
import { Box, Container, CssBaseline, TextField, Grid } from '@mui/material';
import { Color, ColorPicker, createColor } from 'material-ui-color';

interface ColorPickerProps {
  color: Color;
  setColor: (color: Color) => void;
  label: string;
}

const ColorPickerComponent: FC<ColorPickerProps> = ({ color, setColor, label }) => {
  const [hexValue, setHexValue] = useState('#' + color.hex);
  const [hexError, setHexError] = useState(false);

  const handleChange = (value: Color) => {
    setColor(value);
  };

  useEffect(() => {
    setHexValue('#' + color.hex);
  }, [color]);

  const handleTextChange = (e: any) => {
    let value = e.currentTarget.value;
    setHexValue(e.currentTarget.value);
    if (/^#[0-9A-F]{6}$/i.test(value)) {
      setHexError(false);
      setColor(createColor(value.includes('#') ? value : '#' + value));
    } else {
      setHexError(true);
    }
  };

  return (
    <>
      <CssBaseline />
      <Container>
        <Box my={3}>
          <Grid container spacing={1} alignItems="center">
            <Grid item style={{ transform: 'scale(1.5)', marginRight: 8 }}>
              <ColorPicker value={color} onChange={handleChange} hideTextfield />
            </Grid>
            <Grid item>
              <TextField
                label={label}
                variant="outlined"
                margin="dense"
                value={hexValue}
                onChange={handleTextChange}
                error={hexError}
                helperText={hexError ? 'Invalid Hex' : ''}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};
export default ColorPickerComponent;
