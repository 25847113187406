import { Box } from '@mui/material';
import { FC } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { VideoRecordButton } from './VideoRecordButton/VideoRecordButton';
import { RecordingPreview } from './RecordingPreview';

interface RecordVideoComponentProps {
  recordedVideo?: string | null;
  onVideoRecord: (blob: string | null) => void;
}

export const RecordVideoComponent: FC<RecordVideoComponentProps> = ({
  recordedVideo,
  onVideoRecord,
}) => {
  const { status, startRecording, stopRecording, previewStream } = useReactMediaRecorder({
    video: true,
    askPermissionOnMount: true,
    onStop: (blobUrl) => {
      onVideoRecord(blobUrl);
    },
  });

  return (
    <Box>
      <RecordingPreview status={status} stream={previewStream} />

      <Box
        sx={{
          position: 'absolute',
          bottom: '42px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <VideoRecordButton
          handleStart={startRecording}
          handleStop={stopRecording}
          isActive={status === 'recording'}
        />
      </Box>
    </Box>
  );
};
