import { FC, Fragment } from 'react';
import { Grid } from '@mui/material';

import { TextLabel, TextValue, UsersBox, BlockTitle } from './components.style';
import { SectionTitle } from '../Steps.style';
import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';
import { useTypedSelector } from '../../../../store';
import { ParticipantsDTO } from '../../../../api/models/venueCreation';

interface LabelValuePair {
  label: string;
  value: string;
}

export const ParticipantsBlock: FC = () => {
  let Labels: ParticipantsDTO = {
    participantId: VenueWizardPreviewStepStrings.LabelParticipantId,
    participantName: VenueWizardPreviewStepStrings.LabelParticipantName,
    participantPhone: VenueWizardPreviewStepStrings.LabelParticipantPhoneNumber,
    participantEmail: VenueWizardPreviewStepStrings.LabelParticipantEmail,
    participantDonorPageUrl: VenueWizardPreviewStepStrings.LabelParticipantLandingUrl,
  };
  type ParticipantsKeys =
    | 'participantId'
    | 'participantName'
    | 'participantPhone'
    | 'participantEmail'
    | 'participantDonorPageUrl';

  const { participants } = useTypedSelector((state) => state.venueCreation.program_payload);
  const renderKeyValuePairs = (labelValuePair: LabelValuePair[]) => {
    return labelValuePair.map((pair, index) => {
      return (
        <Fragment key={index}>
          <Grid item xs={6}>
            <TextLabel>{pair.label}</TextLabel>
          </Grid>
          <Grid item xs={6}>
            <TextValue>{pair.value}</TextValue>
          </Grid>
        </Fragment>
      );
    });
  };
  const renderItems = () => {
    return participants.map((item, index) => {
      let labelValuePair = (Object.keys(item as ParticipantsDTO) as Array<ParticipantsKeys>).map(
        (key) => {
          return {
            label: Labels[key],
            value: item[key],
          };
        },
      );
      return (
        <UsersBox key={index}>
          <Grid container>
            <Grid item xs={12}>
              <BlockTitle>
                {VenueWizardPreviewStepStrings.ParticipantId.replace(':id', (index + 1).toString())}
              </BlockTitle>
            </Grid>
            {renderKeyValuePairs(labelValuePair)}
          </Grid>
        </UsersBox>
      );
    });
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <SectionTitle>{VenueWizardPreviewStepStrings.ParticipantsTitle}</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        {renderItems()}
      </Grid>
    </Grid>
  );
};
