import React, { useEffect, useMemo, useRef } from 'react';

import { Picker } from 'emoji-mart';
import { Box } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { useInitEmoji } from './useInitEmoji';

// NOTE: something wrong with the native emoji-mart prop typing
// Check this link to find all possible props:
// https://github.com/missive/emoji-mart/blob/main/packages/emoji-mart/src/components/Picker/PickerProps.js

export interface EmojiValue {
  aliases: string[];
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
}

interface NativePickerProps {
  previewPosition?: 'top' | 'bottom' | 'none';
  searchPosition?: 'sticky' | 'static' | 'none';
  skinTonePosition?: 'preview' | 'search' | 'none';
  onClickOutside?: () => void;
  onEmojiSelect?: (emoji: EmojiValue) => void;
}

interface EmojiPickerProps extends NativePickerProps {
  show?: boolean;
  containerProps?: BoxProps;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({
  show = true,
  containerProps,
  previewPosition = 'bottom',
  searchPosition = 'sticky',
  ...rest
}) => {
  const { initializeEmoji } = useInitEmoji();

  useEffect(() => {
    initializeEmoji();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pickerProps = useMemo(() => {
    return { ...rest, previewPosition, searchPosition };
  }, [rest, previewPosition, searchPosition]);

  const ref = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    new Picker({
      // @ts-ignore -> some typing issues in library
      ref,
      ...pickerProps,
    });
  }, [pickerProps]);

  return (
    <Box
      style={{ visibility: show ? 'visible' : 'hidden', zIndex: 1 }}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
      }}
      {...containerProps}
    />
  );
};
