import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchTagsStrings } from '../../../localization/en';
import { useStyles } from '../AccountsSearchTags.styles';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  AccountAttributeName,
  AccountFilterAttribute,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import { RangeFilter } from '../../../../store/storeModels';
import {
  PredefinedFilters,
  createLabelFromDateRange,
  dateRangeFilterDefaultValues,
} from '../../FilterDatePicker/FilterDatePicker.helper';
import {
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  endOfYesterday,
  format,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  subMonths,
  subWeeks,
} from 'date-fns';
import { getUTCDateString } from '../../../../services/utilities';
import {
  removeAccountLastLoginDateFilter,
  setActiveAccountFilterAttributes,
  updateAccountLastLoginDateFilter,
} from '../../../../store/slices/searchFilterSlice';
import { DEFAULT_START_DATE, backEndDateFormat } from '../../../constants/constants';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import MenuContainer from '../../MenuContainer/MenuContainer';
import DateRangeCalendar from '../../DateRangeCalendar/DateRangeCalendar';
import { resetPage } from '../../../../store/slices/organizationVenueSlice';

const LastMemberLoginAtTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const now = new Date();
  const { activeAccountFilterAttributes } = useTypedSelector((state) => state.searchFilter);
  // const {
  //   venue: { createdAt },
  // } = useTypedSelector((state) => state.venue);

  const dateFilterFromIdx = activeAccountFilterAttributes.findIndex(
    (item) =>
      item.name === AccountAttributeName.memberLastLoginAt &&
      item.operation === SearchOperationType.GreaterThan,
  );
  const dateFilterToIdx = activeAccountFilterAttributes.findIndex(
    (item) =>
      item.name === AccountAttributeName.memberLastLoginAt &&
      item.operation === SearchOperationType.LessThan,
  );

  const [dateRange, setDateRange] = useState<RangeFilter>(dateRangeFilterDefaultValues.dateRange);
  const [chosenPredefinedFilter, setChosenPredefinedFilter] = useState<PredefinedFilters | null>(
    null,
  );

  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const isDateMenuOpen = Boolean(dateAnchorEl);

  const handleDateMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setDateAnchorEl(event.currentTarget);
  };

  const handleDateMenuClose = () => {
    setDateAnchorEl(null);
  };

  useEffect(() => {
    const defaultDateRange: RangeFilter =
      dateFilterFromIdx !== -1
        ? {
            from: new Date(activeAccountFilterAttributes[dateFilterFromIdx].value as string),
            to: new Date(activeAccountFilterAttributes[dateFilterToIdx].value as string),
          }
        : dateRangeFilterDefaultValues.dateRange;

    setDateRange(defaultDateRange);
  }, [activeAccountFilterAttributes, dateFilterFromIdx, dateFilterToIdx]);

  const setDateValue = (value: Array<Date | null | undefined>) => {
    setChosenPredefinedFilter(PredefinedFilters.Custom);
    setDateRange({ from: value[0], to: value[1] || value[0] });
  };

  const choosePredefinedFilter = (value: PredefinedFilters) => {
    setChosenPredefinedFilter(value);
    switch (value) {
      case PredefinedFilters.SinceBeginning: {
        setDateRange({});
        break;
      }
      case PredefinedFilters.Today: {
        const startOfToday = startOfDay(now);
        const endOfToday = endOfDay(now);

        setDateRange({
          from: startOfToday,
          to: endOfToday,
          label: createLabelFromDateRange(startOfToday, endOfToday),
        });
        break;
      }
      case PredefinedFilters.Yesterday: {
        const startOfDayBeforeToday = startOfYesterday();
        const endOfDayBeforeToday = endOfYesterday();

        setDateRange({
          from: startOfDayBeforeToday,
          to: endOfDayBeforeToday,
          label: createLabelFromDateRange(startOfDayBeforeToday, endOfDayBeforeToday),
        });
        break;
      }
      case PredefinedFilters.LastWeek: {
        const endOfLastWeek = endOfWeek(subWeeks(now, 1));
        const startOfLastWeek = startOfWeek(endOfLastWeek);
        setDateRange({
          from: startOfLastWeek,
          to: endOfLastWeek,
          label: createLabelFromDateRange(startOfLastWeek, endOfLastWeek),
        });
        break;
      }
      case PredefinedFilters.ThisMonth: {
        const endOfThisMonth = endOfMonth(now);
        const startOfThisMonth = startOfMonth(endOfThisMonth);
        setDateRange({
          from: startOfThisMonth,
          to: endOfThisMonth,
          label: createLabelFromDateRange(startOfThisMonth, endOfThisMonth),
        });
        break;
      }
      case PredefinedFilters.LastMonth: {
        const endOfLastMonth = endOfMonth(subMonths(now, 1));
        const startOfLastMonth = startOfMonth(endOfLastMonth);
        setDateRange({
          from: startOfLastMonth,
          to: endOfLastMonth,
          label: createLabelFromDateRange(startOfLastMonth, endOfLastMonth),
        });
        break;
      }
      case PredefinedFilters.LastThreeMonths: {
        const endOfTodayDay = endOfToday();
        const startOfThreeMonths = subMonths(now, 2);
        setDateRange({
          from: startOfThreeMonths,
          to: endOfTodayDay,
          label: createLabelFromDateRange(startOfThreeMonths, endOfTodayDay),
        });
        break;
      }
      case PredefinedFilters.LastSixMonths: {
        const endOfTodayDay = endOfToday();
        const startOfSixMonths = subMonths(now, 5);
        setDateRange({
          from: startOfSixMonths,
          to: endOfTodayDay,
          label: createLabelFromDateRange(startOfSixMonths, endOfTodayDay),
        });
        break;
      }
    }
  };

  const applyRangeFilterChanges = () => {
    const timezoneOffset = new Date().getTimezoneOffset() / 60;
    const dateFrom = dateRange.from
      ? new Date(getUTCDateString(dateRange.from.toUTCString()))
      : new Date(DEFAULT_START_DATE);
    const dateTo = dateRange.to ? new Date(getUTCDateString(dateRange.to.toUTCString())) : now;

    dateFrom.setHours(dateFrom.getHours() + timezoneOffset);
    dateTo.setHours(dateTo.getHours() + timezoneOffset + 24);

    if (dateFilterFromIdx === -1) {
      const newFilter = [
        ...activeAccountFilterAttributes,
        {
          name: AccountAttributeName.memberLastLoginAt,
          operation: SearchOperationType.GreaterThan,
          value: dateFrom.toISOString(),
        },
        {
          name: AccountAttributeName.memberLastLoginAt,
          operation: SearchOperationType.LessThan,
          value: dateTo.toISOString(),
        },
      ] as AccountFilterAttribute[];

      dispatch(setActiveAccountFilterAttributes(newFilter));
    } else {
      const newFilter = [dateFrom.toISOString(), dateTo.toISOString()];

      dispatch(updateAccountLastLoginDateFilter(newFilter));
    }

    handleDateMenuClose();
    dispatch(resetPage());
  };

  const handleRemoveDateFilter = () => {
    setChosenPredefinedFilter(null);
    setDateRange(dateRangeFilterDefaultValues.dateRange);
    dispatch(removeAccountLastLoginDateFilter());
    dispatch(resetPage());
  };

  return (
    <>
      <Box
        className={`${styles.tag} ${dateFilterFromIdx !== -1 && styles.active}`}
        onClick={handleDateMenuOpen}
      >
        {SearchTagsStrings.Login}:{' '}
        {dateFilterFromIdx !== -1 ? (
          chosenPredefinedFilter === PredefinedFilters.SinceBeginning ? (
            `${format(new Date(DEFAULT_START_DATE), backEndDateFormat)} - ${format(
              now,
              backEndDateFormat,
            )}`
          ) : (
            dateRange.from &&
            dateRange.to &&
            `${format(dateRange.from, backEndDateFormat)} - ${format(
              dateRange.to,
              backEndDateFormat,
            )}`
          )
        ) : (
          <></>
        )}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (dateFilterFromIdx !== -1) {
              e.stopPropagation();
              handleRemoveDateFilter();
            }
          }}
        >
          {dateFilterFromIdx === -1 ? <SmallArrowIcon /> : <PurpleCloseIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={dateAnchorEl}
        isOpen={isDateMenuOpen}
        onClose={handleDateMenuClose}
      >
        <DateRangeCalendar
          chosenPredefinedFilter={chosenPredefinedFilter}
          dateRange={dateRange}
          onApplyRangeFilterChanges={applyRangeFilterChanges}
          onChoosePredefinedFilter={choosePredefinedFilter}
          onSetDateValue={setDateValue}
        />
      </MenuContainer>
    </>
  );
};

export default LastMemberLoginAtTag;
