import { FC, MouseEvent, useState } from 'react';
import { Route, Switch, useHistory, useParams, useRouteMatch } from 'react-router-dom';

import { useAppDispatch, useTypedSelector } from '../../store';
/* import { renderRoutes } from './Rewards.helper'; */
import { IncentiveAd } from './IncentiveAd/IncentiveAd';
import { ClientTypes } from '../../api/models/common';
import { useSegmentPageCall } from '../../hooks/useSegment';
import { WebsiteRoutes } from '../../common/constants/routes';
import { CampaignsTab } from './campaignsTab/CampaignsTab';
import { RedeemRequests } from './redeemRequests/RedeemRequests';
import { SalesDetailsModal } from '../../common/components/NewDetailsModal/SalesDetailsModal';
import { RewardsContainer } from './Rewards.style';
import { setActiveFilter } from '../../store/slices/searchFiltersSlice';
import { TopbarSelectedFilter } from '../../common/components/TopbarSelectedFilter';
import { RewardsPageStrings } from '../../common/localization/en';
import { getSubnavigationItems } from './Rewards.helper';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';

export const Rewards: FC = () => {
  useSegmentPageCall();
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const match = useRouteMatch();
  const { activeCampaign } = useTypedSelector((state) => state.campaigns);
  const {
    venue: { clientType },
  } = useTypedSelector((state) => state.venue);
  const close = () => {
    history.push(match.path.replace('/:id', ''));
  };
  const dispatch = useAppDispatch();
  const [id, setId] = useState('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClearFilter = () => {
    dispatch(setActiveFilter(null));
  };

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setId(id !== event.currentTarget?.id ? event.currentTarget.id : '');
  };

  return (
    <div>
      {clientType !== ClientTypes.TOPGOLF ? (
        <Switch>
          <>
            <SalesDetailsModal isOpen={!!params.id} userId={params.id} handleClose={close} />
            <TopbarSelectedFilter
              anchorEl={anchorEl}
              handleSetAnchorEl={() => setAnchorEl(null)}
              handleSetId={() => setId('')}
              handleClick={handleClick}
              handleClearFilter={handleClearFilter}
              sectionTitle={RewardsPageStrings.RewardCampaigns}
              multiline={false}
            />
            <WebappSubnavigation items={getSubnavigationItems(!activeCampaign)} />
            <RewardsContainer>
              <Route exact path={WebsiteRoutes.RewardsCampaigns}>
                <CampaignsTab />
              </Route>
              <Route
                exact
                path={[WebsiteRoutes.RewardsLeaderboard, WebsiteRoutes.RewardsLeaderboardDetails]}
              >
                <RedeemRequests />
              </Route>
            </RewardsContainer>
          </>
        </Switch>
      ) : (
        <IncentiveAd />
      )}
    </div>
  );
};
