import { useAppDispatch } from '../../store';
import { setProgress } from '../../store/slices/uploadVideoSlice';

export const useOnUploadProgress = ({ videoUpload }: { videoUpload?: boolean }) => {
  const dispatch = useAppDispatch();

  const onUploadProgress = (event: ProgressEvent) => {
    let percentCompleted = Math.round((event.loaded * 100) / event.total);

    dispatch(setProgress({ value: percentCompleted, videoUpload }));
  };

  return onUploadProgress;
};
