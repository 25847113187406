export const UploadCompleteIcon = () => {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.17 0H2.01C0.91 0 0.0100002 0.89 0.0100002 2L0 18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6.83C16 6.3 15.79 5.79 15.41 5.42L10.58 0.59C10.21 0.21 9.7 0 9.17 0ZM9 6V1.5L14.5 7H10C9.45 7 9 6.55 9 6ZM10 12L11.27 11.33C11.6 11.15 12 11.39 12 11.77V14.23C12 14.61 11.6 14.85 11.27 14.67L10 14V15C10 15.55 9.55 16 9 16H5C4.45 16 4 15.55 4 15V11C4 10.45 4.45 10 5 10H9C9.55 10 10 10.45 10 11V12Z"
          fill="#67CE67"
        />
      </svg>
    </>
  );
};
