import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { useStyles } from '../../../CampaignDetails/MainContent/Creators/NoCreators.styles';
import { NoCampaignVisitorsStrings } from '../../../../../common/localization/en';
import { Link, useParams } from 'react-router-dom';
import { WebsiteRoutes } from '../../../../../common/constants/routes';
import { getApiUrlForId } from '../../../../../api/endpoints';
import { isUWMClient } from '../../../../../utils/isUWMClient';

const NoVisitors = () => {
  const classes = useStyles();
  const isUwmClient = isUWMClient();
  const { id } = useParams<{ id: string }>();

  return (
    <Box className={classes.container}>
      <Box
        className={classes.titleWrapper}
        style={{ background: isUwmClient ? 'rgb(195, 216, 229)' : '#EBE9FF' }}
      >
        <Typography className={classes.title}>{NoCampaignVisitorsStrings.Title}</Typography>
        <Typography className={classes.subTitle}>{NoCampaignVisitorsStrings.SubTitle}</Typography>
      </Box>
      <Divider className={classes.divider} />
      <Typography className={classes.infoTitle}>{NoCampaignVisitorsStrings.InfoTitle}</Typography>
      <Typography className={classes.info}>{NoCampaignVisitorsStrings.Info}</Typography>
      <Link
        to={getApiUrlForId(WebsiteRoutes.CampaignDetailsEssentials, id)}
        className={classes.addCustomerButton}
      >
        {NoCampaignVisitorsStrings.AddCustomers}
      </Link>
    </Box>
  );
};

export default NoVisitors;
