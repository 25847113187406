import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, FormControlLabel, useTheme } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
/* import Checkbox from '@mui/material/Checkbox'; */
import Typography from '@mui/material/Typography';
import { BoxProps } from '@mui/system';
import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IEventTracker } from '../../../../hooks/useTrackEvent';
import { VisitedRoutes } from '../../../../pages/NewCampaign/NewCampaign.helper';
import { HalfFilledCircle } from '../../../assets/newDesign/HalfFilledCircle';
import { MinusCircledIcon } from '../../../assets/newDesign/MinusCircledIcon';
import { CheckIconGreen } from '../../../assets/newDesign/SocialVerse/CheckIconGreen';
import { NewCampaignEvents } from '../../../constants/events/newCampaignEvents';
import { AccordionItem } from '../CustomizationAccordion';
import { useStyles } from './CheckboxAccordion.styles';
import { useTypedSelector } from '../../../../store';
import { BlockedIcon } from '../../../assets/newDesign/SocialVerse/BlockedIcon';
import { NewCampaignSubroutes } from '../../../constants/routes';

interface CheckboxAccordionProps extends BoxProps {
  accordionItems: AccordionItem[];
  baseRoute: string;
  setActiveTabIndex?: (index: number) => void;
  handleTrackEvent: IEventTracker;
  isEditMode?: boolean;
  setVisitedAccordions: (acc: string) => void;
  visitedAccordions: VisitedRoutes;
}

const CheckboxAccordion: FC<CheckboxAccordionProps> = ({
  accordionItems,
  padding,
  baseRoute,
  setActiveTabIndex,
  handleTrackEvent,
  isEditMode,
  setVisitedAccordions,
  visitedAccordions,
  ...boxProps
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const history = useHistory();
  const currentRoute = useLocation().pathname;

  const activeTabIndex = accordionItems.findIndex((item) => currentRoute.endsWith(item.subroute));

  const {
    howToRewardCreators: { rewardName },
    isNullReward,
  } = useTypedSelector((state) => state.createCampaign);

  useEffect(() => {
    setActiveTabIndex?.(activeTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIndex]);

  return (
    <Box className={classes.container} {...boxProps}>
      {accordionItems.map((item, index) => {
        const isActiveStep = activeTabIndex === index;

        const isAcknowledgeSMS = item.subroute === NewCampaignSubroutes.AcknowledgementSms;

        const isVisited = visitedAccordions[item.subroute].visited;
        const isRevisited = visitedAccordions[item.subroute].revisited;
        const isValid = visitedAccordions[item.subroute].valid;

        const getIcon = () => {
          if (isVisited) {
            if (isValid) return <CheckIconGreen />;

            if (isAcknowledgeSMS && isActiveStep && !isRevisited) {
              return <HalfFilledCircle color={theme.palette.primary.main} />;
            }

            return <HalfFilledCircle />;
          }
          return !isNullReward && !rewardName ? <BlockedIcon /> : <MinusCircledIcon />;
        };

        return (
          <Accordion disableGutters classes={{ ...classes }} expanded={isActiveStep}>
            <AccordionSummary
              classes={{ content: classes.content }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id={`create_campaign_step_${index + 1}`}
              onClick={() => {
                if (!isNullReward && !rewardName) return;

                if (!isActiveStep) {
                  history.push(`${baseRoute}/${item.subroute}`);
                  handleTrackEvent(
                    NewCampaignEvents.NewRewardCampaignAccordionClick.replace(
                      'number',
                      `${index + 1}`,
                    ),
                  );
                } else {
                  history.push(`${baseRoute}`);
                }
                setVisitedAccordions(item.subroute);
              }}
            >
              <FormControlLabel
                control={
                  <Box
                    className={`${classes.icon} ${
                      //visited and invalid
                      isVisited && !isValid && classes.iconSmall
                    }`}
                  >
                    {getIcon()}
                  </Box>
                }
                label={
                  <Typography
                    className={classes.labelText}
                    style={{
                      color: isActiveStep ? theme.palette.primary.main : '#1A1538',
                    }}
                  >
                    {item.title}
                  </Typography>
                }
              />
            </AccordionSummary>
            <AccordionDetails>
              <Box>{item.sectionContent}</Box>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default CheckboxAccordion;
