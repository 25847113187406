import * as React from 'react';
import { SVGProps, memo } from 'react';

const Icon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width={45}
    height={57}
    viewBox="0 0 45 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.89 1.5h31v-1h-31v1ZM43.39 7v49h1V7h-1Zm-42 49V7h-1v49h1Zm36.5-54.5a5.5 5.5 0 0 1 5.5 5.5h1a6.5 6.5 0 0 0-6.5-6.5v1Zm-31-1A6.5 6.5 0 0 0 .39 7h1a5.5 5.5 0 0 1 5.5-5.5v-1ZM20.336 4.277a.5.5 0 0 0 0 1v-1Zm8 1a.5.5 0 1 0 0-1v1Zm-8 0h8v-1h-8v1Z"
      fill="currentColor"
    />
    <circle
      cx={17.191}
      cy={4.777}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
    <path
      opacity={0.1}
      d="M37.667 8.334H8a3 3 0 0 0-3 3V56h35.667V11.334a3 3 0 0 0-3-3Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <circle opacity={0.25} cx={22.5} cy={18.5} r={4} fill="currentColor" stroke="currentColor" />
    <rect
      opacity={0.25}
      x={10.5}
      y={43.359}
      width={25}
      height={3}
      rx={1.5}
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      opacity={0.25}
      x={10.5}
      y={37.359}
      width={25}
      height={3}
      rx={1.5}
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      opacity={0.25}
      x={10.5}
      y={31.359}
      width={25}
      height={3}
      rx={1.5}
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M7.555 8.834H37.22v-1H7.555v1Zm32.166 2.5V56h1V11.334h-1ZM5.055 56V11.334h-1V56h1ZM37.22 8.834a2.5 2.5 0 0 1 2.5 2.5h1a3.5 3.5 0 0 0-3.5-3.5v1Zm-29.666-1a3.5 3.5 0 0 0-3.5 3.5h1a2.5 2.5 0 0 1 2.5-2.5v-1Z"
      fill="currentColor"
    />
  </svg>
);

export const SignUpFieldsIcon = memo(Icon);
