import { Box, Typography, useTheme } from '@mui/material';
import { DistributorModel } from '../../../api/models/distributors';
import {
  SelectableTile,
  SelectableTileProps,
} from '../../../common/components/SelectableTile/SelectableTile';

interface DistributorTileProps extends SelectableTileProps {
  name: DistributorModel['name'];
  logoUrl: DistributorModel['logoUrl'];
}

export const DistributorTile: React.FC<DistributorTileProps> = ({
  name,
  logoUrl,
  ...selectableTileProps
}) => {
  const theme = useTheme();

  return (
    <SelectableTile width="200px" height="193px" alignItems="center" {...selectableTileProps}>
      <Box display="flex" flexDirection="column" gap="24px" alignItems="center">
        <Typography fontWeight="700" fontSize="20px" color={theme.palette.common.deepDark}>
          {name.toUpperCase()}
        </Typography>
        <img
          src={logoUrl}
          alt={`${name}-logo`}
          style={{
            height: '80px',
            objectFit: 'contain',
          }}
        />
      </Box>
    </SelectableTile>
  );
};
