import { FC, useRef, useState } from 'react';
import { Box, IconButton, Theme } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { makeStyles } from '@mui/styles';
import { topGolfEvents } from '../../../../../constants/events/topGolfEvents';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';

interface VideoDownloadCardProps {
  url: string;
  videoId: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.common.white,
    fontSize: 40,
  },
  video: {
    borderRadius: 6,
    aspectRatio: '5 / 5',
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tgvideo: {
    display: 'block',
    objectFit: 'contain',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    border: 'none',
    borderRadius: 'inherit',
  },
  videoWrapper: {
    position: 'relative',
    borderRadius: '16px',
    border: 'none',
    overflow: 'hidden',
    width: '100%',
    paddingTop: 'calc((27 / 32) * 100%)',
    backgroundColor: 'black',
  },
}));

export const TopgolfVideoDownloadCard: FC<VideoDownloadCardProps> = ({ url, videoId }) => {
  const classes = useStyles();
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { trackEvent } = useTrackEvent();

  const playPauseSwitch = (e: any) => {
    e.stopPropagation();

    if (!refVideo || !refVideo.current) return;
    const videoElement = refVideo.current;

    if (videoElement.paused) {
      videoElement.play().then(() => setIsPlaying(true));
      trackEvent(topGolfEvents.top_golf_video_slide_out_download_modal_video_play, {
        videoId,
      });
    } else {
      videoElement.pause();
      setIsPlaying(false);
      trackEvent(topGolfEvents.top_golf_video_slide_out_download_modal_video_pause, {
        videoId,
      });
    }
  };

  return (
    <Box position="relative" marginBottom={3} onClick={(e) => e.stopPropagation()}>
      <div className={classes.videoWrapper} onClick={playPauseSwitch}>
        <video className={classes.tgvideo} ref={refVideo} src={url} playsInline muted>
          <source src={url} type="video/mp4" />
        </video>
      </div>
      {/* <CardMedia className={classes.tgvideo} component="video" ref={refVideo} src={url} /> */}
      <Box className={classes.videoOverlay} onClick={playPauseSwitch}>
        {!isPlaying && (
          <IconButton className={classes.icon}>
            <PlayCircleOutlineIcon fontSize="inherit" color="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
