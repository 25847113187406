import { FC } from 'react';

interface RewardsCircleSmallProps {
  color?: string;
}
export const RewardsCircleSmall: FC<RewardsCircleSmallProps> = ({ color = '#2DA7E5' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" fill="none" viewBox="0 0 33 33">
      <path
        fill={color}
        fillRule="evenodd"
        d="M16.383.001h.117C25.612 0 33 7.388 33 16.5 33 25.612 25.611 33 16.5 33 7.642 33 .415 26.02.017 17.26L0 17.258V0h16.383v.001z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
