import { FC } from 'react';
import { useAppDispatch } from '../../../../../store';
import { removeSearchFilterAttribute } from '../../../../../store/slices/searchFiltersSlice';
import {
  FilterOptionsId,
  UserStatsPropertyNames,
  VideStatsPropertyNames,
} from '../filterOptions.helper';
import { VideoStatsFilter } from './VideoStatsFilter';
import { VideoStatusFilter, videoStatusPropertyName } from './VideoStatusFilter';
import { ShareTypeFilter, shareTypePropertyName } from './ShareTypeFilter';
import { UserStatusFilter, userStatusPropertyName } from './UserStatusFilter';
import { UserStatsFilter } from './UserStatsFilter';
import { CampaignStatsFilter } from './CampaignStatsFilter';
interface FilterItemsProps {
  selectedOptions: string[];
  handleRemove: (id: string) => void;
}

export const FilterItems: FC<FilterItemsProps> = ({ selectedOptions, handleRemove }) => {
  const dispatch = useAppDispatch();
  const handleRemoveVideoStatsFilter = () => {
    VideStatsPropertyNames.forEach((item) => {
      dispatch(removeSearchFilterAttribute(item));
    });
    handleRemove(FilterOptionsId.VideoStats);
  };
  const handleRemoveVideoStatusFilter = () => {
    dispatch(removeSearchFilterAttribute(videoStatusPropertyName));
    handleRemove(FilterOptionsId.VideoStatus);
  };
  const handleRemoveShareTypeFilter = () => {
    dispatch(removeSearchFilterAttribute(shareTypePropertyName));
    handleRemove(FilterOptionsId.ShareType);
  };
  const handleRemoveUserStatsFilter = () => {
    UserStatsPropertyNames.forEach((item) => {
      dispatch(removeSearchFilterAttribute(item));
    });
    handleRemove(FilterOptionsId.UserStats);
  };
  const handleRemoveUserStatusFilter = () => {
    dispatch(removeSearchFilterAttribute(userStatusPropertyName));
    handleRemove(FilterOptionsId.UserStatus);
  };

  const handleRemoveCampaignStatsFilter = () => {
    UserStatsPropertyNames.forEach((item) => {
      dispatch(removeSearchFilterAttribute(item));
    });
    handleRemove(FilterOptionsId.CampaignStats);
  };

  const renderFilter = (filterOption: string) => {
    switch (filterOption) {
      case FilterOptionsId.VideoStats:
        return <VideoStatsFilter handleRemove={handleRemoveVideoStatsFilter} />;
      case FilterOptionsId.VideoStatus:
        return <VideoStatusFilter handleRemove={handleRemoveVideoStatusFilter} />;
      case FilterOptionsId.ShareType:
        return <ShareTypeFilter handleRemove={handleRemoveShareTypeFilter} />;
      case FilterOptionsId.UserStats:
        return <UserStatsFilter handleRemove={handleRemoveUserStatsFilter} />;
      case FilterOptionsId.UserStatus:
        return <UserStatusFilter handleRemove={handleRemoveUserStatusFilter} />;
      case FilterOptionsId.CampaignStats:
        return <CampaignStatsFilter handleRemove={handleRemoveCampaignStatsFilter} />;
    }
  };
  return (
    <div>
      {selectedOptions.map((item) => {
        return <div style={{ marginTop: '10px' }}>{renderFilter(item)}</div>;
      })}
    </div>
  );
};
