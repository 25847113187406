import { FC, ReactNode, useMemo, useRef, useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { KPIValueApiModel, KPIApiNames, KPIApiModel } from '../../../api/models/analytics';
import { KPIDTOActiveAnalyticsBlock } from '../../../store/storeModels';

import { useWindowResize } from '../../../services/hooks/useWindowResize';
import {
  getData,
  options,
  getNormalizedChartData,
  getMonths,
  createOptions,
  annotationLine,
} from './ChartSection.helper';
import { LayoutMode } from '../../../common/constants/constants';
import { CommonStrings, DashboardStrings } from '../../../common/localization/en';
import { useTypedSelector } from '../../../store';

import { Spinner } from '../../../common/assets/Spinner';
import { ModalChart } from './ModalChart';

import {
  Chart,
  ChartContainer,
  ChartWrapper,
  ChartLeftLabel,
  ChartComponentStyled,
} from './ChartSection.style';
import { theme } from '../../../theme';
import { useClientType } from '../../../services/hooks/useClientType';

interface ChartSectionProps {
  chartData: KPIValueApiModel[];
  isChart: boolean;
  isLoading: boolean;
  onClose: () => void;
  onClickChartAnalyticsBlock: (kpiName: KPIApiNames) => void;
  activeAnalyticsBlock: KPIDTOActiveAnalyticsBlock;
  kpis: KPIApiModel;
  children: ReactNode;
  offsetKPIValues?: number;
}

export const ChartSection: FC<ChartSectionProps> = ({
  chartData,
  isChart,
  isLoading,
  onClose,
  activeAnalyticsBlock,
  children,
  offsetKPIValues,
}) => {
  const device = useWindowResize();
  const { isCummulativeGraph } = useTypedSelector((state) => state.ui);
  const { isHealthCare } = useClientType();
  const chartWrapper = useRef<HTMLDivElement>(null);
  const [chartWidth, setChartWidth] = useState(0);
  const chartDataLength = chartData.length;
  const months = getMonths(chartData);
  const lineChartOptions = useMemo(() => createOptions(chartData), [chartData]);

  useEffect(() => {
    if (chartWrapper && chartWrapper.current) {
      const element = chartWrapper.current as HTMLDivElement;
      const width = element?.offsetWidth;
      setChartWidth(width);
    }
  }, [chartWrapper]);

  const normalizedChartData = useMemo(() => {
    let chartDataOffset: KPIValueApiModel[];
    if (chartData.length && offsetKPIValues) {
      chartDataOffset = chartData.map((item, index) => {
        return index !== 0
          ? item
          : {
              ...item,
              value: item.value + offsetKPIValues,
            };
      });
      return getNormalizedChartData(chartDataOffset);
    }
    return getNormalizedChartData(chartData);
  }, [chartData, offsetKPIValues]);

  if (isLoading && device !== LayoutMode.Mobile) {
    return (
      <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)">
        <Spinner color="var(--lightBlue)" />
      </Box>
    );
  }

  const ChartBlock = (
    <ChartContainer
      style={{
        zoom: '1.11',
      }}
    >
      <ChartWrapper>
        <ChartLeftLabel>
          {isHealthCare && activeAnalyticsBlock.mainName === 'Creators'
            ? CommonStrings.Patients
            : activeAnalyticsBlock.mainName || DashboardStrings.ChartLeftLabel}
        </ChartLeftLabel>
        <Chart ref={chartWrapper}>
          {chartData.length === 1 ? (
            <Bar
              width={100}
              options={options}
              data={(canvas: any) => getData(canvas, chartData, false)}
            />
          ) : (
            <Line
              data={(canvas: any) =>
                getData(canvas, isCummulativeGraph ? normalizedChartData : chartData, true)
              }
              options={lineChartOptions}
              plugins={[annotationLine]}
            />
          )}

          <Box display="flex" paddingLeft="24px">
            {months.map((month, index) => (
              <Box
                key={month.name + index}
                display="flex"
                justifyContent="center"
                width={(chartWidth / chartDataLength) * month.days}
              >
                <Box
                  bgcolor={theme.palette.primary.light}
                  borderRadius="2px"
                  padding="1px 8px"
                  color="#fff"
                >
                  {month.name}
                </Box>
              </Box>
            ))}
          </Box>
        </Chart>
      </ChartWrapper>
    </ChartContainer>
  );

  const ChartBlockHandler =
    device === LayoutMode.Mobile ? (
      <ModalChart
        isLoading={isLoading}
        isChart={isChart}
        onClose={onClose}
        title={activeAnalyticsBlock.mainName}
      >
        {ChartBlock}
      </ModalChart>
    ) : (
      ChartBlock
    );

  return (
    <ChartComponentStyled>
      {children}
      {ChartBlockHandler}
    </ChartComponentStyled>
  );
};
