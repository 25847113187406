export const PointsIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2208 7C20.2208 5.89543 19.3254 5 18.2208 5H6C4.89543 5 4 5.89543 4 7V17.0907C4 18.1952 4.89543 19.0907 6 19.0907H8.53484C8.80005 19.0907 9.05441 19.196 9.24194 19.3836L11.4032 21.5449C11.7938 21.9354 12.4269 21.9354 12.8174 21.5449L14.9787 19.3836C15.1663 19.196 15.4206 19.0907 15.6858 19.0907H18.2208C19.3254 19.0907 20.2208 18.1952 20.2208 17.0907V7Z"
        stroke="#8B89A0"
        strokeWidth="1.3"
      />
      <path
        d="M11.6616 8.32646C11.845 7.95484 12.3749 7.95484 12.5584 8.32646L13.389 10.0095C13.4618 10.157 13.6026 10.2593 13.7654 10.283L15.6227 10.5529C16.0328 10.6124 16.1966 11.1164 15.8998 11.4057L14.5559 12.7157C14.438 12.8306 14.3843 12.9961 14.4121 13.1583L14.7293 15.0081C14.7994 15.4165 14.3707 15.728 14.0039 15.5352L12.3427 14.6618C12.197 14.5852 12.023 14.5852 11.8773 14.6618L10.2161 15.5352C9.84929 15.728 9.42057 15.4165 9.49063 15.0081L9.80789 13.1583C9.83571 12.9961 9.78194 12.8306 9.66409 12.7157L8.32015 11.4057C8.02339 11.1164 8.18714 10.6124 8.59725 10.5529L10.4545 10.283C10.6174 10.2593 10.7582 10.157 10.831 10.0095L11.6616 8.32646Z"
        stroke="#8B89A0"
        strokeWidth="1.3"
      />
    </svg>
  );
};
