import React from 'react';

import styled, { keyframes } from 'styled-components';
import { theme } from '../../../theme';

const pulse = (color: string) => keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${color};
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

const Root = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;

  box-shadow: ${({ color }) => `0 0 0 0 ${color}`};
  transform: scale(1);
  animation: ${({ color }) => pulse(color)} 2s infinite;
`;

interface PulseNotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  color?: string;
}

export const PulseNotification: React.FC<PulseNotificationProps> = ({
  color = theme.palette.primary.main,
  ...divProps
}) => {
  return <Root color={color} {...divProps} />;
};
