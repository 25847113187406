import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '38px 32px 16px',
    background: 'transparent',
    border: '1px solid #DCDBE4',
    borderRadius: '20px',
    color: theme.palette.common.gray,
    cursor: 'pointer',
  },
  active: {
    background: `linear-gradient(180deg, ${theme.palette.primary.main}00 0%, ${theme.palette.primary.main}33 100%)`,
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    boxShadow: `0px 0px 4px ${theme.palette.primary.main}`,
    '& p': {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: '600 !important',
    },
  },
  icon: {
    marginBottom: '28px',
  },
  title: {
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    width: '105px',
  },
}));
