import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useBottomToolbarStyles = makeStyles((theme: Theme) => ({
  ButtonsContainer: {
    position: 'absolute',
    zIndex: 1205,
    bottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '4px 10px 10px 30px',
    width: '100%',
    left: 0,
    '@media (max-height: 900px)': {
      position: 'relative',
      margin: '24px 10px 0px 0px',
    },
  },
  Buttons: {
    boxShadow: 'none',
    cursor: 'pointer',
    flexShrink: 0,
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.common.gray300,
    color: theme.palette.primary.main,
    minWidth: '67px',
    height: '45px',
    backgroundColor: 'transparent',
    '& a': {
      display: 'flex',
      alignItems: 'center',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
    '& svg': {
      height: '22px',
      width: '22px',
    },
  },
  ButtonsFilled: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
  DeleteButton: {
    color: theme.palette.error.main,
  },
}));
