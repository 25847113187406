import styled from 'styled-components';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
export const useWebAppCards = makeStyles((theme) => ({
  SectionTitle: {
    marginBottom: '14px',
    fontSize: '17px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: theme.palette.common.deepDark,
  },
  leftSideDescription: {
    color: theme.palette.common.gray,
    paddingTop: '0px',
    paddingBottom: '14px',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.02em',
  },
  root: {
    width: '100%',
  },
  Title: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepperRoot: {
    marginBottom: '24px',
    padding: '8px 0',
  },
  stepperLabel: {
    fontWeight: 600,
  },
  stepperLabelActive: {
    color: '#464C71',
    fontWeight: 600,
  },
  previewTitle: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.6,
    letterSpacing: 0.15,
    textAlign: 'center',
  },
  bottomControllers: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: 100,
    bottom: -20,
    left: 0,
    width: '100%',
    position: 'fixed',
    backgroundColor: '#fff',
    boxShadow: '0 10px 38px 11px rgba(10, 25, 107, 0.05)',
    paddingLeft: '260px',
    paddingTop: '20px',
    zIndex: 100,
  },
  actionButton: {
    float: 'right',
    margin: '5px 2px',
    width: '120px',
    borderRadius: 100,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  mainContainer: {
    // margin: '-16px -24px',
  },
  cardWrapper: {
    padding: '25px 30px',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    '&>.MuiTypography-root': {
      color: '#8B89A0',
      lineHeight: '28px',
    },
  },
  cardPreviewWrapper: {
    margin: 'initial',
  },
  styledButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    marginRight: '30px',
    padding: '10px 40px',
  },
  cancelButton: {
    width: 'fit-content',
    borderRadius: '100px',
    fontSize: '14px',
    lineHeight: '26px',
    padding: '10px 30px',
  },
  selectedImg: {
    position: 'absolute',
    top: '70px',
    width: '100%',
    height: '88%',
  },
}));
export const ActionButton = styled(Button)`
  float: right;
  margin: 10px;
`;
