import { Box, Grid, Typography } from '@mui/material';

import RewardsIcon from '../../common/assets/QuickStartIcons/RewardsIcon';
import PersonalizeChartIcon from '../../common/assets/QuickStartIcons/PersonalizeChartIcon';
import InviteIcon from '../../common/assets/QuickStartIcons/InviteIcon';
import CheckedStepperIcon from '../../common/assets/QuickStartIcons/CheckedStepperIcon';
import RewardsStepperIcon from '../../common/assets/QuickStartIcons/RewardsStepperIcon';
import PersonalizeStepperIcon from '../../common/assets/QuickStartIcons/PersonalizeStepperIcon';
import InviteStepperIcon from '../../common/assets/QuickStartIcons/InviteStepperIcon';
import UploadIcon from '../../common/assets/QuickStartIcons/UploadIcon';
import { WelcomeScreenIcon } from '../../common/assets/newDesign/WebAppIcons';
import { InvitesQRIcon } from '../../common/assets/newDesign/WebAppIcons/InvitesQRIcon';
import CouponIcon from '../../common/assets/QuickStartIcons/CouponIcon';
import { SignUpFieldsIcon } from '../../common/assets/newDesign/WebAppIcons/SignUpFieldsIcon';
import RewardSMSIcon from '../../common/assets/QuickStartIcons/RewardSMSIcon';
import { TrackingPixelNavIcon } from '../../common/assets/newDesign/WebAppIcons/TrackingPixelNavIcon';
import AutomaticSMSIcon from '../../common/assets/QuickStartIcons/AutomaticSMSIcon';
import ManualSMSIcon from '../../common/assets/QuickStartIcons/ManualSMSIcon';
import SaleAmountIcon from '../../common/assets/QuickStartIcons/SaleAmountIcon';
import PercentAmountIcon from '../../common/assets/QuickStartIcons/PercentAmountIcon';
import CustomRewardIcon from '../../common/assets/QuickStartIcons/CustomRewardIcon';
import { VideosIconAnalytics } from '../../common/assets/newDesign/VideosIconAnalytics';
import { SharesIconAnalytics } from '../../common/assets/newDesign/SharesIconAnalytics';
import { CTAIcon } from '../../common/assets/newDesign/CTAIcon';
import { theme } from '../../theme';
import CouponCircleIcon from '../../common/assets/newDesign/WebAppIcons/CouponCircleIcon';

export const DevIcons = () => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        backgroundColor: '#fff',
        flexDirection: 'column',
        padding: '16px',
        overflowY: 'auto',
        maxHeight: '100vh',
        flexWrap: 'inherit',
      }}
    >
      <Grid item>DevIcons</Grid>
      <Grid item>Welcome</Grid>
      <Grid
        item
        spacing={1}
        sx={{ backgroundColor: '#fff', flexDirection: 'row', display: 'flex', gap: ' 16px' }}
      >
        <Grid item sx={{ width: 'min-content' }}>
          <Box
            sx={{
              borderRadius: '50%',
              border: '2px solid',
              borderColor: theme.palette.primary.main,
              width: '86px',
              height: '86px',
              backgroundColor: ' #EEECFC',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PersonalizeChartIcon />
          </Box>
          <Typography>PersonalizeChartIcon</Typography>
        </Grid>
        <Grid item sx={{ width: 'min-content' }}>
          <Box>
            <RewardsIcon />
          </Box>
          <Typography>PersonalizeChartIcon</Typography>
        </Grid>
        <Grid item sx={{ width: 'min-content' }}>
          <Box>
            <InviteIcon />
          </Box>
          <Typography>InviteIcon</Typography>
        </Grid>
      </Grid>
      {/*  */}
      <Grid item>Horizontal Stepper</Grid>
      <Grid
        item
        spacing={1}
        sx={{ backgroundColor: '#fff', flexDirection: 'row', display: 'flex', gap: ' 16px' }}
      >
        <Grid item>
          <Box>
            <CheckedStepperIcon />
          </Box>
          <Typography>CheckedStepperIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <PersonalizeStepperIcon />
          </Box>
          <Typography>PersonalizeStepperIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <RewardsStepperIcon />
          </Box>
          <Typography>RewardsStepperIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <InviteStepperIcon />
          </Box>
          <Typography>InviteStepperIcon</Typography>
        </Grid>
      </Grid>
      {/*  */}
      <Grid item> UploadIcon</Grid>
      <Grid
        item
        spacing={1}
        sx={{ backgroundColor: '#fff', flexDirection: 'row', display: 'flex', gap: ' 16px' }}
      >
        <Grid item>
          <Box>
            <UploadIcon />
          </Box>
          <Typography>UploadIcon</Typography>
        </Grid>
      </Grid>
      {/*  */}
      <Grid item>Right Side Tab Icons</Grid>
      <Grid
        item
        spacing={1}
        sx={{
          backgroundColor: '#fff',
          flexDirection: 'row',
          display: 'flex',
          gap: ' 16px',
          flexWrap: 'wrap',
        }}
      >
        <Grid item>
          <Box>
            <WelcomeScreenIcon />
          </Box>
          <Typography>WelcomeScreenIcon</Typography>
          <Typography>../../common/assets/newDesign/WebAppIcons</Typography>
        </Grid>
        <Grid item>
          <Box>
            <InvitesQRIcon />
          </Box>
          <Typography>InvitesQRIcon</Typography>
          <Typography>../../common/assets/newDesign/WebAppIcons/InvitesQRIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <CouponIcon />
          </Box>
          <Typography>CouponIcon</Typography>
          <Typography>../../common/assets/Sprint19Icons/CouponIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <CouponCircleIcon />
          </Box>
          <Typography>CouponCircleIcon</Typography>
          <Typography>../../common/assets/newDesign/WebAppIcons/CouponCircleIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <SignUpFieldsIcon />
          </Box>
          <Typography>SignUpFieldsIcon</Typography>
          <Typography>../../common/assets/newDesign/WebAppIcons/SignUpFieldsIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <RewardSMSIcon />
          </Box>
          <Typography>RewardSMSIcon</Typography>
          <Typography>../../common/assets/Sprint19Icons/RewardSMSIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <TrackingPixelNavIcon />
          </Box>
          <Typography>TrackingPixelNavIcon</Typography>
          <Typography>../../common/assets/newDesign/WebAppIcons/TrackingPixelNavIcon</Typography>
        </Grid>
        <Grid item>
          <Box sx={{ color: '#8B89A0' }}>
            <AutomaticSMSIcon />
          </Box>
          <Typography>AutomaticSMSIcon</Typography>
          <Typography>../../common/assets/Sprint19Icons/AutomaticSMSIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <ManualSMSIcon />
          </Box>
          <Typography>ManualSMSIcon</Typography>
          <Typography>../../common/assets/Sprint19Icons/ManualSMSIcon</Typography>
        </Grid>
      </Grid>
      {/*  */}
      <Grid item>Select Reward Type</Grid>
      <Grid
        item
        spacing={1}
        sx={{ backgroundColor: '#fff', flexDirection: 'row', display: 'flex', gap: ' 16px' }}
      >
        <Grid item>
          <Box>
            <SaleAmountIcon />
          </Box>
          <Typography>SaleAmountIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <PercentAmountIcon />
          </Box>
          <Typography>PercentAmountIcon</Typography>
        </Grid>
        <Grid item>
          <Box>
            <CustomRewardIcon />
          </Box>
          <Typography>CustomRewardIcon</Typography>
        </Grid>
      </Grid>
      {/*  */}
      <Grid item>Select the interaction is most valuable to you</Grid>
      <Grid
        item
        spacing={1}
        sx={{ backgroundColor: '#fff', flexDirection: 'row', display: 'flex', gap: ' 16px' }}
      >
        <Grid item>
          <Box>
            <VideosIconAnalytics />
          </Box>
          <Typography>VideosIconAnalytics</Typography>
          <Typography>../../common/assets/newDesign/VideosIconAnalytics</Typography>
        </Grid>
        <Grid item>
          <Box>
            <SharesIconAnalytics />
          </Box>
          <Typography>SharesIconAnalytics</Typography>
          <Typography>../../common/assets/newDesign/SharesIconAnalytics</Typography>
        </Grid>
        <Grid item>
          <Box>
            <CTAIcon />
          </Box>
          <Typography>CTAIcon</Typography>
          <Typography>../../common/assets/newDesign/VideosIconAnalytics</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
