import { Box, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';

import { RoundedButton } from '../../../../common/components/Button/RoundedButton';
import { SelectTextField } from '../../../../common/components/TextField/SelectTextField';
import { useVenue } from '../../../../hooks/useVenue';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { updateMessagingConfig } from '../../../../store/slices/smsInvitesSlice';

export const options = [
  {
    content: 'Today',
    value: 0,
  },
  {
    content: '1 Day After',
    value: 1,
  },
  {
    content: '2 Days After',
    value: 2,
  },
  {
    content: '3 Days After',
    value: 3,
  },
  {
    content: '4 Days After',
    value: 4,
  },
  {
    content: '5 Days After',
    value: 5,
  },
  {
    content: '6 Days After',
    value: 6,
  },
  {
    content: '7 Days After',
    value: 7,
  },
];

interface ScheduleDeliveryProps {
  onSubmit?: (daysShift: number) => void;
}

export const ScheduleDelivery: React.FC<ScheduleDeliveryProps> = ({ onSubmit }) => {
  const { messageConfig, isConfigLoading } = useTypedSelector((store) => store.smsInvitesSlice);

  const dispatch = useAppDispatch();
  const { venueId } = useVenue();

  const [daysShift, setDaysShift] = useState(messageConfig.postMessage.daysShift);

  const theme = useTheme();

  return (
    <Box mx="30px">
      <Box p="32px" bgcolor="white" borderRadius="12px">
        <Typography fontSize="20px" fontWeight="600" mb="16px">
          Message Delivery Schedule
        </Typography>
        <Typography fontSize="14px" color={theme.palette.text.secondary} mb="8px">
          When do you want messages to be sent:
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography fontWeight="600" mr="8px">
            From the transaction date:
          </Typography>
          <SelectTextField
            options={options}
            onChange={(e) => setDaysShift(e.target.value as number)}
            value={daysShift}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <RoundedButton
          title="Import Data"
          loading={isConfigLoading}
          onClick={async () => {
            await dispatch(
              updateMessagingConfig({
                accountId: venueId,
                ...messageConfig,
                postMessage: {
                  ...messageConfig.postMessage,
                  daysShift,
                },
              }),
            );
            onSubmit?.(daysShift);
          }}
        />
      </Box>
    </Box>
  );
};
