import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles<Theme>((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '12px',
  },
  topWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '100%',
  },
  text: {
    color: '#4E496F',
    fontWeight: 500,
    lineHeight: '30px',
  },
}));
