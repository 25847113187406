// split text in two parts, equal if possible. Required according to the design
export const getTextSplit = (text: string) => {
  const middleIndex = text.length % 2 === 0 ? text.length / 2 : text.length / 2 + 1;

  const splitIndex = text.split(' ').reduce((acc) => {
    const currIndex = text.indexOf(' ', acc + 1);
    return Math.abs(currIndex - middleIndex) < Math.abs(acc - middleIndex) ? currIndex : acc;
  }, 0);

  return splitIndex > 0 ? [text.slice(0, splitIndex), text.slice(splitIndex + 1)] : [text, ''];
};
