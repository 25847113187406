import * as Yup from 'yup';

export interface AccountBoxValues {
  password: string;
}

export const initialValuesAccountBox: AccountBoxValues = {
  password: '',
};

export const validationSchemaAccountBox = () =>
  Yup.object().shape({
    password: Yup.string()
      .min(5, 'password should be min 5 characters long')
      .max(25, 'max length 25 characters')
      .required('Required'),
  });
