import * as Yup from 'yup';
// import { emailMaskRegex } from '../../common/constants/constants';

export interface ForgotPasswordFormValues {
  email: string;
}

export const initialValuesForgotPasswordForm: ForgotPasswordFormValues = {
  email: '',
};

export const validationSchemaForgotPassword = () =>
  Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Required'),
  });

export interface LoginFormValues {
  email: string;

  password: string;
}

export const initialValuesLoginForm: LoginFormValues = {
  email: '',

  password: '',
};

export const validationSchemaLoginForm = () =>
  Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Required'),

    password: Yup.string()
      .min(5, 'password should be min 5 characters long')
      .max(25, 'max length 25 characters')
      .required('Required'),
  });
