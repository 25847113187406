import { VenueWizardPreviewStepStrings } from '../../../../common/localization/en';

export const createItems = (
  programId: string,
  programName: string,
  campaignLogoUrl: string,
  campaignLogoData: string,
  campaignId: string,
  campaignStartDate: string,
  campaignName: string,
) => {
  return [
    { label: VenueWizardPreviewStepStrings.LabelProgramId, value: programId },
    {
      label: VenueWizardPreviewStepStrings.LabelProgramName,
      value: programName,
    },
    // {
    //   label: VenueWizardPreviewStepStrings.LabelOrganizationId,
    //   value: organization_id,
    // },
    // {
    //   label: VenueWizardPreviewStepStrings.LabelSchoolName,
    //   value: school_name,
    // },
    {
      label: VenueWizardPreviewStepStrings.LabelSchoolLogo,
      value: campaignLogoUrl,
    },
    {
      label: VenueWizardPreviewStepStrings.LabelSchoolLogoData,
      value: campaignLogoData,
    },
    {
      label: VenueWizardPreviewStepStrings.LabelCampaignId,
      value: campaignId,
    },
    {
      label: VenueWizardPreviewStepStrings.LabelCampaignStart,
      value: campaignStartDate,
      date: true,
    },
    {
      label: VenueWizardPreviewStepStrings.LabelCampaignName,
      value: campaignName,
    },
  ];
};
