import { Box, Card, CardMedia, CircularProgress, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useStyles } from './VideoComponent.styles';
import { PlayIcon } from '../../../../assets/newDesign/VideoControlsIcons/PlayIcon';
import CheckedIcon from '../../../../assets/newDesign/CheckedIcon';
import UncheckedIcon from '../../../../assets/newDesign/UncheckedIcon';

interface Props {
  id: string;
  url: string;
  thumbnail: string;
  name: string;
  isChecked: boolean;
  onStatusChange: (videoId: string) => void;
}

const VideoComponent: FC<Props> = ({ id, thumbnail, url, name, isChecked, onStatusChange }) => {
  const styles = useStyles();

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const theme = useTheme();

  const [isPlaying, setIsPlaying] = useState(false);
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = thumbnail;
    img.onload = () => {
      setTimeout(() => {
        setIsThumbnailLoaded(true);
      }, 400);
    };
  }, [thumbnail]);

  const handlePlay = () => {
    if (!videoRef.current) return;

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
      return;
    }

    videoRef.current.play();
    setIsPlaying(true);
  };

  return (
    <Card
      className={styles.container}
      style={{
        backgroundColor: isThumbnailLoaded ? theme.palette.primary.main : '#fff',
      }}
    >
      {isThumbnailLoaded ? (
        <>
          <CardMedia
            component="video"
            src={url}
            poster={thumbnail}
            className={styles.video}
            ref={videoRef}
            onClick={handlePlay}
          />
          {!isPlaying && <Typography className={styles.userName}>{name}</Typography>}
          {!isPlaying && (
            <Box className={styles.playButton} onClick={handlePlay}>
              <PlayIcon />
            </Box>
          )}
          <Box className={styles.checkWrapper} onClick={() => onStatusChange(id)}>
            {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
          </Box>
        </>
      ) : (
        <CircularProgress className={styles.loader} />
      )}
    </Card>
  );
};

export default VideoComponent;
