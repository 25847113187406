import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: '0 auto',
  },
  pageWrapper: {
    paddingTop: '81px',
    backgroundColor: '#EAECF0',
    display: 'flex',
    flexGrow: 1,
  },
  dashboardButton: {
    width: '272px',
  },
}));
