import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  videoWrapper: {
    position: 'relative',
    width: '289px',
    height: '514px',
    backgroundColor: '#000',
    border: '3px solid #EAECF0',
    borderRadius: '14px',
    overflow: 'hidden',
  },
  playIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  muteIcon: {
    position: 'absolute',
    right: '15px',
    bottom: '0px',
    cursor: 'pointer',
  },
  recordButtonWrapper: {
    position: 'absolute',
    bottom: '50px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 100,
  },
  videoPreviewWrapper: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}));
