import { FC } from 'react';
import { SelectTextField } from '../TextField/SelectTextField';
import { Box, Typography } from '@mui/material';

interface UploadVIdeoSelectorProps {
  value: string;
  onChange: (uploadVideo: string) => void;
}

const UploadVIdeoSelector: FC<UploadVIdeoSelectorProps> = ({ value, onChange }) => {
  return (
    <Box>
      <Typography color="#344054" fontSize="13px" mb="6px" fontWeight={500}>
        Upload a clip for this Creator
      </Typography>
      <SelectTextField
        sx={{ height: '44px' }}
        fullWidth
        name="state"
        placeholder="State"
        defaultValue={'Most recent Campaign Name'}
        value={value}
        onChange={(event: any) => {
          const value: string = event.target.value;

          onChange(value);
        }}
        options={[
          { content: 'Yes', value: 'Yes' },
          { content: 'No', value: 'No' },
        ]}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      />
    </Box>
  );
};

export default UploadVIdeoSelector;
