import React, { useEffect, useMemo, useState } from 'react';
import { TopBar } from '../../../common/components/topBar/TopBar';
import {
  SidebarStrings,
  WebAppPageStrings,
  WebAppSignUpConfigStrings,
} from '../../../common/localization/en';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { WebAppSettingsEvents } from '../../../common/constants/events/webappSettingsEvents';
import { SettingsEvents } from '../../../common/constants/events/settingsEvents';
import { UnsavedChangesModal } from '../../../common/components/UnsavedChangesModal/UnsavedChangesModal';
import { webappTabs } from '../../webapp/tabs';
import { useFormik } from 'formik';
import { TwoSidePageLayout } from '../../../common/layout/TwoSidePageLayout/TwoSidePageLayout';
import { useStyles } from './SignUpTab.styles';
import { WebappSubnavigation } from '../../../common/components/webappSubnavigation/WebappSubnavigation';
import { Box, Typography } from '@mui/material';
import { BottomActionBar } from '../../../common/components/BottomActionBar/BottomActionBar';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { IPhoneSignUpPreview } from '../../../common/components/IPhonePreview/IPhoneSignUpPreview';
import { formatStringWithPattern, getOnlyNumbers } from '../../../services/utilities';
import Select from '../../../common/components/Select/Select';
import { SubSectionTitle } from '../../../common/components/SubSectionTitle/SubSectionTitle';
import { patchVenueProperties } from '../../../store/slices/venueSlice';
import { useToasts } from 'react-toast-notifications';
import { LogoPresentation, LogoPresentationLayout } from '../../../api/models/venue';
import { useThemeLayout } from '../../../hooks/useThemeLayout';
import { useHistory } from 'react-router-dom';
import { WebsiteRoutes } from '../../../common/constants/routes';

const SignUpTab = () => {
  const styles = useStyles();

  const { trackEvent } = useTrackEvent();
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();
  const history = useHistory();

  const {
    venue: { logo, id },
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  const backgroundImage = venueProperties?.['webapp.cards-welcome']?.desktop.cropped;
  const brandColor = venueProperties?.['webapp.config']['primary-color'];
  const signupConfig = venueProperties?.['webapp.signUp.config'];
  const me = useTypedSelector((state) => state.me);

  const { isThemeLayoutEnabled } = useThemeLayout();

  const [isLoading, setIsLoading] = useState(false);

  const {
    values,
    dirty: isFormDirty,
    resetForm,
    submitForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      logoPresentation: signupConfig?.logoPresentation || LogoPresentation.Default,
      layout: signupConfig?.logoPresentationLayout || LogoPresentationLayout.Straight,
      showCompanyName: signupConfig?.showCompanyName || false,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsLoading(true);
      dispatch(
        patchVenueProperties({
          id,
          update: {
            name: 'webapp.signUp.config',
            value: {
              ...signupConfig,
              logoPresentation: values.logoPresentation,
              logoPresentationLayout: values.layout,
              showCompanyName: values.showCompanyName,
            },
          },
        }),
      ).then(() => {
        addToast(WebAppSignUpConfigStrings.nextStepMessage, {
          appearance: 'success',
          autoDismissTimeout: 2500,
        });
        setIsLoading(false);
      });
    },
  });

  const formattedPhone = useMemo(() => {
    if (me.phoneNumber) {
      return formatStringWithPattern(getOnlyNumbers(me.phoneNumber), '+# (###) ###-####');
    }
  }, [me.phoneNumber]);

  const onRouteClick = (route: string) => {
    const subRoute = route.split('/').reverse()[0].replaceAll('-', '_');
    const eventName = `${subRoute}_${SettingsEvents.video_app_sub_navigation_tab_click}`;
    trackEvent(eventName);
  };

  const resetAll = () => {
    resetForm();
  };

  useEffect(() => {
    if (!isThemeLayoutEnabled) {
      history.push(WebsiteRoutes.BrandingStyleTab);
    }
  }, [isThemeLayoutEnabled, history]);

  const logoPresentationOptions = [
    { value: LogoPresentation.Default, name: 'Theme Default' },
    { value: LogoPresentation.BrandColor, name: 'Over Branding Color' },
    { value: LogoPresentation.SplashImage, name: 'Over Splash Image' },
  ];

  const layoutOptions = [
    { value: LogoPresentationLayout.Straight, name: 'Straight Edge' },
    { value: LogoPresentationLayout.Curved, name: 'Curved Edge' },
    { value: LogoPresentationLayout.Fade, name: 'Fade' },
  ];

  return (
    <>
      <TopBar
        sectionTitle={SidebarStrings.WebApp}
        onTabChange={(tab) => {
          trackEvent(WebAppSettingsEvents.NavigationTabChange, { value: tab.text });
        }}
      >
        <WebappSubnavigation items={webappTabs} onRouteClick={onRouteClick} />
      </TopBar>
      <UnsavedChangesModal isUnsavedChangesOnPage={isFormDirty} />
      <TwoSidePageLayout
        leftSideDescriptionClassName={styles.leftSideDescription}
        leftSideDescription={WebAppPageStrings.SignUpTabDescription}
        leftSideNode={
          <Box>
            <Box className={styles.inputTitleWrapper}>
              <Typography className={styles.inputTitle}>
                {WebAppPageStrings.LogoPresentetion}
              </Typography>
              {/* <Tooltip title="???" /> */}
            </Box>
            <Select
              options={logoPresentationOptions}
              onChange={(value) => {
                setFieldValue('logoPresentation', value);

                if (value === LogoPresentation.Default) {
                  setFieldValue('layout', LogoPresentationLayout.Straight);
                }
              }}
              value={values.logoPresentation}
              width="404px"
            />
            {values.logoPresentation !== LogoPresentation.Default && (
              <>
                <Box className={styles.inputTitleWrapper}>
                  <Typography className={styles.inputTitle}>{WebAppPageStrings.Layout}</Typography>
                  {/* <Tooltip title="???" /> */}
                </Box>
                <Select
                  options={layoutOptions}
                  onChange={(value) => {
                    setFieldValue('layout', value);
                  }}
                  value={values.layout}
                  width="404px"
                />
              </>
            )}
            <Box>
              <SubSectionTitle
                mt="28px"
                title={WebAppPageStrings.CompanyName}
                // tooltip={'???'}
                toggleConfig={{
                  value: values.showCompanyName,
                  onChange: () => setFieldValue('showCompanyName', !values.showCompanyName),
                }}
              />
              <Typography className={styles.companyNameInfo}>
                {WebAppPageStrings.CompanyNameInfo}
              </Typography>
            </Box>
          </Box>
        }
        rightSideNode={
          <>
            <Box margin="0 auto">
              <IPhoneSignUpPreview
                phone={formattedPhone || '+1 (760) 840 1402'}
                name={me.name || 'James Bently'}
                email={me.email || 'Jbently@gmail.com'}
                nameRequired
                brandColor={brandColor ?? 'black'}
                logoImage={logo}
                showCompanyName={values.showCompanyName}
                logoPresentation={values.logoPresentation}
                backgroundImage={backgroundImage}
                layout={values.layout}
              />
            </Box>
          </>
        }
      />
      <BottomActionBar
        onCancelClick={resetAll}
        onSaveClick={submitForm}
        show={isFormDirty}
        loading={isLoading}
      />
    </>
  );
};

export default SignUpTab;
