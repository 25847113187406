import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './EditThumbnail.styles';
import { Box, Button, Typography } from '@mui/material';
import { CampaignSummaryIntroVideoStrings } from '../../../../../../../common/localization/en';
import { ThumbnailCustomization } from '../../../../../../../common/components/EditThumbnail/ThumbnailCustomization';
import { ThumbnailFrame } from '../../../../../../../common/components/EditThumbnail/EditThumbnail';
import {
  extractFramesFromCloudinary,
  getVideoWithoutText,
  googleStorageVideoUrlToCloudinaryUrl,
} from '../../../../../../../common/components/EditThumbnail/EditThumbnail.helper';

interface Props {
  videoUrl: string | null;
  selectedThumbnail: ThumbnailFrame | undefined;
  onThumbnailChange: (thumbnail: ThumbnailFrame | undefined) => void;
}

const EditThumbnail: FC<Props> = ({ videoUrl, selectedThumbnail, onThumbnailChange }) => {
  const classes = useStyles();

  const [thumbnailFrames, setThumbnailFrames] = useState<ThumbnailFrame[]>([]);
  const [areFramesLoading, setAreFramesLoading] = useState(false);
  const [counter, setCounter] = useState(1);

  useEffect(() => {
    if (videoUrl) {
      const cloudinaryUrl = videoUrl.includes('cloudinary')
        ? videoUrl
        : googleStorageVideoUrlToCloudinaryUrl(videoUrl);
      getCloudinaryFrames(getVideoWithoutText(cloudinaryUrl));
    }
  }, [videoUrl]);

  useEffect(() => {
    return () => {
      setCounter(1);
    };
  }, []);

  const getCloudinaryFrames = async (
    url: string,
    options?: { interval?: number; altThumbnail?: boolean },
  ) => {
    setAreFramesLoading(true);
    const thumbnailFrames = await extractFramesFromCloudinary(url, {
      interval: options?.interval,
      removeFilter: true,
    });
    const altThumbnailFrames = options?.altThumbnail
      ? await extractFramesFromCloudinary(url, {
          removeFilter: true,
          interval: options?.interval,
        })
      : thumbnailFrames;
    setThumbnailFrames(
      thumbnailFrames.map(({ thumbnail, time }, index) => ({
        thumbnail,
        time,
        altThumbnail: altThumbnailFrames[index].thumbnail,
      })),
    );
    setAreFramesLoading(false);
  };

  const regenerateThumbnails = (interval: number) => {
    if (!videoUrl) return;

    getCloudinaryFrames(getVideoWithoutText(videoUrl), {
      interval,
      altThumbnail: false,
    });
  };

  const handleRegenerateThumbnail = () => {
    onThumbnailChange(undefined);
    const random = Number((Math.random() * 0.3).toFixed(2));
    setCounter(counter + 0.1 + random);
    regenerateThumbnails(counter);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.topWrapper}>
        <Typography className={classes.title}>
          {CampaignSummaryIntroVideoStrings.SelectAThumbnail}
        </Typography>
        <Box className={classes.buttonsWrapper}>
          <Button variant="outlined" onClick={handleRegenerateThumbnail} className={classes.button}>
            {CampaignSummaryIntroVideoStrings.Refresh}
          </Button>
        </Box>
      </Box>
      <Box className={classes.thumbnailsWrapper}>
        <ThumbnailCustomization
          frames={thumbnailFrames}
          areFramesLoading={areFramesLoading}
          onFrameSelect={onThumbnailChange}
          withDivider={false}
        />
      </Box>
    </Box>
  );
};

export default EditThumbnail;
