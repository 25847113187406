import { FC } from 'react';

export const AllIcon: FC = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx={6.62069} cy={7.03671} r={3.45272} stroke="currentColor" strokeWidth={1.3} />
    <circle cx={6.62069} cy={16.9633} r={3.45272} stroke="currentColor" strokeWidth={1.3} />
    <circle cx={16.5473} cy={7.03671} r={3.45272} stroke="currentColor" strokeWidth={1.3} />
    <circle cx={16.5478} cy={16.9633} r={3.45272} stroke="currentColor" strokeWidth={1.3} />
  </svg>
);
