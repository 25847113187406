import React from 'react';
import { Box } from '@mui/material';
import CardLayout from '../../../../common/components/Coupon/CardLayout';

export const RewardsCardIcon = () => {
  return (
    <Box
      sx={{
        marginRight: '12px',
        width: '34px',
        height: '34px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& >svg': {
          transform: 'scale(0.05)',
          flexShrink: 0,
          flexGrow: 0,
        },
      }}
    >
      <CardLayout />
    </Box>
  );
};
