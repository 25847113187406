import { FC } from 'react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { HeroesListView } from '../../../../localization/en';
import PhoneInput from 'react-phone-input-2';

interface SalesHeroPhoneBlockProps {
  blockTitle: string | null;
  blockText: string | null;
}
const useStyles = makeStyles((theme) => ({
  avatar: {
    display: 'flex',
    height: '70px',
    width: '70px',
    borderRadius: '100%',
    marginRight: '20px',
  },
  infoBlockRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4,1fr)',
    gridRowGap: '20px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: '25px',
    borderRadius: '10px',
    padding: '27px 14px',
    border: `1px solid #DCDBE4`,
  },
  infoBlockContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 10px',
  },
  phoneNotAvailable: {
    color: '#DCDBE4',
    fontWeight: '400',
    fontSize: '14px',
  },
  iconContainer: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
    padding: '8px',
    borderRadius: '30px',
    backgroundColor: '#F5F6F8',
    margin: 'auto 13px auto 0',
  },
  iconSvg: {
    display: 'block',
    width: '20px',
    height: '20px',
    fill: '#8B89A0',
  },
  arrowOutlined: {
    border: '1px solid #8B89A0',
    borderRadius: '50%',
  },
  infoBlockContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
  },
  textSmall: {
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textMedium: {
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
  },
  textLarge: {
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
    fontWeight: '500',
  },
  primaryTextColor: {
    color: '#1A1538',
    fontWeight: '600',
    fontSize: '14px',
  },
  secondaryTextColor: {
    color: '#1A1538',
    fontWeight: '400',
    fontSize: '12px',
    marginBottom: 0,
  },
  outlinedWrapper: {
    border: '1px solid #DCDBE4',
    padding: '10px 20px',
    borderRadius: '15px',
    width: '50%',
  },
  displayCorrectPhone: {
    '& .react-tel-input :disabled': {
      cursor: 'text',
    },
    '& .react-tel-input .selected-flag': {
      display: 'none',
    },
    '& .react-tel-input .form-control': {
      border: 'none',
      padding: '0px',
      height: 'auto',
      width: 'auto',
      color: '#8B89A0',
      fontSize: '14px',
      lineHeight: '1.5',
      letterSpacing: '0.02em',
      fontWeight: 400,
    },
    '& .react-tel-input .flag-dropdown': {
      display: 'none',
    },
  },
}));
export const SalesHeroPhoneBlock: FC<SalesHeroPhoneBlockProps> = ({ blockText }) => {
  const styles = useStyles();

  const renderPhoneDetails = () => {
    return (
      <>
        <Typography className={clsx([styles.text, styles.primaryTextColor])} mt="5px">
          {blockText ? (
            <div className={styles.displayCorrectPhone}>
              <PhoneInput
                value={blockText}
                disabled
                inputStyle={{
                  width: '100%',
                }}
              />
            </div>
          ) : (
            <span className={styles.phoneNotAvailable}>(###) ###-####</span>
          )}
        </Typography>
      </>
    );
  };

  return (
    <>
      <div className={styles.outlinedWrapper}>
        <Typography marginBottom="15px" className={clsx([styles.text, styles.secondaryTextColor])}>
          {HeroesListView.Data3}
        </Typography>
        {renderPhoneDetails()}
      </div>
    </>
  );
};

export const PhoneNumberDisplayed: FC<Partial<SalesHeroPhoneBlockProps>> = ({ blockText }) => {
  const styles = useStyles();
  return (
    <>
      <Typography className={clsx([styles.text, styles.primaryTextColor])}>
        {blockText ? (
          <div className={styles.displayCorrectPhone}>
            <PhoneInput value={blockText} disabled />
          </div>
        ) : (
          <span className={styles.phoneNotAvailable}>(###) ###-####</span>
        )}
      </Typography>
    </>
  );
};
