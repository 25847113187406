import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useTotalClicksStyles = makeStyles((theme: Theme) => ({
  Title: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '133.4%',
    color: theme.palette.text.primary,
  },
  Clicks: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '133.4%',
    color: '#0B4C8C',
  },
}));
