import { theme } from '../../../../theme';

export const CardsIcon = () => {
  return (
    <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_990_21683)">
        <rect
          x="21.5334"
          y="7.66406"
          width="19.7919"
          height="26.2828"
          rx="2.18354"
          transform="rotate(15 21.5334 7.66406)"
          fill="white"
          stroke="#1A1538"
          strokeWidth="0.689974"
        />
        <path
          d="M22.7318 11.4335C22.8358 11.0452 23.2349 10.8148 23.6232 10.9188L36.964 14.4935C37.3523 14.5975 37.5827 14.9966 37.4787 15.3849L32.1821 35.1519C32.0781 35.5402 31.679 35.7706 31.2907 35.6666L17.9499 32.0919C17.5616 31.9879 17.3312 31.5888 17.4352 31.2005L22.7318 11.4335Z"
          fill="#D9D4FF"
        />
        <path
          d="M25.0454 13L33.7864 15.1436"
          stroke="#1A1538"
          strokeWidth="0.689974"
          strokeLinecap="round"
        />
        <path
          d="M29.7254 23.2301C30.1619 23.6484 29.9819 24.3824 29.4014 24.5513L25.1695 25.7824C24.589 25.9513 24.0434 25.4285 24.1874 24.8413L25.2371 20.5608C25.3811 19.9736 26.1067 19.7625 26.5432 20.1808L29.7254 23.2301Z"
          fill="#1A1538"
        />
        <rect
          y="9.30469"
          width="19.7919"
          height="26.2828"
          rx="2.18354"
          transform="rotate(-15 0 9.30469)"
          fill="#D9D4FF"
          stroke={theme.palette.primary.main}
          strokeWidth="0.689974"
        />
        <path
          d="M2.9241 11.9726C2.82006 11.5843 3.05048 11.1852 3.43876 11.0812L16.7795 7.5065C17.1678 7.40246 17.5669 7.63288 17.671 8.02116L22.8918 27.5054C22.9958 27.8937 22.7654 28.2928 22.3771 28.3969L9.03632 31.9715C8.64804 32.0756 8.24893 31.8451 8.14489 31.4568L2.9241 11.9726Z"
          fill="white"
        />
        <path
          d="M6 11.9766L14.6933 9.64719"
          stroke="#1A1538"
          strokeWidth="0.689974"
          strokeLinecap="round"
        />
        <path
          d="M15.4226 17.3331C16.0066 17.4896 16.2022 18.2195 15.7747 18.647L12.6582 21.7635C12.2307 22.191 11.5007 21.9954 11.3443 21.4114L10.2036 17.1543C10.0471 16.5703 10.5815 16.0359 11.1654 16.1924L15.4226 17.3331Z"
          fill="#1A1538"
        />
      </g>
      <defs>
        <clipPath id="clip0_990_21683">
          <rect width="40.6513" height="40" fill="white" transform="translate(0 0.449219)" />
        </clipPath>
      </defs>
    </svg>
  );
};
