import { VenueProperties } from '../../../api/models/venue';
import * as Yup from 'yup';
import { CommonStrings } from '../../../common/localization/en';

export const MAX_LENGTH = {
  title: 84,
  subtitle: 160,
};

export const validationSchemaBrandingStyle = () =>
  Yup.object().shape({
    brandColor: Yup.string().required('Required'),
    title: Yup.string()
      .min(3, 'Length must be at least 3')
      .max(MAX_LENGTH.title, CommonStrings.ExceededCharLimit),
    subtitle: Yup.string()
      .min(3, 'Length must be at least 3')
      .max(MAX_LENGTH.subtitle, CommonStrings.ExceededCharLimit),
    alignment: Yup.string(),
  });

export interface UploadFiles {
  mobile: {
    original: File | null;
    cropped: File | null;
  };
  tablet: {
    original: File | null;
    cropped: File | null;
  };
  desktop: {
    original: File | null;
    cropped: File | null;
  };
}
export enum WebAppCardTypes {
  Welcome = 'webapp.cards-welcome',
  Tips = 'webapp.cards-tip',
  Educational = 'webapp.cards-edu',
}

export enum CardTypes {
  Mobile = 'mobile',
  Desktop = 'desktop',
  Tablet = 'tablet',
}

export interface CardImage {
  original: string | null;
  cropped: string | null;
  cropInfo: any | null;
}

export interface Card {
  mobile: CardImage;
  tablet: CardImage;
  desktop: CardImage;
  title?: string;
  subtitle?: string;
  shotTimeSlotIncrement?: string;
  alignment?: string;
}

export interface Cards {
  [WebAppCardTypes.Welcome]: Card;
  [WebAppCardTypes.Tips]: Card;
  [WebAppCardTypes.Educational]: Card;
}

export function getSteps() {
  return ['Welcome Card', 'Tip Card'];
}
export function getTGSteps() {
  return ['Welcome Card', 'Shot Time slots'];
}

export function getTGStep1Title() {
  return 'Shot Time Increments';
}

export enum TimeSlotsCaptions {
  Every10 = 'Every 10 min',
  Every15 = 'Every 15 min',
  Every20 = 'Every 20 min',
  Every30 = 'Every 30 min',
}

export const timeSlotsValues = ['10', '15', '20', '30'];

export const timeSlotsValuesWithCaptions: { [key: string]: string } = {
  '10': TimeSlotsCaptions.Every10,
  '15': TimeSlotsCaptions.Every15,
  '20': TimeSlotsCaptions.Every20,
  '30': TimeSlotsCaptions.Every30,
};

export const TimeSlotsCaptionsValues: { [key: string]: string[] } = {
  '10': ['10:10 AM', '10:20 AM', '10:30 AM'],
  '15': ['10:15 AM', '10:30 AM', '10:45 AM'],
  '20': ['10:20 AM', '10:40 AM', '11:00 AM'],
  '30': ['10:30 AM', '11:00 AM', '11:30 AM'],
};

export const TGStep1Caption =
  'Choose a shot time increment that best organizes shot times for your golfers to quickly find their shot.';

export const getOldCards = (type: WebAppCardTypes, properties: VenueProperties) => {
  switch (type) {
    case WebAppCardTypes.Welcome: {
      return properties?.['webapp.welcome-video'];
    }
    case WebAppCardTypes.Educational: {
      return Array.isArray(properties?.['webapp.loading-cards'])
        ? properties?.['webapp.loading-cards'][0]?.['tip-media-url']
        : properties?.['webapp.loading-cards']?.['tip-media-url'];
    }
    case WebAppCardTypes.Tips: {
      return Array.isArray(properties?.['webapp.tips'])
        ? properties?.['webapp.tips'][0]?.['tip-media-url']
        : properties?.['webapp.tips']?.['tip-media-url'];
    }
  }
};
