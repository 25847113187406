import { FC } from 'react';
import { Box, Grid } from '@mui/material';

import { VenueWizardPreviewStepStrings } from '../../../../../../../common/localization/en';
import { useStyles } from './PreviewOrgInfo.helper';
import { SectionTitle } from '../../../../../../venuewizard/wizardSteps/Steps.style';

interface PreviewOrgInfoProps {
  companyName: string;
  teamName: string;
}

export const PreviewOrgInfo: FC<PreviewOrgInfoProps> = ({ companyName, teamName }) => {
  const classes = useStyles();
  return (
    <Box mt={2}>
      <Grid container>
        <Grid item xs={12}>
          <SectionTitle>{VenueWizardPreviewStepStrings.OrganizationInfo}</SectionTitle>
        </Grid>
        <Box mt={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={8}>
                <Grid container>
                  <Grid item xs={4}>
                    <p className={classes.textLabel}>{VenueWizardPreviewStepStrings.Company}</p>
                  </Grid>

                  <Grid item xs={8}>
                    <p className={classes.textValue}>{companyName}</p>
                  </Grid>

                  <Grid item xs={4}>
                    <p className={classes.textLabel}>{VenueWizardPreviewStepStrings.Team}</p>
                  </Grid>
                  <Grid item xs={8}>
                    <p className={classes.textValue}>{teamName}</p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
};
