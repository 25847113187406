export enum SettingsEvents {
  video_app_sub_navigation_tab_click = 'video_app_sub_navigation_tab_click',

  invites_welcome_modal_open = 'invites_welcome_modal_open',
  invites_welcome_modal_closed = 'invites_welcome_modal_closed',
  invites_cancel_button_click = 'invites_cancel_button_click',
  invites_save_button_click = 'invites_save_button_click',
  invites_import_file_button_click = 'invites_import_file_button_click',
  invites_import_manually_button_click = 'invites_import_manually_button_click',
  invites_open_sample_sms_modal_button_click = 'invites_open_sample_sms_modal_button_click',
  invites_sample_sms_modal_closed = 'invites_sample_sms_modal_closed',
  invites_sample_sms_send = 'invites_sample_sms_send',

  invites_open_sample_sms_modal_closed = 'invites_open_sample_sms_modal_closed',

  invites_sms_input_changed = 'invites_sms_input_changed',

  qr_code_use_company_logo_button_click = 'qr_code_use_company_logo_button_click',
  qr_code_download_button_click = 'qr_code_download_button_click',
  qr_code_logo_was_updated = 'qr_code_logo_was_updated',
  qr_code_color_was_updated = 'qr_code_color_was_updated',
  qr_code_cancel_button_click = 'qr_code_cancel_button_click',
  qr_code_save_button_click = 'qr_code_save_button_click',
  qr_code_displaying_was_updated = 'qr_code_displaying_was_updated',

  email_required_set = 'email_required_set',
}
