import { Breadcrumbs, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from '../../../../store';
import { selectItemById } from '../../../../store/slices/organizationVenueSlice';

import { useStyles } from './topBarBreadCrumbs.helper';

export const TopBarBreadcrumbs: FC = () => {
  const styles = useStyles();
  const { id, venueId } = useParams<{ id: string; venueId: string }>();
  const venue = useTypedSelector((state) =>
    selectItemById(state.OrganizationVenueSlice.items, venueId),
  );

  if (!id) {
    return null;
  }

  return (
    <div>
      <Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
        {venue && (
          <Typography className={styles.text} color="textPrimary">
            {venue.name}
          </Typography>
        )}
      </Breadcrumbs>
    </div>
  );
};
