import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '44px',
    // background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '10px 25px',
  },
  tag: {
    padding: '4px 8px',
    borderRadius: '16px',
    backgroundColor: '#F4F4F4',
    color: '#555555',
    fontSize: '11px',
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  active: {
    color: '#5925DC',
    backgroundColor: '#F4F3FF',
  },
  saveButton: {
    color: '#000',
    fontSize: '12px',
    fontWeight: 400,
  },
  nameInput: {
    '& div': {
      borderRadius: '8px',
    },

    '& input': {
      height: '32px',
      padding: '0 14px',
      width: '146px',
      fontSize: '11px',
      color: theme.palette.primary.main,
    },
  },
  saveWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  statusOption: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    gap: '8px',
    fontSize: '11px',
    fontWeight: 500,
    color: '#101828',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: '0.11s',
    width: '161px',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  pricingPlanOptions:{
    display: 'flex',
    alignItems: 'center',
    padding: '4px 10px',
    gap: '8px',
    fontSize: '11px',
    fontWeight: 500,
    color: '#101828',
    cursor: 'pointer',
    borderRadius: '6px',
    transition: '0.11s',
    width: '239px',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  clipsOption: {
    width: '284px',
    height: '32px',
    lineHeight: '24px',
    padding: '4px 10px',
    borderRadius: '6px',
    fontSize: '11px',
    fontWeight: 500,
    color: '#101828',
    cursor: 'pointer',
    transition: '0.11s',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  customRangeTitle: {
    borderTop: '2px solid #F9FAFB',
    padding: '4px 10px',
    color: '#8B89A0',
    fontSize: '10px',
    fontWeight: 500,
    height: '32px',
    lineHeight: '24px',
  },
  clipsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    padding: '0 8px 12px 8px',
  },
}));
