import React from 'react';

export const IconSuccessGreen = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 0C6.735 0 0 6.735 0 15C0 23.265 6.735 30 15 30C23.265 30 30 23.265 30 15C30 6.735 23.265 0 15 0ZM22.17 11.55L13.665 20.055C13.455 20.265 13.17 20.385 12.87 20.385C12.57 20.385 12.285 20.265 12.075 20.055L7.83 15.81C7.395 15.375 7.395 14.655 7.83 14.22C8.265 13.785 8.985 13.785 9.42 14.22L12.87 17.67L20.58 9.96C21.015 9.525 21.735 9.525 22.17 9.96C22.605 10.395 22.605 11.1 22.17 11.55Z"
        fill="#67CE67"
      />
    </svg>
  );
};
