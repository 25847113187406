import React from 'react';

export const MagnifierIcon = () => {
  return (
    <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6216 19.3273C14.2053 19.3273 17.1105 16.0994 17.1105 12.1175C17.1105 8.13565 14.2053 4.90771 10.6216 4.90771C7.03796 4.90771 4.13281 8.13565 4.13281 12.1175C4.13281 16.0994 7.03796 19.3273 10.6216 19.3273Z"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2207 17.2283L18.999 21.4264"
        stroke="#8B89A0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
