import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderRadius: '6px',
  },
  dayItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '194px',
    cursor: 'pointer',
    position: 'relative',
    boxSizing: 'content-box',

    '&:first-child': {
      borderTopLeftRadius: '6px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '6px',
    },
  },
  rectangleSelected: {
    position: 'absolute',
    zIndex: 2,
    top: '-6px',
    left: '-8px',
    height: '1px',
  },
  iconUnchecked: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    border: '2px solid #D9D9D9',
    position: 'absolute',
    left: '8px',
  },
  iconChecked: {
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: '#12B76A',
    position: 'absolute',
    left: '8px',
  },
}));
