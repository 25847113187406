import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F5F6F8',
    borderRadius: '10px',
    padding: '8px 10px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '8px',
    alignItems: 'center',
    width: 'auto',
    minWidth: '104px',
    '& svg': {
      color: '#464c71',
    },
  },
  points: {
    width: '56px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '12px',
    lineHeight: '14px',
    borderRadius: '6px',
    backgroundColor: '#FFF',
    textAlign: 'center',
  },
}));

interface Props {
  icon: JSX.Element;
  points: number;
  name?: string;
}
export const PointsFrame: FC<Props> = ({ icon, points, name }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {name && <Typography>{name}</Typography>}
      <Box>{icon}</Box>
      <Typography className={classes.points}>{points}</Typography>
    </Box>
  );
};
