import _omit from 'lodash/omit';

import { LocalMessageVariables } from '../../common/components/SMS/SMS.helper';
import { useClientType } from './useClientType';
import { useTypedSelector } from '../../store';
import { useVenueProperty } from '../../hooks/useVenueProperty';
import { VenueProperties } from '../../api/models/venue';

interface UseSmsTemplateVariablesConfig {
  ignoreVariables?: LocalMessageVariables[];
  rewardName?: string;
}

export function useSmsTemplateVariables(
  { ignoreVariables, rewardName }: UseSmsTemplateVariablesConfig = { ignoreVariables: [] },
) {
  const { isHealthCare } = useClientType();
  const { venue } = useTypedSelector((state) => state.venue);
  const me = useTypedSelector((state) => state.me);
  const userName = me.name.split(' ')[0];

  const { value: incentive } = useVenueProperty<VenueProperties['webapp.incentive.config']>({
    property: 'webapp.incentive.config',
  });

  const result = isHealthCare
    ? {
        [LocalMessageVariables.PracticeName]: venue.name || 'Company',
        [LocalMessageVariables.PatientName]: userName || 'Patient',
        [LocalMessageVariables.RewardName]: rewardName || incentive?.title || '$50 Gift Card',
      }
    : {
        [LocalMessageVariables.CompanyName]: venue.name || 'Company',
        [LocalMessageVariables.CreatorName]: userName || 'Creator',
        [LocalMessageVariables.RewardName]: rewardName || incentive?.title || '$50 Gift Card',
      };

  // @ts-ignore
  return _omit(result, ignoreVariables || []) as Record<LocalMessageVariables, string>;
}
