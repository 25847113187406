import React from 'react';

const PublishedIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.00004" cy="9" r="7.91667" stroke="#027A48" strokeWidth="1.5" />
      <path d="M16.9167 9H1.08337" stroke="#027A48" strokeWidth="1.5" />
      <ellipse cx="9" cy="9" rx="3.7605" ry="7.91667" stroke="#027A48" strokeWidth="1.5" />
    </svg>
  );
};

export default PublishedIcon;
