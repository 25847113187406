import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import { useAppDispatch } from '../../../store';
import { postCreateSocialVerse } from '../../../store/slices/socialVerseSlice';
import { CardsIcon } from '../../../common/assets/newDesign/WebAppIcons/CardsIcon';
import { TextFieldComponent } from '../../../common/components/TextField/TextField';
import { TypeSocialVerse } from '../../../common/constants/constants';
import { FSModal } from '../../../common/components/modal/Modal';
import { useStyles } from './NameSocialvModal.style';
import * as Yup from 'yup';
import { NameSocialvModalStrings } from '../../../common/localization/en';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { SocialVerseEventNames } from '../../../common/constants/events/socialVerseEvents';
import { cardTypes } from './CreateSocialvModal.helper';
import { DEFAULT_SV_GLOW_COLOR } from '../../../common/constants/defaults';
import { SocialVerseStrings } from '../../../common/localization/en';

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
  venueId: string;
  viewMode: TypeSocialVerse;
  handleAddVideos: (id: string) => void;
}

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().min(3, 'Name must be at least 3 characters long').max(42).required(),
  });

export const NameSocialvModal: FC<Props> = ({
  isModalOpen,
  onClose,
  venueId,
  viewMode,
  handleAddVideos,
}) => {
  const icon = cardTypes.find((item) => item.id === viewMode)?.icon;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { values, handleChange, handleBlur, errors, setFieldTouched, dirty, resetForm } = useFormik(
    {
      initialValues: { name: '' },
      enableReinitialize: true,
      validateOnChange: true,
      validateOnBlur: false,
      validationSchema,
      onSubmit: () => {},
    },
  );
  const { trackEvent } = useTrackEvent();

  const handleSubmit = async () => {
    const isEducational = viewMode === TypeSocialVerse.Educational;
    const res = await dispatch(
      postCreateSocialVerse({
        accountId: venueId,
        name: values.name,
        viewMode: viewMode,
        showTitle: true,
        keywordFilterEnabled: true,
        title: isEducational ? SocialVerseStrings.WatchLearn : '',
        showUserInfo: true,
        details: {
          withGlow: false,
          glowColor: DEFAULT_SV_GLOW_COLOR,
          captionEnabled: true,
          numberOfGridRows: 2,
          withHoverPreview: true,
          showSupportingText: true,
          numberOfPerspectiveCards: 3,
          showVideoCounter: true,
        },
      }),
    );
    onClose();
    handleAddVideos(res.payload.id);
    trackEvent(SocialVerseEventNames.newSocialVerseCreate, { socialVerseId: res.payload.id });
  };

  return (
    <FSModal modalIsOpen={isModalOpen} onClose={onClose} width="505px" rounded="16px" padding="0">
      <Grid item xs={12}>
        <Grid container flexDirection="row" className={classes.wrapperMainContent}>
          <Box className={classes.iconWrapper}>{icon ? icon : <CardsIcon />}</Box>
          <Grid container flexDirection="column">
            <Typography className={classes.title}>
              {NameSocialvModalStrings.CreateNewModalTitle}
            </Typography>
            <Typography className={classes.subtitle}>
              {NameSocialvModalStrings.CreateNewModalSubTitle}
            </Typography>
            <Grid container justifyContent={'center'} margin="0 auto" padding="0 14px">
              <TextFieldComponent
                className={classes.svNameInput}
                placeholder="Social Verse Name..."
                name="name"
                value={values.name || ''}
                onChange={(event) => {
                  handleChange(event);
                  trackEvent(SocialVerseEventNames.newSocialVerseNameTyped, {
                    value: event.target.value,
                  });
                }}
                onBlur={handleBlur}
                setFieldTouched={setFieldTouched}
                errorText={errors.name}
                fullWidth
                helperText
              />
            </Grid>
          </Grid>
          <Box className={classes.buttonsWrapper}>
            <Button
              variant="text"
              className={classes.buttonCancel}
              onClick={() => {
                resetForm();
                onClose();
                trackEvent(SocialVerseEventNames.newSocialVerseCancel);
              }}
            >
              <Typography variant="body2">{NameSocialvModalStrings.Cancel}</Typography>
            </Button>
            <Button
              variant="contained"
              className={classes.buttonConfirm}
              onClick={handleSubmit}
              disabled={!dirty || !!errors.name}
              id="create-sv-btn-modal"
            >
              <Typography variant="body2">{NameSocialvModalStrings.CreateSocialVerse}</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </FSModal>
  );
};
