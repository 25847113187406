import React, { forwardRef } from 'react';
import { useAccountSidebarButtonStyles } from './AccountSidebarButton.styles';

interface AccountSidebarButtonProps extends React.ComponentProps<'button'> {
  isActive: boolean;
}

export const AccountSidebarButton = forwardRef(
  (
    { isActive, className, ...restProps }: AccountSidebarButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const classes = useAccountSidebarButtonStyles();

    return (
      <button
        ref={ref}
        {...restProps}
        className={`${classes.sidebarButton} ${
          isActive ? classes.activeSidebarButton : classes.inactiveSidebarButton
        } ${className}`}
      />
    );
  },
);
