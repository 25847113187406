import { FC } from "react";

export const BellIcon:FC = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.19684 7.54831L5.9538 9.41922C5.88479 9.95048 5.69576 10.4591 5.40104 10.9064L4.43054 12.3796C4.06178 12.9394 3.86523 13.595 3.86523 14.2653C3.86523 16.1584 5.39987 17.693 7.29293 17.693H16.7446C18.6173 17.693 20.1353 16.1749 20.1353 14.3023C20.1353 13.6092 19.923 12.9328 19.5268 12.3642L18.5277 10.9299C18.2058 10.4678 17.9999 9.93503 17.9274 9.37658L17.6899 7.54831C17.3148 4.66077 14.8552 2.5 11.9434 2.5C9.03155 2.5 6.57194 4.66077 6.19684 7.54831Z"
      stroke="#8B89A0"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M9.49219 19.915C9.93861 20.8521 10.8944 21.4996 12.0015 21.4996C13.1085 21.4996 14.0643 20.8521 14.5107 19.915"
      stroke="#8B89A0"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

