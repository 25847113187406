import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { TableDTO } from '../storeModels';
import { httpClient } from '../../services/httpClient/httpClient';
import { getTableSorting, tableLoadFulFilled, tableLoadPending, tableLoadRejected } from './utils';
import { defaultPagination } from '../../common/constants/constants';
import { VenueAPIModel, VenueApiModelWithStats } from '../../api/models/venue';
import { VenueEndpoints } from '../../api/endpoints';
import { ClientTypes, GetTableDataResponse } from '../../api/models/common';
import { MarketType, MarketTypeModel } from '../../api/models/analytics';
import {
  SearchFilterApiModel,
  SearchFilterAttributes,
  SearchOperationsType,
} from '../../api/models/searchFilter';
// import { SearchOperationType } from '../../api/models/searchFilterModels';

interface GetVenuesOptions {
  params?: {
    orgId?: string;
    clientType?: string;
    page?: string;
    size?: number;
    search?: string;
    sort?: string;
  };
  withStats?: boolean;
  groupId?: string | null;
  includeTestGroups?: boolean;
  deactivated?: boolean;
}
interface GetVenuesWithSearchFilterOptions {
  params?: {
    orgId?: string;
    clientType?: string;
    page?: string;
    size?: number;
    search?: string;
    sort?: string;
  };
  filter: SearchFilterApiModel | SearchFilterAttributes[];
  groupId?: string | null;
  includeTestGroups?: boolean;
  deactivated?: boolean;
}

interface GetVenuesWithSearchFilterOptionsRequest {
  orgId?: string;
  clientType?: string;
  page?: string;
  size?: string;
  search?: string;
  sort?: string;
  attributes?: {
    name: string;
    operation: SearchOperationsType;
    value: any;
  }[];
  withStats?: boolean;
  groupId?: string | null;
  includeTestGroups?: boolean;
  deactivated?: boolean;
}

interface OrgManagerVenues extends TableDTO<VenueApiModelWithStats> {
  analytics: MarketTypeModel;
  groupId: string | null;
  isArchivedAccount: boolean;
}

export const selectItemById = (items: VenueAPIModel[] = [], id: string) =>
  items.find((item) => item.id === id);

const initialState: OrgManagerVenues = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  property_1: ClientTypes.MARKETING,
  analytics: {
    [MarketType.Accounts]: 0,
    [MarketType.Users]: 0,
    [MarketType.Videos]: 0,
    [MarketType.VideoViews]: 0,
    [MarketType.CtaClicks]: 0,
    [MarketType.AverageUsers]: 0,
    [MarketType.AverageVideos]: 0,
    [MarketType.AverageVideoViews]: 0,
    [MarketType.AverageEngagements]: 0,
    [MarketType.AverageConversionRate]: 0,
  },
  search: '',
  groupId: null,
  isArchivedAccount: false,
};

export const getVenueListApiCall = (options: GetVenuesOptions) => {
  const params: GetVenuesOptions = {
    ...options.params,
    withStats: true,
    groupId: options.groupId || null,
    includeTestGroups: true,
    deactivated: options.deactivated,
  };

  if (options.includeTestGroups === false) {
    // pass includeTestGroups only if it set to true
    delete params?.includeTestGroups;
  }

  return httpClient.get<GetVenuesOptions, GetTableDataResponse<VenueApiModelWithStats>>({
    url: VenueEndpoints.CreateVenue,
    requiresToken: true,
    params,
  });
};

export const getAccountsBySearchFilterAPICall = (options: GetVenuesWithSearchFilterOptions) => {
  const params: GetVenuesOptions = {
    ...options.params,
    withStats: true,
    groupId: options.groupId || null,
    includeTestGroups: true,
    deactivated: options.deactivated,
  };

  if (options.includeTestGroups === false) {
    // pass includeTestGroups only if it set to true
    delete params?.includeTestGroups;
  }
  return httpClient.post<
    GetVenuesWithSearchFilterOptionsRequest,
    GetTableDataResponse<VenueApiModelWithStats>
  >({
    url: VenueEndpoints.GetAccountsWithSearchFilter,
    requiresToken: true,
    payload: {
      attributes:
        'id' in options.filter ? [...options.filter.filter.attributes] : [...options.filter],
    },
    params: {
      page: options.params?.page?.toString(),
      size: options.params?.size?.toString(),
      sort: options.params?.sort?.toString(),
      search: options.params?.search,
      withStats:params.withStats,
      groupId:params.groupId,
      deactivated:params.deactivated
    },
  });
};
export const getAccountsBySearchFilter = createAsyncThunk(
  'organizationVenueSlice/getVenueListWithSearchFilter',
  async (options: GetVenuesWithSearchFilterOptions, { rejectWithValue }) => {
    try {
      return getAccountsBySearchFilterAPICall(options);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
export const getVenueList = createAsyncThunk(
  'organizationVenueSlice/getVenueList',
  async (options: GetVenuesOptions, { rejectWithValue }) => {
    try {
      return getVenueListApiCall(options);
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const getAnalyticsMarket = createAsyncThunk(
  'organizationVenueSlice/getAnalyticsMarket',
  async (options: ClientTypes, { rejectWithValue }) => {
    try {
      return await httpClient.get<undefined, MarketTypeModel>({
        url: VenueEndpoints.GetAnalyticsMarket + options,
        requiresToken: true,
      });
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const orgManagerVenueSlice = createSlice({
  name: 'organizationVenueSlice',
  initialState,
  reducers: {
    resetPage(state) {
      state.page = initialState.page;
    },
    goToSelectedPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setMarket(state, action: PayloadAction<string>) {
      return {
        ...initialState,
        property_1: action.payload,
      };
    },
    updateSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
      state.page = defaultPagination.page;
    },
    updateSort(state, action: PayloadAction<string>) {
      const sort = getTableSorting(current(state), action.payload);
      return {
        ...initialState,
        sort,
        search: state.search,
        size: state.size,
        property_1: state.property_1,
        groupId: state.groupId,
        isArchivedAccount: state.isArchivedAccount,
      };
    },
    updateSize(state, action: PayloadAction<number>) {
      state.size = action.payload;
      state.page = initialState.page;
    },

    setGroupId(state, action: PayloadAction<string | null>) {
      state.groupId = action.payload;
    },
    setIsArchivedAccount(state, action: PayloadAction<boolean>) {
      state.isArchivedAccount = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (reducersBuilder) => {
    reducersBuilder.addCase(getVenueList.rejected, (state) => {
      tableLoadRejected(state);
    });
    reducersBuilder.addCase(getVenueList.pending, (state) => {
      tableLoadPending(state);
    });
    reducersBuilder.addCase(getVenueList.fulfilled, (state, { payload }) => {
      tableLoadFulFilled(state, payload);
    });
    reducersBuilder.addCase(getAnalyticsMarket.fulfilled, (state, { payload }) => {
      state.analytics = payload;
    });
    reducersBuilder.addCase(getAccountsBySearchFilter.rejected, (state) => {
      tableLoadRejected(state);
    });
    reducersBuilder.addCase(getAccountsBySearchFilter.pending, (state) => {
      tableLoadPending(state);
    });
    reducersBuilder.addCase(getAccountsBySearchFilter.fulfilled, (state, { payload }) => {
      tableLoadFulFilled(state, payload);
    });
    //getAccountsBySearchFilter
  },
});

export const {
  reset,
  goToSelectedPage,
  resetPage,
  setMarket,
  updateSearch,
  updateSort,
  updateSize,
  setGroupId,
  setIsArchivedAccount,
} = orgManagerVenueSlice.actions;
export default orgManagerVenueSlice.reducer;
