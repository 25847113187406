import React from 'react';

import { escapeRegExp } from '../../../services/utilities';

interface HighlightedTextProps {
  text: string;
  highlights: string[];
}

export const HighlightedText: React.FC<HighlightedTextProps> = ({ text, highlights }) => {
  const parts = text.split(new RegExp(`(${highlights.map(escapeRegExp).join('|')})`, 'gi'));

  return (
    <span>
      {parts.map((part, i) => (
        <span key={i} style={highlights.includes(part) ? { fontWeight: 'bold' } : {}}>
          {part}
        </span>
      ))}
    </span>
  );
};
