import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useInviteTeamMemberStyles = makeStyles((theme: Theme) => ({
  MainTitle: {
    color: theme.palette.common.black,
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '60px',
    letterSpacing: '-0.02em',
    textAlign: 'left',
  },
  MainDescription: {
    color: theme.palette.common.black,
    fontSize: '15px',
    lineHeight: '30px',
    textAlign: 'left',
  },
  CustomTextField: {
    '& .MuiFormLabel-root': {
      color: theme.palette.common.black,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      letterSpacing: '0.02em',
      marginTop: '2px',
      marginBottom: '8px',
    },
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
      marginBottom: '2px',
    },
  },
  InputField: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '12px',
    marginBottom: '60px',
    maxHeight: '42px',
    // '&:focus, :focus-within, :target': {
    //   border: `2px solid ${theme.palette.primary.main}`,
    //   borderRadius: '12px',
    // },
    // '& .react-tel-input': {
    //   maxHeight: '42px',
    // },
    // '& input': {
    //   maxHeight: '42px',
    //   border: 'none !important',
    // },
    // '& fieldset': {
    //   border: 'none !important',
    // },
    // '& .MuiFormHelperText-root': {
    //   marginLeft: '14px',
    //   marginRight: '14px',
    // },
  },
  ModalContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100vw',
    height: '100vh',
    backgroundColor: theme.palette.common.white,
  },
  ModalHeader: {
    padding: '18px 30px',
  },
  Main: {
    flex: 1,
  },
  ModalTitle: {
    padding: 0,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  Revoke: {
    marginBottom: 31,
  },
  EmailTags: {
    padding: '14px 20px',
    backgroundColor: '#F5F6F8',
    // backgroundColor: theme.palette.common.white,
    borderRadius: '12px',
  },
  InfoBlock: {
    marginBottom: 17,
    alignItems: 'baseline',
    display: 'flex',
    padding: '10px 40px 10px 10px',
    backgroundColor: '#F3F6FE',
    borderRadius: 15,
  },
  InfoIcon: {
    marginRight: 18,
    flexShrink: 0,
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    backgroundColor: '#3067FB',
    color: theme.palette.common.white,
    borderRadius: '50%',
  },
  InfoTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: '#3067FB',
    // marginBottom: 8,
  },
  InfoDescription: {
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    // color: '#3067FB',
    // marginBottom: 8,
  },
  LabelEmail: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    marginTop: '2px',
    marginBottom: 8,
  },
  LabelTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginTop: 6,
    // marginBottom: 16,
  },
  LabelDescription: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.02em',
  },
  ModalFooter: {
    padding: '18px',
  },
  Icon: {
    marginRight: 10,
    width: 50,
    height: 50,
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    opacity: '0.1',
  },
  DeleteModal: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.common.white,
    width: 400,
    padding: '30px',
    borderRadius: '15px',
  },
  DeleteModalTitle: {
    padding: 0,
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '30px',
    letterSpacing: '0.02em',
  },
  ModalDescription: {
    marginBottom: 50,
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '21px',
    letterSpacing: '0.02em',
  },
}));
