import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  btnsWrapper: {
    padding: '0',
    borderRadius: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  removeButton: {
    height: '40px',
    width: '40px',
  },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px',
  },
  spinner: {
    height: '24px !important',
    width: '24px !important',
  },
}));
