import { useTypedSelector } from '../../../../store';
import { ClientCategory, useClientType } from '../../../../services/hooks/useClientType';
import { default_sms_reward, default_sms_reward_TruBlu } from './RewardDefaultSMSTemplates';
import {
  awareness_default_sms_reward,
  awareness_default_sms_reward_TruBlu,
} from './AwarenessRewardDefaultSMSTemplates';
import {
  SvHealthInviteStrings,
  SvInviteStrings,
  TravelInviteStrings,
  UwmInviteStrings,
} from '../../../../common/localization/en';
import { InviteFollowUpMessage } from '../../../../api/models/campaigns';

export const useSetDefaultSMSTemplates = (isAwarenessCampaign?: boolean) => {
  const { isNullReward } = useTypedSelector((state) => state.createCampaign);
  const { clientCategory } = useClientType();

  const { isHealthCare } = useClientType();

  const isNoIncentive = isAwarenessCampaign || isNullReward;

  const defaultSmsType = `defaultInvite`;

  const defaultRewardMessage = isHealthCare
    ? default_sms_reward_TruBlu[defaultSmsType]
    : default_sms_reward[defaultSmsType];

  const awareness_defaultRewardMessage = isHealthCare
    ? awareness_default_sms_reward_TruBlu[defaultSmsType]
    : awareness_default_sms_reward[defaultSmsType];

  const inviteMessagesMap: Record<ClientCategory, string> = {
    UWM: UwmInviteStrings.Day1,
    SV_HEALTH: SvHealthInviteStrings.Day1,
    TRAVEL: TravelInviteStrings.Day1,
    SV: SvInviteStrings.Day1,
  };

  const followUpMessagesMap: Record<ClientCategory, InviteFollowUpMessage[]> = {
    UWM: [
      { body: UwmInviteStrings.Day3, delayInDays: 3, enabled: true },
      { body: UwmInviteStrings.Day7, delayInDays: 7, enabled: true },
      { body: UwmInviteStrings.Day14, delayInDays: 14, enabled: true },
    ],
    SV_HEALTH: [
      { body: SvHealthInviteStrings.Day3, delayInDays: 3, enabled: false },
      { body: SvHealthInviteStrings.Day7, delayInDays: 7, enabled: false },
      { body: SvHealthInviteStrings.Day14, delayInDays: 14, enabled: false },
    ],
    TRAVEL: [
      { body: TravelInviteStrings.Day3, delayInDays: 3, enabled: false },
      { body: TravelInviteStrings.Day7, delayInDays: 7, enabled: false },
      { body: TravelInviteStrings.Day14, delayInDays: 14, enabled: false },
    ],
    SV: [
      { body: SvInviteStrings.Day3, delayInDays: 3, enabled: false },
      { body: SvInviteStrings.Day7, delayInDays: 7, enabled: false },
      { body: SvInviteStrings.Day14, delayInDays: 14, enabled: false },
    ],
  };

  return {
    defaultInviteMessage: inviteMessagesMap[clientCategory],
    defaultFollowupMessages: followUpMessagesMap[clientCategory],
    defaultRewardMessage: isNoIncentive ? awareness_defaultRewardMessage : defaultRewardMessage,
  };
};
