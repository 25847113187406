import { Box, Button } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { VideoApiModel } from '../../../api/models/videos';
import { useAppDispatch } from '../../../store';
import { updateVideoThumbnail as updateCampaignVideoThumbnail } from '../../../store/slices/campaignsSlice';
import { updateVideoThumbnail } from '../../../store/slices/socialVerseSlice';
import { updateTopgolfVideoById } from '../../../store/slices/topGolfVideosSlice';
import { updateVideoById } from '../../../store/slices/videosSlice';
import { theme } from '../../../theme';
import { VideoDetailsModalStringsDv5 } from '../../localization/en';
import { useEditThumbnailStyles } from './EditThumbnail.style';
import { ThumbnailCustomization } from './ThumbnailCustomization';

interface IEditThumbnailProps {
  storyThumbnailUrl: string;
  storyAltThumbnailUrl: string;
  storyUrl: string;
  storyId: string;
  frames: ThumbnailFrame[];
  regenerateThumbnails: (interval: number) => void;
  isOpen: boolean;
  handleClose: () => void;
  isTopGolf: boolean;
  areFramesLoading: boolean;
  onUpdateThumbnail?: () => void;
}
export type Frame = { thumbnail: HTMLImageElement; time: number };

export type ThumbnailFrame = {
  thumbnail: HTMLImageElement;
  altThumbnail: HTMLImageElement;
  time: number;
};

export const EditThumbnail: React.FC<IEditThumbnailProps> = ({
  storyThumbnailUrl,
  storyUrl,
  storyId,
  frames,
  isOpen,
  handleClose,
  isTopGolf,
  regenerateThumbnails,
  areFramesLoading,
  onUpdateThumbnail,
}) => {
  const dispatch = useAppDispatch();
  const [selectedThumbnail, setSelectedThumbnail] = useState<ThumbnailFrame>();
  const styles = useEditThumbnailStyles();

  const [counter, setCounter] = useState(1);

  const handleSave = async () => {
    if (!storyUrl || !selectedThumbnail) return;
    const prevSelectedThumbnail = selectedThumbnail;
    try {
      setSelectedThumbnail(undefined);

      const thumbnailUrl = selectedThumbnail.thumbnail.src;
      const altThumbnailUrl = selectedThumbnail.thumbnail.src;

      if (isTopGolf) {
        return dispatch(
          updateTopgolfVideoById({
            id: storyId,
            update: { thumbnailUrl, altThumbnailUrl },
          }),
        );
      }

      const data = await dispatch(
        updateVideoById({ id: storyId, update: { thumbnailUrl, altThumbnailUrl } }),
      );

      const payload = data.payload as VideoApiModel;

      await dispatch(
        updateVideoThumbnail({
          id: payload.id,
          thumbnailUrl: payload.thumbnailUrl,
        }),
      );

      await dispatch(
        updateCampaignVideoThumbnail({
          id: payload.id,
          thumbnailUrl: payload.thumbnailUrl,
          altThumbnailUrl: payload.altThumbnailUrl,
        }),
      );
    } catch (error) {
      setSelectedThumbnail(prevSelectedThumbnail);
      console.error(error);
    }
    onUpdateThumbnail?.();
  };

  const editThumbnails: string[] = [];
  for (let index = 0; index < 5; index++) {
    editThumbnails.push(storyThumbnailUrl as string);
  }

  const handleRegenerateThumbnail = () => {
    setSelectedThumbnail(undefined);
    const random = Number((Math.random() * 0.3).toFixed(2));
    setCounter(counter + 0.1 + random);
    regenerateThumbnails(counter);
  };

  const saveBtnDisabled = !selectedThumbnail;

  useEffect(() => {
    return () => {
      setCounter(1);
    };
  }, []);

  return (
    <div
      className={[
        styles.ChangeTumbnailContainer,
        isOpen
          ? styles.ChangeTumbnailContainerDisplayed
          : styles.ChangeTumbnailContainerNotDisplayed,
      ].join(' ')}
    >
      <Accordion className={styles.ChangeTumbnailAccordion} expanded={true}>
        <AccordionSummary>
          <Box className={styles.IconEditThumbnailContainer}>
            <Typography>{VideoDetailsModalStringsDv5.ChangeThumbnail}</Typography>
            <Box style={{ marginBottom: '6px' }}>
              <Button
                variant="outlined"
                onClick={handleRegenerateThumbnail}
                className={styles.RefreshButton}
              >
                Refresh
              </Button>
              <Button
                color="primary"
                className={[styles.Buttons, styles.ButtonsFilled, styles.ThumbnailButton].join(' ')}
                onClick={() => {
                  handleSave();
                  handleClose();
                }}
                variant="outlined"
                disabled={saveBtnDisabled}
                style={{
                  background: saveBtnDisabled
                    ? theme.palette.primary.light
                    : theme.palette.primary.main,
                  color: 'white',
                }}
              >
                {VideoDetailsModalStringsDv5.SaveThumbnail}
              </Button>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={styles.ChangeTumbnailAccordionDetails}>
          <Box className={styles.EditThumbnailContainer}>
            <ThumbnailCustomization
              onFrameSelect={setSelectedThumbnail}
              frames={frames}
              areFramesLoading={areFramesLoading}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
