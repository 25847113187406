import * as React from 'react';

export const HitVideosCreatedGoalIcon: React.FC = () => {
  return (
    <svg width={46} height={33} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M15.73 26.94s-6.467-3.158-7.58-4.805c-1.112-1.647.523-1.74.523-1.74s-2.886-2.78-2.48-3.95c.406-1.17 1.901-.096 1.901-.096s-1.856-4.09-.98-4.502c1.63-.766 3.916 3.636 3.916 3.636s-.491-1.912.631-1.85c1.693.094 1.623 4.418 1.623 4.418s.191-1.658 1.212-1.12c1.83.967 1.235 10.009 1.235 10.009z"
          fill="#3C8F7C"
        />
        <path
          d="M14.865 16.262c-.797-.438-1.59.841-1.59.841s.08-3.377-1.613-3.471c-1.123-.062-.631 1.85-.631 1.85s-2.287-4.401-3.917-3.636l8.625 15.022c.061-1.049.704-9.74-.874-10.606zM38.983 19.329l.284-.68c.22-.5.785-.739 1.26-.533 1.198.518 1.72 1.959 1.164 3.219l-.406.921c-.178.404-.116.848.124 1.16.218.285.253.68.102 1.023l-.214.486A1.944 1.944 0 0140.079 26c-.313.094-.59.327-.735.658l-.303.688c-.336.76-1.193 1.123-1.916.81l-.413-.177c-.723-.313-1.037-1.182-.702-1.942.542-.91.5-1.588.307-2.048a2.728 2.728 0 01.026-2.148l.043-.097a2.752 2.752 0 011.687-1.512c.327-.103.668-.355.91-.903z"
          fill="#50BFA5"
        />
        <path
          d="M27.265 32.758c-.768-.985-4.978-6.266-7.724-7.146-3.03-.971-.174 2.887-.174 2.887s-5.376-5.82-7.349-5.296c-1.809.481 0 4.089 0 4.089s-2.037-1.998-4.507-1.998c-2.404 0 1.68 7.085 1.9 7.464h17.854z"
          fill="#96D9C9"
        />
        <path
          d="M45.946 11.62s-.775.99-1.231 1.379c-.457.388-.892.76-1.473 1.997-.582 1.237-.565 1.378-.72 1.697-.155.318-.966 1.566-1.028 1.815-.061.248-1.386 1.44-1.797 2.047-.41.606-1.083 1.452-1.171 1.942l.12-.003s.357-1.106 1.408-2.065c1.052-.958.99-1.2 2.018-1.58 1.028-.38 1.982-1.865 1.982-1.865s1.319-1.547 1.491-2.47c.172-.922.606-1.69.401-2.895zM45.142 9.636s-.202.721-.54 1.1c-.34.378-.606.594-.759 1.052-.153.458-.586 1.631-1.386 2.068-.8.437-1.3 2.184-1.929 2.602-.63.418-.785.787-.908 1.724-.122.938-.104 2.28-1.224 3.77l-.068-.45s.843-1.244.904-2.071c.06-.828-.096-2.135.2-2.677.298-.541-.275-.792.291-1.584.567-.792.49-1.13 1.16-1.774 0 0 .058-.024.338-.634s1.596-1.544 2.17-1.834c.575-.29 1.417-.772 1.75-1.292z"
          fill="#256653"
        />
        <path
          d="M42.34 12.793s-.804.842-.96 1.467c-.155.626-.206 1.151-.699 1.943s-.043 1.468-.32 1.904c-.278.436-.423.994-.518 1.42-.095.428-1.09 1.684-1.358 2.374-.268.69-.14.722-.14.722s.331-.917.557-1.193c.226-.276.942-1.243 1.148-1.633.207-.39.563-.59.982-1.235.419-.645.918-1.155 1.125-2.16.207-1.004.86-1.964.182-3.609zM38.88 22.057s.559-1.174 1.718-1.038c1.16.137 1.772.511 2.783-.208 1.01-.72 1.81-2.047 2.124-2.235 0 0-.353-.297-1.165.167-.812.464-1.39.824-1.862 1.095-.47.27-.729-.036-1.192.107-.388.12-.965.592-1.23.848a1.405 1.405 0 01-.287.215c-.095.053-.209.12-.284.17-.15.102-.693.864-.693.864l.089.015z"
          fill="#3C8F7C"
        />
        <path
          d="M38.533 21.747s.754-1.42.66-2.222c-.092-.801-.799-1.541-.656-1.998.143-.458.974-1.59.757-2.627 0 0-.362.797-.57.944-.207.148-1.508.638-1.54 1.672-.034 1.035 1.006 1.518 1.207 1.87.2.354.564.437.521.631-.043.194-.138.914-.421 1.58l.042.15z"
          fill="#3C8F7C"
        />
        <path
          d="M39.207 20.836l.934-1.468s.63-1.207 1.073-2.234c.442-1.027 1.126-4.341 1.126-4.341s-.804.842-.96 1.467c-.155.626-.206 1.151-.699 1.943-.492.792-.043 1.468-.32 1.904-.278.436-.423.994-.518 1.42-.095.428-1.089 1.684-1.358 2.374-.147.378-.175.558-.17.644.056-.644.892-1.71.892-1.71zM42.187 20.52c-.523-.016-.961-.153-1.657.147-.695.3-1.153.687-1.153.687s-.377.41-.546.633l-.04.055.09.015s.558-1.174 1.717-1.038c1.16.137 1.772.511 2.782-.208 1.011-.72 1.812-2.047 2.125-2.235 0 0-1.003.459-1.664.917-.66.458-1.132 1.043-1.654 1.027z"
          fill="#327F6B"
        />
        <path
          d="M38.724 15.845c-.207.147-1.508.637-1.54 1.671-.034 1.035 1.006 1.518 1.207 1.87.2.354.564.437.521.631-.043.194-.138.914-.421 1.58l.018.066a9.06 9.06 0 00.402-.958c.163-.5.13-.73.06-1.18-.067-.45-.897-1.526-1.05-2.026-.154-.5.773-1.065 1.023-1.383.251-.318.35-1.216.35-1.216s-.363.797-.57.944z"
          fill="#327F6B"
        />
        <path
          d="M21.818 32.758c.768-.985 4.978-6.266 7.724-7.146 3.03-.971.174 2.887.174 2.887s5.376-5.82 7.35-5.296c1.808.481 0 4.089 0 4.089s2.036-1.998 4.506-1.998c2.404 0-1.68 7.085-1.9 7.464H21.818z"
          fill="#96D9C9"
        />
        <path
          d="M2.82 29.674c2.242.243 4.331 1.223 6.342 2.179 1.356.645 1.288.828 1.564.924.045.016.09-.032.068-.075-.107-.208-.385-.35-.579-.47a7.588 7.588 0 00-.7-.377c-.512-.246-1.037-.468-1.56-.692-1.06-.457-2.14-.886-3.26-1.177a11.56 11.56 0 00-1.874-.33c-.012-.002-.013.016-.002.018z"
          fill="#256653"
        />
        <path
          d="M4.65 28.956c-.015.091-.111.355-.052.617.035.258.226.512.227.5.016-.001-.296-.085-.508-.35-.226-.243-.352-.668-.301-.852.053-.387.378-.575.513-.462.138.11.141.346.12.547zM6.625 29.657c-.014.091-.11.356-.05.617.034.258.225.512.226.5.016-.002-.297-.084-.508-.35-.227-.243-.352-.668-.302-.852.054-.387.38-.575.514-.462.138.11.141.346.12.547zM8.633 30.543c-.014.09-.11.355-.051.617.035.258.225.512.227.5.016-.002-.297-.085-.508-.35-.227-.243-.352-.668-.302-.852.054-.387.38-.576.513-.463.138.11.142.346.121.548z"
          fill="#256653"
        />
        <path
          d="M4.059 30.784c.04-.075.12-.323.31-.483.171-.17.454-.253.448-.243.012.01-.265-.102-.57-.023-.304.055-.636.299-.703.462-.179.314-.045.633.117.624.164-.005.3-.177.398-.337zM6.114 31.504c.041-.075.12-.323.31-.483.171-.17.455-.253.449-.243.011.01-.266-.102-.57-.023-.305.054-.637.299-.703.462-.18.314-.046.633.117.623.164-.004.3-.176.397-.336zM2.494 30.042c.036-.041.118-.187.26-.262a.67.67 0 01.314-.094c.006.007-.154-.099-.356-.09-.198-.005-.44.105-.503.198-.155.175-.113.392-.01.407.104.019.212-.071.295-.159z"
          fill="#256653"
        />
        <path
          d="M2.731 29.085c.009.055.002.221.082.358.067.14.22.25.218.243.01-.003-.182.005-.35-.108-.172-.098-.32-.316-.324-.429-.04-.228.11-.392.207-.352.098.038.143.17.167.288z"
          fill="#256653"
        />
        <path
          d="M35.234 28.174a15.612 15.612 0 000-22.235C29.033-.2 18.978-.2 12.776 5.94a15.612 15.612 0 000 22.235c6.202 6.14 16.257 6.14 22.458 0z"
          fill="#B75742"
        />
        <path
          d="M34.245 27.195a14.237 14.237 0 000-20.276c-5.655-5.6-14.824-5.6-20.48 0a14.237 14.237 0 000 20.276c5.656 5.599 14.825 5.599 20.48 0z"
          fill="#F47458"
        />
        <path
          d="M32.616 25.582a11.972 11.972 0 000-17.05c-4.755-4.709-12.466-4.709-17.222 0a11.972 11.972 0 000 17.05c4.756 4.708 12.467 4.708 17.222 0z"
          fill="#FCF5EB"
        />
        <path
          d="M30.478 23.464a9 9 0 000-12.817c-3.575-3.539-9.37-3.539-12.946 0a9 9 0 000 12.817c3.575 3.54 9.371 3.54 12.946 0z"
          fill="#F47458"
        />
        <path
          d="M28.602 21.606a6.39 6.39 0 000-9.101c-2.539-2.513-6.654-2.513-9.193 0a6.39 6.39 0 000 9.101c2.539 2.514 6.654 2.514 9.193 0z"
          fill="#FCF5EB"
        />
        <path
          d="M25.663 17.056a1.65 1.65 0 01-1.657 1.641 1.65 1.65 0 01-1.658-1.64 1.65 1.65 0 011.658-1.642 1.65 1.65 0 011.657 1.641z"
          fill="#B75742"
        />
        <path
          d="M34.183 8.91l-.059-1.066L37.77 5.02l-.19 1.543-3.573 2.767.176-.421z"
          fill="#184655"
        />
        <path
          d="M34.453 9.269l1.03.313L39.13 6.76 37.57 6.57l-3.573 2.767.456-.068z"
          fill="#12353D"
        />
        <path
          d="M23.881 17.192a.245.245 0 00.342.041L34.495 9.28l-.3-.38-10.272 7.954a.24.24 0 00-.042.339z"
          fill="#184655"
        />
        <path
          d="M14.91 33.015A44.041 44.041 0 005.921 24c-.035-.027-.083.034-.047.061a43.982 43.982 0 018.848 9.003l.186-.05z"
          fill="#256653"
        />
        <path
          d="M7.902 26.958c.082-.057.294-.276.578-.344.268-.09.608-.03.596-.023.007.017-.223-.243-.584-.319-.346-.1-.821-.015-.977.12-.353.237-.381.639-.206.713.174.079.406-.03.593-.147zM6.457 25.698c.082-.058.295-.276.578-.345.269-.09.609-.03.597-.022.007.016-.224-.244-.584-.32-.346-.1-.822-.015-.977.12-.353.237-.381.639-.206.713.173.079.405-.03.592-.146zM10.334 26.399c-.077.047-.35.143-.476.359a.832.832 0 00-.134.504c.016.012-.22-.29-.235-.63-.043-.337.134-.712.309-.791.318-.209.754-.073.802.107.053.18-.107.337-.266.45zM10.709 21.894a34.884 34.884 0 014.716 11.178l.15-.04a35.112 35.112 0 00-4.698-11.084c-.51-.788-1.06-1.724-2.014-2.031-.018-.006-.025.022-.008.028.872.325 1.377 1.212 1.854 1.95z"
          fill="#256653"
        />
        <path
          d="M10.508 19.788c-.057.082-.273.297-.337.58-.085.267-.018.602-.01.59.016.007-.251-.217-.335-.572-.108-.34-.032-.813.102-.97.232-.354.638-.39.716-.218.084.17-.022.403-.136.59zM9.982 22.82c.082-.058.294-.276.578-.345.268-.09.608-.03.596-.022.008.016-.223-.244-.584-.32-.346-.1-.821-.015-.977.12-.353.237-.381.639-.206.713.174.08.406-.03.593-.146zM10.775 24.148c.082-.058.294-.276.578-.345.268-.09.608-.03.596-.022.007.016-.223-.244-.584-.32-.346-.1-.821-.015-.977.12-.353.237-.381.639-.206.713.174.08.406-.03.593-.146z"
          fill="#256653"
        />
        <path
          d="M11.617 21.291c-.07.073-.317.251-.425.52-.127.25-.113.592-.104.582.015.009-.213-.253-.239-.617-.052-.353.098-.808.255-.942.286-.313.69-.285.741-.104.055.182-.086.394-.228.561zM12.783 22.799c-.098.051-.441.145-.613.399-.196.226-.22.612-.207.604.018.017-.245-.372-.234-.79-.024-.416.226-.86.446-.942.408-.229.93-.025.973.2.049.226-.16.403-.365.529zM14.01 25.08c-.105.035-.459.07-.672.292-.23.19-.32.567-.306.562.015.02-.178-.408-.096-.817.047-.415.368-.81.6-.855.44-.157.92.13.924.358.01.231-.227.37-.45.46zM8.488 20.322c.046-.038.162-.176.327-.229a.726.726 0 01.351-.039c.005.01-.143-.134-.36-.163-.208-.045-.486.026-.572.113-.198.155-.197.394-.09.43.106.04.238-.035.344-.112z"
          fill="#256653"
        />
        <path
          d="M8.929 19.352c-.002.06-.042.235.016.396a.705.705 0 00.184.3c.01-.002-.195-.03-.35-.182-.164-.137-.277-.398-.26-.518.003-.25.195-.395.29-.334.096.06.117.209.12.338zM5.416 24.414c.046-.038.162-.176.327-.23a.726.726 0 01.351-.038c.005.009-.143-.135-.36-.164-.208-.044-.486.027-.572.114-.198.155-.197.394-.09.43.106.04.238-.035.344-.112z"
          fill="#256653"
        />
        <path
          d="M5.948 23.436c-.007.058-.061.23-.016.395.029.164.157.322.158.314.01 0-.192-.046-.334-.21-.152-.15-.243-.418-.216-.537.024-.25.227-.378.316-.31.091.067.1.218.092.348z"
          fill="#256653"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h46v33H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
