import { FC, useEffect, useState } from 'react';
import { HeaderSocialVerse } from '../../../common/components/HeaderSocialVerse/HeaderSocialVerse';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Addvideos } from '../AddVideos/AddVideos';
import { Box } from '@mui/material';
import { useStyles } from './SocialVerseConfig.style';
import { SocialVerseSettingsPage } from '../SocialVerseSettings/SocialVerseSettingsPage';
import { getSocialVerseById } from '../../../store/slices/socialVerseSlice';
import { useAppDispatch } from '../../../store';
import { PreviewModal } from '../../../common/components/PrewievModal/PreviewModal';
import { LAYOUT_CONTAINER_CLASSNAME } from '../../../common/constants/constants';
import { SocialVerseTableIdContext } from '../SocialVerse.helper';

export const SocialVerseConfig: FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const socialvId = history.location.pathname.split('/').pop() || '';

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [storyVideoId, setStoryVideoId] = useState('');
  const [isEditThumbnailOpen, setIsEditThumbnailOpen] = useState(false);

  const handleBackToSocialVerse = () => {
    history.push(WebsiteRoutes.SocialVerse);
  };

  const handleOpenPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };

  const handleClosePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  useEffect(() => {
    dispatch(getSocialVerseById({ id: socialvId }));
  }, [dispatch, socialvId]);

  return (
    <>
      <PreviewModal isOpen={isPreviewModalOpen} onClose={handleClosePreviewModal} />
      <Box className={`${classes.container} ${LAYOUT_CONTAINER_CLASSNAME}`}>
        <HeaderSocialVerse
          onClickBack={handleBackToSocialVerse}
          onOpenPreviewModal={handleOpenPreviewModal}
        />
        <SocialVerseTableIdContext.Provider
          value={{
            setStoryVideoId,
            storyVideoId,
            withEditThumbnailOpen: isEditThumbnailOpen,
            setWithEditThumbnailOpen: setIsEditThumbnailOpen,
          }}
        >
          <Switch>
            <Route path={WebsiteRoutes.SocialVerseAddVideos + '/:id?'}>
              <Addvideos />
            </Route>
            <Route path={WebsiteRoutes.SocialVerseSettings + '/:id?'}>
              <SocialVerseSettingsPage />
            </Route>
          </Switch>
        </SocialVerseTableIdContext.Provider>
      </Box>
    </>
  );
};
