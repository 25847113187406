import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useVideosDetailVideoDataStyles = makeStyles((theme: Theme) => ({
  VideoDataContainer: {
    // marginBottom: 24,
    margin: '20px',
    marginLeft: '0',
  },
  IconVideoDataContainer: {
    flexShrink: 0,
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // margin: '20px',
  },
  VideoDataTitle: {
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 16,
  },
  ShareIconContainer: {
    width: 'min-content',
    display: 'flex',
    alignItems: 'center',
    marginRight: '24px',
  },
  ShareInfoContainer: {
    paddingLeft: 12,
  },
  ShareInfoText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: '#8B89A0',
    whiteSpace: 'nowrap',
  },
  ShareInfoValue: {
    fontWeight: 600,
    fontSize: '28px',
    lineHeight: '32px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
  },
  tooltip: {
    background: '#344054',
    color: 'white',
  },
  arrow: {
    color: '#344054',
  },
}));
