import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './SavedSearchesTabContent.styles';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { RemoveFilterIcon } from '../../../../assets/RemoveFilterIcon';
import IconButton from '@mui/material/IconButton';
import {
  deleteSearchFilterById,
  getSearchFilters,
  setActiveAccountFilterAttributes,
  setFilterSearch,
} from '../../../../../store/slices/searchFilterSlice';
import { AccountFilterApiModel, SearchTarget } from '../../../../../api/models/searchFilterModels';
import { ClipsSearchStrings } from '../../../../localization/en';

interface Props {
  onApplyFilter: () => void;
}

export const SavedSearchesTabContent: FC<Props> = ({ onApplyFilter }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { accountFilters } = useTypedSelector((state) => state.searchFilter);

  const handleApplyFilter = ({ filter, searchText }: AccountFilterApiModel) => {
    dispatch(setActiveAccountFilterAttributes(filter.attributes));
    dispatch(setFilterSearch(searchText));
    onApplyFilter();
  };

  const handleDeleteFilter = (filterId: string) => {
    dispatch(
      deleteSearchFilterById({
        id: filterId,
      }),
    ).then(() => {
      dispatch(
        getSearchFilters({
          target: SearchTarget.ACCOUNTS,
        }),
      );
    });
  };

  return (
    <Box className={classes.container}>
      {accountFilters.length > 0 ? (
        accountFilters.map((filter) => (
          <Box
            className={classes.filterWrapper}
            onClick={() => {
              handleApplyFilter(filter);
            }}
          >
            <Typography className={classes.filterName} style={{ overflow: 'hidden' }}>
              {filter.name}
            </Typography>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleDeleteFilter(filter.id);
              }}
            >
              <RemoveFilterIcon />
            </IconButton>
          </Box>
        ))
      ) : (
        <Box>
          <Typography className={classes.title}>{ClipsSearchStrings.NoFiltersSaved}</Typography>
          <Typography className={classes.subtitle}>{ClipsSearchStrings.ToSaveASearch}</Typography>
          <Typography className={classes.subtitle} paddingBottom="25px">
            {ClipsSearchStrings.Instruction}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
