import { OnboardingWelcomeStrings } from '../../../common/localization/en';
import { useRightSideWelcomeLayoutStyles } from './RightSideWelcomeLayout.style';
import { Box } from '@mui/material';
import { QuickStartBadgeActive } from '../../QuickStart/components/QuickStartBadge/QuickStartBadgeActive';
import { QuickStartBadge } from '../../QuickStart/components/QuickStartBadge/QuickStartBadge';

export const useGetStepsMethod1 = () => {
  const styles = useRightSideWelcomeLayoutStyles();
  return [
    {
      icon: (
        <Box className={styles.collectIconWrapper}>
          <QuickStartBadgeActive />
          <img src="/qs-brand-icon.png" alt="qs success" className={styles.iconImage} />
        </Box>
      ),
      title: OnboardingWelcomeStrings.Method1Steps1Title,
      description: OnboardingWelcomeStrings.Method1Steps1Description,
    },
    {
      icon: (
        <Box className={styles.collectIconWrapper}>
          <QuickStartBadge />
          <img src="/qs-book-icon.png" alt="qs book" className={styles.iconImage} />
        </Box>
      ),
      title: OnboardingWelcomeStrings.Method1Steps2Title,
      description: OnboardingWelcomeStrings.Method1Steps2Description,
    },
  ];
};
