export enum MenuOptions {
  ADD_TO_SV = 'ADD_TO_SV',
  CHANGE_THUMBNAIL = 'CHANGE_THUMBNAIL',
  VIEW_TRANSCRIPT = 'VIEW_TRANSCRIPT',
  DELETE_CLIP = 'DELETE_CLIP',
}
type MenuOption = { label: string; value: MenuOptions };
export const menuOptions: MenuOption[] = [
  {
    label: 'Add to SocialVerse',
    value: MenuOptions.ADD_TO_SV,
  },
  {
    label: 'Change Thumbnail',
    value: MenuOptions.CHANGE_THUMBNAIL,
  },
  {
    label: 'View Transcript',
    value: MenuOptions.VIEW_TRANSCRIPT,
  },
  {
    label: 'Delete Clip',
    value: MenuOptions.DELETE_CLIP,
  },
];
