import { FC, ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { BoxProps } from '@material-ui/core';
import { useAccordionNumberedStyles } from './AccordionNumbered.style';
import { Tooltip } from './../table/Tooltip';
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';

interface IAccordionNumberedProps extends BoxProps {
  children?: JSX.Element;
  number?: number | string | null;
  title: string;
  subtitle?: string;
  description?: string;
  descriptionMaxWidth?: string;
  isExpanded?: boolean;
  toggleAccordion?: () => void;
  tooltip?: string;
  switchComponent?: JSX.Element;
  enabled?: boolean;
  setEnabled?: (value: boolean) => void;
  fullWidth?: boolean;
  showExpandIcon?: boolean;
  margin?: string;
  fullLabel?: boolean;
  showDivider?: boolean;
  additionalNode?: ReactNode;
  startAdornment?: ReactNode;
  titleDivider?: boolean;
}

export const AccordionNumbered: FC<IAccordionNumberedProps> = ({
  number,
  title = 'My title',
  subtitle,
  description,
  descriptionMaxWidth,
  children,
  isExpanded = false,
  toggleAccordion,
  tooltip,
  switchComponent,
  fullWidth,
  showExpandIcon = true,
  margin = '30px',
  fullLabel = false,
  showDivider = true,
  additionalNode,
  startAdornment,
  titleDivider = true,
  ...rest
}) => {
  const styles = useAccordionNumberedStyles();

  return (
    <Box {...rest}>
      <Accordion
        expanded={isExpanded}
        onChange={toggleAccordion}
        className={styles.AccordionNumbered}
        style={{ marginBottom: margin, marginTop: margin }}
      >
        <AccordionSummary
          expandIcon={showExpandIcon ? <ExpandMoreIcon /> : null}
          //  className={styles.SummaryWithTooltip}
          className={styles.SummaryWithTooltip}
          style={fullWidth ? { width: '90%' } : {}}
        >
          <Grid
            // className={styles.SummaryWithSwitch}
            container
            flexWrap="nowrap"
          >
            <Grid className={styles.SummaryWithTooltip} item xs={12}>
              {startAdornment}
              <Grid container alignItems="center" wrap="nowrap">
                {/* <Grid className={styles.SummaryWithTooltip} item xs={6}> */}
                {number && <Typography className={styles.Number}>{number}</Typography>}
                <Typography className={styles.Title}>{title}</Typography>
                {tooltip && (
                  <Tooltip
                    padding="23px 25px"
                    rounded="15px"
                    title={tooltip}
                    icon={<QuestionMarkRoundedIcon className={styles.Tooltip} />}
                  />
                )}
                {/* </Grid> */}
                <Grid className={styles.SummaryWithTooltip} item xs={7}>
                  <Typography className={styles.Subtitle}>{subtitle}</Typography>
                </Grid>
                {additionalNode}
              </Grid>
            </Grid>
            {switchComponent ? (
              <>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}>
                  {switchComponent}
                </Grid>
              </>
            ) : null}
          </Grid>
        </AccordionSummary>
        {description && (
          <Box maxWidth={descriptionMaxWidth || undefined}>
            <Typography className={styles.description}>{description}</Typography>
            {titleDivider && (
              <Box my={3}>
                <Divider />
              </Box>
            )}
          </Box>
        )}
        <AccordionDetails style={{ paddingBottom: '25px', paddingLeft: '0' }}>
          {children}
        </AccordionDetails>
      </Accordion>
      {showDivider && <Divider />}
    </Box>
  );
};
