import { AffiliateModel } from '../api/models/affiliates';
import { useAppDispatch, useTypedSelector } from '../store';
import { getAffiliatesByDistributorIds } from '../store/slices/affiliatesSlice';
import { useDistributors } from './useDistributors';

interface FetchAffiliatesConfig {
  forceDistributorsRefetch?: boolean;
}

export const useAffiliates = () => {
  const dispatch = useAppDispatch();

  const { distributors, fetchDistributors, isDistributorsLoading } = useDistributors();

  const { isLoading: isAffiliatesLoading, affiliates, activeAffiliate } = useTypedSelector(
    (store) => store.affiliates,
  );

  const fetchAffiliates = async (config: FetchAffiliatesConfig = {}) => {
    let _distributors = [...distributors];
    if (!_distributors || _distributors.length === 0 || config.forceDistributorsRefetch) {
      _distributors = await fetchDistributors();
    }

    const distributorIds = _distributors.map(({ id }) => id);
    const { payload } = await dispatch(getAffiliatesByDistributorIds({ distributorIds }));
    return payload as AffiliateModel[];
  };

  /**
   * This method does not fetch plans from backend
   */
  const getAffiliateById = (affiliateId: AffiliateModel['id']) => {
    return affiliates.find(({ id }) => id === affiliateId);
  };

  const isLoading = isDistributorsLoading || isAffiliatesLoading;

  return {
    affiliates,
    isAffiliatesLoading: isLoading,
    fetchAffiliates,
    getAffiliateById,
    activeAffiliate
  };
};
