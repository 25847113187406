import styled from 'styled-components';
import { theme } from '../../../../../../../theme';

export const SectionTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 160%;
  color: ${theme.palette.text.primary};
`;

export const Title = styled.div`
  color: ${theme.palette.text.primary};
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 133.4%;
`;

export const Subtitle = styled.div`
  color: ${theme.palette.text.secondary};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
`;

export const AccountsWraper = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
`;
export const WrapScrollBox = styled.div`
  height: 500px;
  width: 100%;
  overflow: hidden;
`;
interface ColorBoxProps {
  color: string;
}
export const ColorBox = styled.div<ColorBoxProps>`
  ${(props) => `background-color: ${props.color}`};
  width: 48px;
  height: 48px;
  border-radius: 8px;
  box-shadow: 0px 2px 12px -2px rgba(7, 27, 134, 0.12);
`;

export const ColorText = styled.span`
color: ${theme.palette.text.primary}
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 26px;
`;
export const LogoBox = styled.img`
  width: 120px;
  height: 120px;
`;
export const FiltersWrapper = styled.div`
  height: 350px;
  width: 100%;
`;
export const FiltersStepWrapper = styled.div`
  width: 100%;
  min-height: 500px;
`;
export const ImgContainer = styled.div`
  position: relative;
  height: 250px;
  display: flex;
  min-width: 180px;
  margin-right: 70px;
`;
export const FilterImage = styled.img`
  height: 250px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const PreviewWrapper = styled.div`
  width: 100%;
  padding: 16px 24px;
  background-color: ${theme.palette.common.slightlyBlueish};
  min-height: 500px;
`;
export const RewardBox = styled.div`
  box-shadow: 0px 2px 12px -2px rgba(7, 27, 134, 0.12);
  border-radius: 8px;
  min-height: 160px;
  padding: 20px;
  width: 100%;
`;
export const RewardWrapper = styled.div`
  min-height: 500px;
  width: 100%;
`;
interface InputWrapperProps {
  url?: string;
}
export const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;
  max-width: 90px;
  height: 120px;
  border-radius: 8px;
  ${(props) =>
    `background-image: ${props.url ? `url(${props.url})` : 'url(/inputBackground.png)'}`};
  background-size: cover;
  cursor: pointer;
`;
export const PicturedInput = styled.input`
  width: 100%;
  max-width: 90px;
  height: 200px;
  border-radius: 8px;
  opacity: 0;
  cursor: pointer;
`;
export const DefaultRewardPreviewWrapper = styled.div`
  padding-top: 120px;
  width: var(--iphoneXWidth);
  height: var(--iphoneXHeight);
  border-radius: var(--iphoneXborderRadius) var(--iphoneXborderRadius) var(--iphoneXborderRadius)
    var(--iphoneXborderRadius);
  background-color: ${theme.palette.common.slightlyBlueish};
`;

export const ScrollBoxWrapp = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const ScrollBox = styled.div`
  height: 600px;
  width: 100%;
  overflow: hidden;
`;
export const Mailto = styled.a`
  color: inherit;
  width: 100%;
  height: 100%;
`;
export const FilterErrorWrapper = styled.div`
  width: 100%;
  height: 400px;
`;
