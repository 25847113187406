import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useManagerName = makeStyles((theme: Theme) => ({
  NameInput: {
    width: '250px',
    marginLeft: '52px',
    '&.MuiFormHelperText-root': {
      color: theme.palette.error.main,
    },
  },
}));
