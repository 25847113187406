import { FC } from 'react';

export const ArrowRightIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <path
      fill="#fff"
      d="M1 9h11.17l-4.88 4.88c-.39.39-.39 1.03 0 1.42.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0 000-1.41L8.7.71a.996.996 0 10-1.41 1.41L12.17 7H1c-.55 0-1 .45-1 1s.45 1 1 1z"
    />
  </svg>
);
