import { FC } from 'react';

interface Props {
  className?: string;
}

export const RightIcon: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="12" r="12" fill="#1A1538" />
        <path
          d="M10.8008 15.2969L14.4009 11.6968L10.8008 8.09687"
          stroke="white"
          strokeWidth="1.12401"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};
