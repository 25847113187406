import React, { FC } from 'react';
import { OrderDirection } from '../constants/constants';

interface Props {
  orderDirection?: string;
}

export const SortIcon: FC<Props> = ({ orderDirection }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {(orderDirection === OrderDirection.ASC || !orderDirection) && (
        <path
          d="M5.99999 11L8.00003 13L10 11"
          stroke="#8B89A0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
      {(orderDirection === OrderDirection.DESC || !orderDirection) && (
        <path
          d="M5.99999 5L8.00003 3L10 5"
          stroke="#8B89A0"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      )}
    </svg>
  );
};
