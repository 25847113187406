import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useStyles } from './SubTabSelect.styles';
import { SmsMessageType } from '../../../../api/models/smsMessages';
import { useTypedSelector } from '../../../../store';

export interface SubTabItem {
  tabName: string;
  count: number;
  communicationType?: SmsMessageType;
}

interface Props {
  tabsList: SubTabItem[];
  ohChange: () => void;
  tabIndex: number;
  setTabIndex: (value: number) => void;
}

export const SubTabSelect: FC<Props> = ({ tabsList, ohChange, tabIndex, setTabIndex }) => {
  const styles = useStyles();
  const { isLoading, totalItems, search } = useTypedSelector((state) => state.smsMessages);

  const theme = useTheme();

  return (
    <Box className={styles.container}>
      {tabsList.map((tabItem, index) => {
        const isActiveTab = tabIndex === index;
        return (
          <Box
            className={styles.tab}
            style={{
              background: isActiveTab ? '#FFFFFF' : 'none',
              borderRadius: isActiveTab ? '6px' : 'none',
              boxShadow: isActiveTab
                ? '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)'
                : 'none',
            }}
            key={index}
            onClick={() => {
              setTabIndex(index);
              ohChange();
            }}
            id={`${tabItem.tabName.split(' ').join('-')}-table-filter`}
          >
            <Typography
              className={styles.tabName}
              style={{
                color: isActiveTab ? theme.palette.primary.main : '#667085',
              }}
            >
              {tabItem.tabName}
            </Typography>
            <Typography className={styles.amount}>
              {tabIndex === index && (!isLoading || search !== '') ? totalItems : tabItem.count}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};
