import { Box, Modal, Typography } from '@mui/material';
import React from 'react';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';

interface ArchiveModalProps {
  isArchived: boolean;
  isOpen: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
}

export const ArchiveModal: React.FC<ArchiveModalProps> = ({
  isArchived,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box
        sx={{
          position: 'relative',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 'fit-content',
          outline: 'none',
          bgcolor: 'white',
          borderRadius: '16px',
          p: '32px 64px',
          textAlign: 'center',
        }}
      >
        <Typography fontWeight="500" fontSize="18px" mb="4px">
          {isArchived ? (
            <>
              Are you certain you want to
              <br /> activate this archived account?
            </>
          ) : (
            <>
              Are you certain you want to
              <br /> archive this account?
            </>
          )}
        </Typography>
        <Typography fontWeight="500" fontSize="12px" mb="12px" color="#8C8A94">
          {isArchived ? (
            <>
              NOTE: SocialVerses will be enabled but Google <br /> Business Profile will need to be
              re-connected.
            </>
          ) : (
            <>
              NOTE: SocialVerses will be disabled and Google <br /> Business Profile Disconnected.
            </>
          )}
        </Typography>
        <Box>
          <RoundedButton title="Cancel" outlined sx={{ mr: '12px' }} onClick={onClose} />
          <RoundedButton title="Confirm" onClick={onConfirm} />
        </Box>
      </Box>
    </Modal>
  );
};
