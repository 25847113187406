import React from 'react';

export const QuickStartBadge = () => {
  return (
    <svg
      width="152"
      height="181"
      viewBox="0 0 152 181"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_65_4465)">
        <path
          d="M10 13.2375C10 8.81926 13.5817 5.23755 18 5.23755L133.442 5.23755L133.442 157.682H18C13.5817 157.682 10 154.1 10 149.682L10 13.2375Z"
          fill="url(#paint0_linear_65_4465)"
        />
        <path
          d="M10.5 13.2375C10.5 9.09541 13.8579 5.73755 18 5.73755L132.942 5.73755L132.942 157.182H18C13.8579 157.182 10.5 153.824 10.5 149.682L10.5 13.2375Z"
          stroke="#F7B409"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_65_4465"
          x="0"
          y="0.237549"
          width="151.443"
          height="180.444"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="9" />
          <feGaussianBlur stdDeviation="7" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.609197 0 0 0 0 0.6125 0 0 0 0 0.556354 0 0 0 0.26 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_65_4465" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_65_4465"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_65_4465"
          x1="71.7212"
          y1="5.23755"
          x2="71.7212"
          y2="157.682"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#D9D5FB" />
          <stop offset="1" stop-color="#5242EA" />
        </linearGradient>
      </defs>
    </svg>
  );
};
