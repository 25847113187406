import { makeStyles } from '@mui/styles';

const drawerWidth = 230;

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.sidebar.background,
    overflow: 'hidden',
  },
  docked: {
    width: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    zIndex: '1000',
    height: '100%',
    overflow: 'hidden',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: 'all 0.3s ease-out',
  },
  drawerClose: {
    overflowX: 'hidden',
    width: 65,
    transition: 'all 0.3s ease-out',
  },
  logoWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    flexShrink: 0,
    flexGrow: 0,
    marginBottom: '20px',
    marginTop: '20px',
    overflow: 'hidden',
    '& div > svg': {
      width: 150,
      height: 50,
      flexShrink: 0,
      flexGrow: 0,
    },
  },
  brandLogoUwm: {
    objectPosition: 'left',
    transition: 'all 0.3s ease-in',
    paddingLeft: '16.5px',
    height: '24px',
  },
  brandLogoSv: {
    transition: 'all 0.3s ease-in',
    overflow: 'hidden',
    paddingLeft: '16px',
  },
  trubluLogoWrapper: {
    position: 'relative',
    width: '204px',
    overflow: 'hidden',
  },
  brandLogoTrublu: {
    transition: 'all 0.3s ease-in',
    position: 'absolute',
    height: '112px',
  },
}));
