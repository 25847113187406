import { Box } from '@mui/material';
import { useNoResultsContentStyles } from './NoResultsContent.style';
import { DashboardStrings } from '../../../common/localization/en';

export const NoResultsContent = () => {
  const styles = useNoResultsContentStyles();
  return (
    <Box className={styles.Container}>
      <div className={styles.Chart}>
        <div className={styles.Blur}>
          <div className={styles.Message}>
            <p className={styles.Title}>{DashboardStrings.NoResultsTitile}</p>
            <p className={styles.SubTitle}>{DashboardStrings.NoResultsSubTitile}</p>
          </div>
        </div>
      </div>
    </Box>
  );
};
