import { theme } from '../../../../theme';

export const SpheresIcon = () => {
  return (
    <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="29.7246"
        cy="11.0024"
        r="9.81489"
        fill="white"
        stroke="#1A1538"
        strokeWidth="0.666667"
      />
      <circle cx="29.7228" cy="11.0099" r="5.60363" fill="#D9D4FF" />
      <circle
        cx="12.9263"
        cy="15.9727"
        r="12.4649"
        fill="#D9D4FF"
        stroke={theme.palette.primary.main}
        strokeWidth="0.666667"
      />
      <circle cx="12.9272" cy="15.975" r="7.85784" fill="white" />
      <path
        d="M15.7463 15.3036C16.2578 15.5989 16.2578 16.3372 15.7463 16.6325L12.0175 18.7853C11.5061 19.0806 10.8667 18.7114 10.8667 18.1208L10.8667 13.8153C10.8667 13.2246 11.5061 12.8555 12.0175 13.1508L15.7463 15.3036Z"
        fill="#1A1538"
      />
    </svg>
  );
};
