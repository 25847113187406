import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100',
    marginTop: '30px',
    position: 'relative',
  },
  tabs: {
    height: 'fit-content',
    minHeight: 'fit-content',
    width: 'fit-content',
    borderRadius: '8px',
    border: '1px solid #F2F4F7',
    display: 'flex',
    alignItems: 'center',
    padding: '4px',
    background: '#F9FAFB',
    margin: '0 auto',
    marginBottom: '25px',
    '& .MuiTabs-flexContainer': {
      gap: '8px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  tab: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    fontWeight: 500,
    textTransform: 'none',
    padding: '7px 12px',
    height: '34px',
    minHeight: '34px',
    borderRadius: '6px',
    boxSizing: 'content-box',
    border: '1px solid transparent',
    position: 'relative',
    overflow: 'unset',
    color: '#667085',

    '&.Mui-selected': {
      color: theme.palette.primary.main,
      background: '#fff',
      border: '1px solid #D9D9D9',
    },
  },
  directionIcon: {
    position: 'absolute',
    right: '-20px',
    top: '14px',
  },
  swiperWrapper: {
    position: 'relative',
  },
  swiper: {
    width: '850px !important',
  },
  swiperSlide: {
    minWidth: '850px',
    minHeight: '100px',
    display: 'flex !important',
    justifyContent: 'center !important',
  },
  navIcon: {
    position: 'absolute',
    width: '62px',
    height: '62px',
    borderRadius: '50%',
    border: '1px solid #D9D9D9',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      transform: 'scale(0.99)',
    },
  },
  navPrevIcon: {
    left: '-100px',
    top: '242px',
  },
  navNextIcon: {
    right: '-100px',
    top: '242px',
  },
  qsMarkerWrapper: {
    position: 'absolute',
    bottom: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));
