import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonsWrapper: {
    display: 'flex',
  },
  calendarIcon: {
    marginTop: '0px',
  },
  nextSendWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    maxWidth: '700px',
  },
  actionButton: {
    textDecoration: 'underline',
    color: '#475467',
    '& p': {
      color: '#475467',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
