import { FC, SetStateAction } from 'react';
import { Grid } from '@mui/material';

import { Title, Subtitle, ScrollBoxWrapp, ScrollBox } from './Steps.style';
import { VenueWizardPreviewStepStrings } from '../../../common/localization/en';
import { SubdomainBlock } from './components/SubdomainBlock';
import { ColorsAndLogoBlock } from './components/ColorsAndLogoBlock';
import { SelectedFilterSection } from './components/SelectedFilterSection';
import { RewardSection } from './components/RewardsSection';
import { InformationBlock } from './components/InformationBlock';
import { UsersBlock } from './components/UsersBlock';
import { ParticipantsBlock } from './components/ParticipantsBlock';
import { NewRewardSchema, FilterProps } from '../WizzardStepperHelper';

interface PreviewStepProps {
  filterStepState: FilterProps[];
  rewardStepState: NewRewardSchema;
  subdomain: string;
  setSubdomain: (value: SetStateAction<string>) => void;
}

export const PreviewStep: FC<PreviewStepProps> = ({
  rewardStepState,
  subdomain,
  setSubdomain,
  filterStepState,
}) => {
  return (
    <ScrollBox>
      <ScrollBoxWrapp>
        <Grid container alignItems="flex-start" justifyContent="flex-start" spacing={3}>
          <Grid item xs={12}>
            <Title>{VenueWizardPreviewStepStrings.Title}</Title>
            <Subtitle>{VenueWizardPreviewStepStrings.Subtitle}</Subtitle>
          </Grid>
          <Grid item xs={7}>
            <Grid container alignItems="center" justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <SubdomainBlock subdomain={subdomain} setSubdomain={setSubdomain} />
              </Grid>
              <Grid item xs={12}>
                <ColorsAndLogoBlock />
              </Grid>
              <Grid item xs={12}>
                <SelectedFilterSection
                  filterStepState={filterStepState.filter((item) => item.isActive)[0]}
                />
              </Grid>
              <Grid item xs={12}>
                <RewardSection rewardStepState={rewardStepState} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Grid container alignItems="center" justifyContent="flex-start" spacing={3}>
              <Grid item xs={12}>
                <InformationBlock />
              </Grid>
              <Grid item xs={12}>
                <UsersBlock />
              </Grid>
              <Grid item xs={12}>
                <ParticipantsBlock />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ScrollBoxWrapp>
    </ScrollBox>
  );
};
