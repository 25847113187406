import { FC } from 'react';
import { Box } from '@mui/material';

import { KPIApiNames } from '../../../../api/models/analytics';
import { KPIDTOActiveAnalyticsBlock } from '../../../../store/storeModels';
import { AnalyticsBlockModel } from '../AnalyticsSection.helper';

import { StatisticsBlocks } from '../StatisticsBlock/StatisticsBlocks';

interface AnalyticsBlocksProps {
  items: AnalyticsBlockModel[];
  TBDItem?: AnalyticsBlockModel;
  activeAnalyticsBlock?: KPIDTOActiveAnalyticsBlock;
  onClick: (kpiName: KPIApiNames, name: string) => void;
  isLoading?: boolean;
  isTopgolf: boolean;
  isHealthCare: boolean;
  accountsPage?: boolean;
}

export const AnalyticsBlocks: FC<AnalyticsBlocksProps> = ({
  items,
  activeAnalyticsBlock,
  onClick,
  isLoading = false,
  isTopgolf,
  isHealthCare,
  accountsPage,
}) => {
  const onClickHandler = (kpiName: KPIApiNames, name: string) => {
    onClick(kpiName, name);
  };

  return (
    <Box display="flex" columnGap={2}>
      <StatisticsBlocks
        isTopgolf={isTopgolf}
        isHealthCare={isHealthCare}
        isLoading={isLoading}
        items={items}
        activeAnalyticsBlock={activeAnalyticsBlock}
        onClick={onClickHandler}
        accountsPage={accountsPage}
      ></StatisticsBlocks>
    </Box>
  );
};
