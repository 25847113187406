import { SVGProps } from 'react';
import { theme } from '../../../theme';

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      stroke={theme.palette.primary.main}
      strokeLinecap="round"
      strokeWidth={1.3}
      d="M7 1v12M1 7h12"
    />
  </svg>
);
