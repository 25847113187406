import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import CardLayout from './CardLayout';
import { CouponStrings } from '../../localization/en';
import { useCouponStyles } from './Coupon.styles';
import { resizeFullNameFont, resizeNameFont, resizeRewardFont } from './Coupon.helper';
import { format } from 'date-fns/esm';

interface CouponProps {
  companyLogo: string;
  cardOwnerFirstName?: string;
  cardOwnerLastName?: string;
  reward?: string;
  cardNumber?: string;
  date?: string;
  isNullReward?: boolean;
}

export const Coupon: FC<CouponProps> = ({
  companyLogo,
  reward = CouponStrings.DefaultReward,
  cardNumber = CouponStrings.DefaultCardNumber,
  cardOwnerFirstName = CouponStrings.DefaultFirstName,
  cardOwnerLastName = CouponStrings.DefaultLastName,
  date = format(new Date(), 'P'),
  isNullReward = false,
}) => {
  const styles = useCouponStyles({ isNullReward });

  const fullName = `${cardOwnerFirstName} ${cardOwnerLastName}`;

  const firstNameFontSize = resizeNameFont({
    defaultFontSize: 32,
    string: cardOwnerFirstName,
  });
  const rewardFontsize = resizeRewardFont({ defaultFontSize: 37, string: reward });
  const fullNameFontsize = resizeFullNameFont({ defaultFontSize: 22, string: fullName });

  return (
    <Box sx={{ margin: '0 auto', width: 'fit-content' }}>
      <Box className={styles.container}>
        {companyLogo && (
          <Box component="img" src={companyLogo} alt="company logo" className={styles.logo} />
        )}
        <Box
          sx={{
            position: 'absolute',
            filter: 'none',
            right: '14px',
            top: '23px',
            width: isNullReward ? '90%' : '70%',
          }}
        >
          {
            <Typography className={styles.congratsText}>
              {isNullReward ? CouponStrings.ThankYou : CouponStrings.Congrats}
            </Typography>
          }
          {
            <Typography
              className={styles.congratsText}
              sx={{
                fontSize: isNullReward
                  ? cardOwnerFirstName.includes('@')
                    ? '32px !important'
                    : '40px !important'
                  : `${firstNameFontSize}px !important`,
                textTransform: cardOwnerFirstName.includes('@')
                  ? 'lowercase !important'
                  : 'capitalize !important',
                marginTop: isNullReward ? '12px' : '0',
              }}
            >
              {`${cardOwnerFirstName}`}!
            </Typography>
          }
          {!isNullReward && (
            <Typography className={styles.couponText}>{CouponStrings.RewardCard}</Typography>
          )}
          <Typography
            className={styles.rewardText}
            sx={{ fontSize: `${rewardFontsize}px !important` }}
          >
            {reward}
          </Typography>
        </Box>
        {!isNullReward && (
          <Box sx={{ position: 'absolute', filter: 'none', left: '30px', top: '215px' }}>
            <Typography className={styles.cardNumber}>{`${cardNumber}`}</Typography>
          </Box>
        )}
        <Box
          sx={{
            position: 'absolute',
            filter: 'none',
            left: '30px',
            top: '270px',
            maxWidth: '325px',
          }}
        >
          <Typography
            className={styles.cardOwner}
            sx={{
              fontSize: `${fullNameFontsize}px !important`,
              textTransform: cardOwnerFirstName.includes('@')
                ? 'lowercase !important'
                : 'capitalize !important',
            }}
          >{`${fullName}`}</Typography>
        </Box>

        <Box sx={{ position: 'absolute', filter: 'none', right: '22px', top: '268px' }}>
          <Typography className={styles.cardOwner}>{`${date}`}</Typography>
        </Box>

        {!isNullReward ? (
          <CardLayout />
        ) : (
          <img className={styles.card} src="/CreditCard.png" alt="" />
        )}
      </Box>
    </Box>
  );
};
