import { useState } from 'react';

export const useStep = (steps: string[]) => {
  const initialStepIndex = 0;

  const [stepIndex, setStepIndex] = useState(initialStepIndex);
  const step = steps[stepIndex];
  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === steps.length - 1;

  const moveToNextStep = () => {
    if (isLastStep) return;

    setStepIndex((i) => i + 1);
  };

  const moveToPrevStep = () => {
    if (isFirstStep) return;

    setStepIndex((i) => i - 1);
  };

  const resetStep = () => {
    setStepIndex(initialStepIndex);
  };

  return {
    currentStep: step,
    moveToNextStep,
    moveToPrevStep,
    resetStep,
  };
};
