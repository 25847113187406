import { useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ClientTypes } from '../api/models/common';
import { UserRoles } from '../common/constants/constants';
import { WebsiteRoutes } from '../common/constants/routes';
import { useTypedSelector } from '../store';

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function usePreviousNonNull<T, K>(value: T, discountType: K) {
  const ref = useRef<T>();
  const ref2 = useRef<K>();
  useEffect(() => {
    if (value) {
      ref2.current = discountType;
      ref.current = value;
    }
  });
  return [ref.current, ref2.current];
}

/**
 * Hook that trigger handler on clicks outside of the passed ref
 */
export function useOutsideAlerter(
  ref: any,
  handler: (ev: MouseEvent) => void,
  isOpen?: boolean,
  disabled?: boolean,
) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    if (!isOpen) {
      return;
    }
    function handleClickOutside(event: MouseEvent): void {
      if (ref?.current && !ref.current.contains(event.target) && !disabled) {
        handler(event);
      }
    }

    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [ref, handler, isOpen, disabled]);
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useIsTBAccount() {
  const { role } = useTypedSelector((state) => state.me);
  const { items: venues } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { activeDistributor } = useTypedSelector((state) => state.distributors);
  const { activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const currentSelectedVenueClientType = venues[0]?.clientType;

  const isAccountsPage = window.location.pathname === WebsiteRoutes.ProfileAccounts;

  const isTBClient = currentSelectedVenueClientType === ClientTypes.TRUBLU;
  const currentClientTypeIsTB = clientType === ClientTypes.TRUBLU;
  const isSVAdmin = role === UserRoles.admin;

  const distributors = activeDistributor?.name === 'SocialSmiles';

  const allAccounts = !activeDistributor && !activeAffiliate;
  const handleAccountType = useMemo(() => {
    if (!isAccountsPage) {
      return currentClientTypeIsTB;
    }
    if (isSVAdmin) {
      return allAccounts ? false : distributors || activeAffiliate?.name ? true : false;
    }
    if (isTBClient) {
      return true;
    }
    if (!isSVAdmin && (isTBClient || activeAffiliate?.name)) {
      return true;
    }
    
    return false;
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    venues[0],
    activeAffiliate?.name,
    allAccounts,
    distributors,
    isSVAdmin,
    isTBClient,
    isAccountsPage,
    currentClientTypeIsTB,
  ]);

  return handleAccountType;
}
