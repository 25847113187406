import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useSVmodalStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    borderRadius: 30,
  },
  backDrop: {
    backdropFilter: 'blur(20px)',
    backgroundColor: 'rgba(26, 21, 56, 0.6)',
  },
  wrapperEducationSection: {
    padding: '100px 8px',
    background: '#F5F6F8',
  },
  wrapperEducationCards: {
    background: '#F5F6F8',
    height: '100%',
    padding: '90px 0 90px 0',
  },
  wrapperMainContent: {
    background: theme.palette.common.white,
    padding: 50,
    height: '100%',
  },
  body: {
    '&>h3': {
      color: theme.palette.common.black,
      marginBottom: '10px',
      marginTop: '25px',
    },
  },
  textModal: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    marginTop: '20px',
    color: '#8B89A0',
  },

  button: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 100,
    // height: 50,
    padding: '10px 40px',
    '&>.MuiTypography-root': {
      color: theme.palette.common.white,
    },
  },
  paper: {
    maxWidth: '1010px',
    width: '100%',
    borderRadius: 30,
    overflow: 'visible',
  },
  close: {
    position: 'absolute',
    top: '-10px',
    right: '-7px',
    width: 50,
    height: 50,
    background: theme.palette.common.white,
    color: theme.palette.common.deepDark,
    boxShadow: '0px 9px 20px rgba(20, 21, 61, 0.1)',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  modalImage: {
    '&>img': {
      objectFit: 'contain',
      maxHeight: '450px',
      height: '100%',
    },
  },
}));
