import { ChangeEvent, FC } from 'react';
import { Checkbox } from '@mui/material';
import { theme } from '../../../theme';

interface Props {
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, isChecked: boolean) => void;
  outlineColor?: string;
  id?: string;
}

export const CheckBox: FC<Props> = ({ isChecked, onChange, outlineColor, id }) => {
  return (
    <Checkbox
      checked={isChecked}
      onChange={onChange}
      icon={<CheckBoxIcon outlineColor={outlineColor} />}
      checkedIcon={<CheckBoxIconChecked />}
      id={id}
    />
  );
};

interface CheckBoxIconProps {
  outlineColor?: string;
}
const CheckBoxIcon: FC<CheckBoxIconProps> = ({ outlineColor }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.50016 24.6668H16.5002C22.3335 24.6668 24.6668 22.3335 24.6668 16.5002V9.50016C24.6668 3.66683 22.3335 1.3335 16.5002 1.3335H9.50016C3.66683 1.3335 1.3335 3.66683 1.3335 9.50016V16.5002C1.3335 22.3335 3.66683 24.6668 9.50016 24.6668Z"
        stroke={outlineColor || 'white'}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CheckBoxIconChecked: FC = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5002 25.6668H17.5002C23.3335 25.6668 25.6668 23.3335 25.6668 17.5002V10.5002C25.6668 4.66683 23.3335 2.3335 17.5002 2.3335H10.5002C4.66683 2.3335 2.3335 4.66683 2.3335 10.5002V17.5002C2.3335 23.3335 4.66683 25.6668 10.5002 25.6668Z"
        fill="#8B89A0"
      />
      <path
        d="M10.5002 25.6668H17.5002C23.3335 25.6668 25.6668 23.3335 25.6668 17.5002V10.5002C25.6668 4.66683 23.3335 2.3335 17.5002 2.3335H10.5002C4.66683 2.3335 2.3335 4.66683 2.3335 10.5002V17.5002C2.3335 23.3335 4.66683 25.6668 10.5002 25.6668Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M10.5002 25.6668H17.5002C23.3335 25.6668 25.6668 23.3335 25.6668 17.5002V10.5002C25.6668 4.66683 23.3335 2.3335 17.5002 2.3335H10.5002C4.66683 2.3335 2.3335 4.66683 2.3335 10.5002V17.5002C2.3335 23.3335 4.66683 25.6668 10.5002 25.6668Z"
        stroke="#8B89A0"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5002 25.6668H17.5002C23.3335 25.6668 25.6668 23.3335 25.6668 17.5002V10.5002C25.6668 4.66683 23.3335 2.3335 17.5002 2.3335H10.5002C4.66683 2.3335 2.3335 4.66683 2.3335 10.5002V17.5002C2.3335 23.3335 4.66683 25.6668 10.5002 25.6668Z"
        stroke={theme.palette.primary.main}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.0415 13.9999L12.3432 17.3016L18.9582 10.6982"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
