import { FC } from 'react';
import { useStyles } from './TopSection.styles';
import { Box } from '@mui/material';
import CampaignInfo from './CampaignInfo/CampaignInfo';
import CampaignNavigation from './CampaignNavigation/CampaignNavigation';
import RewardInfo from './RewardInfo/RewardInfo';
import AnimatedQsMarker, {
  QSStep,
} from '../../../../common/components/AnimatedQsMarker/AnimatedQsMarker';
import { useQuickStartLaunchPad } from '../../../QuickStart/QuickStartLaunchPadModal/useQuickStartLaunchPad';
import IntroVideo from './IntroVideo/IntroVideo';
import { useTypedSelector } from '../../../../store';
import { useClientType } from '../../../../services/hooks/useClientType';

interface Props {
  qsSteps: QSStep[];
}

export const TopSection: FC<Props> = ({ qsSteps }) => {
  const classes = useStyles();

  const { isUwmClient } = useClientType();
  const { isQSMarkerVisible, currentTopicStep, isLoading } = useQuickStartLaunchPad();
  const { currentCampaign } = useTypedSelector((state) => state.campaigns);

  return (
    <Box className={classes.container}>
      <Box className={classes.leftSideWrapper}>
        <CampaignInfo />
        <CampaignNavigation />
      </Box>
      {isUwmClient && currentCampaign?.isPreferred && <IntroVideo />}
      <RewardInfo qsSteps={qsSteps} />
      {isQSMarkerVisible && currentTopicStep !== 0 && !isLoading && (
        <Box className={classes.qsMarkerWrapper}>
          <AnimatedQsMarker
            arrowPosition="bottom"
            direction="column"
            steps={qsSteps}
            isEssentialsFlow={true}
          />
        </Box>
      )}
    </Box>
  );
};
