import { FC } from 'react';
import { Grid, Button } from '@mui/material';
import { ErrorPageStrings, ContactUsString } from '../../../common/localization/en';
import { ErrorDescription, ErrorTitle, ErrorImage } from '../Venuewizard.style';
import { Mailto, FilterErrorWrapper } from '../wizardSteps/Steps.style';

export const FiltersError: FC = () => {
  return (
    <FilterErrorWrapper>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
        <Grid item xs={12} md={5}>
          <ErrorImage src="/error.png" />
        </Grid>
        <Grid item xs={12} md={12}>
          <ErrorTitle>{ErrorPageStrings.FiltersTitle}</ErrorTitle>
          <ErrorDescription>{ErrorPageStrings.FiltersSubtitle}</ErrorDescription>
          <ErrorDescription>{ErrorPageStrings.FiltersSubtitle2}</ErrorDescription>
        </Grid>
        <Grid item xs={10} md={5}>
          <Button color="primary" fullWidth>
            <Mailto
              href={`mailto:${ContactUsString.SupportEmail1}?cc=${ContactUsString.SupportEmail2}&subject=${ContactUsString.EmailSubject}`}
            >
              {ErrorPageStrings.ReportIssue}
            </Mailto>
          </Button>
        </Grid>
      </Grid>
    </FilterErrorWrapper>
  );
};
