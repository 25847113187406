import { FC } from 'react';

export const SmallStarIcon: FC = () => {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.96199 2.58993C9.13485 2.23972 9.22128 2.06462 9.33862 2.00867C9.44071 1.96 9.55932 1.96 9.6614 2.00867C9.77874 2.06462 9.86517 2.23972 10.038 2.58993L11.678 5.9124C11.7291 6.01579 11.7546 6.06748 11.7919 6.10762C11.8249 6.14316 11.8645 6.17195 11.9085 6.1924C11.9582 6.21551 12.0152 6.22384 12.1293 6.24052L15.7977 6.77672C16.184 6.83318 16.3772 6.86141 16.4666 6.95577C16.5444 7.03786 16.5809 7.15066 16.5661 7.26277C16.5491 7.39162 16.4093 7.52782 16.1296 7.80022L13.4761 10.3848C13.3934 10.4653 13.352 10.5056 13.3253 10.5535C13.3017 10.596 13.2865 10.6426 13.2807 10.6908C13.2741 10.7453 13.2838 10.8022 13.3034 10.916L13.9295 14.5665C13.9955 14.9516 14.0285 15.1441 13.9665 15.2583C13.9125 15.3577 13.8165 15.4274 13.7053 15.448C13.5775 15.4717 13.4046 15.3808 13.0588 15.199L9.77928 13.4743C9.6771 13.4206 9.62601 13.3937 9.57218 13.3832C9.52452 13.3738 9.4755 13.3738 9.42784 13.3832C9.37402 13.3937 9.32293 13.4206 9.22074 13.4743L5.94119 15.199C5.59544 15.3808 5.42256 15.4717 5.29473 15.448C5.18351 15.4274 5.08754 15.3577 5.03355 15.2583C4.9715 15.1441 5.00452 14.9516 5.07056 14.5665L5.69666 10.916C5.71618 10.8022 5.72594 10.7453 5.71934 10.6908C5.71349 10.6426 5.69833 10.596 5.6747 10.5535C5.64802 10.5056 5.60666 10.4653 5.52394 10.3848L2.87042 7.80022C2.59075 7.52782 2.45091 7.39162 2.43389 7.26277C2.41909 7.15066 2.45567 7.03786 2.53344 6.95577C2.62283 6.86141 2.81598 6.83318 3.20228 6.77672L6.87073 6.24052C6.98482 6.22384 7.04186 6.21551 7.09154 6.1924C7.13552 6.17195 7.17512 6.14316 7.20814 6.10762C7.24543 6.06748 7.27095 6.01579 7.32198 5.9124L8.96199 2.58993Z"
        fill="#F1AF3E"
        stroke="#F1AF3E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
