import React, { FC, useState } from 'react';
import { useStyles } from './RewardModal.styles';
import { FSModal } from '../../../../common/components/modal/Modal';
import { Box, Button, ButtonGroup, IconButton, Typography, useTheme } from '@mui/material';
import {
  CustomizeRewardModalStrings,
  NewCampaignStrings,
} from '../../../../common/localization/en';
import { Close } from '@mui/icons-material';
import { useFormik } from 'formik';
import {
  MessageFrequency,
  RewardFulfillmentMethod,
} from '../../../../api/models/rewardFulfillment';
import { NAME_MAX_CHAR_LIMIT } from '../../../NewCampaign/HowToRewardCreators/HowToRewardCreators.helpers';
import { TextFieldComponent } from '../../../../common/components/TextField/TextField';
import Select, { SelectOption } from '../../../../common/components/Select/Select';
import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import {
  CampaignFulfillmentConfig,
  CampaignsApiModel,
  PatchIncentiveCampaignApiModel,
} from '../../../../api/models/campaigns';
import { replaceMessageBodyWithApiVariables } from '../../../../common/components/SMS/SMS.helper';
import { patchIncentiveCampaign } from '../../../../store/slices/campaignsSlice';
import { useAppDispatch } from '../../../../store';
import { RewardTypeOptions } from '../../../NewCampaign/NewCampaign';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  campaign: CampaignsApiModel;
}

const RewardModal: FC<Props> = ({ isOpen, onClose, campaign }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    isNullReward: campaign.isNullReward,
    name: campaign.rewardTitle || '',
    value: campaign.value,
    method: campaign.fulfillmentConfig?.method || RewardFulfillmentMethod.AUTOMATIC_SMS,
    messageFrequency: campaign.isMultiFulfillment ? MessageFrequency.EVERY : MessageFrequency.FIRST,
  };

  const { values, setFieldValue, setFieldTouched, handleChange, resetForm, submitForm } = useFormik(
    {
      initialValues,
      enableReinitialize: true,
      validateOnChange: true,
      validateOnBlur: false,
      onSubmit: async (values) => {
        setIsLoading(true);

        if (campaign.fulfillmentConfig) {
          const fulfillmentConfig: CampaignFulfillmentConfig = {
            method: values.method,
            sms: {
              mediaUrl: '',
              body: replaceMessageBodyWithApiVariables(campaign.fulfillmentConfig.sms.body, true),
            },
          };

          const patchData: PatchIncentiveCampaignApiModel = {
            fulfillmentConfig,
            isMultiFulfillment: values.messageFrequency === MessageFrequency.EVERY,
            isNullReward: values.isNullReward,
            rewardTitle: values.name,
            value: values.value,
            cardEnabled: !values.isNullReward
          };

          await dispatch(patchIncentiveCampaign({ campaignId: campaign.id, values: patchData }));
        }

        setIsLoading(false);
        handleClose();
      },
    },
  );

  const handleClose = () => {
    if (isLoading) return;

    resetForm();
    onClose();
  };

  const rewardValueOptions: SelectOption<RewardTypeOptions>[] = [
    {
      value: RewardTypeOptions.InstantReward,
      name: (
        <>
          <strong>{NewCampaignStrings.InstantReward}</strong>{' '}
          <i>{NewCampaignStrings.InstantRewardInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.FutureSavings,
      name: (
        <>
          <strong>{NewCampaignStrings.FutureSavings}</strong>{' '}
          <i>{NewCampaignStrings.FutureSavingsInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.RaffleEntry,
      name: (
        <>
          <strong>{NewCampaignStrings.RaffleEntry}</strong>{' '}
          <i>{NewCampaignStrings.RaffleEntryInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.PublicRecognition,
      name: (
        <>
          <strong>{NewCampaignStrings.PublicRecognition}</strong>{' '}
          <i>{NewCampaignStrings.PublicRecognitionInfo}</i>
        </>
      ),
    },
    {
      value: RewardTypeOptions.ExclusiveAccess,
      name: (
        <>
          <strong>{NewCampaignStrings.ExclusiveAccess}</strong>{' '}
          <i>{NewCampaignStrings.ExclusiveAccessInfo}</i>
        </>
      ),
    },
  ];

  return (
    <FSModal modalIsOpen={isOpen} onClose={handleClose} rounded="12px" padding="12px" width="547px">
      <Box className={classes.titleWrapper}>
        <Typography className={classes.title}>{CustomizeRewardModalStrings.Title}</Typography>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <Close />
        </IconButton>
      </Box>
      <Typography className={classes.sectionTitle}>
        {CustomizeRewardModalStrings.IncludeReward}
      </Typography>
      <ButtonGroup className={classes.rewardOptionsWrapper}>
        <Button
          className={classes.rewardOptionButton}
          sx={{
            border: !values.isNullReward
              ? `2px solid ${theme.palette.primary.main} !important`
              : '2px solid #8B89A0',
            borderRadius: '50px 0 0 50px',
            background: !values.isNullReward ? '#F9F5FF' : '#FFF',
            color: !values.isNullReward ? theme.palette.primary.main : '#8B89A0',
            zIndex: !values.isNullReward ? 1 : 0,
          }}
          onClick={() => setFieldValue('isNullReward', false)}
        >
          {CustomizeRewardModalStrings.Yes}
        </Button>
        <Button
          className={classes.rewardOptionButton}
          sx={{
            border: values.isNullReward
              ? `2px solid ${theme.palette.primary.main} !important`
              : '2px solid #8B89A0',
            borderRadius: '0 50px 50px 0',
            background: values.isNullReward ? '#F9F5FF' : '#FFF',
            color: values.isNullReward ? theme.palette.primary.main : '#8B89A0',
            zIndex: values.isNullReward ? 1 : 0,
          }}
          onClick={() => setFieldValue('isNullReward', true)}
        >
          {CustomizeRewardModalStrings.No}
        </Button>
      </ButtonGroup>
      <Typography className={classes.sectionTitle}>
        {CustomizeRewardModalStrings.RewardType}
      </Typography>
      <Box className={classes.selectWrapper}>
        <Select
          onChange={(value) => {
            setFieldValue('value', value);
          }}
          options={rewardValueOptions}
          value={values.value}
          placeholder={CustomizeRewardModalStrings.RewardTypePlaceholder}
          withPlaceholder
          width="500px"
          fontSize="14px"
          disabled={values.isNullReward}
          withEllipsis
        />
      </Box>
      <Typography className={classes.info}>{CustomizeRewardModalStrings.RewardTypeInfo}</Typography>
      <Box className={classes.rewardNameWrapper}>
        <Typography className={classes.sectionTitle}>
          {CustomizeRewardModalStrings.RewardName}
        </Typography>
        <Typography className={classes.rewardNameLimit}>
          {values.name.length} / {NAME_MAX_CHAR_LIMIT}
        </Typography>
      </Box>
      <TextFieldComponent
        displayLabel={false}
        className={classes.rewardInput}
        name="name"
        value={values.name}
        // errorText={getError('rewardName', touched, errors)}
        onChange={(event) => {
          handleChange(event);
        }}
        setFieldTouched={setFieldTouched}
        onBlur={(event) => {
          handleChange(event);
        }}
        characterLimit={NAME_MAX_CHAR_LIMIT}
        hideCharLimit
        id="campaign-reward-name-input"
        placeholder={CustomizeRewardModalStrings.RewardPlaceholder}
        disabled={values.isNullReward}
      />
      <Typography className={classes.sectionTitle}>
        {CustomizeRewardModalStrings.RewardDelivery}
      </Typography>
      <ButtonGroup className={classes.messageOptionsWrapper}>
        <Button
          className={classes.rewardOptionButton}
          sx={{
            border:
              values.method !== RewardFulfillmentMethod.AUTOMATIC_SMS
                ? values.isNullReward
                  ? '2px solid #5242ea47 !important'
                  : `2px solid ${theme.palette.primary.main} !important`
                : '2px solid #8B89A0',
            borderRadius: '50px 0 0 50px',
            background:
              values.method !== RewardFulfillmentMethod.AUTOMATIC_SMS ? '#F9F5FF' : '#FFF',
            color:
              values.method !== RewardFulfillmentMethod.AUTOMATIC_SMS
                ? theme.palette.primary.main
                : '#8B89A0',
            zIndex: values.method !== RewardFulfillmentMethod.AUTOMATIC_SMS ? 1 : 0,
          }}
          onClick={() => {
            setFieldValue('method', RewardFulfillmentMethod.MANUAL_SMS);
            setFieldValue('messageFrequency', MessageFrequency.FIRST);
          }}
          disabled={values.isNullReward}
        >
          {CustomizeRewardModalStrings.Manual}
        </Button>
        <Button
          className={classes.rewardOptionButton}
          sx={{
            border:
              values.method === RewardFulfillmentMethod.AUTOMATIC_SMS
                ? values.isNullReward
                  ? '2px solid #5242ea47 !important'
                  : `2px solid ${theme.palette.primary.main} !important`
                : '2px solid #8B89A0',
            borderRadius: '0 50px 50px 0',
            background:
              values.method === RewardFulfillmentMethod.AUTOMATIC_SMS ? '#F9F5FF' : '#FFF',
            color:
              values.method === RewardFulfillmentMethod.AUTOMATIC_SMS
                ? theme.palette.primary.main
                : '#8B89A0',
            zIndex: values.method === RewardFulfillmentMethod.AUTOMATIC_SMS ? 1 : 0,
          }}
          onClick={() => setFieldValue('method', RewardFulfillmentMethod.AUTOMATIC_SMS)}
          disabled={values.isNullReward}
        >
          {CustomizeRewardModalStrings.Automatic}
        </Button>
      </ButtonGroup>
      <Typography className={classes.info}>{CustomizeRewardModalStrings.DeliveryInfo}</Typography>
      {values.method === RewardFulfillmentMethod.AUTOMATIC_SMS && (
        <>
          <Typography className={classes.sectionTitle}>
            {CustomizeRewardModalStrings.RewardFrequency}
          </Typography>
          <ButtonGroup className={classes.messageOptionsWrapper}>
            <Button
              className={classes.rewardOptionButton}
              sx={{
                border:
                  values.messageFrequency !== MessageFrequency.EVERY
                    ? values.isNullReward
                      ? '2px solid #5242ea47 !important'
                      : `2px solid ${theme.palette.primary.main} !important`
                    : '2px solid #8B89A0',
                borderRadius: '50px 0 0 50px',
                background: values.messageFrequency !== MessageFrequency.EVERY ? '#F9F5FF' : '#FFF',
                color:
                  values.messageFrequency !== MessageFrequency.EVERY
                    ? theme.palette.primary.main
                    : '#8B89A0',
                zIndex: values.messageFrequency !== MessageFrequency.EVERY ? 1 : 0,
              }}
              onClick={() => setFieldValue('messageFrequency', MessageFrequency.FIRST)}
              disabled={values.isNullReward}
            >
              {CustomizeRewardModalStrings.FirstClip}
            </Button>
            <Button
              className={classes.rewardOptionButton}
              sx={{
                border:
                  values.messageFrequency === MessageFrequency.EVERY
                    ? values.isNullReward
                      ? '2px solid #5242ea47 !important'
                      : `2px solid ${theme.palette.primary.main} !important`
                    : '2px solid #8B89A0',
                borderRadius: '0 50px 50px 0',
                background: values.messageFrequency === MessageFrequency.EVERY ? '#F9F5FF' : '#FFF',
                color:
                  values.messageFrequency === MessageFrequency.EVERY
                    ? theme.palette.primary.main
                    : '#8B89A0',
                zIndex: values.messageFrequency === MessageFrequency.EVERY ? 1 : 0,
              }}
              onClick={() => setFieldValue('messageFrequency', MessageFrequency.EVERY)}
              disabled={values.isNullReward}
            >
              {CustomizeRewardModalStrings.EveryClip}
            </Button>
          </ButtonGroup>
          <Typography className={classes.info}>
            {CustomizeRewardModalStrings.FrequencyInfo}
          </Typography>
        </>
      )}
      <Box className={classes.buttonsWrapper}>
        <Button
          variant="outlined"
          className={clsx(classes.button, classes.cancelButton)}
          onClick={handleClose}
          disabled={isLoading}
        >
          {CustomizeRewardModalStrings.Cancel}
        </Button>
        <LoadingButton
          className={classes.button}
          variant="contained"
          onClick={submitForm}
          loading={isLoading}
        >
          {CustomizeRewardModalStrings.ConfirmChanges}
        </LoadingButton>
      </Box>
    </FSModal>
  );
};

export default RewardModal;
