import { SVGProps } from 'react';
import { theme } from '../../../theme';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="10" cy="13" r="9.5" stroke={theme.palette.primary.main} />
    <path
      d="M9.05095 9.157C9.05095 8.90767 9.13895 8.706 9.31495 8.552C9.49095 8.39067 9.72195 8.31 10.0079 8.31C10.2939 8.31 10.5249 8.39067 10.7009 8.552C10.8769 8.706 10.9649 8.90767 10.9649 9.157C10.9649 9.399 10.8769 9.60067 10.7009 9.762C10.5249 9.916 10.2939 9.993 10.0079 9.993C9.72195 9.993 9.49095 9.916 9.31495 9.762C9.13895 9.60067 9.05095 9.399 9.05095 9.157ZM10.7779 10.906V17H9.22695V10.906H10.7779Z"
      fill={theme.palette.primary.main}
    />
  </svg>
);
