import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  className?: string;
}

export const TimeSelectIcon: FC<Props> = ({ className }) => (
  <Box className={className}>
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_259_156677)">
        <path
          d="M2.1875 9C2.1875 10.9394 2.95792 12.7994 4.32928 14.1707C5.70064 15.5421 7.5606 16.3125 9.5 16.3125C11.4394 16.3125 13.2994 15.5421 14.6707 14.1707C16.0421 12.7994 16.8125 10.9394 16.8125 9C16.8125 7.0606 16.0421 5.20064 14.6707 3.82928C13.2994 2.45792 11.4394 1.6875 9.5 1.6875C7.5606 1.6875 5.70064 2.45792 4.32928 3.82928C2.95792 5.20064 2.1875 7.0606 2.1875 9ZM18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948212 7.11305 0 9.5 0C11.8869 0 14.1761 0.948212 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9ZM10.3438 4.21875V9C10.3438 9.28125 10.2031 9.54492 9.96758 9.70312L6.59258 11.9531C6.20586 12.2133 5.68203 12.1078 5.42188 11.7176C5.16172 11.3273 5.26719 10.807 5.65742 10.5469L8.65625 8.55V4.21875C8.65625 3.75117 9.03242 3.375 9.5 3.375C9.96758 3.375 10.3438 3.75117 10.3438 4.21875Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_259_156677">
          <rect width="18" height="18" fill="white" transform="matrix(-1 0 0 1 18.5 0)" />
        </clipPath>
      </defs>
    </svg>
  </Box>
);
