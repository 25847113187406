import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: '20px',
    width: '100%',
    minHeight: '700px',
    background: '#fff',
    border: '1px solid #D9D9D9',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  },
}));
