import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    height: '100%',
    paddingLeft: '20px',
  },
  title: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '28px',
    letterSpacing: '0.02em',
    textAlign: 'left',
    color: '#8B89A0',
  },
  tooltipIcon: {
    marginTop: '6px',
  },
  animationInfoWrapper: {
    background: '#F9FAFB',
    borderRadius: '16px',
    padding: '14px 10px 14px 10px',
    marginTop: '8px',
    marginBottom: '8px',
  },
  infoText: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '20px',
    textAlign: 'left',
    color: '#475467',
    userSelect: 'none',
    marginTop: '5px',
    marginLeft: '20px',
  },
  tooltip: {
    color: '#8B89A0',
    fontSize: '10px',
    padding: '2px',
    borderRadius: '50%',
    border: '1px solid #8B89A0',
    width: '18px',
    height: '18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    userSelect: 'none',
    cursor: 'pointer',
    '& svg': {
      width: '12px',
      height: '12px',
    },
  },
  image: {
    position: 'absolute',
  },
  preview: {
    [`@media (max-height: ${theme.breakpoints.values.md}px)`]: {
      transform: 'scale(0.9)',
    },
  },
  playIconWrapper: {
    position: 'absolute',
    top: 'calc(50% - 230px)',
    left: 'calc(50% + 156px)',
  },
  playIcon: {
    '& svg': {
      cursor: 'pointer',
      width: '57px',
      height: '57px',
    },
    '& path': {
      fill: '#8F9195',
    },
  },
  pauseIcon: {
    '& svg': {
      cursor: 'pointer',
      width: '57px',
      height: '57px',
    },
    '& path': {
      fill: '#292D3266',
    },
  },
  logo: {
    position: 'relative',
    height: '40px',
    width: 'inherit',
    objectFit: 'contain',
    marginBottom: '50px',
    marginTop: '35px',
    padding: '0 16px',
  },
  buttonsWrapper: {
    position: 'absolute',
    bottom: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gradient: {
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.68) 100%)',

    width: '265px',
    height: '118px',
    paddingTop: '18px',
  },
  button: {
    height: '42px',
    width: '211px',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50px',
    fontSize: '12px',
    fontWeight: 600,
  },
  recordButton: {
    boxShadow: '0px 4px 7px -2px rgba(44, 44, 44, 0.15)',
  },
  uploadButton: {
    color: 'white',
    border: '1px solid #fff',
    marginTop: '5px',
  },
  glassContainer: {
    width: '255px',
    minHeight: '176px',
    height: 'min-content',
    borderRadius: '8px',
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.45) 35.94%, rgba(0, 0, 0, 0.45) 100%)',
    backdropFilter: 'blur(3.5px)',
    textAlign: 'center',
    margin: '20px auto 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  uploadText: {
    lineHeight: '111%',
    letterSpacing: '0.35px',
    fontSize: '28px',
    width: '170px',
    fontWeight: 600,
    margin: '0 auto',
    color: 'white',
    textAlign: 'center',
  },

  contentWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'black',
  },
  revealWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  companyName: {
    fontSize: '28px',
    lineHeight: '31px',
    fontWeight: 600,
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
    wordBreak: 'break-word',
    maxWidth: '80%',
  },
  PlayIcon: {
    zIndex: '100',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    width: 56,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    pointerEvents: 'none',
    cursor: 'pointer',
    backgroundColor: '#ffffff80',
    transitionDuration: '500ms',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    backdropFilter: 'blur(4px)',
  },
  buttonWrapper: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: '20px',
    zIndex: '100',
  },
  skeleton: {
    borderRadius: '6px',
    position: 'absolute',
  },
  infoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
}));
