import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backgroundImg from '../../../common/assets/NoResultsContent.svg';

export const useNoResultsContentStyles = makeStyles((theme: Theme) => ({
  Container: {
    position: 'absolute',
    zIndex: 1,
    width: 'calc(100% - 0px)',
    height: '100%',
    paddingLeft: '48px',
  },
  Blur: {
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(10px)',
  },
  Chart: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    height: '92%',
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
  },
  Message: {
    position: 'relative',
    zIndex: 2,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 30px 0',
    width: 'fit-content',
    backgroundColor: theme.palette.common.white,
    borderRadius: '12px',
    boxShadow: '0px 18px 30px rgba(64, 77, 104, 0.1)',
  },
  Title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '26px',
    marginBottom: '15px',
    color: 'rgba(143, 148, 182, 1)',
    opacity: 1,
  },
  SubTitle: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(173, 173, 173, 1)',
    letterSpacing: '0.02em',
    opacity: 1,
    whiteSpace: 'pre-line',
    marginBottom: '20px',
  },
}));
