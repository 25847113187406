export enum PerfomanceEvents {
  Perfomance_Page_Load = 'perfomance_page',
  Perfomance_Patients_Statistic_Block_Click = 'perfomance_patients_statistic_block_click',
  Perfomance_Creators_Statistic_Block_Click = 'perfomance_creators_statistic_block_click',
  Perfomance_Videos_Statistic_Block_Click = 'perfomance_videos_statistic_block_click',
  Perfomance_Shares_Statistic_Block_Click = 'perfomance_shares_statistic_block_click',
  Perfomance_Views_Statistic_Block_Click = 'perfomance_views_statistic_block_click',
  Perfomance_Clicks_Statistic_Block_Click = 'perfomance_clicks_statistic_block_click',
  Perfomance_Campaigns_Statistic_Block_Click = 'perfomance_campaigns_statistic_block_click',
  Perfomance_Socialverses_Statistic_Block_Click = 'perfomance_socialverses_statistic_block_click',
  Perfomance_Synced_GBP_Statistic_Block_Click = 'perfomance_synced_gbp_statistic_block_click',
  Perfomance_Cumulative_Radio_Click = 'perfomance_cumulative_radio_click',
  Perfomance_Perday_Radio_Click = 'perfomance_perday_radio_click',
  Perfomance_Apply_Date_Filter = 'perfomance_apply_date_filter',
  Perfomance_Reset_Date_Filter = 'perfomance_reset_date_filter',
  Perfomance_Venues_Statistic_Block_Click = 'accounts_venues_statistic_block_click',
}
