import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { EditableName } from '../../EditableText/EditableName';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { updateUserById } from '../../../../store/slices/userDetailsSlice';
import { setRefetch } from '../../../../store/slices/videosSlice';
import { getFullName } from '../../../../services/utilities';
import { useVideoAuthorInfoStyles } from './VideoAuthorInfo.styles';
import { EmailIcons } from '../../../assets/newDesign/SocialVerse/EmailIcon';
import { UserPhoneStatusIcon } from '../../../../pages/content/tableSection/contentTable/UserPhoneStatusIcon';
import { useToasts } from 'react-toast-notifications';
import { VideoDetailsModalStringsDv5 } from '../../../localization/en';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';
import { UserPhoneNumber } from '../../../../pages/content/tableSection/contentTable/UserPhoneNumber';

interface VideoAuthorInfoProps {}

const VideoAuthorInfo: FC<VideoAuthorInfoProps> = () => {
  const dispatch = useAppDispatch();
  const { userDetails } = useTypedSelector((state) => state.userDetails);

  const styles = useVideoAuthorInfoStyles();
  const { addToast } = useToasts();
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopyEmail = (email: string) => {
    copyToClipboard(email, false);
    addToast(VideoDetailsModalStringsDv5.CopiedEmail, {
      autoDismiss: true,
      appearance: 'success',
      autoDismissTimeout: 2500,
    });
  };

  return (
    <Box>
      <EditableName
        firstName={userDetails?.firstName || ''}
        lastName={userDetails?.lastName || ''}
        onChange={async ({ firstName, lastName }) => {
          if (userDetails && userDetails.id)
            await dispatch(
              updateUserById({
                update: {
                  firstName,
                  lastName,
                  displayName: getFullName({ firstName, lastName }),
                },
                id: userDetails.id,
              }),
            );
          dispatch(setRefetch(true));
        }}
      />
      <Typography className={styles.userContacts}>
        {userDetails?.phoneNumber && <UserPhoneStatusIcon user={userDetails} />}
        <UserPhoneNumber phone={userDetails?.phoneNumber || ''} fontSize="16px" />
        {userDetails?.email && userDetails?.email !== '---' && (
          <Box onClick={() => handleCopyEmail(userDetails.email!)} className={styles.email}>
            <Typography>|</Typography> <EmailIcons />
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default VideoAuthorInfo;
