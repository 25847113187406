import { Box, IconButton, IconButtonProps, useTheme } from '@mui/material';
import { BoxProps } from '@material-ui/core';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import React from 'react';

interface FTEModalButtonProps {
  disableDefaultMargin?: boolean;
  iconButtonProps?: IconButtonProps;
  containerProps?: BoxProps;
  onClick?: () => void;
}

export const FTEModalButton: React.FC<FTEModalButtonProps> = ({
  disableDefaultMargin,
  iconButtonProps,
  containerProps,
  onClick,
}) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="flex-end" {...containerProps}>
      <IconButton
        style={{
          backgroundColor: theme.palette.common.white,
          color: theme.palette.common.gray,
          margin: disableDefaultMargin ? 'inherit' : '0 30px 16px',
        }}
        onClick={onClick}
        {...iconButtonProps}
      >
        <HelpOutlineRoundedIcon />
      </IconButton>
    </Box>
  );
};
