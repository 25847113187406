import { Box, Typography } from '@mui/material';
import React, { useCallback, useRef } from 'react';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

import { mergeRefs } from '../../../services/hooks/useMergeRefs';
import { NewCampaignEvents } from '../../constants/events/newCampaignEvents';
import {
  wrapTemplateVariableWithSquareTags,
  wrapTemplateVariableWithTags,
} from '../SMS/SMS.helper';
import { TextFieldComponent, TextFieldProps } from '../TextField/TextField';
import { MessageTypeTag } from './MessageTypeTag';
import { AddVariableButton, MessageBuilderButton } from './AddVariableButton';
import { UploadImageButton } from './UploadImageButton';

export interface MessageBuilderProps {
  buttons: MessageBuilderButton[];
  message: string;
  disabled?: boolean;
  inputRef?: TextFieldProps['inputRef'];
  onMessageChange: (message: string) => void;
  borderRadius?: string;
  background?: string;
  squareBrackets?: boolean;
  limit?: number;
  errorText?: string;
  withUploadImageButton?: boolean;
  isMms?: boolean;
  maxHeight?: string;
}

export const MessageBuilder: React.FC<MessageBuilderProps> = ({
  message,
  disabled,
  inputRef,
  buttons,
  onMessageChange,
  borderRadius = '100px',
  background,
  squareBrackets = false,
  limit,
  errorText,
  withUploadImageButton = false,
  isMms,
  maxHeight,
}) => {
  const messageRef = useRef<HTMLInputElement>(null);

  const { trackEvent } = useTrackEvent();

  const handleTemplateButtonClick = useCallback(
    async (templateVariable: string) => {
      const cursorPos =
        typeof messageRef.current?.selectionStart === 'number'
          ? messageRef.current?.selectionStart
          : messageRef.current?.value.length;
      if (typeof cursorPos !== 'number') return;

      trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageTemplateButtonClick, {
        value: templateVariable,
      });

      const textBefore = message.substring(0, cursorPos);
      const textAfter = message.substring(cursorPos, message.length);

      const value = squareBrackets
        ? wrapTemplateVariableWithSquareTags(templateVariable)
        : wrapTemplateVariableWithTags(templateVariable);

      const updatedMessage = `${textBefore}${value}${textAfter}`;

      await onMessageChange(updatedMessage);
      if (messageRef.current) {
        const newCursorPos = cursorPos + value.length;
        messageRef.current.selectionStart = newCursorPos;
        messageRef.current.selectionEnd = newCursorPos;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message, onMessageChange, squareBrackets],
  );

  return (
    <Box>
      <TextFieldComponent
        multiline
        fullWidth
        value={message}
        onChange={(e) => {
          onMessageChange(e.target.value);
          trackEvent(NewCampaignEvents.NewRewardCampaignCustomizeMessageTyped, {
            value: e.target.value,
          });
        }}
        inputRef={mergeRefs([inputRef, messageRef])}
        disabled={disabled}
        characterLimit={limit}
        hideCharLimit
        errorText={errorText}
        displayLabel={false}
        id="message-builder-textarea-input"
        inputProps={{
          style: {
            paddingBottom: '48px',
            maxHeight,
          },
        }}
      />
      <Box
        sx={{ position: 'relative' }}
        onClick={() => {
          messageRef.current?.focus();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: errorText ? -66 : -42,
            width: '100%',
            display: 'flex',
            px: '12px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AddVariableButton
              buttons={buttons}
              onSelect={(button) => {
                handleTemplateButtonClick(button.value);
              }}
            />
            {withUploadImageButton && <UploadImageButton />}
          </Box>

          {/* spacer */}
          <Box sx={{ width: '100%', pointerEvents: 'none' }} />

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography color="#8F94B6" fontWeight={700} fontSize={12} mr="10px">
              {`${message.length}/${limit}`}
            </Typography>
            <MessageTypeTag isMms={isMms} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
