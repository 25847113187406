import styled from 'styled-components';

interface ContainerProps {
  collapsed: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 80px;
  background-color: var(--sidebarActiveBgr);
  color: #cfd2e4;
  ${(props) =>
    props.collapsed ? 'justify-content: center; padding-left: 0;' : 'justify-content: flex-start;'};
  transition: 0.2s ease-in;

  div {
    ${(props) => props.collapsed && 'margin-right: 0;'}
  }
`;

export const UserInfo = styled.div`
  display: flex;
  width: 155px;
  font-size: 14px;
`;

export const UserName = styled.div`
  margin-left: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UserEmail = styled.div`
  color: #cfd2e4;
  text-overflow: ellipsis;
  overflow: hidden;
`;
