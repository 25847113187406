import * as React from 'react';
import { FC, memo } from 'react';

interface Props {
  color?: string;
  scale?: number;
}

const SvgComponent: FC<Props> = ({ color, scale }) => (
  <svg
    width={31}
    height={31}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `scale(${scale || 1})`,
    }}
  >
    <path
      d="M15.5.083C7.005.083.083 7.005.083 15.5c0 8.495 6.922 15.417 15.417 15.417 8.495 0 15.417-6.922 15.417-15.417C30.917 7.005 23.995.083 15.5.083Zm7.37 11.871-8.742 8.741a1.155 1.155 0 0 1-1.634 0L8.13 16.332a1.163 1.163 0 0 1 0-1.634 1.163 1.163 0 0 1 1.634 0l3.546 3.546 7.924-7.924a1.163 1.163 0 0 1 1.634 0 1.163 1.163 0 0 1 0 1.634Z"
      fill={color || '#67CE67'}
    />
  </svg>
);

const CheckedStepperIcon = memo(SvgComponent);
export default CheckedStepperIcon;
