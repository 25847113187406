import { Box, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FC, MouseEvent } from 'react';
import { theme } from '../../../../theme';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& thead': {
      '& tr th:first-child > div': {
        paddingLeft: '20px !important',
      },
    },
  },
}));

interface ApproveBtnProps {
  status: string;
  handleClick: () => void;
}

export const ApproveBtn: FC<ApproveBtnProps> = ({ status, handleClick }) =>
  status !== 'PENDING' ? (
    <Box
      sx={{
        borderRadius: '30px',
        background: `${theme.palette.common.lime}1A`,
        color: theme.palette.common.lime,
        lineHeight: '20px',
        height: '20px',
      }}
      textAlign="center"
      fontSize={'12px'}
    >
      Approved
    </Box>
  ) : (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();
          handleClick();
        }}
        variant="outlined"
        fullWidth
        sx={{ borderRadius: '100px', height: '20px', fontSize: '12px' }}
      >
        Approve
      </Button>
    </Box>
  );
