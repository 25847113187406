export const PendingStatusIcon = () => {
  return (
    <>
      <svg
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.088 6.912C15.684 5.508 13.848 4.8 12 4.8V12L6.912 17.088C9.72 19.896 14.28 19.896 17.1 17.088C19.908 14.28 19.908 9.72 17.088 6.912ZM12 0C5.376 0 0 5.376 0 12C0 18.624 5.376 24 12 24C18.624 24 24 18.624 24 12C24 5.376 18.624 0 12 0ZM12 21.6C6.696 21.6 2.4 17.304 2.4 12C2.4 6.696 6.696 2.4 12 2.4C17.304 2.4 21.6 6.696 21.6 12C21.6 17.304 17.304 21.6 12 21.6Z"
          fill="#FF7300"
        />
      </svg>
    </>
  );
};
