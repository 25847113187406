import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './Select.styles';
import { Box, Divider, Typography } from '@mui/material';
import ArrowRightSmall from '../../assets/ArrowRightSmall';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { CheckmarkIcon } from '../../assets/newDesign/CheckmarkIcon';

export interface SelectOption<T> {
  value: T;
  name: string | number | JSX.Element;
  disabled?: boolean;
  withBottomDivider?: boolean;
  invisible?: boolean;
}

interface Props<T> {
  options: SelectOption<T>[];
  value: T;
  onChange: (value: T) => void;
  onClick?: () => void;
  width?: string;
  height?: string;
  withPlaceholder?: boolean;
  placeholder?: string | number;
  margin?: string;
  title?: string;
  fontSize?: string;
  fontWeight?: string;
  position?: 'top' | 'bottom';
  withEllipsis?: boolean;
  optionsWrapperHeight?: string;
  withDynamicPosition?: boolean;
  dynamicPositionBottomOffset?: number;
  disabled?: boolean;
}

const Select = <T,>({
  options,
  value,
  onChange,
  onClick,
  width,
  height,
  withPlaceholder,
  placeholder,
  margin,
  title,
  fontSize,
  fontWeight,
  position = 'bottom',
  withEllipsis,
  optionsWrapperHeight,
  withDynamicPosition,
  dynamicPositionBottomOffset = 0,
  disabled,
}: Props<T>) => {
  const styles = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [dynamicPosition, setDynamicPosition] = useState<'top' | 'bottom'>('bottom');

  const handleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleChange = (value: T) => {
    onChange(value);
    handleOpen();
  };

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsOpen(false);
    },
  });

  const selectRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (withDynamicPosition && selectRef.current && optionsRef.current) {
      const select = selectRef.current;
      const options = optionsRef.current;

      const selectPosition = select.getBoundingClientRect();
      const optionsPosition = options.getBoundingClientRect();
      const toBottom = window.innerHeight - selectPosition.top - dynamicPositionBottomOffset;

      if (optionsPosition.height > toBottom) {
        setDynamicPosition('top');
      }
    }
  }, [dynamicPositionBottomOffset, withDynamicPosition]);

  const optionsPosition = withDynamicPosition ? dynamicPosition : position;

  return (
    <div className={styles.container} style={{ margin: margin }} ref={selectRef} onClick={onClick}>
      {title && <Typography className={styles.title}>{title}</Typography>}
      <Box
        className={styles.select}
        style={{
          width: width && width,
          height: height && height,
          whiteSpace: withEllipsis ? 'nowrap' : 'normal',
          color: disabled ? '#00000042' : '#667085',
          borderColor: disabled ? '#0000001f' : '#D0D5DD',
        }}
        onClick={() => !disabled && handleOpen()}
        ref={refDetectClickOutside}
        id="select"
      >
        {withPlaceholder && !value && (
          <Typography
            fontSize={fontSize || '16px'}
            className={`${styles.placeholder} ${styles.input}`}
            sx={{ color: disabled ? '#00000042' : '#667085' }}
          >
            {placeholder}
          </Typography>
        )}
        {!withPlaceholder && !value && (
          <Typography
            fontSize={fontSize || '16px'}
            fontWeight={fontWeight || 400}
            className={styles.input}
            sx={{ color: disabled ? '#00000042' : '#464c71' }}
          >
            {options[0].name}
          </Typography>
        )}
        {value && (
          <Typography
            fontSize={fontSize || '16px'}
            fontWeight={fontWeight || 400}
            className={styles.input}
            sx={{ color: disabled ? '#00000042' : '#464c71' }}
          >
            {options.find((option) => option.value === value)?.name}
          </Typography>
        )}
        <Box
          className={styles.arrow}
          style={{
            transform: `${
              isOpen
                ? optionsPosition === 'top'
                  ? 'rotate(-90deg)'
                  : 'rotate(90deg)'
                : 'rotate(0)'
            }`,
          }}
        >
          <ArrowRightSmall stroke={disabled ? '#00000042' : '#667085'} />
        </Box>
      </Box>
      <Box
        className={styles.optionsWrapper}
        sx={{
          visibility: isOpen ? 'visible' : 'hidden',

          top: optionsPosition === 'bottom' ? '100%' : 'unset',
          marginTop: optionsPosition === 'bottom' ? '5px' : 'unset',

          bottom: optionsPosition === 'top' ? '100%' : 'unset',
          marginBottom: optionsPosition === 'top' ? '5px' : 'unset',

          fontSize: fontSize || '16px',
          fontWeight: fontWeight || '400',
          maxHeight: optionsWrapperHeight || 'unset',
          zIndex: '101 !important',
        }}
        ref={optionsRef}
      >
        {options.map((option, index) => {
          if (option.invisible) return null;

          return (
            <Box key={index}>
              <Box
                className={`${styles.option} ${option.value === value && styles.selected} ${
                  option.disabled && styles.disabledOption
                }`}
                onClick={() => !option.disabled && handleChange(option.value)}
                id={`select_option${index}`}
                key={index}
              >
                {option.name}
                {option.value === value && <CheckmarkIcon className={styles.checkmark} />}
              </Box>
              {option.withBottomDivider && <Divider color="#EAECF0" />}
            </Box>
          );
        })}
      </Box>
    </div>
  );
};

export default Select;
