import { useTheme } from '@mui/styles';
import React from 'react';

const CampaignInvitationIcon = () => {
  const theme = useTheme();

  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 17H24M14 24H30M14 36V40.671C14 41.7367 14 42.2696 14.2185 42.5432C14.4084 42.7812 14.6965 42.9197 15.0011 42.9194C15.3513 42.919 15.7673 42.5861 16.5995 41.9204L21.3704 38.1037C22.345 37.324 22.8323 36.9341 23.375 36.6569C23.8564 36.411 24.3689 36.2312 24.8984 36.1225C25.4953 36 26.1194 36 27.3675 36H32.4C35.7603 36 37.4405 36 38.7239 35.346C39.8529 34.7708 40.7708 33.8529 41.346 32.7239C42 31.4405 42 29.7603 42 26.4V15.6C42 12.2397 42 10.5595 41.346 9.27606C40.7708 8.14708 39.8529 7.2292 38.7239 6.65396C37.4405 6 35.7603 6 32.4 6H15.6C12.2397 6 10.5595 6 9.27606 6.65396C8.14708 7.2292 7.2292 8.14708 6.65396 9.27606C6 10.5595 6 12.2397 6 15.6V28C6 29.8599 6 30.7899 6.20445 31.5529C6.75925 33.6235 8.37653 35.2408 10.4471 35.7956C11.2101 36 12.1401 36 14 36Z"
        stroke={theme.palette.primary.main}
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CampaignInvitationIcon;
