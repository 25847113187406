import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

import { Container, PageTitle, PageSubtitle } from './TitleSection.style';
import { TotalClicks } from '../../../common/components/TotalClicks/TotalClicks';

interface TitleSectionProps {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  extraRightBlock?: ReactNode;
  centered?: boolean;
  showTotalClicks?: boolean;
}
export const TitleSection: FC<TitleSectionProps> = ({
  title,
  subTitle = '',
  extraRightBlock,
  centered = false,
  showTotalClicks = true,
}) => {
  return (
    <Container>
      <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <PageTitle centered={centered}>{title}</PageTitle>
          <PageSubtitle isSubtitle={Boolean(subTitle)}>{subTitle}</PageSubtitle>
        </Box>
        {showTotalClicks && (
          <Box marginRight="40px">
            <TotalClicks />
          </Box>
        )}
      </Box>
      {extraRightBlock}
    </Container>
  );
};
