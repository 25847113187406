import { FC } from 'react';

export const LabelIcon: FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18 9.5C19.1881 10.6881 21.388 12.388 21.6105 13.0729C21.8063 13.6755 21.8063 14.3245 21.6105 14.927C21.388 15.612 20.7939 16.2061 19.6059 17.3941L17.3941 19.6059C16.2061 20.7939 15.612 21.388 14.927 21.6105C14.3245 21.8063 13.6755 21.8063 13.0729 21.6105C12.388 21.388 11.7939 20.7939 10.6059 19.6059L2.93726 11.9373C2.59135 11.5914 2.4184 11.4184 2.29472 11.2166C2.18506 11.0376 2.10425 10.8425 2.05526 10.6385C2 10.4083 2 10.1637 2 9.67452L2 6.82548C2 6.3363 2 6.09171 2.05526 5.86154C2.10425 5.65746 2.18506 5.46237 2.29472 5.28343C2.4184 5.0816 2.59136 4.90864 2.93726 4.56274L4.56274 2.93726C4.90865 2.59135 5.0816 2.4184 5.28343 2.29472C5.46237 2.18506 5.65746 2.10425 5.86154 2.05526C6.09171 2 6.3363 2 6.82548 2H9M8 8H8.01M8.5 8C8.5 8.27614 8.27614 8.5 8 8.5C7.72386 8.5 7.5 8.27614 7.5 8C7.5 7.72386 7.72386 7.5 8 7.5C8.27614 7.5 8.5 7.72386 8.5 8Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2 8V2M11.2 5H17.2"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
