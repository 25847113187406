import { FC } from "react";

export const Incentiveicon:FC = () => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00586 2.91797H14.9935V5.42829V7.89785C14.9935 10.6471 12.7521 12.8758 9.99969 12.8758C7.24167 12.8758 5.00586 10.654 5.00586 7.89151V5.40327V2.91797Z"
      stroke="#8B89A0"
      strokeWidth={1.5}
      strokeLinejoin="round"
    />
    <path
      d="M10 14.2695V17.5987"
      stroke="#8B89A0"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M6.6709 17.5977H13.3293"
      stroke="#8B89A0"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
    <path
      d="M14.9938 4.57812C14.9938 4.57812 17.0746 4.57812 17.4908 4.57812C17.9069 4.57812 18.3231 4.99303 18.3231 5.40794C18.3231 5.82284 18.725 9.55699 14.5635 9.55699"
      stroke="#8B89A0"
      strokeWidth={1.5}
    />
    <path
      d="M5.00641 4.57812C5.00641 4.57812 2.92565 4.57812 2.5095 4.57812C2.09335 4.57812 1.67719 4.99303 1.67719 5.40794C1.67719 5.82284 1.28281 9.55699 5.44434 9.55699"
      stroke="#8B89A0"
      strokeWidth={1.5}
    />
  </svg>
);

