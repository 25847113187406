import styled from 'styled-components';
import { TableHead, TableRow, TableCell } from '@mui/material';
import { theme } from '../../../theme';

interface CommonTableContainerP {
  padding: string;
}

export const CommonTableContainer = styled.div<CommonTableContainerP>`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 120px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 10px;
  margin-bottom: 24px;
  font-size: 12px;
  font-weight: normal;
  color: #464c71;
  overflow: hidden;

  table {
    table-layout: auto;
    margin: 0;
    border: 0;
    /* border-spacing: 0 10px; */
    border-collapse: separate;
    thead {
      tr {
        div {
          padding: 10px;
          color: #8b89a0;
          justify-content: flex-start;
        }
      }
    }
    td,
    th {
      border: none;
    }
  }
`;
export const StyledCategory = styled.span`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  align-items: center;
  letter-spacing: 0.15px;
  color: #2d5af0;
  margin-left: 20px;
`;

interface SmallImageContainerProps {
  borderRadius: string;
  border?: string;
  imgViewBottom?: boolean;
}
export const SmallImageContainer = styled.div<SmallImageContainerProps>`
  position: relative;
  width: 34px;
  height: 34px;
  border-radius: ${(props) => (props.borderRadius !== '6px' ? props.borderRadius : '100%')};
  ${(props) => props.border && `border: ${props.border}`};
  overflow: hidden;
  margin-right: 14px;
  box-sizing: content-box;
  ${(props) =>
    props.imgViewBottom &&
    `
  img {
    object-fit: none;
    object-position: 100% 100%;
  }
  `};
`;

export const TableHeaderStyled = styled(TableHead)`
  font-size: 10px;
  font-weight: bold;
  color: var(--textGrey);
  th {
    white-space: nowrap;
  }
`;

export const TableHeaderRow = styled(TableRow)``;

interface TableRowProps {
  selected: boolean;
  hasShadow?: boolean;
}
export const TableRowStyled = styled(TableRow)<TableRowProps>`
  color: var(--tableTextColor);
  height: 48px;
  border-collapse: collapse;
  background-color: ${(props) => (props.selected ? '#D9D4FF' : 'var(--white)')};
  td {
    position: relative;
    padding-left: 10px;
    vertical-align: middle;
    border-top: 1px solid #e1e5ee !important;
    background-color: inherit;
  }

  td:first-child {
    padding-left: 20px;
  }
  :hover > td > div > svg {
    display: block;
  }
`;

export const ThStyled = styled(TableCell)`
  font-size: 10px;
  font-weight: bold;
  padding: 0 !important;
  color: var(--textGrey);
  th {
    white-space: nowrap;
  }
`;

export const ThSortable = styled(ThStyled)`
  cursor: pointer;
`;

export const SortableIcon = styled.i`
  font-size: 10px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

export const ImagesText = styled.div`
  font-weight: normal;
  font-size: 14px;
`;

interface DefaultImageProps {
  borderRadius?: string;
}

export const DefaultImage = styled.div<DefaultImageProps>`
  width: 34px;
  height: 34px;
  border-radius: ${(theme) => (theme.borderRadius ? theme.borderRadius : '100%')};
  background-color: #22aae1;
  margin-right: 14px;
`;

export const TopSectionContainer = styled.div<{ showTopDivider?: boolean; isSliptted?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 12px;
  padding-top: ${(props) => (props.showTopDivider ? '10px' : 0)};
  border-top: ${(props) => (props.showTopDivider ? '1px solid #e1e5ee !important' : 'none')};
  position: sticky;
  bottom: 0;
  left: 0;
  background: ${(props) => (props.isSliptted ? '#f5f6f8' : theme.palette.common.white)};
  z-index: 9;
`;
export const ActionBox = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px 0;
`;
export const TextSpan = styled.span`
  color: #1a1538;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
`;

export const TableSectionWrapper = styled.div`
  position: relative;
  height: calc(100vh - 100px);

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    max-height: 900px;
  }

  & .MuiTableCell-head:first-child div {
    padding-left: 25px;
  }
`;

export const WrapperWithDots = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 400px;
  white-space: nowrap;
`;

export const TooltipCircle = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 8px;
  color: #2196f3;
  border-radius: 50%;
  background: #eaf4ff;
`;
