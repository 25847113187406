import { FC } from 'react';

type IFBReelsIconProps = {
  color?: string;
};

export const FBReelsIcon: FC<IFBReelsIconProps> = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.75405 0H14.2459C15.8261 0 17.2638 0.644531 18.3069 1.67969C19.3516 2.71647 20 4.14714 20 5.7194V14.2822C20 15.8512 19.3516 17.2803 18.3101 18.3171L18.3069 18.3203C17.2622 19.3571 15.8245 20 14.2476 20H5.75405C4.17226 20 2.73293 19.3555 1.68986 18.3203L1.65056 18.2764C0.632062 17.2445 0 15.8317 0 14.2806V5.7194C0 4.14551 0.646799 2.71647 1.68986 1.67969C2.73293 0.642904 4.17062 0 5.75405 0ZM14.9844 5.04883L14.9959 5.06673H18.5328C18.3904 4.14225 17.9466 3.31217 17.308 2.67578C16.522 1.89453 15.438 1.40951 14.2459 1.40951H12.7935L14.9844 5.04883ZM13.3486 5.06673L11.1462 1.40951H6.3157L8.54429 5.06673H13.3486ZM6.89537 5.06673L4.74046 1.52832C3.95284 1.71712 3.24709 2.1224 2.69199 2.67578C2.05338 3.31055 1.61127 4.14225 1.46717 5.06673H6.89537ZM18.582 6.47624H1.41804V14.2822C1.41804 15.4492 1.89127 16.512 2.65597 17.29L2.69199 17.3242C3.47798 18.1055 4.56362 18.5921 5.75405 18.5921H14.2459C15.438 18.5921 16.522 18.1071 17.3064 17.3275L17.3096 17.3242C18.094 16.5446 18.582 15.4671 18.582 14.2822V6.47624ZM8.34289 8.94369L12.7362 11.7839C12.8066 11.8294 12.8705 11.888 12.9212 11.9596C13.121 12.2477 13.0473 12.6432 12.7575 12.8418L8.38218 15.4085C8.27247 15.498 8.13165 15.5518 7.97609 15.5518C7.6224 15.5518 7.33584 15.2669 7.33584 14.9154V9.46452H7.33912C7.33912 9.33919 7.37678 9.21224 7.45374 9.10156C7.65679 8.81348 8.05469 8.74349 8.34289 8.94369Z"
      fill={color || '#8B89A0'}
    />
  </svg>
);
