import { FC } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { DetailsModalStrings } from '../../../../localization/en';
import { getInteractionUnits, useStyles } from './InteractionsBlock.helper';
import { VideoMetricsStoreModel } from '../../../../../api/models/users';

const emptyData: VideoMetricsStoreModel = {
  videoViews: 0,
  referrals: 0,
  conversionRate: '-',
  convertedReferrals: 0,
};

interface InteractionsBlockProps {
  withHeader: boolean;
  metricsData?: VideoMetricsStoreModel | null;
}

export const InteractionsBlock: FC<InteractionsBlockProps> = ({ metricsData, withHeader }) => {
  const classes = useStyles();
  const renderInteractionUnits = (data: VideoMetricsStoreModel) => {
    const interactionUnits = getInteractionUnits();

    return interactionUnits.map((unit) => (
      <Grid item xs={4} className={classes.interactionUnit} key={unit.name}>
        <Box width={16}>
          <Box className={classes.dot} bgcolor={unit.color} />
        </Box>
        <Box className={classes.interactionUnitInfo}>
          <Typography variant="subtitle2">{data[unit.value]}</Typography>
          <Typography className={classes.helperText}>{unit.name}</Typography>
        </Box>
      </Grid>
    ));
  };

  return (
    <Box className={classes.root}>
      {withHeader && (
        <>
          <Typography variant="body1">{DetailsModalStrings.Interactions}</Typography>
          <Box mb={2} />
        </>
      )}
      <Grid container className={classes.metricsContainer}>
        {renderInteractionUnits(metricsData || emptyData)}
      </Grid>
    </Box>
  );
};
