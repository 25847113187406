export enum CreatorsTableEventNames {
  PageLoad = 'creators_page',
  CreatorTableSearchbarClick = 'creators_table_searchbar_click',
  CreatorTableSearchbarTyped = 'creators_table_searchbar_typed',
  CampaignsTableSearchbarTyped = 'campaigns_table_searchbar_typed',
  VideosTableSearchbarTyped = 'videos_table_searchbar_typed',
  VenuesTableSearchbarTyped = 'venues_table_searchbar_typed',
  TopgGolfVideosTableSearchbarTyped = 'topgolf_table_searchbar_typed',
  SmsMessagesTableSearchbarTyped = 'sms_messages_table_searchbar_typed',
  InviteesTableSearchbarTyped = 'campaign_invitees_table_searchbar_typed',
  SavedForLaterTableSearchbarTyped = 'campaign_saved_for_later_table_searchbar_typed',
  QueuedInvitationsTableSearchbarTyped = 'queued_invitation_table_searchbar_typed',
  CreatorTableSearchbarSubmitClick = 'creators_table_searchbar_submit_click',
  CreatorTableSearchbarResetClick = 'creators_table_searchbar_reset_click',
  RowClick = 'creators_table_row_click',
  CreatorDetailsModal = 'creators_table_details_modal',
  CloseCreatorDetailsModalClick = 'creators_table_close_details_modal_click',
  CreatorDetailsModalChangeStatusClick = 'creators_table_details_modal_change_status_click',
  CreatorDetailsModalChangeStatusActive = 'creators_table_details_modal_change_status_actived',
  CreatorDetailsModalChangeStatus = 'creators_table_details_modal_change_status',
  CreatorDetailsModalChangeStatusDeactive = 'creators_table_details_modal_change_status_deactived',
  CreatorDetailsModalStoryClick = 'creators_table_details_modal_story_click',
  CreatorDetailsModalStoryCloseClick = 'creators_table_details_modal_story_close_click',
  CreatorDetailsModalStoryPauseClick = 'creators_table_details_modal_story_pause_click',
  CreatorDetailsModalStoryPlayClick = 'creators_table_details_modal_story_play_click',
  CreatorDetailsModalStoryUploadClick = 'creators_table_details_modal_upload_click',
  CreatorDetailsModalFileSelected = 'creators_table_details_modal_file_selected',
  CreatorDetailsModalFileDrop = 'creators_table_details_modal_file_drop',
  CreatorDetailsUploadFileClick = 'creators_table_details_upload_file',
  CreatorDetailsModalFileUploading = 'creators_table_details_modal_file_uploading',
  CreatorDetailsModalStoryCreated = 'creators_table_details_modal_story_created',
  CreatorDetailsModalFileUploadingError = 'creators_table_details_modal_uploading_error',
  CreatorDetailsModalFileUploadingCancelClick = 'creators_table_details_modal_uploading_cancel_click',
  CreatorDetailsModalFileUploadingTryAgainClick = 'creators_table_details_modal_uploading_try_again_click',
  SortTableClick = 'creators_table_sort_by_name_click',
  CreatorDetailsModalEditCampaignClick = 'creators_table_details_modal_edit_campaign_click',
  CreatorDetailsModalSaveCampaignClick = 'creators_table_details_modal_save_campaign_click',
  CreatorDetailsModalSelectCampaign = 'creators_table_details_modal_select_campaign',
  CreatorDetailsModalVideosTabClick = 'creators_table_details_modal_videos_tab_click',
  CreatorDetailsModalRewardTabClick = 'creators_table_details_modal_reward_tab_click',
  CreatorDetailsModalRewardTabRewarded = 'creators_table_details_modal_reward_tab_rewarded',
  CreatorDetailsModalRewardTabRewardFulfillModalCloseClick = 'creators_table_details_modal_reward_tab_reward_fulfill_modal_close_click',
  CreatorDetailsModalRewardTabFulfillRewardClick = 'creators_table_details_modal_reward_tab_fulfill_reward_click',
  CreatorDetailsModalConfirmFulfillRewardClick = 'creators_table_details_modal_reward_tab_confirm_reward_click',
  CreatorDetailsModalAddBonusPointsClick = 'creators_table_details_modal_reward_tab_add_bonus_points_click',
  CreatorDetailsModalAddBonusPointsConfirmClick = 'creators_table_details_modal_reward_tab_add_bonus_points_confirm_click',
  CreatorDetailsModalBonusPointsAdded = 'creators_table_details_modal_reward_tab_bonus_points_added',
  CreatorDetailsModalConfirmAddBonusPointsModalClose = 'creators_table_details_modal_reward_tab_add_bonus_points_modal_close',
  CreatorRewardNowClick = 'creators_table_reward_now_click',
  CreatorRewardNowCloseClick = 'creators_table_reward_now_close_click',
  CreatorRewardNowConfirmClick = 'creators_table_reward_now_confirm_click',
  CreateFilterClick = 'create_filter_click',
  CreateFilterModalResetAllClick = 'create_filter_modal_reset_all_click',
  CreateFilterModalApplyClick = 'create_filter_modal_apply_click',
  CreateFilterModalClose = 'create_filter_modal_close',
  SavedFiltersOpen = 'saved_filters_open',
  SavedFiltersClosePreview = 'saved_filters_close_preview',
}
