import { Box, FormControl, InputBase, Typography } from '@mui/material';
import { Color, ColorPicker, createColor } from 'material-ui-color';
import React, { useEffect } from 'react';
import { ColorFormatType } from '../CustomColorPickerWithInput';
import { useColorInputStyles } from '../CustomColorPicker.style';

export const enum ColorInputPlaceholder {
  hex = '#000000',
  rgb = 'rgb(255,255,255)',
}

interface ColorInputProps {
  color: string;
  colorFormat: ColorFormatType;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFormatChange: (colorFormat: ColorFormatType) => void;
  onColorPick: (color: Color) => void;
  errorText?: string;
  setFieldError?: (field: string, value: string | undefined) => void;
  setColorError: (value: boolean) => void;
}

export const ColorInput: React.FC<ColorInputProps> = ({
  color,
  colorFormat,
  onFormatChange,
  onInputChange,
  onColorPick,
  errorText,
  setFieldError,
  setColorError,
}) => {
  const classes = useColorInputStyles();
  const colorObject = createColor(color);

  useEffect(() => {
    //@ts-ignore
    setColorError(!!colorObject.error);
    //@ts-ignore
    if (setFieldError) setFieldError('brandColor', colorObject.error);
    //@ts-ignore
  }, [colorObject.error, setColorError, setFieldError]);

  return (
    <Box className={classes.colorInputWrapper}>
      {/* use only hex colors SV-367 */}
      {/* <Select
        className={classes.select}
        classes={{ select: classes.inputText }}
        value={colorFormat}
        onChange={(e) => onFormatChange(e.target.value as ColorFormatType)}
      >
        <MenuItem value="hex">Hex</MenuItem>
        <MenuItem value="rgb">RGB</MenuItem>
      </Select>
      <Divider sx={{ width: '2px', height: '24px', backgroundColor: '#DCDBE4' }} /> */}
      <Box className={classes.colorSelectInput}>
        <ColorPicker value={colorObject} onChange={onColorPick} hideTextfield disableAlpha />
      </Box>
      <FormControl sx={{ mt: '6px' }}>
        <InputBase
          className={classes.inputText}
          value={color}
          onChange={onInputChange}
          placeholder={
            colorFormat === 'hex' ? ColorInputPlaceholder.hex : ColorInputPlaceholder.rgb
          }
          inputProps={{ maxLength: 7 }}
        />
        {/* @ts-ignore */}
        {colorObject.error && (
          <Typography sx={{ fontSize: '12px', color: 'rgb(255, 99, 64)', mt: '2px' }}>
            {/* @ts-ignore */}
            {colorObject.error}
          </Typography>
        )}
      </FormControl>
    </Box>
  );
};
