import { FC } from 'react';

export const UserIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none" viewBox="0 0 40 40">
      <rect width="40" height="40" fill="#EAF4FF" rx="8"></rect>
      <path
        fill="#2D5AF0"
        fillRule="evenodd"
        d="M26.912 24.656c-.7-1.84-2.103-3.243-3.856-4.031.964-.79 1.578-1.928 1.578-3.243 0-2.279-1.753-4.12-4.032-4.382h-1.139c-2.279.263-4.032 2.103-4.032 4.382 0 1.315.614 2.454 1.578 3.243-1.753.788-3.243 2.19-3.856 4.031-.263.614-.175 1.14.175 1.665.35.438.877.701 1.49.701H25.16c.613 0 1.139-.262 1.49-.7.35-.527.438-1.052.262-1.666zm-7.186-9.903h.526c1.402.175 2.454 1.227 2.454 2.629 0 1.49-1.227 2.63-2.717 2.63s-2.717-1.14-2.717-2.63c0-1.402 1.052-2.454 2.454-2.63zM14.818 25.27c.701-2.016 2.804-3.506 5.17-3.506 2.367 0 4.47 1.49 5.172 3.506H14.818z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
