import { FC } from 'react';

type InfoIconProps = {
  color?: string;
};

export const InfoIcon: FC<InfoIconProps> = ({ color }) => (
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="12.5" fill="black" stroke={color || '#E5E5E5'} />
    <path
      d="M13 1C6.37306 1 1 6.37306 1 13C1 19.6294 6.37306 25 13 25C19.6269 25 25 19.6269 25 13C25 6.37306 19.6269 1 13 1ZM14.1216 5.33224C15.2922 5.33224 15.6351 6.01061 15.6351 6.78694C15.6351 7.75673 14.8612 8.65306 13.5363 8.65306C12.4294 8.65306 11.9004 8.09714 11.9347 7.17633C11.9322 6.39755 12.5812 5.33224 14.1216 5.33224ZM11.1241 20.1853C10.3233 20.1853 9.74041 19.698 10.2988 17.5673L11.2147 13.7861C11.3739 13.1812 11.4008 12.9388 11.2147 12.9388C10.9771 12.9388 9.93633 13.3551 9.32408 13.769L8.9249 13.1151C10.8694 11.4914 13.1029 10.5388 14.0604 10.5388C14.8612 10.5388 14.9935 11.4841 14.5943 12.9388L13.5437 16.9135C13.3576 17.6163 13.4384 17.8588 13.6245 17.8588C13.8645 17.8588 14.6506 17.5698 15.422 16.96L15.8751 17.5673C13.982 19.458 11.9224 20.1853 11.1241 20.1853Z"
      fill="white"
    />
  </svg>
);
