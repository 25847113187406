import { FC, memo, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import { Spinner } from '../../../assets/Spinner';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import { VideoApiModel } from '../../../../api/models/videos';
import { ContentModalInfo } from './ContentModalInfo';
// import { ContentModalHeader } from './ContentModalHeader';
import { HeroesDetails } from '../../HeroesDetails/HeroesDetails';
import { WebsiteRoutes } from '../../../constants/routes';
import { TeamDetails } from '../../TeamDetails/TeamDetails';
import { SalesHeader } from './salesComponents/SalesHeader';
import { TeamHeader } from './components/TeamHeader';
import { VideoDownloadDialog } from './VideoDownloadDialog/VideoDownloadDialog';
import { VideoMetricsStoreModel } from '../../../../api/models/users';
import { getVideoMetrics } from '../../../../api/independentApiCalls';
import { VideosDetailHeaderToolbar } from '../../VideosDetailHeaderToolbar/VideosDetailHeaderToolbar';
import { TopGolfVideosApiModel } from '../../../../api/models/topgolfVideos';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { DeleteVideoModal } from '../../DeleteVideoModal/DeleteVideoModal';
import { deleteVideoById, getVideos } from '../../../../store/slices/videosSlice';
import { useToasts } from 'react-toast-notifications';
import { DeleteModalVideoStrings } from '../../../localization/en';
import {
  extractFramesFromCloudinary,
  getVideoWithoutText,
  googleStorageVideoUrlToCloudinaryUrl,
} from '../../EditThumbnail/EditThumbnail.helper';
import { ThumbnailFrame } from '../../EditThumbnail/EditThumbnail';
import { SocialVerseEventNames } from '../../../constants/events/socialVerseEvents';
import { useTrackEvent } from '../../../../hooks/useTrackEvent';
import { GetVideosBySocialVerseId } from '../../../../store/slices/socialVerseSlice';
import { getCampaigns } from '../../../../store/slices/campaignsSlice';
import { defaultPagination } from '../../../constants/constants';

interface DetailsModalContentProps {
  activeCampaign: CampaignsApiModel | null;
  isTopgolf: boolean;
  userId: string;
  story?: VideoApiModel | TopGolfVideosApiModel;
  handleClose: () => void;
  handleOpenDeleteModal?: (id: string, deleted: boolean) => void;
  handleOpenConfirmationModal?: (id: string) => void;
  handleAddVideoToCard?: (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    addUsingRowBtn: boolean,
  ) => void;
  onSocialVerseAddClick?: () => void;
  activeTabIndex?: number;
  onUpdateThumbnail?: () => void;
}

export const DetailsModalContent: FC<DetailsModalContentProps> = memo(
  ({
    activeCampaign,
    handleClose,
    story,
    handleOpenDeleteModal,
    handleOpenConfirmationModal,
    isTopgolf,
    handleAddVideoToCard,
    activeTabIndex,
    onUpdateThumbnail,
  }) => {
    const { isLoadingUserDetails, userDetails } = useTypedSelector((state) => state.userDetails);
    const params = useParams<{ id: string }>();

    const { size, page, sort, search } = useTypedSelector((state) => state.videos);
    const { trackEvent } = useTrackEvent();

    const { addToast } = useToasts();
    const { subdomain } = useTypedSelector((state) => state.venue.venue);
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [videoDownloadDialogOpen, setVideoDownloadDialogOpen] = useState(false);

    const { id: venueId } = useTypedSelector((state) => state.venue.venue);
    const [stats, setStats] = useState<VideoMetricsStoreModel | null>(null);
    const [userDateCreated, setuserDateCreated] = useState<string | undefined>(undefined);
    const [thumbnailFrames, setThumbnailFrames] = useState<ThumbnailFrame[]>([]);
    const [areFramesLoading, setAreFramesLoading] = useState<boolean>(false);

    useEffect(() => {
      if (isTopgolf && story) {
        const stats = {
          videoViews: (story as TopGolfVideosApiModel).views,
          referrals: (story as TopGolfVideosApiModel).ctaClicks,
          convertedReferrals: 0,
        };
        setStats(stats);
      } else {
        getVideoMetrics({ id: story?.id as string })
          .then((metrics) => {
            setStats(metrics);
          })
          .catch(() => setStats(null));
        userDetails && userDetails.createdAt
          ? setuserDateCreated(format(new Date(userDetails?.createdAt as string), 'PP'))
          : setuserDateCreated(undefined);
      }
    }, [story, venueId, isTopgolf, userDetails]);

    const getCloudinaryFrames = async (
      url: string,
      options?: { interval?: number; altThumbnail?: boolean },
    ) => {
      setAreFramesLoading(true);
      const thumbnailFrames = await extractFramesFromCloudinary(url, {
        interval: options?.interval,
        removeFilter: true,
      });
      const altThumbnailFrames = options?.altThumbnail
        ? await extractFramesFromCloudinary(url, {
            removeFilter: true,
            interval: options?.interval,
          })
        : thumbnailFrames;
      setThumbnailFrames(
        thumbnailFrames.map(({ thumbnail, time }, index) => ({
          thumbnail,
          time,
          altThumbnail: altThumbnailFrames[index].thumbnail,
        })),
      );
      setAreFramesLoading(false);
    };

    useEffect(() => {
      const storyUrl = story?.url;

      if (storyUrl) {
        const cloudinaryUrl = storyUrl.includes('cloudinary')
          ? storyUrl
          : googleStorageVideoUrlToCloudinaryUrl(storyUrl);
        getCloudinaryFrames(getVideoWithoutText(cloudinaryUrl));
      }
    }, [story?.url]);

    const { convertedReferrals } = stats ? stats : { convertedReferrals: 0 };

    const handleDownloadClose = () => {
      setVideoDownloadDialogOpen(false);
    };

    const handleOpenDownloadVideoDialog = () => {
      setVideoDownloadDialogOpen(true);
    };

    const [openDeleteModal, setopenDeleteModal] = useState(false);
    const handleDeleteModalClose = () => {
      setopenDeleteModal(false);
      trackEvent(SocialVerseEventNames.deleteUserVideoCancel, { videoId: story?.id });
    };
    const handleDeleteModalConfirm = () => {
      trackEvent(SocialVerseEventNames.deleteUserVideoConfirm, { videoId: story?.id });
      setopenDeleteModal(false);
      handleClose();
      story &&
        dispatch(deleteVideoById({ id: story?.id }))
          .then(() => {
            dispatch(
              getVideos({
                accountId: venueId,
                pageable: { page: page, size: size, sort: sort },
                search: search,
              }),
            );

            if (pathname.startsWith(WebsiteRoutes.SocialVerse)) {
              dispatch(GetVideosBySocialVerseId({ id: params.id }));
            }

            if (pathname.startsWith(WebsiteRoutes.RewardCampaigns)) {
              dispatch(
                getCampaigns({
                  accountId: venueId,
                  pageable: {
                    page: defaultPagination.page,
                    size: defaultPagination.size,
                    sort: defaultPagination.sort,
                  },
                }),
              );
            }
            addToast(DeleteModalVideoStrings.Message, {
              appearance: 'success',
              autoDismissTimeout: 2500,
            });
          })
          .catch(() => {
            addToast(DeleteModalVideoStrings.Error, {
              appearance: 'error',
              autoDismissTimeout: 2500,
            });
          });
    };
    const handleDeleteModalOpen = () => {
      setopenDeleteModal(true);
    };

    const handleOutsideSlideOutClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // const tableRow = target.closest('.MuiTableRow-root') as HTMLTableElement;
      const slideout = target.closest('#slideout-root') as HTMLDivElement;
      const modal = target.closest('.MuiModal-root') as HTMLDivElement;
      if (slideout?.dataset.preventSlideoutClose || modal) return;

      handleClose();
    };

    useEffect(() => {
      window.addEventListener('mousedown', handleOutsideSlideOutClick);

      return () => window.removeEventListener('mousedown', handleOutsideSlideOutClick);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingUserDetails) {
      return (
        <Box bgcolor="#fff" borderRadius="8px 0 0 8px">
          <Spinner color="var(--lightBlue)" />
        </Box>
      );
    }

    if (story) {
      return (
        <Box
          bgcolor="#fff"
          borderRadius="8px 0 0 8px"
          overflow="auto"
          data-prevent-slideout-close="true"
          id="slideout-root"
        >
          <div style={{ position: 'initial' }}>
            {/* <ContentModalHeader storyLink={story.storyPageUrl} onClose={handleClose} /> */}
            <VideosDetailHeaderToolbar onClose={handleClose} isTopGolf={isTopgolf} video={story} />
            {(userDetails || isTopgolf) && (
              <ContentModalInfo
                userDateCreated={userDateCreated ? userDateCreated : ''}
                activeCampaign={activeCampaign}
                isTopGolf={isTopgolf}
                subdomain={subdomain}
                video={story}
                thumbnailFrames={thumbnailFrames}
                regenerateThumbnails={(interval: number) => {
                  getCloudinaryFrames(getVideoWithoutText(story.url), {
                    interval,
                    altThumbnail: false,
                  });
                }}
                areFramesLoading={areFramesLoading}
                userData={userDetails ? userDetails : undefined}
                handleOpenDeleteModal={handleOpenDeleteModal}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
                handleOpenDownloadVideoDialog={handleOpenDownloadVideoDialog}
                convertedRefferals={convertedReferrals}
                handleDeleteModalOpen={handleDeleteModalOpen}
                handleAddVideoToCard={handleAddVideoToCard}
                activeTabIndex={activeTabIndex}
                onUpdateThumbnail={onUpdateThumbnail}
              />
            )}

            <VideoDownloadDialog
              open={videoDownloadDialogOpen}
              video={story}
              onClose={handleDownloadClose}
            />
            <DeleteVideoModal
              handleDeleteModalClose={handleDeleteModalClose}
              openDeleteModal={openDeleteModal}
              handleDeleteModalConfirm={handleDeleteModalConfirm}
            />
          </div>
        </Box>
      );
    }

    if (pathname.includes('/content')) {
      return <div></div>;
    }

    if (pathname.includes(WebsiteRoutes.TeamSettings)) {
      return (
        <Box
          bgcolor="#fff"
          borderRadius="8px 0 0 8px"
          height="calc(100vh - 80px)"
          overflow="scroll"
        >
          <TeamHeader onClose={handleClose} />
          {userDetails && <TeamDetails userDetails={userDetails} />}
        </Box>
      );
    }

    return (
      <Box bgcolor="#fff" borderRadius="8px 0 0 8px" height="calc(100vh - 80px)" overflow="scroll">
        <SalesHeader onClose={handleClose} />
        {userDetails && <HeroesDetails />}
      </Box>
    );
  },
);
