import { FC, ReactNode } from 'react';
import { IconButton, Modal } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEditCampaignModalStyles } from './EditCampaignModal.style';

interface IEditCampaignModal {
  isCreationModal: boolean;
  toggleCreationModal: () => void;
  children: ReactNode;
}

export const EditCampaignModal: FC<IEditCampaignModal> = ({
  isCreationModal,
  toggleCreationModal,
  children,
}) => {
  const styles = useEditCampaignModalStyles();
  return (
    <Modal open={isCreationModal} onClose={toggleCreationModal}>
      <div className={styles.Container}>
        <IconButton onClick={toggleCreationModal} className={styles.CloseButton}>
          <CloseRoundedIcon />
        </IconButton>
        <div>{children}</div>
      </div>
    </Modal>
  );
};
