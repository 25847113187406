export interface IUsersGeoFeaturesProperties {
  city: string;
  region: string;
  country: string;
  value: number;
}

interface IUsersGeoFeaturesGeometry {
  type: string;
  coordinates: Array<number>;
}

export interface IUsersGeoFeatures {
  type: string;
  properties: IUsersGeoFeaturesProperties;
  geometry: IUsersGeoFeaturesGeometry;
}

export interface UsersGeoApiModel {
  type: string;
  features: Array<IUsersGeoFeatures>;
}

export interface GeneralKPIsRequest {
  accountId: string;
  from?: string;
  to?: string;
}
export interface GlobalAnalyticsRequest {
  groupId?: string | null;
  deactivated?: boolean;
}
export interface KPIByIdRequest {
  accountId: string;
  from?: string;
  to?: string;
  kpi: KPIApiNames;
}

export interface KPIValueApiModel {
  date: string;
  value: number;
}

export interface globalAnalyticsValues {
  date: string;
  value: number;
}

export enum KPIApiNames {
  WidgetUsers = 'widgetUsers',
  WidgetCloseClicks = 'widgetCloseClicks',
  WidgetCtaClicks = 'widgetCtaClicks',
  WidgetConversionRate = 'widgetConversionRate',
  WidgetVideoViews = 'widgetVideoViews',
  WidgetAvgTimeSpent = 'widgetAvgTimeSpent',
  WidgetUniqueViewers = 'widgetUniqueViewers',
  WidgetPurchases = 'widgetPurchases',
  WidgetBuyNowClicks = 'widgetBuyNowClicks',
  WidgetViews = 'widgetViews',
  Heroes = 'users',
  Videos = 'videos',
  VideosShared = 'videoShares',
  VideosDownloaded = 'videoDownloads',
  VideoLinkCopied = 'videoLinkCopies',
  EstimatedVideoViews = 'estimatedVideoViews',
  VideoLinkViews = 'videoLinkViews',
  VideoViews = 'videoViews',
  Audience = 'audience',
  Referrals = 'referrals',
  Purchases = 'purchases',
  CTAClicks = 'ctaClicks',
  HeroesValue = 'heroesValue',
  users = 'users',
  visitors = 'visitors',
  videoViews = 'videoViews',
  ctaClicks = 'ctaClicks',
  shares = 'shares',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  SMS = 'SMS',
  TWITTER = 'TWITTER',
  DOWNLOAD = 'DOWNLOAD',
  EMAIL = 'EMAIL',
  LINK = 'LINK',
  LINKEDIN = 'LINKEDIN',
  TIKTOK = 'TIKTOK',
  REDDIT = 'REDDIT',
  REELS = 'REELS',
  PINTEREST = 'PINTEREST',
  incentiveCtaClicks = 'incentiveCtaClicks',
  ActiveCampaigns = 'numActiveCampaigns',
  ConvertedReferrals = 'convertedReferrals',
  Invites = 'numInvitesSent',
  Socialverses = 'socialverses',
  PublishedVideos = 'numPublishedVideos',
  GbpVideos = 'numGbpVideos',
}

export enum KpiAnalyticsApiNames {
  CamapignInvitation = 'campaignInvitations',
  SocialVerses = 'socialVerses',
  LiveSocialVerseVideos = 'liveSocialverseVideos',
  GbpVideos = 'gbpVideos',
}

export type KPIApiModel = Record<KPIApiNames, number>;

export type GlobalAnalyticsModel = Record<GlobalAnalyticsNames, number>;

export enum GlobalAnalyticsNames {
  accounts = 'accounts',
  users = 'users',
  videos = 'videos',
  videoViews = 'videoViews',
  ctaClicks = 'ctaClicks',
  averageUsers = 'averageUsers',
  averageVideos = 'averageVideos',
  averageVideoViews = 'averageVideoViews',
  averageEngagements = 'averageEngagements',
  averageConversionRate = 'averageConversionRate',
  socialVerses = 'socialVerses',
  gbpAccounts = 'gbpAccounts',
  liveCampaigns = 'liveCampaigns',
  billableAccounts = 'billableAccounts',
}

export enum MarketType {
  Accounts = 'accounts',
  Users = 'users',
  Videos = 'videos',
  VideoViews = 'videoViews',
  CtaClicks = 'ctaClicks',
  AverageUsers = 'averageUsers',
  AverageVideos = 'averageVideos',
  AverageVideoViews = 'averageVideoViews',
  AverageEngagements = 'averageEngagements',
  AverageConversionRate = 'averageConversionRate',
}

export type MarketTypeModel = Record<MarketType, number>;
