import { PointsFrame } from './../../../common/components/pointsFrame/pointsFrame';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import * as Yup from 'yup';
import { Box } from '@mui/material';
import { SharesIcon } from '../../../common/assets/SharesIcon';
import { DollarCircleIcon } from '../../../common/assets/DollarCircleIcon';
import {
  CommonStrings,
  HowToEarnPointsStrings,
  IncentivesStrings,
} from '../../../common/localization/en';
import { CampaignActivitiesTypes } from '../../../api/models/campaigns';
import { REWARD_CAMPAIGN_MAX_LENGTH } from '../../../common/constants/defaults';

export interface IncevtiveCreationValues {
  imageUrl: string;
  name: string;
  action: string;
  title: string;
  targetPoints?: string;
  points?: number;
  activities?: Array<{
    points: number;
    activityType: CampaignActivitiesTypes;
    status?: boolean;
  }> | null;
  couponEnabled?: boolean;
}

export const activitiesLiterals = {
  CREATE_VIDEO: IncentivesStrings.CreateVideos,
  CONVERSION: HowToEarnPointsStrings.Conversions,
  SHARE: HowToEarnPointsStrings.Shares,
  CALL_TO_ACTION: HowToEarnPointsStrings.CallToAction,
  BONUS_POINTS: HowToEarnPointsStrings.BonusPoints,
};

export const activitiesArr = {
  CREATE_VIDEO: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <PlayCircleOutlineIcon
          style={{ marginRight: '10px', transform: 'translate(0, 4px)', color: '#8B89A0' }}
        />
      }
      points={points}
      name={name}
    />
  ),
  SHARE: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <Box sx={{ transform: 'translate(0, 6px)' }}>
          <SharesIcon color={'#8B89A0'} />
        </Box>
      }
      points={points}
      name={name}
    />
  ),
  CALL_TO_ACTION: (points: number, name?: string) => (
    <PointsFrame
      icon={<img style={{ width: '24px', marginRight: '10px' }} src="/cursor.svg" alt="" />}
      points={points}
      name={name}
    />
  ),
  CONVERSION: (points: number, name?: string) => (
    <PointsFrame
      icon={
        <Box sx={{ transform: 'translate(0, 2px)', opacity: '0.7' }}>
          <DollarCircleIcon color={'#8B89A0'} />
        </Box>
      }
      points={points}
      name={name}
    />
  ),
  BONUS_POINTS: (points: number, name?: string) => (
    <PointsFrame
      icon={<img style={{ width: '24px', marginRight: '10px' }} src="/star.svg" alt="" />}
      points={points}
      name={name}
    />
  ),
};

export const validationSchemaCreation = () =>
  Yup.object().shape({
    // imageUrl: Yup.string().required('Required'),
    name: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.name, CommonStrings.ExceededCharLimit),
    action: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.action, CommonStrings.ExceededCharLimit),
    title: Yup.string()
      .required('Required')
      .min(3)
      .max(REWARD_CAMPAIGN_MAX_LENGTH.title, CommonStrings.ExceededCharLimit),
  });

export enum Tabs {
  RewardCampaign = 'Reward Campaign',
  NoReward = 'No Incentives',
}

export enum Radios {
  RewardCard = 'Reward Card',
  ThankYouCard = 'Thank You Card',
  UploadCard = 'Upload Card',
}
