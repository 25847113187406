import * as Yup from 'yup';
import { SMSInviteUpdateConfigApiPayload } from '../../../api/models/smsInvite';
import {
  replaceMessageBodyWithApiVariables,
  replaceMessageBodyWithLocalVariables,
} from '../../../common/components/SMS/SMS.helper';
import { SmsInviteStrings } from '../../../common/localization/en';
import { SMSInviteFormValues } from './types';
import { makeStyles } from '@mui/styles';

export const MESSAGE_MAX_CHARACTERS = 280;

export const validationSchemaSmsInviteInvite = () =>
  Yup.object().shape({
    /* preMessage: Yup.object().shape({
      body: Yup.string().when('enabled', {
        is: true,
        then: Yup.string()
          .required('Past Purchase Message is required')
          .max(MESSAGE_MAX_CHARACTERS, `Max length ${MESSAGE_MAX_CHARACTERS} characters`),
      }),
      daysShift: Yup.number().required('Past Purchase Timeframe is required'),
      enabled: Yup.boolean(),
    }), */
    postMessage: Yup.object().shape({
      body: Yup.string().when('enabled', {
        is: true,
        then: Yup.string()
          .required('Future Purchase Message is required')
          .max(MESSAGE_MAX_CHARACTERS, `Max length ${MESSAGE_MAX_CHARACTERS} characters`),
      }),
      daysShift: Yup.number().required('Future Purchase Timeframe is required'),
      enabled: Yup.boolean(),
    }),
  });

export const getDefaultPreMessage = (isTrublu?: boolean) => {
  if (isTrublu) {
    return SmsInviteStrings.DefaultPreMessage_TruBlu;
  }
  return SmsInviteStrings.DefaultPreMessage;
};

export const convertFormValuesToApiPayload = (
  formValues: SMSInviteFormValues,
  companyName: string,
  squareBrackets: boolean | undefined = false,
): SMSInviteUpdateConfigApiPayload => {
  return {
    /*  preMessage: {
      body: replaceMessageBodyWithApiVariables(formValues.preMessage.body, squareBrackets),
      daysShift: formValues.preMessage.daysShift,
      enabled: formValues.preMessage.enabled,
      mediaUrl: formValues.mediaUrl || '',
    }, */
    postMessage: {
      body: replaceMessageBodyWithApiVariables(formValues.postMessage.body, squareBrackets),
      daysShift: formValues.postMessage.daysShift,
      enabled: formValues.postMessage.enabled,
      /*   mediaUrl: formValues.mediaUrl || '', */
    },
  };
};

export const getDefaultPostMessage = (isHealthCare: boolean, squareBrackets = true) => {
  return isHealthCare
    ? replaceMessageBodyWithLocalVariables(
        SmsInviteStrings.DefaultPostMessage_TruBlu,
        isHealthCare,
        squareBrackets,
      )
    : replaceMessageBodyWithLocalVariables(
        SmsInviteStrings.DefaultPostMessage,
        isHealthCare,
        squareBrackets,
      );
};

export const convertApiModelToFormValues = (
  data: SMSInviteUpdateConfigApiPayload,
  mediaUrl: string | undefined,
  isHealthCare: boolean,
  squareBrackets: boolean | undefined = false,
): SMSInviteFormValues => {
  const defaultPostMessage = getDefaultPostMessage(isHealthCare, squareBrackets);

  /*  const defaultPreMessage = isTrubluClient
    ? replaceMessageBodyWithLocalVariables(
        SmsInviteStrings.DefaultPreMessage_TruBlu,
        isTrubluClient,
        squareBrackets,
      )
    : replaceMessageBodyWithLocalVariables(
        SmsInviteStrings.DefaultPreMessage,
        isTrubluClient,
        squareBrackets,
      ); */
  return {
    /*  preMessage: {
      body:
        data?.postMessage?.body && data.postMessage.body.trim()
          ? replaceMessageBodyWithLocalVariables(
              data?.preMessage?.body,
              isTrubluClient,
              squareBrackets,
            )
          : defaultPreMessage,
      daysShift: data?.preMessage?.daysShift,
      enabled: data?.preMessage?.enabled,
    }, */
    postMessage: {
      body:
        data?.postMessage?.body && data.postMessage.body.trim()
          ? replaceMessageBodyWithLocalVariables(
              data.postMessage.body,
              isHealthCare,
              squareBrackets,
            )
          : defaultPostMessage,
      daysShift: data?.postMessage?.daysShift || 0,
      enabled: data?.postMessage?.enabled || false,
    },
    mediaUrl,
  };
};

export const useClasses = makeStyles((theme) => ({
  leftSideDescription: {
    fontSize: '14px',
    lineHeight: '28px',
    fontWeight: 400,
    color: '#8B89A0',
  },
  SMSSample: {
    marginBottom: '25px',
    padding: '10px 36px',
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.common.white,
    borderRadius: '40px',
    '& p': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '24px',
      color: theme.palette.grey,
    },
  },
}));
