import React, { useState } from 'react';
import { useAppDispatch, useTypedSelector } from '../../../../store';
import {
  ClipsCountFilterAttribute,
  CreatorAttributeName,
  SearchOperationType,
} from '../../../../api/models/searchFilterModels';
import {
  removeCreatorClipsCountFilter,
  setActiveCreatorFilterAttributes,
} from '../../../../store/slices/searchFilterSlice';
import { Box, Typography } from '@mui/material';
import { SearchTagsStrings } from '../../../localization/en';
import SmallArrowIcon from '../../../assets/newDesign/SmallArrowIcon';
import PurpleCloseIcon from '../../../assets/newDesign/PurpleCloseIcon';
import MenuContainer from '../../MenuContainer/MenuContainer';
import { useStyles } from '../CreatorsSearchTags.styles';
import { CountPicker } from '../../ClipsCountSelect/CountPicker/CountPicker';
import { CountRange } from '../../ClipsCountSelect/ClipsCountSelect';

const ClipCountTag = () => {
  const styles = useStyles();
  const dispatch = useAppDispatch();

  const { activeCreatorFilterAttributes } = useTypedSelector((state) => state.searchFilter);

  const clipsCountFilterFromIdx = activeCreatorFilterAttributes.findIndex(
    (item) =>
      item.name === CreatorAttributeName.ClipsCount &&
      item.operation === SearchOperationType.GreaterThan,
  );
  const clipsCountFilterToIdx = activeCreatorFilterAttributes.findIndex(
    (item) =>
      item.name === CreatorAttributeName.ClipsCount &&
      item.operation === SearchOperationType.LessThan,
  );

  const [countRange, setCountRange] = useState<CountRange[]>(
    activeCreatorFilterAttributes
      .filter((attribute) => attribute.name === CreatorAttributeName.ClipsCount)
      .map((attribute) => ({
        value: attribute.value,
        operation: attribute.operation,
      })) as CountRange[],
  );

  const [predefinedTagText, setPredefinedTagText] = useState('');
  const [clipsCountAnchorEl, setClipsCountAnchorEl] = useState<null | HTMLElement>(null);
  const isClipsCountMenuOpen = Boolean(clipsCountAnchorEl);

  const handleClipsCountMenuOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setClipsCountAnchorEl(event.currentTarget);
  };

  const handleClipsCountMenuClose = (predefinedSelected?: boolean) => {
    setClipsCountAnchorEl(null);

    if (predefinedSelected) return;

    setPredefinedTagText('');
    applyFilterChange();
  };

  const handlePredefinedFilterChange = (value: number) => {
    const range = { value, operation: SearchOperationType.LessThan } as CountRange;
    setCountRange([range]);
    handleClipsCountMenuClose(true);

    if (value === 5) {
      setPredefinedTagText(SearchTagsStrings.LesstThan5);
    } else {
      setPredefinedTagText(SearchTagsStrings.LessThan10);
    }

    const attributes = activeCreatorFilterAttributes.filter((attribute) => {
      return !(
        attribute.name === CreatorAttributeName.ClipsCount &&
        ((attribute.operation === SearchOperationType.LessThan && clipsCountFilterToIdx > -1) ||
          (attribute.operation === SearchOperationType.GreaterThan && clipsCountFilterFromIdx > -1))
      );
    });

    dispatch(
      setActiveCreatorFilterAttributes([
        ...attributes,
        { ...range, name: CreatorAttributeName.ClipsCount },
      ]),
    );
  };

  const applyFilterChange = () => {
    if (countRange.length > 0) {
      const countAttributes: ClipsCountFilterAttribute[] = countRange.map((item) => ({
        ...item,
        name: CreatorAttributeName.ClipsCount,
      }));

      const attributes = activeCreatorFilterAttributes.filter((attribute) => {
        return !(
          attribute.name === CreatorAttributeName.ClipsCount &&
          ((attribute.operation === SearchOperationType.LessThan && clipsCountFilterToIdx > -1) ||
            (attribute.operation === SearchOperationType.GreaterThan &&
              clipsCountFilterFromIdx > -1))
        );
      });

      dispatch(setActiveCreatorFilterAttributes([...attributes, ...countAttributes]));
    }
  };

  const handleRemoveLengthFilter = () => {
    handleRangeChange([]);
    dispatch(removeCreatorClipsCountFilter());
  };

  const handleRangeChange = (range: CountRange[]) => {
    setCountRange(range);
  };

  const countFrom =
    clipsCountFilterFromIdx !== -1
      ? (activeCreatorFilterAttributes[clipsCountFilterFromIdx].value as number) + 1
      : 0;
  const countTo =
    clipsCountFilterToIdx !== -1
      ? (activeCreatorFilterAttributes[clipsCountFilterToIdx].value as number) - 1
      : -1;

  return (
    <>
      <Box
        className={`${styles.tag} ${clipsCountFilterToIdx !== -1 && styles.active}`}
        onClick={handleClipsCountMenuOpen}
      >
        {SearchTagsStrings.ClipCount}:{' '}
        {clipsCountFilterToIdx === -1
          ? ''
          : predefinedTagText
          ? predefinedTagText
          : `${countFrom} to ${countTo}`}
        <Box
          display="flex"
          marginLeft="8px"
          onClick={(e) => {
            if (clipsCountFilterToIdx !== -1) {
              e.stopPropagation();
              handleRemoveLengthFilter();
            }
          }}
        >
          {clipsCountFilterToIdx === -1 ? <SmallArrowIcon /> : <PurpleCloseIcon />}
        </Box>
      </Box>
      <MenuContainer
        anchorElement={clipsCountAnchorEl}
        isOpen={isClipsCountMenuOpen}
        onClose={() => handleClipsCountMenuClose()}
        padding="4px 0 0 0"
      >
        <Box className={styles.clipsOption} onClick={() => handlePredefinedFilterChange(5)}>
          {SearchTagsStrings.LesstThan5}
        </Box>
        <Box className={styles.clipsOption} onClick={() => handlePredefinedFilterChange(10)}>
          {SearchTagsStrings.LessThan10}
        </Box>
        <Typography className={styles.customRangeTitle}>{SearchTagsStrings.CustomRange}</Typography>
        <Box className={styles.clipsWrapper}>
          <CountPicker
            onChange={handleRangeChange}
            countRange={countRange}
            initialVal={countFrom}
            operation={SearchOperationType.GreaterThan}
          />
          <Typography fontSize="12px" color="#000">
            {SearchTagsStrings.To}
          </Typography>
          <CountPicker
            onChange={handleRangeChange}
            countRange={countRange}
            initialVal={countTo}
            operation={SearchOperationType.LessThan}
          />
        </Box>
      </MenuContainer>
    </>
  );
};

export default ClipCountTag;
