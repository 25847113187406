import { Box, FormHelperText, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  ErrorsStrings,
  HowToRewardCreatorsStrings,
  WebAppPageStrings,
} from '../../../../../../../../../common/localization/en';
import { useStyles } from './RewardImageSection.styles';
import { TextFieldComponent } from '../../../../../../../../../common/components/TextField/TextField';
import {
  ContactData,
  ContactMethod,
  HEADER_MAX_CHAR_LIMIT,
  colorPalette,
  getErrorMessageForContactMethod,
  getRegExpForContactMethod,
} from './RewardImageSection.helpers';
import { useFormik } from 'formik';
import { handleChangeWithPattern } from '../../../../../../../../../services/utilities';
import { useAppDispatch, useTypedSelector } from '../../../../../../../../../store';
import {
  setContactMethod,
  setIsError,
  setRewardCardColor,
  setRewardContact,
  setRewardHeader,
} from '../../../../../../../../../store/slices/createCampaignSlice';
import * as Yup from 'yup';
import { useTrackEvent } from '../../../../../../../../../hooks/useTrackEvent';
import { NewCampaignEvents } from '../../../../../../../../../common/constants/events/newCampaignEvents';
import ArrowRightSmall from '../../../../../../../../../common/assets/ArrowRightSmall';
import { useDetectClickOutside } from 'react-detect-click-outside';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import { CustomColorPickerWithInput } from '../../../../../../../../../common/components/CustomColorPicker/CustomColorPickerWithInput';
import { flushSync } from 'react-dom';

export const RewardImageSection: React.FC = () => {
  const styles = useStyles();

  const [isContactMethodOpen, setIsContactMethodOpen] = useState(false);

  const {
    howToRewardCreators: {
      cardColor,
      contact: campaignContact,
      rewardHeader,
      contactMethod: campaignContactMethod,
    },
    isEditMode,
    rewardCardUrl,
    cardEnabled,
  } = useTypedSelector((state) => state.createCampaign);

  const contact = campaignContact || '';
  const contactMethod = campaignContactMethod || ContactMethod.None;

  const {
    venueAttributes: { properties: venueProperties },
  } = useTypedSelector((state) => state.venue);

  const imagePreviouslyUploaded = isEditMode && cardEnabled === false && rewardCardUrl.length > 0;

  const dispatch = useAppDispatch();

  const venueBrandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const defaultCardColor =
    colorPalette.flat().find((color) => color === venueBrandColor) || '#D1D1D1';

  const { trackEvent } = useTrackEvent();

  const {
    values,
    handleChange,
    setFieldValue,
    errors,
    handleBlur,
    validateForm,
    isValid,
  } = useFormik({
    initialValues: {
      header: rewardHeader,
      contact: contact,
    },
    validationSchema: Yup.object().shape({
      header: Yup.string().required(ErrorsStrings.FieldIsRequired),
      contact:
        contactMethod === ContactMethod.Email
          ? Yup.string()
              .email(ErrorsStrings.EmailAddressIsNotValid)
              .required(ErrorsStrings.FieldIsRequired)
          : contactMethod === ContactMethod.None
          ? Yup.string().nullable().notRequired()
          : Yup.string()
              .matches(
                getRegExpForContactMethod(contactMethod as ContactMethod),
                getErrorMessageForContactMethod(contactMethod as ContactMethod),
              )
              .required(ErrorsStrings.FieldIsRequired),
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validateOnMount: false,
    onSubmit: () => {},
  });

  useEffect(() => {
    const getIsError = () => {
      if (!isValid) return true;

      if (contactMethod === ContactMethod.None) return false;
      return !values.contact;
    };

    dispatch(setIsError(getIsError()));
  }, [isValid, contactMethod, dispatch, values.contact]);

  const visitedAccordions = useTypedSelector((store) => store.createCampaign.visitedAccordions);
  const isVisitedTwice = visitedAccordions.filter((v) => v === 'acknowledgement-sms').length > 1;
  const showContactMethodError = !contactMethod && isVisitedTwice && !isContactMethodOpen;

  const contactMethodsMap: Record<ContactMethod, ContactData> = {
    PHONE: {
      name: HowToRewardCreatorsStrings.PhoneNumber,
      element: (
        <TextFieldComponent
          name="contact"
          value={values.contact}
          placeholder="(555) 555-5555"
          onChange={(event) => {
            handlePhoneNumberChange(event as React.ChangeEvent<HTMLInputElement>);
            trackEvent(NewCampaignEvents.NewRewardCampaignRewardCardPhoneNumberChange, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          fullWidth
          className={styles.input}
          displayLabel={false}
          errorText={imagePreviouslyUploaded ? '' : errors.contact}
          disabled={imagePreviouslyUploaded}
          id="reward-phone-number-input"
        />
      ),
    },
    EMAIL: {
      name: HowToRewardCreatorsStrings.EmailAddress,
      element: (
        <TextFieldComponent
          name="contact"
          value={values.contact}
          placeholder="Enter an email"
          onChange={(event) => {
            handleChange(event);
            dispatch(setRewardContact(event.target.value));
            trackEvent(NewCampaignEvents.NewRewardCampaignRewardCardEmailAddressChange, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          fullWidth
          className={styles.input}
          displayLabel={false}
          errorText={imagePreviouslyUploaded ? '' : errors.contact}
          disabled={imagePreviouslyUploaded}
          id="reward-email-address-input"
        />
      ),
    },
    WEBSITE: {
      name: HowToRewardCreatorsStrings.WebsiteUrl,
      element: (
        <TextFieldComponent
          name="contact"
          value={values.contact}
          placeholder="yourwebsite.com"
          onChange={(event) => {
            handleChange(event);
            dispatch(setRewardContact(event.target.value));
            trackEvent(NewCampaignEvents.NewRewardCampaignRewardCardWebsiteChange, {
              value: event.target.value,
            });
          }}
          onBlur={handleBlur}
          fullWidth
          className={styles.input}
          displayLabel={false}
          errorText={imagePreviouslyUploaded ? '' : errors.contact}
          disabled={imagePreviouslyUploaded}
          id="reward-website-url-input"
        />
      ),
    },
    NONE: {
      name: HowToRewardCreatorsStrings.None,
      element: <></>,
    },
  };

  const handleContactMethodOpen = () => {
    if (imagePreviouslyUploaded) return;

    setIsContactMethodOpen(!isContactMethodOpen);
  };

  const changeContactMethod = (method: string) => {
    flushSync(() => {
      dispatch(setContactMethod(method));
      dispatch(setRewardContact(''));
    });
    setFieldValue('contact', '');
    handleContactMethodOpen();
    validateForm();
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChangeWithPattern({
      event,
      prevVal: values.contact,
      pattern: '(###) ###-####',
      setVal: (val: string) => {
        setFieldValue('contact', val);
        dispatch(setRewardContact(val));
      },
    });
  };

  const refDetectClickOutside = useDetectClickOutside({
    onTriggered: () => {
      setIsContactMethodOpen(false);
    },
  });

  return (
    <Box className={styles.container}>
      {/* <Typography className={styles.title}>{HowToRewardCreatorsStrings.Title}</Typography> */}
      {imagePreviouslyUploaded && (
        <Typography className={styles.uploadedText}>
          {HowToRewardCreatorsStrings.PreviouslyUploadedInputs}
        </Typography>
      )}

      <Typography className={styles.title}>Image Color</Typography>
      {imagePreviouslyUploaded ? ( //FIXME: need indicator from BE to find out if uploaded image was custom or default(coupon
        <Typography className={styles.uploadedText}>
          {HowToRewardCreatorsStrings.PreviouslyUploaded}
        </Typography>
      ) : (
        <CustomColorPickerWithInput
          palette={colorPalette}
          color={cardColor || defaultCardColor}
          onColorChange={(color) => {
            dispatch(setRewardCardColor(color));
            trackEvent(NewCampaignEvents.NewRewardCampaignRewardCardColorChange, { value: color });
          }}
          setColorError={(value) => {}}
          title={WebAppPageStrings.ColorPickerSubTitle}
          displayCustomInput
        />
      )}
      <Box className={styles.inputsWrapper}>
        <Box className={styles.inputWrapper}>
          <Typography className={styles.inputLabel}>Image Title</Typography>
          <TextFieldComponent
            name="header"
            value={rewardHeader}
            onChange={(event) => {
              handleChange(event);
              dispatch(setRewardHeader(event.target.value));
              trackEvent(NewCampaignEvents.NewRewardCampaignRewardCardHeaderChange, {
                value: event.target.value,
              });
            }}
            fullWidth
            characterLimit={HEADER_MAX_CHAR_LIMIT}
            className={styles.input}
            displayLabel={false}
            errorText={imagePreviouslyUploaded ? '' : errors.header}
            disabled={imagePreviouslyUploaded}
            id="reward-header-input"
          />
        </Box>
        <Box className={styles.contact}>
          <Typography className={styles.inputLabel} sx={{ margin: '15px 0 2px 0 !important' }}>
            {HowToRewardCreatorsStrings.ContactMethod}
          </Typography>
          <Typography className={styles.contactInfo}>
            {HowToRewardCreatorsStrings.ContactMethodInfo}
          </Typography>
        </Box>
        <Box className={styles.selectWrapper} ref={refDetectClickOutside}>
          <Box
            className={`${styles.select} ${imagePreviouslyUploaded && styles.selectDisabled}`}
            sx={{ border: showContactMethodError ? '1px solid red !important' : undefined }}
            onClick={handleContactMethodOpen}
          >
            {contactMethod
              ? contactMethodsMap[contactMethod as keyof typeof contactMethodsMap].name
              : HowToRewardCreatorsStrings.Select}
            <div
              style={{
                height: '12px',
                transform: isContactMethodOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            >
              <ArrowRightSmall stroke={imagePreviouslyUploaded ? '#9a9b9c' : '#667085'} />
            </div>
          </Box>
          {isContactMethodOpen && (
            <Box className={styles.optionsWrapper}>
              {Object.keys(contactMethodsMap).map((method) => (
                <Box
                  key={method}
                  className={styles.option}
                  onClick={(event) => {
                    event.stopPropagation();
                    changeContactMethod(method);
                    trackEvent(NewCampaignEvents.NewRewardCampaignContactMethodChange, {
                      value: method,
                    });
                  }}
                >
                  {contactMethodsMap[method as keyof typeof contactMethodsMap].name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box className={`${styles.inputWrapper} ${styles.contactInput}`}>
          {contactMethodsMap[contactMethod as keyof typeof contactMethodsMap]?.element}
        </Box>
      </Box>
      {showContactMethodError && (
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& svg': {
              marginRight: '8px',
            },
          }}
          id="input-meta-title-helper-text"
          error
        >
          <ReportProblemRoundedIcon color="error" style={{ height: '16px', width: '16px' }} />
          This field is required
        </FormHelperText>
      )}
    </Box>
  );
};
