import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VenueManagerApiModel } from '../../api/models/venueManagers';
import { defaultPagination } from '../../common/constants/constants';
import { TableDTO } from '../storeModels';

const initialState: TableDTO<VenueManagerApiModel & { itemId?: string }> = {
  error: false,
  isLoading: false,
  items: [],
  page: defaultPagination.page,
  size: defaultPagination.size,
  totalItems: defaultPagination.totalItems,
  totalPages: defaultPagination.totalPages,
  sort: defaultPagination.sortByLastCreated,
  lastUpdated: new Date().toISOString(),
  itemId: undefined,
  property_2: {
    email: '',
    name: '',
  },
};

const venueManagerSlice = createSlice({
  name: 'venuesM',
  initialState,
  reducers: {
    updateNewManager(state, action: PayloadAction<{ name: string; email: string }>) {
      state.property_2 = { name: action.payload.name, email: action.payload.email };
    },
  },
});

export const { updateNewManager } = venueManagerSlice.actions;
export default venueManagerSlice.reducer;
