import { baseTheme } from './themes/base.theme';
import { uwmTheme } from './themes/uwm.theme';
import { isUWMClient } from './utils/isUWMClient';

const getTheme = () => {
  if (isUWMClient()) return uwmTheme;

  return baseTheme;
};

export const theme = getTheme();
