import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  },
  title: {
    fontWeight: 500,
    color: '#344054',
    fontSize: '15px',
    marginBottom: '8px',
    letterSpacing: '0.3px',
  },
  contact: {
    gridArea: 'contact',
  },
  contactInfo: {
    fontSize: '13px',
    color: '#8B89A0',
    width: 320,
    marginBottom: '12px',
  },
  inputsWrapper: {
    display: 'grid',
    gridTemplate: '1fr auto auto / 1fr 1fr',
    gridTemplateAreas: `'header name'
    'contact contact'
    'select input'`,
    columnGap: '15px',
    marginTop: '16px',
  },
  inputWrapper: {
    position: 'relative',
    width: '100%',
    color: '#344054',

    '& .charsLimit': {
      fontSize: '10px !important',
      color: '#C4C4C4 !important',
      top: '-30px !important',
    },
  },
  inputLabel: {
    marginBottom: '12px',
    fontSize: '15px',
    fontWeight: 500,
    letterSpacing: '0.3px',
  },
  input: {
    '& > div': {
      height: '28px',
      borderRadius: '6px',
      border: '1px solid #D0D5DD',
      color: '#667085',
      backgroundColor: '#F9FAFB',
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',

      '& > input': {
        fontSize: '10px',
      },
    },
  },
  phoneNumberInfo: {
    fontSize: '13px',
    color: '#8B89A0',
    width: '240px',
    marginTop: '5px',
  },
  errorWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',

    '& svg': {
      marginRight: '8px',
    },
  },
  errorText: {
    fontSize: '12px',
    color: theme.palette.error.main,
  },
  uploadedText: {
    fontSize: '12px',
    color: '#8B89A0',
    marginTop: '5px',
    marginBottom: '16px',
    letterSpacing: '0.02em',
  },
  selectWrapper: {
    position: 'relative',
    gridArea: 'select',
  },
  select: {
    width: '100%',
    height: '28px',
    backgroundColor: '#F9FAFB',
    border: '3px solid #D0D5DD',
    borderRadius: '6px',
    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
    padding: '0 11px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#667085',
    fontSize: '10px',
    userSelect: 'none',
  },
  selectDisabled: {
    color: '#9a9b9c',
  },
  optionsWrapper: {
    position: 'absolute',
    top: '30px',
    width: '232px',
    backgroundColor: 'white',
    border: '1px solid #D0D5DD',
    borderRadius: '8px',
    padding: '0 6px',
    zIndex: '99999',
  },
  option: {
    width: '100%',
    height: '44px',
    fontSize: '12px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    padding: '0 8px',
    color: '#101828',
    transition: '0.11s',

    '&:hover': {
      backgroundColor: '#F9FAFB',
    },
  },
  contactInput: {
    gridArea: 'input',
  },
}));
