import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const VideoSquareIcon: FC<IconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke={color || '#8B89A0'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.09998 12.0005V10.5205C9.09998 8.61048 10.45 7.84048 12.1 8.79048L13.38 9.53048L14.66 10.2705C16.31 11.2205 16.31 12.7805 14.66 13.7305L13.38 14.4705L12.1 15.2105C10.45 16.1605 9.09998 15.3805 9.09998 13.4805V12.0005Z"
      stroke={color || '#8B89A0'}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
