import { FC } from 'react';

type MobileIconProps = {
  color?: string;
};

export const MobileIcon: FC<MobileIconProps> = ({ color }) => (
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 6V16C17 20 16 21 12 21H6C2 21 1 20 1 16V6C1 2 2 1 6 1H12C16 1 17 2 17 6Z"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11 4.5H7" stroke={color || '#292D32'} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M9.0002 18.1C9.85624 18.1 10.5502 17.406 10.5502 16.55C10.5502 15.694 9.85624 15 9.0002 15C8.14415 15 7.4502 15.694 7.4502 16.55C7.4502 17.406 8.14415 18.1 9.0002 18.1Z"
      stroke={color || '#292D32'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
