import { BoxProps } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
import React from 'react';

interface ChipProps extends BoxProps {
  title: string;
}

export const Chip: React.FC<ChipProps> = ({ title, ...boxProps }) => {
  return (
    <Box bgcolor="#F5F6F8" p="10px" borderRadius="50px" width="fit-content" {...boxProps}>
      <Typography color="#1A1538" fontSize="16px" fontWeight="500">
        {title}
      </Typography>
    </Box>
  );
};
