import { FC } from 'react';

export const CopyIcon: FC = () => {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.787354"
        y="1.33333"
        width="10"
        height="11.6667"
        rx="1.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.2874 4.66667V13C13.2874 14.3807 12.1681 15.5 10.7874 15.5H4.12073"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
