import * as React from 'react';

export const GetFeaturedIcon: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
      <path
        fill="#FFB515"
        d="M12.5 20.092L20.225 25l-2.05-9.25L25 9.526l-8.988-.802L12.5 0 8.988 8.724 0 9.526l6.825 6.224L4.775 25l7.725-4.908z"
      ></path>
      <path
        fill="#fff"
        d="M11.995 16.44l-2.94-3.28a.406.406 0 01.016-.554.359.359 0 01.525.016l2.308 2.577 2.919-4.455a.36.36 0 01.516-.1c.17.121.212.366.096.543l-3.44 5.254z"
      ></path>
    </svg>
  );
};
