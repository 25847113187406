import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UIState } from '../storeModels';

const initialState: UIState = {
  sidebarOpen: false,
  isCummulativeGraph: true,
  emoji: {
    loading: false,
    initialized: false,
  },
  wasInvitingModalDisplayed: false,
  scrollY: 0,
  elementsActiveTabIndex: 0,
  isMenuExpanded: true,
  isTabletView: false,
  isWindowLoaded: false,
  isAccountsSidebarOpen: false,
  isDistributorsMenuExpanded: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    triggerSidebar(state) {
      state.sidebarOpen = !state.sidebarOpen;
    },
    setIsCummulativeGraph(state, action: PayloadAction<boolean>) {
      state.isCummulativeGraph = action.payload;
    },
    setEmojiState(state, action: PayloadAction<UIState['emoji']>) {
      state.emoji = action.payload;
    },
    updateInvitingModalStatus(state) {
      state.wasInvitingModalDisplayed = true;
    },
    setScrollY(state, action: PayloadAction<number | undefined>) {
      state.scrollY = action.payload;
    },
    setElementsActiveTabIndex(state, action: PayloadAction<number>) {
      state.elementsActiveTabIndex = action.payload;
    },
    setIsMenuExpanded(state, action: PayloadAction<boolean>) {
      state.isMenuExpanded = action.payload;
    },
    setIsTabletView(state, action: PayloadAction<boolean>) {
      state.isTabletView = action.payload;
    },
    setIsWindowLoaded(state, action: PayloadAction<boolean>) {
      state.isWindowLoaded = action.payload;
    },
    setIsAccountsSidebarOpen(state, action: PayloadAction<boolean>) {
      state.isAccountsSidebarOpen = action.payload;
    },
    toggleDistributorsMenuExpanded(state) {
      state.isDistributorsMenuExpanded = !state.isDistributorsMenuExpanded;
    },
    reset: () => ({ ...initialState, isWindowLoaded: true }),
  },
});

export const {
  triggerSidebar,
  reset,
  setIsCummulativeGraph,
  setEmojiState,
  updateInvitingModalStatus,
  setScrollY,
  setElementsActiveTabIndex,
  setIsMenuExpanded,
  setIsTabletView,
  setIsWindowLoaded,
  setIsAccountsSidebarOpen,
  toggleDistributorsMenuExpanded,
} = uiSlice.actions;
export default uiSlice.reducer;
