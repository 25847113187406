import { FC } from 'react';

export const WatchIcon: FC = () => {
  return (
    <svg width="54" height="42" viewBox="0 0 54 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="53" height="41" rx="11.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 21C17 21 20 14 27 14C34 14 37 21 37 21C37 21 34 28 27 28C20 28 17 21 17 21Z"
        stroke="#333333"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M27 24C28.6569 24 30 22.6569 30 21C30 19.3431 28.6569 18 27 18C25.3431 18 24 19.3431 24 21C24 22.6569 25.3431 24 27 24Z"
        stroke="#333333"
        strokeWidth="2"
      />
      <path d="M27 14V12" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
      <path d="M33 15.5L34 14" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
      <path d="M36 19L37.5 18" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
      <path d="M16.5 18L18 19" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
      <path d="M21 15.5L20 14" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
      <rect x="0.5" y="0.5" width="53" height="41" rx="11.5" stroke="#8B89A0" />
    </svg>
  );
};
