import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '10px',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  videosWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
  },
  divider: {
    width: '100%',
    borderTop: '1px solid #EBEBEB',
    marginTop: '17px',
  },
  btnWrapper: {
    position: 'absolute',
    bottom: '0px',
    width: '90%',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
  },
  uploadButton: {
    border: '1px solid #D0D5DD',
    boxSizing: 'content-box',
    height: '35px',

    color: '#344054',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #D0D5DD',
    },
  },
}));
