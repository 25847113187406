import { FC, useMemo } from 'react';
import { PricingPlanModel } from '../../../../api/models/pricingPlans';
import { getShortPlanPeriod, getThousandsSeparatedVal } from '../../../../services/utilities';
import { Box, Typography, useTheme } from '@mui/material';
import { BillingStrings } from '../../../../common/localization/en';
import { useStyles } from './PricingPlanPreview.style';
import { SocialVenuLogo } from '../../../../common/assets/newDesign/Sidebar/SocialVenuLogo';

interface PricingPlanPreviewProps {
  plan: PricingPlanModel;
  referralPlan: PricingPlanModel | null;
  isTrubluPlan: boolean;
  isEnterPrisePlan: boolean;
  isQuickSignup?: boolean;
}

export const MobilePlanPreview: FC<PricingPlanPreviewProps> = ({
  plan,
  referralPlan,
  isTrubluPlan,
  isEnterPrisePlan,
  isQuickSignup = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const REFERRAL_PRICE_COLOR = '#3F9700';

  const setupFeeOffset = useMemo(() => {
    return getShortPlanPeriod(plan) === BillingStrings.PerYear ? '44px' : '38px';
  }, [plan]);

  return (
    <div style={{ padding: '60px 30px', background: '#1E0035', marginBottom: '10px' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '40px',
        }}
      >
        <SocialVenuLogo />
      </div>
      <Box
        bgcolor={theme.palette.common.pageBackground}
        borderRadius="15px"
        py="14px"
        px="20px"
        marginTop="20px"
      >
        <Typography
          color={theme.palette.common.deepDark}
          fontSize="30px"
          fontWeight="500"
          mb="26px"
          textAlign={'center'}
        >
          {BillingStrings.PricingPlan}
        </Typography>

        {isQuickSignup && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="flex-start"
            marginTop="-15px"
            marginBottom="15px"
          >
            <div
              style={{
                borderRadius: '16px',
                background: '#EFEEFC',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                paddingLeft: '8px',
                paddingRight: '8px',
                height: '30px',
              }}
            >
              <Typography
                color={theme.palette.primary.main}
                fontSize="20px"
                lineHeight="20px"
                fontWeight="600"
                mb="26px"
                textAlign="center"
              >
                {plan.setupFee === '$0' ? '50%' : '30%'} Percent Discount Applied
              </Typography>
            </div>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
          pr={setupFeeOffset}
        >
          <Box>
            <Typography
              color={theme.palette.common.deepDark}
              fontSize="32px"
              fontWeight="500"
              width="100%"
            >
              {BillingStrings.SetupFee}
            </Typography>
            <Typography className={classes.MobileplanDescription}>{BillingStrings.OneTimeFee}</Typography>
          </Box>
          <Typography
            fontSize="32px"
            fontWeight="500"
            sx={{
              textDecorationLine: referralPlan ? 'line-through' : undefined,
              color: theme.palette.common.deepDark,
            }}
          >
            {isQuickSignup && plan.setupFee === '$0'
              ? 'Waived'
              : getThousandsSeparatedVal(plan.setupFee)}
          </Typography>
        </Box>
        {referralPlan && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pr={setupFeeOffset}
          >
            <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="20px" mr="5px">
              {BillingStrings.AfterPromoCode}
            </Typography>
            <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="32px">
              {getThousandsSeparatedVal(referralPlan.setupFee)}
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" marginTop="10px">
          <Box>
            <Typography
              color={theme.palette.common.deepDark}
              fontSize="32px"
              fontWeight="500"
              width="100%"
            >
              {BillingStrings.SubscriptionFee}
            </Typography>
            <Typography className={classes.MobileplanDescription}>
              {BillingStrings.OneYearCommitment}
            </Typography>
          </Box>
          <Box display="flex" alignItems="flex-end">
            <Typography
              color={theme.palette.common.deepDark}
              fontSize="32px"
              fontWeight="500"
              sx={{
                textDecorationLine: referralPlan ? 'line-through' : undefined,
              }}
            >
              {getThousandsSeparatedVal(plan.subscriptionFee) || '$0'}
            </Typography>
            <Typography
              component="span"
              fontSize="20px"
              color={theme.palette.common.gray}
              sx={{
                textDecorationLine: referralPlan ? 'line-through' : undefined,
              }}
            >
              {getShortPlanPeriod(plan)}
            </Typography>
          </Box>
        </Box>
        {referralPlan && (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="20px" mr="5px">
              {BillingStrings.AfterPromoCode}
            </Typography>
            <Box display="flex" alignItems="flex-end">
              <Typography color={REFERRAL_PRICE_COLOR} fontWeight="500" fontSize="32px">
                {getThousandsSeparatedVal(referralPlan.subscriptionFee) || '$0'}
              </Typography>
              <Typography component="span" fontSize="20px" color={theme.palette.common.gray}>
                {getShortPlanPeriod(referralPlan)}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </div>
  );
};
