import { FC } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { theme } from '../../../theme';
import { FSModal } from '../modal/Modal';
import { BadgeCustomBell } from './BadgeCustomBell';
import { AdminVenueManagementStrings } from '../../localization/en';
import { DisplayedPhone } from '../accountMainInfo/DisplayedPhone';

interface ConfirmationModalProps {
  closeModal: () => void;
  isOpenModal: boolean;
  phone?: string | null;
  enableNotifications: () => void;
  name: string;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  closeModal,
  isOpenModal,
  phone,
  enableNotifications,
  name,
}) => {
  return (
    <FSModal
      onClose={closeModal}
      modalIsOpen={isOpenModal}
      width="435px"
      backgroundColor={theme.palette.common.white}
      padding="16px"
      rounded="15px"
      closeBtn={false}
      blur
    >
      <Stack sx={{ alignItems: 'center' }}>
        <BadgeCustomBell />
        <>
          <Typography
            sx={{
              textAlign: 'center',
              fontWeight: 500,
              color: theme.palette.common.black,
              fontSize: '18px',
              lineHeight: '27px',
              letterSpacing: '0.02em',
              marginTop: '16px',
            }}
          >
            {AdminVenueManagementStrings.ConfirmationModal}
          </Typography>
          <Box sx={{ mt: '16px' }}>{name}</Box>
          <Box sx={{ mt: '16px', mb: '18px' }}>{phone && <DisplayedPhone phone={phone} />}</Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '85%' }}>
            <Button
              sx={{ borderRadius: '25px', p: '10px 30px' }}
              variant="outlined"
              onClick={closeModal}
            >
              {AdminVenueManagementStrings.No}
            </Button>
            <Button
              sx={{ borderRadius: '25px', p: '10px 30px' }}
              variant="contained"
              onClick={enableNotifications}
            >
              {AdminVenueManagementStrings.Yes}
            </Button>
          </Box>
        </>
        <Typography
          sx={{
            textAlign: 'center',
            fontWeight: 400,
            color: '#8B89A0',
            fontSize: '13px',
            lineHeight: '21px',
            letterSpacing: '0.02em',
            marginTop: '21px',
          }}
        >
          {AdminVenueManagementStrings.RegularSMS}
        </Typography>
      </Stack>
    </FSModal>
  );
};
