import { FC } from 'react';

interface Props {
  height?: number;
}

export const VideoSharedScreenIcon: FC<Props | undefined> = ({ height }) => (
  <svg
    width={45}
    height={height || 56}
    viewBox="0 0 45 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 55.9992V7C44 3.68629 41.3137 1 38 1H7C3.68629 1 1 3.68629 1 7V55.9992"
      stroke="currentColor"
    />
    <path
      d="M20.4453 4.77832H28.4453"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={17.3008}
      cy={4.77832}
      r={0.5}
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={0.5}
    />
    <path
      opacity={0.1}
      d="M37.33 8.334H7.665a3 3 0 0 0-3 3V56h35.667V11.334a3 3 0 0 0-3-3Z"
      fill="currentColor"
      stroke="currentColor"
    />
    <path d="M40.33 56V11.334a3 3 0 0 0-3-3H7.665a3 3 0 0 0-3 3V56" stroke="currentColor" />
    <rect
      opacity={0.25}
      x={10.074}
      y={43.218}
      width={25}
      height={6}
      rx={3}
      fill="currentColor"
      stroke="currentColor"
    />
    <rect
      opacity={0.25}
      x={10.074}
      y={16.218}
      width={25}
      height={23}
      rx={3.5}
      fill="currentColor"
      stroke="currentColor"
    />
    <path
      d="M26.844 28.012a.5.5 0 0 1 0 .866l-6.762 3.904a.5.5 0 0 1-.75-.433V24.54a.5.5 0 0 1 .75-.433l6.762 3.904Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);
