import { FC, useEffect, useState } from 'react';
import { FilterStatuses } from '../constants/constants';
import { Select, MenuItem, SelectChangeEvent, Button } from '@mui/material';
import { UserStatuses } from '../../api/models/users';
import { StatusValues, useUserStatusDropDownStyles } from './UserStatusDropDown.style';
import { ArrowBottomIcon } from '../assets/newDesign/ArrowBottomIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { TeamSettingsStrings } from '../localization/en';
import { sendOneUserInvite } from '../../store/slices/heroesSlice';
import { useToasts } from 'react-toast-notifications';

interface IUserStatusDropDownProps {
  id: string;
  name: string | null;
  status: string;
  lastInvitationSentAt: Date | null;
  handleStatusUpdate: (newStatus: UserStatuses) => void;
}

const newUserStatuses: { [key: string]: string } = {
  ACTIVE: 'Active',
  ARCHIVED: 'Deactivated',
  INACTIVE: 'Deactivated',
};

export const UserStatusDropDown: FC<IUserStatusDropDownProps> = ({
  id,
  name,
  status,
  lastInvitationSentAt,
  handleStatusUpdate,
}) => {
  const classes = useUserStatusDropDownStyles();
  const [displayedStatus, setdisplayedStatus] = useState<string>(newUserStatuses[status]);
  useEffect(() => {
    setdisplayedStatus(newUserStatuses[status]);
  }, [id, name, status]);

  const handleChange = (e: SelectChangeEvent) => {
    e.stopPropagation();
    if (e.target.value !== status) {
      const newStatus: UserStatuses =
        e.target.value === 'Deactivated' ? UserStatuses.inactive : UserStatuses.active;
      handleStatusUpdate(newStatus);
    }
  };

  const statusToDisplay = (status: string) =>
    status.toLocaleLowerCase().charAt(0).toUpperCase() + status.toLocaleLowerCase().slice(1);

  if (
    status !== FilterStatuses.active &&
    status !== FilterStatuses.archived &&
    status !== FilterStatuses.inactive
  )
    return null;

  return (
    <div className={classes.Container}>
      <Select
        IconComponent={ArrowBottomIcon}
        value={displayedStatus}
        onChange={handleChange}
        onMouseUp={(e) => {
          e.stopPropagation();
        }}
        className={[classes.Select, classes[displayedStatus as StatusValues]].join(' ')}
        style={{
          color: displayedStatus === 'Active' ? '#24B947' : '#8B89A0',
        }}
        MenuProps={{
          classes: { paper: classes.DropDownList },
          style: {
            zoom: 1.01,
            transform: 'translateY(-8px)',
          },
        }}
      >
        <MenuItem
          value={newUserStatuses.ACTIVE}
          style={{
            color: '#24B947',
          }}
          className={classes.MenuItem}
        >
          {statusToDisplay(newUserStatuses.ACTIVE)}
        </MenuItem>
        <MenuItem
          value={newUserStatuses.ARCHIVED}
          className={classes.MenuItem}
          style={{
            color: '#8B89A0',
          }}
        >
          {statusToDisplay(newUserStatuses.ARCHIVED)}
        </MenuItem>
      </Select>
    </div>
  );
};

interface ISendButtonProps {
  id: string;
  name: string | null;
  status: string;
  lastInvitationSentAt?: Date | null;
}

export const SendButton: FC<ISendButtonProps> = ({ id, name, status, lastInvitationSentAt }) => {
  const classes = useUserStatusDropDownStyles();
  const [inviteSent, setInviteSet] = useState(false);
  const { addToast } = useToasts();

  const sendInvite = async () => {
    const response = await sendOneUserInvite({ id: id });
    if (response.id) {
      const message = name
        ? TeamSettingsStrings.UserReceivedInvite.replace(':name', name)
        : TeamSettingsStrings.InviteSentNoUserName;
      setInviteSet(true);
      addToast(message, {
        appearance: 'success',
        autoDismissTimeout: 2500,
      });
    } else {
      addToast(TeamSettingsStrings.UserFailedInvite, { appearance: 'error' });
      setInviteSet(false);
    }
  };
  const shouldEnableSendInvite = () => {
    return !(status === UserStatuses.inactive || status === UserStatuses.archived);
  };

  return (
    <div className={classes.InviteContainer}>
      {inviteSent ? (
        <div className={classes.InviteSentWrapper}>
          <Button
            className={classes.InviteSent}
            startIcon={<CheckCircleOutlineIcon className={classes.CheckIcon} />}
            fullWidth
          >
            {TeamSettingsStrings.InviteSentButton}{' '}
          </Button>
        </div>
      ) : (
        <Button
          className={classes.InviteSent}
          variant="contained"
          color="primary"
          onClick={sendInvite}
          fullWidth
          disabled={shouldEnableSendInvite()}
        >
          {TeamSettingsStrings.SendInvite}
        </Button>
      )}
    </div>
  );
};
