import React, { FC } from 'react';
import { Box, List, Typography } from '@mui/material';
import { useStyles } from './WelcomeVideoTab.styles';
import { ElementsStrings } from '../../../../../../common/localization/en';
import { Tooltip } from '../../../../../../common/components/Tooltip/Tooltip';
import { RoundedButton } from '../../../../../../common/components/Button/RoundedButton';
import { WelcomeVideoStylesType } from '../../../../../NewCampaign/WelcomeVideoStyles/WelcomeVideoStyles';
import WelcomeVideoIPhonePreview from '../../../../../NewCampaign/WelcomeVideoStylesPreview/WelcomeVideoIPhonePreview';
import CreateVideoPreview from '../../../../../../common/components/previews/CreateVideoPreview/CreateVideoPreview';
import { useTypedSelector } from '../../../../../../store';
import { useVenue } from '../../../../../../hooks/useVenue';
import { useBackgroundImage } from '../../../../../../hooks/webApp/useBackgroundImage';
import WelcomeVideoModal from '../../../../../rewards/CampaignSummary/Modals/WelcomeVideoModal/WelcomeVideoModal';
import { useModal } from '../../../../../../hooks/useModal';
import { checkIsWelcomeVideoDefault } from '../../../../../rewards/CampaignSummary/Modals/WelcomeVideoModal/EditWelcomeVideo/EditWelcomeVideo';
import { useParams } from 'react-router-dom';
import { useTrackEvent } from '../../../../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../../../../common/constants/events/rewardCampaignsEvents';
import { useClientType } from '../../../../../../services/hooks/useClientType';
import { CampaignsApiModel } from '../../../../../../api/models/campaigns';

export const getWelcomeVideoType = (currentCampaign: CampaignsApiModel | null) => {
  if (!currentCampaign?.showWelcomeVideo || !currentCampaign?.welcomeVideoUrl) {
    return ElementsStrings.None;
  }
  if (checkIsWelcomeVideoDefault(currentCampaign?.welcomeVideoUrl)) {
    return ElementsStrings.Default;
  }
  return ElementsStrings.Custom;
};

export const WelcomeVideoTab: FC = () => {
  const classes = useStyles();
  const { id: campaignId } = useParams<{ id: string }>();
  const { trackEvent } = useTrackEvent();
  const { isHealthCare } = useClientType();

  const { currentCampaign } = useTypedSelector((state) => state.campaigns);
  const { venueProperties, logo } = useVenue();
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];
  const launchScreenOptions = venueProperties?.['webapp.launch-screen'];

  const { backgroundImage } = useBackgroundImage();

  const {
    isOpen: isWelcomeVideoModalOpen,
    open: openWelcomeVideoModalOpen,
    close: closeWelcomeVideoModal,
  } = useModal(false);

  const welcomeVideoType = getWelcomeVideoType(currentCampaign);

  return (
    <Box className={classes.container}>
      {currentCampaign && (
        <WelcomeVideoModal
          currentCampaign={currentCampaign}
          isOpen={isWelcomeVideoModalOpen}
          onClose={closeWelcomeVideoModal}
        />
      )}
      <Box className={classes.leftSideWrapper}>
        <Typography className={classes.title}>{ElementsStrings.WelcomeVideo}</Typography>
        <Typography className={classes.descrText}>
          {isHealthCare ? ElementsStrings.AskPatients : ElementsStrings.AskCustomers}
        </Typography>
        <Typography className={classes.settingText}>{ElementsStrings.CurrentSetting}</Typography>
        <Box className={classes.defaultWrapper}>
          <Typography className={classes.defaultText}>{welcomeVideoType}</Typography>
          <Tooltip
            maxWidth="270px"
            content={
              <Box className={classes.tooltipWrapper}>
                <Typography className={classes.tooltipTitle}>
                  {ElementsStrings.VideoOptions}
                </Typography>
                <List className={classes.tooltipList}>
                  {[
                    ElementsStrings.DefaultOption,
                    ElementsStrings.CustomOption,
                    ElementsStrings.NoneOption,
                  ].map((item) => (
                    <li key={item} className={classes.tooltipItem}>
                      {item}
                    </li>
                  ))}
                </List>
              </Box>
            }
          />
        </Box>
        {welcomeVideoType !== ElementsStrings.None && (
          <Typography className={classes.defaultVideoText}>
            {checkIsWelcomeVideoDefault(currentCampaign?.welcomeVideoUrl!)
              ? ElementsStrings.DefaultVideo
              : ElementsStrings.CreateOwnVideo}
          </Typography>
        )}
        {!currentCampaign?.endedAt && (
          <RoundedButton
            className={classes.customizeButton}
            title={ElementsStrings.Customize}
            onClick={() => {
              openWelcomeVideoModalOpen();
              trackEvent(RewardCampaignsEvents.OpenWelcomeVideoModal, {
                campaignId,
              });
            }}
          />
        )}
      </Box>
      <Box className={classes.rightSideWrapper}>
        <Typography className={classes.howAppearText}>
          {isHealthCare ? ElementsStrings.HowVideoAppearTrublu : ElementsStrings.HowVideoAppear}
        </Typography>
        <Box className={classes.iphonePreview}>
          {currentCampaign?.recommendedVideoStyle !== WelcomeVideoStylesType.none ? (
            <WelcomeVideoIPhonePreview videoUrl={currentCampaign?.welcomeVideoUrl || ''} />
          ) : (
            <CreateVideoPreview
              brandColor={brandColor}
              image={backgroundImage || ''}
              logo={logo}
              reveal={launchScreenOptions?.reveal || 'splash'}
              cover={launchScreenOptions?.cover || 'white'}
              launchScreenType={launchScreenOptions?.type || 'name'}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
