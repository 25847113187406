import { FC } from 'react';

export const MobileIcon: FC = () => (
  <svg width="54" height="34" viewBox="0 0 54 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_8050_145454)">
      <rect x="4" width="46" height="26" rx="13" fill="white" shapeRendering="crispEdges" />
      <path
        d="M35 8V18C35 22 34 23 30 23H24C20 23 19 22 19 18V8C19 4 20 3 24 3H30C34 3 35 4 35 8Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M29 6.5H25" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M27 20.1C27.856 20.1 28.55 19.406 28.55 18.55C28.55 17.694 27.856 17 27 17C26.1439 17 25.45 17.694 25.45 18.55C25.45 19.406 26.1439 20.1 27 20.1Z"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_8050_145454"
        x="0"
        y="0"
        width="54"
        height="34"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8050_145454" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_8050_145454"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
