import { FC, memo, useMemo } from 'react';
import {
  ActivityState,
  AnimatedCircle,
  PlainCircle,
  ProgressButton,
  ProgressSvg,
} from './VideoRecordButton.style';

interface ProgressComponentProps {
  size?: number;
  strokeWidth?: number;
  circleOneStroke?: string;
  circleTwoStroke?: string;
  handleStart: () => void;
  handleStop: () => void;
  isActive: boolean;
}

export const VideoRecordButton: FC<ProgressComponentProps> = memo(
  ({
    size = 72,
    strokeWidth = 6,
    circleOneStroke = 'transparent',
    circleTwoStroke = '#ff4f4f',
    isActive,
    handleStop,
    handleStart,
  }) => {
    const center = useMemo(() => size / 2, [size]);
    const radius = useMemo(() => size / 2 - strokeWidth / 2, [size, strokeWidth]);
    const circumference = useMemo(() => 2 * Math.PI * radius, [radius]);

    return (
      <ProgressButton onClick={isActive ? handleStop : handleStart}>
        <ProgressSvg height={size} width={size}>
          <PlainCircle
            stroke={circleOneStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
          />
          <AnimatedCircle
            durationInSeconds={30}
            className={[isActive ? 'active' : ''].join(' ')}
            stroke={circleTwoStroke}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={circumference}
            onAnimationEnd={handleStop}
            strokeLinecap="round"
          />
        </ProgressSvg>
        <ActivityState className={[isActive ? 'active' : ''].join(' ')} />
      </ProgressButton>
    );
  },
);
VideoRecordButton.displayName = 'VideoRecordButton';
