import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useDeleteVideoModalStyles = makeStyles((theme: Theme) => ({
  DeleteModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: theme.palette.common.white,
    borderWidth: 0,
    padding: 24,
  },
  ModalTitle: {
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalText: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.02em',
    color: theme.palette.common.black,
    marginBottom: 24,
  },
  ModalBtn: {
    padding: '10px 25px',
    fontSize: '14px',
    borderWidth: 1,
    borderRadius: '30px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  ConfirmBtn: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));
