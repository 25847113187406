import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, IconButton, MenuItem, Select, Typography } from '@mui/material';
import { useStyles } from './VideoUplaoder.styles';
import { DNDIcon } from '../../assets/newDesign/DNDIcon';
import { VideoUploaderStrings } from '../../localization/en';
import { useDropzone } from 'react-dropzone';
import { useAppDispatch, useTypedSelector } from '../../../store';
import {
  CreateStory,
  createUploadUrl,
  reset,
  setProgress,
  uploadVideoAsFile,
} from '../../../store/slices/uploadVideoSlice';
import { useOnUploadProgress } from '../../../services/hooks/useOnUploadProgress';
import {
  getDefaultStoryDetails,
  getFileSizeInMb,
  getUpdatedVideoUrl,
  getUserStoryDetails,
  getVideoPosterWithCloudinary,
} from './VideoUplader.helpers';
import {
  useAttributesFilterUrl,
  useLogoFilterEnabled,
} from '../../../services/hooks/useAttributesFilterUrl';
import { DEFAULT_VIDEO_DETAILS } from '../../../api/constants';
import { useClientType } from '../../../services/hooks/useClientType';
import { LinearProgressWithLabel } from '../LinearProgressBar/LinearProgress.helper';
import { CompletedIcon } from '../../assets/CompletedIcon';
import { CancelIcon } from '../../assets/CancelIcon';
import { FileIcon } from '../../assets/FileIcon';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { getCampaigns } from '../../../store/slices/campaignsSlice';
import {
  getVideoAspectRatio,
  getVideoDuration,
  isVideoARCloseToTargetAR,
} from '../../../services/utilities';
import { CampaignsApiModel } from '../../../api/models/campaigns';
import { DNDUploadIcon } from '../../assets/DNDUploadIcon';
import { ArrowBottomIcon } from '../../assets/newDesign/ArrowBottomIcon';
import { StarIcon } from '../../assets/StarIcon';
import { UploadCompleteIcon } from '../../assets/UploadCompleteIcon';

enum AddCreatorModalEventNames {
  fileDrop = 'add_creator_modal_file_selected',
  videoUpload = 'add_creator_modal_video_uploading',
  videoCreated = 'add_creator_modal_video_created',
  tryAgain = 'add_creator_modal_try_again_click',
  cancelClick = 'add_creator_modal_cancel_click',
  uploadError = 'add_creator_modal_upload_error',
}

interface Props {
  userId: string;
  onFileSelect?: (value: boolean) => void;
  isCampaignUser?: boolean;
  campaignId?: string;
  disabled: boolean;
  inviteModal?: boolean;
  userName?: string;
  currentCampaign?: CampaignsApiModel | null;
  closeModal?: () => void;
  onUploaded?: () => void;
}

const VideoUploader: FC<Props> = ({
  userId,
  onFileSelect,
  isCampaignUser,
  campaignId,
  disabled,
  inviteModal,
  userName,
  closeModal,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userFile, setUserFile] = useState<File | null>(null);
  const [isUploadError, setIsUploadError] = useState(false);
  const isCancelUpload = useRef(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState('');
  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const [isChangeCampaign, setIsChangeCampaign] = useState(false);
  const [url, setUrl] = useState('');
  const [urlWithoutFilter, setUrlWithoutFilter] = useState('');
  const [duration, setDuration] = useState(0);

  const { items: campaigns } = useTypedSelector((state) => state.campaigns);

  const primaryCampaign = campaigns.find((item) => item.isPreferred);

  const styles = useStyles({ inviteModal, isLoading });

  const timerRef = useRef<number | null>(null);

  const dispatch = useAppDispatch();

  const logoEnabled = useLogoFilterEnabled();
  const attributesFilterUrl = useAttributesFilterUrl();

  const { isAthleticSolutionsClient } = useClientType();

  const onUploadProgress = useOnUploadProgress({});

  const { name } = useTypedSelector((state) => state.me);
  const { userDetails } = useTypedSelector((state) => state.userDetails);
  const { venue, venueAttributes } = useTypedSelector((state) => state.venue);
  const { uploadingProgress } = useTypedSelector((state) => state.uploadVideo);

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (url) {
      dispatch(setProgress({ value: 100, videoUpload: true }));
      setIsLoading(false);
    }
  }, [url, dispatch]);

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ accountId: venueId, pageable: { page: 0, size: 100 } }));
    }
  }, [dispatch, venueId]);

  const storyDetails = useMemo(() => {
    const properties = getDefaultStoryDetails(
      userDetails?.defaultVideoDetails || null,
      venueAttributes.properties?.['webapp.default-story-details'],
      DEFAULT_VIDEO_DETAILS(),
      userDetails?.returnUrl || undefined,
    );
    const userName = userDetails?.displayName || name;

    return getUserStoryDetails(properties, userName, venue.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, venue, venueAttributes]);

  const handleAddVideoClick = () => {
    dispatch(
      CreateStory({
        categoryId: '',
        url: url,
        isPublic: true,
        duration,
        thumbnailUrl: getVideoPosterWithCloudinary(url, true),
        altThumbnailUrl: getVideoPosterWithCloudinary(urlWithoutFilter, true),
        details: {
          ...storyDetails,
          ctaBtnLinkUrl: isAthleticSolutionsClient
            ? userDetails?.returnUrl || null
            : storyDetails.ctaBtnLinkUrl || null,
        },
        campaignId: selectedCampaignId || primaryCampaign?.id || undefined,
        userId: userId,
      }),
    ).then((action: any) => {
      if (action.payload.id) {
        trackEvent(AddCreatorModalEventNames.videoCreated, {
          selectedUserId: userId,
          videoId: action.payload.id,
        });
        closeModal && closeModal();
      }
    });
  };

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setIsLoading(true);
      const file = acceptedFiles[0];

      if (!file) {
        setIsLoading(false);
        return;
      }
      const duration = await getVideoDuration(file);
      setDuration(duration);

      dispatch(reset());
      setUserFile(file);

      trackEvent(AddCreatorModalEventNames.fileDrop, { selectedUserId: userId });

      createUploadUrl(userId)
        .then((res) => {
          if (res) {
            trackEvent(AddCreatorModalEventNames.videoUpload, {
              selectedUserId: userId,
            });
            dispatch(
              uploadVideoAsFile({
                options: res,
                data: file,
                onUploadProgress,
              }),
            ).then(async () => {
              const videoAr = await getVideoAspectRatio(file);
              const shouldFill = !isVideoARCloseToTargetAR(videoAr);

              const url = await getUpdatedVideoUrl(
                res.downloadUrl,
                logoEnabled ? attributesFilterUrl : undefined,
                undefined,
                shouldFill,
              );
              setUrl(url);
              const urlWitoutFilter = await getUpdatedVideoUrl(
                res.downloadUrl,
                undefined,
                undefined,
                shouldFill,
              );
              setUrlWithoutFilter(urlWitoutFilter);
              if (isCancelUpload.current) {
                isCancelUpload.current = false;
                return;
              }

              if (onFileSelect) {
                onFileSelect(false);
              }
            });
          }
        })
        .catch(() => {
          setIsUploadError(true);
          trackEvent(AddCreatorModalEventNames.uploadError, {
            selectedUserId: userId,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      dispatch,
      userId,
      onUploadProgress,
      logoEnabled,
      attributesFilterUrl,
      isAthleticSolutionsClient,
      storyDetails,
      userDetails?.returnUrl,
      onFileSelect,
      isCancelUpload,
      isCampaignUser,
      campaignId,
    ],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg'],
      'video/x-matroska': ['.mkv'],
      'video/quicktime': ['.mov'],
    },
    multiple: false,
    noDragEventsBubbling: true,
    noClick: isLoading || uploadingProgress === 100,
    noDrag: isLoading || uploadingProgress === 100,
    disabled: disabled,
  });

  const tryAgainHandler = () => {
    setIsLoading(false);
    setIsUploadError(false);
    setUserFile(null);
    setUrl('');
    setUrlWithoutFilter('');
    setDuration(0);

    trackEvent(AddCreatorModalEventNames.tryAgain, {
      selectedUserId: userId,
    });
  };

  const handleChangeCampaignClick = () => {
    setIsChangeCampaign(true);
  };

  const cancelUploadingHandler = () => {
    isCancelUpload.current = true;
    setIsLoading(false);
    setIsUploadError(false);
    setUserFile(null);
    dispatch(setProgress({ value: 0, videoUpload: true }));
    trackEvent(AddCreatorModalEventNames.cancelClick, {
      selectedUserId: userId,
    });

    if (onFileSelect) {
      onFileSelect(false);
    }
  };

  useEffect(() => {
    const timer = timerRef.current;

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
      dispatch(setProgress({ value: 0, videoUpload: true }));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div onClick={() => onFileSelect && onFileSelect(true)}>
        <Box
          className={styles.container}
          {...getRootProps()}
          sx={{ cursor: disabled ? 'not-allowed !important' : '' }}
        >
          {!isLoading && uploadingProgress !== 100 && (
            <div className={styles.dropzone}>
              <input {...getInputProps()} />
              {!inviteModal && <DNDIcon color="#D9D9D9" />}
              {inviteModal && userName ? (
                <Box>
                  {inviteModal && !isLoading && !userFile && (
                    <>
                      <Typography className={styles.subtitle}>
                        <span>
                          <DNDUploadIcon />
                        </span>{' '}
                        Drag and Drop or <span style={{ color: '#0B4EF6' }}> choose file</span>
                      </Typography>
                    </>
                  )}
                </Box>
              ) : (
                <Box marginLeft="24px">
                  <Typography className={styles.title}>{VideoUploaderStrings.Title}</Typography>
                  <Typography className={styles.subtitle}>
                    {VideoUploaderStrings.Subtitle}
                  </Typography>
                </Box>
              )}
              {isDragActive && (
                <Box className={styles.dragOver}>
                  <FileIcon />
                </Box>
              )}
            </div>
          )}
          {isLoading && userFile && uploadingProgress < 100 && (
            <Box className={styles.loadingWrapper}>
              <Typography className={styles.fileName}>{userFile.name}</Typography>
              <Typography className={styles.fileSize}>
                {getFileSizeInMb(userFile.size)} MB
              </Typography>
              {!isUploadError && (
                <div className={styles.progressWrapper}>
                  <LinearProgressWithLabel value={uploadingProgress} className={styles.progress} />
                </div>
              )}
              {isUploadError && (
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    tryAgainHandler();
                  }}
                  className={styles.tryAgainButton}
                >
                  Try again
                </Button>
              )}
              {uploadingProgress >= 100 && (
                <Box className={styles.completedIcon}>
                  <CompletedIcon />
                </Box>
              )}
              {uploadingProgress < 100 && (
                <IconButton
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    cancelUploadingHandler();
                  }}
                >
                  <CancelIcon />
                </IconButton>
              )}
            </Box>
          )}
          {uploadingProgress === 100 && userFile && (
            <>
              <Box className={styles.dropzone}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <UploadCompleteIcon />
                  <Typography className={styles.fileName}>{userFile?.name}</Typography>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      cancelUploadingHandler();
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
              </Box>
            </>
          )}
        </Box>
      </div>
      {!isChangeCampaign && (
        <div>
          <Typography className={styles.spanStyles}>
            This clip will be added to {primaryCampaign?.name || 'your primary campaign'}{' '}
            {!!(campaigns.length > 1) && (
              <span
                onClick={handleChangeCampaignClick}
                style={{ color: '#0B4EF6', textDecoration: 'underline' }}
              >
                Change
              </span>
            )}
          </Typography>
        </div>
      )}
      {campaigns.length && campaigns.length >= 1 && isChangeCampaign && (
        <div className={styles.campaignSelectWrapper}>
          <span className={styles.spanStyles}>Add this clip to:</span>
          <Select
            className={styles.select}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              transformOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              marginThreshold: 0,
              PaperProps: {
                className: styles.menuWrapper,
              },
            }}
            classes={{
              icon: styles.dropdownIcon,
              iconOpen: styles.dropdownIconOpen,
            }}
            disableUnderline={true}
            IconComponent={(props) => (
              <Box {...props}>
                <ArrowBottomIcon />
              </Box>
            )}
            value={selectedCampaignId || 1}
            displayEmpty={false}
            disabled={!campaigns.length}
          >
            <MenuItem
              className={styles.selectItem}
              value={1}
              sx={{
                display: 'none',
              }}
            >
              <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <StarIcon /> {primaryCampaign?.name}
              </div>
            </MenuItem>
            {campaigns
              .filter((campaign) => !campaign.endedAt)
              .map((campaign) => (
                <MenuItem
                  className={styles.selectItem}
                  key={campaign.id}
                  value={campaign.id}
                  onClick={() => {
                    setSelectedCampaignId(campaign.id);
                  }}
                >
                  <div
                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                  >
                    {campaign.isPreferred ? <StarIcon /> : <></>}
                    <span
                      style={{ textOverflow: 'ellipsis', overflow: 'hidden', fontSize: '14px' }}
                    >
                      {campaign.name}
                    </span>
                  </div>
                </MenuItem>
              ))}
          </Select>
        </div>
      )}
      <Button
        variant="contained"
        color="primary"
        className={styles.addVideoButton}
        disabled={!url}
        onClick={handleAddVideoClick}
      >
        Add Creator & Clip
      </Button>
    </>
  );
};

export default VideoUploader;
