import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Input } from '@mui/material';
import { useStyles } from './AccountsAdvancedSearch.styles';
import { SearchIcon } from '../../assets/SearchIcon';
import { ClipsSearchStrings } from '../../localization/en';
import IconButton from '@mui/material/IconButton';
import { SearchButtonIcon } from '../../assets/SearchButton';
import { SearchPopUp } from './SearchPopUp/SearchPopUp';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setFilterSearch } from '../../../store/slices/searchFilterSlice';
// import { SearchTarget } from '../../../api/models/searchFilterModels';
// import { getCampaigns } from '../../../store/slices/campaignsSlice';
// import { defaultPagination } from '../../constants/constants';
import CloseIcon from '@mui/icons-material/Close';
import { SearchResultsList } from '../SearchResultsList/SearchResultsList';
import { getDebouncedFunc } from '../../../services/utilities';
import { getVenueListApiCall, resetPage } from '../../../store/slices/organizationVenueSlice';
import { UserRoles } from '../../constants/constants';

export const AccountsAdvancedSearch: FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const { search } = useTypedSelector((state) => state.searchFilter);

  const {
    // isLoading,
    // error,
    // items,
    // totalPages,
    // totalItems,
    // page,
    // size,
    sort,
    // search = '',
    isArchivedAccount,
  } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { groupId } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const { activeAffiliate } = useTypedSelector((state) => state.affiliates);
  const { activeDistributor } = useTypedSelector((state) => state.distributors);
  const includeTestGroups = (!!activeAffiliate && !!activeDistributor) || isArchivedAccount;

  const { role } = useTypedSelector((state) => state.me);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const isPopUpOpenRef = useRef(false);
  const inputRef = useRef<HTMLElement | null>(null);
  const [predictiveSearchItems, setPredictiveSearchItems] = useState<string[]>([]);
  const [isPredictiveItemsLoading, setIsPredictiveItemsLoading] = useState(false);

  const isSVAdmin = role === UserRoles.admin;

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const container = target.closest('#search-container') || target.closest('.MuiModal-root');
    const searchList = target.closest('#search-list');

    if (!container && !searchList && isPopUpOpenRef.current) {
      setIsPopUpOpen(false);
      isPopUpOpenRef.current = false;
    }
  };

  const getPredictiveSearchItems = getDebouncedFunc(async (search: string) => {
    setIsPredictiveItemsLoading(true);
    const result = await getVenueListApiCall({
      params: {
        page: '0',
        size: 100,
        sort: sort.toString(),
        search,
      },
      withStats: true,
      groupId,
      includeTestGroups: includeTestGroups,
      deactivated: isArchivedAccount,
    });
    setIsPredictiveItemsLoading(false);
    const predictiveSearchItems = result.items.map((item) => item.name || '').filter(Boolean);
    setPredictiveSearchItems([...new Set(predictiveSearchItems)]);
  }, 800);

  useEffect(() => {
    setSearchText(search);
  }, [search]);

  useEffect(() => {
    window.addEventListener('mousedown', handleClickOutside);
    return () => window.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (accountId) {
  //     dispatch(
  //       getSearchFilters({
  //         accountId,
  //         target: SearchTarget.VIDEOS,
  //       }),
  //     );

  //     dispatch(
  //       getCampaigns({
  //         accountId,
  //         pageable: { page: 0, size: 10000, sort: defaultPagination.sortByLastCreated },
  //       }),
  //     );
  //   }

  //   window.addEventListener('mousedown', handleClickOutside);
  //   return () => window.removeEventListener('mousedown', handleClickOutside);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [accountId]);

  const togglePopUp = () => {
    setIsPopUpOpen((prev) => {
      const isOpen = !prev;
      isPopUpOpenRef.current = isOpen;
      return isOpen;
    });
  };

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(setFilterSearch(search || searchText));
      dispatch(resetPage());

      setIsPopUpOpen(false);
    },
    [dispatch, searchText],
  );

  return (
    <Box className={classes.container} id="search-container">
      <SearchResultsList
        anchorEl={inputRef.current}
        searchItems={predictiveSearchItems}
        clearItems={() => {
          setPredictiveSearchItems([]);
        }}
        onClick={(searchText) => {
          setSearchText(searchText);
          dispatch(setFilterSearch(searchText));
          if (!isPopUpOpenRef.current) {
            onSearch(searchText);
          }
        }}
      />
      <Input
        id="search-input"
        ref={inputRef}
        className={classes.input}
        disableUnderline={true}
        placeholder={ClipsSearchStrings.PlaceholderAccountsSearchText}
        onChange={(event) => {
          const value = event.target.value;
          setSearchText(value);
          getPredictiveSearchItems(value);

          if (!value) {
            dispatch(setFilterSearch(''));
            setPredictiveSearchItems([]);
          }
        }}
        value={searchText}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            onSearch();
            setPredictiveSearchItems([]);
          }
        }}
      />
      <Box className={classes.searchIcon}>
        <SearchIcon />
      </Box>
      {searchText && !isPredictiveItemsLoading && (
        <IconButton
          onClick={() => {
            setSearchText('');
            dispatch(setFilterSearch(''));
          }}
          className={classes.resetSearchIcon}
        >
          <CloseIcon style={{ fontSize: '16px', cursor: 'pointer' }} />
        </IconButton>
      )}
      {isPredictiveItemsLoading && (
        <CircularProgress size={20} className={classes.predictiveItemsLoader} />
      )}
      {isSVAdmin ? (
        <IconButton className={classes.openSearchButton} onClick={togglePopUp}>
          <SearchButtonIcon />
        </IconButton>
      ) : (
        <></>
      )}
      {isPopUpOpen && (
        <Box className={classes.searchPopUpWrapper}>
          <SearchPopUp
            onSearch={onSearch}
            onApplyFilter={() => {
              togglePopUp();
              setSearchText(search);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
