import { FC } from 'react';

interface Props {
  color?: string;
}

export const QSActionIcon: FC<Props> = ({ color }) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7342 15.8333H4.78972V4.16667H10.7619V2.5H4.78972C3.8427 2.5 3.08337 3.25 3.08337 4.16667V15.8333C3.08337 16.75 3.8427 17.5 4.78972 17.5H16.7342C17.6727 17.5 18.4405 16.75 18.4405 15.8333V10H16.7342V15.8333ZM12.4683 2.5V4.16667H15.5312L7.14448 12.3583L8.34746 13.5333L16.7342 5.34167V8.33333H18.4405V2.5H12.4683Z"
        fill={color || '#FFB515'}
      />
    </svg>
  );
};
