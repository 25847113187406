import styled from 'styled-components';

export const Container = styled.div``;
export const Circle = styled.div`
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 8px;
  color: #2196f3;
  border-radius: 50%;
  background: #eaf4ff;
`;
