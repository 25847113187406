import { FC } from 'react';

import { Box } from '@mui/material';
import PublishedIcon from '../../assets/newDesign/PublishedIcon';
import UnpublishedIcon from '../../assets/newDesign/UnpublishedIcon';
import ModeratedIcon from '../../assets/newDesign/ModeratedIcon';
import PendingModerationIcon from '../../assets/newDesign/PendingModerationIcon';

export type StatusValues = 'PUBLISHED' | 'UNPUBLISHED' | 'MODERATED' | 'PENDING_MODERATION';

interface IStoryStatusprops {
  status: StatusValues | string;
}

export const VideoStatus: FC<IStoryStatusprops> = ({ status }) => {
  const clipStatusIcons: Record<StatusValues | string, JSX.Element> = {
    PUBLISHED: <PublishedIcon />,
    UNPUBLISHED: <UnpublishedIcon />,
    MODERATED: <ModeratedIcon />,
    PENDING_MODERATION: <PendingModerationIcon />,
  };

  return (
    <Box display="flex" alignItems="center">
      {clipStatusIcons[status]}
    </Box>
  );
};
