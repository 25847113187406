import { Collapse, List, ListItem, ListItemText } from '@mui/material';
import clsx from 'clsx';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';

// import { ExpandLessIcon } from '../../../../assets/newDesign/ExpandLessIcon';
// import { ExpandMoreIcon } from '../../../../assets/newDesign/ExpandMoreIcon';
import { ClientTypes } from '../../../../../api/models/common';
import { useTypedSelector } from '../../../../../store';
import { segmentTrackCall } from '../../../../../utils/segment';
import { segmentActions } from '../../../../constants/constants';
import { WebsiteRoutes } from '../../../../constants/routes';
import { checkIsActiveItem, checkIsActiveSubItem, IMenuItem } from '../Menu.helper';
import { SVBadge } from '../Menu.style';
import { useStyles } from './SubMenu.helper';
import { SubItemIcon } from './SubMenu.style';

interface SubMenuProps {
  item: IMenuItem;
  isSubMenuCollapsed: boolean;
  collapsed?: boolean;
  onClickCollapseManagerHandler: () => void;
}

export const SubMenu: FC<SubMenuProps> = ({
  item,
  isSubMenuCollapsed,
  collapsed,
  onClickCollapseManagerHandler,
}) => {
  const classes = useStyles();
  const { id: venueId, clientType } = useTypedSelector((state) => state.venue.venue);
  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;
  const { pathname } = useLocation();
  const redirectToPath = (path: string) => () => {
    if (collapsed) {
      onClickCollapseManagerHandler();
    }
    segmentTrackCall(segmentActions.SideBar + path.split('/').join('_'), venueId);
  };

  // const renderSubMenuArrows = () => {
  //   return isSubMenuCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;
  // };

  const renderSubMenu = () => {
    if (!item.subMenuItems) {
      return null;
    }

    return item.subMenuItems
      .filter((item) =>
        !isTopgolfVenue
          ? item
          : !(
              item.route === WebsiteRoutes.TeamSettings ||
              item.route === WebsiteRoutes.IntegrationsWebWidget
            ),
      )
      .map((subMenuItem) => {
        const isActive =
          checkIsActiveItem(pathname, subMenuItem.route) ||
          checkIsActiveSubItem(pathname, subMenuItem.route, subMenuItem.isNestedRoute || false);
        // const subItemIconColor =
        //   subMenuItem.color || (isActive ? CommonMenuColors.active : CommonMenuColors.inactive);

        return (
          <Collapse key={subMenuItem.route} in={isSubMenuCollapsed} timeout="auto" unmountOnExit>
            <List component="nav" disablePadding>
              <ListItem
                button
                onClick={redirectToPath(subMenuItem.route)}
                component={Link}
                to={subMenuItem.route}
                disabled={subMenuItem.disabled}
                className={classes.listItem}
              >
                <SubItemIcon className={classes.icon}>{subMenuItem.icon}</SubItemIcon>
                <ListItemText
                  primary={subMenuItem.text}
                  classes={{
                    primary: clsx({ [classes.text]: true, [classes.textActive]: isActive }),
                  }}
                />
                <SVBadge badgeContent={subMenuItem.notification} />
              </ListItem>
            </List>
          </Collapse>
        );
      });
  };

  // const onClickListArrowItem = () => {
  //   return !collapsed ? onClickCollapseManagerHandler : () => {};
  // };

  // const renderListItemText = () => {
  //   const onClick = redirectToPath(item.route);

  //   return (
  //     <ListItemText
  //       classes={{ root: classes.textParent, primary: classes.primary }}
  //       primary={item.text}
  //       onClick={onClick}
  //       className={classes.text}
  //     />
  //   );
  // };

  return (
    <>
      {/* <SVListItem classes={{ selected: classes.selected }} selected={!collapsed}> */}
      {/* {!collapsed && <SVListItemIcon className={classes.icon}>{item.icon}</SVListItemIcon>} */}
      {/* {renderListItemText()} */}
      {/* <SubMenuArrowsWrapper onClick={onClickListArrowItem()}>
          {renderSubMenuArrows()}
        </SubMenuArrowsWrapper> */}
      {/* </SVListItem> */}
      {renderSubMenu()}
    </>
  );
};
