import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { TopBar } from '../../common/components/topBar/TopBar';
import { WebappSubnavigation } from '../../common/components/webappSubnavigation/WebappSubnavigation';
import { MyProfile } from '../../common/components/MyProfile/MyProfile';
import { WebsiteRoutes } from '../../common/constants/routes';
import { subnavigationItems, useStyles } from './ProfilePage.helper';

import { WorkspaceStrings } from '../../common/localization/en';
import { UserRoles } from '../../common/constants/constants';
import { useAppDispatch, useTypedSelector } from '../../store';
import { SuperAdminAccountsPage } from '../superAdminAccountsPage/SuperAdminAccountsPage';
import { useEffect } from 'react';
import { getProperties, getVenue, setSelectedVenue } from '../../store/slices/venueSlice';
import { getDistributors } from '../../store/slices/distributorsSlice';
import { getAffiliates } from '../../store/slices/affiliatesSlice';
import { getActiveIncentiveCampaign } from '../../store/slices/campaignsSlice';
import { useAccountsSidebar } from '../../common/layout/AccountsSidebar/useAccountsSidebar';

export const ProfilePage = () => {
  const classes = useStyles();
  const { role, firstName, accounts } = useTypedSelector((state) => state.me);
  const { items } = useTypedSelector((state) => state.OrganizationVenueSlice);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { activeTabName: activeSidebarTabName } = useAccountsSidebar();

  // const hasAccessToOneDistributor

  const isMultiAccounts = accounts > 1 ? true : false;
  const singleAccount = accounts === 1;

  const isSocialVenueAdmin = ([UserRoles.admin_role] as string[]).includes(role);
  const isAdmin = isMultiAccounts || isSocialVenueAdmin;
  const superAdminSubnavItems = subnavigationItems;
  const clientnSubnavItems = subnavigationItems.slice(0, 1);

  useEffect(() => {
    if (singleAccount && pathname === WebsiteRoutes.ProfileAccounts && items.length) {
      dispatch(getVenue({ id: items[0].id }));
      dispatch(getProperties({ id: items[0].id }));
      dispatch(setSelectedVenue({ id: items[0].id }));
      dispatch(getActiveIncentiveCampaign(items[0].id));
      history.push(WebsiteRoutes.RewardCampaigns);
    }
  }, [pathname, dispatch, history, items, singleAccount]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getDistributors());
      dispatch(getAffiliates({}));
    }
  }, [isAdmin, dispatch]);

  const multiAccountWelcomeString =
    pathname === WebsiteRoutes.ProfileAccounts
      ? WorkspaceStrings.WelcomeBack.replace('{user name}', firstName).replace(
          '{section}',
          activeSidebarTabName,
        )
      : WorkspaceStrings.Profile;

  return (
    <>
      <TopBar
        sectionTitle={
          isAdmin
            ? multiAccountWelcomeString.replace('{user name}', firstName)
            : WorkspaceStrings.Profile
        }
      >
        {!isAdmin && (
          <WebappSubnavigation items={isAdmin ? superAdminSubnavItems : clientnSubnavItems} />
        )}
      </TopBar>

      <div className={classes.root}>
        <Switch>
          <Route exact path={WebsiteRoutes.ProfileProfile}>
            <MyProfile />
          </Route>
          <Route exact path={WebsiteRoutes.ProfileAccounts}>
            <SuperAdminAccountsPage />
          </Route>
        </Switch>
      </div>
    </>
  );
};
