import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  Container: {
    margin: '0 auto',
    marginTop: '70px',
    width: '613px',
    height: 'auto',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '20px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(82,66,234,0.05) 100%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  QuestionButton: {
    marginTop: '23px',
    marginRight: '23px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.gray,
    width: '19px',
    height: '19px',
    border: '1px solid',
    borderColor: theme.palette.common.gray,
    borderRadius: '50%',
    fontSize: '10px',
    filter: 'none',
  },
  Title: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.common.black,
    textAlign: 'center',
    marginBottom: '4px',
    textTransform: 'capitalize',
  },
  SubTitle: {
    width: '480px',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '21px',
    color: theme.palette.common.gray600,
    textAlign: 'center',
  },
  FooterLabel: {
    width: '480px',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '21px',
    color: theme.palette.common.gray,
    textAlign: 'center',
    marginTop: '20px',
  },
  iphonePreview: {
    transform: 'scale(0.85)',
    marginTop: '-14px',
  },
}));
