import { makeStyles } from '@mui/styles';
import { HeroesPageStrings } from '../../../../localization/en';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    flexDirection: 'column',
  },
  avatarRow: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3),
  },
  avatar: {
    display: 'flex',
    height: '100px',
    width: '100px',
    borderRadius: '10px',
    marginRight: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(3),
    width: theme.spacing(3),
    backgroundColor: theme.palette.common.lightMint,
    borderRadius: '4px',
    marginBottom: theme.spacing(1),
  },
  infoBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    height: '100px',
    width: '95px',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '12px',
    marginLeft: theme.spacing(1),
  },
  infoBlockName: {
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.text.secondary,
    marginBottom: '6px',
  },
  textInfo: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    marginRight: theme.spacing(2),
  },
  primaryTextColor: {
    color: theme.palette.text.primary,
  },
  secondaryTextColor: {
    color: theme.palette.text.secondary,
  },
}));

export interface InfoBlock {
  icon: string;
  name: HeroesPageStrings;
}

type InfoBlocksObject = {
  [key: string]: InfoBlock;
};
export const infoBlocks: InfoBlocksObject = {
  [HeroesPageStrings.Followers]: {
    icon: '',
    name: HeroesPageStrings.Followers,
  },
  [HeroesPageStrings.Following]: {
    icon: '',
    name: HeroesPageStrings.Following,
  },
  [HeroesPageStrings.NumberOfStories]: {
    icon: '',
    name: HeroesPageStrings.NumberOfStories,
  },
};
