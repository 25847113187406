import { FC } from 'react';
import { Button, CircularProgress } from '@mui/material';

import { useStyles } from './CustomButton.style';
import clsx from 'clsx';

interface Props {
  title: string;
  type?: 'button' | 'reset' | 'submit';
  className?: string;
  disabled?: boolean;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  startIcon?: JSX.Element | string;
  endIcon?: JSX.Element | string;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
  colorType?: 'primary' | 'secondary' | 'error' | 'dark';
  onClick?: (e?: any) => void;
  isLoading?: boolean;
}

export const CustomButton: FC<Props> = ({
  title,
  type = 'button',
  className,
  disabled,
  color = 'primary',
  startIcon,
  endIcon,
  fullWidth,
  size,
  variant = 'contained',
  colorType,
  onClick,
  isLoading,
}) => {
  const classes = useStyles();
  const aditionalStyle =
    colorType === 'primary'
      ? classes.buttonPrimary
      : colorType === 'secondary'
      ? classes.buttonSecond
      : colorType === 'error'
      ? classes.buttonError
      : colorType === 'dark'
      ? classes.buttonDark
      : null;

  return (
    <Button
      type={type}
      color={color}
      disabled={disabled}
      endIcon={endIcon}
      startIcon={startIcon}
      fullWidth={fullWidth}
      size={size}
      variant={variant}
      className={clsx(classes.button, aditionalStyle, className)}
      onClick={onClick}
    >
      {isLoading && (
        <CircularProgress
          style={{
            width: 30,
            height: 30,
            marginRight: '1em',
          }}
          color="secondary"
        />
      )}
      {title}
    </Button>
  );
};
