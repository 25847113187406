import styled from 'styled-components';
import { makeStyles } from '@mui/styles';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  gap: 15px;
  padding: 0 0 32px;
  box-sizing: border-box;
  border-radius: 16px;
`;

export const Title = styled.h1`
  color: #464c71;
  font-weight: 400;
  font-size: 24px;
  margin: 0;
`;

export const useStyles = makeStyles(() => ({}));
