import { useStyles } from './Campaigns.styles';
import { Box } from '@mui/material';
import { RoundedButton } from '../../../common/components/Button/RoundedButton';
import { CommonStrings } from '../../../common/localization/en';
import { WebsiteRoutes } from '../../../common/constants/routes';
import { FC, useEffect, useMemo, useState } from 'react';
import { LeafIcon } from '../../../common/assets/LeafIcon';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { useWindowResize } from '../../../services/hooks/useWindowResize';
import { LayoutMode, segmentActions } from '../../../common/constants/constants';
import { CreateCampaignsTableContent } from './Campaigns.helper';
import {
  getCampaigns,
  getPrimaryCampaign,
  goToSelectedPage,
  patchIncentiveCampaign,
  setCampaignsSorting,
  updateRequestsSize,
} from '../../../store/slices/campaignsSlice';
import {
  convertApiPageToFrontEndPage,
  convertFrontEndPageToApiPage,
} from '../../../services/utilities';
import { CommonTable } from '../../../common/components/table/CommonTable';
import { useTrackEvent } from '../../../hooks/useTrackEvent';
import { RewardCampaignsEvents } from '../../../common/constants/events/rewardCampaignsEvents';
import { ConfirmPrimaryModal } from '../../../common/components/ConfirmPrimaryModal/ConfirmPrimaryModal';
import { AnalyticsSection } from '../../dashboard/AnalyticsSection/AnalyticsSection';
import { PlatformType } from '../../dashboard/Dashboard.helper';
import { useSegmentPageCall } from '../../../hooks/useSegment';
import { segmentTrackCall } from '../../../utils/segment';
import {
  setActiveAnalyticsBlock,
  setKpiValues,
  reset,
  getTopGolfGeneralKPIs,
  getShareSummary,
  getGeneralKPIs,
  getKPIByIdShare,
  getKPIById,
} from '../../../store/slices/analyticsSlice';
import { KPIApiNames } from '../../../api/models/analytics';
import { ClientTypes } from '../../../api/models/common';
import {
  webInitialAnalyticsData,
  webInitialAnalyticsSalesData,
  webInitialAnalyticsTopGolfData,
} from '../../dashboard/AnalyticsSection/AnalyticsSection.helper';
import { VenueProperties } from '../../../api/models/venue';
import { useVenueProperty } from '../../../hooks/useVenueProperty';
import { useCreateDefaultCampaign } from '../../signUp/useCreateDefaultCampaign';
import { useClientType } from '../../../services/hooks/useClientType';
import { getVenue } from '../../../store/slices/venueSlice';
import { useIsFteFinished } from '../../../hooks/useIsFteFinished';
import { getConnectedPeekAccount } from '../../../store/slices/peekSlice';

export const Campaigns: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;
  const {
    isLoading,
    error,
    items,
    totalPages,
    totalItems,
    page,
    size,
    sort,
    search = '',
    lastUpdated,
  } = useTypedSelector((state) => state.campaigns);

  const venueId = useTypedSelector((state) => state.venue.venue.id);
  const { connectedAccount } = useTypedSelector((state) => state.peek);
  const [isConfirmPrimaryModalOpen, setIsConfirmPrimaryModalOpen] = useState(false);
  const [primaryCampaignId, setPrimaryCampaignId] = useState('');

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    if (venueId) {
      dispatch(getCampaigns({ search, accountId: venueId, pageable: { page, size, sort } }));
      dispatch(getConnectedPeekAccount({ accountId: venueId }));
    }
  }, [dispatch, page, size, sort, search, venueId, lastUpdated]);

  const tableContents = CreateCampaignsTableContent({
    items,
    onOpenCampaign: () => {},
    isEditButton: true,
    isMobile,
    onSetPrimaryCampaign: (campaignId: string) => {
      setIsConfirmPrimaryModalOpen(true);
      setPrimaryCampaignId(campaignId);
    },
    isPeekAccountConnected: !!connectedAccount,
  });

  const onGoToPage = (targetPage: number) => {
    const convertedTargetPage = convertFrontEndPageToApiPage(targetPage);

    if (convertedTargetPage >= totalPages) {
      return;
    }

    dispatch(goToSelectedPage(convertedTargetPage));
  };

  const onSort = (name: string) => {
    dispatch(setCampaignsSorting(name));
    trackEvent(RewardCampaignsEvents.RewardCampaignsTableSort.replace('name', name));
  };

  const changeSize = (size: number) => {
    dispatch(updateRequestsSize(size));
  };

  const handleConfirmPrimary = async () => {
    setIsConfirmPrimaryModalOpen(false);
    await dispatch(
      patchIncentiveCampaign({
        campaignId: primaryCampaignId,
        values: {
          isPreferred: true,
        },
      }),
    );
    await dispatch(getCampaigns({ search, accountId: venueId, pageable: { page, size, sort } }));
    dispatch(getPrimaryCampaign({ venueId }));
  };

  const { pathname } = useLocation();
  const { kpis, activeAnalyticsBlock, rangeFilter } = useTypedSelector((state) => state.analytics);
  const [platform, setPlatform] = useState<PlatformType>('');

  const { clientType } = useTypedSelector((state) => state.venue.venue);

  const { items: campaigns } = useTypedSelector((state) => state.campaigns);

  useSegmentPageCall();
  const isTopgolfVenue = clientType === ClientTypes.TOPGOLF;

  const { value: FTEValues, isLoading: isFTELoading } = useVenueProperty<
    VenueProperties['webapp.firstTimeOnboardingFinished.config']
  >({
    property: 'webapp.firstTimeOnboardingFinished.config',
  });

  const { value: wasCongratsModalSeen, isLoading: wasCongratsModalSeenLoading } = useVenueProperty<
    VenueProperties['dashboard.wasCongratsModalSeen']
  >({
    property: 'dashboard.wasCongratsModalSeen',
  });

  const { value: quickstartConfig, isLoading: isQuickstartConfigLoading } = useVenueProperty<
    VenueProperties['quickstart.config']
  >({
    property: 'quickstart.config',
  });

  const showFTE = FTEValues ? !FTEValues?.webapp_modal?.modal : !isFTELoading;

  const { createDefaultCampaign } = useCreateDefaultCampaign();
  const { isUwmClient } = useClientType();

  useEffect(() => {
    dispatch(
      getVenue({
        id: venueId,
      }),
    );
  }, [dispatch, venueId]);

  useEffect(() => {
    if (!venueId || isQuickstartConfigLoading) return;

    if (
      !isUwmClient &&
      ((quickstartConfig === undefined && showFTE) || quickstartConfig?.isQuickStartInProgress)
    ) {
      createDefaultCampaign(venueId).then(() => {
        history.push(WebsiteRoutes.QuickStart);
      });
    } else if (
      isUwmClient &&
      localStorage.getItem('showUwmLaunchModal') === 'true' &&
      campaigns.length > 0
    ) {
      const redirectCampaignId = (
        campaigns.find((campaign) => campaign.isPreferred) || campaigns[0]
      ).id;
      history.push(`${WebsiteRoutes.RewardCampaigns}/${redirectCampaignId}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    showFTE,
    quickstartConfig,
    venueId,
    isQuickstartConfigLoading,
    isUwmClient,
    campaigns,
  ]);

  useEffect(() => {
    if (activeAnalyticsBlock.main !== KPIApiNames.shares) {
      setPlatform('');
    }
  }, [activeAnalyticsBlock]);

  useEffect(() => {
    if (pathname) {
      const defaultBlock =
        clientType === ClientTypes.SALES
          ? webInitialAnalyticsSalesData
          : isTopgolfVenue
          ? webInitialAnalyticsTopGolfData
          : webInitialAnalyticsData;
      const block = isTopgolfVenue ? defaultBlock[0] : defaultBlock[1];
      dispatch(setKpiValues([]));
      dispatch(
        setActiveAnalyticsBlock({
          kpiName: (block?.apiName as KPIApiNames) || (block?.kpiName as KPIApiNames),
          name: block?.name ?? '',
        }),
      );
    }
  }, [dispatch, pathname, clientType, isTopgolfVenue]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    if (venueId) {
      const requestParams = rangeFilter
        ? { accountId: venueId, from: rangeFilter.from, to: rangeFilter.to }
        : { accountId: venueId };
      if (isTopgolfVenue) {
        dispatch(getTopGolfGeneralKPIs(requestParams));
        dispatch(getShareSummary(requestParams));
      } else {
        dispatch(getGeneralKPIs(requestParams));
        dispatch(getShareSummary(requestParams));
      }
    }
  }, [dispatch, lastUpdated, venueId, rangeFilter, isTopgolfVenue]);

  useEffect(() => {
    const kpi = activeAnalyticsBlock.sub || activeAnalyticsBlock.main;
    if (venueId && kpi) {
      const requestParams = rangeFilter
        ? { accountId: venueId, kpi, from: rangeFilter.from, to: rangeFilter.to }
        : { accountId: venueId, kpi };
      if (kpi === KPIApiNames.shares) {
        dispatch(getKPIByIdShare(platform ? { ...requestParams, platform } : requestParams));
      } else {
        dispatch(getKPIById(requestParams));
      }
    }
  }, [
    activeAnalyticsBlock.main,
    activeAnalyticsBlock.sub,
    dispatch,
    venueId,
    rangeFilter,
    platform,
  ]);

  const onClickAnalyticsBlockHandler = (kpiName: KPIApiNames, name: string) => {
    segmentTrackCall(`${segmentActions.Analytics}_${kpiName.toLowerCase()}`, venueId);
    dispatch(setActiveAnalyticsBlock({ kpiName, name }));
  };

  const FTEIsFinished = useIsFteFinished();
  const finishedFTEAndSelectedAdvancedSetup =
    FTEIsFinished || (!!quickstartConfig?.visitedRoutes && !!quickstartConfig.visitedRoutes.length);

  const isCongradsModalOpen = useMemo(() => {
    return (
      !wasCongratsModalSeen && !wasCongratsModalSeenLoading && finishedFTEAndSelectedAdvancedSetup
    );
  }, [finishedFTEAndSelectedAdvancedSetup, wasCongratsModalSeen, wasCongratsModalSeenLoading]);

  const [, setInvitesModalDisabled] = useState(false);

  useEffect(() => {
    if (isCongradsModalOpen) {
      setInvitesModalDisabled(true);
    }
  }, [isCongradsModalOpen]);

  useEffect(() => {
    trackEvent(RewardCampaignsEvents.RewardCampaignsPageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      <ConfirmPrimaryModal
        isOpen={isConfirmPrimaryModalOpen}
        onClose={() => {
          setIsConfirmPrimaryModalOpen(false);
        }}
        onCancel={() => {
          setIsConfirmPrimaryModalOpen(false);
        }}
        onConfirm={handleConfirmPrimary}
      />
      <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '30px' }}>
        <AnalyticsSection
          isHeroes
          onClick={onClickAnalyticsBlockHandler}
          kpis={kpis}
          activeAnalyticsBlock={activeAnalyticsBlock}
        />
      </div>

      <Box className={classes.btnWrapper}>
        <RoundedButton
          title={CommonStrings.CreateNewCampaign}
          startIcon={<LeafIcon />}
          sx={{
            padding: '10px 35px',
          }}
          onClick={() => {
            history.push(WebsiteRoutes.NewCampaign);
            trackEvent(RewardCampaignsEvents.RewardCampaignsNewRewardClick);
          }}
          id="create-new-campaign-button"
        />
      </Box>
      <Box className={classes.table}>
        <CommonTable
          content={tableContents}
          page={convertApiPageToFrontEndPage(page)}
          sort={sort}
          totalItems={totalItems}
          totalPages={totalPages}
          isLoading={isLoading}
          noContent={error}
          goToPage={onGoToPage}
          onSortHeaderClick={onSort}
          size={size}
          onSizeChange={changeSize}
          // handleCellClick={(rowId, cellIndex) => {
          //   if (cellIndex !== 7) {
          //     history.push(getApiUrlForId(WebsiteRoutes.CampaignDetailsSettings, rowId));
          //   }
          // }}
        />
      </Box>
    </Box>
  );
};
