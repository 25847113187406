import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

interface Props {
  size?: 'sm' | 'md';
  color?: string;
}

export const useStyles = makeStyles<Theme, Props>((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    height: '100%',
    margin: '0 50px',
    paddingBottom: '12px',
  },
  card: ({ size, color }) => ({
    position: 'relative',
    height: size === 'sm' ? '60px' : '72px',
    width: 'fit-content',
    minWidth: '170px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '35px',
    background: color || theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: '0px 4px 12px 0px #00000040',
    paddingLeft: '12px',
    paddingRight: '20px',
    '& svg': {
      width: size === 'sm' ? '36px' : '48px',
    },
    '&:hover': {
      '& > div:last-child': {
        display: 'block',
      },
    },
  }),
  title: {
    color: theme.palette.common.white,
    fontFamily: 'Arial',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '19px',
    letterSpacing: '0.02em',
    textAlign: 'left',
  },
  description: {
    color: theme.palette.common.white,
    fontFamily: 'Arial',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '14px',
    letterSpacing: '0.02em',
    width: '170px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
}));
