import React, { useEffect } from 'react';
import { useStyles } from './CampaignNavigation.styles';
import { Box } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { RewardsStrings } from '../../../../../common/localization/en';
import { WebsiteRoutes } from '../../../../../common/constants/routes';
import { getApiUrlForId } from '../../../../../api/endpoints';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import {
  getCampaignCountData,
  getCampaignVideos,
} from '../../../../../store/slices/campaignsSlice';
import { useClientType } from '../../../../../services/hooks/useClientType';

const CampaignNavigation = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { isHealthCare } = useClientType();
  const { id } = useParams<{ id: string }>();
  const { countData, inviteCounts } = useTypedSelector((state) => state.campaigns);
  const { campaignVideos } = useTypedSelector((state) => state.campaigns);

  const tabs = [
    {
      name: RewardsStrings.Essentials,
      path: WebsiteRoutes.CampaignDetailsEssentials,
      count: 0,
    },
    {
      name: RewardsStrings.Invites,
      path: WebsiteRoutes.CampaignDetailsInvites,
      count: inviteCounts.delivered + inviteCounts.undelivered,
    },
    {
      name: RewardsStrings.Visitors,
      path: WebsiteRoutes.CampaignDetailsVisitors,
      count: countData.users,
    },
    {
      name: isHealthCare ? RewardsStrings.Patients : RewardsStrings.Creators,
      path: WebsiteRoutes.CampaignDetailsCreators,
      count: countData.activeUsers,
    },
    {
      name: RewardsStrings.Clips,
      path: WebsiteRoutes.CampaignDetailsClips,
      count: campaignVideos.length,
    },
  ];

  useEffect(() => {
    dispatch(
      getCampaignVideos({
        id: id,
        pageable: {
          page: 0,
          size: 100000,
          sort: ['asc'],
        },
      }),
    );
    dispatch(getCampaignCountData({ campaignId: id }));
  }, [dispatch, id]);

  return (
    <Box className={classes.container}>
      {tabs.map((tab) => {
        const url = getApiUrlForId(tab.path, id);

        return (
          <Link
            key={tab.name}
            to={url}
            className={clsx(classes.tabItem, pathname === url && classes.activeTab)}
          >
            {tab.name} {tab.count > 0 && <Box className={classes.tabCount}>{tab.count}</Box>}
          </Link>
        );
      })}
    </Box>
  );
};

export default CampaignNavigation;
