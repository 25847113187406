import React, { Dispatch } from 'react';

import { CommonStrings } from '../../localization/en';
import { UploadFileIcon } from '../../assets/UploadFileIcon';

import { ChosenFile, DNDAction, DNDActionTypes, IDragAndDropState } from './DragAndDropState';
import {
  DragAndDropContainer,
  InnerText,
  PhotosUploadingContainer,
  PhotoContainer,
  UploaderWithoutFiles,
  UploadManually,
  SelectedImage,
  SelectedImageName,
  CheckIcon,
} from './DragAndDrop.style';

const allowedTypesForDisplayingImg = ['image/png', 'image/jpg', 'image/jpeg'];

interface DragAndDropProps {
  data: IDragAndDropState;
  dispatch: Dispatch<DNDAction>;
  acceptableType?: string;
  multiple?: boolean;
  resendMediaFile: (chosenFile: ChosenFile) => void;
}

export const DragAndDrop: React.FC<DragAndDropProps> = ({
  data,
  dispatch,
  multiple = false,
  acceptableType = '*',
  resendMediaFile,
}) => {
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files.length ? [...e.dataTransfer.files] : [];
    dispatch({
      type: multiple ? DNDActionTypes.ADD_TO_LIST : DNDActionTypes.INSERT_TO_LIST,
      payload: multiple ? files : [files[0]],
    });
    dispatch({ type: DNDActionTypes.SET_OVER_DROPZONE, payload: false });
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!data.inDropZone) {
      dispatch({ type: DNDActionTypes.SET_OVER_DROPZONE, payload: true });
    }
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (data.inDropZone) {
      dispatch({ type: DNDActionTypes.SET_OVER_DROPZONE, payload: false });
    }
  };

  const addFilesByClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const files = e.target.files && e.target.files.length ? [...e.target.files] : [];
    dispatch({
      type: multiple ? DNDActionTypes.ADD_TO_LIST : DNDActionTypes.INSERT_TO_LIST,
      payload: multiple ? files : [files[0]],
    });
  };

  const viewWithoutAddedFiles = () => (
    <UploaderWithoutFiles htmlFor="file-input">
      <UploadFileIcon />
      <InnerText>{CommonStrings.DragAndDropUploadFile}</InnerText>
      <input
        id="file-input"
        type="file"
        accept={acceptableType}
        multiple={multiple}
        onChange={addFilesByClick}
        style={{ display: 'none' }}
      />
    </UploaderWithoutFiles>
  );

  const viewWithFiles = () => (
    <UploaderWithoutFiles>
      <InnerText>
        {CommonStrings.DropFilesHere}
        {multiple ? CommonStrings.DropAddToList : CommonStrings.DropWantToChangeAddToList}
      </InnerText>
      <UploadManually htmlFor="file-input">{CommonStrings.DropClickToChooseFile}</UploadManually>
      <input
        id="file-input"
        type="file"
        multiple={multiple}
        onChange={addFilesByClick}
        style={{ display: 'none' }}
      />
    </UploaderWithoutFiles>
  );

  const onClickResendMediaFile = (e: React.MouseEvent<HTMLDivElement>, chosenFile: ChosenFile) => {
    e.preventDefault();
    if (chosenFile.isUploadError) {
      resendMediaFile(chosenFile);
    }
  };

  const renderUploadingPhotos = () => {
    const getUploadingProgressIcon = (chosenFile: ChosenFile) => {
      if (chosenFile.isUploadError) return 'fas fa-exclamation-triangle';
      if (chosenFile.isUploaded) return 'fas fa-check-circle';
      if (chosenFile.isUploading) return 'fas fa-spinner fa-pulse';
      else return undefined;
    };
    return (
      <PhotosUploadingContainer>
        {data.fileList.map((chosenFile, index) => {
          return (
            <PhotoContainer key={`${chosenFile.name}${index}`}>
              {allowedTypesForDisplayingImg.includes(chosenFile.file.type) && (
                <SelectedImage src={chosenFile.localUrl} alt={`${chosenFile.name}`} />
              )}

              <SelectedImageName>
                <p>{chosenFile.name}</p>
                <p>{chosenFile.size}</p>
              </SelectedImageName>
              <CheckIcon
                error={chosenFile.isUploadError}
                onClick={(e) => onClickResendMediaFile(e, chosenFile)}
                className={getUploadingProgressIcon(chosenFile)}
              />
            </PhotoContainer>
          );
        })}
      </PhotosUploadingContainer>
    );
  };

  return (
    <>
      <DragAndDropContainer onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
        {data.fileList.length > 0 ? viewWithFiles() : viewWithoutAddedFiles()}
      </DragAndDropContainer>
      {data.fileList.length > 0 && renderUploadingPhotos()}
    </>
  );
};
