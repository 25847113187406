import { FC } from 'react';
import { Box } from '@mui/material';

import { EarningItem } from './EarningItem/EarningItem';
import { createEarningValues } from './EarningItem/EarningItem.helper';
import { ActivityDTOModel } from '../../../rewardsActivities/ActivitiesListSection/ActivitiesListSection.helper';

interface EarningListSectionProps {
  items: ActivityDTOModel[];
  mainColor: string;
}
export const EarningListSection: FC<EarningListSectionProps> = ({ items, mainColor }) => {
  const earningValues = createEarningValues(items);

  return (
    <Box>
      {earningValues.map((item) => {
        return (
          <Box key={item.id} marginBottom={1}>
            <EarningItem isTextFirst item={item} mainColor={mainColor} />
          </Box>
        );
      })}
    </Box>
  );
};
