import styled from 'styled-components';
import { theme } from '../../../theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

interface PageTitleProps {
  centered: boolean;
}

export const PageTitle = styled.h1<PageTitleProps>`
  font-size: 24px;
  color: var(--primaryTextColor);
  margin-bottom: 8px;
  text-align: ${(props) => props.centered && "center"};

  @media (max-width: ${theme.breakpoints.values.md}px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

interface PageSubtitleProps {
  isSubtitle?: boolean;
}
export const PageSubtitle = styled.h3<PageSubtitleProps>`
  ${(props) => props.isSubtitle && 'min-height: 20px;'}
  font-size: 14px;
  line-height: 143%;
  color: var(--secondaryColor);

  @media (max-width: ${theme.breakpoints.values.md}px) {
    .MuiTypography-body1 {
      font-size: 12px;
    }
  }
`;

interface IconWrapperProps {
  bgrColor: string;
}
export const IconWrapper = styled.div<IconWrapperProps>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${(props) => props.bgrColor};
  border-radius: 8px;
`;
