import * as Yup from 'yup';

import { ErrorsStrings, TeamSettingsStrings } from '../../../common/localization/en';
import {
  emailValidationSchema,
  getYupValidationWithPhone,
} from '../../validation/commonValidation';
const required = ErrorsStrings.FieldIsRequired;

export const getValidationSchemaInviteTeamMember = getYupValidationWithPhone(
  {
    name: Yup.string()
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' First Name')
      .required(required)
      .trim(),
    lastName: Yup.string()
      .max(48, ErrorsStrings.Maxlength48characters)
      .matches(/^([A-Za-z ,.'`-]{2,48})$/gm, ErrorsStrings.EnterValid + ' Last Name')
      .required(required)
      .trim(),
    email: emailValidationSchema.required(TeamSettingsStrings.ErrorEmailMessage),
  },
  { phoneRequired: true },
);
