import * as React from 'react';
import { SVGProps } from 'react';

export const ShapeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="172"
    height="182"
    viewBox="0 0 172 182"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_292_4520)">
      <path
        d="M10 13.6191C10 9.20086 13.5817 5.61914 18 5.61914L133.442 5.61914L153.442 81.8412L133.442 158.063H18C13.5817 158.063 10 154.482 10 150.063L10 13.6191Z"
        fill="url(#paint0_linear_292_4520)"
      />
      <path
        d="M10.5 13.6191C10.5 9.477 13.8579 6.11914 18 6.11914L133.057 6.11914L152.926 81.8412L133.057 157.563H18C13.8579 157.563 10.5 154.205 10.5 150.063L10.5 13.6191Z"
        stroke="#F7B409"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_292_4520"
        x="0"
        y="0.619141"
        width="171.442"
        height="180.444"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="9" />
        <feGaussianBlur stdDeviation="7" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.609197 0 0 0 0 0.6125 0 0 0 0 0.556354 0 0 0 0.26 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_292_4520" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_292_4520"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_292_4520"
        x1="71.7212"
        y1="5.61914"
        x2="71.7212"
        y2="158.063"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D5FB" />
        <stop offset="1" stopColor="#5242EA" />
      </linearGradient>
    </defs>
  </svg>
);
