import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTypedSelector } from '../../../store';
import { useStyles } from './WelcomeVideoStylesPreview.styles';

// import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded';
import { useClientType } from '../../../services/hooks/useClientType';
import { subtitle } from './WelcomeVideoStylesPreview.helper';
import { NewCampaignStrings } from '../../../common/localization/en';
import WelcomeVideoIPhonePreview from './WelcomeVideoIPhonePreview';
import VideoStyleListInspirationalIcon from '../../../common/assets/QuickStartIcons/VideoStyleListInspirationalIcon';
import { UploadProcessStep } from '../../../store/storeModels';
import CreateVideoPreview from '../../../common/components/previews/CreateVideoPreview/CreateVideoPreview';
import { useVenue } from '../../../hooks/useVenue';
import { useBackgroundImage } from '../../../hooks/webApp/useBackgroundImage';
import { WelcomeVideoStylesType } from '../WelcomeVideoStyles/WelcomeVideoStyles';

interface WelcomeVideoStylesPreviewProps {}

const WelcomeVideoStylesPreview: FC<WelcomeVideoStylesPreviewProps> = () => {
  const styles = useStyles();
  const { isHealthCare } = useClientType();
  const { recommendedVideoStyle } = useTypedSelector((state) => state.createCampaign);
  const { currentStep } = useTypedSelector((state) => state.uploadVideo);
  const { backgroundImage } = useBackgroundImage();
  const { venueProperties, logo } = useVenue();
  const launchScreenOptions = venueProperties?.['webapp.launch-screen'];
  const brandColor = venueProperties?.['webapp.config']?.['primary-color'];

  const text = subtitle;

  const theme = useTheme();

  return (
    <Box className={styles.Container}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', height: '42px' }}>
        {/* <IconButton className={styles.QuestionButton}>
          <QuestionMarkRoundedIcon fontSize="inherit" />
        </IconButton> */}
      </Box>

      <Box sx={{ width: '100%', textAlign: 'center' }}>
        <VideoStyleListInspirationalIcon color={theme.palette.primary.main} />
      </Box>
      <Typography className={styles.Title}>
        {currentStep !== UploadProcessStep.UploadVideo
          ? NewCampaignStrings.CustomWelcomeVideoPreviewTitle
          : text.title}
      </Typography>
      <Typography className={styles.SubTitle}>
        {currentStep !== UploadProcessStep.UploadVideo
          ? isHealthCare
            ? NewCampaignStrings.CustomWelcomeVideoPreviewSubTitle.replace('Creators', 'Patients')
            : NewCampaignStrings.CustomWelcomeVideoPreviewSubTitle
          : isHealthCare
          ? text.subtitle.replace(`creator`, `patient`)
          : text.subtitle}
      </Typography>
      <Typography className={styles.FooterLabel}>
        {isHealthCare
          ? NewCampaignStrings.PreviewFooterLabel.replace(`creator`, `patient`)
          : NewCampaignStrings.PreviewFooterLabel}
      </Typography>
      <Box className={styles.iphonePreview}>
        {recommendedVideoStyle !== WelcomeVideoStylesType.none ? (
          <WelcomeVideoIPhonePreview />
        ) : (
          <CreateVideoPreview
            brandColor={brandColor}
            image={backgroundImage || ''}
            logo={logo}
            reveal={launchScreenOptions?.reveal || 'splash'}
            cover={launchScreenOptions?.cover || 'white'}
            launchScreenType={launchScreenOptions?.type || 'name'}
          />
        )}
      </Box>
    </Box>
  );
};

export default WelcomeVideoStylesPreview;
