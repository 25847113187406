import { FC } from 'react';

export const ExpandMoreIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" fill="none" viewBox="0 0 10 6">
      <path
        fill="#BEC1D8"
        d="M8.586 0H1.414C.524 0 .077 1.077.707 1.707l3.586 3.586a1 1 0 001.414 0l3.586-3.586C9.923 1.077 9.477 0 8.586 0z"
      />
    </svg>
  );
};
