import { FC, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useClasses } from './RewardsBlock.helper';
import { UserApiModel } from '../../../../api/models/users';
import { useTypedSelector } from '../../../../store';
import { useWindowResize } from '../../../../services/hooks/useWindowResize';
import { LayoutMode } from '../../../constants/constants';
import { LeaderBoardButton } from '../../../../pages/rewards/LeaderBoardButton/LeaderBoardButton';
import { CampaignsApiModel } from '../../../../api/models/campaigns';
import { RewardsPointsBlock } from './RewardsPointsBlock';
import CampaignHistory from './CampaignHistory/CampaignHistory';

interface RewardsBlockProps {
  isManageRewardsRedemptionEntry: boolean;
  approveReward: (id: string, name: string) => void;
  user: UserApiModel | null;
  onClickOutsideDisable?: (value: boolean) => void;
  isCampaignUser?: boolean;
  entity?: 'campaigns' | 'heroes';
}
export const RewardsBlock: FC<RewardsBlockProps> = ({
  isManageRewardsRedemptionEntry,
  approveReward,
  user,
  onClickOutsideDisable,
  isCampaignUser,
  entity,
}) => {
  const classes = useClasses();
  const { currUserActiveCampaign, userIncentiveCampaigns } = useTypedSelector(
    (state) => state.campaigns,
  );
  const [editedCampaign, setEditedCampaign] = useState<null | CampaignsApiModel>(null);

  const isRewardPage = true;
  const device = useWindowResize();
  const isMobile = device === LayoutMode.Mobile;

  const LeaderBoardButtonForMobile = useMemo(
    () =>
      isMobile ? (
        <Box mt={4} mb={4} display="flex" justifyContent="center">
          <LeaderBoardButton
            isRewardPage={isRewardPage}
            editedCampaign={editedCampaign}
            setEditedCampaign={setEditedCampaign}
          />
        </Box>
      ) : (
        <>
          <LeaderBoardButton
            isRewardPage={isRewardPage}
            editedCampaign={editedCampaign}
            setEditedCampaign={setEditedCampaign}
          />
        </>
      ),
    [isRewardPage, editedCampaign, isMobile],
  );
  const handleApproveRewardClick = () => {
    if (user) {
      approveReward(user.id, user.name || '');
    }
  };

  return (
    <Box className={classes.root}>
      <Box mt={2} />
      <div>
        <RewardsPointsBlock
          user={user}
          activeCampaign={currUserActiveCampaign}
          leaderBoardButtonForMobile={LeaderBoardButtonForMobile}
          handleApproveRewardClick={handleApproveRewardClick}
          onClickOutsideDisable={onClickOutsideDisable}
          isCampaignUser={isCampaignUser}
          entity={entity}
        />
      </div>
      {userIncentiveCampaigns && userIncentiveCampaigns.length > 1 && currUserActiveCampaign && (
        <CampaignHistory />
      )}
      <Box mt={2} />
    </Box>
  );
};
