import styled, { keyframes } from 'styled-components';

export const ProgressButton = styled.button`
  position: relative;
  display: block;
  border: none;
  border-radius: 50%;
  margin: 0 auto;
  align-self: center;
  padding: 0;
  cursor: pointer;
  background-color: #fff;
  width: 72px;
  height: 72px;
`;

export const ActivityState = styled.span`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ff0000;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 0.2s ease-in-out;
  transform: translate(-50%, -50%) scale(0.8);

  &.active {
    transform: translate(-50%, -50%) scale(0.35);
    border-radius: 16px;
  }
`;

export const ProgressSvg = styled.svg`
  display: inline-block;
  max-width: 100%;
  transform: rotate(-90deg);
`;

const strokeOffset = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const AnimatedCircle = styled.circle<{ durationInSeconds: number }>`
  fill: none;
  transition: ${strokeOffset} 800ms linear;

  &.active {
    animation: ${strokeOffset} ${(props) => props.durationInSeconds + 's'} linear forwards;
  }
`;

export const PlainCircle = styled.circle`
  fill: none;
`;
