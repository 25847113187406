import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useStyles } from './InviteOption.styles';
import { CheckmarkIcon } from '../../assets/newDesign/CheckmarkIcon';

interface Props {
  title: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
  textWidth?: string;
  key: string;
}

const InviteOption: FC<Props> = ({ title, text, isActive, onClick, textWidth, key }) => {
  const styles = useStyles();

  return (
    <Box
      className={`${styles.container} ${isActive && styles.containerActive}`}
      onClick={onClick}
      key={key}
    >
      <CheckmarkIcon className={`${styles.checkmark} ${isActive && styles.checkmarkActive}`} />
      <Typography className={`${styles.title} ${isActive && styles.titleActive}`}>
        {title}
      </Typography>
      <Typography className={styles.text} width={textWidth || '244px'}>
        {text}
      </Typography>
    </Box>
  );
};

export default InviteOption;
