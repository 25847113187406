import { FC } from 'react';

export const FacebookIcon: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="16" fill="none" viewBox="0 0 9 16">
      <path
        fill="#fff"
        d="M8 9H5.656v7H2.531V9H0V6.094h2.531V3.906c0-.833.156-1.541.469-2.125A3.07 3.07 0 014.313.47C4.896.156 5.563 0 6.313 0 6.646 0 7 .02 7.375.063c.375.02.667.052.875.093l.313.032v2.468h-1.25c-.584 0-1.01.156-1.282.469-.25.292-.375.656-.375 1.094v1.875h2.782L8 9z"
      ></path>
    </svg>
  );
};
