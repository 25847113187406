import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '80px',
    borderBottom: '1px solid #DCDBE4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'white',
  },
  btnBack: {
    marginLeft: '60px',
    height: '45px',
    '& p': {
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.02em',
    },
  },
  title: {
    fontSize: '18px',
    lineHeight: '30px',
    fontWeight: 500,
    letterSpacing: '0.02em',
    color: '#000000',
  },
  btnSave: {
    marginRight: '60px',
    width: '250px',
    height: '54px',
  },
}));
