import { FC, ReactNode } from 'react';
import { List } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { ListContent, ListRow, KeysExceptions } from './CommonList.helper';
import { ListItemStyled, HeaderCell, HeaderCells } from './CommonList.style';

interface CommonListProps {
  content: ListContent<ListRow>;
  children?: ReactNode;
  editItemId?: string;
  hasAddForm?: boolean;
}
export const CommonList: FC<CommonListProps> = ({ content, children, editItemId, hasAddForm }) => {
  const renderListItemCell = (row: ListRow) => {
    const itemCells: JSX.Element[] = [];
    Object.entries(row).forEach((entry, index) => {
      const key = entry[0];
      const cell = entry[1];
      const name = typeof cell === 'string' ? cell : cell;

      if (!KeysExceptions.includes(key)) {
        itemCells.push(
          <div key={key} style={{ width: content.itemWidth[index - 1] }}>
            {name}
          </div>,
        );
      }
    });
    return <>{itemCells}</>;
  };

  const renderListHeaders = content.header && (
    <HeaderCells>
      {Object.values(content.header).map((header: string, index) => (
        <HeaderCell
          key={uuidv4()}
          style={{ width: content.itemWidth[index], whiteSpace: 'nowrap' }}
        >
          {header}
        </HeaderCell>
      ))}
    </HeaderCells>
  );

  const renderListItem =
    content.rows &&
    content.rows.map((row: ListRow) => (
      <ListItemStyled key={uuidv4()} bgColor={row.bgColor as string}>
        {editItemId === row.id ? children : renderListItemCell(row)}
      </ListItemStyled>
    ));

  return (
    <List>
      {renderListHeaders}
      {hasAddForm && children}
      {renderListItem}
    </List>
  );
};
