import { FC, useEffect, useState } from 'react';
import { FSModal } from '../../../../../common/components/modal/Modal';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@material-ui/core';
import { VenueApiModelWithStats } from '../../../../../api/models/venue';
import { format } from 'date-fns';
import { VenueManagerApiModel } from '../../../../../api/models/venueManagers';
import { useAppDispatch, useTypedSelector } from '../../../../../store';
import { UserRoles, VenueMappingRoles } from '../../../../../common/constants/constants';
import { getVenueManagerList } from '../../../../../store/slices/venueManagerSlice';
import { InitialStatusIcon } from '../../../../../common/assets/Statuses/InitialStatusIcon';
import { useToasts } from 'react-toast-notifications';

interface AccountActivationDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onGoToAccount: (id: string) => void;
  venue: VenueApiModelWithStats;
}

enum AccountActivationDetailsModalStrings {
  ActivationDate = 'Activation Date:',
  PaymentDate = 'Payment Date:',
  OwnerName = 'Owner Name:',
  EmailAddress = 'Email Address:',
  PhoneNumber = 'Phone Numbers:',
  CompanyName = 'Company Name:',
  Title = 'Account Activation Details',
  Note = 'If this customer has granted permission for SocialV to complete Account Setup, ',
  startHere = 'start here.',
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    color: '#2491EB',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '16px',
    textAlign: 'center',
  },
  label: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '12px',
    textAlign: 'left',
    color: theme.palette.text.secondary,
    marginBottom: '8px',
  },
  value: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '16px',
    textAlign: 'left',
    color: theme.palette.common.black,
    overFlow: 'hidden',
    textOverflow: 'ellipsis',
  },
  note: {
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14.4px',
    letterSpacing: '0.02em',
    textAlign: 'center',
  },
}));

export const AccountActivationDetailsModal: FC<AccountActivationDetailsModalProps> = ({
  isOpen,
  onClose,
  onGoToAccount,
  venue,
}) => {
  const { role } = useTypedSelector((state) => state.me);
  const { items: managers } = useTypedSelector((state) => state.VenueManagerSlice);
  const [owner, setOwner] = useState<VenueManagerApiModel>();
  const classes = useStyles();
  const isSVAdmin = role === UserRoles.admin;
  const dispatch = useAppDispatch();
  const { addToast } = useToasts();

  useEffect(() => {
    if (managers && managers.length) {
      const newowner = managers.filter((item) => item.role !== VenueMappingRoles.owner)[0];
      setOwner(newowner);
    }
  }, [managers, setOwner]);

  useEffect(() => {
    if (isSVAdmin && venue && venue.id) {
      dispatch(getVenueManagerList({ accountId: venue.id }));
    }
  }, [isSVAdmin, venue, dispatch]);

  const copyEmail = () => {
    navigator.clipboard.writeText(owner?.email || '');
    addToast('Email Copied To Clipboard', { appearance: 'success' });
  };

  if (!venue) {
    return <></>;
  }
  return (
    <FSModal
      onClose={onClose}
      modalIsOpen={isOpen}
      rounded="16px"
      blur={true}
      width="450px"
      padding="0"
      closeBtn={true}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        padding="14px 10px"
        height={'50px'}
        borderBottom={'1px solid #F2F3F7'}
      >
        <span style={{ marginTop: '5px', marginRight: '8px' }}>
          <InitialStatusIcon />
        </span>
        <Typography className={classes.title}>
          {AccountActivationDetailsModalStrings.Title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderBottom={'1px solid #F2F3F7'}
      >
        <Grid container justifyContent="center" alignItems="center" padding="14px 24px">
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {AccountActivationDetailsModalStrings.ActivationDate}
            </Typography>
            <Typography className={classes.value} style={{ overflow: 'hidden' }}>
              {venue.createdAt ? format(new Date(venue.createdAt), 'PP') : '---'}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.label} style={{ textAlign: 'end' }}>
              {AccountActivationDetailsModalStrings.PaymentDate}
            </Typography>
            <Typography className={classes.value} style={{ overflow: 'hidden', textAlign: 'end' }}>
              {venue.lastSuccessfulBillingDate
                ? format(new Date(venue.lastSuccessfulBillingDate), 'PP')
                : '---'}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Grid container justifyContent="center" alignItems="center" padding="14px 24px">
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {AccountActivationDetailsModalStrings.CompanyName}
            </Typography>
            <Typography className={classes.value} style={{ overflow: 'hidden' }}>
              {venue.name}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.label} style={{ textAlign: 'end' }}>
              {AccountActivationDetailsModalStrings.OwnerName}
            </Typography>
            <Typography className={classes.value} style={{ overflow: 'hidden', textAlign: 'end' }}>
              {venue.ownerName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Grid container justifyContent="center" alignItems="center" padding="14px 24px">
          <Grid item xs={8}>
            <Typography className={classes.label}>
              {AccountActivationDetailsModalStrings.EmailAddress}
            </Typography>
            <Typography
              className={classes.value}
              style={{ overflow: 'hidden', cursor: 'copy' }}
              onClick={copyEmail}
            >
              {owner?.email}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.label} style={{ textAlign: 'end' }}>
              {AccountActivationDetailsModalStrings.PhoneNumber}
            </Typography>
            <Typography className={classes.value} style={{ overflow: 'hidden', textAlign: 'end' }}>
              {owner?.phoneNumber}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" padding="14px 24px">
        <Typography className={classes.note}>
          {AccountActivationDetailsModalStrings.Note}
          <span
            onClick={() => {
              onGoToAccount(venue.id);
            }}
            style={{ color: '#5242EA', fontWeight: 'bold', cursor: 'pointer' }}
          >
            {AccountActivationDetailsModalStrings.startHere}
          </span>
        </Typography>
      </Box>
    </FSModal>
  );
};
