import React, { useRef } from 'react';
import { Box } from '@mui/material';
import clsx from 'clsx';
import RewardTypeButton from '../../../common/components/RewardTypeButton/RewardTypeButton';
import { useTrackEvent } from '../../../hooks/useTrackEvent';

import { NewCampaignEvents } from '../../../common/constants/events/newCampaignEvents';

import { RewardStrings } from '../../../common/localization/en';

import { useVideoStyleTypesSwitchStyles } from './VideoStyleTypesSwitch.style';
import VideoStyleInspirationalIconActive from '../../../common/assets/QuickStartIcons/VideoStyleInspirationalIconActive';
import VideoStyleInspirationalIcon from '../../../common/assets/QuickStartIcons/VideoStyleInspirationalIcon';
import VideoStyleExperientialIconActive from '../../../common/assets/QuickStartIcons/VideoStyleExperientialIconActive';
import VideoStyleExperientialIcon from '../../../common/assets/QuickStartIcons/VideoStyleExperientialIcon';
import VideoStyleFunnyIconActive from '../../../common/assets/QuickStartIcons/VideoStyleFunnyIconActive';
import VideoStyleFunnyIcon from '../../../common/assets/QuickStartIcons/VideoStyleFunnyIcon';
import { useAppDispatch, useTypedSelector } from '../../../store';
import { setRecommendedVideoStyle } from '../../../store/slices/createCampaignSlice';

export enum VideoStyleTypes {
  Inspirational = 'inspirational',
  Experiential = 'experiential',
  Funny = 'funny',
}
export enum VideoStyleCapitalizedTypes {
  Inspirational = 'Inspirational',
  Experiential = 'Experiential',
  Funny = 'Funny',
}

export const rewardButtons = [
  {
    title: RewardStrings.Inspirational,
    type: VideoStyleTypes.Inspirational,
    activeIcon: <VideoStyleInspirationalIconActive />,
    icon: <VideoStyleInspirationalIcon />,
  },
  {
    title: RewardStrings.Experiential,
    type: VideoStyleTypes.Experiential,
    activeIcon: <VideoStyleExperientialIconActive />,
    icon: <VideoStyleExperientialIcon />,
  },
  {
    title: RewardStrings.Funny,
    type: VideoStyleTypes.Funny,
    activeIcon: <VideoStyleFunnyIconActive />,
    icon: <VideoStyleFunnyIcon />,
  },
];

export const VideoStyleTypesSwitch = () => {
  const inputRef = useRef<HTMLInputElement>();
  const { trackEvent } = useTrackEvent();
  const styles = useVideoStyleTypesSwitchStyles();
  const dispatch = useAppDispatch();
  const { recommendedVideoStyle } = useTypedSelector((state) => state.createCampaign);

  const handleButtonClick = (type: VideoStyleTypes) => {
    trackEvent(NewCampaignEvents.NewRewardCampaignVideoStyleTypesSwitch, { value: type });
    if (inputRef?.current) {
      inputRef.current.focus();
    }
    dispatch(setRecommendedVideoStyle(type));
  };

  return (
    <Box className={styles.rewardTypes}>
      {rewardButtons.map((button) => {
        const isActive = button.type === recommendedVideoStyle;
        return (
          <RewardTypeButton
            icon={isActive ? button.activeIcon : button.icon}
            title={button.title}
            onClick={() => {
              handleButtonClick(button.type);
            }}
            isActive={isActive}
            key={button.title}
            className={clsx(styles.button, {
              [styles.active]: button.type === recommendedVideoStyle,
            })}
          />
        );
      })}
    </Box>
  );
};
