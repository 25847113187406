import React, { MouseEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { TopbarSelectedFilter } from '../../common/components/TopbarSelectedFilter';
import { defaultPagination } from '../../common/constants/constants';
import { CreatorsTableEventNames } from '../../common/constants/events/creatorsTableEvents';
import { WebsiteRoutes } from '../../common/constants/routes';
import { HeroesPageStrings } from '../../common/localization/en';
import { useSegmentPageCall } from '../../hooks/useSegment';
import { useTrackEvent } from '../../hooks/useTrackEvent';
import { useClientType } from '../../services/hooks/useClientType';
import { useAppDispatch, useTypedSelector } from '../../store';
import { getActiveIncentiveCampaign } from '../../store/slices/campaignsSlice';
import { getUserCounts, setUserDataSorting } from '../../store/slices/heroesSlice';
import { setActiveFilter } from '../../store/slices/searchFiltersSlice';
import { HeroesContainer } from './Heroes.style';
import { TableSection } from './tableSection/TableSection';
import { CreatorDetailsSlideout } from '../../common/components/CreatorDetailsSlideout/CreatorDetailsSlideout';
import CreatorsSearchTags from '../../common/components/CreatorsSearchTags/CreatorsSearchTags';
import {
  resetActiveCreatorFilterAttributes,
  setFilterSearch,
} from '../../store/slices/searchFilterSlice';
import { CreatorAttributeName } from '../../api/models/searchFilterModels';

export const Heroes: React.FC = () => {
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { id: venueId } = useTypedSelector((state) => state.venue.venue);
  const { activeFilter } = useTypedSelector((state) => state.SearchFilterSlice);
  const { activeCreatorFilterAttributes, resetTags } = useTypedSelector(
    (state) => state.searchFilter,
  );
  const dispatch = useAppDispatch();

  const [id, setId] = useState('');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { isHealthCare } = useClientType();

  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    dispatch(setUserDataSorting(defaultPagination.sortByLastVideosCount[0]));

    trackEvent(CreatorsTableEventNames.PageLoad);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (venueId) {
      dispatch(getActiveIncentiveCampaign(venueId));
      dispatch(
        getUserCounts({
          accountId: venueId,
        }),
      );
    }
  }, [dispatch, venueId]);

  useEffect(() => {
    return () => {
      dispatch(resetActiveCreatorFilterAttributes());
      dispatch(setFilterSearch(''));
    };
  }, [dispatch]);

  useSegmentPageCall();
  const close = () => {
    history.push(WebsiteRoutes.Heroes);
  };

  const handleClearFilter = () => {
    dispatch(setActiveFilter(null));
  };

  const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setId(id !== event.currentTarget?.id ? event.currentTarget.id : '');
  };

  return (
    <HeroesContainer>
      <TopbarSelectedFilter
        anchorEl={anchorEl}
        handleSetAnchorEl={() => setAnchorEl(null)}
        handleSetId={() => setId('')}
        handleClick={handleClick}
        handleClearFilter={handleClearFilter}
        sectionTitle={isHealthCare ? 'Patients' : HeroesPageStrings.Heroes}
        multiline={false}
      />
      {activeCreatorFilterAttributes.filter(
        (filter) => filter?.name !== CreatorAttributeName.Status,
      ).length > 0 &&
        resetTags !== null && <CreatorsSearchTags />}
      <TableSection showActionBar={activeFilter ? true : false} />
      <CreatorDetailsSlideout userId={params.id} isOpen={!!params.id} handleClose={close} />
    </HeroesContainer>
  );
};
