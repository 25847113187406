import { FC } from 'react';
import { useTheme } from '@mui/material';

export const TooltipInfoIcon: FC = () => {
  const theme = useTheme();
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 2C4.875 2 1.5 5.375 1.5 9.5C1.5 13.625 4.875 17 9 17C13.125 17 16.5 13.625 16.5 9.5C16.5 5.375 13.125 2 9 2Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M9 12.5L9 8.75"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12.5L9 8.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00391 6.5L8.99717 6.5"
        stroke="#292D32"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00391 6.5L8.99717 6.5"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
